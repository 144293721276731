import {Component, Input, OnInit} from "@angular/core";
import {QuestionComponent, QuestionComponentData} from "ee-lahete";
import {ControlContainer} from "@angular/forms";
import {DateObj} from "../../../touko-lomake/syote/syote-utils";
import {NayteTutkimusData} from "../../lahete/lahete-data.model";

@Component({
  selector: 'question-nayte',
  template: `
    <div class="form-row border-left border-primary" [formGroup]="controlContainer.control">
      <div class="col-12 mt-0 mb-0 pl-md-4">
        <p class="small mb-0 pb-0"><strong>{{questionData.label}}</strong></p>
      </div>
      <div class="col-md-3 pl-md-4">
        <div class="form-group">
          <label attribute="sahkoelain.naytenumero" [htmlFor]="'nayte-'+ key +'-tunnus'">Näytenumero</label>
          <input class="form-control" [id]="'nayte-'+ key +'-tunnus'" formControlName="nayteTunnus">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label attribute="sahkoelain.naytemateriaali">Näytemateriaali</label>
          <div class="form-check" *ngFor="let naytemateriaali of nayteTutkimus.naytemateriaalit">
            <input [id]="'naytemateriaali-' + key + '-' +naytemateriaali.value" class="form-check-input" type="radio" formControlName="naytemateriaali" [value]="naytemateriaali.value">
            <label [htmlFor]="'naytemateriaali-' + key + '-' +naytemateriaali.value" class="form-check-label">{{naytemateriaali.label}}</label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label attribute="sahkoelain.tutkimussyy">Tutkimussyy</label>
          <div class="form-check" *ngFor="let tutkimussyy of nayteTutkimus.tutkimussyyt">
            <input [id]="'tutkimussyy-' + key + '-' +tutkimussyy.value" class="form-check-input" type="radio" formControlName="tutkimussyy" [value]="tutkimussyy.value">
            <label [htmlFor]="'tutkimussyy-' + key + '-' +tutkimussyy.value" class="form-check-label">{{tutkimussyy.label}}</label>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label attribute="sahkoelain.naytteenottoPvm" [htmlFor]="'nayte-'+ key +'-paivamaara'">
            Näytteenotto pvm.
          </label>
          <touko-pvm (valueChange)="initNaytePvm($event)" formControlName="naytePvm" htmlId="{{key}}" ariaLabel="pvm input"></touko-pvm>
        </div>
      </div>
      <div class="col-md-1">
        <button class="btn btn-danger ml-2 form-control no-label w-auto" type="button" (click)="removeNayte()" aria-label="poista"><em class="fa fa-trash"></em></button>
      </div>
    </div>
  `
})
export class QuestionNayteComponent implements QuestionComponent, OnInit {
  @Input() questionData: QuestionComponentData;
  @Input() index;
  nayteTutkimus: NayteTutkimusData;
  isNaytePvmInitialized = false;
  key = null;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.key = this.questionData.question.key;
    this.nayteTutkimus = this.questionData.question.options.tutkimus as unknown as NayteTutkimusData;
  }

  removeNayte() {
    this.questionData.question.actions.removeNayte(this.questionData.question.fullPath);
  }

  initNaytePvm(pvm: DateObj) {
    if (!this.isNaytePvmInitialized) {
      this.controlContainer.control.get('naytePvm').setValue(pvm, {emitEvent: false, onlySelf: true});
      this.isNaytePvmInitialized = true;
    }
  }
}
