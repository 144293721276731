import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from "@angular/core";

/**
 * Created by Seppo on 28/02/2018.
 * Käytetään elementeissä, jotka lataavat tietoa palvelimelta.
 *
 * Jos elementti tulee tehdä latauksen ajaksi läpinäkyväksi, lisää elementin .scss-tiedostoon seuraava:
 *
 * ```
 * @import "~sass/touko_global";
 *
 * :host-context(.loading_div) table {
 *  @extend %loading_div;
 * }
 * ```
 */
@Directive({
  selector: '[showSpinner]'
})
export class ShowSpinnerDirective implements OnChanges {

  @Input() showSpinner = true;
  spinner: any;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {

    this.spinner = document.createElement('div');
    this.spinner.classList.add('spinner');
  }

  ngOnChanges(change: SimpleChanges): void {
    this.renderer.appendChild(this.elementRef.nativeElement, this.spinner);
    this.elementRef.nativeElement.classList.add('loading_div');
    if (!change.showSpinner.currentValue) {
      this.removeLoader();
    }
  }

  removeLoader() {
    this.elementRef.nativeElement.classList.remove('loading_div');
    this.renderer.removeChild(this.elementRef.nativeElement, this.spinner);
  }
}
