import {Component, Input, OnInit} from "@angular/core";
import {Raportti} from "../raportti.utils";
import {Teksti} from "../../utils/teksti";

@Component({
  selector: 'naytteenottotila',
  template: `
    <span class="badge text-uppercase" 
          [ngClass]="{'badge-success': raportti.naytteenottotila === '0', 'badge-warning': raportti.naytteenottotila === '1'}" 
          [attribute]="teksti.getFullKey()">
    </span>`
})
export class NaytteenottotilaComponent implements OnInit {
  @Input() raportti: Raportti;
  teksti: Teksti = null;

  ngOnInit(): void {
    this.teksti = new Teksti('Tila', this.raportti.naytteenottotila === '0' ? 'naytteetKasitelty' : 'naytteetKesken', 'raportit');
  }
}
