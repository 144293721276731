<div class="row">
  <div class="col-9">
    <h2 attribute="lomakeYleinen.lomakkeenTapahtumat">Lomakkeen tapahtumat</h2>
  </div>
  <div class="col-3">
    <div *ngIf="currentLomake?.alkuperainenLomakeId" class="float-right">
      <button attribute="etusivu.siirryAlkuperainen" class="btn btn-primary" (click)="clickAlkuperainen(currentLomake.alkuperainenLomakeId)">Siirry voimassaolevaan versioon</button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <lomake-tapahtumat [showSpinner]="showLoader" [lomakeVersiot]="lomakeVersiot"
                       (selectVersio)="clickVersio($event)"></lomake-tapahtumat>
  </div>
</div>

<div class="row">
  <div class="col-md-9">
    <h2 #lomakeKuvausH2 *ngIf="lomakeKasittely?.kuvaus" [attribute]="lomakeKasittely?.kuvaus.getFullKey()"></h2>
    <span class="d-none" id="lomakeTunnus">{{ currentLomake?.alkuperainenLomakeId || currentLomake?.id }}</span>
    <h2 *ngIf="!lomakeKasittely?.kuvaus">Lomakkeen tiedot</h2>
    <lomaketiedot id="lomaketiedot" [data]="lomakeKasittely"></lomaketiedot>

    <div #lomakeYhteenvetoDiv>
      <allekirjoitus id="allekirjoitus" [data]="allekirjoitus"></allekirjoitus>
      <yhteenveto *ngIf="currentLomake?.toimintotyyppi === 'UUSI' || !isViranomainen"
                  [useImportedData]="true"
                  id="yhteenveto"
                  [yhteenveto]="currentLomakeToiminta"></yhteenveto>
      <yhteenveto-vertailu
        *ngIf="(currentLomake?.toimintotyyppi === 'MUUTOS' || currentLomake?.toimintotyyppi === 'HAKEMUS') && isViranomainen"
        [showCompareYhteenveto]="showCompareYhteenveto"
        [lomakeYhteenveto]="currentLomakeToiminta"
        [toimintaYhteenveto]="currentToiminta"></yhteenveto-vertailu>
    </div>

    <div *ngIf="isElyViranomainen && currentLomake && isLuomu">
      <arkistodokumentit id="arkistodokumentit" [lomake]="currentLomake"></arkistodokumentit>
    </div>
  </div>

  <div class="col-md-3">
    <button *ngIf="showNaytaMuuttuneetTiedot"
            class="btn btn-primary btn-block mb-4"
            [clickAsync]="toggleShowCompareYhteenveto">
      {{ btnText.fullKey | attribute }}
    </button>

    <kasvinterveys-rekisterit *ngIf="currentLomake?.asia === 'kasvinterveysrekisteri'"
                              [toimintotyyppi]="currentLomake?.toimintotyyppi?.toLowerCase()"
                              [valvontakohde]="currentLomake?.lahettajaValvontakohde"
                              [asiointitunnus]="currentLomake?.lahettajaAsiointitunnus"
                              [formValues]="currentLomake?.sisalto"></kasvinterveys-rekisterit>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <virkailija-tiedot (onHyvaksy)="lahetaYhteenvetoJaKasittely($event)"></virkailija-tiedot>
  </div>
  <div class="col-12">
    <virkailija-uudelleenkasittely></virkailija-uudelleenkasittely>
  </div>
</div>
