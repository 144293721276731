import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {Tag} from "../../raportti/raportti.utils";
import {Teksti} from "../../utils/teksti";
import {LomakeTila} from "../../touko-lomake/touko-lomake-utils";
import {AttributeService} from "../../tiira/attribute.service";
import {DateObj} from "../../touko-lomake/syote/syote-utils";

/**
 * Created by Seppo on 07/02/2019.
 */

@Component({
  selector: 'lomake-haku',
  templateUrl: './lomake-haku.component.html'
})
export class LomakeHakuComponent implements OnInit {
  lomakehakuForm: FormGroup;
  lastUpdate: number;
  timeOut = 200;
  @Input() initialHakuParams = new InitialHakuParams();
  @Output() paramsUpdate = new EventEmitter<any>();
  @Output() lomakeCleared = new EventEmitter<boolean>();

  lomaketilat = [
    new Tag(new Teksti("Hylätty", "tilaHylatty", "etusivu"), LomakeTila.HYLATTY),
    new Tag(new Teksti("Käsitelty", "tilaKasitelty", "etusivu"), LomakeTila.KASITELTY),
    new Tag(new Teksti("Käsittelyssä", "tilaKasittelyssa", "etusivu"), LomakeTila.KASITTELYSSA),
    new Tag(new Teksti("Odottaa käsittelyä", "tilaOdottaaKasittelya", "etusivu"), LomakeTila.ODOTTAA_KASITTELYA),
    new Tag(new Teksti("Odottaa lisätietoja", "tilaOdottaaLisatietoja", "etusivu"), LomakeTila.ODOTTAA_LISATIETOJA),
  ];

  toimintotyypit = [
    new Tag(new Teksti("Uusi", "hakuUusi", "etusivu"), "UUSI"),
    new Tag(new Teksti("Muutos", "hakuMuutos", "etusivu"), "MUUTOS"),
    new Tag(new Teksti("Lopetus", "hakuLopetus", "etusivu"), "LOPETUS"),
    new Tag(new Teksti("Hakemus", "hakuHakemus", "etusivu"), "HAKEMUS"),
    new Tag(new Teksti("Vuosi-ilmoitus", "hakuVuosiilmoitus", "etusivu"), "VUOSIILMOITUS")
  ];

  alkaenTxt = "";
  saakkaTxt = "";

  constructor(private readonly fb: FormBuilder, private readonly attributeService: AttributeService) { }

  ngOnInit(): void {
    this.lomakehakuForm = this.fb.group({
      tunnus: this.initialHakuParams.tunnus,
      valvontakohde: this.initialHakuParams.valvontakohde,
      ytunnus: this.initialHakuParams.ytunnus,
      maatilatunnus: this.initialHakuParams.maatilatunnus,
      yritys: this.initialHakuParams.yritys,
      lahettaja: this.initialHakuParams.lahettaja,
      kasittelija: this.initialHakuParams.kasittelija,
      lisatiedot: this.initialHakuParams.lisatiedot,
      toimintotyypit: [this.initialHakuParams.toimintotyypit],
      lomakkeenTilat: [this.initialHakuParams.lomakkeenTilat],
      lahetettyAlkuPvm: [this.initialHakuParams.lahetettyAlkuPvm, ToukoValidation.isValidDate],
      lahetettyLoppuPvm: [this.initialHakuParams.lahetettyLoppuPvm, ToukoValidation.isValidDate],
      kasiteltyAlkuPvm: [this.initialHakuParams.kasiteltyAlkuPvm, ToukoValidation.isValidDate],
      kasiteltyLoppuPvm: [this.initialHakuParams.kasiteltyLoppuPvm, ToukoValidation.isValidDate]
    });

    this.lomakehakuForm.valueChanges.subscribe(value => {
      if (!this.lomakehakuForm.valid) {
        return;
      }

      this.lastUpdate = Date.now();
      setTimeout(() => {
        if (Date.now() - this.lastUpdate >= this.timeOut) {
          return this.submit(value);
        }
      }, this.timeOut);
    });

    this.alkaenTxt = this.attributeService.simpleTranslate("raportit", "alkaen");
    this.saakkaTxt = this.attributeService.simpleTranslate("raportit", "saakka");
  }

  submit(val = null) {
    this.paramsUpdate.next(val);
  }

  clearLomake() {
    const clearParams = new InitialHakuParams();
    this.lomakehakuForm.setValue(clearParams);
    this.paramsUpdate.next(clearParams);
    this.lomakeCleared.emit(true);
  }
}

export class InitialHakuParams {
  tunnus?: string = null;
  valvontakohde?: string = null;
  ytunnus?: string = null;
  maatilatunnus?: string = null;
  yritys?: string = null;
  lahettaja?: string = null;
  kasittelija?: string = null;
  lisatiedot?: string = null;
  toimintotyypit?: Array<any> = [];
  lomakkeenTilat?: Array<any> = [];
  lahetettyAlkuPvm?: DateObj = null;
  lahetettyLoppuPvm?: DateObj = null;
  kasiteltyAlkuPvm?: DateObj = null;
  kasiteltyLoppuPvm?: DateObj = null;
}
