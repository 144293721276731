<ng-container *ngIf="item.subitems">
  <h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey"></h3>
  <lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                       [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
  <div class="row" *ngFor="let subItem of item.subitems" [ngSwitch]="subItem.summaryType">
    <ng-container *ngSwitchCase="yhteenvetoTyyppi.LABEL_AND_VALUE">
      <div class="col-full col-lvl-1">
        <h4 attribute="lannoiterekisteri.lannoitevalmisteenMarkkinointiAlaOtsikko"></h4>
      </div>
      <div class="col-left col-lvl-2">
        <p [attribute]="subItem.valueMap?.maaraTeksti?.fullKey"></p>
      </div>
      <div class="col-right">
        <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
          <span id="muuToiminta-{{item.label.key}}">{{subItem.valueMap?.maara?.label}} </span>
          <span attribute="lannoiterekisteri.kgVuosi"></span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
