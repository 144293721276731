import {Field, FieldControl, SummaryFnType, SummaryItem, ValueOption} from "ngx-fielding";
import {OsoiteTiedot} from "../../../account/account";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";

export class LomakeFieldUtils {
  tulkkiHelper = new TulkkiObject('lomakeYleinen');

  createFieldControl = (labelAttribute, summaryFn: SummaryFnType = () => null) => new FieldControl(
    Field.of({
      label: this.tulkkiHelper.createAttr(labelAttribute),
      summaryFn: summaryFn,
      htmlId: labelAttribute
    }),
  );

  copyValue = (value) => JSON.parse(JSON.stringify(value));
}

export class TulkkiObject {
  table: string;

  constructor(table = 'teksti') {
    this.table = table;
  }

  createAttr(key: string, table?: string) {
    return `${table || this.table}.${key}`;
  }

  static getCreateFn = (table: string) => {
    const t = new TulkkiObject(table);
    return t.createAttr.bind(t);
  };
}

export class SummaryFunctions {

  static questionAndAnswer: SummaryFnType = c => new SummaryItem<any>(c.field.label, c.value, {show: Boolean(c.value)});

  static titleSummary: SummaryFnType = fieldGroup => new SummaryItem<any>(fieldGroup.field.label, null, null, TitleSummaryComponent);

  static questionAndAnswerFromOsoiteTiedot: SummaryFnType = c => {
    if (c.value) {
      const ot: OsoiteTiedot = c.value;
      if (!ot.osoite && !ot.postinumero && !ot.postitoimipaikka) {
        return null;
      }
      const osoite = ot.osoite ? ot.osoite : '';
      const postinumero = ot.postinumero ? ot.postinumero : '';
      const postitoimipaikka = ot.postitoimipaikka ? ot.postitoimipaikka : '';

      const maidenNimet = sessionStorage.getItem("maidenNimet");
      const maa = maidenNimet ? JSON.parse(maidenNimet)[ot?.maaKoodi || 'FI'].maanNimi : '–';
      const answer = `${osoite}, ${postinumero} ${postitoimipaikka}, ${maa}`;
      return new SummaryItem<any>(c.field.label, answer);

    } else {
      return null;
    }
  };

  static verkkolaskuosoiteSummary: SummaryFnType = c => {
    if (c.value) {
      if (!c.value?.verkkolaskutusosoite && !c.value?.operaattori) {
        return null;
      }
      const answer = `${c.value?.verkkolaskutusosoite} / ${c.value?.operaattori}`;
      return c.value ?
        new SummaryItem(c.field.label, answer) :
        null;
    }
  };
}

export class RadioValue implements ValueOption {
  value: string;
  label: string;
  htmlId: string;
  options: any;

  constructor(value: string, label?, htmlId?, options?) {
    this.label = label || TulkkiObject.getCreateFn(value);
    this.value = value;
    this.htmlId = htmlId;
    this.options = options;
  }
}

