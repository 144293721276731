import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";

@Component({
  selector: 'liite-lataus',
  template: `
    <div class="input-group">
      <input autocomplete="off" [id]="'hidden-' + htmlId" class="form-control-file" style="display: none;"
             accept="image/jpeg, image/png, application/pdf"
             type="file" (change)="onFileChange($event)" #fileInput/>
      <input autocomplete="off" [attr.aria-labelledby]="labelledBy" [disabled]="isDisabled" type="text" [id]="htmlId"
             (click)="fileInput.click()" class="form-control"
             placeholder="{{'lomakeYleinen.liiteEiLadattua' | attribute}}">
      <div class="input-group-append">

        <button [attribute]="'lomakeYleinen.liiteSelaa'" id="selaa-{{htmlId}}" class="btn btn-outline-primary"
                (click)="fileInput.click()" [disabled]="isDisabled"
                type="button">Selaa...
        </button>
      </div>
    </div>

    <em class="small text-muted" attribute="lomakeYleinen.liiteOhje">Tuetut tiedostomuodot ovat
      PDF, JPG ja PNG. Tiedoston maksimikoko 8Mb.</em>

    <!-- hidden download link -->
    <a class="invisible" #downloadlink></a>
  `
})
export class LiiteLatausComponent {
  @Input() htmlId: string;
  @Input() labelledBy: string;
  @Input() isDisabled: boolean;

  @Output() liiteOutput = new EventEmitter<File>();

  @ViewChild('downloadlink', { static: true }) downloadlink: ElementRef;

  onFileChange(event) {
    this.liiteOutput.emit(event.target.files[0])
  }
}
