<div class="row">
  <div class="col yhteenveto-list pl-0">
    <div *ngFor="let group of yhteenveto" class="ml-md-3 mr-md-3 yhteenveto-list {{group.title?.key}}">
      <h2 *ngIf="group.title" class="yhteenveto-header" [attribute]="group.title?.getFullKey()"></h2>
      <ul class="list-group list-group-flush ml-0 mb-0">
        <ng-container *ngFor="let item of group.data">
          <li class="list-group-item {{item.label?.key}}" *ngIf="group.data.length > 0">
            <yhteenveto-container
              [item]="item"></yhteenveto-container>
          </li>
        </ng-container>
        <li class="list-group-item" *ngIf="group.data.length == 0 && group.title">-</li>
      </ul>
    </div>

  </div>
</div>
