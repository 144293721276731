import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {QuestionComponent, QuestionComponentData} from "ee-lahete";
import {TiiraService} from "../../tiira/tiira.service";
import {LaheteLanguage} from "../lahete/lahete.service";
import {LaheteData} from "../lahete/lahete-koodisto";

@Component({
  selector: 'question-tutkimus-card',
  template: `
    <div class="card">
      <div class="card-header">
        <button class="close" aria-label="Poista tutkimus" type="button" (click)="remove()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="w-75">
            {{title}}
          </div>
        </div>
        <div class="card-body">
          <p>{{tutkimussisalto}}</p>
          <p class="small alert alert-warning mb-2" *ngIf="huomio">
            {{huomio}}
          </p>
          <p class="small mb-0" attribute="sahkoelain.tutkimussyyt">Tutkimussyy</p>
          <ul class="pl-3">
            <li class="small" *ngFor="let tutkimussyy of tutkimussyyt">{{tutkimussyy.label}}</li>
          </ul>

          <p class="small mb-0" attribute="sahkoelain.naytemateriaalit">Tutkimussyy</p>
          <ul class="pl-3">
            <li class="small" *ngFor="let naytemateriaali of naytemateriaalit">{{naytemateriaali.label}}</li>
          </ul>

        </div>
      </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QuestionTutkimusCardComponent)
    }
  ],
})
export class QuestionTutkimusCardComponent extends QuestionComponent implements ControlValueAccessor, OnInit {
  @Input() questionData: QuestionComponentData;
  tutkimusId;
  formValue = {id: null, selected: true};
  title = "";
  huomio = null;
  tutkimussisalto = null;
  tutkimussyyt = [];
  naytemateriaalit = [];
  tiedoksisaaja = null;

  constructor(private tiiraService: TiiraService) {
    super();
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  ngOnInit(): void {
    this.tutkimusId = this.questionData.question.initialValue.id;
    const tutkimus = this.questionData.question.options.tutkimus as unknown as LaheteData;
    const lang = LaheteLanguage[this.tiiraService.getCurrentLanguage()];
    this.title = tutkimus['tutkimus' + lang];
    this.huomio = tutkimus['huom' + lang];
    this.tutkimussisalto = tutkimus['tutkimussisalto' + lang];
    this.tutkimussyyt = this.questionData.question.options.tutkimussyyt as unknown as Array<any>;
    this.naytemateriaalit = this.questionData.question.options.naytemateriaalit as unknown as Array<any>;
    this.tiedoksisaaja = this.questionData.question.options.tiedoksisaaja;
  }

  remove() {
    this.formValue.selected = false;
    this._onChange(this.formValue);
  }

  writeValue(value: any): void {
    if (!value.hasOwnProperty('selected')) {
      value.selected = true;
    }
    this.formValue = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
