import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LomakeService} from "../touko-lomake.service";
import {AccountService} from "../../account/account.service";
import {FieldGroup} from "ngx-fielding";
import {
  Allekirjoitus,
  LOMAKE_KASITTELY_MESSAGE,
  LOMAKE_KUVAUKSET,
  LomakeKasittely,
  LomakeResponse
} from "../touko-lomake-utils";
import {Teksti} from "../../utils/teksti";
import {TarkastusPDFService} from "./tarkastus-pdf.service";
import {MessageService} from "../../message/message.service";
import {LomakeDataResolver} from "../vuosiilmoitus/common/lomake-base/lomake-v2-data";

@Component({
  selector: 'tarkastus-v2',
  template: `
    <div *ngIf="lomake">
      <ng-container *ngIf="!isEsikatselu">
        <div class="row">
          <div class="col-9">
            <h2 attribute="lomakeYleinen.lomakkeenTapahtumat">Lomakkeen tapahtumat</h2>
          </div>
          <div class="col-3">
            <div *ngIf="!showCompareBtn && lomakeResponse?.alkuperainenLomakeId" class="float-right">
              <button attribute="etusivu.siirryAlkuperainen" class="btn btn-primary"
                      (click)="clickAlkuperainen(lomakeResponse.alkuperainenLomakeId)">Siirry voimassaolevaan versioon
              </button>
            </div>
          </div>
        </div>
        <lomake-tapahtumat [showSpinner]="showLoader" [lomakeVersiot]="lomakeVersiot"
                           (selectVersio)="clickVersio($event)"></lomake-tapahtumat>
      </ng-container>
      <div class="d-flex justify-content-between">
        <h2 #lomakeKuvausH2 [attribute]="otsikko">Ilmoitus</h2>
        <button *ngIf="showCompareBtn" class="btn btn-primary" [clickAsync]="naytaMuuttuuneetTiedot">Näytä muuttuneet
          tiedot
        </button>
      </div>
      <div #lomakeYhteenvetoDiv>
        <summary-allekirjoitus *ngIf="!isEsikatselu" [allekirjoitus]="allekirjoitus"></summary-allekirjoitus>
        <field-group-summary-compare *ngIf="showMuuttuneetTiedot" [currentFieldGroup]="lomake"
                                     [compareFieldGroup]="compareLomake"
                                     [excludedPaths]="excludePaths"></field-group-summary-compare>
        <app-field-group-summary *ngIf="!showMuuttuneetTiedot" class="summary" [fieldGroup]="lomake"
                                 [showCompare]="showMuuttuneetTiedot"></app-field-group-summary>
      </div>
      <div class="mt-5" *ngIf="lomakeResponse && isLuomu">
        <arkistodokumentit id="arkistodokumentit" [lomake]="lomakeResponse"></arkistodokumentit>
      </div>
      <virkailija-tiedot (onHyvaksy)="lahetaYhteenvetoJaKasittely($event)"></virkailija-tiedot>
      <virkailija-uudelleenkasittely></virkailija-uudelleenkasittely>
    </div>
    <pre class="small" *devFeature>{{ lomake?.value | json }}</pre>
  `
})

export class TarkastusV2Component implements OnInit {
  lomake: FieldGroup;
  compareLomake: FieldGroup;
  lomakeResponse: LomakeResponse;
  lomakeVersiot: LomakeResponse[] = [];
  showLoader = true;
  showCompareBtn = true;
  allekirjoitus: Allekirjoitus = new Allekirjoitus();
  showMuuttuneetTiedot = false;
  otsikko: string;
  isViranomainen = false;
  readonly isElyViranomainen: boolean = false;
  readonly isLuomu: boolean = false;
  isEsikatselu = false;
  excludePaths: string[] = [];
  openAccordionEvent = new EventEmitter<void>();

  @ViewChild("lomakeYhteenvetoDiv") lomakeYhteenvetoDiv: ElementRef;
  @ViewChild("lomakeKuvausH2") lomakeKuvausH2: ElementRef;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly lomakeService: LomakeService,
              private readonly accountService: AccountService,
              private readonly tarkastusPdfService: TarkastusPDFService,
              private readonly messageService: MessageService,
              private readonly lomakeDataResolver: LomakeDataResolver,
              private cdr: ChangeDetectorRef) {
    this.naytaMuuttuuneetTiedot = this.naytaMuuttuuneetTiedot.bind(this);
    this.isElyViranomainen = this.accountService.isElyViranomainen();
    this.isLuomu = this.accountService.getCurrentAccount().toimiala === "LUOMU";
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async param => {
          const paramId = parseInt(param.id, 10);
          const toimintotyyppi = (param.toimintotyyppi || this.activatedRoute.snapshot.url[0].path).toLowerCase();
          const asia = param.asia || this.activatedRoute.snapshot.url[1].path;
          this.otsikko = this.getLomakeKuvaus(asia, toimintotyyppi);
          this.excludePaths = this.getExcludedPaths(asia)
          if (this.accountService.isViranomainen()) {
            this.isViranomainen = true;
            this.lomakeResponse = await this.lomakeService.getLomakeV2Viranomainen(paramId, asia, toimintotyyppi);
            if (this.lomakeVersiot.length === 0) {
              const alkupLomakeId = this.lomakeResponse.alkuperainenLomakeId ? this.lomakeResponse.alkuperainenLomakeId : this.lomakeResponse.id;
              this.lomakeService.getLomakeVersiot(alkupLomakeId)
                  .then(data => this.lomakeVersiot = data)
                  .then(() => this.showLoader = false);
            }
          } else {
            this.isEsikatselu = this.activatedRoute.snapshot.data['isEsikatselu'];
            this.lomakeResponse = await this.lomakeService.getLomakeV2Asiakas(paramId, asia, toimintotyyppi, this.isEsikatselu);
          }
          this.showCompareBtn = this.isCompareButtonVisible();

          if (this.lomakeVersiot.length === 0) {
            const alkupLomakeId = this.lomakeResponse.alkuperainenLomakeId ? this.lomakeResponse.alkuperainenLomakeId : this.lomakeResponse.id;
            this.lomakeService.getLomakeVersiot(alkupLomakeId)
                .then(data => this.lomakeVersiot = data)
                .then(() => this.showLoader = false);
          }
          const lomakeData = this.lomakeDataResolver.getLomakeData(asia);
          this.lomake = await lomakeData.getForm(toimintotyyppi, true);
          this.compareLomake = await lomakeData.getForm();

          const jsondata = JSON.parse(this.lomakeResponse.sisalto).data;
          this.lomake.initValue(jsondata);
          this.allekirjoitus = Allekirjoitus.createFromLomake(this.lomakeResponse);
        }
    );
  }

  isCompareButtonVisible() {
    const lomakeTyypit = new Set(['muutos', 'hakemus', 'vuosiilmoitus'])
    return this.lomakeResponse.alkuperainenLomakeId == null && this.isViranomainen && lomakeTyypit.has(this.lomakeResponse.toimintotyyppi.toLowerCase());
  }

  sendYhteenvetoPdf(kasittely: LomakeKasittely): Promise<any> {
    const titleHtml = this.lomakeKuvausH2.nativeElement.innerHTML;
    const contentElement = this.lomakeYhteenvetoDiv.nativeElement;

    return this.tarkastusPdfService.createYhteenvetoPdf(kasittely, this.allekirjoitus, titleHtml, contentElement)
        .then(pdf => this.lomakeService.sendYhteenveto(kasittely.id, pdf, true));
  }

  kasitteleLomake(lomakeKasittely: LomakeKasittely): void {
    const kasitteleLomakeFn = this.lomakeService.kasitteleLomakeV2(lomakeKasittely, this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi);

    kasitteleLomakeFn.then(() => {
          this.messageService.notify({message: LOMAKE_KASITTELY_MESSAGE[lomakeKasittely.tila], type: "success"});
          this.router.navigate(['/asiointi/etusivu']);
        })
        .catch(() => {
          this.messageService.notify({
            message: new Teksti("Käsittelyvirhe", "errLomakkeenKasittelyvirhe", "lomakeYleinen"),
            type: "danger"
          });
        });
  }

  lahetaYhteenvetoJaKasittely($event: LomakeKasittely) {
    this.sendYhteenvetoPdf($event)
        .then(() => {
          this.kasitteleLomake($event);
        });
  }

  clickVersio(lomake: LomakeResponse) {
    this.router.navigate([
      `/asiointi/lomake/tarkastus/${lomake.toimintotyyppi.toLowerCase()}`,
      lomake.asia,
      `v${lomake.lomaketyyppiVersio}`,
      lomake.id
    ]);
  }

  clickAlkuperainen(id: number) {
    this.router.navigate([
      `/asiointi/lomake/tarkastus/${this.lomakeResponse.toimintotyyppi.toLowerCase()}`,
      this.lomakeResponse.asia,
      `v${this.lomakeResponse.lomaketyyppiVersio}`,
      id
    ]);
  }

  naytaMuuttuuneetTiedot() {
    return this.lomakeService.getToiminnanTiedotViranomainenV2(this.lomakeResponse.id, this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi).then(response => {
      this.compareLomake.initValue(JSON.parse(response.sisalto).data)
      this.showMuuttuneetTiedot = !this.showMuuttuneetTiedot;
    });
  }

  getLomakeKuvaus(asia, toimintotyyppi) {
    return LOMAKE_KUVAUKSET[asia][toimintotyyppi.toUpperCase()]?.fullKey;
  }

  getExcludedPaths(asia) {
    const paths = {
      lannoiterekisteri: ['toimintaJaTuoteluokatKansallinen', 'toimintaJaTuoteluokatEUToiminta', 'muuToiminta', 'lannoitesivutuotetoimija'],
      siemenpakkaamolupa: ['toiminnanAloitus', 'markkinoimasiKylvosiemenet', 'koneetJaLaitteet', 'lisatiedot', 'painatus', 'tarkastusAjankohta']
    }
    return paths[asia] || []
  }
}
