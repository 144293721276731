import {Component, OnInit} from "@angular/core";
import {AccountService} from "./account.service";
import {Account, KayttajaRooli} from "./account";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Subscription} from "rxjs";
import {AccountNotificationModalComponent} from "./ilmoitusasetukset/account-notification-modal.component";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 02/10/2017.
 */
@Component({
  templateUrl: 'account.component.html',
  selector: "account",
  styleUrls: ["account.scss"]
})
@AutoUnsubscribe
export class AccountComponent implements OnInit {

  account: Account;
  subs: Subscription[] = [];
  isYritystoimija = false;
  isViranomainen = false;
  isHenkilo = false;

  valvontakohdeValinta: any;
  toimipaikanYhteyshenkilo: string;

  constructor(private readonly accountService: AccountService,
              private readonly modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.subs.push(this.accountService.account$.subscribe(account => {
      if (account === null) {
        return;
      }

      this.isYritystoimija = account.kayttajaRooli === KayttajaRooli.ASIAKAS_YRITYS;
      this.isViranomainen = account.kayttajaRooli === KayttajaRooli.VIRANOMAINEN;
      this.isHenkilo = account.kayttajaRooli === KayttajaRooli.ASIAKAS_HENKILO;

      this.account = account;
      this.valvontakohdeValinta = account.valvontakohde;
      if (this.valvontakohdeValinta) {
        this.haeYhteyshenkiloToimialalle();
      } else {
        this.toimipaikanYhteyshenkilo = "-";
      }
    }));
  }

  private haeYhteyshenkiloToimialalle(): void {
    // Jos toimialaa ei vielä valittu, älä tee mitään
    if (this.account.toimiala && this.account.valvontakohde) {
      let yhteyshenkilo: string;
      // Hae yhteyshenkilölistasta sellainen jonka toimiala on sama kuin valittu toimiala
      if (this.account.valvontakohde.asiakas.yhteyshenkilot) {
        for (const yh of this.account.valvontakohde.asiakas.yhteyshenkilot) {
          if (this.account.toimiala.startsWith(yh.valvontasektori_elmoid)) {
            yhteyshenkilo = yh.yhteyshenkilo;
          }
        }
      }
      this.toimipaikanYhteyshenkilo = yhteyshenkilo;
    }
  }

  openIlmoitukset() {
    const modalRef = this.modalService.open(AccountNotificationModalComponent, {size: 'lg'});
    modalRef.componentInstance.yhteyshenkilot = this.account.yhteyshenkilot;
    modalRef.componentInstance.ilmoitukset = this.account.ilmoitukset;
  }
}
