import {Directive, Input, OnInit} from "@angular/core";
import {LomakeErrorService} from "../../lomake-error.service";
import {LomakeEventPublisherService} from "./lomake-base/lomake-event-publisher.service";
import {ControlContainer} from "@angular/forms";

@Directive({
  selector: '[sivuvirhekasittely]'
})
export class LomakevirhekasittelyDirective implements OnInit{
  @Input() virheGroups: string[] = [];
  @Input() sivu: string;


  constructor(private readonly controlContainer: ControlContainer,
              private readonly lomakeErrorService: LomakeErrorService,
              private readonly lomakeEventPublisher: LomakeEventPublisherService) {
  }

  get lomake() {
    return this.controlContainer.control
  }

  ngOnInit(): void {
    this.getGroupVirheet();
    this.getVirheetOnLomakeSent();
  }

  getGroupVirheet() {
    this.virheGroups.forEach(group => {
      const formGroup = this.lomake.get(group);
      formGroup?.statusChanges.subscribe(status => {
        this.lomakeErrorService.setVirhe(this.sivu, group, status === "INVALID" && formGroup.touched);
      })
    })
  }

  getVirheetOnLomakeSent() {
    this.lomakeEventPublisher.lomakeEvent$.subscribe(lomakeEvent => {
      if (lomakeEvent?.eventType === 'send' && !lomakeEvent?.isSuccessful) {
        this.virheGroups.forEach(group => {
          if (this.lomake.get(group).status === 'INVALID') {
            this.lomakeErrorService.setVirhe(this.sivu, group, true);
          }
        });
      }
    });
  }
}
