import {Component, Input} from '@angular/core';
import {LaheteYhteenvetoItem} from "./lahete-yhteenveto";

@Component({
  selector: 'app-lahete-yhteenveto-group',
  templateUrl: './lahete-yhteenveto-group.component.html',
  styleUrls: ['./lahete-yhteenveto-group.component.scss']
})
export class LaheteYhteenvetoGroupComponent {
  @Input() yhteenvetoItem: LaheteYhteenvetoItem;
  @Input() groupPrefix?: string;
}
