import {Component, QueryList, ViewChildren} from "@angular/core";
import {FieldArray, FieldGroup} from "ngx-fielding";
import {ControlContainer} from "@angular/forms";
import {TextInputComponent} from "../text-input.component";

@Component({
  selector: 'verkkokaupat-list',
  template: `
    <ng-container [formGroup]="parentGroup">
      <touko-checkbox
        formControlName="checkbox"
        htmlId="verkkokaupatToimintaCheck"
        labelAttribute="lomakeYleinen.verkkokaupatToiminta"
        (change)="setFocusToLastInput()">
      </touko-checkbox>

      <div *ngIf="isChecked" class="ml-4 mb-4">
        <ng-container formArrayName="verkkokaupat">
          <label id="verkkokauppa-input" attribute="lomakeYleinen.verkkokauppaosoite"></label>
          <div *ngFor="let verkkokauppa of verkkokaupatArray.controlFields; let i = index;" [formGroupName]="i" class="d-flex align-items-end">
            <ng-container *ngIf="!verkkokauppa.get('enddt').value">
              <div class="col-6 px-0">
                <touko-text-input
                  formControlName="osoite"
                  [htmlId]="'vk-osoite-' + i"
                  labelledById="verkkokauppa-input"
                  #osoiteInputs>
                </touko-text-input>
              </div>
              <div>
                <button [id]="'vk-poista-' + i"
                        [disabled]="isRemoveDisabled"
                        type="button"
                        class="btn btn-outline-primary mb-4 ml-3"
                        (click)="removeVerkkokauppa(i)">
                  <span class="fa fa-trash"></span>
                  <span attribute="lomakeYleinen.poistaVerkkokauppa">Poista</span>
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <button type="button" class="btn btn-secondary" id="vk-lisaa" (click)="addVerkkokauppa()">
          <span class="fa fa-plus"></span>
          <span attribute="lomakeYleinen.lisaaVerkkokauppa">Lisää</span>
        </button>
      </div>
    <validation-error [field]="parentGroup"></validation-error>
    </ng-container>
  `
})
export class VerkkokaupatListComponent {
  @ViewChildren('osoiteInputs') osoiteInputs: QueryList<TextInputComponent>;

  constructor(private readonly controlContainer: ControlContainer) {}

  get parentGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get verkkokaupatArray(): FieldArray {
    return this.parentGroup.get('verkkokaupat') as FieldArray;
  }

  get isChecked(): boolean {
    return this.parentGroup.get('checkbox').value;
  }

  get isRemoveDisabled(): boolean {
    return this.verkkokaupatArray.controlFields
      .filter(verkkokauppa => !verkkokauppa.get('enddt').value).length <= 1
  }

  addVerkkokauppa() {
    const newVerkkokauppa = this.verkkokaupatArray.buildField();
    newVerkkokauppa.get('lisatty').setValue(true);
    this.verkkokaupatArray.push(newVerkkokauppa);

    this.setFocusToLastInput();
  }

  removeVerkkokauppa(index: number) {
    if (this.verkkokaupatArray.get([index, 'lisatty']).value) {
      this.verkkokaupatArray.removeAt(index);
    } else {
      this.verkkokaupatArray.get([index, 'enddt']).setValue(Date.now().toString());
    }
  }

  setFocusToLastInput() {
    if (this.isChecked) {
      setTimeout(() => {
        const lastVerkkokauppaInput = this.osoiteInputs.last.htmlId;
        document.getElementById(lastVerkkokauppaInput).focus();
      });
    }
  }
}
