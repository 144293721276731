import {Component, Input, OnInit} from "@angular/core";
import {
  MatcherService,
  QuestionArray,
  QuestionComponentData,
  QuestionGroup,
  QuestionUpdate,
  QuestionUpdateType
} from "ee-lahete";
import {ControlContainer, FormGroup} from "@angular/forms";

@Component({
  selector: 'question-array',
  template: `
    <div [formGroup]="controlContainer.control.parent">
      <div [formArrayName]="controlContainer.name"
           [ngClass]="questionParent.array.controlType === 'tutkimus-card' ? 'row' : null">
        <ng-container [ngSwitch]="questionParent.array.controlType">
          <ng-container *ngFor="let questionData of questionComponentDataList; index as i; last as last">
            <div [ngClass]="['col-md-6', 'col-lg-4', 'mb-1']" *ngSwitchCase="'tutkimus-card'">
              <question-tutkimus-card [questionData]="questionData" [formControlName]="i" [id]="'question-tutkimus-card-' + i"></question-tutkimus-card>
            </div>
            <div *ngSwitchCase="'naytetieto'">
              <question-naytetiedot [questionParent]="questionArray" [questionData]="questionData" [formGroupName]="i"
                                    [index]="i" [isLast]="last" [sivuRef]="sivuRef" [id]="'question-naytetiedot-' + i"></question-naytetiedot>
            </div>
            <div *ngSwitchCase="'nayte-single'">
              <question-nayte [questionData]="questionData" [formGroupName]="i" [index]="i"></question-nayte>
            </div>
            <div *ngSwitchCase="'nested-group'">
              <question-group-nested [formGroupName]="i"
                                     [eelControlMatcher]="questionData.question"
                                     [questionGroup]="questionData.question"
                                     [id]="'question-group-nested-' + i"></question-group-nested>
            </div>
          </ng-container>

        </ng-container>
      </div>
    </div>
  `
})
export class QuestionArrayComponent implements OnInit {
  @Input() questionParent: QuestionGroup;
  @Input() questionArray: QuestionArray;
  @Input() sivuRef: string;

  questionComponentDataList: QuestionComponentData[];

  constructor(public controlContainer: ControlContainer, private mathcerService: MatcherService) {
  }

  ngOnInit(): void {
    this.applyChanges();
    this.mathcerService.$modelUpdate.subscribe(modelUpdate => {
      if (modelUpdate.path.endsWith(this.controlContainer.path[0])) {
        this.applyChanges(modelUpdate);
      }
    });
  }

  applyChanges(modelUpdate: QuestionUpdate = null) {
    if (!modelUpdate || modelUpdate.updateType === QuestionUpdateType.UPDATE) {
      this.questionComponentDataList = this.questionArray.questions.map(q => new QuestionComponentData(q, this.controlContainer.control as FormGroup));
    } else if (modelUpdate.updateType === QuestionUpdateType.ADD) {
      this.questionArray.questions.forEach(q => {
        if (!this.questionComponentDataList.find(qc => qc.question.key === q.key)) {
          this.questionComponentDataList.push(new QuestionComponentData(q, this.controlContainer.control as FormGroup));
          return;
        }
      });
    } else if (modelUpdate.updateType === QuestionUpdateType.REMOVE) {
      const index = this.questionComponentDataList.findIndex(qc => qc.question.key.toString() === modelUpdate.key.toString());
      if (index >= 0) {
        this.questionComponentDataList.splice(index, 1);
      }
    }
  }
}
