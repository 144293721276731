import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "../summary-container.component";
import {ResultType} from "../field-summary-compare/compare.service";

@Component({
  selector: 'yhteyshenkilo-simple-summary',
  template: `
    <h2 [attribute]="item?.question" [ngClass]="{'invisible': compareItemType}" class="mb-2 mt-4"></h2>
    <div class="row pb-2">
      <div class="col-lg-4 col-5 py-0 my-0">
        <span attribute="lomakeYleinen.nimi" [id]="'label-nimi-' + item.field.htmlId">Nimi</span>
      </div>
      <div class="col">
        <span class="answer" [id]="'value-nimi-' + item.field.htmlId">{{item.answer?.nimi}}</span>
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-lg-4 col-5 py-0 my-0">
        <span [id]="'label-yhteystiedot-' + item.field.htmlId" attribute="lomakeYleinen.yhteystiedot">Yhteystiedot</span>
      </div>
      <div class="col">
        <span class="answer">
          <span [id]="'value-email-' + item.field.htmlId">{{item.answer?.email}}</span>, <br>
          <span [id]="'value-puhelin-' + item.field.htmlId">{{item.answer?.puhelin}}</span>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['../summary.scss']
})
export class YhteyshenkiloSimpleSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
