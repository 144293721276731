import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn
} from '@angular/forms';
import {Field} from './field';
import {EMPTY_SUMMARY_FN, SummaryItem} from './summary-item';
import {BehaviorSubject} from "rxjs";

/**
 * @internal
 */
const fieldGetErrorMessages = (that: AbstractField): string[] => {
  if (!that.field.errorMessages) {
    return [];
  }

  const allMsgKeys = new Set(Object.keys(that.field.errorMessages || {}));
  return Object.keys({...that.errors})
  .filter(err => allMsgKeys.has(err))
  // @ts-ignore
  .map(err => that.field.errorMessages[err]);
};

/**
 * @internal
 */
const fieldGetSummary = (that: AbstractField): SummaryItem<any> | null => {
  if (!that.field.summaryFn) {
    return null;
  }
  if (that.field.summaryFn) {
    const summary = that.field.summaryFn(that);
    if (summary) {
      summary.field = that.field;
    }
    return summary;
  } else {
    return fieldGetParentSummary(that);
  }
};

/**
 * @internal
 */
const fieldGetParentSummary = (that: AbstractField): SummaryItem<any> | null => {
  if (!that.parent) {
    return null;
  }

  if (that.parent instanceof FormGroup) {
    const parent = that.parent as FieldGroup;
    if (parent.field.childSummaryFn) {
      const summary = parent.field.childSummaryFn(that);
      if (summary) {
        summary.field = that.field;
      }
      return summary;
    }
  }

  if (that.parent instanceof FormArray) {
    const parent = that.parent as FieldArray;
    if (parent.field.childSummaryFn) {
      const summary = parent.field.childSummaryFn(that);
      if (summary) {
        summary.field = that.field;
      }
      return summary;
    }
  }

  return null;
};

/**
 * @internal
 */
type AngularValidators = ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
/**
 * @internal
 */
type AngularAsyncValidators = AsyncValidatorFn | AsyncValidatorFn[] | null;

/**
 * Extends FormControl.
 *
 * Example usage:
 *
 * Create control "usernameControl" and set SummaryItem:
 * 1. "Your user name is" as question
 * 2. Control value as answer
 *
 * ```typescript
 * const usernameControl = new FieldControl(
 *    Field.build('Username', (c) => new SummaryItem('Your username is', c.value))
 * );
 * usernameControl.setValue('Foo');
 *
 * console.log(usernameControl.summary)
 * // logs {question: "Your username is", answer: "Foo" ...}
 * ```
 *
 * {@link Field see Field}
 */
export class FieldControl extends FormControl implements AbstractField {

  private readonly _field: Field;

  /**
   * FieldControl won't have any children, always returns `null`
   * @param _path path for child field
   */
  get(_path: Array<string | number> | string): AbstractField | null {
    return null;
  }

  /**
   * Get root field of this fieldControl.
   */
  get root(): AbstractField {
    return super.root as AbstractField;
  }

  /**
   * Get Field object
   */
  get field(): Field {
    return this._field;
  }

  /**
   * Get current errors of control as list
   */
  get currentErrors(): string[] {
    return fieldGetErrorMessages(this);
  }

  /**
   * Get summary of this control. Summary is set on constructor at field property.
   */
  get summary(): SummaryItem<any> | null {
    return fieldGetSummary(this);
  }

  /**
   * Should not be used for FieldControl
   */
  asArray(): FieldArray {
    throw Error('Cannot convert');
  }

  /**
   * Get concrete class (instead of Abstract field). Maybe useful in templates:
   *
   * ```html
   * <p>{{ field.asControl.value }}</p>
   * ```
   */
  asControl(): FieldControl {
    return this;
  }

  /**
   * Should not be used for FieldControl
   */
  asGroup(): FieldGroup {
    throw Error('Cannot convert');
  }

  /**
   * Initialize FieldControl value
   * @param value value for this control
   */
  initValue(value: any): void {
    if (value) {
      this.markAsTouched();
    }
    this.setValue(value);
  }

  /**
   * Create new FieldControl instance.
   *
   * @constructor
   * @param field Field containing extended behavior of this library
   * @param formState see FormControl
   * @param validatorOrOpts see FormControl
   * @param asyncValidator see FromControl
   */
  constructor(field: Field,
              formState?: any,
              validatorOrOpts?: AngularValidators,
              asyncValidator?: AngularAsyncValidators) {
    super(formState, validatorOrOpts, asyncValidator);
    this._field = field;
  }
}

/**
 * Extends FormArray
 *
 * Example usage:
 *
 * 1. Add array for products
 * 2. Set title for the summary "Ordered products" and set property "show" true, when array has children.
 * 3. Add function that creates placeholder for new order, show text "Product"(=question) and input value(=answer) in
 * summary.
 * 4. Add "Grand piano" as first value
 * 5. Add new order, set that value as "Guitar"
 *
 * ```typescript
 * const addOrder = () => new FieldControl(Field.build('order'), (c) => new SummaryItem('Product', c.value));
 *
 * const orders = new FieldArray(
 *    Field.build('Username', (c) => new SummaryItem('Ordered products', null, {show: c.controlFields.length > 0})),
 *    addOrder,
 *    [addOrder()]
 * );
 *
 * orders.get(0).setValue('Grand Piano');
 *
 * orders.push(orders.buildField());
 * orders.get(1).setValue('Guitar');
 * ```
 */
export class FieldArray extends FormArray implements AbstractField {

  private readonly _field: Field;
  private readonly _buildFn: (...buildArgs: any) => AbstractField;
  private _arrayPushEvent = new BehaviorSubject<AbstractField>(null);

  /**
   * Get Field object
   */
  get field(): Field {
    return this._field;
  }

  /**
   * Get current errors of control as list
   */
  get currentErrors(): string[] {
    return fieldGetErrorMessages(this);
  }

  /**
   * Get summary of this FieldArray
   */
  get summary(): SummaryItem<any> | null {
    return fieldGetSummary(this);
  }

  /**
   * Create new FieldArray instance.
   * @constructor
   * @param field Field containing extended behavior of this library
   * @param buildFn Method that creates a child for this FieldArray
   * @param controls see FormArray
   * @param validatorOrOpts see FormArray
   * @param asyncValidator see FormArray
   */
  constructor(field: Field, buildFn: (...buildArgs: any) => AbstractField,
              controls: AbstractField[] = [],
              validatorOrOpts?: AngularValidators,
              asyncValidator?: AngularAsyncValidators
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    if (field && !field.summaryFn) {
      field.summaryFn = EMPTY_SUMMARY_FN;
    }
    this._field = field;

    this.controls.forEach((c, i) => {
      const af = c as AbstractField;
      if (af?.field?.htmlId) {
        af.field.htmlId += "-" + i;
      }
    })

    this._buildFn = buildFn.bind(this);
  }

  /**
   * Get child of this FieldArray from given path
   * @param path Path to the child control
   */
  get(path: Array<string | number> | string): AbstractField | null {
    return super.get(path) as AbstractField;
  }

  /**
   * Get root control of this FieldArray.
   */
  get root(): AbstractField {
    return super.root as AbstractField;
  }

  /**
   * Get children as AbstractField list.
   */
  get controlFields(): AbstractField[] {
    return this.controls as AbstractField[];
  }

  /**
   * Execute buildField function.
   * @param buildArgs arguments for the function.
   */
  buildField(...buildArgs: any): AbstractField {
    return this._buildFn(buildArgs);
  }

  /**
   * Execute buildField function and add it to this array.
   * @param buildArgs arguments for the buildField function.
   */
  pushField(...buildArgs: any): void {
    this.push(this.buildField(buildArgs));
  }


  push(control: any, options?: { emitEvent?: boolean }) {
    super.push(control, options);
    if (control?.field?.htmlId) {
      control.field.htmlId += '-' + (this.length - 1);
    }
    this._arrayPushEvent.next(control);
  }

  /**
   * Listen to array push event.
   */
  get arrayChanges() {
    return this._arrayPushEvent.asObservable()
  }

  /**
   * Get concrete class (instead of Abstract field). Maybe useful in templates:
   *
   * ```html
   * <p *ngFor="let child of fiedl.asArray.controlFields">{{ child.value }}</p>
   * ```
   */
  asArray(): FieldArray {
    return this;
  }

  /**
   * Should not be used for FieldArray
   */
  asControl(): FieldControl {
    throw Error('Cannot convert');
  }

  /**
   * Should not be used for FieldArray
   */
  asGroup(): FieldGroup {
    throw Error('Cannot convert');
  }

  /**
   * Initializes the value of this FieldArray based on the provided value. If the provided value has length,
   * this method creates AbstractField objects for this FieldArray as needed and sets value for them recursively.
   *
   * If existing array has more items than the value provides, this method removes existing items.
   *
   * @param value The value to use to initialize this FieldArray.
   */
  initValue(value: any): void {
    if (value?.length) {
      const currentLength = this.controlFields.length;
      value.forEach((v: any, i: number) => {
        if (currentLength < i + 1) {
          this.push(this.buildField());
        }
        this.get([i])?.initValue(v);
      });

      if (value.length < currentLength) {
        for (let i = currentLength; i >= value.length; i--) {
          this.removeAt(i);
        }
      }
    }
  }
}

/**
 * Extends FormArray
 *
 * Example usage:
 *
 * ```typescript
 * const group = new FieldGroup(
 *   Field.build('Registration info', g => new SummaryItem(g.label, g.value)),
 *   {
 *     user: new FieldControl(Field.build(), 'foo'),
 *     pass: new FieldControl(Field.build(), 'defaultPassword')
 *   }
 * );
 *
 * console.log(group.summary);
 * // logs {question: 'Registration info', answer: {user: 'foo', pass: 'bar'}}
 * ```
 */
export class FieldGroup extends FormGroup implements AbstractField {

  private readonly _field: Field;

  /**
   * The Field object associated with this group of field controls.
   * @readonly
   */
  get field(): Field {
    return this._field;
  }

  /**
   * Gets the current validation errors for the group of field controls.
   * @readonly
   */
  get currentErrors(): string[] {
    return fieldGetErrorMessages(this);
  }

  /**
   * Gets a summary of the current value of the group of field controls.
   * @readonly
   */
  get summary(): SummaryItem<any> | null {
    return fieldGetSummary(this);
  }

  /**
   * Creates a new instance of FieldGroup.
   * @constructor
   * @param field The Field object associated with this group of field controls.
   * @param controls A collection of child AbstractField objects that make up this group of field controls.
   * @param validatorOrOpts The validation rules to apply to the group of field controls.
   * @param asyncValidator The asynchronous validation rules to apply to the group of field controls.
   */
  constructor(field: Field,
              controls: { [key: string]: AbstractField },
              validatorOrOpts?: AngularValidators,
              asyncValidator?: AngularAsyncValidators) {
    super(controls, validatorOrOpts, asyncValidator);
    if (field && !field.summaryFn) {
      field.summaryFn = EMPTY_SUMMARY_FN;
    }
    this._field = field;
  }

  /**
   * Get child of this FieldGroup from given path
   * @param path Path to the child control
   */
  get(path: Array<string | number> | string): AbstractField | null {
    return super.get(path) as AbstractField;
  }

  /**
   * Get root control of this FieldGroup.
   */
  get root(): AbstractField {
    return super.root as AbstractField;
  }

  /**
   * Gets a collection of child AbstractField objects that make up this group of field controls.
   * @readonly
   */
  get controlFields(): { [key: string]: AbstractField } {
    return this.controls as { [key: string]: AbstractField };
  }

  /**
   * Should not be used for FieldGroup
   */
  asArray(): FieldArray {
    throw Error('Cannot convert');
  }

  /**
   * Should not be used for FieldGroup
   */
  asControl(): FieldControl {
    throw Error('Cannot convert');
  }

  /**
   * Get concrete class (instead of Abstract field). Maybe useful in templates:
   *
   * ```html
   * <p *ngFor="let field of group.asGroup.controlFields">{{ field.value }}</p>
   * ```
   */
  asGroup(): FieldGroup {
    return this;
  }

  /**
   * Initializes the value of this FieldGroup object based on the provided value. If the provided value is an object,
   * this method recursively initializes the value of each child AbstractField object within this FieldGroup.
   *
   * @param value The value to use to initialize this FieldGroup.
   */
  initValue(value: any): any {
    if (value === Object(value)) {
      Object.entries(value).forEach(([fieldKey, fieldValue]) => {
        this.get(fieldKey)?.initValue(fieldValue);
      });
    }
  }

}

/**
 * Interface representing an abstract form field. Used similar way than Angular AbstractControl
 */
export interface AbstractField extends AbstractControl {
  /** The field data associated with the form field */
  field: Field;

  /** An array of the current error messages associated with the form field. */
  currentErrors: string[];

  /** A summary item representing the form field. */
  summary: SummaryItem<any> | null;

  /** Returns the form field as a FieldGroup. */
  asGroup(): FieldGroup;

  /** Returns the form field as a FieldArray. */
  asArray(): FieldArray;

  /** Returns the form field as a FieldControl. */
  asControl(): FieldControl;

  /** Returns the root form field */
  root: AbstractField;

  /** Returns the form field at the specified path. Uses Angular's get method from AbstractControl */
  get(path: Array<string | number> | string): AbstractField | null;

  /** Initializes the form field with the specified value. */
  initValue(value: any): void;
}
