import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {liiteArray} from "../common/lomake-fields/liite";
import {ToukoValidation} from "../../validators/validators";
import {QuestionOnlySummaryComponent} from "../../summary/question-only-summary.component";
import {KateTableSummaryComponent} from "../../summary/kate-table-summary.component";
import {AnswerOnlySummaryComponent} from "../../summary/answer-only-summary.component";
import {titleSummary} from "../lannoiterekisteri/summary-helpers";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";


const attr = TulkkiObject.getCreateFn("kasvinterveysrekisteri")
export const createToiminta = () => new FieldGroup(
    Field.build(), {
      toimAlaPuutarhaTarha: new FieldControl(Field.of({
        label: attr("toimAlaPuutarhaTarha"),
        htmlId: "toiminta-toimAlaPuutarhaTarha",
        options: {selite: attr("toimAlaPuutarhaTarhaSelite"), kategoria: "puutarha", ikoni: "fa-seedling"}
      })),
      toimAlaPuutarhaMyymala: new FieldControl(Field.of({
        label: attr("toimAlaPuutarhaMyymala"),
        htmlId: "toiminta-toimAlaPuutarhaMyymala",
        options: {selite: attr("toimAlaPuutarhaMyymalaSelite"), kategoria: "puutarha", ikoni: "fa-store"}
      })),
      toimAlaPuutarhaKasvi: new FieldControl(Field.of({
        label: attr("toimAlaPuutarhaKasvi"),
        htmlId: "toiminta-toimAlaPuutarhaKasvi",
        options: {selite: attr("toimAlaPuutarhaKasviSelite"), kategoria: "puutarha", ikoni: "fa-warehouse"}
      })),
      toimAlaPuutarhaTukku: new FieldControl(Field.of({
        label: attr("toimAlaPuutarhaTukku"),
        htmlId: "toiminta-toimAlaPuutarhaTukku",
        options: {selite: attr("toimAlaPuutarhaTukkuSelite"), kategoria: "puutarha", ikoni: "fa-cubes"}
      })),
      toimAlaPuutarhaViherrak: new FieldControl(Field.of({
        label: attr("toimAlaPuutarhaViherrak"),
        htmlId: "toiminta-toimAlaPuutarhaViherrak",
        options: {selite: attr("toimAlaPuutarhaViherrakSelite"), kategoria: "puutarha", ikoni: "fa-tree"}
      })),
      toimAlaPuutarhaKasvitiet: new FieldControl(Field.of({
        label: attr("toimAlaPuutarhaKasvitiet"),
        htmlId: "toiminta-toimAlaPuutarhaKasvitiet",
        options: {selite: attr("toimAlaPuutarhaKasvitietSelite"), kategoria: "puutarha", ikoni: "fa-university"}
      })),
      toimAlaPerunaViljely: new FieldControl(Field.of({
        label: attr("toimAlaPerunaViljely"),
        htmlId: "toiminta-toimAlaPerunaViljely",
        options: {selite: attr("toimAlaPerunaViljelySelite"), kategoria: "peruna", ikoni: "fa-tractor"}
      })),
      toimAlaPuuISPM: new FieldControl(Field.of({
        label: attr("toimAlaPuuISPM"),
        htmlId: "toiminta-toimAlaPuuISPM",
        options: {selite: attr("toimAlaPuuISPMSelite"), kategoria: "puutavara", ikoni: "fa-boxes"}
      })),
      toimAlaPuuToimitus: new FieldControl(Field.of({
        label: attr("toimAlaPuuToimitus"),
        htmlId: "toiminta-toimAlaPuuToimitus",
        options: {selite: attr("toimAlaPuuToimitusSelite"), kategoria: "puutavara", ikoni: "fa-pallet"}
      })),
    }
);

const createValueOptions = (label: string, htmlId: string, myyntiLabel: string) => new FieldGroup(Field.of({
  label: attr(label),
  htmlId: htmlId,
  summaryFn: kateTableSummary
}), {
  jatkokasvatus: new FieldControl(Field.of({
    label: attr("tuoJatkokasvatukseen"),
    htmlId: htmlId + "-jatkokasvatus",
  })),
  myynti: new FieldControl(Field.of({label: attr(myyntiLabel), htmlId: htmlId + "-myynti"}))
})

const createTuontiJaVientiValueOptions = (label: string, htmlId: string) => new FieldGroup(Field.of({
  label: attr(label),
  htmlId: htmlId,
  summaryFn: kateTableSummary
}), {
  tuonti: new FieldControl(Field.of({label: attr("maahantuonti"), htmlId: htmlId + "-tuonti"})),
  vienti: new FieldControl(Field.of({label: attr("maastavienti"), htmlId: htmlId + "-vienti"}))
})

const createKasviValueOptions = (label: string) => new FieldGroup(Field.of(
    {
      label: attr(label),
      htmlId: label,
      summaryFn: kateTableSummary
    }), {
  kasviTaimi: new FieldControl(Field.of({label: attr("kasviTaimi"), htmlId: label + "-kasviTaimi"})),
  kasviValmis: new FieldControl(Field.of({label: attr("kasviValmiit"), htmlId: label + "-kasviValmis"}))
})

export const createTuoPuuvart = () => new FieldGroup(
    Field.of({
      label: attr("tuoPuuvartYhtOtsikko"),
      errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr("errAtLeastOne")},
      summaryFn: titleWithCheckboxSummary
    }), {
      tuoPuuvart: new FieldControl(Field.of({
        label: attr("tuoPuuvart"),
        htmlId: "tuoPuuvart",
        summaryFn: questionOnlySummary,
      })),
      tuoPerenna: createValueOptions("tuoPerenna", "tuoPerenna", "tuoKuluttajille"),
      tuoPuuvartTuli: createValueOptions("tuoPuuvartTuli", "tuoPuuvartTuli", "tuoKuluttajille"),
      tuoPuuvartPrunus: createValueOptions("tuoPuuvartPrunus", "tuoPuuvartPrunus", "tuoKuluttajille"),
      tuoPuuvartMuu: createValueOptions("tuoPuuvartMuu", "tuoPuuvartMuu", "tuoKuluttajille")
    }, ToukoValidation.kateAtLeastOneTrue
)

export const createTuoMarjaVarm = () => new FieldGroup(
    Field.of({
      label: attr("tuoMarjaVarmYhtOtsikko"),
      errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr("errAtLeastOne")},
      summaryFn: titleWithCheckboxSummary
    }), {
      tuoMarjaVarm: new FieldControl(Field.of({
        label: attr("tuoMarjaVarm"),
        htmlId: "tuoMarjaVarm",
        summaryFn: questionOnlySummary
      })),
      tuoMarjaVarmMansikka: createValueOptions("tuoMarjaMansikka", "tuoMarjaVarmMansikka", "tuoKuluttajille"),
      tuoMarjaVarmMuut: createValueOptions("tuoMarjaMuut", "tuoMarjaVarmMuut", "tuoKuluttajille")
    }, ToukoValidation.kateAtLeastOneTrue
)

export const createTuoMarjaCAC = () => new FieldGroup(
    Field.of({
      label: attr("tuoMarjaCACYhtOtsikko"),
      errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr("errAtLeastOne")},
      summaryFn: titleWithCheckboxSummary
    }), {
      tuoMarjaCAC: new FieldControl(Field.of({
        label: attr("tuoMarjaCAC"),
        htmlId: "tuoMarjaCAC",
        summaryFn: questionOnlySummary
      })),
      tuoMarjaCACMansikka: createValueOptions("tuoMarjaMansikka", "tuoMarjaCACMansikka", "tuoKuluttajille"),
      tuoMarjaCACMuut: createValueOptions("tuoMarjaMuut", "tuoMarjaCACMuut", "tuoKuluttajille")
    }, ToukoValidation.kateAtLeastOneTrue
)

export const createTuoKasvi = () => new FieldGroup(
    Field.build(attr("tuoKasviYhtOtsikko"), titleSummary),
    {
      tuoKasviKahvi: createKasviValueOptions("tuoKasviKahvi"),
      tuoKasviJoulutahti: createKasviValueOptions("tuoKasviJoulutahti"),
      tuoKasviLaventeli: createKasviValueOptions("tuoKasviLaventeli"),
      tuoKasviOleanteri: createKasviValueOptions("tuoKasviOleanteri"),
      tuoKasviMyrttilinnunruoho: createKasviValueOptions("tuoKasviMyrttilinnunruoho"),
      tuoKasviMuut: createKasviValueOptions("tuoKasviMuut")
    }
)

export const createTuoVihannesTaimi = () => new FieldGroup(
    Field.of({
      label: attr("tuoVihannesTaimiYhtOtsikko"),
      errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr("errAtLeastOne")},
      summaryFn: titleSummary
    }), {
      tuoVihannesTaimi: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimi"),
        htmlId: "tuoVihannesTaimi",
        summaryFn: questionOnlySummary
      })),
      tuoVihannesTaimiPaprika: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiPaprika"),
        htmlId: "tuoVihannesTaimiPaprika",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiKasvihuonekurkku: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiKasvihuonekurkku"),
        htmlId: "tuoVihannesTaimiKasvihuonekurkku",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiTomaatti: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiTomaatti"),
        htmlId: "tuoVihannesTaimiTomaatti",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiPurjo: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiPurjo"),
        htmlId: "tuoVihannesTaimiPurjo",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiKaalit: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiKaalit"),
        htmlId: "tuoVihannesTaimiKaalit",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiAvomaankurkku: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiAvomaankurkku"),
        htmlId: "tuoVihannesTaimiAvomaankurkku",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiKurpitsat: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiKurpitsat"),
        htmlId: "tuoVihannesTaimiKurpitsat",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesTaimiSalaatit: new FieldControl(Field.of({
        label: attr("tuoVihannesTaimiSalaatit"),
        htmlId: "tuoVihannesTaimiSalaatit",
        summaryFn: answerOnlySummary
      }))
    }, ToukoValidation.kateAtLeastOneTrue
)

export const createTuoVihannesYksityiskaytto = () => new FieldGroup(
    Field.build(attr("tuoVihannesYksityiskayttoYhtOtsikko"), titleSummary), {
      tuoVihannesYksityiskaytto: new FieldControl(Field.of({
        label: attr("tuoVihannesYksityiskaytto"),
        htmlId: "tuoVihannesYksityiskaytto",
        summaryFn: questionOnlySummary
      }))
    }
)

export const createTuoVihannesValmis = () => new FieldGroup(
    Field.of({
      label: attr("tuoVihannesValmisYhtOtsikko"),
      errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr("errAtLeastOne")},
      summaryFn: titleSummary
    }), {
      tuoVihannesValmis: new FieldControl(Field.of({
        label: attr("tuoVihannesValmis"),
        htmlId: "tuoVihannesValmis",
        summaryFn: questionOnlySummary
      })),
      tuoVihannesValmisKurkku: new FieldControl(Field.of({
        label: attr("tuoVihannesValmisKurkku"),
        htmlId: "tuoVihannesValmisKurkku",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesValmisPaprika: new FieldControl(Field.of({
        label: attr("tuoVihannesValmisPaprika"),
        htmlId: "tuoVihannesValmisPaprika",
        summaryFn: answerOnlySummary
      })),
      tuoVihannesValmisTomaatti: new FieldControl(Field.of({
        label: attr("tuoVihannesValmisTomaatti"),
        htmlId: "tuoVihannesValmisTomaatti",
        summaryFn: answerOnlySummary
      }))
    }, ToukoValidation.kateAtLeastOneTrue
)

export const createTuoPerunaViljely = () => new FieldGroup(
    Field.build(attr("tuoPerunaViljelyOtsikko"), titleSummary), {
      tuoPerunaViljelySiemen: new FieldControl(Field.of({
        label: attr("tuoPerunaViljelySiemen"),
        htmlId: "tuoPerunaViljelySiemen",
        summaryFn: answerOnlySummary
      })),
      tuoPerunaViljelyRuoka: new FieldControl(Field.of({
        label: attr("tuoPerunaViljelyRuoka"),
        htmlId: "tuoPerunaViljelyRuoka",
        summaryFn: answerOnlySummary
      })),
      tuoPerunaViljelyRuokateollisuus: new FieldControl(Field.of({
        label: attr("tuoPerunaViljelyRuokateollisuus"),
        htmlId: "tuoPerunaViljelyRuokateollisuus",
        summaryFn: answerOnlySummary
      })),
      tuoPerunaViljelyTarkkelys: new FieldControl(Field.of({
        label: attr("tuoPerunaViljelyTarkkelys"),
        htmlId: "tuoPerunaViljelyTarkkelys",
        summaryFn: answerOnlySummary
      }))
    }
)

export const createTuoISPM15Kasittely = () => new FieldGroup(
    Field.build(attr("tuoISPM15KasittelyYhtOtsikko"), titleSummary), {
      tuoISPM15Kasittely: new FieldControl(Field.of({
        label: attr("tuoISPM15Kasittely"),
        htmlId: "tuoISPM15Kasittely",
        summaryFn: questionOnlySummary
      })),
      tuoISPM15KasittelyLiite: liiteArray(attr("tuoISPM15KasittelyLiite"), "tuoISPM15KasittelyLiite")
    }
)

export const createTuoISPM15Valmistus = () => new FieldGroup(
    Field.build(attr("tuoISPM15ValmistusYhtOtsikko"), titleSummary), {
      tuoISPM15Valmistus: new FieldControl(Field.of({
        label: attr("tuoISPM15Valmistus"),
        htmlId: "tuoISPM15Valmistus",
        summaryFn: questionOnlySummary
      })),
      tuoISPM15ValmistusAlkuperaLiite: liiteArray(attr("tuoISPM15ValmistusAlkuperaLiite"), "tuoISPM15ValmistusAlkuperaLiite"),
      tuoISPM15ValmistusLeimausLiite: liiteArray(attr("tuoISPM15ValmistusLeimausLiite"), "tuoISPM15ValmistusLeimausLiite")
    }
)

export const createMyyTaimimyymala = () => new FieldGroup(
    Field.build(attr("myyTaimiOtsikko"), titleSummary), {
      myyTaimimyymalaPerenna: new FieldControl(Field.of({
        label: attr("myyTaimimyymalaPerenna"),
        htmlId: "myyTaimimyymalaPerenna",
        summaryFn: answerOnlySummary
      })),
      myyTaimimyymalaPuu: new FieldControl(Field.of({
        label: attr("myyTaimimyymalaPuu"),
        htmlId: "myyTaimimyymalaPuu",
        summaryFn: answerOnlySummary
      })),
      myyTaimimyymalaTulipolte: new FieldControl(Field.of({
        label: attr("myyTaimimyymalaTulipolte"),
        htmlId: "myyTaimimyymalaTulipolte",
        summaryFn: answerOnlySummary,
        options: {indent: true}
      }))
    }
)

export const createMyyTaimiValitysKasvi = () => new FieldGroup(
    Field.build(attr("myyTaimiValitysKasviYhtOtsikko"), titleSummary), {
      myyTaimiValitysKasviVihannes: createValueOptions("myyKasviVihannes", "myyTaimiValitysKasviVihannes", "myyMyynti"),
      myyTaimiValitysAvoVihannes: createValueOptions("myyAvoTaimiVihannes", "myyTaimiValitysAvoVihannes", "myyMyynti"),
      myyTaimiValitysMuu: createValueOptions("muutLajit", "myyTaimiValitysMuu", "myyMyynti")
    }
)

export const createMyyTaimiValitysAvo = () => new FieldGroup(
    Field.build(attr("myyTaimiValitysAvoYhtOtsikko"), titleSummary), {
      myyTaimiValitysAvoPerenna: createValueOptions("myyAvoTaimiPerenna", "myyTaimiValitysAvoPerenna", "myyMyynti"),
      myyTaimiValitysAvoPuuvart: createValueOptions("myyAvoTaimiPuuvart", "myyTaimiValitysAvoPuuvart", "myyMyynti"),
      myyTaimiValitysAvoMarja: createValueOptions("myyAvoTaimiMarja", "myyTaimiValitysAvoMarja", "myyMyynti")
    }
)

export const createMyyTaimiValitysSiemen = () => new FieldGroup(
    Field.build(attr("myyTaimiValitysSiemenYhtOtsikko"), titleSummary), {
      myyTaimiValitysSiemenKoriste: new FieldControl(Field.of({
        label: attr("myySiemenKoriste"),
        htmlId: "myyTaimiValitysSiemenKoriste",
        summaryFn: answerOnlySummary
      })),
      myyTaimiValitysSiemenTomaatti: new FieldControl(Field.of({
        label: attr("myySiemenTomaatti"),
        htmlId: "myyTaimiValitysSiemenTomaatti",
        summaryFn: answerOnlySummary
      }))
    }
)

export const createMyyTaimiValitysKasvipassi = () => new FieldGroup(
    Field.build(attr("myyTaimiValitysKasviYhtOtsikko"), titleSummary), {
      myyTaimiValitysKasvipassi: new FieldControl(Field.of({
        label: attr("myyTaimiValitysKasvipassi"),
        htmlId: "myyTaimiValitysKasvipassi",
        summaryFn: answerOnlySummary
      })),
    }
)

export const createMyyViherrakentaminen = () => new FieldGroup(
    Field.build(attr("myyViherOtsikko"), titleSummary), {
      myyViherValitys: new FieldControl(Field.of({
        label: attr("myyViherValitys"),
        htmlId: "myyViherValitys",
        summaryFn: answerOnlySummary
      })),
    }
)

export const createMyyEtamyynti = () => new FieldGroup(
    Field.build(attr("myyEtamyyntiOtsikko"), titleSummary), {
      myyEtamyyntiKasvihuoneViljelma: new FieldControl(Field.of({
        label: attr("myyEtamyyntiKasvihuoneViljelma"),
        htmlId: "myyEtamyyntiKasvihuoneViljelma",
        options: {toimintaId: 'toimAlaPuutarhaKasvi'},
        summaryFn: answerOnlySummary

      })),
      myyEtamyyntiTaimitarhaTaimi: new FieldControl(Field.of({
        label: attr("myyEtamyyntiTaimitarhaTaimi"),
        htmlId: "myyEtamyyntiTaimitarhaTaimi",
        options: {toimintaId: 'toimAlaPuutarhaTarha'},
        summaryFn: answerOnlySummary
      })),
      myyEtamyyntiTaimimyymala: new FieldControl(Field.of({
        label: attr("myyEtamyyntiTaimimyymala"),
        htmlId: "myyEtamyyntiTaimimyymala",
        options: {toimintaId: 'toimAlaPuutarhaMyymala'},
        summaryFn: answerOnlySummary
      })),
      myyEtamyyntiLiite: liiteArray(attr("myyEtamyyntiLiite"), "myyEtamyyntiLiite")
    }
)

export const createMyyPuutavaraKasvipassi = () => new FieldGroup(
    Field.build(attr("myyPuutavaraKasvipassiOtsikko"), titleSummary), {
      myyPuutavaraKasvipassi: new FieldControl(Field.of({
        label: attr("myyPuutavaraKasvipassi"),
        htmlId: "myyPuutavaraKasvipassi",
        summaryFn: answerOnlySummary
      })),
    }
)

export const createTuontiJaVienti = () => new FieldGroup(
    Field.build(attr("tvOtsikko"), titleSummary),
    {
      tvAvoPuutarha: createTuontiJaVientiValueOptions("tvAvoPuutarha", "tvAvoPuutarha"),
      tvKasvihuone: createTuontiJaVientiValueOptions("tvKasvihuone", "tvKasvihuone"),
      tvHedelma: createTuontiJaVientiValueOptions("tvHedelma", "tvHedelma"),
      tvSaha: createTuontiJaVientiValueOptions("tvSaha", "tvSaha"),
      tvPeruna: createTuontiJaVientiValueOptions("tvPeruna", "tvPeruna"),
      tvMuut: createTuontiJaVientiValueOptions("tvMuut", "tvMuut")
    }
)

export const createKasvitietPuutarha = () => new FieldGroup(
    Field.build(attr("tvKasvitietPuutarhaOtsikko"), titleSummary), {
      tvKasvitietPuutarha: new FieldControl(Field.of({
        label: attr("tvKasvitietPuutarha"),
        htmlId: "tvKasvitietPuutarha",
        summaryFn: answerOnlySummary
      })),
    }
)

export const createOmavalvonta = () => new FieldGroup(
    Field.build(attr("omavalvonta"), titleSummary), {
      omavalvontaLiite: liiteArray(attr("omavalvonta"), "omavalvonta"),
    }
)


const kateTableSummary: SummaryFnType = control => new SummaryItem<any>(
    control.field.label,
    getAnswersForTableSummary(control.asGroup()),
    {show: Object.values(control.asGroup().controlFields).some(value => value.value)},
    KateTableSummaryComponent
);

const getAnswersForTableSummary = (group: FieldGroup) => {
  return Object.values(group.controlFields)
      .filter(field => field.value)
      .map(field => field.field);
}

const questionOnlySummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, null, {show: control.value}, QuestionOnlySummaryComponent);
}

const answerOnlySummary: SummaryFnType = control => {
  return new SummaryItem<any>(null, control.field.label, {
    show: control.value,
    isHighlighted: true
  }, AnswerOnlySummaryComponent);
}

export const titleWithCheckboxSummary: SummaryFnType = group => {
  return new SummaryItem<any>(group.field.label, null, {show: Object.values(group.asGroup().controlFields)[0]?.summary?.show}, TitleSummaryComponent)
}
