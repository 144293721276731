import {Field, FieldControl, SummaryItem} from "ngx-fielding";
import {ExcelSummaryComponent} from "../../../summary/excel-summary.component";

const validateExcels = (control: FieldControl) => {
  const excels = control.value || [];
  return excels.some(excel => excel.errors?.length > 0) ?
    {excelVirhe: true} :
    null;
};


export const createExcelTiedostot = () => new FieldControl(
    Field.build(
      "lomakeYleinen.ilmoitetutTuotteet",
      (control) => new SummaryItem<any>(control.field.label, control.value, {show: Boolean(control.status === "VALID" && control.value.length)}, ExcelSummaryComponent)
    ),
    [],
    validateExcels
  );
