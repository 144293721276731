<div class="row">
  <div class="col yhteenveto-list">
    <h2 [attribute]="titleAttribute"></h2>

    <div *ngFor="let group of yhteenveto" class="ml-md-3 mr-md-3 yhteenveto-list">
      <h3 class="yhteenveto-header" [attribute]="group.title?.getFullKey()"></h3>
      <ul class="list-group list-group-flush">

        <ng-container *ngFor="let item of group.data">
          <li class="list-group-item" [ngClass]="{'hide-from-pdf': item.compareStatus === compareStatusEnum.REMOVE}"
              *ngIf="group.data.length > 0 && (showCompareYhteenveto || item.compareStatus !== compareStatusEnum.REMOVE)">
            <yhteenveto-container [item]="item.nonNullItem" [compareStatus]="item.compareStatus" [prevItem]="item.prevItem" [showCompareYhteenveto]="showCompareYhteenveto"></yhteenveto-container>
          </li>
        </ng-container>
        <li class="list-group-item" *ngIf="group.data.length == 0 && group.title">-</li>
      </ul>
    </div>

  </div>
</div>
