import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {ControlContainer, FormControl, FormGroup} from "@angular/forms";
import {Yritys} from "../raportti/raportit-virkailija/yritys";

@Component({
  selector: 'yritys-typeahead',
  template: `
    <div class="input-group" [formGroup]="formGroup">
      <input #typeaheadInput="ngbTypeahead"
             [id]="htmlId"
             [ngbTypeahead]="search"
             [formControlName]="controlName"
             [resultFormatter]="getView"
             [inputFormatter]="getYritysResult"
             [placeholder]="placeholder"
             [editable]="false"
             (focus)="focus$.next($event.target.value)"
             (click)="click$.next($event.target.value)"
             class="form-control z-index-0 custom-select"
      >
    </div>
  `,
  styleUrls: ["valvontakohde-typeahead/valvontakohde-typeahead.component.scss"]
})
export class YritysTypeaheadComponent implements OnInit, OnChanges {
  @Input() items: Yritys[];
  @Input() htmlId: string;
  @Input() controlName: string;
  @Input() placeholder = '';
  @ViewChild('typeaheadInput') typeaheadInput: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  formGroup: FormGroup;
  formControl: FormControl;
  value: string;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeaheadInput.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.items :
        this.items.filter(
          yritys => this.getView(yritys).toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  constructor( private readonly controlContainer: ControlContainer) {
    this.getView = this.getView.bind(this);
  }

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
    this.formControl = this.formGroup.get(this.controlName) as FormControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.items = changes.items.currentValue;
    }
  }

  getView(yritys: Yritys) {
    return `${yritys.paaToiminimi} (${yritys.yTunnus})`;
  }

  getYritysResult(yritys: Yritys) {
    return `${yritys.paaToiminimi} (${yritys.yTunnus})`;
  }

}
