/**
 * Created by Seppo on 03/01/2018.
 */

export class Palvelu {
  asia: string;
  toimintotyyppi: string;
  voimassaId: number | null;
  tila: PalveluTila;
  keskeneraisenId: number | null;
}

export enum PalveluTila {
  AKTIIVINEN = "AKTIIVINEN",
  EI_AKTIIVINEN = "EI_AKTIIVINEN",
  PIILOTETTU = "PIILOTETTU"
}

export interface Palvelut {
  palvelut: Palvelu[];
  asiakirjatVisible: boolean;
}
