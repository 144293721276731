import {TranslationWidth} from "@angular/common";
import {Injectable} from "@angular/core";
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {Subscription} from "rxjs";
import {isNumeric} from "rxjs/internal-compatibility";
import {TiiraService} from "../tiira/tiira.service";
import {DateObj} from "../touko-lomake/syote/syote-utils";
import {AutoUnsubscribe} from "./auto-unsubscribe.decorator";

const LOCALIZED_DATES = {
    FI: {
        viikonpaiva: ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'],
        kuukausi: ['tammi', 'helmi', 'maalis', 'huhti', 'touko', 'kesä', 'heinä', 'elo', 'syys', 'loka', 'marras', 'joulu'],
    },
    SV: {
        viikonpaiva: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
        kuukausi: ['jan', 'febr', 'mars', 'april', 'maj', 'juni', 'juli', 'aug', 'sept', 'okt', 'nov', 'dec'],
    }
    // muut kielet
};

@Injectable()
@AutoUnsubscribe
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    lang = "FI";
    languageSubscription: Subscription;

    constructor(private tiiraService: TiiraService) {
        super();
        this.languageSubscription = this.tiiraService.language$.subscribe(l => this.lang = l);
    }

    getWeekdayShortName(weekday: number): string {
        return LOCALIZED_DATES[this.lang].viikonpaiva[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return LOCALIZED_DATES[this.lang].kuukausi[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return this.getWeekdayShortName(weekday);
    }
}

@Injectable()
export class CustomDateFormatter extends NgbDateParserFormatter {
    parse(value: string): DateObj {
        if (value) {
            const dateParts = value.trim().split('.');

            if (dateParts.length === 3 && dateParts.every(part => part !== null && isNumeric(part))) {
                return new DateObj(Number(dateParts[0]), Number(dateParts[1]), Number(dateParts[2]));
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        let stringDate = "";
        if (date) {
            stringDate += isNumeric(date.day) ? date.day + "." : "";
            stringDate += isNumeric(date.month) ? date.month + "." : "";
            stringDate += isNumeric(date.month) ? date.year : "";
        }
        return stringDate;
    }

}
