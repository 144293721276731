import {Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "touko-select-menu",
  template: `
    <label [htmlFor]="htmlId" [attribute]="labelAttribute"
           [ngClass]="{'d-none': !labelAttribute, 'required': showRequired}"></label>
    <div class="position-relative" [ngClass]="{'select-menu-disabled' : disabled}" (clickOutside)="closeMenu()">
      <button [id]="htmlId" (click)="toggleMenu()" type="button" class="select-menu-btn form-control"
              aria-haspopup="listbox" [attr.aria-disabled]="disabled" [attr.aria-expanded]="dropdownMenuShown"
              [attr.aria-labelledby]="labelAttribute">
        <span class="select-menu-text">{{selectButtonText | attribute}}</span>
        <i class="{{dropdownMenuShown ? 'fa fa-caret-up' : 'fa fa-caret-down'}}" aria-hidden="true"></i>
      </button>
      <button *ngIf="selectionRemovable && selectedValue" (click)="clearSelection()" type="button"
              class="btn-no-style close-btn" aria-label="clear"><i class="fa fa-times text-primary"
                                                                   aria-hidden="true"></i></button>
      <ul *ngIf="dropdownMenuShown" tabindex="-1" role="listbox"
          class="shadow border border-top-0 select-menu list-unstyled">
        <li *ngFor="let option of options" (click)="selectOption(option)" class="menu-option"
            [ngClass]="{'menu-option-selected' : selectedValue === option.value, 'menu-option-disabled' : option.disabled}"
            role="option" tabindex="0" [attribute]="option.label"></li>
      </ul>
    </div>
  `
  ,
  styleUrls: ['./select-menu.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectMenuComponent),
    }
  ]
})

export class SelectMenuComponent implements ControlValueAccessor, OnChanges {
  writeValue(obj: string): void {
    const selected = this.options.find(o => o.value === obj);
    this.selectButtonText = selected?.label || this.defaultTitle;

    this.change.emit(obj);
    this.selectedValue = obj;
    this._onChange(obj);
  }
  private _onChange = (_: any) => {
    // This is intentional
  };
  private _onTouched = (_: any) => {
    // This is intentional
  };

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @Input() htmlId: string;
  @Input() defaultTitle = 'teksti.valitse';
  @Input() labelAttribute: string;
  @Input() options: Option[] = [];
  @Input() disabled = false;
  @Input() selectionRemovable = true;
  @Input() value: string;
  @Input() showRequired = false;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  selectedValue: string;
  isNativeComponent = false;

  dropdownMenuShown = false;
  selectButtonText: string;

  private currentIndex = -1;

  constructor(private elem: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.isFirstChange()) {
      this.isNativeComponent = true;
    }

    if (!this.isNativeComponent) {
      return;
    }

    const changedSelectedValue = changes.value?.currentValue;
    if (changedSelectedValue) {
      const selectedOption = this.options.find(option => option.value === changedSelectedValue);
      if (selectedOption) {
        this.selectButtonText = selectedOption.label;
      }
    } else {
      const defaultOption = this.options.find(option => option.selected);
      if (defaultOption) {
        this.selectedValue = defaultOption.value;
        this.selectButtonText = defaultOption.label;
      } else {
        this.selectButtonText = this.defaultTitle;
      }
    }
  }

  $event: KeyboardEvent;

  toggleMenu() {
    if (!this.disabled) {
this.dropdownMenuShown = !this.dropdownMenuShown;
}
  }

  closeMenu() {
    this.dropdownMenuShown = false;
  }

  selectOption(selectedOption: Option) {
    if (!selectedOption.disabled) {
      this.writeValue(selectedOption.value);
      this.closeMenu();
    }
  }

  clearSelection() {
    this.writeValue(null);
    this.closeMenu();
  }

  selectByIndex(i: number) {
    const value = this.options[i];
    this.selectOption(value);
  }

  handleKeyboardEvents($event: KeyboardEvent) {
    if (this.dropdownMenuShown) {
      $event.preventDefault();
    } else {
      return;
    }
    if ($event.code === 'ArrowUp') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.elem.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
    } else if ($event.code === 'ArrowDown') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex < this.options.length-1) {
        this.currentIndex++;
      }
      this.elem.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
    } else if (($event.code === 'Enter' || $event.code === 'NumpadEnter' || $event.code === 'Space') && this.currentIndex >= 0) {
      this.selectByIndex(this.currentIndex);
    } else if ($event.code === 'Escape') {
      this.closeMenu();
    }
  }
}

export interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
}
