import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToukoLaheteComponent} from './touko-lahete.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {LaheteModule} from "ee-lahete";
import {QuestionInputsModule} from "./question-input/question-inputs.module";
import {AttributeModule} from "../tiira/attribute.module";
import {YhteenvetoModule} from "../touko-lomake/yhteenveto/yhteenveto.module";
import {LaheteYhteenvetoModule} from "./yhteenveto/lahete-yhteenveto.module";
import {MessageModule} from "../message/message.module";
import {LomakeLayoutModule} from "../touko-lomake/lomake-layout.module";
import {LaheteTarkastusComponent} from './tarkastus/lahete-tarkastus.component';
import {UtilsModule} from "../utils/utils.module";
import {ToukoInputsModule} from "../touko-inputs/touko-inputs.module";
import {LaheteServiceConfig} from './lahete/lahete.service';
import {ToukoComponentsModule} from "../touko-components/touko-components.module";

/**
 * Toukon LaheteService-konfiguraatiot
 */
@Injectable()
export class ToukoLaheteServiceConfig extends LaheteServiceConfig {
  getKoodistoURL = "/api/v1/as/lahetteet/koodistot";
}

@NgModule({
  declarations: [
    ToukoLaheteComponent,
    LaheteTarkastusComponent
  ],
    imports: [
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        LaheteModule,
        LomakeLayoutModule,
        QuestionInputsModule,
        YhteenvetoModule,
        AttributeModule,
        MessageModule,
        LaheteYhteenvetoModule,
        UtilsModule,
        FormsModule,
      ToukoInputsModule,
      ToukoComponentsModule
    ],
  providers: [
    {provide: LaheteServiceConfig, useClass: ToukoLaheteServiceConfig},
  ]
})
export class ToukoLaheteModule { }
