import {Injectable} from "@angular/core";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";
import {Field, FieldGroup} from "ngx-fielding";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {createToiminnanAjankohta} from "../common/lomake-fields/toiminnan-ajankohta";
import {createVerkkokaupatGroup} from "../common/lomake-fields/verkkokaupat";
import {createMarkkinoimasiKylvosiemenet, createToiminnanHarjoittaminen} from "./toiminnan-luonne-sivu";
import {createLiite, createLisatiedot, createToimiala, createToiminnanLaajuus} from "./toiminnan-laajuus-sivu";

@Injectable()
export class SiemenrekisteriV2Data implements LomakeV2Data {

  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(toimintotyyppi: string): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();

    const {ulkomainenOsoite, perustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
      Field.build(),
      {
        ulkomainenOsoite,
        perustiedot,
        yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
        toiminnanAloitus: createToiminnanAjankohta(toimintotyyppi),
        markkinoimasiKylvosiemenet: createMarkkinoimasiKylvosiemenet(),
        toiminnanHarjoittaminen: createToiminnanHarjoittaminen(),
        verkkokaupat: createVerkkokaupatGroup(),
        toiminnanLaajuus: createToiminnanLaajuus(),
        yrityksenToimiala: createToimiala(),
        liite: createLiite(),
        lisatiedot: createLisatiedot(),
      }
    )
  }

}



