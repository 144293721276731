import {Component, OnInit} from "@angular/core";
import {FieldArray} from "ngx-fielding";
import {ControlContainer} from "@angular/forms";
import {LuomuvalvontaService} from "../luomuvalvonta.service";
import {AutoUnsubscribe} from "../../../../utils/auto-unsubscribe.decorator";
import {DateObj} from "../../../syote/syote-utils";

@Component({
  selector: 'peltolohko-tiedot',
  template: `
    <h3 attribute="luomu.tilanPeltolohkojenTiedot" class="h3"></h3>
    <div *ngFor="let lohkotietoKortti of peltolohkoTiedotFormArray.controlFields; index as i">
      <div [formGroup]="lohkotietoKortti.asGroup()" class="mb-4">
        <touko-background-card>
          <div class="row">
            <h3 attribute="luomu.lohkotiedot" class="h3 col-11">Lohkotiedot</h3>
            <button *ngIf="peltolohkoTiedotFormArray.controlFields.length > 1" [id]="'poistaLohkotietoKortti-' + i"
                    type="button" class="close col-1" aria-label="Close"
                    (click)="removeLohkotietokortti(i)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row">
            <div class="col-6">
              <date label="luomu.siirtymavaiheenAloitusajankohta"
                    formControlName="siirtymavaiheenSuunniteltuAjankohta"
                    [style]="'h4'"
                    [htmlIdSuffix]="i.toString()"></date>
              <validation-error
                  [field]="lohkotietoKortti.asGroup().controlFields.siirtymavaiheenSuunniteltuAjankohta"></validation-error>
            </div>
            <fieldset class="col-6">
              <legend attribute="luomu.lohkonSijoitus" class="h4">Lohkon sijoitus</legend>
              <touko-radio-input
                  formControlName="lohkonSijoitusValinta"
                  [htmlId]="'luonnonmukainen-radio-' + i"
                  labelAttribute="luomu.luonnonmukainen"
                  [radioGroup]="'lohkonSijoitusValinta-' + i"
                  value="luonnonmukainen"></touko-radio-input>
              <touko-radio-input
                  formControlName="lohkonSijoitusValinta"
                  [htmlId]="'toistaiseksiTavanomainenViljely-radio-' + i"
                  labelAttribute="luomu.toistaiseksiTavanomainenViljely"
                  [radioGroup]="'lohkonSijoitusValinta-' + i"
                  value="toistaiseksiTavanomainenViljely"></touko-radio-input>
              <touko-radio-input
                  formControlName="lohkonSijoitusValinta"
                  [htmlId]="'tavanomainen-radio-' + i"
                  labelAttribute="luomu.tavanomainen"
                  [radioGroup]="'lohkonSijoitusValinta-' + i"
                  value="tavanomainen"></touko-radio-input>
            </fieldset>
          </div>
          <peltolohko-list [peltolohkotArray]="peltolohkotArrayFiltered" [index]="i"></peltolohko-list>
        </touko-background-card>
      </div>
      <validation-error [field]="lohkotietoKortti.asGroup()"></validation-error>
    </div>
    
    <button (click)="addPeltolohkoKortti()" class="btn-secondary mb-7" toukobutton type="button">
      <i aria-hidden="true" class="fa fa-plus-square"></i>
      <span attribute="luomu.uusiSiirtymavaihe"></span>
    </button>
  `
})

@AutoUnsubscribe
export class PeltolohkoTiedot implements OnInit {
  peltolohkotArray = [];

  constructor(private controlContainer: ControlContainer, private luomuvalvontaService: LuomuvalvontaService) {
  }

  get peltolohkoTiedotFormArray(): FieldArray {
    return this.controlContainer.control as FieldArray;
  }

  get peltolohkotArrayFiltered(): Array<any> {
    const selectedKasvulohkoTunnukset = [];

    this.peltolohkoTiedotFormArray.controlFields.forEach(peltolohkoKortti => {
      peltolohkoKortti.get('peltolohkot').asArray().controlFields.forEach(peltolohko => {
        const kasvulohkot = peltolohko.get('kasvulohkot')
        if (kasvulohkot) {
          kasvulohkot.asArray().controlFields.forEach(kasvulohko => {
            selectedKasvulohkoTunnukset.push(kasvulohko.get('lohkotunnus').value)
          })
        }
      })
    })

    if (selectedKasvulohkoTunnukset.length) {
      return this.peltolohkotArray.map(peltolohko => {
        return {
          ...peltolohko,
          kasvulohkot: peltolohko.kasvulohkot.filter(kasvulohko => !selectedKasvulohkoTunnukset.includes(kasvulohko.lohkotunnus))
        };
      }).filter(peltolohko => peltolohko.kasvulohkot.length > 0);

    } else {
      return this.peltolohkotArray;
    }
  }

  ngOnInit() {
    this.luomuvalvontaService.peltolohkot$.subscribe(peltolohkot => {
      this.peltolohkotArray = peltolohkot;
    });

    this.peltolohkoTiedotFormArray.valueChanges.subscribe(() => this.findVirheellisetPaivamaarat());
  }

  addPeltolohkoKortti() {
    this.peltolohkoTiedotFormArray.push(this.peltolohkoTiedotFormArray.buildField())
  }

  removeLohkotietokortti(index: number) {
    this.peltolohkoTiedotFormArray.removeAt(index);
  }

  findVirheellisetPaivamaarat() {
    const dates: Array<string> = this.peltolohkoTiedotFormArray.controlFields.map(control => {
      if (control.value.siirtymavaiheenSuunniteltuAjankohta) {
        return DateObj.toISO(control.value.siirtymavaiheenSuunniteltuAjankohta);
      }
    });
    const dateSet: Set<string> = new Set(dates);
    this.peltolohkoTiedotFormArray.controlFields.forEach((control) => {
      if (control.value.siirtymavaiheenSuunniteltuAjankohta) {
        const ISOdate = DateObj.toISO(control.value.siirtymavaiheenSuunniteltuAjankohta);
        if (dateSet.has(ISOdate)) {
          dateSet.delete(ISOdate)
          control.asGroup().controlFields.siirtymavaiheenSuunniteltuAjankohta.setErrors(null);
        } else {
          control.asGroup().controlFields.siirtymavaiheenSuunniteltuAjankohta.setErrors({errSiirtymavaihe: true})
          control.asGroup().controlFields.siirtymavaiheenSuunniteltuAjankohta.markAsTouched();
        }
      }
    })
  }
}
