import {AbstractLaheteGroup} from "../abstract-lahete-group";
import {
  CompareAction,
  CompareType,
  MatcherModel,
  ModelFragment,
  QuestionControl,
  QuestionGroup,
  Topic,
  TopicListener
} from "ee-lahete";
import {AsiakastietoValidators} from "../../validators/asiakastieto-validators";
import {LaheteService} from "../../lahete.service";
import {EnvironmentService} from "../../../../../environments/environment.service";

// @dynamic
export abstract class AbstractAsiakasGroup extends AbstractLaheteGroup {

  isValidPhonenumber;
  isValidEmail;
  isValidYtunnus;
  isValidElainlaakaritunnus;
  isValidCustomerContactPhonenumber;
  isValidCustomerContactEmail;

  questionGroupKey: string;

  private readonly _emitOpts = {onlySelf: true, emitEvent: false};

  constructor(laheteService: LaheteService) {
    super(laheteService);
    this.isValidPhonenumber = {
      validators: AsiakastietoValidators.isValidPhonenumber,
      errorMessages: {
        [AsiakastietoValidators.PHONE]: this.getLabel("Virheellinen puhelinnumero.")
      }
    };
    this.isValidEmail = {
      validators: AsiakastietoValidators.isValidEmail,
      errorMessages: {
        [AsiakastietoValidators.EMAIL]: this.getLabel("Virheellinen sähköpostiosoite.")
      }
    };
    this.isValidCustomerContactPhonenumber = {
      validators: AsiakastietoValidators.isValidPhonenumber,
      errorMessages: {
        [AsiakastietoValidators.PHONE]: this.getLabel("Virheellinen puhelinnumero.")
      }
    };
    this.isValidCustomerContactEmail = {
      validators: AsiakastietoValidators.isValidEmail,
      errorMessages: {
        [AsiakastietoValidators.EMAIL]: this.getLabel("Virheellinen sähköpostiosoite.")
      }
    };
    this.isValidElainlaakaritunnus = {
      validators: AsiakastietoValidators.isValidElainlaakaritunnus,
      errorMessages: {
        [AsiakastietoValidators.ELAINLAAKARITUNNUS]: this.getLabel("Virheellinen eläinlääkäritunnus.")
      }
    };
    this.isValidYtunnus = {
      validators: AsiakastietoValidators.isValidYtunnus,
      errorMessages: {
        [AsiakastietoValidators.YTUNNUS]: this.getLabel("Virheellinen Y-tunnus.")
      }
    };
  }

  static showIfSelected(elementName: string) {
    return {
      filters: {
        [elementName]:
            new MatcherModel(true, AbstractLaheteGroup.equalsFilterFn, AbstractLaheteGroup.filterActionFn)
      },
      options: {
        requireFullSummaryPath: true
      }
    };
  }

  protected static createTilaajaTyyppiFilterAnyValueTrue() {
    return AbstractAsiakasGroup.createTilaajaTyyppiFilter(null, AbstractAsiakasGroup.anyObjectValueTrue);
  }

  protected static createTilaajaTyyppiFilterEquals(tilaajaValue: TilaajaValue): { [s: string]: MatcherModel } {
    return AbstractAsiakasGroup.createTilaajaTyyppiFilter(tilaajaValue, AbstractAsiakasGroup.equalsFilterFn);
  }

  protected static createTilaajaTyyppiFilterNotEquals(tilaajaValue: TilaajaValue): { [s: string]: MatcherModel } {
    return AbstractAsiakasGroup.createTilaajaTyyppiFilter(tilaajaValue, AbstractAsiakasGroup.notNullAndNotEqualsFilterFn);
  }

  protected static createTilaajaTyyppiModifierNotEquals(tilaajaValue: TilaajaValue) {
    return {'tilaaja.tilaajaTyyppi.tilaajaTyyppi': new MatcherModel(tilaajaValue, AbstractAsiakasGroup.equalsResetFn, AbstractAsiakasGroup.modifierActionFn)};
  }

  protected static everyHenkilotietoValueFalse(groupValue, ignored: Set<string> = new Set(["kotimaanOsoite"])): boolean {
    if (groupValue !== undefined) {
      return !Object.entries(groupValue)
          .filter(([key, val]) => !ignored.has(key))
          .some(([key, val]) => Boolean(val));
    } else return true;
  }

  private static createTilaajaTyyppiFilter(tilaajaValue: TilaajaValue, fn: (compareVal, newVal) => CompareAction) {
    return {'tilaaja.tilaajaTyyppi.tilaajaTyyppi': new MatcherModel(tilaajaValue, fn, AbstractAsiakasGroup.filterActionFn)};
  }

  protected createHenkilotietoGroup(htmlIdSuffix: string): QuestionGroup {
    return new QuestionGroup({
      key: 'henkilotiedot',
      groupComponentType: 'nested',
      label: this.getLabel('asiakasHenkilotiedot'),
      controls: this.createHenkilotiedotControls(htmlIdSuffix),
    });
  }

  protected createYhteyshenkiloGroup(htmlIdSuffix: string): QuestionGroup {
    return new QuestionGroup({
      key: 'erillinenYhteyshenkilo',
      groupComponentType: 'nested',
      controls: this.createYhteyshenkiloControls(htmlIdSuffix),
    });
  }

  protected createLisatietoGroupForTilaaja(htmlIdSuffix: string): QuestionGroup {
    return new QuestionGroup({
      key: 'lisatiedot',
      groupComponentType: 'nested',
      label: this.getLabel('lisatiedot'),
      controls: this.createLisatiedotControlsForTilaaja(htmlIdSuffix)
    });
  }

  protected createLisatietoGroup(htmlIdSuffix: string): QuestionGroup {
    return new QuestionGroup({
      key: 'lisatiedot',
      groupComponentType: 'nested',
      label: this.getLabel('lisatiedot'),
      controls: this.createLisatiedotControls(htmlIdSuffix),
    });
  }

  protected createHenkilotiedotControls(htmlIdSuffix: string): QuestionControl[] {
    return [
      ...this.createNimitiedotControls(htmlIdSuffix),
      this.createTextInput('puhelin', htmlIdSuffix, this.getLabel('asiakasPuhelin'), this.isValidPhonenumber),
      this.createTextInput('email', htmlIdSuffix, this.getLabel('asiakasEmail'), this.isValidEmail),
      ...this.createOsoitetiedotControls(htmlIdSuffix)
    ];
  }

  protected createNimitiedotControls(htmlIdSuffix: string): QuestionControl[] {
    return [
      this.createTextInput('etunimi', htmlIdSuffix, this.getLabel('asiakasEtunimi'), this.requireConditionally('sukunimi')),
      this.createTextInput('sukunimi', htmlIdSuffix, this.getLabel('asiakasSukunimi'), this.requireConditionally('etunimi')),
      this.createTextInput('yritys', htmlIdSuffix, this.getLabel('asiakasYritys')),
      this.createTextInput('ytunnus', htmlIdSuffix, this.getLabel('asiakasYtunnus'), this.isValidYtunnus),
    ];
  }

  protected createYhteyshenkiloControls(htmlIdSuffix: string): QuestionControl[] {
    const elementName = `${this.questionGroupKey}.yhteyshenkilo.yhteyshenkilo`;
    return [
      this.createCheckboxInput(
          'yhteyshenkilo',
          htmlIdSuffix,
          this.getLabel('erillinenYhteyshenkilo'),
          false,
      ),
      this.createTextInput('etunimi', htmlIdSuffix, this.getLabel('yhteyshenkiloEtunimi'), AbstractAsiakasGroup.showIfSelected(elementName)),
      this.createTextInput('sukunimi', htmlIdSuffix, this.getLabel('yhteyshenkiloSukunimi'), AbstractAsiakasGroup.showIfSelected(elementName)),
      this.createTextInput('puhelin', htmlIdSuffix, this.getLabel('yhteyshenkiloPuh'), {...AbstractAsiakasGroup.showIfSelected(elementName), ...this.isValidCustomerContactPhonenumber}),
      this.createTextInput('email', htmlIdSuffix, this.getLabel('yhteyshenkiloSahkoposti'), {...AbstractAsiakasGroup.showIfSelected(elementName), ...this.isValidCustomerContactEmail})
    ];
  }

  protected createOsoitetiedotControls(htmlIdSuffix: string): QuestionControl[] {

    const postinumeroValidators = this.requireConditionally('osoite');
    postinumeroValidators.validators.push(AsiakastietoValidators.isValidPostinumero(this.laheteService.getStoredValue('postitoimipaikkaFn')));
    postinumeroValidators.errorMessages[AsiakastietoValidators.POSTINUMERO] = this.getLabel("Virheellinen postinumero");

    const postinumero = this.createTextInput('postinumero', htmlIdSuffix, this.getLabel('asiakasPostinumero'), postinumeroValidators);
    postinumero.publishers.push(new Topic('postinumeroChange', htmlIdSuffix));

    const postitoimipaikka = this.createTextInput('postitoimipaikka', htmlIdSuffix, this.getLabel('asiakasPostitoimipaikka'), this.requireConditionally('postinumero'));
    this.applyPostinumeroSubscriberToPostitoimipaikka(postitoimipaikka, htmlIdSuffix);

    return [
      this.createCheckboxInput(
          'kotimaanOsoite',
          htmlIdSuffix,
          this.getLabel('Osoite sijaitsee Suomessa'),
          true,
          {options: {hideInSummary: () => true}}
      ),
      this.createTextInput('osoite', htmlIdSuffix, this.getLabel('asiakasOsoite'), this.requireConditionally('postinumero')),
      postinumero,
      postitoimipaikka,
    ];
  }

  protected createLisatiedotControls(htmlIdSuffix: string): QuestionControl[] {
    return [
      this.createCheckboxInput('toimitusEmail', htmlIdSuffix, this.getLabel('asiakasToimitusEmail'), true),
      this.createCheckboxInput('toimitusPosti', htmlIdSuffix, this.getLabel('asiakasToimitusPosti'), false, {filters: false}),
      this.createMaksajaQuestion(htmlIdSuffix)
    ];
  }

  protected createLisatiedotControlsForTilaaja(htmlIdSuffix: string): QuestionControl[] {
    const env = new EnvironmentService();
    return [
      this.createMaksajaQuestion(htmlIdSuffix)
    ].filter(question => question != null);
  }

  protected applyPostitoimipaikkaDisabler(parentGroup: QuestionGroup) {
    const disablerKey = `${parentGroup.fullPath}.kotimaanOsoite`;
    const postitoimipaikkaModel = parentGroup.find(['postitoimipaikka']);
    this.applyPostitoimipaikkaDisablerMatcher(postitoimipaikkaModel, disablerKey);
  }

  protected applyPostitoimipaikkaDisablerMatcher(modelFragment: ModelFragment, disablerKey: string) {
    modelFragment.disablers = {
      [disablerKey]: new MatcherModel(
          true,
          AbstractAsiakasGroup.equalsDisablerFn,
          AbstractAsiakasGroup.disablerActionFn,
          CompareType.EVERY,
          CompareAction.DISABLE
      )
    };
  }

  protected createElainlaakariInput(htmlIdSuffix: string) {
    return this.createTextInput(
        'elainlaakaritunnus',
        htmlIdSuffix,
        this.getLabel('asiakasElainlaakariTunnus'),
        this.isValidElainlaakaritunnus
    );
  }

  protected createTextInput(key: string, htmlIdSuffix: string, label: string, other?: {
    [s: string]: any
  }): QuestionControl {
    return new QuestionControl({
      key: key,
      htmlId: `${key}${htmlIdSuffix}`,
      label: label,
      controlType: 'text',
      ...other
    });
  }

  protected createCheckboxInput(key: string, htmlIdSuffix: string, label: string, initialValue = false, other?: {
    [s: string]: any
  }): QuestionControl {
    return new QuestionControl({
      key: key,
      htmlId: key + htmlIdSuffix,
      label: label,
      initialValue: initialValue,
      controlType: 'checkbox',
      ...other
    });
  }

  protected createRadioInput(key: string, label: string, value: string): QuestionControl {
    return new QuestionControl({
      key: key,
      label: label,
      value: value,
      htmlId: `${key}-${value}`,
      controlType: 'radio'
    });
  }

  private requireConditionally(otherControlName: string) {
    return {
      validators: [AsiakastietoValidators.requiredIfOtherFilled(otherControlName)],
      errorMessages: {
        required: this.getLabel("Kenttä on pakollinen")
      }
    };
  }

  private createMaksajaQuestion(htmlIdSuffix: string) {
    const maksajaQuestion = this.createCheckboxInput(`maksaja`, htmlIdSuffix, this.getLabel('asiakasMaksaja'), false);
    maksajaQuestion.publishers.push(new Topic('maksajaChange', htmlIdSuffix));
    maksajaQuestion.subscribers.push(
        new TopicListener(
            'maksajaChange',
            (received) => received.detail !== htmlIdSuffix,
            (control, val) => Boolean(val) ?
                control.disable(this._emitOpts) :
                control.enable(this._emitOpts)
        )
    );
    return maksajaQuestion;
  }

  private applyPostinumeroSubscriberToPostitoimipaikka(postitoimipaikka: ModelFragment, htmlIdSuffix: string) {
    postitoimipaikka.subscribers.push(
        new TopicListener(
            'postinumeroChange',
            (received) => received.detail === htmlIdSuffix,
            (control, otherVal) => {
              const postitoimipaikkaFn: (val: string) => string = this.laheteService.getStoredValue('postitoimipaikkaFn');
              if (postitoimipaikkaFn) {
                const val = postitoimipaikkaFn(otherVal);
                if (val) {
                  control.setValue(val, this._emitOpts);
                } else {
                  control.setValue(null, this._emitOpts);
                }
              }
            }
        ));
  }
}

export enum TilaajaValue {
  ELAINLAAKARI = "elainlaakari",
  OMISTAJA = "omistaja",
  MUU = "muu"
}
