import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDate, NgbDateParserFormatter, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { ElaintietoResponse } from "../../touko-lahete.service";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'nautarekisterihaku-modal',
  template: `
    <div class="modal-header">
      <h1 class="modal-title" attribute="sahkoelain.valitseElain">Lisää eläin</h1>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Peruuta')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="form-group">
                   <label [htmlFor]="'searchTermInput'" attribute="sahkoelain.hakuKaksoispiste"> </label> <input class="form-control" type="text" name="searchTerm" id="searchTermInput" [(ngModel)]="searchTerm"/>
                    <label attribute="sahkoelain.rajaaNautaHakua" id="rajaaNautaHakuaDate"> </label>
                       <nautarekisteri-date (dateRange)="setElainDateRange($event)"></nautarekisteri-date>
                <div class="form-group form-inline">
                  <span attribute="sahkoelain.valitutElaimetLkm">Lisättyjä eläimiä:</span> <span> &nbsp;{{valitutElaimet.length }}</span>
                </div>
                <div class="form-group form-inline"> 
  <button attribute="sahkoelain.valitseKaikkiElaimet"  id="nayteModalValitseKaikki" type="button" class="btn btn-primary" (click)="valitseKaikkiElaimet()">Valitse kaikki</button>
    <button attribute="sahkoelain.poistaKaikkiElaimet" [disabled]="valitutElaimet.length === 0" id="nayteModalTyhjennaKaikki" type="button" class="btn btn-secondary" (click)="poistaKaikkiElaimet()">Tyhjennä</button>
                </div>

                  <table class="table table-striped table-hover table-sm">
                    <thead>
                    <tr>
                      <th attribute="sahkoelain.euTunnus" scope="col">EU-tunnus</th>
                      <th attribute="sahkoelain.tunniste" scope="col">Tunniste</th>
                      <th attribute="sahkoelain.korva" scope="col">Korva</th>
                      <th attribute="sahkoelain.syntymaaika" scope="col">Syntymäaika</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let i = index; let elain of elaimet$ | async" id="nautarekisterihakuModalRow{{this.i}}" (click)="valitseElain(elain)" [class.text-muted]="this.isElainAiemminValittu(elain)" [class.table-active]="this.isElainValittu(elain)"
                    [class.table-]>
                      <td><ngb-highlight [result]="elain.euTunnus" [term]="searchTerm"></ngb-highlight></td>
                      <td><ngb-highlight [result]="elain.nimi" [term]="searchTerm"></ngb-highlight></td>
                      <td><ngb-highlight [result]="elain.korva" [term]="searchTerm"></ngb-highlight></td>
                      <td><ngb-highlight result="{{elain.syntymaPvm | date:'d.M.yyyy' }}" [term]="searchTerm"></ngb-highlight></td>
                    </tr>
                    </tbody>
                  </table>
                <ngb-pagination
                  [maxSize]="5" [collectionSize]="(lkm$ | async)!" [(page)]="page" [pageSize]="pageSize">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button attribute="sivupalkki.modalPeruuta" type="button" id="nayteModalPeruuta" class="btn btn-light" (click)="activeModal.dismiss('Peruuta')">Peruuta</button>
        <button attribute="sahkoelain.nayteModalValitse" id="nayteModalValitse" type="button" class="btn btn-primary" (click)="submit()">Hyväksy</button>
      </div>
    </form>
  `
})
export class NautarekisterihakuModalComponent implements OnInit {
  valitutElaimet: ElaintietoResponse[] = [];
  pitopaikanElaimet: ElaintietoResponse[] = [];
  aiemminValitutElaimet: ElaintietoResponse[] = [];
  datepicker: NgbDatepicker;

  private _search$ = new Subject<void>();
  private _elaimet$ = new BehaviorSubject<ElaintietoResponse[]>([]);
  private _lkm$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: '',
    searchRange: { fromDate: null, toDate: null }
  };

  static compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

  constructor(public activeModal: NgbActiveModal, public formatter: NgbDateParserFormatter) { }

  get elaimet$() {
 return this._elaimet$.asObservable(); 
}
  get lkm$() {
 return this._lkm$.asObservable(); 
}
  get page() {
 return this._state.page; 
}
  get pageSize() {
 return this._state.pageSize; 
}
  get searchTerm() {
 return this._state.searchTerm; 
}

  set page(page: number) {
 this._set({ page }); 
}
  set searchTerm(searchTerm: string) {
 this._set({ searchTerm }); 
}

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { pageSize, page, searchTerm, searchRange } = this._state;
    let elaimet = this.pitopaikanElaimet?.filter(elain => this.matches(elain, searchTerm, searchRange));
    const lkm = elaimet?.length;
    elaimet = elaimet?.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ elaimet, lkm, searchRange });
  }

  ngOnInit(): void {
    this._search$.pipe(
      switchMap(() => this._search()))
      .subscribe(r => {
        this._elaimet$.next(r.elaimet);
        this._lkm$.next(r.lkm);
      });
    this._search$.next();
  }



  matches(elain: ElaintietoResponse, term: string, searchRange: NautaDateRange): boolean {
    if (Object.entries(searchRange).every(([key, value]) => value === null || '')) {
      return this.searchElaimetWithSearchterm(term, elain);
    } else {
      return this.searchElaimetWithSearchterm(term, elain) && this.syntymaaikaInRange(elain.syntymaPvm, searchRange);
    }
  }

  syntymaaikaInRange = (syntymapaiva: string, range: NautaDateRange): boolean => {
    const syntymapvmAsNgbDate = NgbDate.from(this.formatter.parse(syntymapaiva));
    const syntymapvmMatchesRangeDate = syntymapvmAsNgbDate.equals(range.fromDate) || syntymapvmAsNgbDate.equals(range.toDate);
    if (Object.entries(range).every(([key, value]) => value !== null || '')) {
      return syntymapvmMatchesRangeDate || !syntymapvmAsNgbDate.before(range.fromDate) && !syntymapvmAsNgbDate.after(range.toDate);
    } else {
      return (syntymapvmMatchesRangeDate || syntymapvmAsNgbDate.after(range.fromDate) || syntymapvmAsNgbDate.before(range.toDate));
    }
  };

  searchElaimetWithSearchterm(term: string, elain: ElaintietoResponse): boolean {
    return elain.nimi?.toLowerCase().includes(term.toLowerCase())
      || elain.euTunnus?.toLowerCase().includes(term.toLowerCase())
      || elain.korva?.toLowerCase().includes(term.toLowerCase())
      || elain.syntymaPvm?.includes(term.toLowerCase());
  }

  setElainDateRange(dateRange: any) {
    this._set({ searchRange: dateRange });
  }

  isElainAiemminValittu(elain: ElaintietoResponse): boolean {
    return this.aiemminValitutElaimet.some(elaimet => elain.euTunnus === elaimet.euTunnus);
  }

  isElainValittu(elain: ElaintietoResponse): boolean {
    return this.valitutElaimet.some(elaimet => elain.euTunnus === elaimet.euTunnus);
  }

  valitseElain(elain: ElaintietoResponse) {
    if (this.isElainAiemminValittu(elain)) {
      return;
    }

    const isElainAlreadySelected = this.isElainValittu(elain);
    if (isElainAlreadySelected) {
      this.poistaElain(elain);
    } else {
      this.valitutElaimet = [...this.valitutElaimet, elain];
    }
  }

  poistaElain(elain: ElaintietoResponse) {
    const valittuIndex = this.valitutElaimet.findIndex(elaimet => elain.euTunnus === elaimet.euTunnus);
    const newElainList = [...this.valitutElaimet.slice(0, valittuIndex), ...this.valitutElaimet.slice(valittuIndex + 1)];
    this.valitutElaimet = newElainList;
  }

  poistaKaikkiElaimet() {
    this.valitutElaimet = this.aiemminValitutElaimet;
  }

  valitseKaikkiElaimet() {
    this.valitutElaimet = this.pitopaikanElaimet;
  }

  submit() {
    this.activeModal.close(this.valitutElaimet);
  }

}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  searchRange: NautaDateRange | null;
}

interface SearchResult {
  elaimet: ElaintietoResponse[];
  lkm: number;
}

interface NautaDateRange  {
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
}

