import {AccountModalCloseEvent, AccountModalComponent} from "../toimipaikka-asetukset/account-modal.component";
import {Teksti} from "../../utils/teksti";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PalveluService} from "../../sidebar/palvelu.service";
import {MessageService} from "../../message/message.service";
import {Router} from "@angular/router";
import {Component, Input} from "@angular/core";

@Component({
  selector: 'vaihda-toimipaikka',
  template: `<button [ngClass]='styles' (click)='openAccount()' [id]="htmlId" [attribute]='title'>Muokkaa</button>`
})
export class VaihdaToimipaikkaComponent {

  @Input() title = 'kayttajatili.ilmoitusMuokkaa';
  @Input() styles = "btn btn-outline-primary";
  @Input() redirectUrl = ['asiointi', 'etusivu'];
  @Input() htmlId = "account-vaihda-tiedot"

  constructor(private readonly modalService: NgbModal,
              private readonly palveluService: PalveluService,
              private readonly messageService: MessageService,
              private readonly router: Router) {
  }
  openAccount() {
    const modalRef = this.modalService.open(AccountModalComponent, {size: 'lg'});
    modalRef.result.then(res => {
      const closeEvent = res as AccountModalCloseEvent;
      if (closeEvent.asiaRedirectURL) {
        this.palveluService.isUusiIlmoitusKeskenerainen()
          .then(isKeskenerainen => {
            if (isKeskenerainen) {
              this.messageService.notify({
                message: new Teksti("Siirrytään etusivulle", "siirrytaanEtusivulle", "kayttajatili"),
                type: 'info'
              });
              this.router.navigate(['asiointi', 'etusivu']);
            } else {
              this.router.navigate([closeEvent.asiaRedirectURL]);
            }
          });
      } else {
        this.router.navigate(this.redirectUrl);
      }
    }, _reason => {});
  }
}
