import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BrowserModule} from "@angular/platform-browser";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SyoteGroupComponent} from "./touko-syote-group.component";
import {SyoteLayoutComponent} from "./syote/syote-layout.component";
import {LomakeService} from "./touko-lomake.service";
import {YesNoComponent} from "./syote/custom/touko-yes-no.component";
import {YesNoSelectComponent} from "./syote/custom/touko-yes-no-select.component";
import {RehuvuosiilmoitusComponent} from "./vuosiilmoitus/rehuvuosiilmoitus/rehuvuosiilmoitus.component";
import {RehuvuosiilmoitusTemplateComponent} from "./vuosiilmoitus/rehuvuosiilmoitus/rehuvuosiilmoitustemplate.component";
import {PerustiedotComponent} from "./perustiedot/perustiedot.component";
import {PerustiedotLaskutustiedotComponent} from "./perustiedot/laskutustiedot.component";
import {LannoitevuosiilmoitusComponent} from "./vuosiilmoitus/lannoitevuosiilmoitus/lannoitevuosiilmoitus.component";
import {LannoitevuosiilmoitusTemplateComponent} from "./vuosiilmoitus/lannoitevuosiilmoitus/lannoitevuosiilmoitustemplate.component";
import {RehuValinnaisetComponent} from "./syote/custom/touko-rehuvalinnaiset.component";
import {AccountService} from "../account/account.service";
import {HttpClientModule} from "@angular/common/http";
import {MessageModule} from "../message/message.module";
import {MessageService} from "../message/message.service";
import {RekisteriLopetusComponent} from "./rekisteri-lopetus/rekisteri-lopetus.component";
import {SidebarModule} from "../sidebar/sidebar.module";
import {AttributeModule} from "../tiira/attribute.module";
import {LiiteComponent} from "./syote/custom/touko-liite.component";
import {RehutuotteetComponent} from "./syote/custom/touko-rehutuotteet.component";
import {LannoiteRekisteriUusiComponent} from "./lannoite-rekisteri-uusi/lannoite-rekisteri-uusi.component";
import {LuomulupaComponent} from "./luomu-poikkeus9c/luomulupa.component";
import {LuomuluvatInputComponent} from "./luomu-poikkeus9c/luomuluvat-input.component";
import {CheckboxTextComponent} from "./syote/custom/touko-checkbox-text.component";
import {LiitePvmComponent} from "./syote/custom/touko-liite-pvm.component";
import {TarkastusComponent} from "./tarkastus/tarkastus.component";
import {LoaderModule} from "../loader/loader.module";
import {LoaderService} from "../loader/loader.service";
import {PeruslohkoComponent} from "./syote/groups/touko-peruslohko.component";
import {PeruslohkoLannoiterekisteriComponent} from "./syote/groups/touko-peruslohko-lannoiterekisteri.component";
import {ToukoInputsModule} from "../touko-inputs/touko-inputs.module";
import {LaskutustiedotComponent} from "./syote/groups/touko-laskutustiedot.component";
import {LaskutustiedotLannoiterekisteriComponent} from "./syote/groups/touko-laskutustiedot-lannoiterekisteri.component";
import {RouterModule} from "@angular/router";
import {UtilsModule} from "../utils/utils.module";
import {TarkastusLopetusComponent} from "./tarkastus/tarkastus-lopetus.component";
import {LomakeTapahtumatComponent} from "../lomake-tapahtumat/lomake-tapahtumat.component";
import {TarkastusPDFService} from "./tarkastus/tarkastus-pdf.service";
import {KasvinterveysRekisteriComponent} from "./kate-rekisteri/kasvinterveys-rekisteri.component";
import {ToimintaCardComponent} from "./kate-rekisteri/toiminta-card.component";
import {ToimintaAlertComponent} from "./kate-rekisteri/toiminta-alert.component";
import {LomakeErrorComponent} from "./lomake-error.component";
import {RekisteritCardComponent} from "./kate-rekisteri/rekisterit-card.component";
import {RekisteritService} from "./kate-rekisteri/rekisterit.service";
import {Luomupoikkeus9cV2Component} from './luomu-poikkeus9c/luomupoikkeus9c-v2.component';
import {Luomupoikkeus9cV2Data} from './luomu-poikkeus9c/luomupoikkeus9c-v2-data';
import {ToukoKasvilajiMonivalintaComponent} from './syote/custom/touko-kasvilaji-monivalinta.component';
import {ToukoKasvilajiValintaComponent} from './syote/custom/touko-kasvilaji-valinta.component';
import {ToukoKasvilajikeValintaComponent} from './syote/custom/touko-kasvilajike-valinta.component';
import {YhteenvetoModule} from "./yhteenveto/yhteenveto.module";
import {ToukoMonitekstiComponent} from './syote/custom/touko-moniteksti.component';
import {ArkistodokumentitComponent} from './arkistodokumentit/arkistodokumentit.component';
import {LomakeBaseService} from "./lomake-base.service";
import {LomakeLayoutModule} from "./lomake-layout.module";
import {CommonModule} from "@angular/common";
import {LomakeHakuService} from "./lomake-haku.service";
import {SiemenpakkaamoComponent} from "./siemenpakkaamo/siemenpakkaamo.component";
import {LaheteHakuService} from "./lahete-haku.service";
import {ToukoComponentsModule} from "../touko-components/touko-components.module";
import {TuotetiedotTyyppinimiComponent} from "./lannoite-rekisteri-uusi/tuotetiedot-tyyppinimi/tuotetiedot-tyyppinimi.component";
import {SummaryModule} from "./summary/summary.module";
import {PerustiedotYhteyshenkilotComponent} from "./perustiedot/yhteyshenkilot.component";
import {LomakeHttpService} from "./vuosiilmoitus/common/lomake-base/lomake-http.service";
import {ExcelLatausComponent} from "./syote/custom/touko-excel-lataus.component";
import {TarkastusV2Component} from "./tarkastus/tarkastus-v2.component";
import {RehuvuosiilmoitusData} from "./vuosiilmoitus/rehuvuosiilmoitus/rehuvuosiilmoitus-data";
import {LataaTemplateComponent} from "./vuosiilmoitus/common/lataa-template.component";
import {LomakeErrorService} from "./lomake-error.service";
import {LomakevirhekasittelyDirective} from "./vuosiilmoitus/common/lomakevirhekasittely.directive";
import {LannoitevuosiilmoitusData} from "./vuosiilmoitus/lannoitevuosiilmoitus/lannoitevuosiilmoitus-data";
import {RehuvalvontaV2Component} from "./vuosiilmoitus/rehuvalvonta/rehuvalvonta-v2.component";
import {RehuvalvontaV2Data} from "./vuosiilmoitus/rehuvalvonta/rehuvalvonta-v2-data";
import {RehutoimintaComponent} from "./vuosiilmoitus/rehuvalvonta/rehutoiminta.component";
import {LiiteListComponent} from "../touko-inputs/liitteet-component/liite-list.component";
import {LiiteLatausComponent} from "../touko-inputs/liitteet-component/liite-lataus.component";
import {SiemenrekisteriV2Component} from "./vuosiilmoitus/siemenrekisteri/siemenrekisteri-v2.component";
import {LomakeEventPublisherService} from "./vuosiilmoitus/common/lomake-base/lomake-event-publisher.service";
import {LomakeDataResolver} from "./vuosiilmoitus/common/lomake-base/lomake-v2-data";
import {SiemenrekisteriV2Data} from "./vuosiilmoitus/siemenrekisteri/siemenrekisteri-v2-data.service";
import {LannoiterekisteriV2Component} from "./vuosiilmoitus/lannoiterekisteri/lannoiterekisteri-v2.component";
import {LannoiterekisteriV2Data} from "./vuosiilmoitus/lannoiterekisteri/lannoiterekisteri-v2-data";
import {TuotetiedotTyyppinimiV2Component} from "./lannoite-rekisteri-uusi/tuotetiedot-tyyppinimi/tuotetiedot-tyyppinimi-v2.component";
import {LiitePvmComponentV2} from "../touko-inputs/liitteet-component/liite-pvm-v2.component";
import {SiemenpakkaamoV2Component} from "./vuosiilmoitus/pakkaamolupahakemus/siemenpakkaamo-v2.component";
import {SiemenpakkaamoV2Data} from "./vuosiilmoitus/pakkaamolupahakemus/siemenpakkaamo-v2.data";
import {RekisteriLopetusV2Component} from "./vuosiilmoitus/rekisteri-lopetus/rekisteri-lopetus-v2.component";
import {RekisteriLopetusV2Data} from "./vuosiilmoitus/rekisteri-lopetus/rekisteri-lopetus-v2-data";
import {FilterDetailsPipe} from "./vuosiilmoitus/pakkaamolupahakemus/filter-details-pipe";
import {LaakeRehuLatausComponent} from "./syote/custom/touko-laakerehu-lataus.component";
import {VirkailijaModule} from "./virkailija/virkailija.module";
import {LuomuvalvontaComponent} from "./vuosiilmoitus/luomuvalvonta/luomuvalvonta.component";
import {LuomuvalvontaData} from "./vuosiilmoitus/luomuvalvonta/luomuvalvonta-data";
import {SiirtymavaiheenLyhennysComponent} from "./vuosiilmoitus/luomuvalvonta/siirtymavaiheen-lyhennys.component";
import {KasvituotteetComponent} from "./vuosiilmoitus/luomuvalvonta/kasvituotteet.component";
import {PeltolohkoListComponent} from "./vuosiilmoitus/luomuvalvonta/peltolohkot/peltolohko-list.component";
import {PeltolohkoModalComponent} from "./vuosiilmoitus/luomuvalvonta/peltolohkot/peltolohko-modal.component";
import {KasvinterveysrekisteriV2Component} from "./vuosiilmoitus/kasvinterveysrekisteri/kasvinterveysrekisteri-v2.component";
import {ToimintaCardV2Component} from "./vuosiilmoitus/kasvinterveysrekisteri/toiminta-card-v2.component";
import {PoistaPeltolohkoModalComponent} from "./vuosiilmoitus/luomuvalvonta/peltolohkot/poista-peltolohko-modal.component";
import {ToiminnanKohdeComponent} from "./vuosiilmoitus/luomuvalvonta/toiminnan-kohde.component";
import {PeltolohkoTiedot} from "./vuosiilmoitus/luomuvalvonta/peltolohkot/peltolohko-tiedot";
import {TarkastusLopetusV2Component} from "./tarkastus/tarkastus-lopetus-v2.component";
import {KasvinterveysrekisteriV2Data} from "./vuosiilmoitus/kasvinterveysrekisteri/kasvinterveysrekisteri-v2-data";
import {TilapaisestiPoisKaytostaComponent} from "./vuosiilmoitus/lannoitevuosiilmoitus/tilapaisesti-pois-kaytosta.component";
import {TuotantosuunnatComponent} from "./vuosiilmoitus/luomuvalvonta/tuotantosuunnat.component";
import {ElaintuotantoComponent} from "./vuosiilmoitus/luomuvalvonta/elaintuotanto/elaintuotanto.component";
import {TilanElaimetComponent} from "./vuosiilmoitus/luomuvalvonta/elaintuotanto/tilan-elaimet.component";
import {ElaintuotteetComponent} from "./vuosiilmoitus/luomuvalvonta/elaintuotanto/elaintuotteet.component";
import {LuomuvalvontaAloitusLiitteetComponent} from "./vuosiilmoitus/luomuvalvonta/luomuvalvonta-aloitus-liitteet.component";
import {ElaintuotannonPoikkeuslupaComponent} from "./vuosiilmoitus/luomuvalvonta/elaintuotanto/elaintuotannon-poikkeuslupa.component";


/**
 * Created by Seppo on 15/11/2017.
 */

@NgModule({
  declarations: [
    SyoteGroupComponent,
    LomakeErrorComponent,
    SyoteLayoutComponent,
    YesNoComponent,
    YesNoSelectComponent,
    RehutuotteetComponent,
    RehuValinnaisetComponent,
    LannoitevuosiilmoitusComponent,
    LiiteComponent,
    ExcelLatausComponent,
    LaakeRehuLatausComponent,
    CheckboxTextComponent,
    LiitePvmComponent,
    LiitePvmComponentV2,
    TarkastusComponent,
    TarkastusLopetusComponent,
    TarkastusV2Component,
    LomakeTapahtumatComponent,
    PeruslohkoComponent,
    PeruslohkoLannoiterekisteriComponent,
    LaskutustiedotComponent,
    LaskutustiedotLannoiterekisteriComponent,
    ToimintaCardComponent,
    ToimintaAlertComponent,
    RekisteritCardComponent,
    LuomulupaComponent,
    LuomuluvatInputComponent,
    ToukoKasvilajiMonivalintaComponent,
    ToukoMonitekstiComponent,
    ToukoMonitekstiComponent,
    LomakevirhekasittelyDirective,
    RehuvalvontaV2Component,
    RehutoimintaComponent,
    LiiteListComponent,
    LiiteLatausComponent,
    FilterDetailsPipe,
    KasvituotteetComponent,
    PeltolohkoListComponent,
    PeltolohkoModalComponent,
    ToimintaCardV2Component,
    PoistaPeltolohkoModalComponent,
    ToiminnanKohdeComponent,
    PeltolohkoTiedot,
    TilapaisestiPoisKaytostaComponent,
    TilanElaimetComponent,
    ElaintuotannonPoikkeuslupaComponent,
    ElaintuotantoComponent,
    ElaintuotteetComponent,
    LuomuvalvontaAloitusLiitteetComponent,

    // palvelut
    RehuvuosiilmoitusComponent,
    RehuvuosiilmoitusTemplateComponent,
    LannoiteRekisteriUusiComponent,
    LannoitevuosiilmoitusComponent,
    LannoitevuosiilmoitusTemplateComponent,
    Luomupoikkeus9cV2Component,
    RekisteriLopetusComponent,
    KasvinterveysRekisteriComponent,
    ToukoKasvilajiValintaComponent,
    ToukoKasvilajikeValintaComponent,
    ArkistodokumentitComponent,
    SiemenpakkaamoComponent,
    TuotetiedotTyyppinimiComponent,
    TuotetiedotTyyppinimiV2Component,
    PerustiedotComponent,
    PerustiedotLaskutustiedotComponent,
    PerustiedotYhteyshenkilotComponent,
    LataaTemplateComponent,
    SiemenrekisteriV2Component,
    LannoiterekisteriV2Component,
    SiemenpakkaamoV2Component,
    LannoiterekisteriV2Component,
    RekisteriLopetusV2Component,
    LuomuvalvontaComponent,
    SiirtymavaiheenLyhennysComponent,
    KasvinterveysrekisteriV2Component,
    TarkastusLopetusV2Component,
    TuotantosuunnatComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    LomakeLayoutModule,
    UtilsModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    HttpClientModule,
    MessageModule,
    SidebarModule,
    AttributeModule,
    ToukoInputsModule,
    YhteenvetoModule,
    ToukoComponentsModule,
    SummaryModule,
    VirkailijaModule
  ],
  exports: [
    // palvelut
    RehuvuosiilmoitusComponent,
    RehuvuosiilmoitusTemplateComponent,
    PerustiedotComponent,
    LannoiteRekisteriUusiComponent,
    LannoitevuosiilmoitusTemplateComponent,
    Luomupoikkeus9cV2Component,
    KasvinterveysRekisteriComponent,
    RekisteriLopetusComponent,
    TarkastusComponent,
    LomakeTapahtumatComponent,
    SiemenpakkaamoComponent,
    PerustiedotLaskutustiedotComponent,
    PerustiedotYhteyshenkilotComponent,
    LataaTemplateComponent,
    SiemenrekisteriV2Component
  ],
  providers: [
    FormBuilder,
    {provide: LomakeBaseService, useClass: LomakeService},
    LomakeErrorService,
    LomakeHakuService,
    LaheteHakuService,
    AccountService,
    LoaderService,
    MessageService,
    TarkastusPDFService,
    RekisteritService,
    LomakeHttpService,
    LomakeEventPublisherService,
    RehuvuosiilmoitusData,
    RehuvalvontaV2Data,
    LannoitevuosiilmoitusData,
    SiemenrekisteriV2Data,
    LannoiterekisteriV2Data,
    Luomupoikkeus9cV2Data,
    RekisteriLopetusV2Data,
    SiemenpakkaamoV2Data,
    LomakeDataResolver,
    LuomuvalvontaData,
    KasvinterveysrekisteriV2Data
  ]
})
export class ToukoLomakeModule {
}
