import {Injectable} from "@angular/core";
import {Account, Asetukset, KayttajaRooli, TOIMIALA_TEKSTI} from "./account";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Yhteyshenkilo} from "../touko-lomake/syote/syote-utils";
import {ENV_SESSION_STORAGE_KEY} from "../../environments/environment.service";

/**
 * Created by Seppo on 02/10/2017.
 *
 * Handles user related data.
 */
@Injectable()
export class AccountService {

  private readonly _account = new BehaviorSubject<Account>(null);
  account$ = this._account.asObservable();

  constructor(private readonly http: HttpClient) {}

  cleanUp(): void {
    this._account.next(null);
  }

  getAccountDetails(): Promise<Account> {
    const url = `/api/v1/${ReadAccount.getRole()}/account`;

    return this.http.get(url)
      .toPromise()
      .then(response => {
        const account = response as Account;
        return this.setAccount(account);
      })
      .catch((error: any) =>
        // Jos tilitietojen haku epäonnistuu (kirjautumisen jälkeen)
        // käsitellään se spesifisesti (route-guard + virhesivu)
         this.setAccount(null)
      );
  }

  getCurrentAccount(): Account {
    return this._account.getValue();
  }

  isViranomainen(): boolean {
    return Account.isViranomainen(this.getCurrentAccount());
  }

  isElyViranomainen(): boolean {
    return Account.isElyViranomainen(this.getCurrentAccount());
  }

  isAsiakas(): boolean {
    return Account.isAsiakas(this.getCurrentAccount());
  }

  isMaatilaToimija(): boolean {
    return Account.isMaatila(this.getCurrentAccount());
  }

  isYritysToimija(): boolean {
    return this.getCurrentAccount().kayttajaRooli === KayttajaRooli.ASIAKAS_YRITYS;
  }

  isHenkiloToimija(): boolean {
    return this.getCurrentAccount().kayttajaRooli === KayttajaRooli.ASIAKAS_HENKILO;
  }

  setAccountAsetukset(asetukset: Asetukset) {
    const url = `/api/v1/${ReadAccount.getRole()}/account`;
    return this.http.put(url, asetukset)
      .toPromise()
      .then(response => {
        const account = response as Account;
        return this.setAccount(account);
      });
  }

  setAccountYhteyshenkilot(yhteyshenkilot: Yhteyshenkilo[], ilmoitukset: boolean) {
    const url = `api/v1/${ReadAccount.getRole()}/account/yhteyshenkilot`;
    return this.http.put(url, yhteyshenkilot, {params: {ilmoitukset: `${ilmoitukset}`}})
      .toPromise()
      .then(response => {
        const yhResponse = response as Yhteyshenkilo[];
        this.updateAccountWithYhteyshenkilot(yhResponse, ilmoitukset);
      });
  }

  private setAccount(response: Account) {
    const account = response;
    if (response && response.toimiala) {
      account.toimialaTeksti = TOIMIALA_TEKSTI[response.toimiala];
    }
    if (account && account.yhteyshenkilot) {
      account.yhteyshenkilot = this.sortYhteyshenkilot(account.yhteyshenkilot);
    }
    return this.updateAccountObservable(account);
  }

  private updateAccountWithYhteyshenkilot(yhteyshenkilot: Yhteyshenkilo[], ilmoitukset: boolean) {
    const account = this._account.getValue();
    account.yhteyshenkilot = this.sortYhteyshenkilot(yhteyshenkilot);
    account.ilmoitukset = ilmoitukset;
    return this.updateAccountObservable(account);
  }

  private updateAccountObservable(account: Account) {
    this._account.next(account);
    sessionStorage.setItem("currentAccount", JSON.stringify(account));
    return account;
  }

  private sortYhteyshenkilot(yhteyshenkilot: Yhteyshenkilo[]) {
    if (yhteyshenkilot instanceof Array) {
      return [...yhteyshenkilot].sort((a, b) => a.nimi > b.nimi ? 1 : -1);
    }
    return yhteyshenkilot;
  }
}

export class ReadAccount {
  static getAccount(): Account {
    return JSON.parse(sessionStorage.getItem("currentAccount")) as Account;
  }

  static getRole(): string {
    const asetukset = localStorage.getItem(ENV_SESSION_STORAGE_KEY);
    const role = JSON.parse(asetukset)?.role;
    return role ? role : "as";
  }
}
