import {Injectable} from "@angular/core";
import {
  YhteenvetoCompareGroup,
  YhteenvetoCompareGroupDict,
  YhteenvetoCompareItem,
  YhteenvetoGroup
} from "../yhteenveto-utils";
import {Teksti} from "../../../utils/teksti";

@Injectable()
export class YhteenvetoVertailuService {

  compareLomakeWithToiminta(showCompareYhteenveto, lomakeYhteenveto: YhteenvetoGroup[], toimintaYhteenveto: YhteenvetoGroup[]): YhteenvetoCompareGroup[] {
    const groupTitles: Map<string, Teksti> = new Map();
    const lomakeYhteenvetoDict = this.createYhteenvetoDict(groupTitles, lomakeYhteenveto);
    const toimintaYhteenvetoDict = showCompareYhteenveto ? this.createYhteenvetoDict(groupTitles, toimintaYhteenveto) : {};
    const allKeys = this.getAllKeys(lomakeYhteenvetoDict, toimintaYhteenvetoDict);

    const yhteenveto = [];
    allKeys.forEach(((value, key) => {
      const lomakeGroupItems = this.getValue(lomakeYhteenvetoDict, key);
      const toimintaGroupItems = this.getValue(toimintaYhteenvetoDict, key);

      const compareGroup = new YhteenvetoCompareGroup();
      compareGroup.title = groupTitles.get(key);

      const compareItems: YhteenvetoCompareItem[] = [];

      value.forEach(subKey => {
        const currentValues = this.getValue(lomakeGroupItems, subKey);
        const prevValues = this.getValue(toimintaGroupItems, subKey);

        compareItems.push(new YhteenvetoCompareItem(currentValues, prevValues));
      });

      compareGroup.data = compareItems;
      yhteenveto.push(compareGroup);
    }));

    return yhteenveto;
  }

  private getAllKeys(currentLomake: YhteenvetoCompareGroupDict, currentToiminta: YhteenvetoCompareGroupDict): Map<string, Set<string>> {
    const allKeys = new Map<string, Set<string>>();
    Object.keys(currentLomake).forEach(key => {
      allKeys.set(key, new Set(Object.keys(currentLomake[key])));
    });

    Object.keys(currentToiminta).forEach(key => {
      if (allKeys.has(key)) {
        const item: Set<string> = allKeys.get(key);
        Object.keys(currentToiminta[key]).forEach(t => item.add(t));
        allKeys.set(key, item);
      } else {
        allKeys.set(key, new Set(Object.keys(currentToiminta[key])));
      }
    });

    return allKeys;
  }

  private createYhteenvetoDict(groupTitles, yhteenvetoToCompare: YhteenvetoGroup[]): YhteenvetoCompareGroupDict {
    const yhteenvetoDict = {};
    yhteenvetoToCompare.forEach(group => {
      const itemDict = {};
      group.data.forEach(item => {
        let id = item.htmlId;
        // handle viewing several attachments ESS-1750
        if (item.downloadLink !== null) {
          id = id + item.downloadLink.id;
        }
        itemDict[id] = item;
      });
      yhteenvetoDict[group.title.key] = itemDict;
      groupTitles.set(group.title.key, group.title);
    });

    return yhteenvetoDict;
  }


  private getValue(dict, subKey) {
    return dict && dict.hasOwnProperty(subKey) ? dict[subKey] : null;
  }
}
