import {Field, FieldArray, FieldControl, FieldGroup, SummaryItem} from "ngx-fielding";
import {LiiteSummaryComponent} from "../../../summary/liite-summary.component";
import {Validators} from "@angular/forms";

export const liiteArray = (groupTitle, liiteHtmlId, liiteLabel?) => {
  liiteLabel = liiteLabel || groupTitle;
  return new FieldArray(
      Field.of({label: groupTitle, htmlId: liiteHtmlId}),
      liiteFieldGroupFn(liiteLabel, liiteHtmlId)
  );
}

export const liitePakollinenArray = (groupTitle, liiteHtmlId, liiteLabel?, errorMessage?) => {
  liiteLabel = liiteLabel || groupTitle;
  return new FieldArray(
      Field.of({label: groupTitle, htmlId: liiteHtmlId, errorMessages: {'required': errorMessage}}),
      liiteFieldGroupFn(liiteLabel, liiteHtmlId),
      [],
      Validators.required
  );
}

const liiteFieldGroupFn = (label, htmlId) => {
  return () => new FieldGroup(
      Field.of({
        label: label,
        summaryFn: group => new SummaryItem<any>(label, group.value, {show: group.value}, LiiteSummaryComponent),
        htmlId: htmlId
      }),
      {
        lomakeId: new FieldControl(Field.build()),
      tiedostotyyppi: new FieldControl(Field.build()),
      tiedostonimi: new FieldControl(Field.build()),
      id: new FieldControl(Field.build()),
      valvontakohde: new FieldControl(Field.build())
    }
  )
}
