import {Teksti} from "../../utils/teksti";

export enum RekisteriOrOikeus {
  REKISTERI,
  OIKEUS
}

export class RekisteriResponse {
  rekisteriClientId: string;
  status: string;
}

export class Rekisteri {
  teksti: Teksti;
  order: number;
  type: RekisteriOrOikeus;
  status: string;
}
