import {Injectable} from "@angular/core";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
  AsiakasResponse,
  LiitetiedostoResponse,
  LokitietoResponse,
  LomakeJSON,
  LomakeJSONOld,
  LomakeKasittely,
  LomakeRequest,
  LomakeResponse
} from "./touko-lomake-utils";
import {PalveluService} from "../sidebar/palvelu.service";
import {Perustiedot, Yhteyshenkilo} from "./syote/syote-utils";
import {LoaderService} from "../loader/loader.service";
import {AccountService} from "../account/account.service";
import {Teksti} from "../utils/teksti";
import {LomakeBaseService} from "./lomake-base.service";
import {LomakeRedirectService} from "./lomake-redirect.service";
import {LomakeHakuService} from "./lomake-haku.service";
import {LomakeDataContent} from "./lomake-data";
import {SyoteInterface, SyoteType} from "./syote/syote-interface";
import {first} from "rxjs/operators";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";
import {LomakeErrorService} from "./lomake-error.service";


/**
 * Created by Seppo on 15/11/2017.
 *
 * Servicen avulla voidaan hakea yksittäistä lomaketta koskevat tiedot
 * ja esitäytetyt kentät sekä lähettää täytetty lomakeValues palvelinsovellukselle.
 */
@Injectable()
export class LomakeService extends LomakeBaseService {

  baseAsiakasURL = '/api/v1/as/lomakkeet/ilmoitukset';
  baseViranomainenURL = '/api/v1/vk/lomakkeet/ilmoitukset';

  private readonly _currentLomake = new BehaviorSubject<LomakeResponse>(null);
  currentLomake$ = this._currentLomake.asObservable();

  private readonly _isLahetetty = new BehaviorSubject<boolean>(false);
  isLahetetty$ = this._isLahetetty.asObservable();

  constructor(protected readonly http: HttpClient,
              protected readonly lomakeErrorService: LomakeErrorService,
              private readonly sidebarService: PalveluService,
              private readonly accountService: AccountService,
              private readonly loaderService: LoaderService,
              private readonly lomakeRedirectService: LomakeRedirectService,
              private readonly lomakeHakuService: LomakeHakuService) {
    super();
  }

  public allowRedirect() {
    this.lomakeRedirectService.canRedirect = true;
  }

  public blockRedirect() {
    this.lomakeRedirectService.canRedirect = false;
  }

  cleanUp(): void {
    this.lomakeErrorService.cleanUp();
    this._currentLomake.next(null);
    this._isLahetetty.next(null);
  }

  createLomakeRecursiveProcessing(lomakeData: LomakeDataContent): FormGroup {
    const groups = {};
    Object.entries(lomakeData).forEach(([key, group]) => {
      const syotteet = {};
      group.data.forEach(syote => {
        syotteet[syote.name] = this.createSyoteFromChildRecursive(syote);
      });
      groups[key] = new FormGroup(syotteet, group.validators);
    });
    return new FormGroup(groups);
  }

  createLomake(lomakeData: LomakeDataContent): FormGroup {
    const groups = {};
    Object.entries(lomakeData).forEach(([key, group]) => {
      const syotteet = {};
      group.data.forEach(syote => {
        syotteet[syote.name] = this.createSyoteFromChild(syote);
        if (syote.type === SyoteType.CONTROL && syote.children) {
          syote.children.forEach(child => syotteet[child.name] = this.createSyoteFromChild(child));
        }
      });
      groups[key] = new FormGroup(syotteet, group.validators);
    });
    return new FormGroup(groups);
  }

  private createSyoteFromChild(child: SyoteInterface): AbstractControl {
    if (child.control instanceof AbstractControl) {
      return child.control;
    }
    let control;
    switch (child.type) {
      case SyoteType.CONTROL:
        const [formstate, validatorOrOpts] = this.resolveControlOpts(child);
        control = new FormControl(formstate, validatorOrOpts);
        break;
      case SyoteType.ARRAY:
        control = new FormArray(this.createSyoteFromChildren(child.children));
        break;
    }
    return control;
  }

  private createSyoteFromChildRecursive(child: SyoteInterface): AbstractControl {
    if (child.control instanceof AbstractControl) {
      return child.control;
    }

    let control;
    switch (child.type) {
      case SyoteType.CONTROL:
        const [formstate, validatorOrOpts] = this.resolveControlOpts(child);
        if (child?.children?.length > 0) {
          const syotechildren = {};
          child.children.forEach(syote => {
            syotechildren[syote.name] = this.createSyoteFromChildRecursive(syote);
          });
          control = new FormGroup(syotechildren, validatorOrOpts);
        } else {
          control = new FormControl(formstate, validatorOrOpts);
        }
        break;
      case SyoteType.ARRAY:
        control = new FormArray(this.createSyoteFromChildren(child.children));
        break;
    }

    return control;
  }


  private createSyoteFromChildren(children: SyoteInterface[] | { [key: string]: SyoteInterface }[]): AbstractControl[] {
    return children.map(syote => {
      if (syote && !syote.length) {
        const group = {};
        Object.entries(syote).forEach(([key, control]) => {
          group[key] = this.createSyoteFromChild(control as SyoteInterface);
        });
        return new FormGroup(group);
      }

      return this.createSyoteFromChild(syote);
    });
  }

  private resolveControlOpts(child: SyoteInterface) {

    if (child.control && (child.control?.value || child.control?.disabled || child.control?.validators)) {
      return [child.control, child.control?.validators];
    } else if (child.control && child.control[0] !== undefined) {
      return [child.control[0], child.control[1]];
    }
    return child.control !== null ? [child.control, null] : [null, null];
  }

  checkCanSubmitLomake(form: FormGroup) {
    this._isLahetetty.next(true);
    return form.status === "VALID";
  }

  /**
   * Lähettää lomakkeen palvelinsovellukselle
   *
   * @param requestBody - lomakkeen tiedot
   * @param toimintotyyppi
   * @param asia - lomakkeen asia
   * @param kesken - lomakkeen tallennustila
   * @returns - Promise vastaus
   */
  sendLomakeToV1Endpoint(requestBody: LomakeRequest,
                         toimintotyyppi: string,
                         asia: string,
                         kesken: boolean): Promise<LomakeResponse> {
    let url;
    if (this.isHakemus(toimintotyyppi)) {
      url = `api/v1/as/lomakkeet/${toimintotyyppi}/${asia}?kesken=${kesken.toString()}`;
    } else {
      url = `api/v1/as/lomakkeet/ilmoitukset/${toimintotyyppi}/${asia}?kesken=${kesken.toString()}`;
    }

    return this.sendLomake(requestBody, url);
  }

  tallennaLomake(requestBody: LomakeRequest, toimintotyyppi: string, asia: string, id = 0) {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    const tyyppi = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppi);
    if (id === 0) {

      const url = `api/v2/${role}/lomakkeet/${tyyppi}/${asia}/${toimintotyyppi}/tallenna`;
      return this.processResponse(this.http.post(url, requestBody));
    } else {
      const url = `api/v2/${role}/lomakkeet/${tyyppi}/${asia}/${toimintotyyppi}/${id}/tallenna`;
      return this.processResponse(this.http.put(url, requestBody));
    }
  }

  lahetaLomake(requestBody: LomakeRequest, toimintotyyppi: string, asia: string, id = 0) {
    const tyyppi = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppi);
    if (id === 0) {
      const url = `api/v2/as/lomakkeet/${tyyppi}/${asia}/${toimintotyyppi}/laheta`;
      return this.processResponse(this.http.post(url, requestBody));
    } else {
      const url = `api/v2/as/lomakkeet/${tyyppi}/${asia}/${toimintotyyppi}/${id}/laheta`;
      return this.processResponse(this.http.put(url, requestBody));
    }
  }

  /**
   * Päivittää lomakkeen tiedot
   *
   * @param requestBody - lomakkeen tiedot
   * @param id - lomakkeen id
   * @param toimintotyyppi
   * @param asia - lomakkeen asia
   * @param kesken - lomakkeen tallennustila
   * @returns - Promise vastaus
   */
  updateLomake(requestBody: LomakeRequest, id: number, toimintotyyppi: string, asia: string, kesken: boolean): Promise<LomakeResponse> {
    const tyyppi = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppi);
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    const params = {kesken: kesken.toString()};
    const url = `api/v1/${role}/lomakkeet/${tyyppi}/${id}/${toimintotyyppi}/${asia}`;
    return this.http.put(url, requestBody, {params})
        .toPromise()
        .then(responseData => responseData as LomakeResponse)
        .then(response => {
          this.updateLomakeObservables(response);
          return this.createResponse(response);
        });
  }

  /**
   * Lähettää toiminnan lopetusta koskevan lomakkeen
   *
   * @param lopetusPvm - Lopetuspäivämäärä
   * @param asia - lopetettava toiminta
   * @returns
   */
  sendLopetusLomake(lopetusPvm: string, asia: string): Promise<LomakeResponse> {
    const body = {body: JSON.stringify(lopetusPvm)};
    const url = `/api/v1/as/lomakkeet/ilmoitukset/lopetus/${asia}`;
    return this.http.post(url, body)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          this.updateLomakeObservables(response);
          return this.createResponse(response);
        });
  }

  isHakemus(toimintotyyppi: string): boolean {
    return toimintotyyppi === "hakemus";
  }

  sendYhteenveto(lomakeId: number, file: File, asVirkailija: boolean): Promise<any> {
    const asioijaUrlPart = asVirkailija ? "vk" : "as";
    const url = `api/v1/${asioijaUrlPart}/lomakkeet/ilmoitukset/${lomakeId}/yhteenveto`;
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('content-type', 'multipart/*');
    formData.append('liite', file);
    return this.http.post(url, formData, {headers})
        .toPromise()
        .then(response => response);
  }

  /**
   * Vaihtaa lomakkeen muokkaajan.
   *
   * @param id - Lomakkeen id
   * @param lomake - Lomakkeen tiedot
   * @returns
   */
  updateViimeksiMuokannutAsiakas(id: number, lomake?: LomakeResponse): Promise<LomakeResponse> {
    if (LomakeEndpointUtil.shouldUseV2Endpoint(lomake.asia, `v${lomake.lomaketyyppiVersio}`)) {
      return super.updateViimeksiMuokannutAsiakasV2(id, lomake.asia, String(lomake.toimintotyyppi).toLowerCase())
          .then(r => this.updateLomakeObservables(r));
    } else {
      return super.updateViimeksiMuokannutAsiakas(id)
          .then(r => this.updateLomakeObservables(r));
    }
  }

  private createResponse(data: LomakeResponse): LomakeResponse {
    this.sidebarService.updatePalvelut();
    return data;
  }

  /**
   * Luo lomakkeesta JSON-objektin, joka lähetetään palvelimelle
   *
   * @param data - lomakekentät
   * @param liite - liitetiedostot
   * @param perustiedot - perustietokenttä
   * @param yhteyshenkilot - yhteyshenkilöt
   * @returns - Lähetettävä JSON-objekti
   */
  public createLomakeRequest(data: LomakeJSONOld | LomakeJSON, liite: {
    [s: string]: LiitetiedostoResponse[]
  }, perustiedot: Perustiedot, yhteyshenkilot: Yhteyshenkilo[]): LomakeRequest {
    return {
      body: JSON.stringify(data),
      liitteet: this.convertLiitetiedostoObject(liite),
      perustiedot,
      yhteyshenkilot
    } as LomakeRequest;
  }

  /**
   * Hakee yksittäisen lomakkeen. Ottaa huomioon käyttäjäroolin.
   *
   * @param id - Lomakkeen id
   * @returns Lomake
   */
  getLomake(id: number): Promise<LomakeResponse> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    return this.http.get(`/api/v1/${role}/lomakkeet/ilmoitukset/${id}`)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          this._currentLomake.next(response);
          return response;
        });
  }

  getLomakeV2Viranomainen(id: number, asia: string, toimintotyyppi: string): Promise<LomakeResponse> {
    const toimintotyyppiLC = toimintotyyppi.toLowerCase();
    const urlRoot = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppiLC);
    return this.http.get(`/api/v2/vk/lomakkeet/${urlRoot}/${asia}/${toimintotyyppiLC}/${id}`)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          this._currentLomake.next(response);
          return response;
        });
  }

  getLomakeV2Asiakas(id: number, asia: string, toimintotyyppi: string, kesken: boolean = false): Promise<LomakeResponse> {
    const toimintotyyppiLC = toimintotyyppi.toLowerCase();
    const urlRoot = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppiLC);
    return this.http.get(`/api/v2/as/lomakkeet/${urlRoot}/${asia}/${toimintotyyppiLC}/${id}`, {params: {isKesken: kesken}})
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          this._currentLomake.next(response);
          return response;
        });
  }

  fetchLomake(url: string, params = {}, updateCurrent = true) {
    return this.http.get(url, params)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          if (updateCurrent) {
            this._currentLomake.next(response);
          }
          return response;
        });
  }

  getToiminnanTiedotViranomainen(id: number): Promise<LomakeResponse> {
    return this.http.get(`/api/v1/vk/lomakkeet/ilmoitukset/${id}/toiminta`)
        .toPromise()
        .then(data => data as LomakeResponse);
  }

  getToiminnanTiedotViranomainenV2(id: number, asia: string, toimintotyyppi: string): Promise<LomakeResponse> {
    return this.http.get(`/api/v2/vk/lomakkeet/${LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppi)}/${asia}/${toimintotyyppi.toLowerCase()}/${id}/toiminta`)
        .toPromise()
        .then(data => data as LomakeResponse);
  }

  getMuokattavaLomake(id: number): Promise<LomakeResponse> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    this._isLahetetty.next(false);
    const url = `/api/v1/${role}/lomakkeet/ilmoitukset/${id}`;
    return this.fetchLomake(url, {params: {muokattava: 'true'}});
  }

  getMuokattavaLomakeVersio2(id: number, asia: string, toimintotyyppi: string): Promise<LomakeResponse> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    const tyyppi = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppi);
    const url = `/api/v2/${role}/lomakkeet/${tyyppi}/${asia}/${toimintotyyppi}/${id}`;
    this._isLahetetty.next(false);
    return this.fetchLomake(url, {params: {isKesken: true}});
  }

  getMuutoslomakeTiedot(asia: string, toimintotyyppi: string, updateCurrent = true): Promise<LomakeResponse> {
    this._isLahetetty.next(false);

    let url;
    if (this.isHakemus(toimintotyyppi)) {
      url = `/api/v1/as/lomakkeet/hakemus/${asia}`;
    } else {
      url = `/api/v1/as/lomakkeet/ilmoitukset/muutos/${asia}`;
    }

    return this.fetchLomake(url, updateCurrent);
  }

  getMuutoslomakeTiedotV2(asia: string, toimintotyyppi: string, updateCurrent = true): Promise<LomakeResponse> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    this._isLahetetty.next(false);
    const url = `/api/v2/${role}/lomakkeet/${LomakeEndpointUtil.resolveLomaketyyppiByAsia(asia)}/${asia}/${toimintotyyppi.toLowerCase()}/`;

    return this.fetchLomake(url, {}, updateCurrent);
  }

  /**
   * Vaihtaa lomakkeen käsittelijän. Lomake otetaan käsittelyyn.
   *
   * @param id - Lomakkeen id
   * @returns
   */
  updateViimeksiMuokannutViranomainen(id: number): Promise<LomakeResponse> {
    return this.currentLomake$.pipe(first()).toPromise().then(lomake => {
      if (LomakeEndpointUtil.shouldUseV2Endpoint(lomake.asia, lomake.lomaketyyppiVersio)) {
        return super.updateViimeksiMuokannutViranomainenV2(id, lomake.asia, String(lomake.toimintotyyppi).toLowerCase());
      } else {
        return super.updateViimeksiMuokannutViranomainen(id);
      }
    }).then(response => this.updateLomakeObservables(response));
  }

  kasitteleLomake(kasittely: LomakeKasittely): Promise<LomakeResponse> {
    this.loaderService.startLoadingAnimation(new Teksti("käsitellään lomaketta", "loaderLomakeKasittely", "lomakeYleinen"));
    return this.http.post(`/api/v1/vk/lomakkeet/ilmoitukset/${kasittely.id}`, kasittely)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          this.loaderService.stopLoadingAnimation();
          return this.updateLomakeObservables(response);
        });
  }

  kasitteleLomakeV2(kasittely: LomakeKasittely, asia: string, toimintotyyppi: string): Promise<LomakeResponse> {
    const toimintotyyppiLC = toimintotyyppi.toLowerCase();
    const urlRoot = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppiLC);
    this.loaderService.startLoadingAnimation(new Teksti("käsitellään lomaketta", "loaderLomakeKasittely", "lomakeYleinen"));
    return this.http.post(`/api/v2/vk/lomakkeet/${urlRoot}/${asia}/${toimintotyyppiLC}/${kasittely.id}`, kasittely)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => {
          this.loaderService.stopLoadingAnimation();
          return this.updateLomakeObservables(response);
        });
  }

  otaLomakeUudelleenkasittelyyn(id: number): Promise<LomakeResponse> {
    return this.http.put(`/api/v1/vk/lomakkeet/ilmoitukset/${id}/uusikasittely`, null)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => this.updateLomakeObservables(response));
  }

  otaLomakeUudelleenkasittelyynV2(id: number, asia: string, toimintotyyppi: string): Promise<LomakeResponse> {
    const toimintotyyppiLC = toimintotyyppi.toLowerCase();
    const urlRoot = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppiLC);
    return this.http.put(`/api/v2/vk/lomakkeet/${urlRoot}/${asia}/${toimintotyyppiLC}/${id}/uusikasittely`, null)
        .toPromise()
        .then(data => data as LomakeResponse)
        .then(response => this.updateLomakeObservables(response));
  }

  getLomakeVersiot(id: any) {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    return this.http.get(`api/v1/${role}/lomakkeet/ilmoitukset/${id}/versiot`)
        .toPromise()
        .then(data => data as LomakeResponse[]);
  }

  getAsiakkaat(ytunnus: string): Promise<AsiakasResponse[]> {
    return this.http.get(`api/v1/vk/asiakkaat/${ytunnus}`)
        .toPromise()
        .then(data => data as AsiakasResponse[]);
  }

  validatePerustiedot(form: FormGroup): boolean {
    return [
      form.get('perustiedot'),
      form.get('yhteyshenkilot'),
      form.get('laskutustiedot'),
      form.get('toiminnanAloitus')
    ].filter(item => item !== null && item.enabled)
        .map(item => item.valid)
        .every(v => Boolean(v));
  }

  updateLomakeObservables(response: LomakeResponse): LomakeResponse {
    this.lomakeHakuService.updateLomakkeet(response);
    this._currentLomake.next(response);
    return response;
  }

  getLokitiedot(): Promise<LokitietoResponse[]> {
    return this.http.get('/api/v1/vk/lokitiedot')
        .toPromise()
        .then(response => response as LokitietoResponse[]);
  }

  kuittaaLokitieto(lokitieto: LokitietoResponse): Promise<boolean> {
    return this.http.delete(`/api/v1/vk/lokitiedot/${lokitieto.id}`)
        .toPromise()
        .then(() => true);
  }

  private processResponse(httpObservable: Observable<Object>) {
    return httpObservable
        .toPromise()
        .then(responseData => responseData as LomakeResponse)
        .then(response => {
          this.updateLomakeObservables(response);
          return this.createResponse(response);
        });
  }

  private sendLomake(requestBody: LomakeRequest, url: string): Promise<LomakeResponse> {
    return this.http.post(url, requestBody)
        .toPromise()
        .then(responseData => responseData as LomakeResponse)
        .then(response => {
          this.updateLomakeObservables(response);
          return this.createResponse(response);
        });
  }

  private convertLiitetiedostoObject(liitetiedostot: {
    [s: string]: LiitetiedostoResponse[]
  }): LiitetiedostoResponse[] {
    liitetiedostot = !Boolean(liitetiedostot) ? {} : liitetiedostot;
    let liiteList = [];
    for (const key in liitetiedostot) {
      if (liitetiedostot.hasOwnProperty(key)) {
        liiteList = liiteList.concat(liitetiedostot[key]);
      }
    }
    return liiteList;
  }

  createMallipohja(_baseLomakeId: number, _mallipohjanimi: string): Promise<any> {
    return Promise.resolve(undefined);
  }
}
