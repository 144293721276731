import {InitValueType, QuestionControl, QuestionGroup, QuestionInitValue, TopicListener} from "ee-lahete";
import {AbstractAsiakasGroup, TilaajaValue} from "./abstract-asiakas-group";
import {AsiakastietoValidators} from "../../validators/asiakastieto-validators";
import {GroupBuilder} from "../group-build-options";
import {Validators} from "@angular/forms";


export class TilaajaGroup extends AbstractAsiakasGroup {

  questionGroupKey = 'tilaaja';
  isValidEmail = {
    validators: [AsiakastietoValidators.isValidEmail, Validators.required],
    errorMessages: {
      [AsiakastietoValidators.EMAIL]: this.getLabel("Virheellinen sähköpostiosoite."),
      required: this.getLabel("Kenttä on pakollinen")
    }
  };
  isValidPhonenumber = {
    validators: [AsiakastietoValidators.isValidPhonenumber, Validators.required],
    errorMessages: {
      [AsiakastietoValidators.PHONE]: this.getLabel("Virheellinen puhelinnumero."),
      required: this.getLabel("Kenttä on pakollinen")
    }
  };

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'tilaaja'})): QuestionGroup {

    const tilaajaTyyppiGroup = new QuestionGroup({
      key: 'tilaajaTyyppi',
      groupComponentType: 'nested',
      label: this.getLabel('tilaajaOn'),
      controls: [
        this.createRadioInput('tilaajaTyyppi', this.getLabel('asiakasElainlaakari'), TilaajaValue.ELAINLAAKARI),
        this.createRadioInput('tilaajaTyyppi', this.getLabel('asiakasOmistaja'), TilaajaValue.OMISTAJA),
        this.createRadioInput('tilaajaTyyppi', this.getLabel('asiakasMuu'), TilaajaValue.MUU)
      ]
    });

    const htmlIdSuffix = 'Tilaaja';
    const tilaajaHenkilotiedotControls = this.createHenkilotiedotControls(htmlIdSuffix);
    tilaajaHenkilotiedotControls.unshift(this.createElainlaakariTunnusControl(htmlIdSuffix));
    const tilaajaHenkilotiedotGroup = new QuestionGroup({
      key: 'henkilotiedot',
      groupComponentType: 'nested',
      label: this.getLabel('asiakasHenkilotiedot'),
      controls: tilaajaHenkilotiedotControls,
      filters: TilaajaGroup.createTilaajaTyyppiFilterAnyValueTrue()
    });

    const tilaajaLisatiedotGroup = this.createLisatietoGroupForTilaaja(htmlIdSuffix);
    tilaajaLisatiedotGroup.filters = TilaajaGroup.createTilaajaTyyppiFilterAnyValueTrue();

    const yhteyshenkiloControls = this.createYhteyshenkiloControls(`Yhteyshenkilo${htmlIdSuffix}`);
    const yhteyshenkiloGroup = new QuestionGroup({
      key: 'yhteyshenkilo',
      groupComponentType: 'nested',
      label: this.getLabel('yhteyshenkilo'),
      controls: yhteyshenkiloControls,
      filters: TilaajaGroup.createTilaajaTyyppiFilterAnyValueTrue()
    });

    const group = new QuestionGroup({
      key: this.questionGroupKey,
      label: this.getLabel('tilaaja'),
      groupComponentType: 'nested',
      groups: [
        tilaajaTyyppiGroup,
        tilaajaHenkilotiedotGroup,
        yhteyshenkiloGroup,
        tilaajaLisatiedotGroup
      ],
      validators: [
        AsiakastietoValidators.hasOnlyOneMaksaja
      ],
      errorMessages: {
        [AsiakastietoValidators.MAKSAJA]: this.getLabel("errMaksaja")
      },
      subscribers: [
        new TopicListener(
          'maksajaChange',
          (topic) => topic.detail !== htmlIdSuffix,
          (control, msg) => {
            control.updateValueAndValidity({onlySelf: true, emitEvent: false});
          }
        )
      ]
    });
    this.applyPostitoimipaikkaDisabler(tilaajaHenkilotiedotGroup);

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.tilaaja), InitValueType.PATCH));
    }

    return group;
  }

  private createElainlaakariTunnusControl(htmlIdSuffix: string): QuestionControl {
    const elainlaakariTunnusControl = this.createElainlaakariInput(htmlIdSuffix);
    elainlaakariTunnusControl.filters = TilaajaGroup.createTilaajaTyyppiFilterEquals(TilaajaValue.ELAINLAAKARI);

    return  elainlaakariTunnusControl;
  }
}
