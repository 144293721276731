import {Component, Input, OnInit} from "@angular/core";
import {LomakeBaseService} from "../../touko-lomake/lomake-base.service";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'question-error',
  template: `
    <ng-container *ngIf="checkCondition()">
      <div class="text-danger" *ngFor="let errMsg of getErrors()">
        <small [attribute]="errMsg">{{errMsg}}</small>
      </div>
    </ng-container>
  `
})
export class QuestionErrorComponent implements OnInit {

  @Input() readonly fullPath: string;
  @Input() readonly sivuRef: string;
  @Input() readonly control: AbstractControl;
  @Input() readonly errorMessages: {[s: string]: string};
  @Input() conditions = [];
  isSent: boolean;

  constructor(private readonly toukoLaheteService: LomakeBaseService) {
  }

  ngOnInit(): void {
    this.toukoLaheteService.isLahetetty$.subscribe(val => this.isSent = val);
  }

  public checkCondition() {
      const displayErrors = this.conditions.every(c => c) && this.control.invalid && (this.control.touched || this.isSent);
      if (this.control.valid) {
        this.toukoLaheteService.setVirhe(this.sivuRef, this.fullPath, false);
      }
      return displayErrors;
  }

  public getErrors() {
    const errors = this.control.invalid && this.control.errors ?
      Object.keys(this.control.errors).map(errKey => this.errorMessages[errKey]) :
      [];

    this.toukoLaheteService.setVirhe(this.sivuRef, this.fullPath, errors.length > 0);
    return errors;
  }

}
