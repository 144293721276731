import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";
import {MatcherModel, ModelFragment, Topic, TopicListener} from "../matcher/matcher-model";
import {QuestionGroup} from "./question-group";
import {QuestionControl} from "./question-control";
import {QuestionArray} from "./question-array";

export interface QuestionOptions {
  controlType?: string;
  groupComponentType?: string;
  parent?: ModelFragment;
  groups?: QuestionGroup[];
  controls?: QuestionControl[];
  array?: QuestionArray;
  questions?: ModelFragment[];
  value?: any;
  initialValue?: any;
  htmlId?: string;
  key: string;
  fullPath?: string;
  label?: string;
  placeholder?: string;
  validators?: ValidatorFn[];
  errorMessages?: {[s: string]: string};
  filters?: {[s: string]: MatcherModel};
  disablers?: {[s: string]: MatcherModel};
  modifiers?: {[s: string]: MatcherModel};
  aggregators?: {[s: string]: MatcherModel};
  options?: {[s: string]: any};
  actions?: {[s: string]: (...params: any) => void};
  publishers?: Topic[];
  subscribers?: TopicListener[];
  lateRegisterMatchers?: boolean;
}

export enum QuestionUpdateType {
  ADD,
  UPDATE,
  REMOVE
}

export class QuestionUpdate {
  path: string;
  key: string;
  updateType: QuestionUpdateType;
  question: ModelFragment;
  control: AbstractControl;

  /**
   * Tiedot lomakkeen ja sen mallin päivittämiseen.
   *
   * @param path - polku lomakkeen kontrolliin ja mallin komponenttiin
   * @param key - lomakkeen kontrollin `form(Array|Group|Control)Name` ja mallin `Question.key`
   * @param updateType - päivitystyyppi: ADD, UPDATE, REMOVE
   * @param question - uusi malli (kun `updateType === ADD || UPDATE`)
   * @param control - uusi lomakkeen kontrolli
   */
  constructor(path: string, key: string, updateType: QuestionUpdateType, question: ModelFragment = null, control: AbstractControl = null) {
    this.path = path;
    this.key = key;
    this.updateType = updateType;
    this.question = question;
    this.control = control;
  }
}

export abstract class QuestionComponent {
  abstract questionData: QuestionComponentData;
}

export class QuestionComponentData {
  label: string;
  placeholder: string;
  htmlId: string;
  controlValue?: string;
  formControlName?: string;
  group?: FormGroup;
  readonly question: ModelFragment;
  readonly modifierValues;

  constructor(question: ModelFragment, formGroup: FormGroup = null) {
    this.question = question;
    this.modifierValues = {value: question.value, initialValue: question.initialValue};
    this.label = question.label;
    this.placeholder = question.placeholder;
    this.htmlId = question.htmlId;
    this.controlValue = question.value;
    this.formControlName = question.key;
    this.group = formGroup;
  }
}

export class QuestionInitValue {
  modelFragment: ModelFragment;
  value: any;
  initType: InitValueType;

  constructor(modelFragment: ModelFragment, value: any, initType = InitValueType.SET) {
    this.modelFragment = modelFragment;
    this.value = value;
    this.initType = initType;
  }
}

export enum InitValueType {
  SET,
  PATCH
}

export class QuestionInitMatcher {
  modelFragment: ModelFragment;
  matchers: {[path: string]: MatcherModel};

  constructor(modelFragment: ModelFragment, matcher: {[path: string]: MatcherModel}) {
    this.modelFragment = modelFragment;
    this.matchers = matcher;
  }
}

