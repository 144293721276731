import {Component, forwardRef, Input} from "@angular/core";
import {CheckboxControlValueAccessor, ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "touko-checkbox",
  template: `
    <div [ngClass]="[marginBottom, labelDescrPositionInline ? 'd-flex align-items-center' : '']">
      <div class="d-flex align-items-center" [ngClass]="{'input-disabled': disabled}">
        <input class="form-checkbox" type="checkbox" [id]="htmlId" [checked]="checked" [disabled]="disabled"
               [attr.name]="name" (change)="toggle($event.target.checked)">
        <label class="ml-3 mb-0" [htmlFor]="htmlId" [attribute]="labelAttribute" [id]="htmlId + '-label'"></label>
      </div>
      <label *ngIf="labelDescrAttribute" [htmlFor]="htmlId" [attribute]="labelDescrAttribute"
             [id]="htmlId + '-labelDescription'"
             class="mb-0 small text-muted font-italic"
             [ngClass]="labelDescrPositionInline ? 'ml-2' : 'ml-5 pl-1'"></label>
    </div>
  `
  ,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    }
  ]})
export class CheckboxComponent extends CheckboxControlValueAccessor implements ControlValueAccessor {

  @Input() name: string;
  @Input() disabled: boolean;
  @Input() htmlId: string;
  @Input() labelAttribute: string;
  @Input() labelDescrAttribute: string;
  @Input() labelDescrPositionInline = false;
  @Input() value: string;
  @Input() checked: boolean;
  @Input() marginBottom: string = 'mb-4';

  toggle(value) {
    this.writeValue(value);
    this.onTouched();
  }

  writeValue(value: any) {
    super.writeValue(value);
    this.checked = value;
  }

  setDisabledState(disabled: boolean) {
    super.setDisabledState(disabled);
    this.disabled = disabled;
  }
}
