import {Component, OnInit} from "@angular/core";
import {ControlContainer, Validators} from "@angular/forms";
import {AbstractField, FieldArray, FieldGroup} from "ngx-fielding";
import {LiitetiedostoResponse} from "../../../touko-lomake-utils";
import {AutoUnsubscribe} from "../../../../utils/auto-unsubscribe.decorator";
import {LiitetiedostoLomakeService} from "../../../liitetiedosto-lomake.service";
import {LiitetiedostoPalvelinService} from "../../../touko-liitetiedosto.service";

@Component({
  selector: 'tilan-elaimet',
  template: `
    <div [formGroup]="tilanElaimetGroup" class="mb-6">
      <h3 attribute="luomu.tilanElaimet"></h3>
      <touko-alert-box>
        <p attribute="luomu.tilanElaimetOhje1" class="mb-0"></p>
        <p attribute="luomu.tilanElaimetOhje2" class="mb-0"></p>
      </touko-alert-box>

      <div class="row mt-4">
        <label id="luonnonmukainenLkm" attribute="luomu.luonnonmukainenLkm" class="offset-4 col-3"></label>
        <label id="tavanomainenLkm" attribute="luomu.tavanomainenLkm" class="col-3"></label>
      </div>
      <div *ngFor="let elain of tilanElaimetFiltered"
           [formGroup]="elain.asGroup()"
           class="row align-items-center">
        <div class="col-md-4 col-12">
          <touko-checkbox
              [htmlId]="elain.get('elainValittu').field.htmlId"
              [labelAttribute]="elain.get('elainValittu').field.label"
              formControlName="elainValittu">
          </touko-checkbox>
        </div>
        <div class="col-md-3 col-12">
          <touko-text-input
              [htmlId]="'luonnonmukainen' + elain.get('elainValittu').field.htmlId"
              labelledById="luonnonmukainenLkm"
              formControlName="luonnonmukainenLkm">
          </touko-text-input>
        </div>
        <div class="col-md-3 col-12">
          <touko-text-input
              [htmlId]="'tavanomainen' + elain.get('elainValittu').field.htmlId"
              labelledById="tavanomainenLkm"
              formControlName="tavanomainenLkm">
          </touko-text-input>
        </div>
      </div>

      <div [formGroup]="mehilaispesaGroup" class="row align-items-center">
        <div class="col-md-4 col-12">
          <touko-checkbox
              htmlId="mehilaispesa"
              formControlName="elainValittu"
              labelAttribute="luomu.mehilaispesa">
          </touko-checkbox>
        </div>
        <div class="col-md-3 col-12">
          <touko-text-input
              htmlId="luonnonmukainenmehilaispesa"
              formControlName="luonnonmukainenLkm"
              labelledById="luonnonmukainenLkm">
          </touko-text-input>
        </div>
        <div class="col-md-3 col-12">
          <touko-text-input
              htmlId="tavanomainenmehilaispesa"
              formControlName="tavanomainenLkm"
              labelledById="tavanomainenLkm">
          </touko-text-input>
        </div>
      </div>
      <div *ngIf="mehilaispesaGroup.get('elainValittu').value" class="col-11 align-liitteet">
        <label id="mehilaistarhojenSijaintiLiiteLabel"
               attribute="luomu.mehilaistarhojenSijaintiLiiteLabel"
               class="required"></label>
        <liite-list htmlId="mehilaistarhojenSijaintiLiite"
                    labelledBy="mehilaistarhojenSijaintiLiiteLabel"
                    [lomakeId]="lomakeId"
                    (liiteOut)="setLiitteet($event, 'sijaintiJaPitopaikat')"
                    [liitteetArray]="mehilaispesaGroup.get('sijaintiJaPitopaikat').asArray()"></liite-list>
        <validation-error
            [field]="mehilaispesaGroup.get('sijaintiJaPitopaikat').asArray()"></validation-error>

        <label id="saastumislahteidenArviointiLiiteLabel"
               attribute="luomu.saastumislahteidenArviointiLiiteLabel"
               class="required"></label>
        <liite-list htmlId="saastumislahteidenArviointiLiite"
                    labelledBy="saastumislahteidenArviointiLiiteLabel"
                    [lomakeId]="lomakeId"
                    (liiteOut)="setLiitteet($event, 'saastumislahteidenArviointi')"
                    [liitteetArray]="mehilaispesaGroup.get('saastumislahteidenArviointi').asArray()"></liite-list>
        <validation-error
            [field]="mehilaispesaGroup.get('saastumislahteidenArviointi').asArray()"></validation-error>
      </div>

      <div class="row mt-4">
        <label id="muuElain" attribute="luomu.muuElain" class="col-4"></label>
        <label id="muuElainLuonnonmukainenLkm" attribute="luomu.luonnonmukainenLkm" class="col-3"></label>
        <label id="muuElainTavanomainenLkm" attribute="luomu.tavanomainenLkm" class="col-3"></label>
      </div>

      <div *ngFor="let muuElain of muutElaimetArray.controlFields; let i = index"
           [formGroup]="muuElain.asGroup()"
           class="row">
        <div class="col-md-4 col-12">
          <touko-text-input
              [htmlId]="'muuElain' + i"
              formControlName="nimi"
              labelledById="muuElain">
          </touko-text-input>
        </div>
        <div class="col-md-3 col-12">
          <touko-text-input
              [htmlId]="'muuElainluonnonmukainenLkm' + i"
              formControlName="luonnonmukainenLkm"
              labelledById="muuElainLuonnonmukainenLkm">
          </touko-text-input>
        </div>
        <div class="col-md-3 col-12">
          <touko-text-input
              [htmlId]="'muuElainTavanomainenLkm' + i"
              formControlName="tavanomainenLkm"
              labelledById="muuElainTavanomainenLkm">
          </touko-text-input>
        </div>
        <button [id]="'muuElainPoista-' + i" type="button" toukobutton class="btn-outline-primary mb-4"
                (click)="removeMuuElain(i)">
          <i class="fas fa-trash" aria-hidden="true"></i>
          <span attribute="teksti.poista"></span>
        </button>
      </div>
      <button id="lisaaMuuElain" type="button" toukobutton class="btn-secondary" (click)="addMuuElain()">
        <i class="fas fa-plus-square" aria-hidden="true"></i>
        <span attribute="teksti.uusi"></span>
      </button>
    </div>
  `,
  styles: [`
    .align-liitteet {
      margin-left: 2.3rem;
      padding-left: 0;
      padding-right: 1.7rem;
    }
  `]
})

@AutoUnsubscribe
export class TilanElaimetComponent implements OnInit {
  lomakeId: number;

  constructor(private controlContainer: ControlContainer,
              private liitetiedostoLomakeService: LiitetiedostoLomakeService,
              private liitetiedostoPalvelinService: LiitetiedostoPalvelinService) {
  }

  get tilanElaimetGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get tilanElaimetFiltered(): AbstractField[] {
    const elaimetGroup = this.tilanElaimetGroup.controlFields;
    return Object.keys(elaimetGroup)
        .filter(key => key !== 'mehilaispesa' && key !== 'muutElaimet')
        .map(key => (elaimetGroup[key]));
  }

  get mehilaispesaGroup(): FieldGroup {
    return this.tilanElaimetGroup.get('mehilaispesa').asGroup();
  }

  get sijaintiJaPitopaikat(): FieldArray {
    return this.mehilaispesaGroup.get('sijaintiJaPitopaikat').asArray();
  }

  get saastumislahteidenArviointi(): FieldArray {
    return this.mehilaispesaGroup.get('saastumislahteidenArviointi').asArray();
  }

  get muutElaimetArray(): FieldArray {
    return this.tilanElaimetGroup.get('muutElaimet').asArray();
  }

  ngOnInit() {
    this.getLomakeId();
    this.updateTilanElaimetControls();
    this.updateMehilaispesaControls();
  }

  getLomakeId(): void {
    this.liitetiedostoLomakeService.lomakeId.subscribe(id => {
      this.lomakeId = id;
    })
  }

  updateTilanElaimetControls() {
    this.tilanElaimetFiltered.forEach(tilanElain => {
      const elainValittuControl = tilanElain.get('elainValittu')
      const luonnonmukainenLkmControl = tilanElain.get('luonnonmukainenLkm')
      const tavanomainenLkmControl = tilanElain.get('tavanomainenLkm')

      if (elainValittuControl.value) {
        luonnonmukainenLkmControl.enable();
        tavanomainenLkmControl.enable();
      } else {
        luonnonmukainenLkmControl.disable();
        tavanomainenLkmControl.disable();
      }

      elainValittuControl.valueChanges.subscribe(checked => {
        if (checked) {
          luonnonmukainenLkmControl.enable();
          tavanomainenLkmControl.enable();
        } else {
          luonnonmukainenLkmControl.disable();
          tavanomainenLkmControl.disable();
        }
      })
    })
  }

  updateMehilaispesaControls() {
    const mehilaispesaValittu = this.mehilaispesaGroup.get('elainValittu');
    const mehilaispesaLuonnonmukainenLkmControl = this.mehilaispesaGroup.get('luonnonmukainenLkm');
    const mehilaispesaTavanomainenLkmControl = this.mehilaispesaGroup.get('tavanomainenLkm');

    if (mehilaispesaValittu.value) {
      this.enableControls([mehilaispesaLuonnonmukainenLkmControl, mehilaispesaTavanomainenLkmControl]);
    } else {
      this.disableControls([mehilaispesaLuonnonmukainenLkmControl, mehilaispesaTavanomainenLkmControl]);
      this.clearValidatorsAndErrors(this.sijaintiJaPitopaikat);
      this.clearValidatorsAndErrors(this.saastumislahteidenArviointi);
    }

    mehilaispesaValittu.valueChanges.subscribe(checked => {
      if (checked) {
        this.enableControls([mehilaispesaLuonnonmukainenLkmControl, mehilaispesaTavanomainenLkmControl]);
        this.updateValidatorsForArrays(this.sijaintiJaPitopaikat);
        this.updateValidatorsForArrays(this.saastumislahteidenArviointi);
      } else {
        this.disableControls([mehilaispesaLuonnonmukainenLkmControl, mehilaispesaTavanomainenLkmControl]);
        this.clearValidatorsAndErrors(this.sijaintiJaPitopaikat);
        this.clearValidatorsAndErrors(this.saastumislahteidenArviointi);

        this.deleteLiitetiedostot();
      }
    })
  }

  enableControls(controls: AbstractField[]) {
    controls.forEach(control => control.enable())
  }

  disableControls(controls: AbstractField[]) {
    controls.forEach(control => control.disable())
  }

  updateValidatorsForArrays(arrayControl) {
    if (arrayControl.length === 0) {
      arrayControl.setValidators(Validators.required);
      arrayControl.setErrors({'required': true});
    } else {
      this.clearValidatorsAndErrors(arrayControl);
    }
  }

  clearValidatorsAndErrors(control) {
    control.setValidators(null);
    control.setErrors(null);
  }

  addMuuElain() {
    this.muutElaimetArray.push(this.muutElaimetArray.buildField());
  }

  removeMuuElain(i: number) {
    this.muutElaimetArray.removeAt(i);

    if (!this.muutElaimetArray.length) {
      this.muutElaimetArray.push(this.muutElaimetArray.buildField());
    }
  }

  setLiitteet(evt: LiitetiedostoResponse[], liiteAvain) {
    this.liitetiedostoLomakeService.setLiite(evt, liiteAvain);
  }

  deleteLiitetiedostot() {
    let promiseChain = Promise.resolve();

    if (this.sijaintiJaPitopaikat.length) {
      const liiteId = this.sijaintiJaPitopaikat.controlFields[0].asGroup().controls.id.value
      promiseChain = promiseChain.then(() => this.liitetiedostoPalvelinService.deleteLiitetiedosto(liiteId)
          .then(_ => {
            this.setLiitteet(this.sijaintiJaPitopaikat.value, 'sijaintiJaPitopaikat')
            this.sijaintiJaPitopaikat.controls = [];
            this.sijaintiJaPitopaikat.setValue([]);
          })
      )
    }

    if (this.saastumislahteidenArviointi.length) {
      const liiteId = this.saastumislahteidenArviointi.controlFields[0].asGroup().controls.id.value
      promiseChain = promiseChain.then(() => this.liitetiedostoPalvelinService.deleteLiitetiedosto(liiteId)
          .then(_ => {
            this.setLiitteet(this.saastumislahteidenArviointi.value, 'saastumislahteidenArviointi')
            this.saastumislahteidenArviointi.controls = [];
            this.saastumislahteidenArviointi.setValue([]);
          })
      )
    }

    return promiseChain;
  }
}
