import {Component, OnInit} from "@angular/core";
import {LomakeService} from "../touko-lomake/touko-lomake.service";
import {AccountService} from "../account/account.service";
import {Subscription} from "rxjs";
import {Account, Toimiala} from "../account/account";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 02/11/2017.
 */

@Component({
  selector: 'etusivu',
  templateUrl: './etusivu.component.html',
  styleUrls: ['./etusivu.component.scss']
})
@AutoUnsubscribe
export class EtusivuComponent implements OnInit {
// switch case?
  account: Account = null;
  subs: Subscription[] = [];
  visibleComponent = EtusivuView.NOT_LOADED;

  constructor(private lomakeService: LomakeService,
              private accountService: AccountService) {
  }

  ngOnInit() {
    this.subs.push(this.accountService.account$.subscribe(account => {
        if (account) {
          this.account = account;
          if (Account.isViranomainen(account)) {
            this.visibleComponent = EtusivuView.VIRKAILIJA;
          } else if (Account.isAsiakas(account)) {
            this.visibleComponent = account.toimiala === Toimiala.ELAIN_LABORATORIO ?
              EtusivuView.AS_SAHKOELAIN :
              EtusivuView.AS_TOUKO;
          }
        }
      }
    ));
  }
}

enum EtusivuView {
  NOT_LOADED = "NOT_LOADED",
  VIRKAILIJA = "VIRKAILIJA",
  AS_TOUKO = "AS_TOUKO",
  AS_SAHKOELAIN = "AS_SAHKOELAIN"
}
