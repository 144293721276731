import {Component, Input} from '@angular/core';
import {ControlContainer} from "@angular/forms";
import {QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-group-nested',
  template: `
    <ng-container [formGroup]="controlContainer.control">
      <p *ngIf="questionGroup.label">
        <strong>{{questionGroup.label}}</strong>
      </p>
      <question-group [sivuRef]="sivuRef"
                      [questionGroup]="questionGroup"
                      [hideGroups]="hideGroups"
                      [hideControls]="hideControls"
                      [hideArray]="hideArray"></question-group>
    </ng-container>
  `,
})
export class QuestionGroupNestedComponent {

  @Input() sivuRef: string;
  @Input() questionGroup: QuestionGroup;
  @Input() hideGroups = false;
  @Input() hideArray = false;
  @Input() hideControls = false;

  constructor(public controlContainer: ControlContainer) { }
}
