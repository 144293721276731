export class ValueCheckHelper {
  static hasPositiveValues(obj: any): boolean {
    if (obj === null || obj === false) {
      return false;
    }

    if (typeof obj === 'object') {
      return this.objectHasPositiveValues(obj);
    } else if (Array.isArray(obj)) {
      return this.arrayHasPositiveValues(obj);
    } else {
      return this.isPositiveValue(obj);
    }
  }

  static objectHasPositiveValues(obj: object): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && this.hasPositiveValues(obj[key])) {
        return true;
      }
    }
    return false;
  }

  static arrayHasPositiveValues(arr: any[]): boolean {
    for (const item of arr) {
      if (this.hasPositiveValues(item)) {
        return true;
      }
    }
    return false;
  }

  static isPositiveValue(value: any): boolean {
    return value !== null && value !== false && value !== '';
  }
}
