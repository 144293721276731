import {Component} from "@angular/core";
import {AccountService} from "../../../account/account.service";
import {LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {LomakeInfo} from "../common/lomake-info";
import {Teksti} from "../../../utils/teksti";
import {RehuvalvontaV2Data} from "./rehuvalvonta-v2-data";
import {AbstractField} from "ngx-fielding";
import {RehuvalvontaV2IsvisibleRules} from "./rehuvalvonta-v2-isvisible-rules";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";

@Component({
  selector: 'rehuvalvonta-v2',
  providers: [ProcessLomakeService],
  templateUrl: "rehuvalvonta-v2.component.html",
  styleUrls: ['../common/lomake-style-overrides.scss']
})
export class RehuvalvontaV2Component extends LomakeV2BaseDirective {
  rules: RehuvalvontaV2IsvisibleRules;

  constructor(protected readonly processLomakeService: ProcessLomakeService,
              protected readonly data: RehuvalvontaV2Data,
              protected readonly accountService: AccountService) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  get toiminnat(): AbstractField[] {
    return Object.values(this.lomake.get('rekisteroitavatToiminnat').asGroup().controlFields);
  }

  get tuotetyypit(): AbstractField[] {
    return Object.values(this.lomake.get('tuoteTyypit').asGroup().controlFields);
  }

  get laitostenLisatiedot(): AbstractField[] {
    return Object.values(this.lomake.get('laitostenLisatiedot').asGroup().controlFields);
  }

  get laadunohjaus(): AbstractField[] {
    return Object.values(this.lomake.get('laadunohjaus').asGroup().controlFields);
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new RehuvalvontaInfo(
      accountType,
      toimintotyyppi
    );
  }

  initLomakeBehaviour() {
    this.rules = new RehuvalvontaV2IsvisibleRules(this.lomake);

    if (this.lomake.get('laadunohjaus.ReVFeedQSOther.checked').value) {
      this.lomake.get('laadunohjaus.ReVFeedQSOther.text').enable()
    } else {
      this.lomake.get('laadunohjaus.ReVFeedQSOther.text').disable()
    }
  }

  addLomakeBehaviour() {
    // Method not used/needed here.
  }

}

class RehuvalvontaInfo implements LomakeInfo {
  asia: string;
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.VK_YH.value;
  lomakeTitle: Teksti = new Teksti("Ilmoitus rekisteröintiä varten", "rehuvalvontaIlmoitus", "rehuvalvonta");
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: "as" | "vk", toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.VK_YH, YhteyshenkiloValue.VK_VARA_YH];
    this.asia = "rehuvalvonta";
    this.toimintotyyppi = toimintotyyppi;
    const prefix = 'rehuvalvonta.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1', '2_18', '1_10']);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['20_57', '32_39', '58_64']);
    this.ohjeet.sivu2 = LomakeHelper.getOhjeList(prefix, ['43_47', '70']);
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/ilmoitukset/rehuvalvonta/${toimintotyyppi}`;
  }
}
