import {Component, Input} from "@angular/core";
import {ModalData} from "../modal-utils";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'modal-simple',
  template: `
    <div class="modal-header">
      <h1 class="modal-title" [attribute]="modalTiedot.otsikko.getFullKey()">Otsikko</h1>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="container" [attribute]="modalTiedot.kysymys.getFullKey()">{{modalTiedot.kysymys.label}}</div>
    </div>

    <div class="modal-footer" *ngIf="modalTiedot.suljeButton == false">
      <button [attribute]="modalTiedot.okTeksti.getFullKey()" id="{{modalTiedot.okElementId}}" type="button" class="btn" [ngClass]="modalTiedot.okStyle" (click)="submit()">Hyväksy</button>
      <button attribute="teksti.peruuta" id="peruuta" type="button" class="btn" [ngClass]="modalTiedot.cancelStyle" (click)="activeModal.dismiss('Peruuta')">Peruuta</button>
    </div>

    <div class="modal-footer" *ngIf="modalTiedot.suljeButton == true">
      <button attribute="teksti.sulje" id="sulje" type="button" class="btn" [ngClass]="modalTiedot.suljeStyle" (click)="activeModal.dismiss('Sulje')">Sulje</button>
    </div>
  `
})
export class ModalSimpleComponent {
  @Input() modalTiedot: ModalData;

  constructor(public activeModal: NgbActiveModal) {}

  submit() {
    this.modalTiedot.okFn()
      .then(() => {
        this.activeModal.close("Ok");
      })
      .catch(() => {
        this.activeModal.dismiss("Error");
      })
    ;
  }
}
