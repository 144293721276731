import {Component} from "@angular/core";
import {LoaderService, LoadStatus} from "./loader.service";
import {Teksti} from "../utils/teksti";
import {Subscription} from "rxjs/index";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Latausanimaatiokomponentti. Tulee näyttää aina sellaisten toimintojen yhteydessä, jotka kutsuvat
 * palvelinta JA aiheuttavat selaimessa sivun vaihdon.
 */
@Component({
  selector: 'screen-loader',
  templateUrl: './screen-loader.component.html',
})
@AutoUnsubscribe
export class ScreenLoaderComponent {
  LoadStatus = LoadStatus;
  isLoading = LoadStatus.NOT_STARTED;
  loadingMessage: any;
  show = false;
  loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService) {
    this.loaderSubscription = loaderService.isLoading$.subscribe(value => {
      this.isLoading = value.status;
      this.loadingMessage = value.message;

      switch (value.status) {
        case LoadStatus.IS_LOADING:
          this.show = true;
          break;
        case LoadStatus.LOAD_ENDED:
          setTimeout(() => this.show = false, 2000);
          break;
        case LoadStatus.TIMEOUT_ERROR:
          this.show = false;
          break;
      }
    });
  }

  isMessageTeksti(message: string | Teksti): boolean {
    return message && message instanceof Teksti;
  }
}
