import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2} from "@angular/core";
import {Syote} from "../syote";

type Rehutuotteet = {valittu: boolean; vastaukset: any} | null;

/**
 * Checkbox ja mahdolliset lisäkysymykset
 */
@Component({
  selector: 'touko-rehutuotteet',
  template: `
      <div class="d-flex align-items-center" [ngClass]="liittyvaKysymys && fieldValue.valittu ? 'mb-2' : 'mb-4'">
        <input #kysymys class="form-checkbox" type="checkbox" id="{{item.name}}"
               [checked]="fieldValue.valittu" [(ngModel)]="fieldValue.valittu"
               (click)="naytaKysymykset(kysymys.checked)">
        <label class="ml-3 mb-0" for="{{item.name}}" [attribute]="item.getLabelKey()">
          {{item.label}}
        </label>
      </div>
    <ng-container *ngIf="liittyvaKysymys && fieldValue.valittu">
      <div class="form-check" id="{{item.name}}lisatieto">
        <label class="form-check-label">
            <span class="small" [attribute]="haeKysymysLabel(liittyvaKysymys)"></span>
            <input id="{{liittyvaKysymys}}" type="text" class="form-control"
            (keyup)="kirjoitaVastaus(liittyvaKysymys, $event.target.value)" value="{{haeVastaus(liittyvaKysymys)}}">
        </label>
      </div>
    </ng-container>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RehutuotteetComponent),
    }
  ]
})
export class RehutuotteetComponent implements ControlValueAccessor {

  @Input() item: Syote;
  @Output() rehuvalinta = new EventEmitter<boolean>();
  fieldValue: null | Rehutuotteet = {valittu: false, vastaukset: {}};
  @Input() liittyvaKysymys: string;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: Rehutuotteet | null): void {
   if (value) {
      // päivitetään viittaukset vain jos checkboxin arvo muuttuu
      if (value.valittu !== this.fieldValue.valittu) {
        this.rehuvalinta.emit(value.valittu);
      }
      this.fieldValue = value;
      this._onChange(value);
    }
  }

  kirjoitaVastaus(kysymys: string, vastaus: string): void {
    const val: Rehutuotteet = {valittu: null, vastaukset: {}};
    if (vastaus) {
      val.vastaukset[kysymys] = vastaus;
    }
    val.valittu = this.fieldValue.valittu;
    this.writeValue(val);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  naytaKysymykset(value: boolean): void {
    const val: Rehutuotteet = {valittu: value, vastaukset: this.fieldValue.vastaukset};
    this.writeValue(val);
  }

  haeKysymysLabel(kysymys: string): string {
    return "rehuvalvonta." + kysymys;
  }

  haeVastaus(kysymys: string): string {
    if (this.fieldValue.vastaukset) {
        return this.fieldValue.vastaukset[kysymys];
    }
  }
}

