import {Component, Input} from "@angular/core";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'compare-item',
  template: `
    <div class="d-flex">
      <div *ngIf="compareItemType" class="mr-3 py-1">
        <i *ngIf="compareItemType === 'REMOVE'" class="fas fa-minus text-danger"></i>
        <i *ngIf="compareItemType === 'MODIFY'" class="fas fa-sync-alt text-primary"></i>
        <i *ngIf="compareItemType === 'ADD'" class="fas fa-plus text-add"></i>
      </div>
      <div [ngClass]="getCompareItemStyle()">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./compare-item.scss']
})

export class CompareItemComponent {
  @Input() compareItemType: ResultType;

  getCompareItemStyle(): string {
    if (this.compareItemType === 'ADD') {
      return "summary-compare-item compare-item-add";
    } else if (this.compareItemType === 'MODIFY') {
      return "summary-compare-item compare-item-modify";
    } else if (this.compareItemType === 'REMOVE') {
      return "summary-compare-item compare-item-remove";
    }
    return "";
  }
}
