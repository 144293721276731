import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'title-and-info-summary',
  template: `
    <hr>
    <h2 [attribute]="item?.question" [ngClass]="{'invisible': compareItemType}"></h2>
    <compare-item [compareItemType]="compareItemType">
      <span [attribute]="item?.answer"></span>
    </compare-item>
  `
})
export class TitleAndInfoSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
