import {Observable, throwError as observableThrowError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";

import {MessageService} from "../message/message.service";
import {LoaderService} from "../loader/loader.service";
import {HttpErrorDetails} from "./http-error-details";
import {Teksti} from "./teksti";
import {Router} from "@angular/router";
import {tiiraCommand} from "tiira-frontend";

declare let $: any;

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService, private readonly loaderService: LoaderService, public router: Router) {
  }

  private redirect: Promise<boolean>;

  intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithHeaders = request.clone({headers: this.getHttpOptions()});
    return next.handle(requestWithHeaders).pipe(
      catchError(response => {

        if (response instanceof HttpErrorResponse) {
          this.chechIfSessionWasExpired(response) ?
            this.sessionWasExpired(response) : this.generalHttpError(response);
        }

        return observableThrowError(response);
      }));
  }

  private getHttpOptions() {
    let headers = tiiraCommand.fetchAuthenticationHeaders();
    if (headers == null) { // ei vielä sisäänkirjautumista
      headers = {};
    }
    headers['X-Requested-With'] = 'XMLHttpRequest';
    return new HttpHeaders(headers);
  }


  private chechIfSessionWasExpired(response: HttpErrorResponse) {

    /* In the case of expired session, Tiira will set the redirect URL as
       defined via setRedirectAuthenticationPage in the server side.
       However, the response code is not set to 302.
     */
    return response.url.toLowerCase().endsWith("index.html");
  }

  private sessionWasExpired(response: HttpErrorResponse): void {

    /* When session expiration is detected, we click the "close" button of open modal,
       start loading animtaion and navigate back to login page.

       The loading page will trigger tiiraCommand.showQuestions that will further handle the
       session expiration case (ie. by showing the expiration message to user).
     */
    $("account-modal button.close")?.click();

    this.redirect = this.redirect || new Promise<boolean>(() => true);
    this.redirect.finally(() => {
        this.loaderService.startLoadingAnimation();
        this.router.navigate(['login'])
          .finally(() => {
            this.loaderService.forceStopLoadingAnimation();
          });
    });
  }

  private generalHttpError(response: HttpErrorResponse): void {
    this.loaderService.forceStopLoadingAnimation();
    if (response.status > 400 && response.status < 500) {
      this.messageService.latestError = response.error.message;
      this.redirect = this.redirect || this.router.navigate(['/asiointi/error/notfound']);
    } else {
      this.showErrorMessage(response.error);
    }
  }

  private showErrorMessage(error: HttpErrorDetails): void {
    let message: any;

    if (error?.showInClient) {
      message = error.message;
    } else {
      if (!navigator.onLine) {
        message = new Teksti("Virhe palvelussa, ole hyvä ja tarkista verkkoyhteys.", "virhePalvelussaYhteys", "etusivu");
      } else {
        message = new Teksti("Virhe palvelussa, ole hyvä ja yritä myöhemmin uudestaan.", "virhePalvelussa", "etusivu");
      }
    }

    this.messageService.notify({message, type: error?.severity || "danger"});
  }

}
