import {Component, Input} from "@angular/core";
import {DatePipe} from "@angular/common";
import {tiira, tiiraAttribute} from "tiira-frontend";

@Component({
  selector: "palsa-info",
  template: `
    <div *ngFor="let tiedote of palsaInfo">

      <div *ngIf="!tiedote.hideTiedote" class="alert alert-danger"
           [ngClass]="{'alert-info': tiedote.vakavuustaso == VAKAVUUSTASO.Tiedoksi,
                             'alert-warning': tiedote.vakavuustaso == VAKAVUUSTASO.HaittaaKayttoa ||
                                              tiedote.vakavuustaso == VAKAVUUSTASO.Huoltokatko,
                             'alert-danger': tiedote.vakavuustaso == VAKAVUUSTASO.EstaaKayton}">

        <ng-container *ngFor="let info of tiedote.julkinenTiedoteInfo; let first = first">

          <hr *ngIf="!first"/>
          <p class="p-info-item" *ngIf="info.otsikko"><b>{{ info.otsikko }}</b></p>
          <p class="p-info-item" *ngIf="info.kuvaus">{{ info.kuvaus }}</p>

        </ng-container>

      </div>

    </div>`,
  styles: ['.p-info-item { margin-top: 2px; margin-bottom: 2px; }']
})
export class PalsaInfoComponent {

  @Input() palsaId: string;
  @Input() languages: Array<string> = ['fi', 'sv', 'en'];
  @Input() daysBeforeShowHuoltokatko = 7;
  @Input() localizationKey = "palsa";

  palsaInfo: Array<any> = [];

  VAKAVUUSTASO = {
    Tiedoksi: "TIEDOKSI",
    HaittaaKayttoa: 'HAITTAA_KAYTTOA',
    EstaaKayton: 'ESTAA_KAYTON',
    Huoltokatko: 'HUOLTOKATKO'
  };

  TIEDOTETYYPPI = {
    HuoltoKatko: "HUOLTOKATKOTIEDOTE",
    Vika: 'VIKATIEDOTE',
    Tiedote: 'TIEDOTE'
  };

  private TIEDOTELOCALIZATIONID = {
    SeuraavaHuoltokatko: "seuraavaHuoltokatko",
    Huoltokatko: 'huoltokatko',
    Vikatilanne: 'vikatilanne',
    Hairiotilanne: 'hairiotilanne',
    Tiedoksi: 'tiedoksi'
  };

  private localizations = {};

  constructor(private datePipe: DatePipe) {
  }

  update(): void {
    tiiraAttribute.readLabels(this.localizationKey,
      (attributes: any) => {
        this.localizations = attributes;
        this.checkAndDisplayPalsaInfo();
      }
    );
  }

  private checkAndDisplayPalsaInfo(): void {

    tiira.getPalsaInfo(
      this.palsaId,
      (info: any) => {

        if (info) {

          /* ****************** */
          /* Huoltokatkotiedote */
          /* ****************** */
          if (this.checkIsTiedoteInValidForm(info.nextHuoltokatkoTiedote)) {
            this.prepareNextHuoltokatkoTiedote(info.nextHuoltokatkoTiedote);
          }

          /* ********************** */
          /* Poikkeustilannetiedote */
          /* ********************** */
          if (this.checkIsTiedoteInValidForm(info.mostSeverePoikkeustilanneTiedote)) {
            if (!this.didPalsaSendUsDuplicateTiedote(info.nextHuoltokatkoTiedote, info.mostSeverePoikkeustilanneTiedote)) {
              this.prepareMostSeverePoikkeustilanneTiedote(info.mostSeverePoikkeustilanneTiedote);
            }
          }

        }
      },
      (error: any) => {
        console.error(error);
      }
    );

  }

  private prepareNextHuoltokatkoTiedote(tiedote: any): void {
    if (!this.areWeCurrentlyInHuoltokatko(tiedote)) {

      if (this.shouldWeAlreadyShowTheHuoltokatkoInfo(tiedote)) {
        this.setUpTiedoteOtsikkoAndKuvaus(tiedote, this.TIEDOTELOCALIZATIONID.SeuraavaHuoltokatko);
      } else {
        tiedote.hideTiedote = true;
      }

    } else {
      this.setUpTiedoteOtsikkoAndKuvaus(tiedote, this.TIEDOTELOCALIZATIONID.Huoltokatko);
    }

    this.palsaInfo.push(tiedote);
  }

  private prepareMostSeverePoikkeustilanneTiedote(tiedote: any): void {
    if (tiedote.tiedotetyyppi === this.TIEDOTETYYPPI.HuoltoKatko) {
      this.setUpTiedoteOtsikkoAndKuvaus(tiedote, this.TIEDOTELOCALIZATIONID.Huoltokatko);
    } else {

      switch (tiedote.vakavuustaso) {
        case this.VAKAVUUSTASO.EstaaKayton: {
          this.setUpTiedoteOtsikkoAndKuvaus(tiedote, this.TIEDOTELOCALIZATIONID.Vikatilanne);
          break;
        }
        case this.VAKAVUUSTASO.HaittaaKayttoa: {
          this.setUpTiedoteOtsikkoAndKuvaus(tiedote, this.TIEDOTELOCALIZATIONID.Hairiotilanne);
          break;
        }
        case this.VAKAVUUSTASO.Tiedoksi: {
          this.setUpTiedoteOtsikkoAndKuvaus(tiedote, this.TIEDOTELOCALIZATIONID.Tiedoksi, true);
          break;
        }
        default: {
          break;
        }
      }

    }

    this.palsaInfo.push(tiedote);
  }

  private checkIsTiedoteInValidForm(tiedote: any): boolean {
    if (tiedote && tiedote.tunnus && tiedote.vakavuustaso && tiedote.tiedotetyyppi) {
      return true;
    }
    return false;
  }

  private didPalsaSendUsDuplicateTiedote(tiedote1: any, tiedote2: any): boolean {
    return (tiedote1 && tiedote2 && tiedote1.tunnus === tiedote2.tunnus);
  }

  private areWeCurrentlyInHuoltokatko(tiedote: any): boolean {
    if (tiedote.alkuhetki) {
      const currentDate = new Date();
      const huoltoKatkoStartDate = new Date(tiedote.alkuhetki);

      return (currentDate > huoltoKatkoStartDate);
    }
    return true;
  }

  private shouldWeAlreadyShowTheHuoltokatkoInfo(tiedote: any): boolean {
    const currentDate = new Date();
    const huoltoKatkoStartDate = new Date(tiedote.alkuhetki);
    huoltoKatkoStartDate.setDate(huoltoKatkoStartDate.getDate() - this.daysBeforeShowHuoltokatko);

    return (currentDate > huoltoKatkoStartDate);
  }

  private setUpTiedoteOtsikkoAndKuvaus(tiedote: any, messageId: string, hideIfNoKuvaus?: boolean): void {

    let localizedMessages = {};
    if (this.localizations && this.localizations[messageId] && this.localizations[messageId].labels) {
      localizedMessages = this.localizations[messageId].labels;
    }

    const startTime = this.datePipe.transform(new Date(tiedote.alkuhetki), 'd.M.y H:mm');
    const endTime = this.datePipe.transform(new Date(tiedote.loppuhetkiArvio), 'd.M.y H:mm');

    tiedote.julkinenTiedoteInfo = this.languages.reduce((tiedoteInfo, lang) => {
      const info: any = {};

      if (!this.localizations) {
        /* If we have no localization for otsikko, show only kuvaus */

        if (!tiedote["julkinenKuvaus_" + lang]) {
 return tiedoteInfo;
}

        info.kuvaus = tiedote["julkinenKuvaus_" + lang];
      } else {
        /* Note, that if no kuvaus is given, the otsikko itself can serve as info (except in type tiedote) */

        if (hideIfNoKuvaus && !tiedote["julkinenKuvaus_" + lang]) {
 return tiedoteInfo;
}

        info.kuvaus = tiedote["julkinenKuvaus_" + lang];

        const message = localizedMessages[lang.toUpperCase()];
        if (message) {
          info.otsikko = message.replace("[ALKUAIKA]", startTime).replace("[LOPPUAIKA]", endTime);
        }
      }

      tiedoteInfo.push(info);
      return tiedoteInfo;
    }, []);
  }
}
