import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {AccountComponent} from "./account/account.component";
import {EtusivuComponent} from "./etusivu/etusivu.component";
import {RehuvuosiilmoitusComponent} from "./touko-lomake/vuosiilmoitus/rehuvuosiilmoitus/rehuvuosiilmoitus.component";
import {LoginGuardService as LoginGuard} from "./auth/login-guard.service";
import {PermissionGuardService as PermissionGuard} from "./auth/permission-guard.service";
import {ToimialaGuardService as ToimialaGuard} from "./auth/toimiala-guard.service";
import {PageNotFoundComponent} from "./not-found.component";
import {KauppaeraRaporttiComponent} from "./raportti/raportit-kauppaera/kauppaera-raportti.component";
import {ErrorInfoComponent} from "./error-info.component";
import {RehuvalvontaRaporttiComponent} from "./raportti/raportit-rehuvalvonta/rehuvalvonta-raportti.component";
import {ViljelystarkastuspaatosComponent} from "./raportti/raportit-viljelystarkastus/viljelystarkastus-raportti.component";
import {LannoiteRaporttiComponent} from "./raportti/raportit-lannoite/lannoite-raportti.component";
import {KateTarkastusComponent} from "./raportti/raportit-katetarkastus/katetarkastus-raportti.component";
import {LomakeLokitiedotComponent} from "./lomake-lokitiedot/lomake-lokitiedot.component";
import {ViranomainenGuardService as ViranomainenGuard} from "./auth/viranomainen-guard.service";
import {LomakeLeaveGuardService} from "./auth/lomake-leave-guard.service";
import {ToukoLaheteComponent} from "./touko-lahete/touko-lahete.component";
import {Luomupoikkeus9cV2Component} from "./touko-lomake/luomu-poikkeus9c/luomupoikkeus9c-v2.component";
import {RaportitVirkailijaComponent} from "./raportti/raportit-virkailija/raportit-virkailija.component";
import {InfoValilehtiComponent} from "./info-valilehti/info-valilehti.component";
import {ValvontakohdeGuardService as ValvontakohdeGuard} from "./auth/valvontakohde-guard.service";
import {RaporttiGuardService as RaporttiGuard} from "./auth/raportti-guard.service";
import {LaheteTarkastusComponent} from "./touko-lahete/tarkastus/lahete-tarkastus.component";
import {LuomuRaporttiComponent} from "./raportti/raportit-luomu/luomu-raportti.component";
import {SiemenMuutRaporttiComponent} from "./raportti/raportit-siemenmuut/siemenmuut-raportti.component";
import {TarkastusV2Component} from "./touko-lomake/tarkastus/tarkastus-v2.component";
import {RehuvuosiilmoitusTemplateComponent} from "./touko-lomake/vuosiilmoitus/rehuvuosiilmoitus/rehuvuosiilmoitustemplate.component";
import {RehuvalvontaV2Component} from "./touko-lomake/vuosiilmoitus/rehuvalvonta/rehuvalvonta-v2.component";
import {SiemenrekisteriV2Component} from "./touko-lomake/vuosiilmoitus/siemenrekisteri/siemenrekisteri-v2.component";
import {LannoiterekisteriV2Component} from "./touko-lomake/vuosiilmoitus/lannoiterekisteri/lannoiterekisteri-v2.component";
import {RekisteriLopetusV2Component} from "./touko-lomake/vuosiilmoitus/rekisteri-lopetus/rekisteri-lopetus-v2.component";
import {SiemenpakkaamoV2Component} from "./touko-lomake/vuosiilmoitus/pakkaamolupahakemus/siemenpakkaamo-v2.component";
import {ElabraRaporttiComponent} from "./raportti/raportit-elabra/elabra-raportti.component";
import {LuomuvalvontaComponent} from "./touko-lomake/vuosiilmoitus/luomuvalvonta/luomuvalvonta.component";
import {KasvinterveysrekisteriV2Component} from "./touko-lomake/vuosiilmoitus/kasvinterveysrekisteri/kasvinterveysrekisteri-v2.component";
import {TarkastusLopetusV2Component} from "./touko-lomake/tarkastus/tarkastus-lopetus-v2.component";
import {LannoitevuosiilmoitusComponent} from "./touko-lomake/vuosiilmoitus/lannoitevuosiilmoitus/lannoitevuosiilmoitus.component";
import {LannoitevuosiilmoitusTemplateComponent} from "./touko-lomake/vuosiilmoitus/lannoitevuosiilmoitus/lannoitevuosiilmoitustemplate.component";


/**
 * Created by Seppo on 02/10/2017.
 *
 */
const createTarkastusOrEsikatselu = (rootPath: string) => ({
  path: rootPath,
  children: [
    {
      path: 'lopetus/:asia/:id',
      redirectTo: 'lopetus/:asia/v1/:id'
    },
    {
      path: 'lopetus/:asia/:versio/:id',
      component: TarkastusLopetusV2Component,
      data: {title: 'lomakeTarkistus'}
    },
    {
      path: 'lopetus/:asia/:id',
      redirectTo: 'lopetus/:asia/v1/:id'
    },
    {
      path: ':lomaketyyppi/:asia/:id',
      redirectTo: ':lomaketyyppi/:asia/v1/:id'
    },
    {
      path: ':lomaketyyppi/:asia/:versio/:id',
      component: TarkastusV2Component,
      data: {title: 'lomakeTarkistus'}
    },
    {
      path: 'lahete/:id',
      component: LaheteTarkastusComponent,
      data: {title: 'lomakeTarkistus'}
    }
  ]
});

const routes: Routes = [
  {
    path: 'asiointi',
    canActivate: [LoginGuard],
    children: [
      {path: 'error/:reason', component: ErrorInfoComponent, data: {title: 'virhe'}},
      {path: 'account', component: AccountComponent, canActivate: [PermissionGuard], data: {title: 'asetukset'}},
      {
        path: 'etusivu',
        component: EtusivuComponent,
        canActivate: [PermissionGuard, ToimialaGuard, ValvontakohdeGuard],
        data: {title: 'etusivu'}
      },
      {
        path: 'info-valilehti',
        component: InfoValilehtiComponent,
        canActivate: [PermissionGuard],
        data: {title: 'muutoshistoria'}
      },
      {
        path: 'lomakeloki',
        component: LomakeLokitiedotComponent,
        canActivate: [PermissionGuard, ViranomainenGuard, ToimialaGuard]
      },
      {
        path: 'raportit',
        canActivate: [PermissionGuard, RaporttiGuard],
        children: [
          {
            path: 'kauppaeraraportit',
            component: KauppaeraRaporttiComponent,
            data: {title: 'asiakirjaKauppaera'}
          },
          {
            path: 'siemenvalvontaraportit',
            component: SiemenMuutRaporttiComponent,
            data: {title: 'asiakirjaSiemenMuu'}
          },
          {path: 'kasvinterveysraportit', component: KateTarkastusComponent, data: {title: 'asiakirjaKate'}},
          {
            path: 'rehuvalvontaraportit',
            component: RehuvalvontaRaporttiComponent,
            data: {title: 'asiakirjaRehu'}
          },
          {
            path: 'viljelystarkastuspaatokset',
            component: ViljelystarkastuspaatosComponent,
            data: {title: 'raporttiViljelys'}
          },
          {
            path: 'lannoitevalvontaraportit',
            component: LannoiteRaporttiComponent,
            data: {title: 'asiakirjaLannoite'}
          },
          {
            path: 'virkailija/:raporttisivu',
            canActivate: [ViranomainenGuard],
            component: RaportitVirkailijaComponent,
            data: {title: 'asiakirjaKauppaera'}
          },
          {
            path: 'luomuraportit',
            component: LuomuRaporttiComponent,
            data: {title: 'asiakirjaLuomu'}
          },
          {
            path: 'tutkimustodistukset',
            component: ElabraRaporttiComponent,
            data: {title: 'asiakirjaElabra'}
          }
        ]
      },
      {
        path: 'template',
        canActivate: [PermissionGuard, ToimialaGuard, ValvontakohdeGuard],
        children: [
          {
            path: 'rehuvuosiilmoitus',
            component: RehuvuosiilmoitusTemplateComponent,
            data: {title: 'rehuvuosiilmoitustemplate'}
          },
          {
            path: 'lannoitevuosiilmoitus',
            component: LannoitevuosiilmoitusTemplateComponent,
            data: {title: 'lannoitevuosiilmoitustemplate'}
          },
        ]
      },
      {
        path: 'lomake',
        canActivate: [PermissionGuard, ToimialaGuard, ValvontakohdeGuard],

        children: [
          {
            path: 'lopetus',
            canDeactivate: [LomakeLeaveGuardService],
            children: [
              {path: ':asia/:versio', redirectTo: ':asia/:versio/0/aloita'},
              {path: ':asia/:versio/:id/:mode/sivu/:page', redirectTo: ':asia/:versio/:id/:mode'},
              {
                path: ':asia/:versio/:id/:mode',
                component: RekisteriLopetusV2Component,
                data: {title: 'lomakeLopetus', toimintotyyppi: 'lopetus'}
              }
            ]
          },
          {
            path: 'uusi',
            canDeactivate: [LomakeLeaveGuardService],
            children: [
              {
                path: 'kylvosiemen-markkinointi/v2',
                redirectTo: 'kylvosiemen-markkinointi/v2/0/aloita/sivu/0'
              },
              {
                path: 'kylvosiemen-markkinointi/v2/:id/:mode',
                redirectTo: 'kylvosiemen-markkinointi/v2/:id/:mode/sivu/0'
              },
              {
                path: 'kylvosiemen-markkinointi/v2/:id/:mode/sivu/:page',
                component: SiemenrekisteriV2Component,
                data: {toimintotyyppi: 'uusi', title: 'lomakeUusiSiemen'}
              },
              {path: 'rehuvalvonta/v2', redirectTo: 'rehuvalvonta/v2/0/aloita/sivu/0'},
              {path: 'rehuvalvonta/v2/:id/:mode', redirectTo: 'rehuvalvonta/v2/:id/:mode/sivu/0'},
              {
                path: 'rehuvalvonta/v2/:id/:mode/sivu/:page',
                component: RehuvalvontaV2Component,
                data: {toimintotyyppi: 'uusi', title: 'lomakeUusiRehu'}
              },
              {path: 'lannoiterekisteri/v2', redirectTo: 'lannoiterekisteri/v2/0/aloita/sivu/0'},
              {
                path: 'lannoiterekisteri/v2/:id/:mode',
                redirectTo: 'lannoiterekisteri/v2/:id/:mode/sivu/0'
              },
              {
                path: 'lannoiterekisteri/v2/:id/:mode/sivu/:page',
                component: LannoiterekisteriV2Component,
                data: {toimintotyyppi: 'uusi', title: 'uusiLomakeUusiLannoite'}
              },
              {
                path: 'kasvinterveysrekisteri/:versio',
                redirectTo: 'kasvinterveysrekisteri/v2/0/aloita/sivu/0'
              },
              {
                path: 'kasvinterveysrekisteri/:versio/:id/:mode',
                redirectTo: 'kasvinterveysrekisteri/v2/:id/:mode/sivu/0'
              }, {
                path: 'kasvinterveysrekisteri/v2/:id/:mode/sivu/:page',
                component: KasvinterveysrekisteriV2Component,
                data: {toimintotyyppi: 'uusi', title: 'lomakeUusiKate'}
              },
              {path: 'luomuvalvonta/v2', redirectTo: 'luomuvalvonta/v2/0/aloita/sivu/0'},
              {
                path: 'luomuvalvonta/v2/:id/:mode',
                redirectTo: 'luomuvalvonta/v2/:id/:mode/sivu/0'
              },
              {
                path: 'luomuvalvonta/v2/:id/:mode/sivu/:page',
                component: LuomuvalvontaComponent,
                data: {toimintotyyppi: 'uusi', title: 'uusiLomakeUusiLuomu'}
              },
            ]
          },
          {
            path: 'muutos',
            canDeactivate: [LomakeLeaveGuardService],
            children: [
              {
                path: 'kylvosiemen-markkinointi/v2/:id/:mode',
                redirectTo: 'kylvosiemen-markkinointi/v2/:id/:mode/sivu/0'
              },
              {
                path: 'kylvosiemen-markkinointi/v2/:id/:mode/sivu/:page',
                component: SiemenrekisteriV2Component,
                data: {toimintotyyppi: 'muutos', title: 'lomakeMuutosSiemen'}
              },
              {path: 'rehuvalvonta/v2/:id/:mode', redirectTo: 'rehuvalvonta/v2/:id/:mode/sivu/0'},
              {
                path: 'rehuvalvonta/v2/:id/:mode/sivu/:page',
                component: RehuvalvontaV2Component,
                data: {toimintotyyppi: 'muutos', title: 'lomakeMuutosRehu'}
              },
              {
                path: 'lannoiterekisteri/v2/:id/:mode',
                redirectTo: 'lannoiterekisteri/v2/:id/:mode/sivu/0'
              },
              {
                path: 'lannoiterekisteri/v2/:id/:mode/sivu/:page',
                component: LannoiterekisteriV2Component,
                data: {toimintotyyppi: 'muutos', title: 'uusiLomakeMuutosLannoite'}
              },
              {
                path: 'kasvinterveysrekisteri/:versio/:id/:mode',
                redirectTo: 'kasvinterveysrekisteri/v2/:id/:mode/sivu/0'
              },
              {
                path: 'kasvinterveysrekisteri/v2/:id/:mode/sivu/:page',
                component: KasvinterveysrekisteriV2Component,
                data: {toimintotyyppi: 'muutos', title: 'lomakeMuutosKate'}
              }
            ]
          },
          createTarkastusOrEsikatselu('tarkastus'),
          createTarkastusOrEsikatselu('esikatselu'),
          {
            path: 'hakemus',
            canDeactivate: [LomakeLeaveGuardService],
            children: [
              {
                path: 'luomupoikkeus9c/v2/:id/:mode', redirectTo: 'luomupoikkeus9c/v2/:id/:mode/sivu/0'
              },
              {
                path: 'luomupoikkeus9c/v2/:id/:mode/sivu/:page',
                component: Luomupoikkeus9cV2Component,
                data: {toimintotyyppi: 'hakemus', title: 'lomakeMuutosLuomuPoikkeus9c'}
              },
              {path: 'siemenpakkaamolupa', redirectTo: 'siemenpakkaamolupa/0/aloita/sivu/0'},
              {
                path: 'siemenpakkaamolupa/v2/:id/:mode',
                redirectTo: 'siemenpakkaamolupa/v2/:id/:mode/sivu/0'
              },
              {
                path: 'siemenpakkaamolupa/v2/:id/:mode/sivu/:page',
                component: SiemenpakkaamoV2Component,
                data: {toimintotyyppi: 'hakemus', title: 'lomakePakkaamolupa'}
              }
            ]
          },
          {
            path: 'vuosiilmoitus',
            canDeactivate: [LomakeLeaveGuardService],
            children: [
              {path: 'rehuvuosiilmoitus/v1', redirectTo: 'rehuvuosiilmoitus/v1/0/aloita/sivu/0'},
              {
                path: 'rehuvuosiilmoitus/v1/:id/:mode',
                redirectTo: 'rehuvuosiilmoitus/v1/:id/:mode/sivu/0'
              },
              {
                path: 'rehuvuosiilmoitus/v1/:id/:mode/sivu/:page',
                component: RehuvuosiilmoitusComponent,
                data: {toimintotyyppi: 'vuosiilmoitus', title: 'rehuvuosiilmoitus'}
              },
              {path: 'lannoitevuosiilmoitus/v1', redirectTo: 'lannoitevuosiilmoitus/v1/0/aloita/sivu/0'},
              {
                path: 'lannoitevuosiilmoitus/v1/:id/:mode',
                redirectTo: 'lannoitevuosiilmoitus/v1/:id/:mode/sivu/0'
              },
              {
                path: 'lannoitevuosiilmoitus/v1/:id/:mode/sivu/:page',
                component: LannoitevuosiilmoitusComponent,
                data: {toimintotyyppi: 'vuosiilmoitus', title: 'lannoitevuosiilmoitus'}
              }
            ]
          },
          {
            path: 'lahete',
            canDeactivate: [LomakeLeaveGuardService],
            canActivate: [PermissionGuard, ToimialaGuard],
            children: [
              {path: 'elaintautitutkimus/', redirectTo: 'elaintautitutkimus/v1/0/aloita/sivu/0'},
              {
                path: 'elaintautitutkimus/:id/jatka',
                redirectTo: 'elaintautitutkimus/v1/:id/jatka/sivu/0'
              },
              {path: 'elaintautitutkimus/v1', redirectTo: 'elaintautitutkimus/v1/0/aloita/sivu/0'},
              {path: 'elaintautitutkimus/v1/:id/', redirectTo: 'elaintautitutkimus/v1/:id/jatka/sivu/0'},
              {
                path: 'elaintautitutkimus/v1/:id/:mode',
                redirectTo: 'elaintautitutkimus/v1/:id/:mode/sivu/0'
              },
              {
                path: 'elaintautitutkimus/v1/:id/:mode/sivu/:page',
                component: ToukoLaheteComponent,
                data: {title: 'laheteElaintautitutkimus'}
              },
            ]
          },
        ]
      }
    ]
  },

  {path: 'login', component: LoginComponent, data: {title: 'kirjaudu'}},
  {path: '', redirectTo: '/login', pathMatch: 'full'},

  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [LoginGuard, PermissionGuard, ToimialaGuard, ValvontakohdeGuard],
    data: {title: 'sivuaEiLoydy'}
  }, // Guard also here to prevent "page checking"
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
