import {BehaviorSubject} from "rxjs";
import {AbstractControl, ValidatorFn} from "@angular/forms";

export enum CompareAction {
  NONE,
  FILTER,
  DISABLE,
  RESET,
  SELECT_ONLY_RADIO_OPTION,
  SELECT_ONLY_CHECKBOX_OPTION,
  DESELECT_ONLY_CHECKBOX_OPTION,
  ARRAY_ADD,
  ARRAY_REMOVE,
}

export enum CompareType {
  EVERY,
  SOME,
  SINGLE
}

export class MatcherModel {
  compareValue: any;
  compareFn: (compareValue: any, newValue: any) => CompareAction;
  compareActionFn: (...params: any) => void;
  compareType: CompareType;
  initialCompareState: CompareAction;

  constructor(compareValue: any,
              compareFn: (compareValue: any, newValue: any) => CompareAction,
              compareActionFn: (...params: any) => void,
              compareType = CompareType.EVERY,
              initialCompareState = CompareAction.NONE) {
    this.compareValue = compareValue;
    this.compareFn = compareFn;
    this.compareActionFn = compareActionFn;
    this.compareType = compareType;
    this.initialCompareState = initialCompareState;
  }
}

export class MatcherValueChange {
  key: string;
  nextValue: CompareAction;
  controlValue: any;

  constructor(key: string, nextValue: CompareAction, controlValue: any) {
    this.key = key;
    this.nextValue = nextValue;
    this.controlValue = controlValue;
  }
}

export class Matcher {
  path: string;
  matcherFn: (value: any) => CompareAction;
  matcherValueChange: BehaviorSubject<MatcherValueChange>;

  constructor(path: string, control: BehaviorSubject<MatcherValueChange>, filterFn: any) {
    this.path = path;
    this.matcherFn = filterFn;
    this.matcherValueChange = control;
  }
}

export interface ModelFragment {
  key: string;
  fullPath: string;

  controlType: string;
  htmlId: string;

  label: string;
  placeholder: string;

  value: any;
  initialValue: any;
  validators: ValidatorFn[];
  errorMessages: {[s: string]: string};

  options: {[s: string]: any};

  filters: {[s: string]: MatcherModel};
  disablers: {[s: string]: MatcherModel};
  modifiers: {[s: string]: MatcherModel};
  aggregators: {[s: string]: MatcherModel};
  actions: {[s: string]: (...params: any) => void};

  publishers: Topic[];
  subscribers: TopicListener[];
  lateRegisterMatchers: boolean;

  find(path: string[]): ModelFragment;
  findAll(path: string[], found: ModelFragment[]): ModelFragment[];
  findAllLeaves(found?: ModelFragment[]): ModelFragment[];
  updatePath(parentFullPath: string);
  updateKey(key: string);
  push?(item: ModelFragment, fragmentType: ModelFragmentType);
  remove?(itemKey: string, fragmentType: ModelFragmentType);
  clear?(fragmentType: ModelFragmentType);
}

export enum ModelFragmentType {
  GROUP,
  ARRAY_ITEM,
  CONTROL,
}

export class Topic {
  readonly topic: string;
  readonly detail: string;
  readonly constant: boolean;

  constructor(topic: string, detail: string, constant = true) {
    this.topic = topic;
    this.detail = detail;
    this.constant = constant;
  }
}

// @dynamic
export class TopicListener {
  topic: string;
  detailCompareFn: (topic: Topic) => boolean;
  topicAction: (control: AbstractControl, msg: any, modelFragment?: ModelFragment) => void;
  attachedControl: AbstractControl;
  attachedModelFragment: ModelFragment;

  // @dynamic
  static IGNORE_DETAIL = (t: Topic) => true;

  constructor(
    topic: string,
    topicCompareFn: (topic: Topic) => boolean,
    topicAction: (control: AbstractControl, msg: any, modelFragment?: ModelFragment) => void
  ) {
    this.topic = topic;
    this.detailCompareFn = topicCompareFn;
    this.topicAction = topicAction;
  }

  attachControl(control: AbstractControl) {
    this.attachedControl = control;
  }

  attachModelFragment(modelFragment: ModelFragment) {
    this.attachedModelFragment = modelFragment;
  }

  receive(topic: Topic, message: any) {
    if (this.detailCompareFn(topic)) {
      this.topicAction(this.attachedControl, message, this.attachedModelFragment);
    }
  }
}
