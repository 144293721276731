import {ControlContainer} from "@angular/forms";
import {Component, Input} from "@angular/core";
import {QuestionComponent, QuestionComponentData} from "ee-lahete";


@Component({
  selector: 'question-select',
  template: `
    <ng-container [formGroup]="controlContainer?.control">
      <div class="form-group">
        <label [htmlFor]="questionData.htmlId">{{questionData.label}}</label>
        <select [formControlName]="questionData.formControlName" class="form-control" [id]="questionData.htmlId">
          <option *ngFor="let option of questionData.question.options.selection" [value]="option.value">
            {{option.label}}
          </option>
        </select>
      </div>
    </ng-container>
  `
})
export class QuestionSelectComponent implements QuestionComponent {
  @Input() questionData: QuestionComponentData;

  constructor(public controlContainer: ControlContainer) {
  }
}
