import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Kasvilaji} from '../../../kasvi/kasvilaji.model';
import {Kasvilajike} from '../../../kasvi/kasvilajike.model';

@Component({
  selector: 'touko-uusi-kasvilaji-modal',
  template: `
    <div class="modal-header">
      <legend [attribute]="otsikkoKey">Luo uusi kasvilaji ja lajike</legend>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label><span attribute="luomu.lupaLaji">Laji</span></label>
          <input class="form-control" [disabled]="!kasvilajiAsetettavissa" [(ngModel)]="laji.nimi"  >
        </div>
      </div>
      <div class="form-row" *ngIf="!vainLaji">
        <div class="col-md-12 mb-3">
          <label><span attribute="luomu.lupaLajike">Lajike</span></label>
          <input class="form-control" [(ngModel)]="lajike.kuvaus" >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="tallenna()" attribute="teksti.tallenna">Tallenna</button>
    </div>
  `,
  styleUrls: ['./touko-uusi-kasvi-laji-modal.component.scss'],
})

export class ToukoUusiKasvilajiModal {
  @Input() laji: Kasvilaji = {elmoid: "", nimi: ""};
  lajike: Kasvilajike = {elmoid: "", kuvaus: ""};

  @Input() vainLaji = false;

  get kasvilajiAsetettavissa(): boolean {
    return this.vainLaji || (!this.laji || !this.laji.elmoid || this.laji.elmoid.trim() === "");
  }

  get otsikkoKey(): string {
    return (this.vainLaji) ? "luomu.uusiKasvilaji" : (this.kasvilajiAsetettavissa) ? "luomu.uusiKasvilajiLajike" : "luomu.uusiKasvilajike";
  }

  constructor(public activeModal: NgbActiveModal) {}

  public tallenna() {

    const paluu: ToukoUusiKasviLajiModalResponse = {
      laji: this.laji,
      lajike: this.lajike
    };

    this.activeModal.close(paluu);
  }
}


export interface ToukoUusiKasviLajiModalResponse {
  laji: Kasvilaji;
  lajike: Kasvilajike;
}
