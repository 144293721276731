import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FieldArray} from "ngx-fielding";
import {ControlContainer} from "@angular/forms";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../modaalit/modal-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Kasvilajike} from "../../kasvi/kasvilajike.model";
import {Kasvilaji} from "../../kasvi/kasvilaji.model";
import {ToukoKasvilajikeValintaComponent} from "../syote/custom/touko-kasvilajike-valinta.component";
import {LiitetiedostoResponse} from "../touko-lomake-utils";
import {AttributeService} from "../../tiira/attribute.service";
import {Teksti} from "../../utils/teksti";


abstract class Tyyppi {
  static SIEMEN = 'SIEMEN';
  static TAIMI = 'TAIMI';
}

@Component({
  selector: 'luomulupa',
  templateUrl: './luomulupa.component.html',
  styleUrls: ['./luomulupa.component.scss'],
})
export class LuomulupaComponent {
  @Input() lomakeId: number;
  @Output() liiteOut: EventEmitter<LiitetiedostoResponse[]> = new EventEmitter();

  tyyppiLabel: string = "kiloa";

  constructor(
      private controlcontainer: ControlContainer,
      private modalService: NgbModal,
      private attributeService: AttributeService) {
  }

  get luomuluvat(): FieldArray {
    return this.controlcontainer.control.get("luvatTavanomLisaysaineisto") as FieldArray;
  }

  naytaPoistaNappi(): boolean {
    const luvat = this.controlcontainer.control.get("luvatTavanomLisaysaineisto") as FieldArray;
    return !(luvat && luvat.length == 1);
  }

  poistaLupa(index: number): void {
    const luvat = this.controlcontainer.control.get("luvatTavanomLisaysaineisto") as FieldArray;
    luvat.removeAt(index);
  }

  validateKayttomaara(lupaIndex: number) {
    const lupaTiedotControl = this.luomuluvat.asArray().controlFields[lupaIndex];
    const kayttomaaraValue: number = lupaTiedotControl.get('kayttomaara')?.value;
    const aineistoTyyppi = lupaTiedotControl.get('tyyppi')?.value as Tyyppi;

    if (kayttomaaraValue) {
      let validated: string = String(kayttomaaraValue).valueOf();

      // Poistetaan negatiiviset
      validated = validated.replace('-', '');

      // Hyväksytään max 10 merkkiä pitkä syöte
      if (validated.length > 10) {
        validated = validated.slice(0, 10);
      }

      // Pyöristetään lähimpään kokonaislukuun, jos kysytään kappalemäärää
      if (aineistoTyyppi == Tyyppi.TAIMI) {
        validated = String(Math.round(Number(validated)));
      }

      if (validated !== String(kayttomaaraValue)) {
        lupaTiedotControl.get('kayttomaara').setValue(Number(validated));
      }
    }
  }

  uusiKasvilajike(kasvilajike: Kasvilajike, index: number) {
    const lisaysaineistoArray = this.controlcontainer.control.get('luvatTavanomLisaysaineisto') as FieldArray
    const lajikecontrol = lisaysaineistoArray.controlFields[index].get('lajike');
    lajikecontrol.setValue(kasvilajike, true);
  }

  lajiValittu(laji: Kasvilaji, lajikeRef: ToukoKasvilajikeValintaComponent) {
    lajikeRef.kasvilaji = laji;
  }

  asetaLaji(lajikeRef: ToukoKasvilajikeValintaComponent, groupIndex: number) {
    const lajiControl = this.luomuluvat.asArray().controlFields[groupIndex].get('laji');
    lajikeRef.kasvilaji = lajiControl?.value;
  }

  getMaaraTyyppiLabel(lupaIndex: number): void {
    const aineistoTyyppi = this.luomuluvat.asArray().controlFields[lupaIndex].get('tyyppi').value as Tyyppi;
    switch (aineistoTyyppi) {
      case Tyyppi.SIEMEN:
        this.tyyppiLabel = 'kiloa';
        break;
      case Tyyppi.TAIMI:
        this.tyyppiLabel = 'kappaletta';
        break;
    }
  }

  showHelpModal() {
    const modalRef = this.modalService.open(ModalSimpleComponent);

    modalRef.componentInstance.modalTiedot = new ModalData(
        new Teksti(this.attributeKaantaja('ohjeOtsikko')),
        new Teksti(this.attributeKaantaja('ohje1_1')),
        new Teksti(this.attributeKaantaja('suljeOhje')),
        null,
        '',
        '',
        true, // Modaalissa näytetään pelkästään sulje painike
        new Teksti(this.attributeKaantaja('sulje'))
    );
  }

  onSetLiite(event: LiitetiedostoResponse[]) {
    this.liiteOut.emit(event);
  }

  attributeKaantaja(key: string, defaultText?: string): string {
    return this.attributeService.simpleTranslate("luomuPoikkeus9c", key, false, defaultText)
  }
}
