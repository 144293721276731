import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NayteTutkimusValue} from "../../lahete/lahete-data.model";

@Component({
  selector: 'question-nayte-modal',
  template: `
    <div class="modal-header">
      <h1 class="modal-title" attribute="sahkoelain.nayteModalOtsikko">Valitse näyte</h1>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="select-nayte">
                  <span attribute="sahkoelain.nayteModalValitseNayteTutkimus">Valitse näyte tukimukselle</span>
                  <em> {{currentTutkimus}}</em>
                </label>
                <select id="select-nayte" class="form-control" name="nayte" [(ngModel)]="selectedNayte">
                  <option *ngFor="let option of selectOptions" [ngValue]="option">
                    {{'sahkoelain.nayte' | attribute}}: {{option.nayteTunnus}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button attribute="sivupalkki.modalPeruuta" type="button" class="btn btn-light" (click)="activeModal.dismiss('Peruuta')">Peruuta</button>
        <button attribute="sahkoelain.nayteModalValitse" [disabled]="!selectedNayte" type="button" class="btn btn-primary" (click)="submit()">Hyväksy</button>
      </div>
    </form>
  `
})
export class QuestionNayteModalComponent {
  @Input() currentTutkimus: string;
  @Input() selectOptions: NayteTutkimusValue[];
  selectedNayte: NayteTutkimusValue;

  constructor(public activeModal: NgbActiveModal) {
  }

  submit() {
    this.activeModal.close(this.selectedNayte);
  }

}
