import {Component, Input, OnInit} from "@angular/core";
import {FieldArray, FieldGroup, SummaryItem} from "ngx-fielding";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'tilan-elaimet-summary',
  template: `
    <h3 attribute="luomu.tilanElaimet"></h3>

    <div class="row mb-2">
      <div attribute="luomu.luonnonmukainenLkm" class="col-2 offset-4 font-weight-semibold"></div>
      <div attribute="luomu.tavanomainenLkm" class="col-2 font-weight-semibold"></div>
    </div>
    <div class="row">
      <div class="col-4">
        <div *ngFor="let elainLabel of elainlabels" [id]="elainLabel" [attribute]="'luomu.' + elainLabel"
             class="mb-2"></div>
        <div *ngFor="let elainLabel of muuElainlabels; index as i" [id]="'muuElain-' + i"
             class="mb-2">{{ elainLabel }}
        </div>
      </div>
      <div class="col-4">
        <div *ngFor="let elain of tilanElaimet; index as i" class="row mb-2">
          <div class="col-6">
            <span *ngIf="elain.get('luonnonmukainenLkm').value" [id]="'luonnonmukainenLkm-' + i"
                  aria-hidden="true">{{ elain.get('luonnonmukainenLkm').value }} </span>
          </div>
          <div class="col-6">
            <span *ngIf="elain.get('tavanomainenLkm').value" [id]="'tavanomainenLkm' + i"
                  aria-hidden="true">{{ elain.get('tavanomainenLkm').value }}</span>
          </div>
        </div>
      </div>
    </div>
    <hr>
  `
})

export class TilanElaimetSummaryComponent implements OnInit {
  @Input() item: SummaryItem<any>;
  elainlabels: string[] = [];
  tilanElaimet: AbstractControl[] = [];
  muuElainlabels: string[] = [];

  ngOnInit() {
    Object.entries(this.item.answer.asGroup().controlFields).forEach(elain => {
      const key = elain[0];
      const value = elain[1] as FieldGroup;

      if (key !== "muutElaimet" && value.get('elainValittu').value) {
        this.tilanElaimet.push(value);
        this.elainlabels.push(key);
      }
      if (key === "muutElaimet") {
        const muutElaimet = elain[1] as FieldArray;
        muutElaimet.controlFields.forEach(muuElaintuote => {
          if (muuElaintuote.get('nimi').value) {
            this.muuElainlabels.push(muuElaintuote.get('nimi').value);
            this.tilanElaimet.push(muuElaintuote);
          }
        })
      }
    })
  }
}
