import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Yritys} from "./yritys";
import {Valvontakohde} from "../../valvontakohde/valvontakohde";

@Injectable({
  providedIn: 'root'
})
export class RaportitVirkailijaService {

  constructor(private readonly http: HttpClient) { }

  getYritykset(): Promise<Yritys[]> {
    return this.http.get("/api/v1/vk/yritykset")
      .toPromise()
      .then(response => response as Yritys[]);
  }

  getSahkoinenAsiointiToimijat(): Promise<Array<any>> {
    return this.http.get("/api/v1/vk/yritykset/sahkoinenasiointi")
      .toPromise()
      .then(response => response as Array<any>);
  }

  getValvontakohteet(ytunnus: string): Promise<Valvontakohde[]> {
    return this.http.get("/api/v1/vk/valvontakohteet", {params: {ytunnus}})
      .toPromise()
      .then(response => response as Valvontakohde[]);
  }
}
