/**
 * Created by Seppo on 15/11/2017.
 */

import {Component, Input} from "@angular/core";
import {Teksti} from "../../utils/teksti";

/**
 * Komponentin avulla syötteiden visuaalisen ilmeen voi yhdenmukaistaa.
 * TODO: Eri tyyppisillä syötteillä eri layout?
 */
@Component({
  selector: 'syote-layout',
  template: `
    <label class="form-check-label">
      <ng-content></ng-content><span [attribute]="labelKey || label?.label">{{label?.label}}</span>
    </label>
  `
})
export class SyoteLayoutComponent {
  @Input() label: Teksti;
  @Input() labelKey: string = null;
}
