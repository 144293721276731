import {TulkkiObject} from "../common/lomake-field-utils";
import {Field, FieldArray, FieldControl, FieldGroup} from "ngx-fielding";
import {
  createLuovutuksetGroupWithGroups,
  createMaaraJaAlkuperaControls,
  createPaaravinteidenMaara
} from "./lannoitevuosiilmoitus-data-utils";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

export const createMuuToiminta = (): FieldGroup => {
  const muuToiminta = new FieldGroup(
    Field.build(attr("")),
    {
      lannoitevalmisteenMarkkinointi: new FieldArray(
        Field.build(attr("lannoiteValmisteenTuonti")),
        () => createMaahantuontiGroup(),
        [createMaahantuontiGroup()]
      ),
    }
  )

  return muuToiminta;
}

const createMaahantuontiGroup = (): FieldGroup => {
  return new FieldGroup(
    Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(),
        {
          ...createMaaraJaAlkuperaControls(),
          lahtomaa: new FieldControl(Field.build()),
          alperamaa: new FieldControl(Field.build()),
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}
