/**
 * Created by Seppo on 15/11/2017.
 */


import {Component, Input, OnInit} from "@angular/core";
import {LomakeBaseService} from "./lomake-base.service";
import {Teksti} from "../utils/teksti";

/**
 * Lomakkeiden virheet
 */
@Component({
  selector: 'lomake-error',
  template: `
    <touko-alert-box *ngIf="virheet.length > 0" alertType="error">
      <div *ngFor="let virhe of virheet" [attribute]="virheSanomat[virhe]?.getFullKey()"></div>
    </touko-alert-box>
  `
})
export class LomakeErrorComponent {
  @Input() virheet: any = [];
  @Input() virheSanomat: { [key: string]: Teksti };
}
