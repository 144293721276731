import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Teksti} from "../utils/teksti";

/**
 * Created by Seppo on 01/12/2017.
 */

@Injectable()
export class MessageService {

  private static id = 0;
  static readonly DO_NOT_AUTOCLOSE = -1;
  static readonly DEFAULT_TIMEOUT = 5000;
  static readonly DEFAULT_TIMEOUT_WARNING = 10000;
  static readonly DEFAULT_TIMEOUT_DANGER = -1;

  private _latestError = "";

  private _messages = new BehaviorSubject<{ id: number; message: string | Teksti; type: string }[]>([]);
  private messages: {id: number; message: string | Teksti; type: string; doNotAutoClose: boolean}[] = [];

  messages$ = this._messages.asObservable();

  get latestError(): string {
    return this._latestError;
  }

  set latestError(value: string) {
    this._latestError = value;
  }

  public notify(m: { message: string | Teksti; type?: string; timeout?: number }): number {
    let notificationId;

    switch (m.type.toLowerCase()) {
      case "danger": {
        notificationId = this.addMessage(
          m.message,
          (typeof m.timeout === 'undefined') ? MessageService.DEFAULT_TIMEOUT_DANGER : m.timeout,
          m.type,
          (typeof m.timeout === 'undefined') ? true : (m.timeout === MessageService.DO_NOT_AUTOCLOSE) );
        break;
      }
      case "warning": {
        notificationId = this.addMessage(
          m.message,
          (typeof m.timeout === 'undefined') ? MessageService.DEFAULT_TIMEOUT_WARNING : m.timeout,
          m.type,
          (typeof m.timeout === 'undefined') ? false : (m.timeout === MessageService.DO_NOT_AUTOCLOSE) );
        break;
      }
      default: {
        notificationId = this.addMessage(
          m.message,
          (typeof m.timeout === 'undefined') ? MessageService.DEFAULT_TIMEOUT : m.timeout,
          m.type,
          (typeof m.timeout === 'undefined') ? false : (m.timeout === MessageService.DO_NOT_AUTOCLOSE) );
        break;
      }
    }

    return notificationId;
  }

    /**
     * Lisää+näyttää käyttäjälle uuden notifikaatioviestin
     *
     * @param message - viesti string tai Teksti-objektina
     * @param timeout - aika (ms) jonka viesti näkyy käyttäjälle (Huom! tällä arvolla ole väliä jos doNotAutoClose == true)
     * @param type - viestin tyyppi; info, warning tai danger
     * @param doNotAutoClose - onko viesti suljettava käyttäjän toimesta (Huom! saa arvon true jos type == danger eikä doNotAutoClose ole spesifisesti asetettu)
     */
    public addMessage(message: string | Teksti, timeout = MessageService.DEFAULT_TIMEOUT, type: string = "warning", doNotAutoClose?: boolean): number {

    const msg = {message,
                 id: MessageService.id,
                 type: type.toLowerCase(),
                 doNotAutoClose: (typeof doNotAutoClose === 'undefined') ? type.toLowerCase() === 'danger' : doNotAutoClose };

    const msgInMessages = this.messages.find(m => {
      if (message instanceof Teksti && m.message instanceof Teksti) {
        return message.getFullKey() === m.message.getFullKey();
      }
      return m.message === msg.message;
    });

      if (msgInMessages) {
        return;
      }

    MessageService.id += 1;
    this.messages.push(msg);
    this._messages.next(this.messages);

      if (!msg.doNotAutoClose) {
      setTimeout(() => {
        this.messages = this.messages.filter(m => m.id !== msg.id);
        this._messages.next(this.messages);
      }, timeout);
    }

    return msg.id;
  }

  public closeMessage(id: number) {
    this.messages = this.messages.filter(m => m.id !== id);
    this._messages.next(this.messages);
  }
}
