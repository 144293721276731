import {Teksti} from "./teksti";
import {Option} from "../touko-components/select-menu.component";

export  class Tyyppinimet {

  static getTyyppinimetTeksti() {
    return [
      new Teksti('',  'typpilannoite', 'lannoiterekisteri'),
      new Teksti('',  'apatiittiaSisaltavaFosforilannoite', 'lannoiterekisteri'),
      new Teksti('',  'typpilannoiteNitraatiton', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoite', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoiteKrotonylideenidiureaaIsobutylideenidiureaaUreaformaldehydiä', 'lannoiterekisteri'),
      new Teksti('',  'kalkitsevaNpkLannoite', 'lannoiterekisteri'),
      new Teksti('',  'npkKasviravinne', 'lannoiterekisteri'),
      new Teksti('',  'npkKasviravinneliuos', 'lannoiterekisteri'),
      new Teksti('',  'npkKasviravinnesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'sivuravinnelannoite', 'lannoiterekisteri'),
      new Teksti('',  'sivuravinneliuos', 'lannoiterekisteri'),
      new Teksti('',  'hivenravinnelannoite', 'lannoiterekisteri'),
      new Teksti('',  'hivenravinneliuos', 'lannoiterekisteri'),
      new Teksti('',  'piilannoite', 'lannoiterekisteri'),
      new Teksti('',  'nestemainenPiilannoite', 'lannoiterekisteri'),
      new Teksti('',  'viljaprosessoinninSivujakeidenTuhka', 'lannoiterekisteri'),
      new Teksti('',  'puunTurpeenTaiPeltobiomassojenTuhka', 'lannoiterekisteri'),
      new Teksti('',  'elainperainenTuhka', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenElainperainenLannoite', 'lannoiterekisteri'),
      new Teksti('',  'teknisestiKasiteltyLanta', 'lannoiterekisteri'),
      new Teksti('',  'hoyhenjauho', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenElainperainenLannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenLannoite', 'lannoiterekisteri'),
      new Teksti('',  'sienibiomassa', 'lannoiterekisteri'),
      new Teksti('',  'bakteeribiomassa', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenLannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'merilevajauhe', 'lannoiterekisteri'),
      new Teksti('',  'merilevauute', 'lannoiterekisteri'),
      new Teksti('',  'betaiini', 'lannoiterekisteri'),
      new Teksti('',  'humusvalmisteTaiUute', 'lannoiterekisteri'),
      new Teksti('',  'alkoholienVesiliuosTaiOljyemissio', 'lannoiterekisteri'),
      new Teksti('',  'kasviuute', 'lannoiterekisteri'),
      new Teksti('',  'aminohappovalmiste', 'lannoiterekisteri'),
      new Teksti('',  'melassiuute', 'lannoiterekisteri'),
      new Teksti('',  'vinassiJaVinassiuute', 'lannoiterekisteri'),
      new Teksti('',  'perunanSoluneste', 'lannoiterekisteri'),
      new Teksti('',  'rejektivesi', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenElainperainenKivennaislannoite', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenKivennaislannoite', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenKivennaislannoiteneste', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenElainperainenKivennaislannoiteneste', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenHivenravinnelannoite', 'lannoiterekisteri'),
      new Teksti('',  'epaorgaaninenOrgaanistaAinestaSisaltavaMoniravinnelannoite', 'lannoiterekisteri'),
      new Teksti('',  'epaorgaaninenOrgaanistaAinestaSisaltavaMoniravinneliuos', 'lannoiterekisteri'),
      new Teksti('',  'kalkitsevaElainperainenKivennaislannoite', 'lannoiterekisteri'),
      new Teksti('',  'kalkitsevaOrgaaninenKivennaislannoite', 'lannoiterekisteri'),
      new Teksti('',  'kalkkikivi', 'lannoiterekisteri'),
      new Teksti('',  'dolomiittikalkkikivi', 'lannoiterekisteri'),
      new Teksti('',  'tuhkapitoinenKalkkirae', 'lannoiterekisteri'),
      new Teksti('',  'biotiitti', 'lannoiterekisteri'),
      new Teksti('',  'kalkitusaineenSeos', 'lannoiterekisteri'),
      new Teksti('',  'sokeritehdaskalkki', 'lannoiterekisteri'),
      new Teksti('',  'masuunikuona', 'lannoiterekisteri'),
      new Teksti('',  'teraskuona', 'lannoiterekisteri'),
      new Teksti('',  'meesakalkki', 'lannoiterekisteri'),
      new Teksti('',  'kalkkikiviseos', 'lannoiterekisteri'),
      new Teksti('',  'pccKalkinSaostusjaannos', 'lannoiterekisteri'),
      new Teksti('',  'kalkkiuuninSuodinpoly', 'lannoiterekisteri'),
      new Teksti('',  'kalkinSammutusjaannos', 'lannoiterekisteri'),
      new Teksti('',  'kalkinRikastusjaannos', 'lannoiterekisteri'),
      new Teksti('',  'kalkkipitoinenKiviaines', 'lannoiterekisteri'),
      new Teksti('',  'sokeritehtaanPuhdistamokalkki', 'lannoiterekisteri'),
      new Teksti('',  'sellaisenaanKalkitusaineenaKaytettavienKalkitusaineidenSeos', 'lannoiterekisteri'),
      new Teksti('',  'munankuorikalkki', 'lannoiterekisteri'),
      new Teksti('',  'karbidikalkki', 'lannoiterekisteri'),
      new Teksti('',  'maanparannusturve', 'lannoiterekisteri'),
      new Teksti('',  'kalkittuMaanparannusturve', 'lannoiterekisteri'),
      new Teksti('',  'maanparannuskomposti', 'lannoiterekisteri'),
      new Teksti('',  'maanparannuskompostiJokaSisaltaaJatevesilietetta', 'lannoiterekisteri'),
      new Teksti('',  'lantaseos', 'lannoiterekisteri'),
      new Teksti('',  'tuorekomposti', 'lannoiterekisteri'),
      new Teksti('',  'tuorekompostiJokaSisaltaaJatevesilietetta', 'lannoiterekisteri'),
      new Teksti('',  'kasvijatekomposti', 'lannoiterekisteri'),
      new Teksti('',  'kuivaraeTaiJauhe', 'lannoiterekisteri'),
      new Teksti('',  'kuivaraeTaiJauheJokaSisaltaaJatevesilietetta', 'lannoiterekisteri'),
      new Teksti('',  'hapotettuJaStabiloituPuhdistamoliete', 'lannoiterekisteri'),
      new Teksti('',  'maanparannuslahote', 'lannoiterekisteri'),
      new Teksti('',  'parannettuKuituliete', 'lannoiterekisteri'),
      new Teksti('',  'vanhennettuPuhdistamoliete', 'lannoiterekisteri'),
      new Teksti('',  'katemateriaali', 'lannoiterekisteri'),
      new Teksti('',  'maanRakennettaParantavaKivennaisaine', 'lannoiterekisteri'),
      new Teksti('',  'kasviperainenKasvualustahiili', 'lannoiterekisteri'),
      new Teksti('',  'imeytysrouhe', 'lannoiterekisteri'),
      new Teksti('',  'mineraaliaktivaattori', 'lannoiterekisteri'),
      new Teksti('',  'orgaaninenKompostointivalmiste', 'lannoiterekisteri'),
      new Teksti('',  'biodynaaminenRuiskute', 'lannoiterekisteri'),
      new Teksti('',  'biodynaaminenKompostipreparaatti', 'lannoiterekisteri'),
      new Teksti('',  'kalkkistabiloituPuhdistamoliete', 'lannoiterekisteri'),
      new Teksti('',  'madatysjaannos', 'lannoiterekisteri'),
      new Teksti('',  'lahotettuPuhdistamoliete', 'lannoiterekisteri'),
      new Teksti('',  'kuituliete', 'lannoiterekisteri'),
      new Teksti('',  'kaytettySienimoalusta', 'lannoiterekisteri'),
      new Teksti('',  'kaytettyTurvekasvualusta', 'lannoiterekisteri'),
      new Teksti('',  'kemiallisestiHapetettuPuhdistamoliete', 'lannoiterekisteri'),
      new Teksti('',  'kipsi', 'lannoiterekisteri'),
      new Teksti('',  'typpibakteerivalmiste', 'lannoiterekisteri'),
      new Teksti('',  'mykorritsavalmiste', 'lannoiterekisteri'),
      new Teksti('',  'mikrobiologinenValmiste', 'lannoiterekisteri'),
      new Teksti('',  'kasvienKasvuaEdistavaMikrobivalmiste', 'lannoiterekisteri'),
      new Teksti('',  'kasvuturve', 'lannoiterekisteri'),
      new Teksti('',  'turveseos', 'lannoiterekisteri'),
      new Teksti('',  'erityiskasvualustatuote', 'lannoiterekisteri'),
      new Teksti('',  'lannoitettuJaKalkittuIrtomulta', 'lannoiterekisteri'),
      new Teksti('',  'kompostimulta', 'lannoiterekisteri'),
      new Teksti('',  'juuresmulta', 'lannoiterekisteri'),
      new Teksti('',  'pakattuKukkamulta', 'lannoiterekisteri'),
      new Teksti('',  'pakattuSeosmulta', 'lannoiterekisteri'),
      new Teksti('',  'pakattuErityiskasvualusta', 'lannoiterekisteri'),
      new Teksti('',  'teknisestiKasiteltyIrtomulta', 'lannoiterekisteri'),
      new Teksti('',  'kivennaiskasvualusta', 'lannoiterekisteri'),
      new Teksti('',  'kasvatusliuos', 'lannoiterekisteri'),
      new Teksti('',  'kookoskasvualusta', 'lannoiterekisteri'),
      new Teksti('',  'kasvikuitukasvualusta', 'lannoiterekisteri'),
      new Teksti('',  'sammalkasvualusta', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumnitraatti', 'lannoiterekisteri'),
      new Teksti('',  'kalsiummagnesiumnitraatti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumnitraatti', 'lannoiterekisteri'),
      new Teksti('',  'natriumnitraatti', 'lannoiterekisteri'),
      new Teksti('',  'chilensalpietari', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumsyanamidi', 'lannoiterekisteri'),
      new Teksti('',  'typpipitoinenKalsiumsyanamidi', 'lannoiterekisteri'),
      new Teksti('',  'ammoniumsulfaatti', 'lannoiterekisteri'),
      new Teksti('',  'ammoniumnitraattiTaiKalsiumammoniumnitraatti', 'lannoiterekisteri'),
      new Teksti('',  'ammoniumsulfaattinitraatti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumsulfonitraatti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumammoniumnitraatti', 'lannoiterekisteri'),
      new Teksti('',  'urea', 'lannoiterekisteri'),
      new Teksti('',  'krotonylideenidiurea', 'lannoiterekisteri'),
      new Teksti('',  'isobutylideenidiurea', 'lannoiterekisteri'),
      new Teksti('',  'ureaformaldehydi', 'lannoiterekisteri'),
      new Teksti('',  'typpilannoiteJokaSisaltaaKrotonylideenidiureaa', 'lannoiterekisteri'),
      new Teksti('',  'typpilannoiteJokaSisaltaaIsobutylideenidiureaa', 'lannoiterekisteri'),
      new Teksti('',  'typpilannoiteJokaSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'ureaAmmoniumsulfaatti', 'lannoiterekisteri'),
      new Teksti('',  'emaksinenKuona', 'lannoiterekisteri'),
      new Teksti('',  'normaaliSuperfosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'vakevaSuperfosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'kaksoissuperfosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'osittainLiuennutRaakafosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumiaSisaltavaOsittainLiuennutRaakafosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'dikalsiumfosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'kalsinoituFosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'alumiinikalsiumfosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'pehmeaJauhettuRaakafosfaatti', 'lannoiterekisteri'),
      new Teksti('',  'luonnonKaliumsuola', 'lannoiterekisteri'),
      new Teksti('',  'rikastettuRaakaKaliumsuola', 'lannoiterekisteri'),
      new Teksti('',  'kalisuola', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumsuolojaSisaltavaKaliumkloridi', 'lannoiterekisteri'),
      new Teksti('',  'kaliumsulfaatti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumsuolaaSisaltavaKaliumsulfaatti', 'lannoiterekisteri'),
      new Teksti('',  'kaliumsulfaattiaSisaltavaKiseriitti', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoiteSisaltaaKrotonylideenidiureaaIsobutylideenidiureaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'npLannoite', 'lannoiterekisteri'),
      new Teksti('',  'npLannoiteSisaltaaKrotonylideenidiureaaIsobutylideenidiureaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'nkLannoite', 'lannoiterekisteri'),
      new Teksti('',  'nkLannoiteSisaltaaKrotonylideenidiureaaIsobutylideenidiureaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'pkLannoite', 'lannoiterekisteri'),
      new Teksti('',  'typpilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'ureaAmmoniumnitraattilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumnitraattiliuos', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumnitraattiliuos', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumnitraattisuspensio', 'lannoiterekisteri'),
      new Teksti('',  'ureaformaldehydiaSisaltavaTyppilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'ureaformaldehydiaSisaltavaTyppilannoitesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoiteliuosSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoitesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'npkLannoitesuspensioSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'npLannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'npLannoitesuspensioSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'npLannoitesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'npLannoitesuspensioSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'nkLannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'nkLannoiteliuosSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'nkLannoitesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'nkLannoitesuspensioSisaltaaUreaformaldehydia', 'lannoiterekisteri'),
      new Teksti('',  'pkLannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'pkLannoitesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumsulfaatti', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumkloridiliuos', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumformiaatti', 'lannoiterekisteri'),
      new Teksti('',  'nestemainenKalsiumformiaatti', 'lannoiterekisteri'),
      new Teksti('',  'rikkiAlkuaine', 'lannoiterekisteri'),
      new Teksti('',  'kiseriitti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumsulfaatti', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumsulfaattiliuos', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumhydroksidi', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumhydroksidisuspensio', 'lannoiterekisteri'),
      new Teksti('',  'magnesiumkloridiliuos', 'lannoiterekisteri'),
      new Teksti('',  'boorihappo', 'lannoiterekisteri'),
      new Teksti('',  'natriumboraatti', 'lannoiterekisteri'),
      new Teksti('',  'kalsiumboraatti', 'lannoiterekisteri'),
      new Teksti('',  'boorietanoliamiini', 'lannoiterekisteri'),
      new Teksti('',  'boratoituLannoiteLiuoksena', 'lannoiterekisteri'),
      new Teksti('',  'boratoituLannoiteSuspensiona', 'lannoiterekisteri'),
      new Teksti('',  'kobolttisuola', 'lannoiterekisteri'),
      new Teksti('',  'kobolttikelaatti', 'lannoiterekisteri'),
      new Teksti('',  'kobolttilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'kobolttikompleksi', 'lannoiterekisteri'),
      new Teksti('',  'kuparisuola', 'lannoiterekisteri'),
      new Teksti('',  'kuparioksidi', 'lannoiterekisteri'),
      new Teksti('',  'kuparihydroksidi', 'lannoiterekisteri'),
      new Teksti('',  'kuparikelaatti', 'lannoiterekisteri'),
      new Teksti('',  'kuparipohjainenLannoite', 'lannoiterekisteri'),
      new Teksti('',  'kuparilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'kuparikloridioksidi', 'lannoiterekisteri'),
      new Teksti('',  'kuparikloridioksidiSuspensiona', 'lannoiterekisteri'),
      new Teksti('',  'kuparikompleksi', 'lannoiterekisteri'),
      new Teksti('',  'rautasuola', 'lannoiterekisteri'),
      new Teksti('',  'rautakelaatti', 'lannoiterekisteri'),
      new Teksti('',  'rautalannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'rautakompleksi', 'lannoiterekisteri'),
      new Teksti('',  'mangaanisuola', 'lannoiterekisteri'),
      new Teksti('',  'mangaanikelaatti', 'lannoiterekisteri'),
      new Teksti('',  'mangaanioksidi', 'lannoiterekisteri'),
      new Teksti('',  'mangaanipohjainenLannoite', 'lannoiterekisteri'),
      new Teksti('',  'mangaanilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'mangaanilannoiteSuspensiona', 'lannoiterekisteri'),
      new Teksti('',  'mangaanikompleksi', 'lannoiterekisteri'),
      new Teksti('',  'natriummolybdaatti', 'lannoiterekisteri'),
      new Teksti('',  'ammoniummolybdaatti', 'lannoiterekisteri'),
      new Teksti('',  'molybdeenipohjainenLannoite', 'lannoiterekisteri'),
      new Teksti('',  'molybdeenilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'sinkkisuola', 'lannoiterekisteri'),
      new Teksti('',  'sinkkikelaatti', 'lannoiterekisteri'),
      new Teksti('',  'sinkkioksidi', 'lannoiterekisteri'),
      new Teksti('',  'sinkkipohjainenLannoite', 'lannoiterekisteri'),
      new Teksti('',  'sinkkilannoiteliuos', 'lannoiterekisteri'),
      new Teksti('',  'sinkkilannoitesuspensio', 'lannoiterekisteri'),
      new Teksti('',  'sinkkikompleksi', 'lannoiterekisteri'),
      new Teksti('',  'kiinteaTaiNestemainenHivenravinnelannoiteseos', 'lannoiterekisteri'),
      new Teksti('',  'maahanLevitettavaEyLannoite', 'lannoiterekisteri'),
      new Teksti('',  'lehtisumutteenaKaytettavaEyLannoite', 'lannoiterekisteri'),
    ];
  }

  static getTyyppinimet() {
    return this.getTyyppinimetTeksti().map(tyyppinimi => this.createOption(tyyppinimi));
  }

  static createOption(name: Teksti): Option {
    return { label: name.fullKey, value: name.key };
  }

  static createMap(): {[key: string]: Teksti}{
    const tyyppinimiMap: {[key: string]: Teksti} = {};
    Tyyppinimet.getTyyppinimetTeksti().forEach(tyyppinimi => tyyppinimiMap[tyyppinimi.key] = tyyppinimi);
    return tyyppinimiMap;
  }
}

export const TYYPPINIMI_MAP = Tyyppinimet.createMap();
