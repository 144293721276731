<div>
  <h2 #lomakeKuvausH2 *ngIf="lomakeKasittely?.kuvaus" [attribute]="lomakeKasittely?.kuvaus.getFullKey()"></h2>
  <h2 *ngIf="!lomakeKasittely?.kuvaus">Lomakkeen tiedot</h2>
  <lomaketiedot id="lomaketiedot" [data]="lomakeKasittely"></lomaketiedot>
  <div #lomakeYhteenvetoDiv>
    <allekirjoitus id="allekirjoitus" [data]="allekirjoitus"></allekirjoitus>
    <yhteenveto [useImportedData]="true" [yhteenveto]="lopetusLomake"></yhteenveto>

    <hr>
    <yhteenveto [titleAttribute]="'lomakeYleinen.nykyinenToiminta'"
                [useImportedData]="true"
                [yhteenveto]="currentToiminta"></yhteenveto>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <virkailija-tiedot (onHyvaksy)="lahetaKasittely($event)"></virkailija-tiedot>
  </div>
</div>
