import {Component} from "@angular/core";
import {LomakeService} from "../touko-lomake.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "../../message/message.service";
import {LomakeBaseDirective, LomakeStatus} from "../touko-lomake-base";
import {AccountService} from "../../account/account.service";
import {YhteenvetoService} from "../yhteenveto/yhteenveto.service";
import {LomakeConverter, LomakeJSONOld, LomakeResponse} from "../touko-lomake-utils";
import {RekisteriLopetusData} from "./rekisteri-lopetus-data";
import {YhteenvetoGroup} from "../yhteenveto/yhteenveto-utils";
import {resolveLomakeData} from "../touko-lomakedata-constants";
import {Teksti} from "../../utils/teksti";
import {LomakeBaseService} from "../lomake-base.service";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {LomakeRedirectService} from "../lomake-redirect.service";
import {YhteyshenkiloViewOptions} from "../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";

/**
 * Created by Seppo on 08/01/2018.
 */

@Component({
  selector: 'rekisteri-lopetus',
  styleUrls: ['./rekisteri-lopetus.component.scss'],
  templateUrl: './rekisteri-lopetus.component.html',
})
export class RekisteriLopetusComponent extends LomakeBaseDirective {

  id: number;
  lomakeAsia: string;
  currentToiminta: YhteenvetoGroup[];
  toimiala = "";
  title: Teksti;
  yhteyshenkiloViewOptions = new YhteyshenkiloViewOptions(true, true, true, false);

  constructor(lomakeService: LomakeBaseService,
              messageService: MessageService,
              router: Router,
              activatedRoute: ActivatedRoute,
              accountService: AccountService,
              yhteenvetoService: YhteenvetoService,
              private readonly lomakeRedirectService: LomakeRedirectService
  ) {
    super(lomakeService as LomakeService, messageService, router, activatedRoute, accountService, yhteenvetoService);

    const account = this.accountService.getCurrentAccount();

    this.toimiala = account.toimiala;
    this.title = this.toimiala === "KATE" ?
      new Teksti("Rekisteröintiä edellyttävän toiminnan päättyminen", "toiminnanLopetusKate", "lomakeYleinen") :
      new Teksti("Toiminnan lopetus", "toiminnanLopetus", "lomakeYleinen");
    this.activatedRoute.params.subscribe(r => {
      const isV2Endpoint = LomakeEndpointUtil.shouldUseV2Endpoint(this.lomakeAsia, this.lomakeTyyppiVersio);
      this.lomakeAsia = r.asia;
      let toiminnanHakuFn;
      if (isV2Endpoint) {
        toiminnanHakuFn = this.accountService.isAsiakas() ?
          this.lomakeService.getMuutoslomakeTiedotV2.bind(this.lomakeService, this.lomakeAsia, this.toimintotyyppi, false) :
          this.lomakeService.getToiminnanTiedotViranomainenV2.bind(this.lomakeService, r.id, this.lomakeAsia, this.toimintotyyppi);
      } else {
        toiminnanHakuFn = this.accountService.isAsiakas() ?
          this.lomakeService.getMuutoslomakeTiedot.bind(this.lomakeService, this.lomakeAsia, false) :
          this.lomakeService.getToiminnanTiedotViranomainen.bind(this.lomakeService, r.id);
      }
      toiminnanHakuFn().then(values => {
        if (isV2Endpoint) {
          this.currentToiminta = this.yhteenvetoService.getYhteenveto(LomakeConverter.convertToJSON(this.lomakeAsia, JSON.parse(values.sisalto).data), resolveLomakeData(this.lomakeAsia));
        } else {
          this.currentToiminta = this.yhteenvetoService.getYhteenveto(JSON.parse(values.sisalto) as LomakeJSONOld, resolveLomakeData(this.lomakeAsia));
        }
      });
    });

    this.toimintotyyppi = 'lopetus';
    this.lomakeData = RekisteriLopetusData.getData(account);

    this.trackPerustietoChanges = false;

    this.afterInit = this.afterInit.bind(this);
    this.saveLomake = this.saveLomake.bind(this);
    this.siirryTarkastukseen = this.siirryTarkastukseen.bind(this);
  }

  protected sendLomake(status: LomakeStatus): Promise<LomakeResponse> {
    const isViranomainen = !this.accountService.isAsiakas();
    const requestBody = this.lomakeService.createLomakeRequest(this.convertLomakeToJSON(), this.liitetiedostot, null, this.yhteyshenkilot);
    if (status === LomakeStatus.KESKEN || isViranomainen) {
      return this.lomakeService.tallennaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    } else {
      return this.lomakeService.lahetaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    }
  }

  afterInit() {
    super.afterInit();
    this.lomakeValues.get(['lisatiedot', 'lisatieto']).valueChanges.subscribe(() => {
      this.lomakeValues.get('lopetustoiminta').updateValueAndValidity();
    });
  }

  convertLomakeToJSON() {
    if (this.lomakeAsia === 'kylvosiemen-markkinointi' || this.lomakeAsia === 'rehuvalvonta' || this.lomakeAsia === 'lannoiterekisteri') {
      return {title: this.lomakeAsia, data: this.lomakeValues.getRawValue()};
    }
    return super.convertLomakeToJSON();
  }

  updateYhteenvetoValues() {
    this.yhteenvetoValues = super.convertLomakeToJSON();
    this.yhteenvetoService.updateLomakeArvot(this.yhteenvetoValues);
  }

  siirryTarkastukseen() {
    const urlSegments = this.router.url.split('/');
    const route = ['/asiointi/lomake/tarkastus']
      .concat(urlSegments.slice(3, 7));
    this.lomakeRedirectService.canRedirect = true;
    return Promise.resolve(this.saveLomake()).then(() => this.router.navigate(route));
  }
}
