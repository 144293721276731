import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import {CustomDateFormatter, CustomDatepickerI18n} from "../utils/localizedDates";
import {DateObj} from "../touko-lomake/syote/syote-utils";

@Component({
  selector: 'date',
  template: `
    <div *ngIf="label" class="block">
      <label [attribute]="label" [htmlFor]="'date-input-'+htmlIdSuffix" class="{{style}}">{{ label }}</label>
    </div>
    <div class="block max-width">
      <div class="input-group">
        <input id="date-input-{{htmlIdSuffix}}" [ngClass]="{'is-invalid': isInValid}" class="form-control"
               [attr.aria-labelledby]="labelledBy ? (labelledBy | attribute) : null"
               [attr.aria-label]="ariaLabel ? (ariaLabel | attribute) : null"
               ngbDatepicker #datepicker="ngbDatepicker"
               [navigation]="navigation"
               [maxDate]="maxDate"
               [minDate]="minDate"
               [markDisabled]="isDisabled"
               (ngModelChange)="writeValue($event)"
               placeholder="{{placeholder}}"
               [(ngModel)]="fieldValue"
               [disabled]="inputDisabled"
               (blur)="_onTouched($event)"/>
        <div class="input-group-append">
          <button id="cal-open-{{htmlIdSuffix}}" type="button" class="btn btn-primary form-control"
                  [disabled]="inputDisabled"
                  (click)="datepicker.toggle()" [attr.aria-label]="'lomakeYleinen.avaaKalenteri' | attribute">
            <span class="fa fa-calendar-alt"></span>
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [`.max-width {max-width: 15rem;}`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateComponent),
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n
    }
  ]
})
export class DateComponent implements ControlValueAccessor {

  @ViewChild("datepicker", { static: true })
  private datePickerComponent: NgbDatepicker;

  @Input() placeholder = "";
  @Input() labelledBy = null;
  @Input() ariaLabel: string;
  @Input() htmlIdSuffix: string;
  @Input() label: string;
  @Input() disableWeekends = false;
  @Input() isInValid = false;
  @Input() navigation: Navigation = Navigation.arrows;
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() inputDisabled: boolean;
  @Input() style: string;
  fieldValue: null | DateObj;
  @Output() valueChange: EventEmitter<DateObj> = new EventEmitter();


  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: DateObj | Date): void {
    if (value === null) {
      this.fieldValue = null;
      this.valueChange.emit(null);
      this._onChange(null);
    }

    if (!value) {
      return;
    }

    if (value instanceof Date) {
      value = DateObj.fromDate(value);
    }

    if (JSON.stringify(value) !== JSON.stringify(this.fieldValue)) {
      this.fieldValue = value;
      this.valueChange.emit(this.fieldValue);
      this.datePickerComponent.navigateTo(this.fieldValue);
      this._onChange(DateObj.fromNgbDate(this.fieldValue));
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.inputDisabled = isDisabled;
  }

  selectToday() {
    this.writeValue(DateObj.today());
    return this.fieldValue;
  }

  isDisabled = (date: NgbDate) => {
    if (this.disableWeekends) {
      const day = new Date(date.year, date.month - 1, date.day);
      return day.getUTCDay() >= 5;
    }
  };
}

enum Navigation {
  arrows = "arrows",
  select = "select",
  none = "none"
}
