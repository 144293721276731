import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {ENV_SESSION_STORAGE_KEY} from "./environments/environment.service";

fetch("/api/v1/avoin/ymparistot/asetukset")
  .then(response => response.text())
  .then((response: string) => {
    localStorage.setItem(ENV_SESSION_STORAGE_KEY, response);
    if (JSON.parse(response).prod) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => {
        console.log(err);
      });
  });



