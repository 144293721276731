/**
 * Created by Seppo on 24/07/2018.
 */

import {Component, Input, OnInit} from "@angular/core";
import {Allekirjoitus} from "../touko-lomake-utils";
import {SummaryItem} from "ngx-fielding";

@Component({
  selector: 'summary-allekirjoitus',
  template: `
    <div class="row pb-2">
      <div class="col">
        <h2 attribute="teksti.lahettajanTiedot" class="mb-2">Lähettäjän tiedot</h2>
      </div>
    </div>
    <ng-container *ngFor="let summary of summaryList">
      <div *ngIf="summary.show" class="row pb-2">
        <div class="col-lg-4 col-5"><span [attribute]="summary.question"></span></div>
        <div class="col"><span class="answer" [id]="summary.options.id"> {{summary.answer}}</span></div>
      </div>
    </ng-container>

    <div *ngIf="allekirjoitus?.valvontakohde" class="row pb-2">
      <div class="col-lg-4 col-5"><span attribute="etusivu.valvontakohde"></span></div>
      <div class="col">
        <span class="answer" id="allekirjoitus-valvontakohde"> {{allekirjoitus?.valvontakohde}}</span>
        <span attribute="lomakeYleinen.rekisteroimaton" *ngIf="allekirjoitus?.valvontakohdeAktiivinen === false"
              class="badge badge-warning ml-2 text-uppercase">REKISTERÖIMÄTÖN</span>
      </div>
    </div>
  `,
  styleUrls: ["./summary.scss"]
})
export class SummaryAllekirjoitusComponent implements OnInit {
  @Input() allekirjoitus: Allekirjoitus;
  summaryList: SummaryItem<any>[]

  ngOnInit(): void {
    this.summaryList = [
      this.createItem("lomakeYleinen.nimi", this.allekirjoitus.kokoNimi, 'allekirjoitus-nimi'),
      this.createItem("kayttajatili.paatoiminimi", this.allekirjoitus.paatoiminimi, 'allekirjoitus-organisaatio'),
      this.createItem("kayttajatili.maatilatunnus", this.allekirjoitus.maatilatunnus, 'allekirjoitus-maatilatunnus'),
      this.createItem("kayttajatili.ytunnus", this.allekirjoitus.ytunnus, 'allekirjoitus-ytunnus'),
      this.createItem("kayttajatili.vatNumero", this.allekirjoitus.vatNumero, 'allekirjoitus-ytunnus'),
      this.createItem("kayttajatili.toimipaikanNimi", this.allekirjoitus.osasto, 'allekirjoitus-osasto'),
    ]
  }

  private createItem(attribute: string, data, id) {
    return new SummaryItem<any>(attribute, data, {show: Boolean(data), id: id});
  }
}
