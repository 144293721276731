import {Component, ElementRef, HostListener, OnInit, ViewChild} from "@angular/core";
import {LomakeBaseDirective, LomakeStatus} from "../touko-lomake-base";
import {LomakeService} from "../touko-lomake.service";
import {MessageService} from "../../message/message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "../../account/account.service";
import {YhteenvetoService} from "../yhteenveto/yhteenveto.service";
import {KasvinterveysRekisteriData} from "./kasvinterveys-rekisteri-data";
import {Teksti} from "../../utils/teksti";
import {ToimintaDetails} from "./toiminta-card.component";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalData} from "../../modaalit/modal-utils";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {
  LOMAKE_KUVAUKSET,
  LomakeConverter,
  LomakeJSONOld,
  LomakeKasittely,
  LomakeResponse,
  LomakeTila,
  YhteyshenkiloValue
} from "../touko-lomake-utils";
import {LoaderService} from "../../loader/loader.service";
import {TarkastusPDFService} from "../tarkastus/tarkastus-pdf.service";
import {LiitetiedostoPalvelinService} from "../touko-liitetiedosto.service";
import {RekisteritCardComponent} from "./rekisterit-card.component";
import {LomakeBaseService} from "../lomake-base.service";
import {Verkkokauppa} from "../syote/syote-utils";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  templateUrl: 'kasvinterveys-rekisteri.component.html',
  selector: 'kasvinterveys-rekisteri'
})
export class KasvinterveysRekisteriComponent extends LomakeBaseDirective implements OnInit {

  @ViewChild("lomakeYhteenvetoDiv", { static: true }) lomakeYhteenvetoDiv: ElementRef;
  @ViewChild("rekisteritCard", { static: true }) rekisteritCard: RekisteritCardComponent;

  id: number;
  lomakeValuesCopy: {[key: string]: any} = {};

  lomakeAsia: string;
  kuvaukset = LOMAKE_KUVAUKSET;
  lomakeTitle: Teksti = new Teksti("Kasvinterveydenalan toimintaa koskeva ilmoitus", "otsikko", "kasvinterveysrekisteri");
  ohjeet: {[s: string]: string[]} = {sivu0: [], sivu1: [], sivu2: [], sivu3: [], sivu4: [], sivu5: []};

  toiminnat: {[s: string]: ToimintaDetails[]};
  toiminnatActive: {[s: string]: boolean} = {};
  toiminnatOhjeet: {[toimintaId: string]: {[sivu: string]: string[]}};

  tuoMarjaOptions: [Teksti, Teksti];
  taimiKasviOptions: [Teksti, Teksti];
  tuontiVientiOptions: [Teksti, Teksti];
  tuoMarjaValues: string[];
  jatkoMyyntiValues: string[];
  tuontiVientiValues: string[];
  taimiKasviValues: string[];
  tuoPuuvartOptions: Teksti[];
  myyTaimiValitysOptions: Teksti[];
  verkkokaupat: Verkkokauppa[] = [];

  toiminnatSubs: Subscription[] = [];
  yhteyshenkiloRoolit: YhteyshenkiloValue[] = [YhteyshenkiloValue.KATE_VASTAAVA, YhteyshenkiloValue.KATE_SUOJELU, YhteyshenkiloValue.KATE_ISPM15];

  tarkastusService: TarkastusPDFService;
  liitetiedostoService: LiitetiedostoPalvelinService;

  private readonly fieldsByToimintaId = {
    toimAlaPuutarhaTarha: [],
    toimAlaPuutarhaMyymala: [],
    toimAlaPuutarhaKasvi: [],
    toimAlaPuutarhaTukku: [],
    toimAlaPuutarhaViherrak: [],
    toimAlaPuutarhaKasvitiet: [],
    toimAlaPerunaViljely: [],
    toimAlaPuuISPM: [],
    toimAlaPuuToimitus: [],
    toimAlaPuuValmistaja: []
  };

  static isTrueField(value): boolean {
    if (!value) {
      return false;
    }

    return value === true || Object.values(value).some(v => Boolean(v));
  }

  static isTrueGroupField(value): boolean {
    if (!value) {
      return false;
    }

    return value === true || Object.values(value).some(v => KasvinterveysRekisteriComponent.isTrueGroupField(v));
  }

  get verkkokauppaControl(): FormControl{
    return this.lomakeValues.get('verkkokaupat.verkkokaupat') as FormControl;
  }

  constructor(private readonly modalService: NgbModal,
              private readonly loaderService: LoaderService,
              lomakeService: LomakeBaseService,
              messageService: MessageService,
              router: Router,
              accountService: AccountService,
              activatedRoute: ActivatedRoute,
              yhteenvetoService: YhteenvetoService,
              liitetiedostoService: LiitetiedostoPalvelinService,
              tarkastusService: TarkastusPDFService) {
    super(lomakeService as LomakeService, messageService, router, activatedRoute, accountService, yhteenvetoService);
    this.tarkastusService = tarkastusService;
    this.liitetiedostoService = liitetiedostoService;

    this.lomakeAsia = "kasvinterveysrekisteri";
    this.lomakeData = KasvinterveysRekisteriData.getData(accountService.getCurrentAccount(), this.toimintotyyppi);

    this.canSubmitLomake = this.canSubmitLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.saveLomake = this.saveLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.afterInit = this.afterInit.bind(this);

    this.tuoMarjaValues = ['jatkokasvatus', 'myynti'];
    this.jatkoMyyntiValues = ['jatkokasvatus', 'myynti'];
    this.tuontiVientiValues = ['tuonti', 'vienti'];
    this.taimiKasviValues = ['kasviTaimi', 'kasviValmis'];

    this.tuoPuuvartOptions = [new Teksti("Jatko", "tuoJatkokasvatukseen", this.lomakeAsia), new Teksti("Kuluttaja", "tuoKuluttajille", this.lomakeAsia)];
    this.tuoMarjaOptions = [new Teksti("Jatko", "tuoJatkokasvatukseen", this.lomakeAsia), new Teksti("Kuluttaja", "tuoKuluttajille", this.lomakeAsia)];
    this.taimiKasviOptions = [new Teksti("Taimet", "kasviTaimi", this.lomakeAsia), new Teksti("Valmiit", "kasviValmiit", this.lomakeAsia)];
    this.myyTaimiValitysOptions = [new Teksti("Jatko", "optJatkokasvatus", this.lomakeAsia), new Teksti("Myyntiin", "myyMyynti", this.lomakeAsia)];
    this.tuontiVientiOptions = [new Teksti("Tuonti", "maahantuonti", this.lomakeAsia), new Teksti("Vienti", "maastavienti", this.lomakeAsia)];
  }

  @HostListener('document:keydown.control.shift.l', ['$event']) lokitaLomakeSisalto(e: Event) {
    e.preventDefault();

    this.messageService.notify({
      message: 'Lomakkeen sisältö lokitettu',
      type: 'success',
      timeout: 2000
    });
    throw new Error(JSON.stringify(this.lomakeValues.getRawValue()));
  }

  private initOhjeetDict() {
    const prefix = "kasvinterveysrekisteri.ohje";

    this.toiminnatOhjeet = {
      toimAlaPuutarhaTarha: {
        sivu2: this.getOhjeList(prefix, ['3_1_toimAlaPuutarhaTarha']),
        sivu3: this.getOhjeList(prefix, ['4_4_etamyynti']),
        sivu5: this.getOhjeList(prefix, ['3_3_omavalvonta'])
      },
      toimAlaPuutarhaKasvi: {
        sivu2: this.getOhjeList(prefix, ['3_2_toimAlaPuutarhaKasvi']),
        sivu3: this.getOhjeList(prefix, ['4_4_etamyynti']),
        sivu5: this.getOhjeList(prefix, ['3_3_omavalvonta'])
      },
      toimAlaPuutarhaMyymala: {
        sivu3: this.getOhjeList(prefix, ['4_1_toimAlaPuutarhaMyymala', '4_4_etamyynti']),
      },
      toimAlaPuutarhaTukku: {
        sivu3: this.getOhjeList(prefix, ['4_2_toimAlaPuutarhaTukku']),
        sivu5: this.getOhjeList(prefix, ['3_3_omavalvonta'])
      },
      toimAlaPuutarhaViherrak: {
        sivu3: this.getOhjeList(prefix, ['4_3_toimAlaPuutarhaViherrak'])
      },
      toimAlaPuutarhaKasvitiet: {
        sivu4: this.getOhjeList(prefix, ['5_2_toimAlaPuutarhaKasvitiet'])
      },
      toimAlaPerunaViljely: {
        sivu2: this.getOhjeList(prefix, ['3_4_toimAlaPerunaViljely'])
      },
      toimAlaPuuISPM: {
        sivu2: this.getOhjeList(prefix, ['3_5_toimAlaPuuISPM'])
      },
      toimAlaPuuToimitus: {
        sivu3: this.getOhjeList(prefix, ['4_5_toimAlaPuuToimitus'])
      },
    };

    const suffix = this.toimintotyyppi === "muutos" ? "_muutos" : "_uusi";
    this.ohjeet = {
      sivu0: this.getOhjeList(prefix, ['1_1'], suffix),
      sivu1: this.getOhjeList(prefix, ['2_1']),
      sivu2: [],
      sivu3: [],
      sivu4: [],
      sivu5: []
    };
  }

  afterInit() {
    super.afterInit();

    this.initOhjeetDict();
    this.setFormFieldsByToimintaId();

    this.lomakeData.toiminta.data.forEach(item => {
      this.toiminnatSubs.push(
        this.lomakeValues.get(['toiminta', item.name]).valueChanges.subscribe(value => {
          this.handleToimintaSelect(item.name, value);
        })
      );
    });

    this.handleTulipolteSelect();
    this.handleISPM15KasittelyLiiteSelect();
    this.handleISPM15ValmistusLiiteSelect();
    this.handleIsKasvipassiEnabled();
  }

  convertLomakeToJSON(): LomakeJSONOld {
    return {title: this.lomakeAsia, data: this.lomakeValues.getRawValue()};
  }

  updateYhteenvetoValues() {
    this.yhteenvetoValues = LomakeConverter.convertToJSON(this.lomakeAsia, this.lomakeValues.getRawValue());
    this.yhteenvetoService.updateLomakeArvot(this.yhteenvetoValues);
  }

  /**
   * Tallennetaan lomake, kun poistutaan perustiedot -sivulta, jotta liitteille saadaan
   * aina tietoon lomakkeen id.
   * @param leaveFromSivu - Sivu, jolta siirrytään pois
   */
  handleLeavePerustiedotSivu(leaveFromSivu: number) {
    if (!this.id && leaveFromSivu === 0) {
      this.saveLomake();
    }
  }

  parseLoadedValues(rawValues: string) {
    const json = JSON.parse(rawValues);

    const roolit = new Set(this.yhteyshenkiloRoolit.map(yr => yr.value));

    const yhteyshenkiloValues = json.data.yhteyshenkilot[0] || [];
    yhteyshenkiloValues.forEach(value => {
      value.rooli = roolit.has(value.rooli) ?
        value.rooli :
        YhteyshenkiloValue.KATE_VASTAAVA.value;
    });

    return json;
  }

  /**
   * Asettaa lomakkeen kentät haettavaksi toiminnan mukaan. Helpottaa kenttien resetointia,
   * kun tiedetään, mitkä kentät kuuluvat mihinkin toimintaan.
   */
  private setFormFieldsByToimintaId() {
    Object.keys(this.lomakeData).forEach(key => {
      this.lomakeData[key].data.forEach(syote => {
        if (syote.options && syote.options.hasOwnProperty("toimintaId")) {
          const control = syote.options.resetParent ? this.lomakeValues.get([key]) : this.lomakeValues.get([key, syote.name]);
          this.fieldsByToimintaId[syote.options.toimintaId].push(control);
        }
      });
    });
  }

  /**
   * Käsittelee toiminnan valitsemisen:
   * - Jos toiminta poistetaan, ja lomakkeelta löytyy siihen liittyviä kysymyksiä, avataan käyttäjälle modaali.
   * - Jos toiminta poistetaan, eikä toimintaan liittyviin kysymyksiin ole vastattu, kysymykset piilotetaan.
   * - Jos toiminta valitaan uutena, näytetään tarvittavat kysymykset.
   * - Jos toiminta 'toimAlaPuutarhaKasvitiet' valitaan uutena ja löytyy tuonti/vienti kysymyksiä, avataan modaali.
   *
   * @param toimintaId
   * @param value - toiminnan arvo
   */
  private handleToimintaSelect(toimintaId, value: boolean) {
    if (value === false) {
      const hasAnyFieldFilled = this.fieldsByToimintaId[toimintaId] && this.fieldsByToimintaId[toimintaId].some(field => KasvinterveysRekisteriComponent.isTrueField(field.value));
      if (hasAnyFieldFilled) {
        this.lomakeValues.get(['toiminta', toimintaId]).setValue(true);
        this.openToimintaModal(toimintaId);
      } else {
        this.toiminnatActive[toimintaId] = false;
      }
    } else if (value && toimintaId === 'toimAlaPuutarhaKasvitiet')  {
      const hasAnyFieldFilled = Object.values(this.lomakeValues.get(['tuontiJaVienti']).value).some(field => KasvinterveysRekisteriComponent.isTrueField(field));
      if (hasAnyFieldFilled) {
        this.lomakeValues.get(['toiminta', toimintaId]).setValue(false);
        this.openToimintaModal(toimintaId, this.lomakeValues.get(['tuontiJaVienti']));
      } else {
        this.toiminnatActive[toimintaId] = true;
      }
    } else {
      this.toiminnatActive[toimintaId] = true;
    }

    this.refreshOhjeet();
  }

  private refreshOhjeet() {
    const activeToiminnat: string[] = Object.entries(this.toiminnatActive)
      .filter(([_key, value]) => value)
      .map(([key, _value]) => key);

    const ohjeSivuTuontiVienti = activeToiminnat.includes('toimAlaPuutarhaKasvitiet') ?
      [] : ['kasvinterveysrekisteri.ohje5_1_tuontivienti'];

    this.ohjeet.sivu2 = [];
    this.ohjeet.sivu3 = [];
    this.ohjeet.sivu4 = ohjeSivuTuontiVienti;

    activeToiminnat.forEach(toiminta => {
      Object.entries(this.toiminnatOhjeet[toiminta]).forEach(([key, value]) => {
        const currentOhjeet = new Set(this.ohjeet[key]);
        value.forEach(val => currentOhjeet.add(val));
        this.ohjeet[key] = Array.from(currentOhjeet).sort((a, b) => a.localeCompare(b));
      });
    });
  }

  /**
   * Valitsee puuvartisten kasvien taimimyynnin, jos tulipoltteen isäntäkasvit valittu.
   * Poista tulipoltteen isäntäkasvien myynnin, jos puuvartisten kasvien taimimyynti poistetaan.
   */
  private handleTulipolteSelect() {
    const taimimyymalaTulipolteControl = this.lomakeValues.get(['myyTaimimyymala', 'myyTaimimyymalaTulipolte']);
    const taimimyymalaPuuControl = this.lomakeValues.get(['myyTaimimyymala', 'myyTaimimyymalaPuu']);

    taimimyymalaTulipolteControl.valueChanges.subscribe(value => {
      if (value === true) {
        taimimyymalaPuuControl.setValue(true);
      }
    });

    taimimyymalaPuuControl.valueChanges.subscribe(value => {
      if (value === false) {
        taimimyymalaTulipolteControl.setValue(false);
      }
    });
  }

  /**
   * Lukitsee/avaa liitettä käsittelevän valintaruudun
   */
  private handleISPM15KasittelyLiiteSelect() {
    const tuoISPM15KasittelyControl = this.lomakeValues.get(['tuoISPM15Kasittely', 'tuoISPM15Kasittely']);
    const tuoISPM15KasittelyLiiteControl = this.lomakeValues.get(['tuoISPM15Kasittely', 'tuoISPM15KasittelyLiite']);

    tuoISPM15KasittelyLiiteControl.valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        tuoISPM15KasittelyControl.disable();
      } else {
        tuoISPM15KasittelyControl.enable();
      }
    });
  }

  /**
   * Lukitsee/avaa liitteitä käsittelevät valintaruudut
   */
  private handleISPM15ValmistusLiiteSelect() {
    const tuoISPM15ValmistusControl = this.lomakeValues.get(['tuoISPM15Valmistus', 'tuoISPM15Valmistus']);
    const tuoISPM15ValmistusAlkuperaLiiteControl = this.lomakeValues.get(['tuoISPM15Valmistus', 'tuoISPM15ValmistusAlkuperaLiite']);
    const tuoISPM15ValmistusLeimausLiiteControl = this.lomakeValues.get(['tuoISPM15Valmistus', 'tuoISPM15ValmistusLeimausLiite']);

    tuoISPM15ValmistusAlkuperaLiiteControl.valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        tuoISPM15ValmistusControl.disable();
      } else if (!tuoISPM15ValmistusLeimausLiiteControl.value || tuoISPM15ValmistusLeimausLiiteControl.value.length === 0) {
        tuoISPM15ValmistusControl.enable();
      }
    });

    tuoISPM15ValmistusLeimausLiiteControl.valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        tuoISPM15ValmistusControl.disable();
      } else if (!tuoISPM15ValmistusAlkuperaLiiteControl.value || tuoISPM15ValmistusAlkuperaLiiteControl.value.length === 0) {
        tuoISPM15ValmistusControl.enable();
      }
    });
  }

  public handleIsKasvipassiEnabled() {
    const valitysKasviGroup = this.lomakeValues.get(['myyTaimiValitysKasvi']);
    const valitysAvomaaGroup = this.lomakeValues.get(['myyTaimiValitysAvo']);
    const valitysSiemenGroup = this.lomakeValues.get(['myyTaimiValitysSiemen']);

    const kasvipassiGroupName = 'myyTaimiValitysKasvipassi';
    const kasvipassi = this.lomakeValues.get([kasvipassiGroupName, kasvipassiGroupName]);
    kasvipassi.disable();

    [valitysKasviGroup, valitysAvomaaGroup, valitysSiemenGroup].forEach(field => {
      field.valueChanges.subscribe(ignore => {
        const enable = KasvinterveysRekisteriComponent.isTrueGroupField(valitysKasviGroup.value) ||
          KasvinterveysRekisteriComponent.isTrueGroupField(valitysAvomaaGroup.value) ||
          KasvinterveysRekisteriComponent.isTrueGroupField(valitysSiemenGroup.value);
        if (enable) {
          this.saveGroupValues(kasvipassiGroupName);
          kasvipassi.enable();
          this.loadGroupValues(kasvipassiGroupName);
        } else {
          this.saveGroupValues(kasvipassiGroupName);
          kasvipassi.disable();
        }
      });
    });

  }

  /**
   * Avaa modaalin, jossa kysytään käyttäjältä tyhjennetäänkö toimintaan liittyvät vastaukset lomakkeelta.
   *
   * @param toimintaId - muutettava toiminta
   * @param fields - toimintaan vaikuttavat kentät
   */
  private openToimintaModal(toimintaId, fields = null) {

    if (!fields) {
      fields = this.fieldsByToimintaId[toimintaId];
    }

    const modalRef = this.modalService.open(ModalSimpleComponent);

    const okFn = () => new Promise((val) => {
        if (fields.reset) {
          fields.reset();
        } else {
          fields.forEach(control => control.reset());
        }
        this.toiminnatActive[toimintaId] = !this.toiminnatActive[toimintaId];
        this.lomakeValues.get(['toiminta', toimintaId]).setValue(this.toiminnatActive[toimintaId]);
        modalRef.close("Ok");
        return val;
      });

    const modalKuvaus = toimintaId !== "toimAlaPuutarhaKasvitiet" ?
      "modalKuvaus" :
      "modalKuvausKasvitiet";

    modalRef.componentInstance.modalTiedot = new ModalData(
      new Teksti("Toiminnan poistaminen vaikuttaa lomakkeen valintoihin", "modalOtsikko", this.lomakeAsia),
      new Teksti("Valitse tyhjennä, jos haluat poistaa valinnat", modalKuvaus, this.lomakeAsia),
      new Teksti("Tyhjennä", "modalTyhjenna", this.lomakeAsia),
      okFn,
      "btn-danger"
    );
  }

  /**
   * Lähettää lomakkeen. YLikirjoitettu base-luokasta jotta kasvinterveyden
   * puolella yhteenveto laitetaan mukaan jo asiakkaan lähettäessä lomaketta.
   * Lomake tallennetaan aluksi jotta sen id saadaan yhteenvedolle talteen.
   */
  submitLomake(): Promise<LomakeResponse> {
    let prepareFn = () => Promise.resolve();
    if (this.canSubmitLomake()) {
      prepareFn = this.rekisteritCard.hasRequiredRekisterit ?
        () => this.handleSaveAndSendYhteenveto() :
        () => Promise.resolve();
    }

      return prepareFn()
        .then(() => super.submitLomake());

  }

  protected sendLomake(status: LomakeStatus): Promise<LomakeResponse> {
    const isViranomainen = !this.accountService.isAsiakas();
    const inputGroupValue = (this.lomakeValues.get(['perustiedot']) as FormGroup).getRawValue();
    this.perustiedot['vatnumero'] = inputGroupValue['vatnumero'];
    const requestBody = this.lomakeService.createLomakeRequest(this.convertLomakeToJSON(), this.liitetiedostot, this.perustiedot, this.yhteyshenkilot);
    if (status === LomakeStatus.KESKEN || isViranomainen) {
      return this.lomakeService.tallennaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    } else {
      return this.lomakeService.lahetaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    }
  }

  private handleSaveAndSendYhteenveto(): Promise<void> {
    return this.id === 0 ?
      this.saveLomake(false).then(() => this.sendYhteenvetoPdf()) :
      this.sendYhteenvetoPdf();
  }

  public toggleGroupValues(value, formGroup: string) {
    if (value) {
      this.loadGroupValues(formGroup);
    } else {
      this.saveGroupValues(formGroup);
    }
  }

  private saveGroupValues(formGroup: string) {
    this.lomakeValuesCopy[formGroup] = this.lomakeValues.get(formGroup).value;
    this.lomakeValues.get(formGroup).reset();
  }

  private loadGroupValues(formGroup: string) {
    if (this.lomakeValuesCopy[formGroup]) {
      this.lomakeValues.get(formGroup).setValue(this.lomakeValuesCopy[formGroup]);
    }
  }

  // Should not be a mehtod...
  ifAnyOfGroupSelected(group: string) {
    const isAnySelected = Object.values(this.lomakeValues.get([group]).value).some(field => KasvinterveysRekisteriComponent.isTrueField(field));
    if (isAnySelected) {
      const firstField = this.lomakeData[group].data[0].name;
      this.lomakeValues.get([group, firstField]).setValue(true);
    }
    return isAnySelected;
  }

  ifValueSelected(path: string) {
    return this.lomakeValues.get(path).value === true;
  }

  /**
   * Lähettää yhteenvedon palvelimelle ja lisää sen lomakkeen liitetiedostoihin.
   */
  protected sendYhteenvetoPdf(): Promise<void> {
    // Lomake lähtee ilman viranomaistarkistusta, halutaan näyttää 'toimitettu' teksti yhteenvedon pdf:ssä vaikka tila kannassa hyväksytty.
    const kasittely = new LomakeKasittely(this.id, LomakeTila.TOIMITETTU, null, null, null, this.lomakeValues.value['asiakasid']);
    const title = this.kuvaukset[this.lomakeAsia][this.toimintotyyppi.toUpperCase()].label;
    const contentHtml = this.lomakeYhteenvetoDiv.nativeElement;

    return this.tarkastusService.createYhteenvetoPdf(kasittely, this.allekirjoitus, title, contentHtml)
      .then(pdf => this.liitetiedostoService.sendLiitetiedosto(pdf, 'YHTEENVETO', this.id))
      .then(response => {
        this.liitetiedostot['yhteenveto'] = [response];
      });
  }

  /**
   * Päivittää lomakkeen verkkokaupan osoitteen
   *
   * @param data
   */
  updateVerkkokaupat(data: Verkkokauppa[]) {
    this.verkkokaupat = data;
  }
}
