

<div>
  <div class="row">
    <div class="col touko-lomake">
      <input class="form-control"
             [(ngModel)]="value"
             [ngbTypeahead]="haeKasvilaji"
             [editable]="false"
             [resultFormatter]="muotoileKasvilaji"
             [inputFormatter]="muotoileKasvilaji"
             [showHint]="false"
             (selectItem)="onSelectKasvilaji($event)"
             (focus)="lajiFocus$.next($any($event).target.value)"
             [disabled]="disabled"
      />
    </div>
  </div>
</div>
