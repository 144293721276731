import {Account, Toimiala} from "../account/account";

import {SyoteGroup} from "./syote/syote-group";
import {Asiakas} from "../valvontakohde/valvontakohde";
import {Teksti} from "../utils/teksti";
import {ToukoValidation} from "./validators/validators";
import {YhteenvetoTyyppi} from "./yhteenveto/yhteenveto-utils";
import {Syote} from "./syote/syote";
import {Validators} from "@angular/forms";
import {DateObj, Verkkokauppa, Yhteyshenkilo} from "./syote/syote-utils";
import {ValidatorOptions} from "./touko-lomake-utils";
import {SyoteInterface} from "./syote/syote-interface";

export abstract class LomakeData {

  private static readonly VIEW = "lomakeYleinen";

  /**
   * Tuottaa lomakkeelle perustietolohkon
   *
   * @param view - attribuuttikannan näkymä
   * @param account - kirjautunut käyttäjä kenttien esitäyttämistä varten
   * @returns - perustietolohko
   */
  static getPerustiedot(view, account?: Account): SyoteGroup {
    const ytunnus = Account.isAsiakas(account) ? account.ytunnus : null;
    let toimipaikanNimi = Account.isAsiakas(account) && account.yritys ? account.yritys.paaToiminimi : null;
    let kayntiosoite = null;
    let postiosoite = null;

    let ytunnusTaiMaatilaSyote = new Syote(new Teksti("Y-tunnus", "ytunnus", "kayttajatili"), {
      value: ytunnus,
      disabled: true
    }, "ytunnus");

    if (Account.toimialaEquals(account, Toimiala.LUOMU)) {
      if (Account.isMaatila(account)) {
        const accountInfo = this.handleMaatilaAccount(account, toimipaikanNimi);
        toimipaikanNimi = accountInfo.toimipaikanNimi;
        kayntiosoite = accountInfo.kayntiosoite;
        ytunnusTaiMaatilaSyote = accountInfo.ytunnusTaiMaatilaSyote;
      } else if (account.valvontakohde?.toimipaikka?.osoite) {
        kayntiosoite = {
          osoite: account.valvontakohde.toimipaikka.osoite,
          postinumero: account.valvontakohde.toimipaikka.postinumero,
        };
      }

      postiosoite = Asiakas.haeEnsisijainenOsoite(account?.valvontakohde?.asiakas) || kayntiosoite || null;
      kayntiosoite = kayntiosoite || postiosoite || null;
    }

    return new SyoteGroup(
      view,
      new Teksti("Perustiedot", "perustiedot", LomakeData.VIEW),
      null,
      {
        required: new Teksti("Pakollinen kenttä", "errPakollinenTieto", LomakeData.VIEW),
        [ToukoValidation.VALUE_NOT_EMPTY]: new Teksti("Pakollinen kenttä", "errPakollinenTieto", LomakeData.VIEW),
        [ToukoValidation.OSOITE_OR_EMPTY]: new Teksti("Puutteellinen osoitetieto", "errPuuttellinenOsoite", LomakeData.VIEW),
        [ToukoValidation.VALID_POSTINRO]: new Teksti("Virheellinen postinumero", "errVirheellinenPostinro", LomakeData.VIEW)
      },
      YhteenvetoTyyppi.OSOITE_OR_TEXT,
      new Syote(new Teksti("Toimipaikan nimi", "toimipaikanNimi", LomakeData.VIEW), [toimipaikanNimi, Validators.required], "osasto"),
      new Syote(new Teksti("Postiosoite", "postiosoite", LomakeData.VIEW), [postiosoite, [ToukoValidation.validOsoiteOrAllEmpty, ToukoValidation.valueObjNotEmpty]]),
      new Syote(new Teksti("Käyntiosoite", "kayntiosoite", LomakeData.VIEW), [kayntiosoite, [ToukoValidation.validOsoiteOrAllEmpty, ToukoValidation.valueObjNotEmpty]]),
      ytunnusTaiMaatilaSyote,
      new Syote(new Teksti("Päätoiminimi", "paatoiminimi", "kayttajatili"), {
        value: toimipaikanNimi,
        disabled: true
      }, "yritys")
    );
  }

  static getPerustiedotLannoiteUusi(view, account?: Account): SyoteGroup {
    const syoteGroup = LomakeData.getPerustiedot(view, account);

    syoteGroup.addChild(new Syote(new Teksti("vatnumero", "vatnumero", "kayttajatili"), {
      value: Account.getVatNumber(account),
      disabled: true
    }, "vatnumero"));

    return syoteGroup;
  }

  private static handleMaatilaAccount(account: Account, toimipaikanNimi: string) {
    const hakunimi = account?.valvontakohde?.asiakas?.hakunimi;
    const hakuHetu = account?.valvontakohde?.asiakas?.hetu;
    const asiakkaanNimet = account?.valvontakohde?.asiakas?.asiakkaannimet;
    const toimijaNimi = account?.wholeName;
    const toimijaHetu = account?.hetu;

    if (hakunimi != null && hakunimi.length > 0 && hakunimi.trim()) {
      toimipaikanNimi = hakunimi;
    } else if (asiakkaanNimet != null && asiakkaanNimet.length > 0) {
      toimipaikanNimi = asiakkaanNimet[asiakkaanNimet.length - 1]?.nimi;
    } else if (toimijaNimi != null && toimijaNimi.length > 0 && toimijaNimi.trim() && hakuHetu === toimijaHetu) {
      toimipaikanNimi = toimijaNimi;
    }

    const tilatunnus = account?.maatilatunnus || null;

    const kayntiosoite = account.maatila ? {
      osoite: account.maatila.postiosoite,
      postinumero: account.maatila.postinumero,
      postitoimipaikka: account.maatila.postitoimipaikka,
    } : null;

    const ytunnusTaiMaatilaSyote = new Syote(new Teksti("Maatilatunnus", "maatilatunnus", "kayttajatili"), {
      value: tilatunnus,
      disabled: true
    }, "maatilatunnus");

    return {toimipaikanNimi, kayntiosoite, ytunnusTaiMaatilaSyote};
  }

  static getYhteyshenkilot(view, account?: Account, validatorOptions?: ValidatorOptions, defaultRole = 'ReVCCControlContact'): SyoteGroup {

    const wholename = Account.getReversedWholename(account);

    const fieldValidators = [ToukoValidation.validYhteyshenkiloList];
    if (validatorOptions && validatorOptions.validators) {
      fieldValidators.push(...validatorOptions.validators);
    }

    const errorMessages = {
      pattern: new Teksti("Tarkista puhelinnumero", "errPuhelin", LomakeData.VIEW),
      email: new Teksti("Tarkista sähköposti", "errEmail", LomakeData.VIEW),
      [ToukoValidation.VALID_YHTEYSHENKILO]: new Teksti("Yhteyshenkilon tiedot", "errYhteyshenkilo", LomakeData.VIEW),
    };
    if (validatorOptions && validatorOptions.errorMsgs) {
      Object.keys(validatorOptions.errorMsgs).forEach(
        key => errorMessages[key] = validatorOptions.errorMsgs[key]
      );
    }

    return new SyoteGroup(
      view,
      new Teksti("Yhteyshenkilot", "yhteyshenkilot", LomakeData.VIEW),
      null,
      errorMessages,
      YhteenvetoTyyppi.YHTEYSHENKILOT,
      new Syote(new Teksti("Yhteyshenkilö", "yhteyshenkilot", LomakeData.VIEW), [[new Yhteyshenkilo(wholename, null, null, defaultRole)], fieldValidators])
    );
  }

  static getVerkkokaupat(view, account?: Account, validatorOptions?: ValidatorOptions): SyoteGroup {

    const fieldValidators = [ToukoValidation.validVerkkokauppaList];
    if (validatorOptions && validatorOptions.validators) {
      fieldValidators.push(...validatorOptions.validators);
    }

    const errorMessages = {
      osoite: new Teksti("Tarkista verkko-osoite", "errOsoite", LomakeData.VIEW),
      [ToukoValidation.VALID_VERKKOKAUPPA]: new Teksti("Verkkokaupan tiedot", "errVerkkokauppa", LomakeData.VIEW),
    };
    if (validatorOptions && validatorOptions.errorMsgs) {
      Object.keys(validatorOptions.errorMsgs).forEach(
        key => errorMessages[key] = validatorOptions.errorMsgs[key]
      );
    }

    return new SyoteGroup(
      view,
      new Teksti("Verkkokaupat", "verkkokaupat", LomakeData.VIEW),
      null,
      errorMessages,
      YhteenvetoTyyppi.VERKKOKAUPAT,
      new Syote(new Teksti("Verkkokauppa", "verkkokaupat", LomakeData.VIEW), [[new Verkkokauppa(null)], fieldValidators])
    );
  }


  static getLaskutustiedot(view, account?: Account): SyoteGroup {
    const verkkolaskutusValidator = Account.isViranomainen(account) ?
      [ToukoValidation.validVerkkolaskuosoiteOrEmpty, ToukoValidation.validOperaattoritunnus] :
      [ToukoValidation.validVerkkolaskuosoiteOrEmpty, ToukoValidation.validVerkkolaskuosoiteForm(account?.ytunnus), ToukoValidation.validOperaattoritunnus];

    return new SyoteGroup(
      view,
      new Teksti("Laskutustiedot", "laskutustiedot", LomakeData.VIEW),
      null,
      {
        [ToukoValidation.OSOITE_OR_EMPTY]: new Teksti("Puutteellinen osoitetieto", "errPuuttellinenOsoite", LomakeData.VIEW),
        [ToukoValidation.VALID_POSTINRO]: new Teksti("Virheellinen postinumero", "errVirheellinenPostinro", LomakeData.VIEW),
        [ToukoValidation.VLASKUOSOITE_OR_EMPTY]: new Teksti("Puutteellinen verkkolaskutustieto", "errVerkkolasku", LomakeData.VIEW),
        [ToukoValidation.VLASKUOSOITE_CORRECT_FORM]: new Teksti("Virheellinen muoto verkkolaskuosoitteessa", "errVerkkolaskuosoiteMuoto", LomakeData.VIEW),
        [ToukoValidation.VLASKUOSOITE_OPERAATTORI_TUNNUS]: new Teksti("Valitse oikea operaattoritunnus", "errVerkkolaskuosoiteOperaattoriTunnus", LomakeData.VIEW),
        [ToukoValidation.VLASKUOSOITE_TAI_LASKUTUSOSOITE]: new Teksti("Laskutusosoite tai verkkolaskutusosoite tulee täyttää", "errVerkkolaskuosTailaskutusos", LomakeData.VIEW),
      },
      YhteenvetoTyyppi.LASKUTUSTIETO,
      new Syote(new Teksti("Laskutusosoite", "laskutusosoite", LomakeData.VIEW), [null, ToukoValidation.validOsoiteOrAllEmpty]),
      new Syote(new Teksti("Verkkolaskuosoite", "verkkolaskutusosoite", LomakeData.VIEW), [null, verkkolaskutusValidator])
    );
  }

  static getToiminnanAloitus(view, toimintotyyppi = '', paivamaara = DateObj.today()): SyoteGroup {
    const isMuutos = 'muutos' === toimintotyyppi.toLowerCase();
    const validatorMsg = isMuutos ?
      new Teksti("Merkitse puuttuva ajankohta", "errToiminnanMuutosaika", "lomakeYleinen") :
      new Teksti("Merkitse puuttuva ajankohta", "errToiminnanAloitusaika", "lomakeYleinen");
    return new SyoteGroup(
      view,
      new Teksti("Arvioitu toiminna aloitusajankohta", isMuutos ? "toiminnanMuutos" : "toiminnanAloitus", "lomakeYleinen"),
      [],
      {required: validatorMsg, [ToukoValidation.PVM_VALID]: validatorMsg},
      YhteenvetoTyyppi.SINGLE_SELECTION_DATE,
      new Syote(
        new Teksti(
          "Merkitse arvioitu toiminnan aloitusajankohta",
          isMuutos ? "merkitseToiminnanMuutos" : "merkitseToiminnanAloitus",
          "lomakeYleinen"),
        [paivamaara, [Validators.required, ToukoValidation.isValidDate]],
        "toiminnanAloitus"
      ),
    );
  }

  static getSahkoinenAsiointi(view, toimintotyyppi = ''): SyoteGroup {
    const yhteenvetotyyppi = 'muutos' === toimintotyyppi.toLowerCase() ?
      YhteenvetoTyyppi.LABEL_ONLY :
      YhteenvetoTyyppi.NONE;

    return new SyoteGroup(
      view,
      new Teksti("Sähköinen asiointi", "titleSahkAsiointi", "lomakeYleinen"),
      [],
      {},
      yhteenvetotyyppi,
      new Syote(new Teksti("Suostun sähköiseen asiointiin", "checkSahkAsiointi", "lomakeYleinen"), null, "sahkoinenAsiointi")
    );
  }
}

export interface LomakeDataContent {
  [s: string]: SyoteGroup;
}

export const keyOrderFn = (getDataFn: () => LomakeDataContent) => {
  const groupKeys = [];
  const addGroupKeys = (addedKeys: string[], syote: SyoteInterface) => {
    if (syote.name) {
      addedKeys.push(syote.name);
    }
    if (syote.children) {
      syote.children.forEach(child => addGroupKeys(addedKeys, child));
    }
  };

  Object.entries(getDataFn())
  .forEach(([key, group]) => {
    groupKeys.push(key);
    group.data.forEach(syote => addGroupKeys(groupKeys, syote));
  });

  return groupKeys;
};
