

<div>


  <div class="row">
    <div class="col col-9">
      <input class="form-control"
             #ref
             (keypress)="inputKeypressEvent($event, ref)"
             (focusout)="lisaaRivi(ref)"
      >
    </div>
    <div clas="col col-2">
      <button type="button" class="btn btn-primary" attribute="lisaa" (click)="lisaaRivi(ref)">
      </button>
    </div>

  </div>

  <div class="row" *ngIf="value.length > 0">
    <div class="col col-9">
      <div class="arvolista">
        <div *ngFor="let laji of value; index as idx;" class="row">
          <div class="col">
            <div class="arvo pr-1 pl-1">
              <span>{{laji}}</span>
              <button type="button" class="close stretched-link" aria-label="Remove" (click)="poistaRivi(idx)">
                <span class="fa fa-minus-circle fa-xs" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
