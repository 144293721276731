/**
 * Created by Seppo on 20/11/2017.
 */

import {Teksti} from "../../utils/teksti";
import { YhteenvetoTyyppi } from "../yhteenveto/yhteenveto-utils";
import {SyoteBase, SyoteInterface, SyoteType} from "./syote-interface";

/**
 * Yksittäisen syötteen malli.
 */
export class Syote extends SyoteBase {
  static id = 0;
  type = SyoteType.CONTROL;

  /**
   * Konstruktori
   *
   * @param label - syötteen label ja key Teksti-objektina
   * @param control - syötteen kontrolli, esim. oletusarvo ja validaattorit
   * @param name - syötteen tunniste lähetettävässä JSON-objektissa
   * @param value - syötteen, esim. radio buttonin tuottama arvo
   * @param options - syötteen lisäasetuksia
   * @param summaryType - mahdollistaa syötteen oman yhteenvetotyypin käytön syötegroupin yhteenvetotyypin sijaan.
   */
  constructor(label: Teksti, control = null, name = null, value = null, options: { [s: string]: any } = null, summaryType = null, ...children: SyoteInterface[]) {
    super();
    this.label = label;
    this.control = control;
    this.value = value;
    this.options = options;
    this.summaryType = summaryType;
    this.children = children || [];
    if (name !== null) {
      this.name = name;
    } else if (label.key !== null) {
      this.name = label.key;
    } else {
      this.name = Syote.generateName();
    }
  }

  static resetId() {
    Syote.id = 0;
  }

  static generateName() {
    const id = `id${Syote.id}`;
    Syote.id += 1;
    return id;
  }

  setViewKey(key: string) {
    this.label.view = key;
  }

  getLabelKey() {
    return this.label.getFullKey();
  }
}
