/**
 * Created by Seppo on 08/12/2017.
 */

export class Teksti {
  private _label: string;
  private _key: string;
  private _view: string;
  private _fullKey: string;
  private _hash: string;

  /**
   * Attribuuttikannan teksti
   *
   * @param label - oletusteksti
   * @param key - avain attribuuttikannassa tai null
   * @param view - näkymän avain tai null
   */
  constructor(label, key: string = null, view: string = null) {
    this._label = label;
    this._key = key;
    this._view = view;
    this._fullKey = `${view}.${key}`;
    this._hash = `${label}.${view}.${key}`;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  get view(): string {
    return this._view;
  }

  set view(value: string) {
    this._view = value;
  }

  get fullKey(): string {
    return this._fullKey;
  }

  set fullKey(value: string) {
    this._fullKey = value;
  }

  get hash(): string {
    return this._hash;
  }

  set hash(value: string) {
    this._hash = value;
  }

  getFullKey() {
    if (this._key === null) {
      return null;
    }
    return `${this._view}.${this._key}`;
  }
}
