import {AbstractAsiakasGroup, TilaajaValue} from "./abstract-asiakas-group";
import {InitValueType, QuestionGroup, QuestionInitValue} from "ee-lahete";
import {GroupBuilder} from "../group-build-options";

export class OmistajaGroup extends AbstractAsiakasGroup {

  questionGroupKey = 'omistaja';

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'henkilo'})): QuestionGroup {
    const htmlIdSuffix = 'Omistaja';
    const henkilotiedot = this.createHenkilotietoGroup(htmlIdSuffix);

    const lisatiedotGroup = this.createLisatietoGroup(htmlIdSuffix);
    const maksajaControl = lisatiedotGroup.find(['maksaja']);
    maksajaControl.modifiers = OmistajaGroup.createTilaajaTyyppiModifierNotEquals(TilaajaValue.OMISTAJA);

    const yhteyshenkiloControls = this.createYhteyshenkiloControls(`Yhteyshenkilo${htmlIdSuffix}`);
    const yhteyshenkiloGroup = new QuestionGroup({
      key: 'yhteyshenkilo',
      groupComponentType: 'nested',
      label: this.getLabel('yhteyshenkilo'),
      controls: yhteyshenkiloControls,
      filters: OmistajaGroup.createTilaajaTyyppiFilterNotEquals(TilaajaValue.OMISTAJA)
    });

    const group = new QuestionGroup({
      key: this.questionGroupKey,
      groupComponentType: 'nested',
      label: this.getLabel('asiakasOmistaja'),
      groups: [
        henkilotiedot,
        yhteyshenkiloGroup,
        lisatiedotGroup
      ],
      filters: OmistajaGroup.createTilaajaTyyppiFilterNotEquals(TilaajaValue.OMISTAJA),
      options: {
        hideInSummary: val => OmistajaGroup.everyHenkilotietoValueFalse(val.henkilotiedot)
      }

    });
    this.applyPostitoimipaikkaDisabler(henkilotiedot);

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.omistaja), InitValueType.PATCH));
    }

    return group;
  }
}
