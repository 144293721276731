/**
 * Created by Seppo on 02/10/2017.
 */

/**
 * Specific role of the user.
 */
import {Teksti} from "../utils/teksti";
import {Yhteyshenkilo} from "../touko-lomake/syote/syote-utils";
import {Maatila} from '../maatila/maatila';
import {Valvontakohde} from "../valvontakohde/valvontakohde";

export enum KayttajaRooli {
  TUNTEMATON = "TUNTEMATON",
  ELY_VIRANOMAINEN = "ELY_VIRANOMAINEN",
  VIRANOMAINEN = "VIRANOMAINEN",
  ASIAKAS_YRITYS = "ASIAKAS_YRITYS",
  ASIAKAS_HENKILO = "ASIAKAS_HENKILO"
}

export enum NotificationType {
  KATE_OVS_VANHENEE = "KATE_OVS_VANHENEE",
  EI_ILMOITUKSIA = "EI_ILMOITUKSIA"
}

export class AccountNotification {
  type: NotificationType;
  data: string;

  constructor(type?: NotificationType, data?: string) {
    this.type = type || null;
    this.data = data || null;
  }
}

export class OsoiteTiedot {
  osoite: string;
  postinumero: string;
  postitoimipaikka: string;
  maaKoodi: string;

  constructor(osoite: string = null, postinumero: string = null, postitoimipaikka: string = null, maaKoodi: string = 'FI') {
    this.osoite = osoite;
    this.postinumero = postinumero;
    this.postitoimipaikka = postitoimipaikka;
    this.maaKoodi = maaKoodi;
  }
}

/**
 * Account details.
 */
export class Account {
  wholeName: string;
  firstName: string;
  lastName: string;
  hetu: string;
  organization: string;

  // Yritys
  ytunnus: string;
  yritys: any;
  // Maatila
  maatilatunnus: string;
  maatila: Maatila | null;
  // Valvontakohde (sis. toimipaikka)
  valvontakohdeElmoId: string;
  valvontakohde: Valvontakohde;
  // Muut asetukset
  toimiala: Toimiala;
  toimialaTeksti: Teksti;
  toimialaValtuudet: string[];
  kayttajaRooli: KayttajaRooli;
  ilmoitukset: boolean;
  yhteyshenkilot: Yhteyshenkilo[];

  static getReversedWholename(account: Account) {
    let wholename = null;
    if (Boolean(account)) {
      wholename = account.wholeName.split(" ").reverse().join(" ");
    }
    return wholename;
  }

  static isAsiakas(account: Account): boolean {
    return account?.kayttajaRooli === KayttajaRooli.ASIAKAS_YRITYS ||
        account?.kayttajaRooli === KayttajaRooli.ASIAKAS_HENKILO;
  }

  static isMaatila(account: Account): boolean {
    return Boolean(account?.maatilatunnus);
  }

  static isViranomainen(account: Account): boolean {
    return account?.kayttajaRooli === KayttajaRooli.VIRANOMAINEN || account?.kayttajaRooli === KayttajaRooli.ELY_VIRANOMAINEN;
  }

  static isElyViranomainen(account: Account): boolean {
    return account?.kayttajaRooli === KayttajaRooli.ELY_VIRANOMAINEN;
  }

  static toimialaEquals(account: Account, toimiala): boolean {
    return Boolean(toimiala) && toimiala === account?.toimiala;
  }

  static getVatNumber(account: Account): string | null {
    return Account.isAsiakas(account) && account.yritys ?
      "FI".concat(account.ytunnus.replace("-", "")) :
      null;
  }
}

export class Asetukset {
  toimiala: string;
  toimipaikka: string;

  constructor(toimiala?: string, toimipaikka?: string) {
    this.toimiala = toimiala;
    this.toimipaikka = toimipaikka;
  }
}

export const TOIMIALA_TEKSTI = {
  SIEMEN: new Teksti('Siemen', 'siemenToiminta', 'kayttajatili'),
  REHU: new Teksti('Rehu', 'rehuToiminta', 'kayttajatili'),
  LANNOITE: new Teksti('Lannoite', 'lannoiteToiminta', 'kayttajatili'),
  KATE: new Teksti('Kasvinterveys', 'kasvinterveysToiminta', 'kayttajatili'),
  LUOMU: new Teksti('Luomu', 'luomuToiminta', 'kayttajatili'),
  ELAIN_LABORATORIO: new Teksti('Eläintautitutkimukset', 'elabraToiminta', 'kayttajatili')
};

export class ToimialaAsetus {
  valintaTeksti: Teksti;
  tallennettuValvontakohde: Valvontakohde;
  valvontakohteet: Valvontakohde[];

  constructor(valintaTeksti: Teksti, tallennettuValvontakohde: Valvontakohde = null, valvontakohteet: Valvontakohde[] = []) {
    this.valintaTeksti = valintaTeksti;
    this.tallennettuValvontakohde = tallennettuValvontakohde;
    this.valvontakohteet = valvontakohteet;
  }
}

export enum Toimiala {
  SIEMEN = "SIEMEN",
  REHU = "REHU",
  LANNOITE = "LANNOITE",
  KATE = "KATE",
  LUOMU = "LUOMU",
  ELAIN_LABORATORIO = "ELAIN_LABORATORIO"
}
