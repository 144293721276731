import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";
import {LomakeResponse} from "./touko-lomake-utils";
import {HttpClient} from "@angular/common/http";
import {LomakeErrorService} from "./lomake-error.service";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";

export abstract class LomakeBaseService {

  abstract readonly baseAsiakasURL: string;
  abstract readonly baseViranomainenURL: string;
  abstract isLahetetty$: Observable<boolean>;
  protected abstract http: HttpClient;
  protected abstract lomakeErrorService: LomakeErrorService

  setVirhe(sivu: string, syote: string, virhe: boolean) {
    this.lomakeErrorService.setVirhe(sivu, syote, virhe);
  }

  toggleOhje() {
    this.lomakeErrorService.toggleOhje();
  }

  abstract allowRedirect();

  abstract blockRedirect();

  abstract getLomake(id: number): Promise<any>;

  abstract checkCanSubmitLomake(form: FormGroup): boolean;

  abstract createMallipohja(baseLomakeId: number, mallipohjanimi: string): Promise<any>;

  updateViimeksiMuokannutAsiakas(id: number): Promise<LomakeResponse> {
    return this.http.put(`${this.baseAsiakasURL}/${id}/muokkaaja`, null)
      .toPromise()
      .then(data => data as LomakeResponse);
  }
  updateViimeksiMuokannutAsiakasV2(id: number, asia: string, toimintoTyyppi: string): Promise<LomakeResponse> {
    const ilmoitusTyyppi = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintoTyyppi);
    const baseUrl = `api/v2/as/lomakkeet/${ilmoitusTyyppi}/${asia}/${toimintoTyyppi}`;
    return this.http.put(`${baseUrl}/${id}/muokkaaja`, null)
      .toPromise()
      .then(data => data as LomakeResponse);
  }

  updateViimeksiMuokannutViranomainen(id: number): Promise<LomakeResponse> {
    return this.http.put(`${this.baseViranomainenURL}/${id}/muokkaaja`, null)
      .toPromise()
      .then(data => data as LomakeResponse);
  }

  updateViimeksiMuokannutViranomainenV2(id: number, asia: string, toimintoTyyppi: string): Promise<LomakeResponse> {
    const ilmoitusTyyppi = toimintoTyyppi === "vuosiilmoitus" ? "vuosiilmoitukset" : "ilmoitukset";
    const baseUrl = `/api/v2/vk/lomakkeet/${ilmoitusTyyppi}/${asia}`;
    return this.http.put(`${baseUrl}/${toimintoTyyppi}/${id}/muokkaaja`, null)
      .toPromise()
      .then(data => data as LomakeResponse);
  }

}
