<header>
  <nav [attr.aria-label] = "'lomakeYleinen.paaNavi' | attribute"
       class="main-nav sticky-top d-print-none">
       <a [href]="'#content'"
       class="skip-link"
       (click)="clickSkipLink($event)"
       [ngClass]="!isFocusSkipLinkActive ? 'opacity0' : null"
       (focus)="onFocusSkipLink(true)"
       (focusout)="onFocusSkipLink(false)">
      <span *ngIf="!lang || lang === 'FI'" [attribute]="'teksti.siirrySuoraanSisaltoon'" [lang]="'FI'">Siirry suoraan sisältöön</span>
      <span *ngIf="!lang"> / </span>
      <span *ngIf="!lang || lang === 'SV'" [attribute]="'teksti.siirrySuoraanSisaltoon'" [lang]="'SV'">*SV* Siirry suoraan sisältöön</span>
    </a>

    <div>
      <a id="main-nav-home" class="navbar-brand" [routerLink]="getEtusivuUrl()">
        <span [attribute]="'teksti.otsikko'" [lang]="'FI'">Touko</span>
        <span *ngIf="ymparisto"> - {{ymparisto | uppercase}}</span>
      </a>
    </div>

    <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="isLoggedIn()">
      <button id="mainNavMenu" [attr.aria-label]="'lomakeYleinen.avaaTaiSulje'" aria-controls="mainNavMenuContent" ngbDropdownToggle class="btn-no-style navbar-brand"><em class="fa fa-bars"></em></button>
      <div id="mainNavMenuContent" ngbDropdownMenu>
        <button id="kayttaja-asetukset" class="dropdown-item" [routerLink]="'/asiointi/account'"
                [attribute]="'teksti.tilinAsetukset'">Tilin asetukset
        </button>
        <p class="dropdown-divider"></p>
        <button id="info" class="dropdown-item" [routerLink]="'/asiointi/info-valilehti'"
                [attribute]="'teksti.infoValilehti'">Info
        </button>
        <p class="dropdown-divider"></p>
        <button id="kirjaudu-ulos" (click)="logout()" class="dropdown-item btn-danger"
                [attribute]="'teksti.kirjauduUlos'">Kirjaudu ulos
        </button>
      </div>
    </div>
  </nav>
</header>
