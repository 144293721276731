<div [formGroup]="controlContainer.control">
<div formArrayName="{{controlName}}">

<ng-container *ngFor="let control of lupaTaulukko.controls; index as idx;">
  <div formGroupName="{{idx}}">
    <hr/>
    <div class="col-md-12 pl-0">
      <div class="form-row lupa-otsikko col-md-10 pl-0 mb-3">
        <div class="lupa-otsikko">
          <legend attribute="luomuPoikkeus9c.lupaTavanomLisaysaineisto"></legend>
        </div>
      </div>

      <div class="col-md-2 pl-0 lupa-otsikko">
        <em class="fas fa-question-circle"></em>
        <button id="btn-ohje" type="button" class="btn btn-ohje" (click)="showHelpModal()" attribute="luomuPoikkeus9c.tarvitsetkoApua">Tarvitsetko apua?</button>
      </div>
    </div>

    <div class="form-row">

      <div class="col-md-3 mb-3 pl-0">
        <label><span attribute="luomuPoikkeus9c.tyyppi">Tyyppi</span></label>

        <select  class="form-control" formControlName="tyyppi" id="{{getIdPrefix()}}-drop-tyyppi-{{idx}}" (ngModelChange)="validateKayttomaara(idx)">
          <option value="" disabled selected attribute="luomuPoikkeus9c.valitse">Valitse</option>
          <option value="SIEMEN" attribute="luomuPoikkeus9c.siemen">Siemen</option>
          <option value="TAIMI" attribute="luomuPoikkeus9c.taimi">Taimi</option>
        </select>
      </div>

      <div class="col-md-3 mb-3 pl-0">
        <label><span attribute="luomuPoikkeus9c.lupaLaji">Laji</span></label>
        <kasvilaji-valinta formControlName="laji" (uusiKasvilajike)="uusiKasvilajike($event, idx)" id="{{getIdPrefix()}}-input-kasvilaji-{{idx}}" (ngModelChange)="lajiValittu($event, lajikeRef)" ></kasvilaji-valinta>
      </div>
      <div class="col-md-3 mb-3 pl-0">
        <label><span attribute="luomuPoikkeus9c.lupaLajike">Lajike</span></label>
        <kasvilajike-valinta #lajikeRef formControlName="lajike" id="{{getIdPrefix()}}-input-kasvilajike-{{idx}}" (focusin)="asetaLaji(lajikeRef, idx)"></kasvilajike-valinta>
      </div>
      <div class="col-md-3 mb-3 pl-0">
        <label><span attribute="luomuPoikkeus9c.lupaKayttomaara">Käyttömäärä</span><span>{{getMaaraTyyppiLabel(idx)}}</span> </label>
        <input formControlName="kayttomaara" type="number" class="form-control" id="{{getIdPrefix()}}-input-kayttomaara-{{idx}}" (ngModelChange)="validateKayttomaara(idx)">
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12 mb-0">
        <div class="col-md-12 pl-0 lupa-peruste-label">
          <div class="form-row col-md-6 pl-0 lupa-peruste-title" *ngIf="naytaPerustelulista()">
            <span attribute="luomuPoikkeus9c.lupaPeruste" class="">Peruste</span>
          </div>
          <div class="col-md-6 mb-1 poista-peruste">
            <div class="poista-peruste-button">
              <button (click)="removeRow(idx)" type="button" class="btn btn-sm btn-transparent btn-poista-peruste"
                      id="{{getIdPrefix()}}-yh-poista"
                      [disabled]="luvatLength <= 0" attribute="luomuPoikkeus9c.poistaEdellinenLupa">
              </button>
            </div>
            <div class="poista-peruste-icon">
              <em class="fa fa-trash-alt"></em>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="naytaPerustelulista()">
          <span attribute="luomuPoikkeus9c.lupaPerusteOhje" class="lupa-peruste-ohje">Tarkista luvan peruste</span><a id="btn-ohje" class="btn-ohje" (click)="showHelpModal()" attribute="luomuPoikkeus9c.lupaPerusteOhjeLinkki">käyttöohjeista</a>
        </div>
        <div class="form-row col-md-12 pl-0" *ngIf="naytaPerustelulista()">
          <div *ngFor="let lupaPeruste of lupaPerusteet" class="form-check lupa-peruste-row col-md-12">
            <div class="lupa-peruste-label col-md-3">
              <input value="{{lupaPeruste}}" formControlName="peruste" class="form-check-input" type="radio" id="{{getIdPrefix()}}-radio-peruste-{{lupaPeruste}}-{{idx}}"  />
              <label class="form-check-label"
                     [attribute]="lupaPerusteTekstit[lupaPeruste].valintaTeksti.getFullKey()">
                (a)Ei saatavana luomuna...
              </label>
            </div>
            <div class="lupa-peruste-info col-md-9 pl-1">
              <span [attribute]="lupaPerusteTekstit[lupaPeruste].infoTeksti.getFullKey()"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-3"></div>
      <div class="col-md-9 pl-0 lupa-perustelut">
        <span attribute="luomuPoikkeus9c.lupaPerustelut">Perustelut</span>
        <textarea formControlName="perustelut" class="form-control" id="{{getIdPrefix()}}-text-perustelut-{{idx}}"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-3"></div>
      <div class="col-md-9 pl-0">
        <div class="form-group lupa-perustelut">
          <label class="lupa-liite-label" [htmlFor]="'lupaliite'" attribute="luomuPoikkeus9c.lupaLiite" [markdown]="false">Erillinen selvitys tai suunnitelma, liite</label>
          <touko-liite formControlName="liitteet" (liiteOut)="onSetLiite($event)"
                       htmlId="{{getIdPrefix()}}-{{idx}}_liite" [selite]="'LUPA'"
                       [isDisabled]="getMaaraTyyppiLabel(idx) === '' || null"
                       [maxLiiteLkm]="10" [lomakeId]="lomakeId"></touko-liite>
        </div>
      </div>
    </div>

    <div class="form-row">
        <div class="col-md-12 mb-0">
          <lomake-error [virheSanomat]="virheSanomat" [virheet]="getGroupVirheet(control)"></lomake-error>
      </div>
    </div>

  </div>
</ng-container>

<div class="form-row">
  <div class="col-md-9 mb-1 pl-0">
    <button (click)="addRow()" type="button" class="btn btn-l btn-primary mr-1"
            id="{{getIdPrefix()}}-yh-lisaa"
            attribute="luomuPoikkeus9c.lisaaLupa">Lisää hakemukseen uusi laji
    </button>
  </div>
</div>

</div>
</div>
