/**
 * Created by Seppo on 02/02/2018.
 */

import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LiitetiedostoResponse} from "./touko-lomake-utils";
import {AccountService} from "../account/account.service";

/**
 * Created by Seppo on 15/11/2017.
 *
 * Servicen avulla voidaan hakea yksittäistä liitetiedostoa koskevat tiedot
 * ja lähettää tiedostoja palvelimelle
 */
@Injectable()
export class LiitetiedostoPalvelinService {

  AS_URL = "/api/v1/as/liitetiedosto";
  AS_URL_MULTIPLE_FILES = "/api/v1/as/liitetiedostot";
  VK_URL = "api/v1/vk/liitetiedosto";
  VK_URL_MULTIPLE_FILES = "/api/v1/vk/liitetiedostot";

  constructor(private http: HttpClient, private accountService: AccountService) {
  }

  sendLiitetiedosto(file: File, selite: string, lomakeId: number): Promise<LiitetiedostoResponse> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('content-type', 'multipart/*');
    formData.append('selite', selite);
    formData.append('liite', file);
    formData.append('lomakeId', lomakeId.toString());

    const url = this.accountService.isViranomainen() ? this.VK_URL : this.AS_URL;
    return this.http.post(url, formData, {headers})
      .toPromise()
      .then(r => r as LiitetiedostoResponse);
  }

  deleteLiitetiedosto(id: number) {
    const url = this.accountService.isViranomainen() ? this.VK_URL : this.AS_URL;
    return this.http.delete(`${url}/${id}`)
      .toPromise()
      .then(r => null);
  }

  deleteLiitetiedostot(ids: number[]) {
    const url = this.accountService.isViranomainen() ? this.VK_URL_MULTIPLE_FILES : this.AS_URL_MULTIPLE_FILES;
    return this.http.delete(`${url}/${ids}`)
      .toPromise()
      .then(r => null);
  }

  downloadLiitetiedosto(id: number, params = null): Promise<Blob> {
    const url = this.accountService.isViranomainen() ? this.VK_URL : this.AS_URL;

    return this.http.get(`${url}/${id}`, {params, responseType: 'blob' as 'json'})
      .toPromise()
      .then(r => r as Blob);
  }
}
