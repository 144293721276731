import {Component, Input} from '@angular/core';
import {SummaryItem} from "ngx-fielding";

@Component({
  selector: 'title-summary',
  template: `
    <div class="row col">
      <h2 [attribute]="item.question" class="mb-3 mt-4"></h2>
    </div>
  `,
})
export class TitleSummaryComponent {
  @Input() item?: SummaryItem<any>;
}

@Component({
  selector: 'title-h3-summary',
  template: `
    <div class="row col">
      <h3 [attribute]="item.question" class="mb-3"></h3>
    </div>
  `,
})
export class TitleH3SummaryComponent {
  @Input() item?: SummaryItem<any>;
}

@Component({
  selector: 'title-h4-summary',
  template: `
    <div class="row col">
      <h4 [attribute]="item.question" class="mb-3"></h4>
    </div>
  `,
})
export class TitleH4SummaryComponent {
  @Input() item?: SummaryItem<any>;
}

@Component({
  selector: 'title-h5-summary',
  template: `
    <div class="row col">
      <h5 [attribute]="item?.question" class="mb-2"></h5>
    </div>
  `,
})
export class TitleH5SummaryComponent {
  @Input() item?: SummaryItem<any>;
}
