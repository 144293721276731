import {Component, OnInit} from "@angular/core";
import {LomakeService} from "../touko-lomake/touko-lomake.service";
import {LokitietoResponse} from "../touko-lomake/touko-lomake-utils";


@Component({
  selector: 'lomake-lokitiedot',
  templateUrl: './lomake-lokitiedot.component.html',
  styleUrls: ['../raportti/data-table.component.scss'] // Käytetään samoja tyylejä kuin raporteissa
})
export class LomakeLokitiedotComponent implements OnInit {

  lokitiedot: LokitietoTableView[] = [];
  public isLoading = true;

  constructor(private lomakeService: LomakeService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.lomakeService.getLokitiedot().then(response => {
      if (response) {
        this.lokitiedot = response.map(lokitieto => new LokitietoTableView(lokitieto));
      }
      this.isLoading = false;
    });
  }

  kuittaaLomake(lokitieto: LokitietoResponse, event) {
    this.lomakeService.kuittaaLokitieto(lokitieto).then(response => {
      if (response) {
        this.lokitiedot = this.lokitiedot.filter(l => l.id !== lokitieto.id);
      }
    });
    event.stopPropagation();
  }

}

export class LokitietoTableView {
  id: number;
  lomakeId: number;
  versio: number;
  asia: string;
  toimintotyyppi: string;
  tiedot: string;
  viesti: string;
  aikaleima: string;
  linkki: string;

  constructor(lokitieto: LokitietoResponse) {
    this.id = lokitieto.id;
    this.lomakeId = lokitieto.lomakeId;
    this.versio = lokitieto.versio;
    this.asia = lokitieto.asia;
    this.toimintotyyppi = lokitieto.toimintotyyppi;
    this.viesti = lokitieto.viesti;
    this.aikaleima = lokitieto.aikaleima;
    this.tiedot = lokitieto.tiedot;
    this.linkki = `/asiointi/lomake/tarkastus/${lokitieto.toimintotyyppi.toLowerCase()}/${lokitieto.asia}/v${lokitieto.versio}/${lokitieto.lomakeId}`;
  }
}
