import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareType, MatcherModel} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";

/**
 * Tutkimusalavalinnat muokkaavaat valittavien tutkimusten listaa.
 */
export class TutkimusalatGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string): QuestionGroup {
    const controls: QuestionControl[] = this.laheteKoodisto.koodisto.tutkimusala.map(el =>
        new QuestionControl({
          controlType: controlType,
        initialValue: null,
        key: el.id,
        htmlId: el.id,
        label: this.getLocalizedOf(el, 'kuvaus'),
        modifiers: {
          'elainlajit.elainlajiryhma': new MatcherModel(null, TutkimusalatGroup.anyObjectValueTrueResetFn, TutkimusalatGroup.modifierActionFn, CompareType.SOME)
        }
      })
    );

    return new QuestionGroup({
      key: "tutkimusalat",
      label: this.getLabel('tutkimusala'),
      controls: controls,
    });
  }
}
