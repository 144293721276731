import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Host,
  Input,
  OnInit,
  Optional,
  Output
} from "@angular/core";
import {ControlContainer, FormGroup} from "@angular/forms";
import {QuestionComponentData, QuestionGroup} from "ee-lahete";
import {SivuComponent} from "../../../touko-lomake/touko-sivu.component";


/**
 * @example: Kun määritelty:
 * ```
 *  lomakeGroup: FormGroup;
 *  lomakeModel: {[s: string]: QuestionGroup};
 *  lomakeModelList: QuestionGroup[];
 * ```
 *
 *  Lomakkeen voi luoda kokonaan dynaamisesti:
 *
 * ```
 * <form [formGroup]="lomakeGroup">
 *  <question-group *ngFor="let group of lomakeModelList"
 *    [formGroupName]="group.key"
 *    [questionGroup]="group"></question-group>
 *  </question-group>
 * </form>
 * ```
 *
 * Tai `FormGroup` kerrallaan
 * ```
 * <form [formGroup]="lomakeGroup">
 *  <question-group formGroupName="elainlajit" [questionGroup]="lomakeModel.elainlajit"></question-group>
 * </form>
 * ```
 */
@Component({
  selector: 'question-group',
  template: `
    <ng-container [formGroup]="controlContainer.control">
      <ng-container *ngIf="!hideGroups && questionGroup.groups">
        <ng-container *ngFor="let group of questionGroup.groups">
          <ng-container [ngSwitch]="group.groupComponentType">
            <question-tausta-textarea [formGroupName]="group.key" *ngSwitchCase="'tausta-textarea'"
                                      [eelControlMatcher]="group"
                                      [questionGroup]="group"></question-tausta-textarea>
            <question-tausta-checkbox-textarea [formGroupName]="group.key" *ngSwitchCase="'tausta-checkbox-textarea'"
                                               [eelControlMatcher]="group"
                                               [questionGroup]="group"></question-tausta-checkbox-textarea>
            <question-tausta-select-text [formGroupName]="group.key" *ngSwitchCase="'tausta-select-text'"
                                         [eelControlMatcher]="group"
                                         [questionGroup]="group"></question-tausta-select-text>
            <question-tausta-text-input [formGroupName]="group.key" *ngSwitchCase="'tausta-text-2-column'"
                                        [eelControlMatcher]="group"
                                        [questionGroup]="group"></question-tausta-text-input>
            <question-tausta-radio-date-text [formGroupName]="group.key"
                                             *ngSwitchCase="'tausta-radio-date-text'"
                                             [eelControlMatcher]="group"
                                             [questionGroup]="group"></question-tausta-radio-date-text>
            <question-tausta-alert [formGroupName]="group.key"
                                   *ngSwitchCase="'tausta-alert'"
                                   [eelControlMatcher]="group"
                                   [questionGroup]="group"></question-tausta-alert>
            <question-group-nested [formGroupName]="group.key"
                                   [sivuRef]="sivuRef"
                                   *ngSwitchCase="'tausta-group-nested'"
                                   [eelControlMatcher]="group"
                                   [hideControls]="true"
                                   [hideArray]="true"
                                   [questionGroup]="group"></question-group-nested>
            <question-group-nested [formGroupName]="group.key"
                                   [sivuRef]="sivuRef"
                                   *ngSwitchCase="'nested'"
                                   [eelControlMatcher]="group"
                                   [questionGroup]="group"></question-group-nested>
          </ng-container>
        </ng-container>
      </ng-container>

      <question-array *ngIf="!hideArray && questionGroup.array"
                      [questionParent]="questionGroup"
                      [questionArray]="questionGroup.array"
                      [sivuRef]="sivuRef"
                      [eelControlMatcher]="questionGroup.array"
                      [eelControl]="controlContainer.control.get(questionGroup.array.key)"
                      [formArrayName]="questionGroup.array.key"></question-array>

      <div *ngIf="!hideControls && questionGroup.controls" [ngClass]="{'form-row': hasCustomStyles}">
        <div *ngFor="let questionData of questionComponentDataList; last as isLast"
             [ngClass]="questionData.question?.options?.style">
          <question-control [sivuRef]="sivuRef"
                            [control]="controlContainer.control.get(questionData.formControlName)"
                            [questionData]="questionData"
                            [parent]="questionGroup"
                            [isLastOfGroup]="isLast">
          </question-control>
        </div>
      </div>
      <!--Angularista puuttuu directiven lisääminen dynaamisesti luotuun komponenttiin-->
      <!--<ng-template questionPlaceholder></ng-template>-->

      <question-error [control]="controlContainer?.control"
                      [sivuRef]="sivuRef"
                      [fullPath]="questionGroup.fullPath"
                      [errorMessages]="questionGroup.errorMessages"></question-error>

    </ng-container>
  `
})
export class QuestionGroupComponent implements OnInit, AfterViewInit {

  @Input() sivuRef: string;
  @Input() questionGroup: QuestionGroup;
  @Input() hideGroups = false;
  @Input() hideControls = false;
  @Input() hideArray = false;
  @Output() init = new EventEmitter<boolean>();
  hasCustomStyles = false;
  questionComponentDataList: QuestionComponentData[] = [];

  constructor(public controlContainer: ControlContainer,
              private readonly cdRef: ChangeDetectorRef,
              @Host() @Optional() private readonly sivu?: SivuComponent) {
  }

  ngOnInit(): void {
    this.applyChanges();
  }

  ngAfterViewInit() {
    if (!this.sivuRef && this.sivu) {
      this.sivuRef = this.sivu.ref;
      this.init.emit(true);
      this.cdRef.detectChanges();
    }
  }

  applyChanges() {
    if (this.questionGroup.controls) {
      this.hasCustomStyles = this.questionGroup.controls.some(q => q.options && q.options.style);
      this.questionComponentDataList = this.questionGroup.controls.map(q => new QuestionComponentData(q, this.controlContainer.control as FormGroup));
    }
  }
}
