import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LomakeResponse} from "../touko-lomake/touko-lomake-utils";
/**
 * Created by Seppo on 21/01/2019.
 */

@Component({
  selector: 'lomake-tapahtumat',
  templateUrl: './lomake-tapahtumat.component.html'
})
export class LomakeTapahtumatComponent {
  @Input() lomakeVersiot: LomakeResponse[];
  @Output() selectVersio = new EventEmitter<LomakeResponse>();

  clickVersio(lomake: LomakeResponse) {
    this.selectVersio.emit(lomake);
  }
}
