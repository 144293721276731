import {FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {
  TitleH3SummaryComponent,
  TitleH4SummaryComponent,
  TitleH5SummaryComponent,
  TitleSummaryComponent
} from "../../summary/title-summary/title-summary.component";
import {TranslatedQuestionSummaryComponent} from "../../summary/translated-question-summary.component";
import {AnswerOnlySummaryComponent} from "../../summary/answer-only-summary.component";
import {TitleWithBadgeSummaryComponent} from "../../summary/title-summary/title-with-badge-summary.component";

const createSubTitleSummary = (showFn: (group: FieldGroup) => boolean, summaryComponent, classList = []): SummaryFnType => {
  return (fieldGroup) => {
    return new SummaryItem<any>(
      fieldGroup.field.label,
      null,
      {show: showFn(fieldGroup.asGroup()), classList: classList},
      summaryComponent)
  }
}

const createSubTitleWithBadgeSummary = (showFn: (group: FieldGroup) => boolean): SummaryFnType => {
  return (fieldGroup :FieldGroup) => {
  return new SummaryItem<any>(fieldGroup.field.label, null, {
    show: showFn(fieldGroup.asGroup()),
    badge: titleBadge(fieldGroup),
    classList: ['mt-3']
  }, TitleWithBadgeSummaryComponent)
  }
}

const titleBadge = (group: FieldGroup): string => {
  if (group.get('lopetaToiminta').value) {
    return 'yhtToimintaLopetetaan'
  } else if (Object.values(group.controlFields).slice(1).some(c => c?.summary?.show)) {
    return 'yhtUusiIlmoitettavaToiminta';
  }
}

const isAnyChildVisible: (group: FieldGroup) => boolean = group =>  {
  return Object.values(group.controlFields).some(c => c?.summary?.show)
}

export const titleSummary: SummaryFnType = createSubTitleSummary(group => isAnyChildVisible(group), TitleSummaryComponent, ['mt-2']);

export const subTitleH3Summary: SummaryFnType = createSubTitleSummary(group => isAnyChildVisible(group), TitleH3SummaryComponent, ['mt-3']);

export const subTitleH3WithBadgeSummary: SummaryFnType = createSubTitleWithBadgeSummary(group => isAnyChildVisible(group));

export const subTitleH4Summary: SummaryFnType = createSubTitleSummary(group => isAnyChildVisible(group), TitleH4SummaryComponent, ['pl-5 mt-2']);

export const subTitleH5Summary: SummaryFnType = createSubTitleSummary(group => isAnyChildVisible(group), TitleH5SummaryComponent, ['pl-7 mt-2']);

export const valueSummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, control.value, {
    show: control.value,
    classList: ['pl-7'],
    valueSuffix: 'lannoiterekisteri.tnVuosi'
  });
}

export const answerSummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, control.value, {show: control.value, classList: ['pl-7']}, AnswerOnlySummaryComponent);
}

export const radioSummary = control => {
  const value = control.field.valueOptions.find(o => o.value === control.value);
  return new SummaryItem<any>(value?.label, null, {show: control.value}, TranslatedQuestionSummaryComponent)
}

export const checkBoxSummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, null, {show: control.value, classList: ['pl-7']});
}
