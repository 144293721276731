import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";

@Component({
  selector: 'title-with-badge-summary',
  template: `
    <div class="row">
      <div class="col col-left">
        <h3 [attribute]="item.question" class="mb-3"></h3>
      </div>
      <div class="col col-right">
        <span *ngIf="showBadge"
              [attribute]="'lannoiterekisteri.' + item.options.badge"
              class="badge"
              [ngClass]="bagdeStyle()">
        </span>
      </div>
    </div>
  `,
})
export class TitleWithBadgeSummaryComponent {
  @Input() item?: SummaryItem<any>;

  showBadge(): boolean {
    return Boolean(this.item.options?.badge);
  }

  bagdeStyle(): string {
    if (this.item?.options?.badge === 'yhtToimintaLopetetaan') {
      return 'badge-danger'
    } else if (this.item?.options?.badge === 'yhtUusiIlmoitettavaToiminta') {
      return 'badge-success'
    }
  }
}
