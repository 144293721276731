import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ControlContainer, Validators} from "@angular/forms";
import {FieldArray, FieldGroup} from "ngx-fielding";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";

@Component({
  selector: 'siirtymavaiheen-lyhennys',
  template: `
    <div [formGroup]="formGroup" class="mb-5">
      <h3 attribute="luomu.siirtymavaiheenLyhennys">Siirtymävaiheen lyhennys</h3>
      <fieldset>
        <legend attribute="luomu.siirtymavaiheenLyhennyksenHaku"
                class="font-base font-weight-normal text-body required">Haen
          siirtymävaiheen lyhentämistä
        </legend>
        <touko-radio-input
            formControlName="siirtymavaiheenLyhennysValinta"
            htmlId="siirtymavaiheenLyhennysEi"
            labelAttribute="teksti.ei"
            radioGroup="siirtymavaiheenLyhennys"
            value="ei"
            (change)="deleteLiite(hakemusSiirtymavaiheenLyhentamiseksi.controls[0])">
        </touko-radio-input>
        <touko-radio-input
            formControlName="siirtymavaiheenLyhennysValinta"
            htmlId="siirtymavaiheenLyhennysKylla"
            labelAttribute="teksti.kylla"
            radioGroup="siirtymavaiheenLyhennys"
            value="kylla"></touko-radio-input>
        <div *ngIf="isSiirtymavaiheKylla" class="ml-5">
          <label attribute="luomu.hakemusSiirtymaVaiheenLyhentamiseksi" class="required"></label>
          <liite-list htmlId="siirtymavaiheenLyhennysLiite"
                      labelledBy="siirtymavaiheenLyhennysKylla"
                      [liitteetArray]="formGroup.controlFields.hakemusSiirtymavaiheenLyhentamiseksi.asArray()"
                      (liiteOut)="setLiitteet($event)" [lomakeId]="lomakeId"></liite-list>
          <validation-error
              [field]="formGroup.controlFields.hakemusSiirtymavaiheenLyhentamiseksi.asArray()"></validation-error>
        </div>
      </fieldset>
    </div>
  `
})

@AutoUnsubscribe
export class SiirtymavaiheenLyhennysComponent implements OnInit {
  @Input() lomakeId: number;
  @Output() onSetLiitteet: EventEmitter<LiitetiedostoResponse[]> = new EventEmitter();

  constructor(private controlContainer: ControlContainer, private liitetiedostoService: LiitetiedostoPalvelinService) {
  }

  get hakemusSiirtymavaiheenLyhentamiseksi(): FieldArray {
    return this.formGroup.controlFields.hakemusSiirtymavaiheenLyhentamiseksi.asArray();
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(value => {
      if (value.siirtymavaiheenLyhennysValinta === 'kylla' && value.hakemusSiirtymavaiheenLyhentamiseksi.length === 0) {
        this.hakemusSiirtymavaiheenLyhentamiseksi.setValidators(Validators.required);
        this.hakemusSiirtymavaiheenLyhentamiseksi.setErrors({'required': true});
      } else {
        this.hakemusSiirtymavaiheenLyhentamiseksi.setValidators(null)
        this.hakemusSiirtymavaiheenLyhentamiseksi.setErrors(null)
      }
    })
  }

  deleteLiite(item: any) {
    if (item) {
      this.hakemusSiirtymavaiheenLyhentamiseksi.valid;
      const itemId = item.controls.id.value
      return this.liitetiedostoService.deleteLiitetiedosto(itemId)
          .then(_ignore => {
            this.onSetLiitteet.emit(this.hakemusSiirtymavaiheenLyhentamiseksi.value);
            this.hakemusSiirtymavaiheenLyhentamiseksi.controls = [];
            this.hakemusSiirtymavaiheenLyhentamiseksi.setValue([]);
          });
    }
  }

  get isSiirtymavaiheKylla(): boolean {
    return this.formGroup.get('siirtymavaiheenLyhennysValinta').value === "kylla";
  }

  setLiitteet(evt) {
    this.onSetLiitteet.emit(evt)
  }
}
