import {Component} from '@angular/core';
import {NgbModalConfigService} from "./configs/ngb-modal-config.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(private ngbModalConfig: NgbModalConfigService) {
  }
}
