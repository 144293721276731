<p>
<div class="card">
  <div class="card-body bg-light">
    <div class="row row-cols-2">
      <div class="col">
        <button *ngIf="nautaElainhaku()" (click)="handleNautarekisterihakuModal()"
                id="haeElaimenTiedot"
                type="button" class="btn btn-primary"
                attribute="sahkoelain.haeElaimenTiedot">
          Hae eläimen tiedot
        </button>
        <p>
      </div>

      <div class="col">
        <button *ngIf="questionParent.questions.length > 1" class="close"
                aria-label="Poista tutkittava näyte"
                type="button"
                (click)="removeCurrentNaytetieto()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <ng-container [formGroup]="controlContainer.control">
      <div class="form-row">
        <div class="col-md-6" *ngFor="let control of questionGroup.controls">
          <div class="form-group" [ngSwitch]="control.controlType">
            <ng-container *ngSwitchCase="'text'">
              <label [htmlFor]="control.key + '-' + currDateInMs">{{control.label}}</label>
              <input [formControlName]="control.key" class="form-control" [id]="control.key + '-' + currDateInMs"
                     question-error
                     [isInvalid]="controlContainer.control.get(control.key).invalid"
                     [isTouched]="controlContainer.control.get(control.key).touched">
              <question-error [sivuRef]="sivuRef"
                              [fullPath]="control.fullPath"
                              [errorMessages]="control.errorMessages"
                              [control]="controlContainer.control.get(control.key)"></question-error>
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              <label [htmlFor]="control.key">{{control.label}}</label>
              <select [formControlName]="control.key" class="form-control">
                <option [value]="null" disabled selected>{{'teksti.valitse' | attribute}}...</option>
                <option *ngFor="let option of control.options.selection" [value]="option.value">
                  {{option.label}}
                </option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="'pvm'">
              <label [htmlFor]="'date-input-' + control.key + '-' + currDateInMs">{{control.label}}</label>
              <touko-pvm [formControlName]="control.key" [htmlId]="control.key + '-' + currDateInMs"></touko-pvm>
            </ng-container>
            <ng-container *ngSwitchCase="'pvmVuosi'">
              <label [htmlFor]="'date-input-' + control.key + '-' + currDateInMs">{{control.label}}</label>
              <touko-pvm [formControlName]="control.key" [htmlId]="control.key + '-' + currDateInMs"
                         [maxDate]="getMaxBirthDate()"
                         [minDate]="getMinBirthDate()" [navigation]="'select'"></touko-pvm>
            </ng-container>
            <ng-container *ngSwitchCase="'hidden'"></ng-container>
            <ng-template *devFeature>
              <ng-container *ngSwitchDefault>
                <label [formControlName]="control.key" [htmlFor]="control.key">{{control.label}} (?)</label>
                <input [id]="control.key" class="form-control">
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="form-row">
        <question-group class="col-12"
                        [questionGroup]="questionGroup"
                        [hideArray]="true"
                        [hideControls]="true"></question-group>
      </div>

      <br>

      <div class="form-row">
        <question-array class="col-12" [questionArray]="questionGroup.array" [questionParent]="questionGroup"
                        [formArrayName]="'naytteet'"></question-array>
      </div>
    </ng-container>

    <div class="form-row" *ngFor="let tutkimus of valitutTutkimukset">
      <ng-container *ngIf="!tutkimus.isAdded">
        <div class="col-12 mt-0 mb-0 pl-md-4">
          <p class="small"><strong>{{tutkimus.nayteTutkimus.tutkimus.label}}</strong></p>
        </div>

        <div class="col mb-4 pl-md-4">
          <button (click)="addNewNayte(tutkimus)"
                  type="button" class="btn btn-primary"
                  attribute="sahkoelain.lisaaTutkimusUusiNayte">
            Lisää tutkimus uudelle näytteelle
          </button>

          <button *ngIf="tutkimus.canAddToExisting"
                  (click)="addNayteForExisting(tutkimus)"
                  type="button" class="ml-2 btn btn-primary"
                  attribute="sahkoelain.lisaaTutkimusEdeltavaNayte">
            Lisää tutkimus aiemmalle näytteelle
          </button>
        </div>
      </ng-container>
    </div>

    <div class="form-row mt-4" *ngIf="isLast">
      <div class="col">
        <button type="button"
                class="btn btn-primary"
                attribute="sahkoelain.lisaaTutkittavaElain"
                (click)="addFieldToParent()">Lisää tutkittava eläin/kohde
        </button>

        <div class="btn-group ml-2">
          <button type="button"
                  class="btn btn-outline-primary"
                  attribute="sahkoelain.kopioiEdellinenNayte"
                  (click)="copyFieldToParent(kopiot.value)">Kopioi edellinen näyte</button>
          <div class="btn-group">
            <select #kopiot id="btnGroupDrop1" class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-label="kopion maara">
              <option attribute="sahkoelain.1Kerran" value="1">1 kerran</option>
              <option attribute="sahkoelain.5Kertaa" value="5">5 kertaa</option>
              <option attribute="sahkoelain.10Kertaa" value="10">10 kertaa</option>
              <option attribute="sahkoelain.20Kertaa" value="20">20 kertaa</option>
              <option attribute="sahkoelain.40Kertaa" value="40">40 kertaa</option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
