<div class="card bg-light kesken">
  <div class="card-body">
    <p [id]="'etusivu-kesken-kuvaus-'+lomake.id" [attribute]="getKuvausTeksti(lomake.asia, lomake.toimintotyyppi).fullKey">{{lomake.kuvaus}}</p>
    <dl class="row">
      <dt class="col-6 small" attribute="etusivu.luotu">Luotu</dt>
      <dd class="col-6 small">{{lomake.perpvm | date: 'd.M.yyyy'}}</dd>
      <dt class="col-6 small" attribute="etusivu.paivitetty">Päivitetty</dt>
      <dd class="col-6 small">{{lomake.paipvm | date: 'd.M.yyyy'}}</dd>
      <dd class="col-6 small" attribute="etusivu.muokannutToimija">Viimeksi muokannut</dd>
      <dd class="col-6 small">{{lomake.muokannutToimija}}</dd>
    </dl>

    <div *ngIf="lomake.lisatieto">
      <tila-badge [lomakeTila]="lomake.tila"></tila-badge>
      <p class="small">{{lomake.lisatieto}}</p>
    </div>
  </div>
  <div class="card-footer">
    <ng-container *ngIf="lomake.viimeksiMuokannut">
      <button (click)="jatkaLomake()"
              class="btn btn-success" attribute="teksti.jatka" [id]="'etusivu-kesken-jatka-'+lomake.id">Jatka</button>
      <button *ngIf="lomake.tila !== 'ODOTTAA_LISATIETOJA'" class="btn btn-outline-danger ml-1" (click)="poistaLomake()"
              attribute="teksti.poista" [id]="'etusivu-kesken-poista-'+lomake.id">Poista
      </button>
    </ng-container>

    <ng-container *ngIf="!lomake.viimeksiMuokannut">
      <button class="btn btn-primary" (click)="esikatsele()"
               [id]="'etusivu-kesken-esikatselu-'+lomake.id" attribute="etusivu.esikatsele">Esikatsele
      </button>
      <button class="btn btn-outline-primary float-right" (click)="otaLomakeKasittelyyn()"
              [attr.aria-label]="'etusivu.otaKasittelyyn' | attribute"
              [id]="'etusivu-kesken-kasittelyyn-'+lomake.id">
        <span class="mx-0 fa fa-lock"></span>
      </button>
    </ng-container>
  </div>
</div>
