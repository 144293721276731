import {Component} from "@angular/core";
import {Teksti} from "../../../utils/teksti";
import {LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {AccountService} from "../../../account/account.service";
import {LannoitevuosiilmoitusData} from "./lannoitevuosiilmoitus-data";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {LomakeInfo} from "../common/lomake-info";
import {LannoiteExcelMapper} from "./lannoite-excel-mapper";
import {JSONOut} from "../../syote/custom/touko-excel-lataus.component";
import {ExcelMapper} from "../common/excel-mapper";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";

@Component({
  templateUrl: 'lannoitevuosiilmoitus.component.html',
  selector: 'lannoitevuosiilmoitus',
  providers: [LannoitevuosiilmoitusData, ProcessLomakeService]
})
export class LannoitevuosiilmoitusComponent extends LomakeV2BaseDirective {

  excelMapper: ExcelMapper = new LannoiteExcelMapper();
  excelTypeFormPaths = {
    lannoitevalmistus: "toimintaJaTuoteluokatKansallinen.lannoiteValmisteenValmistus",
    lannoiteomaankayttoon: "toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuotantoOmaKaytto",
    lannoitetuonti: "toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuonti",
    lannoitevienti: "toimintaJaTuoteluokatKansallinen.lannoiteValmisteenVienti",
    lannoitejakelu: "toimintaJaTuoteluokatKansallinen.lannoiteValmisteenJakelija",
    lannoiteeuvalmistus: "euToiminta.euLannoitevalmisteenValmistaja",
    lannoiteeutuonti: "euToiminta.euLannoiteValmisteenTuonti",
    lannoiteeuvienti: "euToiminta.euLannoitevalmisteenVieja",
    lannoiteeujakelu: "euToiminta.euLannoitevalmisteenJakelija",
    lannoitesisamarkkinatuonti: "muuToiminta.lannoitevalmisteenMarkkinointi",
  }

  constructor(protected readonly processLomakeService: ProcessLomakeService,
              protected readonly accountService: AccountService,
              protected readonly data: LannoitevuosiilmoitusData
  ) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  mapJsonToLomake(excelData: JSONOut) {
    if (excelData.errors.length === 0) {
      const formPath = this.excelTypeFormPaths[excelData.json.type];
      this.lomake.get(formPath).initValue(this.excelMapper.mapExcelData(excelData.json))
    }
  }

  removeDeletedExcelData(dataType) {
    const formPath = this.excelTypeFormPaths[dataType];
    this.lomake.get(formPath).asArray().clear();
  }

  storeExcel(excel) {
    this.liitetiedostot = excel;
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new LannoitevuosiilmoitusInfo(accountType);
  }

  initLomakeBehaviour() {
    // Not used currently
  }

  addLomakeBehaviour() {
    this.lomake.get('tuotteet.excelTiedostot').valueChanges.subscribe(() => this.saveLomake());
    this.setEiToimintaaStatus(this.lomake.get('tuotteet.lannoiteEiToimintaa') ? this.lomake.get('tuotteet.lannoiteEiToimintaa').value : false);
  }

  setEiToimintaaStatus(value) {
    if (value) {
      this.lomake.get("tuotteet.excelTiedostot").disable({emitEvent: false});
    } else {
      this.lomake.get("tuotteet.excelTiedostot").enable({emitEvent: false});
    }
  }
}

class LannoitevuosiilmoitusInfo implements LomakeInfo {
  readonly lomakeTitle: Teksti = new Teksti("Ilmoitus rekisteröintiä varten", "vuosiilmoitus", "lannoitevuosiilmoitus");
  readonly asia = "lannoitevuosiilmoitus";
  readonly toimintotyyppi = 'vuosiilmoitus';
  readonly serverBaseUrl: string;
  readonly clientComponentVersion = 'v1'

  readonly yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly defaultRooli: string;

  readonly ohjeet: { [s: string]: string[] } = {};

  constructor(kayttajaRooli: "as" | "vk") {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.VK_YH, YhteyshenkiloValue.TOIMIJAN_YH, YhteyshenkiloValue.TUONNIN_YH, YhteyshenkiloValue.VUOS_ILM];
    this.defaultRooli = this.yhteyshenkiloRoolit[0].value;
    const prefix = 'lannoitevuosiilmoitus.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1']);
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/vuosiilmoitukset/lannoitevuosiilmoitus/vuosiilmoitus`;
  }
}
