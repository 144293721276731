import {Component, Input} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {AbstractField, FieldArray, FieldGroup} from "ngx-fielding";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PeltolohkoModalComponent} from "./peltolohko-modal.component";
import {AutoUnsubscribe} from "../../../../utils/auto-unsubscribe.decorator";

@Component({
  selector: 'peltolohko-list',
  template: `
    <div [formGroup]="formGroup" class="mb-4">
      <table class="table" id="lohkotiedot-table">
        <thead>
        <tr class="row">
          <th id="lohkotunnus" class="h4 col-4 d-flex justify-content-between mb-0 p-3">
            <span attribute="luomu.tunnus">Tunnus</span>
            <button [id]="'sortByTunnus-' + index" type="button" class="btn-no-style"
                    (click)="sortByKey('lohkotunnus')">
              <i class="fa fa-sort text-primary" aria-hidden="true"></i>
            </button>
          </th>
          <th id="lohkoNimi" class="h4 col-4 d-flex justify-content-between mb-0 p-3">
            <span attribute="luomu.nimi">Nimi</span>
            <button [id]="'sortByNimi-' + index" type="button" class="btn-no-style" (click)="sortByKey('lohkoNimi')">
              <i class="fa fa-sort text-primary" aria-hidden="true"></i>
            </button>
          </th>
          <th id="LohkoPinta-ala" class="h4 col-4 d-flex justify-content-between mb-0 p-3">
            <span attribute="luomu.pintaalaHa">Pinta-ala (ha)</span>
            <button [id]="'sortByAla-' + index" type="button" class="btn-no-style" (click)="sortByKey('pintaAla')">
              <i class="fa fa-sort text-primary" aria-hidden="true"></i>
            </button>
          </th>
        </tr>
        </thead>
        <tbody aria-live="polite" id="lohkotiedot-table-body">
        <ng-container *ngFor="let peruslohko of peltolohkot.controlFields; index as peruslohkoIndex;">
          <tr class="row">
            <td *ngIf="peruslohko.get('lohkotunnus'); else eiTunnustaBlock" class="font-weight-normal col-4 p-3">
              {{peruslohko.get('lohkotunnus').value}}
            </td>
            <ng-template #eiTunnustaBlock>
              <td class="font-weight-normal col-4 p-3 font-italic" attribute="luomu.lisattyKasin">(lisätty käsin)</td>
            </ng-template>
            <td class="font-weight-normal col-4 p-3">{{peruslohko.get('lohkoNimi').value}}</td>
            <td class="font-weight-normal col-2 p-3">{{peruslohko.get('pintaAla').value}}</td>
            <td class="col-2 p-0 d-flex justify-content-end">
              <button [id]="'poistaPeruslohko-' + index" type="button" class="btn btn-link-primary font-weight-semibold"
                      attribute="teksti.poista"
                      (click)="removePeruslohko(peruslohkoIndex)">Poista
              </button>
            </td>
          </tr>
          <tr *ngFor="let kasvulohko of peruslohko?.get('kasvulohkot')?.asArray().controlFields; index as kasvulohkoIndex"
              class="row">
            <td class="font-weight-normal col-4 p-3 pl-6">{{kasvulohko.get('lohkotunnus').value}}</td>
            <td class="font-weight-normal col-4 p-3">{{peruslohko.get('lohkoNimi').value}}</td>
            <td class="font-weight-normal col-2 p-3">{{kasvulohko.get('pintaAla').value}}</td>
            <td class="col-2 p-0 d-flex justify-content-end">
              <button [id]="'poistaKasvulohko-' + index" type="button" class="btn btn-link-primary font-weight-semibold"
                      attribute="teksti.poista"
                      (click)="removeKasvulohko(peruslohko, peruslohkoIndex, kasvulohkoIndex)">Poista
              </button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <button [id]="'avaaLohkotietoModaali-' + index" type="button" class="btn btn-primary"
              attribute="luomu.lisaaLohkoja"
              (click)="openLohkoModal()">
        Lisää lohkoja
      </button>
    </div>
  `
})

@AutoUnsubscribe
export class PeltolohkoListComponent {
  @Input() peltolohkotArray: Array<any>;
  @Input() index: number;
  dataIsAscending = true;

  constructor(private controlContainer: ControlContainer, private readonly ngbModalService: NgbModal) {
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get peltolohkot(): FieldArray {
    return this.formGroup.get('peltolohkot') as FieldArray;
  }

  sortByKey(key): void {
    this.dataIsAscending = !this.dataIsAscending

    this.peltolohkot.controlFields.sort((a, b) => {
      const keyA = a.get(key).value;
      const keyB = b.get(key).value;

      if (this.dataIsAscending) {
        return typeof keyA === 'string' ? keyB.localeCompare(keyA) : keyB - keyA;
      } else {
        return typeof keyA === 'string' ? keyA.localeCompare(keyB) : keyA - keyB;
      }
    })
  }

  removePeruslohko(index: number): void {
    this.peltolohkot.removeAt(index);
  }

  removeKasvulohko(peruslohko: AbstractField, peruslohkoIndex: number, kasvulohkoIndex: number): void {
    if (peruslohko.get('kasvulohkot').asArray().controlFields.length === 1) {
      this.peltolohkot.removeAt(peruslohkoIndex);
    } else {
      peruslohko.get('kasvulohkot').asArray().removeAt(kasvulohkoIndex);
    }
  }

  openLohkoModal(): Promise<any> {
    const modalRef = this.ngbModalService.open(PeltolohkoModalComponent, {size: 'xl'});
    modalRef.componentInstance.lohkotiedotArray = this.peltolohkotArray;

    modalRef.closed.subscribe((lohkotTiedotFromModal) => this.addLohkotiedotFromModal(lohkotTiedotFromModal))
    return modalRef.result;
  }

  addLohkotiedotFromModal(lohkotTiedotFromModal: FieldArray) {
    if (lohkotTiedotFromModal) {
      lohkotTiedotFromModal.controlFields.forEach(lohko => {
        if (this.peltolohkot.controlFields.length && lohko.get('lohkotunnus')) {
          let peltolohkoExists = this.peltolohkot.controlFields.find(peltolohko => {
            if (peltolohko.get('lohkotunnus')) {
              return peltolohko.get('lohkotunnus').value === lohko.get('lohkotunnus').value;
            } else {
              return false;
            }
          })
          if (peltolohkoExists) {
            const existingKasvulohkot = peltolohkoExists.get('kasvulohkot').asArray();
            const newKasvulohkot = lohko.get('kasvulohkot').asArray();
            newKasvulohkot.controls.forEach(control => {
              existingKasvulohkot.push(control);
            });
          } else {
            this.peltolohkot.push(lohko);
          }
        } else {
          this.peltolohkot.push(lohko);
        }
      });
    }
  }
}
