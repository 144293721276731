import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl} from "@angular/forms";
import {QuestionComponent, QuestionComponentData} from "ee-lahete";

@Component({
  selector: 'question-text',
  template: `
    <div [formGroup]="controlContainer?.control" class="form-group">
      <label *ngIf="questionData.label" [htmlFor]="questionData.htmlId">{{questionData.label}}</label>
      <input aria-label="tekstikentta" question-error [isInvalid]="control.invalid" [isTouched]="control.touched"
             class="form-control"
             [id]="questionData.question.fullPath" placeholder="{{questionData.placeholder}}"
             [formControlName]="questionData.formControlName">
      <question-error [sivuRef]="sivuRef"
                      [fullPath]="questionData.question.fullPath"
                      [errorMessages]="questionData.question.errorMessages"
                      [control]="control"></question-error>
    </div>
  `
})
export class QuestionTextComponent extends QuestionComponent implements OnInit {
  @Input() questionData: QuestionComponentData;
  @Input() sivuRef: string;
  control: FormControl;

  constructor(public controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    if (this.controlContainer.control && this.questionData) {
      this.control = this.controlContainer.control.get(this.questionData.formControlName) as FormControl;
    }
  }
}
