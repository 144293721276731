/**
 * Created by Seppo on 06/03/2018.
 */

import {Pipe, PipeTransform} from "@angular/core";
import {AttributeService} from "./attribute.service";

/**
 * Lokalisointi pipe. Voidaan käyttää kun direktiivin käyttö ei ole mahdollista.
 */
@Pipe({name: 'attribute', pure: false})
export class AttributePipe implements PipeTransform {
  returnVal = "...";
  constructor(private attributeService: AttributeService) {
  }

  transform(attributeKey: string, lang: string = null): string {
    if (attributeKey != null) {
      const label = this.attributeService.resolveLabel(attributeKey);
      const view = this.attributeService.resolveView(attributeKey);
      this.attributeService.label(label, view, lang === "multi" ? null : lang, lang === "multi").subscribe(value => {
        this.returnVal = value?.teksti || attributeKey;
      });
    }
    return this.returnVal;
  }
}
