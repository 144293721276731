import {LaheteLanguage, LaheteService} from "../lahete.service";
import {LaheteKoodisto} from "../lahete-koodisto";
import {Translations} from "../translations";
import {ElementRef, Renderer2} from "@angular/core";
import {CompareAction, QuestionGroup} from "ee-lahete";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {GroupBuilder} from "./group-build-options";

// @dynamic
export abstract class AbstractLaheteGroup {
  laheteKoodisto: LaheteKoodisto;
  lang: LaheteLanguage;

  protected useBuilderValues = (builder: GroupBuilder, groupKey: string) => {
    return builder.applyValues && builder.initialValues[groupKey];
  }

  static lahetysosoiteMapper = (osoite): string => osoite.substring(0, 3);

  static intersection = (setA, setB, intersectFn = (set, elem) => set.has(elem)) => {
    const _intersection = new Set();
    for (const elem of setB) {
      if (intersectFn(setA, elem)) {
        _intersection.add(elem);
      }
    }
    return _intersection;
  }

  /**
   * Filtteröi elementin näkymästä CSS-tyylien avulla.
   *
   * @param el - Filtteröitävä elementti
   * @param renderer - Angular renderer
   * @param compareAction - Elementille suoritettava toiminto
   */
  static filterActionFn = (el: ElementRef, renderer: Renderer2, compareAction: CompareAction) => {
    if (compareAction === CompareAction.FILTER) {
      renderer.removeClass(el.nativeElement, 'd-none');
    } else {
      renderer.addClass(el.nativeElement, 'd-none');
    }
  }

  /**
   * Disabloi/enabloi lomakkeen kontrollin.
   *
   * @param control - Disabloitava/enabloitava kontrolli
   * @param compareAction - kontrollille suoritettava toiminto
   */
  static disablerActionFn = (control: AbstractControl, compareAction: CompareAction) => {
    if (compareAction === CompareAction.DISABLE) {
      control.disable({emitEvent: false, onlySelf: true});
    } else if (control) {
      control.enable({emitEvent: false, onlySelf: true});
    }
  }

  /**
   * Muokkaa lomakkeen kontrollin arvoa
   *
   * @param control - Kontrolli, jonka arvoa muutetaan
   * @param compareAction - Kontrollille suoritettava toiminto
   * @param value - Kontrollin uusi arvo
   */
  static modifierActionFn = (control: AbstractControl, compareAction: CompareAction, value) => {
    if (compareAction === CompareAction.RESET && control.value) {
      control.reset(value);
    } else if (compareAction === CompareAction.SELECT_ONLY_CHECKBOX_OPTION ||
      compareAction === CompareAction.DESELECT_ONLY_CHECKBOX_OPTION ||
      compareAction === CompareAction.SELECT_ONLY_RADIO_OPTION) {
      control.setValue(value);
    }
  }

  static getFilterReturnVal = (value: boolean) => value ? CompareAction.FILTER : CompareAction.NONE;
  static getDisablerReturnVal = (value: boolean) => value ? CompareAction.DISABLE : CompareAction.NONE;
  static getReturnVal = (value: boolean, trueVal: CompareAction, falseVal = CompareAction.NONE) => value ? trueVal : falseVal;

  static searchFilterFn = (item, search) => {
    if (!item || !search || search.length === 0) {
      return CompareAction.FILTER;
    }
    return item.toLowerCase().trim().includes(search.toLowerCase().trim()) ? CompareAction.FILTER : CompareAction.NONE;
  }

  static equalsFilterFn = (compareVal, newVal) => compareVal === newVal ? CompareAction.FILTER : CompareAction.NONE;
  static containsFilterFn = (compareVal, newVal) => compareVal && compareVal.includes(newVal) ? CompareAction.FILTER : CompareAction.NONE;
  static equalsDisablerFn = (compareVal, newVal) => compareVal === newVal ? CompareAction.DISABLE : CompareAction.NONE;
  static notEqualsDisablerFn = (compareVal, newVal) => compareVal !== newVal ? CompareAction.DISABLE : CompareAction.NONE;
  static notEqualsFilterFn = (compareVal, newVal) => compareVal !== newVal ? CompareAction.FILTER : CompareAction.NONE;
  static notNullAndNotEqualsFilterFn = (compareVal, newVal) => (compareVal !== newVal) && newVal ? CompareAction.FILTER : CompareAction.NONE;
  static equalsResetFn = (compareVal, newVal) => compareVal === newVal ? CompareAction.RESET : CompareAction.NONE;
  static notEqualsResetFn = (compareVal, newVal) => compareVal !== newVal ? CompareAction.RESET : CompareAction.NONE;
  static anyObjectValueTrue = (ignore, newObjectVal) => newObjectVal && Object.values(newObjectVal || {}).some(val => Boolean(val)) ? CompareAction.FILTER : CompareAction.NONE;
  static anyObjectValueTrueResetFn = (ignore, newObjectVal) => Object.values(newObjectVal || {}).some(val => Boolean(val)) ? CompareAction.RESET : CompareAction.NONE;

  static addToArrayIfTrue = (compareVal, newVal) => newVal ? CompareAction.ARRAY_ADD : CompareAction.ARRAY_REMOVE;

  public constructor(protected laheteService: LaheteService) {
    this.lang = laheteService.lang || LaheteLanguage.FI;
    this.laheteKoodisto = this.laheteService.laheteKoodisto;
  }

  /**
   * Palauttaa joukon lähetysosoitteita tutkimusvalintojen mukaan.
   *
   * @param tutkimustenLahetysosoitteet - Valittujen tutkimusten mukaiset lähetysosoitteet ([["HEL1"], ["HEL1", "OUL"]])
   * @param intersectFn - funktio, jonka mukaan joukkojen leikkaus tehdään
   */
  getLahetysosoiteIntersection(tutkimustenLahetysosoitteet: string[][], intersectFn?): Set<string> {
    return tutkimustenLahetysosoitteet.reduce((wholeSet, lahetysosoitteet) => {
      const currentLahetysosoitteet = new Set(lahetysosoitteet);
      if (wholeSet.size === 0) {
        wholeSet = currentLahetysosoitteet;
      } else {
        wholeSet = AbstractLaheteGroup.intersection(wholeSet, currentLahetysosoitteet, intersectFn) as Set<string>;
      }
      return wholeSet;
    }, new Set<string>());
  }

  abstract getGroup(controlType?: string, groupBuilder?: GroupBuilder): QuestionGroup;

  /**
   * Ylikirjoita metodi, mikäli yhteenvedon muodostamisessa ei voida käyttää `getGroup`-metodia
   *
   * @param controlType - kentän/ryhmän tyyppi
   * @param initalValues - kentän/ryhmän arvot
   */
  getSummaryGroup(controlType: string, initalValues): QuestionGroup {
    return null;
  }

  /**
   * Palauttaa lokalisoidun arvon objektista valitun kielen mukaan.
   *
   * @param multiLangObject - Objekti, josta käännös haetaan
   * @param key - Avain ilman kielivalintaa
   *
   * @example
   * // palauttaa 'nimi', kun this.lang = LahteLanguage.FI
   * getLocalizedOf({nimiFi: 'nimi', nimiSv: 'namn'}, 'nimi')
   */
  protected getLocalizedOf(multiLangObject: any, key: string): string {
    let localized = multiLangObject[key + this.lang];
    if (!localized) {
      localized = `(!!) ${multiLangObject[key] || key}`;
      console.error(`Ei käännöstä: ${key}`, multiLangObject);
    }

    return localized;
  }

  protected getLabel(member: string): string {
    const label = Translations.labels[member + this.lang];
    if (!label) {
      console.error(`Ei käännöstä ${member + this.lang}`);
      const prefix = this.lang === LaheteLanguage.SV ? '(!!) ' : '';
      return prefix + member;
    }
    return label;
  }

  /**
   * Luo QuestionGroup objektia vastaavan FormGroup objektin.
   *
   * @param questionGroup - Lomakkeen groupin malli
   */
  protected createFormGroupFromQuestionGroup(questionGroup: QuestionGroup): FormGroup {
    const formData = {};
    if (questionGroup.groups) {
      questionGroup.groups.forEach(group => {
        formData[group.key] = this.createFormGroupFromQuestionGroup(group);
      });
    }

    if (questionGroup.controls) {
      questionGroup.controls.forEach(control => formData[control.key] = new FormControl(control.initialValue, control.validators));
    }

    if (questionGroup.array) {
      formData[questionGroup.array.key] = new FormArray([]);
    }

    return new FormGroup(formData);
  }
}
