import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'toiminta-card-v2',
  styleUrls: ['toiminta-card-v2.component.scss'],
  template: `
    <div [id]="'toiminta-'+otsikko" class="card {{toimintaDetails.kategoria}}" [ngClass]="{'selected': this.hasToimintaValues || fieldValue}"
         (click)="toggleField()">
      <div class="card-header">
        <span *ngIf="toimintaDetails.ikoni" [ngClass]="['fa', toimintaDetails.ikoni, 'pr-1']"></span>
        <span [attribute]="otsikko">Otsikko</span>
      </div>
      <div class="card-body">
        <p class="small" [attribute]="toimintaDetails.selite">Selite</p>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToimintaCardV2Component),
    }
  ]
})
export class ToimintaCardV2Component implements ControlValueAccessor {
  @Input() otsikko: string;
  @Input() toimintaDetails: { selite: string, kategoria: string, ikoni: string };
  @Input() formControlName: string;
  @Input() hasToimintaValues: boolean;

  fieldValue: boolean;

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  toggleField() {
    this.fieldValue = !this.fieldValue;
    this.writeValue(this.fieldValue);
  }

  writeValue(value: boolean): void {
    this.fieldValue = value;
    this._onChange(this.fieldValue);
  }

  private _onChange = (_: any) => {
    // This is intentional
  };

  private _onTouched = (_: any) => {
    // This is intentional
  };
}
