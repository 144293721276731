import {MatcherModel, ModelFragment, ModelFragmentType, Topic, TopicListener} from "../matcher/matcher-model";
import {QuestionGroup} from "./question-group";
import {QuestionControl} from "./question-control";
import {ValidatorFn} from "@angular/forms";
import {PathUtil} from "./path-util";
import {QuestionOptions} from "./question-model";

export class QuestionArray implements ModelFragment {
  initialValue: any;
  value: any;
  label: string;
  options: { [p: string]: any };
  actions: { [action: string]: any};
  key: string;
  fullPath: string;
  controlType: string;
  aggregators: { [p: string]: MatcherModel };
  disablers: { [p: string]: MatcherModel };
  filters: { [p: string]: MatcherModel };
  modifiers: { [p: string]: MatcherModel };
  htmlId: string;
  placeholder: string;
  validators: ValidatorFn[];
  errorMessages: { [s: string]: string; };

  questions: ModelFragment[];

  publishers: Topic[];
  subscribers: TopicListener[];
  lateRegisterMatchers: boolean;

  constructor(options: QuestionOptions) {
    this.key = options.key;
    this.fullPath = options.fullPath || this.key.toString();
    this.initialValue = options.initialValue || null;
    this.value = options.value || null;
    this.label = options.label || null;
    this.controlType = options.controlType;
    this.aggregators = options.aggregators || null;
    this.disablers = options.disablers || null;
    this.filters = options.filters || null;
    this.modifiers = options.modifiers || null;
    this.options = options.options || null;
    this.actions = options.actions;
    this.questions = this.initQuestions(options.questions);
    this.publishers = options.publishers || [];
    this.subscribers = options.subscribers || [];
    this.lateRegisterMatchers = options.lateRegisterMatchers || false;
  }

  find(path: string[]): ModelFragment {
    if (path.length === 0) {
      return this;
    }

    const nextControl = this.questions.find(q => q.key === path[0]);
    if (nextControl) {
      return nextControl.find(path.slice(1));
    } else {
      return null;
    }
  }

  findAll(path: string[], found: ModelFragment[]): ModelFragment[] {
    if (path.length === 0) {
      found.push(this);
    }

    this.questions.forEach(q => {
      if (PathUtil.canContinue(path, q.key)) {
        const subPath = PathUtil.getConsumedPath(path, q.key);
        q.findAll(subPath, found);
      }
    });

    return found;
  }

  findAllLeaves(found = []): ModelFragment[] {
    this.questions.forEach(q => q.findAllLeaves(found));
    return found;
  }

  push(item: QuestionGroup | QuestionControl, fragmentType: ModelFragmentType) {
    if (fragmentType === ModelFragmentType.ARRAY_ITEM) {
      item.updatePath(this.fullPath);
      this.questions.push(item);
    }
  }

  remove(itemKey: string, fragmentType: ModelFragmentType) {
    if (fragmentType === ModelFragmentType.ARRAY_ITEM) {
      const index = this.questions.findIndex(q => q.key === itemKey.toString());
      if (index >= 0) {
        this.questions.splice(index, 1);
      }
    }
  }

  clear(fragmentType: ModelFragmentType) {
    if (fragmentType === ModelFragmentType.ARRAY_ITEM) {
      this.questions.splice(0);
    }
  }

  initQuestions(questions: ModelFragment[]) {
    questions.forEach(q => q?.updatePath(this.fullPath));
    return questions;
  }

  updatePath(parentFullPath: string) {
    if (PathUtil.checkPathIsChildOf(this.fullPath, parentFullPath)) {
      return;
    }
    this.fullPath = `${parentFullPath}.${this.key}`;

    this.questions.forEach(q => q.updatePath(this.fullPath));
  }

  updateKey(key: string) {
    this.key = key;
    this.updatePath(PathUtil.getParent(this.fullPath));
  }

}
