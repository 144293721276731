import {Injectable} from "@angular/core";
import {CanDeactivate} from "@angular/router";
import {KasvinterveysRekisteriComponent} from "../touko-lomake/kate-rekisteri/kasvinterveys-rekisteri.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalData} from "../modaalit/modal-utils";
import {Teksti} from "../utils/teksti";
import {ModalSimpleComponent} from "../modaalit/lomake/modal-simple.component";
import {LomakeRedirectService} from "../touko-lomake/lomake-redirect.service";

@Injectable()
export class LomakeLeaveGuardService implements CanDeactivate<KasvinterveysRekisteriComponent> {

  constructor(private lomakeService: LomakeRedirectService,
              private ngbModalService: NgbModal) {}

  canDeactivate(param1, param2, p3, p4): boolean {
    if (this.lomakeService.canRedirect) {
      return true;
    }

    const okFn = () => this.lomakeService.redirectTo([p4.url]);

    const modalData = new ModalData(
      new Teksti("Otsikko", "keskeytaAsiointi", "teksti"),
      new Teksti("Kuvaus", "tallentamattomatPoistetaan", "teksti"),
      new Teksti("Poista", "poista", "teksti"),
      okFn,
      "btn-danger"
    );

    const modalRef = this.ngbModalService.open(ModalSimpleComponent);
    modalRef.componentInstance.modalTiedot = modalData;

    return this.lomakeService.canRedirect;
  }
}
