import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {
  Allekirjoitus, LOMAKE_KASITTELY_MESSAGE,
  LOMAKE_KUVAUKSET,
  LomakeJSONOld,
  LomakeKasittely,
  LomakeResponse,
  LomakeTila
} from "../touko-lomake-utils";
import {LomakeService} from "../touko-lomake.service";
import {YhteenvetoService} from "../yhteenveto/yhteenveto.service";
import {AccountService} from "../../account/account.service";
import {Subscription} from "rxjs";
import {resolveLomakeData} from "../touko-lomakedata-constants";
import {TarkastusPDFService} from "./tarkastus-pdf.service";
import {YhteenvetoGroup} from "../yhteenveto/yhteenveto-utils";
import {Teksti} from "../../utils/teksti";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {MessageService} from "../../message/message.service";

/**
 * Created by Seppo on 27/02/2018.
 * @deprecated uudet lomakkeet käyttävät TarkastusV2Component luokkaa
 */
@Component({
  templateUrl: 'tarkastus.component.html',
  selector: 'tarkastus',
  styleUrls: ['../../raportti/data-table.component.scss'] // Käytetään samoja tyylejä kuin raporteissa
})
export class TarkastusComponent implements OnInit {

  @ViewChild("lomakeYhteenvetoDiv", { static: true }) lomakeYhteenvetoDiv: ElementRef;
  @ViewChild("lomakeKuvausH2") lomakeKuvausH2: ElementRef;

  allekirjoitus: Allekirjoitus = new Allekirjoitus();
  lomakeKasittely: LomakeKasittely = null;
  lomakeSub: Subscription = null;
  kuvaukset = LOMAKE_KUVAUKSET;
  lomakeVersiot: LomakeResponse[] = [];
  currentLomake: LomakeResponse = null;
  showLoader = true;
  paramId = null;
  showNaytaMuuttuneetTiedot = false;

  currentLomakeToiminta: YhteenvetoGroup[] = [];
  currentToiminta: YhteenvetoGroup[] = [];
  showCompareYhteenveto = false;
  btnShowText = new Teksti("Näytä muuttuneet tiedot", "btnNaytaMuuttuneetTiedot", "lomakeYleinen");
  btnHideText = new Teksti("Piilota muuttuneet tiedot", "btnPiilotaMuuttuneetTiedot", "lomakeYleinen");
  btnText = this.btnShowText;

  public readonly isViranomainen: boolean;
  public readonly isElyViranomainen: boolean;
  public readonly isLuomu: boolean;

  constructor(protected readonly activatedRoute: ActivatedRoute,
              protected readonly router: Router,
              protected readonly lomakeService: LomakeService,
              protected readonly yhteenvetoService: YhteenvetoService,
              protected readonly tarkastusPdfService: TarkastusPDFService,
              protected readonly accountService: AccountService,
              protected readonly messageService: MessageService) {
    this.isViranomainen = this.accountService.isViranomainen();
    this.isElyViranomainen = this.accountService.isElyViranomainen();
    this.isLuomu = this.accountService.getCurrentAccount().toimiala === "LUOMU";
    this.toggleShowCompareYhteenveto = this.toggleShowCompareYhteenveto.bind(this);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
        this.paramId = parseInt(param.id, 10);
        this.getLomake(this.paramId, param.versio, param.asia, param.lomaketyyppi);
      }
    );
  }

  private getLomake(id, lomaketyyppiVersio, asia?, lomaketyyppi?): void {
    let responsePromise: Promise<LomakeResponse>;
    if (LomakeEndpointUtil.shouldUseV2Endpoint(asia, lomaketyyppiVersio)) {
      const isKesken = this.router.url.includes('esikatselu');
      responsePromise = this.isViranomainen ?
        this.lomakeService.getLomakeV2Viranomainen(id, asia, lomaketyyppi) :
        this.lomakeService.getLomakeV2Asiakas(id, asia, lomaketyyppi, isKesken);
    } else {
      responsePromise = this.lomakeService.getLomake(id);
    }

    responsePromise.then(r => this.setLomakkeenEnnakkotiedot(r));
  }

  protected setLomakkeenEnnakkotiedot(response: LomakeResponse): void {
    this.currentLomake = response;
    if (this.paramIdMatchesResponseId(this.paramId, response)) {
      const isKasittelyssa = LomakeTila[response.tila] === LomakeTila.KASITTELYSSA;
      this.yhteenvetoService.initYhteenvetoService(resolveLomakeData(response.asia, this.currentLomake.lomaketyyppiVersio, response.toimintotyyppi));
      const kasittelija = this.isViranomainen && isKasittelyssa ? response.muokannutToimija : null;
      this.allekirjoitus = Allekirjoitus.createFromLomake(response);
      this.lomakeKasittely = new LomakeKasittely(
        response.id,
        LomakeTila[response.tila],
        response.lisatieto, kasittelija,
        this.getKuvausTeksti(response.asia, response.toimintotyyppi));
        this.currentLomakeToiminta = this.yhteenvetoService.getYhteenveto(
          JSON.parse(response.sisalto) as LomakeJSONOld
        );
      resolveLomakeData(response.asia, this.currentLomake.lomaketyyppiVersio, response.toimintotyyppi);
      this.showNaytaMuuttuneetTiedot = this.isViranomainen && this.isAlkuperainenLomake(this.currentLomake) && (this.currentLomake?.toimintotyyppi === 'MUUTOS' || this.currentLomake?.toimintotyyppi === 'HAKEMUS');
      if (this.lomakeVersiot.length === 0) {
        const alkupLomakeId = response.alkuperainenLomakeId ? response.alkuperainenLomakeId : response.id;
        this.lomakeService.getLomakeVersiot(alkupLomakeId)
          .then(data => this.lomakeVersiot = data)
          .then(() => this.showLoader = false);
      }
    }
  }

  protected paramIdMatchesResponseId(param: number, lomake: LomakeResponse) {
    return lomake && lomake.id === param;
  }

  isAlkuperainenLomake(lomake: LomakeResponse) {
    return lomake?.alkuperainenLomakeId === null;
  }

  getKuvausTeksti(asia: string, toimintotyyppi: string) {
    return this.kuvaukset[asia][toimintotyyppi];
  }

  clickVersio(lomake: LomakeResponse) {
    this.router.navigate([
      "/asiointi/lomake/tarkastus/",
      this.getToimintotyyppiUrlFragment(lomake.toimintotyyppi),
      lomake.asia,
      `v${lomake.lomaketyyppiVersio}`,
      lomake.id
    ]);

    this.showCompareYhteenveto = false;
  }

  clickAlkuperainen(id: number) {
    this.router.navigate([
      "/asiointi/lomake/tarkastus/",
      this.getToimintotyyppiUrlFragment(this.currentLomake.toimintotyyppi),
      this.currentLomake.asia,
      `v${this.currentLomake.lomaketyyppiVersio}`,
      id
    ]);

    this.getLomake(id, this.currentLomake.lomaketyyppiVersio, this.currentLomake.asia);
    this.showCompareYhteenveto = true;
  }

  getToimintotyyppiUrlFragment(toimintotyyppi: string) {
    return toimintotyyppi === "HAKEMUS" ? "muutos" : toimintotyyppi.toLowerCase();
  }

  toggleShowCompareYhteenveto(): Promise<void> {
    if (this.currentToiminta.length === 0) {
      return this.lomakeService.getToiminnanTiedotViranomainen(this.currentLomake.id).then(values => {
        this.currentToiminta = this.yhteenvetoService.getYhteenveto(
          JSON.parse(values.sisalto) as LomakeJSONOld,
          resolveLomakeData(this.currentLomake.asia, this.currentLomake.lomaketyyppiVersio, this.currentLomake.toimintotyyppi)
        );
        this.toggleYhteenvetoText();
      });
    } else {
      return Promise.resolve(this.toggleYhteenvetoText());
    }
  }

  sendYhteenvetoPdf(kasittely: LomakeKasittely): Promise<any> {
    const titleHtml = this.lomakeKuvausH2.nativeElement.innerHTML;
    const contentElement = this.lomakeYhteenvetoDiv.nativeElement;

    return this.tarkastusPdfService.createYhteenvetoPdf(kasittely, this.allekirjoitus, titleHtml, contentElement)
      .then(pdf => this.lomakeService.sendYhteenveto(kasittely.id, pdf, true));
  }

  toggleYhteenvetoText() {
    this.showCompareYhteenveto = !this.showCompareYhteenveto;
    this.btnText = this.showCompareYhteenveto ? this.btnHideText : this.btnShowText;
  }

  /**
   * Lähettää virkailijan käsittelyn palvelimelle.
   *
   * @param lomakeKasittely Lomakkeen tila
   */
  kasitteleLomake(lomakeKasittely: LomakeKasittely): void {
    const kasitteleLomakeFn = LomakeEndpointUtil.shouldUseV2Endpoint(this.currentLomake.asia, this.currentLomake.lomaketyyppiVersio) ?
      this.lomakeService.kasitteleLomakeV2(lomakeKasittely, this.currentLomake.asia, this.currentLomake.toimintotyyppi) :
      this.lomakeService.kasitteleLomake(lomakeKasittely);

    kasitteleLomakeFn.then(() => {
      this.messageService.notify({message: LOMAKE_KASITTELY_MESSAGE[lomakeKasittely.tila], type: "success"});
      this.router.navigate(['/asiointi/etusivu']);
    })
      .catch(err => {
        this.messageService.notify({
          message: new Teksti("Käsittelyvirhe", "errLomakkeenKasittelyvirhe", "lomakeYleinen"),
          type: "danger"
        });
      });
  }

  /**
   * Liittää yhteenvedon lomakkeeseen ennen lähetystä eteenpäin.
   *
   * @param lomakeKasittely Lomakkeen tila
   */
  lahetaYhteenvetoJaKasittely(lomakeKasittely: LomakeKasittely) {
    this.sendYhteenvetoPdf(lomakeKasittely)
      .then(() => {
        this.kasitteleLomake(lomakeKasittely);
      });
  }
}
