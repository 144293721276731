import {Component, Input} from "@angular/core";
import {QuestionComponentData, QuestionGroup} from "ee-lahete";
import {AbstractControl, ControlContainer} from "@angular/forms";

@Component({
  selector: 'question-control',
  template: `
    <ng-container [formGroup]="controlContainer.control">
      <ng-container [ngSwitch]="questionData.question.controlType" *ngIf="control">
        <question-radio-card *ngSwitchCase="'radio-card'"
                             [questionParent]="parent"
                             [questionData]="questionData"
                             [eelControlMatcher]="questionData.question"
                             [eelControl]="control"
                             [sivuRef]="sivuRef"
                             [isLast]="isLastOfGroup"
                             [eelModifierValues]="questionData.modifierValues">
        </question-radio-card>
        <question-radio *ngSwitchCase="'radio'"
                        [questionData]="questionData"
                        [eelControlMatcher]="questionData.question"
                        [eelControl]="control"
                        [isLast]="isLastOfGroup"
                        [sivuRef]="sivuRef"
                        [eelModifierValues]="questionData.modifierValues"></question-radio>
        <touko-checkbox *ngSwitchCase="'checkbox'"
                        [htmlId]="'cb-' + questionData.htmlId"
                        [labelAttribute]="questionData.label"
                        [labelDescrAttribute]="questionData.question?.options?.labelDesc"
                        [formControlName]="questionData.formControlName"
                        [name]="questionData.formControlName"
                        [eelControlMatcher]="questionData.question"
                        [eelControl]="control"
                        [eelModifierValues]="questionData.modifierValues"></touko-checkbox>
                  <question-select *ngSwitchCase="'selection'"
                                   [eelControlMatcher]="questionData.question"
                                   [eelControl]="control"
                                   [eelModifierValues]="questionData.modifierValues"
                                   [questionData]="questionData"></question-select>
                  <question-text *ngSwitchCase="'text'"
                                 [sivuRef]="sivuRef"
                                 [eelControlMatcher]="questionData.question"
                                 [eelControl]="control"
                                 [eelModifierValues]="questionData.modifierValues"
                                 [questionData]="questionData"></question-text>
                  <question-checkbox-button *ngSwitchCase="'checkboxbutton'"
                                            [questionData]="questionData"
                                            [formControlName]="questionData.formControlName"
                                            [eelControlMatcher]="questionData.question"
                                            [eelControl]="control"
                                            [eelModifierValues]="questionData.modifierValues"></question-checkbox-button>
                </ng-container>
              </ng-container>

`
})
export class QuestionControlComponent {
  @Input() questionData: QuestionComponentData;
  @Input() sivuRef: string;
  @Input() parent: QuestionGroup;
  @Input() isLastOfGroup = false;
  @Input() control: AbstractControl;

  constructor(public controlContainer: ControlContainer) {
  }

}
