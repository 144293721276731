import {Component} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {AbstractField, FieldArray, FieldGroup} from "ngx-fielding";

@Component({
  selector: 'elaintuotteet',
  template: `
    <div [formGroup]="formGroup" class="mb-6">
      <div class="mb-4">
        <h3 attribute="luomu.tilanTuottamatElaintuotteet"></h3>
        <div class="row mb-2">
          <div class="col-4"></div>
          <label attribute="luomu.luonnonmukainenLabel" id="elaintuote-luonnonMukainenLabel"
                 class="col-3 font-weight-semibold"></label>
          <label attribute="luomu.tavanomainenLabel" id="elaintuote-tavanomainenLabel"
                 class="col-3 font-weight-semibold"></label>
        </div>
        <div *ngFor="let elaintuote of elaintuotteet" [formGroup]="elaintuote.asGroup()" class="row">
          <p [attribute]="elaintuote.field.label" class="col-4"></p>
          <touko-checkbox
              formControlName="luonnonmukainen"
              [htmlId]="'luonnonmukainen-' + elaintuote.field.htmlId"
              aria-labelledby="luonnonMukainenLabel"
              class="col-3"></touko-checkbox>
          <touko-checkbox
              formControlName="tavanomainen"
              aria-labelledby="tavanomainenLabel"
              [htmlId]="'tavanomainen-' + elaintuote.field.htmlId"
              class="col-3"></touko-checkbox>
        </div>
      </div>

      <div formArrayName="muutElaintuotteet">
        <label attribute="luomu.muuElaintuote" id="muuElaintuoteLabel"></label>
        <div *ngFor="let muuElaintuote of muutElaintuotteetArray.controlFields; let i = index"
             [formGroup]="muuElaintuote.asGroup()" class="row align-items-center">
          <div class="col-4">
            <touko-text-input [htmlId]="'muuElaintuotetuotenimi-' + i" formControlName="nimi"
                              labelledById="muuElaintuoteLabel"></touko-text-input>
          </div>
          <div class="col-3">
            <touko-checkbox [htmlId]="'muuElaintuoteLuonnonmukainen-' + i" formControlName="luonnonmukainen"
                            aria-labelledby="luonnonMukainenLabel"></touko-checkbox>
          </div>
          <div class="col-2">
            <touko-checkbox [htmlId]="'muuElaintuoteTavanomainen-' + i" formControlName="tavanomainen"
                            aria-labelledby="tavanomainenLabel"></touko-checkbox>
          </div>
          <div class="col-3">
            <button [id]="'muuElaintuotePoista-' + i" type="button" toukobutton class="btn-outline-primary mb-4"
                    (click)="removeMuuElaintuote(i)">
              <i class="fas fa-trash" aria-hidden="true"></i>
              <span attribute="teksti.poista"></span>
            </button>
          </div>
        </div>
        <button [id]="'muuElaintuoteUusi'" type="button" toukobutton class="btn-secondary" (click)="addMuuElaintuote()">
          <i class="fas fa-plus-square" aria-hidden="true"></i>
          <span attribute="teksti.uusi"></span>
        </button>
      </div>
    </div>
  `
})

export class ElaintuotteetComponent {
  constructor(private controlContainer: ControlContainer) {
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get elaintuotteet(): AbstractField[] {
    return Object.values(this.formGroup.controlFields)
        .filter((tuote) => tuote instanceof FieldGroup)
  }

  get muutElaintuotteetArray(): FieldArray {
    return this.formGroup.get('muutElaintuotteet') as FieldArray;
  }

  addMuuElaintuote() {
    this.muutElaintuotteetArray.push(this.muutElaintuotteetArray.buildField());
  }

  removeMuuElaintuote(index: number) {
    this.muutElaintuotteetArray.removeAt(index);

    if (!this.muutElaintuotteetArray.length) {
      this.muutElaintuotteetArray.push(this.muutElaintuotteetArray.buildField());
    }
  }
}
