import {Component, Input} from '@angular/core';
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'app-kate-table',
  template: `
    <div *ngIf="item.answer.length" class="row" [ngClass]="{'pb-2': !compareItemType}">
      <div *ngIf="!compareItemType" class="col-lg-4 col-5">
        <span [id]="'label-' + htmlId" [attribute]="item?.question"></span>
      </div>
      <div class="col">
        <compare-item [compareItemType]="compareItemType">
          <div *ngFor="let answer of item.answer">
            <span [attribute]="answer.label" [id]="'value-' + answer?.htmlId" class="answer"></span>
            <span [attribute]="item?.options?.valueSuffix" class="answer"></span>
          </div>
        </compare-item>
      </div>
    </div>
  `,
  styleUrls: ['summary.scss']
})
export class KateTableSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;

  get htmlId() {
    return this.item?.field?.htmlId || this.item?.options?.htmlId;
  }

}
