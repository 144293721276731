import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from "./login.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AttributeModule} from "../tiira/attribute.module";
import {PalsaInfoComponent} from "./palsa-info.component";
import {RouterModule} from "@angular/router";
import {LoaderModule} from "../loader/loader.module";
import {LoginCardComponent} from './login-card/login-card.component';
import {LoginVirkailijaComponent} from './login-virkailija/login-virkailija.component';
import {UtilsModule} from "../utils/utils.module";

@NgModule({
  declarations: [
    LoginComponent,
    PalsaInfoComponent,
    LoginCardComponent,
    LoginVirkailijaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    AttributeModule,
    LoaderModule,
    UtilsModule
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
