import {BehaviorSubject, Observable, Observer} from "rxjs";
import {Injectable} from "@angular/core";
import {TiiraService} from "./tiira.service";
import {appVersion} from "../app.version";
import {EnvironmentService, Feature} from "../../environments/environment.service";
import {tiiraAttribute} from "tiira-frontend";

@Injectable()
export class AttributeService {

  private TULKKI_PREFIX = 'TOU_';
  private attributeMap: any = null;
  private lang = null;

  private _ready = new BehaviorSubject<boolean>(false);
  ready$ = this._ready.asObservable();

  constructor(private tiiraService: TiiraService,
              private environmentService: EnvironmentService) {

    if (window.localStorage.getItem("APP_VERSION") !== appVersion) {
      window.localStorage.removeItem("TIIRA_APPLICATION_LABEL_MAP");
    }

    tiiraAttribute.readAllLabels((data: any) => {
      this.attributeMap = data;
      this.lang = null;
      this._ready.next(true);
      window.localStorage.setItem("APP_VERSION", appVersion);

      this.tiiraService.language$.subscribe(lang => {
        this.lang = lang;
        this._ready.next(true); // päivitetään tekstit, kun kieli vaihtuu
      });

      this.tiiraService.pageTitle$.subscribe(title => {
        if (!title) {
          return;
        }
        const text = this.simpleTranslate("TOU_sivuotsikot", title, !Boolean(this.lang));
        this.tiiraService.setDocumentTitle(text);
      });
    });

  }

  public label(field, view = "TOU_teksti", lang = null, multi = false): Observable<{ teksti: string; tooltip: string }> {

    return new Observable((observer: Observer<any>) => {

      if (!this.attributeMap) {
        return;
      }

      const labelLang = lang ? lang : this.lang;
      const attr = this.getAttribute(view, field);

      if (attr && attr.labels) {
        if (labelLang) {
          observer.next({teksti: attr.labels[labelLang], tooltip: attr.infos[labelLang]});
        } else if (multi) {
          observer.next({
            teksti: attr.labels['FI'] + " / " + attr.labels['SV'],
            tooltip: attr.labels['FI'] + "/" + attr.labels['SV']
          });
        }
      } else {
        observer.next(
          false
        );
        if (this.environmentService.isFeatureEnabled(Feature.DEV_FEATURE)) {
          console.debug(`Puuttuu tulkista ${view}.${field}`);
        }
      }
    });
  }

  private getAttribute(view: string, field: string) {
    if (view && field && this.attributeMap[view] && this.attributeMap[view][field]) {
      return this.attributeMap[view][field];
    }
    return null;
  }

  public simpleTranslate(view: string, key: string, multiLang = false, defaultText = ""): string {
    if (!view.startsWith("TOU_")) {
      view = "TOU_" + view;
    }
    let value = null;

    if (!this.attributeMap || !this.attributeMap.hasOwnProperty(view) || !this.attributeMap[view].hasOwnProperty(key)) {
      console.error("Tekstiä ei löydy: " + view + " " + key);
      return defaultText;
    }

    const attr = this.attributeMap[view][key];

    if (!multiLang) {
      value = attr.labels[this.lang];
    } else {
      value = attr.labels['FI'] + "/" + attr.labels['SV'];
    }
    return value ? value : defaultText;
  }

  public resolveView(label: string): string {
    if (label && label.includes('.')) { // TODO: parempi tarkistus
      const splitted = label.split('.');
      return this.TULKKI_PREFIX + splitted[0];
    }
    return this.TULKKI_PREFIX + 'teksti';
  }

  public resolveLabel(label: string): string {
    if (label.includes('.')) {
      const splitted = label.split('.');
      return splitted[1];
    }
    return label;
  }

}
