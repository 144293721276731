import {Injectable} from "@angular/core";

export const ENV_SESSION_STORAGE_KEY = "ymparistoAsetukset";

export class YmparistoAsetukset {
  isProd: boolean;
  features: Set<string>;
  env: string;
  role: string;
  url: string;

  constructor(asetukset: any) {
    if (asetukset) {
      this.isProd = asetukset.prod;
      this.features = new Set(asetukset.features);
      this.env = asetukset.env;
      this.role = asetukset.role;
      this.url = asetukset.url;
    } else { // jos ympäristön asetuksia ei löydy, käytetään tiukumpia mahdollisia
      this.isProd = true;
      this.features = new Set<string>();
      this.env = null;
      this.role = null;
      this.url = null;
    }
  }
}

@Injectable()
export class EnvironmentService {

  private ymparistoAsetukset: YmparistoAsetukset;

  constructor() {
    const asetukset = localStorage.getItem(ENV_SESSION_STORAGE_KEY);
    this.ymparistoAsetukset = new YmparistoAsetukset(JSON.parse(asetukset));
  }

  get environment(): string {
    if (this.ymparistoAsetukset.env == null) {
      return null;
    }
    return this.ymparistoAsetukset.role ? this.ymparistoAsetukset.env + "-" + this.ymparistoAsetukset.role : this.ymparistoAsetukset.env;
  }

  public asWipFeature(wipFeature: any, prodFeature: any = null): any {
    return this.isFeatureEnabled(Feature.WIP_FEATURE) ? wipFeature : prodFeature;
  }

  public isFeatureEnabled(feature: Feature): boolean {
    return this.ymparistoAsetukset.features.has(feature);
  }

  public getUrl() {
    return this.ymparistoAsetukset.url;
  }

}

/**
 * WipFeature  = Ominaisuus on kesken, mutta jossain versiossa halutaan julkaista
 * DevFeature  = Ominaisuus helpottaa kehitystyötä, ei haluta koskaan julkaista
 * TestFeature = Ominaisuus helpottaa e2e testausta
 */
export enum Feature {
  DEV_FEATURE = "devFeature",
  TEST_FEATURE = "testFeature",
  WIP_FEATURE = "wipFeature",
}
