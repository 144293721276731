import {FieldGroup} from "ngx-fielding";

export class RehuvalvontaV2IsvisibleRules {

  private isVisibleSet: {[key: string]: Set<string>} = {};

  isVisible: {[key: string]: boolean} = {};

  get someVisible() {
    return Object.values(this.isVisible).some(ruleValue => ruleValue);
  }

  constructor(private lomake: FieldGroup) {
    this.registerRule(
      'rekisteroitavatToiminnat.lisaaineidenValmistaja.valittu',
      ['arvioRehunMaarasta', 'rehunValmistusOmaan', 'valmistaakoElintarvikkeita', 'valmistusVainPakkaamista']);
    this.registerRule(
      'rekisteroitavatToiminnat.rehuaineidenValmistaja.valittu',
      ['arvioRehunMaarasta', 'rehunValmistusOmaan', 'valmistaakoElintarvikkeita', 'valmistusVainPakkaamista']);
    this.registerRule(
      'rekisteroitavatToiminnat.rehuseoksenValmistaja.valittu',
      ['arvioRehunMaarasta', 'valmistettujenRehuSeostenLkm', 'rehunValmistusOmaan', 'valmistaakoElintarvikkeita', 'valmistusVainPakkaamista']);
    this.registerRule(
      'rekisteroitavatToiminnat.erityisRehunValmistaja.valittu',
      ['arvioRehunMaarasta', 'valmistettujenRehuSeostenLkm', 'rehunValmistusOmaan', 'valmistaakoElintarvikkeita', 'valmistusVainPakkaamista']);
    this.registerRule(
      'rekisteroitavatToiminnat.esiseostenValmistaja.valittu',
      ['arvioRehunMaarasta']);
    this.registerRule(
      'rekisteroitavatToiminnat.rahtisekoittaja.valittu',
      ['arvioRehunMaarasta']);
  }

  private registerRule(path: string, ruleKeys: string[]) {
    this.lomake.get(path).valueChanges.subscribe(isValittu => {
      ruleKeys.forEach(ruleKey => {
        const valittu = Boolean(isValittu);
        const ruleSet = this.isVisibleSet[ruleKey] || new Set<string>();

        if (valittu) {
          ruleSet.add(path);
        } else {
          ruleSet.delete(path);
        }
        this.isVisibleSet[ruleKey] = ruleSet;
        this.isVisible[ruleKey] = ruleSet.size > 0;
      });
    });
  }
}
