<div class="row">
  <div class="col-12">
    <ng-container [ngSwitch]="account?.toimiala">
      <h1 *ngSwitchCase="'SIEMEN'" attribute="etusivu.saapuneetSiemen"></h1>
      <h1 *ngSwitchCase="'REHU'" attribute="etusivu.saapuneetRehu"></h1>
      <h1 *ngSwitchCase="'LANNOITE'" attribute="etusivu.saapuneetLannoite"></h1>
      <h1 *ngSwitchCase="'KATE'" attribute="etusivu.saapuneetKasvinterveys"></h1>
    </ng-container>
    <div class="mb-4">
      <lomake-haku (paramsUpdate)="refreshLomakkeet($event)" [initialHakuParams]="initialHakuParams" (lomakeCleared)="onLomakeCleared($event)"></lomake-haku>
    </div>
    <div><span attribute="lomakeYleinen.saapuneitaYhteensa"></span> {{ totalCounter }}</div>
    <div>
      <lomake-list [showSpinner]="showLoader" [lomakkeet]="lomakeLista" [initialSivunumero]="initialSivunumero" (changeSivu)="onSivuChanged($event)"></lomake-list>
    </div>
  </div>
</div>