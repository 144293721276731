import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Maatila} from "./maatila";

/**
 * Created by Hannu on 20/03/2018.
 *
 * Handles maatila related data.
 */
@Injectable()
export class MaatilaService {

  constructor(private http: HttpClient) {
  }

  /**
   * Hakee kaikki käyttäjälle luvitetut maatilat
   */
  getMaatilat(rooli: string, tilatunnus?: string): Promise<Maatila[]> {
    let response: Promise<any>;
    if (rooli === "as") {
      response = this.http.get("/api/v1/as/maatila/kayttajanmaatilat/")
          .toPromise();
    } else if (tilatunnus) {
      response = this.http.get(`/api/v1/vk/maatila/kayttajanmaatilat/${tilatunnus}`).toPromise();
    }
    return response;
  }
}
