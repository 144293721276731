export class Asiakirja {

tunnus: number;
asiakirjatyyppi: string;
selite: string;
kielikoodi: string;
tiedostotyyppi: string;
luontipvm: string;

}

/**
 * Sähköisen arkiston tyyppejä
 */

export enum Toimenpidetyyppi {
  HALLINTOPAATOKSEN_LAHETTAMINEN = 60000,
  HAKEMUKSEN_JATTAMINEN = 60001,
  ILMOITUKSEN_JATTAMINEN = 60002,
  LUOMUILMOITUKSEN_JATTAMINEN = 60003,
  SIIRTO_KASITTELYYN// TODO, selvitä käytetäänkö, puuttuu Tosikosta, joten uudesta luomuilmoituksesta tuli 60003?
}

export enum Asiakirjatyyppi {
  HAKEMUS_POIKKEUSLUVASTA = 60000,
  HALLINTOPAATOS_POIKKEUSLUVASTA = 60001,
  VUOSIILMOITUS_EXCEL = 60002,
  LUOMUALOITUSILMOITUS = 60005
}
