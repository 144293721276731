import {Component, OnInit} from '@angular/core';
import {Option} from "../../../touko-components/select-menu.component";
import {Tyyppinimet} from "../../../utils/tyyppinimet";
import {ControlContainer, FormGroup} from "@angular/forms";
import {FieldArray} from "ngx-fielding";

@Component({
  selector: 'tuotetiedot-tyyppinimi-v2',
  template: `
    <ng-container [formGroup]="formGroup">
      <ng-container *ngFor="let tuote of tuoteArray.controlFields; let i = index" formArrayName="kansallinenTaiEYtyyppinimitunnus">
        <div [formGroupName]="i" class="row pt-2">
          <div class="col-6 pr-0">
            <touko-select-menu formControlName="tyyppiNimi" [htmlId]="'tyyppinimiDropdown-'+i"
                               labelAttribute="lannoiterekisteri.kansallinenTaiEYtyyppinimitunnus"
                               defaultTitle="lannoiterekisteri.valitse" [options]="tyyppinimet"></touko-select-menu>
          </div>
          <div class="col-6 d-flex align-items-end mb-4">
            <touko-number-input
              formControlName="maara"
              [htmlId]="'kansallinenTaiEYtyyppinimitunnus-'+i"
              labelAttribute="lannoiterekisteri.maara"
              inputWidth="small">
            </touko-number-input>
            <button [id]="'poistaTuote-'+i" toukobutton buttonStyle="outline-primary" class="ml-3"
                    [disabled]="isRemoveDisabled()"
                    type="button" (click)="removeFromArray(i)">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
              <span attribute="teksti.poista">Poista</span>
            </button>
          </div>
        </div>
      </ng-container>
      <div>
        <button id="uusiTuote" toukobutton buttonStyle="danger" type="button" (click)="addToArray()">
          <i class="fas fa-plus-circle" aria-hidden="true"></i><span
          attribute="lannoiterekisteri.uusiTuoteBtn" class="pr-2">Uusi tuote</span>
        </button>
      </div>
    </ng-container>
  `
})
export class TuotetiedotTyyppinimiV2Component implements OnInit {
  tuoteArray: FieldArray;
  tyyppinimet: Array<Option> = [];
  formGroup: FormGroup;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.tuoteArray = this.controlContainer.control.get('kansallinenTaiEYtyyppinimitunnus') as FieldArray;
    this.formGroup = this.tuoteArray.parent as FormGroup;
    this.tyyppinimet = Tyyppinimet.getTyyppinimet().sort();
  }

  addToArray(): void {
    this.tuoteArray.push(this.tuoteArray.buildField());
  }

  removeFromArray(index: number): void {
    this.tuoteArray.removeAt(index);
  }

  isRemoveDisabled(): boolean {
    return this.tuoteArray.length <= 1;
  }
}
