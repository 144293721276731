import {Component, HostBinding, Input} from '@angular/core';
import {AbstractField} from "ngx-fielding";

@Component({
  selector: 'validation-error',
  template: `
    <div *ngIf="showError" class="mb-4">
      <touko-alert-box alertType="error">
        <div *ngFor="let errorMsg of errors" [attribute]="errorMsg"></div>
      </touko-alert-box>
    </div>
  `
})
export class ValidationErrorComponent {
  @Input() field: AbstractField;
  @Input() fields: AbstractField[];

  @HostBinding('class.d-none') get hidden() {return !this.showError}

  get showError() {
    return this.getFields().some(f => this.isErrorVisible(f));
  }

  get errors() {
    return this.getFields().flatMap(f => f.currentErrors);
  }

  private isErrorVisible(field: AbstractField) {
    return field.invalid && field.currentErrors.length > 0 && field.touched;
  }

  private getFields(): AbstractField[] {
    if (this.field) {
      return [this.field];
    }
    return this.fields;
  }
}
