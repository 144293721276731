import {NgModule} from "@angular/core";
import {AccessibilityResultsComponent} from "./accessibility-results.component";
import {CommonModule} from "@angular/common";
import {AccessibilityService} from "./accessibility.service";
import {AccessibilityBtnComponent} from './accessibility-btn.component';

@NgModule({
  declarations: [
    AccessibilityResultsComponent,
    AccessibilityBtnComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AccessibilityResultsComponent,
    AccessibilityBtnComponent
  ],
  providers: [
    AccessibilityService
  ]
})
export class AccessibilityModule {

}
