import {Component, forwardRef, Input} from "@angular/core";
import {Teksti} from "../utils/teksti";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: '[checkbox-table-row]',
  template: `
      <td style="width: 40%">
        <span [attribute]="question.getFullKey()"></span><em *ngIf="description" class="small text-muted ml-2" [attribute]="description.getFullKey()"></em>
      </td>
      <td *ngFor="let item of values; let i = index">
        <touko-checkbox [htmlId]="formControlName + '-' + item" [labelAttribute]="options[i].getFullKey()" [checked]="fieldValue && fieldValue[item]"
                        (input)="toggleField(item)"></touko-checkbox>
      </td>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxTableRowComponent),
    }
  ]
})
export class CheckboxTableRowComponent implements ControlValueAccessor {
  @Input() question: Teksti = null;
  @Input() description: Teksti = null;
  @Input() options: Teksti[];
  @Input() values: string[];
  @Input() formControlName: string;

  fieldValue: {[s: string]: boolean} = {};

  private _onChange = (_: any) => {
    // This is intentional
  };
  private _onTouched = (_: any) => {
    // This is intentional
  };


  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  toggleField(fieldName) {
    if (!this.fieldValue) {
      this.fieldValue = {};
    }

    this.fieldValue[fieldName] = !this.fieldValue[fieldName];
    this.writeValue(this.fieldValue);
  }

  writeValue(value: {[s: string]: boolean}): void {
    if (!value) {
      value = null;
    }

    this.fieldValue = value;
    this._onChange(this.fieldValue);
  }
}
