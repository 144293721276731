import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {MatcherModel} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {InitValueType, QuestionInitValue} from "ee-lahete";

export class TilatiedotGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({})): QuestionGroup {
    const lammasVuohiGroup = new QuestionGroup({
      key: 'lammasVuohi',
      groupComponentType: 'nested',
      label: this.getLabel('Lampaiden/vuohien määrä pitopaikassa (kpl)'),
      controls: [
        this.createSimpleTextInput('yli1v', 'Yli 1v'),
        this.createSimpleTextInput('alle1v', '6kk-1v'),
        this.createSimpleTextInput('alle6kk', 'Alle 6kk'),
      ],
      filters: this.createElainlajiFilter('PA1LA')
    });

    const nautaGroup = new QuestionGroup({
      key: 'nauta',
      groupComponentType: 'nested',
      label: this.getLabel('Nautojen määrä pitopaikassa (kpl)'),
      controls: [
        this.createSimpleTextInput('yli2v', 'Yli 2v'),
        this.createSimpleTextInput('alle2v', '6kk-2v'),
        this.createSimpleTextInput('alle6kk', 'Alle 6kk'),
      ],
      filters: this.createElainlajiFilter('PA1NA')
    });

    const siipikarjaGroup = new QuestionGroup({
      key: 'siipikarja',
      groupComponentType: 'nested',
      groups: [
        new QuestionGroup({
          key: 'lukumaarat',
          groupComponentType: 'nested',
          controls: [
            this.createSimpleTextInput('parvenKoko', 'Parven koko (kpl)'),
            this.createSimpleTextInput('lintujenMaara', 'Lintujen määrä tilalla (kpl)'),
          ]
        }),
        new QuestionGroup({
          key: 'tarkenteet',
          groupComponentType: 'nested',
          controls: [
            this.createSimpleCheckboxInput('yli2000', 'Kanoja ja kalkkunoita yli 100kpl'),
            this.createSimpleCheckboxInput('ulkokanala', 'Ulkokanala'),
            this.createSimpleCheckboxInput('luomukanala', 'Luomukanala'),
          ]
        }),
      ],
      filters: this.createElainlajiFilter('PA1SK')
    });

    const sikaGroup = new QuestionGroup({
      key: 'sika',
      groupComponentType: 'nested',
      controls: [
        this.createSimpleTextInput('emakot', 'Emakoiden määrä pitopaikassa (kpl)' ),
        this.createSimpleTextInput('lihasiat', 'Lihasikojen määrä pitopaikassa (kpl)')
      ],
      filters: this.createElainlajiFilter('PA1SI')
    });

    const turkiselainGroup = new QuestionGroup({
      key: 'turkiselain',
      groupComponentType: 'nested',
      controls: [
        this.createSimpleTextInput('nahkatilinnumero', 'Nahkatilinumero'),
        this.createSimpleTextInput('rehutoimittaja', 'Rehun toimittaja'),
        this.createSimpleTextInput('plasmasytoosistatus', 'Minkkitilan plasmasytoosistatus'),
      ],
      filters: this.createElainlajiFilter('PA1TU')
    });

    const kalaRapuGroup = new QuestionGroup({
      key: 'kalaJaRapu',
      groupComponentType: 'nested',
      controls: [
        this.createSimpleTextInput('vesiviljely', 'Vesiviljelyrekisterinumero'),
        this.createSimpleCheckboxInput('osaKalaterveyspalvelua', 'Laitos kuuluu Ruokaviraston kalaterveyspalveluun (sopimushinnasto)'),
      ],
      filters: this.createElainlajiFilter('PA1KR')
    });

    const group = new QuestionGroup({
      key: 'tilatiedot',
      label: this.getLabel('Tilatiedot'),
      groupComponentType: 'nested',
      groups: [
        lammasVuohiGroup,
        nautaGroup,
        siipikarjaGroup,
        sikaGroup,
        turkiselainGroup,
        kalaRapuGroup,
      ]
    });

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(
        group,
        builder.initValue(val => val.tilatiedot),
        InitValueType.PATCH
      ));
    }

    return group;
  }

  private createSimpleTextInput(key: string, labelKey: string) {
    return new QuestionControl({
      key: key,
      label: this.getLabel(labelKey),
      controlType: 'text',
    });
  }

  private createSimpleCheckboxInput(key: string, labelKey: string) {
    return new QuestionControl({
      key: key,
      label: this.getLabel(labelKey),
      htmlId: key,
      controlType: 'checkbox',
    });
  }

  private createElainlajiFilter(elainlajiryhma: string): {'elainlajit.elainlajiryhma': MatcherModel} {
    return {
      'elainlajit.elainlajiryhma': new MatcherModel(elainlajiryhma,
        TilatiedotGroup.equalsFilterFn,
        TilatiedotGroup.filterActionFn
      )
    };
  }

}
