import {Component, forwardRef, Input} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "touko-background-card",
  template: `
    <div class="background-card-container p-4">
      <h3 *ngIf="title !== undefined" attribute={{title}} class="p-3 my-2"></h3>
      <ng-content></ng-content>
    </div>
  `
  ,
  styleUrls: ['./background-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BackgroundCardComponent),
    }
  ]
})

export class BackgroundCardComponent {
  @Input() title: string;
}
