<h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey">Lannoitevalmisteen jakelija</h3>
<lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus" [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
<div class="row" *ngFor="let pfcItem of this.item.subitems; index as i">
  <div class="col-full col-lvl-1">
    <h4 [attribute]="pfcItem.label.fullKey"></h4>
  </div>
  <ng-container *ngFor="let lannoiteAineItem of pfcItem.subitems">
    <div class="col-full col-lvl-2">
      <h5 [attribute]="lannoiteAineItem.label.fullKey">Kiinteä orgaaninen lannoite</h5>
    </div>
    <ng-container *ngIf="lannoiteAineItem.valueMap?.maaraSuomi?.label">
      <div class="col-left col-lvl-2">
        <p attribute="lannoiterekisteri.markkinoitavienLannoitteidenMaara">
          Suomessa valmistettujen markkinoitavien lannoitteiden määrä
        </p>
      </div>
      <div class="col-right">
        <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
          <span id="lannotteidenmaaraJakelijaSuomi-{{pfcItem.label.key}}-{{lannoiteAineItem.label.key}}">{{lannoiteAineItem.valueMap?.maaraSuomi?.label}} </span>
          <span attribute="lannoiterekisteri.kgVuosi"></span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lannoiteAineItem.valueMap?.maaraSisamarkkinat?.label">
      <div class="col-left col-lvl-2">
        <p attribute="lannoiterekisteri.sisamarkkinoiltaTuotavienLannoitteidenMaara">
          Sisämarkkinoilta tuotavien lannoitteiden määrä
        </p>
      </div>
      <div class="col-right">
        <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
          <span id="lannotteidenmaaraJakelijaSisamarkkinat-{{pfcItem.label.key}}-{{lannoiteAineItem.label.key}}">{{lannoiteAineItem.valueMap?.maaraSisamarkkinat?.label}} </span>
          <span attribute="lannoiterekisteri.kgVuosi"></span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
