/**
 * Yksittäisen näytteen tiedot
 */
export class NayteTutkimusData {
  tutkimus: ValueAndLabel;
  nayte: ValueAndLabel;
  value: NayteTutkimusValue;
  naytemateriaalit: ValueAndLabel[];
  tutkimussyyt: ValueAndLabel[];
  tiedoksisaaja: ValueAndLabel;

  constructor(options: {value: NayteTutkimusValue, nayte: ValueAndLabel, tutkimus: ValueAndLabel, naytemateriaalit: ValueAndLabel[], tutkimussyyt: ValueAndLabel[], tiedoksisaaja?: ValueAndLabel}) {
    this.tutkimus = options.tutkimus;
    this.nayte = options.nayte;
    this.value = options.value;
    this.naytemateriaalit = options.naytemateriaalit;
    this.tutkimussyyt = options.tutkimussyyt;
    this.tiedoksisaaja = options.tiedoksisaaja;
  }
}

/**
 * Yksittäisen näytteen arvot
 */
export class NayteTutkimusValue {
  readonly id: string;
  readonly tutkimusId: number;
  nayteTunnus: string;
  naytePvm: Date;
  naytemateriaali: string;
  tutkimussyy: string;
  tiedoksisaaja: string;

  constructor(options: {nayteTunnus?: string, naytePvm?: Date, naytemateriaali?: string, tutkimussyy?: string, tiedoksisaaja?: string}) {
    this.nayteTunnus = options.nayteTunnus;
    this.naytePvm = options.naytePvm;
    this.naytemateriaali = options.naytemateriaali;
    this.tutkimussyy = options.tutkimussyy;
    this.tiedoksisaaja = options.tiedoksisaaja;
  }
}

export class ValueAndLabel {
  value: any;
  label: string;

  constructor(value: any, label?: string) {
    this.value = value;
    this.label = label;
  }
}
