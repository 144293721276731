<h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey">Lannoitevalmisteen jakelija</h3>
<lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                     [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
<div class="row" *ngFor="let subitem of item.subitems">
  <div class="col-left col-lvl-1">
    <h4 [attribute]="subitem.label.fullKey"></h4>
  </div>
  <div class="col-full col-lvl-2">
    <h5 attribute="lannoiterekisteri.arvioidutMaaratYhteenveto">Arvioidut määrät</h5>
  </div>
  <div class="col-left col-lvl-2">
    <p attribute="lannoiterekisteri.markkinoitavienLannoitteidenMaara">
      Suomessa valmistettujen markkinoitavien lannoitteiden määrä
    </p>
  </div>
  <div class="col-right">
    <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
    <span
      id="suomessaValmistettujenLannotteidenMaara-{{subitem.label.key}}">{{subitem.valueMap?.maaraSuomi?.label}} </span>
      <span attribute="lannoiterekisteri.kgVuosi"></span>
    </div>
  </div>
  <div class="col-left col-lvl-2">
    <p attribute="lannoiterekisteri.sisamarkkinoiltaTuotavienLannoitteidenMaara">
      Sisämarkkinoilta tuotavien lannoitteiden määrä
    </p>
  </div>
  <div class="col-right">
    <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
    <span
      id="sisamarkkinoiltaTuotavienLannoitteidenMaara-{{subitem.label.key}}">{{subitem.valueMap?.maaraSisamarkkinat?.label}} </span>
      <span attribute="lannoiterekisteri.kgVuosi"></span>
    </div>
  </div>
</div>
