import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'title-and-translated-answer-summary',
  template: `
    <h2 [attribute]="item?.question" [ngClass]="{'invisible': compareItemType}" class="mb-4 mt-4"></h2>
    <div class="mb-4">
      <compare-item [compareItemType]="compareItemType">
        <span [id]="'value-' + item.field.htmlId" [attribute]="item?.answer"></span>
      </compare-item>
    </div>
  `
})
export class TitleAndTranslatedAnswerSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
