import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {KoodistoService} from "../koodisto/koodisto.service";
import {OsoiteTiedot} from "../account/account";
import {Teksti} from "../utils/teksti";


@Component({
  selector: "touko-osoite",
  template: `

    <div class="col-md-6">
      <touko-text-input [disabled]="readonly" [value]="fieldValue?.osoite"
                        (change)="updateOsoite($event.target.value)"
                        [htmlId]="idPrefix + '-osoite'" [labelAttribute]="osoiteTeksti.fullKey"></touko-text-input>

    </div>
    <div class="col-md-3">
      <touko-text-input [disabled]="readonly" [value]="fieldValue?.postinumero"
                        (change)="updatePostinro($event.target.value)"
                        [htmlId]="idPrefix + '-postinumero'" labelAttribute="lomakeYleinen.postinro"></touko-text-input>
    </div>
    <div class="col-md-3">
      <touko-text-input [disabled]="true" [value]="fieldValue?.postitoimipaikka"
                        [htmlId]="idPrefix + '-toimipaikka'" labelAttribute="lomakeYleinen.postitoimipaikka"></touko-text-input>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OsoiteComponent),
    }
  ]
})
export class OsoiteComponent implements ControlValueAccessor, OnInit {

  @Input('id') idPrefix = "";
  @Input() osoiteTeksti = new Teksti("Osoite", "osoite", "lomakeYleinen");
  @Input() readonly = false;

  constructor(private koodistoService: KoodistoService) {
    this.fieldValue = new OsoiteTiedot();
  }

  fieldValue: OsoiteTiedot;
  lastPostinumero = "";
  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  ngOnInit(): void {
    if (!this.osoiteTeksti || !(this.osoiteTeksti instanceof Teksti)) {
      this.osoiteTeksti = new Teksti("Osoite", "osoite", "lomakeYleinen");
    }
  }

  writeValue(obj: OsoiteTiedot | { osoite: string; postinumero: string; postitoimipaikka: string; maaKoodi: string }): void {
    if (obj && !(obj instanceof OsoiteTiedot)) {
      obj = new OsoiteTiedot(obj.osoite, obj.postinumero, obj.postitoimipaikka, 'FI');
    }

    this.fieldValue = obj;

    if (this.fieldValue && this.fieldValue.postinumero !== this.lastPostinumero) {
      this.koodistoService.getPostitoimipaikkaAsync(this.fieldValue.postinumero).then(val => {
        this.lastPostinumero = this.fieldValue.postinumero;
        this.fieldValue.postitoimipaikka = val;
        this._onChange(this.fieldValue);
      });
    } else {
      this._onChange(this.fieldValue);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  updateOsoite(value: string) {
    const newVal = this.fieldValue ? this.fieldValue : new OsoiteTiedot();
    newVal.osoite = value;

    this.writeValue(newVal);
  }

  updatePostinro(value: string) {
      const newVal = this.fieldValue ? this.fieldValue : new OsoiteTiedot();
    newVal.postinumero = value.trim();

    this.koodistoService.getPostitoimipaikkaAsync(value.trim()).then(val => {
        this.lastPostinumero = value;
        newVal.postitoimipaikka = val;
        this.writeValue(newVal);
      });
  }

  setDisabledState(isDisabled: boolean) {
    this.readonly = isDisabled;
  }

}
