import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {TulosraporttiService} from "../raportti.service";
import {Raportti, Sarake, SarakeType, Tag} from "../raportti.utils";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Teksti} from "../../utils/teksti";
import {DataTableComponent} from "../data-table.component";
import {RaporttiBaseClass} from "../raportti-base";
import {Syote} from "../../touko-lomake/syote/syote";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 09/11/2017.
 */

const VIEW = "raportit";

@Component({
  selector: 'kauppaera-raportti',
  templateUrl: './kauppaera-raportti.component.html',
  styles: ['.no-wrap{ white-space: nowrap; }', 'th>span{cursor: pointer}']
})
@AutoUnsubscribe
export class KauppaeraRaporttiComponent extends RaporttiBaseClass implements OnInit {
  @ViewChild("datatable") datatable: DataTableComponent;
  @Input() getRaportitFn;
  raporttiForm: FormGroup;
  keys;
  liiteDownloadFn;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;

  kasvilajiTags: Tag[] = [];
  protected toimiala = "SIEMEN";

  tapahtumatyypitTags: Tag[];
  syotteet: { [s: string]: Syote };

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
      this.fb.group({
        kauppaera: null,
        tapahtumatyyppi: [],
        pvmAlku: [null, ToukoValidation.isValidDate],
        pvmLoppu: [null, ToukoValidation.isValidDate],
        kasvilaji: null,
        kasvilajike: null
      })
    );

    this.syotteet = {
      kasvilaji: new Syote(new Teksti('Kasvilaji', 'kasvilaji', VIEW), null, null, null, {dropdownTeksti: new Teksti('Kasvilaji', 'valitseKasvilaji', VIEW)}),
      tapahtumatyyppi: new Syote(new Teksti('Tapahtumatyyppi', 'tapahtumatyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTapahtumatyyppi', VIEW)}),
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };

    this.liiteDownloadFn = this.tulosraporttiService.getKauppaeraLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getKauppaeraLiiteZip.bind(this.tulosraporttiService);
    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getKauppaeraRaportit.bind(this.tulosraporttiService);

    this.keys = {
      pvm: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      kauppaera: new Sarake('kauppaera', new Teksti(VIEW, "kauppaera", VIEW), SarakeType.DEFAULT, () => "no-word-break"),
      dokumentti: this.getAsiakirjatSarakeTitle('asiakirja'),
      kasvilaji: new Sarake('kasvilaji', new Teksti(VIEW, "kasvilaji", VIEW)),
      kasvilajike: new Sarake('kasvilajike', new Teksti(VIEW, "kasvilajike", VIEW)),
      raporttityyppi: new Sarake('tapahtumatyyppi', new Teksti(VIEW, "raporttityyppi", VIEW), SarakeType.TYPE, (tyyppi) => tyyppi === "TRADBATCHCERTDECIS" ? 'badge badge-success' : 'badge badge-primary')
    };

    this.columnOrder = [
      this.keys.kauppaera,
      this.keys.raporttityyppi,
      this.keys.pvm,
      this.keys.kasvilaji,
      this.keys.kasvilajike,
      this.keys.dokumentti
    ];

    this.tapahtumatyypitTags = [
      new Tag(new Teksti("Päätös", "paatos", VIEW), "TRADBATCHCERTDECIS", "btn-success"),
      new Tag(new Teksti("Todistus", "todistus", VIEW), "SAMPLING", "btn-primary"),
    ];

    this.initRaportit().then(() => {
      this.initTags();
    });
  }

  initTags() {
    this.kasvilajiTags = this.mapField((r: Raportti) => new Tag(r.kasvilaji, r.kasvilaji));
  }
}
