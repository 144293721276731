import {Injectable} from "@angular/core";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {SummaryFunctions, TulkkiObject} from "../common/lomake-field-utils";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {createToiminnanAjankohta} from "../common/lomake-fields/toiminnan-ajankohta";
import {createSahkoinenAsiointi} from "../common/lomake-fields/common-fields";
import {QuestionAndTranslatedAnswerSummaryComponent} from "../../summary/question-and-translated-answer-summary.component";
import {Validators} from "@angular/forms";
import {VirheObjekti} from "../../validators/validators";
import {titleSummary} from "../lannoiterekisteri/summary-helpers";
import {createPeltokasvinViljely} from "./peltokasvin-viljely";
import {createElaintuotanto} from "./elaintuotanto/elaintuotanto";

const attr = TulkkiObject.getCreateFn('luomu');

@Injectable()
export class LuomuvalvontaData implements LomakeV2Data {

  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(toimintotyyppi: string, isTarkastus = false): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();
    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
        Field.build(
            attr('luomuvalvontaIlmoitus')
        ), {
          toiminnanAloitus: createToiminnanAjankohta(toimintotyyppi),
          luomutoiminnanKohde: new FieldGroup(
              Field.build(
                  attr('luomutoiminnanKohde'),
                  titleSummary
              ), {
                toiminnanKohde: new FieldControl(Field.build()),
                tilatunnus: new FieldControl(Field.of({
                  label: attr('tilatunnus'),
                  summaryFn: tilatunnusSummary,
                  htmlId: 'tilatunnus',
                  valueOptions: [],
                })),
                kasittelija: new FieldControl(Field.of({
                  label: attr('kasittelija'),
                  summaryFn: kasittelijaSummary,
                  htmlId: 'kasittelija',
                  valueOptions: [],
                })),
              }, validateToiminnankohdeFn,
          ),
          ulkomainenOsoite,
          perustiedot,
          laskutustiedot,
          yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
          sahkoinenAsiointi: createSahkoinenAsiointi(),
          tuotantosuunnat: new FieldGroup(Field.build(attr('tuotantosuunnatEhtojenHyväksyminenOtsikko'), SummaryFunctions.titleSummary), {
            peltokasvi: new FieldControl(Field.of({
              label: attr('tuotantosuuntaPeltokasvi'),
              htmlId: 'tuotantosuunta-peltokasvi',
              options: {
                key: 'peltokasvinviljely'
              },
              summaryFn: tuotantosuunnatSummary
            })),
            elaintuotanto: new FieldControl(Field.of({
              label: attr('tuotantosuuntaElaintuotanto'),
              htmlId: 'tuotantosuunta-elaintuotanto',
              options: {
                key: 'elaintuotanto'
              },
            }))
          }, validateTuotantosuuntaFn),
          ehdot: new FieldControl(Field.of({
            label: attr('ehdotValinta'),
            htmlId: 'ehdotCheckbox',
            summaryFn: ehdotSummary,
            errorMessages: {'required': attr('ehdotPakollinenVirheviesti')},
          }), null, Validators.required),
          peltokasvinviljely: createPeltokasvinViljely(),
          elaintuotanto: createElaintuotanto()
        })
  }
}

const kasittelijaSummary: SummaryFnType = (control) => new SummaryItem(control.field?.label, getElyKeskusFn(control), {show: control.value !== null})
const tilatunnusSummary: SummaryFnType = (control) => new SummaryItem(control.field.label, control.value === "eiTilatunnusta" ? attr('eiTilatunnusta') : control.value, {show: control.value !== null}, QuestionAndTranslatedAnswerSummaryComponent)
const tuotantosuunnatSummary: SummaryFnType = (control) => new SummaryItem(attr('tuotantosuunnatLabel'), attr('tuotantosuuntaPeltokasvi'), {show: control.value === true}, QuestionAndTranslatedAnswerSummaryComponent)
const ehdotSummary: SummaryFnType = (control) => new SummaryItem<any>(attr('sitoumus'), 'teksti.kylla', {show: control.value === true}, QuestionAndTranslatedAnswerSummaryComponent);


const validateToiminnankohdeFn = (group: FieldGroup): VirheObjekti => {
  const toiminnankohde = group.get('toiminnanKohde').value;
  const tilatunnus = group.get('tilatunnus').value;
  const kasittelija = group.get('kasittelija').value;

  if ((toiminnankohde === 'maatila' && tilatunnus && kasittelija) || toiminnankohde === 'muuKuinMaatila') {
    return;
  } else {
    return {required: true};
  }
}

const validateTuotantosuuntaFn = (group: FieldGroup): VirheObjekti => {
  const tuotantosuunnat = Object.values(group.controlFields);
  if (tuotantosuunnat.some(tuotantosuunta => tuotantosuunta.value)) {
    return;
  } else {
    return {required: true};
  }
}

const getElyKeskusFn = (control) => {
  if (control.field && control.field?.valueOptions && control.field?.valueOptions.length > 0) {
    return control.field?.valueOptions.find(value => value.value === control.value)?.label;
  } else {
    return control.value && control.value.length > 3 ? control.value.substring(3) : control.value;
  }
}

