import {Field, FieldControl, FieldGroup, SummaryItem} from "ngx-fielding";
import {TitleAndAnswerSummaryComponent} from "../../../summary/title-and-answer-summary.component";
import {ToukoValidation} from "../../../validators/validators";
import {DateObj} from "../../../syote/syote-utils";
import {Validators} from "@angular/forms";
import {TulkkiObject} from "../lomake-field-utils";

const attr = TulkkiObject.getCreateFn('lomakeYleinen')

export const createToiminnanAjankohta = (toimintotyyppi: string, aloituksenAjankohta: DateObj = DateObj.today()) => {
  let label, errorMsg, titleLabel, htmlId;
  if (toimintotyyppi === 'uusi' || toimintotyyppi === 'hakemus') {
    label = attr('merkitseToiminnanAloitus');
    errorMsg = attr('errToiminnanAloitusaika')
    titleLabel = attr('toiminnanAloitus');
    htmlId = 'merkitseToiminnanAloitus'
  } else {
    label = attr('merkitseToiminnanMuutos');
    errorMsg = attr('errToiminnanMuutosaika');
    titleLabel = attr('toiminnanMuutos');
    htmlId = 'merkitseToiminnanMuutos'
  }

  return new FieldGroup(
    Field.build(titleLabel),
    {
      toiminnanAloitus: new FieldControl(
        Field.of(
          {
            label: label,
            summaryFn: (c) => new SummaryItem<any>(
              titleLabel,
              `${c.value?.day}.${c.value?.month}.${c.value?.year}`,
              {show: Boolean(c.value?.day)},
              TitleAndAnswerSummaryComponent),
            errorMessages: {[ToukoValidation.PVM_VALID]: errorMsg, [ToukoValidation.REQUIRED]: errorMsg},
            htmlId: htmlId
          }
        ),
        aloituksenAjankohta,
        [Validators.required, ToukoValidation.isValidDate]
      )
    });
}
