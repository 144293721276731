import {Field, FieldControl, SummaryItem, ValueOption} from "ngx-fielding";
import {TitleAndAnswerSummaryComponent} from "../../../summary/title-and-answer-summary.component";
import {TitleAndInfoSummaryComponent} from "../../../summary/title-and-info-summary.component";
import {radioSummary} from "../../lannoiterekisteri/summary-helpers";
import {RadioValue} from "../lomake-field-utils";

export const createLisatiedot = () => new FieldControl(
  Field.build(
    "lomakeYleinen.lisatiedot",
    (control) => new SummaryItem<any>(control.field.label, control.value, {show: Boolean(control.value)}, TitleAndAnswerSummaryComponent)
  ),null
);

export const createEiToimintaa = (eiToimintaaLabel: string, eiToimintaaYhteenvetoInfo: string) => new FieldControl(
  Field.build(
    eiToimintaaLabel,
    (control) => new SummaryItem<any>(control.field.label, control.value ? eiToimintaaYhteenvetoInfo : null, {show: control.value}, TitleAndInfoSummaryComponent)
  ),
  false
);

export const createSahkoinenAsiointi = () => new FieldControl(
  Field.build(
    "lomakeYleinen.titleSahkAsiointi",
    (control) => new SummaryItem<any>(control.field.label, 'lomakeYleinen.checkSahkAsiointi', {show: control.value}, TitleAndInfoSummaryComponent)
  ),
  false
);

export const createRadioButtons = (radioValues: Array<RadioValue>) => new FieldControl(
  Field.of(
    {
      valueOptions: radioValues,
      summaryFn: radioSummary
    }
  )
)
