import {Component, OnInit} from "@angular/core";
import {SiemenrekisteriV2Data} from "./siemenrekisteri-v2-data.service";
import {AccountService} from "../../../account/account.service";
import {AbstractField, FieldArray, FieldControl} from "ngx-fielding";
import {LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {LomakeInfo} from "../common/lomake-info";
import {Teksti} from "../../../utils/teksti";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {PerustiedotSkipValidationOptions} from "../common/lomake-fields/perustiedot";

@Component({
  selector: 'siemenrekisteri-v2',
  templateUrl: 'siemenrekisteri-v2.component.html',
  providers: [SiemenrekisteriV2Data, ProcessLomakeService],
  styleUrls: ['../common/lomake-style-overrides.scss']
})
export class SiemenrekisteriV2Component extends LomakeV2BaseDirective implements OnInit {
  constructor(processLomakeService: ProcessLomakeService,
              data: SiemenrekisteriV2Data,
              accountService: AccountService) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  get kasvilajit(): AbstractField[] {
    return Object.values(this.lomake.get('markkinoimasiKylvosiemenet').asGroup().controlFields);
  }

  get maahantuonti(): FieldControl {
    return this.lomake.get('toiminnanHarjoittaminen.maahantuonti').asControl();
  }

  get maastavienti(): FieldControl {
    return this.lomake.get('toiminnanHarjoittaminen.maastavienti').asControl();
  }

  get luomu(): FieldControl {
    return this.lomake.get('toiminnanHarjoittaminen.luomu').asControl();
  }

  get toimiala(): FieldControl {
    return this.lomake.get('yrityksenToimiala.toimiala-radio-group').asControl();
  }

  get toiminnanLaajuus(): FieldControl {
    return this.lomake.get('toiminnanLaajuus.radio-group').asControl();
  }

  get liite(): FieldArray {
    return this.lomake.get('liite.liite').asArray()
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new SiemenrekisteriInfo(accountType, toimintotyyppi);
  }

  initLomakeBehaviour() {
    // Ei toimintoja
  }

  addLomakeBehaviour() {
    // Method not used/needed here.
  }


}

class SiemenrekisteriInfo implements LomakeInfo {
  asia: string;
  skipPerustiedotValidation = PerustiedotSkipValidationOptions.skipLaskutustiedot();
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.SIEM_KAUPP.value;
  lomakeTitle: Teksti = new Teksti(
      "Kylvösiemenen markkinointia, maahantuontia ja maastavientiä koskeva ilmoitus",
      "siemenrekisteriIlmoitus",
      "siemenrekisteri");
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: "as" | "vk", toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.SIEM_KAUPP];
    this.asia = "kylvosiemen-markkinointi";
    this.toimintotyyppi = toimintotyyppi;

    const prefix = 'siemenrekisteri.ohje';
    const suffix = this.toimintotyyppi === "muutos" ? "_muutos" : "_uusi"
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1_1', '1_2'], suffix);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2_1'], suffix);
    this.ohjeet.sivu2 = LomakeHelper.getOhjeList(prefix, ['3_1'], suffix);

    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/ilmoitukset/kylvosiemen-markkinointi/${toimintotyyppi}`;
  }
}
