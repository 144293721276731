import {Component, forwardRef, Input} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {merge, Observable, Subject, timer} from 'rxjs';
import {Kasvilaji} from '../../../kasvi/kasvilaji.model';
import {filter, map, switchMap} from 'rxjs/operators';
import {NgbModal, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {KasviService} from '../../../kasvi/kasvi.service';
import {AttributeService} from '../../../tiira/attribute.service';
import {ToukoUusiKasvilajiModal, ToukoUusiKasviLajiModalResponse} from './touko-uusi-kasvi-laji-modal.component';
import {Kasvilajike} from '../../../kasvi/kasvilajike.model';


@Component({
  selector: 'kasvilajike-valinta',
  templateUrl: './touko-kasvilajike-valinta.component.html',
  styleUrls: ['./touko-kasvilajike-valinta.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToukoKasvilajikeValintaComponent),
    }]
})
export class ToukoKasvilajikeValintaComponent implements ControlValueAccessor {

  public constructor(public controlContainer: ControlContainer, private kasviService: KasviService, private attribute: AttributeService, private modalService: NgbModal) {
  }

  @Input() kasvilaji: Kasvilaji;
  @Input('value') _value: Kasvilajike;

  disabled = false;
  lajikeFocus$ = new Subject<string>();
  private closingPopup = false;

  onChange: any = () => {
    // This is intentional
  };
  onTouched: any = () => {
    // This is intentional
  };

  get value(): Kasvilajike {
    return this._value;
  }

  set value(val: Kasvilajike) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  haeKasvilajike = (teksti: Observable<string>): Observable<Kasvilajike[]> => merge(
      teksti,
      this.lajikeFocus$.pipe(
          filter(() => !this.closingPopup)
      )
  ).pipe<Kasvilajike[]>(
      switchMap<string, Observable<Kasvilajike[]>>((hakusana: string) =>
          this.kasviService.haeLajikkeetLajille(this.kasvilaji, hakusana, 20)
              .pipe<Kasvilajike[]>(map<Kasvilajike[], Kasvilajike[]>(lajikkeet => (hakusana.length > 1) ?
                  lajikkeet.concat([{
                    elmoid: "UUSI",
                    kuvaus: this.attribute.simpleTranslate("luomu", "uusiKasvilajike", null, "Uusi kasvilajike")
                  }]) :
                  lajikkeet.concat([{
                    elmoid: "",
                    kuvaus: this.attribute.simpleTranslate("luomu", "eiLajiketta", null, "Ei lajiketta")
                  }, {
                    elmoid: "UUSI",
                    kuvaus: this.attribute.simpleTranslate("luomu", "uusiKasvilajike", null, "Uusi kasvilajike")
                  }])))
      )
  );

  muotoileKasvilajike(laji: Kasvilajike): string {
    return laji.kuvaus;
  }

  onSelectKasvilajike(payload: NgbTypeaheadSelectItemEvent) {

    const kasvilajike = payload.item as Kasvilajike;

    if (kasvilajike.elmoid === "UUSI") {

      const modalref = this.modalService.open(ToukoUusiKasvilajiModal);
      modalref.componentInstance.laji = this.kasvilaji || {nimi: "", elmoid: ""};

      modalref.result.then((paluu: ToukoUusiKasviLajiModalResponse) => {
        this.value = paluu.lajike;

        this.closingPopup = true;

        // Ikkunan sulkeutumisen jälkeen focus palautuu kenttään ja popup aukeaa uudestaan.
        // Timeri sulkee sen automaattisesti tän tapahtumisen jälkeen.
        timer(1000).subscribe((x) => {
          this.closingPopup = false;
        });

      });

      payload.preventDefault();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: Kasvilajike): void {
    if (value) {
      this.value = value;
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
