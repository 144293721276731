import {ControlContainer} from "@angular/forms";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {LiitetiedostoResponse} from "../../touko-lomake/touko-lomake-utils";
import {FieldArray, FieldGroup} from "ngx-fielding";
import {DateObj} from "../../touko-lomake/syote/syote-utils";

/**
 * Liitetiedoston lataus-komponentti. Lataa liitteen palvelimelle ja tuottaa latauslinkin liitteelle.
 * Asettaa lomakkeelle ladatun liitteen id:n, tiedoston tiedot, sekä latauslinkin.
 */
@Component({
  selector: 'liite-pvm',
  template: `
    <div [formGroup]="formGroup">
      <div class="col-12 px-0">
        <touko-radio-input
          [htmlId]="'liite-radio-' + htmlIdSuffix"
          [labelAttribute]="liiteAttr"
          [radioGroup]="liiteAttr"
          [value]="true"
          formControlName="isLiiteSelected">
        </touko-radio-input>
      </div>
      <div class="col-10 mb-3 pl-5 pr-0">
        <liite-list [liitteetArray]="liitteetArray" (liiteOut)="onSetLiite($event)"
                    [labelledBy]="'liite-radio-' + htmlIdSuffix + '-label'"
                    [htmlId]="'liite-' + htmlIdSuffix"
                    [maxLiiteLkm]="maxLiiteLkm" [selite]="selite" [lomakeId]="lomakeId"
                    [disabled]="!isLiiteRadioSelected"></liite-list>
      </div>
      <div class="col-10 px-0">
        <touko-radio-input
          [htmlId]="'pvm-radio-' + htmlIdSuffix"
          [labelAttribute]="pvmAttr"
          [radioGroup]="liiteAttr"
          [value]="false"
          formControlName="isLiiteSelected">
        </touko-radio-input>
        <div class="pl-5 w-50">
          <date [ariaLabel]="pvmAttr"
                formControlName="pvm"
                [htmlIdSuffix]="'pvm-' + htmlIdSuffix"
                [inputDisabled]="isLiiteRadioSelected"></date>
        </div>
      </div>
    </div>
  `
})
export class LiitePvmComponentV2 implements OnInit {
  @Input() liitteetArray: FieldArray;
  @Input() pvmAttr: string;
  @Input() liiteAttr: string;
  @Input() htmlIdSuffix: string;
  @Input() lomakeId: number;
  @Input() selite: string;
  @Input() maxLiiteLkm = 1;
  @Output() liiteOut: EventEmitter<LiitetiedostoResponse[]> = new EventEmitter();

  formGroup: FieldGroup;
  isLiiteRadioSelected: boolean;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit() {
    this.formGroup = this.controlContainer.control as FieldGroup;

    this.formGroup.get('isLiiteSelected').valueChanges.subscribe(value => {
      if (value !== null) {
        this.isLiiteRadioSelected = value;

        if (!this.isLiiteRadioSelected) {
          this.formGroup.get('pvm').setValue(DateObj.today());
        }
      }
    })
  }

  onSetLiite(event: LiitetiedostoResponse[]) {
    this.liiteOut.emit(event);
  }
}
