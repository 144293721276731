import {Component, Input} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-tausta-alert',
  template: `
    <ng-container [formGroup]="controlContainer.control">
      <div *ngIf="questionGroup.label" class="alert alert-warning">
        {{questionGroup.label}}
      </div>
    </ng-container>
  `
})
export class QuestionTaustaAlertComponent {
  @Input() questionGroup: QuestionGroup;

  constructor(public controlContainer: ControlContainer) {
  }
}
