import {Component, forwardRef, Input} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {Kasvilaji, KasvilajiSorter} from '../../../kasvi/kasvilaji.model';
import {map, switchMap} from 'rxjs/operators';
import {NgbModal, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {KasviService} from '../../../kasvi/kasvi.service';
import {AttributeService} from '../../../tiira/attribute.service';
import {ToukoUusiKasvilajiModal, ToukoUusiKasviLajiModalResponse} from './touko-uusi-kasvi-laji-modal.component';


@Component({
  selector: 'touko-moniteksti',
  templateUrl: './touko-moniteksti.component.html',
  styleUrls: ['./touko-moniteksti.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToukoMonitekstiComponent),
    }]
})
export class ToukoMonitekstiComponent implements ControlValueAccessor {

  public constructor(public controlContainer: ControlContainer, private kasviService: KasviService, private attribute: AttributeService, private modalService: NgbModal) {
  }

  @Input('value') _value: string[] = [];
  onChange: any = () => {
    // This is intentional
  };
  onTouched: any = () => {
    // This is intentional
  };

  get value(): string[] {

    if (!this._value || !Array.isArray(this._value)) {
      this._value = [];
    }

    return this._value;
  }

  set value(val: string[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }


  inputKeypressEvent(nappain: KeyboardEvent, kentta: HTMLInputElement) {

    switch (nappain.code.trim().toLowerCase()) {
      case "enter":
        this.lisaaRivi(kentta);
        break;
      default:
    }
  }

  lisaaRivi(kentta: HTMLInputElement) {

    if(kentta.value.trim() !== "") {
      this.value.push(kentta.value.trim());
      this.value.sort();
      kentta.value = '';
    }

  }


  poistaRivi(index: number) {
    const rivit = this.value;

    rivit.splice(index, 1);

    this.value = rivit;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string[]): void {
    if (value) {
      this.value = value;
    }
  }


}
