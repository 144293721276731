import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {LiitetiedostoResponse} from "./touko-lomake-utils";

@Injectable({
  providedIn: 'root',
})
export class LiitetiedostoLomakeService {
  private lomakeIdSource$ = new BehaviorSubject<number>(null);
  lomakeId = this.lomakeIdSource$.asObservable();

  private liite$ = new BehaviorSubject<LiitteetWithAvain>({liite: [], liiteAvain: ''});
  liitteet = this.liite$.asObservable();

  constructor() {
  }

  setLomakeId(lomakeId: number) {
    this.lomakeIdSource$.next(lomakeId);
  }

  setLiite(liite: LiitetiedostoResponse[], liiteAvain: string) {
    this.liite$.next({liite: liite, liiteAvain});
  }

  clearLiitteet(): void {
    this.liite$.next({liite: [], liiteAvain: ''});
  }
}

interface LiitteetWithAvain {
  liite: LiitetiedostoResponse[];
  liiteAvain: string;
}
