<touko-lomake *ngIf="lomake"
              [formGroup]="lomake"
              [tunnus]="lomakeMetaData.id"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [saveFn]="saveLomake"
              (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              [otsikko]="lomakeInfo.lomakeTitle">

  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'" #sivuPerustiedot sivuvirhekasittely
        [virheGroups]="['laskutustiedot', 'perustiedot', 'yhteyshenkilot']" sivu="ref0">
    <div class="mb-4">
      <touko-alert-box *ngIf="!isPerustiedotValid">
        <div attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</div>
      </touko-alert-box>
    </div>
    <perustiedot [lomake]="lomake"></perustiedot>
    <laskutustiedot [lomake]="lomake"></laskutustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"></yhteyshenkilot>
    <ng-container>
      <fieldset class="mt-6 mb-2">
        <legend [attribute]="'lomakeYleinen.titleSahkAsiointi'"></legend>
        <p attribute="lomakeYleinen.infoSahkAsiointi"></p>
        <touko-checkbox htmlId="sahkoinenAsiointi"
                        labelAttribute="lomakeYleinen.checkSahkAsiointi"
                        formControlName="sahkoinenAsiointi"
                        class="strong"
                        checked="false">
        </touko-checkbox>
      </fieldset>
    </ng-container>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'lannoitevuosiilmoitus.sivuTuotteet'" #sivuTuotteet sivuvirhekasittely
        [virheGroups]="['tuotteet']" sivu="ref1">
    <ng-container formGroupName="tuotteet">
      <touko-excel-lataus formControlName="excelTiedostot"
                          [lomakeId]="lomakeMetaData.id"
                          [excelMapper]="excelMapper"
                          (liitteet)="storeExcel($event)"
                          (jsonOut)="mapJsonToLomake($event)"
                          (removeExcelData)="removeDeletedExcelData($event)"></touko-excel-lataus>

      <hr/>

      <touko-checkbox labelAttribute="lannoitevuosiilmoitus.eiToimintaa"
                      htmlId="eiToimintaa"
                      formControlName="lannoiteEiToimintaa"
                      class="strong"
                      checked="false"
                      (change)="setEiToimintaaStatus($event.target.checked)"></touko-checkbox>
      <div class="ml-5 pl-1 mb-4">
        <span attribute="lannoitevuosiilmoitus.eiToimintaaInfo"></span>
        <a [routerLink]="'/asiointi/lomake/lopetus/lannoiterekisteri/v1/0/aloita'">
          <span>&nbsp;</span><span attribute="lannoitevuosiilmoitus.lopetusilmoitus">lopetusilmoitus</span>
        </a>
      </div>
      <validation-error
        [field]="lomake.get('tuotteet')">
      </validation-error>
    </ng-container>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'lomakeYleinen.lisatiedot'">
    <div class="mb-5">
      <touko-alert-box><p attribute="lannoitevuosiilmoitus.lisatiedotInfo" class="mb-0"></p></touko-alert-box>
    </div>
    <div class="form-row">
      <div class="col-md-12 mb-2">
        <touko-textarea formControlName="lisatiedot" htmlId="lisatiedot"
                        [labelAttribute]="lomake.get('lisatiedot').field.label"
                        [maxLength]="3000"></touko-textarea>
      </div>
    </div>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
    <app-field-group-summary class="summary" [fieldGroup]="lomake"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button id="laheta" class="mt-4 btn btn-primary" type="submit" attribute="teksti.laheta">Lähetä</button>
    </div>
  </sivu>

</touko-lomake>

<pre *devFeature class="small">
  {{ lomake?.getRawValue() | json }}
</pre>
