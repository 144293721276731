import {Component, Input} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

/**
 * Created by Seppo on 20/12/2018.
 */

@Component({
  selector: 'modal-ohje',
  template: `
    <button type="button" (click)="openModal(modal)" class="btn btn-sm btn-primary mr-1"><em
      class="fas fa-info-circle"></em></button>
    <ng-template #modal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h1 class="modal-title" [attribute]="titleAttr"></h1>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <p *ngFor="let ohje of ohjeet" class="ohje" [attribute]="ohje"></p>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="c('Close')">OK</button>
      </div>

    </ng-template>
  `,
  styles: [`.ohje{white-space: pre-line}`]
})
export class ModalOhjeComponent {
  @Input() titleAttr: string;
  @Input() ohjeet: string[];

  constructor(private modalService: NgbModal) {
  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }
}
