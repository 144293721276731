import {Field, FieldArray, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {liiteArray, liitePakollinenArray} from "../common/lomake-fields/liite";
import {TulkkiObject} from "../common/lomake-field-utils";
import {KasvituotteetSummary} from "../../summary/luomuvalvonta/kasvituotteet-summary.component";
import {PeltolohkoSummary} from "../../summary/luomuvalvonta/peltolohko-summary";
import {subTitleH3Summary, titleSummary} from "../lannoiterekisteri/summary-helpers";
import {ToukoValidation} from "../../validators/validators";

const attr = TulkkiObject.getCreateFn('luomu');
export const createPeltokasvinViljely = () => new FieldGroup(Field.build(attr('peltokasvienViljely'), titleSummary), {
  kasvituotteet: new FieldGroup(Field.build(null, kasvituotteetSummaryFn), {
    leipavilja: createKasvituote('leipaviljaLabel', 'leipaviljaCheckbox'),
    rehuvilja: createKasvituote('rehuviljaLabel', 'rehuviljaCheckbox'),
    heina: createKasvituote('heinaLabel', 'heinaCheckbox'),
    peruna: createKasvituote('perunaLabel', 'perunaCheckbox'),
    avomaanvihannekset: createKasvituote('avomaanvihanneksetLabel', 'avomaanvihanneksetCheckbox'),
    marjatTaiHedelmat: createKasvituote('marjatTaiHedelmatLabel', 'marjatTaiHedelmatCheckbox'),
    kylvosiemen: createKasvituote('kylvosiemenLabel', 'kylvosiemenCheckbox'),
    taimiaineisto: createKasvituote('taimiaineistoLabel', 'taimiaineistoCheckbox'),
    muutKasvituotteet: new FieldArray(Field.build(), () => createMuuKasvituote(),
        [createMuuKasvituote()]),
  }),
  peltolohkojenTiedot: new FieldArray(Field.build(attr('tilanPeltolohkojenTiedot')), () => createPeltolohkotiedot(),
      [createPeltolohkotiedot()]),
  siirtymavaiheenLyhennys: new FieldGroup(Field.build(attr('siirtymavaiheenLyhennys'), subTitleH3Summary), {
    siirtymavaiheenLyhennysValinta: new FieldControl(Field.build()),
    hakemusSiirtymavaiheenLyhentamiseksi: liitePakollinenArray(attr('siirtymavaiheenLyhennyksenHaku'), 'hakemusSiirtymavaiheenLyhentamiseksi', null, attr('hakemusSiirtymavaiheenLyhentamiseksiVirheviesti'))
  }),
  liitteet: new FieldGroup(Field.build(attr('liitteet'), subTitleH3Summary), {
    luomusuunnitelma: liitePakollinenArray(attr('luomusuunnitelmaSummary'), 'luomusuunnitelma', null, attr('luomusuunnitelmaPakollinenVirheviesti')),
    muuLiite: liiteArray(attr('muuLiite'), 'muuLiite'),
    liiteSisaltoDesc: new FieldControl(Field.of({
      summaryFn: control => new SummaryItem(control.field.label, control.value, {show: control.value}),
      label: attr('kerroLiitteenSisallosta'),
      htmlId: 'liitteenSisalto'
    }))
  })
})

const createKasvituote = (label, htmlId) => {
  return new FieldGroup(Field.of({
    label: attr(label),
    htmlId: htmlId,
  }), {
    luonnonmukainen: new FieldControl(Field.build()),
    tavanomainen: new FieldControl(Field.build())
  })
}

const createMuuKasvituote = () => {
  return new FieldGroup(Field.build(), {
    nimi: new FieldControl(Field.build()),
    luonnonmukainen: new FieldControl(Field.build()),
    tavanomainen: new FieldControl(Field.build())
  })
}

const createKasvulohko = () => {
  return new FieldGroup(Field.build(), {
    lohkotunnus: new FieldControl(Field.build()),
    lohkoNimi: new FieldControl(Field.build()),
    pintaAla: new FieldControl(Field.build())
  })
}

const createPeltolohkotiedot = () => {
  return new FieldGroup(Field.of({
    summaryFn: peltolohkoSummaryFn,
    errorMessages: {errPeltolohkotiedot: attr("errPeltolohkotiedot")}
  }), {
    siirtymavaiheenSuunniteltuAjankohta: new FieldControl(Field.of({
      label: attr('siirtymavaiheenSuunniteltuAjankohta'),
      htmlId: 'siirtymavaiheenSuunniteltuAjankohta',
      errorMessages: {"errSiirtymavaihe": attr("errSiirtymavaihe")}
    })),
    lohkonSijoitusValinta: new FieldControl(Field.build()),
    peltolohkot: new FieldArray(Field.build(), () => createPeltolohko())
  }, ToukoValidation.peltolohkojenTiedotNotEmpty)
}

const createPeltolohko = () => {
  return new FieldGroup(Field.build(), {
    lohkotunnus: new FieldControl(Field.build()),
    lohkoNimi: new FieldControl(Field.build()),
    pintaAla: new FieldControl(Field.build()),
    kasvulohkot: new FieldArray(Field.build(), createKasvulohko)
  })
}

const peltolohkoSummaryFn: SummaryFnType = (peltolohkoGroup: FieldGroup) => {
  const parent = peltolohkoGroup.parent as FieldArray
  return new SummaryItem<any>(null, peltolohkoGroup, {
    show: peltolohkoGroup.value.peltolohkot.length > 0,
    peltolohkoIndex: parent.controls.indexOf(peltolohkoGroup)
  }, PeltolohkoSummary);
};

const kasvituotteetSummaryFn: SummaryFnType = (kasvituotteetGroup: FieldGroup) => {
  const tuotteet = Object.values(kasvituotteetGroup.controlFields)

  const someKasvituoteHasValue = tuotteet.some(tuote => {
    if (tuote instanceof FieldGroup) {
      return tuote.get('luonnonmukainen').value || tuote.get('tavanomainen').value;
    }
  })

  const muutKasvituotteetArray = tuotteet.find(tuote => tuote instanceof FieldArray) as FieldArray
  const someMuuKasvituoteHasValue = muutKasvituotteetArray.controlFields.some(muuKasvituote => {
    return muuKasvituote.get('nimi').value && (muuKasvituote.get('luonnonmukainen').value || muuKasvituote.get('tavanomainen').value)
  })

  return new SummaryItem<any>(null, kasvituotteetGroup, {show: someKasvituoteHasValue || someMuuKasvituoteHasValue}, KasvituotteetSummary);
};
