import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "../message/message.service";
import {LoaderService} from "../loader/loader.service";
import {PalveluService} from "../sidebar/palvelu.service";
import {PalsaInfoComponent} from "./palsa-info.component";
import {TiiraService} from "../tiira/tiira.service";
import {Subscription} from "rxjs";
import {EnvironmentService, Feature} from "../../environments/environment.service";

/**
 * Kirjautumisnäkymä. Hyödyntää Tiira-palveluita.
 */
@Component({
  selector: "login",
  templateUrl: "login.component.html",
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(PalsaInfoComponent, { static: true })
  private palsaComponent: PalsaInfoComponent;

  showVirkailijaLogin = false;
  currentLang: string;
  virkailijaLoginSuffix: string = '/login?role=virkailija';

  externalLoginLinks: {
    type: string;
    title: string;
    description: string;
    icon: string;
    nameKey: string;
    lang: string;
    url: string;}[];

  loginIcons = {
    'YKSITYIS-FI': 'fa-user',
    'YKSITYIS-SV': 'fa-user',
    'YKSITYIS-HPA-FI': 'fa-user-friends',
    'YKSITYIS-HPA-SV': 'fa-user-friends',
    'YRITYS-FI': 'fa-tractor',
    'YRITYS-SV': 'fa-tractor',
  };

  virkailijaLoginSub: Subscription;

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly tiiraService: TiiraService,
              private readonly messageService: MessageService,
              private readonly loaderService: LoaderService,
              private readonly sidebarService: PalveluService,
              private readonly env: EnvironmentService) {
  }

  ngOnInit() {
    this.sidebarService.hideSidebar();

    this.virkailijaLoginSub = this.activatedRoute.queryParams.subscribe(param => {
      this.showVirkailijaLogin = param && param.role === 'virkailija';
    });

    this.tiiraService.setLoginQuestionsAsync((authData, urls) => {
      this.tiiraService.cleanUpSession();
      this.tiiraService.cleanUpAccount();
      this.currentLang = this.tiiraService.initUserLanguage();

      if (!authData.authenticated && authData.error) {
        this.messageService.addMessage(authData.error);
        /* Stop ANY animation, there was authentication failure (wrong credentials, expired session etc.) */
        this.loaderService.forceStopLoadingAnimation();
        this.router.navigate(['/login']);
      }

      this.externalLoginLinks = urls
        .filter(url => url.lang === this.currentLang)
        .map(url => ({...url, icon: this.loginIcons[url.type]}));

      /* Stop LOGIN animation (we are in the login page and user action is required) */
      this.loaderService.stopLoadingAnimation();
    });

    this.tiiraService.toukoLogin();
  }

  ngAfterViewInit() {
    if (!this.tiiraService.isAuthenticated()) {
      this.palsaComponent.update();
    }
  }

  ngOnDestroy(): void {
    if (this.virkailijaLoginSub) {
      this.virkailijaLoginSub.unsubscribe();
    }
  }

  submitForm(loginFrom) {
    loginFrom.KIELI = this.tiiraService.getCurrentLanguage().toLowerCase();
    this.tiiraService.authQuestionsAnswered(loginFrom);
  }

  setLanguage(languageCode: 'FI' | 'SV') {
    this.tiiraService.setCurrentLanguage(languageCode);
    this.currentLang = languageCode;
  }

  getEnvironmentLoginUrl(moveToVkEnvironment: boolean) {
    const currentApplication = moveToVkEnvironment ? "touko" : "touko-vk";
    const targetApplication = moveToVkEnvironment ? "touko-vk" : "touko";

    if (this.env?.getUrl().includes("localhost")) {
      const url = moveToVkEnvironment ? "http://localhost:8081" : "http://localhost:8080";

      return url + this.virkailijaLoginSuffix;
    } else {
      return this.env?.getUrl() ? this.env?.getUrl().replace(currentApplication, targetApplication) + this.virkailijaLoginSuffix : null;
    }
  }

  protected readonly Feature = Feature;
}
