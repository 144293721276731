import {Validators} from "@angular/forms";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {SyoteGroup} from "../syote/syote-group";
import {ToukoValidation} from "../validators/validators";
import {YhteyshenkiloValue} from "../touko-lomake-utils";
import {Account} from "../../account/account";
import {keyOrderFn, LomakeData, LomakeDataContent} from "../lomake-data";

/**
 * Siemenlomakkeen data
 */
export class SiemenRekisteriData extends LomakeData {

  static lomakeTitle: Teksti = new Teksti("Kylvösiemenen markkinointia, maahantuontia ja maastavientiä koskeva ilmoitus", "siemenrekisteriIlmoitus", "siemenrekisteri");

  static getKeyOrder(): string[] {
    return keyOrderFn(SiemenRekisteriData.getData);
  }

  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    const view = SiemenRekisteriData.lomakeTitle.view;
    const TOIMINNAN_LAAJUUS_RADIO = "radio-group";
    const TOIMIALA_RADIO = "toimiala-radio-group";

    return {
      perustiedot: SiemenRekisteriData.getPerustiedot(view, account),
      yhteyshenkilot: SiemenRekisteriData.getYhteyshenkilot(view, account, null, YhteyshenkiloValue.SIEM_KAUPP.value),
      toiminnanAloitus: SiemenRekisteriData.getToiminnanAloitus(view, toimintotyyppi),
      markkinoimasiKylvosiemenet: new SyoteGroup(
        view,
        new Teksti("Merkitse markkinoimasi kylvösiemenet", "markkinoimasiKylvosiemenet"), // haetaan attribuuttikannasta
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse vähintään yksi", "vahintaanYksi")},
        YhteenvetoTyyppi.LABEL_ONLY,

        new Syote(new Teksti("Viljakasvit", "viljakasvit"), null),
        new Syote(new Teksti("Nurmi- ja rehukasvit",  "nurmiJaRehu"), null),
        new Syote(new Teksti("Öljy- ja kuitukasvit", "oljyJaKuitu"), null),
        new Syote(new Teksti("Siemenperuna", "siemenperuna"), null),
        new Syote(new Teksti("Vihanneskasvit", "vihanneskasvit"), null),
        new Syote(new Teksti("Juurikkaat", "juurikkaat"), null),
        new Syote(new Teksti("Seokset", "seokset"), null),
        new Syote(new Teksti("Muut kasvit", "muutKasvit"), null)
      ),

      toiminnanHarjoittaminen: new SyoteGroup(
        view,
        new Teksti("Toiminnan harjoittaminen", "toiminnanHarjoittaminen"), // haetaan attribuuttikannasta
        null,
        {
          required: new Teksti("Täytä puuttuvat kentät", "taytaPuuttuvat")
        },
        YhteenvetoTyyppi.YES_NO,

        new Syote(new Teksti("Harjoitatteko edellä mainittujen kylvösiementen maahantuontia?", "maahantuonti"), [null, Validators.required]),
        new Syote(new Teksti("Harjoitatteko edellä mainittujen kylvösiementen maastavientiä?", "maastavienti"), [null, Validators.required]),
        new Syote(new Teksti("Markkinoitteko luonnonmukaisesti tuotettua (LUOMU) siementä?", "luomu"), [null, Validators.required])
      ),

      toiminnanLaajuus: new SyoteGroup(
        view,
        new Teksti("Missä laajuudessa harjoitatte toimintaa vuodessa?", "toiminnanLaajuus"),
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse toiminnan laajuutta parhaiten kuvaava vaihtoehto", "toiminnanLaajuusVirhe")},
        YhteenvetoTyyppi.SINGLE_SELECTION_LABEL,

        new Syote(new Teksti("alle 100", "alle100"), null, TOIMINNAN_LAAJUUS_RADIO, "alle100"),
        new Syote(new Teksti("100–1 000 kg"), null, TOIMINNAN_LAAJUUS_RADIO, "100-1000"),
        new Syote(new Teksti("1 000–10 000 kg"), null, TOIMINNAN_LAAJUUS_RADIO, "1000-10000"),
        new Syote(new Teksti("10 000–100 000 kg"), null, TOIMINNAN_LAAJUUS_RADIO, "10000-100000"),
        new Syote(new Teksti("100 000–1 000 000 kg"), null, TOIMINNAN_LAAJUUS_RADIO, "100000-1000000"),
        new Syote(new Teksti("yli 1 000 000", "yli1000000"), null, TOIMINNAN_LAAJUUS_RADIO, "yli1000000"),
      ),

      yrityksenToimiala: new SyoteGroup(
        view,
        new Teksti("Yrityksen toimiala", "toimiala"),
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse yrityksen toimintaa parhaiten kuvaava toimiala.", "toimialaVirhe")},
        YhteenvetoTyyppi.SINGLE_SELECTION_LABEL,

        new Syote(new Teksti("Maatalouden tuotantotarvikkeiden vähittäiskauppa", "maatalousVahittaiskauppa"), null, TOIMIALA_RADIO, "maatalousVahittaiskauppa"),
        new Syote(new Teksti("Muu vähittäiskauppa", "muuVahittaiskauppa"), null, TOIMIALA_RADIO, "muuVahittaiskauppa"),
        new Syote(new Teksti("Maahantuonti- ja välitystoiminta", "maahantuontiJaValitus"), null, TOIMIALA_RADIO, "maahantuontiJaValitus"),
        new Syote(new Teksti("Muu siemenalan toiminta", "muuSiementoiminta"), null, TOIMIALA_RADIO, "muuSiementoiminta"),
        new Syote(new Teksti("Siemenpakkaamo", "siemenpakkaamo"), null, TOIMIALA_RADIO, "siemenpakkaamo"),
        new Syote(new Teksti("Yksityiskäyttö", "yksityiskaytto"), null, TOIMIALA_RADIO, "yksityiskaytto")
      ),

      liite: new SyoteGroup(
        view,
        new Teksti("Yrityksen kaupparekisteriote", "liitteet"),
        null,
        null,
        YhteenvetoTyyppi.ATTACHMENT,
        // new Syote("Yrityksen toimialakuvaus tai kaupparekisteriote", null, "liite")
        new Syote(new Teksti("Yrityksen toimialakuvaus tai kaupparekisteriote", "liite"), null)
      ),

      lisatiedot: new SyoteGroup(
        view,
        new Teksti("Lisätiedot", "lisatiedot"),
        null,
        null,
        YhteenvetoTyyppi.VALUE_ONLY,
        new Syote(new Teksti("Lisätiedot", "lisatieto"), null)
      ),

      verkkokaupat: SiemenRekisteriData.getVerkkokaupat(view, account)
    };
  }
}
