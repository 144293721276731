<touko-lomake *ngIf="isInitialized"
              [otsikko]="otsikko"
              [formGroup]="lomakeGroup"
              [tunnus]="id"
              [saveFn]="save"
              [submitFn]="submit"
              [cancelFn]="cancel"
              [saveable]="true"
              (onSivuChanged)="updateYhteenveto()"
              (onLastPage)="autosaveFunction()">

  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'" [ohjeet]="['sahkoelain.ohje1_1', 'sahkoelain.ohje_yleinen']">
    <fieldset class="mb-4">
      <legend>{{ lomakeModel.tilaaja?.label }}</legend>
      <question-group formGroupName="tilaaja" [questionGroup]="lomakeModel.tilaaja"></question-group>
    </fieldset>

    <div class="mb-4">
      <strong [attribute]="'sahkoelain.titleSahkAsiointi'" [ngStyle]="{'font-size': '14px'}"></strong>
      <p attribute="sahkoelain.infoSahkAsiointi"></p>
      <question-group formGroupName="sahkoinenAsiointi"
                      [questionGroup]="lomakeModel.sahkoinenAsiointi"></question-group>
    </div>

    <fieldset [eelControlMatcher]="lomakeModel.omistaja" class="mb-4">
      <legend>{{ lomakeModel.omistaja?.label }}</legend>
      <question-group formGroupName="omistaja" [questionGroup]="lomakeModel.omistaja"></question-group>
    </fieldset>

    <fieldset [eelControlMatcher]="lomakeModel.elainlaakari" class="mb-4">
      <legend>{{ lomakeModel.elainlaakari?.label }}</legend>
      <question-group formGroupName="elainlaakari" [questionGroup]="lomakeModel.elainlaakari"></question-group>
    </fieldset>

    <fieldset [eelControlMatcher]="lomakeModel.tiedoksisaaja" class="mb-4">
      <legend>{{ lomakeModel.tiedoksisaaja?.label }}</legend>
      <question-group formGroupName="tiedoksisaaja" [questionGroup]="lomakeModel.tiedoksisaaja"></question-group>

      <div class="form-row">
        <div class="col-12">
          <button attribute="sahkoelain.lisaaTiedoksisaaja"
                  class="btn btn-primary"
                  type="button"
                  (click)="addTiedoksisaaja()">
            + Lisää tiedoksisaaja
          </button>
        </div>
      </div>
    </fieldset>

  </sivu>

  <sivu [attribuuttiAvain]="'sahkoelain.sivuTutkimuskohde'"
        [ohjeet]="['sahkoelain.ohje2_1', 'sahkoelain.ohje_yleinen']">
    <div class="row">
      <div class="col-4">
        <fieldset>
          <legend>{{ lomakeModel.elainlajit?.label }}</legend>
          <div class="form-row">
            <question-group formGroupName="elainlajit" [questionGroup]="lomakeModel.elainlajit"></question-group>
          </div>
        </fieldset>
      </div>

      <div class="col-8">
        <fieldset>
          <legend>{{ lomakeModel.elainlajiValinta?.label }}</legend>
          <div [eelControlMatcher]="lomakeModel.elainlajiValinta">
            <div class="form-row">
              <question-group class="col-xl-4 col-lg-6 col-sm-12" formGroupName="elainlajiValinta"
                              [questionGroup]="lomakeModel.elainlajiValinta"></question-group>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </sivu>

  <sivu [attribuuttiAvain]="'sahkoelain.sivuTutkimukset'" [ohjeet]="['sahkoelain.ohje3_1', 'sahkoelain.ohje_yleinen']">
    <fieldset class="mb-4">
      <legend attribute="sahkoelain.tutkimukset">{{ lomakeModel.tutkimukset?.label }}</legend>
      <div class="form-row">
        <question-group class="col-xl-4 col-lg-6 col-sm-12" formGroupName="tutkimushaku"
                        [questionGroup]="lomakeModel.tutkimushaku"></question-group>
        <div class="w-100"></div>
        <question-group class="col-12" formGroupName="tutkimusalat"
                        [questionGroup]="lomakeModel.tutkimusalat"></question-group>
        <question-group class="col-12" formGroupName="tutkimukset"
                        [questionGroup]="lomakeModel.tutkimukset"></question-group>
      </div>
    </fieldset>

    <fieldset [eelControlMatcher]="lomakeModel.valitutTutkimukset">
      <legend attribute="sahkoelain.valitutTutkimukset">Valitut tutkimukset</legend>
      <question-group formGroupName="valitutTutkimukset"
                      [questionGroup]="lomakeModel.valitutTutkimukset"></question-group>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'sahkoelain.sivuNaytteet'" [ohjeet]="['sahkoelain.ohje4_1', 'sahkoelain.ohje_yleinen']">

    <fieldset [eelControlMatcher]="lomakeModel.naytetiedot">
      <legend attribute="sahkoelain.naytetiedot">Näytetiedot</legend>

      <div *ngIf="nautaRekkari()" class="form-row">
        <div class="col-3">
          <label attribute="sahkoelain.nautojenPitopaikkatunnus">Nautojen pitopaikkatunnus</label>
        </div>
        <div class="col-9">
          <select #pitopaikka id="pitopaikkaDropdown" (change)="selectPitopaikka($event.target.value)"
                  class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown"
                  aria-label="valitse pitopaikka">
            <option attribute="sahkoelain.valitse" [value]="null" disabled selected>valitse</option>
            <option *ngFor="let pitopaikka of getPitopaikat()" value="{{pitopaikka}}">{{ pitopaikka }}</option>
          </select>
        </div>
      </div>

      <question-group (init)="afterChildrenInit($event)" formGroupName="naytetiedot"
                      [questionGroup]="lomakeModel.naytetiedot"></question-group>
    </fieldset>

    <fieldset [eelControlMatcher]="lomakeModel.automTiedoksisaaja">
      <legend>{{ lomakeModel.automTiedoksisaaja.label }}</legend>
      <question-group formGroupName="automTiedoksisaaja"
                      [questionGroup]="lomakeModel.automTiedoksisaaja"></question-group>
    </fieldset>

  </sivu>

  <sivu [attribuuttiAvain]="'sahkoelain.sivuTaustakysymykset'"
        [ohjeet]="['sahkoelain.ohje5_1', 'sahkoelain.ohje_yleinen']">
    <fieldset>
      <div></div>
      <legend attribute="sahkoelain.lisakysymykset">Lisäkysymykset</legend>
      <div class="form-row">
        <question-group formGroupName="tilatiedot" [questionGroup]="lomakeModel.tilatiedot"></question-group>
      </div>

      <div class="form-row">
        <question-group formGroupName="tilatyypit" [questionGroup]="lomakeModel.tilatyypit"></question-group>
      </div>

      <div class="form-row">
        <question-group formGroupName="taustakysymykset"
                        [questionGroup]="lomakeModel.taustakysymykset"></question-group>
      </div>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'sahkoelain.sivuToimitusosoite'"
        [ohjeet]="['sahkoelain.ohje6_1', 'sahkoelain.ohje_yleinen']">
    <fieldset [eelControlMatcher]="lomakeModel.toimitustiedot">
      <legend attribute="sahkoelain.toimitusosoite">Toimitusosoite</legend>
      <div class="form-row">
        <touko-alert-box class="mb-3">
          <span attribute="sahkoelain.toimitusInfo"></span>
        </touko-alert-box>
        <question-group formGroupName="toimitustiedot" [questionGroup]="lomakeModel.toimitustiedot"></question-group>
      </div>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'teksti.sivuYhteenveto'" [ohjeet]="['sahkoelain.ohje7_1', 'sahkoelain.ohje_yleinen']">

    <div #laheteYhteenvetoDiv id="laheteYhteenvetoDiv" class="d-print-none">
      <lahete-yhteenveto class="col-md-9" prefix="yhteenvetoValue-" [yhteenveto]="yhteenveto"></lahete-yhteenveto>
    </div>

    <div #laheteYhteenvetoPrintDiv id="laheteYhteenvetoPrintDiv">
      <div #barcodeCanvas id="barcodeCanvas"></div>
      <lahete-yhteenveto class="col-md-9" prefix="yhteenvetoPrintValue-" [yhteenveto]="yhteenveto"></lahete-yhteenveto>
    </div>

    <hr>
    <div class="form-row d-print-none" id="ehdot">
      <div class="form-group col">
        <p attribute="sahkoelain.ehdotSelite" [markdown]="true">Tutkimus- ja toimitusehdot</p>

        <div class="form-check">
          <input id="toimitusehdot" type="checkbox" class="form-check-input" formControlName="toimitusehdot"
                 #toimitusehdot>
          <label for="toimitusehdot" class="form-check-label" attribute="sahkoelain.hyvaksyEhdot">Olen lukenut ja
            hyväksyn ehdot.</label>
        </div>
      </div>
    </div>

    <div class="form-row d-print-none">
      <button class="btn btn-primary"
              type="submit"
              id="lahetaYhteenvetoButton"
              [disabled]="!toimitusehdot.checked"
              attribute="teksti.laheta">Lähetä
      </button>
      <div class="col-9">
        <button class="btn btn-primary" id="tulostaYhteenvetoButton" type="button" (click)="printYhteenveto()">
          <em class="fa fa-print"></em>
          <span attribute="teksti.tulostaYhteenveto">Tulosta yhteenveto</span>
        </button>
      </div>
    </div>

  </sivu>
</touko-lomake>

<pre *devFeature class="small">
  {{ lomakeGroup?.getRawValue() | json }}
</pre>
