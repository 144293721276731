import {FieldGroup} from "ngx-fielding";
import {LomakeResponse} from "../../../touko-lomake-utils";
import {Account, KayttajaRooli, Toimiala} from "../../../../account/account";
import {LomakeInfo} from "../lomake-info";
import {Operaattoritunnus} from "../../../../utils/operaattoritunnus";

export class LomakeProcess {

  public static preProcessSisalto(response: LomakeResponse): any {
    if (response) {
      const sisalto = JSON.parse(response.sisalto).data;
      VerkkokaupatHelper.updateVerkkokaupat(sisalto);
      Verkkolaskuosoite.updateVerkkolaskuOsoite(sisalto);
      return sisalto;
    }
    return {};
  }

  public static postProcessLomake(lomake: FieldGroup, lomakeInfo: LomakeInfo, response: LomakeResponse, account: Account) {
    YhteystiedotHelper.initYhteystiedot(lomake, lomakeInfo, account);
    PerustiedotHelper.initPerustiedot(lomake, response, account);
  }
}

class Verkkolaskuosoite {
  static updateVerkkolaskuOsoite(lomakeData: any) {
    let operaattori = lomakeData.laskutustiedot?.verkkolaskutusosoite?.operaattori;
    const validOperaattorit = new Set<string>(Operaattoritunnus.getOperaattoritunnukset());
    if (operaattori && !validOperaattorit.has(operaattori)) {
      if (validOperaattorit.has("00" + operaattori)) {
        // korjataan vanhoja väärin hyväksyttyjä operaattoritunnusmuotoja
        lomakeData.laskutustiedot.verkkolaskutusosoite.operaattori = "00" + operaattori;
      } else {
        lomakeData.laskutustiedot.verkkolaskutusosoite.operaattori = null;
      }
    }
  }
}

class YhteystiedotHelper {

  static initYhteystiedot = (lomake: FieldGroup, lomakeInfo: LomakeInfo, account: Account) => {
    if (lomake.get('yhteyshenkilot.yhteyshenkilot.0').value.nimi === null) {
      lomake.get('yhteyshenkilot.yhteyshenkilot.0')
          .patchValue({
            nimi: Account.getReversedWholename(account),
            rooli: lomakeInfo.defaultRooli
          })
    }
  }

}

class PerustiedotHelper {

  static initPerustiedot = (lomake: FieldGroup, response: LomakeResponse, account: Account) => {
    const ytunnus = PerustiedotHelper.getYtunnus(response, account);
    lomake.get('perustiedot.ytunnus').setValue(ytunnus);
    lomake.get('perustiedot.yritys').setValue(PerustiedotHelper.getPaatoiminimi(response, account));
    if (ytunnus?.includes("-")) {
      lomake.get('perustiedot.vatnumero').setValue("FI".concat(ytunnus.replace("-", "")));
    }
    if (!response) {
      lomake.get('perustiedot.osasto').setValue(account.yritys?.paaToiminimi)
    }
  };

  private static getPaatoiminimi = (response: LomakeResponse, account: Account) => response?.lahettajaYritys || account.yritys?.paaToiminimi;

  private static getYtunnus = (response: LomakeResponse, account: Account) => {
    if (account.kayttajaRooli === KayttajaRooli.ASIAKAS_HENKILO && account.toimiala === Toimiala.LUOMU) {
      return account.maatilatunnus
    } else {
      return response?.lahettajaAsiointitunnus || account.ytunnus
    }
  }
}

class VerkkokaupatHelper {

  static updateVerkkokaupat(lomakeData: any) {
    const verkkokauppaList = lomakeData.verkkokaupat?.verkkokaupat || [];
    if (verkkokauppaList.filter(v => v.osoite).length > 0) {
      lomakeData.verkkokaupat['checkbox'] = true;
      verkkokauppaList.forEach(verkkokauppa => {
        if (!verkkokauppa.hasOwnProperty('lisatty')) {
          verkkokauppa['lisatty'] = false;
        }
      });
    }
  }

}
