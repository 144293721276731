import {AbstractField, FieldArray, FieldGroup, SummaryItem} from "ngx-fielding";

export class SummaryService {
  getSummary(abstractField: AbstractField, summaryList: SummaryItem<any>[] = []): SummaryItem<any>[] {
    summaryList.push(abstractField.summary)
    const hideChildSummary = Boolean(abstractField.summary) && !abstractField.summary.show;

    if (!hideChildSummary) {
      if (abstractField instanceof FieldGroup) {
        Object.values(abstractField.controlFields).forEach(child => this.getSummary(child, summaryList))
      } else if (abstractField instanceof FieldArray) {
        abstractField.controlFields.forEach(child => this.getSummary(child, summaryList))
      }
    }

    return summaryList.filter(s => s && s.show);
  }
}
