import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
    selector: 'translated-question-and-answer-with-info-summary',
    template: `
      <div *ngIf="item" class="row" [ngClass]="{'pb-2': !compareItemType}">
        <div *ngIf="!compareItemType" class="col-lg-4 col-5">
          <compare-item [compareItemType]="compareItemType">
            <span [id]="'value-' + item.field.htmlId" [attribute]="item?.question"></span>
          </compare-item>
        </div>
        <div class="col" *ngIf="item?.answer?.userprovidedPart">
          <span [id]="'info-question-' + item.field.htmlId" [attribute]="item?.answer?.translatedPart"></span><br>
          <span [id]="'info-value-' + item.field.htmlId" class="answer">{{item?.answer?.userprovidedPart}}</span>
        </div>
      </div>
    `,
    styleUrls: ['./summary.scss']
  })
export class TranslatedQuestionAndAnswerWithInfoSummaryComponent {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
