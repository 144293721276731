import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {QuestionGroup} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {Validators} from "@angular/forms";
import {GroupBuilder} from "./group-build-options";
import {QuestionInitValue} from "ee-lahete";

export class ElainlajiryhmakooditGroup extends AbstractLaheteGroup {

  getGroup(controlType = 'radio', builder = new GroupBuilder({controlType: 'radio'})): QuestionGroup {

    const controls: QuestionControl[] = this.laheteKoodisto.koodisto.elainlajiryhmakoodit.map(el =>
        new QuestionControl({
          controlType: builder.controlType,
          value: el.id,
          initialValue: null,
          key: 'elainlajiryhma',
          htmlId: el.id,
          label: this.getLocalizedOf(el, 'tutkimuskohde'),
          validators: [Validators.required],
          errorMessages: {'required': this.getLabel('Eläinlajiryhmä on pakollinen.')},
        })
    );

    const group = new QuestionGroup({
      key: 'elainlajit',
      label: this.getLabel('elainlajiryhma'),
      controls: controls
    });

    if (this.useBuilderValues(builder, group.key)) {
      controls.forEach(control =>
        this.laheteService.registerInitialValue(new QuestionInitValue(
          control,
          builder.initValue(val => val.elainlajit.elainlajiryhma)
        ))
      );
    }

    return group;
  }
}
