import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {SyoteGroup} from "../syote/syote-group";

export const toimintaJaTuoteluokatEuToiminta = (view: string, toimintotyyppi: string): SyoteGroup => {
  const pakkausRadio = [new Syote(
    new Teksti("Lannoitteen valmistus on ainoastaan pakkaamista?", "lannoitteenValmistusAinoastaanPakkaamista", view), null, "lannoitteenValmistusAinoastaanPakkaamista", "true", {type: 'lannoitteenValmistusAinoastaanPakkaamistaEuRadio'}
  )];
  const valmistusJaTuontimaarat = [new Syote(new Teksti("Suomessa valmistettujen markkinoitavien lannoitteiden määrä", "markkinoitavienLannoitteidenMaara", view), null, "maaraSuomi", null, {
    type: 'text',
  }, YhteenvetoTyyppi.VALUE_ONLY),
    new Syote(new Teksti("Sisämarkkinoilta tuotavien lannoitteiden määrä", "sisamarkkinoiltaTuotavienLannoitteidenMaara", view), null, "maaraSisamarkkinat", null, {
      type: 'text',
    }, YhteenvetoTyyppi.VALUE_ONLY)];

  const cmcCheckboxList = [
    new Syote(new Teksti("CMC 1 - Ensiömateriaalista koostuvat aineet ja seokset", "cmc1EnsiomateriaalistaKoostuvatAineetJaSeokset", view), null, "cmc1", null, {
      type: 'cmcCheckbox',
      showTitle: true
    }),
    new Syote(new Teksti("CMC 2 - Kasvit, kasvien osat tai kasviuutteet", "cmc2KasvitKasvienOsatTaiKasviuutteet", view), null, "cmc2", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 3 - Komposti", "cmc3Komposti", view), null, "cmc3", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 4 - Tuorekasvimädäte", "cmc4Tuorekasvimadate", view), null, "cmc4", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 5 - Muu mädäte kuin tuorekasvimädäte", "cmc5MuuMadateKuinTuorekasvimadate", view), null, "cmc5", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 6 - Elintarviketeollisuuden sivutuotteet", "cmc6ElintarviketeollisuudenSivutuotteet", view), null, "cmc6", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 7 - Mikro-organismit", "cmc7MikroOrganismit", view), null, "cmc7", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 8 - Ravinnepolymeerit", "cmc8Ravinnepolymeerit", view), null, "cmc8", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 9 - Muut polymeerit ja ravinnepolymeerit", "cmc9MuutPolymeeritJaRavinnepolymeerit", view), null, "cmc9", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 10 - Asetuksessa (EY) N:o 1069/2009 tarkoitetut johdetut sivutuotteet", "cmc10Asetuksessa1069/2009TarkoitetutJohdetutSivutuotteet", view), null, "cmc10", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 11 - Direktiivissä 2008/98/EY tarkoitetut sivutuotteet", "cmc11Direktiivissa2008/98/EYTarkoitetutSivutuotteet", view), null, "cmc11", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 12 - Saostuneet fosfaattisuolat ja johdannaiset (struviitti)", "cmc12SaostuneetFosfaattisuolatJaJohdannaiset", view), null, "cmc12", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 13 - Termisesti hapetuksessa muodostuva aines tai johdannainen (tuhka ja kuona)", "cmc13TermisestiHapetuksessaMuodostuvaAinesTaiJohdannainen", view), null, "cmc13", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 14 - Pyrolyysissä ja kaasutuksessa muodostuva aines (biohiili)", "cmc14PyrolyysissaJaKaasutuksessaMuodostuvaAines", view), null, "cmc14", null, {
      type: 'cmcCheckbox',
    }),
    new Syote(new Teksti("CMC 15 - Puhtaat teollisessa prosessissa muodostuvat materiaalit/ainesosat", "cmc15PuhtaatTeollisessaProsessissaMuodostuvatMateriaalit/ainesosat", view), null, "cmc15", null, {
      type: 'cmcCheckbox',
    }),
  ];

  const createSyoteBlock = (teksti: Teksti, name: string): Syote => new Syote(teksti, null, name, null, {
        type: 'checkbox-level3',
      }, null,
      new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
        type: 'text',
      }, YhteenvetoTyyppi.VALUE_ONLY),
      ...pakkausRadio,
      ...cmcCheckboxList
    );

  const lopetaToimintaSyote = new Syote(new Teksti('Haluan lopettaa tämän toiminnan', 'haluanLopettaaToiminnan', view), null, 'lopetaToiminta', null, {type: 'lopetaToiminta'});

  const isMuutosIlmoitus = toimintotyyppi === "muutos";

  return new SyoteGroup(
    view,
    new Teksti("EU-toiminta (CE-merkityt lannoitevalmisteet)", "euToiminta"),
    null,
    null,
    YhteenvetoTyyppi.CHECKBOXES_WITH_OPTIONAL,

    new Syote(
      new Teksti("EU lannoitevalmisteen maahantuoja", "euLannoiteValmisteenTuonti"),
      null,
      "euLannoiteValmisteenTuonti",
      null,
      {yhteenvetoMaaraTeksti: new Teksti("Määrä", "maahantuotavaMaara", view), alakohdeToiminta: 'LANNEUMAAHANTUONTI', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_EU_TOIMINTA,
      lopetaToimintaSyote,
      new Syote(new Teksti("PFC 1 - Lannoiteet", "pfc1Lannoiteet", view), null, "pfc1Lannoite", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kiinteä orgaaninen lannoite", "kiinteaOrgaaninenLannoite", view), null, "kiinteaOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen lannoite", "nestemainenOrgaaninenLannoite", view), null, "nestemainenOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Kiinteä orgaaninen kivennäislannoite", "kiinteaOrgaaninenKivennaislannoite", view), null, "kiinteaOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen kivennäislannoite", "nestemainenOrgaaninenKivennaislannoite", view), null, "nestemainenOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen epäorgaaninen hivenravinnelannoite", "yksiravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "yksiravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen epäorgaaninen hivenravinnelannoite", "moniravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "moniravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY)
        ),
      ),

      new Syote(new Teksti("PFC 2 - Kalkitusaineet", "pfc2Kalkitusaineet", view), null, "pfc2Kalkitusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kalkitusaine", "kalkitusaine", view), null, "kalkitusaine", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 3 - Maanparannusaineet", "pfc3Maanparannusaineet", view), null, "pfc3Maanparannusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Orgaaninen maanparannusaine", "orgaaninenMaanparannusaine", view), null, "orgaaninenMaanparannusaine", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Epäorgaaninen maanparannusaine", "epaorgaaninenMaanparannusaine", view), null, "epaorgaaninenMaanparannusaine", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 4 - Kasvualustat", "pfc4Kasvualustat", view), null, "pfc4Kasvualustat", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kasvualusta", "kasvualusta", view), null, "kasvualusta", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 5 - Inhibiittorit", "pfc5Inhibiittorit", view), null, "pfc5Inhibiittori", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Nitrifikaatioinhibiittori", "nitrifikaatioinhibiittori", view), null, "nitrifikaatioinhibiittori", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Denitrifikaatioinhibiittori", "denitrifikaatioinhibiittori", view), null, "denitrifikaatioinhibiittori", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Ureaasi-Inhibiittori", "ureaasiInhibiittori", view), null, "ureaasiInhibiittori", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 6 - Kasvibiostimulantit", "pfc6Kasvibiostimulantit", view), null, "pfc6Kasvibiostimulantti", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Mikrobipohjainen kasvibiostimulantti", "mikrobipohjainenKasvibiostimulantti", view), null, "mikrobipohjainenKasvibiostimulantti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Ei-mikrobipohjainen kasvibiostimulantti", "eiMikrobipohjainenKasvibiostimulantti", view), null, "eiMikrobipohjainenKasvibiostimulantti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 7 - Mekaaniset seokset", "pfc7MekaanisetSeokset", view), null, "pfc7MekaanisetSeokset", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Lannoitevalmisteiden mekaaninen seos (blendi)", "lannoitevalmisteidenMekaaninenSeos", view), null, "lannoitevalmisteidenMekaaninenSeos", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "euLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),
    ),
    new Syote(
      new Teksti("EU lannoitevalmisteen viejä", "euLannoitevalmisteenVieja"),
      null,
      "euLannoitevalmisteenVieja",
      null,
      {yhteenvetoMaaraTeksti: new Teksti("Määrä", "maastavietavaMaara", view), alakohdeToiminta: 'LANNEUMAASTAVIENTI', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_EU_TOIMINTA,
      lopetaToimintaSyote,
      new Syote(new Teksti("PFC 1 - Lannoiteet", "pfc1Lannoiteet", view), null, "pfc1Lannoite", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kiinteä orgaaninen lannoite", "kiinteaOrgaaninenLannoite", view), null, "kiinteaOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen lannoite", "nestemainenOrgaaninenLannoite", view), null, "nestemainenOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Kiinteä orgaaninen kivennäislannoite", "kiinteaOrgaaninenKivennaislannoite", view), null, "kiinteaOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKivennaislannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen kivennäislannoite", "nestemainenOrgaaninenKivennaislannoite", view), null, "nestemainenOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKivennaislannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Yksiravinteinen epäorgaaninen hivenravinnelannoite", "yksiravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "yksiravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Moniravinteinen epäorgaaninen hivenravinnelannoite", "moniravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "moniravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY)
        ),
      ),

      new Syote(new Teksti("PFC 2 - Kalkitusaineet", "pfc2Kalkitusaineet", view), null, "pfc2Kalkitusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kalkitusaine", "kalkitusaine", view), null, "kalkitusaine", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKalkitusaineidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 3 - Maanparannusaineet", "pfc3Maanparannusaineet", view), null, "pfc3Maanparannusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Orgaaninen maanparannusaine", "orgaaninenMaanparannusaine", view), null, "orgaaninenMaanparannusaine", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienOrgaanistenMaanparannusaineidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Epäorgaaninen maanparannusaine", "epaorgaaninenMaanparannusaine", view), null, "epaorgaaninenMaanparannusaine", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienEpaorgaanistenMaanparannusaineidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Kasvualusta", "kasvualusta", view), null, "kasvualusta", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKasvualustojenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 4 - Kasvualustat", "pfc4Kasvualustat", view), null, "pfc4Kasvualustat", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kasvualusta", "kasvualusta", view), null, "kasvualustaVienti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKasvualustojenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 5 - Inhibiittorit", "pfc5Inhibiittorit", view), null, "pfc5Inhibiittori", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Nitrifikaatioinhibiittori", "nitrifikaatioinhibiittori", view), null, "nitrifikaatioinhibiittori", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Denitrifikaatioinhibiittori", "denitrifikaatioinhibiittori", view), null, "denitrifikaatioinhibiittori", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Ureaasi-Inhibiittori", "ureaasiInhibiittori", view), null, "ureaasiInhibiittori", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 6 - Kasvibiostimulantit", "pfc6Kasvibiostimulantit", view), null, "pfc6Kasvibiostimulantti", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Mikrobipohjainen kasvibiostimulantti", "mikrobipohjainenKasvibiostimulantti", view), null, "mikrobipohjainenKasvibiostimulantti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKasvibiostimulanttienMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
        new Syote(new Teksti("Ei-mikrobipohjainen kasvibiostimulantti", "eiMikrobipohjainenKasvibiostimulantti", view), null, "eiMikrobipohjainenKasvibiostimulantti", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienKasvibiostimulanttienMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),

      new Syote(new Teksti("PFC 7 - Mekaaniset seokset", "pfc7MekaanisetSeokset", view), null, "pfc7MekaanisetSeokset", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Lannoitevalmisteiden mekaaninen seos (blendi)", "lannoitevalmisteidenMekaaninenSeos", view), null, "lannoitevalmisteidenMekaaninenSeos", null, {
            type: 'checkbox-level3',
          }, null, new Syote(new Teksti("Ilmoita tuotavien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
        ),
      ),
    ),
    new Syote(
      new Teksti("EU lannoitevalmisteen jakelija", "euLannoitevalmisteenJakelija"),
      null,
      "euLannoitevalmisteenJakelija",
      null,
      {alakohdeToiminta: 'LANNEUJAKELU', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_EU_JAKELIJA,
      lopetaToimintaSyote,
      new Syote(new Teksti("PFC 1 - Lannoiteet", "pfc1Lannoiteet", view), null, "pfc1Lannoite", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kiinteä orgaaninen lannoite", "kiinteaOrgaaninenLannoite", view), null, "kiinteaOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen lannoite", "nestemainenOrgaaninenLannoite", view), null, "nestemainenOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Kiinteä orgaaninen kivennäislannoite", "kiinteaOrgaaninenKivennaislannoite", view), null, "kiinteaOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen kivennäislannoite", "nestemainenOrgaaninenKivennaislannoite", view), null, "nestemainenOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Yksiravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Moniravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Yksiravinteinen epäorgaaninen hivenravinnelannoite", "yksiravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "yksiravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Moniravinteinen epäorgaaninen hivenravinnelannoite", "moniravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "moniravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),

      new Syote(new Teksti("PFC 2 - Kalkitusaineet", "pfc2Kalkitusaineet", view), null, "pfc2Kalkitusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kalkitusaine", "kalkitusaine", view), null, "kalkitusaine", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),

      new Syote(new Teksti("PFC 3 - Maanparannusaineet", "pfc3Maanparannusaineet", view), null, "pfc3Maanparannusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Orgaaninen maanparannusaine", "orgaaninenMaanparannusaine", view), null, "orgaaninenMaanparannusaine", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Epäorgaaninen maanparannusaine", "epaorgaaninenMaanparannusaine", view), null, "epaorgaaninenMaanparannusaine", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Kasvualusta", "kasvualusta", view), null, "kasvualusta", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),

      new Syote(new Teksti("PFC 4 - Kasvualustat", "pfc4Kasvualustat", view), null, "pfc4Kasvualustat", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kasvualusta", "kasvualusta", view), null, "kasvualusta", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),

      new Syote(new Teksti("PFC 5 - Inhibiittorit", "pfc5Inhibiittorit", view), null, "pfc5Inhibiittori", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Nitrifikaatioinhibiittori", "nitrifikaatioinhibiittori", view), null, "nitrifikaatioinhibiittori", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Denitrifikaatioinhibiittori", "denitrifikaatioinhibiittori", view), null, "denitrifikaatioinhibiittori", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Ureaasi-Inhibiittori", "ureaasiInhibiittori", view), null, "ureaasiInhibiittori", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),

      new Syote(new Teksti("PFC 6 - Kasvibiostimulantit", "pfc6Kasvibiostimulantit", view), null, "pfc6Kasvibiostimulantti", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Mikrobipohjainen kasvibiostimulantti", "mikrobipohjainenKasvibiostimulantti", view), null, "mikrobipohjainenKasvibiostimulantti", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
        new Syote(new Teksti("Ei-mikrobipohjainen kasvibiostimulantti", "eiMikrobipohjainenKasvibiostimulantti", view), null, "eiMikrobipohjainenKasvibiostimulantti", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),

      new Syote(new Teksti("PFC 7 - Mekaaniset seokset", "pfc7MekaanisetSeokset", view), null, "pfc7MekaanisetSeokset", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Lannoitevalmisteiden mekaaninen seos (blendi)", "lannoitevalmisteidenMekaaninenSeos", view), null, "lannoitevalmisteidenMekaaninenSeos", null, {
            type: 'checkbox-level3',
          }, null,
          ...valmistusJaTuontimaarat
        ),
      ),
    ),


    new Syote(
      new Teksti("EU lannoitevalmisteen valmistaja tai valmistuttaja", "euLannoitevalmisteenValmistaja"),
      null,
      "euLannoitevalmisteenValmistaja",
      null,
      {alakohdeToiminta: 'LANNEUVALMISTUS', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_EU_TOIMINTA_VALMISTAJA_VALMISTUTTAJA,
      lopetaToimintaSyote,
      new Syote(new Teksti("PFC 1 - Lannoiteet", "pfc1Lannoiteet", view), null, "pfc1Lannoite", null, {
          type: 'pfcCheckbox',
        }, null,
        new Syote(new Teksti("Kiinteä orgaaninen lannoite", "kiinteaOrgaaninenLannoite", view), null, "kiinteaOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen lannoite", "nestemainenOrgaaninenLannoite", view), null, "nestemainenOrgaaninenLannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),
        new Syote(new Teksti("Kiinteä orgaaninen kivennäislannoite", "kiinteaOrgaaninenKivennaislannoite", view), null, "kiinteaOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
            parent: 'kiinteaOrgaaninenKivennaislannoiteEuValmistaja'
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),
        new Syote(new Teksti("Nestemäinen orgaaninen kivennäislannoite", "nestemainenOrgaaninenKivennaislannoite", view), null, "nestemainenOrgaaninenKivennaislannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),


        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Yksiravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Moniravinteinen kiinteä epäorgaaninen pääravinnelannoite, joka sisältää runsastyppistä ammoniumnitraattia", "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", view), null, "moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Yksiravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Moniravinteinen nestemäinen epäorgaaninen pääravinnelannoite", "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", view), null, "moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Yksiravinteinen epäorgaaninen hivenravinnelannoite", "yksiravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "yksiravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),

        new Syote(new Teksti("Moniravinteinen epäorgaaninen hivenravinnelannoite", "moniravinteinenEpaorgaaninenHivenravinnelannoite", view), null, "moniravinteinenEpaorgaaninenHivenravinnelannoite", null, {
            type: 'checkbox-level3',
          }, null,
          new Syote(new Teksti("Arvio lannoitteiden vuosittaisista valmistusmääristä", "lannotteidenVuosittainenMaara", view), null, "maara", null, {
            type: 'text',
          }, YhteenvetoTyyppi.VALUE_ONLY),
          ...pakkausRadio,
          ...cmcCheckboxList,
        ),
      ),
      new Syote(new Teksti("PFC 2 - Kalkitusaineet", "pfc2Kalkitusaineet", view), null, "pfc2Kalkitusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        createSyoteBlock(new Teksti("Kalkitusaine", "kalkitusaine", view), "kalkitusaine"),
      ),
      new Syote(new Teksti("PFC 3 - Maanparannusaineet", "pfc3Maanparannusaineet", view), null, "pfc3Maanparannusaine", null, {
          type: 'pfcCheckbox',
        }, null,
        createSyoteBlock(new Teksti("Orgaaninen maanparannusaine", "orgaaninenMaanparannusaine", view), "orgaaninenMaanparannusaine"),
        createSyoteBlock(new Teksti("Epäorgaaninen maanparannusaine", "epaorgaaninenMaanparannusaine", view), "epaorgaaninenMaanparannusaine"),
      ),
      new Syote(new Teksti("PFC 4 - Kasvualustat", "pfc4Kasvualustat", view), null, "pfc4Kasvualusta", null, {
          type: 'pfcCheckbox',
        }, null,
        createSyoteBlock(new Teksti("Kasvualusta", "kasvualusta", view), "kasvualusta"),
      ),
      new Syote(new Teksti("PFC 5 - Inhibiittorit", "pfc5Inhibiittorit", view), null, "pfc5Inhibiittori", null, {
          type: 'pfcCheckbox',
        }, null,
        createSyoteBlock(new Teksti("Nitrifikaatioinhibiittori", "nitrifikaatioinhibiittori", view), "nitrifikaatioinhibiittori"),
        createSyoteBlock(new Teksti("Denitrifikaatioinhibiittori", "denitrifikaatioinhibiittori", view), "denitrifikaatioinhibiittori"),
        createSyoteBlock(new Teksti("Ureaasi-Inhibiittori", "ureaasiInhibiittori", view), "ureaasiInhibiittori"),
      ),
      new Syote(new Teksti("PFC 6 - Kasvibiostimulantit", "pfc6Kasvibiostimulantit", view), null, "pfc6Kasvibiostimulantti", null, {
          type: 'pfcCheckbox',
        }, null,
        createSyoteBlock(new Teksti("Mikrobipohjainen kasvibiostimulantti", "mikrobipohjainenKasvibiostimulantti", view), "mikrobipohjainenKasvibiostimulantti"),
        createSyoteBlock(new Teksti("Ei-mikrobipohjainen kasvibiostimulantti", "eiMikrobipohjainenKasvibiostimulantti", view), "eiMikrobipohjainenKasvibiostimulantti"),
      ),
      new Syote(new Teksti("PFC 7 - Mekaaniset seokset", "pfc7MekaanisetSeokset", view), null, "pfc7MekaanisetSeokset", null, {
          type: 'pfcCheckbox',
        }, null,
        createSyoteBlock(new Teksti("Lannoitevalmisteiden mekaaninen seos (blendi)", "lannoitevalmisteidenMekaaninenSeos", view), "lannoitevalmisteidenMekaaninenSeos"),
      )
    )
  );
};
