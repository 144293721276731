

export type Toiminto = "keskeneraisenpoisto" | "vaihdamuokkaaja" | "muu";

/**
 * Luokka, jonka avulla keskitetysti päätetään, käytetäänkö lomakkeilla v1 vai v2 api-endpointia.
 * Kun kaikki endpointit ovat päivitetty, voidaan luokka poistaa.
 */
export class LomakeEndpointUtil {

  public static shouldUseV2Endpoint(asia: string, lomaketyyppiVersio: string | number): boolean {
    const v1Endpoints = new LomakeV1Endpoints();
    const versio = typeof lomaketyyppiVersio == "number" ? `v${lomaketyyppiVersio}` : lomaketyyppiVersio;
    const lomakeKey = `${asia}.${versio}`;
    return !v1Endpoints.endpoints.has(lomakeKey);
  }

  public static shouldUseV2ClientComponent(asia: string, toimintotyyppi: string, lomaketyyppiVersio?: string | number): boolean {
    if (("lannoiterekisteri".match(asia) || "kylvosiemen-markkinointi".match(asia)) && "lopetus".match(toimintotyyppi)) {
      return false;
    }
    const clientURLs = new LomakeV1ClientURLs();

    let lomakeKey = asia;
    if (lomaketyyppiVersio) {
      const versio = typeof lomaketyyppiVersio == "number" ? `v${lomaketyyppiVersio}` : lomaketyyppiVersio;
      lomakeKey = `${asia}.${versio}`
    }

    return !clientURLs.hasKey(lomakeKey);
  }

  public static resolveLomaketyyppiByAsia(asia: string): "ilmoitukset" | "hakemus" | "vuosiilmoitukset" {
    const hakemukset = new Set(["luomupoikkeus9c", "siemenpakkaamolupa"]);
    const vuosiilmoitukset = new Set(["rehuvuosiilmoitus", "lannoitevuosiilmoitus"]);
    if (hakemukset.has(asia)) {
      return "hakemus";
    }
    if (vuosiilmoitukset.has(asia)) {
      return "vuosiilmoitukset";
    }
    return "ilmoitukset";
  }

  public static resolveUrlRootByToimintotyyppi(toimintotyyppi: string): "ilmoitukset" | "hakemus" | "vuosiilmoitukset" {
    if (toimintotyyppi?.toLowerCase() === "vuosiilmoitus") {
      return "vuosiilmoitukset";
    }
    if (toimintotyyppi?.toLowerCase() === "hakemus") {
      return "hakemus";
    }
    return "ilmoitukset";
  }
}

class LomakeV1Endpoints {

  private _endpoints = new Set();

  get endpoints(): Set<any> {
    return this._endpoints;
  }

  constructor() {
    this.addV1Endpoint("elainlaboratoriolahete", "v1");
    this.addV1Endpoint("luomuhakemus", "v1");
    this.addV1Endpoint("luomuKasvivalvonta", "v1");
  }

  addV1Endpoint(asia: string, lomakeTyyppiVersio: "v1" | "v2") {
      const lomakeKey = `${asia}.${lomakeTyyppiVersio}`;
      this._endpoints.add(lomakeKey);
  }
}

class LomakeV1ClientURLs {

  private _clientURLKeysByAsiaAndLomaketyyppiVersio = new Set();
  private _clientURLKeysByAsia = new Set();

  hasKey(key: string): boolean {
    return this._clientURLKeysByAsia.has(key) ||
      this._clientURLKeysByAsiaAndLomaketyyppiVersio.has(key);
  }

  constructor() {
    this.addV1ClientURL('elainlaboratoriolahete', 'v1');
    this.addV1ClientURL('rehuvuosiilmoitus', 'v1');
    this.addV1ClientURL('lannoitevuosiilmoitus', 'v1');
  }


  addV1ClientURL(asia: string, lomakeTyyppiVersio: "v1" | "v2") {
    const lomakeKey = `${asia}.${lomakeTyyppiVersio}`;
    this._clientURLKeysByAsiaAndLomaketyyppiVersio.add(lomakeKey);
    this._clientURLKeysByAsia.add(asia);
  }
}
