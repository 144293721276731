import {Component} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {FieldControl, FieldGroup} from "ngx-fielding";

@Component({
  selector: 'rehutoiminta',
  template: `
    <ng-container [formGroup]="group">
      <div class="form-row">
      <div class="col-3">
        <touko-checkbox formControlName="valittu" [htmlId]="valittu.field.htmlId" [labelAttribute]="valittu.field.label"></touko-checkbox>
      </div>
      <div class="col">
        <touko-text-input *ngIf="arvo && valittu.value" formControlName="arvo" [htmlId]="arvo.field.htmlId" [labelAttribute]="arvo.field.label"></touko-text-input>
      </div>
      </div>
    </ng-container>
  `
})
export class RehutoimintaComponent {
  constructor(private controlContainer: ControlContainer) {
  }

  get group(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get valittu(): FieldControl {
    return this.group.get('valittu').asControl();
  }

  get arvo(): FieldControl | null {
    return this.group.get('arvo')?.asControl();
  }
}
