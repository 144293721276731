import {NgModule} from "@angular/core";
import {YhteenvetoComponent} from "./yhteenveto.component";
import {YhteenvetoItemComponent} from "./yhteenvet-item/yhteenveto-item.component";
import {YhteenvetoService} from "./yhteenveto.service";
import {CommonModule} from "@angular/common";
import {AllekirjoitusComponent} from "./allekirjoitus.component";
import {LomaketiedotComponent} from "./lomaketiedot.component";
import {AttributeModule} from "../../tiira/attribute.module";
import {YhteenvetoVertailuComponent} from "./yhteenveto-vertailu/yhteenveto-vertailu.component";
import {UtilsModule} from "../../utils/utils.module";
import {YhteenvetoContainerComponent} from "./yhteenveto-container.component";
import {LannoiteYhteenvetoModule} from "./lannoiterekisteri-components/lannoite-yhteenveto.module";
import {YhteenvetoComponentHostDirective} from "./yhteenveto-component-host.directive";

@NgModule({
  declarations: [
    AllekirjoitusComponent,
    LomaketiedotComponent,
    YhteenvetoItemComponent,
    YhteenvetoComponent,
    YhteenvetoVertailuComponent,
    YhteenvetoComponentHostDirective,
    YhteenvetoContainerComponent
  ],
  imports: [
    CommonModule,
    AttributeModule,
    UtilsModule,
    LannoiteYhteenvetoModule
  ],
  exports: [
    AllekirjoitusComponent,
    LomaketiedotComponent,
    YhteenvetoComponent,
    YhteenvetoVertailuComponent,
  ],
  providers: [
    YhteenvetoService,
  ]
})
export class YhteenvetoModule {

}
