import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {LomakeService} from "../touko-lomake.service";
import {Router} from "@angular/router";
import {Teksti} from "../../utils/teksti";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AsiakasResponse, LomakeKasittely, LomakeResponse, LomakeTila} from "../touko-lomake-utils";
import {ToukoValidation} from "../validators/validators";
import {AccountService} from "../../account/account.service";
import {NgbModal, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../modaalit/modal-utils";
import {Subscription} from "rxjs/internal/Subscription";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 31/01/2018.
 */

@Component({
  selector: "virkailija-tiedot",
  templateUrl: "./touko-virkailija-tiedot.component.html"
})
@AutoUnsubscribe
export class VirkailijaTiedotComponent {

  @Output() onHyvaksy: EventEmitter<LomakeKasittely> = new EventEmitter<LomakeKasittely>();

  lomake: LomakeResponse;
  show = false;

  isKasittelyssa = false;
  isViimeksiMuokannut = false;
  muokannutToimija = null;
  asiakkaat: AsiakasResponse[] = [];

  kasittelyForm: FormGroup;

  virheSanomat = {
    required: new Teksti("Valitse päätös", "paatosPakollinen", "lomakeYleinen"),
    lisatieto: new Teksti("Lisätieto on pakollinen", "lisatietoPakollinen", "lomakeYleinen")
  };


  subs: Subscription[] = [];
  showSendElmoCheckbox: boolean;

  @ViewChild('asiakasElmoidInput') asiakasElmoidInput: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  resultFormatter = (x: AsiakasResponse) => `${x.elmoid}, ${x.hakunimi}`;

  constructor(private readonly lomakeService: LomakeService,
              private readonly accountService: AccountService,
              private readonly router: Router,
              private readonly formBuilder: FormBuilder,
              private readonly modalService: NgbModal) {

    if (accountService.isViranomainen()) {
      this.subs.push(this.lomakeService.currentLomake$.subscribe(val => {
        if (val) {
          this.lomake = val;
          this.isViimeksiMuokannut = val.viimeksiMuokannut;
          this.muokannutToimija = val.muokannutToimija;

          this.show = this.getShowVirkailijaView(this.lomake);

          this.isKasittelyssa = LomakeTila[val.tila] === LomakeTila.KASITTELYSSA;

          this.createForm(this.lomake.lahettajaAsiakasElmoid, this.lomake.lahettajaValvontakohde);

          if (this.lomake.lahettajaAsiointitunnus) {
            this.lomakeService.getAsiakkaat(this.lomake.lahettajaAsiointitunnus)
              .then(r => this.asiakkaat = r);
          }
        }
      }));
    }

    this.searchAsiakas = this.searchAsiakas.bind(this);
  }

  createForm(asiakasid: string, valvontakohde: string) {
    this.kasittelyForm = this.formBuilder.group({
      asiakasid,
      valvontakohde,
      paatos: [null, Validators.required],
      lisatieto: null,
      sendMail: true,
      sendToElmo: true
    }, {validators: ToukoValidation.lisatietoWhenRequired});
  }

  searchAsiakas(text$: Observable<string>) {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.asiakasElmoidInput.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.asiakkaat :
        this.asiakkaat.filter(
          asiakas => this.resultFormatter(asiakas).toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  }

  selectItem(event) {
    this.kasittelyForm.get('asiakasid').setValue(event.item.elmoid);
    event.preventDefault();
  }

  getShowVirkailijaView(lomake: LomakeResponse) {
    let show = lomake.versio === null &&
      (lomake.tila === LomakeTila.KASITTELYSSA || lomake.tila === LomakeTila.ODOTTAA_KASITTELYA);

    // Luomun erikoiskäsittely
    if (show && this.accountService.getCurrentAccount().toimiala === 'LUOMU') {
      show = this.accountService.isElyViranomainen();
    }
    return show;
  }

  muokkaaViranomaisena() {
    const haeLomakeFn = LomakeEndpointUtil.shouldUseV2Endpoint(this.lomake.asia, this.lomake.lomaketyyppiVersio) ?
      this.lomakeService.getLomakeV2Viranomainen(this.lomake.id, this.lomake.asia, this.lomake.toimintotyyppi) :
      this.lomakeService.getLomake(this.lomake.id);
    const clientVersion = LomakeEndpointUtil.shouldUseV2ClientComponent(this.lomake.asia, this.lomake.toimintotyyppi.toLowerCase(), this.lomake.lomaketyyppiVersio) ? 'v2' : 'v1';
    haeLomakeFn
      .then(r => {
        this.router.navigate(['/asiointi/lomake', r.toimintotyyppi.toLowerCase(), r.asia, clientVersion, this.lomake.id, 'jatka', 'sivu', 0]);
      });
  }

  otaKasittelyyn() {
    const okFn = () => this.lomakeService.updateViimeksiMuokannutViranomainen(this.lomake.id);

    if (!this.isViimeksiMuokannut && this.isKasittelyssa) {
      const modalRef = this.modalService.open(ModalSimpleComponent);
      modalRef.componentInstance.modalTiedot = new ModalData(
        new Teksti("Otsikko", "modalHaluatkoKasitella", "sivupalkki"),
        new Teksti("Kysymys", "modalLomakkenMuokkaaja", "sivupalkki"),
        new Teksti("Kyllä", "kylla", "teksti"),
        okFn
      );
    } else {
      okFn();
    }
  }

  getVirheet() {
    const virheet = [];
    const lisatieto = 'lisatieto';
    const required = 'required';

    if (this.kasittelyForm.getError(required, ['paatos'])) {
      virheet.push(this.virheSanomat[required]);
    }
    if (this.kasittelyForm.getError(lisatieto)) {
      virheet.push(this.virheSanomat[lisatieto]);
    }
    return virheet;
  }

  /**
   * Käynnistää lomakkeen yhteenvedon muodostaminen ja päätöksen tekemisen.
   * Kutsu menee seuraava reittiä:
   * VirkalijatiedotComponent.submitKasittely -> TarkastusComponent.lahetaYhteenvetoJaKasittely ->
   * VirkailijatiedotComponent.kasitteleLomake
   *
   * @param form Lomake
   */
  submitKasittely(form: FormGroup) {
    if (form.valid) {
      const paatos = LomakeTila[form.value['paatos']] as LomakeTila;
      const lomakeKasittely = new LomakeKasittely(
        this.lomake.id,
        paatos,
        form.value?.lisatieto,
        null,
        null,
        form.value?.asiakasId,
        form.value?.sendMail,
        form.value?.sendToElmo);
      this.onHyvaksy.emit(lomakeKasittely);
    }
  }

  showSendToElmoOption(showSendElmo: boolean): void {
    this.showSendElmoCheckbox = showSendElmo;
  }
}
