import {SyoteGroup} from "../syote/syote-group";
import {Teksti} from "../../utils/teksti";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {Syote} from "../syote/syote";
import {ValidatorOptions, YhteyshenkiloValue} from "../touko-lomake-utils";
import {Account} from "../../account/account";
import {RehuValidators} from "../validators/rehu-validators";
import {keyOrderFn, LomakeData, LomakeDataContent} from "../lomake-data";

/**
 * Created by Seppo on 23/02/2018.
 */


export class RehuvalvontaData extends LomakeData {

  static lomakeTitle = new Teksti("Ilmoitus rekisteröintiä varten", "rehuvalvontaIlmoitus", "rehuvalvonta");

  static getKeyOrder(): string[] {
    return keyOrderFn(RehuvalvontaData.getData);
  }

  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    const view = RehuvalvontaData.lomakeTitle.view;
    const yhValidators = new ValidatorOptions();

    yhValidators.validators = [RehuValidators.onlyOneValvontakohteenYhteyshenkilo];
    yhValidators.errorMsgs = {
      [RehuValidators.ONLY_ONE_VK_YHTEYSHENKILO]: new Teksti("Täytä puuttuvat kentät", "errYhteyshenkiloRooli", "lomakeYleinen")
    };

    return {
      perustiedot: RehuvalvontaData.getPerustiedot(view, account),
      laskutustiedot: RehuvalvontaData.getLaskutustiedot(view, account),
      yhteyshenkilot: RehuvalvontaData.getYhteyshenkilot(view, account, yhValidators, YhteyshenkiloValue.VK_YH.value),
      toiminnanAloitus: RehuvalvontaData.getToiminnanAloitus(view, toimintotyyppi),
      sahkoinenAsiointi: RehuvalvontaData.getSahkoinenAsiointi(view, toimintotyyppi),
      rekisteroitavatToiminnat: new SyoteGroup(
        view,
        new Teksti("Toiminnat", "toiminnat"),
        RehuValidators.atLeastOneValittuTrue,
        {[RehuValidators.AT_LEAST_ONE_VALITTU_TRUE]: new Teksti("Valitse vähintään yksi", "vahintaanYksi")},
        YhteenvetoTyyppi.REHU_LABEL_TEXT,

        new Syote(new Teksti("Lisäaineiden valmistaja (A)", "lisaaineidenValmistaja"), null),
        new Syote(new Teksti("Rehuaineiden valmistaja (B)",  "rehuaineidenValmistaja"), null),
        new Syote(new Teksti("Rehuseoksen valmistaja (C)", "rehuseoksenValmistaja"), null),
        new Syote(new Teksti("Erityisravinnoksi tarkoitettujen rehujen valmistaja (L)", "erityisRehunValmistaja"), null),
        new Syote(new Teksti("Esiseosten valmistaja (P)", "esiseostenValmistaja"), null),
        new Syote(new Teksti("Rahtisekoittaja (D)", "rahtisekoittaja"), null),
        new Syote(new Teksti("Puhdistuskäsittelylaitos (K)", "puhdiskasittelylaitos"), null),
        new Syote(new Teksti("Vähittäiskauppa (H1)", "vahittaiskauppa"), null),
        new Syote(new Teksti("Tukkukauppa (H2)", "tukkukauppa"), null),
        new Syote(new Teksti("Sisämarkkinatuoja (H3)", "sisamarkkinatuoja"), null),
        new Syote(new Teksti("Muu markkinoille saattaja (H4)", "muuMarkkinoilleSaattaja"), null),
        new Syote(new Teksti("Maahantuoja, kolmasmaaedustaja (M)", "maahantuojaKolmasMaa"), null),
        new Syote(new Teksti("Viejä (N)", "vieja"), null),
        new Syote(new Teksti("Varastointiliike, irtorehu (V4)", "irtorehunVarastointiliike"), null),
        new Syote(new Teksti("Varastointiliike, pakattu rehu (V5)", "pakatunRehunVarastointiliike"), null),
        new Syote(new Teksti("Kuljetusliike, irtorehu (W4)", "irtorehunKuljetusliike"), null),
        new Syote(new Teksti("Kuljetusliike, pakattu rehu (W5)", "pakatunRehunKuljetusliike"), null),
      ),

      yhteisetKysymykset: new SyoteGroup(
        view,
        new Teksti("Tarkemmat kysymykset toimintoihin ja tuotteisiin liittyen", "tarkemmatYleisKys"),
        null,
        null,
        YhteenvetoTyyppi.REHU_YHTEISETKYSYMYKSET,

        new Syote(new Teksti("Arvio rehun/rehujen vuosittaisista valmistusmääristä, kg", "arvioRehunMaarasta"), null),
        new Syote(new Teksti("Valmistettujen rehuseosten lukumäärä", "valmistettujenRehuSeostenLkm"), null),
        new Syote(new Teksti("Rehun valmistus vain omaan käyttöön", "rehunValmistusOmaan"), null),
        new Syote(new Teksti("Valmistaako yritys myös elintarvikkeita", "valmistaakoElintarvikkeita"), null),
        new Syote(new Teksti("Rehun valmistus on ainoastaan pakkaamista", "valmistusVainPakkaamista"), null)
      ),

      tuoteTyypit: new SyoteGroup(
        view,
        new Teksti("Tuotetyypit", "tuotetyypit"),
        RehuValidators.atLeastOneTrue,
        {[RehuValidators.AT_LEAST_ONE_TRUE]: new Teksti("Valitse vähintään yksi", "vahintaanYksi")},
        YhteenvetoTyyppi.LABEL_ONLY,

        new Syote(new Teksti("Lemmikkieläinten rehut. Myös luonnon lintujen ruuat (E)", "lemmikkielaintenRuuat"), null),
        new Syote(new Teksti("Sivutuotteet ja johdetut tuotteet; luokka 2; sivutuoteasetuksen 1069/2009 mukainen luokitus (G2)",
          "sivutuoteLuokka2"), null),
        new Syote(new Teksti("Sivutuotteet ja johdetut tuotteet; luokka 3; sivutuoteasetuksen 1069/2009 mukainen luokitus (G3)",
          "sivutuoteLuokka3"), null),
        new Syote(new Teksti(
          "Kalajauho ja kalajauhoa sisältävät rehut, myös eläinperäinen dikalsium- ja trikalsiumfosfaatti, verituotteet, verijauho (J)",
          "kalajauhoJaMuutElainperaiset"),
          null),
        new Syote(new Teksti("Elintarviketuotantoeläinten rehut (Q)", "elintarvikeTuotantoRehut"), null),
        new Syote(new Teksti("Turkiseläinten rehut (S)", "turkiselainTuotantoRehut"), null),
        new Syote(new Teksti("Lääkerehut (Z)", "laakerehut"), null)
      ),

      laitostenLisatiedot: new SyoteGroup(
        view,
        new Teksti("Lisätietoja asetuksen (EU) N:o 225/2012 mukaan hyväksyttävistä laitoksista",
          "lisatietojaHyvaksyttavistaLaitoksista"),
        null,
        null,
        YhteenvetoTyyppi.LABEL_ONLY,

        new Syote(new Teksti("Raakakasviöljyn jalostaja", "raakakasvioljy"), null),
        new Syote(new Teksti("Rasvahappojen öljykemiallinen valmistaja", "rasvahapot"), null),
        new Syote(new Teksti("Biodieselin valmistaja", "biodiesel"), null),
        new Syote(new Teksti("Rasvojen sekoittaja", "rasvojenSekoittaja"), null)
      ),

      sivutuoteAsetuksenMukainenHyvaksynta: new SyoteGroup(
        view,
        new Teksti("Sivutuoteasetuksen mukaista hyväksyntää edellyttävät toiminnat", "sivutuoteHyv"),
        RehuValidators.rehuLiite,
        {[RehuValidators.REHU_LIITE]: new Teksti("Liitteeksi on laitettava vähintään alustava luonnos HACCP-perusteisesta omavalvontasuunnitelmasta", "liitePuuttuu")},
        YhteenvetoTyyppi.LABEL_OR_ATTACHMENT,

        new Syote(new Teksti("Käsittelylaitos", "kasittelylaitos"), null),
        new Syote(new Teksti("Lemmikkieläinten ruokia valmistava laitos",  "lemmikkielaintenRuokiaValmistavaLaitos"),
          null),
        new Syote(new Teksti("Hakemuksen liitteet", "hakemuksenLiitteet"), null)
      ),

      sivutuoteAsetuksenMukainenRekisterointi: new SyoteGroup(
        view,
        new Teksti("Sivutuoteasetuksen mukaista rekisteröintiä edellyttävät toiminnat", "sivutuoteRek"),
        null,
        null,
        YhteenvetoTyyppi.LABEL_ONLY,

        new Syote(new Teksti("Rehusekoittamo", "rehusekoittamo"), null),
        new Syote(new Teksti("Keräyskeskus", "kerayskeskus"), null),
      ),

      rehunJaToiminnanLisatiedot: new SyoteGroup(
        view,
        new Teksti("Lisätietoja rehuista ja toiminnasta", "lisatietojaRehuistaJaToiminnasta"),
        null,
        {
          [RehuValidators.REQUIRED]: new Teksti("Täytä lisätietokenttä", "errTaLisatiedot")
        },
        YhteenvetoTyyppi.LABEL_AND_TEXT,

        new Syote(new Teksti("Kirjoita vapaamuotoisesti lisätiedot", "rehunJaToiminnanVapaamuotoisetLisatiedot"), null),
      ),

      laadunohjaus: new SyoteGroup(
        view,
        new Teksti("Laadunohjaus", "laadunohjausTitle"),
        [RehuValidators.detailsNotEmpty],
        {
          [RehuValidators.DETAILS_NOT_EMPTY]: new Teksti("Täytä lisätietokenttä", "errTaLisatiedot")
        },
        YhteenvetoTyyppi.CHECKBOXES_WITH_OPTIONAL,
        new Syote(new Teksti("EFMC yhteisön hyvän käytännön ohje EU:n teollisten rehuseosten ja esiseosten valmistajille", "ReVFeedQSEFMC")),
        new Syote(new Teksti("FAMI-QS yhteisön hyvän käytännön ohje rehun lisäaineiden ja esiseosten valmistajille", "ReVFeedQSFAMIQS")),
        new Syote(new Teksti("FEDIAF hyvän käytännön ohje turvallisten lemmikkieläinruokien valmistamiseksi", "ReVFeedQSFEDIAF")),
        new Syote(new Teksti("FEDIOL eurooppalainen hyvän käytännön ohje rehuaineiden teollisille valmistajille", "ReVFeedQSFEDIOL")),
        new Syote(new Teksti("FSSC 22000 -elintarviketurvallisuuden sertifiointijärjestelmä", "ReVFeedQSFSSC22000")),
        new Syote(new Teksti("GMP+ sertifiointijärjestelmä", "ReVFeedQSGMP")),
        new Syote(new Teksti("ISO 22000 -standardi", "ReVFeedQSISO22000")),
        new Syote(new Teksti("ISO 9001 -standardi", "ReVFeedQSISO9001")),
        new Syote(new Teksti("Muu, mikä?", "ReVFeedQSOther"), [null, RehuValidators.detailsNotEmpty])
      ),

      verkkokaupat: RehuvalvontaData.getVerkkokaupat(view, account)
    };
  }
}
