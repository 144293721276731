import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {Valvontakohde} from "../../valvontakohde/valvontakohde";
import {ControlContainer, FormControl, FormGroup} from "@angular/forms";
import {AttributeService} from "../../tiira/attribute.service";
import {AccountModalComponent} from "../../account/toimipaikka-asetukset/account-modal.component";

@Component({
  selector: 'valvontakohde-typeahead',
  template: `
    <div class="input-group" [formGroup]="formGroup">
      <input #typeaheadInput="ngbTypeahead"
             [id]="htmlId"
             [ngbTypeahead]="search"
             [formControlName]="controlName"
             (selectItem)="select($event.item)"
             (focusout)="close()"
             [resultFormatter]="getValvontakohdeView"
             [inputFormatter]="getValvontakohdeResult"
             [placeholder]="placeholder"
             [editable]="false"
             (focus)="focus$.next($event.target.value)"
             (click)="click$.next($event.target.value)"
             class="form-control z-index-0"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary"
                id="valvontakohde-tyhjenna"
                type="button" (click)="select(null)">
          <em class="fas fa-times"></em>
        </button>
      </div>
    </div>
  `,
  styleUrls: ["valvontakohde-typeahead.component.scss"]
})
export class ValvontakohdeTypeaheadComponent implements OnInit, OnChanges {
  @Input() valvontakohteet: Valvontakohde[];
  @Input() htmlId: string;
  @Input() controlName: string;
  @Input() placeholder = '';
  @Output() selectValvontakohde: EventEmitter<ValvontakohdeSelect> = new EventEmitter<ValvontakohdeSelect>();
  @Output() createUusiIlmoitus: EventEmitter<AccountModalComponent> = new EventEmitter<AccountModalComponent>();
  @ViewChild('typeaheadInput') typeaheadInput: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  fieldValue: Valvontakohde;
  formGroup: FormGroup;
  formControl: FormControl;

  prevValidValvontakohde: Valvontakohde = null;
  pakkaamoteksti: string;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeaheadInput.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.valvontakohteet :
        this.valvontakohteet.filter(
          vk => this.getValvontakohdeView(vk).toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  constructor(private readonly attributeService: AttributeService,
              private readonly controlContainer: ControlContainer) {
    this.getValvontakohdeView = this.getValvontakohdeView.bind(this);
  }

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
    this.formControl = this.formGroup.get(this.controlName) as FormControl;
    this.pakkaamoteksti = this.attributeService.simpleTranslate("kayttajatili", "pakkaamo");

    this.formControl.valueChanges.subscribe(val => {
      if (val || val === null) {
        this.prevValidValvontakohde = val;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.valvontakohteet) {
      this.valvontakohteet = changes.valvontakohteet.currentValue;
    }
  }

  select(valvontakohde: Valvontakohde, saveValvontakohde = true) {
    this.selectValvontakohde.emit({valvontakohde, saveValvontakohde});
    this.formControl.setValue(valvontakohde);
  }

  close() {
    if (!this.formControl.value && this.prevValidValvontakohde && this.valvontakohteet.find(v => v.elmoId === this.prevValidValvontakohde.elmoId)) {
      this.formControl.setValue(this.prevValidValvontakohde);
    }
  }


  /**
   * Palauttaa valvontakohteen toimipaikan lisätiedoilla (pakkaamo id, postinumero, postitoimipaikka).
   *
   * @param valvontakohde
   * @returns
   */
  getValvontakohdeView(valvontakohde: Valvontakohde) {

    const lisainfot = {
      pakkaamo: valvontakohde.pakkaamoElmoid ? `${this.pakkaamoteksti}: ${valvontakohde.pakkaamoElmoid}` : "",
      rekisterinumero: valvontakohde.rekisterinumero || "",
      osoite: `${valvontakohde.toimipaikka.postinumero}, ${valvontakohde.toimipaikka.postitoimipaikka}`
    }

    const pakkaamoJaOsoite = [lisainfot.pakkaamo, lisainfot.osoite].filter(info => info !== "").join(", ")

    const nimi = valvontakohde.toimipaikka.nimi_fi;
    return `${valvontakohde.elmoId} ${nimi} (${pakkaamoJaOsoite}) ${lisainfot.rekisterinumero}`.trim();
  }

  getValvontakohdeResult(valvontakohde: Valvontakohde) {
    return `${valvontakohde.elmoId} ${valvontakohde.toimipaikka.nimi_fi}`;
  }

  createUusiToimipaikka() {
    this.createUusiIlmoitus.emit();
  }
}
class ValvontakohdeSelect {
  valvontakohde: Valvontakohde;
  saveValvontakohde: boolean;
}
