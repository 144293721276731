import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Teksti} from "../utils/teksti";

@Injectable()
export class LoaderService {
  private _isLoading = new BehaviorSubject<LoadEvent>({status: LoadStatus.NOT_STARTED});
  isLoading$ = this._isLoading.asObservable();

  private timeout = 30000;
  private timeoutClock;

  private loadingRequests = 0;

  startLoadingAnimation(loadingMessage?: string | Teksti) {
    this.loadingRequests++;

    this._isLoading.next({
      status: LoadStatus.IS_LOADING,
      message: loadingMessage
    });

    if (this.timeoutClock) {
      clearTimeout(this.timeoutClock);
    }

    this.timeoutClock = setTimeout(() => {
        this._isLoading.next({status: LoadStatus.TIMEOUT_ERROR});
    }, this.timeout);
  }

  stopLoadingAnimation() {
    this.loadingRequests--;

    if (this.loadingRequests < 0) {
      this.loadingRequests = 0;
      return;
    }

    if (this.loadingRequests === 0 && this.timeoutClock) {
      clearTimeout(this.timeoutClock);
      this._isLoading.next({status: LoadStatus.LOAD_ENDED});
    }
  }

  forceStopLoadingAnimation() {
    this.loadingRequests = 1;
    this.stopLoadingAnimation();
  }
}

export enum LoadStatus {
  NOT_STARTED = 'NOT_STARTED',
  IS_LOADING = 'IS_LOADING',
  LOAD_ENDED = 'LOAD_ENDED',
  TIMEOUT_ERROR = 'TIMEOUT_ERROR'
}

class LoadEvent {
  status: LoadStatus;
  message?: string | Teksti;
}
