export class Translations {

  static labels = {
    'Lähetteen tunnusFi': 'Lähetteen tunnus',
    'laheteTunnusSv': 'Remisskod',

    'sahkoinenAsiointiFi': 'Eläintautitutkimusten/Laboratoriotutkimusten tulosten sähköinen toimitus',
    'sahkoinenAsiointiSv': 'Elektronisk leverans av resultaten från djursjukdomsundersökningar',
    'sahkoinenAsiointiRadioFi': 'Haluan tutkimustodistuksen ainoastaan sähköiseen asiointipalveluun Toukoon',
    'sahkoinenAsiointiCheckSv': 'Jag vill ha analyscertifikatet endast till Touko.',
    'sahkopostiRadioFi': 'Haluan tutkimustodistuksen Toukon lisäksi turvaviestinä sähköpostitse',
    'sahkopostiRadioSv': 'Jag vill ha analyscertifikatet per skyddat e-postmeddelande och även Touko.',
    'postiRadioFi': 'Haluan tutkimustodistuksen Toukon lisäksi postitse',
    'postiRadioSv': 'Jag vill ha analyscertifikatet per post och även Touko.',

    'tilaajaFi': 'Tilaaja',
    'tilaajaSv': 'Beställare',
    'tilaajaOnFi': 'Tilaaja on',
    'tilaajaOnSv': 'Beställare är',

    'errMaksajaFi': 'Kaikki tutkimukset eivät ole maksullisia, mutta lähetteellä tulee olla valittuna maksaja. Jos tilaat maksuttomia tutkimuksia, laskua ei tule. Lisätietoja tutkimuksista ja niiden hinnoista laboratoriokäsikirjassa.',
    'errMaksajaSv':  'Alla undersökningar är inte avgiftsbelagda, men remissen måste ha en betalare. Om du beställer avgiftsfria undersökningar, kommer det ingen faktura. Mer information om undersökningar och deras priser finns i laboratoriehandboken.',

    'asiakasOmistajaFi': 'Omistaja',
    'asiakasOmistajaSv': 'Ägare',
    'asiakasElainlaakariFi': 'Hoitava eläinlääkäri',
    'asiakasElainlaakariSv': 'Behandlande veterinär',
    'asiakasMuuFi': 'Muu',
    'asiakasMuuSv': 'Annan',

    'asiakasHenkilotiedotFi': 'Henkilötiedot',
    'asiakasHenkilotiedotSv': 'Personuppgifter',
    'asiakasElainlaakariTunnusFi': 'Eläinlääkäritunnus',
    'asiakasElainlaakariTunnusSv': 'Veterinärnummer',
    'asiakasEtunimiFi': 'Etunimi',
    'asiakasEtunimiSv': 'Förnamn',
    'asiakasSukunimiFi': 'Sukunimi',
    'asiakasSukunimiSv': 'Efternamn',
    'asiakasYritysFi': 'Yrityksen nimi',
    'asiakasYritysSv': 'Företag',
    'asiakasYtunnusFi': 'Y-tunnus',
    'asiakasYtunnusSv': 'FO-nummer',

    'asiakasPuhelinFi': 'Puhelin',
    'asiakasPuhelinSv': 'Telefon',
    'asiakasOsoiteFi': 'Lähiosoite',
    'asiakasOsoiteSv': 'Utdelningsadress',
    'asiakasPostinumeroFi': 'Postinumero',
    'asiakasPostinumeroSv': 'Postnummer',
    'asiakasPostitoimipaikkaFi': 'Postitoimipaikka',
    'asiakasPostitoimipaikkaSv': 'Postanstalt',
    'asiakasEmailFi': 'Sähköpostiosoite',
    'asiakasEmailSv': 'E-postadress',

    'yhteyshenkiloFi': 'Yhteyshenkilö',
    'yhteyshenkiloSv': 'Kontaktperson',
    'erillinenYhteyshenkiloFi': 'Erillinen yhteyshenkilö',
    'erillinenYhteyshenkiloSv':  'Kontaktperson',
    'yhteyshenkiloEtunimiFi': 'Etunimi',
    'yhteyshenkiloEtunimiSv': 'Förnamn',
    'yhteyshenkiloSukunimiFi': 'Sukunimi',
    'yhteyshenkiloSukunimiSv': 'Efternamn',
    'yhteyshenkiloPuhFi': 'Puhelin',
    'yhteyshenkiloPuhSv': 'Telefon',
    'yhteyshenkiloSahkopostiFi': 'Sähköposti',
    'yhteyshenkiloSahkopostiSv': 'E-postadress',

    'lisatiedotFi': 'Lisätiedot',
    'lisatiedotSv':  'Tilläggsinformation',
    'asiakasToimitusEmailFi': 'Tutkimustodistus toimitetaan suojattuna sähköpostina',
    'asiakasToimitusEmailSv': 'Undersökningsremissen sänds per E-post',
    'asiakasToimitusPostiFi': 'Tutkimustodistus toimitetaan postitse',
    'asiakasToimitusPostiSv': 'Analyscertifikatet levereras per post',
    'asiakasTutkimustodistusFi': 'Saa tutkimustodistuksen',
    'asiakasTutkimustodistusSv': 'Mottagare av undersökningsremissen',
    'asiakasMaksajaFi': 'Tutkimuksen maksaja',
    'asiakasMaksajaSv': 'Fakturans mottagare',

    'asiakasTiedoksisaajaFi': 'Tiedoksisaaja',
    'asiakasTiedoksisaajaSv': 'Kopiemottagare',

    'elainlajiryhmaFi': 'Eläinlajiryhmä',
    'elainlajiryhmaSv': 'Djurgrupp',

    'elainlajiFilterFi': 'Suodata eläinlaji',
    'elainlajiFilterSv':  'Filtrera djurarter',

    'elainlajiFi': 'Eläinlaji',
    'elainlajiSv': 'Djurart',

    'taustakysymyksetFi': 'Taustakysymykset',
    'taustakysymyksetSv': 'Bakgrundsinformation',

    'tutkimusFilterFi': 'Suodata tutkimukset',
    'tutkimusFilterSv': 'Filtrera undersökningar',

    'tutkimusFilterPlaceholderFi': 'Suodata tutkimuksen nimellä',
    'tutkimusFilterPlaceholderSv': 'Filtrera efter studienamn',

    'tutkimusalaFi': 'Valitse tutkimusala',
    'tutkimusalaSv': 'Välj forskningsområde',

    'tutkimuksetFi': 'Tutkimukset',
    'tutkimuksetSv': 'Undersökningar',
    'tutkimusFi': 'Tutkimus',
    'tutkimusSv': 'Undersökning',

    'naytetiedotFi': 'Näytetiedot',
    'naytetiedotSv': 'Provinformation',
    'muuNaytemateriaaliFi': 'Muu näytemateriaali',
    'muuNaytemateriaaliSv': 'Annat provmaterial',

    'naytenumeroFi': 'Näytetunnus',
    'naytenumeroSv': 'Provmärke',
    'naytemateriaaliFi': 'Näytemateriaali',
    'naytemateriaaliSv': 'Provmaterial',
    'tutkimussyyFi': 'Tutkimussyy',
    'tutkimussyySv': 'Orsak till undersökning',
    'naytteenottoPvmFi': 'Näytteenottopvm',
    'naytteenottoPvmSv': 'Datum för provtagning',

    'automTiedoksisaajaFi': 'Automaattinen tiedoksisaaja',
    'automTiedoksisaajaSv': 'Automatisk kopiemottagare',

    'toimitusosoiteFi': 'Toimitusosoite',
    'toimitusosoiteSv': 'Leveransadress',

    'tausta1TaustatiedotFi': 'Taustatiedot (tutkimuksen syy, oireet, kesto, taudinkuvan kehittyminen, lääkitys, laboratoriotutkimukset, sairaiden lkm, muutokset olosuhteissa tai ruokinnassa jne.)',
    'tausta1TaustatiedotSv': 'Bakgrundsinformation (orsak till undersökningen, symtom, varaktighet, sjukdomsförloppet, medicinering, laboratorieundersökningar, antal sjuka, förändringar i miljöförhållanden eller utfodring mm.)',

    'tausta3RokotustiedotFi': 'Rokotustiedot',
    'tausta3RokotustiedotSv': 'Vaccinationshistoria',

    'tausta4OtsikkoFi': 'Pääasialliset oireet',
    'tausta4OtsikkoSv': 'Symptom: ',
    'tausta4HengitystieoireetFi': 'Hengitystieoireet',
    'tausta4HengitystieoireetSv': 'Symptom i andningsvägarna',
    'tausta4RipuliFi': 'Ripuli',
    'tausta4RipuliSv': 'Diarré',
    'tausta4NiveloireitaFi': 'Niveloireita',
    'tausta4NiveloireitaSv': 'Ledinflammation',
    'tausta4HermostoOireetFi': 'Hermosto-oireet',
    'tausta4HermostoOireetSv': 'Neurologiska symptom',
    'tausta4HuonoKasvuFi': 'Huono kasvu',
    'tausta4HuonoKasvuSv': 'Dålig tillväxt',
    'tausta4LuomisetFi': 'Luomiset/kuolleena syntyneet',
    'tausta4LuomisetSv': 'Aborteringar/dödfödda',
    'tausta4KuolleisuusNoussutFi': 'Kuolleisuus noussut',
    'tausta4KuolleisuusNoussutSv': 'Förhöjd dödlighet',
    'tausta4EiOireitaFi': 'Ei oireita',
    'tausta4EiOireitaSv': 'Ingen symptom',

    'muuFi': 'Muu, mikä?',
    'muuSv': 'Annat, vad?',

    'tausta5IsanNimiFi': 'Isän rek. nimi',
    'tausta5IsanNimiSv': 'Faderns ID/namn',
    'tausta5EmanNimiFi': 'Emän rek. nimi',
    'tausta5EmanNimiSv': 'Moderns ID/namn',

    'tausta6ElaimenTuhkausFi': 'Eläimen tuhkaus',
    'tausta6ElaimenTuhkausSv': 'Kremering',
    'tausta6YhteistuhkausHavityslaitosFi': 'yhteistuhkaus hävityslaitoksessa',
    'tausta6YhteistuhkausHavityslaitosSv': 'samkremering i destruktionsanläggning',
    'tausta6YhteistuhkausTuhkaamoFi': 'yhteistuhkaus tuhkaamossa',
    'tausta6YhteistuhkausTuhkaamoSv': 'samkremering i kremeringsföretag',
    'tausta6YksilotuhkausTuhkaamoFi': 'yksilötuhkaus tuhkaamossa',
    'tausta6YksilotuhkausTuhkaamoSv': 'individuell kremering',
    'tausta6TuhkaamonNimiFi': 'Tuhkaamon nimi',
    'tausta6TuhkaamonNimiSv': 'Kremeringsföretagets namn',

    'tausta7kuolleitaFi': 'Onko muita eläimiä sairaana/kuollut/luonut?',
    'tausta7kuolleitaSv': 'Är andra djur sjuka/döda/aborterat?',
    'tausta7kuolleisuusFi': 'Kuolleisuus/sairastavuus/luomiset viim. vuoden aikana',
    'tausta7kuolleisuusSv': 'Antal döda/sjuka/aborteringar under det senaste året',

    'tausta8ParvitunnisteFi': 'Parvitunniste',
    'tausta8ParvenIkaFi': 'Parven ikä (viikkoa)',
    'tausta8ParvitunnisteSv': 'Flockens identifikation',
    'tausta8ParvenIkaSv': 'Flockens ålder (veckor)',

    'tausta9TuontiAlkionsiirtoFi': 'Alkuperämaa',
    'tausta9TuontiAlkionsiirtoSv': 'Ursprungsland',

    'tausta10alkionsiirrotFi': 'Alkionsiirrot',
    'tausta10alkionsiirrotSv': 'Embryotransplantation',
    'tausta10alkionsiirrotPvmFi': 'Alkionsiirrot pvm',
    'tausta10alkionsiirrotPvmSv': 'Datum för embryotransplantation',

    'tausta11OtsikkoFi': 'Näytteet tamma',
    'tausta11OtsikkoSv': 'Sto',

    'tausta12OtsikkoFi': 'Lisäksi',
    'tausta12OtsikkoSv': 'Samt',
    'tausta12bakvirtutkimusFi': 'Erikseen laskutettava bakteriologinen tutkimus ja/tai virologinen tutkimus, mikäli patologi katsoo sen tarpeelliseksi',
    'tausta12bakvirtutkimusSv': 'En separat fakturerad bakteriologisk och/eller virologisk undersökning ifall patologen anser den vara nödvändig',
    'tausta12laajatutkimusFi': 'Erikseen laskutettava laaja tutkimus (hermosto, luusto tms.), mikäli patologi katsoo sen tarpeelliseksi',
    'tausta12laajatutkimusSv': 'En separat fakturerad omfattande undersökning (nervsystem, benstomme e.d.) ifall patologen anser den vara nödvändig',

    'tausta13OtsikkoFi': 'Näytteet ori',
    'tausta13OtsikkoSv': 'CEM‐prover av hingst',
    'tausta13UrethanaukkoFi': 'Urethran aukko',
    'tausta13UrethanaukkoSv': 'Uretramynningen',
    'tausta13EsinahanpoimuFi': 'Esinahan poimu',
    'tausta13EsinahanpoimuSv': 'Veck i förhuden',
    'tausta13SpermaFi': 'Sperma',
    'tausta13SpermaSv': 'Sperma',

    'tausta14OtsikkoFi': 'Sivelynäyte',
    'tausta14OtsikkoSv': 'Strykprov från',
    'tausta14KloaakkiFi': 'Kloaakki',
    'tausta14KloaakkiSv': 'Kloaken',
    'tausta14NieluFi': 'Nielu/kitalaki',
    'tausta14NieluSv': 'Svalg/gomvalv',

    'tausta15SijaintipaikkaFi': 'Eläimen sijaintipaikka',
    'tausta15SijaintipaikkaSv': 'Uppstallningsplats',

    'tausta16TilatunnusFi': 'Tilatunnus',
    'tausta16TilatunnusSv': 'Lägenhetssignum',
    'tausta16PitopaikkatunnusFi': 'Pitopaikkatunnus',
    'tausta16PitopaikkatunnusSv': 'Djurhållningsplatssignum',

    'tausta17PitopaikanKuntaFi': 'Pitopaikan/löytöpaikan kunta',
    'tausta17PitopaikanKuntaSv': 'Kommun där djurhållningsplats ligger/fyndkommun',

    'tausta18SairastuneitaYhdistavatTekijatFi': 'Sairastuneita/kuolleita eläimiä yhdistävät tekijät: ',
    'tausta18SairastuneitaYhdistavatTekijatSv': 'Faktorer som gemensamma för de insjuknade/döda djuren',
    'tausta18TyyppiFi': 'Tyyppi',
    'tausta18TyyppiSv': 'Typ',
    'tausta18SijaintiTarhallaFi': 'Sijainti tarhalla',
    'tausta18SijaintiTarhallaSv': 'Läge på farmen',
    'tausta18SukupuoliFi': 'Sukupuoli',
    'tausta18SukupuoliSv': 'Kön',
    'tausta18IkaFi': 'Ikä',
    'tausta18IkaSv': 'Ålder',

    'tausta19Vedenotto': 'Vedenotto',
    'tausta19IkaluokkaFi': 'Ikäluokka',
    'tausta19IkaluokkaSv': 'Ålder',
    'tausta19EmokalaFi': 'emokala',
    'tausta19EmokalaSv': 'moderfisk',
    'tausta19AllasTaiLammikkotyyppiFi': 'Allas- tai lammikkotyyppi, tunniste',
    'tausta19AllasTaiLammikkotyyppiSv': 'Bassäng- eller dammtyp, id-nr',
    'tausta19VedenottoFi': 'Vedenotto',
    'tausta19VedenottoSv': 'Vattenintag',
    'tausta19LahdeFi': 'lähde',
    'tausta19LahdeSv': 'källa',
    'tausta19PohjavesiFi': 'pohjavesi',
    'tausta19PohjavesiSv': 'grundvatten',
    'tausta19JarviJokiFi': 'järvi/joki',
    'tausta19JarviJokiSv': 'insjö/älv',
    'tausta19MeriFi': 'meri',
    'tausta19MeriSv': 'hav',
    'tausta19VedenLampotilaFi': 'Veden lämpötila',
    'tausta19VedenLampotilaSv': 'Vattentemperatur',

    'tausta20VesistoFi': 'Vesistö',
    'tausta20VesistoSv': 'Vattendrag',
    'tausta20LukumaaraFi': 'Kalojen/rapujen lukumäärä',
    'tausta20LukumaaraSv': 'Antal fiskar/kräftor',

    'tausta21NayteLahetettyFi': 'Näyte on lähetetty',
    'tausta21NayteLahetettySv': 'Sänt',
    'tausta21TuoreenaFi': 'tuoreena',
    'tausta21TuoreenaSv': 'färskt',
    'tausta21PakastettunaFi': 'pakastettuna',
    'tausta21PakastettunaSv': 'fruset',
    'tausta21SailytysaineessaFi': 'säilytysaineessa',
    'tausta21SailytysaineessaSv': 'konserveringsmedel',

    'tausta21KuolintapaFi': 'Kuolintapa',
    'tausta21KuolintapaSv': 'Dödssätt',
    'tausta21LoydettyKuolleenaFi': 'löydetty kuolleena',
    'tausta21LoydettyKuolleenaSv': 'hittat död',
    'tausta21MestastettyFi': 'metsästetty/kalastettu',
    'tausta21MestastettySv': 'jagats/fiskade',
    'tausta21LopetettuFi': 'lopetettu sairaana',
    'tausta21LopetettuSv': 'avlivats som sjukt',
    'tausta21KolarielainFi': 'kolarieläin',
    'tausta21KolarielainSv': 'trafikskadad/trafikdödad',
    'tausta21PedonTappamaFi': 'pedon tappama',
    'tausta21PedonTappamaSv': 'dödad av rovdjur',
    'tausta21HylattyTeurastamollaFi': 'hylätty teurastamolla/lihantarkastuksessa',
    'tausta21HylattyTeurastamollaSv': 'kasserad/otjänlig',
    'tausta21EiTietoaFi': 'ei tietoa kuolintavasta',
    'tausta21EiTietoaSv': 'okänd dödsorsak',

    'tausta22KoordinaatitFi': 'Koordinaatit',
    'tausta22KoordinaatitSv': 'Koordinater',
    'tausta22LeveysasteFi': 'Leveysaste (N/lat)',
    'tausta22LeveysasteSv': 'Latitud (N/lat)',
    'tausta22PituusasteFi': 'Pituusaste (E/lon)',
    'tausta22PituusasteSv': 'Longitud (E/lon)',
    'tausta22KoordinaattijarjestelmaFi': 'Koordinaattijärjestelmä',
    'tausta22KoordinaattijarjestelmaSv': 'Koordinatsystem',

    'tausta23NaytteenkeruualueFi': 'Näytteenkeruualue',
    'tausta23NaytteenkeruualueSv': 'Provsamlingsområde',
    'tausta23RiistanhoitoyhdistysFi': 'Riistanhoitoyhdistys',
    'tausta23RiistanhoitoyhdistysSv': 'jaktvårdsförening',
    'tausta23PaliskuntaFi': 'Paliskunta',
    'tausta23PaliskuntaSv': 'renbeteslag',
    'tausta23TarhaFi': 'Tarha',
    'tausta23TarhaSv': 'hjorthägn',
    'tausta23NimiFi': 'Nimi',
    'tausta23NimiSv': 'Namn',

    'tausta24IkaluokkaFi': 'Ikäluokka',
    'tausta24IkaluokkaSv': 'Ålder',
    'tausta24TaysikasvuinenFi': 'Täysikasvuinen',
    'tausta24TaysikasvuinenSv': 'Vuxen',
    'tausta24NuoriFi': 'Nuori',
    'tausta24NuoriSv': 'Ung',

    'tausta25ViranomaisnaytteenottoFi': 'Viranomaisnäytteenotto, näytteenottajana valtuutettu tarkastaja',
    'tausta25ViranomaisnaytteenottoSv': 'Myndighetsprov',
    'tausta25NimiFi': 'Nimi',
    'tausta25NimiSv': 'namn av provtagare (befullmäktigad kontrollör)',

    'tausta26KopioFi': 'Kopio alkuperäisestä lähetteestä tai vastaavat tiedot näytteen mukaan',
    'tausta26KopioSv': 'En kopia av originalremissen eller likadan uppgifter med prov',

    'tausta27TilatunnusFi': 'Tilatunnus',
    'tausta27TilatunnusSv': 'Lägenhetssignum',
    'tausta27PitopaikkatunnusFi': 'Pitopaikkatunnus',
    'tausta27PitopaikkatunnusSv': 'Djurhållningsplatssignum',

    'Virheellinen puhelinnumero.Fi': 'Virheellinen puhelinnumero.',
    'Virheellinen puhelinnumero.Sv': 'Felaktig telefonnummer.',
    'Virheellinen sähköpostiosoite.Fi': 'Virheellinen sähköpostiosoite.',
    'Virheellinen sähköpostiosoite.Sv': 'Felaktig e-postadress.',
    'Virheellinen eläinlääkäritunnus.Fi': 'Virheellinen eläinlääkäritunnus.',
    'Virheellinen eläinlääkäritunnus.Sv': 'Felaktig veterinärnummer.',
    'Virheellinen Y-tunnus.Fi': 'Virheellinen Y-tunnus.',
    'Virheellinen Y-tunnus.Sv': 'Felaktig FO-nummer.',
    'Kenttä on pakollinenFi': 'Kenttä on pakollinen',
    'Kenttä on pakollinenSv': 'Fältet är obligatoriskt.',
    'Virheellinen postinumeroFi': 'Virheellinen postinumero',
    'Virheellinen postinumeroSv': 'Felaktigt postnummer.',
    'Eläinlajiryhmä on pakollinen.Fi': 'Eläinlajiryhmä on pakollinen.',
    'Eläinlajiryhmä on pakollinen.Sv': 'Djurgrupp är obligatoriskt.',
    'Eläinlaji on pakollinenFi': 'Eläinlaji on pakollinen',
    'Eläinlaji on pakollinenSv': 'Djurart är obligatoriskt.',
    'Toimitusosoite on pakollinen.Fi': 'Toimitusosoite on pakollinen.',
    'Toimitusosoite on pakollinen.Sv': 'Leveransadress är obligatoriskt.',
    'Osoite sijaitsee SuomessaFi': 'Osoite sijaitsee Suomessa',
    'Osoite sijaitsee SuomessaSv': 'Adressen är i Finland',

    'Lampaiden/vuohien määrä pitopaikassa (kpl)Fi': 'Lampaiden/vuohien määrä pitopaikassa (kpl)',
    'Lampaiden/vuohien määrä pitopaikassa (kpl)Sv': 'Antal får/getter, st.',
    'Nautojen määrä pitopaikassa (kpl)Fi': 'Nautojen määrä pitopaikassa (kpl)',
    'Nautojen määrä pitopaikassa (kpl)Sv': 'Antal nötdjur, st.',
    'Parven koko (kpl)Fi': 'Parven koko (kpl)',
    'Parven koko (kpl)Sv': 'Populationens storlek, st.',
    'Lintujen määrä tilalla (kpl)Fi': 'Lintujen määrä tilalla (kpl)',
    'Lintujen määrä tilalla (kpl)Sv': 'Antal fåglar, st.',
    'Kanoja ja kalkkunoita yli 100kplFi': 'Kanoja ja kalkkunoita yli 100kpl',
    'Kanoja ja kalkkunoita yli 100kplSv': 'Djurhållningsplats med mer än 100 höns och eller kalkoner',
    'UlkokanalaFi': 'Ulkokanala',
    'UlkokanalaSv': 'Utegående höns',
    'LuomukanalaFi': 'Luomukanala',
    'LuomukanalaSv': 'Ekologisk hönsgård',
    'Emakoiden määrä pitopaikassa (kpl)Fi': 'Emakoiden määrä pitopaikassa (kpl)',
    'Emakoiden määrä pitopaikassa (kpl)Sv': 'Antal suggor, st.',
    'Lihasikojen määrä pitopaikassa (kpl)Fi': 'Lihasikojen määrä pitopaikassa (kpl)',
    'Lihasikojen määrä pitopaikassa (kpl)Sv': 'Antal slaktsvin, st.',
    'NahkatilinumeroFi': 'Nahkatilinumero',
    'NahkatilinumeroSv': 'Skinnkontonummer',
    'Rehun toimittajaFi': 'Rehun toimittaja',
    'Rehun toimittajaSv': 'Foderleverantör',
    'Minkkitilan plasmasytoosistatusFi': 'Minkkitilan plasmasytoosistatus',
    'Minkkitilan plasmasytoosistatusSv': 'Minkfarmens plasmacytosstatus',
    'VesiviljelyrekisterinumeroFi': 'Vesiviljelyrekisterinumero',
    'VesiviljelyrekisterinumeroSv': 'Vattenbruksnummer',
    'Laitos kuuluu Ruokaviraston kalaterveyspalveluun (sopimushinnasto)Fi': 'Laitos kuuluu Ruokaviraston kalaterveyspalveluun (sopimushinnasto)',
    'Laitos kuuluu Ruokaviraston kalaterveyspalveluun (sopimushinnasto)Sv': 'Odlingen tillhör Livsmedelsverkets fiskhälsoservice (avtalspris)',
    'TilatiedotFi': 'Tilatiedot',
    'TilatiedotSv': 'Gårdsinformation',
    'TilatyypitFi': 'Tilatyypit',
    'TilatyypitSv': 'Gårdstyper',
    'TilatyyppiFi': 'Tilatyyppi',
    'TilatyyppiSv': 'Gårdstyp',
    'TunnusFi': 'Tunnus',
    'TunnusSv': 'Lägenhetssignum',
    'Yli 1vFi': 'Yli 1v',
    'Yli 1vSv': 'Över 1 år',
    '6kk-1vFi': '6kk-1v',
    '6kk-1vSv': '6–12 mån.',
    'Alle 6kkFi': 'Alle 6kk',
    'Alle 6kkSv': 'Under 6 mån.',
    'Yli 2vFi': 'Yli 2v',
    'Yli 2vSv': 'Över 2 år',
    '6kk-2vFi': '6kk-2v',
    '6kk-2vSv': '6 mån. – 2 år',
    'Itsestään kuollutFi': 'Itsestään kuollut',
    'Itsestään kuollutSv': 'Självdött',
    'LopetettuFi': 'Lopetettu',
    'LopetettuSv': 'Avlivad',
    'KuolinpäivämääräFi': 'Kuolinpäivämäärä',
    'KuolinpäivämääräSv': 'Dödsdatum',
    'YhteenvetoFi': 'Yhteenveto',
    'YhteenvetoSv': 'Sammanfattning',
  };

  static getLabel(label, lang) {
    return Translations.labels[label + lang];
  }
}
