<div class="row">
  <div class="col">
    <h1 attribute="infoValilehti.otsikko">Versiotiedot</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <div *ngFor="let version of versions">
      <h2 [attribute]="version.title.fullKey"></h2>
      <ul>
        <li *ngFor="let update of version.updates">
          <span [attribute]="update.description.fullKey">Ominaisuus</span>
          <span *ngFor="let flag of update.flags"
                class="badge"
                [ngClass]="flag.tyyli"
                [attribute]="flag.teksti.fullKey">Flag</span>
        </li>
      </ul>
    </div>
  </div>
</div>
