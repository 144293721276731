import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable()
export class LomakeRedirectService {

  private _canRedirect = false;

  constructor(private router: Router) {
  }

  get canRedirect(): boolean {
    return this._canRedirect;
  }

  set canRedirect(value: boolean) {
    this._canRedirect = value;
  }

  public redirectTo(link: string[]) {
    this._canRedirect = true;
    return this.router.navigate(link);
  }

}
