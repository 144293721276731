import {Component} from "@angular/core";
import {RaporttiViewService} from "../raportti-view.service";
import {Subscription} from "rxjs/index";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 22/05/2018.
 */

@Component({
  selector: "raportti-sivutus",
  templateUrl: "./raportti-sivutus.component.html",
})
@AutoUnsubscribe
export class RaporttiSivutusComponent {

  raporttiLkmSivulla: number;
  sivu = 0;
  raportitYht = 0;

  subs: Subscription[] = [];

  constructor(private raporttiViewService: RaporttiViewService) {
    this.subs.push(this.raporttiViewService.sivu$.subscribe(val => {
      if (val) {
        this.sivu = val;
      }
    }));
    this.subs.push(this.raporttiViewService.raportitYht$.subscribe(val => this.raportitYht = val));
    this.subs.push(this.raporttiViewService.raporttiLkmSivulla$.subscribe(val => this.raporttiLkmSivulla = val));
  }

  selectRaporttiLkm(lkm: string) {
    this.raporttiViewService.selectRaporttiLkm(parseInt(lkm, 10));
  }

  selectSivu(sivu: number) {
    this.raporttiViewService.selectSivu(sivu);
  }

  getSivumaara() {
    return Math.floor(this.raportitYht / this.raporttiLkmSivulla) + 1;
  }

}
