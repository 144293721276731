import {MessageModule} from "../message/message.module";
import {AttributeModule} from "../tiira/attribute.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {AccountComponent} from "./account.component";
import {AccountService} from "./account.service";
import {AccountModalComponent} from "./toimipaikka-asetukset/account-modal.component";
import {ToukoInputsModule} from "../touko-inputs/touko-inputs.module";
import {NgModule} from "@angular/core";
import {PostitoimipaikkaPipe} from "../koodisto/postitoimipaikka.pipe";
import {AccountTiedotComponent} from "./toimipaikka-asetukset/account-tiedot.component";
import {AccountNotificationModalComponent} from "./ilmoitusasetukset/account-notification-modal.component";
import {UtilsModule} from "../utils/utils.module";
import {LoaderModule} from "../loader/loader.module";
import {VaihdaToimipaikkaComponent} from "./vaihda-toimipaikka/vaihda-toimipaikka.component";

/**
 * Moduuliin kootaan kaikki Toukon käyttämät custom lomakekomponentit.
 */
@NgModule({
  declarations: [
    AccountComponent,
    AccountModalComponent,
    AccountNotificationModalComponent,
    PostitoimipaikkaPipe,
    AccountTiedotComponent,
    VaihdaToimipaikkaComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LoaderModule,
    AttributeModule,
    MessageModule,
    ToukoInputsModule,
    UtilsModule
  ],
  exports: [
    AccountComponent,
    AccountTiedotComponent,
    VaihdaToimipaikkaComponent
  ],
  entryComponents: [
    AccountModalComponent,
    AccountNotificationModalComponent
  ],
  providers: [
    AccountService
  ]
})
export class AccountModule {
}
