import {Kasvilaji} from './kasvilaji.model';


export interface Kasvilajike {
  kuvaus: string;
  elmoid: string;
}

export function KasvilajikeSorter(a: Kasvilajike, b: Kasvilajike): number {
  if (!a || !a.kuvaus) {
    return -1;
  }

  if (!b || !b.kuvaus) {
    return 1;
  }

  return (a.kuvaus || "").localeCompare(b.kuvaus || "");
}
