import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'answer-only-summary',
  template: `
    <div *ngIf="item" class="row">
      <div class="col">
        <compare-item [compareItemType]="compareItemType">
          <span [class]="item.options?.isHighlighted ? 'answer' : '' " [attribute]="item.answer" [id]="'value-' + item.field.htmlId">{{item.answer}}</span>
        </compare-item>
      </div>
    </div>
  `,
  styleUrls: ['./summary.scss']
})
export class AnswerOnlySummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
