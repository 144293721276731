import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuestionRadioComponent} from "./question-radio.component";
import {QuestionCheckboxButtonComponent} from "./question-checkbox-button.component";
import {QuestionTextComponent} from "./question-text.component";
import {QuestionTaustaTextareaComponent} from "./question-tausta-textarea.component";
import {QuestionTaustaTextInputComponent} from "./question-tausta-text-input.component";
import {QuestionGroupComponent} from "./question-container/question-group.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LaheteModule} from "ee-lahete";
import {QuestionTutkimusCardComponent} from "./question-tutkimus-card.component";
import {UtilsModule} from "../../utils/utils.module";
import {QuestionNaytetiedotComponent} from "./question-nayte/question-naytetiedot.component";
import {ToukoInputsModule} from "../../touko-inputs/touko-inputs.module";
import {QuestionArrayComponent} from "./question-container/question-array.component";
import {QuestionNayteComponent} from "./question-nayte/question-nayte.component";
import {QuestionTaustaCheckboxTexteareaComponent} from "./question-tausta-checkbox-textearea.component";
import { NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AttributeModule} from "../../tiira/attribute.module";
import {QuestionTaustaSelectTextComponent} from "./question-tausta-select-text.component";
import {QuestionTaustaRadioDateTextComponent} from './question-tausta-radio-date-text.component';
import {QuestionGroupNestedComponent} from './question-group-nested.component';
import {QuestionRadioCardComponent} from './question-radio-card.component';
import {QuestionTaustaAlertComponent} from "./question-tausta-alert.component";
import {QuestionSelectComponent} from "./question-select.component";
import {QuestionNayteModalComponent} from "./question-nayte/question-nayte-modal.component";
import {NautarekisterihakuModalComponent} from "./question-nayte/nautarekisterihaku-modal.component";
import {QuestionErrorComponent} from "./question-error.component";
import {QuestionErrorDirective} from "./question-error.directive";
import {QuestionControlComponent} from "./question-container/question-control.component";
import {NautarekisteriDate} from './question-nayte/nautarekisterihaku-date.component';
import {LaheteServiceConfig} from '../lahete/lahete.service';

@Injectable()
export class ToukoQuestionInputConfig extends LaheteServiceConfig {
  getKoodistoURL = "/api/v1/as/lahetteet/koodistot";
}

@NgModule({
  declarations: [
    QuestionGroupComponent,
    QuestionArrayComponent,
    QuestionControlComponent,
    QuestionErrorComponent,
    QuestionErrorDirective,
    QuestionRadioComponent,
    QuestionCheckboxButtonComponent,
    QuestionTextComponent,
    QuestionTutkimusCardComponent,
    QuestionNaytetiedotComponent,
    QuestionNayteComponent,
    QuestionTaustaTextareaComponent,
    QuestionTaustaTextInputComponent,
    QuestionTaustaSelectTextComponent,
    QuestionTaustaCheckboxTexteareaComponent,
    QuestionTaustaRadioDateTextComponent,
    QuestionGroupNestedComponent,
    QuestionRadioCardComponent,
    QuestionTaustaAlertComponent,
    QuestionSelectComponent,
    QuestionNayteModalComponent,
    NautarekisterihakuModalComponent,
    NautarekisteriDate
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LaheteModule,
    ToukoInputsModule,
    UtilsModule,
    AttributeModule,
    NgbModule
  ],
    exports: [
        QuestionGroupComponent,
        QuestionControlComponent,
    ],
  providers: [
    {provide: LaheteServiceConfig, useClass: ToukoQuestionInputConfig},
  ]
})
export class QuestionInputsModule { }
