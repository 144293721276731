import {TulkkiObject} from "../common/lomake-field-utils";
import {Field, FieldArray, FieldControl, FieldGroup} from "ngx-fielding";
import {
  createAinesosaluokat,
  createLuovutuksetGroupWithGroups,
  createMaaraJaAlkuperaControls,
  createPaaravinteidenMaara
} from "./lannoitevuosiilmoitus-data-utils";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

export const createEUToiminta = (): FieldGroup => {
  return new FieldGroup(
    Field.build(attr("")),
    {
      euLannoiteValmisteenTuonti: new FieldArray(
        Field.build(attr("lannoiteValmisteenTuonti")),
        () => createMaahantuontiGroup(),
        [createMaahantuontiGroup()]
      ),
      euLannoitevalmisteenVieja: new FieldArray(
        Field.build(attr("lannoiteValmisteenVieja")),
        () => createVientiGroup(),
        [createVientiGroup()]
      ),
      euLannoitevalmisteenJakelija: new FieldArray(
        Field.build(attr("")),
        () => createJakelijaGroup(),
        [createJakelijaGroup()]
      ),
      euLannoitevalmisteenValmistaja: new FieldArray(
        Field.build(attr("")),
        () => createValmistusGroup(),
        [createValmistusGroup()]
      )
    }
  );
}

const createMaahantuontiGroup = (): FieldGroup => {
  return new FieldGroup(
    Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(),
        {
          ...createMaaraJaAlkuperaControls(),
          lahtomaa: new FieldControl(Field.build()),
          alperamaa: new FieldControl(Field.build()),
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}

const createVientiGroup = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(), createMaaraJaAlkuperaControls()),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: createLuovutuksetGroupWithGroups()
    })
}

const createJakelijaGroup = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(),
        {
          ...createMaaraJaAlkuperaControls(),
          lahtomaa: new FieldControl(Field.build()),
          alperamaa: new FieldControl(Field.build()),
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}

const createValmistusGroup = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(), {
          ...createMaaraJaAlkuperaControls(),
          vainOmaanKayttoon: new FieldControl(Field.build()),
          vainPakkaamista: new FieldControl(Field.build()),
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      ainesosaluokat: createAinesosaluokat(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}
