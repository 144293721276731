import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EtusivuComponent} from "./etusivu.component";
import {EtusivuAsiakasComponent} from "./etusivu-asiakas/etusivu-asiakas.component";
import {EtusivuVirkailijaComponent} from "./etusivu-virkailija/etusivu-virkailija.component";
import {LomakeCardComponent} from "./lomake-card.component";
import {LomakeProgressComponent} from "./lomake-progress.component";
import {OmavalvontasuunnitelmaComponent} from "./etusivu-asiakas/omavalvontasuunnitelma.component";
import {UtilsModule} from "../utils/utils.module";
import {LomakeListViewComponent} from "./lomake-table/lomake-list-view.component";
import {LomakeHakuComponent} from "./lomake-table/lomake-haku.component";
import {LoaderModule} from "../loader/loader.module";
import {AttributeModule} from "../tiira/attribute.module";
import {MessageModule} from "../message/message.module";
import {ToukoInputsModule} from "../touko-inputs/touko-inputs.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {EtusivuElaintauditComponent} from './etusivu-elaintaudit/etusivu-elaintaudit.component';
import {MallipohjaModalComponent} from "./etusivu-elaintaudit/mallipohja-modal.component";
import {LaheteListausComponent} from './lahetetyt/lahete-listaus.component';
import {LomakeTableComponent} from "./lomake-table/lomake-table.component";
import {LomakeRowComponent} from './lomake-table/lomake-row.component';

@NgModule({
  declarations: [
    EtusivuComponent,
    EtusivuAsiakasComponent,
    EtusivuVirkailijaComponent,
    EtusivuElaintauditComponent,
    LomakeCardComponent,
    LomakeProgressComponent,
    LomakeListViewComponent,
    LomakeTableComponent,
    LomakeHakuComponent,
    LaheteListausComponent,
    OmavalvontasuunnitelmaComponent,
    LomakeRowComponent,
    MallipohjaModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AttributeModule,
    UtilsModule,
    NgbModule,
    LoaderModule,
    MessageModule,
    ToukoInputsModule,
    FormsModule,
  ],
  exports: [
    EtusivuComponent
  ]
})
export class EtusivuModule { }
