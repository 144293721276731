<div class="modal-header">
  <h1 class="modal-title" [attribute]="'kayttajatili.valitseAsiointitili'">Asiointitilin valinta</h1>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="accountForm">
  <div class="modal-body">
    <div class="form-group">
      <div class="col">
        <fieldset>
          <legend [attribute]="'kayttajatili.valitseToimiala'"></legend>
          <div *ngFor="let toimialaValtuus of toimialaValtuudet" class="form-check">
            <touko-radio-input [formControlName]="'toimiala'" [value]="toimialaValtuus" [htmlId]="toimialaValtuus | lowercase" [labelAttribute]="modaaliAsetukset[toimialaValtuus].valintaTeksti.getFullKey()" [radioGroup]="'toimialavaltuudet'"></touko-radio-input>
          </div>
          <form-error [showError]="isKenttaVirhe('toimiala')" [errorMsg]="errPakollinenKentta"></form-error>
          <div class="" *ngIf="toimiala === 'SIEMEN' && isAsiakas">
            <span [attribute]="'kayttajatili.infoPakkaajanRekisterointi'"></span>
          </div>
        </fieldset>
      </div>
    </div>

    <ng-container *ngIf="naytaValvontakohdeValinta()">
      <hr/>
      <div>
        <div class="form-group">
          <div class="col">

            <label [htmlFor]="'kayttaja-modal-valvontakohde-typeahead'">
              <span *ngIf="naytaIlmoitaToimipaikka()"
                    [attribute]="'kayttajatili.valitseToimipaikkaTaiIlmoitaUusi'"></span>
              <span *ngIf="!naytaIlmoitaToimipaikka()" [attribute]="'kayttajatili.valitseToimipaikka'"></span>
            </label>

            <div class="form-row">
              <div class="col-8">
                <valvontakohde-typeahead
                  [controlName]="'toimipaikka'"
                  [valvontakohteet]="modaaliAsetukset[toimiala]?.valvontakohteet"
                  (selectValvontakohde)="selectValvontakohde($event.valvontakohde, $event.saveValvontakohde)"
                  [placeholder]="'kayttajatili.valitseToimipaikkaOhje' | attribute"
                  [htmlId]="'kayttaja-modal-valvontakohde-typeahead'"
                  (createUusiIlmoitus)="createUusiIlmoitusEvent()">
                </valvontakohde-typeahead>
              </div>
              <div class="col-4">
                <button *ngIf="this.naytaIlmoitaToimipaikka()" id="kayttaja-modal-valvontakohde-lisaa-uusi-toimipaikka"
                        class="btn btn-outline-primary btn-block" type="button" (click)="createUusiIlmoitusEvent()">
                  <em class="fa fa-plus-square"></em>
                  <span attribute="kayttajatili.ilmoitaUusiToimipaikka">Ilmoita uusi toimipaikka</span>
                </button>
              </div>
            </div>

            <ng-container *ngIf="isKenttaVirhe('toimipaikka')">
              <form-error [showError]="this.naytaIlmoitaToimipaikka()"
                          [errorMsg]="errValitseToimipaikka"></form-error>
              <form-error [showError]="!this.naytaIlmoitaToimipaikka()"
                          [errorMsg]="errToimipaikkaPakollinen"></form-error>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="valvontakohde">
        <div class="col">
          <div class="alert alert-secondary">
            <account-tiedot [toimiala]="toimiala"></account-tiedot>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="submit" id="account-tallenna" class="btn btn-success" [clickAsync]="submit"
            attribute="teksti.tallenna">
      Tallenna
    </button>
    <button type="button" id="account-peruuta" class="btn btn-light" attribute="teksti.peruuta"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
</form>
