import {Component, Input} from "@angular/core";
import {LOMAKE_KUVAUKSET, LomakeResponse} from "../touko-lomake/touko-lomake-utils";
import {Teksti} from "../utils/teksti";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";

/**
 * Created by Seppo on 21/01/2019.
 */

@Component({
  selector: 'lomake-progress',
  templateUrl: './lomake-progress.component.html'
})
export class LomakeProgressComponent  {
  @Input() lomake: LomakeResponse;
  kuvaukset = LOMAKE_KUVAUKSET;

  getKuvausTeksti(asia: string, toimintotyyppi: string): Teksti {
    return (this.kuvaukset[asia]) ? this.kuvaukset[asia][toimintotyyppi] : new Teksti("FIXME: Täydennä LOMAKE_KUVAUKSET -listaa", "FIXME_täydennä_LOMAKE_KUVAUKSET-listaa", "etusivu");
  }

  get lomakeURL() {
    const toimintotyyppi = this.lomake.toimintotyyppi.toLowerCase()
    const clientVersion = LomakeEndpointUtil.shouldUseV2ClientComponent(this.lomake.asia, toimintotyyppi, this.lomake.lomaketyyppiVersio) ? 'v2' : 'v1';
    return `/asiointi/lomake/tarkastus/${toimintotyyppi}/${this.lomake.asia}/${clientVersion}/${this.lomake.id}`
  }

}
