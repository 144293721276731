import {Component, Input} from "@angular/core";
import {YhteenvetoComponentBase} from "../../yhteenveto-container.component";
import {CompareStatus, YhteenvetoItem} from "../../yhteenveto-utils";

@Component({
  selector: "lannoite-lopeta-toiminta",
  templateUrl: "lannoite-lopeta-toiminta.component.html",
  styleUrls: ["../yhteenveto-style.scss"]
})
export class LannoiteLopetaToimintaComponent implements YhteenvetoComponentBase {
  @Input() item: YhteenvetoItem = null;
  @Input() prevItem: YhteenvetoItem = null;
  @Input() compareStatus?: CompareStatus = null;
  @Input() showCompareYhteenveto = false;
}
