import {Field, FieldControl, FieldGroup} from "ngx-fielding";

export const createPaaravinteidenMaara = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      typpi: new FieldControl(Field.build()),
      fosfori: new FieldControl(Field.build()),
    }
  )
}

export const createMaaraJaAlkuperaControls = () => {
  return {
    tuotenimi: new FieldControl(Field.build()),
    maara: new FieldControl(Field.build()),
    maaranYksikko: new FieldControl(Field.build()),
    ilmoitaLannoiteValmistelainTuote: new FieldControl(Field.build()),
    tyyppiNimiTunnus: new FieldControl(Field.build()),
    tuoteluokka: new FieldControl(Field.build()),
    tuotetyyppi: new FieldControl(Field.build()),
    alperamaa: new FieldControl(Field.build())
  }
}

export const createCheckboxWithMaara = () => {
  return {
    checkbox: new FieldControl(Field.build()),
    maara: new FieldControl(Field.build())
  }
}

export const createAinesosaluokat = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      aol1: new FieldControl(Field.build()),
      aol2: new FieldControl(Field.build()),
      aol3: new FieldControl(Field.build()),
      aol4: new FieldControl(Field.build()),
      aol5: new FieldControl(Field.build()),
      aol6: new FieldControl(Field.build()),
      aol7: new FieldControl(Field.build()),
      aol8: new FieldControl(Field.build()),
      aol9: new FieldControl(Field.build()),
      aol10: new FieldControl(Field.build()),
      aol11: new FieldControl(Field.build()),
      aol12: new FieldControl(Field.build()),
      aol13: new FieldControl(Field.build()),
      aol14: new FieldControl(Field.build()),
      aol15: new FieldControl(Field.build()),
      aol16: new FieldControl(Field.build()),
      cmc1: new FieldControl(Field.build()),
      cmc2: new FieldControl(Field.build()),
      cmc3: new FieldControl(Field.build()),
      cmc4: new FieldControl(Field.build()),
      cmc5: new FieldControl(Field.build()),
      cmc6: new FieldControl(Field.build()),
      cmc7: new FieldControl(Field.build()),
      cmc8: new FieldControl(Field.build()),
      cmc9: new FieldControl(Field.build()),
      cmc10: new FieldControl(Field.build()),
      cmc11: new FieldControl(Field.build()),
      cmc12: new FieldControl(Field.build()),
      cmc13: new FieldControl(Field.build()),
      cmc14: new FieldControl(Field.build()),
      cmc15: new FieldControl(Field.build()),
    }
  )
}

export const createLuovutuksetGroupWithGroups = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      luovutettuvalmistuttajalle: new FieldGroup(Field.build(),
        createCheckboxWithMaara()
      ),
      peltoviljelyAlkuvuonna: new FieldGroup(Field.build(),
        {
          checkbox: new FieldControl(Field.build()),
          ajalla: new FieldControl(Field.build()),
          maara: new FieldControl(Field.build()),
        }
      ),
      peltoviljelyLoppuvuonna: new FieldGroup(Field.build(),
        {
          checkbox: new FieldControl(Field.build()),
          ajalla: new FieldControl(Field.build()),
          maara: new FieldControl(Field.build()),
        }
      ),
      metsanlannoitus: new FieldGroup(Field.build(),
        createCheckboxWithMaara()
      ),
      kotitalouskaytto: new FieldGroup(Field.build(),
        createCheckboxWithMaara()
      ),
      kasvihuoneviljely: new FieldGroup(Field.build(),
        createCheckboxWithMaara()
      ),
      viherrakentaminen: new FieldGroup(Field.build(),
        createCheckboxWithMaara()
      ),
      jatkojalostukseen: new FieldGroup(Field.build(),
        createCheckboxWithMaara()
      ),
      muukaytto: new FieldGroup(Field.build(),
        {
          checkbox: new FieldControl(Field.build()),
          maara: new FieldControl(Field.build()),
          muukayttolisatieto: new FieldControl(Field.build()),
        }
      ),
      vientiEuAlueelle: new FieldGroup(Field.build(),
        {
          checkbox: new FieldControl(Field.build()),
          maara: new FieldControl(Field.build()),
          vientimaa: new FieldControl(Field.build()),
        }
      ),
      vientiEuAlueelta: new FieldGroup(Field.build(),
        {
          checkbox: new FieldControl(Field.build()),
          maara: new FieldControl(Field.build()),
          vientimaa: new FieldControl(Field.build()),
        }
      )
    }
  )
}
