import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {MatcherService} from "./matcher.service";
import {BehaviorSubject} from "rxjs";

import {AbstractControl} from "@angular/forms";
import {CompareAction, CompareType, MatcherModel, MatcherValueChange, ModelFragment} from "./matcher-model";

@Directive({
  selector: '[eelControlMatcher]'
})
export class ControlMatcherDirective implements OnInit {

  @Input('eelControlMatcher') question: ModelFragment = null;
  @Input('eelControl') control: AbstractControl = null;
  @Input('eelModifierValues') modifierValues = {initialValue: null, value: null};

  controlFilter: {[s: string]: MatcherModel} = null;
  controlDisabler: {[s: string]: MatcherModel} = null;
  controlModifier: {[s: string]: MatcherModel} = null;
  controlAggregator: {[s: string]: MatcherModel} = null;

  filterResults: {[s: string]: CompareAction} = {};
  disablerResults: {[s: string]: CompareAction} = {};
  modifierResults: {[s: string]: CompareAction} = {};
  aggregatorResults: {[s: string]: CompareAction} = {};

  filterSubject: BehaviorSubject<MatcherValueChange> = new BehaviorSubject(null);
  $filterSubject = this.filterSubject.asObservable();

  disablerSubject: BehaviorSubject<MatcherValueChange> = new BehaviorSubject(null);
  $disablerSubject = this.disablerSubject.asObservable();

  modifierSubject: BehaviorSubject<MatcherValueChange> = new BehaviorSubject(null);
  $modifierSubject = this.modifierSubject.asObservable();

  aggregatorSubject: BehaviorSubject<MatcherValueChange> = new BehaviorSubject(null);
  $aggregatorSubject = this.aggregatorSubject.asObservable();

  constructor(private readonly el: ElementRef,
              private readonly renderer: Renderer2,
              private readonly matcherService: MatcherService) {
    this.registerControlFilters = this.registerControlFilters.bind(this);
    this.subscribeMatcherChanges = this.subscribeMatcherChanges.bind(this);
  }

  /**
   * Luo funktion, jonka avulla voidaan seurata matcher-muutoksia.
   *
   * @param matcherModel - matcherin tiedot
   */
  private createMatcherFn(matcherModel: MatcherModel): (value: any) => CompareAction {
    return (val) => {
      if (matcherModel.compareFn) {
        return matcherModel.compareFn(matcherModel.compareValue, val);
      }
      return CompareAction.NONE;
    };
  }

  ngOnInit(): void {
    if (this.question.lateRegisterMatchers) {
      this.initControlFilters = this.initControlFilters.bind(this);

      this.matcherService.registerControlFilterSubject(
        this.question.fullPath,
        this.filterSubject,
        this.initControlFilters
        );
    }

    if (!(this.question.filters || this.question.disablers || this.question.modifiers || this.question.aggregators)) {
      return;
    }

    this.controlFilter = this.question.filters;
    this.controlDisabler = this.question.disablers;
    this.controlModifier = this.question.modifiers;
    this.controlAggregator = this.question.aggregators;

    if (this.controlFilter) {
      this.renderer.addClass(this.el.nativeElement, 'd-none');
    }

    this.registerControlFilters();
    this.registerControlDisablers();
    this.registerControlModifiers();
    this.registerControlAggregators();

    this.subscribeMatcherChanges();
  }

  initControlFilters(filters: {[s: string]: MatcherModel}) {
    this.controlFilter = filters;
    const showOnStart = Object.values(this.controlFilter).some(v => v.initialCompareState === CompareAction.FILTER);
    if (!showOnStart) {
      this.renderer.addClass(this.el.nativeElement, 'd-none');
    }

    this.registerControlFilters();
    this.subscribeMatcherChanges();
    this.matcherService.setPathFilterStatus(this.question.fullPath, showOnStart ? CompareAction.FILTER : CompareAction.NONE);
  }

  /**
   * Rekistereröi komponentin filterit MatcherServiceen, voidaan seurata `filterSubject`-muutoksia.
   */
  private registerControlFilters() {
    if (!this.controlFilter) {
      return;
    }

    Object.keys(this.controlFilter).forEach(key => {
      this.filterResults[key] = this.controlFilter[key].initialCompareState;
      const filterFn = this.createMatcherFn(this.controlFilter[key]);
      this.matcherService.registerControlFilterObservable(key, this.filterSubject, filterFn);
    });
  }

  /**
   * Rekisteröi komponentin disablerit MatcherServiceen, voidaan seurata `disablerSubject`-muutoksia.
   */
  private registerControlDisablers() {
    if (!this.controlDisabler) {
      return;
    }

    Object.keys(this.controlDisabler).forEach(key => {
      const result = this.controlDisabler[key].initialCompareState;
      this.disablerResults[key] = result;
      const disablerFn = this.createMatcherFn(this.controlDisabler[key]);
      this.matcherService.registerControlDisablerObservable(key, this.disablerSubject, disablerFn);
      if (result === CompareAction.DISABLE) {
        this.control.disable({onlySelf: true, emitEvent: false});
      }
    });
  }

  /**
   * Rekisteröi komponentin modifierit MatcherServiceen, voidaan seurata `modifierSubject`-muutoksia.
   */
  private registerControlModifiers() {
    if (!this.controlModifier) {
      return;
    }

    Object.keys(this.controlModifier).forEach(key => {
      this.modifierResults[key] = this.controlModifier[key].initialCompareState;
      const modifierFn = this.createMatcherFn(this.controlModifier[key]);
      this.matcherService.registerControlModifierObservable(key, this.modifierSubject, modifierFn);
    });
  }

  /**
   * Rekisteröi komponentin kokoajat MatcherServiceen, voidaan seurata `aggregatorSubject`-muutoksia.
   */
  private registerControlAggregators() {
    if (!this.controlAggregator) {
      return;
    }

    Object.keys(this.controlAggregator).forEach(key => {
      this.aggregatorResults[key] = this.controlAggregator[key].initialCompareState;
      const aggregatorFn = this.createMatcherFn(this.controlAggregator[key]);
      this.matcherService.registerControlAggregatorObservable(key, this.aggregatorSubject, aggregatorFn);
    });
  }

  /**
   * Seuraa lomakkeen matcher-arvojen muutoksia sekä ajaa muutosfunktiot.
   */
  private subscribeMatcherChanges() {

    this.$disablerSubject.subscribe(change => {
      if (!change) {
        return;
      }
      this.disablerResults[change.key] = change.nextValue;
      const arg = this.getCompareActionArgument(this.disablerResults, this.controlDisabler[change.key].compareType);
      this.controlDisabler[change.key].compareActionFn(this.control, arg);
    });

    this.$filterSubject.subscribe(change => {
      if (!change) {
        return;
      }
      this.filterResults[change.key] = change.nextValue;
      const arg = this.getCompareActionArgument(this.filterResults, this.controlFilter[change.key].compareType);
      this.matcherService.setPathFilterStatus(this.question.fullPath, arg);
      this.controlFilter[change.key].compareActionFn(this.el, this.renderer, arg);
    });

    if (this.controlModifier) {
      const modifierValueMap = {
        [CompareAction.RESET]: this.modifierValues.initialValue,
        [CompareAction.SELECT_ONLY_RADIO_OPTION]: this.modifierValues.value,
        [CompareAction.SELECT_ONLY_CHECKBOX_OPTION]: true,
        [CompareAction.DESELECT_ONLY_CHECKBOX_OPTION]: false
      };

      this.$modifierSubject.subscribe(change => {
        if (!change) {
          return;
        }
        this.modifierResults[change.key] = change.nextValue;
        const arg = this.getCompareActionArgument(this.modifierResults, this.controlModifier[change.key].compareType);
        this.controlModifier[change.key].compareActionFn(this.control, arg, modifierValueMap[arg]);
      });
    }

    this.$aggregatorSubject.subscribe(change => {
      if (!change) {
        return;
      }

      const aggregatorAction = () => {
        this.aggregatorResults[change.key] = change.nextValue;
        const arg = this.getCompareActionArgument(this.aggregatorResults, this.controlAggregator[change.key].compareType, change.nextValue);

        this.controlAggregator[change.key].compareActionFn(
          this.control,
          this.controlAggregator[change.key].compareValue || change.controlValue,
          arg);
      };

      // Aggregator funktio suoritetaan asynkronisena. Käyttäjälle vähemmän näkyvää viivettä.
      // HUOM! setTimeout silmin nähden nopeampi kuin Promise tai Observable.
      setTimeout(aggregatorAction, 0);
    });

  }

  /**
   * Päättelee, mikä toiminto annetuilla tuloksilla suoritetaan.
   *
   * @param resultMap - matcher tulokset, joissa avaimena lomakkeen kontrollin/groupin nimi ja arvona CompareAction.
   * @param compareType - EVERY: Kaikki arvot != NONE, SOME: Vähintään yksi arvo != NONE.
   */
  private getCompareActionArgument(resultMap: {[s: string]: CompareAction}, compareType: CompareType, nextValue?: CompareAction): CompareAction {
    if (compareType === CompareType.SINGLE) {
      return nextValue;
    }

    const allValues = Object.values(resultMap);
    const values = allValues.filter(v => v !== CompareAction.NONE);
    if (values.length === 0) {
      return CompareAction.NONE;
    } else if (compareType === CompareType.EVERY) {
      return allValues.length === values.length ? values[0] : CompareAction.NONE;
    } else {
      return values[0];
    }
  }
}
