import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Valvontakohde} from "./valvontakohde";
import {KoodistoService} from "../koodisto/koodisto.service";
import {BehaviorSubject} from "rxjs/index";
import {Toimiala} from "../account/account";
import {AccountService} from "../account/account.service";

@Injectable()
export class ValvontakohdeService {

  private _valvontakohde = new BehaviorSubject<Valvontakohde>(null);
  public valvontakohde$ = this._valvontakohde.asObservable();

  constructor(private http: HttpClient, private koodistoService: KoodistoService, private accountService: AccountService) {}

  cleanUp(): void {
    this._valvontakohde.next(null);
  }

  getValvontakohteet(): Promise<Valvontakohde[]> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    const url = `/api/v1/${role}/account/valvontakohteet`;

    return Promise.all([this.http.get(url).toPromise(), this.koodistoService.getPostinumerot()])
      .then(results => {
        const valvontakohteet = results[0] as Valvontakohde[];
        valvontakohteet.forEach(vk => {
          if (results[1][vk.toimipaikka.postinumero]) {
            vk.toimipaikka.postitoimipaikka = results[1][vk.toimipaikka.postinumero].postitoimipaikka;
          }
        });
        return valvontakohteet;
      });
  }

  getValvontakohdeDetails(valvontakohde, toimiala): Promise<Valvontakohde> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    const url = `/api/v1/${role}/account/valvontakohteet/${valvontakohde}/toimialat/${toimiala}`;
    return this.http.get(url)
      .toPromise()
      .then(result => result as Valvontakohde);
  }

  selectValvontakohde(valvontakohde: Valvontakohde, toimiala: Toimiala) {
    if (valvontakohde) {
      this.getValvontakohdeDetails(valvontakohde.elmoId, toimiala).then(response => {
        this._valvontakohde.next(response);
      });
    } else {
      this._valvontakohde.next(null);
    }
  }
}
