import {LomakeKasittely} from "../touko-lomake-utils";

export class YhteenvetoHtmlBuilder {

  private readonly title: string;
  private readonly kasittely: LomakeKasittely;
  private _content: any;

  private readonly originalInnerHTML: string;

  constructor(title: string, kasittely: LomakeKasittely, content: any) {
    this.title = title;
    this.kasittely = kasittely;
    this._content = content;
    this.originalInnerHTML = this._content.innerHTML;
  }

  applyYhteenvetoHTML() {
    const date = new Date().toLocaleString("fi");
    this._content.innerHTML = `<h2>${this.title}</h2>
                  <ul>
                    <li>Muodostettu: ${date}</li>
                    <li>Tila: ${this.kasittely.tila} </li>
                    <li>Lisätiedot: ${this.kasittely.lisatieto ? this.kasittely.lisatieto : '-'}</li>
                    <li>Lomakkeen tunnus Toukossa: ${this.kasittely.id}</li>
                    <li>Asiakkaan tunnus: ${this.kasittely.asiakasElmoid ? this.kasittely.asiakasElmoid : '-'}</li>
                  </ul>
                  <hr>
                  ${this.originalInnerHTML}`;
  }

  resetOriginalElement() {
    this._content.innerHTML = this.originalInnerHTML;
  }

  get content(): any {
    return this._content;
  }
}
