import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AccountService} from "../account/account.service";
import {MessageService} from "../message/message.service";
import {Observable} from "rxjs";
import {Teksti} from "../utils/teksti";

@Injectable()
export class ValvontakohdeGuardService implements CanActivate {

  constructor(private readonly accountService: AccountService,
              private readonly messageService: MessageService,
              private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const account = this.accountService.getCurrentAccount();

    if (this.accountService.isAsiakas() && account.toimiala && !account.valvontakohdeElmoId) {
      this.messageService.addMessage(new Teksti("Valitse asioinnin kohteena oleva toimipaikka tilin asetuksista.", "errValitseAsionninToimipaikka", "kayttajatili"));

      if (!this.router.url.endsWith('/asiointi/account')) {
        this.router.navigate(['/asiointi/account']);
      }
      return false;
    }

    return true;
  }

}
