import {Component} from "@angular/core";
import {LomakeInfo} from "../common/lomake-info";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {AccountService} from "../../../account/account.service";
import {LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {Teksti} from "../../../utils/teksti";
import {LannoiterekisteriV2Data} from "./lannoiterekisteri-v2-data";
import {AbstractField, FieldControl, FieldGroup} from "ngx-fielding";

@Component({
  selector: 'lannoiterekisteri-v2',
  templateUrl: 'lannoiterekisteri-v2.component.html',
  providers: [ProcessLomakeService]
})
export class LannoiterekisteriV2Component extends LomakeV2BaseDirective {
  alaokohdeToiminnot: {[s: string]: boolean} = {};

  constructor(protected readonly processLomakeService: ProcessLomakeService,
              protected readonly data: LannoiterekisteriV2Data,
              protected readonly accountService: AccountService) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  get lopetaMaahantuonti(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuonti.lopetaToiminta").asControl()
  }

  get lannoiteValmisteenMaahantuonti(): AbstractField[] {
    return Object.values(this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuonti").asGroup().controlFields).slice(1);
  }

  get lopetaMaastavienti(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenVienti.lopetaToiminta").asControl()
  }

  get lannoiteValmisteenVienti(): AbstractField[] {
    return Object.values(this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenVienti").asGroup().controlFields).slice(1);
  }

  get lopetaOmaKaytto(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuotantoOmaKaytto.lopetaToiminta").asControl()
  }

  get lannoiteValmisteenOmaKaytto(): AbstractField[] {
    return Object.values(this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuotantoOmaKaytto").asGroup().controlFields).slice(1);
  }

  get lopetaJakelija(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenJakelija.lopetaToiminta").asControl()
  }

  get lannoiteValmisteenJakelija(): AbstractField[] {
    return Object.values(this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenJakelija").asGroup().controlFields).slice(1);
  }

  get lopetaValmisteenValmistus(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenValmistus.lopetaToiminta").asControl()
  }

  get lannoiteValmisteenValmistus(): AbstractField[] {
    return Object.values(this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenValmistus").asGroup().controlFields).slice(1);
  }

  get lopetaVarastointi(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenVarastointi.lopetaToiminta").asControl()
  }

  get lannoiteValmisteenVarastointi(): AbstractField[] {
    return Object.values(this.lomake.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenVarastointi").asGroup().controlFields).slice(1);
  }

  ainesosaluokatAsArray(group: AbstractField): AbstractField[] {
    const ainesosaluokatGroup = group.get('ainesosaluokat');
    if (ainesosaluokatGroup) {
      return Object.values(ainesosaluokatGroup.asGroup().controlFields);
    }
  }

  get toimintaJaTuoteluokatEUToiminta(): AbstractField[] {
    return Object.values(this.lomake.get('toimintaJaTuoteluokatEUToiminta').asGroup().controlFields);
  }

  get lopetaEuMaahantuonti(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatEUToiminta.euLannoiteValmisteenTuonti.lopetaToiminta").asControl()
  }

  get euMaahantuonti(): AbstractField[] {
    return Object.values(this.lomake.get('toimintaJaTuoteluokatEUToiminta.euLannoiteValmisteenTuonti').asGroup().controlFields).slice(1);
  }

  get lopetaEuVienti(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatEUToiminta.euLannoitevalmisteenVieja.lopetaToiminta").asControl()
  }

  get euVienti(): AbstractField[] {
    return Object.values(this.lomake.get('toimintaJaTuoteluokatEUToiminta.euLannoitevalmisteenVieja').asGroup().controlFields).slice(1);
  }

  groupTuotteet(group): AbstractField[] {
    return Object.values(group.controlFields);
  }

  get lopetaEuJakelija(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatEUToiminta.euLannoitevalmisteenJakelija.lopetaToiminta").asControl()
  }

  get euJakelija(): AbstractField[] {
    return Object.values(this.lomake.get('toimintaJaTuoteluokatEUToiminta.euLannoitevalmisteenJakelija').asGroup().controlFields).slice(1);
  }

  get lopetaEuValmistaja(): FieldControl {
    return this.lomake.get("toimintaJaTuoteluokatEUToiminta.euLannoitevalmisteenValmistaja.lopetaToiminta").asControl()
  }

  get euValmistaja(): AbstractField[] {
    return Object.values(this.lomake.get('toimintaJaTuoteluokatEUToiminta.euLannoitevalmisteenValmistaja').asGroup().controlFields).slice(1);
  }

  get lopetaMuuToiminta(): FieldControl {
    return this.lomake.get("muuToiminta.lannoitevalmisteenMarkkinointi.lopetaToiminta").asControl()
  }

  get lopetaLannoitesivutuotetoimija(): FieldControl {
    return this.lomake.get("lannoitesivutuotetoimija.lannoitesivutuotetoimija.lopetaToiminta").asControl()
  }

  get lannoitesivutuotetoimija(): FieldGroup {
    return this.lomake.get('lannoitesivutuotetoimija.lannoitesivutuotetoimija').asGroup();
  }

  get kasiteltavatRaakaAineet(): AbstractField[] {
    const raakaAineet = this.lannoitesivutuotetoimija.get('kasiteltavatRaakaaineet')
    return Object.values(raakaAineet.asGroup().controlFields).filter(raakaAine => raakaAine.field.htmlId !== 'kasiteltavatRaakaaineet-muuInfo');
  }

  initLomakeBehaviour() {
    this.handleMuuInfoChanges();

    if (this.lomakeInfo.toimintotyyppi === 'muutos') {
      this.initAlakohdetoiminnot();
    }
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new LannoitevalvontaInfo(
      accountType,
      toimintotyyppi
    );
  }

  private initAlakohdetoiminnot() {
    this.alaokohdeToiminnot = {};
    this.accountService.getCurrentAccount().valvontakohde?.alakohteet
      .forEach(alakohde => this.alaokohdeToiminnot[alakohde.toiminta_elmoid.toLowerCase()] = "ReVStActive" === alakohde.toimintastatus)
  }

  getActiveString(toiminta): string {
    return this.alaokohdeToiminnot[toiminta] ? 'lomakeYleinen.aktiivinen' : null;
  }

  private handleMuuInfoChanges() {
    const lannSivutoimPath = 'lannoitesivutuotetoimija.lannoitesivutuotetoimija'
    this.muuInfoChanges(lannSivutoimPath + '.laitosHyvaksynta.laitosHyvaksyntaRadio', lannSivutoimPath + '.laitosHyvaksynta.laitosHyvaksyntaMuuInfo');
    this.muuInfoChanges(lannSivutoimPath + '.kasiteltavatRaakaaineet.muu', lannSivutoimPath + '.kasiteltavatRaakaaineet.muuInfo');
    this.muuInfoChanges(lannSivutoimPath + '.ymparistolupa.toiminnallaOnYmparistolupa', lannSivutoimPath + '.ymparistolupa.luvanNum');
  }

  private muuInfoChanges(subrcribedControlPath: string, targetControlPath: string) {
    if (this.lomake.get(subrcribedControlPath).value === 'muu' || this.lomake.get(subrcribedControlPath).value === true) {
      this.lomake.get(targetControlPath).enable();
    }

    this.lomake.get(subrcribedControlPath).valueChanges.subscribe(value => {
      if (value === 'muu' || value === true) {
        this.lomake.get(targetControlPath).enable();
      } else {
        this.lomake.get(targetControlPath).disable();
      }
    })
  }

  addLomakeBehaviour() {
    // Method not used/needed here.
  }
}

class LannoitevalvontaInfo implements LomakeInfo {
  asia: string;
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.VK_YH.value;
  lomakeTitle: Teksti = new Teksti("Lannoitevalmistetoimintaa koskeva ilmoitus", "otsikko", "lannoiterekisteri");
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: "as" | "vk", toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.VK_YH, YhteyshenkiloValue.VK_VARA_YH];
    this.asia = "lannoiterekisteri";
    this.toimintotyyppi = toimintotyyppi;
    const suffix = this.toimintotyyppi === "muutos" ? "_muutos" : "";

    const prefix = 'lannoiterekisteri.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1_1', '1_2'], suffix);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2_1', '2_2', '2_3'], suffix);
    this.ohjeet.sivu2 = LomakeHelper.getOhjeList(prefix, ['3_1'], suffix);
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/ilmoitukset/lannoiterekisteri/${toimintotyyppi}`;
  }
}
