<div class="row">
  <div class="col-12">
    <h1 attribute="raportit.otsikkoViljelys">Tulosraportit</h1>
  </div>
</div>

<!-- Rajaus -->
<form [formGroup]="raporttiForm" (submit)="submit()">
  <div class="row form-group">
    <div class="col-sm-6 col-lg-3">
      <label for="raportti-viljelystunnus" attribute="raportit.viljelysTunnus">Viljelystunnus</label>
      <input id="raportti-viljelystunnus" formControlName="viljelysTunnus" class="form-control" type="text">
    </div>
    <div class="col-sm-6 col-lg-3">
      <label for="raportti-viljelystarkastus-numero" attribute="raportit.viljelysNumero">Viljelystarkastusnumero</label>
      <input id="raportti-viljelystarkastus-numero" formControlName="viljelystarkastusNumero" class="form-control"
             type="text">
    </div>
    <div class="col-sm-6 col-lg">
      <label for="raportti-viljelija" attribute="raportit.viljelija">Viljelijä</label>
      <input id="raportti-viljelija" formControlName="viljelijaNimi" class="form-control" type="text">
    </div>

    <div class="col-sm-6 col-lg-2" *ngIf="tapahtumatyypitTags?.length > 1">
      <touko-taglist [item]="syotteet.tapahtumatyyppi"
                  [dropdownTeksti]="syotteet.tapahtumatyyppi.options.dropdownTeksti"
                  [formControlName]="'tapahtumatyyppi'"
                  [tags]="tapahtumatyypitTags"></touko-taglist>
    </div>
    <div class="col-sm-6 col-lg-2">
      <touko-taglist [item]="syotteet.kasvilaji"
                  [dropdownTeksti]="syotteet.kasvilaji.options.dropdownTeksti"
                  [formControlName]="'kasvilaji'"
                  [tags]="kasvilajiTags"></touko-taglist>
    </div>

    <div class="col-sm-6 col-lg">
      <label for="raportti-kasvilajike" attribute="raportit.kasvilajike">Kasvilajike</label>
      <input id="raportti-kasvilajike" formControlName="kasvilajike" class="form-control" type="text">
    </div>
  </div>
  <div class="row form-group">


    <div class="col-sm-12 col-md-6 col-lg-2">
      <touko-pvm [isInValid]="getInvalid('pvmAlku')" [htmlId]="'raportti-pvmAlku'" [item]="syotteet.pvmAlku"
              formControlName="pvmAlku"></touko-pvm>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">
      <touko-pvm [isInValid]="getInvalid('pvmLoppu')" [htmlId]="'raportti-pvmLoppu'" [item]="syotteet.pvmLoppu"
              formControlName="pvmLoppu"></touko-pvm>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">

      <button class="btn btn-outline-primary form-control no-label" type="button" (click)="reset()"
              id="raportti-tyhjenna"
              attribute="raportit.tyhjenna">
        Tyhjennä
      </button>
    </div>
  </div>
</form>


<raportti-sivutus></raportti-sivutus>

<div class="row form-group"></div>

<div class="row">
  <div class="col-sm-12">
    <datatable [showSpinner]="showLoader" [columnOrder]="columnOrder"
               [liiteDownloadFn]="liiteDownloadFn" [liiteZipDownloadFn]="liiteDownloadZipFn">
    </datatable>
  </div>
</div>
