import {Component, Input, OnInit} from "@angular/core";
import {TulosraporttiService} from "../raportti.service";
import {RaporttiHaku, Sarake, SarakeType, Tag} from "../raportti.utils";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Teksti} from "../../utils/teksti";
import {RaporttiBaseClass} from "../raportti-base";
import {Syote} from "../../touko-lomake/syote/syote";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";

const VIEW = "raportit";

@Component({
  selector: 'lannoite-raportti',
  templateUrl: './lannoite-raportti.component.html',
  styles: ['.no-wrap{ white-space: nowrap; }', 'th>span{cursor: pointer}']
})
export class LannoiteRaporttiComponent extends RaporttiBaseClass implements OnInit {

  @Input() getRaportitFn: (...any: any[]) => Promise<RaporttiHaku>;
  raporttiForm: FormGroup;
  keys: any;
  liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  syotteet: { [s: string]: Syote };
  tapahtumatyypitTags: Tag[] = [];
  tarkastustyypitTags: Tag[] = [];
  tilaTags: Tag[] = [];
  toimiala = "LANNOITE";

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
      this.fb.group({
        tunnus: null,
        otsikko: null,
        pvmAlku: [null, ToukoValidation.isValidDate],
        pvmLoppu: [null, ToukoValidation.isValidDate],
        tapahtumaliitos: [],
        tapahtumatyyppi: [],
        tila: [],
        tarkastustyyppi: []
      })
    );

    this.syotteet = {
      otsikko: new Syote(new Teksti('Otsikko', 'tapahtumanOtsikko', VIEW), null, null, null, null),
      tapahtumatyyppi: new Syote(new Teksti('Tapahtumatyyppi', 'tapahtumatyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTapahtumatyyppi', VIEW)}),
      tarkastustyyppi: new Syote(new Teksti('Tarkastustyyppi', 'tarkastustyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTarkastustyyppi', VIEW)}),
      tila: new Syote(new Teksti('Tila', 'tila', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTila', VIEW)}),
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };

    this.keys = {
      tunnus: new Sarake('tunnus', new Teksti(VIEW, 'tunnus', VIEW), SarakeType.DEFAULT, () => "no-word-break"),
      tapahtumanOtsikko: new Sarake('kuvaus', new Teksti(VIEW, 'tapahtumanOtsikko', VIEW)),
      kasiteltyAika: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      tila: new Sarake('tilaNimi', new Teksti(VIEW, 'tila', VIEW)),
      tapahtumatyyppi: new Sarake('tapahtumatyyppi', new Teksti(VIEW, "tapahtumatyyppi", VIEW), SarakeType.TYPE, () => 'badge badge-primary'),
      tarkastustyyppi: new Sarake('tarkastustyyppiNimi', new Teksti(VIEW, 'tarkastustyyppi', VIEW)),
      tapahtumaliitos: new Sarake('tapahtumaliitos', new Teksti(VIEW, 'tapahtumaliitos', VIEW), SarakeType.DEFAULT, () => "no-word-break"),
      asiakirja: this.getAsiakirjatSarakeTitle('asiakirjat')
    };

    this.columnOrder = [
      this.keys.tunnus,
      this.keys.tapahtumanOtsikko,
      this.keys.kasiteltyAika,
      this.keys.tila,
      this.keys.tapahtumatyyppi,
      this.keys.tarkastustyyppi,
      this.keys.tapahtumaliitos,
      this.keys.asiakirja
    ];

    this.liiteDownloadFn = this.tulosraporttiService.getLannoitevalvontaLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getLannoitevalvontaLiiteZip.bind(this.tulosraporttiService);
    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getLannoitevalvontaRaportit.bind(this.tulosraporttiService);

    this.initRaportit().then(() => {
      this.initTags();
    });
  }

  initTags() {
    this.tapahtumatyypitTags = this.mapField(r => new Tag(r.tapahtumatyyppiNimi, r.tapahtumatyyppi));
    this.tarkastustyypitTags = this.mapField(r => new Tag(r.tarkastustyyppiNimi, r.tarkastustyyppiNimi));
    this.tilaTags = this.mapField(r => new Tag(r.tilaNimi, r.tilaNimi));
  }
}
