<div class="card bg-light mb-4">
  <div class="card-body">
    <div class="card-title">
      <span [attribute]="viewOptions.titleAttribute">
        Lähetetyt
      </span>
    </div>
    <lomake-table [sarakkeet]="sarakkeet"
                  [viewOptions]="viewOptions.lomakeTableViewOptions"
                  (selectRow)="selectTableRow($event)"
                  (selectItem)="selectTableCell($event)"
                  [lomakkeet]="lomakkeet">
    </lomake-table>

    <div class="float-right">
      <button *ngIf="viewOptions.lahetetyyppi"
              (click)="selectShowMore()"
              class="btn btn-sm btn-outline-primary mr-2" [disabled]="lomakkeet.length < pageSize">
        Näytä enemmän
      </button>
      <a [routerLink]="viewOptions.actionLink"
         class="btn btn-sm btn-outline-primary float-right"
         [attribute]="viewOptions.actionAttribute">Luo uusi lähete</a>
    </div>

  </div>
</div>
