import {Component, OnInit} from "@angular/core";
import {AccountService} from "../../account/account.service";
import {LomakeService} from "../touko-lomake.service";
import {Subscription} from "rxjs";
import {LomakeResponse, LomakeTila} from "../touko-lomake-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../modaalit/modal-utils";
import {Teksti} from "../../utils/teksti";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

@Component({
  selector: "virkailija-uudelleenkasittely",
  template: `
    <div *ngIf='show'>
      <hr>
      <button class='btn btn-primary'
              (click)='otaUudelleenkasittelyyn()'
              id="uudelleenkasittely-btn"
              attribute='lomakeYleinen.vkUusiKasittelyPainike'>Ota uudelleenkäsittelyyn</button>
    </div>`
})
@AutoUnsubscribe
export class VirkailijaUudelleenkasittelyComponent implements OnInit {

  show = false;
  subs: Subscription[] = [];
  lomake: LomakeResponse = null;
  validTilat = new Set([LomakeTila.KASITELTY, LomakeTila.HYLATTY, LomakeTila.ODOTTAA_LISATIETOJA])

  constructor(private accountService: AccountService,
              private lomakeService: LomakeService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.accountService.isViranomainen()) {

      this.subs.push(this.lomakeService.currentLomake$.subscribe(lomake => {
        if (lomake && lomake.alkuperainenLomakeId === null) {
          this.lomake = lomake;
          this.show = this.validTilat.has(LomakeTila[lomake.tila]);

          // Luomun erikoiskäsittely
          if (this.show && this.accountService.getCurrentAccount().toimiala === 'LUOMU') {
            this.show = this.accountService.isElyViranomainen();
          }
        } else {
          this.show = false;
        }
      }));
    }
  }

  otaUudelleenkasittelyyn() {
    const view = "lomakeYleinen";
    const modalRef = this.modalService.open(ModalSimpleComponent);
    const modalData = new ModalData(
      new Teksti("Otsikko", "vkModalUusiKasittelyOtsikko", view),
      new Teksti("Kuvaus", "vkModalUusiKasittelyKuvaus", view),
      new Teksti("Ota käsittelyyn", "vkUusiKasittelyPainike", view),
      () =>  LomakeEndpointUtil.shouldUseV2Endpoint(this.lomake.asia, this.lomake.lomaketyyppiVersio) ?
        this.lomakeService.otaLomakeUudelleenkasittelyynV2(this.lomake.id, this.lomake.asia, this.lomake.toimintotyyppi) :
        this.lomakeService.otaLomakeUudelleenkasittelyyn(this.lomake.id)
      );
    modalRef.componentInstance.modalTiedot = modalData;
  }
}
