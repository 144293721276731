import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AccountService} from "../account/account.service";
import {Observable} from "rxjs";
import {Teksti} from "../utils/teksti";
import {MessageService} from "../message/message.service";

@Injectable()
export class RaporttiGuardService implements CanActivate {
  constructor(private accountService: AccountService, private messageService: MessageService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const account = this.accountService.getCurrentAccount();

    if (this.accountService.isViranomainen() && account.toimiala) {
      return true;
    }

    const isRaporttiUrl = route.url[0].path === "raportit";
    if (isRaporttiUrl && route.queryParams.hasOwnProperty("vk")) {
      return true;
    }

    if (!account.toimiala) {
      this.messageService.addMessage(new Teksti("Valitse asiontiasi kuvaava toimiala", "valitseToimiala", "kayttajatili"));
      this.router.navigate(['/asiointi/account']);
      return false;
    }

    if (!account.valvontakohdeElmoId) {
      this.messageService.addMessage(new Teksti("Valitse asioinnin kohteena oleva toimipaikka tilin asetuksista.", "errValitseAsionninToimipaikka", "kayttajatili"));
      this.router.navigate(['/asiointi/account']);
      return false;
    }

    return true;
  }
}
