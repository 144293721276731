<h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey">Lannoitevalmisteen tuotanto maatilalla omaan
  käyttöön</h3>
<lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                     [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
<div class="row" *ngFor="let lannoiteAine of item.subitems">
  <div class="col-full col-lvl-1">
    <h4 [attribute]="lannoiteAine.label.fullKey"></h4>
  </div>

  <ng-container *ngIf="lannoiteAine.valueMap?.maara">
    <div class="col-full col-lvl-2">
      <h5 attribute="lannoiterekisteri.arvioidutMaaratYhteenveto">Arvioidut määrät</h5>
    </div>
    <div class="col-left col-lvl-2">
      <p attribute="lannoiterekisteri.arvioVuosittaisistaValmistusmaarista">
        Vuosittainen valmistusmäärä
      </p>
    </div>
    <div class="col-right">
      <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
        <span class="mr-1"
              id="{{item.label.key}}-{{lannoiteAine.label.key}}-valmistusMaara">{{lannoiteAine.valueMap.maara.label}}</span>
        <span attribute="lannoiterekisteri.kgVuosi"></span>
      </div>
    </div>
  </ng-container>

  <div class="col-full col-lvl-2" *ngIf="lannoiteAine?.valueMap?.omaKaytto || lannoiteAine?.valueMap?.vainPakkaamista">
    <h5 attribute="lannoiterekisteri.valmistus">Valmistus</h5>
  </div>
  <ng-container *ngIf="lannoiteAine?.valueMap?.omaKaytto">
    <div class="col-left col-lvl-2">
      <p attribute="lannoiterekisteri.lannoitteenValmistusOmaanKayttoon">
        Lannoitteen valmistus vain omaan käyttöön?
      </p>
    </div>
    <div class="col-right">
      <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
        <span id="{{lannoiteAine.label.key}}-lannoitteenValmistusOmaanKayttoon"
           [attribute]="lannoiteAine?.valueMap?.omaKaytto?.fullKey"></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="lannoiteAine?.valueMap?.vainPakkaamista">
    <div class="col-left col-lvl-2">
      <p attribute="lannoiterekisteri.lannoitteenValmistusAinoastaanPakkaamista">
        Lannoitteen valmistus on ainoastaan pakkaamista?
      </p>
    </div>
    <div class="col-right">
      <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
      <span id="{{lannoiteAine.label.key}}-lannoitteenValmistusAinoastaanPakkaamista"
            [attribute]="lannoiteAine?.valueMap?.vainPakkaamista?.fullKey"></span>
      </div>
    </div>
  </ng-container>

  <div class="col-full col-lvl-2" *ngIf="lannoiteAine.subitems.length > 0">
    <h5 attribute="lannoiterekisteri.ainesosaluokatYhteenveto">Ainesosaluokat</h5>
    <ul class="list-unstyled mb-3 ml-4" *ngFor="let aol of lannoiteAine.subitems">
      <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
        <span [attribute]="aol.label.fullKey"
              id="ainesosaluokka-{{item.label.key}}-{{lannoiteAine.label.key}}-{{aol.label.key}}"></span>
      </div>
    </ul>
  </div>
</div>
