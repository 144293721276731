<div *ngIf="show">
  <hr/>

  <ng-container *ngIf="!isViimeksiMuokannut">
    <div class="alert alert-warning mb-2" *ngIf="isKasittelyssa">
      <span attribute="sivupalkki.modalPoistaLukitus"></span><em> {{muokannutToimija}}</em>
    </div>
    <button id="otaKasittelyyn" attribute="teksti.tarkastusOtaKasittelyyn" class="btn btn-primary" (click)="otaKasittelyyn()">Ota lomake käsittelyyn</button>
  </ng-container>

  <ng-container *ngIf="isViimeksiMuokannut">

    <h1 [attribute]="'lomakeYleinen.virkailijaOtsikko'">Lomakkeen käsittely</h1>

    <form [formGroup]="kasittelyForm" (submit)="submitKasittely(kasittelyForm)">
      <div class="form-group col-lg-9">
        <ng-container *ngIf="lomake.toimintotyyppi === 'UUSI'">
          <p attribute="lomakeYleinen.virkailijaAsiakasIdOhjeUusi" [markdown]="true">
            Tarkista, löytyykö annettujen perustietojen mukaista asiakasta Elmosta. Mikäli
            asiakas löytyy, syötä asiakkaan Elmo-asiakastunnus. Tällöin Elmoon luodaan uusi valvontakohde, johon
            on linkitetty annettu asiakas sekä uusi toimipaikka.

            Muussa tapauksessa jätä tämä kenttä tyhjäksi.
          </p>
        </ng-container>

        <ng-container *ngIf="lomake.toimintotyyppi === 'MUUTOS'">
          <p attribute="lomakeYleinen.virkailijaAsiakasIdOhjeMuutos" [markdown]="true">
            Tarkista, onko asiakastunnus liitetty muihin valvontakohteisiin. Jos valvontakohteita ei löydy, lomake voidaan
            hyväksyä. Mikäli muita valvontakohteita löytyy, voi virkailija toimia seuraavilla tavoilla:
            - Luoda tällä lomakkeella uuden valvontakohteen tyhjentämällä Elmo-asiakastunnus -kentän arvon.
            - Tehdä Elmossa löydetystä valvontakohteesta uuden valvontakohteen.
          </p>
        </ng-container>

        <label for="elmo-asiakas">Lähettäjän Elmo-asiakastunnus</label>
        <input #asiakasElmoidInput="ngbTypeahead"
               id="elmo-asiakas"
               [ngbTypeahead]="searchAsiakas"
               formControlName="asiakasid"
               class="form-control"
               type="text"
               (focus)="focus$.next($event.target.value)"
               (click)="click$.next($event.target.value)"
               (selectItem)="selectItem($event)"
               [resultFormatter]="resultFormatter">
      </div>
      <div class="form-group col-lg-9">
        <div class="form-check">
          <input name="paatos" class="form-check-input" formControlName="paatos" type="radio" [value]="'KASITELTY'"
                 id="hyvaksy" value="KASITELTY" (change)="showSendToElmoOption(true)" >
          <label for="hyvaksy" class="form-check-label" [attribute]="'lomakeYleinen.hyvaksyLomake'">Hyväksy
            lomake</label>
        </div>
        <div class="form-check">
          <input name="paatos" class="form-check-input" formControlName="paatos" type="radio"
                 [value]="'ODOTTAA_LISATIETOJA'" value="ODOTTAA_LISATIETOJA" id="pyydaLisatieto"
                 (change)="showSendToElmoOption(false)">
          <label for="pyydaLisatieto" class="form-check-label" [attribute]="'lomakeYleinen.pyydaLisatieto'">Pyydä
            lisätietoja</label>
        </div>
        <div class="form-check">
          <input name="paatos" class="form-check-input" formControlName="paatos" type="radio" [value]="'HYLATTY'"
                 value="HYLATTY" id="hylkaa" (change)="showSendToElmoOption(false)">
          <label for="hylkaa" class="form-check-label" [attribute]="'lomakeYleinen.hylkaaLomake'">Hylkää
            lomake</label>
        </div>
      </div>
      <div class="form-group col-lg-9">
        <label for="lisatietoPerustelu" [attribute]="'lomakeYleinen.lisatietoPerustelu'">Lisätieto / perustelu</label>
        <textarea formControlName="lisatieto" name="lisatieto" class="form-control" id="lisatietoPerustelu"></textarea>
      </div>

      <div class="form-group col-lg-9">
          <touko-checkbox  *ngIf="lomake.toimintotyyppi !== 'LOPETUS'" htmlId="sendMail" type="checkbox"  formControlName="sendMail" labelAttribute="lomakeYleinen.lahetaSposti"></touko-checkbox>
          <touko-checkbox *ngIf="lomake.toimintotyyppi === 'LOPETUS'" htmlId="sendMail" type="checkbox" formControlName="sendMail" labelAttribute="lomakeYleinen.lahetaSpostiIlmoituksenYhteyshenkilolle"></touko-checkbox>
          <touko-checkbox *ngIf="showSendElmoCheckbox" htmlId="sendToElmo" type="checkbox" formControlName="sendToElmo" labelAttribute="lomakeYleinen.sendToElmo"></touko-checkbox>
      </div>

      <div class="mb-2 col-lg-9" *ngIf="!kasittelyForm.valid && (kasittelyForm.dirty || kasittelyForm.touched)">
        <div *ngFor="let virhe of getVirheet()" class="alert alert-warning" [attribute]="virhe.getFullKey()">
        </div>
      </div>

      <div class="form-group col-lg-9">
        <button id="kasittelyLaheta" type="submit" class="btn btn-primary" [disabled]="!kasittelyForm.valid">
          <span [attribute]="'teksti.laheta'">Lähetä</span>
          <em class="fa fa-paper-plane"></em>
        </button>
        <button id="kasittelyMuokkaa" type="button" class="btn btn-success ml-2" (click)="muokkaaViranomaisena()">
          <em class="fa fa-edit"></em>
          <span [attribute]="'lomakeYleinen.muokkaa'">Muokkaa</span>
        </button>
      </div>
    </form>
  </ng-container>
</div>
