import {Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild} from "@angular/core";
import {CompareStatus, YhteenvetoItem} from "./yhteenveto-utils";
import {YhteenvetoItemComponent} from "./yhteenvet-item/yhteenveto-item.component";
import {YhteenvetoComponentHostDirective} from "./yhteenveto-component-host.directive";

@Component({
  selector: 'yhteenveto-container',
  template: '<ng-container yhteenveto-component-host></ng-container>'
})
export class YhteenvetoContainerComponent implements OnInit {
  @ViewChild(YhteenvetoComponentHostDirective, {static: true}) componentHost: YhteenvetoComponentHostDirective;
  @Input() item: YhteenvetoItem = null;
  @Input() prevItem: YhteenvetoItem = null;
  @Input() compareStatus?: CompareStatus = null;
  @Input() showCompareYhteenveto = false;

  component: Type<YhteenvetoComponentBase> = YhteenvetoItemComponent;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();

    const component = this.componentFactoryResolver.resolveComponentFactory(this.item.component);
    const componentRef = viewContainerRef.createComponent(component);
    componentRef.instance.item = this.item;
    componentRef.instance.compareStatus = this.compareStatus;
    componentRef.instance.prevItem = this.prevItem;
    componentRef.instance.showCompareYhteenveto = this.showCompareYhteenveto;
  }
}

export interface YhteenvetoComponentBase {
  item?: YhteenvetoItem;
  compareStatus?: CompareStatus;
  prevItem?: YhteenvetoItem;
  showCompareYhteenveto: boolean;
}
