<div class="row">
  <div class="col-left">
    <h3 [attribute]="item.label.fullKey">Lannoitevalmisteen maahantuonti</h3>
  </div>
  <div class="col-right">
    <p>
      <span class="badge badge-success" attribute="lannoiterekisteri.yhtUusiIlmoitettavaToiminta">Uusi ilmoitettava toiminta</span>
    </p>
  </div>
</div>
