import {Component, Input} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-tausta-text-input',
  template: `
    <ng-container [formGroup]="controlContainer.control">
      <div class="form-group" *ngFor="let control of questionGroup.controls">
        <label [htmlFor]="control.key">{{control.label}}</label>
        <input class="form-control" type="text" [id]="control.key" [formControlName]="control.key">
      </div>
    </ng-container>
  `
})
export class QuestionTaustaTextInputComponent {
  @Input() questionGroup: QuestionGroup;

  constructor(public controlContainer: ControlContainer) {
  }
}
