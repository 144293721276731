import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, forwardRef, Input, Renderer2, ViewChild} from "@angular/core";
import {Tag} from "../raportti/raportti.utils";
import {Syote} from "../touko-lomake/syote/syote";
import {Teksti} from "../utils/teksti";

/**
 * Created by Seppo on 28/05/2018.
 */

@Component({
  selector: 'touko-taglist',
  template: `
    <label *ngIf="item" [attribute]="item?.getLabelKey()">{{item?.label.label}}</label>
    <div ngbDropdown>
      <button type="button" class="btn btn-outline-primary" [id]="'taglist-'+item?.label.fullKey" ngbDropdownToggle #dropdown
              [attribute]="dropdownTeksti.fullKey">{{dropdownTeksti.label}}
      </button>
      <div ngbDropdownMenu>
        <button type="button" class="dropdown-item" *ngFor="let tag of tags" (click)="selectTag(tag)">
          {{tag.nimi}}
        </button>
      </div>
    </div>
    <button *ngFor="let tag of fieldValue" (click)="removeTag(tag)"
            [id]="'taglist-remove-'+tag.nimi"
            class="btn btn-sm mr-1 mt-1" [ngClass]="tag.style">
      <span class="fa fa-times"></span> {{tag.nimi}}
    </button>
  `,
  styles: ['button{width:100%;}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagListComponent),
      multi: true
    }
  ],
  host: {
    '(change)': '_onChange($event)'
  }
})
export class TagListComponent implements ControlValueAccessor {

  @Input() htmlId: string;
  @Input() item: Syote;
  @Input() dropdownTeksti: Teksti;
  @Input() tags: Tag[];
  @Input() fieldValue: Tag[] = []; // TODO: Muuta arvo samaan tyyppin, missä data lähetetään palvelimelle -> string[]
  @Input() value = "";
  @ViewChild("dropdown", { static: true }) dropdown: ElementRef;

  textValue: string;
  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  constructor(private _renderer: Renderer2) {

  }

  removeTag(tag: Tag) {
    this.writeValue(tag);
  }

  selectTag(tag: Tag) {
    this.writeValue(tag);
  }

  writeValue(tag: Tag): void {
    if (tag === null) {
      this.fieldValue = [];
      this._onChange(null);
      return;
    }

    if (this.mapFieldValues().indexOf(tag.value) < 0) {
      this.fieldValue.push(tag);
      this._onChange(this.mapFieldValues());
    } else if (this.mapFieldValues().indexOf(tag.value) >= 0) {
      this.fieldValue = this.fieldValue.filter(t => t.value !== tag.value);
      this._onChange(this.mapFieldValues());
    }
    this.clearText();
  }

  clearText() {
    setTimeout(() => this.textValue = "", 1);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this.dropdown.nativeElement, 'disabled', isDisabled);
  }

  mapFieldValues() {
    return this.fieldValue.map(t => t.value);
  }

}
