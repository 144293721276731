

<div>


  <div class="row">
    <div class="col touko-lomake">
      <input class="form-control"
             #ref
             [ngbTypeahead]="haeKasvilaji"
             [editable]="true"
             [resultFormatter]="muotoileKasvilaji"
             [inputFormatter]="muotoileKasvilaji"
             [showHint]="false"
             (selectItem)="onSelectKasvilaji($event, ref);"
      >
    </div>

  </div>

  <div class="row" *ngIf="value.length > 0">
    <div class="col">
      <div class="lajilista">
        <div *ngFor="let laji of value; index as idx;" class="row">
          <div class="col">
            <div class="laji pr-1 pl-1">
              <span>{{laji.nimi}}</span>
              <button type="button" class="close stretched-link" aria-label="Remove" (click)="poistaLaji(idx)">
                <span class="fa fa-minus-circle fa-xs" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
