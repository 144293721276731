import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareAction, MatcherModel} from "ee-lahete";
import {ValueAndLabel} from "../lahete-data.model";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {InitValueType, QuestionInitValue} from "ee-lahete";

export class TaustakysymyksetGroup extends AbstractLaheteGroup {

  getGroup(controlType = '', builder = new GroupBuilder({controlType: 'tausta'})): QuestionGroup {
    const tausta4Controls = [
      new QuestionControl({
        controlType: 'checkbox',
        key: 'tausta4-hengitystieoireet',
        label: this.getLabel('tausta4Hengitystieoireet')
      }),
      new QuestionControl({controlType: 'checkbox', key: 'tausta4-ripuli', label: this.getLabel('tausta4Ripuli')}),
      new QuestionControl({
        controlType: 'checkbox',
        key: 'tausta4-niveloireita',
        label: this.getLabel('tausta4Niveloireita')
      }),
      new QuestionControl({controlType: 'checkbox', key: 'tausta4-hermosto-oireet', label: this.getLabel('tausta4HermostoOireet')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta4-huono-kasvu', label: this.getLabel('tausta4HuonoKasvu')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta4-luomiset', label: this.getLabel('tausta4Luomiset')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta4-kuolleisuus-noussut', label: this.getLabel('tausta4KuolleisuusNoussut')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta4-ei-oireita', label: this.getLabel('tausta4EiOireita')}),
      new QuestionControl({
        controlType: 'checkbox',
        key: 'tausta4-muu-check',
        label: this.getLabel('muu'),
        options: {
          link: 'tausta4-muu-text',
          hideInSummary: () => true
        }
      }),
      new QuestionControl({
        controlType: 'textarea',
        key: 'tausta4-muu-text',
        options: {hideInSummary: (controlValue: any) => !controlValue || controlValue.length === 0}
      })
    ];

    const tausta6Controls = [
      new QuestionControl({controlType: 'selection', key: 'elaimenTuhkaus', label: this.getLabel('tausta6ElaimenTuhkaus'), initialValue: builder.applyValues ? 'yhteistuhkausHavityslaitos' : null, options: {
          selection: [
            new ValueAndLabel('yhteistuhkausHavityslaitos', this.getLabel('tausta6YhteistuhkausHavityslaitos')),
            new ValueAndLabel('yhteistuhkausTuhkaamo', this.getLabel('tausta6YhteistuhkausTuhkaamo')),
            new ValueAndLabel('yksilotuhkausTuhkaamo', this.getLabel('tausta6YksilotuhkausTuhkaamo'))
          ]
        }}),
      new QuestionControl({controlType: 'text-input', key: 'tuhkaamonNimi', label: this.getLabel('tausta6TuhkaamonNimi')})
    ];

    const tausta7Controls = [
      new QuestionControl({controlType: 'textarea', key: 'tausta7-kuolleita', label: this.getLabel('tausta7kuolleita')}),
      new QuestionControl({controlType: 'textarea', key: 'tausta7-kuolleisuus', label: this.getLabel('tausta7kuolleisuus')}),
    ];

    const tausta8Controls = [
      new QuestionControl({controlType: 'text-input', key: 'tausta8-parvitunniste', label: this.getLabel('tausta8Parvitunniste')}),
      new QuestionControl({controlType: 'text-input', key: 'tausta8-parvenika', label: this.getLabel('tausta8ParvenIka')}),
    ];

    const tausta9Controls = [
      new QuestionControl({controlType: 'selection', key: 'alkuperamaa', label: this.getLabel('tausta9TuontiAlkionsiirto'), options: {
          selection: this.laheteKoodisto.koodisto.maaluettelo.map(maa => new ValueAndLabel(maa.id, this.getLocalizedOf(maa, 'kuvaus')))
        }
      })
    ];

    const tausta12Controls = [
      new QuestionControl({ controlType: 'checkbox', key: 'bakvirtutkimus', label: this.getLabel('tausta12bakvirtutkimus')}),
      new QuestionControl({ controlType: 'checkbox', key: 'laajatutkimus', label: this.getLabel('tausta12laajatutkimus')}),
    ];

    const tausta16Controls = [
      new QuestionControl({controlType: 'text-input', key: 'tausta16-tilatunnus', label: this.getLabel('tausta16Tilatunnus')}),
      new QuestionControl({controlType: 'text-input', key: 'tausta16-pitopaikkatunnus', label: this.getLabel('tausta16Pitopaikkatunnus')}),
    ];

    const tausta18Controls = [
      new QuestionControl({controlType: 'checkbox', key: 'tausta18-tyyppi', label: this.getLabel('tausta18Tyyppi')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta18-sijainti', label: this.getLabel('tausta18SijaintiTarhalla')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta18-sukupuoli', label: this.getLabel('tausta18Sukupuoli')}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta18-ika', label: this.getLabel('tausta18Ika'), options: {link: 'tausta18-muu'}}),
      new QuestionControl({controlType: 'checkbox', key: 'tausta18-muu-check', label: this.getLabel('muu'), options: {link: 'tausta18-muu-text'}}),
      new QuestionControl({controlType: 'textarea', key: 'tausta18-muu-text'}),
    ];

    const tausta19Group = new QuestionGroup({
      key: 'tausta19',
      groupComponentType: 'tausta-group-nested',
      filters: {'tutkimukset': new MatcherModel('tausta19', this.filterGroup, TaustakysymyksetGroup.filterActionFn)},
      groups: [
        new QuestionGroup({
          key: 'tausta19-ikaluokka',
          groupComponentType: 'tausta-radio-date-text',
          label: this.getLabel('tausta19Ikaluokka'),
          controls: [
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode01', htmlId: 'tausta19-ikaluokka-0', label: '0'}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode02', htmlId: 'tausta19-ikaluokka-0+', label: '0+'}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode03', htmlId: 'tausta19-ikaluokka-1', label: '1'}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode04', htmlId: 'tausta19-ikaluokka-1+', label: '1+'}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode06', htmlId: 'tausta19-ikaluokka-2', label: '2'}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode07', htmlId: 'tausta19-ikaluokka-2+', label: '2+'}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-ikaluokka-radio', value: 'ReVAgeCode21', htmlId: 'tausta19-ikaluokka-emo', label: this.getLabel('tausta19Emokala')}),
          ]
        }),
        new QuestionGroup({
          key: 'tausta19-allastyyppi',
          groupComponentType: 'tausta-radio-date-text',
          controls: [
            new QuestionControl({controlType: 'text-input', key: 'tausta19-allastyyppi-id', label: this.getLabel('tausta19AllasTaiLammikkotyyppi')}),
          ]
        }),
        new QuestionGroup({
          key: 'tausta19-vedenotto',
          groupComponentType: 'tausta-radio-date-text',
          label: this.getLabel('tausta19Vedenotto'),
          controls: [
            new QuestionControl({controlType: 'radio', key: 'tausta19-vedenotto-radio', value: 'lahde', htmlId: 'tausta19-vedenotto-lahde', label: this.getLabel('tausta19Lahde')}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-vedenotto-radio', value: 'pohjavesi', htmlId: 'tausta19-vedenotto-pohjavesi', label: this.getLabel('tausta19Pohjavesi')}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-vedenotto-radio', value: 'joki', htmlId: 'tausta19-vedenotto-jarvi-joki', label: this.getLabel('tausta19JarviJoki')}),
            new QuestionControl({controlType: 'radio', key: 'tausta19-vedenotto-radio', value: 'meri', htmlId: 'tausta19-vedenotto-meri', label: this.getLabel('tausta19Meri')}),
            new QuestionControl({controlType: 'text-input', key: 'tausta19-vedenotto-lampotila', label: this.getLabel('tausta19VedenLampotila')}),
            new QuestionControl({controlType: 'text-input', key: 'tausta19-vedenotto-ph', label: 'pH'}),
            new QuestionControl({controlType: 'text-input', key: 'tausta19-vedenotto-o2', label: 'O2'}),
          ]
        }),
      ]
    });

    const tausta20Controls = [
      new QuestionControl({controlType: 'text-input', key: 'tausta20-vesisto', label: this.getLabel('tausta20Vesisto')}),
      new QuestionControl({controlType: 'text-input', key: 'tausta20-lukumaara', label: this.getLabel('tausta20Lukumaara')}),
    ];

    const tausta23Controls = [
      new QuestionControl({controlType: 'radio', key: 'tausta23-naytteenkeruualue', value: 'riistanhoitoyhdistys', htmlId: 'tausta23-riistanhoitoyhdistys', label: this.getLabel('tausta23Riistanhoitoyhdistys')}),
      new QuestionControl({controlType: 'radio', key: 'tausta23-naytteenkeruualue', value: 'paliskunta', htmlId: 'tausta23-paliskunta', label: this.getLabel('tausta23Paliskunta')}),
      new QuestionControl({controlType: 'radio', key: 'tausta23-naytteenkeruualue', value: 'tarha', htmlId: 'tausta23-tarha', label: this.getLabel('tausta23Tarha')}),
      new QuestionControl({controlType: 'text-input', key: 'tausta23-nimi', label: this.getLabel('tausta23Nimi')})
    ];

    const tausta25Controls = [
      new QuestionControl({controlType: 'checkbox', key: 'tausta25-check', label: this.getLabel('tausta25Viranomaisnaytteenotto'), options: {link: 'tausta25-text'}}),
      new QuestionControl({controlType: 'textarea', key: 'tausta25-text', label: this.getLabel('tausta25Nimi')}),
    ];

    const group = new QuestionGroup({
      key: 'taustakysymykset',
      label: this.getLabel('taustakysymykset'),
      groups: [
        this.createTaustakysymysGroup('tausta16', 'tausta-select-text', tausta16Controls),
        this.createTaustakysymysGroup('tausta1', 'tausta-textarea', this.createTextareaControl('taustatiedot', this.getLabel('tausta1Taustatiedot'))),
        this.createTaustakysymysGroup('tausta3', 'tausta-textarea', this.createTextareaControl('rokotustiedot', this.getLabel('tausta3Rokotustiedot'))),
        this.createTaustakysymysGroup('tausta4', 'tausta-checkbox-textarea', tausta4Controls, this.getLabel('tausta4Otsikko')),
        this.createTaustakysymysGroup('tausta6', 'tausta-select-text', tausta6Controls),
        this.createTaustakysymysGroup('tausta7', 'tausta-textarea', tausta7Controls),
        this.createTaustakysymysGroup('tausta8', 'tausta-select-text', tausta8Controls),
        this.createTaustakysymysGroup('tausta9', 'tausta-select-text', tausta9Controls),
        this.createTaustakysymysGroup('tausta12', 'tausta-checkbox-textarea', tausta12Controls),
        this.createTaustakysymysGroup('tausta18', 'tausta-checkbox-textarea', tausta18Controls, this.getLabel('tausta18SairastuneitaYhdistavatTekijat')),
        tausta19Group,
        this.createTaustakysymysGroup('tausta20', 'tausta-select-text', tausta20Controls),
        this.createTaustakysymysGroup('tausta23', 'tausta-radio-date-text', tausta23Controls, this.getLabel('tausta23Naytteenkeruualue')),
        this.createTaustakysymysGroup('tausta25', 'tausta-checkbox-textarea', tausta25Controls),
        this.createTaustakysymysGroup('tausta26', 'tausta-alert', [], this.getLabel('tausta26Kopio'))
      ],
      filters: {
        'tutkimukset': new MatcherModel(null, AbstractLaheteGroup.anyObjectValueTrue, AbstractLaheteGroup.filterActionFn)
      }
    });

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(
        new QuestionInitValue(group, builder.initValue(val => val.taustakysymykset), InitValueType.PATCH)
      );
    }

    return group;
  }

  createTaustakysymysGroup = (key, groupComponentType, controls: QuestionControl[], title = null) => {
    return new QuestionGroup({
      key: key,
      label: title,
      groupComponentType: groupComponentType,
      filters: {
        'tutkimukset': new MatcherModel(key, this.filterGroup, TaustakysymyksetGroup.filterActionFn)
      },
      controls: controls
    });
  }

  private createTextareaControl = (key: string, label?: string) => {
    return [new QuestionControl({
      controlType: 'textarea',
      key: key,
      label: label
    })];
  }

  filterGroup = (compareVal, newVal): CompareAction => {
    const allSelectedIds = Object.keys(newVal).filter(key => newVal[key]);

    const selectedTaustakysymykset = allSelectedIds.reduce((wholeSet, id) => {
      const lisakysymykset = this.laheteKoodisto.dataObj[id].lisakysymykset;
      if (lisakysymykset) {
        lisakysymykset.forEach(taustakysymys => wholeSet.add(taustakysymys));
        return wholeSet;
      } else {
        console.error('tutkimussyykoodi puuttuu:');
        console.error(this.laheteKoodisto.dataObj[id]);
      }
    }, new Set());

    return selectedTaustakysymykset ? TaustakysymyksetGroup.getFilterReturnVal(selectedTaustakysymykset.size > 0 && selectedTaustakysymykset.has(compareVal)) : CompareAction.NONE;
  }
}
