import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareAction, MatcherModel, ModelFragmentType, TopicListener} from "ee-lahete";
import {FormArray, FormControl} from "@angular/forms";
import {NayteTutkimusData, NayteTutkimusValue, ValueAndLabel} from "../lahete-data.model";
import {LaheteData, Naytemateriaali, Tiedoksisaaja, Tutkimussyy} from "../lahete-koodisto";
import {LaheteLanguage} from "../lahete.service";
import {Translations} from "../translations";
import {InitValueType, QuestionInitValue, QuestionUpdate, QuestionUpdateType} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {QuestionArray} from "ee-lahete";
import {PathUtil} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {NaytetiedotValidators} from "../validators/naytetieto-validators";

// @dynamic
export class NaytetiedotGroup extends AbstractLaheteGroup {

  private static readonly controlTypeMap = new Map<string, string>([
    ['teksti', 'text'],
    ['vapaa teksti', 'text'],
    ['vapaa teksti, ohjattu pituus', 'text'],
    ['valintalista', 'select'],
    ['valintalista, vapaa teksti', 'select'],
    ['valintalista?', 'select'],
    ['pvm', 'pvm']
  ]);

  private readonly nayteSingle = 'nayte-single';

  private selectedElainlaji: string = null;
  private selectedTaustakysymykset: Set<string> = new Set();
  private nayteValintalistat: { [s: string]: ValueAndLabel[] } = {};
  private taustakysymysControls: { [s: string]: (parentId: string) => QuestionGroup } = {};

  /**
   * Hakee listan tutkimuksen näytemateriaalit, tutkimussyyt ja tiedoksisaajan sekä niiden oletusarvot.
   * HUOM! Staattinen metodi huomattavasti nopeampi, kuin käyttää `mapNayteTutkimusData.bind(this)`
   *
   * @param tutkimus - Tutkimuksen tiedot
   * @param tutkimussyyKoodisto - Tutkimussyyt koodisto
   * @param naytemateriaaliKoodisto - Näytemateriaalit koodisto
   * @param tiedoksisaajaKoodisto - Tiedoksisaajat koodisto
   * @param nayteId - Näytteen tunnus
   * @param lang - kielivalinta
   *
   * @return Näytteen tiedot. Sisältää oletusarvot ja mahdolliset valinnat.
   */
  static mapNayteTutkimusData = (tutkimus: LaheteData,
                                 tutkimussyyKoodisto: Tutkimussyy[],
                                 naytemateriaaliKoodisto: Naytemateriaali[],
                                 tiedoksisaajaKoodisto: Tiedoksisaaja[],
                                 lang: LaheteLanguage,
                                 nayteId = null): NayteTutkimusData => {

    const tutkimussyyValue = tutkimus.tutkimussyyKoodi.length === 1 ? tutkimus.tutkimussyyKoodi[0] : null;
    const naytemateriaaliValue = tutkimus.naytemateriaaliProvet.length === 1 ? tutkimus.naytemateriaaliProvet[0] : null;
    const tiedoksisaajaValue = tutkimus.automTiedoksisaaja || null;

    const tutkimussyyt: ValueAndLabel[] = NaytetiedotGroup.mapTutkimussyytData(tutkimus, tutkimussyyKoodisto, lang);
    const naytemateriaalit: ValueAndLabel[] = NaytetiedotGroup.mapNaytemateriaaliData(tutkimus, naytemateriaaliKoodisto, lang);

    const tiedoksisaajaLabel = tiedoksisaajaKoodisto.find(t => t.id.trim() === tutkimus.automTiedoksisaaja);
    const localizedTiedoksisaajaLabel = tiedoksisaajaLabel ? tiedoksisaajaLabel[`nimi${lang}`] : tutkimus.automTiedoksisaaja;
    const tiedoksisaaja: ValueAndLabel = tutkimus.automTiedoksisaaja ? new ValueAndLabel(tutkimus.automTiedoksisaaja, localizedTiedoksisaajaLabel) : null;

    return new NayteTutkimusData({
      value: new NayteTutkimusValue({
        naytemateriaali: naytemateriaaliValue,
        tutkimussyy: tutkimussyyValue,
        tiedoksisaaja: tiedoksisaajaValue
      }),
      nayte: new ValueAndLabel(nayteId),
      tutkimus: new ValueAndLabel(tutkimus.id, tutkimus[`tutkimus${lang}`]),
      naytemateriaalit: naytemateriaalit,
      tutkimussyyt: tutkimussyyt,
      tiedoksisaaja: tiedoksisaaja
    });
  }

  static mapTutkimussyytData = (tutkimus: LaheteData, tutkimussyyKoodisto, lang) => {
    return tutkimus.tutkimussyyKoodi.reduce(((previousValue, currentValue) => {
      const value = currentValue === 'muu' ? 'muutut' : currentValue;
      const localizedTutkimussyyLabel = tutkimussyyKoodisto.find(t => t.id.trim() === value.trim())[`kuvaus${lang}`];
      const mapped = new ValueAndLabel(value, localizedTutkimussyyLabel);
      previousValue.push(mapped);
      return previousValue;
    }), []) || [];
  }

  static mapNaytemateriaaliData = (tutkimus: LaheteData, naytemateriaaliKoodisto, lang) => {
    return  tutkimus.naytemateriaaliProvet.reduce(((previousValue, currentValue) => {
      const naytemateriaaliLabel = naytemateriaaliKoodisto.find(t => t.id.trim() === currentValue.trim());
      const localizedNaytemateriaaliLabel = naytemateriaaliLabel ?
        naytemateriaaliLabel[`kuvaus${lang}`] :
        Translations.getLabel('muuNaytemateriaali', lang);
      const mapped = new ValueAndLabel(currentValue, localizedNaytemateriaaliLabel);
      previousValue.push(mapped);
      return previousValue;
    }), []) || [];
  }

  /**
   * Palauttaa taustakysymyksen id:n numeerisena. ('tausta22' -> 22)
   * @param taustakysymysId - taustakysymyksen id, esim. tausta22
   * @return taustakysymyksen id numerona
   */
  static parseTaustaNro = (taustakysymysId: string) => parseInt(taustakysymysId.substr(6), 10);

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'naytetieto'})): QuestionGroup {
    this.initNaytetietoValintalistat();
    this.initTaustakysymysGroups();

    const compareFn = (prevVal, newVal) => {
      return newVal ? CompareAction.ARRAY_ADD : CompareAction.ARRAY_REMOVE;
    };

    const elainlajiValintalistaTopicListener = new TopicListener(
      'elainlajiChange',
      TopicListener.IGNORE_DETAIL,
      (ignore, valueChange) => {

        this.nayteValintalistat.rotu = this.laheteKoodisto.koodisto.rodut
          .filter(r => valueChange && valueChange.elainlaji === r.animalBreed)
          .map(r =>
            new ValueAndLabel(r.id, this.getLocalizedOf(r, 'kuvaus')))
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    );

    let questions = [];

    const naytetiedot = builder.initValueByPath(['naytetiedot', 'naytetiedot']);
    if (builder.applyValues && naytetiedot) {
      const valitut = builder.initValueByPath(['valitutTutkimukset', 'valitut'], []);
      this.selectedElainlaji = builder.initValueByPath(['elainlajit', 'elainlajiryhma']);
      this.selectedTaustakysymykset = this.getVisibleTaustakysymykset(valitut);

      const koodisto = this.laheteService.laheteKoodisto;
      const tutkimukset: NayteTutkimusData[] = valitut.map(vt => {
          return NaytetiedotGroup.mapNayteTutkimusData(
            koodisto.dataObj[vt.id],
            koodisto.koodisto.tutkimussyyt,
            koodisto.koodisto.naytemateriaalit,
            koodisto.koodisto.tiedoksisaajat,
            this.lang
          );
      });

      questions = naytetiedot.map(n => {
        const naytteet = n.naytteet?.map(nayte => {
          const tutkimus = tutkimukset.find(t => t.tutkimus.value === nayte.tutkimusId);
          return this.createNayteGroup(tutkimus, n.id, nayte);
        });

        const group = this.createNaytetietoGroup(n.id, tutkimukset, naytteet);
        if (group.controls) {
          group.push(new QuestionControl({key: 'id', controlType: 'hidden', initialValue: n.id}), ModelFragmentType.CONTROL);
        }

        this.laheteService.registerInitialValue(new QuestionInitValue(group, n, InitValueType.PATCH));

        return group;
      });
    }

    return new QuestionGroup({
      key: 'naytetiedot',
      label: this.getLabel('naytetiedot'),
      array: new QuestionArray({
        key: 'naytetiedot',
        questions: questions,
        controlType: builder.controlType,
        aggregators: {
          "valitutTutkimukset.valitut": new MatcherModel(null, compareFn, this.naytetiedotAggregatorFn.bind(this)),
        },
        subscribers: [
          elainlajiValintalistaTopicListener
        ],
        actions: {
          addNaytetieto: this.addNaytetieto,
          copyNaytetieto: this.copyNaytetieto
        }
      }),
      filters: {
        'tutkimukset': new MatcherModel(null, AbstractLaheteGroup.anyObjectValueTrue, AbstractLaheteGroup.filterActionFn)
      }
    });
  }

  getSummaryGroup(controlType: string, initialValues): QuestionGroup {
    this.initNaytetietoValintalistat();
    this.initTaustakysymysGroups();

    this.selectedElainlaji = initialValues.elainlajit.elainlajiryhma;

    const groups = [];
    initialValues.naytetiedot.naytetiedot.forEach(naytetieto => {
      groups.push(this.createNaytetietoSummaryGroup(naytetieto.id, naytetieto));
    });

    return new QuestionGroup({
      key: 'naytetiedot',
      label: this.getLabel('naytetiedot'),
      array: new QuestionArray({
        key: 'naytetiedot',
        questions: groups,
        controlType: controlType
      }),
    });
  }

  initTaustakysymysGroups = () => {

    const taustaRadioDateText = 'tausta-radio-date-text';
    const taustaText2Column = 'tausta-text-2-column';
    const textInput = 'text-input';
    const taustaGroupNested = 'tausta-group-nested';
    const taustaCheckboxTextarea = 'tausta-checkbox-textarea';

    this.taustakysymysControls.tausta2 = (parentId) => new QuestionGroup({
      key: 'tausta2',
      groupComponentType: taustaRadioDateText,
      controls: [
        new QuestionControl({controlType: 'radio', htmlId: `tausta2-itsestaankuollut-${parentId}`,  key: 'tausta2-kuolinsyy', value: 'itsestaanKuollut', label: this.getLabel('Itsestään kuollut')}),
        new QuestionControl({controlType: 'radio', htmlId: `tausta2-lopetettu-${parentId}`,  key: 'tausta2-kuolinsyy', value: 'lopetettu', label: this.getLabel('Lopetettu')}),
        new QuestionControl({controlType: 'date', htmlId: `tausta2-kuolinpvm-${parentId}`, key: 'tausta2-kuolinpvm', label: this.getLabel('Kuolinpäivämäärä')})
      ]
    });

    this.taustakysymysControls.tausta5 = (parentId) => new QuestionGroup({
      key: 'tausta5',
      groupComponentType: taustaText2Column,
      controls: [
        new QuestionControl({controlType: textInput, htmlId: `isanRekNimi-${parentId}`, key: 'isanRekNimi', label: this.getLabel('tausta5IsanNimi')}),
        new QuestionControl({controlType: textInput, htmlId: `emanRekNimi-${parentId}`, key: 'emanRekNimi', label: this.getLabel('tausta5EmanNimi')})
      ]
    });

    this.taustakysymysControls.tausta10 = (parentId) => new QuestionGroup({
      key: 'tausta10',
      groupComponentType: taustaRadioDateText,
      label: this.getLabel('tausta10alkionsiirrot'),
      controls: [
        new QuestionControl({controlType: 'date', htmlId: `tausta2-alkionsiirtopvm-${parentId}`, key: 'tausta2-alkionsiirtopvm', label: this.getLabel('tausta10alkionsiirrotPvm')})
      ]
    });

    this.taustakysymysControls.tausta11 = (parentId) => new QuestionGroup({
      key: 'tausta11',
      groupComponentType: taustaGroupNested,
      groups: [
        new QuestionGroup({
          label: this.getLabel('tausta11Otsikko'),
          key: 'tausta11-tamma',
          groupComponentType: taustaCheckboxTextarea,
          controls: [
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-sinusclitoridis-${parentId}`, key: 'tausta11-sinusclitoridis', label: 'Sinus Clitoridis'}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-fossaclitoridis-${parentId}`, key: 'tausta11-fossaclitoridis', label: 'Fossa Clitoridis'}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-cervixuterus-${parentId}`, key: 'tausta11-cervixuterus', label: 'Cervix/Uterus'}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-muu-tamma-check-${parentId}`, key: 'tausta11-muu-tamma-check', label: this.getLabel('muu'), options: {link: 'tausta11-muu-tamma-text'}}),
            new QuestionControl({controlType: 'textarea', htmlId: `tausta11-muu-tamma-text-${parentId}`, key: 'tausta11-muu-tamma-text'})
          ]
        }),
        new QuestionGroup({
          label: this.getLabel('tausta13Otsikko'),
          key: 'tausta11-ori',
          groupComponentType: taustaCheckboxTextarea,
          controls: [
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-fossaurethalis-${parentId}`, key: 'tausta11-fossaurethalis', label: 'Fossa urethralis'}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-urethanaukko-${parentId}`,  key: 'tausta11-urethanaukko', label: this.getLabel('tausta13Urethanaukko')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-esinahanpoimu-${parentId}`, key: 'tausta11-esinahanpoimu', label: this.getLabel('tausta13Esinahanpoimu')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-sperma-${parentId}`,        key: 'tausta11-sperma', label: this.getLabel('tausta13Sperma')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta11-muu-ori-check-${parentId}`, key: 'tausta11-muu-ori-check', label: this.getLabel('muu'), options: {link: 'tausta11-muu-ori-text'}}),
            new QuestionControl({controlType: 'textarea', htmlId: `tausta11-muu-ori-text-${parentId}`,  key: 'tausta11-muu-ori-text'})
          ]
        })
      ]
    });

    this.taustakysymysControls.tausta13 = (parentId) => new QuestionGroup({
      key: 'tausta13',
      label: this.getLabel('tausta13Otsikko'),
      groupComponentType: taustaCheckboxTextarea,
      controls: [
        new QuestionControl({controlType: 'checkbox', htmlId: `tausta13-fossaurethalis-${parentId}`, key: 'tausta13-fossaurethalis', label: 'Fossa urethralis'}),
        new QuestionControl({controlType: 'checkbox', htmlId: `tausta13-urethanaukko-${parentId}`, key: 'tausta13-urethanaukko', label: this.getLabel('tausta13Urethanaukko')}),
        new QuestionControl({controlType: 'checkbox', htmlId: `tausta13-esinahanpoimu-${parentId}`, key: 'tausta13-esinahanpoimu', label: this.getLabel('tausta13Esinahanpoimu')}),
        new QuestionControl({controlType: 'checkbox', htmlId: `tausta13-sperma-${parentId}`, key: 'tausta13-sperma', label: this.getLabel('tausta13Sperma')}),
        new QuestionControl({controlType: 'checkbox', htmlId: `tausta13-muu-check-${parentId}`, key: 'tausta13-muu-check', label: this.getLabel('muu'), options: {link: 'tausta13-muu-text'}}),
        new QuestionControl({controlType: 'textarea', htmlId: `tausta13-muu-text-${parentId}`, key: 'tausta13-muu-text'})
      ]
    });

    this.taustakysymysControls.tausta14 = (parentId) => new QuestionGroup({
      key: 'tausta14',
      label: this.getLabel('tausta14Otsikko'),
      groupComponentType: taustaRadioDateText,
      controls: [
        new QuestionControl({controlType: 'radio', htmlId: `tausta14-sivelynayte-kloaakki-${parentId}`,  value: 'kloaakki', key: 'tausta14-sivelynayte', label: this.getLabel('tausta14Kloaakki')}),
        new QuestionControl({controlType: 'radio', htmlId: `tausta14-sivelynayte-nielu-${parentId}`, value: 'nielu', key: 'tausta14-sivelynayte', label: this.getLabel('tausta14Nielu')})
      ]
    });

    this.taustakysymysControls.tausta15 = (parentId) => new QuestionGroup({
      key: 'tausta15',
      groupComponentType: 'tausta-textarea',
      controls: [
        new QuestionControl({controlType: 'textarea', key: `tausta15-sijaintipaikka`, label: this.getLabel('tausta15Sijaintipaikka'), htmlId: `tausta15-sijaintipaikka-${parentId}`})
      ]
    });

    this.taustakysymysControls.tausta17 = (parentId) => new QuestionGroup({
      key: 'tausta17',
      groupComponentType: 'tausta-select-text',
      controls: [
        new QuestionControl({
          key: 'tausta17-kunta',
          controlType: 'selection',
          htmlId: `tausta17-kunta-${parentId}`,
          label: this.getLabel('tausta17PitopaikanKunta'),
          options: {
            selection: this.nayteValintalistat.kunta
          }
        })
      ]
    });

    this.taustakysymysControls.tausta21 = (parentId) => new QuestionGroup({
      key: 'tausta21',
      groupComponentType: taustaGroupNested,
      groups: [
        new QuestionGroup({
          key: 'tausta21-nayte-lahetetty',
          label: this.getLabel('tausta21NayteLahetetty'),
          groupComponentType: taustaCheckboxTextarea,
          controls: [
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-tuoreena-${parentId}`, key: 'tausta21-tuoreena', label: this.getLabel('tausta21Tuoreena')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-pakastettuna-${parentId}`, key: 'tausta21-pakastettuna', label: this.getLabel('tausta21Pakastettuna')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-sailytysaineessa-${parentId}`, key: 'tausta21-sailytysaineessa', label: this.getLabel('tausta21Sailytysaineessa')}),
          ]
        }),
        new QuestionGroup({
          key: 'tausta21-kuolintapa',
          label: this.getLabel('tausta21Kuolintapa'),
          groupComponentType: taustaCheckboxTextarea,
          controls: [
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-loydetty-kuolleena-${parentId}`, key: 'tausta21-loydetty-kuolleena', label: this.getLabel('tausta21LoydettyKuolleena')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-metsastetty-kalastettu-${parentId}`, key: 'tausta21-metsastetty-kalastettu', label: this.getLabel('tausta21Mestastetty')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-lopetettu-sairaana-${parentId}`, key: 'tausta21-lopetettu-sairaana', label: this.getLabel('tausta21Lopetettu')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-kolarielain-${parentId}`, key: 'tausta21-kolarielain', label: this.getLabel('tausta21Kolarielain')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-pedon-tappama-${parentId}`, key: 'tausta21-pedon-tappama', label: this.getLabel('tausta21PedonTappama')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-hylatty-teurastamolla-lihantarkastuksessa-${parentId}`, key: 'tausta21-hylatty-teurastamolla-lihantarkastuksessa', label: this.getLabel('tausta21HylattyTeurastamolla')}),
            new QuestionControl({controlType: 'checkbox', htmlId: `tausta21-ei-tietoa-kuolintavasta-${parentId}`, key: 'tausta21-ei-tietoa-kuolintavasta', label: this.getLabel('tausta21EiTietoa')}),
          ]
        })
      ]
    });

    const tausta22Koordinaattijarjestelma = 'tausta22-koordinaattijarjestelma';
    this.taustakysymysControls.tausta22 = (parentId) => new QuestionGroup({
      key: 'tausta22',
      groupComponentType: taustaGroupNested,
      groups: [
        new QuestionGroup({
          groupComponentType: taustaText2Column,
          label: this.getLabel('tausta22Koordinaatit'),
          key: 'koordinaatit',
          controls: [
            new QuestionControl({controlType: textInput, key: 'tausta22-leveysaste', label: this.getLabel('tausta22Leveysaste')}),
            new QuestionControl({controlType: textInput, key: 'tausta22-pituusaste', label: this.getLabel('tausta22Pituusaste')})
          ]
        }),
        new QuestionGroup({
          groupComponentType: taustaRadioDateText,
          label: this.getLabel('tausta22Koordinaattijarjestelma'),
          key: 'koordinaattijarjestelma',
          controls: [
            new QuestionControl({controlType: 'radio', htmlId: 'tausta22-etrs', value: 'tausta22-etrs', key: tausta22Koordinaattijarjestelma, label: 'ETRS-TM35FIN'}),
            new QuestionControl({controlType: 'radio', htmlId: 'tausta22-wgs84', value: 'tausta22-wgs84', key: tausta22Koordinaattijarjestelma, label: 'WGS84'}),
            new QuestionControl({controlType: 'radio', htmlId: 'tausta22-muu', value: 'tausta22-muu', key: tausta22Koordinaattijarjestelma, label: this.getLabel('muu'), options: {link: 'tausta22-muu-text'}}),
            new QuestionControl({controlType: textInput, key: 'tausta22-muu-text'}),
          ]
        })
      ]
    });

    this.taustakysymysControls.tausta24 = (parentId) => new QuestionGroup({
      key: 'tausta24',
      label: this.getLabel('tausta24Ikaluokka'),
      groupComponentType: taustaRadioDateText,
      controls: [
        new QuestionControl({controlType: 'radio', value: 'ReVAgeCode16', htmlId: `tausta24-ReVAgeCode16-${parentId}`, key: 'tausta24-ikaluokka', label: this.getLabel('tausta24Taysikasvuinen')}),
        new QuestionControl({controlType: 'radio', value: 'ReVAgeCode23', htmlId: `tausta24-ReVAgeCode23-${parentId}`, key: 'tausta24-ikaluokka', label: this.getLabel('tausta24Nuori')}),
      ]
    });

    this.taustakysymysControls.tausta27 = (parentId) => new QuestionGroup({
      key: 'tausta27',
      groupComponentType: taustaText2Column,
      controls: [
        new QuestionControl({controlType: textInput, htmlId: `tausta27-tilatunnus-${parentId}`, key: 'tilatunnus', label: this.getLabel('tausta27Tilatunnus')}),
        new QuestionControl({controlType: textInput, htmlId: `tausta27-pitopaikkatunnus-${parentId}`, key: 'pitopaikkatunnus', label: this.getLabel('tausta27Pitopaikkatunnus')})
      ]
    });
  }

  initNaytetietoValintalistat = () => {
    this.nayteValintalistat.kunta = this.laheteKoodisto.koodisto.kunnat.map(kunta =>
      new ValueAndLabel(kunta.id, this.getLocalizedOf(kunta, 'nimi'))
    );
    this.nayteValintalistat.sukupuoli = this.laheteKoodisto.koodisto.sukupuoli.map(sukupuoli =>
      new ValueAndLabel(sukupuoli.id, this.getLocalizedOf(sukupuoli, 'kuvaus'))
    );
    this.nayteValintalistat.alkuperamaa = this.laheteKoodisto.koodisto.maaluettelo.map(maa =>
      new ValueAndLabel(maa.id, this.getLocalizedOf(maa, 'kuvaus'))
    );
    this.nayteValintalistat.rotu = this.laheteKoodisto.koodisto.rodut.map(rotu =>
      new ValueAndLabel(rotu.id, this.getLocalizedOf(rotu, 'kuvaus'))
    );
  }

  naytetiedotAggregatorFn = (formArray: FormArray, valitutTutkimukset, compareActionType: CompareAction) => {
    if (compareActionType === CompareAction.ARRAY_ADD) {
      this.selectedElainlaji = valitutTutkimukset.length > 0 ?
        this.laheteKoodisto.dataObj[valitutTutkimukset[0].id].elainlajikoodi :
        null;

      this.selectedTaustakysymykset = this.getVisibleTaustakysymykset(valitutTutkimukset);

      const tutkimukset = valitutTutkimukset.map(t => NaytetiedotGroup.mapNayteTutkimusData(
        this.laheteKoodisto.dataObj[t.id],
        this.laheteKoodisto.koodisto.tutkimussyyt,
        this.laheteKoodisto.koodisto.naytemateriaalit,
        this.laheteKoodisto.koodisto.tiedoksisaajat,
        this.lang)
      );
      this.addNaytetieto(0, tutkimukset, QuestionUpdateType.UPDATE);
    }
  }

  getVisibleTaustakysymykset = (valitutTutkimukset: {id: number, selected: boolean}[]) => {
    return valitutTutkimukset.reduce((prev, current) => {
      this.laheteKoodisto.dataObj[current.id].lisakysymykset.forEach(taustakysymys => prev.add(taustakysymys));
      return prev;
    }, new Set<string>());
  }

  addNaytetieto = (newId: number, valitutTutkimukset: NayteTutkimusData[], updateType: QuestionUpdateType, groupValues?) => {
    const newIdAsString = newId?.toString() || Date.now().toString();
    const questionGroup = this.createNaytetietoGroup(newIdAsString, valitutTutkimukset);
    const formGroup = this.createFormGroupFromQuestionGroup(questionGroup);
    formGroup.addControl('id', new FormControl(newIdAsString));

    this.laheteService.updateQuestion(new QuestionUpdate(
      "naytetiedot.naytetiedot",
      newIdAsString,
      updateType,
      questionGroup,
      formGroup
    ));

    if (groupValues) {
      groupValues.naytteet = [];
      formGroup.patchValue(groupValues);
    }
  }

  copyNaytetieto = (copies: number, currentCount: number, valitutTutkimukset: NayteTutkimusData[], values) => {

    const valuesToCopy = [];
    let idIterator = Date.now();

    for (let i = 0; i < copies; i++) {
      const newValue = JSON.parse(JSON.stringify(values));
      idIterator++;
      newValue.id = (idIterator).toString();

      newValue.naytteet.forEach((nayte, j) => {
        idIterator++;
        nayte.id = (idIterator).toString();
        const naytetunnusParts = nayte.nayteTunnus.split('-');
        const nayteTunnusSuffix = naytetunnusParts.length > 0 ?
          naytetunnusParts[1] : j;
        nayte.nayteTunnus = `${currentCount + (i + 1)}-${nayteTunnusSuffix}`;
      });
      valuesToCopy.push(newValue);
    }

    valuesToCopy.forEach(group => {
      const nayteValues = JSON.parse(JSON.stringify(group.naytteet));
      this.addNaytetieto(group.id, valitutTutkimukset, QuestionUpdateType.ADD, group);
      nayteValues.forEach(nayte => {
        const nayteTutkimus = valitutTutkimukset.find(t => t.tutkimus.value === nayte.tutkimusId);
        this.addNayte(nayteTutkimus, group.id, nayte.nayteTunnus, nayte);
      });
    });
  }

  addNayte = (tutkimus: NayteTutkimusData, naytetietoKey, nayteTunnus?, groupValues?) => {
    const key = groupValues?.id || Date.now().toString();
    const values = {
      id: key,
      tutkimusId: tutkimus.tutkimus.value,
      nayteTunnus: nayteTunnus,
      naytemateriaali: tutkimus.value.naytemateriaali,
      tutkimussyy: tutkimus.value.tutkimussyy,
      naytePvm: tutkimus.value.naytePvm || new Date()
    };
    const question = this.createNayteGroup(tutkimus, naytetietoKey, values);

    const formGroup = this.createFormGroupFromQuestionGroup(question);

    if (groupValues) {
      formGroup.patchValue(groupValues);
    }

    this.laheteService.updateQuestion(new QuestionUpdate(
      `naytetiedot.naytetiedot.${naytetietoKey}.naytteet`,
      question.key,
      QuestionUpdateType.ADD,
      question,
      formGroup
    ));
  }

  createNayteGroup = (tutkimus: NayteTutkimusData, naytetietoKey: string, values: {[s: string]: any}) => {
    const fullPath = `naytetiedot.naytetiedot.${naytetietoKey}.naytteet.${values.id}`;

    return new QuestionGroup({
      groupComponentType: this.nayteSingle,
      key: values.id,
      fullPath: fullPath,
      label: tutkimus.tutkimus.label,
      actions: {
        removeNayte: this.removeQuestionArrayItem
      },
      options: {
        tutkimus: tutkimus
      },
      controls: [
        new QuestionControl({key: 'id', controlType: 'hidden', initialValue: values.id}),
        new QuestionControl({key: 'tutkimusId', controlType: 'hidden', label: this.getLabel('tutkimus'), initialValue: values.tutkimusId}),
        new QuestionControl({key: 'nayteTunnus', controlType: 'text-input', label: this.getLabel('naytenumero'), initialValue: values.nayteTunnus}),
        new QuestionControl({key: 'naytemateriaali', controlType: 'radio-nayte', label: this.getLabel('naytemateriaali'), initialValue: values.naytemateriaali, options: {data: tutkimus.naytemateriaalit}}),
        new QuestionControl({key: 'tutkimussyy', controlType: 'radio-nayte', label: this.getLabel('tutkimussyy'), initialValue: values.tutkimussyy, options: {data: tutkimus.tutkimussyyt}}),
        new QuestionControl({key: 'naytePvm', controlType: 'pvm', label: this.getLabel('naytteenottoPvm'), initialValue: values.naytePvm}),
      ],
    });
  }

  removeQuestionArrayItem = (fullPath) => {
    this.laheteService.updateQuestion(new QuestionUpdate(
      PathUtil.getParent(fullPath),
      PathUtil.getLastKey(fullPath),
      QuestionUpdateType.REMOVE
    ));
  }

  /**
   * Luo näytetietokysymykset valitun eläinlajiryhmän mukaan.
   *
   * @param newId - Näytetiedon id-arvo
   * @param valitutTutkimukset lista valituista tutkimuksista
   * @param naytteet lista kysymyksistä
   */
  private createNaytetietoGroup = (newId: string, valitutTutkimukset: NayteTutkimusData[], naytteet: QuestionGroup[] = []): QuestionGroup => {

    const refreshRodut = new TopicListener(
      'elainlajiChange',
      TopicListener.IGNORE_DETAIL,
      ((control, msg, modelFragment) => {
        modelFragment.options.selection = this.nayteValintalistat.rotu;

        const emitOpts = {emitEvent: false, onlySelf: true};
        if (this.nayteValintalistat.rotu.length === 0) {
          control.setValue(null, emitOpts);
          control.disable(emitOpts);
        } else {
          control.enable(emitOpts);
        }
      })
    );

    const nayteControls = this.createNaytetietoQuestionControls(refreshRodut);
    nayteControls.push(new QuestionControl({key: 'id', controlType: 'hidden', initialValue: newId}));

    const taustakysymysGroups: QuestionGroup[] = [...this.selectedTaustakysymykset]
      .sort((taustaA, taustaB) => NaytetiedotGroup.parseTaustaNro(taustaA) - NaytetiedotGroup.parseTaustaNro(taustaB))
      .filter(taustakysymys => this.taustakysymysControls.hasOwnProperty(taustakysymys))
      .map(taustakysymys => this.taustakysymysControls[taustakysymys](newId));

    return new QuestionGroup({
      key: newId,
      fullPath: `naytetiedot.naytetiedot.${newId}`,
      groups: taustakysymysGroups,
      controls: nayteControls,
      array: new QuestionArray({
        key: 'naytteet',
        controlType: this.nayteSingle,
        questions: naytteet,
      }),
      actions: {
        addNayte: this.addNayte,
        removeNaytetieto: this.removeQuestionArrayItem
      },
      options: {
        tutkimukset: valitutTutkimukset
      }
    });
  }

  private createNaytetietoQuestionControls = (rotuTopicListener?: TopicListener) => {
    return this.laheteKoodisto.koodisto.naytetiedot
      .filter(naytetieto => naytetieto.isNaytetieto)
      .filter(naytetieto => !naytetieto.taustakysymysFilter)
      .filter(naytetieto => !naytetieto.elainlajiryhmaFilter || naytetieto.elainlajiryhmaFilter.includes(this.selectedElainlaji))
      .map(naytetieto => {
        const controlType = NaytetiedotGroup.controlTypeMap.get(naytetieto.kentanTyyppi) || 'text';
        const questionControl = new QuestionControl({
          key: naytetieto.tunnus,
          label: this.getLocalizedOf(naytetieto, 'kuvaus'),
          controlType: controlType
        });

        if (controlType === 'select') {
          const valintalista = this.nayteValintalistat[naytetieto.tunnus] || [new ValueAndLabel("Valinta1", "Valinta1")];
          questionControl.options = {selection: valintalista};
        }
        if (naytetieto.tunnus === 'rotu' && rotuTopicListener) {
          questionControl.subscribers.push(rotuTopicListener);
        }

        if (naytetieto.tunnus === 'euTunnus') {
          questionControl.validators = [NaytetiedotValidators.isValidEutunnus];
          questionControl.errorMessages = { euTunnus: 'sahkoelain.euTunnusVirhe'};
        }

        // Syntymäajalle eri controlType jotta sille voidaan nättää myös vuosi.
        if (naytetieto.tunnus === 'syntymaaika') {
          questionControl.controlType = 'pvmVuosi';
        }

        return questionControl;
      });
  }

  private createNaytetietoSummaryGroup = (newId: string, value): QuestionGroup => {

    const taustakysymysGroups = Object.keys(value)
      .filter(key => this.taustakysymysControls.hasOwnProperty(key))
      .map(tausta => {
        return this.taustakysymysControls[tausta](newId);
      });

    const naytteet = [];
    value.naytteet.forEach(nayte => {
      naytteet.push(this.createNayteSummaryGroup(nayte.id, nayte));
    });


    return new QuestionGroup({
      key: newId,
      groups: taustakysymysGroups,
      controls: this.createNaytetietoQuestionControls(),
      array: new QuestionArray({
        key: 'naytteet',
        controlType: this.nayteSingle,
        questions: naytteet,
      })
    });
  }

  private createNayteSummaryGroup = (newId: string, values): QuestionGroup => {

    const laheteData = this.laheteKoodisto.dataObj[values.tutkimusId.toString()];

    const naytemateriaaliSelections = NaytetiedotGroup.mapNaytemateriaaliData(
      laheteData,
      this.laheteKoodisto.koodisto.naytemateriaalit,
      this.lang
    );
    const tutkimussyySelections = NaytetiedotGroup.mapTutkimussyytData(
      laheteData,
      this.laheteKoodisto.koodisto.tutkimussyyt,
      this.lang
    );

    return new QuestionGroup({
      groupComponentType: this.nayteSingle,
      key: newId,
      label: this.getLocalizedOf(laheteData, 'tutkimus'),
      actions: {
        removeNayte: this.removeQuestionArrayItem
      },
      controls: [
        new QuestionControl({key: 'id', controlType: 'hidden'}),
        new QuestionControl({key: 'tutkimusId', controlType: 'hidden', label: this.getLabel('tutkimus')}),
        new QuestionControl({key: 'nayteTunnus', controlType: 'text-input', label: this.getLabel('naytenumero')}),
        new QuestionControl({key: 'naytemateriaali', controlType: 'radio-nayte', label: this.getLabel('naytemateriaali'), options: {data: naytemateriaaliSelections}}),
        new QuestionControl({key: 'tutkimussyy', controlType: 'radio-nayte', label: this.getLabel('tutkimussyy'), options: {data: tutkimussyySelections}}),
        new QuestionControl({key: 'naytePvm', controlType: 'pvm', label: this.getLabel('naytteenottoPvm')}),
      ],
    });

  }
}
