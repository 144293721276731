enum ConsumeType {
  GET_ONE,
  SKIP,
  CONSUME_HEAD,
  DO_NOT_CONSUME
}

export class PathUtil {
  static readonly SINGLE_ANY_KEY = '?';
  static readonly NESTED_ANY_KEY = '*';

  static checkPathIsChildOf(pathToCheck: string, parent: string): boolean {
    if (pathToCheck.startsWith(parent)) {
      const parentPathFragmentsCount = parent.split('.').length;
      const splittedPathToParent = pathToCheck.split('.', parentPathFragmentsCount);
      return splittedPathToParent.join('.') === parent;
    }
    return  false;
  }

  static checkPathMatchesPattern(path: string, pattern: string): boolean {
    let pathFragments = path.split('.');
    let patternFragments = pattern.split('.');

    while (pathFragments.length > 0) {
      if (this.canContinue(patternFragments, pathFragments[0])) {
        patternFragments = this.getConsumedPath(patternFragments, pathFragments[0]);
        pathFragments = pathFragments.slice(1);
      } else {
        return false;
      }
    }

    if (patternFragments.length > 0 && patternFragments[0] === this.NESTED_ANY_KEY) {
      patternFragments = patternFragments.slice(1);
    }

    return pathFragments.length === 0 && patternFragments.length === 0;
  }

  static isWildCard(pathFragment: string) {
    return pathFragment === this.SINGLE_ANY_KEY || pathFragment === this.NESTED_ANY_KEY;
  }

  static getConsumedPath(currentPath: string[], modelFragmentKey: string): string[] {
    const pathCopy = currentPath.concat();
    if (currentPath && currentPath.length > 0) {
      const consumeType = this.getConsumeType(currentPath, modelFragmentKey);
      if (consumeType === ConsumeType.GET_ONE) {
        pathCopy.splice(0, 1);
      } else if (consumeType === ConsumeType.CONSUME_HEAD) {
        pathCopy.splice(0, 2);
      }
    }
    return pathCopy;
  }

  static canContinue(currentPath: string[], modelFragmentKey: string) {
    if (currentPath && currentPath.length > 0 && modelFragmentKey) {
      return this.getConsumeType(currentPath, modelFragmentKey) !== ConsumeType.DO_NOT_CONSUME;
    }
    return false;
  }

  static getParent(path: string): string {
    const splitted = path.split('.');
    return splitted.slice(0, -1).join('.');
  }

  static getLastKey(path: string): string {
    const splitted = path.split('.');
    return splitted.pop().toString();
  }

  private static getConsumeType(pathPiece: string[], modelFragmentKey: string) {
    const first = pathPiece[0];
    if (first === modelFragmentKey || first === this.SINGLE_ANY_KEY) {
      return ConsumeType.GET_ONE;
    } else if (first === this.NESTED_ANY_KEY) {
      const nextPiece = pathPiece[1] || null;
      const consumeNext = nextPiece ?
        nextPiece === modelFragmentKey : false;
      return consumeNext ? ConsumeType.CONSUME_HEAD : ConsumeType.SKIP;
    }
    return ConsumeType.DO_NOT_CONSUME;
  }
}
