import {Field, FieldArray, FieldControl, FieldGroup} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {
  createAinesosaluokat,
  createCheckboxWithMaara,
  createLuovutuksetGroupWithGroups,
  createMaaraJaAlkuperaControls,
  createPaaravinteidenMaara
} from "./lannoitevuosiilmoitus-data-utils";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

export const createKansallinenToiminta = (): FieldGroup => {
  return new FieldGroup(
    Field.build(attr("kansallinenToiminta")),
    {
      lannoiteValmisteenTuonti: new FieldArray(
        Field.build(attr("lannoiteValmisteenTuonti")),
        () => createMaahantuontiGroup(),
        [createMaahantuontiGroup()]
      ),
      lannoiteValmisteenVienti: new FieldArray(
        Field.build(attr("lannoiteValmisteenVienti")),
        () => createVientiGroup(),
        [createVientiGroup()]
      ),
      lannoiteValmisteenTuotantoOmaKaytto: new FieldArray(
        Field.build(attr("")),
        () => createTuotantoOmaanKayttoon(),
        [createTuotantoOmaanKayttoon()]
      ),
      lannoiteValmisteenJakelija: new FieldArray(
        Field.build(attr("")),
        () => createJakelijaGroup(),
        [createJakelijaGroup()]
      ),
      lannoiteValmisteenValmistus: new FieldArray(
        Field.build(attr("")),
        () => createValmistusGroup(),
        [createValmistusGroup()]
      )
    }
  );
}

const createMaahantuontiGroup = (): FieldGroup => {
  return new FieldGroup(
    Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(),
        {
          ...createMaaraJaAlkuperaControls(),
          lahtomaa: new FieldControl(Field.build()),
          alperamaa: new FieldControl(Field.build()),
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}

const createVientiGroup = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(), createMaaraJaAlkuperaControls()),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: new FieldGroup(Field.build(),
        {
          vientiEuAlueelle: new FieldGroup(Field.build(),
            createCheckboxWithMaara()
          ),
          vientiEuAlueelta: new FieldGroup(Field.build(),
            createCheckboxWithMaara()
          )
        }
      )
    })
}

const createTuotantoOmaanKayttoon = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(), {
          ...createMaaraJaAlkuperaControls(),
          vainOmaanKayttoon: new FieldControl(Field.build())
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      ainesosaluokat: createAinesosaluokat(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}

const createJakelijaGroup = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(), createMaaraJaAlkuperaControls()),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}

const createValmistusGroup = (): FieldGroup => {
  return new FieldGroup(Field.build(),
    {
      maaraJaTiedot: new FieldGroup(Field.build(), {
        ...createMaaraJaAlkuperaControls(),
        vainOmaanKayttoon: new FieldControl(Field.build()),
        vainPakkaamista: new FieldControl(Field.build()),
        }
      ),
      paaravinteidenMaara: createPaaravinteidenMaara(),
      ainesosaluokat: createAinesosaluokat(),
      luovutukset: createLuovutuksetGroupWithGroups()
    }
  )
}
