import {Component, forwardRef, Input} from "@angular/core";
import {ControlContainer, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AbstractField, FieldGroup} from "ngx-fielding";

@Component({
    selector: '[checkbox-table-row-v2]',
    template: `
        <td style="width: 40%">
            <span [attribute]="question"></span><em *ngIf="description" class="small text-muted ml-2"
                                                    [attribute]="description"></em>
        </td>
        <ng-container [formGroup]="formGroup">
            
            <td *ngFor="let control of formControls">
                <touko-checkbox [htmlId]="control.field.htmlId"
                                [labelAttribute]="control.field.label"
                                [formControl]="control.asControl()"></touko-checkbox>
            </td>
        </ng-container>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CheckboxTableRowV2Component),
        }
    ]
})
export class CheckboxTableRowV2Component {
    @Input() question: string = null;
    @Input() description: string = null;

    constructor(private controlContainer: ControlContainer) {
    }

    get formGroup(): FieldGroup {
        return this.controlContainer.control as FieldGroup;
    }

    get formControls(): AbstractField[] {
        return Object.values(this.formGroup.controlFields);
    }
}
