<touko-lomake *ngIf="lomake"
              [formGroup]="lomake"
              [tunnus]="lomakeMetaData.id"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [cancelFn]="cancelLomake"
              (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              [saveFn]="saveLomake">


  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        sivuvirhekasittely [virheGroups]="['laskutustiedot', 'perustiedot', 'yhteyshenkilot']"
        sivu="ref0">
    <div class="mb-4">
      <touko-alert-box *ngIf="!isPerustiedotValid">
        <div attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</div>
      </touko-alert-box>
    </div>

    <perustiedot [lomake]="lomake"></perustiedot>
    <laskutustiedot [lomake]="lomake"></laskutustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"></yhteyshenkilot>

    <fieldset formGroupName="toiminnanAloitus">
      <legend [attribute]="lomake.get('toiminnanAloitus').field.label"></legend>
      <date [label]="lomake.get('toiminnanAloitus.toiminnanAloitus').field.label" formControlName="toiminnanAloitus"
            htmlIdSuffix="toiminnanAloitus"></date>
    </fieldset>
    <validation-error [field]="lomake.get('toiminnanAloitus')"></validation-error>

    <fieldset class="mt-6 mb-2">
      <legend [attribute]="'lomakeYleinen.titleSahkAsiointi'"></legend>
      <p attribute="lomakeYleinen.infoSahkAsiointi"></p>
      <touko-checkbox htmlId="sahkoinenAsiointi"
                      labelAttribute="lomakeYleinen.checkSahkAsiointi"
                      formControlName="sahkoinenAsiointi"
                      class="strong"
                      checked="false">
      </touko-checkbox>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid"
        [attribuuttiAvain]="'rehuvalvonta.toiminnatJaTuotetyypit'"
        [ohjeet]="lomakeInfo.ohjeet['sivu1']"
        sivuvirhekasittely [virheGroups]="['rekisteroitavatToiminnat', 'tuoteTyypit']" sivu="ref1">
    <fieldset class="mb-2">
      <legend attribute="rehuvalvonta.toiminnat"></legend>
      <rehutoiminta *ngFor="let toiminta of toiminnat" [formGroup]="toiminta.asGroup()"></rehutoiminta>
      <validation-error [field]="lomake.get('rekisteroitavatToiminnat')"></validation-error>
    </fieldset>

    <fieldset *ngIf="rules.someVisible" formGroupName="yhteisetKysymykset" class="mb-2">
      <legend attribute="rehuvalvonta.tarkemmatYleisKys"></legend>
      <touko-text-input *ngIf="rules.isVisible['arvioRehunMaarasta']" formControlName="arvioRehunMaarasta"
                        htmlId="arvioRehunMaarasta"
                        labelAttribute="rehuvalvonta.arvioRehunMaarasta"></touko-text-input>
      <touko-text-input *ngIf="rules.isVisible['valmistettujenRehuSeostenLkm']"
                        formControlName="valmistettujenRehuSeostenLkm" htmlId="valmistettujenRehuSeostenLkm"
                        labelAttribute="rehuvalvonta.valmistettujenRehuSeostenLkm"></touko-text-input>
      <touko-yes-no *ngIf="rules.isVisible['rehunValmistusOmaan']" formControlName="rehunValmistusOmaan"
                    groupName="rehunValmistusOmaanKayttoon"
                    htmlId="rehunValmistusOmaanKayttoon" label="rehuvalvonta.rehunValmistusOmaan"></touko-yes-no>
      <touko-yes-no *ngIf="rules.isVisible['valmistaakoElintarvikkeita']"
                    formControlName="valmistaakoElintarvikkeita" groupName="valmistaakoElintarvikkeita"
                    htmlId="valmistaakoElintarvikkeita"
                    label="rehuvalvonta.valmistaakoElintarvikkeita"></touko-yes-no>
      <touko-yes-no *ngIf="rules.isVisible['valmistusVainPakkaamista']" formControlName="valmistusVainPakkaamista"
                    groupName="valmistusVainPakkaamista"
                    htmlId="valmistusVainPakkaamista" label="rehuvalvonta.valmistusVainPakkaamista"></touko-yes-no>
    </fieldset>

    <fieldset formGroupName="tuoteTyypit" class="mb-2">
      <legend attribute="rehuvalvonta.tuotetyypit"></legend>
      <touko-checkbox *ngFor="let tuotetyyppi of tuotetyypit" [formControl]="tuotetyyppi.asControl()"
                      [labelAttribute]="tuotetyyppi.field.label"
                      [htmlId]="tuotetyyppi.field.htmlId"></touko-checkbox>
      <validation-error [field]="lomake.get('tuoteTyypit')"></validation-error>
    </fieldset>

    <fieldset formGroupName="laitostenLisatiedot">
      <legend attribute="rehuvalvonta.lisatietojaHyvaksyttavistaLaitoksista"></legend>
      <touko-checkbox *ngFor="let lisatieto of laitostenLisatiedot" [formControl]="lisatieto.asControl()"
                      [labelAttribute]="lisatieto.field.label"
                      [htmlId]="lisatieto.field.htmlId"></touko-checkbox>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid"
        [attribuuttiAvain]="'rehuvalvonta.lisatietojaRehuistaJaToiminnasta'"
        [ohjeet]="lomakeInfo.ohjeet['sivu2']"
        sivuvirhekasittely [virheGroups]="['sivutuoteAsetuksenMukainenHyvaksynta', 'verkkokaupat', 'laadunohjaus']" sivu="ref2">
    <fieldset formGroupName="sivutuoteAsetuksenMukainenHyvaksynta">
      <legend attribute="rehuvalvonta.sivutuoteHyv"></legend>
      <touko-checkbox formControlName="kasittelylaitos" labelAttribute="rehuvalvonta.kasittelylaitos"
                      htmlId="sivutuote1"></touko-checkbox>
      <touko-checkbox formControlName="lemmikkielaintenRuokiaValmistavaLaitos"
                      labelAttribute="rehuvalvonta.lemmikkielaintenRuokiaValmistavaLaitos"
                      htmlId="sivutuote2"></touko-checkbox>
      <label id="label-hakemuksenLiitteet" attribute="rehuvalvonta.hakemuksenLiitteet">Hakemuksen liitteet</label>
      <liite-list labelledBy="label-hakemuksenLiitteet" formArrayName="hakemuksenLiitteet"
                  htmlId="hakemuksenLiitteet"
                  selite="OVS" [maxLiiteLkm]="5"
                  [lomakeId]="lomakeMetaData.id"
                  [liitteetArray]="lomake.get('sivutuoteAsetuksenMukainenHyvaksynta.hakemuksenLiitteet').asArray()"
                  (liiteOut)="onSetLiitteet($event, 'liitteet')"></liite-list>
      <validation-error [field]="lomake.get('sivutuoteAsetuksenMukainenHyvaksynta')"></validation-error>
    </fieldset>

    <fieldset formGroupName="sivutuoteAsetuksenMukainenRekisterointi" class="mb-2">
      <legend attribute="rehuvalvonta.sivutuoteRek"></legend>
      <touko-checkbox formControlName="rehusekoittamo" htmlId="sivutuoterekrehusekoittamo"
                      labelAttribute="rehuvalvonta.rehusekoittamo"></touko-checkbox>
      <touko-checkbox formControlName="kerayskeskus" htmlId="sivutuoterekkerayskeskus"
                      labelAttribute="rehuvalvonta.kerayskeskus"></touko-checkbox>
    </fieldset>

    <fieldset formGroupName="rehunJaToiminnanLisatiedot" class="mb-2">
      <legend attribute="rehuvalvonta.lisatietojaRehuistaJaToiminnasta"></legend>
      <touko-textarea formControlName="rehunJaToiminnanVapaamuotoisetLisatiedot"
                      labelAttribute="rehuvalvonta.rehunJaToiminnanVapaamuotoisetLisatiedot"
                      htmlId="rehunJaToiminnanLisatiedot"
                      rows="8"></touko-textarea>
    </fieldset>

    <fieldset formGroupName="verkkokaupat" class="mb-2">
      <legend attribute="lomakeYleinen.verkkokaupat"></legend>
      <verkkokaupat-list></verkkokaupat-list>
    </fieldset>

    <fieldset formGroupName="laadunohjaus">
      <legend attribute="rehuvalvonta.laadunohjausTitle"></legend>
      <p attribute="rehuvalvonta.laadunohjaus"></p>
      <p attribute="rehuvalvonta.laadunohjausLisatieto"></p>
      <ng-container *ngFor="let laadunohjausJarjestelma of laadunohjaus; last as isLast">
        <touko-checkbox *ngIf="!isLast" [formControl]="laadunohjausJarjestelma.asControl()"
                        [labelAttribute]="laadunohjausJarjestelma.field.label"
                        [htmlId]="laadunohjausJarjestelma.field.htmlId"></touko-checkbox>
        <div *ngIf="isLast" formGroupName="ReVFeedQSOther">
          <touko-checkbox formControlName="checked" [labelAttribute]="laadunohjausJarjestelma.field.label"
                          [htmlId]="laadunohjausJarjestelma.get('checked').field.htmlId"></touko-checkbox>
          <touko-text-input
            formControlName="text"
            [htmlId]="laadunohjausJarjestelma.get('text').field.htmlId"
            [labelledById]="laadunohjausJarjestelma.get('checked').field.htmlId + '-label'">
          </touko-text-input>
          <validation-error [field]="laadunohjausJarjestelma"></validation-error>
        </div>
      </ng-container>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
    <app-field-group-summary class="summary" [fieldGroup]="lomake"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button id="laheta" class="mt-4 btn btn-primary" type="submit" attribute="teksti.laheta">Lähetä</button>
    </div>
  </sivu>
</touko-lomake>

<pre *devFeature class="small">
      {{ lomake?.getRawValue() | json }}
</pre>
