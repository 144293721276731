import {Injectable} from "@angular/core";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {TulkkiObject} from "../common/lomake-field-utils";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {createSahkoinenAsiointi} from "../common/lomake-fields/common-fields";
import {
  QuestionAndTranslatedAnswerSummaryComponent
} from "../../summary/question-and-translated-answer-summary.component";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";
import {createLaitostenLisatiedot, createToiminnat, createTuotetyypit} from "./toiminta-ja-tuotetyypit-sivu";
import {
  createLaadunohjaus,
  createRehunJaToiminnanLisatiedot,
  createSivutuoteAsetuksenMukainenHyvaksynta,
  createSivutuoteAsetuksenMukainenRekisterointi
} from "./lisatietoja-sivu";
import {createVerkkokaupatGroup} from "../common/lomake-fields/verkkokaupat";
import {createToiminnanAjankohta} from "../common/lomake-fields/toiminnan-ajankohta";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";

const attr = TulkkiObject.getCreateFn('rehuvalvonta');

@Injectable()
export class RehuvalvontaV2Data implements LomakeV2Data {

  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(toimintotyyppi: string): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();
    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
      Field.build(
        attr('rehuvalvontaIlmoitus')
      ), {
        ulkomainenOsoite,
        perustiedot,
        laskutustiedot,
        yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
        sahkoinenAsiointi: createSahkoinenAsiointi(),
        toiminnanAloitus: createToiminnanAjankohta(toimintotyyppi),
        rekisteroitavatToiminnat: createToiminnat(),
        yhteisetKysymykset: createYhteisetKysymykset(),
        tuoteTyypit: createTuotetyypit(),
        laitostenLisatiedot: createLaitostenLisatiedot(),
        sivutuoteAsetuksenMukainenHyvaksynta: createSivutuoteAsetuksenMukainenHyvaksynta(),
        sivutuoteAsetuksenMukainenRekisterointi: createSivutuoteAsetuksenMukainenRekisterointi(),
        rehunJaToiminnanLisatiedot: createRehunJaToiminnanLisatiedot(),
        verkkokaupat: createVerkkokaupatGroup(),
        laadunohjaus: createLaadunohjaus()
      }
    )
  }
}

const createYhteisetKysymykset = (): FieldGroup => {
  const textInputSummary: SummaryFnType = (control) => new SummaryItem(control.field.label, control.value, {show: control.value});
  const radioButtonSummary: SummaryFnType = (control) => new SummaryItem(control.field.label, control.value ? 'kylla' : 'ei', {show: control.value !== null}, QuestionAndTranslatedAnswerSummaryComponent)

  const createTextField = (label) => new FieldControl(
    Field.of({
      label: attr(label),
      summaryFn: textInputSummary,
      htmlId: label
    })
  )
  const createRadioField = (label) => new FieldControl(
    Field.of({
      label: attr(label),
      summaryFn: radioButtonSummary,
      htmlId: label
    })
  )

  return new FieldGroup(
    Field.build(attr('tarkemmatYleisKys'),
      (group) => {
        const toiminnat = group.parent.get('rekisteroitavatToiminnat') as FieldGroup;
        let isAnyValittu = false;
        Object.values(toiminnat.controlFields).forEach(control => {
          if (!isAnyValittu) {
            isAnyValittu = Boolean(control.get('valittu').value);
          }
        })

        return new SummaryItem(group.field.label, null, {show: isAnyValittu}, TitleSummaryComponent)
      }),
    {
      arvioRehunMaarasta: createTextField('arvioRehunMaarasta'),
      valmistettujenRehuSeostenLkm: createTextField('valmistettujenRehuSeostenLkm'),
      rehunValmistusOmaan: createRadioField('rehunValmistusOmaan'),
      valmistaakoElintarvikkeita: createRadioField('valmistaakoElintarvikkeita'),
      valmistusVainPakkaamista: createRadioField('valmistusVainPakkaamista'),
    });
}
