import {Component, Input} from "@angular/core";
import {FieldGroup} from "ngx-fielding";
import {ControlContainer} from "@angular/forms";

@Component({
  selector: "touko-yritys-tiedot",
  template: `
    <div [formGroup]="formGroup">
      <div *ngIf="isHenkiloAsiakas" class="form-row">
        <div class="col-md-6">
          <touko-text-input htmlId="tilatunnus" labelAttribute="Maatilatunnus"
                            formControlName="ytunnus"></touko-text-input>
        </div>
      </div>
      <div *ngIf="!isHenkiloAsiakas" class="form-row">
        <div class="col-md-3">
          <touko-text-input htmlId="pt-yritys" [labelAttribute]="lomake.get('perustiedot.yritys').field.label"
                            formControlName="yritys"></touko-text-input>
        </div>
        <div class="col-md-3">
          <touko-text-input htmlId="pt-ytunnus" [labelAttribute]="lomake.get('perustiedot.ytunnus').field.label"
                            formControlName="ytunnus"></touko-text-input>
        </div>
        <div class="col-md-3">
          <touko-text-input htmlId="vat-numero" [labelAttribute]="lomake.get('perustiedot.vatnumero').field.label"
                            formControlName="vatnumero"></touko-text-input>
        </div>
      </div>
    </div>
  `
  ,
})

export class ToukoYritysTiedotComponent {
  @Input() lomake: FieldGroup;
  @Input() isHenkiloAsiakas: boolean;

  constructor(private controlContainer: ControlContainer) {
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }
}
