import {Component, OnInit} from "@angular/core";
import {TiiraService} from "./tiira/tiira.service";
import {PalveluService} from "./sidebar/palvelu.service";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "./message/message.service";

/**
 * Komponetti virhe- tai poikkeustilannesivun näyttämiseen.
 */
@Component({
  template: `
    <div class="row">
      <div class="col-sm-12 text-center alert alert-danger" [ngSwitch]="reason">
        <ng-container *ngSwitchCase="'noaccountinfo'">
          <p [attribute]="'etusivu.eiKayttajatietoja'"></p>
          <p>{{ "etusivu.eiKayttajatietojaLogOut_1" | attribute }}
            <a style="text-decoration: underline;" (click)="logout()"><b>{{ "teksti.kirjauduUlos" | attribute | uppercase }}</b></a>
            {{ "etusivu.eiKayttajatietojaLogOut_2" | attribute }}
          </p>
        </ng-container>

        <ng-container *ngSwitchCase="'nopermission'">
          <p [attribute]="'etusivu.eiOikeuksiaIlmoitus'"></p>
          <p>{{ "etusivu.eiOikeuksiaLogOut_1" | attribute }}
            <a style="text-decoration: underline;" (click)="logout()"><b>{{ "teksti.kirjauduUlos" | attribute | uppercase }}</b></a>
            {{ "etusivu.eiOikeuksiaLogOut_2" | attribute }}
          </p>
        </ng-container>

        <ng-container *ngSwitchCase="'notfound'">
          <h1>
            <span *ngIf="message">{{message}}</span>
            <span *ngIf="!message" attribute="etusivu.virheJokinMeniPieleen"></span>
          </h1>
          <p>
            <span attribute="etusivu.virheTarkistaToimipaikka">Tarkista, että sinulla on valittuna oikea toimiala ja toimipaikka.</span>
            <a [routerLink]="'/asiointi/account'">
              <span attribute="etusivu.virheSiirryAsetukset" [markdown]="true">Siirry Asetukset-sivulle.</span>
            </a>
          </p>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <h1 attribute="etusivu.virheJokinMeniPieleen">Jokin meni pieleen...</h1>
          <p>
            <span attribute="etusivu.virheTarkistaToimipaikka">Tarkista, että sinulla on valittuna oikea toimiala ja toimipaikka.</span>
            <a [routerLink]="'/asiointi/account'">
              <span attribute="etusivu.virheSiirryAsetukset" [markdown]="true">Siirry Asetukset-sivulle.</span>
            </a>
          </p>
        </ng-container>

      </div>
    </div>
  `
})

export class ErrorInfoComponent implements OnInit {

  reason: string;
  message: string;
  private readonly validReasons = ['noaccountinfo', 'nopermission', 'notfound'];

  constructor(private route: ActivatedRoute,
              private tiiraService: TiiraService,
              private sidebarService: PalveluService,
              private messageService: MessageService) {
  }

  ngOnInit() {

    this.message = this.messageService.latestError;

    this.route.params.subscribe(params => {
      this.reason = params['reason'];
      if (this.validReasons.includes(this.reason) && this.reason !== 'notfound') {
        this.sidebarService.hideSidebar();
      }
    }).unsubscribe();
  }

  logout() {
    this.tiiraService.logout();
  }
}
