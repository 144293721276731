import {Field, FieldArray, FieldControl, FieldGroup, SummaryItem} from "ngx-fielding";
import {TitleSummaryComponent} from "../../../summary/title-summary/title-summary.component";
import {TulkkiObject} from "../lomake-field-utils";
import {AnswerOnlySummaryComponent} from "../../../summary/answer-only-summary.component";

const attr = TulkkiObject.getCreateFn('lomakeYleinen');


export const createVerkkokaupatGroup = () => new FieldGroup(
  Field.build(
    attr('verkkokaupat'),
    group => new SummaryItem(group.field.label, null, {show: group.get('checkbox').value}, TitleSummaryComponent),
    {osoiteRequired: attr('errVerkkokauppa')}
  ),
  {
    checkbox: new FieldControl(Field.build()),
    verkkokaupat: createVerkkokaupatArray()
  },
  (group) => {
    if (group.get('checkbox').value) {
      const osoitteet = ([...group.get('verkkokaupat').value] || [])
        .filter(verkkokauppa => {
          const trimmed = (verkkokauppa?.osoite || "").trim();
          return trimmed.length > 0;
        })
      return osoitteet.length > 0 ? null : {osoiteRequired: true}
    }
    return null;
  }
);

const createVerkkokaupatArray = (): FieldArray => {
  const verkkokauppa = createVerkkokauppa();
  verkkokauppa.get('lisatty').setValue(true);

  return new FieldArray(Field.build(attr('verkkokaupat')), () => createVerkkokauppa(),
    [verkkokauppa]);
}

const createVerkkokauppa = (): FieldGroup => {
  return new FieldGroup(
    Field.of({
      htmlId: 'verkkokaupat',
      summaryFn: group => new SummaryItem(null, group.get('osoite').value, {show: !group.get('enddt').value}, AnswerOnlySummaryComponent)
    }),
    {
      enddt: new FieldControl(Field.build()),
      osoite: new FieldControl(Field.build(attr('verkkokauppaosoite'))),
      lisatty: new FieldControl(Field.build())
    }
  )
}

