import {Component, OnInit} from '@angular/core';
import {AxeResults, NodeResult} from "axe-core";
import {AccessibilityService} from "./accessibility.service";
import {Subscription} from "rxjs";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

@Component({
  selector: 'accessibility-results',
  templateUrl: './accessibility-results.component.html',
})
@AutoUnsubscribe
export class AccessibilityResultsComponent implements OnInit {

  results: AxeResults = null;
  resultSub: Subscription = null;

  showJSON = false;

  constructor(
      private accessibilityService: AccessibilityService
  ) {
  }

  ngOnInit(): void {
    this.resultSub = this.accessibilityService.$accessibilityResults.subscribe(axeResults => {
      this.results = axeResults;
    });
  }

  toggleJSON() {
    this.showJSON = !this.showJSON;
  }

  clearHighlights() {
    this.accessibilityService.clearElementHighlights();
  }

  hideResults() {
    this.accessibilityService.clearElementHighlights();
    this.results = null;
  }

  toggleElementHighlight(nodeResult: NodeResult) {
    this.accessibilityService.higlightElements(nodeResult.target.join(""));
  }

}
