import {LomakeEvent, LomakeEventPublisherService} from "./lomake-event-publisher.service";
import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LomakeHttpService} from "./lomake-http.service";
import {LomakeRedirectService} from "../../../lomake-redirect.service";
import {LomakeInfo} from "../lomake-info";
import {LiitetiedostoResponse, LomakeRequest, LomakeResponse} from "../../../touko-lomake-utils";

@Injectable()
export class ProcessLomakeService {
  private readonly etusivuURL = "/asiointi/etusivu";

  constructor(private lomakeHttpService: LomakeHttpService,
              private lomakeEventPublisher: LomakeEventPublisherService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private lomakeRedirectService: LomakeRedirectService
  ) {
  }

  getUrlInformation() {
    return {
      id: Number(this.activatedRoute.snapshot.params.id),
      toimintotyyppi: this.activatedRoute.snapshot.data.toimintotyyppi,
      asia: this.activatedRoute.snapshot.params.asia
    }
  }

  getLomakeTiedot(lomakeInfo: LomakeInfo): Promise<LomakeResponse> {
    const id = this.getUrlInformation().id;
    this.lomakeRedirectService.canRedirect = false;
    const toimityypitWithPohjatiedot = new Set(['muutos', 'hakemus', 'vuosiilmoitus']);
    if (id) {
      return this.getKeskenerainenLomake(lomakeInfo.serverBaseUrl, id);
    } else if (toimityypitWithPohjatiedot.has(lomakeInfo.toimintotyyppi)) {
      return this.getLomakePohjatiedot(lomakeInfo.serverBaseUrl);
    }
    return Promise.resolve(null)
  }

  getKeskenerainenLomake(serverBaseUrl: string, id: number) {
    this.lomakeRedirectService.canRedirect = false;
    return this.lomakeHttpService.fetchKeskenerainenLomake(serverBaseUrl, id);
  }

  getLomakePohjatiedot(serverBaseUrl: string) {
    this.lomakeRedirectService.canRedirect = false;
    return this.lomakeHttpService.fetchLomakePohjatiedot(serverBaseUrl);
  }

  saveLomake(lomakeJson: object, lomakeInfo: LomakeInfo, liitetiedostot: { [s: string]: LiitetiedostoResponse[] }, id) {
    return this.lomakeHttpService.saveKeskenerainenLomake(this.createLomakeRequest(lomakeJson, this.convertLiitetiedostoObject(liitetiedostot), lomakeInfo.asia), lomakeInfo.serverBaseUrl, id)
      .then(lomakeResponse => {
        this.lomakeEventPublisher.publishLomakeEvent(new LomakeEvent(lomakeResponse, "save"));
        return lomakeResponse;
      })
      .then(lomakeResponse => {
        this.activatedRoute.params.subscribe(params => {
          const page = params['page'] ? params['page'] : 0;
          this.router.navigate(['/asiointi/lomake', lomakeInfo.toimintotyyppi, lomakeInfo.asia, lomakeInfo.clientComponentVersion, lomakeResponse.id, 'jatka', 'sivu', page]);
        });
        return lomakeResponse.id;
      })
      .catch(reason => {
        console.error(reason);
        this.lomakeEventPublisher.publishLomakeEvent(new LomakeEvent(null, "save", false));
      });
  }

  submitLomake(lomakeJson: object, lomakeInfo: LomakeInfo, id, liitetiedostot: { [s: string]: LiitetiedostoResponse[] }, valid) {
    if (valid) {
      this.lomakeRedirectService.canRedirect = true;
      const requestBody = this.createLomakeRequest(lomakeJson, this.convertLiitetiedostoObject(liitetiedostot), lomakeInfo.asia);
      return this.lomakeHttpService.sendLomake(requestBody, lomakeInfo.serverBaseUrl, id)
        .then(lomakeResponse => {
          this.lomakeEventPublisher.publishLomakeEvent(new LomakeEvent(lomakeResponse, "send"));
          return lomakeResponse;
        })
        .then(lomakeResponse => {
          this.activatedRoute.params.subscribe(() => {
            this.router.navigate([this.etusivuURL]);
          });
          return lomakeResponse.id;
        })
        .catch(reason => {
          console.error(reason);
          this.lomakeEventPublisher.publishLomakeEvent(new LomakeEvent(null, "send", false));
        }).finally(() => {
          this.lomakeRedirectService.canRedirect = false;
        });
    } else {
      this.lomakeEventPublisher.publishLomakeEvent(new LomakeEvent(null, "send", false));
      return Promise.resolve(id);
    }
  }

  cancelLomake() {
    this.lomakeRedirectService.canRedirect = true;
    return this.router.navigate([this.etusivuURL]);
  }

  createLomakeRequest(lomakeJson: any, liite: LiitetiedostoResponse[], asia: string): LomakeRequest {
    return {
      body: JSON.stringify({data: lomakeJson, title: asia}),
      liitteet: liite,
      perustiedot: lomakeJson.perustiedot,
      yhteyshenkilot: lomakeJson.yhteyshenkilot.yhteyshenkilot
    } as LomakeRequest;
  }

  private convertLiitetiedostoObject(liitetiedostot: { [s: string]: LiitetiedostoResponse[] }): LiitetiedostoResponse[] {
    liitetiedostot = !Boolean(liitetiedostot) ? {} : liitetiedostot;
    let liiteList = [];
    for (const key in liitetiedostot) {
      if (liitetiedostot.hasOwnProperty(key)) {
        liiteList = liiteList.concat(liitetiedostot[key]);
      }
    }
    return liiteList;
  }
}
