import {Component, forwardRef, Input} from "@angular/core";
import {Teksti} from "../../utils/teksti";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

export class ToimintaDetails {
  selite: Teksti;
  kategoria: string;
  ikoni = "fa-check-circle";

  constructor(selite: Teksti, kategoria: string, ikoni: string) {
    this.kategoria = kategoria;
    this.selite = selite;
    this.ikoni = ikoni;
  }
}


@Component({
  selector: 'toiminta-card',
  styleUrls: ['toiminta-card.component.scss'],
  template: `
    <div [id]="'toiminta-'+otsikko.key" class="card {{toimintaDetails.kategoria}}" [ngClass]="{'selected': fieldValue}" (click)="toggleField()">
      <div class="card-header">
        <span *ngIf="toimintaDetails.ikoni" [ngClass]="['fa', toimintaDetails.ikoni, 'pr-1']"></span>
        <span [attribute]="otsikko.getFullKey()">Otsikko</span>
      </div>
      <div class="card-body">
        <p class="small" [attribute]="toimintaDetails.selite.getFullKey()">Selite</p>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToimintaCardComponent),
    }
  ]
})
export class ToimintaCardComponent implements ControlValueAccessor {
  @Input() otsikko: Teksti;
  @Input() toimintaDetails: ToimintaDetails;
  @Input() formControlName: string;

  fieldValue: boolean;

  private _onChange = (_: any) => {
    // This is intentional
  };

  private _onTouched = (_: any) => {
    // This is intentional
  };


  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  toggleField() {
    this.fieldValue = !this.fieldValue;
    this.writeValue(this.fieldValue);
  }

  writeValue(value: boolean): void {
    this.fieldValue = value;
    this._onChange(this.fieldValue);
  }
}
