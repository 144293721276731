<h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="this.item.label.fullKey"></h3>
<lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                     [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
<div class="row" *ngFor="let subItem of this.item.subitems; index as i">
  <div class="col-full col-lvl-1">
    <h4 [attribute]="subItem.label.fullKey"></h4>
  </div>
  <ng-container *ngFor="let subsubItem of subItem.subitems; index as i">
    <div class="col-full col-lvl-2">
      <h5 [attribute]="subsubItem.label.fullKey"></h5>
    </div>
    <div class="col-left col-lvl-2">
      <p [attribute]="item.options?.maaraTeksti?.fullKey">
        Maahantuotavien lannoitteiden määrä
      </p>
    </div>
    <div class="col-right">
      <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
        <span class="mr-1"
              id="{{this.item.label.key === 'euLannoiteValmisteenTuonti' ? 'lannotteidenmaaraTuonti' : 'lannotteidenmaaraVienti'}}-{{subItem.label.key}}-{{subsubItem.label.key}}">{{subsubItem.valueMap?.maara?.label}}</span>
        <span attribute="lannoiterekisteri.kgVuosi"></span>
      </div>
    </div>
  </ng-container>
</div>
