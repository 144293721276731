import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, forwardRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild} from "@angular/core";

/**
 * Created by Seppo on 04/02/2019.
 */

@Component({
  selector: "touko-osasto",
  template: `
    <div class="form-control parent" [ngClass]="{'focused': focused, 'readonly': osastoReadonly}" (click)="clickInput()">
        <em *ngIf="yritysValue" >{{yritysValue}}</em>
          <input #inputField
                 id="pt-osasto"
                 (blur)="writeOsasto($event.target.value)"
                 (focus)="setFocus(true)"
                 (keydown)="checkYritys($event.keyCode, $event.target.value)"
                 [value]="osastoValue"
                 [disabled]="osastoReadonly" >
    </div>
    <div class="dropdown fix-margin" *ngIf="showdropdown">
      <div class="dropdown-menu show">
        <button type="button" (click)="setYritysAsPaatoiminimi()" class="dropdown-item active">{{paatoiminimi}}</button>
      </div>
    </div>
  `,
  styles: [
    `
      .fix-margin {
        margin-top: -1.5rem;
      }
      .readonly{
        background-color: #e9ecef;
        opacity: 1;
      }
     .parent{
       display: block;
       overflow: hidden;
       white-space: nowrap;
       padding-top: 0.5rem;
     }
     input{
      width: 80%;
      outline-width: 0;
      background: none;
      border: 0;
    }
    .focused{
      color: #495057;
      border-color: #409bff;
      background-color: #fff;
      box-shadow: 0 0 0 0.2rem rgba(0, 91, 191, 0.25);
      outline: 0 none;
    }`
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OsastoComponent),
    }
  ]
})
export class OsastoComponent implements ControlValueAccessor, OnChanges {
  @Input() paatoiminimi = "";
  @ViewChild("inputField", { static: true }) inputField: ElementRef;
  @Input() osastoNimiLock = false;
  @Input() osastoReadonly = false;
  yritysValue = "";
  osastoValue = "";
  fieldValue: string = null;
  focused = false;
  showdropdown = false;

  private DELETE_KEY = 8;
  private ENTER_KEY = 13;

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('paatoiminimi')) {
      this.resolveYritys(this.fieldValue);
      this.resolveOsasto(this.fieldValue);
    }
  }

  clickInput() {
    this.inputField.nativeElement.focus();
  }

  setFocus(isFocused) {
    this.focused = isFocused;
  }

  checkYritys(keyCode, value) {

    this.setDropdown(this.yritysValue + value);
    if (keyCode === this.DELETE_KEY && !value && !this.osastoNimiLock) {
      this.yritysValue = "";
    } else if (keyCode === this.ENTER_KEY && this.showdropdown) {
      this.setYritysAsPaatoiminimi();
    } else if (this.paatoiminimi === value && !this.yritysValue) {
      this.setYritysAsPaatoiminimi();
    } else if (value.length > 2 && value.startsWith(this.paatoiminimi) && !this.yritysValue) {
      this.yritysValue = this.paatoiminimi;
      this._renderer.setProperty(this.inputField.nativeElement, "value", value.substr(this.paatoiminimi.length, value.length));
    }

    this.setFocus(true);
  }

  setYritysAsPaatoiminimi() {
    this.yritysValue = this.paatoiminimi;
    this._renderer.setProperty(this.inputField.nativeElement, "value", "");
    this.showdropdown = false;
  }

  setDropdown(value) {
    if (value !== this.paatoiminimi) {
      this.showdropdown = this.paatoiminimi.startsWith(value) && value.length > 2 && value.length < this.paatoiminimi.length;
    } else {
      this.showdropdown = false;
    }
  }

  writeOsasto(osValue) {
    let valueToWrite = "";
    this.osastoValue = this.getOsastoValue(osValue);

    if (this.yritysValue && osValue) {
      this.yritysValue = this.paatoiminimi + ", ";
      valueToWrite = this.yritysValue + this.osastoValue;
    } else if (this.yritysValue && !osValue) {
      this.yritysValue = this.paatoiminimi;
      valueToWrite = this.yritysValue;
    } else if (!this.yritysValue && osValue) {
      valueToWrite = this.osastoValue;
    }

    this._renderer.setProperty(this.inputField.nativeElement, "value", this.osastoValue);
    this.writeValue(valueToWrite, false);
    this.setFocus(false);
  }

  writeValue(value: string, init = true): void {
    this.fieldValue = value ? value : "";

    if (init) {
      this.resolveYritys(this.fieldValue);
    }

    this._onChange(this.fieldValue);
    this._onTouched(true);
  }

  resolveYritys(value) {
    if (!value || !value.startsWith(this.paatoiminimi)) {
      return;
    }

    this.yritysValue = this.paatoiminimi;
  }

  resolveOsasto(value) {
    if (!value || !value.startsWith(this.paatoiminimi)) {
      this.osastoValue = value;
      return;
    }

    if (value.startsWith(this.paatoiminimi) && value.length > this.paatoiminimi.length) {
      this.yritysValue += ", ";
      this.osastoValue = value.substr(this.paatoiminimi.length + 1, value.length).trim();
    }
  }

  getOsastoValue(value) {
    if (!value) {
      return value;
    }
    return this.clearOsastoPrefix(value);
  }

  clearOsastoPrefix(val) {
    let returnVal = val;
    const match = val.match("^[^A-Za-z0-9]*");
    if (match) {
      returnVal = val.slice(match[0].length);
    }
    return returnVal.trim();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
