import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {AccountService} from "../account/account.service";

/**
 * Route guard, jonka avulla voidaan varmistaa että käyttäjä on kirjautunut sisään
 * ennen navigoinnin suorittamista.
 */
@Injectable()
export class ViranomainenGuardService implements CanActivate {

  constructor(private accountService: AccountService) {}

  canActivate(): boolean {
    return this.accountService.isViranomainen();
  }
}
