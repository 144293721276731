import {Component, ComponentFactoryResolver, OnInit, Type, ViewChild} from '@angular/core';
import {RaporttiHaku, SortOrder} from "../raportti.utils";
import {TulosraporttiService} from "../raportti.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {RehuvalvontaRaporttiComponent} from "../raportit-rehuvalvonta/rehuvalvonta-raportti.component";
import {ComponentHostDirective} from "./component-host.directive";
import {RaporttiBaseClass} from "../raportti-base";
import {ActivatedRoute} from "@angular/router";
import {KauppaeraRaporttiComponent} from "../raportit-kauppaera/kauppaera-raportti.component";
import {KateTarkastusComponent} from "../raportit-katetarkastus/katetarkastus-raportti.component";
import {ViljelystarkastuspaatosComponent} from "../raportit-viljelystarkastus/viljelystarkastus-raportti.component";
import {LannoiteRaporttiComponent} from "../raportit-lannoite/lannoite-raportti.component";
import {LuomuRaporttiComponent} from "../raportit-luomu/luomu-raportti.component";
import {RaporttiViewService} from "../raportti-view.service";
import {RaportitVirkailijaService} from "./raportit-virkailija.service";
import {Valvontakohde} from "../../valvontakohde/valvontakohde";
import {Yritys} from "./yritys";
import {AccountService} from "../../account/account.service";
import {SiemenMuutRaporttiComponent} from "../raportit-siemenmuut/siemenmuut-raportti.component";

@Component({
  selector: 'app-raportit-virkailija',
  templateUrl: './raportit-virkailija.component.html',
})
export class RaportitVirkailijaComponent implements OnInit {
  @ViewChild(ComponentHostDirective, {static: true}) componentHost: ComponentHostDirective;

  ytunnus = null;
  toimipaikka = null;
  yritysList: Yritys[] = [];
  valvontakohdeList: Valvontakohde[] = [];
  eValvontakohdeMap: Map<string, Valvontakohde[]> = new Map<string, Valvontakohde[]>();

  toimijaForm: FormGroup;
  currentComponent: RaporttiBaseClass;
  currentServiceFn: (params: any) => Promise<RaporttiHaku>;
  currentRaporttityyppi: string;

  raporttiSettings: {[s: string]: {component: Type<any>; serviceFn: (params: any) => Promise<RaporttiHaku>}};

  private virkailijaToimiala;

  constructor(private readonly tulosraporttiService: TulosraporttiService,
              private readonly fb: FormBuilder,
              private readonly componentFactoryResolver: ComponentFactoryResolver,
              private readonly activatedRoute: ActivatedRoute,
              private readonly raporttiViewService: RaporttiViewService,
              private readonly virkailijaRaporttiService: RaportitVirkailijaService,
              private readonly accountService: AccountService
              ) {
    this.getRaportit = this.getRaportit.bind(this);

    this.raporttiSettings = {
      kauppaeraraportit: {
        component: KauppaeraRaporttiComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaKauppaeraRaportit.bind(this.tulosraporttiService),
      },
      kasvinterveysraportit: {
        component: KateTarkastusComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaKasvinterveysRaportit.bind(this.tulosraporttiService),
      },
      rehuvalvontaraportit: {
        component: RehuvalvontaRaporttiComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaRehuvalvontaRaportit.bind(this.tulosraporttiService),
      },
      viljelystarkastuspaatokset: {
        component: ViljelystarkastuspaatosComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaViljelystarkastusRaportit.bind(this.tulosraporttiService)
      },
      siemenvalvontaraportit: {
        component: SiemenMuutRaporttiComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaSiemenvalvontaraportit.bind(this.tulosraporttiService)
      },
      lannoitevalvontaraportit: {
        component: LannoiteRaporttiComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaLannoitevalvontaRaportit.bind(this.tulosraporttiService)
      },
      luomuraportit: {
        component: LuomuRaporttiComponent,
        serviceFn: this.tulosraporttiService.getVirkailijaLuomuvalvontaRaportit.bind(this.tulosraporttiService)
      },
    };
  }

  get yritysControl() {
return this.toimijaForm.get('yritys');
}
  get toimipaikkaControl() {
return this.toimijaForm.get('toimipaikka');
}
  get easiointiControl() {
return this.toimijaForm.get('easiointi');
}
  get avaamattomatAsiakirjatControl() {
return this.toimijaForm.get('avaamattomatAsiakirjat');
}

  ngOnInit(): void {
    this.currentServiceFn = async () => new RaporttiHaku();
    this.virkailijaToimiala = this.accountService.getCurrentAccount().toimiala;

    this.toimijaForm = this.fb.group({
      toimipaikka: [{value: null, disabled: true}],
      yritys: null,
      easiointi: false,
      avaamattomatAsiakirjat: [{value: false, disabled: true}],
    });
    this.yritysControl.valueChanges.subscribe(yritys => {
      if (yritys) {
        this.toimipaikkaControl.enable({emitEvent: false, onlySelf: true});
        if (this.easiointiControl.value) {
          this.setSahkoinenAsiointitoimijat(this.avaamattomatAsiakirjatControl.value);
        } else {
          this.setAllYritysValvontakohteet(yritys.yTunnus);
        }
      }
    });

    this.easiointiControl.valueChanges.subscribe(easiointi => {
      this.clearToimija();
      if (easiointi) {
        this.avaamattomatAsiakirjatControl.enable();
        this.updateSahkoinenAsiointitoimijat().then(() => {
          this.setSahkoinenAsiointitoimijat(this.avaamattomatAsiakirjatControl.value);
        });
      } else {
        this.avaamattomatAsiakirjatControl.setValue(false);
        this.avaamattomatAsiakirjatControl.disable();
        this.updateToimijat().then(() => {
          if (this.yritysControl.value) {
            this.setAllYritysValvontakohteet(this.yritysControl.value.yTunnus);
          }
        });
      }
    });

    this.avaamattomatAsiakirjatControl.valueChanges.subscribe(showOnlyAvaamattomat => {
      this.setSahkoinenAsiointitoimijat(showOnlyAvaamattomat);
    });

    this.activatedRoute.params.subscribe(params => {
      if (params && this.raporttiSettings.hasOwnProperty(params.raporttisivu)) {
        this.setRaporttiComponent(params.raporttisivu);
      }
    });

    this.virkailijaRaporttiService.getYritykset().then(yritykset => {
      if (yritykset) {
        yritykset.sort((a, b) => a.paaToiminimi.localeCompare(b.paaToiminimi));

        this.yritysList = yritykset;
      }
    });
  }

  private setAllYritysValvontakohteet(yritys: string): void {
    this.virkailijaRaporttiService.getValvontakohteet(yritys)
    .then(vkList => vkList.filter(vk => vk.toimialat.indexOf(this.virkailijaToimiala) >= 0))
    .then(vkList => {
      this.valvontakohdeList = vkList;
      this.clearToimija();
    });
  }

  private updateSahkoinenAsiointitoimijat(): Promise<void> {
    return this.virkailijaRaporttiService.getSahkoinenAsiointiToimijat().then(r => {
        this.yritysList = [];
        r.forEach(eToimija => {
          this.yritysList.push(eToimija.yritys);
          this.eValvontakohdeMap.set(eToimija.ytunnus, eToimija.sahkoinenToimipaikkaList);
        });
      }
    );
  }

  private updateToimijat(): Promise<void> {

    return this.virkailijaRaporttiService.getYritykset().then(yritykset => {
      if (yritykset) {
        yritykset.sort((a, b) => a.paaToiminimi.localeCompare(b.paaToiminimi));

        this.yritysList = yritykset;
      }
    });
  }

  private setSahkoinenAsiointitoimijat(showOnlyAvaamattomat = false) {
  const yTunnus = this.yritysControl.value ? this.yritysControl.value.yTunnus : null;
  const fullValvontkohdeList = this.eValvontakohdeMap.get(yTunnus) || [];

    this.valvontakohdeList = showOnlyAvaamattomat ?
      fullValvontkohdeList.filter(vk => vk.hasUnreadAsiakirja) :
      fullValvontkohdeList;
  }

  private setRaporttiComponent(raporttityyppi: string) {
    this.currentRaporttityyppi = raporttityyppi;
    this.currentServiceFn = this.raporttiSettings[raporttityyppi].serviceFn;

    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();

    const component = this.componentFactoryResolver.resolveComponentFactory(this.raporttiSettings[raporttityyppi].component);
    const componentRef = viewContainerRef.createComponent(component);
    const componentView = componentRef.instance as RaporttiBaseClass;
    componentView.getRaportitFn = this.getRaportit;
    this.currentComponent = componentView;
  }

  private clearToimija() {
    this.toimipaikkaControl.setValue(null, {emitEvent: false, onlySelf: true});
    this.updateToimija(null);
  }

  updateToimija(valvontakohde: Valvontakohde) {
    this.toimipaikka = valvontakohde ? valvontakohde.elmoId : null;

    this.currentComponent.submit().then(() => {
      this.currentComponent.initTags();
      this.raporttiViewService.jarjesta('kasiteltyAika', SortOrder.ASC);
    });
  }

  getRaportit(params?): Promise<RaporttiHaku> {
    if (!this.toimipaikka) {
      return new Promise(() => new RaporttiHaku());
    }

    if (params) {
      params.toimipaikka = this.toimipaikka;
    } else {
      params = {toimipaikka: this.toimipaikka};
    }

    return this.currentServiceFn(params);
  }
}
