import {NgModule} from "@angular/core";
import {SortArrowDirective} from "./sort-arrow.directive";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BrowserModule} from "@angular/platform-browser";
import {TilaBadgeComponent} from "./tila-badge.component";
import {AttributeModule} from "../tiira/attribute.module";
import {DevFeatureDirective} from "./dev-feature.directive";
import {YesNoBadgeComponent} from "./yes-no-badge.component";
import {CustomDateFormatter, CustomDatepickerI18n} from "./localizedDates";
import {TestFeatureDirective} from "./test-feature.directive";
import {WipFeatureDirective} from "./wip-feature.directive";
import {IsAsiakasDirective} from "./is-asiakas.directive";
import {IsViranomainenDirective} from "./is-viranomainen.directive";

@NgModule({
  declarations: [
    SortArrowDirective,
    TilaBadgeComponent,
    YesNoBadgeComponent,
    DevFeatureDirective,
    TestFeatureDirective,
    WipFeatureDirective,
    IsAsiakasDirective,
    IsViranomainenDirective
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AttributeModule,
  ],
  exports: [
    SortArrowDirective,
    TilaBadgeComponent,
    YesNoBadgeComponent,
    DevFeatureDirective,
    TestFeatureDirective,
    WipFeatureDirective,
    IsAsiakasDirective,
    IsViranomainenDirective
  ],
  providers: [
    CustomDatepickerI18n,
    CustomDateFormatter
  ]
})
export class UtilsModule {
}
