import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {
  InitValueType,
  QuestionInitMatcher,
  QuestionInitValue,
  QuestionUpdate,
  QuestionUpdateType
} from "../question/question-model";
import {
  CompareAction,
  Matcher,
  MatcherModel,
  MatcherValueChange,
  ModelFragment,
  ModelFragmentType,
  Topic,
  TopicListener
} from "./matcher-model";
import {QuestionGroup} from "../question/question-group";
import {QuestionControl} from "../question/question-control";
import {QuestionArray} from "../question/question-array";
import {PathUtil} from "../question/path-util";


@Injectable({
  providedIn: 'root'
})
export class MatcherService {
  get form(): FormGroup {
    return this._form;
  }
  get model(): { [p: string]: QuestionGroup } {
    return this._model;
  }

  private initialValues: QuestionInitValue[] = [];
  private initialFilters: QuestionInitMatcher[] = [];
  private registeredFilterSubjects: {[s: string]: {subject: BehaviorSubject<MatcherValueChange>, matcherSetter}} = {};

  private constantFilteredPaths = [];
  private filteredPaths: {[s: string]: CompareAction} = {};
  private readonly _modelUpdate = new Subject<QuestionUpdate>();
  $modelUpdate = this._modelUpdate.asObservable();

  private _form: FormGroup;
  private _model: {[s: string]: QuestionGroup} = null;

  filterMatcher: {[s: string]: Matcher[]} = {};
  disablerMatcher: {[s: string]: Matcher[]} = {};
  modifierMatcher: {[s: string]: Matcher[]} = {};
  aggregatorMatcher: {[s: string]: Matcher[]} = {};
  subscribersList: {[topic: string]: TopicListener[]} = {};
  activeTopics: {[topic: string]: {topic: Topic, message: any}} = {};

  styleMap: {[s: string]: string};

  subs: Subscription[];

  public initModelAndForm(groups: QuestionGroup[]) {
    this._model = this.buildModel(groups);
    this._form = this.buildForm(groups);
    this.applyQuestionGroupMatchers(groups);
  }

  public initStyleMap(styleMap: {[s: string]: string}) {
    this.styleMap = styleMap;
    Object.entries(styleMap).forEach(([key, value]) => {
      const modelFragments = this.findAllByPath(key.split('.'));
      modelFragments.forEach(fragment => this.applyOptions(fragment, {style: value}));
    });
  }

  /**
   * Palauttaa lomakkeesta objektin, jossa avaimena FormGroup nimi ja arvona kysymykset
   *
   * @param groups - Lomakkeen kysymysosiot
   */
  private buildModel(groups: QuestionGroup[]): { [s: string]: QuestionGroup } {
    const modelGroup = {};
    groups.forEach(group => modelGroup[group.key] = group);
    return modelGroup;
  }

  /**
   * Luo Angular FormGroup-objektin annettujen QuestionGroup-objektien mukaan.
   *
   * @param groups - Lomakkeen kysymysosiot
   */
  private buildForm(groups: QuestionGroup[]): FormGroup {
    const formGroup = {};

    groups.forEach(group => {
      if (group.groups && group.groups.length > 0) {
        formGroup[group.key] = this.buildForm(group.groups);
        if (group.validators) {
          formGroup[group.key].setValidators(group.validators);
        }
        return;
      }

      const groupControls = {};
      if (group.array) {
        const controls = group.array.questions.map(q => this.createFormElement(q));
        groupControls[group.array.key] = new FormArray(controls, group.array.validators);
      }

      if (group.controls) {
        group.controls.forEach(control => {
          groupControls[control.key] = new FormControl(control.initialValue, control.validators);
        });
      }

      formGroup[group.key] = new FormGroup(groupControls, group.validators);
    });

    return new FormGroup(formGroup);
  }

  public getVisibleFormValues(visibleChildNodes = new Set<string>(), values?: Object): Object {
    const rawValue = values || this._form.getRawValue();
    const addedValues = {};

    Object.keys(rawValue).forEach(key => {
      const valuesToAdd = this.getVisibleRecursive(key, visibleChildNodes, rawValue[key]);
      if (valuesToAdd !== undefined) {
        addedValues[key] = valuesToAdd;
      }
    });
    return addedValues;
  }

  private getVisibleRecursive(rawValuePath, possibleFilteredPaths: Set<string>, value) {
    if (possibleFilteredPaths.size === 0 || possibleFilteredPaths.has(rawValuePath) || value == false) {
      return value;
    } else if (value instanceof Array) {
      const values = [];
      value.forEach((val, index) => {
        const key = val.id || index;
        const valuesToAdd = this.getVisibleRecursive(`${rawValuePath}.${key}`, possibleFilteredPaths, val);
        if (valuesToAdd !== undefined) {
          values.push(valuesToAdd);
        }
      });
      return values.length > 0 ?
        values :
        undefined;
    } else if (value instanceof Object)  {
      const addedValues = {};
      Object.keys(value).forEach(key => {
        const valuesToAdd = this.getVisibleRecursive(`${rawValuePath}.${key}`, possibleFilteredPaths, value[key]);
        if (valuesToAdd !== undefined) {
          addedValues[key] = valuesToAdd;
        }
      });
      return Object.keys(addedValues).length > 0 ?
        addedValues :
        undefined;
    }
    return undefined;
  }

  private createFormElement(question: ModelFragment): AbstractControl {
    if (question instanceof QuestionControl) {
      return new FormControl(question.initialValue, question.validators);
    } else if (question instanceof QuestionGroup) {
      const controlsMap = {};
      const groups = question.groups || [];
      const controls = question.controls || [];
      const questions = [...groups, ...controls];
      questions.forEach(q => controlsMap[q.key] = this.createFormElement(q));

      if (question.array) {
        controlsMap[question.array.key] = this.createFormElement(question.array);
      }

      return new FormGroup(controlsMap, question.validators);
    } else if (question instanceof QuestionArray) {
      const questions = question.questions.map(q => this.createFormElement(q));
      return new FormArray(questions, question.validators);
    }
    return null;
  }

  areObjectStringsEqual = (left, right) => (left || "").toString() === (right || "").toString();

  registerInitialValue(questionInitValue: QuestionInitValue) {
    this.initialValues.push(questionInitValue);
  }

  registerInitialFilter(questionInitMatcher: QuestionInitMatcher) {
    this.initialFilters.push(questionInitMatcher);
  }

  registerControlFilterSubject(key, subject, setControlFilterFn) {
    this.registeredFilterSubjects[key] = {subject: subject, matcherSetter: setControlFilterFn};
  }

  /**
   * Päivittää Angular lomakkeen.
   *
   * @param questionUpdate - Tiedot lomakkeen päivittämiseen
   */
  updateForm(questionUpdate: QuestionUpdate) {
    const formLocation = this.findFormPath(questionUpdate.path);

    if (formLocation instanceof FormArray) {
      if (questionUpdate.updateType === QuestionUpdateType.ADD) {
        const newControl = questionUpdate.control ?
          questionUpdate.control :
          this.createFormElement(questionUpdate.question);
        formLocation.push(newControl);
      } else if (questionUpdate.updateType === QuestionUpdateType.UPDATE) {
        const newControl = questionUpdate.control ?
          questionUpdate.control :
          this.createFormElement(questionUpdate.question);
        formLocation.clear();
        formLocation.push(newControl);
      } else if (questionUpdate.updateType === QuestionUpdateType.REMOVE) {
        const index = formLocation.controls.findIndex(c => this.areObjectStringsEqual(c.value.id, questionUpdate.key));
        formLocation.removeAt(index);
      }
      this.updateModelArray(questionUpdate);
    }
  }

  findFormPath(path: string): AbstractControl {
    const splitted = path.split('.');
    let foundPathFragments = 0;
    let currentGroup: AbstractControl = this._form;

    if (currentGroup === null) {
      return null;
    }

    splitted.forEach(fragment => {
      if (!currentGroup) {
        console.error(`Lomakkeen polkua '${path}' ei löytynyt`);
        currentGroup = null;
        return;
      }

      const temp = currentGroup.get(fragment);
      if (temp !== null) {
        currentGroup = temp;
        foundPathFragments++;
      } else if (currentGroup instanceof FormArray) {
         currentGroup = currentGroup.controls.find(c => this.areObjectStringsEqual(c.value.id, fragment));
         foundPathFragments++;
      }
    });
    return splitted.length === foundPathFragments ? currentGroup : null;
  }

  /**
   * Päivittää lomakkeen mallin, toteutettu niille mallin osille, jotka on liitetty
   * FormArray-tyyppisiin lomakkeen kenttiin.
   *
   * @param questionUpdate - Tiedot mallin päivittämiseen
   */
  private updateModelArray(questionUpdate: QuestionUpdate) {
    const modelLocation = this.getModelLocation(questionUpdate.path.split('.'));
    const modelFragmentType = ModelFragmentType.ARRAY_ITEM;
    if (!modelLocation) {
      console.error("Polkua lomakkeen malliin ei löytynyt");
    } else if (modelLocation && questionUpdate.updateType === QuestionUpdateType.ADD) {
      modelLocation.push(questionUpdate.question, modelFragmentType);
      const modelFragment = modelLocation.find([questionUpdate.key]);
      this.updateModelStyle(modelFragment);
      this.applySingleModelFragmentMathcer(modelFragment);
      this._modelUpdate.next(questionUpdate);
    } else if (modelLocation && questionUpdate.updateType === QuestionUpdateType.UPDATE) {
      modelLocation.clear(ModelFragmentType.ARRAY_ITEM);
      modelLocation.push(questionUpdate.question, modelFragmentType);
      const modelFragment = modelLocation.find([questionUpdate.key]);
      this.updateModelStyle(modelFragment);
      this.applySingleModelFragmentMathcer(modelFragment);
      this._modelUpdate.next(questionUpdate);
    } else if (modelLocation && questionUpdate.updateType === QuestionUpdateType.REMOVE) {
      modelLocation.remove(questionUpdate.key, modelFragmentType);
      this._modelUpdate.next(questionUpdate);
    }
  }

  private updateModelStyle(modelFragment: ModelFragment) {
    const childFragments = modelFragment.findAllLeaves();
    Object.entries(this.styleMap).forEach(([key, value]) => {
      childFragments.forEach(childFragment => {
        if (childFragment.fullPath && PathUtil.checkPathMatchesPattern(childFragment.fullPath, key)) {
          this.applyOptions(childFragment, {style: value});
        }
      });
    });
  }

  /**
   * Hakee polkua vastaavaan ModelFragment-objektin.
   *
   * @param path - Polku mallin osaan
   */
  private getModelLocation(path: string[]): ModelFragment {
    const modelGroup = this._model[path[0]];
    path.splice(0, 1);
    return modelGroup.find(path);
  }

  applyQuestionGroupMatchers(questionGroups: QuestionGroup[]) {
    questionGroups.forEach(group => {
      this.applySingleModelFragmentMathcer(group);
    });
  }

  private applySingleModelFragmentMathcer(modelFragment: ModelFragment) {
    this.applyModelFragmentMatchers(modelFragment);

    if (modelFragment instanceof QuestionGroup) {
      if (modelFragment.groups) {
        this.applyQuestionGroupMatchers(modelFragment.groups);
      }
      if (modelFragment.controls) {
        modelFragment.controls.forEach(control => {
          this.applyModelFragmentMatchers(control);
        });
      }
      if (modelFragment.array) {
        this.applyModelFragmentMatchers(modelFragment.array);
        const containsGroups = modelFragment.array.questions.some(q => q instanceof QuestionGroup);
        if (containsGroups) {
          this.applyQuestionGroupMatchers(modelFragment.array.questions as QuestionGroup[]);
        } else {
          modelFragment.array.questions.forEach(control => {
            this.applyModelFragmentMatchers(control);
          });
        }
      }
    }

    if (modelFragment instanceof QuestionArray) {
      const containsGroups = modelFragment.questions.some(q => q instanceof QuestionGroup);
      if (containsGroups) {
        this.applyQuestionGroupMatchers(modelFragment.questions as QuestionGroup[]);
      } else {
        modelFragment.questions.forEach(control => {
          this.applyModelFragmentMatchers(control);
        });
      }
    }

  }

  private applyModelFragmentMatchers(modelFragment: ModelFragment) {
    this.applyAllMatchers(this.filterMatcher, modelFragment.filters);
    this.applyAllMatchers(this.disablerMatcher, modelFragment.disablers);
    this.applyAllMatchers(this.modifierMatcher, modelFragment.modifiers);
    this.applyAllMatchers(this.aggregatorMatcher, modelFragment.aggregators);
    this.applyPubSub(modelFragment);
  }

  /**
   * Asettaa MatcherServicelle kuuntelijat form kentille
   * @param matcherMap - filter-, disabler-, modifier- tai aggregatorMatcher
   * @param matchers - form kentän matcherit
   */
  private applyAllMatchers(matcherMap: {[s: string]: Matcher[]}, matchers: {[s: string]: MatcherModel}) {
    if (matchers) {
      Object.keys(matchers).forEach(key => {
        if (!matcherMap.hasOwnProperty(key)) {
          this.applyMatcher(matcherMap, key.split('.'));
        }
      });
    }
  }

  private applyPubSub(modelFragment: ModelFragment) {
    const parsedPath = this.parsePath(modelFragment.fullPath.split('.'));
    const formGroup = this._form.get(parsedPath) || this.findFormPath(modelFragment.fullPath);

    if (formGroup && modelFragment.subscribers.length > 0) {
      modelFragment.subscribers.forEach(subscriber => {
        subscriber.attachControl(formGroup);
        subscriber.attachModelFragment(modelFragment);
        if (!this.subscribersList[subscriber.topic]) {
          this.subscribersList[subscriber.topic] = [];
        }
        this.subscribersList[subscriber.topic].push(...modelFragment.subscribers);
        const activeTopic = this.activeTopics[subscriber.topic];
        if (activeTopic) {
          subscriber.receive(activeTopic.topic, activeTopic.message);
        }
      });

    }

    if (formGroup && modelFragment.publishers.length > 0) {
      formGroup.valueChanges.subscribe(value => {
        modelFragment.publishers.forEach(topic => {
          const subs = this.subscribersList[topic.topic];
          if (subs) {
            this.activeTopics[topic.topic] = {topic: topic, message: value};
            subs.forEach(s => s.receive(topic, value));
          }
        });
      });
    }

  }

  /**
   * Asettaa kuuntelijan halutulle formGroup-komponentille. Kun formGroupin arvot vastaavat matchereitä,
   * rekisteröidylle komponentille suoritetaan määritetty toiminto.
   *
   * @param matcherMap - filter-, disabler-, modifier- tai aggregatorMatcher
   * @param path - polku, jonka arvojen muutoksia seurataan
   */
  private applyMatcher(matcherMap: {[s: string]: Matcher[]}, path: string[]) {
    if (!this._form) {
      console.error("Lomake tulee asettaa metodissa `initForm`");
    }

    if (!path || path.length === 0) {
      return;
    }

    let formGroup: AbstractControl = null;
    const parsedPath = this.parsePath(path);

    try {
      formGroup = this._form.get(parsedPath);
    } catch (e) {
      console.error("Ei löydetty polkua", parsedPath);
      console.error(e);
      return;
    }

    if (formGroup === null) {
      return;
    }

    const joinedPath = parsedPath.join(".");
    matcherMap[joinedPath] = [];

    formGroup.valueChanges.subscribe(val => {
      if (matcherMap[joinedPath].length > 0) {
        matcherMap[joinedPath].forEach(registeredControl => {
          registeredControl.matcherValueChange.next(
            new MatcherValueChange(registeredControl.path, registeredControl.matcherFn(val), val)
          );
        });
      }
    });
  }

  private parsePath(path: string[]) {
    return path.map(p => isNaN(parseInt(p, 10)) ? p : parseInt(p, 10));
  }

  applyOptionsByPath(path: string[], options: {[key: string]: any}) {
    const modelFragments = this.findAllByPath(path);
    modelFragments.forEach(fragment => this.applyOptions(fragment, options));
  }

  findAllByPath(path: string[]): ModelFragment[] {
    const firstFragment = path && path.length > 0 ? path[0] : null;
    const isFirstFragmentWildCard = PathUtil.isWildCard(firstFragment);

    if (firstFragment && path.length === 1 && (isFirstFragmentWildCard || this._model[firstFragment])) {
      return [this._model[firstFragment]];
    } else if (path.length > 1 && (isFirstFragmentWildCard || this._model[firstFragment])) {
      const found: ModelFragment[] = [];
      const rootModels = isFirstFragmentWildCard ?
        Object.values(this._model) :
        [this._model[firstFragment]];

      rootModels.forEach(rootModel => {
        rootModel.findAll(PathUtil.getConsumedPath(path, rootModel.key), found);
      });
      return found;
    }
    return [];
  }

  applyOptionsByControlType(controlType: string, options: {[key: string]: any}) {
    Object.values(this._model).forEach(modelFragment => {
      this.applyOptionRecursiveByControlType(controlType, modelFragment, options);
    });
  }

  private applyOptionRecursiveByControlType(controlType: string, modelFragment: ModelFragment, options: {[key: string]: any}) {
    if (modelFragment instanceof QuestionGroup) {
      if (modelFragment.controlType === controlType || modelFragment.groupComponentType === controlType) {
        this.applyOptions(modelFragment, options);
      }
      (modelFragment.groups || []).forEach(g => this.applyOptionRecursiveByControlType(controlType, g, options));
      (modelFragment.controls || []).forEach(c => this.applyOptionRecursiveByControlType(controlType, c, options));
      if (modelFragment.array) {
        this.applyOptionRecursiveByControlType(controlType, modelFragment.array, options);
      }
    } else if (modelFragment instanceof QuestionArray) {
      if (modelFragment.controlType === controlType) {
        this.applyOptions(modelFragment, options);
      }
      modelFragment.questions.forEach(q => this.applyOptionRecursiveByControlType(controlType, q, options));
    } else if (modelFragment instanceof QuestionControl && modelFragment.controlType === controlType) {
      this.applyOptions(modelFragment, options);
    }
  }

  private applyOptions(mathcable: ModelFragment, options: {[key: string]: any}) {
    mathcable.options = {...mathcable.options, ...options};
  }

  public applyRegisteredInitialValues() {
    this.initialValues.forEach(initialValue => {
      if (initialValue.value === undefined) {
        return;
      }

      const abstractControl = this.findFormPath(initialValue.modelFragment.fullPath);
      if (initialValue.initType === InitValueType.SET) {
        abstractControl.setValue(initialValue.value);
      } else {
        abstractControl.patchValue(initialValue.value);
      }
    });

    this.initialValues = [];
  }

  public applyRegisteredMatchers() {
    this.initialFilters.forEach(initialMatcher => {
      const rsubj = this.registeredFilterSubjects[initialMatcher.modelFragment.fullPath];
      rsubj.matcherSetter(initialMatcher.matchers);
      this.initControlFiltersState(initialMatcher);
    });

    this.initialFilters = [];
  }

  initControlFiltersState(initialMatcher: QuestionInitMatcher) {
    if (initialMatcher.matchers) {
      Object.entries(initialMatcher.matchers).forEach(([path, value]) => {
        const control = this.findFormPath(path);
        const matchers: Matcher[] = this.filterMatcher[path];
        if (matchers) {
          matchers.forEach(m => m.matcherValueChange.next(
            new MatcherValueChange(m.path, m.matcherFn(control.value), control.value)
          ));
        }
      });
    }
  }

  setConstantFilteredPaths(filtered: string[]) {
    this.constantFilteredPaths = filtered;
  }

  /**
   * Hakee polut (esim. 'tutkimukset'), jotka on asetettu näkyviksi lomakkeella
   */
  getFilteredPaths(): string[] {
    const dynamicFilteredPaths = Object.entries(this.filteredPaths)
      .filter(([key, value]) => value === CompareAction.FILTER)
      .map(([key, value]) => key);

    return [...dynamicFilteredPaths, ...this.constantFilteredPaths];
  }

  setPathFilterStatus(path, compareAction: CompareAction) {
    this.filteredPaths[path] = compareAction;
  }

  /**
   * Rekisteröi yksittäisen filtterin kontrolliin. Filter-tyyppisten matchereiden avulla, kenttiä näytetään ja
   * piilotetaan.
   *
   * @param path - Polku komponenttiin, joka näytetään / piilotetaan
   * @param observable - komponentin kuuntelija, jolle välitetään `MatcherValueChange`-objekti arvojen muutoksista.
   * @param filterFn - Funktio, jonka mukaan kentälle suoritetaan `compareActionFn`.
   */
  registerControlFilterObservable(path, observable: BehaviorSubject<MatcherValueChange>, filterFn: (val: any) => CompareAction) {
    this.registerControlMatcher(this.filterMatcher, path, observable, filterFn);
  }

  /**
   * Rekisteröi yksittäisen disablerin kontrolliin. Disabler-tyyppisten mathcereiden avulla kenttiä enabloidaan ja
   * disabloidaan.
   *
   * @param path - Polku komponenttiin, joka enabloidaan / disabloidaan
   * @param observable - komponentin kuuntelija, jolle välitetään `MatcherValueChange`-objekti arvojen muutoksista.
   * @param disablerFn - Funktio, jonka mukaan kentälle suoritetaan `compareActionFn`
   */
  registerControlDisablerObservable(path, observable: BehaviorSubject<MatcherValueChange>, disablerFn: (val: any) => CompareAction) {
    this.registerControlMatcher(this.disablerMatcher, path, observable, disablerFn);
  }

  /**
   * Rekisteröi yksittäisen modifierin kontrolliin. Modifier-tyyppisten matchereiden avulla muokataan lomakkeen
   * syötteiden arvoja.
   *
   * @param path - Polku komponenttiin, jonka arvoa muutetaan
   * @param observable - komponentin kuuntelija, jolle välitetään `MatcherValueChange`-objekti arvojen muutoksista.
   * @param modifierFn - Funktio, jonka mukaan kentälle suoritetaan `compareActionFn`
   */
  registerControlModifierObservable(path, observable: BehaviorSubject<MatcherValueChange>, modifierFn: (val: any) => CompareAction) {
    this.registerControlMatcher(this.modifierMatcher, path, observable, modifierFn);
  }

  /**
   * Rekisteröi yksittäisen aggregatorin kontrolliin. Aggregator-tyyppisten matchereiden avulla muokataan lomakkeen
   * FormArray-tyyppisiä lomakkeen syötteitä.
   *
   * @param path - Polku komponenttiin, johon kenttiä lisätään / poistetaan
   * @param observable - komponentin kuuntelija, jolle välitetään `MatcherValueChange`-objekti arvojen muutoksista
   * @param aggregatorFn - Funktio, jonka mukaan suoritetaan `compareActionFn`
   */
  registerControlAggregatorObservable(path, observable: BehaviorSubject<MatcherValueChange>, aggregatorFn: (val: any) => CompareAction) {
    this.registerControlMatcher(this.aggregatorMatcher, path, observable, aggregatorFn);
  }

  private registerControlMatcher(matcherMap: {[s: string]: Matcher[]}, path, observable: BehaviorSubject<MatcherValueChange>, matcherFn) {
    if (!matcherMap[path]) {
      console.error(`Polkua ${path} ei löytynyt`);
      console.error(`Määritellyt polut: ${Object.keys(matcherMap).join(", ")}`);
      return;
    }

    matcherMap[path].push(new Matcher(path, observable, matcherFn));
  }
}
