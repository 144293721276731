import {AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn} from '@angular/forms';

/**
 * TemplateTaulukko, extendoi formArrayn, ja lisää siihen automaattisen templaattiominaisuuden, jolla arvoa asettaessa saadaan luotua uudet rivit automaattisesti templaten pohjalta
 */
export class SyoteTemplateTaulukko extends FormArray {

  protected _riviTemplateFunktio: () => AbstractControl = null;

  get riviTemplateFunktio(): (() => AbstractControl) {
    return this._riviTemplateFunktio;
  }

  /**
   *
   * @param riviTemplateFunktio Palauttaa kutsuttaessa formcontrollin uudelle riville
   *
   * @param controls {@link FormArray.controls}
   * @param validatorOrOpts {@link FormArray.validatorOrOpts}
   * @param asyncValidator {@link FormArray.asyncValidator}
   */
  constructor(riviTemplateFunktio: (() => AbstractControl), controls: AbstractControl[], validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(Array.isArray(controls) ? controls : [], validatorOrOpts, asyncValidator);

    this._riviTemplateFunktio = riviTemplateFunktio;

  }

  /**
   * Lisää rivejä taulukkoon kunnes niitä on yhteensä kokonaismäärän verran.
   *
   * Uudet rivit saavat formControllin templatefunktiolta.
   *
   * @param kokonaisMaara
   */
  protected lisaaRiveja(kokonaisMaara: number) {
    if (this.riviTemplateFunktio) {
      const lisaaMaara = kokonaisMaara - this.length;

      for (let n = 0; n < lisaaMaara; n++) {
        this.lisaaTyhja();
      }
    }
  }


  /**
   * Lisää tarvittava määrän rivejä ja ajaa {@link FormArray.patchValue} -funktion.
   *
   * @param value
   * @param options
   */
  public patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.lisaaRiveja(value.length);
    super.patchValue(value, options);
  }


  /**
   * Lisää tarvittava määrän rivejä ja ajaa {@link FormArray.setValue} -funktion.
   *
   * @param value
   * @param options
   */
  public setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.lisaaRiveja(value.length);
    super.setValue(value, options);
  }


  /**
   * Lisää uusi tyhjä templatenmukainen rivi taulukkoon, kätevä ajaa esim
   */
  public lisaaTyhja() {
    this.push(this.riviTemplateFunktio());
  }
}
