import {Component, Input, OnInit} from '@angular/core';
import {CompareStatus, YhteenvetoItem} from "../yhteenveto-utils";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {YhteenvetoComponentBase} from "../yhteenveto-container.component";

@Component({
  selector: 'yhteenveto-item',
  templateUrl: './yhteenveto-item.component.html',
  styleUrls: ['./yhteenveto-item.component.scss']
})
export class YhteenvetoItemComponent implements OnInit, YhteenvetoComponentBase {

  @Input() item: YhteenvetoItem = null;
  @Input() compareStatus: CompareStatus = null;
  @Input() prevItem: YhteenvetoItem = null;
  @Input() showCompareYhteenveto = false;

  showPrevItem = false;
  compareStatusEnum = CompareStatus;
  itemStyle: string;

  constructor(private liitetiedostoService: LiitetiedostoPalvelinService) {
  }

  ngOnInit(): void {
    this.itemStyle = this.compareStatus ?
      CompareStatus[this.compareStatus.valueOf()].toLowerCase() :
      null;
  }

  /**
   * Avaa liitteen uuteen ikkunaan. HUOM! IE:llä tiedosto ladataan.
   *
   * @param item - Liitetiedoston sisältö
   * @returns
   */
  clickOpenLiite(item: LiitetiedostoResponse) {

    return this.liitetiedostoService.downloadLiitetiedosto(item.id)
      .then(blob => {
        if (this.isIE()) {
          const nav = (window.navigator as any);
          nav.msSaveOrOpenBlob(blob, item.tiedostonimi);
        } else {
          const file = window.URL.createObjectURL(blob);
          window.open(file, "_blank");
        }
      });
  }

  toggleShowPrevItem() {
    this.showPrevItem = !this.showPrevItem;
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}
