import {KasvinterveysRekisteriData} from "./kate-rekisteri/kasvinterveys-rekisteri-data";
import {Account} from "../account/account";
import {LomakeDataContent} from "./lomake-data";
import {SiemenpakkaamoData} from "./siemenpakkaamo/siemenpakkaamo-data";
import {LannoiteRekisteriDataUusi} from "./lannoite-rekisteri-uusi/lannoite-rekisteri-data-uusi";
import {SiemenRekisteriData} from "./siemen-rekisteri/siemen-rekisteri-data";
import {RehuvalvontaData} from "./rehuvalvonta/rehuvalvonta-data";
import {LannoiteRekisteriData} from "./lannoite-rekisteri/lannoite-rekisteri-data";
import {LuomuPoikkeus9cData} from "./luomu-poikkeus9c/luomu-poikkeus9c-data";

/**
 * Created by Seppo on 10/01/2019.
 */

const LOMAKE_DATA_FUNCTIONS: {[key: string]: (account?: Account, toimintotyyppi?: string) => LomakeDataContent} = {
  'kylvosiemen-markkinointi-v1': SiemenRekisteriData.getData,
  'siemenpakkaamolupa-v1': SiemenpakkaamoData.getData,
  'rehuvalvonta-v1': RehuvalvontaData.getData,
  'lannoiterekisteri-v1': LannoiteRekisteriData.getData,
  'lannoiterekisteri-v2': LannoiteRekisteriDataUusi.getData,
  'kasvinterveysrekisteri-v1': KasvinterveysRekisteriData.getData,
  'luomupoikkeus9c-v1': LuomuPoikkeus9cData.getData,
};

export const resolveLomakeData = (asia: string, versio = 1, toimintotyyppi?: string, account?: Account): LomakeDataContent => LOMAKE_DATA_FUNCTIONS[`${asia}-v${versio}`](account, toimintotyyppi);
