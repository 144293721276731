import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {SyoteGroup} from "../syote/syote-group";
import {Account} from "../../account/account";
import {keyOrderFn, LomakeData, LomakeDataContent} from "../lomake-data";
import {ToukoValidation} from "../validators/validators";
import {toimintaJaTuoteluokatKansallinen} from "./lannoite-toiminta-ja-tuoteluokat-kansallinen";
import {toimintaJaTuoteluokatEuToiminta} from "./lannoite-toiminta-ja-tuoteluokat-eu-toiminta";


export class LannoiteRekisteriDataUusi extends LomakeData {

  static lomakeTitle: Teksti = new Teksti("Lannoitevalmistetoimintaa koskeva ilmoitus", "otsikko", "lannoiterekisteri");

  static getKeyOrder(): string[] {
    return keyOrderFn(LannoiteRekisteriDataUusi.getData);
  }

  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    const view = LannoiteRekisteriDataUusi.lomakeTitle.view;

    toimintotyyppi = toimintotyyppi?.toLowerCase();
    const LAATUJARJESTELMA_LIITE = new Teksti("Laatujärjestelmä liitteet", "liiteLaatujarjestelma", view);
    const LAATUJARJESTELMA_PVM = new Teksti("Laatujärjestelmä päivä", "liiteLaatujarjestelmaPvm", view);
    const TUOTETIEDOT_LIITE = new Teksti("Valmiste liitteet", "liiteValmisteet", view);
    const TUOTETIEDOT_PVM = new Teksti("Valmiste liitteet", "liiteValmisteetPvm", view);
    const REQUIRED_IN_ALOITUSILMOITUS = toimintotyyppi === "uusi" ? [null, ToukoValidation.tuotetiedotLiiteTaiPvm] : null;
    const TOIMINTAKUVAUS = toimintotyyppi === "muutos" ? "muutosilmoitusToimintaKuvaus" : "uusiIlmoitusToimintaKuvaus";
    const isMuutosIlmoitus = toimintotyyppi === "muutos";

    return {
      ulkomainenOsoite: new SyoteGroup(
        view,
        new Teksti("Ulkomainen osoite", "ulkomainenOsoite", view),
        null,
        null,
        YhteenvetoTyyppi.NONE,
        new Syote(new Teksti("Ulkomainen osoite", "ulkomainenOsoite", view))),
      perustiedot: LannoiteRekisteriDataUusi.getPerustiedotLannoiteUusi(view, account),
      laskutustiedot: LannoiteRekisteriDataUusi.getLaskutustiedot(view, account),
      yhteyshenkilot: LannoiteRekisteriDataUusi.getYhteyshenkilot(view, account),
      toiminnanAloitus: LannoiteRekisteriDataUusi.getToiminnanAloitus(view, toimintotyyppi),
      sahkoinenAsiointi: LannoiteRekisteriDataUusi.getSahkoinenAsiointi(view, toimintotyyppi),
      toimintaJaTuoteluokatKansallinen: toimintaJaTuoteluokatKansallinen(view, toimintotyyppi),
      toimintaJaTuoteluokatEUToiminta: toimintaJaTuoteluokatEuToiminta(view, toimintotyyppi),
      muuToiminta: new SyoteGroup(
        view,
        new Teksti("Muu rekisteröitävä toiminta", "muuToiminta"),
        null,
        null,
        YhteenvetoTyyppi.LANNOITE_MUU_TOIMINTA,
        new Syote(
          new Teksti("Vastavuoroisen tunnustamiseen perustuva lannoitevalmisteen markkinointi", "lannoitevalmisteenMarkkinointi"),
          null,
          "lannoitevalmisteenMarkkinointi",
          null,
          {alakohdeToiminta: 'LANNMARKVASTVTUN', yhteenvetoMaaraTeksti: new Teksti("Määrä", "markkinoilleTuotavienLannoitteidenMaara", view), isMuutosIlmoitus},
          null,
          new Syote(new Teksti('Haluan lopettaa tämän toiminnan', 'haluanLopettaaToiminnan', view), null, 'lopetaToiminta'),
          new Syote(new Teksti("Suomen markkinoille tuotavien lannoitteiden määrä", "markkinoilleTuotavienLannoitteidenMaara", view), null, "maara", null, {
            type: 'text',
            parent: 'lannoitevalmisteenMarkkinointi'
          }),
        ),
      ),

      lannoitesivutuotetoimija: new SyoteGroup(
        view,
        new Teksti("Hyväksyntää edellyttävän eläinperäisen sivutuotteen käsittely lannoitevalmisteeksi", "sivutuotteenKasittelyLannoitevalmisteeksi"),
        null,
        null,
        YhteenvetoTyyppi.LANNOITE_SIVUTUOTE,
        new Syote(
          new Teksti("Lannoitesivutuotetoimija", "lannoitesivutuotetoimija"),
          null,
          "lannoitesivutuotetoimija",
          null,
          {alakohdeToiminta: 'SIVULANNOITE', isMuutosIlmoitus},
          null,
          new Syote(new Teksti('Haluan lopettaa tämän toiminnan', 'haluanLopettaaToiminnan', view), null, 'lopetaToiminta'),
          new Syote(new Teksti("Hae toiminnalle laitoshyväksyntää", "haeToiminnalleLaitoshyväksyntaa", view), null, "laitosHyvaksynta", null, null, YhteenvetoTyyppi.VALUE_ONLY,
            new Syote(new Teksti("Uusi tuotantolaitos tai toiminta", "uusiTuotantolaitosTaiToiminta", view), null, "laitosHyvaksyntaRadio", "uusiTuotantolaitosTaiToiminta", {
              type: 'radio',
            }),
            new Syote(new Teksti("Toiminnassa oleva laitos, ei aiempaa hyväksyntää", "toiminnassaOlevaLaitos", view), null, "laitosHyvaksyntaRadio", "toiminnassaOlevaLaitos", {
              type: 'radio',
            }),
            new Syote(new Teksti("Muu, mikä?", "muu", view), null, "laitosHyvaksyntaRadio", "muu", {
              type: 'radio',
            }),
            new Syote(new Teksti("", "muuInfo", view), {value: null, disabled: true}, "laitosHyvaksyntaMuuInfo", null,  {
              type: 'text', labelledBy: 'laitosHyvaksynta-muu-label'
            }),
          ),
          new Syote(new Teksti("Käsiteltävät raaka-aineet", "kasiteltavatRaakaaineet", view), null, "kasiteltavatRaakaaineet", null, null,
            YhteenvetoTyyppi.VALUE_ONLY,
            new Syote(new Teksti("Luokan 3 jäte", "luokankolmejate", view), null, "luokankolmejate", null, {type: 'checkbox'}),
            new Syote(new Teksti("Muu luokan 3 eläinperäinen sivutuote", "muuLuokankolmeElainperainenSivutuote", view), null, "muuLuokankolmeElainperainenSivutuote", null, {type: 'checkbox'}),
            new Syote(new Teksti("Lanta", "lanta", view), null, "lanta", null, {type: 'checkbox'}),
            new Syote(new Teksti("Muu, mitä?", "muu", view), null, "muu", null, {type: 'checkbox'}),
            new Syote(new Teksti("", "muuInfo", view), {value: null, disabled: true}, "muuInfo", null,  {
              type: 'text', labelledBy: 'kasiteltavatRaakaaineet-muu-label'
            }),
          ),
          new Syote(new Teksti("Ympäristölupa", "ymparistolupa", view), null, "ymparistolupa", null, null,
            YhteenvetoTyyppi.VALUE_ONLY,
            new Syote(new Teksti("Toiminnalla on ympäristölupa", "toiminnallaOnYmparistolupa", view), null, "toiminnallaOnYmparistolupa", null, {type: 'checkbox'}),
            new Syote(new Teksti("Ympäristöluvan numero", "ymparistoluvanNum", view), {value: null, disabled: true}, "luvanNum", null,  {
              type: 'text', labelledBy: 'ymparistolupa-toiminnallaOnYmparistolupa-label'
            }),
          ),
        ),
      ),
      lannoitteenValmistusOmaanKayttoon: new SyoteGroup(
        view,
        new Teksti("Lannoitteen valmistus vain omaan käyttöön?", "lannoitteenValmistusOmaanKayttoon"),
        null,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Lannoitteen valmistus vain omaan käyttöön?", "lannoitteenValmistusOmaanKayttoon")},
        YhteenvetoTyyppi.SINGLE_SELECTION_LABEL,

        new Syote(new Teksti("Kyllä", "kylla"), null, "lannoitteenValmistusOmaanKayttoon", "true"),
        new Syote(new Teksti("Ei", "ei"), null, "lannoitteenValmistusOmaanKayttoon", "false"),
      ),

      lannoitteenValmistusAinoastaanPakkaamista: new SyoteGroup(
        view,
        new Teksti("Lannoitteen valmistus on ainoastaan pakkaamista?", "lannoitteenValmistusAinoastaanPakkaamista"),
        null,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Lannoitteen valmistus on ainoastaan pakkaamista?", "lannoitteenValmistusAinoastaanPakkaamista")},
        YhteenvetoTyyppi.SINGLE_SELECTION_LABEL,

        new Syote(new Teksti("Kyllä", "kylla"), null, "lannoitteenValmistusAinoastaanPakkaamista", "true"),
        new Syote(new Teksti("Ei", "ei"), null, "lannoitteenValmistusAinoastaanPakkaamista", "false"),
      ),

      lannoitteenValmistusAinoastaanPakkaamistaEu: new SyoteGroup(
        view,
        new Teksti("Lannoitteen valmistus on ainoastaan pakkaamista?", "lannoitteenValmistusAinoastaanPakkaamista"),
        null,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Lannoitteen valmistus on ainoastaan pakkaamista?", "lannoitteenValmistusAinoastaanPakkaamista")},
        YhteenvetoTyyppi.SINGLE_SELECTION_LABEL,

        new Syote(new Teksti("Kyllä", "kylla"), null, "lannoitteenValmistusAinoastaanPakkaamistaEu", "true"),
        new Syote(new Teksti("Ei", "ei"), null, "lannoitteenValmistusAinoastaanPakkaamistaEu", "false"),
      ),

      lisatiedot: new SyoteGroup(
        view,
        new Teksti("Toiminnan lisätietoja", "toimintaKuvausOtsikko"),
        null,
        null,
        YhteenvetoTyyppi.LABEL_AND_TEXT,
        new Syote(new Teksti("Muutosilmoitus lisätietoja", TOIMINTAKUVAUS), null)
      ),

      liitePakolliset: new SyoteGroup(
        view,
        new Teksti("Ilmoituksen liitteeksi vaadittavat tiedot", "liiteTiedot"),
        [],
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse valmisteiden tuotetieto.", "valmisteenTuotetiedotPakollinen", view) },
        YhteenvetoTyyppi.ATTACHMENT_DATE,
        new Syote(TUOTETIEDOT_LIITE, REQUIRED_IN_ALOITUSILMOITUS, null, null, {liite: TUOTETIEDOT_LIITE, pvm: TUOTETIEDOT_PVM}),
        new Syote(LAATUJARJESTELMA_LIITE, [null], null, null, {liite: LAATUJARJESTELMA_LIITE, pvm: LAATUJARJESTELMA_PVM})
      ),

      liiteMuut: new SyoteGroup(
        view,
        new Teksti("Muut liitteet", "liiteMuut"),
        [],
        {},
        YhteenvetoTyyppi.ATTACHMENT_NO_LABEL,
        new Syote(new Teksti("Muut liitteet", "liiteMuut"), null)
      ),

      verkkokaupat: LannoiteRekisteriDataUusi.getVerkkokaupat(view, account)

    };
  }
}
