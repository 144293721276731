import {Field, FieldArray, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {attr} from "./siemenpakkaamo-v2.data";
import {DateObj} from "../../syote/syote-utils";
import {RadioValue} from "../common/lomake-field-utils";
import {ToukoValidation} from "../../validators/validators";
import {Validators} from "@angular/forms";
import {TitleAndAnswerSummaryComponent} from "../../summary/title-and-answer-summary.component";
import {AnswerOnlySummaryComponent} from "../../summary/answer-only-summary.component";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";
import {TranslatedQuestionSummaryComponent} from "../../summary/translated-question-summary.component";
import {QuestionAndAnswerKoneetJaLaitteetComponent} from "../../summary/question-and-answer-koneet-ja-laitteet.component";
import {QuestionOnlySummaryComponent} from "../../summary/question-only-summary.component";

export const createHakemusTyypit = (): FieldGroup => {
  return new FieldGroup(
      Field.of(
          {label: attr('hakemustyyppi'), summaryFn: titleSummary}),
      {
        'radio-hakemustyyppi': new FieldControl(
            Field.of({
                  label: attr("hakemustyyppiRadio"),
                  valueOptions: [
                    new RadioValue("hakemusUusi", attr("hakemusUusi"), "hakemusUusi"),
                    new RadioValue("hakemusJatko", attr("hakemusJatko"), "hakemusJatko"),
                    new RadioValue("hakemusLaajennus", attr("hakemusLaajennus"), "hakemusLaajennus")
                  ],
                  errorMessages: {
                    required: attr('valitseYksi')
                  },
                  summaryFn: radioSummary
                },
            ),
            null,
        )
      },
      [ToukoValidation.atLeastOneTrue]
  )
};

export const createMarkkinoimasiKylvosiemenet = (validators: Array<ToukoValidation>): FieldGroup => new FieldGroup(Field.of(
        {
          label: attr('markkinoimasiKylvosiemenet'),
          summaryFn: titleSummary,
          errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr('vahintaanYksi')}
        }),
    {
      viljakasvit: new FieldControl(Field.of({
        label: attr('viljakasvit'),
        htmlId: "viljakasvit",
        summaryFn: checkBoxSummary
      })),
      nurmiJaRehu: new FieldControl(Field.of({
        label: attr('nurmiJaRehu'),
        htmlId: "nurmiJaRehu",
        summaryFn: checkBoxSummary
      })),
      oljyJaKuitu: new FieldControl(Field.of({
        label: attr('oljyJaKuitu'),
        htmlId: "oljyJaKuitu",
        summaryFn: checkBoxSummary
      })),
      siemenperuna: new FieldControl(Field.of({
        label: attr('siemenperuna'),
        htmlId: "siemenperuna",
        summaryFn: checkBoxSummary
      })),
      vihanneskasvit: new FieldControl(Field.of({
        label: attr('vihanneskasvit'),
        htmlId: "vihanneskasvit",
        summaryFn: checkBoxSummary
      })),
      juurikkaat: new FieldControl(Field.of({
        label: attr('juurikkaat'),
        htmlId: "juurikkaat",
        summaryFn: checkBoxSummary
      })),
      seokset: new FieldControl(Field.of({
        label: attr('seokset'),
        htmlId: "seokset",
        summaryFn: checkBoxSummary
      })),
      muutKasvit: new FieldControl(Field.of({
        label: attr('muutKasvit'),
        htmlId: "muutKasvit",
        summaryFn: checkBoxSummary
      })),
      muutKasvitLisatieto: new FieldControl(Field.of({
        label: attr('muutKasvitLisatieto'),
        summaryFn: control => new SummaryItem<any>(null, control.value, {
          show: control.root.get('markkinoimasiKylvosiemenet.muutKasvit').value,
          classList: ["ml-4"]
        }, AnswerOnlySummaryComponent)
      }))
    }, validators as Validators);

export const createLisatiedotToiminta = (): FieldGroup => new FieldGroup(Field.of(
        {
          label: attr('lisatiedotRyhmaOtsikko'),
          summaryFn: titleSummary
        }),
    {
      luomusiemenpakkaus: new FieldControl(Field.of({
        label: attr('luomusiemenpakkaus'),
        htmlId: "luomusiemenpakkaus",
        summaryFn: checkBoxSummary
      })),
      luomusiemenpakkausDetails: new FieldControl(Field.of({
        label: attr('luomusiemenpakkausDetails'),
        htmlId: "luomusiemenpakkausDetails",
        summaryFn: answerSummary
      })),
      onkosopimustuotantoa: new FieldControl(Field.of({
        label: attr('sopimustuotantoa'),
        htmlId: "onkosopimustuotantoa",
        summaryFn: checkBoxSummary
      })),
      onkosopimustuotantoaDetails: new FieldControl(Field.of({
        label: attr('sopimustuotantoDetails'),
        htmlId: "onkosopimustuotantoaDetails",
        summaryFn: answerSummary
      })),
      kaytetaankomuidentoimijoidenpalveluita: new FieldControl(Field.of({
        label: attr('kaytetaankoMuidenPalveluita'),
        htmlId: "kaytetaankomuidentoimijoidenpalveluita",
        summaryFn: checkBoxSummary
      })),
      kaytetaankomuidentoimijoidenpalveluitaDetails: new FieldControl(Field.of({
        label: attr('kaytetaankomuidentoimijoidenpalveluitaDetails'),
        htmlId: "kaytetaankomuidentoimijoidenpalveluitaDetails",
        summaryFn: answerSummary
      })),
      kasitellaankomuutakuinsiementavaraa: new FieldControl(Field.of({
        label: attr('kasitellaankoMuutaKuinSiemTavaraa'),
        htmlId: "kasitellaankomuutakuinsiementavaraa",
        summaryFn: checkBoxSummary
      })),
      kasitellaankomuutakuinsiementavaraaDetails: new FieldControl(Field.of({
        label: attr('kasitellaankomuutakuinsiementavaraaDetails'),
        htmlId: "kasitellaankomuutakuinsiementavaraaDetails",
        summaryFn: answerSummary
      }))
    });

export const createToiminnanLaajuus = (validators: Array<ToukoValidation>): FieldGroup =>
    new FieldGroup(
        Field.of(
            {label: attr('toiminnanLaajuus'), summaryFn: titleSummary}),
        {
          'radio-group': new FieldControl(
              Field.of({
                    label: attr("toiminnanlaajuusRadio"),
                    valueOptions: [
                      new RadioValue("enintaan1000", attr("enintaanTuhat"), "enintaanTuhat"),
                      new RadioValue("1001-10000", attr("enintaan10Tuhatta"), "enintaan10Tuhatta"),
                      new RadioValue("10001-200000", attr("enintaan200tuhatta"), "enintaan200tuhatta"),
                      new RadioValue("200001-500000", attr("enintaan500tuhatta"), "enintaan500tuhatta"),
                      new RadioValue("500001-1000000", attr("enintaanMiljoona"), "enintaanMiljoona"),
                      new RadioValue("yli1000001", attr("yliMiljoonaYksi"), "yliMiljoonaYksi")
                    ],
                    errorMessages: {
                      required: attr('valitseYksi')
                    },
                    summaryFn: radioSummary
                  },
              ),
              null,
          )
        },
        validators as Validators
    )

export const createKoneetJaLaitteet = (validators: Array<ToukoValidation>): FieldGroup => {
  const createLaiteWithCheckboxAndDate = (groupname: string, parentCheckbox: string) => new FieldGroup(Field.of({options: {arrayName: groupname}}),
      {
        checkbox: new FieldControl(Field.of({
          label: attr('milloinRuokavirastoHyvaksynyt'),
          htmlId: parentCheckbox === "automaattinennaytteenotin" ? "checkbox-" : "vakuustulostinCheckbox-"
        })),
        date: new FieldControl(Field.of({
          label: attr('merkitseAjankohta'),
          htmlId: 'date-',
          summaryFn: (c) => {
            const check = c.parent.get('checkbox').value;
            const laitteet = c.parent.parent as FieldArray;
            const laiteIndex = laitteet.controls.indexOf(c.parent)
            return new SummaryItem<any>(
                check ? attr('ruokavirastoHyvaksynyt') : attr('ruokavirastoHyvaksynytEi'), check ? `${c.value?.month}/${c.value?.year}` : "",
                {
                  show: c.root.get(['koneetJaLaitteet', parentCheckbox])?.value,
                  laiteNumber: laitteet.controls.indexOf(c.parent) + 1,
                  checkboxHtmlId: groupname + '-checkbox-' + laiteIndex,
                  dateHtmlId: groupname + '-date-' + laiteIndex
                },
                QuestionAndAnswerKoneetJaLaitteetComponent)
          }
        }), DateObj.firstDayOfMonth())
      }
  );

  const createLaiteWithDate = (parentCheckbox: string) => new FieldGroup(Field.of({options: {arrayName: 'vaakaArray'}}),
      {
        date: new FieldControl(Field.of({
          label: attr('merkitseAjankohta'),
          htmlId: 'date-',
          summaryFn: (c) => {
            const laitteet = c.parent.parent as FieldArray;
            const laiteIndex = laitteet.controls.indexOf(c.parent)
            return new SummaryItem<any>(
                attr('hyvaksymisAjankohta'), `${c.value?.month}/${c.value?.year}`,
                {
                  show: c.root.get(['koneetJaLaitteet', parentCheckbox])?.value,
                  laiteNumber: laitteet.controls.indexOf(c.parent) + 1,
                  checkboxHtmlId: 'vaakaArray-checkbox-' + laiteIndex,
                  dateHtmlId: 'vaakaArray-date-' + laiteIndex
                },
                QuestionAndAnswerKoneetJaLaitteetComponent)
          }
        }), DateObj.firstDayOfMonth())
      }
  );

  return new FieldGroup(Field.of({
        label: attr('koneetJaLaitteet'),
        summaryFn: titleSummary,
        errorMessages: {[ToukoValidation.AT_LEAST_ONE_TRUE]: attr('vahintaanYksi')}
      }),
      {
        lajittelija: new FieldControl(Field.of({
          label: attr('lajittelija'),
          htmlId: "lajittelija",
          summaryFn: checkBoxSummary
        })),
        peittauslaite: new FieldControl(Field.of({
          label: attr('peittauslaite'),
          htmlId: "peittauslaite",
          summaryFn: checkBoxSummary
        })),
        sekoituslaite: new FieldControl(Field.of({
          label: attr('sekoituslaite'),
          htmlId: "sekoituslaite",
          summaryFn: checkBoxSummary
        })),
        eranumeronmerkintalaite: new FieldControl(Field.of({
          label: attr('eranumeronMerkintalaite'),
          htmlId: "eranumeronmerkintalaite",
          summaryFn: checkBoxSummary
        })),
        automaattinennaytteenotin: new FieldControl(Field.of({
          label: attr('automaattinenNaytteenotin'),
          htmlId: "automaattinennaytteenotin",
          summaryFn: checkBoxSummary
        })),
        naytteenotinArray: new FieldArray(Field.build(
                attr('naytteenotinArray')), () => createLaiteWithCheckboxAndDate('naytteenotinArray', 'automaattinennaytteenotin'),
            [
              createLaiteWithCheckboxAndDate('naytteenotinArray', 'automaattinennaytteenotin')
            ]
        ),
        vakuustulostin: new FieldControl(Field.of({
          label: attr('vakuustulostin'),
          htmlId: "vakuustulostin",
          summaryFn: checkBoxSummary
        })),
        vakuustulostinArray: new FieldArray(Field.build(
                attr('vakuustulostinArray')), () => createLaiteWithCheckboxAndDate('vakuustulostinArray', 'vakuustulostin'),
            [
              createLaiteWithCheckboxAndDate('vakuustulostinArray', 'vakuustulostin')
            ]
        ),
        vaaka: new FieldControl(Field.of({
          label: attr('vaaka'),
          htmlId: "vaaka",
          summaryFn: checkBoxSummary
        })),
        vaakaArray: new FieldArray(Field.build(
                attr('vaakaArray')), () => createLaiteWithDate('vaaka'),
            [
              createLaiteWithDate('vaaka')
            ]
        ),
        puhdistuslaitteet: new FieldControl(Field.of({
          label: attr('puhdistuslaitteet'),
          htmlId: "puhdistuslaitteet",
          summaryFn: checkBoxSummary
        })),
        puhdistuslaitteetDetails: new FieldControl(Field.of({
          label: attr('puhdistuslaitteetDetails'),
          htmlId: "puhdistuslaitteetDetails",
          summaryFn: answerSummary
        })),
        muutlaitteet: new FieldControl(Field.of({
          label: attr('muutlaitteet'),
          htmlId: "muutlaitteet",
          summaryFn: checkBoxSummary
        })),
        muutlaitteetDetails: new FieldControl(Field.of({
          label: attr('muutlaitteetDetails'),
          htmlId: "muutlaitteetDetails",
          summaryFn: answerSummary
        })),
      }, validators as Validators);
}

export const createVakuusLipukkeet = (validators: Array<ToukoValidation>): FieldGroup =>
    new FieldGroup(
        Field.of(
            {label: attr('painatus'), summaryFn: titleSummary}),
        {
          'radio-group': new FieldControl(
              Field.of({
                    label: attr("painatusRadio"),
                    valueOptions: [
                      new RadioValue("painetaanItse", attr("painetaanItse"), "painetaanItse"),
                      new RadioValue("tulostetaanItse", attr("tulostetaanItse"), "tulostetaanItse"),
                      new RadioValue("tilataanSiemYksikosta", attr("tilataanSiemYksikosta"), "tilataansiemenYksikosta"),
                    ],
                    errorMessages: {
                      required: attr('valitseYksi')
                    },
                    summaryFn: radioSummary
                  },
              ),
              null,
          )
        },
        validators as Validators
    )

export const createUudetPakkaamotDate = (validators: Array<ToukoValidation>) => {
  let label, titleLabel, htmlId;
  label = attr('tarkastusAjankohta');
  titleLabel = attr('tarkastusAikavalille');
  htmlId = 'tarkastusDate'

  return new FieldGroup(
      Field.build(titleLabel
      ),
      {
        tarkastusDate: new FieldControl(
            Field.of(
                {
                  label: label,
                  summaryFn: (c) => new SummaryItem<any>(
                      titleLabel, `${c.value?.day}.${c.value?.month}.${c.value?.year}`,
                      {show: Boolean(c.value?.day)},
                      TitleAndAnswerSummaryComponent),
                  errorMessages: {
                    [ToukoValidation.PVM_VALID]: 'lomakeYleinen.errTarkastusAjankohta',
                    [ToukoValidation.REQUIRED]: 'lomakeYleinen.errTarkastusAjankohta'
                  },
                  htmlId: htmlId
                }
            ),
            null,
            validators as Validators
        )
      },
  );
}

const createTitleSummary = (showFn: (group: FieldGroup) => boolean, summaryComponent, classList = []): SummaryFnType => {
  return (fieldGroup) => {
    return new SummaryItem<any>(
        fieldGroup.field.label,
        null,
        {show: showFn(fieldGroup.asGroup()), classList: classList},
        summaryComponent)
  }
}

const isAnyChildVisible: (group: FieldGroup) => boolean = group => {
  return Object.values(group.controlFields).some(c => c?.summary?.show)
}

export const titleSummary: SummaryFnType = createTitleSummary(group => isAnyChildVisible(group), TitleSummaryComponent);

const checkBoxSummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, null, {show: control.value}, QuestionOnlySummaryComponent);
}

export const radioSummary = control => {
  const value = control.field.valueOptions.find(o => o.value === control.value);
  return new SummaryItem<any>(value?.label, null, {show: control.value}, TranslatedQuestionSummaryComponent, value)
}

const answerSummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, control.value, {show: control.value}, AnswerOnlySummaryComponent);
}
