import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LOMAKE_KUVAUKSET, LomakeResponse, LomakeKuvaus } from "../../touko-lomake/touko-lomake-utils";
import { Sarake } from "../../raportti/raportti.utils";

@Component({
  selector: '[lomake-row]',
  templateUrl: './lomake-row.component.html',
  styleUrls: ['../../raportti/data-table.component.scss']
})
export class LomakeRowComponent {

  @Input() lomake: LomakeResponse;
  @Input() sarakkeet: Sarake[];
  @Output() selectItem: EventEmitter<SarakeClickEvent> = new EventEmitter<SarakeClickEvent>();

  kuvaukset: { [s: string]: LomakeKuvaus } = LOMAKE_KUVAUKSET;

  clickItem(lomake: LomakeResponse, sarake: Sarake, event) {
    event.stopPropagation();
    event.preventDefault();
    this.selectItem.emit(new SarakeClickEvent(lomake, sarake));
  }
}

export class SarakeClickEvent {
  readonly lomake: LomakeResponse;
  readonly sarake: Sarake;

  constructor(lomake: LomakeResponse, sarake: Sarake) {
    this.lomake = lomake;
    this.sarake = sarake;
  }
}
