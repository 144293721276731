import {Component, Input, OnInit} from '@angular/core';
import {QuestionGroup} from "ee-lahete";
import {ControlContainer} from "@angular/forms";

@Component({
  selector: 'question-tausta-checkbox-textarea',
  template: `
    <div [formGroup]="controlContainer.control" class="form-group">
      <label *ngIf="questionGroup.label">{{questionGroup.label}}</label>
      <ng-container *ngFor="let control of questionGroup.controls">
        <div class="form-check" *ngIf="control.controlType === 'checkbox'">
            <input class="form-check-input" type="checkbox" [id]="control.key" [formControlName]="control.key">
            <label class="form-check-label" [htmlFor]="control.key">{{control.label}}</label>
        </div>
        <textarea class="form-control" *ngIf="control.controlType === 'textarea'" [formControlName]="control.key"></textarea>
      </ng-container>
    </div>
  `
})
export class QuestionTaustaCheckboxTexteareaComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.questionGroup.controls.forEach(control => {
      if (control.options && control.options.link) {
        const textarea = this.controlContainer.control.get(control.options.link);
        const checkbox = this.controlContainer.control.get(control.key);

        if (textarea && checkbox) {
          textarea.disable();
          checkbox.valueChanges.subscribe(val => {
            if (val) {
              textarea.enable();
            } else {
              textarea.reset();
              textarea.disable();
            }
          });
        }
      }
    });
  }
}
