import {Component} from "@angular/core";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";
import {LomakeInfo} from "../common/lomake-info";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {AccountService} from "../../../account/account.service";
import {LuomuvalvontaData} from "./luomuvalvonta-data";
import {LiitetiedostoResponse, LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {Teksti} from "../../../utils/teksti";
import {AbstractField, FieldControl, FieldGroup} from "ngx-fielding";
import {YhteyshenkiloViewOptions} from "../../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";
import {LiitetiedostoLomakeService} from "../../liitetiedosto-lomake.service";
import {Subscription} from "rxjs";
import {createPeltokasvinViljely} from "./peltokasvin-viljely";
import {createElaintuotanto} from "./elaintuotanto/elaintuotanto";
import {TuotantosuunnatService} from "./tuotantosuunnat.service";
import {LuomuvalvontaService} from "./luomuvalvonta.service";
import {EnvironmentService, Feature} from "../../../../environments/environment.service";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {ValueCheckHelper} from "../../../utils/value-check-helper";

@Component({
  selector: 'luomuvalvonta',
  templateUrl: 'luomuvalvonta.component.html',
  providers: [ProcessLomakeService, LuomuvalvontaService, TuotantosuunnatService]
})

@AutoUnsubscribe
export class LuomuvalvontaComponent extends LomakeV2BaseDirective {
  yhteyshenkiloViewOptions: YhteyshenkiloViewOptions = new YhteyshenkiloViewOptions(true, true, true, false);
  liitetiedostoServiceSubscription: Subscription;

  constructor(protected readonly processLomakeService: ProcessLomakeService,
              protected readonly data: LuomuvalvontaData,
              protected readonly accountService: AccountService,
              private liitetiedostoLomakeService: LiitetiedostoLomakeService,
              private liitetiedostoPalvelinService: LiitetiedostoPalvelinService,
              private environmentService: EnvironmentService) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  get ehdot(): FieldControl {
    return this.lomake.get("ehdot").asControl();
  }

  get isToiminnankohdeValid(): boolean {
    return this.lomake.get('luomutoiminnanKohde').status === 'VALID';
  }

  get isTuotantosuuntaValid(): boolean {
    return this.lomake.get('tuotantosuunnat').valid;
  }

  get tuotantosuunnat(): FieldGroup {
    return this.lomake.get('tuotantosuunnat').asGroup();
  }

  get tuotantosuunnatControls(): AbstractField[] {
    return Object.values(this.lomake.get('tuotantosuunnat').asGroup().controlFields);
  }

  ehdotLista() {
    return [
      'luomu.ehto1',
      'luomu.ehto2',
      'luomu.ehto3',
      'luomu.ehto4',
      'luomu.ehto5',
      'luomu.ehto6',
      'luomu.ehto7',
      'luomu.ehto8',
      'luomu.ehto9'
    ]
  }

  addLomakeBehaviour() {
    this.modifyLomakeDataByTuotantosuunta();
  }

  initLomakeBehaviour() {
    this.setLomakeIdToService();
    this.setLomakeLiitetiedostot();
  }

  setLomakeIdToService() {
    this.lomakeMetaData.idChange.subscribe((id) => {
      this.liitetiedostoLomakeService.setLomakeId(id);
    });
  }

  setLomakeLiitetiedostot() {
    this.liitetiedostoServiceSubscription = this.liitetiedostoLomakeService.liitteet.subscribe(liite => {
      if (liite.liite.length > 0) {
        this.onSetLiitteet(liite.liite, liite.liiteAvain);
        this.liitetiedostoLomakeService.clearLiitteet();
      }
    });
  }

  modifyLomakeDataByTuotantosuunta() {
    if (!this.environmentService.isFeatureEnabled(Feature.WIP_FEATURE)) {
      this.lomake.get('tuotantosuunnat').get('peltokasvi').setValue(true);
    }

    if (this.tuotantosuunnat.get('peltokasvi').value) {
      this.lomake.asGroup().addControl('peltokasvinviljely', createPeltokasvinViljely())
    } else {
      this.lomake.asGroup().removeControl('peltokasvinviljely');
    }

    if (!this.tuotantosuunnat.get('elaintuotanto').value) {
      this.lomake.asGroup().removeControl('elaintuotanto');
    }

    this.tuotantosuunnat.get('peltokasvi').valueChanges.subscribe(value => {
      value ? this.lomake.asGroup().addControl('peltokasvinviljely', createPeltokasvinViljely())
          : this.lomake.asGroup().removeControl('peltokasvinviljely');
    })

    if (!this.tuotantosuunnat.get('elaintuotanto').value) {
      this.lomake.asGroup().removeControl('elaintuotanto');
    }

    this.tuotantosuunnat.get('elaintuotanto').valueChanges.subscribe(value => {
      value ? this.lomake.asGroup().addControl('elaintuotanto', createElaintuotanto())
          : this.lomake.asGroup().removeControl('elaintuotanto');
    })
  }

  tuotantosuuntaHasValue(tuotantosuunta: AbstractField): boolean {
    const formControlName = tuotantosuunta.field.options.key;
    if (formControlName && this.lomake.get(formControlName)) {
      return ValueCheckHelper.hasPositiveValues(this.lomake.get(formControlName).value)
    }

    return false;
  }

  tuotantosuuntaUnselected(tuotantosuuntaId: string) {
    const deletePromises = [];

    const deleteLiitteet = (liitteet: any) => {
      Object.values(liitteet).forEach((liiteItem: LiitetiedostoResponse) => {
        if (liiteItem.id) {
          const deletePromise = this.liitetiedostoPalvelinService.deleteLiitetiedosto(liiteItem.id)
              .catch((error) => console.error(`Error deleting liite with id ${liiteItem.id}:`, error));
          deletePromises.push(deletePromise);
        }
      });
    };

    if (tuotantosuuntaId === 'tuotantosuunta-peltokasvi') {
      deleteLiitteet(this.lomake.get('peltokasvinviljely').get('liitteet').asGroup().controls);
      deleteLiitteet(this.lomake.get('peltokasvinviljely').get('siirtymavaiheenLyhennys').get('hakemusSiirtymavaiheenLyhentamiseksi').asArray().value);
    } else if (tuotantosuuntaId === 'tuotantosuunta-elaintuotanto') {
      ['elaintenhoitosuunnitelma', 'pohjapiirrosTuotantorakennuksesta', 'kuvausJaPohjapiirrosUlkotarhoistaJaJaloittelualueista', 'selvitysLaitumista', 'ylimaarainenLannanLevitystaKoskevaSopimus', 'muuLiite'].forEach(key => {
        deleteLiitteet(this.lomake.get('elaintuotanto').get('liitteet').asGroup().get(key).asArray().value);
      });
      deleteLiitteet(this.lomake.get('elaintuotanto').get('elaintuotannonPoikkeuslupa').get('hakemusElaintuotannonPoikkeusluvasta').asArray().value);
      ['sijaintiJaPitopaikat', 'saastumislahteidenArviointi'].forEach(key => {
        deleteLiitteet(this.lomake.get('elaintuotanto').get('tilanElaimet').get('mehilaispesa').asGroup().get(key).asArray().value);
      });
    }

    Promise.all(deletePromises)
        .then(() => this.saveLomake().then(() => {
        }));
  }

  ngOnDestroy() {
    this.liitetiedostoServiceSubscription.unsubscribe();
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new LuomuvalvontaInfo(
        accountType,
        toimintotyyppi
    );
  }
}

class LuomuvalvontaInfo implements LomakeInfo {
  asia: string;
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.LUOMU_YH.value;
  lomakeTitle: Teksti = new Teksti("Ilmoitus luonnonmukaiseen valvontaan liittymisestä", "luomuvalvontaIlmoitus", "luomu");
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: "as" | "vk", toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.LUOMU_YH];
    this.asia = "luomuvalvonta";
    this.toimintotyyppi = toimintotyyppi;

    const prefix = 'luomu.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1_1', '1_2']);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2_1']);
    this.ohjeet.sivu2 = LomakeHelper.getOhjeList(prefix, ['3_1']);
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/ilmoitukset/luomuvalvonta/${toimintotyyppi}`;
  }
}
