
<span  id="label-{{item.htmlId}}" class="{{item.label?.key}}_title" *ngIf="item.label !== null && !item.hasSubitems()" [attribute]="item.label?.getFullKey()"></span>
<em [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo">
  <ng-container *ngIf="item.label !== null">&nbsp;</ng-container>

  <span class="user-provided-info {{item.label?.key}}_value {{(item.hasSubitems()?'lista-otsikko':'')}}  " id="value-{{item.htmlId}}" [attribute]="item.value?.getFullKey()">{{item.value?.label}}</span>

  <span class="user-provided-info" *ngFor="let info of item.infos" id="info-{{item.htmlId}}"> {{info?.label}}</span>


  <ul class="list-group list-group-flush" *ngIf="item.hasSubitems()">
    <li *ngFor="let subitem of item.subitems" class="list-group-item {{subitem.label?.key}}">
      <yhteenveto-item [item]="subitem"></yhteenveto-item>
    </li>
  </ul>

  <ng-container *ngIf="compareStatus === compareStatusEnum.MODIFY && showCompareYhteenveto">
    <br>
    <div class="prev-item hide-from-pdf d-inline">
      <span class="user-provided-info" id="prev-value-{{prevItem.htmlId}}" [attribute]="prevItem.value?.getFullKey()">{{prevItem.value?.label}}</span>
      <span class="user-provided-info" *ngFor="let info of prevItem.infos" id="prev-info-{{prevItem.htmlId}}"> {{info?.label}}</span>
    </div>
  </ng-container>
</em>

<button *ngIf="item.downloadLink" (click)="clickOpenLiite(item.downloadLink)" class="btn btn-sm btn-outline-dark ml-2 mb-1"
        [attr.aria-label]="'lomakeYleinen.esikatseleLiite' | attribute" type="button">
  <span class="fa fa-eye"></span>
</button>
