import {AbstractField, FieldArray, FieldGroup, SummaryItem} from "ngx-fielding";

const _ = require('lodash');

export type ResultType = 'ADD' | 'MODIFY' | 'REMOVE' | 'UNCHANGED' | 'HIDDEN' | 'IGNORED';

export type CompareType = {
  [key: string]: CompareType;
  newValue?: any;
  oldValue?: any;
  // @ts-ignore
  type?: ResultType;
}

export class CompareService {

  getSummaryCompare(compareType: CompareType, compareResults: SummaryCompareItem[] = [], excludedPaths = new Set(), ignore = false): SummaryCompareItem[] {
    const compareResultKeys = new Set(['newValue', 'oldValue', 'type']);
    const oldSummary = compareType?.oldValue;
    const newSummary = compareType?.newValue;
    if (ignore) {
      compareResults.push(new SummaryCompareItem(newSummary, null, newSummary?.show === false ? 'HIDDEN' : 'IGNORED'));
    } else if (newSummary || oldSummary) {
      compareResults.push(new SummaryCompareItem(newSummary, oldSummary, compareType.type));
    }
    Object.entries(compareType)
        .filter(([key, _value]) => !compareResultKeys.has(key))
        .forEach(([key, value]) => {
          this.getSummaryCompare(value, compareResults, excludedPaths, ignore || excludedPaths.has(key));
        });
    return compareResults
  }

  compare(fieldGroup: FieldGroup, compareData: FieldGroup): CompareType {
    return this.compareGroup(fieldGroup, compareData);
  }

  compareGroup(fieldGroup: FieldGroup, compareDataGroup: FieldGroup): CompareType {
    let groupCompareResult = {...this.createCompareSummaryResult(this.getFieldSummary(fieldGroup), this.getFieldSummary(compareDataGroup))}

    Object.entries(fieldGroup.controlFields).forEach(([key, field]) => {
      if (compareDataGroup === undefined) {
        groupCompareResult[key] = new CompareResult(field.summary, field.summary, "ADD")
      } else {
        groupCompareResult[key] = this.compareAbstractField(field, compareDataGroup.get(key));
      }
    });

    return groupCompareResult as CompareType;
  }

  compareAbstractField(field: AbstractField, compareData: AbstractField): CompareType | CompareResult[] | CompareResult {
    if (field.summary?.options?.type === "yhteyshenkilot") {
      return this.compareYhteyshenkilot(field as FieldArray, compareData as FieldArray);
    } else if (field instanceof FieldGroup) {
      return this.compareGroup(field, compareData.asGroup());
    } else if (field instanceof FieldArray) {
      return this.compareArray(field, compareData.asArray());
    } else {
      return this.createCompareSummaryResult(field.summary, compareData.summary);
    }
  }

  compareYhteyshenkilot(newData: FieldArray, compareData: FieldArray): CompareType {
    const compareResults: CompareResult[] = [];
    let results: any = {};

    const oldMap: Map<any, any> = new Map();
    compareData.controlFields.forEach(oldItem => {
      const id = this.getId(oldItem);
      if (id !== null) {
        oldMap.set(id, oldItem);
      }
    })

    const newMap: Map<any, any> = new Map();
    newData.controlFields.forEach(newItem => {
      const id = this.getId(newItem);
      if (id !== null) {
        newMap.set(id, newItem);
      }
    })

    newData.controlFields.forEach(newItem => {
      const newId = this.getId(newItem);
      const oldItem = oldMap.get(newId);

      if (!oldItem) {
        compareResults.push(new CompareResult(newItem.summary, newItem.summary, 'ADD'));
      } else if (newItem.value.elmoStatus === "INACTIVE") {
        compareResults.push(new CompareResult(null, oldItem.summary, 'REMOVE'));
      } else if (!_.isEqual(newItem.value, oldItem.value)) {
        compareResults.push(new CompareResult(newItem.summary, oldItem.summary, 'MODIFY'));
      } else {
        compareResults.push(new CompareResult(newItem.summary, null, 'UNCHANGED'));
      }
    })

    compareResults.forEach((res, i) => {
      results[i] = res;
    })
    return results as CompareType;
  }

  getId(item: any): string {
    return item?.value?.elmoid;
  }

  compareArray(field: FieldArray, compareData: FieldArray): CompareType {
    let groupCompareResult = {...this.createCompareSummaryResult(this.getFieldSummary(field), this.getFieldSummary(compareData))}
    const arrayCompareResults = [];
    const compareDataArray = compareData || new FieldArray(field.field, () => field.buildField(), []);

    field.controlFields.forEach((f, i) => {
      const fieldToCompare = compareDataArray.get([i]);
      if (!fieldToCompare) {
        arrayCompareResults.push(new CompareResult(f.summary, f.summary, 'ADD'));
      } else {
        arrayCompareResults.push(this.compareAbstractField(f, fieldToCompare));
      }
    });

    for (let i = field.controlFields.length; i < compareDataArray.controlFields.length; i++) {
      arrayCompareResults.push(new CompareResult(null, compareDataArray.get([i]).summary, 'REMOVE'));
    }

    arrayCompareResults.forEach((res, i) => {
      groupCompareResult[i] = res;
    })
    return groupCompareResult as CompareType;
  }

  createCompareSummaryResult(newSummaryItem: SummaryItem<any>, oldSummaryItem: SummaryItem<any>): CompareResult {
    let compareResult = new CompareResult(newSummaryItem, oldSummaryItem, 'UNCHANGED');
    if (newSummaryItem?.show === false && oldSummaryItem?.show === false) {
      compareResult.type = 'HIDDEN';
      return compareResult;
    }

    if (newSummaryItem && !oldSummaryItem) {
      compareResult.type = 'ADD';
      compareResult.oldValue = newSummaryItem;
    } else if (newSummaryItem?.answer && !oldSummaryItem?.answer) {
      compareResult.type = 'ADD';
      compareResult.oldValue = newSummaryItem;
    } else if (!newSummaryItem && oldSummaryItem) {
      compareResult.type = 'REMOVE';
    } else if (!newSummaryItem?.answer && oldSummaryItem?.answer) {
      compareResult.type = 'REMOVE';
    } else if (newSummaryItem?.question !== oldSummaryItem?.question) {
      compareResult.type = 'MODIFY';
    } else if (!_.isEqual(newSummaryItem?.answer, oldSummaryItem?.answer)) {
      compareResult.type = 'MODIFY';
    }

    return compareResult;
  }

  getFieldSummary(abstractField: AbstractField): SummaryItem<any> | null {
    return abstractField?.summary?.show ? abstractField.summary : null;
  }
}

export class CompareResult {
  type: ResultType
  newValue: SummaryItem<any>;
  oldValue: SummaryItem<any>;

  constructor(newVal, oldVal, type: ResultType) {
    this.newValue = newVal;
    this.oldValue = oldVal;
    this.type = type;
  }
}

export class SummaryCompareItem {
  type: ResultType
  newSummary: SummaryItem<any> | null;
  oldSummary: SummaryItem<any> | null;

  constructor(newVal, oldVal, type: ResultType) {
    this.newSummary = newVal;
    this.oldSummary = oldVal;
    this.type = type;
  }
}
