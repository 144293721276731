import * as XLSX from "xlsx";
import {ExcelUtils} from "./excel-utils";

export class ExcelValidators {

  public static checkNegativeCellValues(sanitizedRowData): string[] {
    const negativeValues = [];
    if (sanitizedRowData) {
      sanitizedRowData.forEach((row => {
        row.data.forEach((value, i) => {
          if (!isNaN(value)) {
            if (Number(value) < 0) {
              negativeValues.push(XLSX.utils.encode_cell({c: i, r: row.rowIndex}));
            }
          }
        });
      }));
    }
    return negativeValues;
  }

  public static checkEitherOne(sanitizedRowData, firstComparableColumn): string[] {
    const cells = [];
    if (sanitizedRowData) {
      sanitizedRowData.forEach((column) => {
        if (column?.data[firstComparableColumn] && column?.data[firstComparableColumn + 1]) {
          cells.push(XLSX.utils.encode_cell({c: firstComparableColumn, r: column.rowIndex }) + "/" + XLSX.utils.encode_cell({
            c: firstComparableColumn + 1,
            r: column.rowIndex
          }));
        }
      });
    }
    return cells;
  }

  public static checkRequiredCells(sanitizedRowData, headerRow): string[] {
    const required = [];
    if (sanitizedRowData) {
      const requiredColumns = ExcelUtils.requiredColums(headerRow);
      if (requiredColumns) {
        requiredColumns.forEach(column => {
          sanitizedRowData.forEach((row) => {
            const value = row.data[column];
            if (!value && value != 0) {
              required.push(XLSX.utils.encode_cell({c: column, r: row.rowIndex}));
            } else if (value.toString().includes("Valitse avautuvasta valikosta") || value.toString().includes("Välj från menyn som öppnas")) {
              required.push(XLSX.utils.encode_cell({c: column, r: row.rowIndex}));
            }
          });
        });
      }
    }
    return required;
  }
}
