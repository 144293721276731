import {LomakeBaseDirective, LomakeStatus} from "../touko-lomake-base";
import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "../../message/message.service";
import {LomakeService} from "../touko-lomake.service";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {Teksti} from "../../utils/teksti";
import {LannoiteRekisteriDataUusi} from "./lannoite-rekisteri-data-uusi";
import {LomakeConverter, LomakeJSONOld, LomakeResponse, YhteyshenkiloValue} from "../touko-lomake-utils";
import {AccountService} from "../../account/account.service";
import {YhteenvetoService} from "../yhteenveto/yhteenveto.service";
import {LomakeBaseService} from "../lomake-base.service";
import {Subscription} from "rxjs";
import {Tyyppinimet} from "../../utils/tyyppinimet";
import {Verkkokauppa} from "../syote/syote-utils";
import {Option} from "../../touko-components/select-menu.component";
import {SyoteInterface} from "../syote/syote-interface";

/**
 * Created by Seppo on 09/02/2018.
 */

@Component({
  templateUrl: 'lannoite-rekisteri-uusi.component.html',
  selector: 'lannoite-rekisteri-uusi',
  styleUrls: ['./lannoite-rekisteri.component.scss']
})

export class LannoiteRekisteriUusiComponent extends LomakeBaseDirective {
  id: number;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  lomakeTitle: Teksti = new Teksti("Lannoitevalmistetoimintaa koskeva ilmoitus", "otsikko", "lannoiterekisteri");
  lomakeAsia: string;
  ohjeet: { [s: string]: string[] } = {};
  subs: Subscription[] = [];
  tyyppinimet: Array<Option> = [];
  verkkokaupat: Verkkokauppa[] = [];
  private alaokohdeToiminnot = new Map();

  get vastavuoroinenToimintaControl(): SyoteInterface {

    return this.lomakeData['muuToiminta'].dataObject['lannoitevalmisteenMarkkinointi'].findChild(['maara']);

  }

  constructor(lomakeService: LomakeBaseService,
              messageService: MessageService,
              accountService: AccountService,
              router: Router,
              activatedRoute: ActivatedRoute,
              yhteenvetoService: YhteenvetoService) {
    super(lomakeService as unknown as LomakeService, messageService, router, activatedRoute, accountService, yhteenvetoService);
    this.lomakeTyyppiVersio = 'v2';
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.VK_YH, YhteyshenkiloValue.TOIMIJAN_YH, YhteyshenkiloValue.TUONNIN_YH, YhteyshenkiloValue.VUOS_ILM];
    // ei käytetä lomakeDatassa
    this.lomakeAsia = "lannoiterekisteri";
    this.lomakeData = LannoiteRekisteriDataUusi.getData(accountService.getCurrentAccount(), this.toimintotyyppi);

    const suffix = this.toimintotyyppi === "muutos" ? "_muutos" : "";

    const prefix = 'lannoiterekisteri.ohje';
    this.ohjeet.sivu0 = this.getOhjeList(prefix, ['1_1', '1_2'], suffix);
    this.ohjeet.sivu1 = this.getOhjeList(prefix, ['2_1', '2_2', '2_3'], suffix);
    this.ohjeet.sivu2 = this.getOhjeList(prefix, ['3_1'], suffix);

    this.canSubmitLomake = this.canSubmitLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.saveLomake = this.saveLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.tyyppinimet = Tyyppinimet.getTyyppinimet().sort();
  }

  get ulkomainenOsoite(): boolean {
    return Boolean(this.lomakeValues.get("ulkomainenOsoite.ulkomainenOsoite").value);
  }

  convertLomakeToJSON(): LomakeJSONOld {
    return {title: this.lomakeAsia, data: this.lomakeValues.value};
  }

  ngOnDestroy() {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }

  updateYhteenvetoValues() {
    const perustiedot = this.lomakeValues.getRawValue().perustiedot;
    this.yhteenvetoValues = LomakeConverter.convertToJSON(this.lomakeAsia, { ...this.lomakeValues.value, perustiedot });
    this.yhteenvetoService.updateLomakeArvot(this.yhteenvetoValues);
  }

  afterInit() {
    super.afterInit();

    this.handleSubscriptions(
      this.lomakeValues.get(['lannoitesivutuotetoimija', 'lannoitesivutuotetoimija', 'laitosHyvaksynta', 'laitosHyvaksyntaRadio']),
      this.lomakeValues.get(['lannoitesivutuotetoimija', 'lannoitesivutuotetoimija', 'laitosHyvaksynta', 'laitosHyvaksyntaMuuInfo'])
    );
    this.handleSubscriptions(
      this.lomakeValues.get(['lannoitesivutuotetoimija', 'lannoitesivutuotetoimija', 'kasiteltavatRaakaaineet', 'muu']),
      this.lomakeValues.get(['lannoitesivutuotetoimija', 'lannoitesivutuotetoimija', 'kasiteltavatRaakaaineet', 'muuInfo'])
    );
    this.handleSubscriptions(
      this.lomakeValues.get(['lannoitesivutuotetoimija', 'lannoitesivutuotetoimija', 'ymparistolupa', 'toiminnallaOnYmparistolupa']),
      this.lomakeValues.get(['lannoitesivutuotetoimija', 'lannoitesivutuotetoimija', 'ymparistolupa', 'luvanNum'])
    );

    const postiOsoite = this.lomakeValues.get(['perustiedot', 'postiosoite']);
    const kayntiOsoite = this.lomakeValues.get(['perustiedot', 'kayntiosoite']);
    const laskutusOsoite = this.lomakeValues.get(['laskutustiedot', 'laskutusosoite']);

    this.lomakeValues.get(['ulkomainenOsoite', 'ulkomainenOsoite']).valueChanges.subscribe(isChecked => {
      this.handleMaakoodit(postiOsoite, isChecked);
      this.handleMaakoodit(kayntiOsoite, isChecked);
      this.handleMaakoodit(laskutusOsoite, isChecked);
    });

    this.initAlakohdetoiminnot();
  }

  private initAlakohdetoiminnot() {
    if (this.toimintotyyppi === 'muutos') {
      this.alaokohdeToiminnot = new Map<string, string>(
        this.accountService.getCurrentAccount().valvontakohde.alakohteet
          .map(alakohde => [alakohde.toiminta_elmoid, alakohde.toimintastatus])
      );
    }
  }

  public isToimintoActive(toiminto: string) {
    return this.alaokohdeToiminnot.has(toiminto) && "ReVStActive".match(this.alaokohdeToiminnot.get(toiminto));
  }

  public getCollapseComponentLabel(toiminto: string) {
    return this.isToimintoActive(toiminto) ?
      'lomakeYleinen.aktiivinen' :
      null;
  }

  private handleSubscriptions(subscriber: AbstractControl, subscribed: AbstractControl) {
    this.subs.push(subscriber.valueChanges.subscribe(value => {
      if (value === 'muu' || value === true) {
        subscribed.enable({onlySelf: true, emitEvent: false});
      } else {
        subscribed.disable();
      }
    }));
  }

  protected getLomakeValues(): FormGroup {
    return this.lomakeService.createLomakeRecursiveProcessing(this.lomakeData);
  }

  protected sendLomake(status: LomakeStatus): Promise<LomakeResponse> {
    const isViranomainen = !this.accountService.isAsiakas();
    const inputGroupValue = (this.lomakeValues.get(['perustiedot']) as FormGroup).getRawValue();
    this.perustiedot['vatnumero'] = inputGroupValue['vatnumero'];
    const requestBody = this.lomakeService.createLomakeRequest(this.convertLomakeToJSON(), this.liitetiedostot, this.perustiedot, this.yhteyshenkilot);
    if (status === LomakeStatus.KESKEN || isViranomainen) {
      return this.lomakeService.tallennaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    } else {
      return this.lomakeService.lahetaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    }
  }

  updateLomakeModel(values) {
    const formArrayValues = values.toimintaJaTuoteluokatKansallinen?.
      lannoiteValmisteenTuonti?.
      vanhanLainsaadannonMukaanTuonti?.
      kansallinenTaiEYtyyppinimitunnus || [];

    const formArray = this.lomakeValues.get("toimintaJaTuoteluokatKansallinen.lannoiteValmisteenTuonti.vanhanLainsaadannonMukaanTuonti.kansallinenTaiEYtyyppinimitunnus") as FormArray;
    formArrayValues.forEach((_value, index) => {
      if (index === 0) {
        return;
      }
      formArray.push(new FormGroup({tyyppiNimi: new FormControl(), maara: new FormControl()}));
    });

  }

  /**
   * Päivittää lomakkeen verkkokaupan osoitteen
   *
   * @param data
   */
  updateVerkkokaupat(data: Verkkokauppa[]) {
    this.verkkokaupat = data;
  }

  private handleMaakoodit(osoite: AbstractControl, isChecked: boolean) {
    const newValue = osoite.value || {};
    newValue.maaKoodi = isChecked ? null : 'FI';
    osoite.setValue(newValue);
  }
}
