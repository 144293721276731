import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import * as axe from "axe-core";
import {AxeResults} from "axe-core";

declare let window: Window;

@Injectable()
export class AccessibilityService {

  private highlightedElements: HTMLElement[] = [];
  private _accessibilityResults: BehaviorSubject<AxeResults> = new BehaviorSubject(null);
  public $accessibilityResults = this._accessibilityResults.asObservable();

  public runAccessibilityCheck() {
    this.clearElementHighlights();

    axe.run()
      .then(results => this._accessibilityResults.next(results));
  }

  public higlightElements(selector: string) {
    const elements = window.document.querySelectorAll(selector);
    elements.forEach(el => {
      if (el instanceof HTMLElement) {
        el.style.border = '2px solid red';
        el.style.opacity = '1';
        el.scrollIntoView();
        this.highlightedElements.push(el);
      }
    });
  }

  public clearElementHighlights() {
    this.highlightedElements.forEach(el => {
      el.style.border = '';
      el.style.opacity = '';
    });

    this.highlightedElements = [];
  }
}
