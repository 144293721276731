import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input} from "@angular/core";
import {Teksti} from "../utils/teksti";

@Component({
  selector: '[radio-table-row]',
  template: `
    <td style="width: 40%">
      <span [attribute]="question.getFullKey()" (click)="toggleValue()">Kysymys</span>
    </td>
    <td *ngFor="let item of values; let i = index">
      <div class="form-check">
        <input type="radio" [id]="formControlName + '-' + item" [value]="item" [name]="formControlName" class="form-check-input"
               (click)="writeValue(item)" [checked]="item === fieldValue">
        <label class="form-check-label" [htmlFor]="formControlName + '-' + item" [attribute]="options[i].getFullKey()">Option</label>
      </div>
    </td>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioTableRowComponent),
    }
  ]
})
export class RadioTableRowComponent implements ControlValueAccessor {
  @Input() question: Teksti = null;
  @Input() formControlName: string;
  @Input() options: Teksti[];
  @Input() values: string[];

  fieldValue: string;
  private _onChange = (_: any) => {
    // This is intentional
  };
  private _onTouched = (_: any) => {
    // This is intentional
  };

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // setDisabledState(isDisabled: boolean): void {
  // }

  writeValue(value: string): void {
    if (!value || !this.values.includes(value) || value === this.fieldValue) {
      value = null;
    }

    this.fieldValue = value;
    this._onChange(this.fieldValue);
  }

  toggleValue() {
    const val = this.fieldValue === this.values[0] ? this.values[1] : this.values[0];
    this.writeValue(val);
  }
}
