import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "touko-text-input",
  template: `
    <div [ngClass]="inputStyle === 'inline' ? 'd-flex align-items-center' : 'form-group'">
      <label *ngIf="labelAttribute" [ngClass]="{'mb-0': inputStyle === 'inline'}" [htmlFor]="htmlId"><span
        [attribute]="labelAttribute"></span></label>
      <input (change)="updateValue($event.target.value)" [attr.aria-labelledby]="labelledById" class="form-control"
             [ngClass]="{'inline-input' : inputStyle === 'inline', 'small-input' : inputWidth === 'small'}" type="text" [value]="value"
             [id]="htmlId" [attr.aria-describedby]="htmlId + '-description'" [disabled]="disabled"
             [placeholder]="placeholderAttribute">
      <span [id]="htmlId + '-description'" *ngIf="descriptionAttribute" [attribute]="descriptionAttribute"
            class="d-inline-block whitespace-nowrap" [ngClass]="{'mt-1': inputStyle !== 'inline'}"></span>
    </div>
  `
  ,
  styles: [
    `
    .inline-input {
      margin: 0 1rem;
    }
    .small-input {
      max-width: 8rem;
    }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextInputComponent),
    }
  ]
})
export class TextInputComponent implements ControlValueAccessor {

  @Input() value: string;
  @Input() disabled: boolean;
  @Input() htmlId: string;
  @Input() labelAttribute: string;
  @Input() descriptionAttribute: string;
  @Input() inputStyle: string;
  @Input() inputWidth: string;
  @Input() labelledById: string = null;
  @Input() placeholderAttribute: string = "";

  _onChange = (_value: any) => {
    // ignore
  }
  _onTouched = () => {
    // ignore
  }

  writeValue(value: any) {
    this.value = value;
    this._onChange(value);
  }

  updateValue(value) {
    this.writeValue(value);
    this._onTouched();
  }

  registerOnChange(fn: any): void {
      this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
      this._onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
