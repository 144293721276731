import {ErrorHandler, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {ReadAccount} from "../account/account.service";

@Injectable()
export class ClientErrorHandler implements ErrorHandler {

  constructor(private readonly http: HttpClient,
              private readonly router: Router) {}

  /**
   * Selainsovelluksen virheet lähetetetään backendille, eikä niille tehdä selaimessa tämän jälkeen mitään.
   *
   * @param error Virhesanoma
   */
  handleError(error: any): void {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const content = { error: error?.toString(), route: this.router.url };
    this.http.post(`/api/v1/${ReadAccount.getRole()}/log/error`, content, {headers})
      .toPromise()
      .then(() => console.log(error))
      .catch(() => null);
  }
}
