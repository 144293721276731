import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareAction, MatcherModel} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {Validators} from "@angular/forms";
import {GroupBuilder} from "./group-build-options";
import {InitValueType, QuestionInitValue} from "ee-lahete";

export class ToimitusosoiteGroup extends AbstractLaheteGroup {

  getLahetysosoitteet = (valitutTutkimukset): Set<string> => {
    const lahetysosoiteIntersection = this.getLahetysosoiteIntersection(
        valitutTutkimukset.map(vt => this.laheteKoodisto.dataObj[vt.id].lahetysosoite),
        this.intersectFn
    );

    const filteredLahetysosoitteet = new Set<string>();
    let isHelsinkiOsoiteAdded = false;
    lahetysosoiteIntersection.forEach(lahetysosoite => {
      const isHelsinkiOsoite = lahetysosoite.startsWith('HEL');
      if (isHelsinkiOsoite && !isHelsinkiOsoiteAdded) {
        filteredLahetysosoitteet.add(lahetysosoite);
        isHelsinkiOsoiteAdded = true;
      } else if (!isHelsinkiOsoite) {
        filteredLahetysosoitteet.add(lahetysosoite);
      }
    });

    return filteredLahetysosoitteet;
  }

  getLahetysosoitteetFilterFn = (compareVal, newVal) => {
    return this.getLahetysosoitteet(newVal).has(compareVal) ?
      CompareAction.FILTER : CompareAction.NONE;
  }

  getLahetysosoitteetModifierFn = (compareVal, newVal) => {
    const lahetysosoitteet = this.getLahetysosoitteet(newVal);

    if (lahetysosoitteet.size === 0) {
      return CompareAction.RESET;
    }

    return lahetysosoitteet.size === 1 && lahetysosoitteet.has(compareVal) ?
      CompareAction.SELECT_ONLY_RADIO_OPTION : CompareAction.NONE;
  }

  intersectFn = (set: Set<string>, elem: string) => {
    const setContainsValue = elem.startsWith('HEL') && ['HEL1', 'HEL2', 'HEL3'].some(hel => set.has(hel));
    return setContainsValue || set.has(elem);
  }

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'radio-card'})): QuestionGroup {

    const controls: QuestionControl[] = this.laheteKoodisto.koodisto.osoitteetJaLahete.map(toimitustieto => {
      return new QuestionControl({
        key: 'toimitusosoite',
        value: toimitustieto.id,
        controlType: builder.controlType,
        validators: [Validators.required],
        errorMessages: {'required': this.getLabel('Toimitusosoite on pakollinen.')},
        label: this.getLocalizedOf(toimitustieto, 'osoitetarranTeksti'),
        filters: {
          'valitutTutkimukset.valitut': new MatcherModel(
            toimitustieto.id,
            this.getLahetysosoitteetFilterFn,
            ToimitusosoiteGroup.filterActionFn
          )
        },
        modifiers: {
          'valitutTutkimukset.valitut': new MatcherModel(
            toimitustieto.id,
            this.getLahetysosoitteetModifierFn,
            ToimitusosoiteGroup.modifierActionFn
            )
        }
      });
    });

    const group = new QuestionGroup({
      key: 'toimitustiedot',
      label: this.getLabel('toimitusosoite'),
      controls: controls
    });

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.toimitustiedot), InitValueType.PATCH));
    }

    return group;
  }

}
