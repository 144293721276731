export class YhteyshenkiloViewOptions {
  nimi: boolean;
  puhelin: boolean;
  email: boolean;
  rooli: boolean;
  modifyElmoStatus: boolean;

  constructor(nimi = true, puhelin = true, email = true, rooli = true, modifyElmoStatus = true) {
    this.nimi = nimi;
    this.puhelin = puhelin;
    this.email = email;
    this.rooli = rooli;
    this.modifyElmoStatus = modifyElmoStatus;
  }
}
