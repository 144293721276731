import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'translated-question-summary',
  template: `
    <div *ngIf="item" class="row" [ngClass]="{'pb-2': !compareItemType}">
      <div class="col">
        <compare-item [compareItemType]="compareItemType">
          <span [id]="'value-' + item.field.htmlId" [attribute]="item?.question"></span>
        </compare-item>
      </div>
    </div>
  `
})
export class TranslatedQuestionSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
