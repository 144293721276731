import {AbstractField} from './abstract-field';
import {SummaryItem} from './summary-item';

/**
 * Summary functions take current AbstractField as a parameter and returns SummaryItem or null.
 */
export type SummaryFnType = (field: AbstractField) => SummaryItem<any> | null;

/**
 * Key-value pair of error messages. Key must match with the error
 * provided by the Angular Validator.
 */
export interface ErrorMessages {
  [error: string]: string;
}

/**
 * SummaryFnType can be set to a current field (self) and/or for the children.
 */
export interface SummaryFnTypeOptions {
  /** Summary function for the current field */
  self: SummaryFnType;
  /** Summary function for the direct child fields */
  children: SummaryFnType;
}

/**
 * Used for radio buttons, select components and other multi value fields.
 * Behaviour of these properties must be implemented in the application
 */
export interface ValueOption {
  /** Use as value for selected option. */
  value: string;
  /** Use as label for selected option. */
  label: string;
  /** Is option disabled or not. */
  disabled?: boolean;
  /** Is option (initially) selected or not. */
  selected?: boolean;
  /** Additional options. Don't go overboard. */
  options?: {[key: string]: any};
}

/**
 * @internal
 */
function isSummaryFnTypeOptions(input: any): input is SummaryFnTypeOptions {
  if (input) {
    return 'self' in input && 'children' in input;
  }
  return false;
}

/**
 * Field used by Abstract field. This class acts as a wrapper for extending the Angular controls.
 *
 * Example usages:
 *
 * 1. Create FieldControl with summary function based on label and control value. Set summary's "show" property as false, if no value is provided. Mark "YourCustomComponent" as a component used to draw the summary.</caption>
 *
 * ```typescript
 * const summaryFn = (field: AbstractField) => {
 *    new SummaryItem(field.label, field.value, {show: Boolean(field.value), YourCustomComponent}
 * }
 *
 * const fieldControl = new FieldControl(
 *    Field.build(
 *      'username',
 *      (field) => new SummaryItem(field.label, field.value)
 *    )
 * );
 * ```
 *
 * 2. Create FieldControl with validation and error message
 *
 * ```typescript
 * const errorMessage = {required: "This field is required"};
 *
 * const fieldControl = new FieldControl(
 *    Field.build(
 *      'username'
 *      null,
 *      errorMessage
 *    ),
 *    'defaultusername',
 *    Validators.required   // Angular validator
 * );
 *
 * console.log(fieldControl.currentErrors)
 * // logs "[]"
 *
 * fieldControl.setValue("");
 *
 * console.log(fieldControl.currentErrors)
 * // logs "['This field is required']"
 * ```
 *
 */
export class Field {
  /** Label of the input field */
  label?: string;
  /** Function that returns summary presentation for the field */
  summaryFn?: SummaryFnType;
  /** Function that returns summary presentation of the children of this field */
  childSummaryFn?: SummaryFnType;
  /** Key-value pair of error messages */
  errorMessages?: ErrorMessages;
  /** Used for example with radio and select inputs */
  valueOptions?: ValueOption[];
  /** Placeholder text for the input */
  placeholder?: string;
  /** HTML id for the input */
  htmlId?: string;
  /** Key-value pair for options */
  options?: {[key: string]: any}

  /**
   * Create field using key/value pairs
   * @param options Field properties
   */
  static of(options: Field): Field {
    return new Field(options);
  }

  /**
   * Creates field used by AbstractField
   *
   * @param label Label of the input field
   * @param summaryFn Function that returns summary presentation for the field
   * @param errorMessages Key-value pair of error messages
   * @param valueOptions Used for example with radio and select inputs
   * @param placeholder Placeholder text for the input
   * @param htmlId id to help e2e testing, label or undefined if not provided
   */
  static build(label?: string,
               summaryFn?: SummaryFnType | SummaryFnTypeOptions,
               errorMessages?: ErrorMessages,
               valueOptions?: ValueOption[],
               placeholder?: string,
               htmlId?: string): Field {
    let childSummaryFn: SummaryFnType | undefined;
    let selfSummaryFn: SummaryFnType | undefined;

    if (isSummaryFnTypeOptions(summaryFn)) {
      selfSummaryFn = summaryFn.self;
      childSummaryFn = summaryFn.children;
    } else {
      selfSummaryFn = summaryFn;
      childSummaryFn = undefined;
    }

    return new Field({label, summaryFn: selfSummaryFn, childSummaryFn, errorMessages, valueOptions, placeholder, htmlId});
  }

  private constructor(options: Field) {
    this.label = options.label || undefined;
    this.placeholder = options.placeholder || undefined;
    this.errorMessages = options.errorMessages || {};
    this.valueOptions = options.valueOptions || [];
    this.summaryFn = options.summaryFn;
    this.childSummaryFn = options.childSummaryFn;
    this.htmlId = options.htmlId || options.label || undefined;
    this.options = options.options || {};
  }
}
