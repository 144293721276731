import {Component, Input} from '@angular/core';
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "../summary-container.component";
import {ResultType} from "../field-summary-compare/compare.service";

@Component({
  selector: 'app-question-and-answer',
  template: `
    <div *ngIf="item" class="row" [ngClass]="{'pb-2': !compareItemType}">
      <div *ngIf="!compareItemType" class="col-lg-4 col-5">
        <span [id]="'label-' + htmlId" [attribute]="item?.question"></span>
      </div>
      <div class="col">
        <compare-item [compareItemType]="compareItemType">
          <span [id]="'value-' + htmlId" class="answer">{{item?.answer}}&nbsp;</span>
          <span [attribute]="item?.options?.valueSuffix" class="answer"></span>
        </compare-item>
      </div>
    </div>
  `,
  styleUrls: ['../summary.scss']
})
export class QuestionAndAnswerSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;

  get htmlId() {
    return this.item?.field?.htmlId || this.item?.options?.htmlId;
  }

}
