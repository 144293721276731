import {Component} from "@angular/core";
import {LannoitevuosiilmoitusData} from "./lannoitevuosiilmoitus-data";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";

@Component({
  template: `
    <div class="mb-5 row">
      <h1 class="ml-3" attribute="lannoitevuosiilmoitus.lannoiteTemplateOtsikko">Lomakepohjat vuosi-ilmoituksen antamista varten</h1>
      <div class="col-9">
        <touko-alert-box><p attribute="lannoitevuosiilmoitus.lannoiteTemplateInfo" class="mb-0"></p></touko-alert-box>

        <div *ngFor="let template of excelTemplates">
          <h3 class="mt-3" [attribute]="template.header"></h3>
          <div class="mb-2">
            <lataa-template-component [templateId]="template.header + 'FI'"
                                      [templateName]="template.nameFI"></lataa-template-component>
          </div>
          <div>
            <lataa-template-component [templateId]="template.header + 'SV'"
                                      [templateName]="template.nameSV"></lataa-template-component>
          </div>
        </div>
      </div>
      <div class="col-3">
        <button id="template-ohje-btn" class="btn btn-link-secondary mb-3" (click)="toggleOhje()">
          <em class="far fa-question-circle text-primary"></em>
          <span class="pl-2" attribute="lomakeYleinen.tarvitsetkoApua"></span>
        </button>
        <div class="small" *ngIf="isOhjeVisible">
          <p [attribute]="'lannoitevuosiilmoitus.ohjeTemplate'" [markdown]="true"></p>
        </div>
      </div>
    </div>
  `,
  selector: 'lannoitevuosiilmoitus',
  providers: [LannoitevuosiilmoitusData, ProcessLomakeService]
})

export class LannoitevuosiilmoitusTemplateComponent {
  excelTemplates = [
    {
      header: 'lannoitevuosiilmoitus.lannoiteValmistusOtsikko',
      nameFI: '01_Lannoitevalmisteen_valmistus_tai_valmistuttaminen_v02',
      nameSV: '01_Tillverkning eller konstruering av gödselfabrikat_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.lannoiteValmisteenTuotantoOmaKaytto',
      nameFI: '02_Lannoitevalmisteen_tuotanto_maatilalla_omaan_kayttoon_v02',
      nameSV: '02_Tillverkning av gödselmedel för eget bruk_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.lannoiteTuontiOtsikko',
      nameFI: '03_Lannoitevalmisteen_maahantuonti_v02',
      nameSV: '03_Införsel av gödselmedel_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.lannoiteValmisteenVienti',
      nameFI: '04_Lannoitevalmisteen_vienti_v02',
      nameSV: '04_Utförsel av gödselmedel_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.lannoiteValmisteenJakelija',
      nameFI: '05_Lannoitevalmisteen_jakelu_v02',
      nameSV: '05_Utdelning av gödselmedel_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.euLannoitevalmisteenValmistus',
      nameFI: '11_CE-Lannoitevalmisteen_valmistus_tai_valmistuttaminen_v02',
      nameSV: '11_Tillverkning eller konstruering av CE-märkt gödselfabrikat_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.euLannoitevalmisteenTuonti',
      nameFI: '13_CE-Lannoitevalmisteen_maahantuonti_v02',
      nameSV: '13_Införsel av CE-märkt gödselmedel_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.euLannoitevalmisteenVienti',
      nameFI: '14_CE-Lannoitevalmisteen_vienti_v02',
      nameSV: '14_Utförsel av CE-märkt gödselmedel_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.euLannoitevalmisteenJakelija',
      nameFI: '15_CE-Lannoitevalmisteen_jakelu_v02',
      nameSV: '15_Utdelning av CE-märkt gödselmedel_v02'
    },
    {
      header: 'lannoitevuosiilmoitus.lannoitevalmisteenMarkkinointi',
      nameFI: '20_Vastavuoroiseen_tunnustamiseen_perustuva_lannoitevalmisteen_tuonti_EU-alueelta_v02',
      nameSV: '20_Utsläppning av gödselfabrikat på marknaden pä basis av ömsesidigt erkännande_v02'
    },
  ];

  isOhjeVisible = false;

  toggleOhje() {
    this.isOhjeVisible = !this.isOhjeVisible;
  }
}
