<h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey">Lannoitevalmisteen varastointi</h3>
<lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                     [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
<div class="row">
  <div class="col-full col-lvl-1">
    <ul class="list-unstyled">
      <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
        <li *ngFor="let subitem of item.subitems" [attribute]="subitem.label.fullKey"
            id="{{item.label.key}}-{{subitem.label.key}}"></li>
      </div>
    </ul>
  </div>
</div>
