import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Palvelut, PalveluTila} from "../utils/palvelu-tila";
import {ReadAccount} from "../account/account.service";

/**
 * Created by Seppo on 03/11/2017.
 */

@Injectable()
export class PalveluService {

  private readonly _showSideBar = new BehaviorSubject<boolean>(false);
  showSideBar$ = this._showSideBar.asObservable();

  private readonly _palvelut = new BehaviorSubject<Palvelut>(null);
  palvelut$ = this._palvelut.asObservable();

  constructor(private readonly http: HttpClient) {
  }

  cleanUp() {
    this._showSideBar.next(false);
    this._palvelut.next(null);
  }

  showSidebar() {
    if (!this._showSideBar.getValue()) {
      this._showSideBar.next(true);
    }
  }

  hideSidebar() {
    if (this._showSideBar.getValue()) {
      this._showSideBar.next(false);
    }
  }

  isUusiIlmoitusKeskenerainen(): Promise<boolean> {
    return this.getPalvelut()
      .then(response => response?.palvelut
        .filter(p => p.keskeneraisenId !== null)
        .some(p => p.tila === PalveluTila.AKTIIVINEN)
      );
  }

  updatePalvelut() {
    return this.getPalvelut()
      .then(palvelut => this._palvelut.next(palvelut));
  }

  private getPalvelut(): Promise<Palvelut> {
    return this.http.get(`/api/v1/${ReadAccount.getRole()}/palvelut`)
      .toPromise()
      .then(response => response as Palvelut);
  }

}
