/**
 * Created by Seppo on 21/11/2017.
 */

import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {Teksti} from "../../utils/teksti";
import {ValidatorFn} from '@angular/forms';
import {SyoteInterface} from "./syote-interface";

/**
 * Sisältää Syöte-objekteja, joiden avulla lomakkeen malli määräytyy
 */
export class SyoteGroup {
  viewKey: string;
  title?: Teksti;
  validators?: ValidatorFn | ValidatorFn[] | null;
  errorMessages: { [s: string]: Teksti };
  summaryType: YhteenvetoTyyppi;
  data: SyoteInterface[];
  dataObject: { [s: string]: SyoteInterface } = {};
  readonly singleField: SyoteInterface;

  /**
   * Konstruktori
   *
   * @param viewKey - Avain lokalisointia varten
   * @param title - FormGroupin otsikko
   * @param validators - ryhmän validaattorit
   * @param errorMessages - virheviestit, esim. {required: "Virheviesti"}
   * @param summaryType - tyyppi, miten ryhmän sisältämä data esitetään. Samaa muotoa käytetään JSONin muodostuksessa
   * @param data - ryhmän sisältämät syötteet
   */
  constructor(viewKey: string, title: Teksti, validators: ValidatorFn | ValidatorFn[] | null, errorMessages: { [p: string]: Teksti }, summaryType: YhteenvetoTyyppi, ...data: SyoteInterface[]) {
    this.viewKey = viewKey;
    this.title = title;
    this.validators = validators;
    this.errorMessages = errorMessages;
    this.summaryType = summaryType;
    this.data = data;
    if (data.length > 0) {
      this.singleField = this.data[0];
    }
    this.dataObject = {};
    this.data.forEach(item => {
      this.dataObject[item.name] = item;
      item.children.forEach(childitem => {
        this.dataObject[childitem.name] = childitem;
      });
    });

    if (this.title && !this.title.view) {
      this.title.view = this.viewKey;
    }
    this.data.forEach(syote => {
      if (!syote.label.view) {
        syote.label.view = this.viewKey;
        syote.label.fullKey = `${this.viewKey}.${syote.label.key}`;
      }
    });

    for (const errorKey in this.errorMessages) {
      if (!this.errorMessages[errorKey].view) {
        this.errorMessages[errorKey].view = this.viewKey;
      }
    }
  }

  addChild(syote: SyoteInterface) {
    if (!syote.label.view) {
      syote.label.view = this.viewKey;
      syote.label.fullKey = `${this.viewKey}.${syote.label.key}`;
    }
    this.data.push(syote);
    this.dataObject[syote.name] = syote;
    syote.children.forEach(childitem => {
      this.dataObject[childitem.name] = childitem;
    });
  }
}
