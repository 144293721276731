<ng-container *ngIf="item.subitems">
  <h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey"></h3>
  <lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus" [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
  <div class="row" *ngFor="let subItem of item.subitems" [ngSwitch]="subItem?.summaryType">
    <ng-container *ngSwitchCase="yhteenvetoTyyppi.LANNOITE_SIVUTUOTE_LAITOSHYVAKSYNTA">

        <div class="col-left col-lvl-1">
          <p [attribute]="subItem?.label?.fullKey"></p>
        </div>
        <div class="col-right">
          <ng-container *ngFor="let subsubItem of subItem.subitems" [ngSwitch]="subsubItem.summaryType">
            <p *ngIf="subsubItem?.label?.key !== 'muuInfo'" [attribute]="subsubItem?.label.fullKey" class="mb-0"></p>
            <p *ngIf="subsubItem?.label?.key === 'muuInfo'" class="mb-0">{{ subsubItem?.value.label }}</p>
          </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="yhteenvetoTyyppi.LANNOITE_SIVUTUOTE_RAAKAAINEET">
        <div class="col-full col-lvl-1">
          <h5 [attribute]="subItem?.label?.fullKey"></h5>
          <ul class="list-unstyled">
            <ng-container *ngFor="let subsubItem of subItem.subitems">
              <li *ngIf="subsubItem?.label?.key !== 'muuInfo'" class="mb-2"
                  [attribute]="subsubItem?.label?.fullKey"></li>
              <li *ngIf="subsubItem?.label?.key === 'muuInfo'">{{ subsubItem?.value.label }}</li>
            </ng-container>
          </ul>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="yhteenvetoTyyppi.LANNOITE_SIVUTUOTE_YMPARISTOLUPA">
      <ng-container *ngFor="let subsubItem of subItem.subitems">

          <div class="col-full col-lvl-1">
            <h5 [attribute]="subItem.label?.fullKey"></h5>
          </div>
          <ng-container *ngIf="subsubItem.valueMap">
            <div class="col-left col-lvl-1">
              <p [attribute]="subsubItem.valueMap.toiminnallaOnYmparistolupaKey.label"></p>
            </div>
            <div class="col-right">
              <p [attribute]="subsubItem.valueMap.toiminnallaOnYmparistolupa.fullKey"></p>
            </div>
          </ng-container>
          <ng-container *ngIf="subsubItem.valueMap.luvanNum.label">
            <div class="col-left col-lvl-1">
              <p [attribute]="subsubItem.valueMap.luvanNumKey.label"></p>
            </div>
            <div class="col-right">
              <p>{{ subsubItem?.valueMap.luvanNum.label }}</p>
            </div>
          </ng-container>

      </ng-container>
    </ng-container>
  </div>
</ng-container>


