/**
 * Created by Team on 8/12/2022.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild} from "@angular/core";
import * as XLSX from 'xlsx';
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {ExcelUtils} from "../../vuosiilmoitus/common/excel-utils";
import {LoadedExcel} from "../../../touko-components/excel-list.component";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {ExcelMapper} from "../../vuosiilmoitus/common/excel-mapper";

/**
 * Excelin lataus-komponentti. Muuttaa ladatun excelin front endissä sen JSON muotoon.
 * Annetaan outputtina JSON muille komponenteille.
 */
@Component({
  selector: 'touko-excel-lataus',
  template: `
    <div [ngClass]="{'opacity-50': isDisabled}">
      <div class="input-group">
        <input autocomplete="off" id="hidden-excel-lataus-input" class="form-control-file" style="display: none;"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               type="file" [attr.aria-label]="'lomakeYleinen.lataaExcel' | attribute" (change)="onFileChange($event)"
               #fileInput
               [disabled]="isDisabled"/>
        <div class="form-control" (click)="fileInput.click()">{{ 'lomakeYleinen.liiteLataaUusi' | attribute }}</div>
        <div class="input-group-append">
          <button [attribute]="'lomakeYleinen.liiteSelaa'" id="selaa-excel-lataus-input" class="btn btn-outline-primary"
                  (click)="fileInput.click()" [disabled]="isDisabled"
                  type="button">Selaa...
          </button>
        </div>
      </div>
      <em class="small text-muted" attribute="lomakeYleinen.excelOhje">Tuetut tiedostomuodot on .xlsx. Tiedoston
        maksimikoko 8Mb.</em>

      <div *ngIf="errorMessage?.isError" class="mt-4">
        <touko-alert-box alertType="error" [closable]="true" (close)="closeAlert()">
          <p class="mb-0" [attribute]="errorMessage.message">
            Excelin latauksessa tapahtui virhe
          </p>
          <p>
            <div *ngIf="errorMessage.excelType" class="mb-0"[attribute]="errorMessage.excelType">excel-tyyppi</div>  
            <div *ngIf="errorMessage.excelName" class="mb-0">{{ errorMessage.excelName }}</div>
        </touko-alert-box>
      </div>
      <excel-list [loadedExcels]="loadedExcels" (removeExcelData)="removeExcel($event)"
                  [disabled]="isDisabled"></excel-list>
    </div>
  `,
  styles: [`
    .opacity-50 {
      opacity: 0.5;
    }
  `],
  providers: [
    LiitetiedostoPalvelinService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ExcelLatausComponent),
    }
  ]
})

export class ExcelLatausComponent implements ControlValueAccessor {
  @Input() lomakeId: number;
  @Input() excelMapper: ExcelMapper;
  @Output() jsonOut: EventEmitter<JSONOut> = new EventEmitter();
  @Output() removeExcelData = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;

  loadedExcels: LoadedExcel[] = [];
  liiteExcels: LiitetiedostoResponse[] = [];
  isDisabled = false;
  errorMessage: ErrorMsg;

  @Output() liitteet: EventEmitter<{ liitteet: LiitetiedostoResponse[] }> = new EventEmitter();

  constructor(private _renderer: Renderer2,
              private _elementRef: ElementRef,
              private liitetiedostoService: LiitetiedostoPalvelinService) {
  }

  writeValue(obj: any): void {
    this.loadedExcels = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  async onFileChange(event) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    const liitetiedosto = await this.liitetiedostoService.sendLiitetiedosto(selectedFile, 'MUUT', this.lomakeId);

    this.liiteExcels.push(liitetiedosto);
    this.liitteet.emit({liitteet: this.liiteExcels});

    fileReader.onload = (loadEvent: any) => {
      this.errorMessage = {isError: false, message: ""};
      const binaryData = loadEvent.target.result;
      const workbook = XLSX.read(binaryData, {type: "binary"});
      const excelInfo = ExcelUtils.findExelInfo(workbook);

      const excelName = selectedFile.name;
      this.validateExcel(excelInfo.type, excelInfo.version, excelName);
      // general input excel errors
      if (!this.errorMessage?.isError) {
        const jsonSisalto = this.parseSisaltoJson(excelInfo.type, workbook, excelName);
        // excel content errors
        if (!this.errorMessage?.isError) {
          const excelSectionTitle = this.excelMapper.getSectionTitles()[excelInfo.type];
          this.jsonOut.emit(jsonSisalto);
          this.loadedExcels.push({
            name: excelName,
            type: excelInfo.type,
            sectionTitle: excelSectionTitle,
            liiteId: liitetiedosto.id,
            errors: jsonSisalto.errors
          });
          this._onChange(this.loadedExcels);
          this._onTouched(true);
        }
      }

      this._renderer.setProperty(this.fileInput.nativeElement, 'value', null);
    };
    fileReader.readAsBinaryString(selectedFile);
  }

  validateExcel(excelType: string, excelVersion: string, excelName: string): void {
    const excelTyyppiOtsikot = Object.keys(this.excelMapper.getSectionTitles());
    const excelTypeSameAsToimiala = excelTyyppiOtsikot.some(tyyppiOtsikko => tyyppiOtsikko === excelType);

    if (!excelType || !excelVersion) {
      this.errorMessage = {isError: true, message: "lomakeYleinen.errExcelVirheellinenTyyppi", excelName: excelName};
    } else {
      if (!excelTypeSameAsToimiala) {
        this.errorMessage = {isError: true, message: "lomakeYleinen.errExcelVirheellinenTyyppi", excelName: excelName};
      } else if (this.loadedExcels.some(e => e.type === excelType)) {
        this.errorMessage = {isError: true, message: "lomakeYleinen.errExcelSamaTyyppi", excelName: excelName, excelType: excelType}
      } else if (!this.excelMapper.isVersionAllowed(excelType, excelVersion)) {
        this.errorMessage = {isError: true, message: "lomakeYleinen.errExcelVaaraVersio", excelName: excelName};
      }
    }
  }

  removeExcel(event) {
    this.liitetiedostoService.deleteLiitetiedosto(event.excel.liiteId).then(() => {
      this.removeExcelData.emit(event.excel.type);
      this.loadedExcels.splice(event.index, 1);
      this.liiteExcels.splice(event.index, 1);
      this.liitteet.emit({liitteet: this.liiteExcels});
      this._onChange(this.loadedExcels);
      if (!this.loadedExcels.length || this.errorMessage?.isError) {
        this.errorMessage = {isError: false, message: ""};
      }
    });
  }

  createExcelError(sheet: string, sheetName: string, sanitized_row_data: any[]): ExcelError {
    const excelError = new ExcelError(sheet);

    const sheetErrors: SheetError[] = [];
    this.excelMapper.getExcelValidators(sheetName).forEach(validator => {
      const cells = validator.validatorFn(sanitized_row_data);
      if (sanitized_row_data && sanitized_row_data.length && cells.length) {
        sheetErrors.push({errorMsg: validator.errMsg, cells: cells});
      }
    });
    if (sheetErrors.length) {
      excelError.errors = sheetErrors;
      return excelError;
    }
    return null;
  }

  parseSisaltoJson(excelType: string, workbook: XLSX.WorkBook, workbookName: string) {
    let excelErrors: ExcelError[] = [];
    const jsonData = {type: excelType, data: []};
    let isWorkbookEmpty = true;

    workbook.SheetNames.forEach((sheet, index) => {
      const rowData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {header: 1, skipHidden: false});
      if (!rowData || rowData.length === 0 || rowData[0][26] === "skip") {
        return;
      }
      const sanitized_row_data = this.excelMapper.sanitizeRowData(rowData);
      if (sanitized_row_data.length > 0) {
        isWorkbookEmpty = false;
      }
      jsonData.data.push(sanitized_row_data.map(row => row.data));
      const excelError = this.createExcelError(sheet, this.excelMapper.getSheetName(excelType, index - 1, workbook.SheetNames.length), sanitized_row_data);
      if (excelError) {
        excelErrors.push(excelError);
      }
    });

    if (isWorkbookEmpty) {
      this.errorMessage = {isError: true, message: 'lomakeYleinen.errExcelTyhja', excelName: workbookName}
    }

    return {errors: excelErrors, json: jsonData};
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  closeAlert() {
    this.errorMessage = {isError: false, message: ""};
  }
}

export class ExcelError {
  sheetName: string;
  errors: SheetError[] = [];

  constructor(sheetName) {
    this.sheetName = sheetName;
  }
}

export interface SheetError {
  errorMsg: string;
  cells: string[];
}

export interface ErrorMsg {
  isError: boolean;
  message: string;
  excelName?: string;
  excelType?: string;
}

export type JSONOut = { json: { type: string, data: any[] }, errors: ExcelError[] }
