import {LomakeResponse} from "../../../touko-lomake-utils";
import {Injectable} from "@angular/core";
import {MessageService} from "../../../../message/message.service";
import {Teksti} from "../../../../utils/teksti";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class LomakeEventPublisherService {
  protected readonly _lomakeEvent = new BehaviorSubject<LomakeEvent>(null);
  lomakeEvent$ = this._lomakeEvent.asObservable();

  lomakeEventMessages = {
    save: {
      success: {message: new Teksti("Lomake tallennettu.", "lomakeTallennettu", "lomakeYleinen"), type: "success"},
      failure: {
        message: new Teksti("Lomakkeen tallennus epäonnistui", "tallennusEpaonnistui", "lomakeYleinen"),
        type: "danger"
      }
    },
    send: {
      success: {message: new Teksti("Lomake lähetetty.", "lomakeLahetetty", "lomakeYleinen"), type: "success"},
      failure: {
        message: new Teksti("Lomakkeen lähetys epäonnistui", "lahetysEpaonnistui", "lomakeYleinen"),
        type: "danger"
      }
    }
  };

  constructor(private messageService: MessageService) {
  }

  publishLomakeEvent(lomakeEvent: LomakeEvent) {
    const msg = this.lomakeEventMessages[lomakeEvent.eventType][lomakeEvent.success];
    this._lomakeEvent.next(lomakeEvent);
    this.messageService.notify(msg);
  }

}

export class LomakeEvent {
  lomakeResponse: LomakeResponse;
  eventType: "send" | "save";
  readonly isSuccessful: boolean;

  get success() {
    return this.isSuccessful ? "success" : "failure";
  }

  constructor(lomakeResponse: LomakeResponse, eventType: "send" | "save", isSuccessful = true) {
    this.lomakeResponse = lomakeResponse;
    this.eventType = eventType;
    this.isSuccessful = isSuccessful;
  }
}
