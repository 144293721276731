import {Component, Input, OnInit} from "@angular/core";
import {AccountService} from "../../account/account.service";
import {Router} from "@angular/router";
import {LomakeRedirectService} from "../lomake-redirect.service";

@Component({
  selector: 'siirry-tarkastukseen',
  template: `
    <div class="row d-print-none" *ngIf="isViranomainen">
    <div class="col-12">
      <hr>
      <button type="button" class="btn btn-success" id="viranomainen-tallenna-ja-tarkasta" [clickAsync]="siirryTarkastukseen">Tallenna ja siirry
        tarkastusnäkymään
      </button>
    </div>
  </div>`
})
export class SiirryTarkastukseenComponent implements OnInit {

  isViranomainen = false;
  @Input() saveFn: () => Promise<any>;

  constructor(private readonly accountService: AccountService,
              private readonly lomakeRedirectService: LomakeRedirectService,
              private readonly router: Router,
  ) {
    this.siirryTarkastukseen = this.siirryTarkastukseen.bind(this);
  }

  ngOnInit(): void {
    this.isViranomainen = this.accountService.isViranomainen();
  }

  siirryTarkastukseen() {
    const urlSegments = this.router.url.split('/');
    const route = ['/asiointi/lomake/tarkastus']
    .concat(urlSegments.slice(3, 7));
    this.lomakeRedirectService.canRedirect = true;
    return Promise.resolve(this.saveFn()).then(() => this.router.navigate(route));
  }
}
