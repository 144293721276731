import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {LOMAKE_TILA_BADGE, LomakeTila} from "../touko-lomake/touko-lomake-utils";
import {Teksti} from "./teksti";
/**
 * Created by Seppo on 30/11/2018.
 */

@Component({
  selector: 'tila-badge',
  template: `
    <span *ngIf="tilaTeksti !== null" class="badge text-uppercase" [ngClass]="tilaTyyli">
      {{tilaTeksti.getFullKey() | attribute}}
    </span>
  `
})
export class TilaBadgeComponent implements OnChanges {

  @Input() lomakeTila: LomakeTila | string;
  tilaTiedot = LOMAKE_TILA_BADGE;

  tilaTeksti: Teksti = null;
  tilaTyyli: string = null;

  ngOnChanges(change: SimpleChanges): void {
    this.tilaTeksti = this.getTilaTeksti();
    this.tilaTyyli = this.getBadgeClass();
  }


  getTilaTeksti() {
    if (typeof this.lomakeTila === "string") {
      this.lomakeTila = LomakeTila[this.lomakeTila];
    }

    if (this.tilaTiedot.hasOwnProperty(this.lomakeTila)) {
      return this.tilaTiedot[this.lomakeTila].teksti;
    } else {
      return this.tilaTiedot[LomakeTila.ODOTTAA_KASITTELYA].teksti;
    }
  }

  getBadgeClass() {
    if (typeof this.lomakeTila === "string") {
      this.lomakeTila = LomakeTila[this.lomakeTila];
    }

    if (this.tilaTiedot.hasOwnProperty(this.lomakeTila)) {
      return this.tilaTiedot[this.lomakeTila].tyyli;
    }
    return 'badge-default';
  }
}
