import {Teksti} from "../utils/teksti";
/**
 * Created by Seppo on 17/07/2018.
 */

export class ModalData {
  otsikko: Teksti;
  kysymys: Teksti;
  okTeksti: Teksti;
  okFn: any;
  okStyle: string;
  okElementId: string;
  cancelStyle: string;
  suljeButton: boolean;
  suljeTeksti: Teksti;
  suljeStyle: string;

  constructor(otsikko = new Teksti("Ei otsikkoa"),
              kysymys = new Teksti("Ei kysymystä"),
              okTeksti = new Teksti("Kyllä", "kylla", "teksti"),
              okFn: () => Promise<any> = () => Promise.resolve(),
              okStyle = "btn-primary",
              cancelStyle = "btn-light",
              suljeButton = false,
              suljeTeksti = new Teksti("Sulje", "sulje", "teksti"),
              suljeStyle = "btn-danger"
  ) {
    this.otsikko = otsikko;
    this.kysymys = kysymys;
    this.okTeksti = okTeksti;
    this.okFn = okFn;
    this.okElementId = okTeksti.key;
    this.okStyle = okStyle;
    this.cancelStyle = cancelStyle;
    this.suljeButton = suljeButton;
    this.suljeTeksti = suljeTeksti;
    this.suljeStyle = suljeStyle;
  }
}
