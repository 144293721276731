import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {SyoteGroup} from "../syote/syote-group";
import {SyoteArray} from "../syote/syote-array";
import {EnvironmentService, Feature} from "../../../environments/environment.service";

export const toimintaJaTuoteluokatKansallinen = (view: string, toimintotyyppi: string): SyoteGroup => {
  const aolChecboxes = [];

  const env = new EnvironmentService();
    aolChecboxes.push(
      new Syote(new Teksti("AOL 1 - Ensiömateriaalista koostuvat aineet ja seokset", "ensiomateriaalistaKoostuvatAineetJaSeokset", view), null, "aol1", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto',
        showTitle: true
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 2 - Kasvit, kasvien osat tai kasviuutteet", "kasvitKasvienOsatTaiKasviuutteet", view), null, "aol2", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 3 - Komposti", "komposti", view), null, "aol3", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 4 - Tuorekasvimädäte", "tuorekavimadateOmaKaytto", view), null, "aol4", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 5 - Muu mädäte kuin tuorekasvimädäte", "muuMadateKuinTuorekasvimadateOmaKaytto", view), null, "aol5", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 6 - Elintarviketeollisuuden sivutuotteet", "elintarviketeollisuudenSivutuotteetOmaKaytto", view), null, "aol6", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 7 - Mikro-organismit", "mikroOrganismitOmaKaytto", view), null, "aol7", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 8 - Ravinnepolymeerit", "ravinnepolymeeritOmaKaytto", view), null, "aol8", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 9 - Muut polymeerit ja ravinnepolymeerit", "muutPolymeeritOmaKaytto", view), null, "aol9", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 10 - Asetuksessa (EY) N:o 1069/2009 tarkoitetut johdetut sivutuotteet", "johdetutSivutuotteetOmaKaytto", view), null, "aol10", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 11 - Direktiivissä 2008/98/EY tarkoitetut sivutuotteet", "direktiivi2008/98/EYSivutuotteetOmaKaytto", view), null, "aol11", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 12 - Saostuneet fosfaattisuolat ja johdannaiset (struviitti)", "saostuneetFosfaattisuolatJaJohdannaisetOmaKaytto", view), null, "aol12", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 13 - Termisesti hapetuksessa muodostuva aines tai johdannainen (tuhka ja kuona)", "termisestiHapetuksessaMuodostuvaAinesTaiJohdannainenOmaKaytto", view), null, "aol13", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 14 - Pyrolyysissä ja kaasutuksessa muodostuva aines (biohiili)", "pyrolyysissaJaKaasutuksessaMuodostuvaAinesOmaKaytto", view), null, "aol14", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 15 - Puhtaassa teollisessa prosessissa muodostuvat materiaalit ja ainesosat", "puhtaassaTeollisessaProsessissaMuodostuvatMateriaalitJaAinesosatOmaKaytto", view), null, "aol15", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY),
      new Syote(new Teksti("AOL 16 - Muut ainesosat", "muutAinesosatOmaKaytto", view), null, "aol16", null, {
        type: 'AOLcheckbox',
        parent: 'kasvualustatOmaKaytto'
      }, YhteenvetoTyyppi.VALUE_ONLY));

  const valmistusJaTuontimaarat = [
    new Syote(new Teksti("Suomessa valmistettujen markkinoitavien lannoitteiden määrä", "markkinoitavienLannoitteidenMaara", view), null, "maaraSuomi", null, {
      type: 'textJakelija',
      parent: 'kasvualustaJakelija',
      showTitle: true
    }, YhteenvetoTyyppi.VALUE_ONLY),
    new Syote(new Teksti("Sisämarkkinoilta tuotavien lannoitteiden määrä", "sisamarkkinoiltaTuotavienLannoitteidenMaara", view), null, "maaraSisamarkkinat", null, {
      type: 'textJakelija',
      parent: 'kasvualustaJakelija'
    }, YhteenvetoTyyppi.VALUE_ONLY)
  ];

  const lannoitteenValmistusKysymykset = [
    new Syote(new Teksti("Arvio lannotteiden vuosittaisista valmistusmääristä", "lannoitteidenMaara", view), null, "maara", null, {
      type: 'text',
      parent: 'orgaanisetLannoitteetValmistus',
      showValmistusTitle: true
    }, YhteenvetoTyyppi.VALUE_ONLY),
    new Syote(new Teksti("Lannoitteen valmistus vain omaan käyttöön?", "lannoitteenValmistusOmaanKayttoon", view), null, "lannoitteenValmistusOmaanKayttoon", null, {type: 'lannoitteenValmistusRadio'}),
    new Syote(new Teksti("Lannoitteen valmistus on ainoastaan pakkaamista?", "lannoitteenValmistusAinoastaanPakkaamista", view), null, "lannoitteenValmistusAinoastaanPakkaamista", null, {type: 'lannoitteenValmistusRadio'}),
  ];

  const lopetaToimintaSyote = new Syote(new Teksti('Haluan lopettaa tämän toiminnan', 'haluanLopettaaToiminnan', view), null, 'lopetaToiminta', null, {type: 'lopetaToiminta'});

  const isMuutosIlmoitus = toimintotyyppi === "muutos";

  return new SyoteGroup(
    view,
    new Teksti("Kansallinen toiminta", "kansallinenToiminta"),
    null,
    null,
    YhteenvetoTyyppi.CHECKBOXES_WITH_OPTIONAL,
    new Syote(
      new Teksti("Lannoitevalmisteen maahantuonti", "lannoiteValmisteenTuonti"),
      null,
      "lannoiteValmisteenTuonti",
      null,
      {yhteenvetoMaaraTeksti: new Teksti("Määrä", "maahantuotavaMaara", view), alakohdeToiminta: 'LANNMAAHANTUONTI', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_KANSALLINEN_TOIMINTA,

      lopetaToimintaSyote,

      new Syote(new Teksti("Orgaaniset lannoitteet", "orgaanisetLannoitteet", view), null, "orgaanisetLannoitteet", null, null, null,
        new Syote(new Teksti("Maahantuotavien lannoitteiden määrä", "tuotavienLannoitteidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Orgaaniset kivennäislannoitteet", "orgaanisetKivennaislannoitteet", view), null, "orgaanisetKivennaislannoitteet", null, null, null,
        new Syote(new Teksti("Maahantuotavien kivennäislannoitteiden määrä", "tuotavienKivennaislannoitteidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Epäorgaaniset lannoitteet", "epaorgaanisetLannoitteet", view), null, "epaorgaanisetLannoitteet", null, {
        }, null, new Syote(new Teksti("Maahantuotavien epäorgaanisten lannoitteiden määrä", "tuotavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Kalkitusaineet", "kalkitusaineet", view), null, "kalkitusaineet", null, {
        }, null, new Syote(new Teksti("Maahantuotavien kalkitusaineiden määrä", "tuotavienKalkitusaineidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Orgaaniset maanparannusaine", "orgaanisetMaanparannusaineet", view), null, "orgaanisetMaanparannusaineet", null, {
        }, null, new Syote(new Teksti("Maahantuotavien orgaanisten maanparannusaineiden määrä", "tuotavienOrgaanistenMaanparannusaineidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Epäorgaaniset maanparannusaine", "epaorgaanisetMaanparannusaineet", view), null, "epaorgaanisetMaanparannusaineet", null, {
        }, null, new Syote(new Teksti("Maahantuotavien epäorgaanisten maanparannusaineiden määrä", "tuotavienEpaorgaanistenMaanparannusaineidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Kasvualustat", "kasvualustat", view), null, "kasvualustat", null, {
        }, null, new Syote(new Teksti("Maahantuotavien kasvualustojen määrä", "tuotavienKasvualustojenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Kasvibiostimulantit", "kasvibiostimulantit", view), null, "kasvibiostimulantit", null, {
        }, null, new Syote(new Teksti("Maahantuotavien kasvibiostimulanttien määrä", "tuotavienKasvibiostimulanttienMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Lannoitevalmisteiden seokset", "lannoitevalmisteidenSeokset", view), null, "lannoitevalmisteidenSeokset", null, {
        }, null, new Syote(new Teksti("Maahantuotavien seoksien määrä", "tuotavienSeoksienMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Ilmoitan vanhan lainsäädännön mukaiset tyyppinimet", "vanhanLainsaadannonMukaan", view), null, "vanhanLainsaadannonMukaanTuonti", null, {
        }, YhteenvetoTyyppi.LABEL_AND_VALUE_ARRAY,
        new SyoteArray( new Teksti("Kansallinen tai EY-tyyppinimitunnus", "kansallinenTaiEYtyyppinimitunnus", view), null, "kansallinenTaiEYtyyppinimitunnus", null, {type: 'dropdown'}, null,
          [{tyyppiNimi: new Syote(new Teksti("Kansallinen tai EY-tyyppinimitunnus", "kansallinenTaiEYtyyppinimitunnus", view), null, "tyyppiNimi", null, {
            type: 'dropdown',
          }, YhteenvetoTyyppi.VALUE_ONLY),
            maara: new Syote(new Teksti("Kansallinen tai EY-tyyppinimitunnus", "kansallinenTaiEYtyyppinimitunnus", view), null, "maara", null, {
              type: 'dropdown',
            }, YhteenvetoTyyppi.VALUE_ONLY)}]
        )
      )
    ),

    new Syote(
      new Teksti("Lannoitevalmisteen vienti", "lannoiteValmisteenVienti"),
      null,
      "lannoiteValmisteenVienti",
      null,
      {yhteenvetoMaaraTeksti: new Teksti("Määrä", "maastavietavaMaara", view), alakohdeToiminta: 'LANNMAASTAVIENTI', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_KANSALLINEN_TOIMINTA,

      lopetaToimintaSyote,

      new Syote(new Teksti("Orgaaniset lannoitteet", "orgaanisetLannoitteet", view), null, "orgaanisetLannoitteet", null, {
        }, null, new Syote(new Teksti("Maastavietävien lannoitteiden määrä", "vietavienLannoitteidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Orgaaniset kivennäislannoitteet", "orgaanisetKivennaislannoitteet", view), null, "orgaanisetKivennaislannoitteet", null, {
        }, null, new Syote(new Teksti("Maastavietävien kivennäislannoitteiden määrä", "vietavienKivennaislannoitteidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Epäorgaaniset lannoitteet", "epaorgaanisetLannoitteet", view), null, "epaorgaanisetLannoitteet", null, {
      }, null, new Syote(new Teksti("Maastavietävien epäorgaanisten lannoitteiden määrä", "vietavienEpaorgaanistenLannoitteidenMaara", view), null, "maara", null, {
        type: 'text',
        showValmistusTitle: false
      }, YhteenvetoTyyppi.VALUE_ONLY)),

      new Syote(new Teksti("Kalkitusaineet", "kalkitusaineet", view), null, "kalkitusaineet", null, {
      }, null, new Syote(new Teksti("Maastavietävien kalkitusaineiden määrä", "vietavienKalkitusaineidenMaara", view), null, "maara", null, {
        type: 'text',
        showValmistusTitle: false
      }, YhteenvetoTyyppi.VALUE_ONLY)),

      new Syote(new Teksti("Orgaaniset maanparannusaine", "orgaanisetMaanparannusaineet", view), null, "orgaanisetMaanparannusaineet", null, {
      }, null, new Syote(new Teksti("Maastavietävien orgaanisten maanparannusaineiden määrä", "vietavienOrgaanistenMaanparannusaineidenMaara", view), null, "maara", null, {
        type: 'text',
        showValmistusTitle: false
      }, YhteenvetoTyyppi.VALUE_ONLY)),

      new Syote(new Teksti("Epäorgaaniset maanparannusaine", "epaorgaanisetMaanparannusaineet", view), null, "epaorgaanisetMaanparannusaineet", null, {
        }, null, new Syote(new Teksti("Maastavietävien epäorgaanisten maanparannusaineiden määrä", "vietavienEpaorgaanistenMaanparannusaineidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Kasvualustat", "kasvualustat", view), null, "kasvualustat", null, {
        }, null, new Syote(new Teksti("Maastavietävien kasvualustojen määrä", "vietavienKasvualustojenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Kasvibiostimulantit", "kasvibiostimulantit", view), null, "kasvibiostimulantit", null, {
        }, null, new Syote(new Teksti("Maastavietävien kasvibiostimulanttien määrä", "vietavienKasvibiostimulanttienMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),

      new Syote(new Teksti("Lannoitevalmisteiden seokset", "lannoitevalmisteidenSeokset", view), null, "lannoitevalmisteidenSeokset", null, {
        }, null, new Syote(new Teksti("Maastavietävien seoksien määrä", "vietavienSeoksienMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY)
      ),
    ),

    new Syote(
      new Teksti("Lannoitevalmisteen tuotanto maatilalla omaan käyttöön", "lannoiteValmisteenTuotantoOmaKaytto"),
      null,
      "lannoiteValmisteenTuotantoOmaKaytto",
      null,
      {alakohdeToiminta: 'LANNMAATILATUOTANTO', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITEVALMISTE_AINESOSALUOKAT,

      lopetaToimintaSyote,

      new Syote(new Teksti("Orgaaniset lannoitteet", "orgaanisetLannoitteet", view), null, "orgaanisetLannoitteet", null, {
        }, null,
        new Syote(new Teksti("Arvio lannotteiden vuosittaisista valmistusmääristä", "lannoitteidenMaara", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Orgaaniset kivennäislannoitteet", "orgaanisetKivennaislannoitteet", view), null, "orgaanisetKivennaislannoitteet", null, {
        }, null, new Syote(new Teksti("Arvio kivennäislannotteiden vuosittaisista valmistusmääristä", "kivennaislannoitteidenMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Epäorgaaniset lannoitteet", "epaorgaanisetLannoitteet", view), null, "epaorgaanisetLannoitteet", null, {
        }, null, new Syote(new Teksti("Arvio epäorgaanisten lannotteiden vuosittaisista valmistusmääristä", "epaorgaanistenLannoitteidenMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Kalkitusaineet", "kalkitusaineet", view), null, "kalkitusaineet", null, {
        }, null, new Syote(new Teksti("Arvio kalkitusaineiden vuosittaisista valmistusmääristä", "kalkitusaineidenMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Orgaaniset maanparannusaineet", "orgaanisetMaanparannusaineet", view), null, "orgaanisetMaanparannusaineet", null, {
        }, null, new Syote(new Teksti("Arvio orgaanisten maanparannusaineiden vuosittaisista valmistusmääristä", "orgaanistenMaanparannusaineidenMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Epäorgaaniset maanparannusaineet", "epaorgaanisetMaanparannusaineet", view), null, "epaorgaanisetMaanparannusaineet", null, {
        }, null, new Syote(new Teksti("Arvio epäorgaanisten maanparannusaineiden vuosittaisista valmistusmääristä", "epaorgaanistenMaanparannusaineidenMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Kasvualustat", "kasvualustat", view), null, "kasvualustat", null, {
        }, null, new Syote(new Teksti("Arvio kasvualustojen vuosittaisista valmistusmääristä", "kasvualustojenMaara", view), null, "maara", null, {
          type: 'text',
          parent: 'kasvualustatOmaKaytto',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Kasvibiostimulantit", "kasvibiostimulantit", view), null, "kasvibiostimulantit", null, {
        }, null, new Syote(new Teksti("Arvio kasvibiostimulanttien vuosittaisista valmistusmääristä", "kasvibiostimulanttienMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          parent: 'kasvibiostimulantitOmaKaytto',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),

      new Syote(new Teksti("Lannoitevalmisteiden seokset", "lannoitevalmisteidenSeokset", view), null, "lannoitevalmisteidenSeokset", null, {
        }, null, new Syote(new Teksti("Arvio lannoitevalmisteiden seoksien vuosittaisista valmistusmääristä", "seoksienMaaraOmaKaytto", view), null, "maara", null, {
          type: 'text',
          parent: 'lannoitevalmisteidenSeoksetOmaKaytto',
          showValmistusTitle: false
        }, YhteenvetoTyyppi.VALUE_ONLY),
        ...aolChecboxes
      ),
    ),

    new Syote(
      new Teksti("Lannoitevalmisteen jakelija", "lannoiteValmisteenJakelija"),
      null,
      "lannoiteValmisteenJakelija",
      null,
      {alakohdeToiminta: 'LANNJAKELU', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITEVALMISTEEN_JAKELIJA,

      lopetaToimintaSyote,

      new Syote(new Teksti("Orgaaniset lannoitteet", "orgaanisetLannoitteet", view), null, "orgaanisetLannoitteet", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Orgaaniset kivennäislannoitteet", "orgaanisetKivennaislannoitteet", view), null, "orgaanisetKivennaislannoitteet", null, {
        }, null,
        ...valmistusJaTuontimaarat,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Epäorgaaniset lannoitteet", "epaorgaanisetLannoitteet", view), null, "epaorgaanisetLannoitteet", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Kalkitusaineet", "kalkitusaineet", view), null, "kalkitusaineet", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Orgaaniset maanparannusaineet", "orgaanisetMaanparannusaineet", view), null, "orgaanisetMaanparannusaineet", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Epäorgaaniset maanparannusaineet", "epaorgaanisetMaanparannusaineet", view), null, "epaorgaanisetMaanparannusaineet", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Kasvualustat", "kasvualustat", view), null, "kasvualustat", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Kasvibiostimulantit", "kasvibiostimulantit", view), null, "kasvibiostimulantit", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),

      new Syote(new Teksti("Lannoitevalmisteiden seokset", "lannoitevalmisteidenSeokset", view), null, "lannoitevalmisteidenSeokset", null, {
          showValmistusTitle: false
        }, null,
        ...valmistusJaTuontimaarat
      ),
    ),

    new Syote(
      new Teksti("Lannoitevalmisteen valmistus tai valmistuttaminen", "lannoiteValmisteenValmistus"),
      null,
      "lannoiteValmisteenValmistus",
      null,
      {alakohdeToiminta: 'LANNVALMISTUS', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITEVALMISTE_AINESOSALUOKAT,

      lopetaToimintaSyote,

      new Syote(new Teksti("Orgaaniset lannoitteet", "orgaanisetLannoitteet", view), null, "orgaanisetLannoitteet", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Orgaaniset kivennäislannoitteet", "orgaanisetKivennaislannoitteet", view), null, "orgaanisetKivennaislannoitteet", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Epäorgaaniset lannoitteet", "epaorgaanisetLannoitteet", view), null, "epaorgaanisetLannoitteet", null, {
          showValmistusTitle: false
        }, null,
        ...lannoitteenValmistusKysymykset
      ),

      new Syote(new Teksti("Kalkitusaineet", "kalkitusaineet", view), null, "kalkitusaineet", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Orgaaniset maanparannusaineet", "orgaanisetMaanparannusaineet", view), null, "orgaanisetMaanparannusaineet", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Epäorgaaniset maanparannusaineet", "epaorgaanisetMaanparannusaineet", view), null, "epaorgaanisetMaanparannusaineet", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Kasvualustat", "kasvualustat", view), null, "kasvualustat", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Kasvibiostimulantit", "kasvibiostimulantit", view), null, "kasvibiostimulantit", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),

      new Syote(new Teksti("Lannoitevalmisteiden seokset", "lannoitevalmisteidenSeokset", view), null, "lannoitevalmisteidenSeokset", null, {
        }, null,
        ...lannoitteenValmistusKysymykset,
        ...aolChecboxes
      ),
    ),

    new Syote(
      new Teksti("Lannoitevalmisteen varastointi", "lannoiteValmisteenVarastointi"),
      null,
      "lannoiteValmisteenVarastointi",
      null,
      {alakohdeToiminta: 'LANNVARASTOINTI', isMuutosIlmoitus},
      YhteenvetoTyyppi.LANNOITE_VARASTOINTI,

      lopetaToimintaSyote,

      new Syote(new Teksti("Orgaaniset lannoitteet", "orgaanisetLannoitteet", view), null, "orgaanisetLannoitteet", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Orgaaniset kivennäislannoitteet", "orgaanisetKivennaislannoitteet", view), null, "orgaanisetKivennaislannoitteet", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Epäorgaaniset lannoitteet", "epaorgaanisetLannoitteet", view), null, "epaorgaanisetLannoitteet", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Kalkitusaineet", "kalkitusaineet", view), null, "kalkitusaineet", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Orgaaniset maanparannusaine", "orgaanisetMaanparannusaineet", view), null, "orgaanisetMaanparannusaineet", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Epäorgaaniset maanparannusaine", "epaorgaanisetMaanparannusaineet", view), null, "epaorgaanisetMaanparannusaineet", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Kasvualustat", "kasvualustat", view), null, "kasvualustat", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Kasvibiostimulantit", "kasvibiostimulantit", view), null, "kasvibiostimulantit", null, {
        type: 'checkbox',
      }),
      new Syote(new Teksti("Lannoitevalmisteiden seokset", "lannoitevalmisteidenSeokset", view), null, "lannoitevalmisteidenSeokset", null, {
        type: 'checkbox',
      }),
    )
  );
};
