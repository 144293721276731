import {Injectable} from "@angular/core";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";
import {Field, FieldGroup} from "ngx-fielding";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {createToiminnanAjankohta} from "../common/lomake-fields/toiminnan-ajankohta";
import {createSahkoinenAsiointi} from "../common/lomake-fields/common-fields";
import {
  createKasvitietPuutarha,
  createMyyEtamyynti,
  createMyyPuutavaraKasvipassi,
  createMyyTaimimyymala,
  createMyyTaimiValitysAvo,
  createMyyTaimiValitysKasvi,
  createMyyTaimiValitysKasvipassi,
  createMyyTaimiValitysSiemen,
  createMyyViherrakentaminen,
  createOmavalvonta,
  createToiminta,
  createTuoISPM15Kasittely,
  createTuoISPM15Valmistus,
  createTuoKasvi,
  createTuoMarjaCAC,
  createTuoMarjaVarm,
  createTuontiJaVienti,
  createTuoPerunaViljely,
  createTuoPuuvart,
  createTuoVihannesTaimi,
  createTuoVihannesValmis,
  createTuoVihannesYksityiskaytto
} from "./toiminnat";
import {createVerkkokaupatGroup} from "../common/lomake-fields/verkkokaupat";

@Injectable()
export class KasvinterveysrekisteriV2Data implements LomakeV2Data {

  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(toimintotyyppi: string): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();

    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
        Field.build(),
        {
          ulkomainenOsoite,
          perustiedot,
          laskutustiedot,
          yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
          toiminnanAloitus: createToiminnanAjankohta(toimintotyyppi),
          sahkoinenAsiointi: new FieldGroup(Field.build(), {sahkoinenAsiointi: createSahkoinenAsiointi()}),
          toiminta: createToiminta(),
          tuoPuuvart: createTuoPuuvart(),
          tuoMarjaVarm: createTuoMarjaVarm(),
          tuoMarjaCAC: createTuoMarjaCAC(),
          tuoKasvi: createTuoKasvi(),
          tuoVihannesTaimi: createTuoVihannesTaimi(),
          tuoVihannesYksityiskaytto: createTuoVihannesYksityiskaytto(),
          tuoVihannesValmis: createTuoVihannesValmis(),
          tuoPerunaViljely: createTuoPerunaViljely(),
          tuoISPM15Kasittely: createTuoISPM15Kasittely(),
          tuoISPM15Valmistus: createTuoISPM15Valmistus(),
          myyTaimimyymala: createMyyTaimimyymala(),
          myyTaimiValitysKasvi: createMyyTaimiValitysKasvi(),
          myyTaimiValitysAvo: createMyyTaimiValitysAvo(),
          myyTaimiValitysSiemen: createMyyTaimiValitysSiemen(),
          myyTaimiValitysKasvipassi: createMyyTaimiValitysKasvipassi(),
          myyViherrakentaminen: createMyyViherrakentaminen(),
          myyEtamyynti: createMyyEtamyynti(),
          verkkokaupat: createVerkkokaupatGroup(),
          myyPuutavaraKasvipassi: createMyyPuutavaraKasvipassi(),
          tuontiJaVienti: createTuontiJaVienti(),
          kasvitietPuutarha: createKasvitietPuutarha(),
          omavalvonta: createOmavalvonta()
        }
    )
  }
}



