import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RaporttiHaku, RaporttiLiitetiedosto} from "./raportti.utils";
import {BehaviorSubject, Observable, Subscriber} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";

/**
 * Tulosraporttien ja liitteiden haku
 */
@Injectable()
export class TulosraporttiService {

  private getRaportitSub: Subscriber<any>;
  private readonly _isGetRaportitRequestPending = new BehaviorSubject<boolean>(false);
  isGetRaportitRequestPending$ = this._isGetRaportitRequestPending.asObservable();

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Hakee käyttäjän raportit
   */
  getKauppaeraRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/kauppaeraraportit";
    return this.getRaportit(url, params);
  }

  getPakkaamolupaPaatosRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/pakkaamolupapaatosraportit";
    return this.getRaportit(url, params);
  }

  getToimijanRekisterioteRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/toimijanrekisterioteraportit";
    return this.getRaportit(url, params);
  }

  getSiemenValvontaRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/siemenvalvontaraportit";
    return this.getRaportit(url, params);
  }

  getRehuvalvontaRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/rehuvalvontaraportit";
    return this.getRaportit(url, params);
  }

  getViljelystarkastusRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/viljelystarkastuspaatokset";
    return this.getRaportit(url, params);
  }

  getElabraTutkimustodistusRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/tutkimustodistukset";
    return this.getRaportit(url, params);
  }

  getLannoitevalvontaRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/lannoitevalvontaraportit";
    return this.getRaportit(url, params);
  }

  getKasvinterveysRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/tarkastuspoytakirjat";
    return this.getRaportit(url, params);
  }

  getLuomuRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/as/raportit/luomuvalvontaraportit";
    return this.getRaportit(url, params);
  }

  getVirkailijaKauppaeraRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/kauppaeraraportit";
    return this.getRaportit(url, params);
  }

  getVirkailijaSiemenvalvontaraportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/siemenvalvontaraportit";
    return this.getRaportit(url, params);
  }

  getVirkailijaRehuvalvontaRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/rehuvalvontaraportit";
    return this.getRaportit(url, params);
  }

  getVirkailijaViljelystarkastusRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/viljelystarkastuspaatokset";
    return this.getRaportit(url, params);
  }

  getVirkailijaLannoitevalvontaRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/lannoitevalvontaraportit";
    return this.getRaportit(url, params);
  }

  getVirkailijaKasvinterveysRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/tarkastuspoytakirjat";
    return this.getRaportit(url, params);
  }

  getVirkailijaLuomuvalvontaRaportit(params = {}): Promise<RaporttiHaku> {
    const url = "/api/v1/vk/raportit/luomuvalvontaraportit";
    return this.getRaportit(url, params);
  }

  /**
   * Hakee käyttäjän raportit
   */
  getRaportit(url, params = {}): Promise<RaporttiHaku> {
    if (this._isGetRaportitRequestPending.value) {
      this.getRaportitSub.error("CANCEL");
    }

    const obs = new Observable(o => this.getRaportitSub = o);
    this._isGetRaportitRequestPending.next(true);

    return this.http.get(url, {params}).pipe(takeUntil(obs))
      .toPromise()
      .then(r => {
        this._isGetRaportitRequestPending.next(false);
        return r as RaporttiHaku;
      })
      .catch(reason => {
        if (reason !== "CANCEL") {
          console.error(reason);
          return reason;
        }
        return null;
      });
  }

  getKauppaeraLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/kauppaeraraportit/liitteet");
  }

  getKauppaeraLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/kauppaeraraportit/liitteet/zip");
  }

  getSiemenValvontaLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/siemenvalvontaraportit/liitteet");
  }

  getSiemenValvontaLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/siemenvalvontaraportit/liitteet/zip");
  }

  getPakkaamoLupaLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/pakkaamoluparaportit/liitteet");
  }

  getPakkaamoLupaLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/pakkaamoluparaportit/liitteet/zip");
  }

  getToimijanRekisterioteLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/toimijanrekisterioteraportit/liitteet");
  }

  getToimijanRekisterioteLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/toimijanrekisterioteraportit/liitteet/zip");
  }

  getRehuvalvontaLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/rehuvalvontaraportit/liitteet");
  }

  getRehuvalvontaLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/rehuvalvontaraportit/liitteet/zip");
  }

  getViljelystarkastusLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/viljelystarkastuspaatokset/liitteet");
  }

  getViljelystarkastusLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/viljelystarkastuspaatokset/liitteet/zip");
  }

  getLannoitevalvontaLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/lannoitevalvontaraportit/liitteet");
  }

  getLannoitevalvontaLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/lannoitevalvontaraportit/liitteet/zip");
  }

  getLuomuvalvontaLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/luomuvalvontaraportit/liitteet");
  }

  getLuomuvalvontaLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/luomuvalvontaraportit/liitteet/zip");
  }

  getKasvinterveysLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/tarkastuspoytakirjat/liitteet");
  }

  getKasvinterveysLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/tarkastuspoytakirjat/liitteet/zip");
  }

  getElabraLiite(tiedostonimi: string): Promise<Blob> {
    return this.getLiite(tiedostonimi, "/api/v1/as/raportit/tutkimustodistukset/liitteet");
  }

  getElabraLiiteZip(tiedostonimet: string[]): Promise<Blob> {
    return this.getLiiteZip(tiedostonimet, "/api/v1/as/raportit/tutkimustodistukset/liitteet/zip");
  }

  private getLiite(tiedostonimi, url): Promise<Blob> {
    const params = {liitetiedosto: tiedostonimi};
    return this.http.get(url, {params, responseType: 'blob' as 'json'})
      .toPromise()
      .then(r => this.downloadFile(r));
  }

  private getLiiteZip(tiedostot: string[], url): Promise<Blob> {
    const params = {liitetiedostot: tiedostot};
    return this.http.get(url, {params, responseType: 'blob' as 'json'})
      .toPromise()
      .then(r => new Blob([r as any], {type: 'application/zip'}));
  }

  private downloadFile(data): Blob {
    return new Blob([data], {type: 'application/pdf'});
  }

  setRaportti(raporttiLiite: RaporttiLiitetiedosto) {
    const url = `/api/v1/vk/raportit/`;
    this.http.put(url, raporttiLiite)
      .subscribe();
  }
}
