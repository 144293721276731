import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild} from "@angular/core";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {LoadedExcel} from "../../../touko-components/excel-list.component";
import {ErrorMsg} from "./touko-excel-lataus.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'laakerehu-lataus',
  template: `
    <div class="input-group">
      <input autocomplete="off" id="hidden-laakerehu-lataus-input" class="form-control-file" style="display: none;"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
             type="file" attr.aria-label="lomakeYleinen.lataaLaakerehu" (change)="onFileChange($event)" #fileInput
             [disabled]="isDisabled"/>
      <div class="form-control" (click)="fileInput.click()">{{'lomakeYleinen.liiteLataaUusi' | attribute}}</div>
      <div class="input-group-append">
        <button [attribute]="'lomakeYleinen.liiteSelaa'" id="selaa-laakerehu-lataus-input" class="btn btn-outline-primary"
                (click)="fileInput.click()" [disabled]="isDisabled"
                type="button">Selaa...
        </button>
      </div>
    </div>

    <em class="small text-muted" attribute="rehuvuosiilmoitus.laakerehuLiiteOhje">Tuetut tiedostomuodot ovat
      PDF ja .xlsx. Tiedoston maksimikoko 8Mb.</em>

    <div *ngIf="errorMessage?.isError" class="mt-4">
      <touko-alert-box alertType="error" [closable]="true" (close)="closeAlert()">
        <p class="mb-0" [attribute]="errorMessage.message">
          Excelin latauksessa tapahtui virhe
        </p>
      </touko-alert-box>
    </div>

    <excel-list [loadedExcels]="loadedLiitteet" (removeExcelData)="removeExcel($event)" [lataaBtnLabel]="'lomakeYleinen.lataaTiedosto'"></excel-list>
  `,
  providers: [
    LiitetiedostoPalvelinService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LaakeRehuLatausComponent),
    }
  ]
})
export class LaakeRehuLatausComponent implements ControlValueAccessor {

  @Input() lrlomakeId: number;
  @Input() isDisabled: boolean;
  @Input() maxLiiteLkm = 1;
  @Output() lrliitteet: EventEmitter<{ laakeRehuLiitteet: LiitetiedostoResponse[] }> = new EventEmitter();
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  loadedLiitteet: LoadedExcel[] = [];
  lrliite: LiitetiedostoResponse[] = [];
  errorMessage: ErrorMsg;

  constructor(
      private _renderer: Renderer2,
      private liitetiedostoService: LiitetiedostoPalvelinService) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(obj: any): void {
    this.loadedLiitteet = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  async onFileChange(event) {
    this.errorMessage = {isError: false, message: ""};
    if (this.loadedLiitteet.length >= this.maxLiiteLkm) {
      this.errorMessage = {isError: true, message: "lomakeYleinen.errLaakeRehuMax"};
    } else {
      const selectedFile = event.target.files[0];
      const liitetiedosto = await this.liitetiedostoService.sendLiitetiedosto(selectedFile, "LAAKEREHU", this.lrlomakeId);

      this.lrliite.push(liitetiedosto);
      this.lrliitteet.emit({laakeRehuLiitteet: this.lrliite});
      this.loadedLiitteet.push({
        name: selectedFile.name,
        type: "laakeRehu",
        sectionTitle: "rehuvuosiilmoitus.rehuLaakeOtsikko",
        liiteId: liitetiedosto.id,
        errors: []
      });

      this._onChange(this.loadedLiitteet);
      this._onTouched(true);
    }
    this._renderer.setProperty(this.fileInput.nativeElement, 'value', null);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  removeExcel(event) {
    this.liitetiedostoService.deleteLiitetiedosto(event.excel.liiteId).then(() => {
      this.loadedLiitteet.splice(event.index, 1);
      this.lrliite.splice(event.index, 1);
      this.lrliitteet.emit({laakeRehuLiitteet: this.lrliite});
      this._onChange(this.loadedLiitteet);
    });
  }

  closeAlert() {
    this.errorMessage = {isError: false, message: ""};
  }
}
