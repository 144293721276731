<touko-lomake *ngIf="lomake"
              [formGroup]="lomake"
              [tunnus]="lomakeMetaData.id"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [cancelFn]="cancelLomake"
              (onSivuChanged)="handleLeaveSivu($event)"
              [saveFn]="saveLomake">

  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        sivuvirhekasittely [virheGroups]="['perustiedot', 'yhteyshenkilot']"
        sivu="ref0">
    <div class="mb-4">
      <touko-alert-box *ngIf="!isPerustiedotValid">
        <div attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</div>
      </touko-alert-box>
    </div>
    <perustiedot [lomake]="lomake" [isHenkiloAsiakas]="accountService.isHenkiloToimija()"></perustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"></yhteyshenkilot>
  </sivu>

  <sivu [attribuuttiAvain]="'luomuPoikkeus9c.luvatTavanomLisaysaineisto'"
        [selectable]="isPerustiedotValid"
        [virheGroups]="['luvatTavanomLisaysaineisto']" sivu="ref1" sivuvirhekasittely>

    <div formArrayName="luvatTavanomLisaysaineisto">
      <luomulupa [lomakeId]="this.lomakeMetaData.id"></luomulupa>
    </div>

    <button (click)="lisaaLupa()" type="button" class="btn btn-l btn-primary mr-1"
            id="tavanomainen-yh-lisaa"
            attribute="luomuPoikkeus9c.lisaaLupa">Lisää hakemukseen uusi laji
    </button>
  </sivu>

  <sivu [attribuuttiAvain]="'teksti.sivuYhteenveto'" [selectable]="isPerustiedotValid">
    <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
    <app-field-group-summary class="summary" [fieldGroup]="lomake"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button id="laheta" class="mt-4 btn btn-primary" type="submit" attribute="teksti.laheta">Lähetä</button>
    </div>
  </sivu>
</touko-lomake>

<pre *devFeature class="small">
      {{ lomake?.getRawValue() | json }}
</pre>
