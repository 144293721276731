<div class="row" *ngIf="showOVSNotification()">
  <div class="col-lg-4 col-md-6">
    <omavalvontasuunnitelma></omavalvontasuunnitelma>
  </div>
</div>

<ngb-alert type="info" class="mb-4" *ngIf="showPalaute" (closed)="closePalauteAlert()">
  <span attribute="etusivu.palauteKysely" [markdown]="true">
    Anna palautetta Toukon käytöstä osoitteessa
  </span>
</ngb-alert>

<div class="row">
  <div class="col-12">
    <h1 attribute="etusivu.toimipaikanIlmoitukset">Toimipaikan ilmoitukset</h1>
  </div>
</div>

<div class="row" *ngIf="keskeneraisetLomakkeet.length > 0">
  <div class="col-12">
    <h2 attribute="etusivu.keskeneraiset"></h2>
  </div>
  <lomake-card *ngFor="let kesken of keskeneraisetLomakkeet"
               class="col-lg-3 col-md-4"
               [lomake]="kesken"
               (jatka)="jatkaKeskeneraistaLomaketta($event)"
               (poista)="deleteKeskenerainen($event.id)">
  </lomake-card>
</div>

<div class="row" *ngIf="lahetetytLomakkeet.length > 0">
  <div class="col-12">
    <h2 attribute="etusivu.lahetetyt">Lähetetyt</h2>
    <lomake-progress *ngFor="let lomake of lahetetytLomakkeet"
                     [lomake]="lomake"></lomake-progress>

    <button *ngIf="canLoadMore" class="btn btn-primary" [clickAsync]="naytaLisaaLomakkeita" attribute="etusivu.naytaLisaaLomakkeita">
      Näytä lisää lähetettyjä lomakkeita
    </button>

  </div>

</div>

<div class="row" *ngIf="lahetetytLomakkeet.length === 0 && keskeneraisetLomakkeet.length === 0">
  <div class="col-12 alert alert-warning">
    <p attribute="etusivu.infoEiLahetettyja">Valitulle toimipaikalle ei löytynyt lähetettyjä tai keskeneräisiä
      ilmoituksia. Voit tehdä ilmoituksen tai tarkastella
      valittuun toimipaikkaan liittyviä raportteja vasemman sivupalkin linkeistä.</p>
    <p attribute="etusivu.infoVaihdaToimipaikka">Tilin asetukset -sivulla voit vaihtaa asioinnin kohteena olevan
      toimipaikan.</p>
  </div>
</div>

