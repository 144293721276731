import {TulkkiObject} from "../common/lomake-field-utils";
import {Field, FieldArray, FieldControl, FieldGroup, SummaryFnType, SummaryItem, ValueOption} from "ngx-fielding";
import {
  checkBoxSummary,
  subTitleH3Summary,
  subTitleH3WithBadgeSummary,
  subTitleH4Summary,
  subTitleH5Summary,
  titleSummary,
  valueSummary
} from "./summary-helpers";
import {Tyyppinimet} from "../../../utils/tyyppinimet";
import {TitleH4SummaryComponent,} from "../../summary/title-summary/title-summary.component";
import {QuestionAndTranslatedAnswerSummaryComponent} from "../../summary/question-and-translated-answer-summary.component";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

class RadioValue implements ValueOption {
  label: string;
  value: string;
  htmlId: string;

  constructor(value: string, label?, htmlId?) {
    this.label = label || attr(value);
    this.value = value;
    this.htmlId = htmlId;
  }
}

export const createToimintaJaTuoteluokaKansallinen = (toimintotyyppi): FieldGroup => {
  return new FieldGroup(Field.build(
    attr('kansallinenToiminta'), titleSummary), {
    lannoiteValmisteenTuonti: createTuonti(toimintotyyppi),
    lannoiteValmisteenVienti: createVienti(toimintotyyppi),
    lannoiteValmisteenTuotantoOmaKaytto: createOmaKaytto(toimintotyyppi),
    lannoiteValmisteenJakelija: createJakelija(toimintotyyppi),
    lannoiteValmisteenValmistus: createValmistus(toimintotyyppi),
    lannoiteValmisteenVarastointi: createVarastointi(toimintotyyppi)
  })
}

const createTuonti = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('lannoiteValmisteenTuonti'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatKansallinen-lannoiteValmisteenTuonti',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  orgaanisetLannoitteet: createTuontiTuote('orgaanisetLannoitteet', 'tuotavienLannoitteidenMaara', 'lannoiteValmisteenTuonti-orgaanisetLannoitteet-maara'),
  orgaanisetKivennaislannoitteet: createTuontiTuote('orgaanisetKivennaislannoitteet', 'tuotavienKivennaislannoitteidenMaara', 'lannoiteValmisteenTuonti-orgaanisetKivennaislannoitteet-maara'),
  epaorgaanisetLannoitteet: createTuontiTuote('epaorgaanisetLannoitteet', 'tuotavienEpaorgaanistenLannoitteidenMaara', 'lannoiteValmisteenTuonti-epaorgaanisetLannoitteet-maara'),
  kalkitusaineet: createTuontiTuote('kalkitusaineet', 'tuotavienKalkitusaineidenMaara', 'lannoiteValmisteenTuonti-kalkitusaineet-maara'),
  orgaanisetMaanparannusaineet: createTuontiTuote('orgaanisetMaanparannusaineet', 'tuotavienOrgaanistenMaanparannusaineidenMaara', 'lannoiteValmisteenTuonti-orgaanisetMaanparannusaineet-maara'),
  epaorgaanisetMaanparannusaineet: createTuontiTuote('epaorgaanisetMaanparannusaineet', 'tuotavienEpaorgaanistenMaanparannusaineidenMaara', 'lannoiteValmisteenTuonti-epaorgaanisetMaanparannusaineet-maara'),
  kasvualustat: createTuontiTuote('kasvualustat', 'tuotavienKasvualustojenMaara', 'lannoiteValmisteenTuonti-kasvualustat-maara'),
  kasvibiostimulantit: createTuontiTuote('kasvibiostimulantit', 'tuotavienKasvibiostimulanttienMaara', 'lannoiteValmisteenTuonti-kasvibiostimulantit-maara'),
  lannoitevalmisteidenSeokset: createTuontiTuote('lannoitevalmisteidenSeokset', 'tuotavienSeoksienMaara', 'lannoiteValmisteenTuonti-lannoitevalmisteidenSeokset-maara'),
  vanhanLainsaadannonMukaanTuonti: new FieldGroup(Field.build(attr('vanhanLainsaadannonMukaan'), null, null, null, null, "vanhanLainsaadannonMukaanTuonti"), {
    kansallinenTaiEYtyyppinimitunnus: new FieldArray(Field.build(attr('vanhanLainsaadannonMukaan'), (control) => new SummaryItem<any>(control.field.label, null, {show: control.value.filter(v => v.maara).length, classList: ['pl-5']}, TitleH4SummaryComponent)),
      createTyyppinimi,
      [createTyyppinimi()]
    )
  })
});

const createTuontiTuote = (tuontiLabel, maaraLabel, maaraId) => new FieldGroup(Field.of({label: attr(tuontiLabel), htmlId: tuontiLabel, summaryFn: subTitleH4Summary}), {
  maarat: new FieldGroup(Field.build(), {
    maara: new FieldControl(Field.of({
      label: attr(maaraLabel),
      htmlId: maaraId,
      summaryFn: tuontiValueSummary}))
  })
});

const tuontiValueSummary: SummaryFnType = control => {
  return new SummaryItem<any>(attr('maahantuotavaMaara'), control.value, {
    show: control.value,
    classList: ['pl-7'],
    valueSuffix: 'lannoiterekisteri.tnVuosi'
  });
}

const createVienti = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('lannoiteValmisteenVienti'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatKansallinen-lannoiteValmisteenVienti',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  orgaanisetLannoitteet: createVientiTuote('orgaanisetLannoitteet', 'vietavienLannoitteidenMaara', 'lannoiteValmisteenVienti-orgaanisetLannoitteet-maara'),
  orgaanisetKivennaislannoitteet: createVientiTuote('orgaanisetKivennaislannoitteet', 'vietavienKivennaislannoitteidenMaara', 'lannoiteValmisteenVienti-orgaanisetKivennaislannoitteet-maara'),
  epaorgaanisetLannoitteet: createVientiTuote('epaorgaanisetLannoitteet', 'epaorgaanisetLannoitteet', 'lannoiteValmisteenVienti-epaorgaanisetLannoitteet-maara'),
  kalkitusaineet: createVientiTuote('kalkitusaineet', 'vietavienKalkitusaineidenMaara', 'lannoiteValmisteenVienti-kalkitusaineet-maara'),
  orgaanisetMaanparannusaineet: createVientiTuote('orgaanisetMaanparannusaineet', 'vietavienOrgaanistenMaanparannusaineidenMaara', 'lannoiteValmisteenVienti-orgaanisetMaanparannusaineet-maara'),
  epaorgaanisetMaanparannusaineet: createVientiTuote('epaorgaanisetMaanparannusaineet', 'vietavienEpaorgaanistenMaanparannusaineidenMaara', 'lannoiteValmisteenVienti-epaorgaanisetMaanparannusaineet-maara'),
  kasvualustat: createVientiTuote('kasvualustat', 'vietavienKasvualustojenMaara', 'lannoiteValmisteenVienti-kasvualustat-maara'),
  kasvibiostimulantit: createVientiTuote('kasvibiostimulantit', 'vietavienKasvibiostimulanttienMaara', 'lannoiteValmisteenVienti-kasvibiostimulantit-maara'),
  lannoitevalmisteidenSeokset: createVientiTuote('lannoitevalmisteidenSeokset', 'vietavienSeoksienMaara', 'lannoiteValmisteenVienti-lannoitevalmisteidenSeokset-maara')
})

const createVientiTuote = (vientiLabel, maaraLabel, maaraId) => new FieldGroup(Field.of({label: attr(vientiLabel), htmlId: vientiLabel, summaryFn: subTitleH4Summary}), {
  maarat: new FieldGroup(Field.build(), {
    maara: new FieldControl(Field.of({
      label: attr(maaraLabel),
      htmlId: maaraId,
      summaryFn: valueSummary
    }))
  })
});

const createOmaKaytto = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('lannoiteValmisteenTuotantoOmaKaytto'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatKansallinen-lannoiteValmisteenTuotantoOmaKaytto',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  orgaanisetLannoitteet: createOmaKayttoTuote('orgaanisetLannoitteet', 'lannoitteidenMaara', 'lannoiteValmisteenTuotantoOmaKaytto-orgaanisetLannoitteet-maara', 'lannoiteValmisteenTuotantoOmaKaytto-orgaanisetLannoitteet'),
  orgaanisetKivennaislannoitteet: createOmaKayttoTuote('orgaanisetKivennaislannoitteet', 'kivennaislannoitteidenMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-orgaanisetKivennaislannoitteet-maara', 'lannoiteValmisteenTuotantoOmaKaytto-orgaanisetKivennaislannoitteet'),
  epaorgaanisetLannoitteet: createOmaKayttoTuote('epaorgaanisetLannoitteet', 'epaorgaanistenLannoitteidenMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-epaorgaanisetLannoitteet-maara', 'lannoiteValmisteenTuotantoOmaKaytto-epaorgaanisetLannoitteet'),
  kalkitusaineet: createOmaKayttoTuote('kalkitusaineet', 'kalkitusaineidenMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-kalkitusaineet-maara', 'lannoiteValmisteenTuotantoOmaKaytto-kalkitusaineet'),
  orgaanisetMaanparannusaineet: createOmaKayttoTuote('orgaanisetMaanparannusaineet', 'orgaanistenMaanparannusaineidenMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-orgaanisetMaanparannusaineet-maara', 'lannoiteValmisteenTuotantoOmaKaytto-orgaanisetMaanparannusaineet'),
  epaorgaanisetMaanparannusaineet: createOmaKayttoTuote('epaorgaanisetMaanparannusaineet', 'epaorgaanistenMaanparannusaineidenMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-epaorgaanisetMaanparannusaineet-maara', 'lannoiteValmisteenTuotantoOmaKaytto-epaorgaanisetMaanparannusaineet'),
  kasvualustat: createOmaKayttoTuote('kasvualustat', 'kasvualustojenMaara', 'lannoiteValmisteenTuotantoOmaKaytto-kasvualustat-maara', 'lannoiteValmisteenTuotantoOmaKaytto-kasvualustat'),
  kasvibiostimulantit: createOmaKayttoTuote('kasvibiostimulantit', 'kasvibiostimulanttienMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-kasvibiostimulantit-maara', 'lannoiteValmisteenTuotantoOmaKaytto-kasvibiostimulantit'),
  lannoitevalmisteidenSeokset: createOmaKayttoTuote('lannoitevalmisteidenSeokset', 'seoksienMaaraOmaKaytto', 'lannoiteValmisteenTuotantoOmaKaytto-lannoitevalmisteidenSeokset-maara', 'lannoiteValmisteenTuotantoOmaKaytto-lannoitevalmisteidenSeokset')
})

const createOmaKayttoTuote = (tuotetyyppi, label, maaraId, aolId) => new FieldGroup(Field.of({label: attr(tuotetyyppi), htmlId: tuotetyyppi, summaryFn: subTitleH4Summary}), {
  maarat: new FieldGroup(Field.build(attr('arvioidutMaaratYhteenveto'), subTitleH5Summary), {
    maara: new FieldControl(Field.of({
      label: attr(label),
      htmlId: maaraId,
      summaryFn: control => new SummaryItem<any>(attr('arvioVuosittaisistaValmistusmaarista'), control.value, {
        show: control.value,
        classList: ['pl-7'],
        valueSuffix: 'lannoiterekisteri.tnVuosi'
      })
    }))
  }),
  ...createAols(aolId)
})

const createJakelija = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('lannoiteValmisteenJakelija'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatKansallinen-lannoiteValmisteenJakelija',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  orgaanisetLannoitteet: new FieldGroup(createJakelijaField('orgaanisetLannoitteet'), {
    ...createJakelijaMaarat('orgaanisetLannoitteet')
  }),
  orgaanisetKivennaislannoitteet: new FieldGroup(createJakelijaField('orgaanisetKivennaislannoitteet'), {
    ...createJakelijaMaarat('orgaanisetKivennaislannoitteet'),
  }),
  epaorgaanisetLannoitteet: new FieldGroup(createJakelijaField('epaorgaanisetLannoitteet'), {
    ...createJakelijaMaarat('epaorgaanisetLannoitteet'),
  }),
  kalkitusaineet: new FieldGroup(createJakelijaField('kalkitusaineet'), {
    ...createJakelijaMaarat('kalkitusaineet'),
  }),
  orgaanisetMaanparannusaineet: new FieldGroup(createJakelijaField('orgaanisetMaanparannusaineet'), {
    ...createJakelijaMaarat('orgaanisetMaanparannusaineet')
  }),
  epaorgaanisetMaanparannusaineet: new FieldGroup(createJakelijaField('epaorgaanisetMaanparannusaineet'), {
    ...createJakelijaMaarat('epaorgaanisetMaanparannusaineet'),
  }),
  kasvualustat: new FieldGroup(createJakelijaField('kasvualustat'), {
    ...createJakelijaMaarat('kasvualustat'),
  }),
  kasvibiostimulantit: new FieldGroup(createJakelijaField('kasvibiostimulantit'), {
    ...createJakelijaMaarat('kasvibiostimulantit'),
  }),
  lannoitevalmisteidenSeokset: new FieldGroup(createJakelijaField('lannoitevalmisteidenSeokset'), {
    ...createJakelijaMaarat('lannoitevalmisteidenSeokset'),
  })
})

const createJakelijaField = (jakelijaLabel) => {
  return Field.of({label: attr(jakelijaLabel), htmlId: jakelijaLabel, summaryFn: subTitleH4Summary})
}

const createJakelijaMaarat = (jakeluTyyppi) => {
  return {
    maarat: new FieldGroup(Field.build(), {
      maaraSuomi: new FieldControl(Field.of({label: attr('markkinoitavienLannoitteidenMaara'), htmlId: 'lannoiteValmisteenJakelija-' + jakeluTyyppi + '-maaraSuomi', summaryFn: valueSummary})),
      maaraSisamarkkinat: new FieldControl(Field.of({label: attr('sisamarkkinoiltaTuotavienLannoitteidenMaara'), htmlId: 'lannoiteValmisteenJakelija-' + jakeluTyyppi + '-maaraSisamarkkinat', summaryFn: valueSummary}))
    })
  }
}

const createValmistus = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('lannoiteValmisteenValmistus'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatKansallinen-lannoiteValmisteenValmistus',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  orgaanisetLannoitteet: new FieldGroup(createValmistusField('orgaanisetLannoitteet'), {
    ...createValmistusMaarat('orgaanisetLannoitteet'),
    ...createValmistusRadiot('orgaanisetLannoitteet'),
    ...createAols('lannoiteValmisteenValmistus-orgaanisetLannoitteet')
  }),
  orgaanisetKivennaislannoitteet: new FieldGroup(createValmistusField('orgaanisetKivennaislannoitteet'), {
    ...createValmistusMaarat('orgaanisetKivennaislannoitteet'),
    ...createValmistusRadiot('orgaanisetKivennaislannoitteet'),
    ...createAols('lannoiteValmisteenValmistus-orgaanisetKivennaislannoitteet')
  }),
  epaorgaanisetLannoitteet: new FieldGroup(createValmistusField('epaorgaanisetLannoitteet'), {
    ...createValmistusMaarat('epaorgaanisetLannoitteet'),
    ...createValmistusRadiot('epaorgaanisetLannoitteet'),
    ...createAols('lannoiteValmisteenValmistus-epaorgaanisetLannoitteet')
  }),
  kalkitusaineet: new FieldGroup(createValmistusField('kalkitusaineet'), {
    ...createValmistusMaarat('kalkitusaineet'),
    ...createValmistusRadiot('kalkitusaineet'),
    ...createAols('lannoiteValmisteenValmistus-kalkitusaineet')
  }),
  orgaanisetMaanparannusaineet: new FieldGroup(createValmistusField('orgaanisetMaanparannusaineet'), {
    ...createValmistusMaarat('orgaanisetMaanparannusaineet'),
    ...createValmistusRadiot('orgaanisetMaanparannusaineet'),
    ...createAols('lannoiteValmisteenValmistus-orgaanisetMaanparannusaineet')
  }),
  epaorgaanisetMaanparannusaineet: new FieldGroup(createValmistusField('epaorgaanisetMaanparannusaineet'), {
    ...createValmistusMaarat('epaorgaanisetMaanparannusaineet'),
    ...createValmistusRadiot('epaorgaanisetMaanparannusaineet'),
    ...createAols('lannoiteValmisteenValmistus-epaorgaanisetMaanparannusaineet')
  }),
  kasvualustat: new FieldGroup(createValmistusField('kasvualustat'), {
    ...createValmistusMaarat('kasvualustat'),
    ...createValmistusRadiot('kasvualustat'),
    ...createAols('lannoiteValmisteenValmistus-kasvualustat')
  }),
  kasvibiostimulantit: new FieldGroup(createValmistusField('kasvibiostimulantit'), {
    ...createValmistusMaarat('kasvibiostimulantit'),
    ...createValmistusRadiot('kasvibiostimulantit'),
    ...createAols('lannoiteValmisteenValmistus-kasvibiostimulantit')
  }),
  lannoitevalmisteidenSeokset: new FieldGroup(createValmistusField('lannoitevalmisteidenSeokset'), {
    ...createValmistusMaarat('lannoitevalmisteidenSeokset'),
    ...createValmistusRadiot('lannoitevalmisteidenSeokset'),
    ...createAols('lannoiteValmisteenValmistus-lannoitevalmisteidenSeokset')
  })
})

const createValmistusField = (valmistusLabel) => {
  return Field.of({label: attr(valmistusLabel), htmlId: valmistusLabel, summaryFn: subTitleH4Summary})
}

const createValmistusMaarat = (valmistusTyyppi) => {
  return {
    maarat: new FieldGroup(Field.build(attr('arvioidutMaaratYhteenveto'), subTitleH5Summary), {
      maara: new FieldControl(
        Field.of({label: attr('lannoitteidenMaara'), htmlId: 'lannoiteValmisteenValmistus-' + valmistusTyyppi + '-maara', summaryFn: valueSummary}
        ))
    })
  }
}

const createValmistusRadiot = (valmistustyyppi) => {
  return {
    valmistus: new FieldGroup(Field.build(attr('valmistus'), subTitleH5Summary), {
      lannoitteenValmistusOmaanKayttoon: createValmistusRadio('lannoiteValmisteenValmistus-' + valmistustyyppi + '-lannoitteenValmistusOmaanKayttoon', valmistusOmaKayttoRadioSummary),
      lannoitteenValmistusAinoastaanPakkaamista: createValmistusRadio('lannoiteValmisteenValmistus-' + valmistustyyppi + '-lannoitteenValmistusAinoastaanPakkaamista', valmistusVainPakkaamistaRadioSummary)
    })
  }
}

const createValmistusRadio = (htmlId, summaryFn) => new FieldControl(
  Field.of(
    {
      htmlId: htmlId,
      valueOptions: [
        new RadioValue("true", attr("kylla"), htmlId + '_yes'),
        new RadioValue("false", attr("ei"), htmlId + '_no'),
      ],
      summaryFn: summaryFn
    }));

const valmistusOmaKayttoRadioSummary = control => {
  const value = control.field.valueOptions.find(o => o.value === control.value);
  return new SummaryItem<any>(attr('lannoitteenValmistusOmaanKayttoon'), value?.label, {
    show: control.value,
    classList: ['pl-7']
  }, QuestionAndTranslatedAnswerSummaryComponent)
}

const valmistusVainPakkaamistaRadioSummary = control => {
  const value = control.field.valueOptions.find(o => o.value === control.value);
  return new SummaryItem<any>(attr('lannoitteenValmistusAinoastaanPakkaamista'), value?.label, {
    show: control.value,
    classList: ['pl-7'],
  }, QuestionAndTranslatedAnswerSummaryComponent)
}

const createVarastointi = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('lannoiteValmisteenVarastointi'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatKansallinen-lannoiteValmisteenVarastointi',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  orgaanisetLannoitteet: new FieldControl(Field.of({
    label: attr('orgaanisetLannoitteet'),
    htmlId: 'orgaanisetLannoitteet',
    summaryFn: varastointiCheckBoxSummary
  })),
  orgaanisetKivennaislannoitteet: new FieldControl(Field.of({
    label: attr('orgaanisetKivennaislannoitteet'),
    htmlId: 'orgaanisetKivennaislannoitteet',
    summaryFn: varastointiCheckBoxSummary
  })),
  epaorgaanisetLannoitteet: new FieldControl(Field.of({
    label: attr('epaorgaanisetLannoitteet'),
    htmlId: 'epaorgaanisetLannoitteet',
    summaryFn: varastointiCheckBoxSummary
  })),
  kalkitusaineet: new FieldControl(Field.of({
    label: attr('kalkitusaineet'),
    htmlId: 'kalkitusaineet',
    summaryFn: varastointiCheckBoxSummary
  })),
  orgaanisetMaanparannusaineet: new FieldControl(Field.of({
    label: attr('orgaanisetMaanparannusaineet'),
    htmlId: 'orgaanisetMaanparannusaineet',
    summaryFn: varastointiCheckBoxSummary
  })),
  epaorgaanisetMaanparannusaineet: new FieldControl(Field.of({
    label: attr('epaorgaanisetMaanparannusaineet'),
    htmlId: 'epaorgaanisetMaanparannusaineet',
    summaryFn: varastointiCheckBoxSummary
  })),
  kasvualustat: new FieldControl(Field.of({
    label: attr('kasvualustat'),
    htmlId: 'kasvualustat',
    summaryFn: varastointiCheckBoxSummary
  })),
  kasvibiostimulantit: new FieldControl(Field.of({
    label: attr('kasvibiostimulantit'),
    htmlId: 'kasvibiostimulantit',
    summaryFn: varastointiCheckBoxSummary
  })),
  lannoitevalmisteidenSeokset: new FieldControl(Field.of({
    label: attr('lannoitevalmisteidenSeokset'),
    htmlId: 'lannoitevalmisteidenSeokset',
    summaryFn: varastointiCheckBoxSummary
  }))
})

const subTitleH3SummaryByToimintotyyppi = (toimintotyyppi) => {
  return toimintotyyppi === 'muutos' ? subTitleH3WithBadgeSummary : subTitleH3Summary;
}

const varastointiCheckBoxSummary: SummaryFnType = control => {
  return new SummaryItem<any>(control.field.label, null, {show: control.value, classList: ['pl-5']});
}

const createTyyppinimi = () => new FieldGroup(
  Field.of({summaryFn: tyyppinimiSummary, htmlId: 'tyyppinimi'}), {
    tyyppiNimi: new FieldControl(Field.of({
      label: attr('kansillinenTaiEYtyyppinimitunnus'),
      htmlId: 'tyyppinimiDropdown'
    })),
    maara: new FieldControl(Field.of({
      label: attr('kansillinenTaiEYtyyppinimitunnus'),
      htmlId: 'kansallinenTaiEYtyyppinimitunnus'
    }))
  }
);

const tyyppinimiSummary: SummaryFnType = group => {
  const tyyppinimi = Tyyppinimet.getTyyppinimet()
    .find(tn => tn.value === group.get('tyyppiNimi').value);

  return new SummaryItem<any>(tyyppinimi?.label, group.get('maara').value, {
    show: group.get('maara').value,
    classList: ['pl-7'],
    valueSuffix: 'lannoiterekisteri.tnVuosi'
  });
}

const createAols = (aolId) => {
  return {
    ainesosaluokat: new FieldGroup(Field.build(attr('ainesosaluokatYhteenveto'), subTitleH5Summary), {
      aol1: new FieldControl(Field.of({
        label: attr('ensiomateriaalistaKoostuvatAineetJaSeokset'),
        htmlId: aolId + "-aol1",
        summaryFn: checkBoxSummary
      })),
      aol2: new FieldControl(Field.of({
        label: attr('kasvitKasvienOsatTaiKasviuutteet'),
        htmlId: aolId + "-aol2",
        summaryFn: checkBoxSummary
      })),
      aol3: new FieldControl(Field.of({label: attr('komposti'), htmlId: aolId + "-aol3", summaryFn: checkBoxSummary})),
      aol4: new FieldControl(Field.of({
        label: attr('tuorekavimadateOmaKaytto'),
        htmlId: aolId + "-aol4",
        summaryFn: checkBoxSummary
      })),
      aol5: new FieldControl(Field.of({
        label: attr('muuMadateKuinTuorekasvimadateOmaKaytto'),
        htmlId: aolId + "-aol5",
        summaryFn: checkBoxSummary
      })),
      aol6: new FieldControl(Field.of({
        label: attr('elintarviketeollisuudenSivutuotteetOmaKaytto'),
        htmlId: aolId + "-aol6",
        summaryFn: checkBoxSummary
      })),
      aol7: new FieldControl(Field.of({
        label: attr('mikroOrganismitOmaKaytto'),
        htmlId: aolId + "-aol7",
        summaryFn: checkBoxSummary
      })),
      aol8: new FieldControl(Field.of({
        label: attr('ravinnepolymeeritOmaKaytto'),
        htmlId: aolId + "-aol8",
        summaryFn: checkBoxSummary
      })),
      aol9: new FieldControl(Field.of({
        label: attr('muutPolymeeritOmaKaytto'),
        htmlId: aolId + "-aol9",
        summaryFn: checkBoxSummary
      })),
      aol10: new FieldControl(Field.of({
        label: attr('johdetutSivutuotteetOmaKaytto'),
        htmlId: aolId + "-aol10",
        summaryFn: checkBoxSummary
      })),
      aol11: new FieldControl(Field.of({
        label: attr('direktiivi2008/98/EYSivutuotteetOmaKaytto'),
        htmlId: aolId + "-aol11",
        summaryFn: checkBoxSummary
      }))
    })
  }
}
