<touko-lomake (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              *ngIf="lomake"
              [cancelFn]="cancelLomake"
              [formGroup]="lomake"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveFn]="saveLomake"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [tunnus]="lomakeMetaData.id">
  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        [virheGroups]="['laskutustiedot', 'perustiedot', 'yhteyshenkilot']" sivu="ref0"
        sivuvirhekasittely>
    <div *ngIf="!isPerustiedotValid" class="mb-4">
      <touko-alert-box>
        <div attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</div>
      </touko-alert-box>
    </div>
    <toiminnan-kohde formGroupName="luomutoiminnanKohde"></toiminnan-kohde>
    <perustiedot [lomake]="lomake"></perustiedot>
    <laskutustiedot [lomake]="lomake"></laskutustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"
                    [yhteyshenkiloViewOptions]="yhteyshenkiloViewOptions"></yhteyshenkilot>
    <fieldset class="col-md-5 col-12 px-0" formGroupName="toiminnanAloitus">
      <legend [attribute]="lomake.get('toiminnanAloitus').field.label"></legend>
      <date [label]="lomake.get('toiminnanAloitus.toiminnanAloitus').field.label"
            formControlName="toiminnanAloitus"
            htmlIdSuffix="toiminnanAloitus"></date>
    </fieldset>
    <validation-error [field]="lomake.get('toiminnanAloitus')"></validation-error>

    <fieldset class="mt-6 mb-2">
      <legend [attribute]="'lomakeYleinen.titleSahkAsiointi'"></legend>
      <p attribute="lomakeYleinen.infoSahkAsiointi"></p>
      <touko-checkbox checked="false"
                      class="strong"
                      formControlName="sahkoinenAsiointi"
                      htmlId="sahkoinenAsiointi"
                      labelAttribute="lomakeYleinen.checkSahkAsiointi">
      </touko-checkbox>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'luomu.liittymisenEhdot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu1']"
        [selectable]="isPerustiedotValid && isToiminnankohdeValid"
        [virheGroups]="['ehdot']"
        sivu="ref1"
        sivuvirhekasittely>
    <div>
      <h2 attribute="luomu.tuotantosuunnatEhtojenHyväksyminenOtsikko" class="mb-5"></h2>

      <fieldset class="mb-3" formGroupName="tuotantosuunnat">
        <legend attribute="luomu.tuotantosuunnat" class="h5">Valvontaan ilmoitettavat tuotantosuunnat ja toimialat
        </legend>
        <p attribute="luomu.tuotantosuuntaPeltokasvi" class="font-base" htmlId="tuotantosuunta-peltokasvi"></p>
      </fieldset>

      <fieldset *wipFeature class="mb-3" formGroupName="tuotantosuunnat">
        <legend attribute="luomu.tuotantosuunnat" class="h5">Valvontaan ilmoitettavat tuotantosuunnat ja toimialat
        </legend>
        <tuotantosuunta-checkbox *ngFor="let tuotantosuunta of tuotantosuunnatControls"
                                 [selectedTuotantosuuntaHasValue]="tuotantosuuntaHasValue(tuotantosuunta)"
                                 (tuotantosuuntaWithDataUnselected)="tuotantosuuntaUnselected($event)"
                                 [tuotantosuuntaControl]="tuotantosuunta.asControl()"></tuotantosuunta-checkbox>
      </fieldset>


      <fieldset>
        <legend attribute="luomu.liittymisenEhdot" class="h5 required"></legend>
        <touko-checkbox
            [formControl]="ehdot"
            [htmlId]="ehdot.field.htmlId"
            [labelAttribute]="ehdot.field.label"></touko-checkbox>

        <ul class="ml-2">
          <li *ngFor="let ehto of ehdotLista()"
              [attribute]="ehto"
              class="mb-4">
          </li>
        </ul>

        <validation-error [field]="lomake.get('ehdot')"></validation-error>
      </fieldset>
    </div>
  </sivu>

  <sivu [attribuuttiAvain]="'luomu.toiminta'" [ohjeet]="lomakeInfo.ohjeet['sivu2']"
        [selectable]="isPerustiedotValid && isToiminnankohdeValid && isTuotantosuuntaValid"
        [virheGroups]="['peltokasvinviljely.liitteet.luomusuunnitelma',
        'peltokasvinviljely.siirtymavaiheenLyhennys.hakemusSiirtymavaiheenLyhentamiseksi',
        'peltokasvinviljely.peltolohkojenTiedot',
        'elaintuotanto.elaintuotannonPoikkeuslupa.hakemusElaintuotannonPoikkeusluvasta',
        'elaintuotanto.tilanElaimet.mehilaispesa.sijaintiJaPitopaikat',
        'elaintuotanto.tilanElaimet.mehilaispesa.saastumislahteidenArviointi']"
        sivu="ref2"
        sivuvirhekasittely>
    <div *ngIf="tuotantosuunnat.get('peltokasvi').value" formGroupName="peltokasvinviljely">
      <h2 attribute="luomu.peltokasvienViljely"></h2>
      <kasvituotteet formGroupName="kasvituotteet"></kasvituotteet>
      <peltolohko-tiedot formArrayName="peltolohkojenTiedot"></peltolohko-tiedot>
      <siirtymavaiheen-lyhennys (onSetLiitteet)="onSetLiitteet($event, 'siirtymavaiheenLyhennys')"
                                [lomakeId]="lomakeMetaData.id"
                                formGroupName="siirtymavaiheenLyhennys"></siirtymavaiheen-lyhennys>
      <luomuvalvonta-aloitus-liitteet formGroupName="liitteet"></luomuvalvonta-aloitus-liitteet>
    </div>
    <ng-container *wipFeature>
      <elaintuotanto *ngIf="tuotantosuunnat.get('elaintuotanto').value" formGroupName="elaintuotanto"></elaintuotanto>
    </ng-container>
  </sivu>

  <sivu [attribuuttiAvain]="'teksti.sivuYhteenveto'"
        [selectable]="isPerustiedotValid && isToiminnankohdeValid && isTuotantosuuntaValid">
    <app-field-group-summary [fieldGroup]="lomake" class="summary"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button attribute="teksti.laheta" class="mt-4 btn btn-primary" id="laheta" type="submit">Lähetä</button>
    </div>
  </sivu>
</touko-lomake>

<pre *devFeature class="small">
  {{ lomake?.getRawValue() | json }}
</pre>
