const cols1 = 'col-md-6 offset-right-md-6';
const colMd6 = 'col-md-6';
const colMd3 = 'col-md-3';

export class ToukoLaheteStyles {
  static styles = {
    '*.henkilotiedot.elainlaakaritunnus': cols1,
    '*.henkilotiedot.etunimi': colMd6,
    '*.henkilotiedot.sukunimi': colMd6,
    '*.henkilotiedot.yritys': colMd6,
    '*.henkilotiedot.ytunnus': colMd6,
    '*.henkilotiedot.puhelin': colMd6,
    '*.henkilotiedot.email': colMd6,
    '*.henkilotiedot.kotimaanOsoite': cols1,
    '*.henkilotiedot.osoite': colMd6,
    '*.henkilotiedot.postinumero': colMd3,
    '*.henkilotiedot.postitoimipaikka': colMd3,

    '*.lisatiedot.toimitusEmail': cols1,
    '*.lisatiedot.maksaja': cols1,
    '*.lisatiedot.toimitusPosti': cols1,

    '*.yhteyshenkilo.yhteyshenkilo': cols1,
    '*.yhteyshenkilo.etunimi': colMd6,
    '*.yhteyshenkilo.sukunimi': colMd6,
    '*.yhteyshenkilo.puhelin': colMd6,
    '*.yhteyshenkilo.email': colMd6,

    'laskutusosoite.etunimi': colMd6,
    'laskutusosoite.sukunimi': colMd6,
    'laskutusosoite.yritys': colMd6,
    'laskutusosoite.ytunnus': colMd6,
    'laskutusosoite.kotimaanOsoite': cols1,
    'laskutusosoite.osoite': colMd6,
    'laskutusosoite.postinumero': colMd3,
    'laskutusosoite.postitoimipaikka': colMd3,

    'tutkimusalat.?': 'ml-1'
  };
}











