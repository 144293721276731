import {AbstractAsiakasGroup, TilaajaValue} from "./abstract-asiakas-group";
import {InitValueType, QuestionGroup, QuestionInitValue} from "ee-lahete";
import {GroupBuilder} from "../group-build-options";

export class ElainlaakariGroup extends AbstractAsiakasGroup {

  questionGroupKey = 'elainlaakari';

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'henkilo'})): QuestionGroup {
    const htmlIdSuffix = 'Elainlaakari';
    const henkilotiedotGroup = this.createHenkilotietoGroup(htmlIdSuffix);
    henkilotiedotGroup.controls.unshift(this.createElainlaakariInput(htmlIdSuffix));

    const lisatiedotGroup = this.createLisatietoGroup(htmlIdSuffix);
    const maksajaControl = lisatiedotGroup.find(['maksaja']);
    maksajaControl.modifiers = ElainlaakariGroup.createTilaajaTyyppiModifierNotEquals(TilaajaValue.ELAINLAAKARI);

    const yhteyshenkiloControls = this.createYhteyshenkiloControls(`Yhteyshenkilo${htmlIdSuffix}`);
    const yhteyshenkiloGroup = new QuestionGroup({
      key: 'yhteyshenkilo',
      groupComponentType: 'nested',
      label: this.getLabel('yhteyshenkilo'),
      controls: yhteyshenkiloControls,
      filters: ElainlaakariGroup.createTilaajaTyyppiFilterNotEquals(TilaajaValue.ELAINLAAKARI)
    });

    const group = new QuestionGroup({
      key: this.questionGroupKey,
      groupComponentType: 'nested',
      label: this.getLabel('asiakasElainlaakari'),
      groups: [
        henkilotiedotGroup,
        yhteyshenkiloGroup,
        lisatiedotGroup
      ],
      filters: ElainlaakariGroup.createTilaajaTyyppiFilterNotEquals(TilaajaValue.ELAINLAAKARI),
      options: {
        hideInSummary: val => ElainlaakariGroup.everyHenkilotietoValueFalse(val.henkilotiedot)
      }
    });
    this.applyPostitoimipaikkaDisabler(henkilotiedotGroup);

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.elainlaakari), InitValueType.PATCH));
    }

    return group;
  }
}
