import {Component} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {FieldGroup} from "ngx-fielding";

@Component({
  selector: 'elaintuotanto',
  template: `
    <div [formGroup]="elaintuotantoGroup">
      <h2 attribute="luomu.elainTuotanto"></h2>
      <tilan-elaimet formGroupName="tilanElaimet"></tilan-elaimet>
      <elaintuotteet formGroupName="elaintuotteet"></elaintuotteet>
      <elaintuotannon-poikkeuslupa formGroupName="elaintuotannonPoikkeuslupa"></elaintuotannon-poikkeuslupa>
      <luomuvalvonta-aloitus-liitteet formGroupName="liitteet"></luomuvalvonta-aloitus-liitteet>
    </div>
  `
})

export class ElaintuotantoComponent {
  constructor(private controlContainer: ControlContainer) {
  }

  get elaintuotantoGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }
}
