import {AbstractAsiakasGroup} from "./abstract-asiakas-group";
import {
  InitValueType,
  QuestionArray,
  QuestionGroup,
  QuestionInitValue,
  QuestionUpdate,
  QuestionUpdateType
} from "ee-lahete";
import {GroupBuilder} from "../group-build-options";

export class TiedoksisaajaGroup extends AbstractAsiakasGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'tiedoksisaaja'})): QuestionGroup {
    let initialTiedoksisaajat: QuestionGroup[];
    const tiedoksisaajatValue = builder.initValueByPath(['tiedoksisaaja', 'tiedoksisaajat'], []);
    if (builder.applyValues && tiedoksisaajatValue.length > 0) {
      initialTiedoksisaajat = this.mapTiedoksisaajat(tiedoksisaajatValue);
    } else {
      initialTiedoksisaajat = [this.createSingleTiedoksisaajaGroup('0', 'Tiedoksisaaja0')];
    }

    const group = this.getTiedoksisaajaGroup(initialTiedoksisaajat);

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.tiedoksisaaja), InitValueType.PATCH));
    }

    return group;
  }

  getSummaryGroup(controlType: string, initalValues): QuestionGroup {
    const tiedoksisaajat = this.mapTiedoksisaajat(initalValues.tiedoksisaaja?.tiedoksisaajat || []);
    return this.getTiedoksisaajaGroup(tiedoksisaajat);
  }

  mapTiedoksisaajat(tiedoksisaajat) {
    return tiedoksisaajat
      .map((value, index) => {
        return this.createSingleTiedoksisaajaGroup(index.toString(), `Tiedoksisaaja${index}`);
      });
  }

  private getTiedoksisaajaGroup(tiedoksisaajat: QuestionGroup[] = []) {
    return new QuestionGroup({
      key: 'tiedoksisaaja',
      label: this.getLabel('asiakasTiedoksisaaja'),
      array: new QuestionArray({
        key: 'tiedoksisaajat',
        controlType: 'nested-group',
        questions: tiedoksisaajat,
      }),
      actions: {
        addNewTiedoksisaaja: this.addNewTiedoksisaaja.bind(this)
      },
      filters: TiedoksisaajaGroup.createTilaajaTyyppiFilterAnyValueTrue()
    });
  }

  private addNewTiedoksisaaja(arrayIndex: number) {
    const indxAsString = arrayIndex.toString();
    const tiedoksisaajaGroup = this.createSingleTiedoksisaajaGroup(indxAsString, `Tiedoksisaaja${indxAsString}`);
    this.laheteService.updateQuestion(new QuestionUpdate(
      'tiedoksisaaja.tiedoksisaajat',
      indxAsString,
      QuestionUpdateType.ADD,
      tiedoksisaajaGroup,
      this.createFormGroupFromQuestionGroup(tiedoksisaajaGroup)
    ));
  }

  private createSingleTiedoksisaajaGroup(indexAsString: string, htmlIdSuffix: string): QuestionGroup {

    const henkilotietoGroup = this.createHenkilotietoGroup(htmlIdSuffix);

    const lisatietoGroup = this.createLisatietoGroup(htmlIdSuffix);

    const group = new QuestionGroup({
      key: indexAsString,
      groupComponentType: 'group-nested',
      label: `${this.getLabel('asiakasTiedoksisaaja')} #${parseInt(indexAsString, 10) + 1}`,
      groups: [
        henkilotietoGroup,
        lisatietoGroup
      ],
      options: {
        hideInSummary: val => TiedoksisaajaGroup.everyHenkilotietoValueFalse(val.henkilotiedot)
      }
    });

    const groupRoot = `tiedoksisaaja.tiedoksisaajat.${indexAsString}`;

    const disablerKey = `${groupRoot}.henkilotiedot.kotimaanOsoite`;
    const postitoimipaikkaControl = henkilotietoGroup.find(['postitoimipaikka']);
    this.applyPostitoimipaikkaDisablerMatcher(postitoimipaikkaControl, disablerKey);

    return group;
  }

}
