import {Component, forwardRef, Input} from "@angular/core";
import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "touko-number-input",
  template: `
    <div class="form-group mb-0" [ngClass]="inputStyle === 'inline' ? 'd-flex align-items-center justify-content-between' : 'form-group'">
      <label [ngClass]="{'mb-0': inputStyle === 'inline'}" [htmlFor]="htmlId" [attribute]="labelAttribute"></label>
      <div [ngClass]="{'d-flex align-items-center': inputStyle === 'inline'}">
        <input class="form-control mb-0" [ngClass]="{'inline-input' : inputStyle === 'inline', 'small-input' : inputWidth === 'small'}" type="number" [id]="htmlId" [attr.aria-describedby]="htmlId + '-description'" [value]="value" [disabled]="disabled" oninput="this.value=this.value.slice(0,this.maxLength)" maxlength="10">
        <span [id]="htmlId + '-description'" *ngIf="descriptionAttribute" [attribute]="descriptionAttribute" class="d-inline-block whitespace-nowrap" [ngClass]="{'mt-1': inputStyle !== 'inline'}"></span>
      </div>
    </div>
  `
  ,
  styles: [
    `
    .inline-input {
      margin: 0 1rem;
    }
    .small-input {
      max-width: 8rem;
    }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberInputComponent),
    }
  ]
})
export class NumberInputComponent extends DefaultValueAccessor {

  @Input() value: string;
  @Input() disabled: boolean;
  @Input() htmlId: string;
  @Input() labelAttribute: string;
  @Input() descriptionAttribute: string;
  @Input() inputStyle: string;
  @Input() inputWidth: string;

  writeValue(value: any) {
    super.writeValue(value);
    this.value = value;
  }

  setDisabledState(disabled: boolean) {
    super.setDisabledState(disabled);
    this.disabled = disabled;
  }
}
