/**
 * Created by Seppo on 24/07/2018.
 */
import {Component, Input} from "@angular/core";
import {LomakeKasittely} from "../touko-lomake-utils";

@Component({
  selector: 'lomaketiedot',
  template: `
    <div class="row" *ngIf="data">
      <div class="col yhteenveto-list">
        <ul class="list-group list-group-flush">
          <li class="list-group-item pt-0">
            <span attribute="etusivu.tila">Tila </span>
            <tila-badge [lomakeTila]="data.tila" class="ml-1"></tila-badge>
          </li>
          <li class="list-group-item" *ngIf="data.kasittelija">
            <span attribute="etusivu.kasittelija">Käsittelijä </span><span class="ml-1 user-provided-info">{{data.kasittelija}}</span>
          </li>
          <li class="list-group-item" *ngIf="data.lisatieto">
            <span attribute="etusivu.lisatiedot">Lisätiedot </span><span class="ml-1 user-provided-info">{{data.lisatieto}}</span>
          </li>
        </ul>
      </div>
    </div>`,
  styleUrls: ['yhteenveto.component.scss']
})
export class LomaketiedotComponent {
  @Input() data: LomakeKasittely;
}
