import {Component, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {LomakeBaseDirective, LomakeStatus} from "../touko-lomake-base";
import {LomakeService} from "../touko-lomake.service";
import {MessageService} from "../../message/message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Teksti} from "../../utils/teksti";
import {LomakeConverter, LomakeResponse, YhteyshenkiloValue} from "../touko-lomake-utils";
import {AccountService} from "../../account/account.service";
import {YhteenvetoService} from "../yhteenveto/yhteenveto.service";
import {LomakeBaseService} from "../lomake-base.service";
import {SiemenpakkaamoData} from "./siemenpakkaamo-data";
import {Subscription} from "rxjs";
import {DateObj} from "../syote/syote-utils";
import {ToukoValidation} from "../validators/validators";
import {Account, OsoiteTiedot} from "../../account/account";
import {SyoteInterface, SyoteType} from "../syote/syote-interface";
import {YhteyshenkiloViewOptions} from "../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

interface ShowInputInfo {
  show: boolean;
  type: string;
  inputGroup: string;
}

@Component({
  selector: 'app-siemenpakkaamo',
  templateUrl: './siemenpakkaamo.component.html',
  styleUrls: ['./siemenpakkaamo.component.scss']
})
@AutoUnsubscribe
export class SiemenpakkaamoComponent extends LomakeBaseDirective implements OnInit {
  id: number;
  lomakeTitle: Teksti = new Teksti(
      "Uusi siemenpakkaamolupahakemus siemenpakkaamotoimintaa varten",
      "siemenPakkaamoHakemusOtsikko",
      "siemenpakkaamo");
  lomakeAsia: string;
  ohjeet: { [s: string]: string[] } = {};
  yhteyshenkiloRoolit = [YhteyshenkiloValue.SIEM_PAKK];
  yhteyshenkiloViewOptions = new YhteyshenkiloViewOptions(true, true, true, false);
  showInput: { [s: string]: ShowInputInfo } = {};
  subs: Subscription[] = [];
  painatusValinta: string;
  isUusiHakemus: boolean;
  defaultYhteyshenkiloNimi: string;
  lomakeTyyppiVersio = 'v2';

  constructor(lomakeService: LomakeBaseService,
              messageService: MessageService,
              router: Router,
              accountService: AccountService,
              activatedRoute: ActivatedRoute,
              yhteenvetoService: YhteenvetoService
  ) {
    super(lomakeService as LomakeService, messageService, router, activatedRoute, accountService, yhteenvetoService);
    this.lomakeAsia = "siemenpakkaamolupa";
    this.lomakeData = SiemenpakkaamoData.getData(accountService.getCurrentAccount(), this.toimintotyyppi);
    this.defaultYhteyshenkiloNimi = Account.getReversedWholename(accountService.getCurrentAccount());

    const prefix = 'siemenpakkaamo.ohje';
    this.ohjeet.sivu0 = this.getOhjeList(prefix, ['1_1']);
    this.ohjeet.sivu1 = this.getOhjeList(prefix, ['2_1']);
    this.ohjeet.sivu2 = this.getOhjeList(prefix, ['3_1']);

    this.canSubmitLomake = this.canSubmitLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.saveLomake = this.saveLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.isUusiHakemus = this.router.url.includes('hakemus');
  }

  createMultipleChoiceFields(inputGroupName: string) {
    const syoteGroup = this.lomakeData[inputGroupName].dataObject;
    const valueChange = this.lomakeValues.get([inputGroupName]).valueChanges.subscribe(inputGroup => {
      const inputGroupValue = (this.lomakeValues.get([inputGroupName]) as FormGroup).getRawValue();
      const values = {};
      Object.entries(inputGroupValue).forEach(([key]) => {
        const syote = syoteGroup[key];
        if (syote.options?.parent) {
          values[syote.name] = {
            show: inputGroup[syote.options.parent],
            type: syote.options.type,
            inputGroup: inputGroupName
          };
        }
      });
      this.showInput = {...this.showInput, ...values};
    });
    this.subs.push(valueChange);
  }

  protected sendLomake(status: LomakeStatus): Promise<LomakeResponse> {
    const isViranomainen = !this.accountService.isAsiakas();
    const inputGroupValue = (this.lomakeValues.get(['perustiedot']) as FormGroup).getRawValue();
    this.perustiedot['vatnumero'] = inputGroupValue['vatnumero'];
    const requestBody = this.lomakeService.createLomakeRequest(this.convertLomakeToJSON(), this.liitetiedostot, this.perustiedot, this.yhteyshenkilot);
    if (status === LomakeStatus.KESKEN || isViranomainen) {
      return this.lomakeService.tallennaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    } else {
      return this.lomakeService.lahetaLomake(requestBody, this.toimintotyyppi, this.lomakeAsia, this.id);
    }
  }

  /**
   * Tallentaa lomakkeen
   */
  saveLomake(notifyUser = true) {
    return this.sendLomake(LomakeStatus.KESKEN)
        .then(r => {
          if (notifyUser) {
            this.messageService.notify({
              message: new Teksti("Lomake tallennettu.", "lomakeTallennettu", "lomakeYleinen"),
              type: "success"
            });
          }
          this.id = r.id;
          this.activatedRoute.params.subscribe(params => {
            const page = params['page'] ? params['page'] : 0;
            this.router.navigate(['/asiointi/lomake', this.toimintotyyppi, r.asia, 'v1', r.id, 'jatka', 'sivu', page]);
          });

        })
        .catch(e => {
          console.error("SaveLomake ei onnistunut", e);
          this.messageService.notify({
            message: new Teksti("Lomakkeen tallennus epäonnistui", "tallennusEpaonnistui", "lomakeYleinen"),
            type: "danger"
          });
        });
  }

  createDisabledFields(inputGroupName: string) {
    const syoteGroupData = this.lomakeData[inputGroupName].data;
    syoteGroupData.forEach(syote => {
      this.subscribeInputValueChanges(syote, inputGroupName);
      syote.children.forEach(childItem => this.subscribeInputValueChanges(childItem, inputGroupName));
    });
  }

  subscribeInputValueChanges(syote: SyoteInterface, inputGroupName: string) {
    if (syote.type === SyoteType.ARRAY) {
      syote.children.forEach((childItem, index) => {
        if (childItem.checkbox) {
          this.applyInputSubscription(childItem.checkbox, [inputGroupName, syote.name, index]);
        }
        if (childItem.date) {
          this.applyInputSubscription(childItem.date, [inputGroupName, syote.name, index]);
        }
      });
    } else if (syote.type === SyoteType.CONTROL) {
      this.applyInputSubscription(syote, [inputGroupName]);
    }
  }

  applyInputSubscription(syote: SyoteInterface, inputPath: string[]) {
    const changesToListen = this.lomakeValues.get([...inputPath, syote?.options?.enableBy]);
    const fieldToEnable = this.lomakeValues.get([...inputPath, syote.name]);

    if (syote?.options?.enableBy) {
      fieldToEnable.disable();
      changesToListen.valueChanges.subscribe((value) => {
        if (value) {
          fieldToEnable.enable();
        } else {
          fieldToEnable.disable();
        }
      });
    }
  }

  painatusRadioButtonHandler() {
    this.lomakeValues.get(['painatus']).valueChanges.subscribe(value => {
      this.painatusValinta = value['radio-group'];
    });
  }

  updateYhteenvetoValues() {
    this.yhteenvetoValues = LomakeConverter.convertToJSON(this.lomakeAsia, this.getSanitizedValues());
    this.yhteenvetoService.updateLomakeArvot(this.yhteenvetoValues);
  }

  getSanitizedValues(): any {
    const sanitizedLomake = JSON.parse(JSON.stringify(this.lomakeValues?.value));
    sanitizedLomake.perustiedot = this.lomakeValues.getRawValue().perustiedot;
    sanitizedLomake.laskutustiedot = this.lomakeValues.getRawValue().laskutustiedot;
    const showIsFalsy = (showInputValue: ShowInputInfo): boolean => !showInputValue.show;
    const lomakeHasValue = (key: string, showInputValue: ShowInputInfo): boolean => sanitizedLomake[showInputValue.inputGroup][key];

    Object.entries(this.showInput).forEach(([key, value]) => {
      if (showIsFalsy(value) && lomakeHasValue(key, value) && value.type !== 'array') {
        sanitizedLomake[value.inputGroup][key] = null;
      } else if (showIsFalsy(value) && lomakeHasValue(key, value) && value.type === 'array') {
        delete sanitizedLomake[value.inputGroup][key];
      }
    });
    return sanitizedLomake;
  }

  convertLomakeToJSON() {
    return {title: this.lomakeAsia, data: this.getSanitizedValues()};
  }

  checkForLaskutustiedot() {
    this.lomakeValues.get('laskutustiedot').setValidators(ToukoValidation.isLaskutustiedotNotEmpty);
  }

  autofillLaskutustiedot(event?: any) {
    const laskutusosoiteCheckbox = event.target.checked;
    const osoiteValues = this.lomakeValues.getRawValue().perustiedot.postiosoite;
    if (laskutusosoiteCheckbox) {
      this.lomakeValues.get(['laskutustiedot']).patchValue({laskutusosoite: new OsoiteTiedot(osoiteValues.osoite, osoiteValues.postinumero, osoiteValues.postitoimipaikka)});
      this.lomakeValues.get(['laskutustiedot']).disable();
    } else {
      this.lomakeValues.get(['laskutustiedot']).enable();
    }
  }

  createGroupWithDate() {
    return new FormGroup({
      date: new FormControl(DateObj.firstDayOfMonth())
    });
  }

  createGroupWithCheckboxAndDate() {
    const checkbox = new FormControl();
    const date = new FormControl({value: DateObj.firstDayOfMonth(), disabled: true});

    const uusiLaite = new FormGroup({
      checkbox,
      date
    });

    checkbox.valueChanges.subscribe(value => {
      if (value) {
        date.enable();
      } else {
        date.disable();
      }
    });
    return uusiLaite;
  }

  addLisalaite(laitenimi: string): void {
    const laitteet = this.lomakeValues.get(['koneetJaLaitteet', laitenimi]) as FormArray;
    const uusiLaite = laitenimi === 'vaakaArray' ? this.createGroupWithDate() : this.createGroupWithCheckboxAndDate();

    const laitteetModel = this.getLaite(laitenimi);
    const child = laitteetModel.children[0];

    // @ts-ignore
    laitteetModel.children.push(child);
    laitteet.push(uusiLaite);
  }

  removeLisalaite(laitenimi, index) {
    const laitteetModel = this.getLaite(laitenimi);
    laitteetModel.children.splice(index, 1);

    const laitteet = this.lomakeValues.get(['koneetJaLaitteet', laitenimi]) as FormArray;
    laitteet.removeAt(index);
  }

  getLaite(laitenimi: string) {
    return this.lomakeData['koneetJaLaitteet'].dataObject[laitenimi];
  }

  updateLomakeModel(values) {
    this.updateLomakeModelArray(values, ['koneetJaLaitteet', 'naytteenotinArray'], this.addLisalaite.bind(this));
    this.updateLomakeModelArray(values, ['koneetJaLaitteet', 'vakuustulostinArray'], this.addLisalaite.bind(this));
    this.updateLomakeModelArray(values, ['koneetJaLaitteet', 'vaakaArray'], this.addLisalaite.bind(this));
  }

  updateLomakeModelArray(values, path, updateFn) {
    const [groupKey, arrayKey] = [...path];
    const arrayValues = values?.[groupKey]?.[arrayKey] || [];
    arrayValues.forEach((ignore, i) => {
      if (i > 0) {
        updateFn(arrayKey);
      }
    });
  }

  afterInit() {
    super.afterInit();
    this.createMultipleChoiceFields('lisatiedot');
    this.createMultipleChoiceFields('koneetJaLaitteet');
    this.createDisabledFields('koneetJaLaitteet');
    this.createDisabledFields('lisatiedot');
    this.checkForLaskutustiedot();
    this.painatusRadioButtonHandler();
  }

}
