
/**
 * Valvontakohteen tiedot.
 */
export class Osoite {
  osoite: string;
  postinumero: string;
  postitoimipaikka: string;
  kunta: string;
  valtio: string;
  changed: boolean;
}

export class AsiakasOsoite {
  elmoid: string;
  status: string;
  osoitetyyppi_elmoid: string;
  kielikoodi: string;
  osoite: string;
  postinumero: string;
  maa: string;
  ulkomainenpostinumero: string;
  ulkomainenpostitoimipaikka: string;

  static compareAsiakasOsoite(osoite1: AsiakasOsoite, osoite2: AsiakasOsoite): number {

    // jos molemmat on tyhjiä
    if (osoite1 === osoite2) {
      return 0;
    }

    if (osoite1 === null || !(osoite1.osoitetyyppi_elmoid in AsiakasOsoite.AsiakasOsoiteJarjestys)) {
      return 1;
    }

    if (osoite2 === null || !(osoite2.osoitetyyppi_elmoid in AsiakasOsoite.AsiakasOsoiteJarjestys)) {
      return -1;
    }

    return AsiakasOsoite.AsiakasOsoiteJarjestys[osoite1.osoitetyyppi_elmoid] - AsiakasOsoite.AsiakasOsoiteJarjestys[osoite2.osoitetyyppi_elmoid];
  }

  static AsiakasOsoiteJarjestys: {[key: string]: number} = {
    CustAddType1: 2,
    CustAddType2: 4,
    CustAddType3: 1,
    CustAddType4: 3,
    CustAddType5: 5,
    CustAddType6: 6,
    CustAddType7: 7,
    CustAddType8: 8,
    CustAddType9: 10,
    CustAddType10: 9,
  };
}

export class Asiakas {
  elmoid: string;
  muokkauspvm: string;
  status: string;
  sovel_sovellus: string;
  tja_toimijatunnus: number;
  ytunnus: string;
  kielikoodi: string;
  sahkoposti: string;
  hakunimi: string;
  internetosoite: string;
  ovtid: string;
  chnid: string;
  toimijakoodi: string;
  nimi: string;
  hetu: string;
  asiakkaannimet: [AsiakkaanNimet];

  // accountComponentti käyttää yhteyshenkilöä, ei mitään hajua tyypeistä yms.
  yhteyshenkilot: any;
  asiakasosoitteet: [AsiakasOsoite];

  static haeEnsisijainenOsoite(asiakas: Asiakas) {
    if (!asiakas || !asiakas.asiakasosoitteet || asiakas.asiakasosoitteet.length <= 0) {
      return null;
    }

    const asiakasosoite = asiakas.asiakasosoitteet.sort(AsiakasOsoite.compareAsiakasOsoite)[0];

    return {
      osoite: asiakasosoite.osoite,
      postinumero: asiakasosoite.postinumero,
      postitoimipaikka: ""
    };
  }
}

export class AsiakkaanNimet {
  nimi: string;
}

export class Toimipaikka {
  elmoid: string;
  muokkauspvm: string;
  luontipvm: string;
  status: string;
  sovel_sovellus: string;
  nimi_fi: string;
  nimi_sv: string;
  nimi_en: string;
  osoite: string;
  postinumero: string;
  kieli: string;
  postitoimipaikka: string;
}

export class Valvontakohde {
  alakohteet: Array<any>;
  elmoId: string;
  toimipaikkaElmoid: string;
  toimipaikkaSovellus: string;
  asiakasElmoid: string;
  asiakasSovellus: string;
  toimipaikka: Toimipaikka;
  asiakas: Asiakas;
  pakkaamoElmoid: string;
  toimialat: string[];
  valvontakohdeDetails: ValvontakohdeDetails[];
  hasUnreadAsiakirja?: boolean;
  tilatunnus?: string;
  rekisterinumero?: string;
  kasvinterveysRekisteritiedot?: KasvinterveysRekisteritiedot;
}

export interface LyhytnimiSelite {
  lyhytNimi: string;
  selite: string;
}

export interface Vientivalmius {
  rekisteri: string;
  voimassaolo: Date;
}

export interface KasvinterveysRekisteritiedot {
  rekisterinumero: string;
  rekisterinumeroVisible: boolean;
  rekisterit: Array<any>;
}

export class ValvontakohdeDetails {
  toimiala: string;
  toiminnot: LyhytnimiSelite[];
  tuotetyypit: LyhytnimiSelite[];
  hyvaksynnat: Hyvaksynta[];
  vientivalmiudet: Vientivalmius[];
}

export class Hyvaksynta {
  numero: string;
  tyyppi: string;
  naytaNumero: boolean;
  toiminnot: LyhytnimiSelite[];
}
