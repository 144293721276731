<!-- view -->
<p *ngIf="yhteenvetoItem.title">{{yhteenvetoItem.title}}</p>
<ng-container *ngIf="yhteenvetoItem.label || yhteenvetoItem.value">
  <ng-container [ngSwitch]="yhteenvetoItem.yhteenvetoTyyppi">
    <ng-container *ngSwitchCase="yhteenvetoItem.YhteenvetoTyyppi.LABEL_PREFORMATTED">
      <strong>Toimitusosoite</strong>
      <pre id="yhteenvetoValue-toimitusosoite-{{groupPrefix}}" class="small">{{yhteenvetoItem.label}}</pre>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span *ngIf="yhteenvetoItem.label">
            <strong class="mr-2">{{yhteenvetoItem.label}}</strong>
      </span>
      <span *ngIf="yhteenvetoItem.value"
            [id]="groupPrefix + yhteenvetoItem.htmlIdSuffix">{{yhteenvetoItem.value}}</span>
    </ng-container>
  </ng-container>
</ng-container>

<!-- children -->
<ul *ngIf="yhteenvetoItem.children.length > 0">
  <li *ngFor="let item of yhteenvetoItem.children">
    <app-lahete-yhteenveto-group groupPrefix="{{groupPrefix}}" [yhteenvetoItem]="item"></app-lahete-yhteenveto-group>
  </li>
</ul>
