import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AbstractControl, ControlContainer} from "@angular/forms";
import {Perustiedot} from "../syote-utils";
import {Subscription} from "rxjs/internal/Subscription";
import {Teksti} from "../../../utils/teksti";
import {OsoiteTiedot} from "../../../account/account";
import {AccountService} from '../../../account/account.service';
import {SyoteInterface} from "../syote-interface";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";

const _ = require('lodash');

/**
 * Peruslohkokomponentti
 */
@Component({
  selector: 'touko-perustiedot',
  template: `
    <div [formGroup]="controlContainer.control">
      <div class="form-row mb-3">
        <div class="col-md-6" required>
          <label [attribute]="isMaatila() ? 'lomakeYleinen.viljelijanNimi' : 'lomakeYleinen.toimipaikanNimi' " for="pt-osasto">Toimipaikan nimi</label>
          <touko-osasto *ngIf="!isMaatila()" formControlName="osasto" [paatoiminimi]="paatoiminimi"
                        [osastoNimiLock]="osastoNimiLock" [osastoReadonly]="osastoReadonly">
          </touko-osasto>
          <input *ngIf="isMaatila()" class="form-control" formControlName="osasto" id="pt-osasto" />
        </div>
        <div *ngIf="!isMaatila()" class="col-md-3">
          <label for="pt-yritys" attribute="kayttajatili.paatoiminimi">Päätoiminimi</label>
          <input id="pt-yritys" class="form-control" formControlName="yritys"/>
        </div>
        <div class="col-md-3">
          <label for="pt-ytunnus" [attribute]="isMaatila() ? 'kayttajatili.maatilatunnus' : 'kayttajatili.ytunnus'  ">Y-tunnus</label>
          <input *ngIf="!isMaatila()" id="pt-ytunnus" class="form-control" formControlName="ytunnus"/>
          <input *ngIf="isMaatila()" id="pt-maatilatunnus" class="form-control" formControlName="maatilatunnus"/>
        </div>
      </div>
      <!-- POSTIOSOITE -->
      <touko-osoite id="pt-posti" required (keyup)="setInitializingToFalse()" class="form-row mb-3" formControlName="postiosoite"
                    [osoiteTeksti]="formFieldsLabels['postiosoite']"
                    [readonly]="osoitteetReadonly"></touko-osoite>

      <!-- KÄYNTIOSOITE  attribute="lomakeYleinen.checkEriKayntiosoite" -->
      <div *ngIf="!osoitteetReadonly" class="form-row mb-3">
        <div class="col-12">
          <touko-checkbox htmlId="postiosoiteCheck" [checked]="isEqualKayntiOsoite" labelAttribute="lomakeYleinen.checkSamaKayntiosoite" (change)="toggleCheckbox()" class="col-sm-12"></touko-checkbox>
        </div>
      </div>
      <touko-osoite id="pt-kaynti" required *ngIf="!isEqualKayntiOsoite" [osoiteTeksti]="formFieldsLabels['kayntiosoite']"
                    class="form-row mb-3" formControlName="kayntiosoite"
                    [readonly]="osoitteetReadonly">
      </touko-osoite>
    </div>
  `
})
@AutoUnsubscribe
export class PeruslohkoComponent implements OnInit {

  @Input() paatoiminimi;
  @Input() formFields: SyoteInterface[];
  @Input() osastoNimiLock = false;
  @Input() osoitteetReadonly = false;
  @Input() osastoReadonly = false;
  @Output() perustietoUpdate: EventEmitter<Perustiedot> = new EventEmitter();

  formFieldsLabels: {[val: string]: Teksti} = {};

  perustiedot = new Perustiedot();
  valueSubscription: Subscription;
  osoiteSubscription: Subscription;
  postiosoiteControl: AbstractControl;
  kayntiosoiteControl: AbstractControl;
  initializing = true;
  isEqualKayntiOsoite = false;

  constructor(public controlContainer: ControlContainer, protected accountService: AccountService) {
  }

  ngOnInit(): void {
    this.init();
  }

  protected init() {
    this.formFields.forEach(field => {
      this.formFieldsLabels[field.name] = field.label;
    });

    this.valueSubscription = this.controlContainer.valueChanges.subscribe(values => {
      if (values) {
        this.perustietoUpdate.emit(values);
        if (this.initializing && values.kayntiosoite?.osoite && values.postiosoite?.osoite) {
          const kaynti = this.osoiteAsObject(values.kayntiosoite);
          const posti = this.osoiteAsObject(values.postiosoite);
          this.isEqualKayntiOsoite = _.isEqual(kaynti, posti);
          this.initializing = false;
        }
      }
    });

    this.postiosoiteControl = this.controlContainer.control.get('postiosoite');
    this.kayntiosoiteControl = this.controlContainer.control.get('kayntiosoite');

    this.osoiteSubscription = this.postiosoiteControl.valueChanges.subscribe(value => {
      if (this.isEqualKayntiOsoite && !this.initializing) {
        this.kayntiosoiteControl.setValue(new OsoiteTiedot(value.osoite, value.postinumero, value.postitoimipaikka));
      }
    });
  }

  toggleCheckbox() {
    this.isEqualKayntiOsoite = !this.isEqualKayntiOsoite;
    this.initializing = false;
    if (this.isEqualKayntiOsoite) {
      const value = this.postiosoiteControl.value;
      if (value) {
        this.kayntiosoiteControl.setValue(new OsoiteTiedot(value.osoite, value.postinumero, value.postitoimipaikka, value.maaKoodi));
      }
    }
  }

  osoiteAsObject(data: OsoiteTiedot | {osoite: string; postinumero: string; postitoimipaikka: string; maaKoodi: string}): OsoiteTiedot {
    return {osoite: data.osoite, postinumero: data.postinumero, postitoimipaikka: data.postitoimipaikka, maaKoodi: data.maaKoodi};
  }

  setInitializingToFalse() {
    this.initializing = false;
  }

  isMaatila(): boolean {
    return this.accountService.isMaatilaToimija();
  }

}
