import {Component, Input} from '@angular/core';
import {LaheteYhteenvetoItem} from "./lahete-yhteenveto";
import {Translations} from "../lahete/translations";
import {LaheteLanguage, LaheteService} from "../lahete/lahete.service";
@Component({
  selector: 'lahete-yhteenveto',
  templateUrl: './lahete-yhteenveto.component.html',
  styleUrls: ['./lahete-yhteenveto.component.scss']
})
export class LaheteYhteenvetoComponent {

  @Input() yhteenveto: LaheteYhteenvetoItem[] = [];
  @Input() prefix?: string;
  private lang: LaheteLanguage;
  public constructor(protected laheteService: LaheteService) {
    this.lang = laheteService.lang || LaheteLanguage.FI;
  }
  protected getLabelKey(key: string): string {
    return Translations.getLabel(key, this.lang);
  }
}
