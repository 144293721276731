import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareAction, MatcherModel} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {QuestionInitMatcher} from "ee-lahete";

export class AutomaattinenTiedoksisaajaGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'checkbox'})): QuestionGroup {

    const controls = this.laheteKoodisto.koodisto.tiedoksisaajat.map((tiedoksisaaja, i) => {
      const controlFilters = {
        'valitutTutkimukset.valitut': new MatcherModel(
          tiedoksisaaja.id,
          this.isTiedoksisaajaVisibleFilter,
          AutomaattinenTiedoksisaajaGroup.filterActionFn
        )
      };

      const control = new QuestionControl({
        key: tiedoksisaaja.id,
        htmlId: tiedoksisaaja.id,
        controlType: builder.controlType,
        label: this.getLocalizedOf(tiedoksisaaja, 'nimi'),
        filters: builder.applyValues ? null : controlFilters,
        initialValue: builder.initValueByPath(['automTiedoksisaaja', tiedoksisaaja.id]),
        modifiers: {
          'valitutTutkimukset.valitut': new MatcherModel(
            tiedoksisaaja.id,
            this.isTiedoksisaajaVisibleModifier,
            AutomaattinenTiedoksisaajaGroup.modifierActionFn
          )
        }
      });

      if (builder.applyValues) {
        const valitut = builder.initValueByPath(['valitutTutkimukset', 'valitut'], []);
        controlFilters['valitutTutkimukset.valitut'].initialCompareState = this.isTiedoksisaajaVisibleFilter(tiedoksisaaja.id, valitut);
        this.laheteService.registerInitialFilter(new QuestionInitMatcher(control, controlFilters));
      }

      return control;
    });

    const groupFilters = {
      'valitutTutkimukset.valitut': new MatcherModel(
        null,
        (ignoreVal, newVal) => this.isAnyTiedoksisaajaVisibleFilter(newVal),
        AutomaattinenTiedoksisaajaGroup.filterActionFn)
    };

    const group = new QuestionGroup({
      key: 'automTiedoksisaaja',
      label: this.getLabel('automTiedoksisaaja'),
      filters: builder.applyValues ? null : groupFilters,
      controls: controls
    });

    if (builder.applyValues) {
      const valitut = builder.initValueByPath(['valitutTutkimukset', 'valitut'], []);
      groupFilters['valitutTutkimukset.valitut'].initialCompareState = this.isAnyTiedoksisaajaVisibleFilter(valitut);
      this.laheteService.registerInitialFilter(new QuestionInitMatcher(group, groupFilters));
    }

    return group;
  }

  isTiedoksisaajaVisible = (compareVal, newVal) =>
    (newVal || []).some(value => this.laheteKoodisto.dataObj[value.id].automTiedoksisaaja === compareVal)

  isTiedoksisaajaVisibleFilter = (compareVal, newVal) => this.isTiedoksisaajaVisible(compareVal, newVal) ?
    CompareAction.FILTER :
    CompareAction.NONE

  isTiedoksisaajaVisibleModifier = (compareVal, newVal) => this.isTiedoksisaajaVisible(compareVal, newVal) ?
    CompareAction.SELECT_ONLY_CHECKBOX_OPTION :
    CompareAction.RESET

  isAnyTiedoksisaajaVisibleFilter: (newVal: any) => CompareAction = (newVal) =>
    (newVal || []).some(value => Boolean(this.laheteKoodisto.dataObj[value.id].automTiedoksisaaja)) ?
      CompareAction.FILTER :
      CompareAction.NONE

}
