import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AccountNotification} from "../../account/account";

@Injectable()
export class OmavalvontasuunnitelmaService {

  constructor(private readonly http: HttpClient) {
  }

  public getNotification(): Promise<AccountNotification> {
    return this.http.get("/api/v1/as/notifications")
      .toPromise()
      .then(notification => notification as AccountNotification);
  }
}
