import {Directive, ElementRef, OnInit} from "@angular/core";
import {AccountService} from "../account/account.service";

/**
 * Poistaa elementin ympäristöistä, jotka ajetaan tuotanto-moodissa.
 */
@Directive({
  selector: '[isAsiakas]'
})
export class IsAsiakasDirective implements OnInit {

  constructor(private elementRef: ElementRef,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    if (!this.accountService.isAsiakas()) {
      this.elementRef.nativeElement.remove();
    }
  }

}
