import {ExcelMapper} from "../common/excel-mapper";
import {ExcelValidators} from "../common/excel-validators";
import {TranslateExcel} from "./translate-excel";
import {ExcelUtils} from "../common/excel-utils";

export class RehuExcelMapper implements ExcelMapper {
  public static valilehdetValmistus = {
    V04: ["rehuAineetValmistus", "lisaaineetValmistus", "lisaaineidenEsiseoksetValmistus", "elintarviketuotantoelaintenRehutValmistus", "lemmikkielaintenRehutValmistus", "luonnonelaintenRehutValmistus", "turkiselaintenRehutValmistus", "rahtisekoittajat"]
  };
  public static valilehdetTuonti = ["rehuAineetTuonti", "lisaaineetTuonti", "lisaaineidenEsiseoksetTuonti", "elintarviketuotantoelaintenRehutTuonti", "lemmikkielaintenRehutTuonti", "luonnonelaintenRehutTuonti"];

  public static valilehdetRaakaAineKaytto = {
    V03: ["rehuAineetRaakaAineKaytto", "lisaaineetKokkidiostaatitRaakaAineKaytto", "lisaaineetRaakaAineKaytto", "lisaaineidenEsiseoksetRaakaAineKaytto", "rehuseoksetRaakaAineKaytto"]
  };
  private static VALMISTUS = "valmistus";
  private static TUONTI = "tuonti";
  private static RAAKAAINEKAYTTO = "raakaAineKaytto";
  private headerRow = []
  private translateExcel = new TranslateExcel();
  private allowedVersions = {
    valmistus: new Set(["V03", "V04"]),
    tuonti: new Set(["V03"]),
    raakaAineKaytto: new Set(["V03"])
  }

  public getSectionTitles(): { [key: string]: string } {
    return {
      valmistus: 'rehuvuosiilmoitus.rehuValmistusOtsikko',
      tuonti: 'rehuvuosiilmoitus.rehuTuontiOtsikko',
      raakaAineKaytto: 'rehuvuosiilmoitus.rehuRaakaaineOtsikko'
    }
  }

  public isVersionAllowed(excelType: string, excelVersion: string): boolean {
    return this.allowedVersions[excelType].has(excelVersion);
  }

  public sanitizeRowData(rowData): { rowIndex: number, data: any }[] {
    let sanitized = [];
    const headerRowAmount = 4;
    if (rowData) {
      this.headerRow = rowData[1];
      sanitized = rowData.slice(4)
          .map((data, rowId: number) => ({rowIndex: rowId + headerRowAmount, data: data}))
          .filter((data, _rowId) => data.data[26] !== "skip")
          .filter((data, _rowId) => (data.data || []).some(cell => cell?.trim()));

    }
    return sanitized;
  }

  public getExcelValidators(sheetName: string): { validatorFn: (rowData) => string[], errMsg: string }[] {
    const firstComparableColumn = 3;
    const validators = [];

    validators.push({
          validatorFn: (rowData) => ExcelValidators.checkRequiredCells(rowData, this.headerRow),
          errMsg: "lomakeYleinen.excelPakollinenTieto"
        },
        {
          validatorFn: (rowData) => ExcelValidators.checkNegativeCellValues(rowData),
          errMsg: "lomakeYleinen.excelNegatiivinenArvo"
        });

    if (sheetName !== RehuExcelMapper.valilehdetRaakaAineKaytto.V03[1] && sheetName !== RehuExcelMapper.valilehdetValmistus.V04[7]) {
      validators.push({
        validatorFn: (rowData) => ExcelValidators.checkEitherOne(rowData, firstComparableColumn),
        errMsg: "rehuvuosiilmoitus.rehuExcelGmoLuomu"
      })
    }

    return validators;
  }


  formatDataToForm(data, excelType, sheetName) { // NOSONAR
    const tyyppinimiOrTuote = sheetName.toString().includes('rehuAineet') ? 'tuote' : 'tyyppinimi';
    if (excelType === RehuExcelMapper.VALMISTUS && sheetName === RehuExcelMapper.valilehdetValmistus.V04[7]) {
      return this.createValmistusRahtisekoittajaData(data);
    } else if (excelType === RehuExcelMapper.VALMISTUS) {
      return this.createValmistusData(data, tyyppinimiOrTuote);
    } else if (excelType === RehuExcelMapper.TUONTI) {
      return this.createTuontiData(data, tyyppinimiOrTuote);
    } else if (excelType === RehuExcelMapper.RAAKAAINEKAYTTO && sheetName === RehuExcelMapper.valilehdetRaakaAineKaytto.V03[1]) {
      return this.createKokkidiostaatitData(data)
    } else if (excelType === RehuExcelMapper.RAAKAAINEKAYTTO) {
      return this.createRehuaineRaakaaineKayttoData(data, tyyppinimiOrTuote);
    } else {
      console.error("Tunnistamaton exceltyyppi");
      // TODO show error to user in load view
    }
  }

  private createValmistusData(data, tyyppinimiOrTuote: 'tyyppinimi' | 'tuote') {
    const mappedRehutyyppi = this.getMappedRehutyyppi(data[3], data[4]);
    return {
      rehuaineValmistus: {
        id: null,
        rehunimi: this.translateExcel.translateValmistus(data[0]) || null,
        [tyyppinimiOrTuote]: this.translateExcel.translateValmistus(data[1]) || null,
        elainlaji: this.translateExcel.translateValmistus(data[2]) || null,
      },
      rehutyyppi: mappedRehutyyppi,
      tuotantotapa: null,
      vainPakkaamista: data[5] !== null && data[5] !== undefined && data[5].toLowerCase() === 'x',
      pakkauttaja: data[8] || null,
      valmistusmaarat: {
        valmistettuTaiPakattuMaara: ExcelUtils.removeSpaces(data[6]) || null,
        tuoteluku: ExcelUtils.removeSpaces(data[7]) || null,
        lemmikkielaintenruokinta: ExcelUtils.removeSpaces(data[11]) || null,
        luonnonelaintenruokinta: ExcelUtils.removeSpaces(data[12]) || null,
        elintarviketuotanto: ExcelUtils.removeSpaces(data[9]) || null,
        turkiselainruokinta: ExcelUtils.removeSpaces(data[10]) || null,
        rehuvalmistusRaakaAine: ExcelUtils.removeSpaces(data[13]) || null,
        vientiEU: ExcelUtils.removeSpaces(data[14]) || null,
        vientiMuu: ExcelUtils.removeSpaces(data[15]) || null,
      }
    };
  }

  private createValmistusRahtisekoittajaData(data) {
    const mappedRehutyyppi = this.getMappedRehutyyppi(null, data[4]);
    return {
      rehuaineValmistus: {
        id: null,
        rehunimi: this.translateExcel.translateValmistus(data[0]) || null,
        tyyppinimi: this.translateExcel.translateValmistus(data[1]) || null,
        tuote: this.translateExcel.translateValmistus(data[2]) || null,
      },
      kalajauhollinen: data[3] !== null && data[3] !== undefined && data[3].toLowerCase() === 'x',
      rehutyyppi: mappedRehutyyppi,
      tuotantotapa: null,
      vainPakkaamista: data[5] !== null && data[5] !== undefined && data[5].toLowerCase() === 'x',
      pakkauttaja: data[8] || null,
      valmistusmaarat: {
        valmistettuTaiPakattuMaara: ExcelUtils.removeSpaces(data[6]) || null,
        tuoteluku: ExcelUtils.removeSpaces(data[7]) || null,
        lemmikkielaintenruokinta: ExcelUtils.removeSpaces(data[11]) || null,
        luonnonelaintenruokinta: ExcelUtils.removeSpaces(data[12]) || null,
        elintarviketuotanto: ExcelUtils.removeSpaces(data[9]) || null,
        turkiselainruokinta: ExcelUtils.removeSpaces(data[10]) || null,
        rehuvalmistusRaakaAine: ExcelUtils.removeSpaces(data[13]) || null,
        vientiEU: ExcelUtils.removeSpaces(data[14]) || null,
        vientiMuu: ExcelUtils.removeSpaces(data[15]) || null,
      }
    };
  }

  private createTuontiData(data, tyyppinimiOrTuote: 'tyyppinimi' | 'tuote') {
    const mappedRehutyyppi = this.getMappedRehutyyppi(data[3], data[4]);
    return {
      rehuaineTuonti: {
        id: null,
        rehunimi: data[0] || null,
        [tyyppinimiOrTuote]: this.translateExcel.translateTuonti(data[1]) || null,
        elainlaji: this.translateExcel.translateTuonti(data[2]) || null,
      },
      rehutyyppi: mappedRehutyyppi,
      valmistusmaarat: {
        tuontiMaara: ExcelUtils.removeSpaces(data[6]) || null,
        tuoteluku: ExcelUtils.removeSpaces(data[7]) || null,
        tuontiMaa: data[8] || null,
        alkuperaMaa: data[9] || null,
        lisatieto: data[10] || null,
      }
    };
  }

  private createRehuaineRaakaaineKayttoData(data, tyyppinimiOrTuote: 'tyyppinimi' | 'tuote') {
    const mappedRehutyyppi = this.getMappedRehutyyppi(data[3], data[4]);
    return {
      rehuaineRaakaaineKaytto: {
        id: null,
        rehunimi: data[0] || null,
        [tyyppinimiOrTuote]: this.translateExcel.translateRaakaAine(data[1]) || null,
        rehukategoria: this.translateExcel.translateRaakaAine(data[2]) || null,
      },
      rehutyyppi: mappedRehutyyppi,
      kaytetytMaarat: {
        alkuperaMaa: this.translateExcel.translateRaakaAine(data[5]) || null,
        kaytettyMaara: ExcelUtils.removeSpaces(data[6]) || null,
        lisatieto: data[7] || null,
      }
    };
  }

  private createKokkidiostaatitData(data) {
    return {
      rehuaineRaakaaineKaytto: {
        id: null,
        rehunimi: data[0] || null,
        tyyppinimi: this.translateExcel.translateRaakaAine(data[1]) || null,
        rehukategoria: this.translateExcel.translateRaakaAine(data[2]) || null,
      },
      kokkidiostaattiValmiste: {
        pitoisuus: data[3] || null,
        vaikuttavaAine: this.translateExcel.translateRaakaAine(data[4]) || null,
      },
      kaytetytMaarat: {
        alkuperaMaa: this.translateExcel.translateRaakaAine(data[5]) || null,
        kaytettyMaara: ExcelUtils.removeSpaces(data[6]) || null,
        kokkidiostaatitKotimaa: data[7] || null,
        kokkidiostaatitVienti: ExcelUtils.removeSpaces(data[8]) || null,
        lisatieto: data[9] || null,
      }
    };
  }

  private getMappedRehutyyppi(gmo, luomu) {
    if (gmo != null && isNaN(gmo) && gmo.toLowerCase() === 'x') {
      return "gmoRehu";
    } else if (luomu != null && isNaN(luomu) && luomu.toLowerCase() === 'x') {
      return "luomuRehu";
    } else {
      return "tavallinen";
    }
  }

  private filterRehuaine(sheet: Array<any>, excelType) {
    const kasviperaiset = [];
    const elainperaiset = [];
    const muut = [];
    let rehuaineObject;
    sheet.forEach((data) => {
      rehuaineObject = this.formatDataToForm(data, excelType, this.handleSheetType(excelType, sheet.length)[0])
      if (+(data[1].substring(0, data[1].indexOf("."))) < 8) {
        kasviperaiset.push(rehuaineObject);
      } else if (+(data[1].substring(0, data[1].indexOf(".")) <= 12)) {
        elainperaiset.push(rehuaineObject);
      } else if (+(data[1].substring(0, data[1].indexOf(".")) > 12)) {
        muut.push(rehuaineObject);
      }
    });
    return {kasviperaiset, elainperaiset, muut};
  }

  public getSheetName(excelType: string, index: number, lenght: number) {
    return this.handleSheetType(excelType, lenght)[index];
  }

  private handleSheetType(excelType: string, _lenght: number): Array<string> {
    switch (excelType) {
      case RehuExcelMapper.VALMISTUS:
        return RehuExcelMapper.valilehdetValmistus.V04;
      case RehuExcelMapper.TUONTI:
        return RehuExcelMapper.valilehdetTuonti;
      case RehuExcelMapper.RAAKAAINEKAYTTO:
        return RehuExcelMapper.valilehdetRaakaAineKaytto.V03;
    }
  }

  public mapExcelData(excelData: { data: any[], type: string }) {
    const excelType = excelData.type;
    const dataSheets = excelData.data;
    const result = {};
    dataSheets.forEach((sheet, index) => {
      const sheetType = this.handleSheetType(excelType, dataSheets.length)[index];
      if (index !== 0) {
        if (sheetType) {
          result[sheetType] = sheet.map(row => this.formatDataToForm(row, excelType, sheetType));
        } else {
          console.error("Unknown ExcelType", excelType, index);
        }
      } else {  // REHUAINEET erottelu (kasviperäiset, eläinperäiset, muut)
        result[sheetType] = this.filterRehuaine(sheet, excelType)
      }
    });
    return result;
  }
}


