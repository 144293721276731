import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, OnInit, ViewChild} from "@angular/core";
import {KoodistoService} from "../koodisto/koodisto.service";
import {OsoiteTiedot} from "../account/account";
import {Teksti} from "../utils/teksti";
import {Option} from "../touko-components/select-menu.component";
import {merge, Observable, OperatorFunction, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "touko-osoite-lannoite",
  template: `

    <div class="col-md-6">
      <touko-text-input [disabled]="readonly" [value]="fieldValue?.osoite"
                        (change)="updateOsoite($event.target.value)"
                        [htmlId]="idPrefix + '-osoite'" [labelAttribute]="osoiteTeksti.fullKey"></touko-text-input>
    </div>

    <div class="col-md-3">
      <touko-text-input [disabled]="readonly" [value]="fieldValue?.postinumero"
                        (change)="updatePostinro($event.target.value)"
                        [htmlId]="idPrefix + '-postinumero'" labelAttribute="lomakeYleinen.postinro"></touko-text-input>
    </div>

    <div class="col-md-3">
      <touko-text-input [disabled]="readonly || !ulkomainenOsoite" [value]="fieldValue?.postitoimipaikka"
                        (change)="updatePostitoimipaikka($event.target.value)"
                        [htmlId]="idPrefix + '-toimipaikka'"
                        labelAttribute="lomakeYleinen.postitoimipaikka"></touko-text-input>
    </div>

    <div *ngIf="maidenNimet && maidenNimet.length && ulkomainenOsoite" class="col-md-6 mb-2">
      <div class="maa-valinta">
        <label [htmlFor]="idPrefix + 'maa'" attribute="kayttajatili.maanNimi"></label>
        <input
          [disabled]="readonly"
          #maavalinta="ngbTypeahead"
          [id]="idPrefix + 'maa'"
          type="text"
          class="form-control"
          (selectItem)="updateMaaKoodi($event.item)"
          [ngbTypeahead]="search"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [editable]="false"
          [placement]="'bottom-start'"
          [value]="getMaannimi(fieldValue?.maaKoodi)"
          (focus)="focus$.next($any($event).target.value)"
          (click)="click$.next($any($event).target.value)"
        />
        <button *ngIf="fieldValue?.maaKoodi" type="button" [attr.aria-label]="'teksti.sulje'"
                class="btn-no-style clear-selection-btn" (click)="updateMaaKoodi({label: null, value: null})">
          <i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  `,
  styles: [`
    .clear-selection-btn {
      position: absolute;
      right: 1rem;
      top: 2.4rem;
      color: #004f71;
      font-size: 0.9rem;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LannoiteOsoiteComponent),
    }
  ]
})
export class LannoiteOsoiteComponent implements ControlValueAccessor, OnInit {

  @Input('id') idPrefix = "";
  @Input() osoiteTeksti = new Teksti("Osoite", "osoite", "lomakeYleinen");
  @Input() readonly = false;
  @Input() ulkomainenOsoite: boolean;
  maidenNimet: Option[];

  @ViewChild('maavalinta') maavalinta: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private koodistoService: KoodistoService) {
    this.fieldValue = new OsoiteTiedot();
  }

  fieldValue: OsoiteTiedot;
  lastPostinumero = "";
  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  ngOnInit(): void {
    if (!this.osoiteTeksti || !(this.osoiteTeksti instanceof Teksti)) {
      this.osoiteTeksti = new Teksti("Osoite", "osoite", "lomakeYleinen");
    }

    this.assignMaat();
  }

  async assignMaat() {
    const maat = await this.koodistoService.getMaidenNimet();

    this.maidenNimet = Object.values(maat).map(maa => ({
      value: maa.maaKoodi,
      label: maa.maanNimi
    }));
  }

  writeValue(obj: OsoiteTiedot | { osoite: string; postinumero: string; postitoimipaikka: string; maaKoodi: string }): void {
      if (obj && !(obj instanceof OsoiteTiedot)) {
        obj = new OsoiteTiedot(obj.osoite, obj.postinumero, obj.postitoimipaikka, obj.maaKoodi);
      }

      this.fieldValue = obj;

      if (this.fieldValue && this.fieldValue.postinumero !== this.lastPostinumero && !this.ulkomainenOsoite) {
        this.koodistoService.getPostitoimipaikkaAsync(this.fieldValue.postinumero).then(val => {
          this.lastPostinumero = this.fieldValue.postinumero;
          this.fieldValue.postitoimipaikka = val;
          this._onChange(this.fieldValue);
        });
      } else {
        this._onChange(this.fieldValue);
      }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  updateOsoite(value: string) {
    this.fieldValue = this.fieldValue ? this.fieldValue : new OsoiteTiedot();
    this.fieldValue.osoite = value;
    this._onTouched(this.fieldValue);

    this.writeValue(this.fieldValue);
  }

  updatePostinro(value: string) {
      this.fieldValue = this.fieldValue ? this.fieldValue : new OsoiteTiedot();
    this.fieldValue.postinumero = value.trim();
      this._onTouched(this.fieldValue);

      if (!this.ulkomainenOsoite) {
        this.koodistoService.getPostitoimipaikkaAsync(this.fieldValue.postinumero).then(val => {
          this.lastPostinumero = value;
          this.fieldValue.postitoimipaikka = val;
        });
      }

      this.writeValue(this.fieldValue);
  }

  updatePostitoimipaikka(value: string) {
    this.fieldValue = this.fieldValue ? this.fieldValue : new OsoiteTiedot();
    this.fieldValue.postitoimipaikka = value;
    this._onTouched(this.fieldValue);

    this.writeValue(this.fieldValue);
  }

  updateMaaKoodi(value: Option) {
    this.fieldValue = this.fieldValue ? this.fieldValue : new OsoiteTiedot();
    this.fieldValue.maaKoodi = value.value;

    this._onTouched(this.fieldValue);

    this.writeValue(this.fieldValue);
  }

  setDisabledState(isDisabled: boolean) {
    this.readonly = isDisabled;
  }

  search: OperatorFunction<string, readonly Option[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.maavalinta.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => term === '' ?
          this.maidenNimet :
          this.maidenNimet
            .filter((v) => v.label.toLowerCase().startsWith(term.toLowerCase())))
    );
  };

  formatter(maa: Option) {
    return maa.label;
  }

  getMaannimi(maaKoodi: string) {
    return this.maidenNimet.find(maa => maa.value === maaKoodi)?.label || null;
  }
}
