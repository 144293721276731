import {Component} from "@angular/core";

@Component({
  template: `
      <div class="mb-5 row">
          <h1 class="ml-3" attribute="rehuvuosiilmoitus.rehuTemplateOtsikko">Lomakepohjat vuosi-ilmoituksen antamista
              varten</h1>
          <div class="col-9">
              <touko-alert-box><p attribute="rehuvuosiilmoitus.rehuTemplateInfo" class="mb-0"></p>
              </touko-alert-box>
              <ng-container *ngFor="let template of excelTemplates">
                  <h3 class="mt-3" [attribute]="template.header"></h3>
                  <div class="mb-2">
                    <lataa-template-component [templateId]="template.header + 'FI'"
                                              [templateName]="template.nameFI"></lataa-template-component>
                  </div>
                  <div>
                      <lataa-template-component [templateId]="template.header + 'SV'"
                                                [templateName]="template.nameSV"></lataa-template-component>
                  </div>
              </ng-container>
          </div>
          <div class="col-3">
            <button id="template-ohje-btn" class="btn btn-link-secondary mb-3" (click)="toggleOhje()">
                  <em class="far fa-question-circle text-primary"></em>
                  <span class="pl-2" attribute="lomakeYleinen.tarvitsetkoApua"></span>
              </button>
              <div class="small" *ngIf="isOhjeVisible">
                  <p [attribute]="'rehuvuosiilmoitus.ohjeTemplate'" [markdown]="true"></p>
              </div>
          </div>
      </div>
  `,
  selector: 'rehuvuosiilmoitus-template',
})

export class RehuvuosiilmoitusTemplateComponent {
  excelTemplates = [
    {
      header: 'rehuvuosiilmoitus.rehuValmistusOtsikko',
      nameFI: '1_Rehu_vuosi-ilmoituslomake_valmistus_v04',
      nameSV: '1_Årsanmälan_om_foder_Tillverkning_v04'
    },
    {
      header: 'rehuvuosiilmoitus.rehuTuontiOtsikko',
      nameFI: '2_Rehu_vuosi-ilmoituslomake_tuonti_v03',
      nameSV: '2_Årsanmälan_om_foder_Import_v03'
    },
    {
      header: 'rehuvuosiilmoitus.rehuRaakaaineOtsikko',
      nameFI: '3_Rehu_vuosi-ilmoituslomake_raaka-ainekaytto_v03',
      nameSV: '3_Årsanmälan_om_foder_Användning_av_råvaror_vid_tillverkningen_v03'
    },
    {
      header: 'rehuvuosiilmoitus.rehuLaakeOtsikko',
      nameFI: '4_Lääkerehuilmoitus_v01',
      nameSV: '4_Årsanmälan_om_läkemedelsfoder_v01'
    }
  ];

  isOhjeVisible = false;

  toggleOhje() {
    this.isOhjeVisible = !this.isOhjeVisible;
  }


}
