import {Component} from "@angular/core";
import {LomakeInfo} from "../common/lomake-info";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {AccountService} from "../../../account/account.service";
import {LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {Teksti} from "../../../utils/teksti";
import {AbstractField, FieldArray, ValueOption} from "ngx-fielding";
import {SiemenpakkaamoV2Data} from "./siemenpakkaamo-v2.data";
import {YhteyshenkiloViewOptions} from "../../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";

@Component({
  selector: 'siemenpakkaamo-v2',
  templateUrl: 'siemenpakkaamo-v2.component.html',
  providers: [ProcessLomakeService]
})
export class SiemenpakkaamoV2Component extends LomakeV2BaseDirective {

  yhteyshenkiloViewOptions = new YhteyshenkiloViewOptions(true, true, true, false)
  yhteyshenkiloRooli = YhteyshenkiloValue.SIEM_PAKK;

  constructor(protected readonly processLomakeService: ProcessLomakeService,
              protected readonly data: SiemenpakkaamoV2Data,
              protected readonly accountService: AccountService) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  get hakemustyypit(): ValueOption[] {
    return this.lomake.get("hakemustyyppi.radio-hakemustyyppi").field.valueOptions;
  }

  get markkinoimasiKylvosiemenet(): AbstractField[] {
    return Object.values(this.lomake.get("markkinoimasiKylvosiemenet").asGroup().controlFields);
  }

  get koneetJaLaitteet(): AbstractField[] {
    return Object.values(this.lomake.get("koneetJaLaitteet").asGroup().controlFields);
  }

  get toiminnanLaajuus(): ValueOption[] {
    return this.lomake.get("toiminnanLaajuus.radio-group").field.valueOptions;
  }
  get lisatiedotToiminta(): AbstractField[] {
    return Object.values(this.lomake.get("lisatiedot").asGroup().controlFields);
  }
  get vakuusLipukkeet(): AbstractField {
    return this.lomake.get("painatus.radio-group");
  }
  initLomakeBehaviour() {
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new SiemenpakkaamolupahakemusInfo(accountType, toimintotyyppi);
  }

  getFieldArray(path: string): FieldArray {
    return this.lomake.get(path) as FieldArray;
  }

  addLisalaite(laitenimi: string): void {
    const laitteet = this.lomake.get(['koneetJaLaitteet', laitenimi]).asArray();
    laitteet.pushField();
  }

  removeLisalaite(laitenimi: string, index: number): void {
    const laitteet = this.lomake.get(['koneetJaLaitteet', laitenimi]).asArray();
    laitteet.removeAt(index);
  }

  submitLomake() {
    this.lomake.markAllAsTouched();
    return this.processLomakeService.submitLomake(this.lomake.getRawValue(), this.lomakeInfo, this.getLomakeMetaData().id, {}, this.lomake.valid).then(responseId => {
      this.getLomakeMetaData().id = responseId || null;
    });
  }

  addLomakeBehaviour() {
  }
}

class SiemenpakkaamolupahakemusInfo implements LomakeInfo {
  asia: string;
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.SIEM_PAKK.value;
  lomakeTitle: Teksti = new Teksti("Siemenpakkaamon lupahakemus pakkaustoimintaa varten", "siemenPakkaamoHakemusOtsikko", "siemenpakkaamo");
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: "as" | "vk", toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.SIEM_PAKK];
    this.asia = "siemenpakkaamolupa";
    this.toimintotyyppi = toimintotyyppi;
    const suffix = this.toimintotyyppi === "hakemus" ? "_hakemus" : "";

    const prefix = 'siemenpakkaamolupa.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1_1', '1_2'], suffix);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2_1', '2_2', '2_3'], suffix);
    this.ohjeet.sivu2 = LomakeHelper.getOhjeList(prefix, ['3_1'], suffix);
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/hakemus/siemenpakkaamolupa/${toimintotyyppi}`;
  }
}
