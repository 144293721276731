import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {SyoteGroup} from "../syote/syote-group";
import {Account} from "../../account/account";
import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {SyoteTemplateTaulukko} from '../syote/SyoteTemplateTaulukko';
import {LuomuValidators} from "../validators/luomu-validators";
import {keyOrderFn, LomakeData, LomakeDataContent} from "../lomake-data";

export class LuomuPoikkeus9cData extends LomakeData {

  static lomakeTitle: Teksti = new Teksti("Poikkeuslupa, lisäysaineisto 9c", "otsikko", "luomuPoikkeus9c");

  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    return {
      perustiedot: LuomuPoikkeus9cData.getPerustiedot(LuomuPoikkeus9cData.lomakeTitle.view, account),
      yhteyshenkilot: LuomuPoikkeus9cData.getYhteyshenkilot(LuomuPoikkeus9cData.lomakeTitle.view, account, null, "ReVCCROrganicCust"),
      luvatTavanomLisaysaineisto: LuomuPoikkeus9cData.getLuomuluvatTavanom(),
      luvatKemialPeittaus: LuomuPoikkeus9cData.getLuomuluvatKemiall()
    };
  }


  static getLuomuLupaErrors(): ({ [s: string]: Teksti }) {
    return {
      kayttomaara: new Teksti("Kayttomaara on pakollinen", "errKayttomaara", LuomuPoikkeus9cData.lomakeTitle.view),
      laji: new Teksti("Laji on pakollinen", "errLaji", LuomuPoikkeus9cData.lomakeTitle.view),
      lajike: new Teksti("Lajike on pakollinen", "errLajike", LuomuPoikkeus9cData.lomakeTitle.view),
      perustelut: new Teksti("Perustelut ovat pakolliset", "errPerustelut", LuomuPoikkeus9cData.lomakeTitle.view),
      peruste: new Teksti("Peruste tarvitsee antaa", "errPeruste", LuomuPoikkeus9cData.lomakeTitle.view),
      tyyppi: new Teksti("Tyyppi tarvitsee antaa", "errTyyppi", LuomuPoikkeus9cData.lomakeTitle.view),
      kayttomaaraTaimiLuku: new Teksti("Vain kokonaiset taimet ovat sallittuja", "errKayttomaaraTaimi", LuomuPoikkeus9cData.lomakeTitle.view),
    };
  }


  static getLuomuLupaControls(lisaaPeruste: boolean, validaatioFunktio: ValidatorFn, lupaTyyppi: string): FormGroup {
    const lajiControl = new FormControl('', null);
    const lajikeControl = new FormControl({disabled: true, value: ''}, null);
    const tyyppiControl = new FormControl('', null);
    const kayttomaaraControl = new FormControl({disabled: true, value: null}, null);

    lajiControl.valueChanges
      .subscribe((value: any) => {
        if (value && value.nimi && value.nimi.trim() !== "") {
          lajikeControl.enable();
        } else {
          lajikeControl.disable();
        }
      });

    tyyppiControl.valueChanges
      .subscribe((value: string) => {
        if (value && value.trim() !== "") {
          kayttomaaraControl.enable();
        } else {
          kayttomaaraControl.disable();
        }
      });

    const controls: { [key: string]: AbstractControl } = {
      laji: lajiControl,
      lajike: lajikeControl,
      kayttomaara: kayttomaaraControl,
      tyyppi: tyyppiControl,
      perustelut: new FormControl('', null),
      liitteet: new FormControl([], null),
    };

    if (lisaaPeruste) {
      controls.peruste = new FormControl('', null);
    }

    if (lupaTyyppi === "tavanomainen") {
      controls.lupaTyyppi = new FormControl('tavanomainen', null);
    } else {
      controls.lupaTyyppi = new FormControl('kemiallinenpeittaus', null);
    }

    return new FormGroup(controls,
      validaatioFunktio
    );

  }

  static getKeyOrder(): string[] {
    return keyOrderFn(LuomuPoikkeus9cData.getData);
  }

  static getLuomuTavamLupaRivi(): FormGroup {
    return LuomuPoikkeus9cData.getLuomuLupaControls(true, LuomuValidators.luomuTavanomInputRivi, "tavanomainen");
  }

  static getLuomuKemialLupaRivi(): FormGroup {
    return LuomuPoikkeus9cData.getLuomuLupaControls(false, LuomuValidators.luomuKemiaInputRivi, "kemiallinenpeittaus");
  }

  static getLuomuluvatTavanom(): SyoteGroup {
    return new SyoteGroup(
      LuomuPoikkeus9cData.lomakeTitle.view,
      new Teksti("Lupa käyttää tavanomaisesti tuotettua lisäysaineistoa", "lupaTavanomLisaysaineisto"),
      null,
      LuomuPoikkeus9cData.getLuomuLupaErrors(),
      YhteenvetoTyyppi.LUOMULUVAT,
      new Syote(new Teksti("Luomun luvat", "luvatTavanomLisaysaineisto"),
        new SyoteTemplateTaulukko(LuomuPoikkeus9cData.getLuomuTavamLupaRivi, [LuomuPoikkeus9cData.getLuomuTavamLupaRivi()])
      )
    );
  }

  static getLuomuluvatKemiall(): SyoteGroup {
    return new SyoteGroup(
      LuomuPoikkeus9cData.lomakeTitle.view,
      new Teksti("Luvat käyttää kemiallisesti peitattuja siemeniä uuden lisäysaineiston tuotannossa", "luvatKemialPeittaus"),
      null,
      LuomuPoikkeus9cData.getLuomuLupaErrors(),
      YhteenvetoTyyppi.LUOMULUVAT,
      new Syote(new Teksti("Luomun luvat", "luvatKemialPeittaus"),
        new SyoteTemplateTaulukko(LuomuPoikkeus9cData.getLuomuKemialLupaRivi, [LuomuPoikkeus9cData.getLuomuKemialLupaRivi()])
      )
    );
  }
}
