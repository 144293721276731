import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {Teksti} from "../../utils/teksti";
import {RekisteritService} from "./rekisterit.service";
import {LomakeRequest} from "../touko-lomake-utils";
import {AccountService} from "../../account/account.service";
import {RekisteriOrOikeus} from "./rekisteri";
import {KasvinterveysRekisteritiedot} from "../../valvontakohde/valvontakohde";
import {Subscription} from "rxjs";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

@Component({
  selector: "kasvinterveys-rekisterit",
  template: `
    <div class="card mb-4" *ngIf="requiredRekisterit.length > 0 || requiredOikeudet.length > 0">
      <div class="card-header" attribute="kasvinterveysrekisteri.rekCardOtsikko">
        Toimintaan vaaditut oikeudet / rekisterit
      </div>
      <div class="card-body" [showSpinner]="showLoader">
        <ul class="list-unstyled small">
          <li [id]="rekisteri.teksti.key" *ngFor="let rekisteri of requiredRekisterit">
            <ng-container *ngTemplateOutlet="rekisteriItem; context:{rekisteri: rekisteri}"></ng-container>
          </li>
        </ul>
        <hr *ngIf="requiredOikeudet.length > 0">
        <ul class="list-unstyled small" *ngIf="requiredOikeudet.length > 0">
          <li [id]="oikeus.teksti.key" *ngFor="let oikeus of requiredOikeudet">
            <ng-container *ngTemplateOutlet="rekisteriItem; context:{rekisteri: oikeus}"></ng-container>
          </li>
        </ul>
      </div>

      <div class="card-footer" *ngIf="toimintotyyppi === 'muutos'">
        <div>
          <h2 class="descriptive-title" attribute="kasvinterveysrekisteri.rekCardSeliteOtsikko"></h2>
          <ul class="list-unstyled small">
            <li>
              <em class="mr-1 fas fa-check-circle text-success"></em>
              <em attribute="kasvinterveysrekisteri.rekCardVoimassa">Voimassa oleva rekisteri</em>
            </li>
            <li>
              <em class="mr-1 fas fa-minus-circle icon-missing"></em>
              <em attribute="kasvinterveysrekisteri.rekCardPuuttuva">Puuttuva rekisteri</em>
            </li>
            <li>
              <em class="mr-1 fas fa-times-circle text-danger"></em>
              <em attribute="kasvinterveysrekisteri.rekCardPoistettava">Poistettava rekisteri</em>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="kasvinterveysRekisterit?.rekisterinumero" class="card mb-4">
      <div class="card-body">
        <span class="h4 d-block mb-0" attribute="kayttajatili.kasvinterveysrekisteritunnus"></span>
        <span class="d-block mb-2" id="account-tiedot-rekisterinumero">
                  FI-{{ kasvinterveysRekisterit?.rekisterinumero }}</span>

        <ng-container *ngFor="let vientivalmius of kasvinterveysRekisterit?.rekisterit; index as i">
          <ng-container
              *ngIf="isVientivalmius(vientivalmius.kasvinterveysalakohderekisteri_elmoid) && vientivalmius.vientivalmiuspvm">
                      <span class="h4 d-block mb-0"
                            [attribute]="'kayttajatili.' + vientivalmius.kasvinterveysalakohderekisteri_elmoid"></span>
            <span class="d-block mb-2"
                  [id]="'account-tiedot-vientivalmius-' + i">{{ vientivalmius.vientivalmiuspvm | date:'dd.MM.yyyy' }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <ng-template #rekisteriItem let-rekisteri="rekisteri">
      <span *ngIf="toimintotyyppi === 'muutos'" [id]="'ikoni-' + rekisteri.teksti.key" class="mr-1 fas"
            [ngClass]="{
                    'fa-check-circle text-success': rekisteri.status === 'ACTIVE',
                    'fa-times-circle text-danger': rekisteri.status === 'REMOVE',
                    'fa-minus-circle icon-missing': rekisteri.status === 'MISSING'}"></span>
      <span [id]="'teksti-' + rekisteri.teksti.key" [attribute]="rekisteri.teksti.getFullKey()"></span>
    </ng-template>


  `,
  styleUrls: ["toiminta-card.component.scss"]
})
@AutoUnsubscribe
export class RekisteritCardComponent implements OnChanges, OnInit {

  @Input() formValues: string | any = null;
  @Input() toimintotyyppi: string;
  @Input() valvontakohde: string;
  @Input() asiointitunnus: string;
  @Input() toimiala: string = null;

  kasvinterveysRekisterit: KasvinterveysRekisteritiedot;
  private readonly subs: Subscription[] = [];

  requiredRekisterit: { teksti: Teksti; status: string; order?: number }[] = [];
  requiredOikeudet: { teksti: Teksti; status: string; order? }[] = [];
  hasRequiredRekisterit = false;
  showLoader = false;

  constructor(private rekisteritService: RekisteritService, private accountService: AccountService) {
  }

  ngOnChanges(): void {
    if (typeof this.formValues !== "string") {
      this.formValues = JSON.stringify(this.formValues);
    }
    if (this.formValues && !this.showLoader) {
      this.showLoader = true;
      const lomakeSisalto = new LomakeRequest();
      lomakeSisalto.body = this.formValues;
      if (this.accountService.isViranomainen() && this.asiointitunnus && this.valvontakohde) {
        this.rekisteritService.getLomakeVirkailijaRekisterit(lomakeSisalto, this.asiointitunnus, this.valvontakohde)
            .then(response => this.handleRekisterit(response));
      } else {
        this.rekisteritService.getLomakeRekisterit(lomakeSisalto)
            .then(response => this.handleRekisterit(response))
      }
    }
  }

  ngOnInit(): void {
    this.subs.push(this.accountService.account$.subscribe(v => {
      this.kasvinterveysRekisterit = v.valvontakohde?.kasvinterveysRekisteritiedot;
    }));
  }

  isVientivalmius(rekisteriId: string): boolean {
    return rekisteriId.includes('ReVVientivalm')
  }

  handleRekisterit(rekisterit) {
    this.requiredOikeudet = [];
    this.requiredRekisterit = [];

    rekisterit.forEach(r => {
      this.insertRequired(r, r.status);
    });

    const orderFn = (rek1, rek2) => rek1.order - rek2.order;
    this.requiredRekisterit.sort(orderFn);
    this.requiredOikeudet.sort(orderFn);

    this.hasRequiredRekisterit = [...this.requiredRekisterit, ...this.requiredOikeudet]
        .every(rekisteri => rekisteri.status === 'ACTIVE') && this.toimintotyyppi === 'muutos';

    this.showLoader = false;
  }

  insertRequired(rekisteri, status) {
    const item = {teksti: rekisteri.teksti, status, order: rekisteri.order};
    if (rekisteri.type === RekisteriOrOikeus.REKISTERI) {
      this.requiredRekisterit.push(item);
    } else {
      this.requiredOikeudet.push(item);
    }
  }
}

