import {Field, FieldArray, FieldControl, FieldGroup} from "ngx-fielding";


export const createRehuAineetValmistus = () => {
  return new FieldGroup(Field.build(), {
    kasviperaiset: createValmistusArray('kasviperaiset'),
    elainperaiset: createValmistusArray('elainperaiset'),
    muut: createValmistusArray('muut')
  });
}
export const createValmistusArray = (nimi: string) => {
  const createRehuAineValmistus = () => new FieldGroup(
    Field.build('valmistus'),
    {
      rehuaineValmistus: new FieldGroup(Field.build('rehuaine'), {
        id: new FieldControl(Field.build('id')),
        rehunimi: new FieldControl(Field.build('rehunimi')),
        tyyppinimi: new FieldControl(Field.build('tyyppinimi')),
        tuote: new FieldControl(Field.build('tuote')),
        elainlaji: new FieldControl(Field.build('elainlaji'))
      }),
      rehutyyppi: new FieldControl(Field.build('rehutyyppi')),
      tuotantotapa: new FieldControl(Field.build('Tuotantotapa')),
      vainPakkaamista: new FieldControl(Field.build('Vain pakkaamista')),
      pakkauttaja: new FieldControl(Field.build('pakkauttaja')),
      valmistusmaarat: new FieldGroup(Field.build('valmistusmaarat'), {
        valmistettuTaiPakattuMaara: new FieldControl(Field.build('valmistettuTaiPakattuMaara')),
        tuoteluku: new FieldControl(Field.build('tuoteluku')),
        lemmikkielaintenruokinta: new FieldControl(Field.build('lemmikkielaintenruokinta')),
        luonnonelaintenruokinta: new FieldControl(Field.build('luonnonelaintenruokinta')),
        elintarviketuotanto: new FieldControl(Field.build('elintarviketuotanto')),
        turkiselainruokinta: new FieldControl(Field.build('turkiselainruokinta')),
        rehuvalmistusRaakaAine: new FieldControl(Field.build('rehuvalmistusRaakaAine')),
        vientiEU: new FieldControl(Field.build('vientiEU')),
        vientiMuu: new FieldControl(Field.build('vientiMuu')),
      })
    }
  );
  return new FieldArray(
    Field.build(nimi),
    createRehuAineValmistus,
    []
  );
};

export const createValmistusRahtisekoittajatArray = () => {

  return new FieldArray(
    Field.build("rahtisekoittajat"),
    () => new FieldGroup(
      Field.build('valmistus'),
      {
        rehuaineValmistus: new FieldGroup(Field.build('rehuaine'), {
          id: new FieldControl(Field.build('id')),
          rehunimi: new FieldControl(Field.build('rehunimi')),
          tyyppinimi: new FieldControl(Field.build('tyyppinimi')),
          tuote: new FieldControl(Field.build('tuote')),
        }),
        kalajauhollinen: new FieldControl(Field.build('kalajauhollinen')),
        rehutyyppi: new FieldControl(Field.build('rehutyyppi')),
        vainPakkaamista: new FieldControl(Field.build('Vain pakkaamista')),
        valmistusmaarat: new FieldGroup(Field.build('valmistusmaarat'), {
          valmistettuTaiPakattuMaara: new FieldControl(Field.build('valmistettuTaiPakattuMaara')),
          tuoteluku: new FieldControl(Field.build('tuoteluku')),
        })
      }
    ),
    []
  );
}

export const createRehuAineetTuonti = () => {
  return new FieldGroup(Field.build(), {
    kasviperaiset: createTuontiArray('kasviperaiset'),
    elainperaiset: createTuontiArray('elainperaiset'),
    muut: createTuontiArray('muut')
  });
}

export const createTuontiArray = (nimi: string) => {
  const createRehuAineTuonti = () => new FieldGroup(
    Field.build('tuonti'),
    {
      rehuaineTuonti: new FieldGroup(Field.build('rehuaine'), {
        id: new FieldControl(Field.build('id')),
        rehunimi: new FieldControl(Field.build('rehunimi')),
        tyyppinimi: new FieldControl(Field.build('tyyppinimi')),
        tuote: new FieldControl(Field.build('tuote')),
        elainlaji: new FieldControl(Field.build('elainlaji'))
      }),
      rehutyyppi: new FieldControl(Field.build('rehutyyppi')),
      valmistusmaarat: new FieldGroup(Field.build('tuontiMaarat'), {
        tuontiMaara: new FieldControl(Field.build('tuontiMaara')),
        tuoteluku: new FieldControl(Field.build('tuoteluku')),
        tuontiMaa: new FieldControl(Field.build('tuontiMaa')),
        alkuperaMaa: new FieldControl(Field.build('alkuperaMaa')),
        lisatieto: new FieldControl(Field.build('lisatieto')),
      })
    }
  );
  return new FieldArray(
    Field.build(nimi),
    createRehuAineTuonti,
    []
  );
};

export const createRehuAineetRaakaaineKaytto = () => {
  return new FieldGroup(Field.build(), {
    kasviperaiset: createRaakaaineKayttoArray('kasviperaiset'),
    elainperaiset: createRaakaaineKayttoArray('elainperaiset'),
    muut: createRaakaaineKayttoArray('muut')
  });
}

export const createLisaaineetKokkidiostaatitRaakaAineKaytto = () => {
  return new FieldArray(
    Field.build("lisaaineetKokkidiostaatitRaakaAineKaytto"),
    () => new FieldGroup(
      Field.build('raakaAineKaytto'),
      {
        rehuaineRaakaaineKaytto: new FieldGroup(Field.build('rehuaine'), {
          id: new FieldControl(Field.build('id')),
          rehunimi: new FieldControl(Field.build('rehunimi')),
          tyyppinimi: new FieldControl(Field.build('tyyppinimi')),
          rehukategoria: new FieldControl(Field.build('rehukategoria'))
        }),
        kokkidiostaattiValmiste: new FieldGroup(Field.build('kokkidiostaattiValmiste'), {
          pitoisuus: new FieldControl(Field.build('pitoisuus')),
          vaikuttavaAine: new FieldControl(Field.build('vaikuttavaAine')),
        }),
        kaytetytMaarat: new FieldGroup(Field.build('kaytetytMaarat'), {
          alkuperaMaa: new FieldControl(Field.build('alkuperaMaa')),
          kaytettyMaara: new FieldControl(Field.build('kaytettyMaara')),
          kokkidiostaatitKotimaa: new FieldControl(Field.build('kokkidiostaatitKotimaa')),
          kokkidiostaatitVienti: new FieldControl(Field.build('kokkidiostaatitVienti')),
          lisatieto: new FieldControl(Field.build('lisatieto')),
        })
      }
    ),
    []
  );
}

export const createRaakaaineKayttoArray = (nimi: string) => {
  const createRehuAineRaakaaineKaytto = () => new FieldGroup(
    Field.build('raakaAineKaytto'),
    {
      rehuaineRaakaaineKaytto: new FieldGroup(Field.build('rehuaine'), {
        id: new FieldControl(Field.build('id')),
        rehunimi: new FieldControl(Field.build('rehunimi')),
        tuote: new FieldControl(Field.build('tuote')),
        tyyppinimi: new FieldControl(Field.build('tyyppinimi')),
        rehukategoria: new FieldControl(Field.build('rehukategoria'))
      }),
      rehutyyppi: new FieldControl(Field.build('´rehutyyppi')),
      kaytetytMaarat: new FieldGroup(Field.build('kaytetytMaarat'), {
        alkuperaMaa: new FieldControl(Field.build('alkuperaMaa')),
        kaytettyMaara: new FieldControl(Field.build('kaytettyMaara')),
        lisatieto: new FieldControl(Field.build('lisatieto')),
      })
    }
  );
  return new FieldArray(
    Field.build(nimi),
    createRehuAineRaakaaineKaytto,
    []
  );
};
