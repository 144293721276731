import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "touko-textarea",
  template: `
    <div class="form-group mb-4">
      <label [htmlFor]="htmlId" [attribute]="labelAttribute"></label>
      <textarea class="form-control mb-3" type="text" [id]="htmlId" [attr.aria-describedby]="htmlId + '-description'"
                [value]="value" [disabled]="disabled" [attr.maxlength]="maxLength" [rows]="rows"
                (change)="onValueChange($event.target.value)" (keyup)="onValueChange($event.target.value)"></textarea>
      <div [ngClass]="descriptionAttribute ? 'd-flex justify-content-between' : 'text-right'">
        <div [id]="htmlId + '-description'" class="small" *ngIf="descriptionAttribute" [attribute]="descriptionAttribute"></div>
        <div *ngIf="maxLength" class="text-right small text-nowrap">{{ value?.length }} / {{ maxLength }} <span attribute="merkkia">merkkiä</span></div>
      </div>
    </div>
  `
  ,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor {
  value: string;
  disabled: boolean;
  @Input() htmlId: string;
  @Input() labelAttribute: string;
  @Input() descriptionAttribute: string;
  @Input() maxLength: number;
  @Input() rows: number

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: any) {
    this.value = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onValueChange(value: string) {
    this.value = value;
    this._onChange(value);
  }
}
