<aside class="small">
  <div class="sidebar d-print-none">
    <ul>
      <li class="perustiedot">

        <a routerLink="/asiointi/account" class="sidebar-heading mb-2">
          <span [attribute]="'teksti.tilinAsetukset'">Tilin asetukset</span>
          <em class="fa fa-cog align-top pt-1 float-right"></em>
        </a>

        <ul *ngIf="user" id="perustieto-list" class="list-group list-tieto">
          <li class="list-group-item border-0">
            <div class="row">

              <ng-container *ngIf="accountService.isHenkiloToimija()">
                <div class="col-12">
                  <p>{{user.wholeName}}</p>
                </div>

                <ng-container *ngIf="user.maatila">
                  <div class="col-12">
                    <p>{{user.maatila.tilanNimi}}</p>
                  </div>
                  <div class="col-12">
                    <p>{{user.maatila.maatilatunnus}}</p>
                  </div>
                </ng-container>

                <div class="col-12">
                  <strong class="text-primary" attribute="kayttajatili.toimiala">Toimiala</strong>
                  <p *ngIf="user.toimiala">
                    <span>{{user.toimialaTeksti.getFullKey() | attribute}}</span>
                  </p>
                  <p *ngIf="!user.toimiala">-</p>
                </div>

                <div *ngIf="user.valvontakohdeElmoId && toimiala !== 'ELAIN_LABORATORIO'" class="col-12">
                  <strong class="text-primary" attribute="kayttajatili.toimipaikka">Toimipaikka</strong>
                  <p *ngIf="user.valvontakohde">
                    <span>{{user.valvontakohde.toimipaikka.nimi_fi}} </span>
                    <span>({{user.valvontakohdeElmoId}})</span>
                  </p>
                  <p *ngIf="!user.valvontakohde">
                    <span [attribute]="'lomakeYleinen.eiAktiivinen'">Ei aktiivinen</span>
                  </p>
                </div>

              </ng-container>

              <ng-container *ngIf="accountService.isYritysToimija()">
                <div class="col-12">
                  <p>{{user.wholeName}}</p>
                </div>
                <div class="col-12">
                  <p>{{user?.yritys?.paaToiminimi}}</p>
                </div>

                <div class="col-12">
                  <strong class="text-primary" attribute="kayttajatili.toimiala">Toimiala</strong>
                  <p *ngIf="user.toimiala">
                    <span>{{user.toimialaTeksti.getFullKey() | attribute}}</span>
                  </p>
                  <p *ngIf="!user.toimiala">-</p>
                </div>

                <div *ngIf="toimiala !== 'ELAIN_LABORATORIO'" class="col-12">
                  <strong class="text-primary" attribute="kayttajatili.toimipaikka">Toimipaikka</strong>
                  <p *ngIf="user.valvontakohde">
                    <span>{{user.valvontakohde.toimipaikka.nimi_fi}} </span>
                    <span>({{user.valvontakohdeElmoId}})</span>
                  </p>
                  <p *ngIf="!user.valvontakohde && user.valvontakohdeElmoId">
                    <span [attribute]="'lomakeYleinen.eiAktiivinen'">Ei aktiivinen</span>
                  </p>
                  <p *ngIf="!user.valvontakohdeElmoId">-</p>
                </div>

                <div *ngIf="toimiala === 'KATE'" class="col-12">
                  <strong class="text-primary" attribute="kayttajatili.kasvinterveysrekisteritunnus"></strong>
                  <p *ngIf="user.valvontakohde?.kasvinterveysRekisteritiedot.rekisterinumero">FI-{{user.valvontakohde?.kasvinterveysRekisteritiedot.rekisterinumero}}</p>
                  <p *ngIf="!user.valvontakohde?.kasvinterveysRekisteritiedot.rekisterinumero">-</p>
                </div>
              </ng-container>

              <ng-container
                *ngIf="user.kayttajaRooli === KayttajaRooli.VIRANOMAINEN || user.kayttajaRooli === KayttajaRooli.ELY_VIRANOMAINEN">
                <div class="col-12">
                  <strong class="text-primary" attribute="kayttajatili.toimiala">Toimiala</strong>
                  <p *ngIf="user.toimiala">
                    <span>{{user.toimialaTeksti.getFullKey() | attribute}}</span>
                  </p>
                  <p *ngIf="!user.toimiala">-</p>
                </div>
              </ng-container>
            </div>
          </li>
        </ul>
      </li>

      <ng-container *ngIf="accountService.isViranomainen()">
        <li *ngFor="let vkLink of vkLinks">
          <a id="sp-{{vkLink.title.key}}-virkailija" [routerLink]="vkLink.link"
             [class.active-main-link]="router.isActive(vkLink.link, false)">
            <em class="fa fa-angle-right"></em>
            <span class="nav-label" [attribute]="vkLink.title.getFullKey()"></span>
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="accountService.isAsiakas()">

        <ul>
          <li class="sidebar_link">
            <a [routerLink]="'/asiointi/etusivu'">
              <span class="nav-label" attribute="sivupalkki.toimipaikanAsiointi">Toimipaikan asiointi</span>
              <em class="fa fa-angle-right align-top pt-1 float-right"></em>
            </a>
          </li>
          <li class="px-2 mt-2 mb-4">
            <vaihda-toimipaikka [htmlId]="'sidebar-account-vaihda-tiedot'"
                                styles="btn btn-outline-primary btn-sm btn-block"
                                title="sivupalkki.vaihdaToimipaikka"></vaihda-toimipaikka>
          </li>
        </ul>

        <ng-container *ngFor="let link of links">
          <li *ngIf="link.raporttiLinks.length > 0">
          <span id="sp-{{link.title.key}}-raportit" class="sidebar-heading"
                [attribute]="link.raporttiTitle.getFullKey()"></span>
            <ul>
              <li *ngFor="let raporttiLink of link.raporttiLinks" class="sidebar_link">
                <a id="sp-{{raporttiLink.title.key}}-raportti" [routerLink]="raporttiLink.link"
                   [class.active-main-link]="router.isActive(raporttiLink.link, false)">
                  <span class="nav-label" [attribute]="raporttiLink.title.getFullKey()"></span>
                  <em class="fa fa-angle-right align-top pt-1 float-right"></em>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="link.lomakeLinks.length > 0 && !lomakeLinksPiilotettu(link.lomakeLinks)">
          <span *ngIf="link.title" id="sp-{{link.title.key}}-ilmoitukset" class="sidebar-heading"
                [attribute]="link.title.getFullKey()"></span>
            <ul>
              <ng-container *ngFor="let lomakeLink of link.lomakeLinks">
                <li *ngIf="lomakeLink.tila !== 'PIILOTETTU' && lomakeLink.sublinks.length === 0" class="sidebar_link">
                  <a tabindex="0" id="sp-{{lomakeLink.title.key}}" (click)="clickLink(lomakeLink)"
                     [ngClass]="{'disabled': lomakeLink.tila === 'EI_AKTIIVINEN'}">
                    <div class="d-inline-block">
                      <span class="nav-label" [attribute]="lomakeLink.title.getFullKey()"></span>
                      <span *ngIf="lomakeLink.tila === 'EI_AKTIIVINEN'" class="nav-label small d-block">
                      <em attribute="sivupalkki.lomakeKasittelyssa">Lomake käsittelyssä</em>
                    </span>
                    </div>
                    <em class="fa fa-angle-right align-top pt-1 float-right"></em>
                  </a>
                </li>

                <li>
                  <ul *ngIf="lomakeLink.tila !== 'PIILOTETTU' && lomakeLink.sublinks.length > 0" class="sidebar_link">
                    <li>
                      <a tabindex="0" id="sp-{{lomakeLink.title.key}}" (click)="clickHeaderLink(lomakeLink)"
                         [ngClass]="{'disabled': lomakeLink.tila === 'EI_AKTIIVINEN'}">
                        <div class="d-inline-block">
                          <span class="nav-label" [attribute]="lomakeLink.title.getFullKey()"></span>
                        </div>
                        <em class="fa align-top pt-1 float-right"
                            [ngClass]="{'fa-angle-right': lomakeLink.sublinks[0].isCollapsed, 'fa-angle-down': !lomakeLink.sublinks[0].isCollapsed}"></em>
                      </a>
                    </li>
                    <ng-container *ngFor="let subLink of lomakeLink.sublinks">
                      <li *ngIf="!subLink.isCollapsed">
                        <a tabindex="0" [ngClass]="{'disabled': subLink.tila === 'EI_AKTIIVINEN'}"
                           id="sp-{{subLink.title.key}}" (click)="clickLink(subLink)">
                          <div class="d-inline-block pl-3">
                            <span class="nav-label" [attribute]="subLink.title.getFullKey()"></span>
                            <span *ngIf="subLink.tila === 'EI_AKTIIVINEN'" class="nav-label small d-block">
                            <em attribute="sivupalkki.lomakeKasittelyssa">Lomake käsittelyssä</em>
                          </span>
                          </div>
                          <em class="fa fa-angle-right align-top pt-1 float-right"></em>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-container>

    </ul>
  </div>
</aside>
