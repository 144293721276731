import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuestionAndAnswerSummaryComponent} from './question-and-answer/question-and-answer-summary.component';
import {FieldGroupSummaryComponent} from './field-summary/field-group-summary.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AttributeModule} from "../../tiira/attribute.module";
import {YhteyshenkiloSummaryComponent} from "./yhteyshenkilo-summary/yhteyshenkilo-summary.component";
import {SummaryContainerComponent} from "./summary-container.component";
import {SummaryComponentHostDirective} from "./summary-component-host.directive";
import {
  TitleH3SummaryComponent,
  TitleH4SummaryComponent,
  TitleH5SummaryComponent,
  TitleSummaryComponent
} from "./title-summary/title-summary.component";
import {ExcelSummaryComponent} from "./excel-summary.component";
import {ToukoComponentsModule} from "../../touko-components/touko-components.module";
import {FieldGroupSummaryCompareComponent} from "./field-summary-compare/field-group-summary-compare.component";
import {SummaryAllekirjoitusComponent} from "./summary-allekirjoitus.component";
import {TitleAndAnswerSummaryComponent} from "./title-and-answer-summary.component";
import {TitleAndInfoSummaryComponent} from "./title-and-info-summary.component";
import {CompareItemComponent} from "./compare-item.component";
import {QuestionAndTranslatedAnswerSummaryComponent} from "./question-and-translated-answer-summary.component";
import {LiiteSummaryComponent} from "./liite-summary.component";
import {TranslatedQuestionSummaryComponent} from "./translated-question-summary.component";
import {TranslatedQuestionAndAnswerWithInfoSummaryComponent} from "./translated-question-and-answer-with-info-summary.component";
import {AnswerOnlySummaryComponent} from "./answer-only-summary.component";
import {TranslatedQuestionAndAnswerTwoRowsSummaryComponent} from "./translated-question-and-answer-two-rows-summary.component";
import {QuestionAndAnswerKoneetJaLaitteetComponent} from "./question-and-answer-koneet-ja-laitteet.component";
import {TitleWithBadgeSummaryComponent} from "./title-summary/title-with-badge-summary.component";
import {QuestionOnlySummaryComponent} from "./question-only-summary.component";
import {PeltolohkoSummary} from "./luomuvalvonta/peltolohko-summary";
import {KasvituotteetSummary} from "./luomuvalvonta/kasvituotteet-summary.component";
import {ToukoInputsModule} from "../../touko-inputs/touko-inputs.module";
import {KateTableSummaryComponent} from "./kate-table-summary.component";
import {SummaryService} from "./field-summary/summary.service";
import {NgbCollapse} from "@ng-bootstrap/ng-bootstrap";
import {TitleAndTranslatedAnswerSummaryComponent} from "./title-and-translated-answer-summary.component";
import {YhteyshenkiloSimpleSummaryComponent} from "./yhteyshenkilo-summary/yhteyshenkilo-simple-summary.component";
import {ElaintuotteetSummaryComponent} from "./luomuvalvonta/elaintuotteet-summary.component";
import {TilanElaimetSummaryComponent} from "./luomuvalvonta/tilan-elaimet-summary.component";


@NgModule({
  declarations: [
    AnswerOnlySummaryComponent,
    QuestionAndAnswerSummaryComponent,
    FieldGroupSummaryComponent,
    FieldGroupSummaryCompareComponent,
    YhteyshenkiloSummaryComponent,
    TitleSummaryComponent,
    TitleH3SummaryComponent,
    TitleH4SummaryComponent,
    TitleH5SummaryComponent,
    TitleWithBadgeSummaryComponent,
    SummaryComponentHostDirective,
    SummaryContainerComponent,
    ExcelSummaryComponent,
    SummaryAllekirjoitusComponent,
    TitleAndAnswerSummaryComponent,
    TitleAndInfoSummaryComponent,
    CompareItemComponent,
    QuestionAndTranslatedAnswerSummaryComponent,
    LiiteSummaryComponent,
    TranslatedQuestionSummaryComponent,
    TranslatedQuestionAndAnswerWithInfoSummaryComponent,
    TranslatedQuestionAndAnswerTwoRowsSummaryComponent,
    QuestionAndAnswerKoneetJaLaitteetComponent,
    QuestionOnlySummaryComponent,
    PeltolohkoSummary,
    KasvituotteetSummary,
    TitleAndTranslatedAnswerSummaryComponent,
    YhteyshenkiloSimpleSummaryComponent,
    KateTableSummaryComponent,
    ElaintuotteetSummaryComponent,
    TilanElaimetSummaryComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AttributeModule,
    ToukoComponentsModule,
    ToukoInputsModule,
    NgbCollapse
  ],
  exports: [
    FieldGroupSummaryComponent,
    FieldGroupSummaryCompareComponent,
    SummaryAllekirjoitusComponent
  ],
  providers: [
    SummaryService
  ]
})
export class SummaryModule {
}
