

export interface Kasvilaji {
  nimi: string;
  elmoid: string;


}

export function KasvilajiSorter(a: Kasvilaji, b: Kasvilaji): number {
  if (!a || !a.nimi) {
    return -1;
  }

  if (!b || !b.nimi) {
    return 1;
  }

  return (a.nimi || "").localeCompare(b.nimi || "");
}
