import {Component, ElementRef, forwardRef, Input, Renderer2, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {QuestionComponent, QuestionComponentData} from "ee-lahete";

@Component({
  selector: 'question-checkbox-button',
  template: `
    <div class="form-group">
    <label class="btn btn-sm" [htmlFor]="'cbb-'+questionData.htmlId" [ngClass]="formValue ? 'btn-primary' : 'btn-outline-primary'">
      <input #checkboxbutton [checked]="formValue" class="d-none" [id]="'cbb-'+questionData.htmlId" type="checkbox"
             (change)="setValue($event.target.checked)"> {{questionData.label}}
    </label>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QuestionCheckboxButtonComponent)
    }
  ]
})
export class QuestionCheckboxButtonComponent extends QuestionComponent implements ControlValueAccessor {
  @Input() questionData: QuestionComponentData;
  @ViewChild('checkboxbutton', {static: true}) checkboxbutton: ElementRef;
  isDisabled = false;
  formValue = false;

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  constructor(private renderer: Renderer2) {
    super();
  }

  setValue(value: boolean) {
    this.formValue = Boolean(value);
    this._onChange(value);
  }

  writeValue(value: any): void {
    this.formValue = Boolean(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.renderer.setProperty(this.checkboxbutton.nativeElement, 'disabled', isDisabled);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
