import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LaheteYhteenvetoComponent} from './lahete-yhteenveto.component';
import {LaheteYhteenvetoGroupComponent} from './lahete-yhteenveto-group.component';
import {LaheteYhteenvetoService} from "./lahete-yhteenveto.service";


@NgModule({
  declarations: [
    LaheteYhteenvetoComponent,
    LaheteYhteenvetoGroupComponent
  ],
  exports: [
    LaheteYhteenvetoComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    LaheteYhteenvetoService
  ]
})
export class LaheteYhteenvetoModule { }
