import {Component, Input, OnInit} from "@angular/core";
import {RaporttiBaseClass} from "../raportti-base";
import {RaporttiHaku, Sarake, SarakeType, Tag} from "../raportti.utils";
import {Teksti} from "../../utils/teksti";
import {TulosraporttiService} from "../raportti.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {Syote} from "../../touko-lomake/syote/syote";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";

/**
 * Created by Seppo on 22/05/2018.
 */

const VIEW = "raportit";

@Component({
  selector: "rehuvalvonta-raportti",
  templateUrl: "./rehuvalvonta-raportti.component.html"
})
export class RehuvalvontaRaporttiComponent extends RaporttiBaseClass implements OnInit {

  @Input() getRaportitFn: (...any: any[]) => Promise<RaporttiHaku>;
  keys: any;
  liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  raporttiForm: FormGroup;
  tapahtumatyypitTags: Tag[] = [];
  tarkastustyypitTags: Tag[] = [];
  tilaTags: Tag[] = [];
  toimiala = "REHU";


  syotteet: { [s: string]: Syote };

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
      this.fb.group({
        tunnus: null,
        kuvaus: null,
        tila: [],
        tarkastustyyppi: [],
        tapahtumatyyppi: [],
        pvmAlku: [null, ToukoValidation.isValidDate],
        pvmLoppu: [null, ToukoValidation.isValidDate]
      })
    );

    this.syotteet = {
      tapahtumatyyppi: new Syote(new Teksti('Tapahtumatyyppi', 'tapahtumatyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTapahtumatyyppi', VIEW)}),
      tarkastustyyppi: new Syote(new Teksti('Tarkastustyyppi', 'tarkastustyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTarkastustyyppi', VIEW)}),
      tila: new Syote(new Teksti('Tila', 'tila', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTila', VIEW)}),
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };

    this.keys = {
      tunnus: new Sarake('tunnus', new Teksti(VIEW, 'tunnus', VIEW), SarakeType.DEFAULT, () => "no-word-break"),
      kuvaus: new Sarake('kuvaus', new Teksti(VIEW, 'kuvaus', VIEW)),
      pvm: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      dokumentti: this.getAsiakirjatSarakeTitle('asiakirjat'),
      tapahtumaliitos: new Sarake('tapahtumaliitos', new Teksti(VIEW, 'tapahtumaliitos', VIEW), SarakeType.DEFAULT, () => "no-word-break"),
      tila: new Sarake('tilaNimi', new Teksti(VIEW, 'tila', VIEW)),
      tarkastustyyppi: new Sarake('tarkastustyyppiNimi', new Teksti(VIEW, 'tarkastustyyppi', VIEW)),
      raporttityyppi: new Sarake('tapahtumatyyppi', new Teksti(VIEW, "tapahtumatyyppi", VIEW), SarakeType.TYPE, () => 'badge badge-primary')
    };

    this.columnOrder = [
      this.keys.tunnus,
      this.keys.kuvaus,
      this.keys.pvm,
      this.keys.tila,
      this.keys.tarkastustyyppi,
      this.keys.tapahtumaliitos,
      this.keys.raporttityyppi,
      this.keys.dokumentti
    ];

    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getRehuvalvontaRaportit.bind(this.tulosraporttiService);
    this.liiteDownloadFn = this.tulosraporttiService.getRehuvalvontaLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getRehuvalvontaLiiteZip.bind(this.tulosraporttiService);

    this.initRaportit().then(() => {
      this.initTags();
    });
  }

  initTags() {
    this.tapahtumatyypitTags = this.mapField(r => new Tag(r.tapahtumatyyppiNimi, r.tapahtumatyyppi));
    this.tarkastustyypitTags = this.mapField(r => new Tag(r.tarkastustyyppiNimi, r.tarkastustyyppiNimi));
    this.tilaTags = this.mapField(r => new Tag(r.tilaNimi, r.tilaNimi));
  }
}
