import {Component, Input, OnInit} from "@angular/core";
import {ControlContainer, FormArray} from "@angular/forms";
import {
  QuestionArray,
  QuestionComponent,
  QuestionComponentData,
  QuestionGroup,
  QuestionUpdateType
} from "ee-lahete";
import {Subscription} from "rxjs";
import {NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {QuestionNayteModalComponent} from "./question-nayte-modal.component";
import {NautarekisterihakuModalComponent} from "./nautarekisterihaku-modal.component";
import {DateObj} from "../../../touko-lomake/syote/syote-utils";
import {ElaintietoResponse} from "../../touko-lahete.service";
import {NayteTutkimusValue, NayteTutkimusData} from "../../lahete/lahete-data.model";
import {LaheteService} from "../../lahete/lahete.service";

@Component({
  selector: 'question-naytetiedot',
  templateUrl: 'question-naytetiedot.component.html'
})
export class QuestionNaytetiedotComponent extends QuestionComponent implements OnInit {
  private static maxIndex = 0;
  private _index = 0;
  // uniikki ID htmllän puolelle kenttiin. 
  public readonly currDateInMs: string = new Date().getTime().toString();
  @Input() index;
  @Input() questionData: QuestionComponentData;
  @Input() questionParent: QuestionArray;
  @Input() isLast = false;
  @Input() sivuRef: string;

  questionGroup: QuestionGroup;
  sukupuoli = null;
  valitutTutkimukset: Tutkimus[] = [];
  private readonly nayteSubs: Subscription[] = [];
  private naytteetSub: Subscription;

  private static updateTutkimusDetails(tutkimusToUpdate: Tutkimus, nayteValue: NayteTutkimusValue) {
    tutkimusToUpdate.nayteTutkimus.value.naytemateriaali = nayteValue.naytemateriaali;
    tutkimusToUpdate.nayteTutkimus.value.naytePvm = nayteValue.naytePvm;
  }

  constructor(public controlContainer: ControlContainer,
              private readonly ngbModalService: NgbModal,
              private readonly laheteService: LaheteService) {
    super();
  }

  ngOnInit(): void {
    this.initNaytetietoIndex();
    this.questionGroup = this.questionData.question as QuestionGroup;

    const importedTutkimukset = this.questionGroup.options.tutkimukset as unknown as Array<NayteTutkimusData>;
    const addedTutkimukset = new Set(this.questionGroup.array.questions.map(q => q.find(['tutkimusId']).initialValue));

    this.valitutTutkimukset = importedTutkimukset.map((t, index) => new Tutkimus(t, index.toString(), addedTutkimukset.has(t.tutkimus.value)));

    this.naytteetSub = this.controlContainer.control.get('naytteet').valueChanges.subscribe(val => {
      const currentControls = new Set(val.map(v => v.tutkimusId));
      const currentModel = this.valitutTutkimukset.filter(vt => vt.isAdded); // .map(vt => vt.nayteTutkimus.tutkimus.value);
      if (currentControls.size < currentModel.length) {
        currentModel.forEach(model => {
          if (!currentControls.has(model.nayteTutkimus.tutkimus.value)) {
            model.isAdded = false;
          }
        });
        this.updateTutkimuksetContainsSelectedNaytemateriaali();
        this.subscribeToTutkimusNaytemateriaaliChange();
      }
    });

    const isEmptyNaytteet = this.questionGroup.array.questions && this.questionGroup.array.questions.length === 0;
    if (this.valitutTutkimukset.length === 1 && isEmptyNaytteet) {
      this.addNewNayte(this.valitutTutkimukset[0]);
    }
  }

  initNaytetietoIndex() {
    if (this.index === 0) {
      QuestionNaytetiedotComponent.maxIndex = 0;
    } else {
      QuestionNaytetiedotComponent.maxIndex++;
    }
    this._index = QuestionNaytetiedotComponent.maxIndex;
  }

  addFieldToParent(groupValues?: any) {
    this.questionParent.actions.addNaytetieto(undefined, this.questionGroup.options.tutkimukset, QuestionUpdateType.ADD, groupValues);
  }

  copyFieldToParent(copies = "1") {
    const copyCount = parseInt(copies, 10);
    this.questionParent.actions.copyNaytetieto(
      copyCount,
      QuestionNaytetiedotComponent.maxIndex + 1,
      this.questionGroup.options.tutkimukset,
      this.controlContainer.control.value
    );
  }

  removeCurrentNaytetieto() {
    this.questionGroup.actions.removeNaytetieto(this.questionGroup.fullPath, 1);
    if (this.isLast) {
      QuestionNaytetiedotComponent.maxIndex--;
    }
  }

  addNayte(tutkimus: Tutkimus, tunnus?: string) {
    this.questionGroup.actions.addNayte(tutkimus.nayteTutkimus, this.questionGroup.key, tunnus);
  }

  addNewNayte(tutkimus: Tutkimus) {
    tutkimus.isAdded = true;
    this.addNayte(tutkimus, `${this._index + 1}-${parseInt(tutkimus.nayteTutkimus.nayte.value, 10) + 1}`);
    this.updateTutkimuksetContainsSelectedNaytemateriaali();
    this.subscribeToTutkimusNaytemateriaaliChange();
  }

  addNayteForExisting(tutkimus: Tutkimus) {
    const tutkimusNaytemateriaalit = new Set(tutkimus.nayteTutkimus.naytemateriaalit.map(n => n.value));
    const naytteetWithUniqueId = this.getNayteValuesWithNaytemateriaali()
      .filter(c => tutkimusNaytemateriaalit.has(c.naytemateriaali))
      .reduce((prev: NayteTutkimusValue[], current) => {
        if (prev.some(p => p.nayteTunnus === current.nayteTunnus)) {
          return prev;
        }
        prev.push(current);
        return prev;
      }, []);

    if (naytteetWithUniqueId.length === 1) {
      tutkimus.isAdded = true;
      QuestionNaytetiedotComponent.updateTutkimusDetails(tutkimus, naytteetWithUniqueId[0]);
      this.addNayte(tutkimus, naytteetWithUniqueId[0].nayteTunnus);
    } else if (naytteetWithUniqueId.length > 1) {
      this.openNayteModal(tutkimus, naytteetWithUniqueId).then(result => {
          if (result) {
            tutkimus.isAdded = true;
            const val = result as NayteTutkimusValue;
            QuestionNaytetiedotComponent.updateTutkimusDetails(tutkimus, val);
            this.addNayte(tutkimus, val.nayteTunnus);
          }
      });
    }
  }

  updateTutkimuksetContainsSelectedNaytemateriaali() {
    Promise.resolve().then(() => {
      this.valitutTutkimukset.forEach(t => {
        t.canAddToExisting = this.tutkimusContainsSelectedNaytemateriaali(t);
      });
    });
  }

  subscribeToTutkimusNaytemateriaaliChange() {
    this.nayteSubs.forEach(s => s.unsubscribe());

    const naytteet = this.controlContainer.control.get('naytteet') as FormArray;
    naytteet.controls.forEach(c => {
      this.nayteSubs.push(c.get('naytemateriaali').valueChanges.subscribe(() => this.updateTutkimuksetContainsSelectedNaytemateriaali()));
    });
  }

  tutkimusContainsSelectedNaytemateriaali(tutkimus: Tutkimus): boolean {
    const selected = new Set(this.getNayteValuesWithNaytemateriaali().map(c => c.naytemateriaali.toString()));

    const tutkimusNaytemateriaalit: string[] = tutkimus.nayteTutkimus.naytemateriaalit.map(n => n.value.toString());
    return tutkimusNaytemateriaalit.some(tn => selected.has(tn));
  }

  getNayteValuesWithNaytemateriaali(): NayteTutkimusValue[] {
    const naytteet = this.controlContainer.control.get('naytteet') as FormArray;
    return naytteet.controls
      .filter(c => c.value.naytemateriaali)
      .map(c => c.value as NayteTutkimusValue);
  }

  openNayteModal(currentTutkimus: Tutkimus, selectOptions: NayteTutkimusValue[]): Promise<any> {
    const modalRef = this.ngbModalService.open(QuestionNayteModalComponent);
    modalRef.componentInstance.currentTutkimus = currentTutkimus.nayteTutkimus.tutkimus.label;
    modalRef.componentInstance.selectOptions = selectOptions;
    return modalRef.result;
  }

  handleNautarekisterihakuModal() {
    const lomakkeenElaimet = new Set(this.controlContainer.control.parent.value.map(elain => elain.euTunnus));
    const modalRef = this.ngbModalService.open(NautarekisterihakuModalComponent);
    const pitopaikanElaimet = this.laheteService.getStoredValue('pitopaikanelaimet');
    modalRef.componentInstance.pitopaikanElaimet = pitopaikanElaimet;
    const aiemminValitutElaimet: ElaintietoResponse[] = pitopaikanElaimet.filter(pitopaikanElain => lomakkeenElaimet.has(pitopaikanElain.euTunnus));
    modalRef.componentInstance.valitutElaimet = aiemminValitutElaimet;
    modalRef.componentInstance.aiemminValitutElaimet = aiemminValitutElaimet;
    const res = modalRef.result;

    const createDateObj = (syntymaaika: string): DateObj => { 
      const dateParts = syntymaaika.split("-", 3); 
      if (dateParts?.length === 3) {
return new DateObj(+dateParts[2], +dateParts[1], +dateParts[0]);
}
      };

    if (res) {
        const result = res.then(elaimet => elaimet
          .map((elain: ElaintietoResponse) => elain))
          .then((elainMap: ElaintietoResponse[]) => {
            //poistetaan mahdolliset duplikaatit
            const filteredList = [];
            elainMap.forEach(elain => {
              if (!aiemminValitutElaimet.some(aiemminValittuElain => aiemminValittuElain.euTunnus === elain.euTunnus)) {
                filteredList.push(elain);
              }
            });
            return filteredList;
          });

        result.then(elaimet => { 
          elaimet.forEach((elain: ElaintietoResponse, index: number) => {
          if (index === 0 && !this.controlContainer.control.get('euTunnus').value) {
            this.controlContainer.control.get('euTunnus').setValue(elain.euTunnus);
            this.controlContainer.control.get('korvanumero').setValue(elain.korva);
            this.controlContainer.control.get('tunniste').setValue(elain.nimi);
            this.controlContainer.control.get('syntymaaika').setValue(createDateObj(elain.syntymaPvm));
          } else {
            const elainValues = { euTunnus: elain.euTunnus, korvanumero: elain.korva, tunniste: elain.nimi, syntymaaika: createDateObj(elain.syntymaPvm) };
            setTimeout(() => {
              this.addFieldToParent(elainValues);
            }, 1);
          }
        });
      }).catch(r => {
          // on cancel, nop
      } );
  }
}

  // Näytetäänkö "Hae eläimen tiedot" nappia
  nautaElainhaku(): boolean {
   return this.laheteService.getStoredValue('nautaElainhaku') && this.laheteService.getStoredValue('pitopaikanelaimet')?.length > 0;
  }

  getMinBirthDate(): NgbDateStruct {
    return {year: new Date().getFullYear() - 20, day: 1, month: 0};
  }

  getMaxBirthDate(): NgbDateStruct {
    const date = new Date();
    return {year: date.getFullYear(), day: date.getDate(), month: date.getMonth() + 1};
  }

}

class Tutkimus {
  nayteTutkimus: NayteTutkimusData;
  isAdded: boolean;
  canAddToExisting: boolean;

  constructor(nayteTutkimus: NayteTutkimusData, nayteId, isAdded = false) {
    this.nayteTutkimus = nayteTutkimus;
    this.nayteTutkimus.nayte.value = nayteId;
    this.isAdded = isAdded;
    this.canAddToExisting = false;
  }
}
