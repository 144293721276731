import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DateObj} from "../../syote/syote-utils";
import {EnvironmentService, Feature} from "../../../../environments/environment.service";
import {BehaviorSubject} from "rxjs";
import {MaatilaService} from "../../../maatila/maatila.service";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {ReadAccount} from "../../../account/account.service";

@Injectable()
export class LuomuvalvontaService {

  constructor(private httpClient: HttpClient,
              private readonly environmentService: EnvironmentService,
              private maatilaService: MaatilaService,
              private koodistoService: KoodistoService) {
  }

  private peltolohkotSubject = new BehaviorSubject<string[]>([]);
  peltolohkot$ = this.peltolohkotSubject.asObservable();

  updatepeltolohkot(newArray: string[]) {
    this.peltolohkotSubject.next(newArray);
  }

  public async getElyKeskusByKuntakoodi(kuntakoodi: string) {
    return await this.koodistoService.getElyKeskusByKuntakoodi(kuntakoodi);
  }

  public async getElyKeskukset() {
    return await this.koodistoService.getElyKeskukset();
  }

  public async getMaatilat(tilatunnus?: string) {
    return await this.maatilaService.getMaatilat(ReadAccount.getRole(), tilatunnus);
  }

  public getKasvulohkot(tilatunnus) {
    const yearModifier = this.environmentService.isFeatureEnabled(Feature.DEV_FEATURE) ? 2 : 1;  // Kehi/Testi:ssä 2
                                                                                                 // vuoden takaiset
                                                                                                 // tiedot
    return this.httpClient.get(`/api/v1/as/maatila/${tilatunnus}/peltolohkot/${DateObj.today().year - yearModifier}`).toPromise();
  }
}
