import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from "@angular/core";
/**
 * Created by Seppo on 24/04/2018.
 */

@Directive({
  selector: '[sort-arrow]'
})
export class SortArrowDirective implements OnChanges, OnInit {

  private static ARROW_UP = 'ml-1 fa fa-caret-up';
  private static ARROW_DOWN = 'ml-1 fa fa-caret-down';
  private static INVISIBLE = 'invisible ml-1 fa fa-caret-down';

  @Input('sort-arrow') sortArrow = 0; // Arrow direction
  arrowElement: any;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.arrowElement = document.createElement('em');
    this.renderer.appendChild(this.elementRef.nativeElement, this.arrowElement);
    this.setIcon();
  }

  ngOnChanges(change: SimpleChanges): void {
    if (!change['sortArrow'].firstChange) {
      this.setIcon();
    }
  }

  setIcon() {
    if (this.sortArrow > 0) {
      this.renderer.setAttribute(this.arrowElement, 'class', SortArrowDirective.ARROW_UP);
    } else if (this.sortArrow < 0) {
      this.renderer.setAttribute(this.arrowElement, 'class', SortArrowDirective.ARROW_DOWN);
    } else {
      this.renderer.setAttribute(this.arrowElement, 'class', SortArrowDirective.INVISIBLE);
    }
  }
}
