import {Component, Input} from '@angular/core';

@Component({
  selector: 'login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss']
})
export class LoginCardComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() iconStyle: string;
}
