import {Component, Input} from '@angular/core';
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'app-question-and-answer-koneetjalaitteet-indentation',
  template: `
      <div *ngIf="item" class="row" [ngClass]="{'pb-2': !compareItemType}">
          <div *ngIf="!compareItemType" class="col-lg-4 col-5 pl-5">
              <span>{{item?.options?.laiteNumber}}: &nbsp;</span>
              <span [id]="'value-' + item?.options?.checkboxHtmlId" [attribute]="item?.question"></span>
          </div>
          <div class="col">
              <compare-item [compareItemType]="compareItemType">
                  <span [id]="'value-' + item?.options?.dateHtmlId" class="answer">{{item?.answer}}&nbsp;</span>
                  <span [attribute]="item?.options?.valueSuffix" class="answer"></span>
              </compare-item>
          </div>
      </div>
  `,
  styleUrls: ['./summary.scss']
})
export class QuestionAndAnswerKoneetJaLaitteetComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;

}
