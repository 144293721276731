<h3 *ngIf="!item.options.isMuutosIlmoitus" [attribute]="item.label.fullKey">Lannoitevalmisteen tuotanto maatilalla omaan
  käyttöön</h3>
<lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                     [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
<ng-container *ngFor="let lannoiteAines of item.subitems">
  <div class="row" *ngFor="let lannoiteAine of lannoiteAines.subitems">
    <ng-container *ngIf="lannoiteAine !== null">
      <div class="col-full col-lvl-1">
        <h4 [attribute]="lannoiteAine.label.fullKey">Orgaaniset lannoitteet</h4>
      </div>

      <ng-container *ngIf="lannoiteAine.valueMap?.maara">
        <div class="col-full col-lvl-2">
          <h5 attribute="lannoiterekisteri.arvioidutMaaratYhteenveto">Arvioidut määrät</h5>
        </div>
        <div class="col-left col-lvl-2">
          <p attribute="lannoiterekisteri.arvioVuosittaisistaValmistusmaarista">
            Vuosittainen valmistusmäärä
          </p>
        </div>
        <div class="col-right">
          <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
            <span class="mr-1"
                  id="{{item.label.key}}-{{lannoiteAine.label.key}}">{{lannoiteAine.valueMap?.maara?.label}}</span>
            <span attribute="lannoiterekisteri.kgVuosi"></span>
          </div>
        </div>
      </ng-container>

      <div class="col-full col-lvl-2"
           *ngIf="lannoiteAine?.valueMap?.omaKaytto || lannoiteAine?.valueMap?.vainPakkaamista">
        <h5 attribute="lannoiterekisteri.valmistus">Valmistus</h5>
      </div>
      <ng-container *ngIf="lannoiteAine?.valueMap?.vainPakkaamista">
        <div class="col-left col-lvl-2">
          <p attribute="lannoiterekisteri.lannoitteenValmistusAinoastaanPakkaamista"></p>
        </div>
        <div class="col-right">
          <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
            <span id="{{lannoiteAine.label.key}}-lannoitteenValmistusAinoastaanPakkaamista"
                  [attribute]="lannoiteAine?.valueMap?.vainPakkaamista?.fullKey"></span>
          </div>
        </div>
      </ng-container>

      <div class="col-full col-lvl-2 pb-3">
        <h5 attribute="lannoiterekisteri.ainesosaluokatYhteenveto">Ainesosaluokat</h5>
        <ul class="list-unstyled ml-4 mb-3" *ngFor="let cmc of lannoiteAine.subitems">
          <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
            <span
              id="ainesosaluokka-{{lannoiteAine.label.key}}-{{cmc.label.key}}" [attribute]="cmc.label.fullKey">
            </span>
          </div>
        </ul>
      </div>
    </ng-container>
  </div>
</ng-container>
