import {Directive, OnInit} from "@angular/core";
import {AccountService} from "../../../../account/account.service";
import {ProcessLomakeService} from "./process-lomake.service";
import {LomakeV2Data} from "./lomake-v2-data";
import {LomakeInfo, LomakeMetaData} from "../lomake-info";
import {LiitetiedostoResponse} from "../../../touko-lomake-utils";
import {FieldGroup} from "ngx-fielding";
import {LomakeProcess} from "../lomake-fields/lomake-process";
import {PerustiedotValidator} from "../lomake-fields/perustiedot";
import {Account} from "../../../../account/account";

@Directive()
export abstract class LomakeV2BaseDirective implements OnInit {

  public lomake: FieldGroup;
  public isPerustiedotValid: boolean;

  public lomakeInfo: LomakeInfo;
  protected liitetiedostot: { [key: string]: LiitetiedostoResponse[] } = {};
  public lomakeMetaData: LomakeMetaData;

  protected constructor(protected readonly processLomakeService: ProcessLomakeService,
                        protected readonly data: LomakeV2Data,
                        protected readonly accountService: AccountService) {
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  abstract initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo;

  abstract initLomakeBehaviour();

  abstract addLomakeBehaviour();

  public initializeComponent() {
    var account = this.accountService.getCurrentAccount();
    var rooli: "vk" | "as" = Account.isViranomainen(account) ? "vk" : "as";
    this.lomakeInfo = this.initLomakeInfo(
      rooli,
      this.processLomakeService.getUrlInformation().toimintotyyppi
    );
    this.lomakeMetaData = new LomakeMetaData(account);
    return this.data.getForm(this.lomakeInfo.toimintotyyppi)
      .then(form => {
        this.lomake = form
        this.initLomakeBehaviour();
      })
      .then(() => this.getLomake()
        .then(response => {
          const sisalto = LomakeProcess.preProcessSisalto(response);
          this.lomake.initValue(sisalto);
          if (rooli === "vk") {
            this.lomakeMetaData = new LomakeMetaData(account, response);
          }

          LomakeProcess.postProcessLomake(this.lomake, this.lomakeInfo, response, this.lomakeMetaData.account);
        })
        .then(() => {
          const perustiedotValidator = new PerustiedotValidator(this.lomake, this.lomakeInfo.skipPerustiedotValidation);
          perustiedotValidator.isPerustiedotValid$.subscribe(isValid => {
            this.isPerustiedotValid = isValid;
          });
          this.addLomakeBehaviour();
        })
      )
  }

  public getLomakeMetaData(): LomakeMetaData {
    return this.lomakeMetaData;
  }

  getLomake() {
    return this.processLomakeService.getLomakeTiedot(this.lomakeInfo).then(response => {
      if (response?.id) {
        this.lomakeMetaData.id = response.id;
      }
      return response;
    });
  }

  /**
   * Tallennetaan lomake, kun poistutaan perustiedot -sivulta, jotta liitteille saadaan
   * aina tietoon lomakkeen id.
   * @param leaveFromSivu - Sivu, jolta siirrytään pois
   */
  handleLeavePerustiedotSivu(leaveFromSivu: number) {
    if (!this.lomakeMetaData.id && leaveFromSivu === 0) {
      this.saveLomake();
    }
  }

  saveLomake() {
    return this.processLomakeService.saveLomake(this.lomake.getRawValue(), this.lomakeInfo, this.liitetiedostot, this.lomakeMetaData.id).then(responseId => {
      this.lomakeMetaData.id = responseId || null;
    });
  }

  submitLomake() {
    this.lomake.markAllAsTouched();
    this.lomake.value.perustiedot = this.lomake.getRawValue().perustiedot;
    return this.processLomakeService.submitLomake(this.lomake.value, this.lomakeInfo, this.lomakeMetaData.id, this.liitetiedostot, this.lomake.valid).then(responseId => {
      this.lomakeMetaData.id = responseId || null;
    });
  }

  cancelLomake() {
    return this.processLomakeService.cancelLomake();
  }

  public onSetLiitteet(liitteet: LiitetiedostoResponse[], liiteAvain: string) {
    this.liitetiedostot[liiteAvain] = liitteet;

    setTimeout(() => {
      this.saveLomake().then(() => {
        // ignore
      });
    }, 1000);
  }

}
