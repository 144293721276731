/**
 * Created by Seppo on 22/11/2017.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, Renderer2, forwardRef, Input} from "@angular/core";
import {Syote} from "../syote";

/**
 * Kyllä / ei -radio komponentti
 */
@Component({
  selector: 'touko-yes-no',
  template: `
    <div class="row">
      <div class="col-md-8">
        <label (click)="toggleValue()" [attribute]="label">{{ label }}</label>
      </div>

      <div class="col-6 col-md-2">
        <touko-radio-input
          (click)="writeValue(true)"
          [radioGroup]="groupName"
          [htmlId]="htmlId + '-yes'"
          [checked]="fieldValue"
          value="true"
          labelAttribute="kylla"></touko-radio-input>
      </div>

      <div  class="col-6 col-md-2">
        <touko-radio-input
          (click)="writeValue(false)"
          [radioGroup]="groupName"
          [htmlId]="htmlId + '-no'"
          [checked]="fieldValue !== null && !fieldValue"
          value="false"
          labelAttribute="ei"></touko-radio-input>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => YesNoComponent),
    }
  ]
})
export class YesNoComponent implements ControlValueAccessor {

  @Input() groupName: string;
  @Input() label: string;
  @Input() htmlId: string;

  fieldValue: null | boolean;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: any): void {
    if (value !== 'undefined') {
      this.fieldValue = value;
    }
    this._onChange(this.fieldValue);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  toggleValue() {
    this.writeValue(!Boolean(this.fieldValue));
  }

}
