import {Component, Input, OnInit} from "@angular/core";
import {YhteenvetoGroup} from "./yhteenveto-utils";
import {YhteenvetoService} from "./yhteenveto.service";
import {Subscription} from "rxjs";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Yhteenvedon esittämiseen käytettävä komponentti. Voidaan käyttää lomakkeen yhteydessä tai ilman.
 */
@Component(
  {
    selector: 'yhteenveto',
    styleUrls: ['./yhteenveto.component.scss'],
    templateUrl: 'yhteenveto.component.html'
  }
)
@AutoUnsubscribe
export class YhteenvetoComponent implements OnInit {
  @Input() useImportedData = false;
  @Input() yhteenveto: YhteenvetoGroup[] = [];
  @Input() lomakeData;
  yhteenvetoSub: Subscription;
  @Input() titleAttribute = "teksti.sivuYhteenveto";

  constructor(private yhteenvetoService: YhteenvetoService) {
  }

  ngOnInit(): void {

    if (this.useImportedData) {
      return;
    }

    this.yhteenvetoSub = this.yhteenvetoService.yhteenveto$.subscribe(val => {
      if (val) {
        this.yhteenveto = val;
      }
    });
  }
}
