import {Component, Input} from "@angular/core";
import {Teksti} from "../utils/teksti";
/**
 * Created by Seppo on 11/06/2018.
 */
@Component({
  selector: 'form-error',
  template: `
    <div *ngIf="showError" class="alert alert-danger my-1" [attribute]="errorMsg.getFullKey()"></div>`
})
export class FormErrorComponent {
  @Input() errorMsg: Teksti;
  @Input() showError = false;
}
