import {BrowserModule, Title} from "@angular/platform-browser";
import {ErrorHandler, NgModule} from "@angular/core";

import {AppComponent} from "./app.component";
import {LayoutComponent} from "./layout/layout.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ClientErrorHandler} from "./utils/error-handler";
import {AppRoutingModule} from "./app-routing.module";
import {TiiraService} from "./tiira/tiira.service";
import {AccountService} from "./account/account.service";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SidebarModule} from "./sidebar/sidebar.module";
import {MainNavComponent} from "./main-nav/main-nav.component";
import {ToukoLomakeModule} from "./touko-lomake/touko-lomake.module";
import {LoginGuardService} from "./auth/login-guard.service";
import {PageNotFoundComponent} from "./not-found.component";
import {KoodistoService} from "./koodisto/koodisto.service";

import {LomakeService} from "./touko-lomake/touko-lomake.service";
import {MessageModule} from "./message/message.module";
import {AttributeModule} from "./tiira/attribute.module";
import {ToimialaGuardService} from "./auth/toimiala-guard.service";
import {DatePipe} from "@angular/common";
import {LoaderModule} from "./loader/loader.module";
import {MaatilaService} from "./maatila/maatila.service";
import {ValvontakohdeService} from "./valvontakohde/valvontakohde.service";
import {TulosraporttiService} from "./raportti/raportti.service";
import {FooterComponent} from "./layout/footer.component";
import {PermissionGuardService} from "./auth/permission-guard.service";
import {RequestInterceptor} from "./utils/request-interceptor.service";
import {ErrorInfoComponent} from "./error-info.component";
import {RaporttiModule} from "./raportti/raportti.module";
import {AccountModule} from "./account/account.module";
import {UtilsModule} from "./utils/utils.module";
import {ToukoInputsModule} from "./touko-inputs/touko-inputs.module";
import {LomakeLokitiedotComponent} from "./lomake-lokitiedot/lomake-lokitiedot.component";
import {ViranomainenGuardService} from "./auth/viranomainen-guard.service";
import {LiitetiedostoPalvelinService} from "./touko-lomake/touko-liitetiedosto.service";
import {ToukoLaheteModule} from "./touko-lahete/touko-lahete.module";
import {LomakeLeaveGuardService} from "./auth/lomake-leave-guard.service";

import {LaheteModule} from 'ee-lahete';
import {KasviService} from './kasvi/kasvi.service';
import {ToukoUusiKasvilajiModal} from './touko-lomake/syote/custom/touko-uusi-kasvi-laji-modal.component';
import {NgbModalConfigService} from "./configs/ngb-modal-config.service";
import {InfoValilehtiComponent} from './info-valilehti/info-valilehti.component';
import {AccessibilityModule} from "./accessibility-results/accessibility.module";
import {LoginModule} from "./login/login.module";
import {ValvontakohdeGuardService} from "./auth/valvontakohde-guard.service";
import {RaporttiGuardService} from "./auth/raportti-guard.service";
import {ArkistodokumentitService} from "./touko-lomake/arkistodokumentit/arkistodokumentit-service";
import {EtusivuModule} from "./etusivu/etusivu.module";
import {WipFeatureGuardService} from "./utils/wip-feature-guard.service";
import {EnvironmentService} from "../environments/environment.service";
import {LiitetiedostoLomakeService} from "./touko-lomake/liitetiedosto-lomake.service";

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    FooterComponent,
    MainNavComponent,
    PageNotFoundComponent,
    ErrorInfoComponent,
    LomakeLokitiedotComponent,
    ToukoUusiKasvilajiModal,
    InfoValilehtiComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule, // bootstrap components
    UtilsModule,
    AppRoutingModule,
    AttributeModule,
    AccountModule,
    LoginModule,
    EtusivuModule,
    AccessibilityModule,
    SidebarModule,
    ToukoLomakeModule,
    ToukoLaheteModule,
    MessageModule,
    LoaderModule,
    RaporttiModule,
    ToukoInputsModule,
    LaheteModule
  ],
  providers: [
    NgbModalConfigService,
    TiiraService,
    AccountService,
    LoginGuardService,
    ToimialaGuardService,
    ValvontakohdeGuardService,
    RaporttiGuardService,
    PermissionGuardService,
    ViranomainenGuardService,
    LomakeLeaveGuardService,
    WipFeatureGuardService,
    LomakeService,
    LiitetiedostoPalvelinService,
    LiitetiedostoLomakeService,
    MaatilaService,
    ValvontakohdeService,
    TulosraporttiService,
    KoodistoService,
    KasviService,
    ArkistodokumentitService,
    DatePipe,
    Title,
    EnvironmentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: ClientErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
