import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  template: `
    <form>
    <div class="form-group modal-header">
      <h1 class="modal-title" [attribute]="'teksti.kopioiMallipohjaksi'">Kopioi mallipohjaksi</h1>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="form-group modal-body">
      <label [attribute]="'teksti.mallipohjanNimi'" for="mallipohjanimi">Mallipohjan nimi</label>
        <input type="text" class="form-control" id="mallipohjanimi" [(ngModel)]="nimi" name="mallipohjanimi" required aria-describedby="mallipohjanimi" #mallipohjanimi="ngModel">
        <small *ngIf="mallipohjanimi.dirty && mallipohjanimi.errors" [attribute]="'teksti.mallipohjanimivaaditaan'" class="form-text text-danger">
            Mallipohjalla pitää olla nimi.
        </small>
    </div>

    <div class="form-group modal-footer">
      <button [attribute]="'teksti.tallenna'" id="tallenna" type="button" class="btn btn-primary" (click)="submit()">Tallenna</button>
      <button attribute="teksti.peruuta" id="peruuta" type="button" class="btn btn-light" (click)="activeModal.dismiss('Peruuta')">Peruuta</button>
    </div>
    </form>
  `,
  selector: "mallipohja-modal"
})
export class MallipohjaModalComponent {

  constructor(public activeModal: NgbActiveModal) {}

  nimi: string;
  submit() {

    if (this.nimi?.trim()) {
      this.activeModal.close(this.nimi?.trim());
    }
  }
}
