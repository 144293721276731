import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Yhteyshenkilo} from "../../touko-lomake/syote/syote-utils";
import {AccountService} from "../account.service";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {Teksti} from "../../utils/teksti";
import {YhteyshenkiloViewOptions} from "../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";


@Component({
  template: `
    <div class="modal-header">
      <h1 class="modal-title" attribute="kayttajatili.ilmoitusasetukset">Ilmoitusasetukset</h1>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form [formGroup]="notificationForm" (submit)="submit()">
    <div class="modal-body">
      <div class="col">
        <div class="form-group">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" formControlName="ilmoitukset" id="modal-ilmoitukset">
            <label class="form-check-label" for="modal-ilmoitukset" attribute="kayttajatili.ilmoitusModalKaytossa">Haluan vastaanottaa toimipaikkaa koskevia ilmoituksia</label>
          </div>
          <yhteyshenkilot-input formControlName="yhteyshenkilot" [viewOptions]="yhteyshenkiloViewOptions"></yhteyshenkilot-input>
        </div>
      </div>

      <div class="alert" [ngClass]="'alert-danger'" *ngIf="virheet.length > 0">
        <span *ngFor="let virhe of virheet" [attribute]="virheSanomat[virhe]?.getFullKey()" class="block clearfix"></span>
        <button class="btn btn-sm btn-secondary" *ngIf="containsInvalidYhteyshenkilo" attribute="lomakeYleinen.lokiTaulBtnKuittaus" (click)="kuittaaVirhe()">Kuittaa virhe</button>
      </div>


    </div>

    <div class="modal-footer">
      <button type="submit" id="account-tallenna" class="btn btn-success" attribute="teksti.tallenna">
        Tallenna
      </button>
      <button type="button" id="account-peruuta" class="btn btn-light" attribute="teksti.peruuta" (click)="activeModal.dismiss('Cross click')"></button>
    </div>

    </form>
  `,
  selector: "account-notification-modal",
  styleUrls: ["../account.scss"]
})
export class AccountNotificationModalComponent implements OnInit {

  notificationForm: FormGroup;
  virheet: string[] = [];
  virheSanomat = {};
  containsInvalidYhteyshenkilo = false;
  yhteyshenkiloViewOptions = new YhteyshenkiloViewOptions(true, false, true, false, false);

  @Input() yhteyshenkilot: Yhteyshenkilo[];
  @Input() ilmoitukset: boolean;

  constructor(public activeModal: NgbActiveModal,
              private readonly accountService: AccountService,
              private readonly fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.virheSanomat = {
      [ToukoValidation.VALID_YHTEYSHENKILO]:  new Teksti("Yhteyshenkilon tiedot", "errYhteyshenkiloPakollinen", "kayttajatili"),
      email: new Teksti("Tarkista sähköposti", "errEmail", "lomakeYleinen"),
      name: new Teksti("Nimi liian pitkä", "errLongName", "lomakeYleinen"),
      ytrVirhe: new Teksti("Ota yhteyttä virheen selvittämiseksi.", "errYhteyshenkilonHaku", "lomakeYleinen")
    };

    const hasInitValue = this.yhteyshenkilot !== null && this.yhteyshenkilot.length > 0;

    const yh = hasInitValue ?
      this.yhteyshenkilot :
      [new Yhteyshenkilo()];
    yh.sort((a, b) => a.nimi > b.nimi ? 1 : -1);
    this.notificationForm = this.fb.group({
      ilmoitukset: [this.ilmoitukset],
      yhteyshenkilot: [yh, [ToukoValidation.validIlmoitusYhteyshenkiloList]]
    });

    this.notificationForm.statusChanges.subscribe(status => {
      if (status === "VALID") {
        this.virheet = [];
      }
    });

    if (hasInitValue) {
      this.notificationForm.get('yhteyshenkilot').updateValueAndValidity();
    }
  }

  kuittaaVirhe(): void {
    const yhteyshenkilotControl = this.notificationForm.get('yhteyshenkilot');
    const currentValue: Yhteyshenkilo[] = yhteyshenkilotControl.value;

    yhteyshenkilotControl.setValue(currentValue.filter(y => y.ytrStatus === 'VALID'));
  }

  submit() {
    if (!this.notificationForm.valid) {
      this.virheet = Object.keys(this.notificationForm.get('yhteyshenkilot').errors);
      this.containsInvalidYhteyshenkilo = this.virheet.findIndex(virhe => virhe === 'ytrVirhe') >= 0;
    } else {
      this.virheet = [];
      this.accountService.setAccountYhteyshenkilot(this.notificationForm.get('yhteyshenkilot').value, this.notificationForm.get('ilmoitukset').value);
      this.activeModal.close();
    }
  }
}
