import {ToukoValidation, VirheObjekti} from "./validators";
import {FormControl, FormGroup} from "@angular/forms";
import {Yhteyshenkilo} from "../syote/syote-utils";

export class RehuValidators extends ToukoValidation {

  static ONLY_ONE_VK_YHTEYSHENKILO = 'onlyOneVkYhteyshenkilo';
  static REHU_LIITE = 'liitePuuttuu';
  static AT_LEAST_ONE_VALITTU_TRUE = 'atLeastOneValittuTrue';
  static DETAILS_NOT_EMPTY = 'detailsNotEmpty';

  static onlyOneValvontakohteenYhteyshenkilo(control: FormControl): VirheObjekti {
    const val = control.value as Yhteyshenkilo[];
    const rooliExists = val.filter(v => v.elmoStatus === 'ACTIVE' && v.rooli === 'ReVCCControlContact');

    return RehuValidators.buildError(rooliExists.length === 1, RehuValidators.ONLY_ONE_VK_YHTEYSHENKILO);
  }

  /**
   * Vähintään yhdestä lomakeryhmän kentistä täytyy löytyä valittu: 'true',
   * jotta validointi on hyväksytty.
   *
   * @param group - lomakkeen syöteryhmä
   * @returns
   * @constructor
   */
  static atLeastOneValittuTrue(group: FormGroup): VirheObjekti {
    let atLeastOneValittuTrue = false;
    for (const item in group.controls) {
      if (group.controls[item].value && group.controls[item].value.valittu) {
        atLeastOneValittuTrue = true;
      }
    }
    return RehuValidators.buildError(atLeastOneValittuTrue, RehuValidators.AT_LEAST_ONE_VALITTU_TRUE);
  }

  static rehuLiite(group: FormGroup): VirheObjekti {

    const isKasittelylaitos = group.value['kasittelylaitos'] === true;
    const isLemmikkiRuokaLaitos = group.value['lemmikkielaintenRuokiaValmistavaLaitos'] === true;
    const hasToimintoja = isKasittelylaitos || isLemmikkiRuokaLaitos;

    const liitteet = group.value['hakemuksenLiitteet'] || [];

    return RehuValidators.buildError(!hasToimintoja || liitteet.length > 0, RehuValidators.REHU_LIITE);
  }

  static detailsNotEmpty(control: FormControl): VirheObjekti {
    const val = control.value;
    if (val !== null && val.checked) {
      return RehuValidators.buildError(Boolean(val.text), RehuValidators.DETAILS_NOT_EMPTY);
    } else {
      return RehuValidators.buildError(true, RehuValidators.DETAILS_NOT_EMPTY);
    }
  }

}
