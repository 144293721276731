import {MessageService} from "./message.service";
import {MessageComponent} from "./message.component";
import {BrowserModule} from "@angular/platform-browser";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgModule} from "@angular/core";
import {AttributeModule} from "../tiira/attribute.module";
/**
 * Created by Seppo on 01/12/2017.
 */

@NgModule({
  declarations: [
    MessageComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AttributeModule
  ],
  exports: [
    MessageComponent
  ],
  providers: [
    MessageService
  ]
})
export class MessageModule {
}
