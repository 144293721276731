<!-- Palvelusaatavuustiedot -->
<div class="row justify-content-center">
  <div class="col-6">
    <palsa-info [palsaId]="'TOUKO'" [localizationKey]="'TOU_palsa'" [languages]="['fi', 'sv']"></palsa-info>
  </div>
</div>

<div class="row justify-content-center">

  <div class="col-12 text-center mt-4">
    <p *ngIf="currentLang === 'FI'">Suomeksi / <a [routerLink]="'.'" [queryParamsHandling]="'preserve'" lang="sv" (click)="setLanguage('SV')">På svenska</a></p>
    <p *ngIf="currentLang === 'SV'"><a [routerLink]="'.'" [queryParamsHandling]="'preserve'" lang="fi" (click)="setLanguage('FI')">Suomeksi</a> / På svenska</p>
    <h1 id="login-tervetuloa" attribute="login.tervetuloa">Tervetuloa Touko-asiointipalveluun</h1>
  </div>

</div>

<div class="row justify-content-center">
  <div class="col-md-8 col-lg-6 max-50rem" *ngIf="!showVirkailijaLogin">

    <a *ngFor="let url of externalLoginLinks" [attr.href]="url.url" [id]="'link-' + url.title">
      <login-card [title]="url.title"
                  [description]="url.description"
                  [iconStyle]="url.icon"></login-card>
    </a>

    <a *wipFeature [href]="getEnvironmentLoginUrl(true)" [id]="'link-' + 'login.viranomainen'">
      <login-card [description]="'login.viranomainenSelite'"
                  [iconStyle]="'fa-university'"
                  [title]="'login.viranomainen'"></login-card>
    </a>

    <a *ngIf="!env.isFeatureEnabled(Feature.WIP_FEATURE)" [id]="'link-' + 'login.viranomainen'" [queryParams]="{role: 'virkailija'}"
       [routerLink]="'/login'">
      <login-card [title]="'login.viranomainen'"
                  [description]="'login.viranomainenSelite'"
                  [iconStyle]="'fa-university'"></login-card>
    </a>

    <a *testFeature [href]="getEnvironmentLoginUrl(false)" [id]="'link-' + 'login.AStestlogin'">
      <login-card [description]="'login.AStestiloginSelite'"
                  [iconStyle]="'fas fa-vial'"
                  [title]="'login.AStesti'"></login-card>
    </a>

  </div>
  <div class="col-md-8 col-lg-6 max-50rem" *ngIf="showVirkailijaLogin">
    <login-virkailija (onSubmitForm)="submitForm($event)"></login-virkailija>
  </div>

</div>

<div class="row justify-content-center mt-4" *ngIf="!showVirkailijaLogin">
  <div class="col-md-8 col-lg-6 max-50rem">
    <p attribute="login.infoOtsikko">Touko-asiointipalvelun sisältö eri asiakasryhmille:</p>
    <h2 attribute="login.kirjauduYritysasiakas">Yritysasiakas</h2>
    <h3 class="h4"  attribute="login.infoLomakkeet">Hakemukset sekä rekisteröinti- ja valvontailmoitukset:</h3>
    <ul>
      <li attribute="login.infoLomakkeetTouko">Siemen-, rehu-, lannoite- ja kasvinterveydenalan rekisteröinti-ilmoitukset.</li>
      <li attribute="login.infoLomakkeetLuomu">Luomualan toimijan valvontaan liittyminen ja eri hakemukset.</li>
    </ul>
    <h3 class="h4" attribute="login.infoRaportit">Ladattavissa olevat raportit:</h3>
    <ul>
      <li attribute="login.infoRaportitSiemen">Siemenalan toimipaikan kauppaerien päätökset ja tutkimustodistukset sekä toimipaikan viljelystarkastuspäätökset.</li>
      <li attribute="login.infoRaportitRehu">Rehuvalvonnan raportit.</li>
      <li attribute="login.infoRaportitLannoite">Lannoitevalvonnan raportit.</li>
      <li attribute="login.infoRaportitKate">Kasvinterveydenalan valvonnan raportit ja rekisteri-ilmoitukset.</li>
      <li attribute="login.infoRaportitLuomu">Luomuvalvonnan raportit.</li>
    </ul>

    <h2 attribute="login.kirjauduHenkiloasiakas">Henkilöasiakas</h2>
    <h3 class="h4"  attribute="login.infoLomakkeet">Hakemukset sekä rekisteröinti- ja valvontailmoitukset:</h3>
    <ul>
      <li attribute="login.infoLomakkeetLuomu">Luomualan toimijan valvontaan liittyminen ja eri hakemukset</li>
      <li attribute="login.infoLomakkeetSahkoelain">Eläintautitutkimusten tutkimuslähetteet</li>
    </ul>

    <h3 attribute="login.infoRaportit" class="h4">Ladattavissa olevat raportit:</h3>
    <ul>
      <li attribute="login.infoRaportitLuomu">Luomuvalvonnan raportit.</li>
    </ul>

    <h2 attribute="login.kirjauduHPA">Henkilön puolesta asiointi</h2>
    <h3 class="h4"  attribute="login.infoLomakkeet">Hakemukset sekä rekisteröinti- ja valvontailmoitukset:</h3>
    <ul>
      <li attribute="login.infoLomakkeetSahkoelain">Eläintautitutkimusten tutkimuslähetteet</li>
    </ul>

    <h2 attribute="login.viranomainen">Viranomainen</h2>
    <ul>
      <li attribute="login.infoVirkailijaRuokavirasto">Ruokaviraston viranomaistoiminta.</li>
      <li attribute="login.infoVirkailijaEly">Ruokaviraston ELY-keskustoiminta.</li>
    </ul>
  </div>
</div>
