<div class="row">
    <div class="col-12">
        <h1>Tutkimustodistukset</h1>
    </div>
</div>

<!-- Rajaus -->
<form (submit)="submit()" [formGroup]="raporttiForm">
    <div class="row form-group">
        <div class="col-sm-6 col-lg-3">
            <label attribute="raportit.laheteTunnus" for="raportti-tunnus">Lähetteen tunnus</label>
          <input class="form-control" formControlName="laheteTunnus" id="raportti-tunnus" name="" type="text">
        </div>
        <div class="col-sm-6 col-lg-3">
            <label attribute="raportit.elmoLaheteNumero" for="raportti-lahete-numero">Elmo lähete numero</label>
            <input class="form-control" formControlName="elmoLaheteNumero" id="raportti-lahete-numero" name=""
                   type="text">
        </div>
        <div class="col-sm-6 col-lg-3">
            <label attribute="raportit.elmoRaporttiTunnus" for="raportti-elmo-tunnus">Raportoinnin tunnus</label>
          <input class="form-control" formControlName="elmoRaporttiTunnus" id="raportti-elmo-tunnus" name=""
                   type="text">
        </div>
    </div>

    <div class="row form-group">

        <div class="col-sm-12 col-md-6 col-lg-2">
            <touko-pvm [htmlId]="'raportti-pvmAlku'" [isInValid]="getInvalid('pvmAlku')" [item]="syotteet.pvmAlku"
                       formControlName="pvmAlku"></touko-pvm>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-2">
            <touko-pvm [htmlId]="'raportti-pvmLoppu'" [isInValid]="getInvalid('pvmLoppu')" [item]="syotteet.pvmLoppu"
                       formControlName="pvmLoppu"></touko-pvm>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-2">

            <button (click)="reset()" attribute="raportit.tyhjenna"
                    class="btn btn-outline-primary form-control no-label"
                    id="raportti-tyhjenna"
                    type="button">
                Tyhjennä
            </button>
        </div>
    </div>
</form>

<raportti-sivutus></raportti-sivutus>

<div class="row form-group"></div>

<div class="row">
    <div class="col-sm-12">
        <datatable [columnOrder]="columnOrder" [liiteDownloadFn]="liiteDownloadFn"
                   [liiteZipDownloadFn]="liiteDownloadZipFn" [showSpinner]="showLoader">
        </datatable>
    </div>
</div>
