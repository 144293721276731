import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {SyoteGroup} from "../syote/syote-group";
import {ToukoValidation} from "../validators/validators";
import {YhteyshenkiloValue} from "../touko-lomake-utils";
import {Account} from "../../account/account";
import {keyOrderFn, LomakeData, LomakeDataContent} from "../lomake-data";
import {DateObj} from "../syote/syote-utils";
import {SyoteArray} from "../syote/syote-array";

/**
 * Siemenlomakkeen data
 */
export class SiemenpakkaamoData extends LomakeData {

  static lomakeTitle: Teksti = new Teksti("Siemenpakkaamolupahakemus", "siemenPakkaamoHakemusOtsikko", "siemenpakkaamo");

  static getKeyOrder(): string[] {
    return keyOrderFn(SiemenpakkaamoData.getData);
  }

 static disableFields(syoteGroup: SyoteGroup, fieldsToDisable: string[]): void {
    fieldsToDisable.forEach(key => {
      const syoteToChange = syoteGroup?.data?.findIndex(syote => syote.label.key === key);
      syoteGroup.data[syoteToChange].control = {value: null, disabled: true};
    });
  }

  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    const view = SiemenpakkaamoData.lomakeTitle.view;
    const TOIMINNAN_LAAJUUS_RADIO = "radio-group";
    const PAINATUS_RADIO = "radio-group";
    const TRANSLATIONS = {
      checkbox: (checked) => checked ? "ruokavirastoHyvaksynyt" : "ruokavirastoHyvaksynytEi",
      date: "hyvaksymisAjankohta"
    };

    let perustiedot = SiemenpakkaamoData.getPerustiedot(view, account);
    SiemenpakkaamoData.disableFields(perustiedot, ["kayntiosoite", "postiosoite"]);

    return {
      hakemustyyppi: new SyoteGroup(
        view,
        new Teksti("Hakemustyyppi", "hakemustyyppi"),
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse vähintään yksi", "vahintaanYksi")},
        YhteenvetoTyyppi.RADIO_SELECTION,
        new Syote(new Teksti("hakemustyyppiRadio", "hakemustyyppiRadio"), null, "radio-hakemustyyppi", null, {radioOptions: [
            new Syote(new Teksti("Uusi hakemus", "hakemusUusi", view), null, "radio-hakemustyyppi", "hakemusUusi"),
            new Syote(new Teksti("Jatkohakemus", "hakemusJatko", view), null, "radio-hakemustyyppi", "hakemusJatko"),
            new Syote(new Teksti("Laajennushakemus", "hakemusLaajennus", view), null, "radio-hakemustyyppi", "hakemusLaajennus")
          ]}),
      ),
      perustiedot: perustiedot,
      laskutustiedot: SiemenpakkaamoData.getLaskutustiedot(view, account),
      yhteyshenkilot: SiemenpakkaamoData.getYhteyshenkilot(view, account, null, YhteyshenkiloValue.SIEM_PAKK.value),
      toiminnanAloitus: SiemenpakkaamoData.getToiminnanAloitus(view, toimintotyyppi, null),
      markkinoimasiKylvosiemenet: new SyoteGroup(
        view,
        new Teksti("Merkitse markkinoimasi kylvösiemenet", "markkinoimasiKylvosiemenet"), // haetaan attribuuttikannasta
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse vähintään yksi", "vahintaanYksi")},
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Viljakasvit", "viljakasvit"), null),
        new Syote(new Teksti("Nurmi- ja rehukasvit", "nurmiJaRehu"), null),
        new Syote(new Teksti("Öljy- ja kuitukasvit", "oljyJaKuitu"), null),
        new Syote(new Teksti("Siemenperuna", "siemenperuna"), null),
        new Syote(new Teksti("Vihanneskasvit", "vihanneskasvit"), null),
        new Syote(new Teksti("Juurikkaat", "juurikkaat"), null),
        new Syote(new Teksti("Seokset", "seokset"), null, "seokset")
      ),
      toiminnanLaajuus: new SyoteGroup(
        view,
        new Teksti("Missä laajuudessa harjoitatte toimintaa vuodessa?", "toiminnanLaajuus"),
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse toiminnan laajuutta parhaiten kuvaava vaihtoehto", "valitseYksi")},
        YhteenvetoTyyppi.RADIO_SELECTION,
        new Syote(new Teksti("toiminnanLaajuusRadio", "toiminnanlaajuusRadio"), null, TOIMINNAN_LAAJUUS_RADIO, null, {
          radioOptions:
            [ new Syote(new Teksti("enintään 1 000 kg", "enintaanTuhat", view), null, TOIMINNAN_LAAJUUS_RADIO, "enintaan1000"),
              new Syote(new Teksti("1001–10 000 kg", "enintaan10Tuhatta", view), null, TOIMINNAN_LAAJUUS_RADIO, "1001-10000"),
              new Syote(new Teksti("10 001–200 000 kg", "enintaan200tuhatta", view), null, TOIMINNAN_LAAJUUS_RADIO, "10001-200000"),
              new Syote(new Teksti("200 001–500 000 kg", "enintaan500tuhatta", view), null, TOIMINNAN_LAAJUUS_RADIO, "200001-500000"),
              new Syote(new Teksti("500 001–1 000 000 kg", "enintaanMiljoona", view), null, TOIMINNAN_LAAJUUS_RADIO, "500001-1000000"),
              new Syote(new Teksti("yli 1 000 001", "yliMiljoonaYksi", view), null, TOIMINNAN_LAAJUUS_RADIO, "yli1000001")
            ]
        })
      ),
      koneetJaLaitteet: new SyoteGroup(
        view,
        new Teksti("Ilmoita käyttämäsi koneet ja laitteet?", "koneetJaLaitteet"),
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse käyttämäsi laitteet", "vahintaanYksi")},
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Lajittelija", "lajittelija"), null, "lajittelija", null, null),
        new Syote(new Teksti("Peittauslaite", "peittauslaite"), null, "peittauslaite", null, null),
        new Syote(new Teksti("Sekoituslaite", "sekoituslaite"), null, "sekoituslaite", null, null),
        new Syote(new Teksti("Eränumeron merkintälaite", "eranumeronMerkintalaite"), null, "eranumeronmerkintalaite", null, null),
        new Syote(
          new Teksti("Automaattinen näytteenotin", "automaattinenNaytteenotin"),
          null,
          "automaattinennaytteenotin",
          null,
          null,
          null,
          new SyoteArray(new Teksti("naytteenotinArray", "ruokavirastoHyvaksynyt", view), null, "naytteenotinArray", null, {
            parent: 'automaattinennaytteenotin',
            type: 'array',
            translate: TRANSLATIONS
          }, YhteenvetoTyyppi.SYOTE_CHECKBOX_AND_DATE_ARRAY, [{
            checkbox: new Syote(new Teksti("Automaattinen näytteenotin checkbox", "milloinRuokavirastoHyvaksynyt", view), null, "checkbox", null, {
              type: 'checkbox'
            }),
            date: new Syote(new Teksti("Milloin laite on hyväksytty?", "merkitseAjankohta", view), {
              value: DateObj.firstDayOfMonth(),
              disabled: true
            }, "date", null, {type: 'month-date', enableBy: 'checkbox'}, YhteenvetoTyyppi.LAITE_HYVAKSYTTY_PVM)
          }])
        ),
        new Syote(
          new Teksti("Vakuustulostin", "vakuustulostin"),
          null,
          "vakuustulostin",
          null,
          null,
          null,
          new SyoteArray(new Teksti("vakuustulostinArray", "ruokavirastoHyvaksynyt", view), null, "vakuustulostinArray", null, {
            parent: 'vakuustulostin',
            type: 'array',
            translate: TRANSLATIONS
          }, YhteenvetoTyyppi.SYOTE_CHECKBOX_AND_DATE_ARRAY, [{
            checkbox: new Syote(new Teksti("Vakuustulostin checkbox", "milloinRuokavirastoHyvaksynyt", view), null, "vakuustulostinCheckbox", null, {
              type: 'checkbox'
            }, null),
            date: new Syote(new Teksti("Milloin laite on hyväksytty?", "merkitseAjankohta", view), {
              value: DateObj.firstDayOfMonth(),
              disabled: true
            }, "date", null, {type: 'month-date', enableBy: 'checkbox'}, YhteenvetoTyyppi.LAITE_HYVAKSYTTY_PVM)
          }])
        ),
        new Syote(
          new Teksti("Vaaka", "vaaka"),
          null,
          "vaaka",
          null,
          null,
          null,
          new SyoteArray(new Teksti("vaakaArray", "vaakanVarmennus", view), null, "vaakaArray", null, {
            parent: 'vaaka',
            type: 'array',
            translate: TRANSLATIONS
          }, YhteenvetoTyyppi.SYOTE_DATE_ARRAY, [{
            date: new Syote(new Teksti("vaakaVarmennettu", "vaakaVarmennettu", view), {
              value: DateObj.firstDayOfMonth(),
              disabled: false
            }, "date", null, {type: 'month-date'}, YhteenvetoTyyppi.LAITE_HYVAKSYTTY_PVM)
          }])),
        new Syote(
          new Teksti("Puhdistuslaitteet", "puhdistuslaitteet"),
          null,
          "puhdistuslaitteet",
          null,
          null,
          null,
          new Syote(new Teksti("Puhdistuslaitteet details", "lisatieto", view), null, "puhdistuslaitteetDetails", null, {
            type: 'textarea',
            parent: 'puhdistuslaitteet',
            yhteenvetoId: 'puhdistuslaitteetDetails'
          }, YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY)
        ),
        new Syote(
          new Teksti("Muut laitteet", "muutlaitteet"),
          null, "muutlaitteet",
          null,
          null,
          null,
          new Syote(new Teksti("Muut laitteet details", "lisatieto", view), null, "muutlaitteetDetails", null, {
            type: 'textarea',
            parent: 'muutlaitteet',
            yhteenvetoId: 'muutlaitteetDetails'
          }, YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY)
        ),
      ),
      lisatiedot: new SyoteGroup(
        view,
        new Teksti("Lisätiedot", "lisatieto"),
        null,
        null,
        YhteenvetoTyyppi.LABEL_ONLY,

        new Syote(
          new Teksti("Pakkaatteko luomusiementä?", "luomusiemenpakkaus"),
          null,
          "luomusiemenpakkaus",
          null,
          null,
          null,
          new Syote(new Teksti("Luomusiemen pakkaus details", "lisatiedot", view), null, "luomusiemenpakkausDetails", null, {
            type: 'textarea',
            parent: 'luomusiemenpakkaus',
            yhteenvetoId: 'luomusiemenpakkausDetails'
          }, YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY)
        ),
        new Syote(
          new Teksti("Onko pakkaamolla sopimustuotantoa?", "sopimustuotantoa"),
          null,
          "onkosopimustuotantoa",
          null,
          null,
          null,
          new Syote(new Teksti("Onko sopimustuotantoa details", "sopimustuotantoDetails", view), null, "onkosopimustuotantoaDetails", null, {
            type: 'textarea',
            parent: 'onkosopimustuotantoa',
            yhteenvetoId: 'onkosopimustuotantoaDetails'
          }, YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY)
        ),
        new Syote(
          new Teksti("Käytetäänkö muiden toimijoiden palveluita, kuten lajittelua, peittausta, sekoitusta, pakkausta?", "kaytetaankoMuidenPalveluita"),
          null, "kaytetaankomuidentoimijoidenpalveluita",
          null,
          null,
          null,
          new Syote(new Teksti("Käytetäänkö muiden toimijoiden palveluita Details", "lisatiedot", view), null, "kaytetaankomuidentoimijoidenpalveluitaDetails", null, {
            type: 'textarea',
            parent: 'kaytetaankomuidentoimijoidenpalveluita',
            yhteenvetoId: 'kaytetaankomuidentoimijoidenpalveluitaDetails'
          }, YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY)
        ),
        new Syote(
          new Teksti("Käsitelläänkö pakkaamossa muuta kuin siementavaraa?", "kasitellaankoMuutaKuinSiemTavaraa"),
          null,
          "kasitellaankomuutakuinsiementavaraa",
          null,
          null,
          null,
          new Syote(new Teksti("Käsitelläänkö pakkaamossa muuta kuin siementavaraa Details", "lisatiedot", view), null, "kasitellaankomuutakuinsiementavaraaDetails", null, {
            type: 'textarea',
            parent: 'kasitellaankomuutakuinsiementavaraa',
            yhteenvetoId: 'kasitellaankomuutakuinsiementavaraaDetails'
          }, YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY)
        ),
      ),
      painatus: new SyoteGroup(
        view,
        new Teksti("Valise sopivin vaihtoehto", "painatus"),
        ToukoValidation.atLeastOneTrue,
        {[ToukoValidation.AT_LEAST_ONE_TRUE]: new Teksti("Valitse painatustapa", "vahintaanYksi")},
        YhteenvetoTyyppi.RADIO_SELECTION,
        new Syote(new Teksti("painatusRadio", "painatusRadio"), null, PAINATUS_RADIO, null, {radioOptions: [
            new Syote(new Teksti("Painetaan itse", "painetaanItse", view), null, PAINATUS_RADIO, "painetaanItse"),
            new Syote(new Teksti("Tulostetaan itse", "tulostetaanItse", view), null, PAINATUS_RADIO, "tulostetaanItse"),
            new Syote(new Teksti("Tilataan siemenyksiköstä", "tilataanSiemYksikosta", view), null, PAINATUS_RADIO, "tilataansiemenYksikosta")
          ]}),
      ),
      tarkastusAjankohta: new SyoteGroup(
        view,
        new Teksti("Valise sopivin vaihtoehto", "tarkastusAikavalille"), null, null, YhteenvetoTyyppi.LAITE_HYVAKSYTTY_PVM,
        new Syote(new Teksti("Tarkistusajankohta", "tarkastusAjankohta"), null, "tarkastusDate", null, {type: 'date'}),
      ),

    };
  }
}

