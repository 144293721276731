import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TagListComponent} from "./taglist.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AttributeModule} from "../tiira/attribute.module";
import {PvmComponent} from "./touko-pvm";
import {FormErrorComponent} from "./form-error.component";
import {TagListCheckComponent} from "./taglist-check.component";
import {OsoiteComponent} from "./osoite.component";
import {KoodistoService} from "../koodisto/koodisto.service";
import {YhteyshenkilotInputComponent} from "./yhteyshenkilot/yhteyshenkilot.component";
import {VerkkolaskuComponent} from "./verkkolasku.component";
import {OsastoComponent} from "./touko-osasto.component";
import {RadioTableRowComponent} from "./radio-table-row.component";
import {CheckboxTableRowComponent} from "./checkbox-table-row.component";
import {ValvontakohdeTypeaheadComponent} from './valvontakohde-typeahead/valvontakohde-typeahead.component';
import {YritysTypeaheadComponent} from './yritys-typeahead.component';
import {CommonModule} from "@angular/common";
import {VerkkokaupatInputComponent} from "./verkkokaupat.component";
import {KuukausiPvmComponent} from "./touko-kuukausi-pvm";
import {TextInputComponent} from "./text-input.component";
import {RadioComponent} from "./radio-component";
import {TextareaComponent} from "./textarea-component";
import {CheckboxComponent} from "./checkbox-component";
import {CollapseComponent} from "./collapse-component";
import {NumberInputComponent} from "./number-input.component";
import {YhteyshenkiloComponent} from "./yhteyshenkilot/yhteyshenkilo.component";
import {ToukoComponentsModule} from "../touko-components/touko-components.module";
import {YhteyshenkiloListComponent} from "./yhteyshenkilot/yhteyshenkilo-list.component";
import {LannoiteOsoiteComponent} from "./lannoite-osoite.component";
import {ValidationErrorComponent} from "../touko-lomake/vuosiilmoitus/common/validation-error.component";
import {DateComponent} from "./date.component";
import {VerkkokaupatListComponent} from "./verkkokaupat-component/verkkokaupat-list.component";
import {CheckboxTableRowV2Component} from "./checkbox-table-row-v2.component";
import {ToukoYritysTiedotComponent} from "./touko-yritys-tiedot.component";

/**
 * Created by Seppo on 28/05/2018.
 */

/**
 * Moduuliin kootaan kaikki Toukon käyttämät custom lomakekomponentit.
 */
@NgModule({
    declarations: [
        TextareaComponent,
        TextInputComponent,
        RadioComponent,
        CheckboxComponent,
        TagListComponent,
        TagListCheckComponent,
        PvmComponent,
        DateComponent,
        KuukausiPvmComponent,
        OsoiteComponent,
        LannoiteOsoiteComponent,
        OsastoComponent,
        YhteyshenkilotInputComponent,
        VerkkolaskuComponent,
        FormErrorComponent,
        RadioTableRowComponent,
        CheckboxTableRowComponent,
        ValvontakohdeTypeaheadComponent,
        YritysTypeaheadComponent,
        VerkkokaupatInputComponent,
        VerkkokaupatListComponent,
        CollapseComponent,
        NumberInputComponent,
        YhteyshenkiloComponent,
        YhteyshenkiloListComponent,
        ValidationErrorComponent,
        CheckboxTableRowV2Component,
        ToukoYritysTiedotComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        AttributeModule,
        ReactiveFormsModule,
        ToukoComponentsModule,
    ],
    exports: [
        TextInputComponent,
        TagListComponent,
        TagListCheckComponent,
        PvmComponent,
        KuukausiPvmComponent,
        OsoiteComponent,
        LannoiteOsoiteComponent,
        OsastoComponent,
        YhteyshenkilotInputComponent,
        VerkkolaskuComponent,
        FormErrorComponent,
        RadioTableRowComponent,
        CheckboxTableRowComponent,
        ValvontakohdeTypeaheadComponent,
        YritysTypeaheadComponent,
        VerkkokaupatInputComponent,
        RadioComponent,
        CheckboxComponent,
        CollapseComponent,
        TextareaComponent,
        NumberInputComponent,
        YhteyshenkiloComponent,
        YhteyshenkiloListComponent,
        ValidationErrorComponent,
        DateComponent,
        VerkkokaupatListComponent,
        CheckboxTableRowV2Component,
        ToukoYritysTiedotComponent
    ],
    providers: [
        KoodistoService
    ]
})
export class ToukoInputsModule {
}
