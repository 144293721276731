import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {ValueAndLabel} from "../lahete-data.model";
import {CompareType, MatcherModel} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {InitValueType, QuestionInitValue} from "ee-lahete";

export class TilatyypitGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: ''})): QuestionGroup {
    const group = new QuestionGroup({
      key: 'tilatyypit',
      label: this.getLabel('Tilatyypit'),
      controls: [
        this.createControlForElainlaji('nautaTilatyypit', 'PA1NA'),
        this.createControlForElainlaji('sikaTilatyypit', 'PA1SI'),
        this.createControlForElainlaji('siipikarjaTilatyypit', 'PA1SK'),
        this.createControlForElainlaji('poroTilatyypit', 'PA1PO'),
      ],
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, TilatyypitGroup.notEqualsFilterFn, TilatyypitGroup.filterActionFn)
      }
    });

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.tilatyypit), InitValueType.PATCH));
    }

    return group;
  }

  private createControlForElainlaji(key: string, elainlaji: string): QuestionControl {
    const selections = this.laheteKoodisto.koodisto.tilatyypit
      .filter(tilatyyppi => tilatyyppi.elainlajikoodi.includes(elainlaji))
      .map(tilatyyppi => new ValueAndLabel(tilatyyppi.id, this.getLocalizedOf(tilatyyppi, 'kuvaus')));

    return new QuestionControl({
      key: key,
      controlType: 'selection',
      label: this.getLabel('Tilatyyppi'),
      options: {
        selection: selections
      },
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(elainlaji, TilatyypitGroup.containsFilterFn, TilatyypitGroup.filterActionFn, CompareType.SOME)
      }
    });
  }

}
