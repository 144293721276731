<!-- Modal -->
  <div class="modal-header">
    <h1 class="modal-title" attribute="sivupalkki.modalHaluatkoJatkaa">Haluatko jatkaa keskeneräistä asiointia?</h1>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form>
    <div class="modal-body" [showSpinner]="showLoader">
      <p *ngIf="keskenerainenLomake" [attribute]="kuvaukset[keskenerainenLomake.asia][keskenerainenLomake.toimintotyyppi].getFullKey()"></p>
      <ng-container *ngIf="!showLoader">
      <div class="container">
        <dl class="row">
          <dt class="col-4" attribute="etusivu.luotu">Luotu</dt>
          <dd class="col-8">{{keskenerainenLomake?.perpvm | date: 'd.M.yyyy'}}</dd>
          <dt class="col-4" attribute="etusivu.paivitetty">Päivitetty</dt>
          <dd class="col-8">{{keskenerainenLomake?.paipvm | date: 'd.M.yyyy'}}</dd>
          <dt class="col-4" attribute="etusivu.muokannutToimija">Viimeksi muokannut</dt>
          <dd class="col-8">{{keskenerainenLomake?.muokannutToimija}}</dd>
        </dl>

        <ng-container *ngIf="!keskenerainenLomake?.viimeksiMuokannut">
          <div class="alert alert-warning">
            <p>
              <span attribute="sivupalkki.modalPoistaLukitus">Poistetaanko lomakkeen lukitus käyttäjältä:</span>
              <em> {{keskenerainenLomake?.muokannutToimija}}</em>
            </p>
            <button attribute="sivupalkki.modalPoistaLukitusOk"
                    class="btn btn-primary"
                    (click)="poistaLukitus()">Poista lukitus</button>
          </div>
        </ng-container>

        <ng-container *ngIf="keskenerainenLomake?.viimeksiMuokannut && keskenerainenLomake.tila !== 'ODOTTAA_LISATIETOJA'">
        <div class="form-check">
          <input class="form-check-input" [(ngModel)]="modalValue" type="radio" id="jatka" name="valinta" [value]="'JATKA'" [checked]="modalValue === 'JATKA'">
          <label class="form-check-label" for="jatka" attribute="sivupalkki.modalJatka">Kyllä, jatkan aloittamani lomakkeen täyttämistä.</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" [(ngModel)]="modalValue" type="radio" id="uusi" name="valinta" [value]="'UUSI'">
          <label class="form-check-label" for="uusi">
            <span attribute="sivupalkki.modalKeskeyta">En, haluan luoda uuden lomakkeen.</span>
            <span> <strong class="text-danger" attribute="sivupalkki.modalKeskenerainenPoistetaan">Keskeneräinen lomake poistetaan.</strong></span>
            </label>
        </div>
        </ng-container>
      </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button [disabled]="!modalValue" attribute="sivupalkki.modalHyvaksy" type="button" id="button-hyvaksy" class="btn btn-primary" (click)="submit()">Hyväksy</button>
      <button attribute="sivupalkki.modalPeruuta" type="button" id="button-peruuta" class="btn btn-light" (click)="activeModal.dismiss('Peruuta')">Peruuta</button>
    </div>
  </form>
