/**
 * Created by Kari on 16/08/2022.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChildren
} from "@angular/core";
import {Verkkokauppa} from "../touko-lomake/syote/syote-utils";
import {TextInputComponent} from "./text-input.component";

@Component({
  selector: "verkkokaupat-input",
  template: `
    <touko-checkbox
      htmlId="verkkokaupatToimintaCheck"
      labelAttribute="lomakeYleinen.verkkokaupatToiminta"
      [(ngModel)]="verkkokaupatToiminta"
      (change)="updateStatus()">
    </touko-checkbox>
    <ng-container *ngFor="let verkkokauppa of fieldValue; index as i; trackBy:trackByFn">
      <div *ngIf="!verkkokauppa.enddt" class="form-row align-items-end ml-4">
        <div class="col-6">
          <touko-text-input
              [htmlId]="'vk-osoite-' + i"
              [disabled]="!verkkokaupatToiminta"
              [labelAttribute]="i === firstActiveIndex ? 'lomakeYleinen.verkkokauppaosoite' : null"
              [value]="verkkokauppa.osoite"
              (change)="updateField('osoite', i, $event.target.value)"
              #nimiInput></touko-text-input>
        </div>
      <div>
        <button [attr.tabindex]="0"
                (click)="removeRowIndex(i)"
                [id]="'vk-poista-' + i"
                [disabled]="!verkkokaupatToiminta"
                type="button" class="btn btn-outline-primary mb-4 ml-3">
          <span class="fa fa-trash"></span>
          <span attribute="lomakeYleinen.poistaVerkkokauppa">Poista</span>
        </button>
      </div>
      </div>
    </ng-container>

    <div class="form-row ml-4" *ngIf="maxCount > 1">
      <div class="col-12">
        <button *ngIf="fieldValue && verkkokaupatLength < maxCount" (click)="addRow()" type="button"
                class="btn btn-secondary"
                id="vk-lisaa"
                [disabled]="!verkkokaupatToiminta">
            <span class="fa fa-plus"></span>
            <span attribute="lomakeYleinen.lisaaVerkkokauppa">Lisää</span>
        </button>
      </div>
    </div>
  `,
  styleUrls: ["verkkokaupat.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VerkkokaupatInputComponent),
    }
  ]
})
export class VerkkokaupatInputComponent implements ControlValueAccessor, AfterViewChecked {

  private _addRowClicked = false;

  @Input() maxCount = 50;
  @Input() viewOptions = new VerkkokauppaViewOptions();

  @Output() verkkokaupatUpdate: EventEmitter<Verkkokauppa[]> = new EventEmitter();
  @ViewChildren("nimiInput") nimiInput: QueryList<TextInputComponent>;

  verkkokaupatLength: number;
  firstActiveIndex: number;
  verkkokaupatToiminta: boolean;

  storedVerkkokaupat: Verkkokauppa[];
  fieldValue: Verkkokauppa[];
  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(obj: Verkkokauppa[]): void {
    this.fieldValue = this.sanitizeValue(obj);
    const valueToSave = this.cleanValue(this.fieldValue);
    this.updateVerkkokauppaHelpers(valueToSave);
  }

  sanitizeValue(obj: Verkkokauppa[]): Verkkokauppa[] {
    if (obj && obj instanceof Array && obj.length > 0) {
      obj = obj.map(o => new Verkkokauppa(o.osoite, o.enddt));
    } else {
      obj = [];
    }
    return obj;
  }

  updateChanges() {
    const valueToSave = this.cleanValue(this.fieldValue);
    this.updateVerkkokauppaHelpers(valueToSave);
    this._onChange(valueToSave);
    this._onTouched(valueToSave);
  }

  updateVerkkokauppaHelpers(valueToSave: Verkkokauppa[]) {
    this.verkkokaupatUpdate.emit(valueToSave);
    this.verkkokaupatLength = valueToSave.length;
    if (this.verkkokaupatLength > 0) {
      this.verkkokaupatToiminta = true;
    }
    this.firstActiveIndex = 0;
  }

  updateField(field: string, index: number, value: string) {
    this.fieldValue[index][field] = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  addRow() {
    this._addRowClicked = true;
    const yh = new Verkkokauppa();
    yh.lisatty = true;
    this.fieldValue.push(yh);
  }

  removeRow() {
    if (this.verkkokaupatLength > 1) {
      this.removeOrSetInactive(this.findLastActiveIndex());
    }
  }

  removeRowIndex(index: number) {
    if (this.verkkokaupatLength > 0 && index < this.fieldValue.length && index >= 0) {
      this.removeOrSetInactive(index);
    }
  }

  removeAll() {
    this.fieldValue.splice(0, this.verkkokaupatLength);
  }

  removeOrSetInactive(index: number) {
    var verkkokauppa = this.fieldValue.at(index);
    if (verkkokauppa.lisatty) {
      this.fieldValue.splice(index,1);
    } else {
      verkkokauppa.enddt = Date.now().toString();
    }
    this.updateChanges();
  }

  findLastActiveIndex(lastIndex?) {
    if (!lastIndex) {
      lastIndex = this.fieldValue.length - 1;
    }
    return lastIndex;

  }

  cleanValue(obj: Verkkokauppa[]): Verkkokauppa[] {
    return obj.filter(o => o.osoite);
  }

  updateStatus() {
    if(!this.verkkokaupatToiminta && this.verkkokaupatLength > 0) {
      this.storedVerkkokaupat = [...this.fieldValue];
      this.removeAll();
      this.updateChanges();

    } else if (this.verkkokaupatToiminta && this.storedVerkkokaupat != null  && this.storedVerkkokaupat.length > 0) {
      this.fieldValue = this.storedVerkkokaupat;
      this.updateChanges();
    }
  }

  ngAfterViewChecked(): void {
    if (this._addRowClicked) {
      const lastNimiInputId = this.nimiInput.last.htmlId;
      document.getElementById(lastNimiInputId).focus();
      this._addRowClicked = false;
    }
  }

  trackByFn(index: any) {
    return index;
  }
}

export class VerkkokauppaViewOptions {
  osoite: boolean;

  constructor(osoite = true) {
    this.osoite = osoite;
  }
}
