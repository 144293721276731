/**
 * Created by Seppo on 28/02/2018.
 */

import {BrowserModule} from "@angular/platform-browser";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgModule} from "@angular/core";
import {ClickAsyncDirective} from "./click-async.directive";
import {LoaderService} from "./loader.service";
import {ScreenLoaderComponent} from "./screen-loader.component";
import {ShowSpinnerDirective} from "./show-spinner.directive";
import {AttributeModule} from "../tiira/attribute.module";

/**
 * Created by Seppo on 01/12/2017.
 */
@NgModule({
  declarations: [
    ClickAsyncDirective,
    ShowSpinnerDirective,
    ScreenLoaderComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AttributeModule
  ],
  exports: [
    ClickAsyncDirective,
    ShowSpinnerDirective,
    ScreenLoaderComponent
  ],
  providers: [
    LoaderService
  ]
})
export class LoaderModule {
}
