import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {TranslatedQuestionSummaryComponent} from "../../summary/translated-question-summary.component";
import {TulkkiObject} from "../common/lomake-field-utils";
import {FormGroup} from "@angular/forms";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";
import {TranslatedQuestionAndAnswerTwoRowsSummaryComponent} from "../../summary/translated-question-and-answer-two-rows-summary.component";
import {AnswerOnlySummaryComponent} from "../../summary/answer-only-summary.component";

const attr = TulkkiObject.getCreateFn('siemenrekisteri');

const checkboxSummary: SummaryFnType = control => new SummaryItem<any>(control.field.label, null, {show: control.value}, TranslatedQuestionSummaryComponent);

const createTitleSummary = (showFn: (group: FieldGroup) => boolean): SummaryFnType => {
  return (fieldGroup) => {
    return new SummaryItem<any>(
        fieldGroup.field.label,
        null,
        {show: showFn(fieldGroup.asGroup())},
        TitleSummaryComponent)
  }
}

const titleSummary: SummaryFnType = createTitleSummary(group => Object.values(group.controlFields).some(c => c?.summary?.show));

const atLeastOneTrue = (group: FormGroup) => {
  let _atLeastOneTrue = false;
  for (const item in group.controls) {
    if (group.controls[item].value) {
      _atLeastOneTrue = true;
    }
  }
  return _atLeastOneTrue ? null : {atLeastOneTrue: true};
}

export const createMarkkinoimasiKylvosiemenet = () => {
  return new FieldGroup(
      Field.build(
          attr('markkinoimasiKylvosiemenet'),
          titleSummary,
          {atLeastOneTrue: attr('vahintaanYksi')}),
      {
        viljakasvit: new FieldControl(Field.of({
          label: attr('viljakasvit'),
          htmlId: 'viljakasvit',
          summaryFn: checkboxSummary
        })),
        nurmiJaRehu: new FieldControl(Field.of({
          label: attr('nurmiJaRehu'),
          htmlId: 'nurmiJaRehu',
          summaryFn: checkboxSummary
        })),
        oljyJaKuitu: new FieldControl(Field.of({
          label: attr('oljyJaKuitu'),
          htmlId: 'oljyJaKuitu',
          summaryFn: checkboxSummary
        })),
        siemenperuna: new FieldControl(Field.of({
          label: attr('siemenperuna'),
          htmlId: 'siemenperuna',
          summaryFn: checkboxSummary
        })),
        vihanneskasvit: new FieldControl(Field.of({
          label: attr('vihanneskasvit'),
          htmlId: 'vihanneskasvit',
          summaryFn: checkboxSummary
        })),
        juurikkaat: new FieldControl(Field.of({
          label: attr('juurikkaat'),
          htmlId: 'juurikkaat',
          summaryFn: checkboxSummary
        })),
        seokset: new FieldControl(Field.of({label: attr('seokset'), htmlId: 'seokset', summaryFn: checkboxSummary})),
        muutKasvit: new FieldControl(Field.of({
          label: attr('muutKasvit'),
          htmlId: 'muutKasvit',
          summaryFn: checkboxSummary
        })),
        muutKasvitLisatieto: new FieldControl(Field.of({
          label: attr('muutKasvitLisatieto'),
          summaryFn: control => new SummaryItem<any>(null, control.value, {
            show: control.root.get('markkinoimasiKylvosiemenet.muutKasvit').value,
            classList: ["ml-4"]
          }, AnswerOnlySummaryComponent)
        }))
      },
      atLeastOneTrue
  )
}

export const createMuutLisatieto = () => {
  return new FieldGroup(
      Field.of({label: attr('muutKasvitLisatieto'), summaryFn: titleSummary}),
      {
        muutKasvitLisatieto: new FieldControl(
            Field.of({
              label: attr('muutKasvitLisatieto'),
              summaryFn: control => new SummaryItem<any>(null, control.value, {show: control.value}, AnswerOnlySummaryComponent)
            }))
      }
  )
}

const questionAndTranslatedAnswer = c => new SummaryItem<any>(c.field.label, c.value ? 'kylla' : 'ei', {show: c.value !== null}, TranslatedQuestionAndAnswerTwoRowsSummaryComponent);

export const createToiminnanHarjoittaminen = () => {
  return new FieldGroup(
      Field.build(
          attr('toiminnanHarjoittaminen'),
          titleSummary
      ),
      {
        maahantuonti: new FieldControl(Field.of({
          label: attr('maahantuonti'),
          htmlId: 'maahantuonti',
          summaryFn: questionAndTranslatedAnswer
        }), null),
        maastavienti: new FieldControl(Field.of({
          label: attr('maastavienti'),
          htmlId: 'maastavienti',
          summaryFn: questionAndTranslatedAnswer
        }), null),
        luomu: new FieldControl(Field.of({
          label: attr('luomu'),
          htmlId: 'luomu',
          summaryFn: questionAndTranslatedAnswer
        }), null),
      }
  )
}
