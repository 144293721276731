import {TulkkiObject} from "../../common/lomake-field-utils";
import {Field, FieldArray, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {subTitleH3Summary} from "../../lannoiterekisteri/summary-helpers";
import {liiteArray, liitePakollinenArray} from "../../common/lomake-fields/liite";
import {ElaintuotteetSummaryComponent} from "../../../summary/luomuvalvonta/elaintuotteet-summary.component";
import {TilanElaimetSummaryComponent} from "../../../summary/luomuvalvonta/tilan-elaimet-summary.component";

const attr = TulkkiObject.getCreateFn('luomu');

export const createElaintuotanto = () => new FieldGroup(Field.build(attr('elainTuotanto')), {
  tilanElaimet: new FieldGroup(Field.build(null, tilanElaimetSummaryFn), {
    lypsylehma: createTilanElain('lypsylehma', 'lypsylehma'),
    emolehma: createTilanElain('emolehma', 'emolehma'),
    hieho: createTilanElain('hieho', 'hieho'),
    siitossonni: createTilanElain('siitossonni', 'siitossonni'),
    lihanauta: createTilanElain('lihanauta', 'lihanauta'),
    nautaAlle8kk: createTilanElain('nautaAlle8kk', 'nautaAlle8kk'),
    hevonen: createTilanElain('hevonen', 'hevonen'),
    poni: createTilanElain('poni', 'poni'),
    emakkoEnsikko: createTilanElain('emakkoEnsikko', 'emakkoEnsikko'),
    karju: createTilanElain('karju', 'karju'),
    lihasika: createTilanElain('lihasika', 'lihasika'),
    uuhi: createTilanElain('uuhi', 'uuhi'),
    passi: createTilanElain('passi', 'passi'),
    karitsaAlle6kk: createTilanElain('karitsaAlle6kk', 'karitsaAlle6kk'),
    munituskana: createTilanElain('munituskana', 'munituskana'),
    emolintu: createTilanElain('emolintu', 'emolintu'),
    kananuorikko: createTilanElain('kananuorikko', 'kananuorikko'),
    broileri: createTilanElain('broileri', 'broileri'),
    kalkkuna: createTilanElain('kalkkuna', 'kalkkuna'),
    hanhi: createTilanElain('hanhi', 'hanhi'),
    ankka: createTilanElain('ankka', 'ankka'),
    mehilaispesa: new FieldGroup(Field.build(), {
      elainValittu: new FieldControl(Field.build()),
      luonnonmukainenLkm: new FieldControl(Field.build()),
      tavanomainenLkm: new FieldControl(Field.build()),
      sijaintiJaPitopaikat: liitePakollinenArray(attr('sijaintiJaPitopaikat'), 'sijaintiJaPitopaikat', null, 'luomu.errSijaintiJaPitopaikatPakollinen'),
      saastumislahteidenArviointi: liitePakollinenArray(attr('saastumislahteidenArviointi'), 'saastumislahteidenArviointi', null, 'luomu.errSaastumislahteidenArviointiPakollinen'),
    }),
    muutElaimet: new FieldArray(Field.build(), () => createMuuElain(),
        [createMuuElain()]),
  }),
  elaintuotteet: new FieldGroup(Field.build(null, elaintuotteetSummaryFn), {
    maito: createElaintuote('maitoLabel', 'maitoCheckbox'),
    naudanliha: createElaintuote('naudanlihaLabel', 'naudanlihaCheckbox'),
    vasikat: createElaintuote('vasikatLabel', 'vasikatCheckbox'),
    sianliha: createElaintuote('sianlihaLabel', 'sianlihaCheckbox'),
    porsaat: createElaintuote('porsaatLabel', 'porsaatCheckbox'),
    siipikarjanliha: createElaintuote('siipikarjanlihaLabel', 'siipikarjanlihaCheckbox'),
    kananmunat: createElaintuote('kananmunatLabel', 'kananmunatCheckbox'),
    kananuorikko: createElaintuote('kananuorikkoLabel', 'kananuorikkoCheckbox'),
    lampaanliha: createElaintuote('lampaanlihaLabel', 'lampaanlihaCheckbox'),
    hunaja: createElaintuote('hunajaLabel', 'hunajaCheckbox'),
    kuhnurit: createElaintuote('kuhnuritLabel', 'kuhnuritCheckbox'),
    muutElaintuotteet: new FieldArray(Field.build(), () => createMuuElaintuote(),
        [createMuuElaintuote()]),
  }),
  elaintuotannonPoikkeuslupa: new FieldGroup(Field.build(attr('elaintuotannonPoikkeuslupa'), subTitleH3Summary), {
    elaintuotannonPoikkeuslupaValinta: new FieldControl(Field.build()),
    hakemusElaintuotannonPoikkeusluvasta: liitePakollinenArray(attr('elaintuotannonPoikkeuslupaHaku'), 'hakemusElaintuotannonPoikkeusluvasta', null, attr('hakemusElaintuotannonPoikkeusluvastaVirheviesti'))
  }),
  liitteet: new FieldGroup(Field.build(attr('liitteet'), subTitleH3Summary), {
    elaintenhoitosuunnitelma: liitePakollinenArray(attr('elaintenhoitosuunnitelma'), 'elaintenhoitosuunnitelma', null, attr('elaintenhoitosuunnitelmaPakollinenVirheviesti')),
    pohjapiirrosTuotantorakennuksesta: liitePakollinenArray(attr('pohjapiirrosTuotantorakennuksesta'), 'pohjapiirrosTuotantorakennuksesta', null, attr('pohjapiirrosTuotantorakennuksestaPakollinenVirheviesti')),
    kuvausJaPohjapiirrosUlkotarhoistaJaJaloittelualueista: liiteArray(attr('kuvausJaPohjapiirrosUlkotarhoistaJaJaloittelualueista'), 'kuvausJaPohjapiirrosUlkotarhoistaJaJaloittelualueista'),
    selvitysLaitumista: liiteArray(attr('selvitysLaitumista'), 'selvitysLaitumista'),
    ylimaarainenLannanLevitystaKoskevaSopimus: liiteArray(attr('ylimaarainenLannanLevitystaKoskevaSopimus'), 'ylimaarainenLannanLevitystaKoskevaSopimus'),
    muuLiite: liiteArray(attr('muuLiite'), 'muuLiite'),
    liiteSisaltoDesc: new FieldControl(Field.of({
      summaryFn: control => new SummaryItem(control.field.label, control.value, {show: control.value}),
      label: attr('kerroLiitteenSisallosta'),
      htmlId: 'liitteenSisalto'
    }))
  })
})

const createElaintuote = (label, htmlId) => {
  return new FieldGroup(Field.of({
    label: attr(label),
    htmlId: htmlId,
  }), {
    luonnonmukainen: new FieldControl(Field.build()),
    tavanomainen: new FieldControl(Field.build())
  })
}

const createMuuElaintuote = () => {
  return new FieldGroup(Field.build(), {
    nimi: new FieldControl(Field.build()),
    luonnonmukainen: new FieldControl(Field.build()),
    tavanomainen: new FieldControl(Field.build())
  })
}

const elaintuotteetSummaryFn: SummaryFnType = (elaintuotteetGroup: FieldGroup) => {
  const tuotteet = Object.values(elaintuotteetGroup.controlFields)

  const someElaintuoteHasValue = tuotteet.some(tuote => {
    if (tuote instanceof FieldGroup) {
      return tuote.get('luonnonmukainen').value || tuote.get('tavanomainen').value;
    }
  })

  const muutElaintuotteetArray = tuotteet.find(tuote => tuote instanceof FieldArray) as FieldArray
  const someMuuElaintuoteHasValue = muutElaintuotteetArray.controlFields.some(muuElaintuote => {
    return muuElaintuote.get('nimi').value && (muuElaintuote.get('luonnonmukainen').value || muuElaintuote.get('tavanomainen').value)
  })

  return new SummaryItem<any>(null, elaintuotteetGroup, {show: someElaintuoteHasValue || someMuuElaintuoteHasValue}, ElaintuotteetSummaryComponent);
};

const tilanElaimetSummaryFn: SummaryFnType = (tilanElaimetGroup: FieldGroup) => {
  const tilanElaimet = Object.values(tilanElaimetGroup.controlFields)

  const someElainValittu = tilanElaimet.some(tuote => {
    if (tuote instanceof FieldGroup) {
      return tuote.get('elainValittu').value;
    }
  })

  const muutElaintuotteetArray = tilanElaimet.find(tuote => tuote instanceof FieldArray) as FieldArray
  const someMuuElainValittu = muutElaintuotteetArray.controlFields.some(muuElain => {
    return muuElain.get('nimi').value;
  })

  return new SummaryItem<any>(null, tilanElaimetGroup, {show: someElainValittu || someMuuElainValittu}, TilanElaimetSummaryComponent);
};

const createTilanElain = (label, htmlId) => {
  return new FieldGroup(Field.build(), {
    elainValittu: new FieldControl(Field.of({
      label: attr(label),
      htmlId: htmlId,
    })),
    luonnonmukainenLkm: new FieldControl(Field.build()),
    tavanomainenLkm: new FieldControl(Field.build())
  })
}

const createMuuElain = () => {
  return new FieldGroup(Field.build(), {
    nimi: new FieldControl(Field.build()),
    luonnonmukainenLkm: new FieldControl(Field.build()),
    tavanomainenLkm: new FieldControl(Field.build())
  })
}
