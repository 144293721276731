import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LoginState, TiiraService } from "../tiira/tiira.service";
import { PalveluService } from "../sidebar/palvelu.service";

/**
 * Route guard, jonka avulla voidaan varmistaa että käyttäjä on kirjautunut sisään
 * ennen navigoinnin suorittamista.
 */
@Injectable()
export class LoginGuardService implements CanActivate {

  constructor(public tiira: TiiraService, public sidebarService: PalveluService, public router: Router) {}

  canActivate(): boolean {
    if (this.tiira.isLoggedIn() !== LoginState.LOGGEDIN) {
      this.sidebarService.hideSidebar();
      this.router.navigate(['login']);
      return false;
    }

    this.sidebarService.showSidebar();
    return true;
  }
}
