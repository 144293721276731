import {Injectable} from "@angular/core";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {Field, FieldGroup} from "ngx-fielding";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {createToiminnanAjankohta} from "../common/lomake-fields/toiminnan-ajankohta";
import {TulkkiObject} from "../common/lomake-field-utils";
import {
  createHakemusTyypit,
  createKoneetJaLaitteet,
  createLisatiedotToiminta,
  createMarkkinoimasiKylvosiemenet,
  createToiminnanLaajuus,
  createUudetPakkaamotDate,
  createVakuusLipukkeet
} from "./siemenpakkaamo-v2-data-fields";
import {ToukoValidation} from "../../validators/validators";
import {Validators} from "@angular/forms";

export const attr = TulkkiObject.getCreateFn('siemenpakkaamo');

@Injectable()
export class SiemenpakkaamoV2Data implements LomakeV2Data {
  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(toimintotyyppi?: string): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();
    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
        Field.build(
            attr('siemenpakkaamolupahakemus')
        ), {
          ulkomainenOsoite,
          perustiedot,
          laskutustiedot,
          hakemustyyppi: createHakemusTyypit(),
          yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
          toiminnanAloitus: createToiminnanAjankohta(toimintotyyppi, null),
          markkinoimasiKylvosiemenet: createMarkkinoimasiKylvosiemenet([ToukoValidation.atLeastOneTrue]),
          toiminnanLaajuus: createToiminnanLaajuus([ToukoValidation.atLeastOneTrue]),
          koneetJaLaitteet: createKoneetJaLaitteet([ToukoValidation.atLeastOneTrue]),
          lisatiedot: createLisatiedotToiminta(),
          painatus: createVakuusLipukkeet([ToukoValidation.atLeastOneTrue]),
          tarkastusAjankohta: createUudetPakkaamotDate([Validators.required, ToukoValidation.isValidDate])
        })
  }
}
