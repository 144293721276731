import {Component} from "@angular/core";
import {FieldArray} from "ngx-fielding";
import {LomakeHelper, YhteyshenkiloValue} from "../touko-lomake-utils";
import {Teksti} from "../../utils/teksti";
import {LomakeV2BaseDirective} from "../vuosiilmoitus/common/lomake-base/lomake-v2-base.directive";
import {ProcessLomakeService} from "../vuosiilmoitus/common/lomake-base/process-lomake.service";
import {Luomupoikkeus9cV2Data} from "./luomupoikkeus9c-v2-data";
import {AccountService} from "../../account/account.service";
import {PerustiedotSkipValidationOptions} from "../vuosiilmoitus/common/lomake-fields/perustiedot";
import {LomakeInfo} from "../vuosiilmoitus/common/lomake-info";
import {FormArray} from "@angular/forms";

@Component({
  selector: 'luomupoikkeus9c-v2',
  templateUrl: 'luomupoikkeus9c-v2.component.html',
  providers: [Luomupoikkeus9cV2Data, ProcessLomakeService],
  styleUrls: ['luomupoikkeus9c-v2.component.scss']
})
export class Luomupoikkeus9cV2Component extends LomakeV2BaseDirective {

  constructor(processLomakeService: ProcessLomakeService,
              data: Luomupoikkeus9cV2Data,
              accountService: AccountService) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
  }

  handleLeaveSivu(leaveFromSivu: number) {
    if (!this.lomakeMetaData.id && leaveFromSivu === 0) {
      this.saveLomake();
    } else if (leaveFromSivu === 1) {
      this.luvatTavanomLisaysaineisto.markAllAsTouched();
    }
  }

  get luvatTavanomLisaysaineisto(): FormArray {
    return this.lomake.get(['luvatTavanomLisaysaineisto', 'luvatTavanomLisaysaineisto']).asArray();
  }

  get liite(): FieldArray {
    return this.lomake.get('liite.liite').asArray()
  }

  initLomakeInfo(accountType: 'as' | 'vk', toimintotyyppi: string): LomakeInfo {
    return new Luomupoikkeus9CV2Info(accountType, toimintotyyppi);
  }

  initLomakeBehaviour() {
    // Ei toimintoja
  }

  addLomakeBehaviour(): void {
    // Ei toimontoja
  }

  lisaaLupa() {
    const luvat = this.lomake.get(['luvatTavanomLisaysaineisto', 'luvatTavanomLisaysaineisto']).asArray();
    luvat.push(luvat.buildField());
  }
}

class Luomupoikkeus9CV2Info implements LomakeInfo {
  asia: string;
  skipPerustiedotValidation = PerustiedotSkipValidationOptions.skipLaskutustiedot();
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.LUOMU_YH.value;
  lomakeTitle: Teksti = new Teksti(
      'Poikkeuslupa, lisäysaineisto',
      'otsikko',
      'luomuPoikkeus9c');
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: 'as' | 'vk', toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.LUOMU_YH];
    this.asia = 'luomupoikkeus9c';
    this.toimintotyyppi = toimintotyyppi;

    const suffix = '';
    const prefix = 'luomuPoikkeus9c.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1_1'], suffix);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2_1'], suffix);

    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/${toimintotyyppi}/luomupoikkeus9c/${toimintotyyppi}`;
  }
}
