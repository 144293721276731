import {FormControl, FormGroup} from "@angular/forms";
import {Teksti} from "../utils/teksti";
import {Perustiedot, Yhteyshenkilo} from "./syote/syote-utils";
import {VirheObjekti} from "./validators/validators";
import {Account} from "../account/account";

/**
 * Created by Seppo on 24/11/2017.
 */

/**
 * Lomakkeen tallennusmuoto
 */
export interface LomakeJSONOld {
  title: string;
  data: {
    groupName: string;
    values: { field: string; value: any }[];
  }[];
}

export interface LomakeJSON {
  title: string;
  data: {[key: string]: any};
}

export class AsiakasResponse {
  elmoid: string;
  hakunimi: string;

  constructor(elmoid?: string, hakunimi?: string) {
    this.elmoid = elmoid;
    this.hakunimi = hakunimi;
  }
}

export class LokitietoResponse {
  id: number;
  lomakeId: number;
  versio: number;
  asia: string;
  toimintotyyppi: string;
  tiedot: string;
  viesti: string;
  aikaleima: string;
}

export interface LomakeResponse {
  id: number;
  laheteId?: string;
  asia?: string;
  lomaketyyppiVersio: number;
  kuvaus?: string;
  kesken?: boolean;
  lahetetty?: number;
  mallipohjanimi?: string;
  toimintotyyppi?: string;
  sisalto?: any;
  lahettajaNimi?: string;
  lahetysAika?: string;
  lahettajaAsiointitunnus?: string;
  lahettajaYritys?: string;
  lahettajaValvontakohde?: string;
  lahettajaValvontakohdeAktiivinen?: boolean;
  lahettajaAsiakasElmoid?: string;
  lahettajaMaatilatunnus?: string;
  paipvm?: string;
  muokannutToimija?: string;
  viimeksiMuokannut?: boolean;
  perpvm?: string;
  viimeksiKasiteltyAika?: string;
  tila?: string;
  lisatieto?: string;
  alkuperainenLomakeId?: number;
  arkistoAsiatunnus?: number;
  versio?: number;
  lomakeVirhe?: LomakeVirhe;
}

export class LomakeVirhe {
  virheKoodi;
  virheViesti;
}

export class LomakeRequest {
  body: string;
  liitteet: LiitetiedostoResponse[];
  perustiedot: Perustiedot;
  yhteyshenkilot: Yhteyshenkilo[];
}

export enum LomakeTila {
  KASITELTY = "KASITELTY",
  HYLATTY = "HYLATTY",
  ODOTTAA_KASITTELYA = "ODOTTAA_KASITTELYA",
  ODOTTAA_LISATIETOJA = "ODOTTAA_LISATIETOJA",
  KASITTELYSSA = "KASITTELYSSA",
  TOIMITETTU = "TOIMITETTU"
}

export class LomakeKasittely {
  id: number;
  tila: LomakeTila;
  lisatieto: string;
  kasittelija: string;
  kuvaus: Teksti;
  asiakasElmoid: string;
  sendMail: boolean;
  sendToElmo: boolean;

  constructor(id: number = null,
    tila: LomakeTila = null,
    lisatieto: string = null,
    kasittelija: string = null,
    kuvaus: Teksti = null,
    asiakasElmoid: string = null,
    sendMail = true,
    sendToElmo = true) {
    this.id = id;
    this.tila = tila;
    this.lisatieto = lisatieto;
    this.kasittelija = kasittelija;
    this.kuvaus = kuvaus;
    this.asiakasElmoid = asiakasElmoid;
    this.sendMail = sendMail;
    this.sendToElmo = sendToElmo;
  }
}

export class Allekirjoitus {
  kokoNimi: string;
  ytunnus: string;
  paatoiminimi;
  valvontakohde: string;
  valvontakohdeAktiivinen: boolean;
  osasto: string;
  maatilatunnus: string;
  vatNumero: string;

  constructor(kokoNimi = null, ytunnus = null, paatoiminimi = null, valvontakohde = null, valvontakohdeAktiivinen = null, osasto = null, maatilatunnus = null) {
    this.kokoNimi = kokoNimi;
    this.ytunnus = ytunnus;
    this.vatNumero = ytunnus ? `FI${ytunnus.replace('-', '')}` : null;
    this.paatoiminimi = paatoiminimi;
    this.valvontakohde = valvontakohde;
    this.valvontakohdeAktiivinen = valvontakohdeAktiivinen;
    this.osasto = osasto;
    this.maatilatunnus = maatilatunnus;
  }

  static createFromLomake(lomake: LomakeResponse): Allekirjoitus {
    if (lomake) {
      return new Allekirjoitus(
        lomake.lahettajaNimi,
          lomake.lahettajaMaatilatunnus ? null : lomake.lahettajaAsiointitunnus,
        lomake.lahettajaYritys,
        lomake.lahettajaValvontakohde,
        lomake.lahettajaValvontakohdeAktiivinen,
        null,
        lomake.lahettajaMaatilatunnus
      );
    }

    return new Allekirjoitus();
  }

  static createFromAccount(account: Account): Allekirjoitus {
    const ytunnus = account.ytunnus;
    const maatilatunnus = account.maatilatunnus;
    const yritysNimi = account.yritys?.paaToiminimi || null;
    const vkTunnus = account.valvontakohdeElmoId || ytunnus;
    const osasto = account.valvontakohde?.toimipaikka?.nimi_fi || null;
    const vkAktiivinen = ytunnus !== vkTunnus;

    return new Allekirjoitus(account.wholeName, ytunnus, yritysNimi, vkTunnus, vkAktiivinen, osasto, maatilatunnus);
  }
}

export class LiitetiedostoResponse {
  id: number;
  lomakeId: number;
  tiedostonimi: string;
  tiedostotyyppi: string;
  selite: string;
}

const LOMAKE_LYHYTKUVAUKSET = {
  UUSI: new Teksti("Uusi ilmoitus", "uusiIlmoitus", "etusivu"),
  MUUTOS: new Teksti("Muutosilmoitus", "muutosilmoitus", "etusivu"),
  LOPETUS: new Teksti("Lopetusilmoitus", "lopetusilmoitus", "etusivu"),
};

const VUOSIILMOITUS_LYHYTKUVAUKSET = {
  VUOSIILMOITUS: new Teksti("Vuosiilmoitus", "vuosiilmoitus", "etusivu")
};

const LUOMU_HAKEMUS = {
  HAKEMUS: new Teksti("Hakemus", "hakemus", "etusivu")
};

export interface LomakeKuvaus {
  UUSI?: Teksti;
  MUUTOS?: Teksti;
  LOPETUS?: Teksti;
  HAKEMUS?: Teksti;
  VUOSIILMOITUS?: Teksti;
  LYHYT_KUVAUS: {
    UUSI?: Teksti;
    MUUTOS?: Teksti;
    LOPETUS?: Teksti;
    HAKEMUS?: Teksti;
    VUOSIILMOITUS?: Teksti;
  };
}


export const LOMAKE_KUVAUKSET = {
  'kylvosiemen-markkinointi': {
    UUSI: new Teksti("Uusi ilmoitus. Kylvösiemenen markkinointi- maahantuonti ja maastavienti.", "kylvosiemenUusiKuvaus", "etusivu"),
    MUUTOS: new Teksti("Muutosilmoitus. Kylvösiemenen markkinointi- maahantuonti ja maastavienti.", "kylvosiemenMuutosKuvaus", "etusivu"),
    LOPETUS: new Teksti("Lopetusilmoitus. Kylvösiemenen markkinointi- maahantuonti ja maastavienti.", "kylvosiemenLopetusKuvaus", "etusivu"),
    LYHYT_KUVAUS: { ...LOMAKE_LYHYTKUVAUKSET }
  },
  siemenpakkaamolupa: {
    HAKEMUS: new Teksti("Uusi ilmoitus. Siemenpakkaamolupahakemus.", "siemenpakkaamoUusiKuvaus", "etusivu"),
    LYHYT_KUVAUS: { HAKEMUS: new Teksti("Uusi ilmoitus. Siemenpakkaamolupahakemus.", "siemenpakkaamoHakemus", "etusivu") }
  },
  rehuvalvonta: {
    UUSI: new Teksti("Uusi ilmoitus. Rehuhygienia-asetuksen mukainen rekisteröinti.", "rehuvalvontaUusiKuvaus", "etusivu"),
    MUUTOS: new Teksti("Muutosilmoitus. Rehuhygienia-asetuksen mukainen rekisteröinti.", "rehuvalvontaMuutosKuvaus", "etusivu"),
    LOPETUS: new Teksti("Lopetusilmoitus. Rehuhygienia-asetuksen mukainen rekisteröinti.", "rehuvalvontaLopetusKuvaus", "etusivu"),
    LYHYT_KUVAUS: { ...LOMAKE_LYHYTKUVAUKSET }
  },
  lannoiterekisteri: {
    UUSI: new Teksti("Uusi ilmoitus. Lannoitevalmistetoiminnan rekisteröinti.", "lannoitetoimintaUusiKuvaus", "etusivu"),
    MUUTOS: new Teksti("Muutosilmoitus. Lannoitevalmistetoiminnan mukainen rekisteröinti.", "lannoitetoimintaMuutosKuvaus", "etusivu"),
    LOPETUS: new Teksti("Lopetusilmoitus. Lannoitevalmistetoiminnan mukainen rekisteröinti.", "lannoitetoimintaLopetusKuvaus", "etusivu"),
    LYHYT_KUVAUS: { ...LOMAKE_LYHYTKUVAUKSET }
  },
  kasvinterveysrekisteri: {
    UUSI: new Teksti("Uusi ilmoitus. Kasvinterveydenalan toiminnan mukainen rekisteröinti.", "kasvinterveystoimintaUusiKuvaus", "etusivu"),
    MUUTOS: new Teksti("Muutosilmoitus. Kasvinterveydenalan toiminnan mukainen rekisteröinti.", "kasvinterveystoimintaMuutosKuvaus", "etusivu"),
    LOPETUS: new Teksti("Lopetusilmoitus. Kasvinterveydenalan toiminnan mukainen rekisteröinti.", "kasvinterveystoimintaLopetusKuvaus", "etusivu"),
    LYHYT_KUVAUS: { ...LOMAKE_LYHYTKUVAUKSET }
  },
  luomuvalvonta: {
    UUSI: new Teksti("Uusi ilmoitus. Luomuvalvonnan toiminnan rekisteröinti", "luomuvalvontaUusiKuvaus", "etusivu"),
    LYHYT_KUVAUS: {...LOMAKE_LYHYTKUVAUKSET}
  },
  luomupoikkeus9c: {
    HAKEMUS: new Teksti("Poikkeuslupa, lisäysaineisto 9c", "9cMuutosKuvaus", "etusivu"),
    LYHYT_KUVAUS: { ...LUOMU_HAKEMUS }
  },
  rehuvuosiilmoitus:{
    VUOSIILMOITUS: new Teksti("Vuosiilmoitus", "vuosiilmoitus", "rehuvuosiilmoitus"),
    LYHYT_KUVAUS: { ...VUOSIILMOITUS_LYHYTKUVAUKSET }
  },
  lannoitevuosiilmoitus:{
    VUOSIILMOITUS: new Teksti("Vuosiilmoitus", "vuosiilmoitus", "lannoitevuosiilmoitus"),
    LYHYT_KUVAUS: { ...VUOSIILMOITUS_LYHYTKUVAUKSET }
  }
};

export const LOMAKE_TILA_BADGE = {
  [LomakeTila.HYLATTY]: { tyyli: 'badge-danger', teksti: new Teksti("-", "tilaHylatty", "etusivu") },
  [LomakeTila.KASITELTY]: { tyyli: 'badge-success', teksti: new Teksti("-", "tilaKasitelty", "etusivu") },
  [LomakeTila.ODOTTAA_KASITTELYA]: { tyyli: 'badge-primary', teksti: new Teksti("-", "tilaOdottaaKasittelya", "etusivu") },
  [LomakeTila.ODOTTAA_LISATIETOJA]: { tyyli: 'badge-info', teksti: new Teksti("-", "tilaOdottaaLisatietoja", "etusivu") },
  [LomakeTila.KASITTELYSSA]: { tyyli: 'badge-warning', teksti: new Teksti("-", "tilaKasittelyssa", "etusivu") },
  [LomakeTila.TOIMITETTU]: { tyyli: 'badge-success', teksti: new Teksti("-", "tilaKasitelty", "etusivu") },
};

export const LOMAKE_KASITTELY_MESSAGE = {
  [LomakeTila.KASITELTY]: new Teksti("Hyväksyttiin", "lomakeHyvaksyttiin", "lomakeYleinen"),
  [LomakeTila.HYLATTY]: new Teksti("Hylättiin", "lomakeHylattiin", "lomakeYleinen"),
  [LomakeTila.ODOTTAA_LISATIETOJA]: new Teksti("Odottaa lisätietoa", "lomakeOdottaaLisatietoa", "lomakeYleinen")
};

export class ValidatorOptions {
  validators: ((g: FormGroup | FormControl) => VirheObjekti)[];
  errorMsgs: { [s: string]: Teksti };
}

export class YhteyshenkiloValue {
  public static VK_YH = new YhteyshenkiloValue('ReVCCControlContact', new Teksti("Yhteyshenkilö", "rooliVkYhteyshenkilo", "lomakeYleinen"));
  public static VK_VARA_YH = new YhteyshenkiloValue('ReVCCControlContactS', new Teksti("Varayhteyshenkilö", "rooliVaraVkYhteyshenkilo", "lomakeYleinen"));
  public static SIEM_KAUPP = new YhteyshenkiloValue('ReVCCRSeedMark', new Teksti("Siemenkauppavastaava", "rooliSiemKauppVastaava", "lomakeYleinen"));
  public static SIEM_PAKK = new YhteyshenkiloValue('ReVCCRSeed', new Teksti('SiemenpakkaamoYhteyshenkilo', "rooliSiementoimija", "lomakeYleinen"));
  public static KATE_SUOJELU = new YhteyshenkiloValue('ReVCCRPlantCustA', new Teksti("Kasvinsuojeluvastaava", "rooliKateSuojeluVastaava", "lomakeYleinen"), true);
  public static KATE_VASTAAVA = new YhteyshenkiloValue('ReVCCPlantHealthContact', new Teksti("Kasvinterveysvastaava", "rooliKateKasvinterveysvastaava", "lomakeYleinen"));
  public static KATE_ISPM15 = new YhteyshenkiloValue('ReVCCRISPM15', new Teksti("ISMP 15 -vastaava", "rooliKateISMPVastaava", "lomakeYleinen"), true);
  public static TOIMIJAN_YH = new YhteyshenkiloValue('ReVCCCustContact', new Teksti("Toimijan yhteyshenkilö", "rooliToimijanYH", "lomakeYleinen"));
  public static TUONNIN_YH = new YhteyshenkiloValue('ReVCCImpContact', new Teksti("Tuonnin yhteyshenkilö", "rooliTuonninYH", "lomakeYleinen"));
  public static VUOS_ILM = new YhteyshenkiloValue('ReVCCYearContact', new Teksti("Vuosi-ilmoitukset", "rooliVuosIlm", "lomakeYleinen"));
  public static LUOMU_YH = new YhteyshenkiloValue('ReVCCROrganicCust', new Teksti("Yhteyshenkilö", "rooliLuomuYhteyshenkilo", "lomakeYleinen"));
  public static TOUKO_LOPETUS = new YhteyshenkiloValue('ToukoLopetusYhteyshenkilo', null);

  value: string;
  teksti: Teksti;
  disabled: boolean;

  constructor(value: string, teksti: Teksti, disabled = false) {
    this.value = value;
    this.teksti = teksti;
    this.disabled = disabled;
  }
}

export const YhteyshenkiloTeksti = {
  [YhteyshenkiloValue.VK_YH.value]: YhteyshenkiloValue.VK_YH.teksti,
  [YhteyshenkiloValue.VK_VARA_YH.value]: YhteyshenkiloValue.VK_VARA_YH.teksti,
  [YhteyshenkiloValue.SIEM_KAUPP.value]: YhteyshenkiloValue.SIEM_KAUPP.teksti,
  [YhteyshenkiloValue.SIEM_PAKK.value]: YhteyshenkiloValue.SIEM_PAKK.teksti,
  [YhteyshenkiloValue.KATE_VASTAAVA.value]: YhteyshenkiloValue.KATE_VASTAAVA.teksti,
  [YhteyshenkiloValue.KATE_SUOJELU.value]: YhteyshenkiloValue.KATE_SUOJELU.teksti,
  [YhteyshenkiloValue.KATE_ISPM15.value]: YhteyshenkiloValue.KATE_ISPM15.teksti,
  [YhteyshenkiloValue.TOIMIJAN_YH.value]: YhteyshenkiloValue.TOIMIJAN_YH.teksti,
  [YhteyshenkiloValue.TUONNIN_YH.value]: YhteyshenkiloValue.TUONNIN_YH.teksti,
  [YhteyshenkiloValue.VUOS_ILM.value]: YhteyshenkiloValue.VUOS_ILM.teksti,
  [YhteyshenkiloValue.LUOMU_YH.value]: YhteyshenkiloValue.LUOMU_YH.teksti,
};

export class LomakeHelper {
  static getOhjeList(prefix: string, keys: string[], suffix = ""): string[] {
    return keys.map(k => `${prefix}${k}${suffix}`);
  }
}

export class LomakeConverter {

  /**
   * Konvertoi annetun lomakkeen JSON-muotoon.
   *
   * Voidaan käyttää esimerkiksi lomakkeen lähettämiseen ja
   * yhteenvedon muodostamiseen
   */
  static convertToJSON(title: string, formValues: any, excludedRawValueFields = ['yritys', 'ytunnus']): LomakeJSONOld {
    const json = {
      title,
      data: []
    };

    for (const group in formValues) {
      if (!formValues.hasOwnProperty(group)) {
        continue;
      }

      const groupValues = [];
      const formGroupValues = formValues[group];

      for (const item in formGroupValues) {
        if (!formGroupValues.hasOwnProperty(item) || excludedRawValueFields.includes(item)) {
          continue;
        }
        const val = formGroupValues[item];
        if (val !== null) {
          groupValues.push({ field: item, value: val });
        }
      }

      json.data.push({ groupName: group, values: groupValues });
    }

    return json;
  }

  /**
   * Konvertoi lomakkeen arvot "groupName / field"-muodosta JSON-muotoon
   *
   * @param formData
   */
  static convertToLomakeValues(formData: any): { [key: string]: any } {
    return Object.entries(formData).reduce((group, [_index, groupEntry]) => {
      const groupValues = {};
      // @ts-ignore
      groupEntry.values.forEach(groupEntryValue => {
        groupValues[groupEntryValue.field] = groupEntryValue.value;
      });

      group[(groupEntry as any).groupName] = groupValues;
      return group;
    }, {});
  }
}
