import {TulkkiObject} from "../common/lomake-field-utils";
import {AbstractField, Field, FieldArray, FieldControl, FieldGroup, SummaryItem} from "ngx-fielding";

import {createVerkkokaupatGroup} from "../common/lomake-fields/verkkokaupat";
import {titleSummary} from "./summary-helpers";
import {LiiteSummaryComponent} from "../../summary/liite-summary.component";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

export const createLisatiedotJaLiitteet = (toimintotyyppi) => {
  return {
    lisatiedot: createLisatiedot(),
    liitePakolliset: createLiitePakolliset(toimintotyyppi),
    liiteMuut: createLiiteMuut(),
    verkkokaupat: createVerkkokaupatGroup()
  }
}

const createLisatiedot = () => new FieldGroup(Field.build(
    attr('toimintaKuvausOtsikko'), titleSummary), {
    uusiIlmoitusToimintaKuvaus: new FieldControl(Field.of({
      label: attr('uusiIlmoitusToimintaKuvaus'),
      htmlId: 'liiteMuu1',
      summaryFn: (control) => new SummaryItem<any>(control.field.label, control.value, {show: control.value})
    })),
  }
);

const createLiitePakolliset = (toimintotyyppi) => {
  const validator = (group) => {
    const hasLiite = group.get('liite').value.length > 0;
    const hasDate = group.get('pvm').value
    return hasLiite || hasDate ? null : {liiteTaiPvmSelected: true}
  };

  const liitteet = new FieldGroup(Field.build(
      attr('liiteTiedot'), titleSummary), {
      liiteValmisteet: new FieldGroup(Field.of({
          errorMessages: {liiteTaiPvmSelected: attr('valmisteenTuotetiedotPakollinen')}}
        ), {
          liite: liiteArray(attr('liiteValmisteet'), 'liite-valmisteet'),
          pvm: new FieldControl(Field.build(
              attr('liiteValmisteetPvm'),
              pvmSummary),
            {value: null, disabled: true}),
          isLiiteSelected: new FieldControl(Field.build())
        },
      validator),
      liiteLaatujarjestelma: new FieldGroup(Field.build(), {
        liite: liiteArray(attr('liiteLaatujarjestelma'), 'liite-laatujarjestelma'),
        pvm: new FieldControl(Field.build(
            attr('liiteLaatujarjestelmaPvm'),
            pvmSummary),
          {value: null, disabled: true}),
        isLiiteSelected: new FieldControl(Field.build()),
      }),
    }
  );

  if (toimintotyyppi === 'muutos') {
    liitteet.get('liiteValmisteet').removeValidators(validator);
    liitteet.updateValueAndValidity();
  }

  return liitteet;
}

const pvmSummary = (control): SummaryItem<any> => new SummaryItem<any>(
  control.field.label,
  `${control?.value?.day}.${control?.value?.month}.${control?.value?.year}`,
  {show: showPvmSummary(control)});

const showPvmSummary = (control: FieldControl): boolean => {
  const isLiiteSelected = control.parent.get('isLiiteSelected').value;
  return !isLiiteSelected && control?.value && control?.value?.day;
}

const createLiiteMuut = () => new FieldGroup(Field.build(
    attr('liiteMuut'), titleSummary), {
    liiteMuut: new FieldArray(
      Field.of({label: attr('liiteMuut'), htmlId: 'liiteMuut'}),
      () => new FieldGroup(
        Field.of({
          label: attr('liiteMuut'),
          summaryFn: group => new SummaryItem<any>(null, group.value, {show: showLiiteSummary(group)}, LiiteSummaryComponent),
          htmlId: 'liiteMuut'
        }),
        {
          lomakeId: new FieldControl(Field.build()),
          tiedostotyyppi: new FieldControl(Field.build()),
          tiedostonimi: new FieldControl(Field.build()),
          id: new FieldControl(Field.build()),
          valvontakohde: new FieldControl(Field.build())
        }
      )
    )
  }
);

const liiteArray = (groupTitle, liiteHtmlId, liiteLabel?) => {
  liiteLabel = liiteLabel || groupTitle;
  return new FieldArray(
    Field.of({label: groupTitle, htmlId: liiteHtmlId}),
    liiteFieldGroupFn(liiteLabel, liiteHtmlId)
  );
}

const liiteFieldGroupFn = (label, htmlId) => {
  return () => new FieldGroup(
    Field.of({
      label: label,
      summaryFn: group => new SummaryItem<any>(group.field.label, group.value, {show: showLiiteSummary(group)}, LiiteSummaryComponent),
      htmlId: htmlId
    }),
    {
      lomakeId: new FieldControl(Field.build()),
      tiedostotyyppi: new FieldControl(Field.build()),
      tiedostonimi: new FieldControl(Field.build()),
      id: new FieldControl(Field.build()),
      valvontakohde: new FieldControl(Field.build())
    }
  )
}

const showLiiteSummary = (group: AbstractField): boolean => {
  const groupParent = group.parent;
  const isLiiteSelected = groupParent.parent.get('isLiiteSelected')?.value;
  return isLiiteSelected && group?.value;
}

