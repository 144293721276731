import { FormControl } from "@angular/forms";
import { ValidatorsBase } from "./validators-base";

// @dynamic
export class NaytetiedotValidators extends ValidatorsBase {
    static isValidEutunnus(control: FormControl) {
        const euTunnus = control.value || "";
        const isValid = euTunnus.length === 0 || (euTunnus.length === 14 && euTunnus.startsWith('FI'));
        return NaytetiedotValidators.validate(isValid, "euTunnus");
    }
}
