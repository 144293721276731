import {PalveluService} from "./palvelu.service";
import {SidebarComponent} from "./sidebar.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {AttributeModule} from "../tiira/attribute.module";
import {HttpClientModule} from "@angular/common/http";
import {ModaaliModule} from "../modaalit/modaali.module";
import {UtilsModule} from "../utils/utils.module";
import {AccountModule} from "../account/account.module";

/**
 * Created by Seppo on 23/11/2017.
 */

@NgModule({
  declarations: [
    SidebarComponent
  ],
    imports: [
        BrowserModule,
        NgbModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        AttributeModule,
        ModaaliModule,
        UtilsModule,
        AccountModule
    ],
  exports: [
    SidebarComponent
  ],
  providers: [PalveluService],
})
export class SidebarModule {
}
