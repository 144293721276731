<div class="pb-3" *ngFor="let luomulupa of luomuluvat.asArray().controlFields; index as i" id="luomulupa-{{i}}">
  <div class="card">
    <div class="card-header">
      {{ 'luomuPoikkeus9c.lupaTavanomLisaysaineisto' | attribute }}
      <button (click)="poistaLupa(i)" *ngIf="naytaPoistaNappi()" class="btn btn-sm btn-primary float-right"
              type="button">Poista
      </button>
    </div>
    <div class="card-body" [formGroup]="luomulupa.asGroup()">

      <div class="form-row">
        <div class="col-md-3 mb-3 pl-0">
          <label class="required"><span attribute="luomuPoikkeus9c.tyyppi">Tyyppi</span></label>
          <select (change)="getMaaraTyyppiLabel(i)" class="form-control" formControlName="tyyppi" id="tavanomainen-drop-tyyppi-{{i}}">
            <option value="" disabled selected attribute="luomuPoikkeus9c.valitse">Valitse</option>
            <option value="SIEMEN" attribute="luomuPoikkeus9c.siemen">Siemen</option>
            <option value="TAIMI" attribute="luomuPoikkeus9c.taimi">Taimi</option>
          </select>
          <validation-error [field]="luomulupa.asGroup().get('tyyppi').asControl()"></validation-error>
        </div>
        <div class="col-md-3 mb-3 pl-0">
          <label class="required"><span attribute="luomuPoikkeus9c.lupaLaji">Laji</span></label>
          <kasvilaji-valinta (ngModelChange)="lajiValittu($event, lajikeRef)" (uusiKasvilajike)="uusiKasvilajike($event, i)"
                             formControlName="laji"
                             id="tavanomainen-input-kasvilaji-{{i}}"></kasvilaji-valinta>
          <validation-error [field]="luomulupa.asGroup().get('laji').asControl()"></validation-error>
        </div>
        <div class="col-md-3 mb-3 pl-0">
          <label class="required"><span attribute="luomuPoikkeus9c.lupaLajike">Lajike</span></label>
          <kasvilajike-valinta #lajikeRef (focusin)="asetaLaji(lajikeRef, i)" formControlName="lajike"
                               id="tavanomainen-input-kasvilajike-{{i}}"></kasvilajike-valinta>
          <validation-error [field]="luomulupa.asGroup().get('lajike').asControl()"></validation-error>
        </div>
        <div class="col-md-3 mb-3 pl-0">
          <label class="required"><span
              attribute="luomuPoikkeus9c.lupaKayttomaara">Käyttömäärä</span>, <span>{{this.attributeKaantaja(tyyppiLabel)}}</span>
          </label>
          <input (change)="validateKayttomaara(i)" class="form-control" formControlName="kayttomaara"
                 id="tavanomainen-input-kayttomaara-{{i}}" type="number">
          <validation-error [field]="luomulupa.asGroup().get('kayttomaara').asControl()"></validation-error>
        </div>
      </div>
      <fieldset>
        <legend attribute="luomuPoikkeus9c.seoksenKomponentti" class="font-base text-body font-weight-normal required">
          Seoksen
          komponentti
        </legend>
        <touko-radio-input *ngFor="let seoksenKomponentti of luomulupa.get('seoksenKomponentti').field.valueOptions"
                           [htmlId]="'tavanomainen-radio-' + seoksenKomponentti.value + '-' + i"
                           [labelAttribute]="seoksenKomponentti.label"
                           [value]="seoksenKomponentti.value"
                           formControlName="seoksenKomponentti"
                           [radioGroup]="'seoksenKomponentti' + i"></touko-radio-input>
        <validation-error [field]="luomulupa.asGroup().get('seoksenKomponentti').asControl()"></validation-error>
      </fieldset>
      <fieldset>
        <legend class="font-base font-weight-normal required">
          <span attribute="luomuPoikkeus9c.lupaPerusteOhje" class="text-body">Tarkista luvan peruste</span>
          <a (click)="showHelpModal()" attribute="luomuPoikkeus9c.lupaPerusteOhjeLinkki" class="btn-ohje pl-1"
             id="btn-ohje">käyttöohjeista</a>
        </legend>
        <div *ngFor="let peruste of luomulupa.get('peruste').field.valueOptions" class="form-row">
          <div class="col-md-3">
            <touko-radio-input
                [htmlId]="'tavanomainen-radio-peruste-' + peruste.value + '-' + i"
                [labelAttribute]="peruste.label"
                [value]="peruste.value"
                formControlName="peruste"
                [radioGroup]="'peruste' + i"></touko-radio-input>
          </div>
          <div class="col-md-9">
            <span [attribute]="peruste.options?.infoTeksti"></span>
          </div>
        </div>
        <validation-error [field]="luomulupa.asGroup().get('peruste').asControl()"></validation-error>
      </fieldset>

      <div class="form-row">
        <div class="col-md-3"></div>
        <div class="col-md-9 pl-0 lupa-perustelut">
          <span attribute="luomuPoikkeus9c.lupaPerustelut" class="required">Perustelut</span>
          <touko-textarea formControlName="perustelut" [maxLength]="255" htmlId="tavanomainen-text-perustelut-{{i}}"></touko-textarea>
          <validation-error [field]="luomulupa.asGroup().get('perustelut').asControl()"></validation-error>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-3"></div>
        <div class="col-md-9 pl-0 lupa-perustelut">
          <label attribute="luomuPoikkeus9c.lupaLiite" class="lupa-liite-label" id="lupa-liite-label"></label>
          <liite-list (liiteOut)="onSetLiite($event)" [liitteetArray]="luomulupa.get('liitteet').asArray()"
                      [lomakeId]="this.lomakeId"
                      [maxLiiteLkm]="1" formArrayName="liitteet"
                      htmlId="liitteet"
                      labelledBy="lupa-liite-label"
                      selite="OVS"></liite-list>
        </div>
      </div>
    </div>
  </div>
</div>
