import {TulkkiObject} from "../common/lomake-field-utils";
import {Field, FieldControl, FieldGroup, SummaryItem, ValueOption} from "ngx-fielding";
import {
  checkBoxSummary, subTitleH3Summary,
  subTitleH3WithBadgeSummary,
  subTitleH4Summary, subTitleH5Summary,
  titleSummary,
  valueSummary
} from "./summary-helpers";
import {
  QuestionAndTranslatedAnswerSummaryComponent
} from "../../summary/question-and-translated-answer-summary.component";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

class RadioValue implements ValueOption {
  label: string;
  value: string;
  htmlId: string;

  constructor(value: string, label?, htmlId?) {
    this.label = label || attr(value);
    this.value = value;
    this.htmlId = htmlId;
  }
}

export const createToimintaJaTuoteluokatEUToiminta = (toimintotyyppi): FieldGroup => {
  return new FieldGroup(Field.build(attr('euToiminta'), titleSummary), {
    euLannoiteValmisteenTuonti: createTuontiEU(toimintotyyppi),
    euLannoitevalmisteenVieja: createViejaEU(toimintotyyppi),
    euLannoitevalmisteenJakelija: createJakelijaEU(toimintotyyppi),
    euLannoitevalmisteenValmistaja: createValmistajaEU(toimintotyyppi)
  })
}

const createTuontiEU = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('euLannoiteValmisteenTuonti'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatEUToiminta-euLannoiteValmisteenTuonti',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  pfc1Lannoite: new FieldGroup(Field.of({
    label: attr('pfc1Lannoite'),
    htmlId: 'euLannoiteValmisteenTuonti-pfc1Lannoite',
    summaryFn: subTitleH4Summary
  }), {
    kiinteaOrgaaninenLannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'kiinteaOrgaaninenLannoite'),
    nestemainenOrgaaninenLannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'nestemainenOrgaaninenLannoite'),
    kiinteaOrgaaninenKivennaislannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'kiinteaOrgaaninenKivennaislannoite'),
    nestemainenOrgaaninenKivennaislannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'nestemainenOrgaaninenKivennaislannoite'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti'),
    yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite'),
    moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite'),
    yksiravinteinenEpaorgaaninenHivenravinnelannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'yksiravinteinenEpaorgaaninenHivenravinnelannoite'),
    moniravinteinenEpaorgaaninenHivenravinnelannoite: createEuTuote('euLannoiteValmisteenTuonti', 'pfc1Lannoite', 'moniravinteinenEpaorgaaninenHivenravinnelannoite'),
  }),
  pfc2Kalkitusaine: new FieldGroup(Field.of({
      label: attr('pfc2Kalkitusaine'),
      htmlId: 'euLannoiteValmisteenTuonti-pfc2Kalkitusaine',
      summaryFn: subTitleH4Summary
    }), {
      kalkitusaine: createEuTuote('euLannoiteValmisteenTuonti', 'pfc2Kalkitusaine', 'kalkitusaine')
    }
  ),
  pfc3Maanparannusaine: new FieldGroup(Field.of({
    label: attr('pfc3Maanparannusaine'),
    htmlId: 'euLannoiteValmisteenTuonti-pfc3Maanparannusaine',
    summaryFn: subTitleH4Summary
  }), {
    orgaaninenMaanparannusaine: createEuTuote('euLannoiteValmisteenTuonti', 'pfc3Maanparannusaine', 'orgaaninenMaanparannusaine'),
    epaorgaaninenMaanparannusaine: createEuTuote('euLannoiteValmisteenTuonti', 'pfc3Maanparannusaine', 'epaorgaaninenMaanparannusaine')
  }),
  pfc4Kasvualustat: new FieldGroup(Field.of({
    label: attr('pfc4Kasvualustat'),
    htmlId: 'euLannoiteValmisteenTuonti-pfc4Kasvualustat',
    summaryFn: subTitleH4Summary
  }), {
    kasvualusta: createEuTuote('euLannoiteValmisteenTuonti', 'pfc4Kasvualustat', 'kasvualusta', 'euLannoitteidenMaara')
  }),
  pfc5Inhibiittori: new FieldGroup(Field.of({
    label: attr('pfc5Inhibiittorit'),
    htmlId: 'euLannoiteValmisteenTuonti-pfc5Inhibiittori',
    summaryFn: subTitleH4Summary
  }), {
    nitrifikaatioinhibiittori: createEuTuote('euLannoiteValmisteenTuonti', 'pfc5Inhibiittori', 'nitrifikaatioinhibiittori'),
    denitrifikaatioinhibiittori: createEuTuote('euLannoiteValmisteenTuonti', 'pfc5Inhibiittori', 'denitrifikaatioinhibiittori'),
    ureaasiInhibiittori: createEuTuote('euLannoiteValmisteenTuonti', 'pfc5Inhibiittori', 'ureaasiInhibiittori')
  }),
  pfc6Kasvibiostimulantti: new FieldGroup(Field.of({
    label: attr('pfc6Kasvibiostimulantit'),
    htmlId: 'euLannoiteValmisteenTuonti-pfc6Kasvibiostimulantti',
    summaryFn: subTitleH4Summary
  }), {
    mikrobipohjainenKasvibiostimulantti: createEuTuote('euLannoiteValmisteenTuonti', 'pfc6Kasvibiostimulantti', 'mikrobipohjainenKasvibiostimulantti'),
    eiMikrobipohjainenKasvibiostimulantti: createEuTuote('euLannoiteValmisteenTuonti', 'pfc6Kasvibiostimulantti', 'eiMikrobipohjainenKasvibiostimulantti')
  }),
  pfc7MekaanisetSeokset: new FieldGroup(Field.of({
    label: attr('pfc7MekaanisetSeokset'),
    htmlId: 'euLannoiteValmisteenTuonti-pfc7MekaanisetSeokset',
    summaryFn: subTitleH4Summary
  }), {
    lannoitevalmisteidenMekaaninenSeos: createEuTuote('euLannoiteValmisteenTuonti', 'pfc7MekaanisetSeokset', 'lannoitevalmisteidenMekaaninenSeos')
  })
});

const createEuTuote = (groupId, pfcId, lannoitetyyppiId, maaraLabel = 'euLannoitteidenMaara', maaraId = 'maara') => {
  return new FieldGroup(Field.of({label: attr(lannoitetyyppiId), htmlId: [groupId, pfcId, lannoitetyyppiId].join('-'), summaryFn: subTitleH5Summary}),
    createMaara(maaraLabel, [groupId, pfcId, lannoitetyyppiId, maaraId].join('-'))
  )
}

const createViejaEU = (toimintotyyppi) => new FieldGroup(Field.of(
  {
    label: attr('euLannoitevalmisteenVieja'),
    htmlId: 'euLannoitevalmisteenVieja',
    summaryFn: subTitleH3SummaryByToimintotyyppi(toimintotyyppi)
  }), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatEUToiminta-euLannoitevalmisteenVieja',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  pfc1Lannoite: new FieldGroup(Field.of({
    label: attr('pfc1Lannoite'),
    htmlId: 'euLannoitevalmisteenVieja-pfc1Lannoite',
    summaryFn: subTitleH4Summary
  }), {
    kiinteaOrgaaninenLannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'kiinteaOrgaaninenLannoite', 'vietavienLannoitteidenMaara'),
    nestemainenOrgaaninenLannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'nestemainenOrgaaninenLannoite', 'vietavienLannoitteidenMaara'),
    kiinteaOrgaaninenKivennaislannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'kiinteaOrgaaninenKivennaislannoite', 'vietavienLannoitteidenMaara'),
    nestemainenOrgaaninenKivennaislannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'nestemainenOrgaaninenKivennaislannoite', 'vietavienLannoitteidenMaara'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite', 'vietavienLannoitteidenMaara'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite', 'vietavienLannoitteidenMaara'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti', 'vietavienLannoitteidenMaara'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti', 'vietavienLannoitteidenMaara'),
    yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite', 'vietavienLannoitteidenMaara'),
    moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite', 'vietavienLannoitteidenMaara'),
    yksiravinteinenEpaorgaaninenHivenravinnelannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'yksiravinteinenEpaorgaaninenHivenravinnelannoite', 'vietavienLannoitteidenMaara'),
    moniravinteinenEpaorgaaninenHivenravinnelannoite: createEuTuote('euLannoitevalmisteenVieja', 'pfc1Lannoite', 'moniravinteinenEpaorgaaninenHivenravinnelannoite', 'vietavienLannoitteidenMaara'),
  }),
  pfc2Kalkitusaine: new FieldGroup(Field.of({
    label: attr('pfc2Kalkitusaine'),
    htmlId: 'euLannoitevalmisteenVieja-pfc2Kalkitusaine',
    summaryFn: subTitleH4Summary
  }), {
    kalkitusaine: createEuTuote('euLannoitevalmisteenVieja', 'pfc2Kalkitusaine', 'kalkitusaine', 'vietavienLannoitteidenMaara')
  }),
  pfc3Maanparannusaine: new FieldGroup(Field.of({
    label: attr('pfc3Maanparannusaine'),
    htmlId: 'euLannoitevalmisteenVieja-pfc3Maanparannusaine',
    summaryFn: subTitleH4Summary
  }), {
    orgaaninenMaanparannusaine: createEuTuote('euLannoitevalmisteenVieja', 'pfc3Maanparannusaine', 'orgaaninenMaanparannusaine', 'vietavienLannoitteidenMaara'),
    epaorgaaninenMaanparannusaine: createEuTuote('euLannoitevalmisteenVieja', 'pfc3Maanparannusaine', 'epaorgaaninenMaanparannusaine', 'vietavienLannoitteidenMaara'),
    kasvualusta: createEuTuote('euLannoitevalmisteenVieja', 'pfc3Maanparannusaine', 'kasvualusta', 'vietavienLannoitteidenMaara')
  }),
  pfc4Kasvualustat: new FieldGroup(Field.of({
    label: attr('pfc4Kasvualustat'),
    htmlId: 'euLannoitevalmisteenVieja-pfc4Kasvualustat',
    summaryFn: subTitleH4Summary
  }), {
    kasvualustaVienti: createEuTuote('euLannoitevalmisteenVieja', 'pfc4Kasvualustat', 'kasvualusta', 'vietavienLannoitteidenMaara')
  }),
  pfc5Inhibiittori: new FieldGroup(Field.of({
    label: attr('pfc5Inhibiittorit'),
    htmlId: 'euLannoitevalmisteenVieja-pfc5Inhibiittori',
    summaryFn: subTitleH4Summary
  }), {
    nitrifikaatioinhibiittori: createEuTuote('euLannoitevalmisteenVieja', 'pfc5Inhibiittori', 'nitrifikaatioinhibiittori', 'vietavienLannoitteidenMaara'),
    denitrifikaatioinhibiittori: createEuTuote('euLannoitevalmisteenVieja', 'pfc5Inhibiittori', 'denitrifikaatioinhibiittori', 'vietavienLannoitteidenMaara'),
    ureaasiInhibiittori: createEuTuote('euLannoitevalmisteenVieja', 'pfc5Inhibiittori', 'ureaasiInhibiittori', 'vietavienLannoitteidenMaara')
  }),
  pfc6Kasvibiostimulantti: new FieldGroup(Field.of({
    label: attr('pfc6Kasvibiostimulantit'),
    htmlId: 'euLannoitevalmisteenVieja-pfc6Kasvibiostimulantti',
    summaryFn: subTitleH4Summary
  }), {
    mikrobipohjainenKasvibiostimulantti: createEuTuote('euLannoitevalmisteenVieja', 'pfc6Kasvibiostimulantti', 'mikrobipohjainenKasvibiostimulantti', 'vietavienLannoitteidenMaara'),
    eiMikrobipohjainenKasvibiostimulantti: createEuTuote('euLannoitevalmisteenVieja', 'pfc6Kasvibiostimulantti', 'eiMikrobipohjainenKasvibiostimulantti', 'vietavienLannoitteidenMaara')
  }),
  pfc7MekaanisetSeokset: new FieldGroup(Field.of({
    label: attr('pfc7MekaanisetSeokset'),
    htmlId: 'euLannoitevalmisteenVieja-pfc7MekaanisetSeokset',
    summaryFn: subTitleH4Summary
  }), {
    lannoitevalmisteidenMekaaninenSeos: createEuTuote('euLannoitevalmisteenVieja', 'pfc7MekaanisetSeokset', 'lannoitevalmisteidenMekaaninenSeos', 'vietavienLannoitteidenMaara')
  })
});

const createJakelijaEU = (toimintotyyppi) => new FieldGroup(Field.of(
  {
    label: attr('euLannoitevalmisteenJakelija'),
    htmlId: 'euLannoitevalmisteenJakelija',
    summaryFn: subTitleH3SummaryByToimintotyyppi(toimintotyyppi)
  }), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatEUToiminta-euLannoitevalmisteenJakelija',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  pfc1Lannoite: new FieldGroup(Field.of({
    label: attr('pfc1Lannoite'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc1Lannoite',
    summaryFn: subTitleH4Summary
  }), {
    kiinteaOrgaaninenLannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'kiinteaOrgaaninenLannoite'),
    nestemainenOrgaaninenLannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'nestemainenOrgaaninenLannoite'),
    kiinteaOrgaaninenKivennaislannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'kiinteaOrgaaninenKivennaislannoite'),
    nestemainenOrgaaninenKivennaislannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'nestemainenOrgaaninenKivennaislannoite'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti'),
    yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite'),
    moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite'),
    yksiravinteinenEpaorgaaninenHivenravinnelannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'yksiravinteinenEpaorgaaninenHivenravinnelannoite'),
    moniravinteinenEpaorgaaninenHivenravinnelannoite: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc1Lannoite', 'moniravinteinenEpaorgaaninenHivenravinnelannoite'),
  }),
  pfc2Kalkitusaine: new FieldGroup(Field.of({
    label: attr('pfc2Kalkitusaine'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc2Kalkitusaine',
    summaryFn: subTitleH4Summary
  }), {
    kalkitusaine: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc2Kalkitusaine', 'kalkitusaine')
  }),
  pfc3Maanparannusaine: new FieldGroup(Field.of({
    label: attr('pfc3Maanparannusaine'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc3Maanparannusaine',
    summaryFn: subTitleH4Summary
  }), {
    orgaaninenMaanparannusaine: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc3Maanparannusaine', 'orgaaninenMaanparannusaine'),
    epaorgaaninenMaanparannusaine: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc3Maanparannusaine', 'epaorgaaninenMaanparannusaine'),
    kasvualusta: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc3Maanparannusaine', 'kasvualusta')
  }),
  pfc4Kasvualustat: new FieldGroup(Field.of({
    label: attr('pfc4Kasvualustat'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc4Kasvualustat',
    summaryFn: subTitleH4Summary
  }), {
    kasvualusta: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc4Kasvualustat', 'kasvualusta')
  }),
  pfc5Inhibiittori: new FieldGroup(Field.of({
    label: attr('pfc5Inhibiittorit'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc5Inhibiittori',
    summaryFn: subTitleH4Summary
  }), {
    nitrifikaatioinhibiittori: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc5Inhibiittori', 'nitrifikaatioinhibiittori'),
    denitrifikaatioinhibiittori: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc5Inhibiittori', 'denitrifikaatioinhibiittori'),
    ureaasiInhibiittori: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc5Inhibiittori', 'ureaasiInhibiittori')
  }),
  pfc6Kasvibiostimulantti: new FieldGroup(Field.of({
    label: attr('pfc6Kasvibiostimulantit'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc6Kasvibiostimulantti',
    summaryFn: subTitleH4Summary
  }), {
    mikrobipohjainenKasvibiostimulantti: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc6Kasvibiostimulantti', 'mikrobipohjainenKasvibiostimulantti'),
    eiMikrobipohjainenKasvibiostimulantti: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc6Kasvibiostimulantti', 'eiMikrobipohjainenKasvibiostimulantti')
  }),
  pfc7MekaanisetSeokset: new FieldGroup(Field.of({
    label: attr('pfc7MekaanisetSeokset'),
    htmlId: 'euLannoitevalmisteenJakelija-pfc7MekaanisetSeokset',
    summaryFn: subTitleH4Summary
  }), {
    lannoitevalmisteidenMekaaninenSeos: createEuJakelijaTuote('euLannoitevalmisteenJakelija', 'pfc7MekaanisetSeokset', 'lannoitevalmisteidenMekaaninenSeos')
  })
});

const createEuJakelijaTuote = (groupId, pfcId, lannoitetyyppiId) => {
  return new FieldGroup(Field.of(
    {
      label: attr(lannoitetyyppiId),
      htmlId: [groupId, pfcId, lannoitetyyppiId].join('-'),
      summaryFn: subTitleH5Summary
    }),
    createJakelijaMaarat([groupId, pfcId, lannoitetyyppiId].join('-'))
  );
}

const createJakelijaMaarat = (htmlIdPrefix) => {
  return {
    maarat: new FieldGroup(Field.build(attr('arvioidutMaaratYhteenveto'), subTitleH5Summary), {
      maaraSuomi: new FieldControl(Field.of({
        label: attr('markkinoitavienLannoitteidenMaara'),
        htmlId: htmlIdPrefix + '-maaraSuomi',
        summaryFn: valueSummary
      })),
      maaraSisamarkkinat: new FieldControl(Field.of({
        label: attr('sisamarkkinoiltaTuotavienLannoitteidenMaara'),
        htmlId:  htmlIdPrefix + '-maaraSisamarkkinat',
        summaryFn: valueSummary
      }))
    })
  }
}

const createValmistajaEU = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('euLannoitevalmisteenValmistaja'), subTitleH3SummaryByToimintotyyppi(toimintotyyppi)), {
  lopetaToiminta: new FieldControl(Field.of({
    label: attr('haluanLopettaaToiminnan'),
    htmlId: 'toimintaJaTuoteluokatEUToiminta-euLannoitevalmisteenValmistaja',
    summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
  })),
  pfc1Lannoite: new FieldGroup(Field.of({
    label: attr('pfc1Lannoite'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc1Lannoite',
    summaryFn: subTitleH4Summary
  }), {
    kiinteaOrgaaninenLannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'kiinteaOrgaaninenLannoite'),
    nestemainenOrgaaninenLannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'nestemainenOrgaaninenLannoite'),
    kiinteaOrgaaninenKivennaislannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'kiinteaOrgaaninenKivennaislannoite'),
    nestemainenOrgaaninenKivennaislannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'nestemainenOrgaaninenKivennaislannoite'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoite'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoite'),
    yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'yksiravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti'),
    moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'moniravinteinenKiinteaEpaorgaaninenPaaravinnelannoiteAmmoniumnitraatti'),
    yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'yksiravinteinenNestemainenEpaorgaaninenPaaravinnelannoite'),
    moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'moniravinteinenNestemainenEpaorgaaninenPaaravinnelannoite'),
    yksiravinteinenEpaorgaaninenHivenravinnelannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'yksiravinteinenEpaorgaaninenHivenravinnelannoite'),
    moniravinteinenEpaorgaaninenHivenravinnelannoite: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc1Lannoite', 'moniravinteinenEpaorgaaninenHivenravinnelannoite'),
  }),
  pfc2Kalkitusaine: new FieldGroup(Field.of({
    label: attr('pfc2Kalkitusaine'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc2Kalkitusaine',
    summaryFn: subTitleH4Summary
  }), {
    kalkitusaine: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc2Kalkitusaine', 'kalkitusaine')
  }),
  pfc3Maanparannusaine: new FieldGroup(Field.of({
    label: attr('pfc3Maanparannusaine'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc3Maanparannusaine',
    summaryFn: subTitleH4Summary
  }), {
    orgaaninenMaanparannusaine: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc3Maanparannusaine', 'orgaaninenMaanparannusaine'),
    epaorgaaninenMaanparannusaine: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc3Maanparannusaine', 'epaorgaaninenMaanparannusaine'),
  }),
  pfc4Kasvualustat: new FieldGroup(Field.of({
    label: attr('pfc4Kasvualustat'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc4Kasvualusta',
    summaryFn: subTitleH4Summary
  }), {
    kasvualusta: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc4Kasvualusta', 'kasvualusta')
  }),
  pfc5Inhibiittori: new FieldGroup(Field.of({
    label: attr('pfc5Inhibiittorit'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc5Inhibiittori',
    summaryFn: subTitleH4Summary
  }), {
    nitrifikaatioinhibiittori: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc5Inhibiittori', 'nitrifikaatioinhibiittori'),
    denitrifikaatioinhibiittori: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc5Inhibiittori', 'denitrifikaatioinhibiittori'),
    ureaasiInhibiittori: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc5Inhibiittori', 'ureaasiInhibiittori')
  }),
  pfc6Kasvibiostimulantti: new FieldGroup(Field.of({
    label: attr('pfc6Kasvibiostimulantit'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc6Kasvibiostimulantti',
    summaryFn: subTitleH4Summary
  }), {
    mikrobipohjainenKasvibiostimulantti: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc6Kasvibiostimulantti', 'mikrobipohjainenKasvibiostimulantti'),
    eiMikrobipohjainenKasvibiostimulantti: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc6Kasvibiostimulantti', 'eiMikrobipohjainenKasvibiostimulantti')
  }),
  pfc7MekaanisetSeokset: new FieldGroup(Field.of({
    label: attr('pfc7MekaanisetSeokset'),
    htmlId: 'euLannoitevalmisteenValmistaja-pfc7MekaanisetSeokset',
    summaryFn: subTitleH4Summary
  }), {
    lannoitevalmisteidenMekaaninenSeos: createEuValmistusTuote('euLannoitevalmisteenValmistaja', 'pfc7MekaanisetSeokset', 'lannoitevalmisteidenMekaaninenSeos')
  })
});

const createEuValmistusTuote = (groupId, pfcId, lannoitetyyppiId) => {
  return new FieldGroup(Field.of(
    {
      label: attr(lannoitetyyppiId),
      htmlId: [groupId, pfcId, lannoitetyyppiId].join('-'),
      summaryFn: subTitleH5Summary}
  ), {
    ...createMaaraWithSummaryTitle('lannotteidenVuosittainenMaara', [groupId, pfcId, lannoitetyyppiId, 'maara'].join('-')),
    ...createValmistusRadio(lannoitetyyppiId + 'lannoitteenValmistusAinoastaanPakkaamista'),
    ...createCmc(lannoitetyyppiId)
  })
}

const createValmistusRadio = (htmlId) => {
  return {
    valmistus: new FieldGroup(Field.build(attr('valmistus'), subTitleH5Summary), {
      lannoitteenValmistusAinoastaanPakkaamista: new FieldControl(
        Field.of(
          {
            htmlId: htmlId,
            valueOptions: [
              new RadioValue("true", attr("kylla"), htmlId + '_yes'),
              new RadioValue("false", attr("ei"), htmlId + '_no'),
            ],
            summaryFn: valmistusVainPakkaamistaRadioSummary
          }))
    })
  }
}

const valmistusVainPakkaamistaRadioSummary = control => {
  const value = control.field.valueOptions.find(o => o.value === control.value);
  return new SummaryItem<any>(attr('lannoitteenValmistusAinoastaanPakkaamista'), value?.label, {
    show: control.value,
    classList: ['pl-7']
  }, QuestionAndTranslatedAnswerSummaryComponent)
}

const subTitleH3SummaryByToimintotyyppi = (toimintotyyppi) => {
  return toimintotyyppi === 'muutos' ? subTitleH3WithBadgeSummary : subTitleH3Summary;
}

const createMaara = (label, htmlId) => {
  return {
    maarat: new FieldGroup(Field.build(), {
      maara: new FieldControl(
        Field.of({
          label: attr(label),
          htmlId: htmlId,
          summaryFn: valueSummary
        }))
    })
  }
}

const createMaaraWithSummaryTitle = (label, htmlId) => {
  return {
    maarat: new FieldGroup(Field.build(attr('arvioidutMaaratYhteenveto'), subTitleH5Summary), {
      maara: new FieldControl(
        Field.of({
          label: attr(label),
          htmlId: htmlId,
          summaryFn: valueSummary
        }))
    })
  }
}

const createCmc = (cmcId) => {
  return {
    ainesosaluokat: new FieldGroup(Field.build(attr('ainesosaluokatYhteenveto'), subTitleH5Summary), {
      cmc1: new FieldControl(Field.of({
        label: attr('cmc1EnsiomateriaalistaKoostuvatAineetJaSeokset'),
        htmlId: cmcId + "cmc1",
        summaryFn: checkBoxSummary
      })),
      cmc2: new FieldControl(Field.of({
        label: attr('cmc2KasvitKasvienOsatTaiKasviuutteet'),
        htmlId: cmcId + "cmc2",
        summaryFn: checkBoxSummary
      })),
      cmc3: new FieldControl(Field.of({
        label: attr('cmc3Komposti'),
        htmlId: cmcId + "cmc3",
        summaryFn: checkBoxSummary
      })),
      cmc4: new FieldControl(Field.of({
        label: attr('cmc4Tuorekasvimadate'),
        htmlId: cmcId + "cmc4",
        summaryFn: checkBoxSummary
      })),
      cmc5: new FieldControl(Field.of({
        label: attr('cmc5MuuMadateKuinTuorekasvimadate'),
        htmlId: cmcId + "cmc5",
        summaryFn: checkBoxSummary
      })),
      cmc6: new FieldControl(Field.of({
        label: attr('cmc6ElintarviketeollisuudenSivutuotteet'),
        htmlId: cmcId + "cmc6",
        summaryFn: checkBoxSummary
      })),
      cmc7: new FieldControl(Field.of({
        label: attr('cmc7MikroOrganismit'),
        htmlId: cmcId + "cmc7",
        summaryFn: checkBoxSummary
      })),
      cmc8: new FieldControl(Field.of({
        label: attr('cmc8Ravinnepolymeerit'),
        htmlId: cmcId + "cmc8",
        summaryFn: checkBoxSummary
      })),
      cmc9: new FieldControl(Field.of({
        label: attr('cmc9MuutPolymeeritJaRavinnepolymeerit'),
        htmlId: cmcId + "cmc9",
        summaryFn: checkBoxSummary
      })),
      cmc10: new FieldControl(Field.of({
        label: attr('cmc10Asetuksessa1069/2009TarkoitetutJohdetutSivutuotteet'),
        htmlId: cmcId + "cmc10",
        summaryFn: checkBoxSummary
      })),
      cmc11: new FieldControl(Field.of({
        label: attr('cmc11Direktiivissa2008/98/EYTarkoitetutSivutuotteet'),
        htmlId: cmcId + "cmc11",
        summaryFn: checkBoxSummary
      })),
      cmc12: new FieldControl(Field.of({
        label: attr('cmc12SaostuneetFosfaattisuolatJaJohdannaiset'),
        htmlId: cmcId + "cmc12",
        summaryFn: checkBoxSummary
      })),
      cmc13: new FieldControl(Field.of({
        label: attr('cmc13TermisestiHapetuksessaMuodostuvaAinesTaiJohdannainen'),
        htmlId: cmcId + "cmc13",
        summaryFn: checkBoxSummary
      })),
      cmc14: new FieldControl(Field.of({
        label: attr('cmc14PyrolyysissaJaKaasutuksessaMuodostuvaAines'),
        htmlId: cmcId + "cmc14",
        summaryFn: checkBoxSummary
      })),
      cmc15: new FieldControl(Field.of({
        label: attr('cmc15PuhtaatTeollisessaProsessissaMuodostuvatMateriaalit/ainesosat'),
        htmlId: cmcId + "cmc15",
        summaryFn: checkBoxSummary
      }))
    })
  }
}
