import {Component} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {AbstractField, FieldArray, FieldGroup} from "ngx-fielding";

@Component({
  selector: 'kasvituotteet',
  template: `
    <div [formGroup]="formGroup" class="mb-6">
      <div class="mb-4">
        <h3 attribute="luomu.tilanTuottamatKasvituotteet"></h3>
        <div class="row mb-2">
          <div class="col-4"></div>
          <label attribute="luomu.luonnonmukainenLabel" id="luonnonMukainenLabel"
                 class="col-3 font-weight-semibold"></label>
          <label attribute="luomu.tavanomainenLabel" id="tavanomainenLabel" class="col-3 font-weight-semibold"></label>
        </div>
        <div *ngFor="let kasvituote of kasvituotteet" [formGroup]="kasvituote.asGroup()" class="row">
          <p [attribute]="kasvituote.field.label" class="col-4"></p>
          <touko-checkbox
              formControlName="luonnonmukainen"
              [htmlId]="'luonnonmukainen-' + kasvituote.field.htmlId"
              aria-labelledby="luonnonMukainenLabel"
              class="col-3"></touko-checkbox>
          <touko-checkbox
              formControlName="tavanomainen"
              aria-labelledby="tavanomainenLabel"
              [htmlId]="'tavanomainen-' + kasvituote.field.htmlId"
              class="col-3"></touko-checkbox>
        </div>
      </div>

      <div formArrayName="muutKasvituotteet">
        <label attribute="luomu.muuKasvituote" id="muuKasvituoteLabel"></label>
        <div *ngFor="let muuKasvituote of muutKasvituotteetArray.controlFields; let i = index"
             [formGroup]="muuKasvituote.asGroup()" class="row align-items-center">
          <div class="col-4">
            <touko-text-input [htmlId]="'muuKasvituotenimi-' + i" formControlName="nimi"
                              labelledById="muuKasvituoteLabel"></touko-text-input>
          </div>
          <div class="col-3">
            <touko-checkbox [htmlId]="'muuLuonnonmukainen-' + i" formControlName="luonnonmukainen"
                            aria-labelledby="luonnonMukainenLabel"></touko-checkbox>
          </div>
          <div class="col-2">
            <touko-checkbox [htmlId]="'muuTavanomainen-' + i" formControlName="tavanomainen"
                            aria-labelledby="tavanomainenLabel"></touko-checkbox>
          </div>
          <div class="col-3">
            <button [id]="'muuPoista-' + i" type="button" toukobutton class="btn-outline-primary mb-4"
                    (click)="removeMuuKasvituote(i)">
              <i class="fas fa-trash" aria-hidden="true"></i>
              <span attribute="teksti.poista"></span>
            </button>
          </div>
        </div>
        <button type="button" toukobutton class="btn-secondary" (click)="addMuuKasvituote()">
          <i class="fas fa-plus-square" aria-hidden="true"></i>
          <span attribute="teksti.uusi"></span>
        </button>
      </div>
    </div>
  `
})

export class KasvituotteetComponent {
  constructor(private controlContainer: ControlContainer) {
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get kasvituotteet(): AbstractField[] {
    return Object.values(this.formGroup.controlFields)
        .filter((tuote) => tuote instanceof FieldGroup)
  }

  get muutKasvituotteetArray(): FieldArray {
    return this.formGroup.get('muutKasvituotteet') as FieldArray;
  }

  addMuuKasvituote() {
    this.muutKasvituotteetArray.push(this.muutKasvituotteetArray.buildField());
  }

  removeMuuKasvituote(index: number) {
    this.muutKasvituotteetArray.removeAt(index);

    if (!this.muutKasvituotteetArray.length) {
      this.muutKasvituotteetArray.push(this.muutKasvituotteetArray.buildField());
    }
  }
}
