import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {Raportti, RaporttiLiitetiedosto, SarakeType, SortOrder} from "./raportti.utils";
import {MessageService} from "../message/message.service";
import {Teksti} from "../utils/teksti";
import {RaporttiViewService} from "./raportti-view.service";
import {Subscription} from "rxjs/index";
import {AccountService} from "../account/account.service";
import {TulosraporttiService} from "./raportti.service";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 21/05/2018.
 */

@Component({
  selector: "datatable",
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
@AutoUnsubscribe
export class DataTableComponent implements OnInit {

  @ViewChild("downloadlink", { static: true }) downloadlink: ElementRef;
  sivu;
  raporttiLkmSivulla;
  @Input() columnOrder;
  raportit: Raportti[] = [];      // clientissa näytettävät raportit
  @Input() liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  @Input() liiteZipDownloadFn: (tiedostonimet: string[]) => Promise<string>;

  sortOrder = SortOrder.ASC;
  sortKey = 'kasiteltyAika';
  raporttiSarakeEnum = SarakeType;
  raportitToDownload: Set<RaporttiLiitetiedosto>;

  subs: Subscription[] = [];
  private readonly tiedostotunnusRaporttiMap: Map<string, Raportti> = new Map<string, Raportti>();

  constructor(public readonly accountService: AccountService,
              private readonly raporttiViewService: RaporttiViewService,
              private readonly messageService: MessageService,
              private readonly raporttiService: TulosraporttiService) {
    this.getLiite = this.getLiite.bind(this);
  }

  ngOnInit() {
    this.subs.push(this.raporttiViewService.raportit$.subscribe(raportitResponse => {
      this.raportit = raportitResponse;

      raportitResponse.forEach(raportti => {
        raportti.liitetiedostoList.forEach(liite => {
          this.tiedostotunnusRaporttiMap.set(liite.tiedostoTunnus, raportti);
        });
      });
    }));
    this.subs.push(this.raporttiViewService.raporttiLkmSivulla$.subscribe(val => this.raporttiLkmSivulla = val));
    this.subs.push(this.raporttiViewService.sivu$.subscribe(val => this.sivu = val));
    this.subs.push(this.raporttiViewService.sortArrow$.subscribe(val => this.updateSortArrow(val.key, val.order)));
    this.raportitToDownload = new Set<RaporttiLiitetiedosto>();
  }

  getJarjestys(key: string) {
    return this.sortKey === key ? this.sortOrder : 0;
  }

  jarjesta(key = "kasiteltyAika") {
    this.updateSortArrow(key);
    this.raporttiViewService.jarjesta(key, this.sortOrder);
  }

  private updateSortArrow(key: string, order?: number) {
    if (key === this.sortKey) {
      this.sortOrder = this.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.sortOrder = SortOrder.DESC;
    }

    if (order) {
      this.sortOrder = order;
    }

    this.sortKey = key;
  }

  /**
   * Avaa liitteen uuteen ikkunaan. HUOM! IE:llä tiedosto ladataan.
   *
   * @param liite - Raportin liitetiedosto
   * @returns
   */
  openLiite(liite: RaporttiLiitetiedosto) {
    return this.liiteDownloadFn(liite.tiedostoTunnus)
      .then(blobStr => {
        if (this.isIE()) {
          const filename = this.getFileName(liite.tiedostoNimi);
          const nav = (window.navigator as any);
          nav.msSaveOrOpenBlob(blobStr, filename);
        } else {
          const blob = new Blob([blobStr], {type: 'application/pdf'});
          const file = window.URL.createObjectURL(blob);
          window.open(file, "_blank");
        }
        this.setRaporttiAsAvattu(liite);
      });
  }

  /**
   * Tallentaa liitteen.
   *
   * @param liite - Raportin liitetiedosto
   * @returns
   */
  getLiite(liite: RaporttiLiitetiedosto) {
    return this.liiteDownloadFn(liite.tiedostoTunnus)
      .then(blobStr => {
        const filename = this.getFileName(liite.tiedostoNimi);
        if (this.isIE()) {
          window.navigator.msSaveBlob(blobStr, filename);
        } else {
          const blob = new Blob([blobStr]);
          const file = window.URL.createObjectURL(blob);
          const linkElement = this.downloadlink.nativeElement;
          linkElement.href = file;
          linkElement.download = filename;
          linkElement.click();
          window.URL.revokeObjectURL(file);
        }
      }).then(() => {
        this.setRaporttiAsAvattu(liite);
        this.messageService.addMessage(new Teksti("Lataus onnistui", "liitteenLatausOnnistui", "raportit"), 4000, "success");
      });
  }

  downloadLiiteZip() {
    const tiedostoTunnukset: string[] = [];
    this.raportitToDownload.forEach(value => tiedostoTunnukset.push(value.tiedostoTunnus));
    return this.liiteZipDownloadFn(tiedostoTunnukset).then(blobStr => {
      tiedostoTunnukset.forEach(tiedosto => this.setRaporttiAsAvattu(tiedosto));
      const blob = new Blob([blobStr]);
      const file = window.URL.createObjectURL(blob);
      const linkElement = this.downloadlink.nativeElement;
      linkElement.href = file;
      linkElement.download = "touko.zip";
      linkElement.click();
      window.URL.revokeObjectURL(file);
    });
  }

  private setRaporttiAsAvattu(liite: RaporttiLiitetiedosto | string) {
    const tiedostotunnus: string = typeof liite === "string" ?
      liite :
      liite.tiedostoTunnus;
    const raportti = this.tiedostotunnusRaporttiMap.get(tiedostotunnus);
    if (raportti) {
      raportti.avattu = true;
    }
  }

  selectLiite(liitetiedosto, isAdded: boolean) {
    if (isAdded) {
      this.raportitToDownload.add(liitetiedosto);
    } else {
      this.raportitToDownload.delete(liitetiedosto);
    }
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }

  /**
   * Lisää tiedostonimeen .pdf-päätteen, jos sitä ei löydy.
   *
   * @param tiedostonimi - Tiedostonimi
   * @returns tiedostonimi (+ .pdf)
   */
  getFileName(tiedostonimi: string): string {
    return tiedostonimi.endsWith('.pdf') ? tiedostonimi : `${tiedostonimi}.pdf`;
  }

  setRaporttiLiitePiilotettu(liite: RaporttiLiitetiedosto, piilotettuState: boolean) {
    liite.piilotettu = piilotettuState;
    this.raporttiService.setRaportti(liite);
  }

  get isViranomainen(): boolean {
    return this.accountService.isViranomainen();
  }
}
