import {Component, Input, OnInit} from "@angular/core";
import {FieldArray, FieldGroup, SummaryItem} from "ngx-fielding";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'elaintuotteet-summary',
  template: `
    <h3 attribute="luomu.tilanTuottamatElaintuotteet"></h3>

    <div class="row mb-2">
      <div attribute="luomu.luonnonmukainenLabel" class="col-2 offset-4 font-weight-semibold"></div>
      <div attribute="luomu.tavanomainenLabel" class="col-2 font-weight-semibold"></div>
    </div>
    <div class="row">
      <div class="col-4">
        <div *ngFor="let tuoteLabel of elaintuotelabels" [id]="tuoteLabel" [attribute]="'luomu.' + tuoteLabel + 'Label'"
             class="mb-2"></div>
        <div *ngFor="let tuoteLabel of muuElaintuotelabels; index as i" [id]="'muuElaintuote-' + i"
             class="mb-2">{{ tuoteLabel }}
        </div>
      </div>
      <div class="col-4">
        <div *ngFor="let elaintuote of elaintuotteet; index as i" class="row mb-2">
          <div class="col-6">
            <span *ngIf="elaintuote.get('luonnonmukainen').value" [id]="'luonnonmukainen-' + i" class="fa fa-check"
                  aria-hidden="true"></span>
          </div>
          <div class="col-6">
            <span *ngIf="elaintuote.get('tavanomainen').value" [id]="'tavanomainen' + i" class="fa fa-check"
                  aria-hidden="true"></span>
          </div>
        </div>
      </div>
    </div>
    <hr>
  `
})

export class ElaintuotteetSummaryComponent implements OnInit {
  @Input() item: SummaryItem<any>;
  elaintuotelabels: string[] = [];
  elaintuotteet: AbstractControl[] = [];
  muuElaintuotelabels: string[] = [];

  ngOnInit() {
    Object.entries(this.item.answer.asGroup().controlFields).forEach(tuote => {
      const key = tuote[0];
      const value = tuote[1] as FieldGroup;

      if (key !== "muutElaintuotteet" && (value.get('luonnonmukainen').value || value.get('tavanomainen').value)) {
        this.elaintuotteet.push(value);
        this.elaintuotelabels.push(key);
      }
      if (key === "muutElaintuotteet") {
        const muutElaintuotteet = tuote[1] as FieldArray;
        muutElaintuotteet.controlFields.forEach(muuElaintuote => {
          if (muuElaintuote.get('nimi').value && (muuElaintuote.get('luonnonmukainen').value || muuElaintuote.get('tavanomainen').value)) {
            this.muuElaintuotelabels.push(muuElaintuote.get('nimi').value);
            this.elaintuotteet.push(muuElaintuote);
          }
        })
      }
    })
  }
}
