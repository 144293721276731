import {Component, Input} from "@angular/core";
import {YhteenvetoComponentBase} from "../../yhteenveto-container.component";
import {CompareStatus, YhteenvetoItem} from "../../yhteenveto-utils";

@Component({
  selector: "lannoitevalmisteenjakelija-yhteenveto",
  templateUrl: "lannoitevalmisteenjakelija-yhteenveto.component.html",
  styleUrls: ['../yhteenveto-style.scss', "../../yhteenvet-item/yhteenveto-item.component.scss"]
})
export class LannoitevalmisteenjakelijaYhteenvetoComponent implements YhteenvetoComponentBase {
  @Input() item: YhteenvetoItem = null;
  @Input() prevItem: YhteenvetoItem = null;
  @Input() compareStatus?: CompareStatus = null;
  @Input() showCompareYhteenveto = false;
  itemStyle: string;

  ngOnInit(): void {
    this.itemStyle = this.compareStatus ?
      CompareStatus[this.compareStatus.valueOf()].toLowerCase() :
      null;
  }
}
