<div class="row">
  <div class="col-9">

    <h2 attribute="lomakeYleinen.lokiSivuOtsikkoVirheelliset">Virheelliset lomakkeiden toimitukset</h2>

    <p attribute="lomakeYleinen.lokiSivuKuvaus" [markdown]="true">
      Tälle sivulle on listattu virheellisesti toimitetut lomakkeet. Ennen virheen kuittaamista, tarkista että
      lomake on toimitettu onnistuneesti Ruokavirastoon.

      Voit ottaa lomakkeen uudelleenkäsittelyyn valitsemalla sen taulukosta.
    </p>
  </div>

  <div class="col-12" *ngIf="isLoading || lokitiedot.length > 0" [showSpinner]="isLoading">
    <div class="table-responsive table-hover">
      <table class="table table-striped table-sm">
        <caption class="d-none">Virheellisesti toimitetut lomakkeet</caption>
        <thead>
        <th scope="col" attribute="lomakeYleinen.lokiTaulLomake">Lomake</th>
        <th scope="col" attribute="lomakeYleinen.lokiTaulTiedot">Tiedot</th>
        <th scope="col" attribute="lomakeYleinen.lokiTaulToimitusajankohta">Toimitusajankohta</th>
        <th scope="col" attribute="lomakeYleinen.lokiTaulVirheviesti">Virheviesti</th>
        <th scope="col" attribute="lomakeYleinen.lokiTaulKuittaus">Kuittaus</th>
        </thead>
        <tbody style="cursor: pointer">
        <tr *ngFor="let lokitieto of lokitiedot" [routerLink]="lokitieto.linkki">
          <td>{{lokitieto.lomakeId}}</td>
          <td>{{lokitieto.tiedot}}</td>
          <td>{{lokitieto.aikaleima | date:'d.M.yyyy klo H.mm'}}</td>
          <td>{{lokitieto.viesti}}</td>
          <td>
            <button (click)="kuittaaLomake(lokitieto, $event)" class="btn btn-sm btn-success">
              <span class="fas fa-check mr-1"></span> <span attribute="lomakeYleinen.lokiTaulBtnKuittaus">Kuittaa virhe</span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="lokitiedot.length === 0" class="col-12">
    <div class="alert alert-success">
      <p>Ei virheellisiä lomakkeita.</p>
    </div>
  </div>

</div>
