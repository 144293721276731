<table>
  <caption class="d-none" attribute="kayttajatili.toimipaikka"></caption>
  <tr>
    <th scope="row" attribute="kayttajatili.asiakasnumero">Asiakasnumero</th>
    <td id="account-tiedot-toimipaikka-elmoid">{{valvontakohde?.elmoId}}</td>
  </tr>
  <tr>
    <th scope="row" attribute="kayttajatili.toimipaikanNimi">Toimipaikan nimi</th>
    <td id="account-tiedot-toimipaikka-nimi">
      {{valvontakohde?.toimipaikka?.nimi_fi}}
    </td>
  </tr>
  <tr class="account-align-top">
    <th scope="row" attribute="kayttajatili.kayntiosoite">Käyntiosoite</th>
    <td id="account-tiedot-toimipaikka-osoite">
      <span>{{valvontakohde?.toimipaikka?.osoite}}</span>
      <span>{{valvontakohde?.toimipaikka?.postinumero}} {{valvontakohde?.toimipaikka?.postinumero | postitoimipaikka | async}}</span>
    </td>
  </tr>
  <ng-container *ngIf="toimiala === 'KATE'">
    <tr *ngIf="valvontakohde?.rekisterinumero" class="account-align-top">
      <th scope="row" attribute="kayttajatili.kasvinterveysrekisteritunnus"></th>
      <td id="account-tiedot-rekisterinumero">
        <span>{{valvontakohde?.rekisterinumero}}</span>
      </td>
    </tr>
    <ng-container *ngFor="let vientivalmius of valvontakohdeDetails?.vientivalmiudet; index as i">
        <tr  class="account-align-top">
          <th scope="row" [attribute]="'kayttajatili.' + vientivalmius.rekisteri"></th>
          <td [id]="'account-tiedot-vientivalmius-' + i">
            <span>{{vientivalmius.voimassaolo | date:'dd.MM.yyyy'}}</span>
          </td>
        </tr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="toimiala !== 'KATE'">
    <tr *ngIf="valvontakohdeDetails?.toiminnot !== null">
      <th scope="row" attribute="kayttajatili.toiminnot">Toiminnot</th>
      <td>
      <span *ngFor="let toiminto of valvontakohdeDetails?.toiminnot"
            class="badge badge-primary mr-1"
            id={{toiminto.lyhytNimi}}
            placement="top"
            [ngbTooltip]="toiminto.selite">
            {{toiminto.lyhytNimi}}
      </span>
      </td>
    </tr>
    <tr *ngIf="valvontakohdeDetails?.tuotetyypit !== null">
      <th scope="row" attribute="kayttajatili.tuotetyypit">Tuotetyypit</th>
      <td>
      <span *ngFor="let tuotetyyppi of valvontakohdeDetails?.tuotetyypit"
            class="badge badge-primary mr-1"
            placement="top"
            id={{tuotetyyppi.lyhytNimi}}
            [ngbTooltip]="tuotetyyppi.selite">
            {{tuotetyyppi.lyhytNimi}}
      </span>
      </td>
    </tr>
    <tr class="account-align-top">
      <th scope="row" attribute="kayttajatili.hyvaksynnat">Hyväksynnät</th>
      <td>
        <div class="block" *ngFor="let hyvaksynta of valvontakohdeDetails?.hyvaksynnat">
          <span>{{hyvaksynta.tyyppi}} </span><span *ngIf="hyvaksynta.naytaNumero">({{hyvaksynta.numero}}) </span>
          <span *ngFor="let hyvaksyttyToiminto of hyvaksynta.toiminnot"
                class="badge badge-primary mr-1"
                placement="top"
                [ngbTooltip]="hyvaksyttyToiminto.selite">
              {{hyvaksyttyToiminto.lyhytNimi}}
            </span>
        </div>
        <br>
      </td>
    </tr>
  </ng-container>
</table>
