<div class="row">
  <div class="col-12">
    <h1 attribute="raportit.otsikkoLuomu">Luomuraportit</h1>
  </div>
</div>

<!-- Rajaus -->
<form [formGroup]="raporttiForm" (submit)="submit()">
  <div class="row form-group">
    <div class="col-sm-6 col-lg-3">
      <label for="raportti-tunnus"> <span attribute="raportit.tunnus">Tunnus</span>/<span
        attribute="raportit.tapahtumaliitos"></span></label>
      <input id="raportti-tunnus" formControlName="tunnus" class="form-control" type="text" name="">
    </div>

    <div class="col-sm-6 col-lg-3">
      <label for="raportti-luomu" attribute="raportit.kuvaus">Kuvaus</label>
      <input id="raportti-luomu" formControlName="otsikko" class="form-control" type="text" name="">
    </div>

    <div class="col-sm-6 col-lg-2">
      <touko-taglist [item]="syotteet.tila"
                  [dropdownTeksti]="syotteet.tila.options.dropdownTeksti"
                  [value]="raporttiForm.get('tila').value"
                  [formControlName]="'tila'"
                  [tags]="tilaTags"></touko-taglist>
    </div>
    <div class="col-sm-6 col-lg-2">
      <touko-taglist [item]="syotteet.tarkastustyyppi"
                  [dropdownTeksti]="syotteet.tarkastustyyppi.options.dropdownTeksti"
                  [value]="raporttiForm.get('tarkastustyyppi').value"
                  [formControlName]="'tarkastustyyppi'"
                  [tags]="tarkastustyypitTags"></touko-taglist>
    </div>
    <div class="col-sm-6 col-lg-2">
      <touko-taglist [item]="syotteet.tapahtumatyyppi"
                  [dropdownTeksti]="syotteet.tapahtumatyyppi.options.dropdownTeksti"
                  [value]="raporttiForm.get('tapahtumatyyppi').value"
                  [formControlName]="'tapahtumatyyppi'"
                  [tags]="tapahtumatyypitTags"></touko-taglist>
    </div>
  </div>
  <div class="row form-group">

    <div class="col-sm-12 col-md-6 col-lg-2">
      <touko-pvm [isInValid]="getInvalid('pvmAlku')" [htmlId]="'raportti-pvmAlku'" [item]="syotteet.pvmAlku"
              formControlName="pvmAlku"></touko-pvm>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">
      <touko-pvm [isInValid]="getInvalid('pvmLoppu')" [htmlId]="'raportti-pvmLoppu'" [item]="syotteet.pvmLoppu"
              formControlName="pvmLoppu"></touko-pvm>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">

      <button class="btn btn-outline-primary form-control no-label" type="button" (click)="reset()"
              id="raportti-tyhjenna"
              attribute="raportit.tyhjenna">
        Tyhjennä
      </button>
    </div>
  </div>
</form>

<raportti-sivutus></raportti-sivutus>

<div class="row form-group"></div>

<div class="row">
  <div class="col-sm-12">
    <datatable [showSpinner]="showLoader" [columnOrder]="columnOrder"
               [liiteDownloadFn]="liiteDownloadFn" [liiteZipDownloadFn]="liiteDownloadZipFn">
    </datatable>
  </div>
</div>
