<touko-lomake *ngIf="lomake"
              [formGroup]="lomake"
              [tunnus]="lomakeMetaData.id"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [cancelFn]="cancelLomake"
              (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              [saveFn]="saveLomake">
  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        sivuvirhekasittely [virheGroups]="['laskutustiedot', 'perustiedot', 'yhteyshenkilot']"
        sivu="ref0">
    <div class="mb-4">
      <touko-alert-box *ngIf="!isPerustiedotValid">
        <div attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</div>
      </touko-alert-box>
    </div>
    <perustiedot [lomake]="lomake"></perustiedot>
    <laskutustiedot [lomake]="lomake"></laskutustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"></yhteyshenkilot>

    <fieldset formGroupName="toiminnanAloitus" class="col-md-5 col-12 px-0">
      <legend [attribute]="lomake.get('toiminnanAloitus').field.label"></legend>
      <date [label]="lomake.get('toiminnanAloitus.toiminnanAloitus').field.label" formControlName="toiminnanAloitus"
            htmlIdSuffix="toiminnanAloitus"></date>
    </fieldset>
    <validation-error [field]="lomake.get('toiminnanAloitus')"></validation-error>
  </sivu>

  <sivu [selectable]="isPerustiedotValid"
        [attribuuttiAvain]="'lannoiterekisteri.toimintaJaTuoteluokat'"
        [ohjeet]="lomakeInfo.ohjeet['sivu1']"
        sivu="ref1">

    <div class="mb-5">
      <touko-alert-box alertType="warning">
        <p
          [attribute]="lomakeInfo.toimintotyyppi === 'muutos' ? 'lannoiterekisteri.tuoteluokatMuutosLabel' : 'lannoiterekisteri.tuoteluokatLabel'"
          class="mb-0"></p>
      </touko-alert-box>
    </div>

    <fieldset formGroupName="toimintaJaTuoteluokatKansallinen">
      <legend attribute="lannoiterekisteri.kansallinenToiminta"></legend>
      <touko-collapse-component htmlId="toggler-lannoiteValmisteenTuonti"
                                labelAttribute="lannoiterekisteri.lannoiteValmisteenTuonti"
                                formGroupName="lannoiteValmisteenTuonti"
                                [infoAttribute]="getActiveString('lannmaahantuonti')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannmaahantuonti"
            [htmlId]="lopetaMaahantuonti.field.htmlId"
            [labelAttribute]="lopetaMaahantuonti.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannmaahantuonti">
          <touko-collapse-component *ngFor="let maahantuonti of lannoiteValmisteenMaahantuonti"
                                    [htmlId]="'toggler-lannoiteValmisteenTuonti-' + maahantuonti.field.htmlId"
                                    [labelAttribute]="maahantuonti.field.label"
                                    [formGroup]="maahantuonti.asGroup()">
            <div class="pb-4">
              <div class="background-card">
                <ng-container *ngIf="maahantuonti.field.htmlId !== 'vanhanLainsaadannonMukaanTuonti'; else elseBlock">
                  <fieldset formGroupName="maarat">
                    <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                    <touko-number-input
                        formControlName="maara"
                        [htmlId]="maahantuonti.get('maarat.maara').field.htmlId"
                        [labelAttribute]="maahantuonti.get('maarat.maara').field.label"
                        descriptionAttribute="lannoiterekisteri.tnVuosi"
                        inputStyle="inline"
                        inputWidth="small">
                    </touko-number-input>
                  </fieldset>
                </ng-container>

                <ng-template #elseBlock>
                  <fieldset>
                    <legend attribute="lannoiterekisteri.tiedotTyyppinimiKohtaisesti" class="h4 text-dark"></legend>
                    <tuotetiedot-tyyppinimi-v2></tuotetiedot-tyyppinimi-v2>
                  </fieldset>
                </ng-template>
              </div>
            </div>
          </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-lannoiteValmisteenVienti"
                                labelAttribute="lannoiterekisteri.lannoiteValmisteenVienti"
                                formGroupName="lannoiteValmisteenVienti"
                                [infoAttribute]="getActiveString('lannmaastavienti')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannmaastavienti"
            [htmlId]="lopetaMaastavienti.field.htmlId"
            [labelAttribute]="lopetaMaastavienti.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannmaastavienti">
          <touko-collapse-component *ngFor="let vienti of lannoiteValmisteenVienti"
                                    [htmlId]="'toggler-lannoiteValmisteenVienti-' + vienti.field.htmlId"
                                    [labelAttribute]="vienti.field.label"
                                    [formGroup]="vienti.asGroup()">
            <fieldset class="pb-4" formGroupName="maarat">
              <div class="background-card">
                <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                <touko-number-input
                    formControlName="maara"
                    [htmlId]="vienti.get('maarat.maara').field.htmlId"
                    [labelAttribute]="vienti.get('maarat.maara').field.label"
                    descriptionAttribute="lannoiterekisteri.tnVuosi"
                    inputStyle="inline"
                    inputWidth="small">
                </touko-number-input>
              </div>
            </fieldset>
          </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-lannoiteValmisteenTuotantoOmaKaytto"
                                labelAttribute="lannoiterekisteri.lannoiteValmisteenTuotantoOmaKaytto"
                                formGroupName="lannoiteValmisteenTuotantoOmaKaytto"
                                [infoAttribute]="getActiveString('lannmaatilatuotanto')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannmaatilatuotanto"
            [htmlId]="lopetaOmaKaytto.field.htmlId"
            [labelAttribute]="lopetaOmaKaytto.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannmaatilatuotanto">
          <touko-collapse-component *ngFor="let omaKaytto of lannoiteValmisteenOmaKaytto"
                                    [htmlId]="'toggler-lannoiteValmisteenTuotantoOmaKaytto-' + omaKaytto.field.htmlId"
                                    [labelAttribute]="omaKaytto.field.label"
                                    [formGroup]="omaKaytto.asGroup()">
            <div class="pb-4">
              <div class="background-card">
                <fieldset formGroupName="maarat" class="mb-4">
                  <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                  <touko-number-input
                      formControlName="maara"
                      [htmlId]="omaKaytto.get('maarat.maara').field.htmlId"
                      [labelAttribute]="omaKaytto.get('maarat.maara').field.label"
                      descriptionAttribute="lannoiterekisteri.tnVuosi"
                      inputStyle="inline"
                      inputWidth="small">
                  </touko-number-input>
                </fieldset>
                <fieldset formGroupName="ainesosaluokat">
                  <legend attribute="lannoiterekisteri.labelAinesosaluokat" class="h4 text-dark"></legend>
                  <touko-checkbox *ngFor="let aol of ainesosaluokatAsArray(omaKaytto); index as i"
                                  [htmlId]="aol.field.htmlId"
                                  [labelAttribute]="aol.field.label"
                                  [formControlName]="'aol' + (i+1)"></touko-checkbox>
                </fieldset>
              </div>
            </div>
          </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-lannoiteValmisteenJakelija"
                                labelAttribute="lannoiterekisteri.lannoiteValmisteenJakelija"
                                formGroupName="lannoiteValmisteenJakelija"
                                [infoAttribute]="getActiveString('lannjakelu')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannjakelu"
            [htmlId]="lopetaJakelija.field.htmlId"
            [labelAttribute]="lopetaJakelija.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannjakelu">
          <touko-collapse-component *ngFor="let jakelija of lannoiteValmisteenJakelija"
                                    [htmlId]="'toggler-lannoiteValmisteenJakelija-' + jakelija.field.htmlId"
                                    [labelAttribute]="jakelija.field.label"
                                    [formGroup]="jakelija.asGroup()">
            <div class="pb-4">
              <div class="background-card">
                <fieldset formGroupName="maarat">
                  <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                  <touko-number-input
                      formControlName="maaraSuomi"
                      [htmlId]="jakelija.get('maarat.maaraSuomi').field.htmlId"
                      [labelAttribute]="jakelija.get('maarat.maaraSuomi').field.label"
                      descriptionAttribute="lannoiterekisteri.tnVuosi"
                      inputStyle="inline"
                      inputWidth="small">
                  </touko-number-input>
                  <touko-number-input
                      formControlName="maaraSisamarkkinat"
                      [htmlId]="jakelija.get('maarat.maaraSisamarkkinat').field.htmlId"
                      [labelAttribute]="jakelija.get('maarat.maaraSisamarkkinat').field.label"
                      descriptionAttribute="lannoiterekisteri.tnVuosi"
                      inputStyle="inline"
                      inputWidth="small">
                  </touko-number-input>
                </fieldset>
              </div>
            </div>
          </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-lannoiteValmisteenValmistus"
                                labelAttribute="lannoiterekisteri.lannoiteValmisteenValmistus"
                                formGroupName="lannoiteValmisteenValmistus"
                                [infoAttribute]="getActiveString('lannvalmistus')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannvalmistus"
            [htmlId]="lopetaValmisteenValmistus.field.htmlId"
            [labelAttribute]="lopetaValmisteenValmistus.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannvalmistus">
          <touko-collapse-component *ngFor="let valmistus of lannoiteValmisteenValmistus"
                                    [htmlId]="'toggler-lannoiteValmisteenValmistus-' + valmistus.field.htmlId"
                                    [labelAttribute]="valmistus.field.label"
                                    [formGroup]="valmistus.asGroup()">
            <div class="pb-4">
              <div class="background-card">
                <fieldset formGroupName="maarat">
                  <legend attribute="lannoiterekisteri.lannoitteidenMaaraJaValmistus" class="h4 text-dark"></legend>
                  <touko-number-input
                      formControlName="maara"
                      [htmlId]="valmistus.get('maarat.maara').field.htmlId"
                      [labelAttribute]="valmistus.get('maarat.maara').field.label"
                      descriptionAttribute="lannoiterekisteri.tnVuosi"
                      inputStyle="inline"
                      inputWidth="small">
                  </touko-number-input>
                </fieldset>

                <div formGroupName="valmistus">
                  <fieldset class="mt-4 mb-2">
                    <legend attribute="lannoiterekisteri.lannoitteenValmistusOmaanKayttoon"
                            class="h4 text-dark"></legend>
                    <touko-radio-input
                      *ngFor="let omaKaytto of (valmistus.get('valmistus.lannoitteenValmistusOmaanKayttoon').field.valueOptions || [])"
                      [labelAttribute]="omaKaytto.label"
                      [value]="omaKaytto.value"
                      [radioGroup]="valmistus.field.htmlId + 'lannoitteenValmistusOmaanKayttoon'"
                      [htmlId]="omaKaytto.htmlId"
                      formControlName="lannoitteenValmistusOmaanKayttoon"></touko-radio-input>
                  </fieldset>
                  <fieldset class="mb-2">
                    <legend attribute="lannoiterekisteri.lannoitteenValmistusAinoastaanPakkaamista"
                            class="h4 text-dark"></legend>
                    <touko-radio-input
                      *ngFor="let vainPakkaamista of (valmistus.get('valmistus.lannoitteenValmistusAinoastaanPakkaamista').field.valueOptions || [])"
                      [labelAttribute]="vainPakkaamista.label"
                      [value]="vainPakkaamista.value"
                      [radioGroup]="valmistus.field.htmlId + 'lannoitteenValmistusAinoastaanPakkaamista'"
                      [htmlId]="vainPakkaamista.htmlId"
                      formControlName="lannoitteenValmistusAinoastaanPakkaamista"></touko-radio-input>
                  </fieldset>
                </div>

                <fieldset *ngIf="ainesosaluokatAsArray(valmistus)" formGroupName="ainesosaluokat">
                  <legend attribute="lannoiterekisteri.labelAinesosaluokat" class="h4 text-dark"></legend>
                  <touko-checkbox *ngFor="let aol of ainesosaluokatAsArray(valmistus); index as i"
                                  [htmlId]="aol.field.htmlId"
                                  [labelAttribute]="aol.field.label"
                                  [formControlName]="'aol' + (i+1)"></touko-checkbox>
                </fieldset>
              </div>
            </div>
          </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-lannoiteValmisteenVarastointi"
                                labelAttribute="lannoiterekisteri.lannoiteValmisteenVarastointi"
                                formGroupName="lannoiteValmisteenVarastointi"
                                [infoAttribute]="getActiveString('lannvarastointi')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannvarastointi"
            [htmlId]="lopetaVarastointi.field.htmlId"
            [labelAttribute]="lopetaVarastointi.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannvarastointi">
          <touko-checkbox *ngFor="let varastointi of lannoiteValmisteenVarastointi"
                          [htmlId]="varastointi.field.htmlId"
                          [labelAttribute]="varastointi.field.label"
                          [formControl]="varastointi.asControl()"></touko-checkbox>
        </ng-container>
      </touko-collapse-component>
    </fieldset>

    <fieldset formGroupName="toimintaJaTuoteluokatEUToiminta">
      <legend attribute="lannoiterekisteri.euToiminta"></legend>
      <touko-collapse-component htmlId="toggler-euLannoiteValmisteenTuonti"
                                labelAttribute="lannoiterekisteri.euLannoiteValmisteenTuonti"
                                formGroupName="euLannoiteValmisteenTuonti"
                                [infoAttribute]="getActiveString('lanneumaahantuonti')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lanneumaahantuonti"
            [htmlId]="lopetaEuMaahantuonti.field.htmlId"
            [labelAttribute]="lopetaEuMaahantuonti.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lanneumaahantuonti">
          <touko-collapse-component *ngFor="let tuonti of euMaahantuonti"
                                  [htmlId]="'toggler-' + tuonti.field.htmlId"
                                  [labelAttribute]="tuonti.field.label"
                                  [formGroup]="tuonti.asGroup()">
          <touko-collapse-component *ngFor="let tuote of groupTuotteet(tuonti.asGroup())"
                                    [htmlId]="'toggler-' + tuote.field.htmlId"
                                    [labelAttribute]="tuote.field.label"
                                    [formGroup]="tuote.asGroup()">
            <fieldset class="pb-4" formGroupName="maarat">
              <div class="background-card">
                <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                <touko-number-input
                    formControlName="maara"
                    [htmlId]="tuote.get('maarat.maara').field.htmlId"
                    [labelAttribute]="tuote.get('maarat.maara').field.label"
                    descriptionAttribute="lannoiterekisteri.tnVuosi"
                    inputStyle="inline"
                    inputWidth="small">
                </touko-number-input>
              </div>
            </fieldset>
          </touko-collapse-component>
        </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-euLannoitevalmisteenVieja"
                                labelAttribute="lannoiterekisteri.euLannoitevalmisteenVieja"
                                formGroupName="euLannoitevalmisteenVieja"
                                [infoAttribute]="getActiveString('lanneumaastavienti')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lanneumaastavienti"
            [htmlId]="lopetaEuVienti.field.htmlId"
            [labelAttribute]="lopetaEuVienti.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lanneumaastavienti">
          <touko-collapse-component *ngFor="let vienti of euVienti"
                                  [htmlId]="'toggler-' + vienti.field.htmlId"
                                  [labelAttribute]="vienti.field.label"
                                  [formGroup]="vienti.asGroup()">
          <touko-collapse-component *ngFor="let tuote of groupTuotteet(vienti)"
                                    [htmlId]="'toggler-' + tuote.field.htmlId"
                                    [labelAttribute]="tuote.field.label"
                                    [formGroup]="tuote.asGroup()">
            <fieldset class="pb-4" formGroupName="maarat">
              <div class="background-card">
                <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                <touko-number-input
                    formControlName="maara"
                    [htmlId]="tuote.get('maarat.maara').field.htmlId"
                    [labelAttribute]="tuote.get('maarat.maara').field.label"
                    descriptionAttribute="lannoiterekisteri.tnVuosi"
                    inputStyle="inline"
                    inputWidth="small">
                </touko-number-input>
              </div>
            </fieldset>
          </touko-collapse-component>
        </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-euLannoitevalmisteenJakelija"
                                labelAttribute="lannoiterekisteri.euLannoitevalmisteenJakelija"
                                formGroupName="euLannoitevalmisteenJakelija"
                                [infoAttribute]="getActiveString('lanneujakelu')">

        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lanneujakelu"
            [htmlId]="lopetaEuJakelija.field.htmlId"
            [labelAttribute]="lopetaEuJakelija.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lanneujakelu">
          <touko-collapse-component *ngFor="let jakelija of euJakelija"
                                  [htmlId]="'toggler-' + jakelija.field.htmlId"
                                  [labelAttribute]="jakelija.field.label"
                                  [formGroup]="jakelija.asGroup()">
          <touko-collapse-component *ngFor="let tuote of groupTuotteet(jakelija)"
                                    [htmlId]="'toggler-' + tuote.field.htmlId"
                                    [labelAttribute]="tuote.field.label"
                                    [formGroup]="tuote.asGroup()">
            <fieldset class="pb-4" formGroupName="maarat">
              <div class="background-card">
                <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                <touko-number-input
                    formControlName="maaraSuomi"
                    [htmlId]="tuote.get('maarat.maaraSuomi').field.htmlId"
                    [labelAttribute]="tuote.get('maarat.maaraSuomi').field.label"
                    descriptionAttribute="lannoiterekisteri.tnVuosi"
                    inputStyle="inline"
                    inputWidth="small">
                </touko-number-input>
                <touko-number-input
                    formControlName="maaraSisamarkkinat"
                    [htmlId]="tuote.get('maarat.maaraSisamarkkinat').field.htmlId"
                    [labelAttribute]="tuote.get('maarat.maaraSisamarkkinat').field.label"
                    descriptionAttribute="lannoiterekisteri.tnVuosi"
                    inputStyle="inline"
                    inputWidth="small">
                </touko-number-input>
              </div>
            </fieldset>

          </touko-collapse-component>
        </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>

      <touko-collapse-component htmlId="toggler-euLannoitevalmisteenValmistaja"
                                labelAttribute="lannoiterekisteri.euLannoitevalmisteenValmistaja"
                                formGroupName="euLannoitevalmisteenValmistaja"
                                [infoAttribute]="getActiveString('lanneuvalmistus')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lanneuvalmistus"
            [htmlId]="lopetaEuValmistaja.field.htmlId"
            [labelAttribute]="lopetaEuValmistaja.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lanneuvalmistus">
          <touko-collapse-component *ngFor="let valmistaja of euValmistaja"
                                  [htmlId]="'toggler-' + valmistaja.field.htmlId"
                                  [labelAttribute]="valmistaja.field.label"
                                  [formGroup]="valmistaja.asGroup()">
          <touko-collapse-component *ngFor="let tuote of groupTuotteet(valmistaja)"
                                    [htmlId]="'toggler-' + tuote.field.htmlId"
                                    [labelAttribute]="tuote.field.label"
                                    [formGroup]="tuote.asGroup()">
            <div class="pb-4">
              <div class="background-card">
                <fieldset formGroupName="maarat">
                  <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
                  <touko-number-input
                      formControlName="maara"
                      [htmlId]="tuote.get('maarat.maara').field.htmlId"
                      [labelAttribute]="tuote.get('maarat.maara').field.label"
                      descriptionAttribute="lannoiterekisteri.tnVuosi"
                      inputStyle="inline"
                      inputWidth="small">
                  </touko-number-input>
                </fieldset>
                <fieldset class="mb-2 mt-4" formGroupName="valmistus">
                  <legend attribute="lannoiterekisteri.lannoitteenValmistusAinoastaanPakkaamista"
                          class="h4 text-dark"></legend>
                  <touko-radio-input
                    *ngFor="let vainPakkaamista of (tuote.get('valmistus.lannoitteenValmistusAinoastaanPakkaamista').field.valueOptions || [])"
                    [labelAttribute]="vainPakkaamista.label"
                    [value]="vainPakkaamista.value"
                    [radioGroup]="valmistaja.field.htmlId + 'lannoitteenValmistusAinoastaanPakkaamista'"
                    [htmlId]="vainPakkaamista.htmlId"
                    formControlName="lannoitteenValmistusAinoastaanPakkaamista"></touko-radio-input>
                </fieldset>
                <fieldset formGroupName="ainesosaluokat">
                  <legend attribute="lannoiterekisteri.labelAinesosaluokat" class="h4 text-dark"></legend>
                  <touko-checkbox *ngFor="let cmc of ainesosaluokatAsArray(tuote); index as i"
                                  [htmlId]="cmc.field.htmlId"
                                  [labelAttribute]="cmc.field.label"
                                  [formControlName]="'cmc' + (i+1)"></touko-checkbox>
                </fieldset>
              </div>
            </div>
          </touko-collapse-component>
        </touko-collapse-component>
        </ng-container>
      </touko-collapse-component>
    </fieldset>
    <fieldset formGroupName="muuToiminta">
      <legend attribute="lannoiterekisteri.muuToiminta"></legend>
      <touko-collapse-component htmlId="toggler-lannoitevalmisteenMarkkinointi"
                                labelAttribute="lannoiterekisteri.lannoitevalmisteenMarkkinointi"
                                formGroupName="lannoitevalmisteenMarkkinointi"
                                [infoAttribute]="getActiveString('lannmarkvastvtun')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.lannmarkvastvtun"
            [htmlId]="lopetaMuuToiminta.field.htmlId"
            [labelAttribute]="lopetaMuuToiminta.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.lannmarkvastvtun">
          <fieldset class="pb-4" formGroupName="maarat">
          <div class="background-card">
            <ng-container>
              <legend attribute="lannoiterekisteri.arvioidutMaarat" class="h4 text-dark"></legend>
              <touko-number-input
                  formControlName="maara"
                  htmlId="muutoiminta-maara"
                  labelAttribute="lannoiterekisteri.markkinoilleTuotavienLannoitteidenMaara"
                  descriptionAttribute="lannoiterekisteri.tnVuosi"
                  inputStyle="inline"
                  inputWidth="small">
              </touko-number-input>
            </ng-container>
          </div>
        </fieldset>
        </ng-container>
      </touko-collapse-component>
    </fieldset>

    <fieldset formGroupName="lannoitesivutuotetoimija">
      <legend attribute="lannoiterekisteri.sivutuotteenKasittelyLannoitevalmisteeksi"></legend>
      <touko-collapse-component htmlId="toggler-lannoitesivutuotetoimija"
                                labelAttribute="lannoiterekisteri.lannoitesivutuotetoimija"
                                formGroupName="lannoitesivutuotetoimija"
                                [infoAttribute]="getActiveString('sivulannoite')">
        <div class="pb-1">
          <touko-checkbox
            *ngIf="alaokohdeToiminnot.sivulannoite"
            [htmlId]="lopetaLannoitesivutuotetoimija.field.htmlId"
            [labelAttribute]="lopetaLannoitesivutuotetoimija.field.label"
            formControlName="lopetaToiminta">
          </touko-checkbox>
        </div>

        <ng-container *ngIf="!alaokohdeToiminnot.sivulannoite">
          <div class="background-card">
          <fieldset formGroupName="laitosHyvaksynta">
            <legend attribute="lannoiterekisteri.haeToiminnalleLaitoshyväksyntaa" class="h4 text-dark"></legend>
            <touko-radio-input
              *ngFor="let laitosHyvaksyntaRadio of lannoitesivutuotetoimija.get('laitosHyvaksynta.laitosHyvaksyntaRadio').field.valueOptions || []"
              [labelAttribute]="laitosHyvaksyntaRadio.label"
              [value]="laitosHyvaksyntaRadio.value"
              [radioGroup]="'laitosHyvaksyntaRadio'"
              [htmlId]="'laitosHyvaksynta-' + laitosHyvaksyntaRadio.value"
              formControlName="laitosHyvaksyntaRadio"></touko-radio-input>
            <div class="col-6 pl-5">
              <touko-text-input
                htmlId="laitosHyvaksynta-laitosHyvaksyntaMuuInfo"
                [labelledById]="'laitosHyvaksynta-muu-label'"
                formControlName="laitosHyvaksyntaMuuInfo"></touko-text-input>
            </div>
          </fieldset>
          <fieldset formGroupName="kasiteltavatRaakaaineet">
            <legend attribute="lannoiterekisteri.kasiteltavatRaakaaineet" class="h4 text-dark"></legend>
            <touko-checkbox *ngFor="let raakaAine of kasiteltavatRaakaAineet"
                            [htmlId]="raakaAine.field.htmlId"
                            [labelAttribute]="raakaAine.field.label"
                            [formControl]="raakaAine.asControl()"></touko-checkbox>
            <div class="col-6 pl-5">
              <touko-text-input
                htmlId="kasiteltavatRaakaaineet-muuInfo"
                [labelledById]="'kasiteltavatRaakaaineet-muu-label'"
                formControlName="muuInfo"></touko-text-input>
            </div>
          </fieldset>
          <fieldset formGroupName="ymparistolupa">
            <legend attribute="lannoiterekisteri.ymparistolupa" class="h4 text-dark"></legend>
            <touko-checkbox htmlId="ymparistolupa-toiminnallaOnYmparistolupa"
                            labelAttribute="lannoiterekisteri.toiminnallaOnYmparistolupa"
                            formControlName="toiminnallaOnYmparistolupa"></touko-checkbox>
            <div class="col-6 pl-5">
              <touko-text-input
                htmlId="ymparistolupa-luvanNum"
                [labelledById]="'ymparistolupa-toiminnallaOnYmparistolupa-label'"
                formControlName="luvanNum"></touko-text-input>
            </div>
          </fieldset>
        </div>
        </ng-container>

      </touko-collapse-component>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'lannoiterekisteri.liitteet'"
        [ohjeet]="lomakeInfo.ohjeet['sivu2']"
        sivuvirhekasittely [virheGroups]="['liitePakolliset.liiteValmisteet']" sivu="ref2">
    <fieldset formGroupName="lisatiedot">
      <legend attribute="lannoiterekisteri.toimintaKuvausOtsikko"></legend>
      <touko-textarea htmlId="liiteMuu1" labelAttribute="lannoiterekisteri.uusiIlmoitusToimintaKuvaus"
                      formControlName="uusiIlmoitusToimintaKuvaus"></touko-textarea>
    </fieldset>
    <fieldset formGroupName="liitePakolliset">
      <legend attribute="lannoiterekisteri.liiteTiedot"></legend>
      <fieldset formGroupName="liiteValmisteet" class="mb-4">
        <legend attribute="lannoiterekisteri.liiteValmisteet" class="h4"></legend>
        <liite-pvm
          [liitteetArray]="lomake.get('liitePakolliset.liiteValmisteet.liite').asArray()"
          pvmAttr="lannoiterekisteri.liiteValmisteetPvm"
          liiteAttr="lannoiterekisteri.liiteValmisteet"
          htmlIdSuffix="valmisteet"
          selite="VALM_TUOTETIEDOT"
          [lomakeId]="lomakeMetaData.id"
          (liiteOut)="onSetLiitteet($event, 'tuotetiedot')"></liite-pvm>
      </fieldset>
      <div class="mb-6">
        <validation-error [field]="lomake.get('liitePakolliset.liiteValmisteet')"></validation-error>
      </div>

      <fieldset formGroupName="liiteLaatujarjestelma">
        <legend attribute="lannoiterekisteri.liiteLaatujarjestelmaOtsikko" class="h4"></legend>
        <liite-pvm
          [liitteetArray]="lomake.get('liitePakolliset.liiteLaatujarjestelma.liite').asArray()"
          pvmAttr="lannoiterekisteri.liiteLaatujarjestelmaPvm"
          liiteAttr="lannoiterekisteri.liiteLaatujarjestelma"
          htmlIdSuffix="laatujarjestelma"
          selite="OVS"
          [lomakeId]="lomakeMetaData.id"
          (liiteOut)="onSetLiitteet($event, 'laatujarjestelma')"></liite-pvm>
      </fieldset>
    </fieldset>
    <div class="col-10 px-0">
      <label id="label-liiteMuut" attribute="lannoiterekisteri.liiteMuut" class="h4"></label>
      <liite-list [liitteetArray]="lomake.get('liiteMuut.liiteMuut').asArray()"
                  htmlId="liiteMuut"
                  labelledBy="label-liiteMuut"
                  maxLiiteLkm="10"
                  [lomakeId]="lomakeMetaData.id"
                  (liiteOut)="onSetLiitteet($event, 'muut')"></liite-list>
    </div>
    <fieldset formGroupName="verkkokaupat" class="mb-2">
      <legend attribute="lomakeYleinen.verkkokaupat"></legend>
      <verkkokaupat-list></verkkokaupat-list>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
    <app-field-group-summary class="summary" [fieldGroup]="lomake"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button id="laheta" class="mt-4 btn btn-primary" type="submit" attribute="teksti.laheta">Lähetä</button>
    </div>
  </sivu>
</touko-lomake>

<pre *devFeature>{{lomake?.getRawValue() | json}}</pre>

