import {Component, Input} from "@angular/core";
import {FieldGroup} from "ngx-fielding";
import {YhteyshenkiloValue} from "../touko-lomake-utils";
import {Option} from "../../touko-components/select-menu.component";
import {YhteyshenkiloViewOptions} from "../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";

@Component({
  selector: "yhteyshenkilot",
  template: `
    <ng-container [formGroup]="lomake.get('yhteyshenkilot').asGroup()">
      <ng-container formArrayName="yhteyshenkilot">
        <fieldset required class="mb-2">
          <legend [attribute]="lomake.get('yhteyshenkilot.yhteyshenkilot').field.label"></legend>
          <yhteyshenkilo-list [yhteyshenkiloViewOptions]="yhteyshenkiloViewOptions" [roolit]="roolit"></yhteyshenkilo-list>
        </fieldset>
      </ng-container>
    </ng-container>
  `
})
export class PerustiedotYhteyshenkilotComponent {
  @Input() lomake: FieldGroup;
  @Input() rooliValues: YhteyshenkiloValue[];
  @Input() yhteyshenkiloViewOptions = new YhteyshenkiloViewOptions();

  get roolit(): Option[] {
    return this.rooliValues.map(rooli => ({
      value: rooli.value,
      label: rooli.teksti.fullKey,
      selected: false,
      disabled: rooli.disabled
    }));
  }

}
