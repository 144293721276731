import {CompareAction, CompareType, MatcherModel} from "ee-lahete";
import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {QuestionInitValue} from "ee-lahete";

// @dynamic
export class TutkimusGroup extends AbstractLaheteGroup {

  static getDisablerReturnVal = (value: boolean) => value ? CompareAction.DISABLE : CompareAction.NONE;

  getGroup(controlType?, builder = new GroupBuilder({controlType: 'checkbox'})) {
    const valitutTutkimuksetModifier = (compareVal, currentVal) => currentVal.valitut.some(val => compareVal === val.id && val.selected === false) ?
      CompareAction.DESELECT_ONLY_CHECKBOX_OPTION :
      CompareAction.NONE;

    const controls: QuestionControl[] = this.laheteKoodisto.data.map(el => {
      const tutkimussyyt = (el.tutkimussyyKoodi || []).map(elTutkimussyy => {
        const tutkimyssyy = this.laheteKoodisto.koodisto.tutkimussyyt.find(ts => ts.id === elTutkimussyy);
        if (!tutkimyssyy) {
          console.error(`Tutkimuksen ${el.id} tutkimussyytä '${elTutkimussyy}' ei löytynyt koodistosta`);
          return null;
        }
        return this.getLocalizedOf(tutkimyssyy, 'kuvaus');
      }).filter(tutkimyssyy => tutkimyssyy !== null);

      const control = new QuestionControl({
        controlType: builder.controlType,
        key: el.id,
        htmlId: el.id,
        label: this.getLocalizedOf(el, 'tutkimus'),
        options: {labelDesc: tutkimussyyt.join(', ')},
        filters: {
          'elainlajit.elainlajiryhma': new MatcherModel(el.elainlajikoodi, TutkimusGroup.equalsFilterFn, TutkimusGroup.filterActionFn),
          'tutkimusalat': new MatcherModel(el.provetKoodi, (cVal, nVal) => {
            const trueValues = Object.keys(nVal).filter(key => nVal[key]);
            return (trueValues.length === 0 || trueValues.includes(cVal)) ? CompareAction.FILTER : CompareAction.NONE;
          }, TutkimusGroup.filterActionFn, CompareType.EVERY, CompareAction.FILTER),
          'tutkimushaku.tutkimushaku': new MatcherModel(this.getLocalizedOf(el, 'tutkimus'), TutkimusGroup.searchFilterFn, TutkimusGroup.filterActionFn, CompareType.EVERY, CompareAction.FILTER)
        },
        disablers: {
          'tutkimukset': new MatcherModel(el.lahetysosoite, this.groupDisabler.bind(this), TutkimusGroup.disablerActionFn),
        },
        modifiers: {
          'elainlajit.elainlajiryhma': new MatcherModel(el.elainlajikoodi, TutkimusGroup.notEqualsResetFn, TutkimusGroup.modifierActionFn, CompareType.SOME),
          'valitutTutkimukset': new MatcherModel(el.id, valitutTutkimuksetModifier, TutkimusGroup.modifierActionFn, CompareType.SOME)
        },
      });

      if (this.useBuilderValues(builder, 'tutkimukset') && builder.initialValues.tutkimukset && builder.initialValues.tutkimukset[el.id]) {
        this.laheteService.registerInitialValue(new QuestionInitValue(control, builder.initValue(val => val.tutkimukset[el.id])));
      }

      return control;
    });

    return new QuestionGroup({
      key: "tutkimukset",
      label: this.getLabel('tutkimukset'),
      controls: controls,
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, TutkimusGroup.notEqualsFilterFn, TutkimusGroup.filterActionFn)
      }
    });
  }

  groupDisabler(compareVal, newVal): CompareAction {
    const allSelectedIds: string[] = Object.keys(newVal).filter(key => newVal[key]);
    const allSelectedOsoitteet: string[][] = allSelectedIds.map(id => this.laheteKoodisto.dataObj[id].lahetysosoite.map(TutkimusGroup.lahetysosoiteMapper));
    const filteredLahetysosoiteSet: Set<string> = this.getLahetysosoiteIntersection(allSelectedOsoitteet);

    return TutkimusGroup.getDisablerReturnVal(filteredLahetysosoiteSet.size > 0 && TutkimusGroup.intersection(filteredLahetysosoiteSet, new Set(compareVal.map(TutkimusGroup.lahetysosoiteMapper))).size === 0);
  }
}
