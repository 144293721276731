import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {ControlMatcherDirective} from './matcher/control-matcher.directive';
import {MatcherService} from "./matcher/matcher.service";

@NgModule({
  declarations: [
    ControlMatcherDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    ControlMatcherDirective,
  ],
  providers: [
    MatcherService
  ]
})
export class LaheteModule { }
