import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {TulosraporttiService} from "../raportti.service";
import {Sarake, SarakeType} from "../raportti.utils";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Teksti} from "../../utils/teksti";
import {DataTableComponent} from "../data-table.component";
import {RaporttiBaseClass} from "../raportti-base";
import {Syote} from "../../touko-lomake/syote/syote";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 09/11/2017.
 */

const VIEW = "raportit";

@Component({
  selector: 'elabra-raportti',
  templateUrl: './elabra-raportti.component.html',
  styles: ['.no-wrap{ white-space: nowrap; }', 'th>span{cursor: pointer}']
})
@AutoUnsubscribe
export class ElabraRaporttiComponent extends RaporttiBaseClass implements OnInit {
  @ViewChild("datatable") datatable: DataTableComponent;
  @Input() getRaportitFn;
  raporttiForm: FormGroup;
  keys;
  liiteDownloadFn;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  syotteet: { [s: string]: Syote };
  protected toimiala = "ELAIN_LABORATORIO";

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
        this.fb.group({
          laheteTunnus: null,
          elmoRaporttiTunnus: null,
          elmoLaheteNumero: null,
          pvmAlku: [null, ToukoValidation.isValidDate],
          pvmLoppu: [null, ToukoValidation.isValidDate],
        })
    );

    this.syotteet = {
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };

    this.liiteDownloadFn = this.tulosraporttiService.getElabraLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getElabraLiiteZip.bind(this.tulosraporttiService);
    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getElabraTutkimustodistusRaportit.bind(this.tulosraporttiService);

    this.keys = {
      tunnus: new Sarake('laheteTunnus', new Teksti(VIEW, "lahete", VIEW), SarakeType.DEFAULT, () => "no-word-break"),
      pvm: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      elmoLaheteNumero: new Sarake('elmoLaheteNumero', new Teksti(VIEW, 'elmoLaheteNumero', VIEW), SarakeType.DEFAULT),
      raporttiTunnus: new Sarake('elmoRaporttiTunnus', new Teksti(VIEW, 'elmoRaporttiTunnus', VIEW), SarakeType.DEFAULT),
      dokumentti: this.getAsiakirjatSarakeTitle('asiakirja'),
    };

    this.columnOrder = [
      this.keys.tunnus,
      this.keys.pvm,
      this.keys.elmoLaheteNumero,
      this.keys.raporttiTunnus,
      this.keys.dokumentti
    ];

    this.initRaportit();
  }

  initTags() {
    // ignore
  }
}
