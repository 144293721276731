/**
 * Created by Seppo on 06/07/2018.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, OnInit, Renderer2} from "@angular/core";
import {Tag} from "../raportti/raportti.utils";
/**
 * Created by Seppo on 28/05/2018.
 */

@Component({
  selector: 'touko-taglist-check',
  template: `
    <div [ngClass]="{'d-inline mr-4 align-self-center': inline}" *ngFor="let tag of tags">
      <touko-checkbox [checked]="checkedTags[tag.value] || false" [labelAttribute]="tag.nimi?.getFullKey()"
                      type="checkbox" htmlId="{{tag.value}}" (change)="selectTag(tag.value)"></touko-checkbox>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagListCheckComponent),
      multi: true
    }
  ]
})
export class TagListCheckComponent implements ControlValueAccessor, OnInit {

  @Input() inline = false;
  @Input() tags: Tag[];
  @Input() fieldValue: string[] = [];

  checkedTags = {};

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  constructor(private _renderer: Renderer2) {
  }

  ngOnInit() {
    this.tags.forEach(t => {
      this.checkedTags[t.value] = this.isSelected(t);
    });
  }

  isSelected(tag: Tag): boolean {
    return (this.fieldValue || []).findIndex(val => val === tag.value) >= 0;
  }

  selectTag(tagValue: string) {
    const currentFieldValues = [...this.fieldValue];

    if(!currentFieldValues.includes(tagValue)) {
      currentFieldValues.push(tagValue);
      this.writeValue(currentFieldValues);
    } else {
      this.writeValue(currentFieldValues.filter(tag => tag !== tagValue));
    }
  }

  private clearAll() {
    for (const key of Object.keys(this.checkedTags)) {
      this.checkedTags[key] = false;
    }
  }

  writeValue(tags: string[]): void {
    if (tags === null) {
      this.fieldValue = [];
      this._onChange(null);
      this.clearAll();
      return;
    }
    this.updateCheckedTags(tags);
    this.fieldValue = tags;
    this._onChange(this.fieldValue);
  }

  private updateCheckedTags = (tags: string[] = []) => {
    this.checkedTags = {};
    tags.forEach(tag => {
      this.checkedTags[tag] = true;
    });
  };

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // This is intentional
  }

}
