/**
 * Created by Seppo on 02/11/2017.
 */
import {Component} from "@angular/core";
import {PalveluService} from "../sidebar/palvelu.service";
import {Subscription} from "rxjs/index";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

@Component({
  selector: 'layout',
  template: `
    <screen-loader #loader></screen-loader>

    <div class="main-content" [hidden]="loader.isLoading !== 'LOAD_ENDED'">
      <main-nav></main-nav>
      <div class="container-fluid">
        <div class="row">
          <div *ngIf="showSidebar" class="p-0 col-xl-2">
            <sidebar></sidebar>
          </div>
          <div class="container-fluid content-fix" [ngClass]="{'col-xl-10': showSidebar, 'col-lg-12': !showSidebar}">
            <main class="mainContent" id="content" [tabIndex]="0">
              <message></message>
              <router-outlet></router-outlet>
            </main>
            <touko-footer></touko-footer>
          </div>
        </div>
      </div>

    </div>
  `,
  styleUrls: ['./layout.component.scss']
})
@AutoUnsubscribe
export class LayoutComponent {
  showSidebar = false;
  sidebarSubscription: Subscription;

  constructor(private readonly sidebarService: PalveluService) {
    this.sidebarSubscription = this.sidebarService.showSideBar$.subscribe(show => {
      this.showSidebar = show;
    });
  }
}
