<touko-lomake *ngIf="lomake"
              [formGroup]="lomake"
              [tunnus]="lomakeMetaData.id"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [cancelFn]="cancelLomake"
              (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              [saveFn]="saveLomake">


  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        sivuvirhekasittely [virheGroups]="['perustiedot', 'yhteyshenkilot']"
        sivu="ref0">
    <perustiedot [lomake]="lomake"></perustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"></yhteyshenkilot>

    <fieldset formGroupName="toiminnanAloitus" class="mb-2">
      <legend [attribute]="lomake.get('toiminnanAloitus').field.label"></legend>
      <date [label]="lomake.get('toiminnanAloitus.toiminnanAloitus').field.label" formControlName="toiminnanAloitus"
            htmlIdSuffix="toiminnanAloitus"></date>
      <validation-error [field]="lomake.get('toiminnanAloitus')"></validation-error>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'siemenrekisteri.sivuToiminnanLuonne'" [ohjeet]="lomakeInfo.ohjeet.sivu1"
        [selectable]="isPerustiedotValid"
        sivuvirhekasittely [virheGroups]="['markkinoimasiKylvosiemenet', 'verkkokaupat']"
        sivu="ref1">
    <fieldset formGroupName="markkinoimasiKylvosiemenet">
      <legend [attribute]="'siemenrekisteri.markkinoimasiKylvosiemenet'"></legend>
      <ng-container *ngFor="let kasvilaji of kasvilajit; last as isLast;">
        <touko-checkbox *ngIf="!isLast"
                        [formControl]="kasvilaji.asControl()"
                        [htmlId]="kasvilaji.field.htmlId"
                        [labelAttribute]="kasvilaji.field.label">
        </touko-checkbox>
        <div *ngIf="isLast" class="ml-5 pl-1">
          <touko-textarea *ngIf="isLast && lomake.get('markkinoimasiKylvosiemenet').get('muutKasvit').value"
                          [labelAttribute]="'lomakeYleinen.lisatiedot'"
                          formControlName="muutKasvitLisatieto" htmlId="muutKasvitLisatieto">
          </touko-textarea>
        </div>
        <validation-error [field]="lomake.get('markkinoimasiKylvosiemenet')"></validation-error>
      </ng-container>
    </fieldset>

    <fieldset formGroupName="toiminnanHarjoittaminen" class="mb-2">
      <legend [attribute]="'siemenrekisteri.toiminnanHarjoittaminen'"></legend>
      <touko-yes-no [htmlId]="maahantuonti.field.htmlId" [label]="maahantuonti.field.label"
                    formControlName="maahantuonti" groupName="maahantuonti"></touko-yes-no>
      <touko-yes-no [htmlId]="maastavienti.field.htmlId" [label]="maastavienti.field.label"
                    formControlName="maastavienti" groupName="maastavienti"></touko-yes-no>
      <touko-yes-no [htmlId]="luomu.field.htmlId" [label]="luomu.field.label" formControlName="luomu"
                    groupName="luomu"></touko-yes-no>
    </fieldset>

    <fieldset formGroupName="verkkokaupat" class="mb-2">
      <legend attribute="lomakeYleinen.verkkokaupat"></legend>
      <verkkokaupat-list></verkkokaupat-list>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'siemenrekisteri.sivuToiminnanLaajuus'" [ohjeet]="lomakeInfo.ohjeet.sivu2"
        [selectable]="isPerustiedotValid"
        sivuvirhekasittely [virheGroups]="['toiminnanLaajuus', 'yrityksenToimiala', 'liite']"
        sivu="ref2">
    <fieldset class="mb-2">
      <legend attribute="siemenrekisteri.toiminnanLaajuus"></legend>
      <touko-radio-input *ngFor="let toiminnanLaajuusValinta of (toiminnanLaajuus.field.valueOptions || [])"
                         [labelAttribute]="toiminnanLaajuusValinta.label"
                         [value]="toiminnanLaajuusValinta.value"
                         [radioGroup]="'radio-group'"
                         [htmlId]="toiminnanLaajuusValinta.value"
                         [formControl]="toiminnanLaajuus"></touko-radio-input>
      <validation-error [field]="toiminnanLaajuus"></validation-error>
    </fieldset>

    <fieldset formGroupName="yrityksenToimiala" class="mb-2">
      <legend attribute="siemenrekisteri.toimiala"></legend>
      <p [attribute]="toimiala.field.label"></p>
      <touko-radio-input *ngFor="let toimialaValinta of (toimiala.field.valueOptions || [])"
                         [labelAttribute]="toimialaValinta.label"
                         [value]="toimialaValinta.value"
                         [radioGroup]="'toimiala-radio-group'"
                         [htmlId]="toimialaValinta.value"
                         [formControl]="toimiala"></touko-radio-input>
      <validation-error [field]="toimiala"></validation-error>

    </fieldset>

    <fieldset class="mb-2">
      <legend attribute="lomakeYleinen.liitteet">Liitteet</legend>
      <label id="liite" [attribute]="liite.field.label"></label>
      <liite-list (liiteOut)="onSetLiitteet($event, 'liitteet')"
                  [liitteetArray]="liite"
                  [lomakeId]="lomakeMetaData.id"
                  [htmlId]="liite.field.htmlId"></liite-list>
    </fieldset>

    <fieldset formGroupName="lisatiedot" class="mb-2">
      <legend attribute="siemenrekisteri.lisatiedot"></legend>
      <touko-textarea formControlName="lisatieto"
                      labelAttribute="siemenrekisteri.lisatieto"
                      htmlId="lisatiedot"></touko-textarea>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
    <app-field-group-summary class="summary" [fieldGroup]="lomake"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button id="laheta" class="mt-4 btn btn-primary" type="submit" attribute="teksti.laheta">Lähetä</button>
    </div>
  </sivu>

</touko-lomake>


<pre *devFeature>{{lomake?.getRawValue() | json}}</pre>
