import {Component, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AccountService} from "../account.service";
import {ValvontakohdeService} from "../../valvontakohde/valvontakohde.service";
import {Valvontakohde} from "../../valvontakohde/valvontakohde";
import {Subscription} from "rxjs";
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Teksti} from "../../utils/teksti";
import {Asetukset, Toimiala, ToimialaAsetus} from "../account";
import {MessageService} from "../../message/message.service";
import {AttributeService} from "../../tiira/attribute.service";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {EnvironmentService} from "../../../environments/environment.service";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 20/04/2018.
 */

@Component({
  templateUrl: "account-modal.component.html",
  selector: "account-modal",
  styleUrls: ["../account.scss"]
})
@AutoUnsubscribe
export class AccountModalComponent implements OnInit {

  private env = new EnvironmentService();

  private static pakkaamoteksti: string;

  toimiala: Toimiala;
  toimialaValtuudet: string[];
  modaaliAsetukset = {
    SIEMEN: new ToimialaAsetus(new Teksti("Siemen", "siemenToiminta", "kayttajatili")),
    REHU: new ToimialaAsetus(new Teksti("Rehu", "rehuToiminta", "kayttajatili")),
    LANNOITE: new ToimialaAsetus(new Teksti("Lannoite", "lannoiteToiminta", "kayttajatili")),
    KATE: new ToimialaAsetus(new Teksti("Kasvinterveys", "kasvinterveysToiminta", "kayttajatili")),
    LUOMU: new ToimialaAsetus(new Teksti("Luomu", "luomuToiminta", "kayttajatili")),
    ELAIN_LABORATORIO: new ToimialaAsetus(new Teksti("Eläintautitutkimukset", "elabraToiminta", "kayttajatili"))
  };

  toimialaAsiat: Map<string, string> = new Map<string, string>([
    [Toimiala.SIEMEN, "kylvosiemen-markkinointi"],
    [Toimiala.REHU, "rehuvalvonta"],
    [Toimiala.LANNOITE, "lannoiterekisteri"],
    [Toimiala.KATE, "kasvinterveysrekisteri"],
    [Toimiala.LUOMU, "luomuvalvonta"]
  ]);

  valvontakohteet: Valvontakohde[] = [];
  valvontakohde: Valvontakohde = null;
  isAsiakas = false;

  lomakeSent = false;
  accountForm: FormGroup;
  errPakollinenKentta = new Teksti('Virhe', 'pakollinenKentta', 'lomakeYleinen');
  errValitseToimipaikka = new Teksti('Virhe', 'errValitseToimipaikka', 'kayttajatili');
  errToimipaikkaPakollinen = new Teksti('Virhe', 'errToimipaikkaPakollinen', 'kayttajatili');

  subs: Subscription[] = [];

  constructor(public activeModal: NgbActiveModal,
              private readonly accountService: AccountService,
              private readonly fb: FormBuilder,
              private readonly messageService: MessageService,
              private readonly attributeService: AttributeService,
              private readonly valvontakohdeService: ValvontakohdeService) {

    AccountModalComponent.pakkaamoteksti = this.attributeService.simpleTranslate("kayttajatili", "pakkaamo");

    this.accountForm = this.fb.group({
      toimiala: [null, Validators.required],
      toimipaikka: [{value: null, disabled: true}, Validators.nullValidator],
    });

    this.subs.push(this.accountForm.get('toimiala').valueChanges.subscribe(value => {
      if (value && value !== Toimiala.ELAIN_LABORATORIO) {
        this.changeToimiala(value);
        this.accountForm.get('toimipaikka').enable();
      } else {
        this.accountForm.get('toimipaikka').disable();
      }
    }));

    if (this.accountService.isAsiakas()) {
      this.accountForm.get('toimipaikka').setValidators(this.toimipaikkaValidator);
    }

    this.submit = this.submit.bind(this);
  }

  ngOnInit(): void {
    const account = this.accountService.getCurrentAccount();
    this.toimialaValtuudet = [...account.toimialaValtuudet].sort();

    if (account.toimiala) {
      this.accountForm.get('toimiala').setValue(account.toimiala);
      this.toimiala = account.toimiala;
    }

    if (this.accountService.isAsiakas()) {
      this.isAsiakas = true;

      this.valvontakohdeService.getValvontakohteet().then(valvontakohteet => {
        this.valvontakohteet = valvontakohteet;
        this.mapValvontakohteetToModaaliAsetukset(valvontakohteet);
        if (account.toimiala) {
          this.filterValvontakohteet(account.toimiala);
          this.setIfOnlyValvontakohde();
        }

        if (account.valvontakohde) {
          const valvontakohde = this.valvontakohteet.find(vk => vk.elmoId === account.valvontakohdeElmoId);
          this.selectValvontakohde(valvontakohde, true);
        }
      });
    }
  }

  mapValvontakohteetToModaaliAsetukset(kaikkiValvontakohteet: Valvontakohde[]) {
    this.toimialaValtuudet.forEach(toimiala => {
      this.modaaliAsetukset[toimiala].valvontakohteet = kaikkiValvontakohteet.filter(vk => vk.toimialat.indexOf(toimiala) >= 0);
    });
  }

  submit() {
    this.lomakeSent = true;
    if (this.accountForm.invalid) {
      this.messageService.notify({
        message: new Teksti("Asiointitietojen tallennus epäonnistui. Syötä kaikki tiedot.", "tallennusEpaonnistui", "kayttajatili"),
        type: "warning"
      });
      return Promise.resolve();
    }

    const asetukset = this.getFormAsAsetukset();
    return this.accountService.setAccountAsetukset(asetukset)
        .then(() => this.activeModal.close(new AccountModalCloseEvent(asetukset)));
  }

  private getFormAsAsetukset(): Asetukset {
    const toimiala = this.accountForm.get('toimiala').value;
    let toimipaikka = null;
    if (this.accountService.isAsiakas() && toimiala !== Toimiala.ELAIN_LABORATORIO) {
      toimipaikka = this.valvontakohde?.elmoId || null;
    }
    return new Asetukset(toimiala, toimipaikka);
  }


  changeToimiala(toimiala) {
    this.toimiala = toimiala;

    const tallennettuValvontakohde = this.modaaliAsetukset[this.toimiala].tallennettuValvontakohde;
    this.filterValvontakohteet(this.toimiala);

    if (tallennettuValvontakohde) {
      this.selectValvontakohde(tallennettuValvontakohde);
    } else {
      this.selectValvontakohde(null);
    }

    this.setIfOnlyValvontakohde();
  }

  selectValvontakohde(valvontakohde: Valvontakohde, saveValvontakohde = true) {
    if (this.toimiala && saveValvontakohde) {
      this.modaaliAsetukset[this.toimiala].tallennettuValvontakohde = valvontakohde;
    }
    this.valvontakohde = valvontakohde;
    this.valvontakohdeService.selectValvontakohde(this.valvontakohde, this.toimiala);
    this.accountForm.get('toimipaikka').setValue(this.valvontakohde);
  }

  naytaValvontakohdeValinta(): boolean {
    return this.accountService.isAsiakas() &&
        this.accountForm.get('toimiala').value &&
        this.accountForm.get('toimiala').value !== Toimiala.ELAIN_LABORATORIO;
  }

  naytaIlmoitaToimipaikka(): boolean {
    return this.accountService.isAsiakas() && this.toimialaAsiat.has(this.toimiala) && !(this.accountService.isHenkiloToimija() && this.toimiala === Toimiala.LUOMU);
  }

  /**
   * Päivittää valvontakohdelistauksen toimialan mukaan.
   *
   * @param toimiala - SIEMEN, REHU, LANNOITE, LUOMU tai ELAINLABORATORIO
   */
  filterValvontakohteet(toimiala: Toimiala) {
    if (!toimiala) {
      return;
    }
    this.valvontakohteet = this.modaaliAsetukset[toimiala].valvontakohteet;
  }

  /**
   * Valitsee lomakkeelle valvontakohteen, jos niitä on vain yksi.
   */
  setIfOnlyValvontakohde() {
    if (this.valvontakohteet.length === 1) {
      this.selectValvontakohde(this.valvontakohteet[0]);
    }
  }

  isKenttaVirhe(kenttaId: string) {
    const kentta = this.accountForm.get(kenttaId);
    let virhe = null;
    if (this.lomakeSent || (kentta.dirty || kentta.touched)) {
      virhe = kentta.errors;
    }
    return virhe !== null;
  }

  toimipaikkaValidator: ValidatorFn = (): ValidationErrors | null => {
    if (this.accountForm.get('toimiala').value === Toimiala.ELAIN_LABORATORIO) {
      return null;
    }

    return !this.valvontakohde ? {valitseValvontakohde: true} : null;
  };

  createUusiIlmoitusEvent() {
    const asia = this.toimialaAsiat.get(this.toimiala) || null;
    const clientComponentVersion = LomakeEndpointUtil.shouldUseV2ClientComponent(asia, "uusi") ? 'v2' : 'v1';
    const url = `/asiointi/lomake/uusi/${asia}/${clientComponentVersion}`;

    if (asia) {
      const asetukset = new Asetukset(this.accountForm.get('toimiala').value);
      this.selectValvontakohde(null, false);
      this.accountService.setAccountAsetukset(asetukset)
          .then(() => this.activeModal.close(new AccountModalCloseEvent(asetukset, url)));
    }
  }

}

export class AccountModalCloseEvent extends Asetukset {
  asiaRedirectURL: string;

  constructor(asetukset: Asetukset, asiaRedirectURL: string = null) {
    super(asetukset?.toimiala, asetukset?.toimipaikka);
    this.asiaRedirectURL = asiaRedirectURL;
  }
}
