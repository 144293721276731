import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";

@Directive({ selector: "[toukobutton]" })

export class ButtonDirective implements OnInit {
  @Input() buttonStyle = "primary";

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, "btn");
    this.renderer.addClass(this.elementRef.nativeElement, `btn-${this.buttonStyle}`);
  }
}
