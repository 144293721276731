import {Component, Input, OnInit} from '@angular/core';
import {LomakeResponse} from "../touko-lomake-utils";
import {ArkistodokumentitService} from "./arkistodokumentit-service";
import {Asiakirja, Asiakirjatyyppi, Toimenpidetyyppi} from "./asiakirja";
import {LomakeService} from "../touko-lomake.service";

@Component({
  selector: 'arkistodokumentit',
  templateUrl: './arkistodokumentit.component.html',
  styleUrls: ['./arkistodokumentit.component.scss']
})
export class ArkistodokumentitComponent implements OnInit {

  @Input() lomake: LomakeResponse;

  elmoonLahetetytHakemukset: Asiakirja[];

  constructor(private arkistoService: ArkistodokumentitService,
              private lomakeService: LomakeService) {
  }

  ngOnInit(): void {
    if(this.isArkistoHakemus() && this.isElmoonLahetettyjaHakemuksia()) {
      this.readElmoonLahetetytHakemukset();
    }
  }

  isArkistoHakemus() {
    return this.lomake.arkistoAsiatunnus != null;
  }

  isElmoonLahetettyjaHakemuksia() {
    return this.elmoonLahetetytHakemukset != null && this.elmoonLahetetytHakemukset.length > 0;
  }

  /**
   * Avaa liitteen uuteen ikkunaan. HUOM! IE:llä tiedosto ladataan.
   *
   * @param item - Liitetiedoston sisältö
   * @returns
   */
  avaaAlkuperainenHakemusArkistossa() {
    if(this.isArkistoHakemus()) {
      this.readAlkuperainenHakemusArkistossa();
    }
  }

  avaaAsiakirja(asiak: Asiakirja) {
    this.avaaAsiakirjaTunnuksella(asiak.tunnus);
  }

  avaaAsiakirjaTunnuksella(asiakirjaTunnus: number) {
    return this.arkistoService.downloadAsiakirja(asiakirjaTunnus)
      .then(blob => {
        if (this.isIE()) {
          const nav = (window.navigator as any);
          nav.msSaveOrOpenBlob(blob, 'alkuperainen_hakemus.pdf'); // TODO: tiedostonimi?
        } else {
          const file = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
          window.open(file, "_blank");
        }
      });
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }

  private readAlkuperainenHakemusArkistossa() {
    this.arkistoService.readAsiakirjatunnus(this.lomake.arkistoAsiatunnus,
      Toimenpidetyypit[this.lomake.asia][0], Asiakirjatyypit[this.lomake.asia]).then(response => {
        if(response) {
          this.avaaAsiakirjaTunnuksella(response);
        }
      });
  }

  private readElmoonLahetetytHakemukset() {
    this.arkistoService.readAsiakirjat(this.lomake.arkistoAsiatunnus,
      Toimenpidetyypit[this.lomake.asia][1], Asiakirjatyypit[this.lomake.asia]).then(response => {
      if(response) {
        this.elmoonLahetetytHakemukset = response;
      }
    });
  }

}

const Asiakirjatyypit = {
  luomupoikkeus9c : Asiakirjatyyppi.HAKEMUS_POIKKEUSLUVASTA,
  luomuvalvonta: Asiakirjatyyppi.LUOMUALOITUSILMOITUS
};

/*
 * Ensin alkuperäisen hakemuksen toimenpidetyyppi, sitten Elmoon lähetettyjen hakemusten toimenpidetyyppi.
 */
const Toimenpidetyypit = {
  luomupoikkeus9c : [Toimenpidetyyppi.HAKEMUKSEN_JATTAMINEN, Toimenpidetyyppi.SIIRTO_KASITTELYYN],
  luomuvalvonta : [Toimenpidetyyppi.LUOMUILMOITUKSEN_JATTAMINEN, Toimenpidetyyppi.SIIRTO_KASITTELYYN]
};
