import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {
  CompareAction,
  CompareType,
  InitValueType,
  MatcherModel,
  QuestionControl,
  QuestionGroup,
  QuestionInitValue,
  Topic
} from "ee-lahete";
import {FormGroup} from "@angular/forms";
import {ErrorObj, ValidatorsBase} from "../validators/validators-base";
import {GroupBuilder} from "./group-build-options";
import {Elainlaji} from "../lahete-koodisto";

export class ElainlajiGroup extends AbstractLaheteGroup {

  private elainlajiCount: { [s: string]: number } = {};
  private readonly defaults: Map<string, string> = new Map([
    ['PA1NA', 'SPAN00304'],
    ['PA1SI', 'SPAN00435'],
    ['PA1HE', 'SPAN00072']
  ]);

  private readonly setElainlajiCountByElainlajiryhma = (el: Elainlaji) => {
    if (el.elainlajiryhmakoodit) {
      el.elainlajiryhmakoodit.forEach(elRyhmakoodi => {
        if (this.elainlajiCount.hasOwnProperty(elRyhmakoodi)) {
          this.elainlajiCount[elRyhmakoodi] += 1;
        } else {
          this.elainlajiCount[elRyhmakoodi] = 1;
        }
      });
    }
  }

  private readonly setDefaultOrResetFn = (compareVal, newVal) => {
    if (this.defaults.has(newVal)) {
      return compareVal === this.defaults.get(newVal) ?
        CompareAction.SELECT_ONLY_RADIO_OPTION :
        CompareAction.NONE;
    }
    return CompareAction.RESET;
  }

  private readonly equals0CompareFn = (ignore, newVal) => !this.elainlajiCount.hasOwnProperty(newVal) ? CompareAction.FILTER : CompareAction.NONE;
  private readonly greaterThan0CompareFn = (ignore, newVal) => this.elainlajiCount[newVal] > 0 ? CompareAction.FILTER : CompareAction.NONE;
  private readonly greaterThan10CompareFn = (ignore, newVal) => this.elainlajiCount[newVal] && this.elainlajiCount[newVal] >= 10 ? CompareAction.FILTER : CompareAction.NONE;

  getGroup(controlType?, builder = new GroupBuilder({controlType: 'radio'})): QuestionGroup {

    const controls: QuestionControl[] = [];

    this.elainlajiCount = {};

    const elainlajiFilterControl = new QuestionControl({
      controlType: 'text',
      value: '',
      initialValue: '',
      key: 'elainlaji-filter',
      htmlId: 'elainlaji-filter',
      placeholder: this.getLabel('elainlajiFilter'),
      options: {hideInSummary: () => true},
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, this.greaterThan10CompareFn, ElainlajiGroup.filterActionFn),
      },
      modifiers: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, ElainlajiGroup.anyObjectValueTrueResetFn, ElainlajiGroup.modifierActionFn)
      }
    });

    const elainlajiValinnatControls = this.laheteKoodisto.koodisto.elainlajit
      .filter(el => el.kuvausFi)
      .map(el => {
        this.setElainlajiCountByElainlajiryhma(el);

        return new QuestionControl({
          controlType: builder.controlType,
          value: el.id,
          initialValue: null,
          key: 'elainlaji',
          htmlId: `elainlaji-${el.id}`,
          label: this.getLocalizedOf(el, 'kuvaus'),
          filters: {
            'elainlajit.elainlajiryhma': new MatcherModel(el.elainlajiryhmakoodit, ElainlajiGroup.containsFilterFn, ElainlajiGroup.filterActionFn),
            'elainlajiValinta.elainlaji-filter': new MatcherModel(
              this.getLocalizedOf(el, 'kuvaus'),
              ElainlajiGroup.searchFilterFn,
              ElainlajiGroup.filterActionFn,
              CompareType.EVERY,
              CompareAction.FILTER
            )
          },
          options: {hideInSummary: (controlValue: any) => !controlValue},
          modifiers: {
            'elainlajit.elainlajiryhma': new MatcherModel(el.id, this.setDefaultOrResetFn, ElainlajiGroup.modifierActionFn, CompareType.SOME)
          }
        });
      });

    const muuValintaControl = new QuestionControl({
      controlType: 'radio',
      value: 'MUU1',
      key: 'elainlaji',
      htmlId: 'elainlaji-muu-radio',
      label: this.getLabel('muu'),
      options: {hideInSummary: () => true},
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, this.greaterThan0CompareFn, ElainlajiGroup.filterActionFn),
      },
      modifiers: {
        'elainlajiValinta.elainlaji-muu-text': new MatcherModel(
          null,
          (prevVal, newVal) => newVal && newVal.length > 0 ? CompareAction.SELECT_ONLY_RADIO_OPTION : CompareAction.NONE,
          ElainlajiGroup.modifierActionFn
        )
      }
    });
    elainlajiValinnatControls.push(muuValintaControl);

    const muuValintaTextControl = new QuestionControl({
      controlType: 'text',
      value: null,
      key: 'elainlaji-muu-text',
      htmlId: 'elainlaji-muu-text',
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, this.greaterThan0CompareFn, ElainlajiGroup.filterActionFn),
      },
      disablers: {
        'elainlajiValinta.elainlaji': new MatcherModel('MUU1', ElainlajiGroup.notEqualsDisablerFn, ElainlajiGroup.disablerActionFn)
      },
      modifiers: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, ElainlajiGroup.anyObjectValueTrueResetFn, ElainlajiGroup.modifierActionFn, CompareType.SOME),
        'elainlajiValinta.elainlaji': new MatcherModel('MUU1', ElainlajiGroup.notEqualsResetFn, ElainlajiGroup.modifierActionFn)
      },
      options: {hideInSummary: (controlValue: any) => !controlValue || controlValue.length === 0}
    });

    const muuTextControl = new QuestionControl({
      controlType: 'text',
      value: 'MUU0',
      key: 'elainlaji-text',
      htmlId: 'elainlaji-text',
      label: this.getLabel('elainlaji'),
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, this.equals0CompareFn, ElainlajiGroup.filterActionFn),
      },
      modifiers: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, ElainlajiGroup.anyObjectValueTrueResetFn, ElainlajiGroup.modifierActionFn, CompareType.SOME)
      },
      options: {hideInSummary: (controlValue: any) => !controlValue || controlValue.length === 0}
    });

    controls.push(elainlajiFilterControl);
    controls.push(...elainlajiValinnatControls);
    controls.push(muuValintaTextControl);
    controls.push(muuTextControl);

    const group = new QuestionGroup({
      key: 'elainlajiValinta',
      label: this.getLabel('elainlaji'),
      controls: controls,
      validators: [ElainlajiGroupValidators.isElainlajiValid],
      errorMessages: {
        [ElainlajiGroupValidators.ELAINLAJI]: this.getLabel('Eläinlaji on pakollinen')
      },
      filters: {
        'elainlajit.elainlajiryhma': new MatcherModel(null, ElainlajiGroup.notEqualsFilterFn, ElainlajiGroup.filterActionFn)
      },
      publishers: [
        new Topic('elainlajiChange', 'elainlaji', true)
      ]
    });

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.elainlajiValinta), InitValueType.PATCH));
    }

    return group;
  }
}

class ElainlajiGroupValidators extends ValidatorsBase {

  public static ELAINLAJI = 'elainlaji';

  public static isElainlajiValid(group: FormGroup): ErrorObj {

    const elainlajiValue = group.get('elainlaji').value;
    if (elainlajiValue !== null && elainlajiValue !== 'MUU1') {
      return null;
    }

    const elainlajiText = group.get('elainlaji-text').value || group.get('elainlaji-muu-text').value || "";
    return ElainlajiGroupValidators.validate(Boolean(elainlajiText.trim()), ElainlajiGroupValidators.ELAINLAJI);
  }

}
