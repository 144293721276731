import {Component, Input, OnInit} from "@angular/core";
import {FieldArray, FieldGroup, SummaryItem} from "ngx-fielding";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'kasvituotteet-summary',
  template: `
    <h3 attribute="luomu.tilanTuottamatKasvituotteet"></h3>

    <div class="row mb-2">
      <div attribute="luomu.luonnonmukainenLabel" class="col-2 offset-4 font-weight-semibold"></div>
      <div attribute="luomu.tavanomainenLabel" class="col-2 font-weight-semibold"></div>
    </div>
    <div class="row">
      <div class="col-4">
        <div *ngFor="let tuoteLabel of kasvituotelabels" [id]="tuoteLabel" [attribute]="'luomu.' + tuoteLabel + 'Label'"
             class="mb-2"></div>
        <div *ngFor="let tuoteLabel of muuKasvituotelabels; index as i" [id]="'muuKasvituote-' + i"
             class="mb-2">{{tuoteLabel}}</div>
      </div>
      <div class="col-4">
        <div *ngFor="let kasvituote of kasvituotteet; index as i" class="row mb-2">
          <div class="col-6">
            <span *ngIf="kasvituote.get('luonnonmukainen').value" [id]="'luonnonmukainen-' + i" class="fa fa-check"
                  aria-hidden="true"></span>
          </div>
          <div class="col-6">
            <span *ngIf="kasvituote.get('tavanomainen').value" [id]="'tavanomainen' + i" class="fa fa-check"
                  aria-hidden="true"></span>
          </div>
        </div>
      </div>
    </div>
    <hr>
  `
})

export class KasvituotteetSummary implements OnInit {
  @Input() item: SummaryItem<any>;
  kasvituotelabels: string[] = [];
  kasvituotteet: AbstractControl[] = [];
  muuKasvituotelabels: string[] = [];

  ngOnInit() {
    Object.entries(this.item.answer.asGroup().controlFields).forEach(tuote => {
      const key = tuote[0];
      const value = tuote[1] as FieldGroup;

      if (key !== "muutKasvituotteet" && (value.get('luonnonmukainen').value || value.get('tavanomainen').value)) {
        this.kasvituotteet.push(value);
        this.kasvituotelabels.push(key);
      }
      if (key === "muutKasvituotteet") {
        const muutKasvituotteet = tuote[1] as FieldArray;
        muutKasvituotteet.controlFields.forEach(muuKasvituote => {
          if (muuKasvituote.get('nimi').value && (muuKasvituote.get('luonnonmukainen').value || muuKasvituote.get('tavanomainen').value)) {
            this.muuKasvituotelabels.push(muuKasvituote.get('nimi').value);
            this.kasvituotteet.push(muuKasvituote);
          }
        })
      }
    })
  }
}
