<div class="table-responsive">
  <table class="table table-striped table-sm raportti" id="raportti-table">
    <caption class="d-none" attribute="raportit.otsikko"></caption>
    <thead>
    <tr>
      <th id="data-order-num">#</th>
      <th class="inline-parent" *ngFor="let col of columnOrder" scope="col" (click)="jarjesta(col.key)">
        <span class="inline-child" [attribute]="col.title.getFullKey()" [sort-arrow]="getJarjestys(col.key)">{{col.title.label}}</span>
      </th>
    </tr>
    </thead>
    <tbody aria-live="polite" id="raportti-table-body">
    <tr *ngFor="let raportti of raportit; index as i" [ngClass]="{'table-success': !raportti.avattu}">
      <td class="align-top"><strong>{{(i+1) + raporttiLkmSivulla * (sivu-1)}}</strong></td>

      <td *ngFor="let col of columnOrder" class="align-top">
        <ng-container [ngSwitch]="col.type">
          <ng-container *ngSwitchCase="raporttiSarakeEnum.DATE">
                <span [ngClass]="col.styleFn(raportti[col.key])">
                  {{raportti[col.key] | date:'d.M.yyyy'}}
                </span>
          </ng-container>
          <ng-container *ngSwitchCase="raporttiSarakeEnum.FILE_LIST">
            <div *ngFor="let file of raportti[col.key]"
                 class="inline-parent"
                 [ngClass]="{'mb-1': raportti[col.key] != null && raportti[col.key].length > 1}">

              <div class="inline-child">
                <div *ngIf="this.accountService.isViranomainen(); then showViranomainen else showAsiakas"></div>
                <ng-template #showViranomainen>
                  <div class="form-check">
                    <input class="form-check-input" [id]="'piilota-' + file.tiedostoTunnus" type="checkbox" [checked]="!file.piilotettu" (change)="setRaporttiLiitePiilotettu(file, !$event.target.checked)">
                    <label class="form-check-label" [htmlFor]="'piilota-' + file.tiedostoTunnus">{{file.tiedostoNimi}}</label>
                  </div>
                </ng-template>
                <ng-template #showAsiakas>
                  <div class="form-check-inline">
                    <input (change)="selectLiite(file, $event.target.checked)"
                           class="form-check-input" type="checkbox" [id]="file.tiedostoTunnus">
                    <label class="form-check-label" [htmlFor]="file.tiedostoTunnus">{{file.tiedostoNimi}}</label>
                  </div>
                  <button [disabled]="raportitToDownload.size > 0" class="mr-1"
                          [ngClass]="col.styleFn(raportti[col.key])"
                          (click)="openLiite(file)">
                    <em class="fa fa-eye"></em>
                  </button>
                  <button [disabled]="raportitToDownload.size > 0"
                          [ngClass]="col.styleFn(raportti[col.key])" [clickAsync]="getLiite"
                          [params]="[file]">
                    <em class="fa fa-download"></em>
                  </button>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="raporttiSarakeEnum.TYPE">
                <span class="small" [ngClass]="col.styleFn(raportti[col.key])">
                  {{raportti.tapahtumatyyppiNimi | uppercase }}
                </span>
          </ng-container>
          <ng-container *ngSwitchCase="raporttiSarakeEnum.NTILA">
            <naytteenottotila *ngIf="raportti[col.key]" [raportti]="raportti"></naytteenottotila>
          </ng-container>
          <ng-container *ngSwitchDefault>
                <span [ngClass]="col.styleFn(raportti[col.key])">
                  {{raportti[col.key]}}
                </span>
          </ng-container>
        </ng-container>
      </td>

    </tr>
    </tbody>
  </table>
</div>

<button *ngIf="!this.isViranomainen" (click)="downloadLiiteZip()"
        [disabled]="raportitToDownload.size === 0"
        id="lataa-raportti-zip"
        class="btn btn-primary">
  <span attribute="raportit.lataaValitutAsiakirjat">Lataa valitut asiakirjat</span>
  <span> ({{raportitToDownload.size}})</span>
</button>
<!-- hidden download link -->
<a class="invisible" #downloadlink></a>
