<div *ngIf="show" class="fullscreen-loader" id="fullscreen-loader" [ngClass]="{'loading': isLoading === LoadStatus.IS_LOADING}">
  <div class="fullscreen-loader-content loader-spinner">
    <!--Spinner animation divs -->
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <p class="fullscreen-loader-content loader-message">
    <span *ngIf="!isMessageTeksti(loadingMessage)">{{ loadingMessage }}</span>
    <span *ngIf="isMessageTeksti(loadingMessage)" [attribute]="loadingMessage.getFullKey()"></span>
  </p>
</div>

<div *ngIf="isLoading === LoadStatus.TIMEOUT_ERROR" style="padding: 20px;">
  <h2 [attribute]="'etusivu.virhePalvelussaTimeout'">Virhe palvelussa, ole hyvä ja kokeile päivittää sivu uudelleen.</h2>
</div>
