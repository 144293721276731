import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";
import {AccountService} from "../../../account/account.service";
import {KasvinterveysrekisteriV2Data} from "./kasvinterveysrekisteri-v2-data";
import {LomakeInfo} from "../common/lomake-info";
import {PerustiedotSkipValidationOptions} from "../common/lomake-fields/perustiedot";
import {
  Allekirjoitus,
  LOMAKE_KUVAUKSET,
  LomakeHelper,
  LomakeKasittely,
  LomakeTila,
  YhteyshenkiloValue
} from "../../touko-lomake-utils";
import {Teksti} from "../../../utils/teksti";
import {AbstractField, FieldControl} from "ngx-fielding";
import {ModalSimpleComponent} from "../../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../../modaalit/modal-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";
import {RekisteritCardComponent} from "../../kate-rekisteri/rekisterit-card.component";
import {TarkastusPDFService} from "../../tarkastus/tarkastus-pdf.service";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";

@Component({
  selector: 'kasvinterveysrekisteri-v2',
  templateUrl: 'kasvinterveysrekisteri-v2.component.html',
  providers: [KasvinterveysrekisteriV2Data, ProcessLomakeService],
})
@AutoUnsubscribe
export class KasvinterveysrekisteriV2Component extends LomakeV2BaseDirective implements OnInit {

  @ViewChild("rekisteritCard") rekisteritCard: RekisteritCardComponent;
  @ViewChild("lomakeYhteenvetoDiv") lomakeYhteenvetoDiv: ElementRef;

  tarkastusService: TarkastusPDFService;
  liitetiedostoService: LiitetiedostoPalvelinService;
  kuvaukset = LOMAKE_KUVAUKSET;
  allekirjoitus: Allekirjoitus = new Allekirjoitus();

  private readonly toimintaMap = {
    toimAlaPuutarhaTarha: ["tuoPuuvart", "tuoMarjaVarm", "tuoMarjaCAC"],
    toimAlaPuutarhaMyymala: ["myyTaimimyymala"],
    toimAlaPuutarhaKasvi: ["tuoKasvi", "tuoVihannesTaimi", "tuoVihannesYksityiskaytto", "tuoVihannesValmis"],
    toimAlaPuutarhaTukku: ["myyTaimiValitysKasvi", "myyTaimiValitysAvo", "myyTaimiValitysSiemen", "myyTaimiValitysKasvipassi"],
    toimAlaPuutarhaViherrak: ["myyViherrakentaminen"],
    toimAlaPuutarhaKasvitiet: ["kasvitietPuutarha"],
    toimAlaPerunaViljely: ["tuoPerunaViljely"],
    toimAlaPuuISPM: ["tuoISPM15Kasittely", "tuoISPM15Valmistus"],
    toimAlaPuuToimitus: ["myyPuutavaraKasvipassi"]
  };
  private readonly toiminnatWithMainCheckbox = ["tuoPuuvart", "tuoMarjaVarm", "tuoMarjaCAC", "tuoVihannesTaimi", "tuoVihannesValmis"];

  constructor(private readonly modalService: NgbModal,
              processLomakeService: ProcessLomakeService,
              data: KasvinterveysrekisteriV2Data,
              tarkastusService: TarkastusPDFService,
              liitetiedostoService: LiitetiedostoPalvelinService,
              accountService: AccountService) {
    super(processLomakeService, data, accountService);
    this.tarkastusService = tarkastusService;
    this.liitetiedostoService = liitetiedostoService;
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  get toiminnat(): AbstractField[] {
    return Object.values(this.lomake.get("toiminta").asGroup().controlFields);
  }

  get tuoPuuvart(): AbstractField[] {
    return Object.values(this.lomake.get("tuoPuuvart").asGroup().controlFields)
  }

  get tuoMarjaVarm(): AbstractField[] {
    return Object.values(this.lomake.get("tuoMarjaVarm").asGroup().controlFields)
  }

  get tuoMarjaCAC(): AbstractField[] {
    return Object.values(this.lomake.get("tuoMarjaCAC").asGroup().controlFields)
  }

  get tuoKasvi(): AbstractField[] {
    return Object.values(this.lomake.get("tuoKasvi").asGroup().controlFields)
  }

  get tuoVihannesTaimi(): AbstractField[] {
    return Object.values(this.lomake.get("tuoVihannesTaimi").asGroup().controlFields)
  }

  get tuoVihannesYksityiskaytto(): AbstractField {
    return Object.values(this.lomake.get("tuoVihannesYksityiskaytto").asGroup().controlFields)[0]
  }

  get tuoVihannesValmis(): AbstractField[] {
    return Object.values(this.lomake.get("tuoVihannesValmis").asGroup().controlFields)
  }

  get tuoPerunaViljely(): AbstractField[] {
    return Object.values(this.lomake.get("tuoPerunaViljely").asGroup().controlFields)
  }

  get tuoISPM15Kasittely(): AbstractField {
    return this.lomake.get("tuoISPM15Kasittely.tuoISPM15Kasittely")
  }

  get tuoISPM15Valmistus(): AbstractField {
    return this.lomake.get("tuoISPM15Valmistus.tuoISPM15Valmistus")
  }

  get myyTaimimyymala(): AbstractField[] {
    return Object.values(this.lomake.get("myyTaimimyymala").asGroup().controlFields)
  }

  get myyTaimiValitysKasvi(): AbstractField[] {
    return Object.values(this.lomake.get("myyTaimiValitysKasvi").asGroup().controlFields)
  }

  get myyTaimiValitysAvo(): AbstractField[] {
    return Object.values(this.lomake.get("myyTaimiValitysAvo").asGroup().controlFields)
  }

  get myyTaimiValitysSiemen(): AbstractField[] {
    return Object.values(this.lomake.get("myyTaimiValitysSiemen").asGroup().controlFields)
  }

  get myyTaimiValitysKasvipassi(): AbstractField {
    return Object.values(this.lomake.get("myyTaimiValitysKasvipassi").asGroup().controlFields)[0]
  }

  get myyViherrakentaminen(): AbstractField {
    return Object.values(this.lomake.get("myyViherrakentaminen").asGroup().controlFields)[0]
  }

  get myyEtamyynti(): AbstractField[] {
    return Object.values(this.lomake.get("myyEtamyynti").asGroup().controlFields)
  }

  get verkkokaupat(): AbstractField[] {
    return Object.values(this.lomake.get("verkkokaupat").asGroup().controlFields)
  }

  get myyPuutavaraKasvipassi(): AbstractField {
    return Object.values(this.lomake.get("myyPuutavaraKasvipassi").asGroup().controlFields)[0]
  }

  get tuontiJaVienti(): AbstractField[] {
    return Object.values(this.lomake.get("tuontiJaVienti").asGroup().controlFields)
  }

  get kasvitietPuutarha(): AbstractField {
    return Object.values(this.lomake.get("kasvitietPuutarha").asGroup().controlFields)[0]
  }

  get omavalvonta(): AbstractField {
    return Object.values(this.lomake.get("omavalvonta").asGroup().controlFields)[0]
  }

  static containsTrue(object: any): boolean {
    for (const key in object) {
      const value = object[key];
      if (value === true || (value && typeof value === "object" && this.containsTrue(value))) {
        return true;
      }
    }
    return false;
  }

  checkSomeToimintaValuesNotNull(toiminnat: Array<string>): boolean {
    return toiminnat.some(toiminta =>
        Object.values(this.lomake.get(toiminta).asGroup().controlFields)
            .some((control: FieldControl) => KasvinterveysrekisteriV2Component.containsTrue(control.value)));
  }

  addLomakeBehaviour() {
    Object.values(this.lomake.get('toiminta').asGroup().controlFields).forEach((fieldControl: FieldControl) => fieldControl.valueChanges.subscribe((isToggled: boolean) => {
      if (!isToggled) {
        const toiminnatHasValues = this.checkSomeToimintaValuesNotNull(this.toimintaMap[fieldControl.field.label.split('.')[1]]);
        if (toiminnatHasValues) {
          this.openToimintaModal(fieldControl);
        }
      }
    }));
    this.toiminnatWithMainCheckbox.forEach(toimintaKey => {
      const toiminta = this.lomake?.get(toimintaKey);
      if (KasvinterveysrekisteriV2Component.containsTrue(toiminta.value) && toiminta.value[toimintaKey] == null) {
        toiminta.asGroup().controlFields[toimintaKey].setValue(true);
      }
    })
  }

  private openToimintaModal(control: AbstractField) {
    const modalRef = this.modalService.open(ModalSimpleComponent);

    const okFn = () => new Promise((val) => {
      this.toimintaMap[control.field.label.split('.')[1]].forEach(toiminta => {
        this.lomake.get(toiminta).reset();
      })
      modalRef.close("Ok");
      return val;
    });

    const modalKuvaus = !control.field.label.includes("toimAlaPuutarhaKasvitiet") ?
        "modalKuvaus" :
        "modalKuvausKasvitiet";

    modalRef.componentInstance.modalTiedot = new ModalData(
        new Teksti("Toiminnan poistaminen vaikuttaa lomakkeen valintoihin", "modalOtsikko", this.lomakeInfo.asia),
        new Teksti("Valitse tyhjennä, jos haluat poistaa valinnat", modalKuvaus, this.lomakeInfo.asia),
        new Teksti("Tyhjennä", "modalTyhjenna", this.lomakeInfo.asia),
        okFn,
        "btn-danger"
    );
  }

  initLomakeBehaviour() {
    this.lomake.get('myyTaimiValitysSiemen').valueChanges.subscribe(value => {
      if (value.myyTaimiValitysSiemenKoriste || value.myyTaimiValitysSiemenTomaatti) {
        this.lomake.get('myyTaimiValitysKasvipassi.myyTaimiValitysKasvipassi').enable()
      } else {
        this.lomake.get('myyTaimiValitysKasvipassi.myyTaimiValitysKasvipassi').disable()
      }
    })
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new KasvinterveysrekisteriV2Info(accountType, toimintotyyppi);
  }

  /**
   * Lähettää lomakkeen. YLikirjoitettu base-luokasta jotta kasvinterveyden
   * puolella yhteenveto laitetaan mukaan jo asiakkaan lähettäessä lomaketta.
   * Lomake tallennetaan aluksi jotta sen id saadaan yhteenvedolle talteen.
   */
  submitLomake(): Promise<void> {
    let prepareFn = () => Promise.resolve();
    if (this.lomake.valid) {
      prepareFn = this.rekisteritCard.hasRequiredRekisterit ?
          () => this.handleSaveAndSendYhteenveto() :
          () => Promise.resolve();
    }

    return prepareFn()
        .then(() => super.submitLomake());

  }

  /**
   * Lähettää yhteenvedon palvelimelle ja lisää sen lomakkeen liitetiedostoihin.
   */
  protected sendYhteenvetoPdf(): Promise<void> {
    // Lomake lähtee ilman viranomaistarkistusta, halutaan näyttää 'toimitettu' teksti yhteenvedon pdf:ssä vaikka tila
    // kannassa hyväksytty.
    const kasittely = new LomakeKasittely(this.lomakeMetaData.id, LomakeTila.TOIMITETTU, null, null, null, this.lomake.value['asiakasid']);
    const title = this.kuvaukset[this.lomakeInfo.asia][this.lomakeInfo.toimintotyyppi.toUpperCase()].label;
    const contentHtml = this.lomakeYhteenvetoDiv.nativeElement;

    return this.tarkastusService.createYhteenvetoPdf(kasittely, this.allekirjoitus, title, contentHtml)
        .then(pdf => this.liitetiedostoService.sendLiitetiedosto(pdf, 'YHTEENVETO', this.lomakeMetaData.id))
        .then(response => {
          this.liitetiedostot['yhteenveto'] = [response];
        });
  }

  private handleSaveAndSendYhteenveto(): Promise<void> {
    return this.lomakeMetaData.id === 0 ?
        this.saveLomake().then(() => this.sendYhteenvetoPdf()) :
        this.sendYhteenvetoPdf();
  }

}

class KasvinterveysrekisteriV2Info implements LomakeInfo {
  asia: string;
  skipPerustiedotValidation = PerustiedotSkipValidationOptions.skipLaskutustiedot();
  serverBaseUrl: string;
  defaultRooli = YhteyshenkiloValue.KATE_VASTAAVA.value;
  lomakeTitle: Teksti = new Teksti(
      "Kasvinterveydenalan toimintaa koskeva ilmoitus",
      "otsikko",
      "kasvinterveysrekisteri");
  ohjeet: { [p: string]: string[] } = {};
  toimintotyyppi: string;
  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly clientComponentVersion = 'v2'

  constructor(kayttajaRooli: "as" | "vk", toimintotyyppi) {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.KATE_VASTAAVA];
    this.asia = "kasvinterveysrekisteri";
    this.toimintotyyppi = toimintotyyppi;

    const prefix = 'kasvinterveysrekisteri.ohje';
    const suffix = this.toimintotyyppi === "muutos" ? "_muutos" : "_uusi"
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1_1'], suffix);
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2_1']);
    this.ohjeet.sivu2 = [prefix + '3_1_toimAlaPuutarhaTarha', prefix + '3_2_toimAlaPuutarhaKasvi', prefix + '3_4_toimAlaPerunaViljely', prefix + '3_5_toimAlaPuuISPM'];
    this.ohjeet.sivu3 = [prefix + '4_1_toimAlaPuutarhaMyymala', prefix + '4_2_toimAlaPuutarhaTukku', prefix + '4_3_toimAlaPuutarhaViherrak', prefix + '4_4_etamyynti', prefix + '4_5_toimAlaPuuToimitus'];
    this.ohjeet.sivu4 = [prefix + '5_2_toimAlaPuutarhaKasvitiet'];
    this.ohjeet.sivu5 = [prefix + '3_3_omavalvonta']

    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/ilmoitukset/kasvinterveysrekisteri/${toimintotyyppi}`;
  }
}
