/**
 * Created by Seppo on 22/05/2018.
 */
import {BrowserModule} from "@angular/platform-browser";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgModule} from "@angular/core";
import {AttributeModule} from "../tiira/attribute.module";
import {KauppaeraRaporttiComponent} from "./raportit-kauppaera/kauppaera-raportti.component";
import {RaporttiSivutusComponent} from "./raportit-sivutus/raportti-sivutus.component";
import {LannoiteRaporttiComponent} from "./raportit-lannoite/lannoite-raportti.component";
import {TulosraporttiService} from "./raportti.service";
import {DataTableComponent} from "./data-table.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoaderModule} from "../loader/loader.module";
import {RehuvalvontaRaporttiComponent} from "./raportit-rehuvalvonta/rehuvalvonta-raportti.component";
import {ToukoInputsModule} from "../touko-inputs/touko-inputs.module";
import {ViljelystarkastuspaatosComponent} from "./raportit-viljelystarkastus/viljelystarkastus-raportti.component";
import {RaporttiViewService} from "./raportti-view.service";
import {UtilsModule} from "../utils/utils.module";
import {KateTarkastusComponent} from "./raportit-katetarkastus/katetarkastus-raportti.component";
import {NaytteenottotilaComponent} from "./raportit-katetarkastus/naytteenottotila.component";
import {RaportitVirkailijaComponent} from './raportit-virkailija/raportit-virkailija.component';
import {ComponentHostDirective} from "./raportit-virkailija/component-host.directive";
import {LuomuRaporttiComponent} from "./raportit-luomu/luomu-raportti.component";
import {SiemenMuutRaporttiComponent} from "./raportit-siemenmuut/siemenmuut-raportti.component";
import {ElabraRaporttiComponent} from "./raportit-elabra/elabra-raportti.component";

/**
 * Created by Seppo on 01/12/2017.
 */

@NgModule({
  declarations: [
    KauppaeraRaporttiComponent,
    SiemenMuutRaporttiComponent,
    KateTarkastusComponent,
    RehuvalvontaRaporttiComponent,
    LannoiteRaporttiComponent,
    ViljelystarkastuspaatosComponent,
    RaporttiSivutusComponent,
    DataTableComponent,
    NaytteenottotilaComponent,
    RaportitVirkailijaComponent,
    ComponentHostDirective,
    LuomuRaporttiComponent,
    ElabraRaporttiComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    AttributeModule,
    LoaderModule,
    ToukoInputsModule,
    UtilsModule,
  ],
  exports: [
    KauppaeraRaporttiComponent,
    SiemenMuutRaporttiComponent,
    KateTarkastusComponent,
    RehuvalvontaRaporttiComponent,
    ViljelystarkastuspaatosComponent,
    LannoiteRaporttiComponent,
    LuomuRaporttiComponent,
    ElabraRaporttiComponent
  ],
  providers: [
    TulosraporttiService,
    RaporttiViewService
  ]
})
export class RaporttiModule {
}
