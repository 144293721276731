

<div>
  <div class="row">
    <div class="col touko-lomake">
      <input class="form-control"
             [(ngModel)]="value"
             [ngbTypeahead]="haeKasvilajike"
             [editable]="false"
             [resultFormatter]="muotoileKasvilajike"
             [inputFormatter]="muotoileKasvilajike"
             [showHint]="false"
             (selectItem)="onSelectKasvilajike($event)"
             (focus)="lajikeFocus$.next($any($event).target.value)"
             [disabled]="disabled"
      />
    </div>
  </div>
</div>
