import {Teksti} from "../../utils/teksti";
import {Syote} from "../syote/syote";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {SyoteGroup} from "../syote/syote-group";
import {Validators} from "@angular/forms";
import {Account} from "../../account/account";
import {LannoiteValidators} from "../validators/lannoite-validators";
import {LomakeData, LomakeDataContent} from "../lomake-data";

/**
 * Created by Seppo on 23/02/2018.
 */

export class LannoiteRekisteriData extends LomakeData {

  static lomakeTitle: Teksti = new Teksti("Lannoitevalmistetoimintaa koskeva ilmoitus", "otsikko", "lannoiterekisteri");

  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    const view = LannoiteRekisteriData.lomakeTitle.view;
    const TAYTA_PUUTTUVA_KENTTA = new Teksti("Täytä puuttuva kenttä", "errTaytaPuuttuva");

    const MUU_TOIMIALA_CONTROL = toimintotyyppi === "uusi" ?
      [null, LannoiteValidators.detailsNotEmpty] :
      null;

    const REQUIRED_IN_UUSI = toimintotyyppi === "uusi" ? [null, Validators.required] : null;
    const VALMISTETTAVAT_TEXT = toimintotyyppi === "muutos" ?
      new Teksti("Valmistettavat", "valmistettavatMuutos") :
      new Teksti("Valmistettavat", "valmistettavat");
    const TOIMINNANKUVAUS_TEXT = toimintotyyppi === "muutos" ?
      new Teksti("Kuvaus", "toiminnanKuvausMuutos") :
      new Teksti("Kuvaus", "toiminnanKuvaus")
    ;

    const OMAVALVONTA_LIITE = new Teksti("Omavalvonta liitteet", "liiteOmavalvonta", view);
    const OMAVALVONTA_PVM = new Teksti("Omavalvonta päivä", "liiteOmavalvontaPvm", view);
    const TUOTETIEDOT_LIITE = new Teksti("Valmiste liitteet", "liiteValmisteet", view);
    const TUOTETIEDOT_PVM = new Teksti("Valmiste liitteet", "liiteValmisteetPvm", view);

    return {
      perustiedot: LannoiteRekisteriData.getPerustiedot(view, account),
      laskutustiedot: LannoiteRekisteriData.getLaskutustiedot(view, account),
      yhteyshenkilot: LannoiteRekisteriData.getYhteyshenkilot(view, account),
      toiminnanAloitus: LannoiteRekisteriData.getToiminnanAloitus(view, toimintotyyppi),
      sahkoinenAsiointi: LannoiteRekisteriData.getSahkoinenAsiointi(view, toimintotyyppi),
      toimiala: new SyoteGroup(
        view,
        new Teksti("Toimiala", "toimiala"),
        [LannoiteValidators.atLeastOneTrue],
        {
          [LannoiteValidators.AT_LEAST_ONE_TRUE]: new Teksti("Valitse vähintään yksi", "errTaValitseVahYksi"),
          [LannoiteValidators.DETAILS_NOT_EMPTY]: new Teksti("Täytä lisätietokenttä", "errTaLisatiedot")
        },
        YhteenvetoTyyppi.CHECKBOXES_WITH_OPTIONAL,
        new Syote(new Teksti("Lannoitevalmisteen valmistus (K)", "taLannvalmistus"), null),
        new Syote(new Teksti("Orgaanisen lannoitevalmisteen hyväksyntää vaativa valmistus (O)", "taLannorgvalmistus"), null),
        new Syote(new Teksti("Lannoitevalmisteen tuotanto maatilalla (F)", "taLannmaatilatuotanto"), null),
        new Syote(new Teksti("Lannoitevalmisteen sisämarkkinatuonti (E)", "taLannsisamarkktuonti"), null),
        new Syote(new Teksti("Lannoitevalmisteen maahantuonti (M)", "taLannmaahantuonti"), null),
        new Syote(new Teksti("Lannoitevalmisteen vienti (N)", "taLannmaastavienti"), null),
        new Syote(new Teksti("Lannoitevalmisteen markkinoille saattaminen ja valmistuttaminen (H)", "taLannmarkkinointi"), null),
        new Syote(new Teksti("Lannoitevalmisteen varastointi (V)", "taLannvarastointi"), null),
        new Syote(new Teksti("Lannoitevalmisteen pakkaaminen (P)", "taLannpakkaus"), null),
        new Syote(new Teksti("Lannoitesivutuotetoimija (SL)", "taSivulannoite"), null),
        new Syote(new Teksti("Muu lannoitevalmistealan toiminta, mikä? (X)", "taLannmuu"), MUU_TOIMIALA_CONTROL),
      ),
      valmistettavat: new SyoteGroup(
        view,
        new Teksti("Valmistettavat", "valmistettavat"),
        [],
        {required: TAYTA_PUUTTUVA_KENTTA},
        YhteenvetoTyyppi.VALUE_ONLY,
        new Syote(VALMISTETTAVAT_TEXT, REQUIRED_IN_UUSI, "valmistettavat")
      ),
      toiminnanKuvaus: new SyoteGroup(
        view,
        new Teksti("Toiminnan kuvaus", "toiminnanKuvaus"),
        [],
        {required: TAYTA_PUUTTUVA_KENTTA},
        YhteenvetoTyyppi.VALUE_ONLY,
        new Syote(TOIMINNANKUVAUS_TEXT, REQUIRED_IN_UUSI, "toiminnanKuvaus")
      ),
      liitePakolliset: new SyoteGroup(
        view,
        new Teksti("Ilmoituksen liitteeksi vaadittavat tiedot", "liiteTiedot"),
        [],
        {},
        YhteenvetoTyyppi.ATTACHMENT_DATE,
        new Syote(TUOTETIEDOT_LIITE, [null], null, null, {liite: TUOTETIEDOT_LIITE, pvm: TUOTETIEDOT_PVM}),
        new Syote(OMAVALVONTA_LIITE, [null], null, null, {liite: OMAVALVONTA_LIITE, pvm: OMAVALVONTA_PVM})
      ),

      liiteMuut: new SyoteGroup(
        view,
        new Teksti("Muut liitteet", "liiteMuut"),
        [],
        {},
        YhteenvetoTyyppi.ATTACHMENT_NO_LABEL,
        new Syote(new Teksti("Muut liitteet", "liiteMuut"), null)
      )
    };
  }
}
