import {Component, Input, OnInit} from '@angular/core';
import {Option} from "../../../touko-components/select-menu.component";
import {Tyyppinimet} from "../../../utils/tyyppinimet";
import {SyoteInterface} from "../../syote/syote-interface";
import {ControlContainer, FormArray, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'tuotetiedot-tyyppinimi',
  templateUrl: './tuotetiedot-tyyppinimi.component.html',
  styles: []
})
export class TuotetiedotTyyppinimiComponent implements OnInit {
  @Input() syote: SyoteInterface;
  tyyppinimet: Array<Option> = [];
  tuoteArray: FormArray;
  formGroup: FormGroup;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
    this.tyyppinimet = Tyyppinimet.getTyyppinimet().sort();
    this.tuoteArray = this.controlContainer.control.get(this.syote.name) as FormArray;
  }

  addToArray(): void {
    const fg = new FormGroup({tyyppiNimi: new FormControl(), maara: new FormControl()});
    this.tuoteArray.push(fg);
  }

  removeFromArray(index: number): void {
    this.tuoteArray.removeAt(index);
  }

  isRemoveDisabled(): boolean {
    return this.tuoteArray.length <= 1;
  }
}
