import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {FieldGroup} from "ngx-fielding";
import {YhteyshenkiloViewOptions} from "./yhteyshenkilo-view-options";

@Component({
  selector: 'yhteyshenkilo-input',
  template: `
      <div class="form-row" [formGroup]="formGroup">
          <div *ngIf="viewOptions?.nimi" class="col-4">
              <touko-text-input
                      formControlName="nimi"
                      [htmlId]="'yh-nimi-' + index"
                      labelAttribute="lomakeYleinen.nimi"></touko-text-input>
          </div>
          <div *ngIf="viewOptions?.puhelin" class="col-2">
              <touko-text-input
                      formControlName="puhelin"
                      [htmlId]="'yh-puh-' + index"
                      labelAttribute="lomakeYleinen.puhelin"></touko-text-input>
          </div>
          <div *ngIf="viewOptions?.email" class="col-3">
              <touko-text-input
                      formControlName="email"
                      [htmlId]="'yh-email-' + index"
                      labelAttribute="lomakeYleinen.email"></touko-text-input>
          </div>
          <div class="col-3 d-flex">
              <touko-select-menu *ngIf="viewOptions?.rooli"
                                 formControlName="rooli"
                                 [htmlId]="'yh-rooli-' + index"
                                 [options]="roolit"
                                 class="w-100"
                                 labelAttribute="lomakeYleinen.yhteyshenkilonRooli"></touko-select-menu>
              <button
                      type="button"
                      class="btn-no-style ml-2"
                      [disabled]="!isRemovable"
                      (click)="remove()"
                      aria-label="remove">
                  <span class="fa fa-times" aria-hidden="true"></span>
              </button>
          </div>
          <validation-error class="col-12 mb-4" [fields]="[formGroup]"></validation-error>
      </div>
  `
})

export class YhteyshenkiloComponent implements OnInit {

  @Input() index: number;
  @Input() roolit = [];
  @Input() isRemovable = false;
  @Input() viewOptions = new YhteyshenkiloViewOptions();

  @Output() onRemove = new EventEmitter<void>();

  public formGroup: FieldGroup;

  constructor(private readonly controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FieldGroup;
  }

  remove() {
    this.onRemove.emit();
  }
}
