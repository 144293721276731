import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LomakeResponse} from "../../touko-lomake/touko-lomake-utils";
import {LomakeTableViewOptions} from "../lomake-table/lomake-table.component";
import {Sarake} from "../../raportti/raportti.utils";
import {SarakeClickEvent} from "../lomake-table/lomake-row.component";

@Component({
  selector: 'lahete-listaus',
  templateUrl: './lahete-listaus.component.html'
})
export class LaheteListausComponent {

  @Input() viewOptions: LaheteListausViewOptions = new LaheteListausViewOptions();
  @Input() lomakkeet: LomakeResponse[] = [];
  @Input() sarakkeet: Sarake[] = [];
  @Output() selectRow = new EventEmitter<LomakeResponse>();
  @Output() selectItem = new EventEmitter<SarakeClickEvent>();
  @Output() showMore = new EventEmitter<ShowMoreEvent>();

  pageSize = 10;

  selectTableRow(item: LomakeResponse) {
    this.selectRow.emit(item);
  }

  selectTableCell(item: SarakeClickEvent) {
    this.selectItem.emit(item);
  }

  selectShowMore() {
    this.pageSize *= 2;
    this.showMore.emit({pageSize: this.pageSize, lahetetyyppi: this.viewOptions.lahetetyyppi});
  }
}

export type Lahetetyyppi = 'keskeneraiset' | 'lahetetyt' | 'mallipohjat';

export interface ShowMoreEvent {
  pageSize: number;
  lahetetyyppi: Lahetetyyppi;
}

export class LaheteListausViewOptions {
  lahetetyyppi: Lahetetyyppi;
  titleAttribute: string;
  actionAttribute: string;
  actionLink: string;
  lomakeTableViewOptions: LomakeTableViewOptions;

  constructor(lahetetyyppi?: Lahetetyyppi,
              titleAttribute?: string,
              actionAttribute?: string,
              actionLink?: string,
              lomakeTableViewOptions = new LomakeTableViewOptions(
                ["table", "table-striped", "table-hover"]
              )) {
    this.lahetetyyppi = lahetetyyppi;
    this.titleAttribute = titleAttribute;
    this.actionAttribute = actionAttribute;
    this.actionLink = actionLink;
    this.lomakeTableViewOptions = lomakeTableViewOptions;
  }
}
