import {ToukoValidation, VirheObjekti} from "./validators";
import {FormControl} from "@angular/forms";

export class LuomuValidators extends ToukoValidation {

  static luomuNaarasElainHankintaRivi(control: FormControl): VirheObjekti {
    const lupa = control.value;
    return LuomuValidators.buildError(lupa.lkmProsenteissa <= 40, 'lkmProsenteissa');
  }

  static luomuKemiaInputRivi(control: FormControl): VirheObjekti {
    return LuomuValidators.luomuInputRivi(control, false);
  }

  static luomuTavanomInputRivi(control: FormControl): VirheObjekti {
    return LuomuValidators.luomuInputRivi(control, true);
  }

  static luomuInputRivi(control: FormControl, validoiPerusteValinta: boolean): VirheObjekti {

    const lupaValues = {
      tyyppi: control.value?.tyyppi || "",
      kayttomaara: control.value?.kayttomaara || null,
      laji: control.value?.laji?.nimi || "",
      lajike: control.value?.lajike?.kuvaus || "",
      perustelut: control.value?.perustelut || "",
      peruste: control.value?.peruste || "",
    };

    const errors: VirheObjekti = {};

    if (Object.values(lupaValues).every(val => !Boolean(val))) {
      return null;
    }

    LuomuValidators.addErrorToVirheobjekti(errors, 'tyyppi', lupaValues.tyyppi.trim() === "");
    LuomuValidators.addErrorToVirheobjekti(errors, 'laji', lupaValues.laji.trim() === "");
    LuomuValidators.addErrorToVirheobjekti(errors, 'lajike', lupaValues.lajike.trim() === "");
    LuomuValidators.addErrorToVirheobjekti(errors, 'kayttomaara', !lupaValues.kayttomaara || lupaValues.kayttomaara <= 0);

    if (!errors.kayttomaara) {
      LuomuValidators.addErrorToVirheobjekti(errors, 'kayttomaaraTaimiLuku', lupaValues.tyyppi === "TAIMI" && lupaValues.kayttomaara % 1 > 0);
    }

    if (validoiPerusteValinta) {
      // Valinnalla A ei tarvitse perusteluja antaa.
      const isPerustelutRequired = lupaValues.peruste.trim().toLocaleLowerCase() !== "a";
      LuomuValidators.addErrorToVirheobjekti(errors, 'perustelut', isPerustelutRequired && !lupaValues.perustelut.trim());
      LuomuValidators.addErrorToVirheobjekti(errors, 'peruste', lupaValues.peruste.trim() === "");
    }

    return LuomuValidators.getErrorOrNull(errors);
  }

  private static addErrorToVirheobjekti(virhe: VirheObjekti, errorKey: string, isErrorCondition: boolean) {
    if (isErrorCondition) {
      virhe[errorKey] = true;
    }
  }

}
