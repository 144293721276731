import {NgModule} from "@angular/core";
import {AttributeModule} from "../tiira/attribute.module";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BrowserModule} from "@angular/platform-browser";
import {ModalJatkaComponent} from "./lomake/modal-jatka.component";
import {FormsModule} from "@angular/forms";
import {ModalSimpleComponent} from "./lomake/modal-simple.component";
import {LoaderModule} from "../loader/loader.module";

/**
 * Created by Seppo on 17/07/2018.
 */

@NgModule({
  declarations: [
    ModalJatkaComponent,
    ModalSimpleComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    AttributeModule,
    LoaderModule
  ],
  exports: [
    ModalJatkaComponent,
    ModalSimpleComponent
  ],
  providers: [
    NgbActiveModal
  ]
})
export class ModaaliModule {
}
