import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class LomakeErrorService {
  protected readonly _virhe = new BehaviorSubject<{ sivu: string; syote: string; virhe: boolean }>(null);
  virhe$ = this._virhe.asObservable();

  protected readonly _showOhje = new BehaviorSubject<boolean>(false);
  showOhje$ = this._showOhje.asObservable();

  setVirhe(sivu: string, syote: string, virhe: boolean) {
    this._virhe.next({sivu, syote, virhe});
  }

  toggleOhje() {
    this._showOhje.next(!this._showOhje.getValue());
  }

  cleanUp() {
    this._virhe.next(null);
    this._showOhje.next(false);
  }

}
