import {NgModule} from "@angular/core";
import {LannoitevalmisteAinesosaluokatYhteenvetoComponent} from "./lannoitevalmiste-ainesosaluokat/lannoitevalmiste-ainesosaluokat-yhteenveto.component";
import {LannoitevalmisteenjakelijaYhteenvetoComponent} from "./lainnoitevalmisteenjakelija/lannoitevalmisteenjakelija-yhteenveto.component";
import {TuoteluokatYhteenvetoComponent} from "./tuoteluokat-yhteenveto/tuoteluokat-yhteenveto.component";
import {LannoitevarastointiComponent} from "./lannoitevarastointi/lannoitevarastointi.component";
import {LannoiteEuToimintaComponent} from "./lannoite-eu-toiminta/lannoite-eu-toiminta.component";
import {CommonModule} from "@angular/common";
import {AttributeModule} from "../../../tiira/attribute.module";
import {UtilsModule} from "../../../utils/utils.module";
import {
  LannoiteEuToimintaValmistajaValmistuttajaComponent
} from "./lannoite-eu-toiminta-valmistaja/lannoite-eu-toiminta-valmistaja-valmistuttaja.component";
import {LannoiteMuuToimintaComponent} from "./lannoite-muu-toiminta/lainnoite-muu-toiminta.component";
import {LannoiteSivutuoteComponent} from "./lannoite-sivutuote/lainnoite-sivutuote.component";
import {LannoiteEuJakelija} from "./lannoite-eu-jakelija/lannoite-eu-jakelija";
import {LannoiteLopetaToimintaComponent} from "./lannoite-lopeta-toiminta/lannoite-lopeta-toiminta.component";
import {
  LannoiteUusiIlmoitettavaToiminta
} from "./lannoite-uusi-ilmoitettava-toiminta/lannoite-uusi-ilmoitettava-toiminta.component";

@NgModule({
  declarations: [
    LannoitevalmisteAinesosaluokatYhteenvetoComponent,
    LannoitevalmisteenjakelijaYhteenvetoComponent,
    LannoitevarastointiComponent,
    TuoteluokatYhteenvetoComponent,
    LannoiteEuToimintaComponent,
    LannoiteEuToimintaValmistajaValmistuttajaComponent,
    LannoiteMuuToimintaComponent,
    LannoiteSivutuoteComponent,
    LannoiteEuJakelija,
    LannoiteLopetaToimintaComponent,
    LannoiteUusiIlmoitettavaToiminta
  ],
  imports: [
    CommonModule,
    AttributeModule,
    UtilsModule
  ],
  exports: [
    LannoitevalmisteAinesosaluokatYhteenvetoComponent,
    LannoitevalmisteenjakelijaYhteenvetoComponent,
    LannoitevarastointiComponent,
    TuoteluokatYhteenvetoComponent,
    LannoiteEuToimintaComponent,
    LannoiteEuToimintaValmistajaValmistuttajaComponent,
    LannoiteMuuToimintaComponent,
    LannoiteSivutuoteComponent,
    LannoiteEuJakelija
  ]
})
export class LannoiteYhteenvetoModule {

}
