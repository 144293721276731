import {Component, OnInit} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {AbstractField, FieldArray, FieldControl, FieldGroup} from "ngx-fielding";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {LiitetiedostoLomakeService} from "../../liitetiedosto-lomake.service";

@Component({
  selector: 'luomuvalvonta-aloitus-liitteet',
  template: `
    <div [formGroup]="formGroup">
      <h3 attribute="luomu.liitteet">Liitteet</h3>
      <ng-container *ngFor="let liite of liitteet">
        <label [id]="liite.field.htmlId + '-liiteLabel'" [attribute]="liite.field.label"
               [ngClass]="{'required': liite.field.errorMessages?.required}">
        </label>
        <liite-list [htmlId]="liite.field.htmlId + '-liite'"
                    [labelledBy]="liite.field.htmlId + '-liiteLabel'"
                    [liitteetArray]="liite.asArray()"
                    (liiteOut)="setLiitteet($event, 'liitteet')" [lomakeId]="lomakeId"></liite-list>
        <validation-error [field]="liite"></validation-error>
      </ng-container>

      <ng-container *ngIf="liiteDescrControl">
        <touko-textarea [formControl]="liiteDescrControl.asControl()" [htmlId]="liiteDescrControl.field.htmlId"
                        [labelAttribute]="liiteDescrControl.field.label"
                        [maxLength]="3000"></touko-textarea>
      </ng-container>
    </div>
  `
})

export class LuomuvalvontaAloitusLiitteetComponent implements OnInit {
  lomakeId: number;

  constructor(private controlContainer: ControlContainer, private liitetiedostoLomakeService: LiitetiedostoLomakeService) {
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get liitteet(): AbstractField[] {
    return Object.values(this.formGroup.controlFields).filter(control => control instanceof FieldArray);
  }

  get liiteDescrControl(): AbstractField {
    const liiteDescr = Object.values(this.formGroup.controlFields).filter(control => control instanceof FieldControl);
    return liiteDescr.length ? liiteDescr[0] : null;
  }

  ngOnInit(): void {
    this.getLomakeId();
  }

  getLomakeId(): void {
    this.liitetiedostoLomakeService.lomakeId.subscribe(id => {
      this.lomakeId = id;
    })
  }

  setLiitteet(evt: LiitetiedostoResponse[], liiteAvain) {
    if (evt) {
      this.liitetiedostoLomakeService.setLiite(evt, liiteAvain);
    }
  }
}
