/**
 * Created by Seppo on 15/11/2017.
 */


import {Component, Input} from "@angular/core";
import {Teksti} from "../utils/teksti";

/**
 * Syöte group
 */
@Component({
  selector: 'syote-group',
  template: `
    <fieldset class="mb-4">
      <legend *ngIf="otsikko" class="mb-5" [attribute]="otsikko?.getFullKey()">{{otsikko}}</legend>
      <div class="form-group">
        <ng-content></ng-content>
        <div class="mt-2">
            <lomake-error [virheSanomat]="virheSanomat" [virheet]="virheet"></lomake-error>
        </div>
      </div>
    </fieldset>
  `
})
export class SyoteGroupComponent {
  @Input() otsikko: Teksti = null;
  @Input() virheet: any = [];
  @Input() virheSanomat: { [key: string]: Teksti };
}
