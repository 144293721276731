<ng-container [formGroup]="formGroup">
<h3 attribute="lannoiterekisteri.tiedotTyyppinimiKohtaisesti">Tuotteiden tiedot tyyppinimi
    kohtaisesti</h3>
  <div class="row pt-2" [formArrayName]="syote.name">
    <ng-container *ngFor="let tuote of tuoteArray.controls; let i = index" [formGroup]="tuote">
      <div class="col-6 pr-0">
        <touko-select-menu formControlName="tyyppiNimi" [htmlId]="'tyyppinimiDropdown-'+i" labelAttribute="lannoiterekisteri.kansallinenTaiEYtyyppinimitunnus" defaultTitle="lannoiterekisteri.valitse" [options]="tyyppinimet"></touko-select-menu>
      </div>
      <div class="col-6 d-flex align-items-end mb-4">
        <touko-number-input
          formControlName="maara"
          [htmlId]="syote.name+'-'+i"
          labelAttribute="lannoiterekisteri.maara"
          inputWidth="small">
        </touko-number-input>
        <button [id]="'poistaTuote-'+i" toukobutton buttonStyle="outline-primary" class="ml-3" [disabled]="isRemoveDisabled()"
                type="button" (click)="removeFromArray(i)">
          <i class="fas fa-trash-alt" aria-hidden="true"></i>
          <span attribute="teksti.poista">Poista</span>
        </button>
      </div>
    </ng-container>
  </div>
  <div>
    <button id="uusiTuote" toukobutton buttonStyle="danger" type="button" (click)="addToArray()">
      <i class="fas fa-plus-circle" aria-hidden="true"></i><span
      attribute="lannoiterekisteri.uusiTuoteBtn" class="pr-2">Uusi tuote</span>
    </button>
  </div>
</ng-container>
