<touko-lomake *ngIf="lomake"
              [formGroup]="lomake"
              [tunnus]="lomakeMetaData.id"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [cancelFn]="cancelLomake"
              (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              [saveFn]="saveLomake">
  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        sivuvirhekasittely [virheGroups]="['laskutustiedot', 'perustiedot', 'yhteyshenkilot']"
        sivu="ref0">
    <div class="mb-4">
      <touko-alert-box *ngIf="!isPerustiedotValid">
        <div attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</div>
      </touko-alert-box>
    </div>
    <fieldset formGroupName="hakemustyyppi" class="mb-3">
      <legend attribute="siemenpakkaamo.hakemustyyppi"></legend>
      <p attribute="siemenpakkaamo.valitseHakemustyyppi">Valitse hakemustasi parhaiten kuvaava vaihtoehto.</p>
      <touko-radio-input *ngFor="let hakemustyyppi of hakemustyypit; index as i"
                         [htmlId]="hakemustyyppi.htmlId"
                         [value]="hakemustyyppi.value"
                         [labelAttribute]="hakemustyyppi.label"
                         [radioGroup]="'radio-group'"
                         [formControlName]="'radio-hakemustyyppi'">
      </touko-radio-input>
    </fieldset>

    <perustiedot [lomake]="lomake"></perustiedot>
    <laskutustiedot [lomake]="lomake"></laskutustiedot>
    <yhteyshenkilot [yhteyshenkiloViewOptions]="yhteyshenkiloViewOptions" [lomake]="lomake"
                    [rooliValues]="[yhteyshenkiloRooli]"></yhteyshenkilot>

    <fieldset formGroupName="toiminnanAloitus" class="col-md-6 col-12 px-0">
      <legend [attribute]="'lomakeYleinen.toiminnanAloitus'"></legend>
      <date [label]="'lomakeYleinen.merkitseToiminnanAloitus'" formControlName="toiminnanAloitus"
            htmlIdSuffix="toiminnanAloitus"></date>
      <small>
        <a [href]="'https://avointieto.ruokavirasto.fi/#/kasvi/siemenpakkaamot'" target="_blank"
           attribute="siemenpakkaamo.voimassaolotiedotInfo">Täältä löydät pakkauslupasi voimassaolotiedot</a>
      </small>
    </fieldset>
    <validation-error [field]="lomake.get('toiminnanAloitus.toiminnanAloitus')"></validation-error>
  </sivu>

  <sivu [selectable]="isPerustiedotValid"
        [attribuuttiAvain]="'siemenpakkaamo.sivuToiminnanLuonne'"
        [ohjeet]="lomakeInfo.ohjeet['sivu1']">

    <fieldset class="mb-3">
      <legend attribute="siemenpakkaamo.markkinoimasiKylvosiemenet"></legend>
      <ng-container *ngFor="let kylvosiemen of markkinoimasiKylvosiemenet; index as i; last as isLast;">
        <touko-checkbox
            *ngIf="!isLast"
            [formControl]="kylvosiemen"
            [htmlId]="'check-' + kylvosiemen.field.htmlId"
            [labelAttribute]="kylvosiemen.field.label">
        </touko-checkbox>
        <div *ngIf="isLast" class="ml-5 pl-1">
          <touko-textarea *ngIf="isLast && lomake.get('markkinoimasiKylvosiemenet').get('muutKasvit').value"
                          [formControl]="kylvosiemen"
                          [labelAttribute]="'lomakeYleinen.lisatiedot'" htmlId="muutKasvitLisatieto">
          </touko-textarea>
        </div>
      </ng-container>
      <validation-error [field]="lomake.get('markkinoimasiKylvosiemenet')"></validation-error>
    </fieldset>

    <fieldset formGroupName="toiminnanLaajuus" class="mb-3">
      <legend attribute="siemenpakkaamo.toiminnanLaajuus"></legend>
      <touko-radio-input *ngFor="let maara of toiminnanLaajuus; index as i"
                         [htmlId]="maara.htmlId"
                         [value]="maara.value"
                         [labelAttribute]="maara.label"
                         [radioGroup]="'radio-group'"
                         [formControlName]="'radio-group'">
      </touko-radio-input>
      <validation-error [field]="lomake.get('toiminnanLaajuus')"></validation-error>
    </fieldset>

    <fieldset formGroupName="koneetJaLaitteet">
      <legend attribute="siemenpakkaamo.koneetJaLaitteet"></legend>
      <touko-checkbox *ngFor="let laite of koneetJaLaitteet.slice(0, 4)"
                      [htmlId]="'koneetJaLaitteetCheckbox-' + laite.field.htmlId"
                      [labelAttribute]="laite.field.label"
                      [formControl]="laite">
      </touko-checkbox>
      <div *ngFor="let laite of koneetJaLaitteet.slice(4,9); index as i">
        <touko-checkbox *ngIf="i%2 === 0"
                        [htmlId]="'koneetJaLaitteetCheckbox-' + laite.field.htmlId"
                        [labelAttribute]="laite.field.label"
                        [formControl]="laite">
        </touko-checkbox>
        <div *ngIf="laite.value && i%2 === 0" class="ml-5 pl-1 mb-4">
          <div
              *ngFor="let checkboxAndDate of koneetJaLaitteet.slice(4)[i + 1].controlFields; index as k; last as isLast">
            <touko-checkbox *ngIf="!laite.field.label.includes('vaaka')"
                            [htmlId]="checkboxAndDate.get('checkbox').field.htmlId + k"
                            [labelAttribute]="checkboxAndDate.get('checkbox').field.label"
                            [formControl]="checkboxAndDate.get('checkbox')">
            </touko-checkbox>
            <label
                [attribute]="'siemenpakkaamo.vaakaVarmennettu'"
                [id]="'date-' + k + '-' + laite.field.htmlId">Päivämäärä</label>
            <touko-kuukausi-pvm [id]="checkboxAndDate.get('date').field.htmlId  + k"
                                [idSuffix]="laite.field.htmlId"
                                [formControl]="checkboxAndDate.get('date')"
                                (removeItem)="removeLisalaite(checkboxAndDate.field.options.arrayName, k)"
                                [disabled]="laite.field.label.includes('vaaka') ? false : !checkboxAndDate.get('checkbox')?.value"
                                [isRemoveDisabled]="koneetJaLaitteet.slice(4)[i + 1].controlFields.length === 1"
                                [rangeOfYears]="laite.field.label.includes('vaaka') ? {yearsForward: 5, yearsBackward: 5} : {yearsForward: 1, yearsBackward: 10}">
            </touko-kuukausi-pvm>
            <button *ngIf="isLast" id="btnLisaaLaite-{{checkboxAndDate.field.options.arrayName}}"
                    class="btn btn-primary mt-2"
                    type="button" attribute="siemenpakkaamo.btnLisaaLaite"
                    (click)="addLisalaite(checkboxAndDate.field.options.arrayName)">Lisää laite
            </button>
          </div>
        </div>
      </div>
      <div *ngFor="let laite of koneetJaLaitteet.slice(9); index as i">
        <touko-checkbox *ngIf="i%2 === 1"
                        [htmlId]="'koneetJaLaitteetCheckbox-' + laite.field.htmlId"
                        [labelAttribute]="laite.field.label"
                        [formControl]="laite">
        </touko-checkbox>
        <div *ngIf="laite.value && i%2 === 1" class="ml-5 pl-1">
          <label [htmlFor]="koneetJaLaitteet.slice(9)[i + 1].field.htmlId" attribute="siemenpakkaamo.lisatieto">Lisätiedot</label>
          <textarea [id]="koneetJaLaitteet.slice(9)[i + 1].field.htmlId"
                    [formControlName]="koneetJaLaitteet.slice(9)[i + 1].field.htmlId" class="form-control"
                    rows="4"></textarea>
        </div>
      </div>
      <validation-error [field]="lomake.get('koneetJaLaitteet')"></validation-error>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'siemenpakkaamo.lisatiedotRyhmaOtsikko'">
    <fieldset class="mb-3">
      <legend attribute="siemenpakkaamo.lisatiedotRyhmaOtsikko"></legend>
      <p attribute="siemenpakkaamo.lisatiedotHuomautus">Täytetään vain, mikäli toimipaikalla harjoitetaan ko.
        toimintaa.</p>
      <div *ngFor="let toiminta of lisatiedotToiminta; index as i">
        <touko-checkbox *ngIf="i%2 === 0"
                        [htmlId]="'lisatiedotCheckbox-' + toiminta.field.htmlId"
                        [labelAttribute]="toiminta.field.label"
                        [formControl]="toiminta">
        </touko-checkbox>
        <div *ngIf="toiminta.value && i%2 === 0" class="ml-5 pl-1 mb-5">
          <label [htmlFor]="lisatiedotToiminta[i + 1].field.htmlId"
                 attribute="siemenpakkaamo.lisatieto">Lisätiedot</label>
          <textarea [id]="lisatiedotToiminta[i + 1].field.htmlId" [formControl]="lisatiedotToiminta[i + 1].asControl()"
                    class="form-control"
                    rows="4"></textarea>
          <touko-alert-box *ngIf="toiminta.field.htmlId.includes('luomusiemenpakkaus') && toiminta.value">
                  <span attribute="siemenpakkaamo.luomusiemenLisaHuomautus">Luomusiementä pakkaavan
                    toimijan on liityttävä
                    luonnonmukaisen tuotannon valvontajärjestelmään</span>
          </touko-alert-box>
        </div>
      </div>
    </fieldset>

    <fieldset formGroupName="painatus" class="mb-3">
      <legend attribute="siemenpakkaamo.painatus"></legend>
      <div *ngFor="let vakuuslipuke of vakuusLipukkeet.field.valueOptions; index as i">
        <touko-radio-input
            [formControlName]="'radio-group'"
            [htmlId]="vakuuslipuke.htmlId"
            [labelAttribute]="vakuuslipuke.label"
            [radioGroup]="'radio-group'"
            [value]="vakuuslipuke.value">
        </touko-radio-input>
        <div *ngIf="vakuusLipukkeet.value === 'painetaanItse' && vakuuslipuke.value === 'painetaanItse'" class="mb-4">
          <touko-alert-box>
            <div attribute="siemenpakkaamo.painatusOhje"></div>
          </touko-alert-box>
        </div>
      </div>
      <validation-error [field]="lomake.get('painatus')"></validation-error>
    </fieldset>

    <fieldset formGroupName="tarkastusAjankohta" class="col-md-6 col-12 px-0">
      <legend [attribute]="lomake.get('tarkastusAjankohta').field.label"></legend>
      <date [label]="lomake.get('tarkastusAjankohta.tarkastusDate').field.label" formControlName="tarkastusDate"
            htmlIdSuffix="tarkastusDate"></date>
      <validation-error [field]="lomake.get('tarkastusAjankohta.tarkastusDate')"></validation-error>
    </fieldset>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
    <app-field-group-summary class="summary" [fieldGroup]="lomake"></app-field-group-summary>
    <div *ngIf="lomakeMetaData.isAsiakas">
      <hr>
      <button id="laheta" class="mt-4 btn btn-primary" type="submit" attribute="teksti.laheta">Lähetä</button>
    </div>
  </sivu>
</touko-lomake>

<pre *devFeature class="small">
  {{ lomake?.getRawValue() | json }}
</pre>
