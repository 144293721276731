export type ErrorObj = { [s: string]: any; } | null;

export abstract class ValidatorsBase {

  protected static validate(isValid: boolean, errMsgKey: string): ErrorObj {
    if (isValid) {
      return null;
    } else {
      return {[errMsgKey]: true};
    }
  }
}
