import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AccountNotification} from "../../account/account";
import {OmavalvontasuunnitelmaService} from "./omavalvontasuunnitelma.service";

@Component({
  selector: 'omavalvontasuunnitelma',
  template: `
      <div class="card mb-4" *ngIf="notification?.type === 'KATE_OVS_VANHENEE'">
        <div class="card-header">
                <span attribute="etusivu.paivitaOmavalvontasuunnitelma">
                Päivitä omavalvontasuunnitelma
                </span>
          <em class="fa fa-exclamation-circle text-danger float-right"></em>
        </div>
        <div class="card-body">
          <p class="small mt-2"><span attribute="etusivu.edellinenOVStoimitettu">Edellinen omavalvontasuunnitelma toimitettu</span> {{notification.data}}
            .
            <span attribute="etusivu.lahetaPaivitettyOVS">Lähetä päivitetty versio PDF-tiedostona viimeistään </span>
            <span class="text-danger"> {{getLastDate()}}.</span>
          </p>
          <button class="btn btn-primary" id="omavalvontasuunnitelmaan" (click)="omavalvontasuunnitelmaan()">
            <span attribute="etusivu.OVSlomakkeelle" id="lomakkeelle">Lomakkeelle</span>
            <span class="fa fa-arrow-right"></span>
          </button>
        </div>
      </div>
    `,
  styleUrls: ['../etusivu.component.scss'],
  providers: [
    OmavalvontasuunnitelmaService
  ]
})
export class OmavalvontasuunnitelmaComponent implements OnInit {

  notification: AccountNotification;

  constructor(private readonly router: Router,
              private readonly ovsService: OmavalvontasuunnitelmaService) {
  }

  ngOnInit(): void {
    this.ovsService.getNotification()
      .then(response => this.notification = response);
  }

  getLastDate(): string {
    const split = this.notification.data.split(".", 3);
    const plusOneYear = +split[2] + 1;
    return `${split[0]}.${split[1]}.${plusOneYear}`;
  }

  omavalvontasuunnitelmaan() {
    this.router.navigate(["/asiointi/lomake/muutos/kasvinterveysrekisteri/0/aloita/sivu/5"]);
  }
}
