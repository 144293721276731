import {ValidatorOptions, YhteyshenkiloValue} from "../touko-lomake-utils";
import {Account} from "../../account/account";
import {Teksti} from "../../utils/teksti";
import {SyoteGroup} from "../syote/syote-group";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {Syote} from "../syote/syote";
import {ToimintaDetails} from "./toiminta-card.component";
import {ToukoValidation} from "../validators/validators";
import {keyOrderFn, LomakeData, LomakeDataContent} from "../lomake-data";

export class KasvinterveysRekisteriData extends LomakeData {

  static lomakeTitle: Teksti = new Teksti("Kasvinterveydenalan toiminnan rekisteröintiä koskeva ilmoitus", "katerekisteriIlmoitus", "kasvinterveysrekisteri");

  static getKeyOrder(): string[] {
    return keyOrderFn(KasvinterveysRekisteriData.getData);
  }
  static getData(account?: Account, toimintotyyppi?: string): LomakeDataContent {

    const view = KasvinterveysRekisteriData.lomakeTitle.view;

    const toimAlaPuutarhaTarhaToiminta =  new ToimintaDetails(new Teksti("-", "toimAlaPuutarhaTarhaSelite", view), "puutarha", 'fa-seedling');
    const toimAlaPuutarhaMyymalaToiminta =  new ToimintaDetails(new Teksti("-", "toimAlaPuutarhaMyymalaSelite", view), "puutarha", 'fa-store');
    const toimAlaPuutarhaKasviToiminta =  new ToimintaDetails(new Teksti("-", "toimAlaPuutarhaKasviSelite", view), "puutarha", 'fa-warehouse');
    const toimAlaPuutarhaTukkuToiminta =  new ToimintaDetails(new Teksti("-", "toimAlaPuutarhaTukkuSelite", view), "puutarha", 'fa-cubes');
    const toimAlaPuutarhaViherrakToiminta =  new ToimintaDetails(new Teksti("-", "toimAlaPuutarhaViherrakSelite", view), "puutarha", 'fa-tree');
    const toimAlaPuutarhaKasvitietToiminta =  new ToimintaDetails(new Teksti("-", "toimAlaPuutarhaKasvitietSelite", view), "puutarha", 'fa-university');
    const toimAlaPerunaViljelyToiminta = new ToimintaDetails(new Teksti("-", "toimAlaPerunaViljelySelite", view), "peruna", 'fa-tractor');
    const toimAlaPuuISPMToiminta = new ToimintaDetails(new Teksti("-", "toimAlaPuuISPMSelite", view), "puutavara", 'fa-boxes');
    const toimAlaPuuToimitusToiminta = new ToimintaDetails(new Teksti("-", "toimAlaPuuToimitusSelite", view), "puutavara", 'fa-pallet');

    const tuoPuuvartOptionsByValue = {jatkokasvatus: new Teksti("Jatko", "tuoJatkokasvatukseen", view), myynti: new Teksti("Kuluttaja", "tuoKuluttajille", view), order: ['jatkokasvatus', 'myynti']};
    const tuoMarjaOptionsByValue = {jatkokasvatus: new Teksti("Jatko", "tuoJatkokasvatukseen", view), myynti: new Teksti("Kuluttaja", "tuoKuluttajille", view), order: ['jatkokasvatus', 'myynti']};
    const tuoTaimiKasviOptionsByValue = {kasviTaimi: new Teksti("Taimet", "kasviTaimi", view), kasviValmis: new Teksti("Valmiit", "kasviValmiit", view), order: ['kasviTaimi', 'kasviValmis']};
    const myyTaimiValitysOptionsByValue = {jatkokasvatus: new Teksti("Jatko", "optJatkokasvatus", view), myynti: new Teksti("Myyntiin", "myyMyynti", view), order: ['jatkokasvatus', 'myynti']};
    const tvOptionsByValue = {tuonti: new Teksti("Tuonti", "maahantuonti", view), vienti: new Teksti("Vienti", "maastavienti", view), order: ['tuonti', 'vienti']};

    const errAtLeastOneTeksti = new Teksti("Valitse vähintään yksi.", "errAtLeastOne");
    const yhValidators = new ValidatorOptions();
    yhValidators.validators = [ToukoValidation.allKasvinterveysvastaavaYhteyshenkilo];
    yhValidators.errorMsgs = {[ToukoValidation.ALL_KATE_VASTAAVA]: new Teksti("-", "errKasvinterveysvastaavaRooli", view)};

    return {
      perustiedot: KasvinterveysRekisteriData.getPerustiedot(view, account),
      laskutustiedot: KasvinterveysRekisteriData.getLaskutustiedot(view, account),
      yhteyshenkilot: KasvinterveysRekisteriData.getYhteyshenkilot(view, account, yhValidators, YhteyshenkiloValue.KATE_VASTAAVA.value),
      toiminnanAloitus: KasvinterveysRekisteriData.getToiminnanAloitus(view, toimintotyyppi),
      sahkoinenAsiointi: KasvinterveysRekisteriData.getSahkoinenAsiointi(view, toimintotyyppi),

      toiminta: new SyoteGroup(
        view,
        new Teksti("Toiminta", "toimintaOtsikko"), null, null,
        YhteenvetoTyyppi.NONE,
        new Syote(new Teksti("-", "toimAlaPuutarhaTarha"), null, null, null, {toiminta: toimAlaPuutarhaTarhaToiminta}),
        new Syote(new Teksti("-", "toimAlaPuutarhaMyymala"), null, null, null, {toiminta: toimAlaPuutarhaMyymalaToiminta}),
        new Syote(new Teksti("-", "toimAlaPuutarhaKasvi"), null, null, null, {toiminta: toimAlaPuutarhaKasviToiminta}),
        new Syote(new Teksti("-", "toimAlaPuutarhaTukku"), null, null, null, {toiminta: toimAlaPuutarhaTukkuToiminta}),
        new Syote(new Teksti("-", "toimAlaPuutarhaViherrak"), null, null, null, {toiminta: toimAlaPuutarhaViherrakToiminta}),
        new Syote(new Teksti("-", "toimAlaPuutarhaKasvitiet"), null, null, null, {toiminta: toimAlaPuutarhaKasvitietToiminta}),
        new Syote(new Teksti("-", "toimAlaPerunaViljely"), null, null, null, {toiminta: toimAlaPerunaViljelyToiminta}),
        new Syote(new Teksti("-", "toimAlaPuuISPM"), null, null, null, {toiminta: toimAlaPuuISPMToiminta}),
        new Syote(new Teksti("-", "toimAlaPuuToimitus"), null, null, null, {toiminta: toimAlaPuuToimitusToiminta}),
      ),

      tuoPuuvart: new SyoteGroup(
        view,
        new Teksti("Tuotanto taimitarhoissa (puuvartiset kasvit)", "tuoPuuvartYhtOtsikko"),
        [ToukoValidation.atLeastOneChildSelected],
        {[ToukoValidation.AT_LEAST_ONE_CHILD_SELECTED]: errAtLeastOneTeksti},
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Puuvartisten kasvien tuotanto", "tuoPuuvart"), null, null, null, {toimintaId: "toimAlaPuutarhaTarha", resetParent: true}),
        new Syote(new Teksti("Perennojen tuotanto", "tuoPerenna"), null, null, null, tuoPuuvartOptionsByValue),
        new Syote(new Teksti("Tulipoltteen isäntäkasvit", "tuoPuuvartTuli"), null, null, null, tuoPuuvartOptionsByValue),
        new Syote(new Teksti("Prunus suvun kasvit", "tuoPuuvartPrunus"), null, null, null, tuoPuuvartOptionsByValue),
        new Syote(new Teksti("Muut", "muutLajit"), null, "tuoPuuvartMuu", null, tuoPuuvartOptionsByValue)
      ),

      tuoMarjaVarm: new SyoteGroup(
        view,
        new Teksti("Marjakasvien taimituotanto (varmennettu taimiaineisto)", "tuoMarjaVarmYhtOtsikko"),
        [ToukoValidation.atLeastOneChildSelected],
        {[ToukoValidation.AT_LEAST_ONE_CHILD_SELECTED]: errAtLeastOneTeksti},
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Marjakasvien tuotanto kuluttajille", "tuoMarjaVarm"), null, null, null, {toimintaId: "toimAlaPuutarhaTarha", resetParent: true}),
        new Syote(new Teksti("Mansikka", "tuoMarjaMansikka"), null, "tuoMarjaVarmMansikka", null, tuoMarjaOptionsByValue),
        new Syote(new Teksti("Muut marjakasvit", "tuoMarjaMuut"), null, "tuoMarjaVarmMuut", null, tuoMarjaOptionsByValue),
      ),

      tuoMarjaCAC: new SyoteGroup(
        view,
        new Teksti("Marjakasvien taimituotanto (varmennettu taimiaineisto)", "tuoMarjaCACYhtOtsikko"),
        [ToukoValidation.atLeastOneChildSelected],
        {[ToukoValidation.AT_LEAST_ONE_CHILD_SELECTED]: errAtLeastOneTeksti},
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Marjakasvien tuotanto kuluttajille", "tuoMarjaCAC"), null, null, null, {toimintaId: "toimAlaPuutarhaTarha", resetParent: true}),
        new Syote(new Teksti("Mansikka", "tuoMarjaMansikka"), null, "tuoMarjaCACMansikka", null, tuoMarjaOptionsByValue),
        new Syote(new Teksti("Muut marjakasvit", "tuoMarjaMuut"), null, "tuoMarjaCACMuut", null, tuoMarjaOptionsByValue),
      ),

      tuoKasvi: new SyoteGroup(
        view,
        new Teksti("Tuotanto kasvihuoneviljelmillä", "tuoKasviYhtOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Kahvi", "tuoKasviKahvi"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", ... tuoTaimiKasviOptionsByValue}),
        new Syote(new Teksti("Joulutähti", "tuoKasviJoulutahti"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", ... tuoTaimiKasviOptionsByValue}),
        new Syote(new Teksti("Laventeli", "tuoKasviLaventeli"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", ... tuoTaimiKasviOptionsByValue}),
        new Syote(new Teksti("Oleanteri", "tuoKasviOleanteri"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", ... tuoTaimiKasviOptionsByValue}),
        new Syote(new Teksti("Myrttilinnunruoho", "tuoKasviMyrttilinnunruoho"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", ... tuoTaimiKasviOptionsByValue}),
        new Syote(new Teksti("Muut", "tuoKasviMuut"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", ... tuoTaimiKasviOptionsByValue}),
      ),

      tuoVihannesTaimi: new SyoteGroup(
        view,
        new Teksti("Tuotanto kasvihuoneviljelmillä (vihannesten taimet)", "tuoVihannesTaimiYhtOtsikko"),
        [ToukoValidation.atLeastOneChildSelected],
        {[ToukoValidation.AT_LEAST_ONE_CHILD_SELECTED]: errAtLeastOneTeksti},
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Vihannesten taimien tuotanto", "tuoVihannesTaimi"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", resetParent: true}),
        new Syote(new Teksti("Paprika", "tuoVihannesTaimiPaprika")),
        new Syote(new Teksti("Kasvihuonekurkku", "tuoVihannesTaimiKasvihuonekurkku")),
        new Syote(new Teksti("Tomaatti", "tuoVihannesTaimiTomaatti")),
        new Syote(new Teksti("Purjo", "tuoVihannesTaimiPurjo")),
        new Syote(new Teksti("Kaalit", "tuoVihannesTaimiKaalit")),
        new Syote(new Teksti("Avomaankurkku", "tuoVihannesTaimiAvomaankurkku")),
        new Syote(new Teksti("Kurpitsat", "tuoVihannesTaimiKurpitsat")),
        new Syote(new Teksti("Salaatit", "tuoVihannesTaimiSalaatit")),
      ),

      tuoVihannesYksityiskaytto: new SyoteGroup(
        view,
        new Teksti("Tuotanto kasvihuoneviljelmillä (yksityiskäyttö)", "tuoVihannesYksityiskayttoYhtOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Valmiiden vihannesten tuotanto", "tuoVihannesYksityiskaytto"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi"}),
      ),

      tuoVihannesValmis: new SyoteGroup(
        view,
        new Teksti("Tuotanto kasvihuoneviljelmillä (vihannesten taimet)", "tuoVihannesValmisYhtOtsikko"),
        [ToukoValidation.atLeastOneChildSelected],
        {[ToukoValidation.AT_LEAST_ONE_CHILD_SELECTED]: errAtLeastOneTeksti},
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Valmiiden vihannesten tuotanto", "tuoVihannesValmis"), null, null, null, {toimintaId: "toimAlaPuutarhaKasvi", resetParent: true}),
        new Syote(new Teksti("Kurkku", "tuoVihannesValmisKurkku")),
        new Syote(new Teksti("Paprika", "tuoVihannesValmisPaprika")),
        new Syote(new Teksti("Tomaatti", "tuoVihannesValmisTomaatti")),
      ),

      tuoPerunaViljely: new SyoteGroup(
        view,
        new Teksti("Perunan viljlely", "tuoPerunaViljelyOtsikko"), null, null, YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Sertifioitua siemenperunaa", "tuoPerunaViljelySiemen"), null, null, null, {toimintaId: "toimAlaPerunaViljely"}),
        new Syote(new Teksti("Ruokaperunaa vähintään 0,5 hehtaarin alalla", "tuoPerunaViljelyRuoka"), null, null, null, {toimintaId: "toimAlaPerunaViljely"}),
        new Syote(new Teksti("Ruokateollisuusperunaa", "tuoPerunaViljelyRuokateollisuus"), null, null, null, {toimintaId: "toimAlaPerunaViljely"}),
        new Syote(new Teksti("Tärkkelysperunaa", "tuoPerunaViljelyTarkkelys"), null, null, null, {toimintaId: "toimAlaPerunaViljely"}),
      ),

      tuoISPM15Kasittely: new SyoteGroup(
        view,
        new Teksti("ISPM 15 käsittelijät", "tuoISPM15KasittelyYhtOtsikko"), null, null, YhteenvetoTyyppi.LABEL_OR_ATTACHMENT,
        new Syote(new Teksti("Toimipaikalla käsittellään ISPM 15 -puutavaraa", "tuoISPM15Kasittely"), null, null, null, {toimintaId: "toimAlaPuuISPM"}),
        new Syote(new Teksti("Lataa käsittelydokumentit (esim. kuivauskäyrät)", "tuoISPM15KasittelyLiite")),
      ),

      tuoISPM15Valmistus: new SyoteGroup(
        view,
        new Teksti("ISPM 15 valmistajat", "tuoISPM15ValmistusYhtOtsikko"), null, null, YhteenvetoTyyppi.LABEL_OR_ATTACHMENT,
        new Syote(new Teksti("Toimipaikalla valmistetaan ISPM 15 -puutavaraa", "tuoISPM15Valmistus"), null, null, null, {toimintaId: "toimAlaPuuISPM"}),
        new Syote(new Teksti("Lataa puutavaran alkuperään liittyvät dokumentit", "tuoISPM15ValmistusAlkuperaLiite")),
        new Syote(new Teksti("Lataa kuvat leimausjäljistä (kaikki käytetyt leimasimet)", "tuoISPM15ValmistusLeimausLiite")),
      ),

      myyTaimimyymala: new SyoteGroup(
        view,
        new Teksti("Taimimyymälätoiminta", "myyTaimiOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Perennat", "myyTaimimyymalaPerenna"), null, null, null, {toimintaId: "toimAlaPuutarhaMyymala"}),
        new Syote(new Teksti("Puut ja pensaat", "myyTaimimyymalaPuu"), null, null, null, {toimintaId: "toimAlaPuutarhaMyymala"}),
        new Syote(new Teksti("Osa taimista tulipoltteen isäntäkasveja", "myyTaimimyymalaTulipolte"), null, null, null, {toimintaId: "toimAlaPuutarhaMyymala", indent: true})
      ),

      myyTaimiValitysKasvi: new SyoteGroup(
        view,
        new Teksti("Taimien välitys", "myyTaimiValitysKasviYhtOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Vihannekset", "myyKasviVihannes"), null, "myyTaimiValitysKasviVihannes", null, {toimintaId: "toimAlaPuutarhaTukku", ... myyTaimiValitysOptionsByValue}),
        new Syote(new Teksti("Vihannekset", "myyAvoTaimiVihannes"), null, "myyTaimiValitysAvoVihannes", null, {toimintaId: "toimAlaPuutarhaTukku", ... myyTaimiValitysOptionsByValue}),
        new Syote(new Teksti("Muut", "muutLajit"), null, "myyTaimiValitysMuu", null, {toimintaId: "toimAlaPuutarhaTukku", ... myyTaimiValitysOptionsByValue}),
      ),

      myyTaimiValitysAvo: new SyoteGroup(
        view,
        new Teksti("Taimien välitys (avomaan kasvit)", "myyTaimiValitysAvoYhtOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Perennat", "myyAvoTaimiPerenna"), null, "myyTaimiValitysAvoPerenna", null, {toimintaId: "toimAlaPuutarhaTukku", ... myyTaimiValitysOptionsByValue}),
        new Syote(new Teksti("Puuvartiset", "myyAvoTaimiPuuvart"), null, "myyTaimiValitysAvoPuuvart", null, {toimintaId: "toimAlaPuutarhaTukku", ... myyTaimiValitysOptionsByValue}),
        new Syote(new Teksti("Marjakasvit", "myyAvoTaimiMarja"), null, "myyTaimiValitysAvoMarja", null, {toimintaId: "toimAlaPuutarhaTukku", ... myyTaimiValitysOptionsByValue}),
      ),

      myyTaimiValitysSiemen: new SyoteGroup(
        view,
        new Teksti("Taimien välitys (siemenet)", "myyTaimiValitysSiemenYhtOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Koristekasvit", "myySiemenKoriste"), null, "myyTaimiValitysSiemenKoriste", null, {toimintaId: "toimAlaPuutarhaTukku"}),
        new Syote(new Teksti("Tomaatit", "myySiemenTomaatti"), null, "myyTaimiValitysSiemenTomaatti", null, {toimintaId: "toimAlaPuutarhaTukku"}),
      ),

      myyTaimiValitysKasvipassi: new SyoteGroup(
        view,
        new Teksti("Taimien välitys (kasvipassin käyttöoikeus)", "myyTaimiValitysKasviYhtOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Kasvipassi", "myyTaimiValitysKasvipassi"), null, null, null, {toimintaId: "toimAlaPuutarhaTukku"})
      ),

      myyViherrakentaminen: new SyoteGroup(
        view,
        new Teksti("Viherrakentaminen", "myyViherOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Viherrakentamiseen tarkoitettujen kasvien välitys", "myyViherValitys"), null, null, null, {toimintaId: "toimAlaPuutarhaViherrak"})
      ),

      myyEtamyynti: new SyoteGroup(
        view,
        new Teksti("Etämyynti", "myyEtamyyntiOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_OR_ATTACHMENT,
        new Syote(new Teksti("Etämyynti", "myyEtamyyntiKasvihuoneViljelma"), null, null, null, {toimintaId: 'toimAlaPuutarhaKasvi'}),
        new Syote(new Teksti("Etämyynti", "myyEtamyyntiTaimitarhaTaimi"), null, null, null, {toimintaId: 'toimAlaPuutarhaTarha'}),
        new Syote(new Teksti("Etämyynti", "myyEtamyyntiTaimimyymala"), null, null, null, {toimintaId: 'toimAlaPuutarhaMyymala'}),
        new Syote(new Teksti("Etämyyntiliite", "myyEtamyyntiLiite"), null, null,
        YhteenvetoTyyppi.ATTACHMENT,
        new Syote(new Teksti("Etämyyntiliite", "myyEtamyyntiLiite"))
        ),
      ),
      verkkokaupat: KasvinterveysRekisteriData.getVerkkokaupat(view, account),

      myyPuutavaraKasvipassi: new SyoteGroup(
        view,
        new Teksti("Kasvipassia vaativan puutavaran toimitus", "myyPuutavaraKasvipassiOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Toimipaikka harjoittaa puutavaran toimitusta", "myyPuutavaraKasvipassi"), null, null, null, {toimintaId: 'toimAlaPuuToimitus'})
      ),

      tuontiJaVienti: new SyoteGroup(
        view,
        new Teksti("Tuonti ja vienti", "tvOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS,
        new Syote(new Teksti("Avomaa", "tvAvoPuutarha"), null, null, null, tvOptionsByValue),
        new Syote(new Teksti("Kasvihuonekasvit", "tvKasvihuone"), null, null, null, tvOptionsByValue),
        new Syote(new Teksti("Hedelmät", "tvHedelma"), null, null, null, tvOptionsByValue),
        new Syote(new Teksti("Sahatuotteet", "tvSaha"), null, null, null, tvOptionsByValue),
        new Syote(new Teksti("Peruna", "tvPeruna"), null, null, null, tvOptionsByValue),
        new Syote(new Teksti("Muut", "tvMuut"), null, null, null, tvOptionsByValue),
      ),

      kasvitietPuutarha: new SyoteGroup(
        view,
        new Teksti("Kasvitieteelliset puutarhat", "tvKasvitietPuutarhaOtsikko"), null, null,
        YhteenvetoTyyppi.LABEL_ONLY,
        new Syote(new Teksti("Kasvien vienti ja tuonti", "tvKasvitietPuutarha"), null, null, null, {toimintaId: 'toimAlaPuutarhaKasvitiet'})
      ),

      omavalvonta: new SyoteGroup(
        view,
        new Teksti("Omavalvontasuunnitelma", "omavalvonta"), null, null,
        YhteenvetoTyyppi.ATTACHMENT,
        new Syote(new Teksti("Omavalvontasuunnitelma", "omavalvonta"))
      ),
    };
  }
}
