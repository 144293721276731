import {Component} from "@angular/core";

@Component({
  selector: 'tilapaisesti-pois-kaytosta',
  template: `
    <div>
      <h1 attribute="lomakeYleinen.poissaKaytosta">Tilapäisesti poissa käytöstä</h1>
      <p attribute="lomakeYleinen.ilmoitusPoisKaytosta">Vuosi-ilmoitusten jättäminen on tilapäisesti poissa käytöstä.
        Vuosi-ilmoituslomakkeet ovat päivitettävänä.</p>
    </div>
  `
})
export class TilapaisestiPoisKaytostaComponent {
}
