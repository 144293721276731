import {NgModule} from "@angular/core";
import {ButtonDirective} from "./button.directive";
import {SelectMenuComponent} from "./select-menu.component";
import { CommonModule } from '@angular/common';
import {ClickOutsideDirective} from "./click-outside-directive";
import {AttributeModule} from "../tiira/attribute.module";
import {BackgroundCardComponent} from "./background-card.component";
import {AlertBoxComponent} from "./alert-box.component";
import {ExcelListComponent} from "./excel-list.component";

@NgModule({
  declarations: [
    ButtonDirective,
    ClickOutsideDirective,
    SelectMenuComponent,
    BackgroundCardComponent,
    AlertBoxComponent,
    ExcelListComponent
  ],
  imports: [
    CommonModule,
    AttributeModule
  ],
  exports: [
    ButtonDirective,
    ClickOutsideDirective,
    SelectMenuComponent,
    BackgroundCardComponent,
    AlertBoxComponent,
    ExcelListComponent
  ],
  providers: [
  ]
})
export class ToukoComponentsModule {
}
