/**
 * Created by Seppo on 12/02/2018.
 */

/**
 * Created by Seppo on 22/11/2017.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, forwardRef, Input, Renderer2, ViewChild} from "@angular/core";
import {Syote} from "../syote";

type FieldValue = { checked: boolean; text: string } | null;

/**
 * Checkbox lisätietokentällä
 */
@Component({
  selector: 'touko-checkbox-text',
  template: `
    <div class="d-flex align-items-center mb-4">
      <input [id]="'check-' + item.name" [checked]="fieldValue?.checked" [value]="fieldValue?.checked" class="form-checkbox" type="checkbox" (click)="toggleValue($event.target.checked)"/>
      <label [id]="'label-' + item.name" [attribute]="labelKey" [htmlFor]="'check-' + item.name" class="ml-3 mb-0">Muu, mikä</label>
    </div>
    <input [attr.aria-labelledby]="'label-' + item.name" [id]="'text-' + item.name" class="form-control" type="text" [value]="fieldValue?.text" (keyup)="onTextChange($event.target.value)" #textInput />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxTextComponent),
    }
  ]
})
export class CheckboxTextComponent implements ControlValueAccessor {

  @Input() labelKey: string;
  @Input() groupName: string;
  @Input() item: Syote;
  @ViewChild('textInput', { static: true }) textInput: ElementRef;

  fieldValue: FieldValue = {checked: false, text: ""};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: FieldValue): void {
    if (!value) {
      this._onChange(null);
      this.setDisabledState(true);
      return;
    }

    value.text = value.text ? value.text : null;
    this.fieldValue = value;

    this._onChange(this.fieldValue);
    this.setDisabledState(!value.checked);
    this.setFocused();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this.textInput.nativeElement, 'disabled', isDisabled);
  }

  setFocused(): void {
    this.textInput.nativeElement.focus();
  }

  onTextChange(text: string) {
    const fieldVal: FieldValue = {checked: this.fieldValue.checked, text};
    this.writeValue(fieldVal);
  }

  toggleValue(value: boolean) {
    const text = this.fieldValue ? this.fieldValue.text : "";
    let fieldVal: FieldValue = {checked: value, text};
    if (!value) {
      fieldVal = null;
    }

    this.writeValue(fieldVal);
  }

}
