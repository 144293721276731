import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class TuotantosuunnatService {
  private tuotantosuunnat: string[] = [];
  private peltokasviSelectedSubject = new BehaviorSubject<boolean>(null)
  peltokasviSelected = this.peltokasviSelectedSubject.asObservable();
  private elaintuotantoSelectedSubject = new BehaviorSubject<boolean>(null)
  elaintuotantoSelected = this.elaintuotantoSelectedSubject.asObservable();

  addTuotantosuunta(value: string): void {
    if (!this.tuotantosuunnat.includes(value)) {
      this.tuotantosuunnat.push(value);
      if (value === 'peltokasvi') this.peltokasviSelectedSubject.next(true);
      if (value === 'elaintuotanto') this.elaintuotantoSelectedSubject.next(true);
    }
  }

  removeTuotantosuunta(value: string): void {
    this.tuotantosuunnat = this.tuotantosuunnat.filter(v => v !== value);
    if (value === 'peltokasvi') this.peltokasviSelectedSubject.next(false);
    if (value === 'elaintuotanto') this.elaintuotantoSelectedSubject.next(false);
  }
}
