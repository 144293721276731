import {AbstractLaheteGroup} from "../abstract-lahete-group";
import {QuestionControl, QuestionGroup} from "ee-lahete";
import {GroupBuilder} from "../group-build-options";
import {InitValueType, QuestionInitValue} from "../../../../../../projects/ee-lahete/src/lib/question/question-model";

export class SahkoinenAsiointiGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'sahkoinenAsiointi'})): QuestionGroup {
    const group = new QuestionGroup(
        {
          label: this.getLabel('sahkoinenAsiointi'),
          key: 'sahkoinenAsiointi',
          controls: [
            this.createCheckbox('turvaposti', this.getLabel('sahkopostiRadio')),
          this.createCheckbox('posti', this.getLabel('postiRadio'))
        ]
      }
    );

    if (builder.applyValues) {
      this.laheteService.registerInitialValue(new QuestionInitValue(group, builder.initValue(val => val.sahkoinenAsiointi), InitValueType.PATCH));
    }
    return group;
  }

  protected createCheckbox(key: string, label: string): QuestionControl {
    return new QuestionControl({
      key: key,
      label: label,
      htmlId: `${key}`,
      initialValue: false,
      controlType: 'checkbox'
    });
  }
}
