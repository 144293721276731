<ng-container *ngIf="item.subitems">
  <h3 [attribute]="item.label.fullKey">Lannoitevalmisteen maahantuonti</h3>
  <lannoite-uusi-ilmoitettava-toiminta *ngIf="item.options.isMuutosIlmoitus"
                                       [item]="item"></lannoite-uusi-ilmoitettava-toiminta>
  <ng-container *ngFor="let subItem of item.subitems" [ngSwitch]="subItem.summaryType">
    <div class="row" *ngSwitchCase="yhteenvetoTyyppi.LABEL_AND_VALUE">
      <div class="col-full col-lvl-1">
        <h4 [attribute]="subItem?.label?.fullKey">Orgaaniset lannoitteet</h4>
      </div>
      <div class="col-left col-lvl-2">
        <p [attribute]="subItem.valueMap?.maaraTeksti?.fullKey">Maahantuotava määrä</p>
      </div>
      <div class="col-right">
        <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
          <span id="{{item.label.key}}-{{subItem?.label?.key}}">{{subItem.valueMap?.maara?.label}} </span>
          <span attribute="lannoiterekisteri.kgVuosi"></span>
        </div>
      </div>
    </div>
    <ng-container *ngSwitchCase="yhteenvetoTyyppi.LABEL_AND_VALUE_ARRAY">
      <div class="row">
      <ng-container *ngIf="subItem.valueMap.tyyppiNimi">
        <div class="col-full col-lvl-1" *ngIf="subItem?.label">
          <h4 [attribute]="subItem?.label?.fullKey"></h4>
        </div>
        <div class="col-left col-lvl-2">
          <p id="{{subItem.valueMap?.tyyppiNimi.key}}-nimi-{{subItem.htmlId}}" [attribute]="subItem.valueMap?.tyyppiNimi?.fullKey"></p>
        </div>
        <div class="col-right">
          <div [ngClass]="showCompareYhteenveto ? itemStyle : null" class="yhteenveto-arvo d-inline">
            <span id="{{subItem.valueMap?.tyyppiNimi.key}}-maara-{{subItem.htmlId}}">{{subItem.valueMap.maara.label}} </span>
            <span attribute="lannoiterekisteri.kgVuosi"></span>
          </div>
        </div>
      </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
