import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {ResultType} from "./field-summary-compare/compare.service";
import {LiitetiedostoResponse} from "../touko-lomake-utils";
import {LiitetiedostoPalvelinService} from "../touko-liitetiedosto.service";

@Component({
  selector: 'liite-summary',
  template: `
    <div *ngIf="item" class="row">
      <div *ngIf="item.question" class="col-4">
        <span [id]="'label-' + htmlId" [attribute]="item.question"></span>
      </div>
      <div class="col d-flex align-items-center">
        <compare-item [compareItemType]="compareItemType">
          <span [id]="'value-' + htmlId" class="answer">{{ item?.answer.tiedostonimi }}</span>
        </compare-item>
        <div>
          <button class="btn btn-sm btn-outline-dark ml-2"
                  [attr.aria-label]="'lomakeYleinen.esikatseleLiite' | attribute" type="button"
                  (click)="clickOpenLiite(item.answer)">
            <span class="fa fa-eye"></span>
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./summary.scss']
})
export class LiiteSummaryComponent {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;

  constructor(private liitetiedostoService: LiitetiedostoPalvelinService) {
  }

  clickOpenLiite(item: LiitetiedostoResponse) {
    return this.liitetiedostoService.downloadLiitetiedosto(item.id)
      .then(blob => {
        if (this.isIE()) {
          const nav = (window.navigator as any);
          nav.msSaveOrOpenBlob(blob, item.tiedostonimi);
        } else {
          const file = window.URL.createObjectURL(blob);
          window.open(file, "_blank");
        }
      });
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }

  get htmlId() {
    return this.item?.options?.htmlId || this.item?.field?.htmlId;
  }
}
