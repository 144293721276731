import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FieldControl} from "ngx-fielding";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalSimpleComponent} from "../../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../../modaalit/modal-utils";
import {Teksti} from "../../../utils/teksti";

@Component({
  selector: 'tuotantosuunta-checkbox',
  template: `
    <touko-checkbox [htmlId]="tuotantosuuntaControl.field.htmlId"
                    [labelAttribute]="tuotantosuuntaControl.field.label"
                    [formControl]="tuotantosuuntaControl"
                    (click)="toggleCheckbox($event)"></touko-checkbox>
  `,
})

export class TuotantosuunnatComponent {
  @Input() selectedTuotantosuuntaHasValue: boolean;
  @Input() tuotantosuuntaControl: FieldControl
  @Output() tuotantosuuntaWithDataUnselected = new EventEmitter<string>();

  constructor(private modalService: NgbModal) {
  }

  toggleCheckbox(event) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;

    if (!isChecked && this.selectedTuotantosuuntaHasValue) {
      event.preventDefault();
      this.openModal();
    }
  }

  openModal() {
    const modalRef = this.modalService.open(ModalSimpleComponent);
    const okFn = () => new Promise((val) => {
      modalRef.close('Ok');
      this.tuotantosuuntaWithDataUnselected.emit(this.tuotantosuuntaControl.field.htmlId);
      this.tuotantosuuntaControl.setValue(false);
      return val;
    });

    modalRef.componentInstance.modalTiedot = new ModalData(
        new Teksti('Valinnan muuttaminen vaikuttaa lomakkeelle annettuihin tietoihin',
            'poistaTuotantosuuntaOtsikko',
            'luomu'),
        new Teksti('Poistettavaan tuotantosuuntaan löytyi annettuja tietoja lomakkeelta. Valitse "Tyhjennä", mikäli haluat tyhjentää tuotantosuuntaan liittyvät tiedot.',
            'poistaTuotantosuuntaInfo',
            'luomu'),
        new Teksti('Tyhjennä',
            'poistaTuotantosuuntaButton',
            'luomu'),
        okFn,
        "btn-danger"
    );
  }
}
