import {Teksti} from "../../../utils/teksti";
import {Allekirjoitus, LomakeResponse, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {Account} from "../../../account/account";
import {PerustiedotSkipValidationOptions} from "./lomake-fields/perustiedot";
import {EventEmitter} from "@angular/core";

export interface LomakeInfo {
  skipPerustiedotValidation?: PerustiedotSkipValidationOptions;
  lomakeTitle: Teksti;
  asia: string;
  toimintotyyppi: string;
  serverBaseUrl: string;
  clientComponentVersion: 'v1' | 'v2'

  yhteyshenkiloRoolit: YhteyshenkiloValue[];
  defaultRooli: string;
  ohjeet: { [s: string]: string[] };
}

export class LomakeMetaData {
  idChange = new EventEmitter<number>();

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    if (!this._id) {
      this._id = value;
      this.idChange.emit(this._id);
    }
  }

  get allekirjoitus(): Allekirjoitus {
    return this._allekirjoitus;
  }
  get account(): Account {
    return this._account;
  }

  get isViranomainen(): boolean {
    return Account.isViranomainen(this._account);
  }

  get isAsiakas(): boolean {
    return Account.isAsiakas(this._account);
  }

  constructor(account: Account, lomakeResponse?: LomakeResponse) {
    this._account = account;
    if (lomakeResponse) {
      this._id = lomakeResponse.id;
      this._allekirjoitus = Allekirjoitus.createFromLomake(lomakeResponse);
    } else {
      this._allekirjoitus = Allekirjoitus.createFromAccount(account);
    }
  }

  private _id: number;
  private _account: Account;
  private _allekirjoitus: Allekirjoitus;
}
