import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";
import {LaheteService} from "../lahete.service";

export class LaheteTunnusGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'id'})): QuestionGroup {

    return new QuestionGroup(
        {
          label: this.getLabel('laheteTunnus'),
        key: 'lahete',
        controls: [
          new QuestionControl({
            key: 'id',
            htmlId: 'laheteId',
            label: this.getLabel('Tunnus'),
            controlType: builder.controlType,
            initialValue: builder.initValue(val => val.lahete.id, LaheteService.createTunnus()),
          })
        ]
      }
    );
  }

}
