<hr *ngIf="results">

<div *ngIf="results?.violations" id="axe-results" class="small">
  <ng-container *ngFor="let violation of results.violations">
    <p class="mb-0"><strong>{{violation.description}}</strong> [{{violation.id}}] [{{violation.impact}}]</p>
    <p>{{violation.help}} <a [href]="violation.helpUrl"><span class="fa fa-arrow-right"></span> See help</a></p>
    <ul>
      <li class="list-unstyled" *ngFor="let node of violation.nodes">
        <p>
          {{node.target[0]}} <button class="btn btn-sm btn-outline-primary" (click)="toggleElementHighlight(node)">Näytä elementti</button>
        </p>
        <p>{{node.failureSummary}}</p>
        <pre>{{node.html}}</pre>
      </li>
    </ul>
  </ng-container>
</div>

<div *ngIf="results" id="axe-actions">
  <button class="btn btn-sm btn-secondary mb-4 mr-2" (click)="toggleJSON()">Näytä/piilota JSON-muotoinen data</button>
  <button class="btn btn-sm btn-secondary mb-4 mr-2" (click)="clearHighlights()">Poista elementtien korostukset</button>
  <button class="btn btn-sm btn-danger mb-4" (click)="hideResults()">Piilota saavutettavuustulokset</button>
  <pre *ngIf="showJSON" style="white-space: pre-wrap">{{results?.violations | json}}</pre>
</div>
