import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DateObj} from "../touko-lomake/syote/syote-utils";

/**
 * kuukausi/vuosi -komponentti
 */

interface DateRange {
  yearsForward: number;
  yearsBackward: number;
}

@Component({
  selector: 'touko-kuukausi-pvm',
  template: `
    <div class="input-group mb-5">
      <div class="input-group-prepend mr-2">
        <select #monthInput class="form-control mb-0" [attr.aria-labelledby]="id + '-' + idSuffix"
                [id]="id + '-month-' + idSuffix" [disabled]="disabled" (change)="setMonth($event.currentTarget.value)">
          <option *ngFor="let month of months; index as i" [value]="i + 1" [selected]="i + 1 === fieldValue.month"
                  [attribute]="'siemenpakkaamo.' + month"></option>
        </select>
      </div>
      <div class="mr-2">
        <select #yearInput class="form-control mb-0" [attr.aria-labelledby]="id + '-' + idSuffix"
                [id]="id + '-year-' + idSuffix" [disabled]="disabled" (change)="setYear($event.currentTarget.value)" data-toggle="dropdown">
          <option *ngFor="let year of years" [value]="year" [selected]="year === fieldValue.year">{{year}}</option>
        </select>
      </div>
      <div class="input-group-append mr-2">
        <button #resetInput class="btn btn-outline-primary" type="button"
                attribute="siemenpakkaamo.palautaKuluvaKuukausi" (click)="clearDate()">Palauta kuluva kuukausi
        </button>
      </div>
      <div class="input-group-append">
        <button class="btn btn-outline-danger"
                type="button" attribute="siemenpakkaamo.btnPoistaLaite"
                (click)="removeDate()" [disabled]="isRemoveDisabled">Poista laite
        </button>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KuukausiPvmComponent),
    }
  ]
})
export class KuukausiPvmComponent implements ControlValueAccessor, OnInit {
  @Input() disabled: boolean = false;
  @Input() idSuffix: string;
  @Input() id: string;
  @Input() isRemoveDisabled: boolean;
  @Input() rangeOfYears: DateRange = {yearsForward: 1, yearsBackward: 10};
  @Output() removeItem: EventEmitter<void> = new EventEmitter<void>();
  fieldValue: null | DateObj;
  years: Array<number>;
  months: Array<string>;
  @ViewChild("monthInput", {static: true}) monthInput: ElementRef;
  @ViewChild("yearInput", {static: true}) yearInput: ElementRef;
  @ViewChild("resetInput", {static: true}) resetInput: ElementRef;

  constructor(private readonly _renderer: Renderer2, private readonly _elementRef: ElementRef) {
    this.months = ["tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "toukokuu", "kesakuu", "heinakuu",
      "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"];
  }

  ngOnInit() {
    if (this.rangeOfYears) {
      this.setRange();
    }
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  setMonth(month: number) {
    const selectedYear = this.fieldValue?.year || DateObj.today().year;
    const newValue = new DateObj(1, Number(month), selectedYear);
    this.writeValue(newValue);
  }

  setYear(year: number) {
    const selectedMonth = this.fieldValue?.month || DateObj.today().month;
    const newValue = new DateObj(1, selectedMonth, Number(year));
    this.writeValue(newValue);
  }

  clearDate() {
    this.writeValue(DateObj.firstDayOfMonth());
  }

  setRange() {
    const currentYear = DateObj.today().year;
    const range = (yearsInFuture, yearsInPast) => Array.from({length: (yearsInFuture - yearsInPast) + 1}, (_, i) => yearsInFuture - i);
    this.years = range(currentYear + this.rangeOfYears.yearsForward, currentYear - this.rangeOfYears.yearsBackward);
  }

  removeDate() {
    this.removeItem.emit();
  }

  writeValue(value: DateObj): void {
    this.fieldValue = value;
    this._onChange(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._renderer.setProperty(this.monthInput.nativeElement, 'disabled', isDisabled);
    this._renderer.setProperty(this.yearInput.nativeElement, 'disabled', isDisabled);
    this._renderer.setProperty(this.resetInput.nativeElement, 'disabled', isDisabled);
  }
}
