import {FieldGroup} from "ngx-fielding";
import {RehuvalvontaV2Data} from "../../rehuvalvonta/rehuvalvonta-v2-data";
import {RehuvuosiilmoitusData} from "../../rehuvuosiilmoitus/rehuvuosiilmoitus-data";
import {LannoitevuosiilmoitusData} from "../../lannoitevuosiilmoitus/lannoitevuosiilmoitus-data";
import {Injectable} from "@angular/core";
import {SiemenrekisteriV2Data} from "../../siemenrekisteri/siemenrekisteri-v2-data.service";
import {SiemenpakkaamoV2Data} from "../../pakkaamolupahakemus/siemenpakkaamo-v2.data";
import {LannoiterekisteriV2Data} from "../../lannoiterekisteri/lannoiterekisteri-v2-data";
import {Luomupoikkeus9cV2Data} from "../../../luomu-poikkeus9c/luomupoikkeus9c-v2-data";
import {LuomuvalvontaData} from "../../luomuvalvonta/luomuvalvonta-data";
import {KasvinterveysrekisteriV2Data} from "../../kasvinterveysrekisteri/kasvinterveysrekisteri-v2-data";

export interface LomakeV2Data {
  getForm(toimintotyyppi?: string, tarkastus?: boolean): Promise<FieldGroup>;
}

@Injectable()
export class LomakeDataResolver {

  private dataMap = new Map<string, LomakeV2Data>();

  constructor(private rehuvalvonta: RehuvalvontaV2Data,
              private rehuvuosiilmoitus: RehuvuosiilmoitusData,
              private lannoitevuosiilmoitus: LannoitevuosiilmoitusData,
              private siemenrekisteri: SiemenrekisteriV2Data,
              private pakkaamolupahakemus: SiemenpakkaamoV2Data,
              private lannoiterekisteri: LannoiterekisteriV2Data,
              private luomupoikkeus9c: Luomupoikkeus9cV2Data,
              private luomuvalvonta: LuomuvalvontaData,
              private kasvinterveysrekisteri: KasvinterveysrekisteriV2Data) {
    this.dataMap.set('rehuvalvonta', rehuvalvonta);
    this.dataMap.set('rehuvuosiilmoitus', rehuvuosiilmoitus);
    this.dataMap.set('lannoitevuosiilmoitus', lannoitevuosiilmoitus);
    this.dataMap.set('kylvosiemen-markkinointi', siemenrekisteri);
    this.dataMap.set('siemenpakkaamolupa', pakkaamolupahakemus);
    this.dataMap.set('lannoiterekisteri', lannoiterekisteri);
    this.dataMap.set('luomupoikkeus9c', luomupoikkeus9c);
    this.dataMap.set('luomuvalvonta', luomuvalvonta);
    this.dataMap.set('kasvinterveysrekisteri', kasvinterveysrekisteri);
  }

  getLomakeData(asia: string): LomakeV2Data {
    return this.dataMap.get(asia);
  }
}
