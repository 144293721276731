import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";
import {TranslatedQuestionSummaryComponent} from "../../summary/translated-question-summary.component";
import {
  TranslatedQuestionAndAnswerWithInfoSummaryComponent
} from "../../summary/translated-question-and-answer-with-info-summary.component";

const attr = TulkkiObject.getCreateFn('rehuvalvonta')

export const createToiminnat = () => new FieldGroup(
  Field.build(
    attr('toiminnat'),
    createTitleSummary(isAnyToimintaValittu),
    {atLeastOneSelected: attr('vahintaanYksi')},
  ), {
    lisaaineidenValmistaja: createToimintaWithLisakysymys('lisaaineidenValmistaja', 'valmistetutRehunLisaaineet'),
    rehuaineidenValmistaja: createToimintaWithLisakysymys('rehuaineidenValmistaja', 'valmistetutRehuaineet'),
    rehuseoksenValmistaja: createToiminta('rehuseoksenValmistaja'),
    erityisRehunValmistaja: createToiminta('erityisRehunValmistaja'),
    esiseostenValmistaja: createToiminta('esiseostenValmistaja'),
    rahtisekoittaja: createToiminta('rahtisekoittaja'),
    puhdiskasittelylaitos: createToiminta('puhdiskasittelylaitos'),
    vahittaiskauppa: createToiminta('vahittaiskauppa'),
    tukkukauppa: createToiminta('tukkukauppa'),
    sisamarkkinatuoja: createToimintaWithLisakysymys('sisamarkkinatuoja', 'arvioSisamarkkinaRehuista'),
    muuMarkkinoilleSaattaja: createToiminta('muuMarkkinoilleSaattaja'),
    maahantuojaKolmasMaa: createToimintaWithLisakysymys('maahantuojaKolmasMaa', 'arvioMaahantuotavistaRehuista'),
    vieja: createToimintaWithLisakysymys('vieja', 'arvioEustaVietavistaRehuista'),
    irtorehunVarastointiliike: createToiminta('irtorehunVarastointiliike',),
    pakatunRehunVarastointiliike: createToiminta('pakatunRehunVarastointiliike',),
    irtorehunKuljetusliike: createToimintaWithLisakysymys('irtorehunKuljetusliike', 'irtorehuaKuljettavienAutojenLkm'),
    pakatunRehunKuljetusliike: createToimintaWithLisakysymys('pakatunRehunKuljetusliike', 'pakattuaRehuaKuljettavienAutojenLkm')
  },
  (group) => isAnyToimintaValittu(group as FieldGroup) ? null : {atLeastOneSelected: true}
  );

const createToiminta = (valittu): FieldGroup => {
  return new FieldGroup(Field.build(null), {
    valittu: new FieldControl(Field.of({
      label: attr(valittu),
      htmlId: valittu,
      summaryFn: (control) => new SummaryItem<any>(control.field.label, null, {show: control.value}, TranslatedQuestionSummaryComponent)
    }))
  });
}

const createToimintaWithLisakysymys = (valittu, arvo): FieldGroup => {
  return  new FieldGroup(
    Field.of({
      summaryFn: (group) => {
        const valittu = group.get('valittu');
        if (valittu.value) {
          const arvo = group.get('arvo')
          return new SummaryItem<any>(
            valittu.field.label,
            {translatedPart: arvo.field.label, userprovidedPart: arvo.value},
            {show: true},
            TranslatedQuestionAndAnswerWithInfoSummaryComponent)
        }
        return new SummaryItem<any>(null, null, {show: false}, TranslatedQuestionAndAnswerWithInfoSummaryComponent);
      },
      htmlId: valittu,
    }), {
      valittu: new FieldControl(Field.of({
        label: attr(valittu),
        htmlId: valittu,
      })),
      arvo: new FieldControl(Field.of({
        label: attr(arvo),
        htmlId: arvo,
      }))
    });
}

export const createTuotetyypit = (): FieldGroup => {
  return new FieldGroup(
    Field.build(attr('tuotetyypit'),
      createTitleSummary(isAnyTruthy),
      {atLeastOneSelected: attr('vahintaanYksi')}
    ),
    {
      lemmikkielaintenRuuat: new FieldControl(Field.of({label: attr('lemmikkielaintenRuuat'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppilemmikkielaintenRuuat'})),
      sivutuoteLuokka2: new FieldControl(Field.of({label: attr('sivutuoteLuokka2'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppisivutuoteLuokka2'})),
      sivutuoteLuokka3: new FieldControl(Field.of({label: attr('sivutuoteLuokka3'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppisivutuoteLuokka3'})),
      kalajauhoJaMuutElainperaiset: new FieldControl(Field.of({label: attr('kalajauhoJaMuutElainperaiset'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppikalajauhoJaMuutElainperaiset'})),
      elintarvikeTuotantoRehut: new FieldControl(Field.of({label: attr('elintarvikeTuotantoRehut'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppielintarvikeTuotantoRehut'})),
      turkiselainTuotantoRehut: new FieldControl(Field.of({label: attr('turkiselainTuotantoRehut'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppiturkiselainTuotantoRehut'})),
      laakerehut: new FieldControl(Field.of({label: attr('laakerehut'), summaryFn: checkboxSummary, htmlId: 'tuotetyyppilaakerehut'}))
    },
    (group) => isAnyTruthy(group as FieldGroup) ? null : {atLeastOneSelected: true}
  );
}

export const createLaitostenLisatiedot = (): FieldGroup => {
  return new FieldGroup(Field.build(attr('lisatietojaHyvaksyttavistaLaitoksista'),
    createTitleSummary(isAnyTruthy)
  ), {
    raakakasvioljy: new FieldControl(Field.of({label: attr('raakakasvioljy'), summaryFn: checkboxSummary, htmlId: 'lisatiedotraakakasvioljy'})),
    rasvahapot: new FieldControl(Field.of({label: attr('rasvahapot'), summaryFn: checkboxSummary, htmlId: 'lisatiedotrasvahapot'})),
    biodiesel: new FieldControl(Field.of({label: attr('biodiesel'), summaryFn: checkboxSummary, htmlId: 'lisatiedotbiodiesel'})),
    rasvojenSekoittaja: new FieldControl(Field.of({label: attr('rasvojenSekoittaja'), summaryFn: checkboxSummary, htmlId: 'lisatiedotrasvojenSekoittaja'}))
  });
}

const createTitleSummary = (showFn: (group: FieldGroup) => boolean): SummaryFnType => {
  return (fieldGroup) => new SummaryItem<any>(
    fieldGroup.field.label,
    null,
    {show: showFn(fieldGroup.asGroup())},
    TitleSummaryComponent)
}

const isAnyToimintaValittu = (group: FieldGroup) => {
  return Object.values(group.value).some((val: any) => val?.valittu)
}

const isAnyTruthy = (group: FieldGroup) => {
  return Object.values(group.value).some(isChecked => isChecked)
}

const checkboxSummary: SummaryFnType = control => new SummaryItem<any>(control.field.label, null, {show: control.value}, TranslatedQuestionSummaryComponent);
