import {SyoteGroup} from "../syote/syote-group";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";
import {Teksti} from "../../utils/teksti";
import {Validators} from "@angular/forms";
import {ToukoValidation} from "../validators/validators";
import {DateObj} from "../syote/syote-utils";
import {Syote} from "../syote/syote";
import {Account} from "../../account/account";
import {LomakeData, LomakeDataContent} from "../lomake-data";
import {YhteyshenkiloValue} from "../touko-lomake-utils";

/**
 * Created by Seppo on 09/01/2019.
 */

export class RekisteriLopetusData extends LomakeData {
  static getData(account?: Account): LomakeDataContent {
    const VIEW = 'lomakeYleinen';

    const tekstitByToimiala = {
      DEFAULT: {
        lopetusToiminta: new Teksti("Lopetusvuoden tiedot", "lopetusTiedot", VIEW),
        lopetusPvm: new Teksti("Arvioitu lopetuspäivämäärä", "arvioituLopetus", VIEW),
        lisatiedot: new Teksti("Muut lisätiedot", "lopetusMuutLisatiedot", VIEW),
        lopetusEiValmistusta: new Teksti("Lopetusvuoden toiminta", "lopetusEiValmistusta", VIEW)
      },
      SIEMEN: {
        lopetusToiminta: null,
        lopetusPvm:  new Teksti("Toiminnan lopettamisen päivämäärä", "merkitseToiminnanLopetus", VIEW),
        lisatiedot: new Teksti("Lisätiedot", "lopetusLisatiedot", VIEW)
      },
      REHU: {
        lopetusPvm: new Teksti("Ei rehualan toimintaa alkaen", "arvioituLopetusRehu", VIEW)
      },
      LANNOITE: {},
      KATE: {
        lopetusToiminta: new Teksti("Selvitys toiminnan päättymisestä", "lopetusTiedotKate", VIEW),
        lopetusEiValmistusta: new Teksti("Ei valmistusta tai tuontia", "lopetusEiValmistustaKate")
      }
    };

    const getTekstiFn = (toimiala, key) => {
        if (tekstitByToimiala[toimiala].hasOwnProperty(key)) {
          return tekstitByToimiala[toimiala][key];
        } else {
          return tekstitByToimiala.DEFAULT[key];
        }
    };

    const errLopetusPvmTeksti = new Teksti("Anna lopetuspäivämäärä", "errLopetusPvm", VIEW);
    const data = {
      lopetustiedot: new SyoteGroup(
        VIEW,
        null, null, {
          [ToukoValidation.PVM_VALID]: errLopetusPvmTeksti,
          required: errLopetusPvmTeksti
        }, YhteenvetoTyyppi.SINGLE_SELECTION_DATE_AND_LABEL,
        new Syote(getTekstiFn(account.toimiala, "lopetusPvm"), [DateObj.today(), [ToukoValidation.isValidDate, Validators.required]], 'lopetusPvm'),
      ),
      lopetustoiminta: new SyoteGroup(
        VIEW,
        getTekstiFn(account.toimiala, "lopetusToiminta"), [ToukoValidation.isValidLopetusilmoitusLisatieto],
        {
          [ToukoValidation.VALID_LOPETUSILMOITUS_LISATIETO]: new Teksti("Ilmoita lopetusvuoden toiminta", "errIlmoitaLopetustoiminta", VIEW)
        },
        YhteenvetoTyyppi.SINGLE_SELECTION_LABEL,
        new Syote(getTekstiFn(account.toimiala, "lopetusEiValmistusta"), null, 'radio', 'EI_MUUTOKSIA'),
        new Syote(new Teksti("Valmistustiedot lisätiedoissa", "lopetusTiedotLisatiedoissa", VIEW), null, 'radio', 'MUUTOKSIA')
      ),
      lisatiedot: new SyoteGroup(
        VIEW,
        null, null, {}, YhteenvetoTyyppi.LABEL_AND_VALUE,
        new Syote(getTekstiFn(account.toimiala, "lisatiedot"), null, 'lisatieto')
      ),
      yhteyshenkilot: this.getYhteyshenkilot(VIEW, account, null, YhteyshenkiloValue.TOUKO_LOPETUS.value)
    };

    data.yhteyshenkilot.title = new Teksti("Ilmoituksen yhteyshenkilot", "ilmoYhteyshenkilo", VIEW);

    return data;
  }
}
