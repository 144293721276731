import {Component, OnInit} from "@angular/core";
import {PalveluService} from "./palvelu.service";
import {AccountService} from "../account/account.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Teksti} from "../utils/teksti";
import {Account, KayttajaRooli, Toimiala} from "../account/account";
import {ModalJatkaComponent} from "../modaalit/lomake/modal-jatka.component";
import {Subscription} from "rxjs";
import {LomakeMenuItem, SidebarLinks} from "./sidebar-links";
import {LomakeHakuService} from "../touko-lomake/lomake-haku.service";
import {EnvironmentService} from "../../environments/environment.service";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";
import {PalveluTila} from "../utils/palvelu-tila";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 02/11/2017.
 */

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
@AutoUnsubscribe
export class SidebarComponent implements OnInit {

  user: Account = null;
  KayttajaRooli = KayttajaRooli;
  private readonly viewKey = "sivupalkki";
  toimiala = "";
  links = [];
  vkLinks = [];
  subs: Subscription[] = [];
  sidebarLinks = null;

  constructor(public accountService: AccountService,
              public readonly router: Router,
              private readonly sidebarService: PalveluService,
              private readonly ngbModalService: NgbModal,
              private readonly lomakeHakuService: LomakeHakuService,
              private readonly environmentService: EnvironmentService) {

    this.sidebarLinks = new SidebarLinks(environmentService);

  }

  ngOnInit() {
    this.subs.push(this.sidebarService.palvelut$.subscribe(palvelut => {
      if (palvelut === null || this.user === null) {
        return;
      }
      if (this.user.toimiala === Toimiala.LUOMU && this.user.kayttajaRooli === KayttajaRooli.ASIAKAS_HENKILO) {
        palvelut.palvelut.forEach(palvelu => {
          if (palvelu.toimintotyyppi === "UUSI") {
            palvelu.tila = PalveluTila.PIILOTETTU
          }
        })
      }
      this.links = this.sidebarLinks.getSidebarLinks(palvelut, this.user);
    }));

    this.subs.push(this.accountService.account$.subscribe(account => {
      this.user = account;
      if (account === null) {
        return;
      }

      if (this.accountService.isViranomainen() && this.user.toimiala !== null) {
        this.initVirkailijaLinks();
      }

      this.toimiala = account.toimiala;
      if (account.toimiala) {
        this.sidebarService.updatePalvelut();
      }
    }));

  }

  initVirkailijaLinks() {
    const raporttisivut = this.sidebarLinks.getVirkailijaRaporttiLinks();

    this.vkLinks = [
      {
        title: new Teksti("Saapuneet", "vkSaapuneet", this.viewKey),
        link: "/asiointi/etusivu",
        notifications: 0
      },
      {
        title: new Teksti("Saapuneet", "vkVirheelliset", this.viewKey),
        link: "/asiointi/lomakeloki",
        notifications: 0
      },
    ];

    if (this.user.toimiala && raporttisivut.hasOwnProperty(this.user.toimiala)) {
      raporttisivut[this.user.toimiala].forEach(raporttisivu => {
        this.vkLinks.push(raporttisivu);
      });
    }
  }

  clickLink(item: LomakeMenuItem) {
    if (this.router.url.includes(item.link) || item.tila === 'EI_AKTIIVINEN') {
      return;
    }

    if (item.keskeneraisenId && item.toimintotyyppi !== "UUSI" && item.tilallinenLinkki) {
      this.openAndHandleModal(item);
    } else if (item.tilallinenLinkki) {
      if (item.toimintotyyppi !== "LOPETUS") {
        this.navigateToCreatedLomake(item);
      } else {
        this.router.navigate([item.link]);
      }
    } else {
      this.router.navigate([item.link])
    }
  }

  clickHeaderLink(item: LomakeMenuItem) {
    for (let subItem of item.sublinks) {
      subItem.isCollapsed = !subItem.isCollapsed;
    }
    this.initVirkailijaLinks();
  }

  openAndHandleModal(item: LomakeMenuItem) {
    const modalRef = this.ngbModalService.open(ModalJatkaComponent);
    modalRef.componentInstance.item = item;
    modalRef.result.then(
        result => {
          if (result === 'JATKA') {
            this.navigateToExistingLomake(item);
          } else if (result === 'UUSI') {
            if (LomakeEndpointUtil.shouldUseV2Endpoint(item.asia, item.lomaketyyppiVersio)) {
              this.lomakeHakuService.deleteKeskenerainenIlmoitusV2(item.keskeneraisenId, item.toimintotyyppi.toLowerCase(), item.asia)
                  .then(() => this.navigateToCreatedLomake(item));
            } else {
              this.lomakeHakuService.deleteKeskenerainenIlmoitus(item.keskeneraisenId)
                  .then(() => this.navigateToCreatedLomake(item));
            }
          }
        },
        _reason => null
    );
  }

  lomakeLinksPiilotettu(lomakeLinks): boolean {
    return lomakeLinks.every(link => link.tila === 'PIILOTETTU')
  }

  private navigateToCreatedLomake(item: LomakeMenuItem): void {
    const continueLink = item.link;
    const lomakeId = item.voimassaId || 0;
    this.router.navigate([continueLink, lomakeId, 'aloita']);
  }

  private navigateToExistingLomake(item: LomakeMenuItem): void {
    this.router.navigate([item.link, item.keskeneraisenId, 'jatka']);
  }
}
