import {Component, Input} from '@angular/core';
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "../summary-container.component";
import {ResultType} from "../field-summary-compare/compare.service";

@Component({
  selector: 'yhteyshenkilo-summary',
  styleUrls: ['../summary.scss'],
  template: `
    <div *ngIf="!compareItemType && item.answer?.elmoStatus === 'ACTIVE'">
          <div class="row pb-2">
              <div class="col-lg-4 col-5 py-0 my-0">
                  <span attribute="lomakeYleinen.nimi" [id]="'label-nimi-' + item.field.htmlId">Nimi</span>
              </div>
              <div class="col">
                  <span class="answer" [id]="'value-nimi-' + item.field.htmlId">{{item.answer?.nimi || '–'}}</span>
              </div>
          </div>
          <div class="row pb-2">
              <div class="col-lg-4 col-5 py-0 my-0">
                  <span [id]="'label-yhteystiedot-' + item.field.htmlId" attribute="lomakeYleinen.yhteystiedot">Yhteystiedot</span>
              </div>
              <div class="col">
        <span class="answer">
          <span [id]="'value-email-' + item.field.htmlId">{{item.answer?.email || '–'}}</span>, <br>
          <span [id]="'value-puhelin-' + item.field.htmlId">{{item.answer?.puhelin || '–'}}</span>
        </span>
              </div>
          </div>
          <div class="row pb-2">
              <div class="col-lg-4 col-5 py-0 my-0">
                  <span [id]="'label-rooli-' + item.field.htmlId" attribute="lomakeYleinen.yhteyshenkilonRooli">Yhteyshenkilön rooli</span>
              </div>
              <div class="col">
        <span [id]="'value-rooli-' + item.field.htmlId" class="answer" [attribute]="item.answer?.rooli || '–'">
        </span>
              </div>
          </div>
      </div>
    <div class="mb-2">
          <compare-item *ngIf="compareItemType" [compareItemType]="compareItemType">
            <div class="row pb-2">
              <div class="answer col">{{item.answer?.nimi || '–'}}</div>
            </div>
            <div class="row pb-2">
              <div class="answer col">
                {{item.answer?.email || '–'}}, <br>
                {{item.answer?.puhelin || '–'}}
              </div>
            </div>
            <div class="row pb-2">
              <div class="answer col" [attribute]="item.answer?.rooli || '–'">
              </div>
              </div>
          </compare-item>
      </div>
  `
})
export class YhteyshenkiloSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
