/**
 * Created by Seppo on 02/11/2018.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from "@angular/core";
import {Yhteyshenkilo} from "../../touko-lomake/syote/syote-utils";
import {YhteyshenkiloValue} from "../../touko-lomake/touko-lomake-utils";
import {Option} from "../../touko-components/select-menu.component";
import {TextInputComponent} from "../text-input.component";
import {YhteyshenkiloViewOptions} from "./yhteyshenkilo-view-options";

@Component({
  selector: "yhteyshenkilot-input",
  template: `
    <ng-container *ngFor="let yhteyshenkilo of fieldValue; index as i; trackBy:trackByFn">
      <div class="form-row"  *ngIf="isActiveYhteyshenkilo(yhteyshenkilo)">
      <div class="col" *ngIf="viewOptions.nimi">
        <touko-text-input
          [htmlId]="'yh-nimi-' + i"
          [labelAttribute]="i === firstActiveIndex ? 'lomakeYleinen.nimi' : null"
          [value]="yhteyshenkilo.nimi"
          (change)="updateField('nimi', i, $event.target.value)"
          required
          #nimiInput></touko-text-input>
      </div>
      <div class="col" *ngIf="viewOptions.puhelin">
        <touko-text-input
          [htmlId]="'yh-puh-' + i"
          [labelAttribute]="i === firstActiveIndex ? 'lomakeYleinen.puhelin' : null"
          [value]="yhteyshenkilo.puhelin"
          required
          (change)="updateField('puhelin', i, $event.target.value)"></touko-text-input>
      </div>
      <div class="col" *ngIf="viewOptions.email">
        <touko-text-input
          [htmlId]="'yh-email-' + i"
          [labelAttribute]="i === firstActiveIndex ? 'lomakeYleinen.email' : null"
          [value]="yhteyshenkilo.email"
          required
          (change)="updateField('email', i, $event.target.value)"></touko-text-input>
      </div>
      <div class="col-3" *ngIf="viewOptions.rooli">
        <touko-select-menu [htmlId]="'yh-rooli-' + i"
                           required
                           [options]="roolit"
                           (change)="updateField('rooli', i, $event)"
                           [labelAttribute]="i === firstActiveIndex ? 'lomakeYleinen.yhteyshenkilonRooli' : null"
                           [value]="yhteyshenkilo.rooli"
                           [selectionRemovable]="false"></touko-select-menu>
      </div>
      <div>
        <button [ngClass]="{'first': i === firstActiveIndex, 'invisible': yhteyshenkilotLength <= 1}"
                [attr.tabindex]="0"
                (click)="removeRowIndex(i)"
                [id]="'yh-poista-' + i"
                type="button" class="close float-right" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="maxCount > 1">
      <div class="col mb-4">
        <button *ngIf="fieldValue && yhteyshenkilotLength < maxCount" (click)="addRow()" type="button" class="btn btn-sm btn-outline-primary mr-1"
                id="yh-lisaa"
                attribute="lomakeYleinen.lisaaYhteyshenkilo">Lisää yhteyshenkilö
        </button>
        <button *ngIf="fieldValue && yhteyshenkilotLength > 1" (click)="removeRow()" type="button" class="btn btn-sm btn-secondary"
                id="yh-poista"
                [disabled]="yhteyshenkilotLength <= 1" attribute="lomakeYleinen.poistaEdellinenYhteyshenkilo">Poista edellinen yhteyshenkilö
        </button>
      </div>
    </div>
  `,
  styles: [
  `button.close {
      height: 0;
      top: 0.2em;
      position: relative;
    }
      button.first {
        top: 1.5em;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => YhteyshenkilotInputComponent),
    }
  ]
})
export class YhteyshenkilotInputComponent implements ControlValueAccessor, AfterViewChecked, OnInit {

  private _addRowClicked = false;

  @Input() maxCount = 50;
  @Input() viewOptions = new YhteyshenkiloViewOptions();
  @Input() rooliValues: YhteyshenkiloValue[] = [];
  @Input() defaultRooli: string = null;
  @Input() defaultYhteyshenkiloNimi: string = null;

  @Output() yhteyshenkilotUpdate: EventEmitter<Yhteyshenkilo[]> = new EventEmitter();
  @ViewChildren("nimiInput") nimiInput: QueryList<TextInputComponent>;

  yhteyshenkilotLength: number;
  firstActiveIndex: number;
  roolit: Array<Option> = [];

  fieldValue: Yhteyshenkilo[];
  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  ngOnInit() {
    this.defaultRooli = this.defaultRooli || (this.rooliValues.length > 0 ? this.rooliValues[0].value : null);
    this.roolit = this.rooliValues.map(rooli => ({
      value: rooli.value,
      label: rooli.teksti.fullKey,
      selected: rooli.value === this.defaultRooli,
      disabled: rooli.disabled
    }));
  }

  isActiveYhteyshenkilo = (y: Yhteyshenkilo) => y.elmoStatus !== "INACTIVE" && y.ytrStatus !== "INVALID";

  writeValue(obj: Yhteyshenkilo[]): void {
    this.fieldValue = this.sanitizeValue(obj);
    const valueToSave = this.cleanValue(this.fieldValue);
    this.updateYhteyshenkiloHelpers(valueToSave);
  }

  sanitizeValue(obj: Yhteyshenkilo[]): Yhteyshenkilo[] {
    if (obj && obj instanceof Array && obj.length > 0) {
      obj = obj.filter(this.isActiveYhteyshenkilo).map(o => {
        const yhteyshenkilo = new Yhteyshenkilo(o.nimi, o.puhelin, o.email, o.rooli, o.elmoStatus, o.ytrStatus);
        yhteyshenkilo.id = o.id ? o.id : null;
        yhteyshenkilo.ytrTunnus = o.ytrTunnus ? o.ytrTunnus : null;
        yhteyshenkilo.elmoid = o.elmoid ? o.elmoid : null;
        return yhteyshenkilo;
      });
    } else {
      obj = [];
    }

    if (obj.filter(this.isActiveYhteyshenkilo).length === 0) {
      obj.push(new Yhteyshenkilo(this.defaultYhteyshenkiloNimi, null, null, this.defaultRooli));
    }

    return obj;
  }

  updateChanges() {
    const valueToSave = this.cleanValue(this.fieldValue);
    this.updateYhteyshenkiloHelpers(valueToSave);

    this._onChange(valueToSave);
    this._onTouched(valueToSave);
  }

  updateYhteyshenkiloHelpers(valueToSave: Yhteyshenkilo[]) {
    this.yhteyshenkilotUpdate.emit(valueToSave);
    this.yhteyshenkilotLength = valueToSave.filter(this.isActiveYhteyshenkilo).length;
    const foundIndex = valueToSave.findIndex(this.isActiveYhteyshenkilo);
    this.firstActiveIndex = foundIndex > 0 ? foundIndex : 0;
  }

  updateField(field: string, index: number, value: string) {
    this.fieldValue[index][field] = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  addRow() {
    this._addRowClicked = true;
    const yh = new Yhteyshenkilo();
    yh.rooli = this.defaultRooli;

    this.fieldValue.push(yh);
  }

  /**
   * Huom. viimeistä yhteyshenkilöä ei voi poistaa.
   */
  removeRow() {
    if (this.yhteyshenkilotLength > 1) {
      this.removeOrSetInactive(this.findLastActiveIndex());
    }
  }

  /**
   * Huom. viimeistä yhteyshenkilöä ei voi poistaa.
   */
  removeRowIndex(index: number) {
    if (this.yhteyshenkilotLength > 1 && index < this.fieldValue.length && index >= 0) {
      this.removeOrSetInactive(index);
    }
  }

  removeOrSetInactive(index: number) {
    if (this.fieldValue[index].elmoid && this.viewOptions.modifyElmoStatus) {
      this.fieldValue[index].elmoStatus = "INACTIVE";
    } else {
      this.fieldValue.splice(index, 1);
    }
    this.updateChanges();
  }

  findLastActiveIndex(lastIndex?) {
    if (!lastIndex) {
      lastIndex = this.fieldValue.length - 1;
    }
    const lastItem = this.fieldValue[lastIndex];

    if (lastItem.elmoStatus === "ACTIVE" && lastItem.ytrStatus === "VALID") {
      return lastIndex;
    } else {
      return this.findLastActiveIndex(this.findLastActiveIndex(lastIndex - 1));
    }
  }

  cleanValue(obj: Yhteyshenkilo[]): Yhteyshenkilo[] {
    return obj.filter(o => o.nimi || o.email || o.puhelin);
  }

  ngAfterViewChecked(): void {
    if (this._addRowClicked) {
      const lastNimiInputId = this.nimiInput.last.htmlId;
      document.getElementById(lastNimiInputId).focus();
      this._addRowClicked = false;
    }
  }

  trackByFn(index: any) {
    return index;
  }
}
