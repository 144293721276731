import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef} from "@angular/core";
import {VerkkolaskuTiedot} from "../touko-lomake/syote/syote-utils";
import {Option} from "../touko-components/select-menu.component";
import {Operaattoritunnus} from "../utils/operaattoritunnus";

/**
 * Created by Seppo on 05/11/2018.
 */

@Component({
  selector: "touko-verkkolasku",
  template: `
    <div class="col-md-3">
      <touko-text-input [value]="fieldValue?.verkkolaskutusosoite"
                        (change)="updateVerkkolaskutusosoite($event.target.value)"
                        htmlId="lt-verkkolaskutus"
                        labelAttribute="lomakeYleinen.verkkolaskutusosoite"></touko-text-input>
    </div>
    <div class="col-md-3">
      <touko-select-menu htmlId="lt-operaattori"
                         [options]="operaattorit"
                         (change)="updateOperaattori($event)"
                         [value]="fieldValue?.operaattori"
                         labelAttribute="lomakeYleinen.operaattori"
                         defaultTitle="lomakeYleinen.optionEiValintaa"></touko-select-menu>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VerkkolaskuComponent),
    }
  ]
})
export class VerkkolaskuComponent implements ControlValueAccessor {

  fieldValue: VerkkolaskuTiedot = new VerkkolaskuTiedot();
  operaattorit: Array<Option> = [];

  constructor() {
    this.fieldValue = new VerkkolaskuTiedot();
    this.operaattorit = Operaattoritunnus.getOperaattoritunnukset().map(operaattori => ({
      label: operaattori,
      value: operaattori
    }));
  }

  writeValue(obj: VerkkolaskuTiedot | { verkkolaskutusosoite: string; operaattori: string }): void {
    if (obj && !(obj instanceof VerkkolaskuTiedot)) {
      obj = new VerkkolaskuTiedot(obj.verkkolaskutusosoite, obj.operaattori);
    }

    this.fieldValue = obj;
    this._onChange(obj);
  }

  updateVerkkolaskutusosoite(value: string) {
    this.fieldValue = this.fieldValue ? this.fieldValue : new VerkkolaskuTiedot();
    this.fieldValue.verkkolaskutusosoite = value;
    this._onTouched(this.fieldValue);

    this.writeValue(this.fieldValue);
  }

  updateOperaattori(value: string) {
    this.fieldValue = this.fieldValue ? this.fieldValue : new VerkkolaskuTiedot();
    this.fieldValue.operaattori = value === 'null' ? null : value;
    this._onTouched(this.fieldValue);

    this.writeValue(this.fieldValue);
  }


  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

}
