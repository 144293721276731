import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LomakeResponse} from "../../touko-lomake/touko-lomake-utils";
import {Sarake} from "../../raportti/raportti.utils";
import {SarakeClickEvent} from "./lomake-row.component";

@Component({
  selector: 'lomake-table',
  templateUrl: './lomake-table.component.html',
  styleUrls: ['../../raportti/data-table.component.scss'] // Käytetään samoja tyylejä kuin raporteissa
})
export class LomakeTableComponent {

  @Input() sarakkeet: Sarake[] = [];
  @Input() lomakkeet: LomakeResponse[] = [];
  @Input() viewOptions: LomakeTableViewOptions = new LomakeTableViewOptions();

  @Input() previousSortKey;
  @Input() sortOrder;

  @Output() sortChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() selectItem: EventEmitter<SarakeClickEvent> = new EventEmitter<SarakeClickEvent>();
  @Output() selectRow: EventEmitter<LomakeResponse> = new EventEmitter<LomakeResponse>();

  /**
   * Hakee järjerstyksen tietylle kentällä. Valitulla kentällä arvo on -1 (=ASC) tai 1 (=DESC), muilla 0.
   *
   * @param key - kenttä
   * @returns -1 (=ASC) tai 1 (=DESC) tai 0.
   */
  getJarjestys(key: string) {
    return this.previousSortKey === key ? this.sortOrder : 0;
  }

  sortByKey(key: string) {
    this.sortChange.emit(key);
  }

  clickItem(lomake: SarakeClickEvent) {
    this.selectItem.emit(lomake);
  }

  clickRow(lomake: LomakeResponse) {
    this.selectRow.emit(lomake);
  }
}

export class LomakeTableViewOptions {
  tableStyles: string[];
  showHeadings = true;
  rowLinkFormatter: (lomake: LomakeResponse) => string;
  rowIdFormatter: (lomake: LomakeResponse) => string;

  constructor(tableStyles = ["table", "table-striped", "table-sm", "table-hover"],
              showHeadings = true,
              rowIdFormatter: (lomake: LomakeResponse) => string = (lomake => `lomakeTable-${lomake.id}`),
              rowLinkFormatter: (lomake: LomakeResponse) => string = null
              ) {
    this.tableStyles = tableStyles;
    this.showHeadings = showHeadings;
    this.rowIdFormatter = rowIdFormatter;
    this.rowLinkFormatter = rowLinkFormatter;
  }
}
