import {Injectable, Injector} from "@angular/core";
import {ActivationEnd, NavigationStart, Router} from "@angular/router";
import {AccountService} from "../account/account.service";
import {BehaviorSubject} from "rxjs";
import {LoaderService} from "../loader/loader.service";
import {Title} from "@angular/platform-browser";
import {Teksti} from "../utils/teksti";
import {LomakeService} from "../touko-lomake/touko-lomake.service";
import {PalveluService} from "../sidebar/palvelu.service";
import {RaporttiViewService} from "../raportti/raportti-view.service";
import {ValvontakohdeService} from "../valvontakohde/valvontakohde.service";
import {tiira, tiiraCommand} from "tiira-frontend";

/**
 * Created by Seppo on 02/10/2017.
 */

declare let $: any;

/**
 * Handles Tiira calls
 */
@Injectable()
export class TiiraService {
  private readonly loginStatus: BehaviorSubject<LoginState> = new BehaviorSubject(LoginState.NOTLOGGEDIN);
  loginStatus$ = this.loginStatus.asObservable();

  private readonly _language: BehaviorSubject<string> = new BehaviorSubject(null);
  language$ = this._language.asObservable();

  private readonly _pageTitle: BehaviorSubject<string> = new BehaviorSubject(null);
  pageTitle$ = this._pageTitle.asObservable();

  private readonly _etusivuURL = "/asiointi/etusivu";
  private readonly _uksiPartialURL = "PALVELUID=TOUKO";
  private readonly _loginsivuURL = "/login";

  private ssoLogoutUrl = undefined;

  private _refreshRedirectUrl: string = this._loginsivuURL;

  constructor(private readonly router: Router,
              private readonly accountService: AccountService,
              private readonly loaderService: LoaderService,
              private readonly titleService: Title,
              private readonly injector: Injector) {
    let isFirstTimeNavigation = true;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && isFirstTimeNavigation) {
        isFirstTimeNavigation = false;
        /* Start LOGIN animation */
        this.loaderService.startLoadingAnimation();
        if (!event.url.includes(this._loginsivuURL) && !event.url.includes(this._uksiPartialURL)) {
          this.setRefreshRedirectUrl(decodeURI(event.url));
        }
      } else if (event instanceof ActivationEnd) {
        if (event.snapshot.data.hasOwnProperty('title')) {
          this._pageTitle.next(event.snapshot.data.title);
        }
      }
    });
  }

  /**
   * Wrapper for Tiira's login function.
   */
  toukoLogin(initLang = true): void {
    if (this.loginStatus.getValue() === LoginState.LOGGEDIN) {
      return;
    }

    tiira.login(() => {
      this.loginStatus.next(LoginState.LOGGEDIN);
      if (initLang) {
        this.initUserLanguage();
      }

      this.prepareAccountAfterLogin().then(() => {
          /* Stop LOGIN animation, user logged in succesfully or was already logged in (page refresh etc) */
          if (this.refreshRedirectUrl.startsWith(this._loginsivuURL) || this.refreshRedirectUrl === '/') {
            this.router.navigateByUrl(this.getEtusivuUrl()).then(() => this.loaderService.stopLoadingAnimation());
          } else {
            this.router.navigateByUrl(this.refreshRedirectUrl).then(() => this.loaderService.stopLoadingAnimation());
          }
        });

      tiira.getSSOLogoutUrl((url) => {
        this.ssoLogoutUrl = url;
      });
    });
  }

  private prepareAccountAfterLogin(): Promise<any> {
    return this.accountService.getAccountDetails();
  }

  logout(redirectUrl = this._loginsivuURL) {
    this.loaderService.startLoadingAnimation(new Teksti("kirjaudutaan ulos", "loaderLogout", "teksti"));
    this.cleanUpSession();

    tiira.logout(data => {
      this.cleanUpAccount();
      this.setRefreshRedirectUrl(this._loginsivuURL);
      if (this.ssoLogoutUrl) {
        window.location.href = this.ssoLogoutUrl;
        // Note! We do not need to stop the loadingAnimatio heren since we are going to an external page
        //       --> Will also keep the window from flickering the underlying asiointi-site before
        //           the actual navigation.
      } else {
        this.router.navigate([redirectUrl]);
        this.loaderService.stopLoadingAnimation();
      }
    });
  }

  cleanUpSession() {
    tiira.clearSession();
    this.setRefreshRedirectUrl(this.refreshRedirectUrl);
  }

  cleanUpAccount() {
    this.loginStatus.next(LoginState.NOTLOGGEDIN);
    this.resetCurrentLanguage();
    this.cleanUpServices();
  }

  /**
   * Some services must be cleaned in order for the next logged-in user to get a clear table.
   *
   * !! Add your service here if cleanup is required at logout !!
   *
   * Note that by injecting and calling a specific cleanUp -function (and since this will be called already in login)
   * will remove the benefits of lazy loading. If/when we have more services an alternative solution should be
   * considered.
   */
  private cleanUpServices(): void {
    Array<any>(
     this.injector.get(PalveluService),
     this.injector.get(LomakeService),
     this.injector.get(RaporttiViewService),
     this.injector.get(ValvontakohdeService),
     this.injector.get(AccountService)
    ).forEach(s => s.cleanUp());
  }

  private setRefreshRedirectUrl(url: string) {
    window.sessionStorage.setItem("refreshRedirectUrl", url);
    this._refreshRedirectUrl = url;
  }

  get refreshRedirectUrl(): string {
    const url = window.sessionStorage.getItem("refreshRedirectUrl");
    return  url ? url :
      this._refreshRedirectUrl;
  }

  /**
   * Check the user login status.
   *
   * @returns
   */
  isLoggedIn(): LoginState {
    return this.loginStatus.getValue();
  }

  /**
   * Check is the user authenticated (according to tiira).
   */
  isAuthenticated() {
    return tiira.authenticated();
  }

  /**
   * Wrapper for Tiira's authQuestionsAnswered function.
   * Confirm login questions.
   *
   * @param answers - login information
   */
  authQuestionsAnswered(answers) {
    this.loaderService.startLoadingAnimation(new Teksti("kirjaudutaan sisään", "loaderLogin", "teksti"));
    tiiraCommand.authQuestionsAnswered(answers, this.toukoLogin.bind(this, false));
  }

  /**
   * Wrapper for Tiira's showQuestion function
   *
   * @param func - callback function, takes Tiira's authentication questions as a parameter
   * @returns
   */
  setLoginQuestionsAsync(func: (any, urls) => any) {
    tiiraCommand.showQuestions = (authData) => {
      const urls = [
        {
          type: 'YRITYS-FI',
          lang: 'FI',
          title: "login.kirjauduYritysasiakas",
          description: "login.kirjauduYritysasiakasSelite",
          url: $(authData.authContainer).find("ulkoinenkirjautuminen[tyyppi=\"SuomifiYPA\"] ulkoinenkirjautuminen-url[kieli=\"fi.FI\"]").text()
        },
        {
          type: 'YRITYS-SV',
          lang: 'SV',
          title: "login.kirjauduYritysasiakas",
          description: "login.kirjauduYritysasiakasSelite",
          url: $(authData.authContainer).find("ulkoinenkirjautuminen[tyyppi=\"SuomifiYPA\"] ulkoinenkirjautuminen-url[kieli=\"sv.FI\"]").text()
        },
        {
          type: 'YKSITYIS-FI',
          lang: 'FI',
          title: "login.kirjauduHenkiloasiakas",
          description: "login.kirjauduHenkiloasiakasSelite",
          url: $(authData.authContainer).find("ulkoinenkirjautuminen[tyyppi=\"Suomifi\"] ulkoinenkirjautuminen-url[kieli=\"fi.FI\"]").text()
        },
        {
          type: 'YKSITYIS-SV',
          lang: 'SV',
          title: "login.kirjauduHenkiloasiakas",
          description: "login.kirjauduHenkiloasiakasSelite",
          url: $(authData.authContainer).find("ulkoinenkirjautuminen[tyyppi=\"Suomifi\"] ulkoinenkirjautuminen-url[kieli=\"sv.FI\"]").text()
        },
        {
          type: 'YKSITYIS-HPA-FI',
          lang: 'FI',
          title: "login.kirjauduHPA",
          description: "login.kirjauduHPASelite",
          url: $(authData.authContainer).find("ulkoinenkirjautuminen[tyyppi=\"SuomifiHPA\"] ulkoinenkirjautuminen-url[kieli=\"fi.FI\"]").text()
        },
        {
          type: 'YKSITYIS-HPA-SV',
          lang: 'SV',
          title: "login.kirjauduHPA",
          description: "login.kirjauduHPASelite",
          url: $(authData.authContainer).find("ulkoinenkirjautuminen[tyyppi=\"SuomifiHPA\"] ulkoinenkirjautuminen-url[kieli=\"sv.FI\"]").text()
        },
      ];
      return func(authData, urls);
    };
    return tiiraCommand.showQuestions;
  }

  setCurrentLanguage(value) {
    tiira.setLanguage(value);
    this._language.next(value);
  }

  initUserLanguage(): string {
    let defaultLang = tiira.resolveLanguage();
    if (!defaultLang) {
      defaultLang = 'FI';
      this.setCurrentLanguage(defaultLang);
    } else {
      this._language.next(defaultLang);
    }
    return defaultLang;
  }

  getCurrentLanguage(): string {
    return this._language.getValue() || tiira.resolveLanguage();
  }

  getDefaultLanguage() {
    return this.getCurrentLanguage() || 'FI';
  }

  resetCurrentLanguage(): void {
    this._language.next(null);
  }

  setDocumentTitle(title: string) {
    this.titleService.setTitle(`Touko - ${title}`);
  }

  getEtusivuUrl(): string {
    return this._etusivuURL;
  }
}

/**
 * Login states
 */
export enum LoginState {
  NOTLOGGEDIN,
  LOGGEDIN
}
