/**
 * Created by Seppo on 15/01/2018.
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Syote} from "../touko-lomake/syote/syote";
import {NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {DateObj} from "../touko-lomake/syote/syote-utils";
import { CustomDateFormatter, CustomDatepickerI18n } from "../utils/localizedDates";
import {SyoteInterface} from "../touko-lomake/syote/syote-interface";


/**
 * Päivämääräkomponentti
 * @deprecated käytetään jatkossa date
 */
@Component({
  selector: 'touko-pvm',
  template: `
    <div *ngIf="item" class="block">
      <label [attribute]="item.getLabelKey()" [htmlFor]="'date-input-'+htmlId">{{item.label}}</label>
    </div>
    <div class="block">
      <div class="input-group">
        <input id="date-input-{{htmlId}}" [ngClass]="{'is-invalid': isInValid}" class="form-control"
               [attr.aria-labelledby]="labelledBy ? (labelledBy | attribute) : null"
               [attr.aria-label]="ariaLabel ? (ariaLabel | attribute) : null"
               ngbDatepicker #datepicker="ngbDatepicker"
               [navigation]="navigation"
               [maxDate]="maxDate"
               [minDate]="minDate"
               [markDisabled]="isDisabled"
               (ngModelChange)="writeValue($event)"
               placeholder="{{placeholder}}"
               [(ngModel)]="fieldValue"
               [disabled]="inputDisabled"/>
        <div class="input-group-append">
          <button id="cal-open-{{htmlId}}" type="button" class="btn btn-primary form-control" [disabled]="inputDisabled"
                  (click)="datepicker.toggle()" [attr.aria-label]="'lomakeYleinen.avaaKalenteri' | attribute">
            <span class="fa fa-calendar-alt"></span>
          </button>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PvmComponent),
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n
    }
  ]
})
export class PvmComponent implements ControlValueAccessor {

  @ViewChild("datepicker", { static: true })
  private datePickerComponent: NgbDatepicker;

  @Input() placeholder = "";
  @Input() labelledBy = null;
  @Input() ariaLabel: string;
  @Input() htmlId: string;
  @Input() item: SyoteInterface;
  @Input() disableWeekends = false;
  @Input() isInValid = false;
  @Input() navigation: Navigation = Navigation.arrows;
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() inputDisabled = false;
  fieldValue: null | DateObj;
  @Output() valueChange: EventEmitter<DateObj> = new EventEmitter();
  currentDayString;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private parser: NgbDateParserFormatter) {
    const today = DateObj.today();
    this.currentDayString = today.day + "." + today.month + ".";
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: DateObj | Date): void {

    if (value === null) {
      this.fieldValue = null;
      this.valueChange.emit(null);
      this._onChange(null);
    }

    if (!value) {
      return;
    }

    if (value instanceof Date) {
      value = DateObj.fromDate(value);
    }

    if (JSON.stringify(value) !== JSON.stringify(this.fieldValue)) {
      this.fieldValue = value;
      this.valueChange.emit(this.fieldValue);
      this.datePickerComponent.navigateTo(this.fieldValue);
      this._onChange(DateObj.fromNgbDate(this.fieldValue));
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.datePickerComponent.setDisabledState(isDisabled);
  }

  selectToday() {
    this.writeValue(DateObj.today());
    return this.fieldValue;
  }

  isDisabled = (date: NgbDate) => {
    if (this.disableWeekends) {
      const day = new Date(date.year, date.month - 1, date.day);
      return day.getUTCDay() >= 5;
    }
  };
}

enum Navigation {
  arrows = "arrows",
  select = "select",
  none = "none"
}
