<ng-container *ngFor="let sarake of sarakkeet">
  <ng-container [ngSwitch]="sarake.type">
    <td [ngClass]="sarake.styleFn(lomake)" *ngSwitchCase="'ID'" [id]="lomake[sarake.key]">
      {{lomake[sarake.key]}}
    </td>
    <td [ngClass]="sarake.styleFn(lomake)" *ngSwitchCase="'DATE'">
      {{lomake[sarake.key] | date:'d.M.yyyy'}}
    </td>
    <td [ngClass]="sarake.styleFn(lomake)" *ngSwitchCase="'DATETIME'">
      <span>{{lomake[sarake.key] | date:'d.M.yyyy'}}</span>
      <em class="ml-2">{{lomake[sarake.key] | date:'HH.mm'}}</em>
    </td>
    <td [ngClass]="sarake.styleFn(lomake)" *ngSwitchCase="'TYPE'">
      <tila-badge [lomakeTila]="lomake.tila"></tila-badge>
    </td>
    <td [ngClass]="sarake.styleFn(lomake)" *ngSwitchCase="'LOMAKE_LINK'">
      <a *ngIf="!lomake.lomakeVirhe; else lomakeLinkElse" [routerLink]="['./']" (click)="clickItem(lomake, sarake, $event)">
        <span [attribute]="kuvaukset[lomake.asia]['LYHYT_KUVAUS'][lomake.toimintotyyppi].fullKey"></span>
      </a>
      <ng-template #lomakeLinkElse>
        <span [attribute]="kuvaukset[lomake.asia]['LYHYT_KUVAUS'][lomake.toimintotyyppi].fullKey"></span>
      </ng-template>
    </td>
    <td *ngSwitchCase="'SYMBOL_LINK'">
      <a [routerLink]="['./']" [id]="sarake.key + '-' + lomake.id" (click)="clickItem(lomake, sarake, $event)" aria-label="kopioi mallipohjaksi">
        <span [ngClass]="sarake.styleFn(lomake)"></span>
      </a>
    </td>
    <td *ngSwitchCase="'SYMBOL'">
      <span [ngClass]="sarake.styleFn(lomake)"></span>
    </td>
    <td [ngClass]="sarake.styleFn(lomake)" *ngSwitchDefault>
      {{lomake[sarake.key]}}
    </td>
  </ng-container>
</ng-container>
