<hr>

<div class="row">
  <div class="col-md-4 col-lg-2">
    <label class="small" for="raportti-nayta" attribute="raportit.nayta">Näytä</label>
    <select id="raportti-nayta" class="form-control" [value]="raporttiLkmSivulla"
            (change)="selectRaporttiLkm($event.target.value)">
      <option [value]="10">{{'raportit.nayta' | attribute}} 10</option>
      <option [value]="20">{{'raportit.nayta' | attribute}} 20</option>
      <option [value]="50">{{'raportit.nayta' | attribute}} 50</option>
    </select>
  </div>

  <div class="col-md-4">
    <label class="small" [htmlFor]="'raportti-sivu'" attribute="raportit.wcagSivutus">Valitse sivu</label>
    <ngb-pagination [disabled]="getSivumaara() <= 1" [collectionSize]="raportitYht"
                    [id]="'raportti-sivu'"
                    [pageSize]="raporttiLkmSivulla" [maxSize]="5"
                    [boundaryLinks]="true" [(page)]="sivu"
                    (pageChange)="selectSivu(sivu)"></ngb-pagination>
  </div>
</div>
