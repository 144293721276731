<touko-lomake [formGroup]="lomakeValues" [tunnus]="id" [otsikko]="lomakeTitle" [saveable]="true"
              [submitFn]="submitLomake" [saveFn]="saveLomake" [cancelFn]="cancelLomake"
              (onSivuChanged)="updateYhteenvetoValues()">

  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'" [ohjeet]="ohjeet.sivu0" #sivuPerustiedot>

    <div class="mb-4">
      <touko-alert-box *ngIf="!isPerustiedotValid">
        <p class="mb-0" attribute="lomakeYleinen.perustiedotTuleeTayttaa"></p>
      </touko-alert-box>
    </div>

    <syote-group [otsikko]="lomakeData.hakemustyyppi.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('hakemustyyppi'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.hakemustyyppi.errorMessages"
                 formGroupName="hakemustyyppi">
      <p attribute="siemenpakkaamo.valitseHakemustyyppi">Valitse hakemustasi parhaiten kuvaava vaihtoehto.</p>
      <div class="form-check" *ngFor="let item of lomakeData.hakemustyyppi.data[0].options?.radioOptions">
        <input type="radio" class="form-check-input" [formControlName]="item.name"
               [id]="item.value"
               [value]="item.value" [attr.value]="item.value">
        <label [htmlFor]="item.value" [attribute]="item.label.getFullKey()"></label>
      </div>
    </syote-group>

    <syote-group [otsikko]="lomakeData.perustiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('perustiedot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.perustiedot.errorMessages">
      <touko-perustiedot (perustietoUpdate)="updatePerustiedot($event)" [formFields]="lomakeData.perustiedot.data"
                         [paatoiminimi]="allekirjoitus.paatoiminimi" formGroupName="perustiedot" [osastoReadonly]="true"
                         [osoitteetReadonly]="true"></touko-perustiedot>
    </syote-group>

    <syote-group [otsikko]="lomakeData.laskutustiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('laskutustiedot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.laskutustiedot.errorMessages">
      <div class="form-check">
        <input (change)="autofillLaskutustiedot($event)" id="laskutusosoiteCheck" class="form-check-input"
               type="checkbox">
        <label attribute="siemenpakkaamo.laskutusosoiteSamaKuinPostiosoite" [htmlFor]="'laskutusosoiteCheck'">Laskutusosoite
          sama kuin postiosoite</label>
      </div>

      <touko-laskutustiedot [formFields]="lomakeData.laskutustiedot.data" formGroupName="laskutustiedot">
      </touko-laskutustiedot>
    </syote-group>

    <syote-group [otsikko]="lomakeData.yhteyshenkilot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('yhteyshenkilot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.yhteyshenkilot.errorMessages" formGroupName="yhteyshenkilot">
      <yhteyshenkilot-input [viewOptions]="yhteyshenkiloViewOptions"
                            (yhteyshenkilotUpdate)="updateYhteyshenkilot($event)"
                            [rooliValues]="yhteyshenkiloRoolit"
                            [defaultYhteyshenkiloNimi]="defaultYhteyshenkiloNimi"
                            [defaultRooli]="'ReVCCRSeed'"
                            formControlName="yhteyshenkilot">
      </yhteyshenkilot-input>
    </syote-group>

    <syote-group [otsikko]="lomakeData.toiminnanAloitus.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('toiminnanAloitus'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.toiminnanAloitus.errorMessages" formGroupName="toiminnanAloitus">
      <div class="row">
        <div class="col-md-6">
          <touko-pvm formControlName="toiminnanAloitus" [htmlId]="lomakeData.toiminnanAloitus.singleField.name"
                     [item]="lomakeData.toiminnanAloitus.singleField"></touko-pvm>
        </div>
      </div>
      <div>
        <small>
          <a [href]="'https://avointieto.ruokavirasto.fi/#/kasvi/siemenpakkaamot'" target="_blank"
             attribute="siemenpakkaamo.voimassaolotiedotInfo">Täältä löydät pakkauslupasi voimassaolotiedot</a>
        </small>
      </div>
    </syote-group>

  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'siemenpakkaamo.sivuToiminnanLuonne'"
        [ohjeet]="ohjeet.sivu1" #sivu1>
    <syote-group [otsikko]="lomakeData.markkinoimasiKylvosiemenet.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('markkinoimasiKylvosiemenet'), sivu1.ref)"
                 [virheSanomat]="lomakeData.markkinoimasiKylvosiemenet.errorMessages"
                 formGroupName="markkinoimasiKylvosiemenet">
      <div class="form-check" *ngFor="let item of lomakeData.markkinoimasiKylvosiemenet.data">
        <syote-layout [label]="item.label" [labelKey]="item.getLabelKey()">
          <input [name]="item.name" id="check-{{item.name}}" class="form-check-input" type="checkbox"
                 formControlName="{{item.name}}">
        </syote-layout>
      </div>
    </syote-group>

    <syote-group [otsikko]="lomakeData.toiminnanLaajuus.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('toiminnanLaajuus'), sivu1.ref)"
                 [virheSanomat]="lomakeData.toiminnanLaajuus.errorMessages" formGroupName="toiminnanLaajuus">

      <div class="form-check" *ngFor="let item of lomakeData.toiminnanLaajuus.data[0].options?.radioOptions">
        <syote-layout [label]="item.label" [labelKey]="item.getLabelKey()">
          <input [attr.name]="item.name" [attr.value]="item.value" [value]="item.value" class="form-check-input"
                 type="radio" formControlName="{{item.name}}">
        </syote-layout>

      </div>
    </syote-group>
    <syote-group [otsikko]="lomakeData.koneetJaLaitteet.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('koneetJaLaitteet'), sivu1.ref)"
                 [virheSanomat]="lomakeData.koneetJaLaitteet.errorMessages" formGroupName="koneetJaLaitteet">

      <div *ngFor="let item of lomakeData.koneetJaLaitteet.data">
        <div class="form-check">
          <syote-layout [label]="item.label" [labelKey]="item.getLabelKey()">
            <div>
              <input id="{{'koneetJaLaitteetCheckbox-' + item.name}}" [value]="item.value" class="form-check-input" type="checkbox" [formControlName]="item.name">
            </div>
          </syote-layout>
        </div>

        <div *ngFor="let childItem of item.children;">
          <div class="mb-4 form-group" *ngIf="showInput[childItem.name]?.show">
            <ng-container [ngSwitch]='childItem.options?.type'>
              <div *ngSwitchCase="'textarea'">
                <label [htmlFor]="childItem.name" attribute="siemenpakkaamo.lisatieto">Lisätiedot</label>
                <textarea [id]="childItem.name" [formControlName]="childItem.name" class="form-control"
                          rows="4"></textarea>
              </div>
              <div *ngSwitchCase="'array'" [formArrayName]="childItem.name" class="offset-1">
                <div
                  *ngFor="let arrayItem of lomakeValues.get(['koneetJaLaitteet', childItem.name])?.controls; index as i;">
                  <div [formGroupName]="i">
                    <div *ngIf="childItem.children[i].checkbox">
                      <input [id]="childItem.children[i].checkbox.name + '-' + i" class="form-check-input"
                             type="checkbox"
                             [formControl]="arrayItem.get('checkbox')">
                      <label [htmlFor]="childItem.children[i].checkbox.name + '-' + i" class='form-check-label'
                             [attribute]="childItem.children[i].checkbox.label.getFullKey()"></label>
                    </div>
                    <div *ngIf="childItem.children[i].date">
                      <label [htmlFor]="childItem.children[i].date.name"
                             [id]="childItem.children[i].date.name + '-' + i + '-' + item.name"
                             [attribute]="item.name === 'vaaka' ? 'siemenpakkaamo.vaakaVarmennettu' : 'siemenpakkaamo.merkitseAjankohta'">Päivämäärä</label>
                      <touko-kuukausi-pvm [id]="childItem.children[i].date.name + '-' + i"
                                          [formControl]="arrayItem.get('date')" (removeItem)="removeLisalaite(childItem.name, i)" [isRemoveDisabled]="childItem.children.length === 1" [idSuffix]="item.name" [rangeOfYears]="item.name === 'vaaka' ? {yearsForward: 5, yearsBackward: 5} : {yearsForward: 1, yearsBackward: 10}"></touko-kuukausi-pvm>
                    </div>

                  </div>
                </div>
                <div class="mt-2">
                  <button id="btnLisaaLaite-{{childItem.name}}" class="btn btn-primary" type="button" attribute="siemenpakkaamo.btnLisaaLaite" (click)="addLisalaite(childItem.name)">Lisää laite
                  </button>
                </div>
              </div>
              <div *ngSwitchDefault></div>
            </ng-container>
          </div>
        </div>
      </div>

    </syote-group>
  </sivu>

  <sivu [selectable]="isPerustiedotValid"
        [attribuuttiAvain]="'siemenpakkaamo.lisatiedotRyhmaOtsikko'"
        [ohjeet]="ohjeet.sivu2" #sivu2>
    <syote-group [otsikko]="lomakeData.lisatiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('lisatiedot'), sivu2.ref)"
                 [virheSanomat]="lomakeData.lisatiedot.errorMessages" formGroupName="lisatiedot">
      <p attribute="siemenpakkaamo.lisatiedotHuomautus">Täytetää vain, mikäli toimipaikalla harjoitetaan ko.
        toimintaa.</p>
      <div *ngFor="let item of lomakeData.lisatiedot.data" class="row">
        <div class="col-lg-9">
          <div class="form-check">
            <syote-layout *ngIf="!item.options?.parent" [label]="item.label" [labelKey]="item.getLabelKey()">
              <div>
                <input [value]="item.value" id="{{'lisatiedotCheckbox-' + item.name}}" class="form-check-input" type="checkbox" [formControlName]="item.name">
              </div>
            </syote-layout>
          </div>
          <div *ngFor="let childItem of item.children">
            <div *ngIf="showInput[childItem.name]?.show" class="mb-4 form-group">
              <label [htmlFor]="childItem.name " attribute="siemenpakkaamo.lisatieto">Lisätiedot</label>
              <textarea [id]="childItem.name" [formControlName]="childItem.name" class="form-control"
                        rows="4"></textarea>
              <div class="alert alert-info" *ngIf="item.name === 'luomusiemenpakkaus'">
                  <span attribute="siemenpakkaamo.luomusiemenLisaHuomautus">Luomusiementä pakkaavan
                    toimijan on liityttävä
                    luonnonmukaisen tuotannon valvontajärjestelmään</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </syote-group>
    <syote-group [otsikko]="lomakeData.painatus.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('painatus'), sivu2.ref)"
                 [virheSanomat]="lomakeData.painatus.errorMessages" formGroupName="painatus">
      <div class="form-check" *ngFor="let item of lomakeData.painatus.data[0].options?.radioOptions">
        <syote-layout [label]="item.label" [labelKey]="item.getLabelKey()">
          <div>
            <input [attr.name]="item.name" [attr.value]="item.value" [value]="item.value" class="form-check-input"
                   type="radio" formControlName="{{item.name}}">
          </div>
        </syote-layout>
        <div *ngIf="painatusValinta === 'painetaanItse' && item.value === 'painetaanItse' " class="mb-4 form-group">
          <div class="alert alert-info">
            <span [attribute]="'siemenpakkaamo.painatusOhje'"></span>
          </div>
        </div>
      </div>
    </syote-group>

    <div *ngIf="isUusiHakemus" class="mb-4 form-group">
      <syote-group [otsikko]="lomakeData.tarkastusAjankohta.title"
                   [virheet]="getGroupVirheet(lomakeValues.get('tarkastusAjankohta'), sivu2.ref)"
                   [virheSanomat]="lomakeData.painatus.errorMessages" formGroupName="tarkastusAjankohta">
        <div class="" *ngFor="let item of lomakeData.tarkastusAjankohta.data">
          <div class="col-md-4 col-lg-4 p-0 p-0" *ngFor="let item of lomakeData.tarkastusAjankohta.data">
            <ng-container [ngSwitch]='item.options?.type'>
              <div *ngSwitchCase="'date'">
                <label [htmlFor]="'date-input-' + item.name"
                       attribute="siemenpakkaamo.tarkastusAjankohta">Päivämäärä</label>
                <touko-pvm [htmlId]="item.name" [formControlName]="item.name" [disableWeekends]="true"></touko-pvm>
              </div>
            </ng-container>
          </div>

        </div>
      </syote-group>
    </div>

  </sivu>


  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <allekirjoitus [data]="allekirjoitus"></allekirjoitus>
    <yhteenveto></yhteenveto>

    <button *ngIf="isAsiakas" id="laheta" class="btn btn-primary" type="submit">
      <span attribute="teksti.laheta">Lähetä</span>
      <em class="fa fa-paper-plane"></em>
    </button>

  </sivu>

  <div class="alert alert-primary my-2" *ngIf="lisatieto">
    <span attribute="etusivu.lisatiedot">Lisätiedot</span>: {{lisatieto}}
  </div>
</touko-lomake>

<pre *devFeature class="small">
  {{ lomakeValues.getRawValue() | json }}
</pre>
