<div id="arkistoDokumentit" *ngIf="isArkistoHakemus() || isElmoonLahetettyjaHakemuksia()">

  <h2 id="arkistoDokumentitOtsikko" attribute="lomakeYleinen.dokumentitSahkArkistossa">Dokumentit sähköisessä arkistossa</h2>

  <ul>

    <li id="arkistoAlkuperHakemus" *ngIf="isArkistoHakemus()">
      <a id="arkistoAlkuperHakemusLink" (click)="avaaAlkuperainenHakemusArkistossa()"
         attribute="lomakeYleinen.alkupHakemusSahkoisessaArkistossa">
        Alkuperäinen hakemus
     </a>
      <a id="arkistoAlkuperHakemusPdfIcon" (click)="avaaAlkuperainenHakemusArkistossa()">
        <img class="pdf-icon" [src]="'assets/img/pdf_icon.png'" alt="PDF">
      </a>
    </li>

    <div id="elmoonLahetetytHakemuset" *ngIf="isElmoonLahetettyjaHakemuksia()">
      <div *ngFor="let hakemus of elmoonLahetetytHakemukset; let i = index">
        <li>
          <a id="elmoonLahetettyHakemusLink-{{i}}" (click)="avaaAsiakirja(hakemus)">
             {{'lomakeYleinen.elmoonLahetettyHakemusSahkArkistossa' | attribute }} ( {{hakemus.luontipvm}} )
         </a>
          <a id="elmoonLahetettyHakemusPdfIcon-{{i}}" (click)="avaaAsiakirja(hakemus)">
            <img class="pdf-icon" [src]="'assets/img/pdf_icon.png'" alt="PDF">
          </a>
        </li>
      </div>
    </div>

  </ul>

</div>

<div id="arkistoHakemusPuuttuuVaroitus" *ngIf="!isArkistoHakemus()">
  <p style="color:red" attribute="lomakeYleinen.hakemustaEiLoydyArkistosta">Hakemusta ei löydy sähköisestä arkistosta!</p>
</div>
