import {Injectable} from "@angular/core";
import {LomakeResponse} from "./touko-lomake-utils";
import {BehaviorSubject, Observable, Subscriber} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DateObj} from "./syote/syote-utils";
import {HttpClient} from "@angular/common/http";
import {PalveluService} from "../sidebar/palvelu.service";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";

@Injectable()
export class LomakeHakuService {

  readonly _lomakkeet = new BehaviorSubject<LomakeResponse[]>([]);
  lomakkeet$ = this._lomakkeet.asObservable();

  readonly _isGetLomakkeetRequestPending = new BehaviorSubject<boolean>(false);
  isGetLomakkeetRequestPending$ = this._isGetLomakkeetRequestPending.asObservable();

  getLomakkeetSub: Subscriber<any>;

  constructor(readonly http: HttpClient,
              readonly sidebarService: PalveluService) {
  }

  public getVirkailijaIlmoitukset(vkParams): Promise<LomakeResponse[]> {
    const url = '/api/v1/vk/lomakkeet/ilmoitukset';
    const params = this.createHakuParamsForVirkailija(vkParams);

    return this.getLomakkeet(url, params);
  }

  public createHakuParamsForVirkailija(vkParams): {[s: string]: string} {
    const params = {};
    for (const key in vkParams) {
      if (vkParams.hasOwnProperty(key) && vkParams[key] !== null && vkParams[key] !== "") {
        const val = vkParams[key];
        if (val instanceof Array && val.length > 0) {
          params[key] = val.join(',');
        } else if (val instanceof DateObj) {
          params[key] = `${val.year}-${val.month}-${val.day}`;
        } else {
          params[key] = val;
        }
      }
    }
    return params;
  }

  public getAsiakasLahetteet(): Promise<LomakeResponse[]> {
    const url = '/api/v1/as/lomakkeet/lahetteet';
    return this.getLomakkeet(url, null);
  }

  public getAsiakasIlmoitukset(lahetetytCount: number): Promise<LomakeResponse[]> {
    const url = '/api/v1/as/lomakkeet/ilmoitukset';
    const params = {lahetetytCount: lahetetytCount.toString(10)};

    return this.getLomakkeet(url, params);
  }

  getLomakkeet(url, params) {
    if (this._isGetLomakkeetRequestPending.value) {
      this.getLomakkeetSub.error("CANCEL");
    }

    const obs = new Observable(o => this.getLomakkeetSub = o);
    this._isGetLomakkeetRequestPending.next(true);
    return this.http.get(url, {params}).pipe(takeUntil(obs))
      .toPromise()
      .then(data => data as LomakeResponse[])
      .then(response => {
        this._isGetLomakkeetRequestPending.next(false);
        this._lomakkeet.next(response);
        return response;
      }).catch(reason => {
        if (reason !== "CANCEL") {
          console.error(reason);
        }
        this._isGetLomakkeetRequestPending.next(false);
        this._lomakkeet.next([]);
        return [];
      });
  }

  public deleteKeskenerainenIlmoitus(id: number): Promise<any> {
    return this.http.delete(`/api/v1/as/lomakkeet/ilmoitukset/${id}`)
      .toPromise()
      .then(() => {
        this.updateLomakkeetAfterDelete(id);
        return this.sidebarService.updatePalvelut();
      });
  }

  public deleteKeskenerainenIlmoitusV2(id: number, toimintotyyppi: string, asia: string): Promise<any> {
    const urlRoot = LomakeEndpointUtil.resolveUrlRootByToimintotyyppi(toimintotyyppi);
    return this.http.delete(`/api/v2/as/lomakkeet/${urlRoot}/${asia}/${toimintotyyppi}/${id}`)
      .toPromise()
      .then(() => {
        this.updateLomakkeetAfterDelete(id);
        return this.sidebarService.updatePalvelut();
      });
  }

  public deleteKeskenerainenLahete(id: number): Promise<any> {
    return this.http.delete(`/api/v1/as/lomakkeet/lahetteet/${id}`)
      .toPromise()
      .then(() => {
        this.updateLomakkeetAfterDelete(id);
        return this.sidebarService.updatePalvelut();
      });
  }

  public updateLomakkeet(response: LomakeResponse): LomakeResponse {
    const lomakkeet = this._lomakkeet.getValue().map(lomake => response.id === lomake.id ? response : lomake);
    this._lomakkeet.next(lomakkeet);
    return response;
  }

  public updateLomakkeetAfterDelete(id: number) {
    const lomakkeet = this._lomakkeet.getValue();
    this._lomakkeet.next(lomakkeet.filter(l => l.id !== id));
  }
}
