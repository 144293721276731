import {Component} from "@angular/core";
import {MessageService} from "./message.service";
import {Teksti} from "../utils/teksti";
import {Subscription} from "rxjs/internal/Subscription";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 01/12/2017.
 */

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
@AutoUnsubscribe
export class MessageComponent {
  messages = [];
  messageSubscription: Subscription;

  constructor(private messageService: MessageService) {
    this.messageSubscription = this.messageService.messages$.subscribe(
      msgs => this.messages = msgs
    );
  }

  isMessageTeksti(msg: {id: number; message: string | Teksti}): boolean {
    return msg.message instanceof Teksti;
  }

  closeMessage(msg) {
    this.messageService.closeMessage(msg.id);
  }
}
