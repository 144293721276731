import {ExcelMapper} from "../common/excel-mapper";
import {ExcelValidators} from "../common/excel-validators";
import {ExcelUtils} from "../common/excel-utils";

export class LannoiteExcelMapper implements ExcelMapper {
  private headerRow = [];

  public getExcelValidators(): { validatorFn: (rowData) => string[], errMsg: string }[] {

    return [
      {
        validatorFn: (rowData) => ExcelValidators.checkRequiredCells(rowData, this.headerRow),
        errMsg: "lomakeYleinen.excelPakollinenTieto"
      },
      {
        validatorFn: (rowData) => ExcelValidators.checkNegativeCellValues(rowData),
        errMsg: "lomakeYleinen.excelNegatiivinenArvo"
      },
    ]
  }
  getSheetName(excelType: string, sheetIndex: number) {
    return "lomake";
  }

  public getSectionTitles(): { [key: string]: string } {
    return {
      lannoitevalmistus: 'lannoitevuosiilmoitus.lannoiteValmistusOtsikko',
      lannoitetuonti: 'lannoitevuosiilmoitus.lannoiteTuontiOtsikko',
      lannoiteomaankayttoon: "lannoitevuosiilmoitus.lannoiteValmisteenTuotantoOmaKaytto",
      lannoitevienti: "lannoitevuosiilmoitus.lannoiteValmisteenVienti",
      lannoitejakelu: "lannoitevuosiilmoitus.lannoiteValmisteenJakelija",
      lannoiteeuvalmistus: "lannoitevuosiilmoitus.euLannoitevalmisteenValmistus",
      lannoiteeutuonti: "lannoitevuosiilmoitus.euLannoitevalmisteenTuonti",
      lannoiteeuvienti: "lannoitevuosiilmoitus.euLannoitevalmisteenVienti",
      lannoiteeujakelu: "lannoitevuosiilmoitus.euLannoitevalmisteenJakelija",
      lannoitesisamarkkinatuonti: "lannoitevuosiilmoitus.lannoitevalmisteenMarkkinointi",
    }
  }

  public isVersionAllowed(_exceltype: string, _excelVersion: string): boolean {
    return true;
  }

  public mapExcelData(excelData: {data: any[], type: string}) {
    return excelData.data[ILMOITUS_SHEET_INDEX].map(row => {

      const tyyppiNimiTunnus = row[Columns.TYYPPINIMI] ? row[Columns.TYYPPINIMI].split(' ')[0] : null;
      const tuotetyyppi = row[Columns.TUOTETYYPPI] ? row[Columns.TUOTETYYPPI].split(' ')[0] : null;
      const tuoteluokka = row[Columns.TUOTELUOKKA] ? row[Columns.TUOTELUOKKA].split(' ')[0] : null;

      return {
        maaraJaTiedot: {
          tuotenimi: row[Columns.TUOTENIMI] || null,
          maara: this.getMaara(row[Columns.MAARA]),
          maaranYksikko: row[Columns.MAARANYKSIKKO] || null,
          ilmoitaLannoiteValmistelainTuote: Boolean(tyyppiNimiTunnus),
          tyyppiNimiTunnus: tyyppiNimiTunnus,
          tuoteluokka: tuoteluokka,
          tuotetyyppi: tuotetyyppi,
          vainOmaanKayttoon: Boolean(row[Columns.VAIN_OMAANKAYTTOON]),
          vainPakkaamista: Boolean(row[Columns.VAIN_PAKKAAMISTA]),
          alperamaa: row[Columns.ALPERAMAA] || null,
          lahtomaa: row[Columns.LAHTOMAA] || null
        },
        paaravinteidenMaara: {
          typpi: row[Columns.TYPPI] || null,
          fosfori: row[Columns.FOSFORI] || null
        },
        ainesosaluokat: {
          aol1: Boolean(row[Columns.AOL1]),
          aol2: Boolean(row[Columns.AOL2]),
          aol3: Boolean(row[Columns.AOL3]),
          aol4: Boolean(row[Columns.AOL4]),
          aol5: Boolean(row[Columns.AOL5]),
          aol6: Boolean(row[Columns.AOL6]),
          aol7: Boolean(row[Columns.AOL7]),
          aol8: Boolean(row[Columns.AOL8]),
          aol9: Boolean(row[Columns.AOL9]),
          aol10: Boolean(row[Columns.AOL10]),
          aol11: Boolean(row[Columns.AOL11]),
          cmc1: Boolean(row[Columns.CMC1]),
          cmc2: Boolean(row[Columns.CMC2]),
          cmc3: Boolean(row[Columns.CMC3]),
          cmc4: Boolean(row[Columns.CMC4]),
          cmc5: Boolean(row[Columns.CMC5]),
          cmc6: Boolean(row[Columns.CMC6]),
          cmc7: Boolean(row[Columns.CMC7]),
          cmc8: Boolean(row[Columns.CMC8]),
          cmc9: Boolean(row[Columns.CMC9]),
          cmc10: Boolean(row[Columns.CMC10]),
          cmc11: Boolean(row[Columns.CMC11]),
          cmc12: Boolean(row[Columns.CMC12]),
          cmc13: Boolean(row[Columns.CMC13]),
          cmc14: Boolean(row[Columns.CMC14]),
          cmc15: Boolean(row[Columns.CMC15])
        },
        luovutukset: {
          luovutettuvalmistuttajalle: {
            checkbox: this.getCheckBox(row[Columns.LUOVUTETTU_VALMISTUTTAJALLE_MAARA]),
            maara: this.getMaara(row[Columns.LUOVUTETTU_VALMISTUTTAJALLE_MAARA])
          },
          peltoviljelyAlkuvuonna: {
            checkbox: this.getCheckBox(row[Columns.PELTOVILJELY_MAARA_ALKUVUONNA]),
            ajalla: "alkuvuosi",
            maara: this.getMaara(row[Columns.PELTOVILJELY_MAARA_ALKUVUONNA])
          },
          peltoviljelyLoppuvuonna: {
            checkbox: this.getCheckBox(row[Columns.PELTOVILJELY_MAARA_LOPPUVUONNA]),
            ajalla: "loppuvuosi",
            maara: this.getMaara(row[Columns.PELTOVILJELY_MAARA_LOPPUVUONNA])
          },
          metsanlannoitus: {
            checkbox: this.getCheckBox(row[Columns.METSANLANNOITUS_MAARA]),
            maara: this.getMaara(row[Columns.METSANLANNOITUS_MAARA])
          },
          kotitalouskaytto: {
            checkbox: this.getCheckBox(row[Columns.KOTITALOUSKAYTTO_MAARA]),
            maara: this.getMaara(row[Columns.KOTITALOUSKAYTTO_MAARA])
          },
          kasvihuoneviljely: {
            checkbox: this.getCheckBox(row[Columns.KASVIHUONEVILJELY_MAARA]),
            maara: this.getMaara(row[Columns.KASVIHUONEVILJELY_MAARA])
          },
          viherrakentaminen: {
            checkbox: this.getCheckBox(row[Columns.VIHERRAKENTAMINEN_MAARA]),
            maara: this.getMaara(row[Columns.VIHERRAKENTAMINEN_MAARA])
          },
          jatkojalostukseen: {
            checkbox: this.getCheckBox(row[Columns.JATKOJALOSTUKSEEN_MAARA]),
            maara: this.getMaara(row[Columns.JATKOJALOSTUKSEEN_MAARA])
          },
          muukaytto: {
            checkbox: this.getCheckBox(row[Columns.MUUKAYTTO_MAARA]),
            maara: this.getMaara(row[Columns.MUUKAYTTO_MAARA])
          },
          vientiEuAlueelle: {
            checkbox: this.getCheckBox(row[Columns.VIENTI_EU_ALUEELLE]),
            maara: this.getMaara(row[Columns.VIENTI_EU_ALUEELLE])
          },
          vientiEuAlueelta: {
            checkbox: this.getCheckBox(row[Columns.VIENTI_EU_ALUEELTA]),
            maara: this.getMaara(row[Columns.VIENTI_EU_ALUEELTA])
          }
        }
      };
    })
  }

  public sanitizeRowData(rowData): {rowIndex: number, data: any}[] {
    let excelRowsWithFilledData = [];
    const headerRowAmount = 3;
    if (rowData) {
      this.headerRow = rowData[1];

      const excelData = rowData.slice(headerRowAmount)
      excelRowsWithFilledData = excelData
          .filter((data, _rowId) => data[107] !== "skip")
          .map((columns, i) => {
            const normalizedData = columns.map(this.processCellValue);
            return {data: normalizedData, rowIndex: i + headerRowAmount};
          })
          .filter(row => row.data.some(rowValue => rowValue && rowValue !== ''))
    }
    return excelRowsWithFilledData;
  }

  private getMaara(maara): string {
    const maaraModified = ExcelUtils.removeSpaces(maara);
    if (maaraModified == null) {
      return null;
    } else if (Number(maaraModified) == 0) {
      return "0";
    } else {
      return maaraModified;
    }
  }

  private getCheckBox(value): boolean {
    return value != null;
  }

  private processCellValue(cell) {
    if (typeof cell === 'string') {
      return cell.trim() !== '' ? cell.trim() : null;
    }
    return cell;
  }
}

const ILMOITUS_SHEET_INDEX = 0;

enum Columns {
  TUOTENIMI,
  TYYPPINIMI,
  TUOTETYYPPI,
  TUOTELUOKKA,
  VAIN_PAKKAAMISTA,
  VAIN_OMAANKAYTTOON,
  AOL1,
  AOL2,
  AOL3,
  AOL4,
  AOL5,
  AOL6,
  AOL7,
  AOL8,
  AOL9,
  AOL10,
  AOL11,
  AOL12,
  AOL13,
  CMC1,
  CMC2,
  CMC3,
  CMC4,
  CMC5,
  CMC6,
  CMC7,
  CMC8,
  CMC9,
  CMC10,
  CMC11,
  CMC12,
  CMC13,
  CMC14,
  CMC15,
  MAARA = 35,
  MAARANYKSIKKO,
  ALPERAMAA,
  LAHTOMAA,
  TYPPI,
  FOSFORI,
  LUOVUTETTU_VALMISTUTTAJALLE_MAARA,
  PELTOVILJELY_MAARA_ALKUVUONNA,
  PELTOVILJELY_MAARA_LOPPUVUONNA,
  METSANLANNOITUS_MAARA,
  KOTITALOUSKAYTTO_MAARA,
  KASVIHUONEVILJELY_MAARA,
  VIHERRAKENTAMINEN_MAARA,
  JATKOJALOSTUKSEEN_MAARA,
  MUUKAYTTO_MAARA,
  VIENTI_EU_ALUEELLE,
  VIENTI_EU_ALUEELTA,
  SKIP = 26,
}
