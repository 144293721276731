import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {Teksti} from "../../../utils/teksti";
import {MessageService} from "../../../message/message.service";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: "lataa-template-component",
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="far fa-file font-lg text-primary" aria-hidden="true"></i>
        <span class="ml-3" attribute="{{templateName}}"></span>.xlsx
      </div>
      <div>
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="getLiite(templateName)"
                id="{{templateId}}-btn">
          <i class="fas fa-download" aria-hidden="true"></i>
          <span attribute="lomakeYleinen.btnLataaExcel">Lataa</span>
        </button>
      </div>
    </div>
    <a class="invisible" #downloadlink></a>
  `
  ,

})

export class LataaTemplateComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("downloadlink", {static: true}) downloadlink: ElementRef;
  @Input() templateId: string;
  @Input() templateName: string;

  constructor(private readonly messageService: MessageService, private http: HttpClient) {
    this.getLiite = this.getLiite.bind(this);
  }

  ngOnInit() {
    // This is intentional
  }

  getLiite(liite: string) {
    return this.http.get(`/api/v1/as/template/${liite}.xlsx`, {responseType: 'blob' as 'json'})
      .toPromise()
      .then(blobStr => {
        const filename = liite + '.xlsx';
        if (this.isIE()) {
          window.navigator.msSaveBlob(blobStr, filename);
        } else {
          const blob = new Blob([blobStr as BlobPart], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const file = window.URL.createObjectURL(blob);
          const linkElement = this.downloadlink.nativeElement;
          linkElement.href = file;
          linkElement.download = filename;
          linkElement.click();
          window.URL.revokeObjectURL(file);
        }
      }).then(() => {
        this.messageService.addMessage(new Teksti("Lataus onnistui", "liitteenLatausOnnistui", "raportit"), 4000, "success");
      });
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }

}
