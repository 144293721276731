import {Component, Input, OnInit} from "@angular/core";
import {Valvontakohde, ValvontakohdeDetails} from "../../valvontakohde/valvontakohde";
import {AccountService} from "../account.service";
import {Subscription} from "rxjs";
import {ValvontakohdeService} from "../../valvontakohde/valvontakohde.service";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 31/07/2018.
 */

@Component({
  selector: 'account-tiedot',
  templateUrl: './account-tiedot.component.html',
  styleUrls: ["../account.scss"]
})
@AutoUnsubscribe
export class AccountTiedotComponent implements OnInit {
  valvontakohde: Valvontakohde = null;
  valvontakohdeDetails: ValvontakohdeDetails = null;

  @Input() toimiala: string = null;

  private readonly subs: Subscription[] = [];

  constructor(private readonly valvontakohdeService: ValvontakohdeService,
              private readonly accountService: AccountService) {
  }

  ngOnInit(): void {
    if (this.toimiala === null) {
      this.toimiala = this.accountService.getCurrentAccount().toimiala;
    }

    this.subs.push(this.valvontakohdeService.valvontakohde$.subscribe(v => {
        this.valvontakohde = v;
        if (v !== null) {
          let details = null;
          if (v.valvontakohdeDetails !== null && v.valvontakohdeDetails.length > 0) {
            details = v.valvontakohdeDetails.filter(d => d.toimiala === this.toimiala)[0];
          }
          this.valvontakohdeDetails = details;
        }

    }));
  }
}
