import {Component, Input} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-tausta-select-text',
  template: `
    <div [formGroup]="controlContainer.control" class="form-group">
      <label *ngIf="questionGroup.label">{{questionGroup.label}}</label>
      <ng-container *ngFor="let control of questionGroup.controls">
        <ng-container [ngSwitch]="control.controlType">
          <ng-container *ngSwitchCase="'selection'">
            <label [htmlFor]="control.key">{{control.label}}</label>
            <select class="form-control" [id]="control.key" [formControlName]="control.key">
              <option *ngIf="!control.initialValue" [value]="null" disabled selected>{{'teksti.valitse' | attribute}}...</option>
              <option *ngFor="let option of control.options.selection" [value]="option.value">
                {{option.label}}
              </option>
            </select>
          </ng-container>
          <ng-container *ngSwitchCase="'text-input'">
            <label [htmlFor]="control.key">{{control.label}}</label>
            <input class="form-control" [id]="control.key" [formControlName]="control.key" />
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  `
})
export class QuestionTaustaSelectTextComponent {
  @Input() questionGroup: QuestionGroup;

  constructor(public controlContainer: ControlContainer) {
  }
}
