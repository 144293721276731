import { Teksti } from "../../utils/teksti";
import { LiitetiedostoResponse } from "../touko-lomake-utils";
import {YhteenvetoComponentBase} from "./yhteenveto-container.component";
import {Type} from "@angular/core";
import {YhteenvetoItemComponent} from "./yhteenvet-item/yhteenveto-item.component";

/**
 * Lomakkeen formGroupin yhteenvetotyypit
 */
export enum YhteenvetoTyyppi {
  NONE,
  VALUE_ONLY, // näytetään ainoastaan kentän arvo
  LABEL_ONLY, // näytetään ainoastaan label
  LABEL_AND_VALUE, // näytetään molemmat
  LABEL_PREFORMATTED,
  LABEL_AND_VALUE_BY_OPTIONS, // näytetään kenttä ja lisäksi arvo options-objektin mukaan
  CHECKBOXES_WITH_OPTIONAL,
  YES_NO, // näytetään kentän label sekä "Kyllä" tai "Ei"
  LABEL_AND_TEXT, // näytetään kentän label ja vapaamuotoinen teksti
  REHU_LABEL_TEXT, // näytetään kentän label, kysymys ja vastaus
  REHU_YHTEISETKYSYMYKSET,
  SINGLE_SELECTION_LABEL, // näytetään valitun vaihtoehdon label
  SINGLE_SELECTION_DATE,
  SINGLE_SELECTION_DATE_AND_LABEL,
  ATTACHMENT, // näytetään kysymys ja tiedoston nimi
  ATTACHMENT_NO_LABEL, // näytetään tiedoston nimi, mutta ei kysymystä
  ATTACHMENT_DATE, // näytetään lista liitteitä tai toimituspäivämäärä
  LABEL_OR_ATTACHMENT,
  OSOITE_OR_TEXT, // perustietojen käsittely
  LASKUTUSTIETO,
  YHTEYSHENKILOT,
  LUOMULUVAT, // LUOMU: Lupien tiedot // TODO :Tämä voi vielä muuttua, tuleeko eri luville omat tyypit?,
  SIIRTYMAAJANLYHENNYSLUVAT, // Luomulomake: Siirtymäajan lyhennys
  ELAINTUOTANTOLUPA_SL, // Luomulomake: Eläintuotantolupa, siirtymäajan lyhennys
  ELAINTUOTANTOLUPA_NAUTOJENKYTKEMINEN,
  ELAINTUOTANTOLUPA_TAVANOMELAINPOIKKEUSOLOSUHTEET,
  ELAINTUOTANTOLUPA_NAARASELAINHANKINTA,
  ELAINTUOTANTOLUPA_TAVANOMELAINENSIMMAINENKERTA,
  ELAINTUOTANTOLUPA_TAVANOMSIIPIKARJA,
  ELAINTUOTANTOLUPA_TAVANOMREHUPOIKKEUSOLOISSA,
  ELAINTUOTANTOLUPA_SUORITETTAVATTOIMENPITEET,
  ELAINTUOTANTOLUPA_MEHILAISHOITO,
  MUUTOSILMOITUS_OMISTAJAMUUTOS,
  MUUTOSILMOITUS_UUSITOIMIPAIKKA,
  MUUTOSILMOITUS_UUSIALIHANKKIJA,
  MUUTOSILMOITUS_TOIMINNANKESKEYTYS_ALOITUS_KATKO,
  MUUTOSILMOITUS_TOIMINNAN_MUUTOS,
  MUUTOSILMOITUS_TOIMINNAN_MUUTOS_LUOMUELINTARVIKKEET,
  MUUTOSILMOITUS_LISATIETOJA,
  LAITE_HYVAKSYTTY_PVM, //Siemenpakkaamon lupahakemus lomakkeeseen laitteiden hyväksymispäivämäärän tyyppi.
  SYOTE_CHECKBOX_AND_DATE_ARRAY, //Syotteen arrayn label ja value
  SIEMENPAKKAAMO_VALUE_ONLY, // Siemenpakkaamon lupahakemukseen oma value only kenttä, jonka käsittely poikkeaa hieman normaalista value onlysta.
  SYOTE_DATE_ARRAY,
  VERKKOKAUPAT,
  LANNOITE_KANSALLINEN_TOIMINTA,
  LABEL_AND_VALUE_ARRAY,
  LANNOITEVALMISTEEN_JAKELIJA,
  LANNOITEVALMISTE_AINESOSALUOKAT,
  LANNOITE_VARASTOINTI,
  LANNOITE_EU_TOIMINTA,
  LANNOITE_EU_TOIMINTA_VALMISTAJA_VALMISTUTTAJA,
  LANNOITE_MUU_TOIMINTA,
  LANNOITE_SIVUTUOTE,
  LANNOITE_SIVUTUOTE_LAITOSHYVAKSYNTA,
  LANNOITE_SIVUTUOTE_YMPARISTOLUPA,
  LANNOITE_SIVUTUOTE_RAAKAAINEET,
  RADIO_SELECTION,
  LANNOITE_EU_JAKELIJA,
}

export class YhteenvetoTekstiConstants {
  public static readonly HAKEMUKSEN_TIEDOT = new Teksti("Hakemuksen tiedot");
}

/**
 * Yksittäisen lomakekentän esitys yhteenvedossa.
 */
export class YhteenvetoItem {

  public static readonly EI_TEKSTI = new Teksti("Ei", "ei", "teksti");
  public static readonly KYLLA_TEKSTI = new Teksti("Kyllä", "kylla", "teksti");

  htmlId: string;
  label: Teksti;
  value: Teksti;
  valueMap: {[key: string]: Teksti};
  infos: Teksti[];
  subitems: YhteenvetoItem[];
  downloadLink: LiitetiedostoResponse = null;
  component?: Type<YhteenvetoComponentBase>;
  summaryType?: YhteenvetoTyyppi;
  options?: {[key: string]: any};

  constructor(label: Teksti = null, value: Teksti | {[key: string]: Teksti} = null, htmlId: string = null, infos: Teksti[] = [], summaryType: YhteenvetoTyyppi = null, component: Type<YhteenvetoComponentBase> = YhteenvetoItemComponent ) {
    this.label = label;
    if (value === null || value instanceof Teksti) {
      this.value = value as Teksti;
    } else {
      this.valueMap = value;
    }
    this.summaryType = summaryType;

    if (htmlId) {
      this.htmlId = htmlId;
    } else if (label) {
      this.htmlId = this.label.key;
    } else if (value instanceof Teksti && value.key) {
      this.htmlId = value.key;
    }

    if (!this.htmlId) {
      console.error("htmlId tulee määrittää");
      console.error(label);
      console.error(value);
    }
    this.infos = infos;
    this.component = component;
  }

  /**
   * Tarkistaa, ovatko yhteenveto objektit samanlaiset.
   *
   * @param other - Yhteenveto-objekti, johon tietoja verrataan
   */
  compare(other: YhteenvetoItem): boolean {
    if (other.value) {
      return other.value.hash === this.value.hash;
    }

    if (this.subitems) {
      if (!this.hasEqualAmountOfSubitems(other)) {
        return false;
      }
      const sortedOtherSubitems = [...other.subitems].sort(sortYhteenvetoItem);
      const sortedSubItems = [...this.subitems].sort(sortYhteenvetoItem);
      const areNotEqual = sortedSubItems.some((thisItem, i) => !thisItem.compare(sortedOtherSubitems[i]));

      if (areNotEqual) {
        return false;
      }
    }

    if (other.infos) {
      const val1 = other.infos.map(info => info.hash).join("_");
      const val2 = this.infos.map(info => info.hash).join("_");
      return val1 === val2;
    }

    return other.label.hash === this.label.hash;
  }

  private hasEqualAmountOfSubitems(other: YhteenvetoItem) {
    return other.subitems && other.subitems.length === this.subitems.length;
  }

  hasSubitems(): boolean {
    return this.subitems && this.subitems.length > 0;
  }
}

export function sortYhteenvetoItem(a: YhteenvetoItem, b: YhteenvetoItem): number {
  if (!a) {
    return (b) ? 1 : 0;
  }

  return a.htmlId.localeCompare(b.htmlId);
}

export class YhteenvetoCompareItem {
  currentItem: YhteenvetoItem;
  prevItem: YhteenvetoItem;
  nonNullItem: YhteenvetoItem;
  compareStatus: CompareStatus;

  constructor(currentItem: YhteenvetoItem, prevItem?: YhteenvetoItem) {
    this.currentItem = currentItem;
    this.prevItem = prevItem;
    this.nonNullItem = currentItem || prevItem;
    this.compareStatus = this.getChangeStatus(currentItem, prevItem);
  }

  private getChangeStatus(current: YhteenvetoItem, prev: YhteenvetoItem): CompareStatus {
    if (prev === null) {
      return CompareStatus.ADD;
    } else if (current === null) {
      return CompareStatus.REMOVE;
    } else if (current.compare(prev)) {
      return CompareStatus.UNCHANGED;
    } else {
      return CompareStatus.MODIFY;
    }
  }
}

export enum CompareStatus {
  UNCHANGED,
  ADD,
  MODIFY,
  REMOVE
}

export class YhteenvetoGroup {
  title: Teksti = null;
  data: YhteenvetoItem[] = [];
}

export class YhteenvetoCompareGroup {
  title: Teksti = null;
  data: YhteenvetoCompareItem[] = [];
}

export interface YhteenvetoCompareGroupDict {
  [key: string]: { [itemKey: string]: YhteenvetoItem };
}
