import {AfterViewInit, Component, OnInit, Renderer2} from "@angular/core";
import {AccountService} from "../account/account.service";
import {TiiraService} from "../tiira/tiira.service";
import {Account, KayttajaRooli} from "../account/account";
import {Subscription} from "rxjs/internal/Subscription";
import {LomakeRedirectService} from "../touko-lomake/lomake-redirect.service";
import {EnvironmentService} from "../../environments/environment.service";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 02/11/2017.
 */

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
@AutoUnsubscribe
export class MainNavComponent implements OnInit {

  user: Account = null;
  accountSubscription: Subscription;
  KayttajaRooli = KayttajaRooli;

  ymparisto: string;
  isFocusSkipLinkActive = false;
  lang: string;

  /**
   *
   * @param tiiraService - servicen avulla päätellään, näytetäänkö sivupalkin menu-painike
   * @param accountService - päivittää käyttäjätiedot yläpalkkiin
   * @param lomakeRedirectService
   * @param renderer
   */
  constructor(private tiiraService: TiiraService,
              private accountService: AccountService,
              private environmentService: EnvironmentService,
              private lomakeRedirectService: LomakeRedirectService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.accountSubscription = this.accountService.account$.subscribe((data) => this.user = data);
    this.tiiraService.language$.subscribe(lang => this.lang = lang);
    this.lang = this.tiiraService.getCurrentLanguage();
    this.ymparisto = this.environmentService.environment;
  }

  onFocusSkipLink(isFocusSkipLinkActive: boolean) {
    this.isFocusSkipLinkActive = isFocusSkipLinkActive;
  }

  clickSkipLink(event) {
    const element = this.renderer.selectRootElement("#content", true);
    element.focus();
    element.scrollIntoView();
    event.preventDefault();
  }

  /**
   * Uloskirjautuminen
   */
  logout() {
    this.lomakeRedirectService.canRedirect = true;
    this.tiiraService.logout();
  }

  isLoggedIn() {
    return this.tiiraService.isLoggedIn();
  }

  getEtusivuUrl(): string {
    return this.tiiraService.getEtusivuUrl();
  }
}

