import {Field, FieldGroup} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {Injectable} from "@angular/core";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {createExcelTiedostot} from "../common/lomake-fields/excel-lataus";
import {createKansallinenToiminta} from "./kansallinen-toiminta";
import {createEUToiminta} from "./eu-toiminta";
import {createMuuToiminta} from "./muu-toiminta";
import {createEiToimintaa, createLisatiedot, createSahkoinenAsiointi} from "../common/lomake-fields/common-fields";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";

const attr = TulkkiObject.getCreateFn('lomakeYleinen');
const vuosiilmoitusAttr = TulkkiObject.getCreateFn('lannoitevuosiilmoitus');

@Injectable()
export class LannoitevuosiilmoitusData implements LomakeV2Data {

  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();
    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
      Field.build(
        attr('lannoitevuosiilmoitus', 'lannoitevuosiilmoitus')
      ),
      {
        ulkomainenOsoite,
        perustiedot,
        laskutustiedot,
        yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
        sahkoinenAsiointi: createSahkoinenAsiointi(),
        excelTiedostot: createExcelTiedostot(),
        tuotteet: new FieldGroup(Field.build(null, null, {pakollinentieto: attr('errExcelTaiEiToimintaaPuuttuu')}), {
            excelTiedostot: createExcelTiedostot(),
            lannoiteEiToimintaa: createEiToimintaa(vuosiilmoitusAttr('eiToimintaa'), vuosiilmoitusAttr('eiToimintaaYhteenvetoInfo')),
          },
          [(group) => {
            const validateEiKumpaakaanValittu = !group.get('lannoiteEiToimintaa').value && !group.get('excelTiedostot').value?.length;
            return validateEiKumpaakaanValittu ? {pakollinentieto: true} : null;
          }]
        ),
        lisatiedot: createLisatiedot(),
        toimintaJaTuoteluokatKansallinen: createKansallinenToiminta(),
        euToiminta: createEUToiminta(),
        muuToiminta: createMuuToiminta()
      }
    );
  }
}
