import {Component, Input, OnInit} from "@angular/core";
import {QuestionComponent, QuestionComponentData, QuestionGroup} from "ee-lahete";
import {ControlContainer, FormControl} from "@angular/forms";

@Component({
  selector: 'question-radio-card',
  styles: ['.pre-wrap {white-space: pre}', 'input {visibility:hidden; position: absolute}'],
  template: `
    <div [formGroup]="controlContainer?.control" class="form-group">
      <label class="card" [ngClass]="{'border-primary bg-light': isSelected()}"
             question-error [isTouched]="control.touched" [isInvalid]="control.invalid" [errorStyle]="'border-danger'">
        <div class="card-body" [ngClass]="{'text-primary': isSelected()}">
          <input [formControlName]="questionData.formControlName"
                 [checked]="isSelected()"
                 [value]="questionData.question.value"
                 type="radio"/>
          <div class="card-text pre-wrap">{{questionData.label}}</div>
        </div>
      </label>
    </div>
    <question-error [control]="control"
                    [sivuRef]="sivuRef"
                    [fullPath]="questionData.question.fullPath"
                    [conditions]="[isLast]"
                    [errorMessages]="questionData.question.errorMessages"></question-error>
  `
})
export class QuestionRadioCardComponent extends QuestionComponent implements OnInit {
  @Input() questionData: QuestionComponentData;
  @Input() questionParent: QuestionGroup;
  @Input() isLast = false;
  @Input() sivuRef: string;

  control: FormControl;

  constructor(public controlContainer: ControlContainer) {
    super();
  }

  public isSelected() {
    return this.questionData.question.value === this.control.value;
  }

  ngOnInit(): void {
    if (this.controlContainer.control && this.questionData) {
      this.control = this.controlContainer.control.get(this.questionData.formControlName) as FormControl;
    }
  }

}
