import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'translated-question-and-answer-with-info-summary',
  template: `
    <div *ngIf="item" class="row" [ngClass]="{'pb-2': !compareItemType}">
      <div class="col">
        <span *ngIf="!compareItemType" [id]="'label-' + item.field.htmlId" [attribute]="item?.question"></span>
        <compare-item  [compareItemType]="compareItemType">
          <span [id]="'value-' + item.field.htmlId" class="answer" [attribute]="item?.answer"></span>
        </compare-item>
      </div>
    </div>
  `,
  styleUrls: ['./summary.scss']
})
export class TranslatedQuestionAndAnswerTwoRowsSummaryComponent {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
