import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareType, MatcherModel} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";


export class TutkimushakuGroup extends AbstractLaheteGroup {
  getGroup(controlType?: string): QuestionGroup {
    return new QuestionGroup({
      key: 'tutkimushaku',
      label: this.getLabel('tutkimusFilter'),
      controls: [
        new QuestionControl({
          controlType: controlType,
          value: 'Hae tutkimuksista',
          initialValue: "",
          key: 'tutkimushaku',
          htmlId: 'tutkimushaku',
          placeholder: this.getLabel('tutkimusFilterPlaceholder'),
          modifiers: {
            'elainlajit.elainlajiryhma': new MatcherModel(null, TutkimushakuGroup.anyObjectValueTrueResetFn, TutkimushakuGroup.modifierActionFn, CompareType.SOME)
          }
        })
      ],
    });
  }
}
