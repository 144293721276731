import {Teksti} from "../utils/teksti";

/**
 * Created by Seppo on 10/04/2018.
 */

export class RaporttiHaku {
  raporttiLkm = 0;
  raportit: Raportti[] = [];

  constructor(raporttiLkm = 0, raportit = []) {
    this.raporttiLkm = raporttiLkm;
    this.raportit = raportit;
  }
}

export class Raportti {
  kauppaera: string;
  kasiteltyAika: Date;
  tapahtumatyyppi: string;
  tapahtumatyyppiNimi: string;
  kasvilaji: string;
  kasvilajike: string;
  naytteenottotila: string;
  avattu: boolean;
  liitetiedostoList: RaporttiLiitetiedosto[];
}

export class RaporttiLiitetiedosto {
  tiedostoNimi: string;
  tiedostoTunnus: string;
  viimeksiAvattu: string;
  piilotettu: boolean;
}

export class ViljelystarkastusRaportti {
  kasiteltyAika: Date;
  tapahtumatyyppi: string;
  tapahtumatyyppiNimi: string;
  tiedostonimi: string;
  kasvilaji: string;
  kasvilajike: string;
  viljelija: string;
}

export enum SarakeType {
  DATE = "DATE",
  FILE_LIST = "FILE_LIST",
  TYPE = "TYPE",
  NTILA = "NTILA",
  DEFAULT = "DEFAULT",
  LOMAKE_LINK = "LOMAKE_LINK",
  ID = "ID",
  DATETIME = "DATETIME",
  SYMBOL = "SYMBOL",
  SYMBOL_LINK = "SYMBOL_LINK",
}

export class Sarake {
  private readonly _type: SarakeType;
  private readonly _key: string;
  private readonly _title: Teksti;
  private readonly _styleFn: (value: any) => string;

  constructor(key, title, type = SarakeType.DEFAULT, styleFn = (values) => "") {
    this._key = key;
    this._title = title;
    this._type = type;
    this._styleFn = styleFn;
  }


  get type(): SarakeType {
    return this._type;
  }

  get key(): string {
    return this._key;
  }

  get title(): Teksti {
    return this._title;
  }

  get styleFn(): (value: any) => string {
    return this._styleFn;
  }
}

export enum Tapahtumatyyppi {
  TRADBATCHCERTDECIS,
  SAMPLING
}

export enum SortOrder {
  ASC = -1,
  DESC = 1
}

/**
 * TagList-komponentin arvo-objekti
 */
export class Tag {
  nimi: string | Teksti;
  value: any;
  style: string;

  /**
   * TagList-syötekentässä käytettävä objekti.
   *
   * @param nimi - Lokalisoitu nimi (käytä esim. AttributeServicen simpleTranslate funktiota)
   * @param value - Tagin arvo valittuna
   * @param style - Tyyli (esim. badge-success)
   */
  constructor(nimi: string | Teksti, value: any, style = 'badge-primary') {
    this.nimi = nimi;
    this.value = value;
    this.style = style;
  }
}
