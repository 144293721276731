<div class="table-responsive">
  <table [ngClass]="viewOptions.tableStyles">
    <thead *ngIf="viewOptions.showHeadings">
    <tr>
      <th scope="col" *ngFor="let sarake of sarakkeet" (click)="sortByKey(sarake.key)" [ngClass]="{'d-none': sarake.title === undefined}">
        <span [attribute]="sarake.title?.getFullKey()"></span>
        <span [sort-arrow]="getJarjestys(sarake.key)"></span>
      </th>
    </tr>
    </thead>
    <tbody>

    <ng-container *ngIf="viewOptions.rowLinkFormatter; then rowsWithLink else rowsWithoutLink"></ng-container>

    <ng-template #rowsWithoutLink>
      <tr lomake-row
          *ngFor="let lomake of lomakkeet"
          [ngClass]="{'table-danger': lomake.lomakeVirhe}"
          [lomake]="lomake"
          [id]="viewOptions.rowIdFormatter(lomake)"
          [sarakkeet]="sarakkeet"
          (click)="clickRow(lomake)"
          (selectItem)="clickItem($event)"></tr>
    </ng-template>

    <ng-template #rowsWithLink>
      <tr lomake-row
          *ngFor="let lomake of lomakkeet"
          [ngClass]="{'table-danger': lomake.lomakeVirhe}"
          [routerLink]="viewOptions.rowLinkFormatter(lomake)"
          [lomake]="lomake"
          [id]="viewOptions.rowIdFormatter(lomake)"
          [sarakkeet]="sarakkeet"
          (selectItem)="clickItem($event)"></tr>
    </ng-template>

    </tbody>
  </table>
</div>
