import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {LiitetiedostoPalvelinService} from "../touko-lomake/touko-liitetiedosto.service";
import {ExcelError} from "../touko-lomake/syote/custom/touko-excel-lataus.component";

@Component({
  selector: 'excel-list',
  template: `
    <div class="mt-4">
      <div *ngFor="let excel of loadedExcels; index as i;">
        <h2 class="h3" [attribute]="excel.sectionTitle"></h2>
        <div class="d-flex align-items-center justify-content-between mb-4">
          <div class="d-flex align-items-center">
            <i class="far fa-file font-lg text-primary" aria-hidden="true"></i>
            <span class="ml-3" id="{{excel.type}}-{{i}}">{{ excel.name }}</span>
          </div>
          <div>
            <button type="button" class="btn btn-outline-primary btn-sm mr-1" id="lataa-{{i}}"
                    (click)="lataaExcel(excel)" [disabled]="disabled">
              <i class="fas fa-download" aria-hidden="true"></i>
              <span [attribute]="lataaBtnLabel">Lataa</span>
            </button>
            <button *ngIf="excelRemovable" type="button" id="poista-{{i}}" class="btn btn-outline-primary btn-sm"
                    (click)="removeExcel(i, excel)"
                    attribute="teksti.poista" [disabled]="disabled"><i class="fas fa-times" aria-hidden="true"></i>Poista
            </button>
          </div>
        </div>
        <div *ngIf="excel.errors.length > 0" class="mt-4 mb-4">
          <touko-alert-box alertType="error" [closable]="true" (close)="closeAlert()">
            <p><span attribute="lomakeYleinen.excelSisaltaaVirheita">Lataamasi tiedosto sisälsi virheitä. Korjaa virheet ja lataa uudelleen tiedosto: </span>
              <strong>{{ excel.name }}</strong></p>
            <div *ngFor="let sheet of excel.errors">
              <p><strong>{{ sheet.sheetName }}</strong></p>
              <ul *ngFor="let error of sheet.errors">
                <li><span [attribute]="error.errorMsg"></span>
                  <ul>
                    <li *ngFor="let cell of error.cells">
                      {{ cell }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </touko-alert-box>
        </div>
      </div>
    </div>
    <a class="invisible" #downloadlink></a>
  `
})
export class ExcelListComponent {
  @Output() removeExcelData = new EventEmitter<any>();

  @ViewChild("downloadlink", { static: true }) downloadlink: ElementRef;

  @Input() excelRemovable = true;
  @Input() loadedExcels: LoadedExcel[] = [];
  @Input() disabled = false;
  @Input() lataaBtnLabel = "lomakeYleinen.btnLataaExcel";

  excelTypeAlreadyExists = false;

  constructor(private liitetiedostoService: LiitetiedostoPalvelinService,) {
  }

  removeExcel(index, excel: LoadedExcel) {
    this.removeExcelData.emit({excel, index});
  }

  closeAlert() {
    this.excelTypeAlreadyExists = false;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  lataaExcel(excel: LoadedExcel) {
    return this.liitetiedostoService.downloadLiitetiedosto(excel.liiteId, {liitetiedosto: excel.name}).then(blob => {
      const file = window.URL.createObjectURL(blob);
      const linkElement = this.downloadlink.nativeElement;
      linkElement.href = file;
      linkElement.download = excel.name;
      linkElement.click();
      window.URL.revokeObjectURL(file);
    });
  }
}

export interface LoadedExcel {
  name: string;
  sectionTitle: string;
  type: string;
  liiteId: number;
  errors: ExcelError[];
}
