import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, RadioControlValueAccessor} from "@angular/forms";

@Component({
  selector: "touko-radio-input",
  template: `
    <div class="d-flex align-items-center mb-4" [ngClass]="{'input-disabled': disabled}">
      <input class="radio-button" type="radio" [checked]="checked" [id]="htmlId" [disabled]="disabled"
             [attr.name]="radioGroup" [value]="value">
      <label class="mb-0 ml-3" [htmlFor]="htmlId" [attribute]="labelAttribute" [id]="htmlId + '-label'"></label>
    </div>
  `
,
providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => RadioComponent),
  }
]})
export class RadioComponent extends RadioControlValueAccessor implements ControlValueAccessor {

  @Input() radioGroup: string;
  @Input() disabled: boolean;
  @Input() htmlId: string;
  @Input() labelAttribute: string;
  @Input() checked: boolean;

  writeValue(value: any) {
    super.writeValue(value);
    this.checked = value === this.value;
  }

  setDisabledState(disabled: boolean) {
    super.setDisabledState(disabled);
    this.disabled = disabled;
  }
}
