import { Component, Input } from "@angular/core";

@Component({
  selector: "touko-collapse-component",
  template: `
  <span *ngIf="infoAttribute" class="float-right text-danger" [attribute]="infoAttribute"></span>
  <button type="button"
          [id]="htmlId"
          class="btn dropdown-btn border-0 shadow-none p-0 d-flex align-items-center mb-4 text-left"
          (click)="collapse.toggle()"
          [attr.aria-expanded]="!isCollapsed"
          [attr.aria-label]="'lomakeYleinen.avaaTaiSulje'">
    <i *ngIf="isCollapsed" class="fa fa-plus-circle text-primary font-lg" aria-hidden="true"></i>
    <span *ngIf="!isCollapsed">
      <i class="fa fa-minus circle text-primary" aria-hidden="true"></i>
    </span>
    <span [attribute]="labelAttribute"></span>
  </button>
  <div class="collapse pl-5" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ng-content></ng-content>
  </div>
  `,
  styleUrls: ["collapse-component.scss"]
})
export class CollapseComponent {
  @Input() isCollapsed = true;
  @Input() htmlId: string;
  @Input() labelAttribute: string;
  @Input() infoAttribute: string;
}
