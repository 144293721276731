/**
 * Created by Seppo on 01/11/2018.
 */
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {Laskutustiedot} from "../syote-utils";
import {Subscription} from "rxjs/internal/Subscription";
import {SyoteInterface} from "../syote-interface";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";

/**
 * Peruslohkokomponentti
 */
@Component({
  selector: 'touko-laskutustiedot',
  template: `
    <div [formGroup]="controlContainer.control">

      <!-- LASKUTUSOSOITE -->
      <touko-osoite id="lt-lasku" class="form-row mb-3" [osoiteTeksti]="formFields[0].label" formControlName="laskutusosoite"></touko-osoite>
      <touko-verkkolasku class="form-row mb-3" formControlName="verkkolaskutusosoite"></touko-verkkolasku>
    </div>
  `,
})
@AutoUnsubscribe
export class LaskutustiedotComponent implements OnInit {

  @Input() formFields: SyoteInterface[];
  @Output() laskutustiedotUpdate: EventEmitter<Laskutustiedot> = new EventEmitter();
  laskutustiedot = new Laskutustiedot();
  valueSubscription: Subscription;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.valueSubscription = this.controlContainer.valueChanges.subscribe(values => {
      if (values) {
        this.laskutustiedotUpdate.emit(values);
      }
    });
  }
}
