<touko-lomake [formGroup]="lomakeValues"
              [tunnus]="id"
              [otsikko]="lomakeTitle"
              [saveable]="isPerustiedotValid && isLiitetiedostotUploaded"
              [submitFn]="submitLomake"
              [saveFn]="saveLomake"
              [cancelFn]="cancelLomake"
              (onLastPage)="updateYhteenvetoValues()"
              (onSivuChanged)="handleLeavePerustiedotSivu($event)">

  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'" [ohjeet]="ohjeet.sivu0" #sivuPerustiedot>
    <div class="mb-4" *ngIf="!isPerustiedotValid">
      <touko-alert-box>
        <p class="mb-0" attribute="lomakeYleinen.perustiedotTuleeTayttaa">Perustiedot tulee täyttää</p>
      </touko-alert-box>
    </div>

    <syote-group [otsikko]="lomakeData.perustiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('perustiedot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.perustiedot.errorMessages">
      <touko-perustiedot (perustietoUpdate)="updatePerustiedot($event)" [formFields]="lomakeData.perustiedot.data"
                         [paatoiminimi]="allekirjoitus.paatoiminimi"
                         formGroupName="perustiedot"></touko-perustiedot>
    </syote-group>

    <syote-group [otsikko]="lomakeData.laskutustiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('laskutustiedot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.laskutustiedot.errorMessages">
      <p attribute="kasvinterveysrekisteri.perustiedotVerkkolaskuOhje" [markdown]="true">Tarkista
        verkkolaskutusosoite</p>
      <touko-laskutustiedot [formFields]="lomakeData.laskutustiedot.data"
                            formGroupName="laskutustiedot"></touko-laskutustiedot>
    </syote-group>

    <syote-group [otsikko]="lomakeData.yhteyshenkilot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('yhteyshenkilot'), sivuPerustiedot.ref, true)"
                 [virheSanomat]="lomakeData.yhteyshenkilot.errorMessages"
                 formGroupName="yhteyshenkilot">
      <yhteyshenkilot-input (yhteyshenkilotUpdate)="updateYhteyshenkilot($event)"
                            formControlName="yhteyshenkilot"
                            [rooliValues]="yhteyshenkiloRoolit">
      </yhteyshenkilot-input>
    </syote-group>

    <syote-group [otsikko]="lomakeData.toiminnanAloitus.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('toiminnanAloitus'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.toiminnanAloitus.errorMessages"
                 formGroupName="toiminnanAloitus">
      <div class="row">
        <div class="col-md-6">
          <touko-pvm formControlName="toiminnanAloitus"
                     [htmlId]="lomakeData.toiminnanAloitus.singleField.name"
                     [item]="lomakeData.toiminnanAloitus.singleField"></touko-pvm>
        </div>
      </div>
    </syote-group>

    <syote-group *ngIf="toimintotyyppi === 'muutos'" [otsikko]="lomakeData.sahkoinenAsiointi.title"
                 formGroupName="sahkoinenAsiointi">
      <p attribute="lomakeYleinen.infoSahkAsiointi"></p>
      <touko-checkbox htmlId="check-sahkoinen-asiointi"
                      [labelAttribute]="lomakeData.sahkoinenAsiointi.singleField.label.fullKey"
                      formControlName="sahkoinenAsiointi"></touko-checkbox>
    </syote-group>

  </sivu>

  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuToiminta'" [ohjeet]="ohjeet.sivu1"
        [selectable]="isPerustiedotValid">

    <fieldset>
      <legend attribute="kasvinterveysrekisteri.toimOtsikko">Toiminta</legend>
      <div class="form-group" formGroupName="toiminta">
        <p attribute="kasvinterveysrekisteri.toimSelite" [markdown]="true">Valitse toimintaasi parhaiten kuvaavat
          vaihtoehdot.</p>
        <div class="form-row">
          <toiminta-card *ngFor="let item of lomakeData.toiminta.data" class="col-md-4 mb-4"
                         [formControlName]="item.name" [otsikko]="item.label"
                         [toimintaDetails]="item.options.toiminta"></toiminta-card>
        </div>
      </div>
    </fieldset>

  </sivu>

  <sivu #sivu1 [attribuuttiAvain]="'kasvinterveysrekisteri.sivuTuotanto'" [ohjeet]="ohjeet.sivu2"
        [selectable]="isPerustiedotValid">

    <toiminta-alert
      *ngIf="!toiminnatActive.toimAlaPuutarhaKasvi && !toiminnatActive.toimAlaPuutarhaTarha && !toiminnatActive.toimAlaPuuISPM && !toiminnatActive.toimAlaPerunaViljely">
      <p attribute="kasvinterveysrekisteri.sivuTuotantoAlert" [markdown]="true">
        Mikäli toimipaikkasi harjoittaa kasvien tai taimien tuotantoa, valitse Toiminta-välilehdeltä
        <em>Taimitarhat</em> tai <em>Kasvihuoneviljelmät</em>.
      </p>
    </toiminta-alert>

    <fieldset *ngIf="toiminnatActive.toimAlaPuutarhaTarha" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tuoTaimiOtsikko">Tuotanto taimitarhoissa</legend>
      <p attribute="kasvinterveysrekisteri.tuoTaimiKysymys">Merkitse toimintaan kuuluvien taimien tuotanto.</p>

      <div formGroupName="tuoPuuvart">
        <touko-checkbox [htmlId]="lomakeData.tuoPuuvart.singleField.name"
                        [formControlName]="lomakeData.tuoPuuvart.singleField.name"
                        [labelAttribute]="lomakeData.tuoPuuvart.singleField.getLabelKey()"
                        (input)="toggleGroupValues($event.target.checked, 'tuoPuuvart')"></touko-checkbox>

        <div *ngIf="ifAnyOfGroupSelected('tuoPuuvart')" class="mb-4">
          <p attribute="kasvinterveysrekisteri.tuoPuuvartSelite" [markdown]="true">Merkitse puuvartiset kasvit</p>
          <table class="table table-sm">
            <tbody>
            <tr checkbox-table-row *ngFor="let row of lomakeData.tuoPuuvart.data | slice:1"
                [formControlName]="row.name"
                [question]="row.label"
                [options]="tuoPuuvartOptions"
                [values]="jatkoMyyntiValues">
            </tr>
            </tbody>
          </table>
          <lomake-error [virheSanomat]="lomakeData.tuoPuuvart.errorMessages"
                        [virheet]="getGroupVirheet(lomakeValues.get('tuoPuuvart'), sivu1.ref)"></lomake-error>
        </div>
      </div>

      <div formGroupName="tuoMarjaVarm">
        <touko-checkbox [htmlId]="lomakeData.tuoMarjaVarm.singleField.name"
                        [labelAttribute]="lomakeData.tuoMarjaVarm.singleField.getLabelKey()"
                        [formControlName]="lomakeData.tuoMarjaVarm.singleField.name"
                        (input)="toggleGroupValues($event.target.checked, 'tuoMarjaVarm')"></touko-checkbox>
        <div *ngIf="ifAnyOfGroupSelected('tuoMarjaVarm')" class="mb-4">
          <p attribute="kasvinterveysrekisteri.tuoMarjaVarmKysymys" [markdown]="true">Merkitse varmennettu
            taimituotanto.</p>
          <table class="table table-sm">
            <tbody>
            <tr checkbox-table-row *ngFor="let item of lomakeData.tuoMarjaVarm.data | slice:1"
                [formControlName]="item.name"
                [question]="item.label"
                [options]="tuoMarjaOptions"
                [values]="tuoMarjaValues"></tr>
            </tbody>
          </table>
          <lomake-error [virheSanomat]="lomakeData.tuoMarjaVarm.errorMessages"
                        [virheet]="getGroupVirheet(lomakeValues.get('tuoMarjaVarm'), sivu1.ref)"></lomake-error>
        </div>
      </div>

      <div formGroupName="tuoMarjaCAC">
        <touko-checkbox [htmlId]="lomakeData.tuoMarjaCAC.singleField.name"
                        [formControlName]="lomakeData.tuoMarjaCAC.singleField.name"
                        [labelAttribute]="lomakeData.tuoMarjaCAC.singleField.getLabelKey()"
                        (input)="toggleGroupValues($event.target.checked, 'tuoMarjaCAC')"></touko-checkbox>
        <div *ngIf="ifAnyOfGroupSelected('tuoMarjaCAC')" class="mb-4">
          <p attribute="kasvinterveysrekisteri.tuoMarjaCACKysymys" [markdown]="true">Merkitse CAC-tuotettavat
            marjakasvien taimet.</p>
          <table class="table table-sm">
            <tbody>
            <tr checkbox-table-row *ngFor="let item of lomakeData.tuoMarjaCAC.data | slice:1"
                [formControlName]="item.name"
                [question]="item.label"
                [options]="tuoMarjaOptions"
                [values]="tuoMarjaValues"></tr>
            </tbody>
          </table>
          <lomake-error [virheSanomat]="lomakeData.tuoMarjaCAC.errorMessages"
                        [virheet]="getGroupVirheet(lomakeValues.get('tuoMarjaCAC'), sivu1.ref)"></lomake-error>
        </div>
      </div>

    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPuutarhaKasvi" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tuoKasviOtsikko">Tuotanto kasvihuoneviljelmillä</legend>
      <p attribute="kasvinterveysrekisteri.tuoKasviKysymys" [markdown]="true">Merkitse tuottamasi kasvien taimet
        jatkokasvatukseen ja myyntiin</p>
      <div formGroupName="tuoKasvi">
        <table class="table table-sm">
          <tbody>
          <tr checkbox-table-row *ngFor="let row of lomakeData.tuoKasvi.data"
              [formControlName]="row.name" [question]="row.label"
              [options]="taimiKasviOptions"
              [values]="taimiKasviValues">
          </tr>
          </tbody>
        </table>
      </div>

      <div formGroupName="tuoVihannesTaimi" class="mt-4">
        <p attribute="kasvinterveysrekisteri.tuoVihannesTaimiKysymys">Merkitse kasvihuoneessa tuottamasi vihannekset tai
          taimet, joita jatkokasvatetaan toisilla ammattiviljelmillä</p>

        <touko-checkbox [htmlId]="lomakeData.tuoVihannesTaimi.singleField.name"
                        [labelAttribute]="lomakeData.tuoVihannesTaimi.singleField.getLabelKey()"
                        [formControlName]="lomakeData.tuoVihannesTaimi.singleField.name"
                        (input)="toggleGroupValues($event.target.checked, 'tuoVihannesTaimi')"></touko-checkbox>

        <ng-container *ngIf="ifAnyOfGroupSelected('tuoVihannesTaimi')">
          <div class="mb-4 ml-4">
            <touko-checkbox *ngFor="let item of lomakeData.tuoVihannesTaimi.data| slice:1" [htmlId]="item.name"
                            [formControlName]="item.name"
                            [labelAttribute]="item.getLabelKey()" [labelDescrAttribute]="item.getLabelKey() + 'Lat'"
                            [labelDescrPositionInline]="true"></touko-checkbox>
            <lomake-error [virheet]="getGroupVirheet(lomakeValues.get('tuoVihannesTaimi'), sivu1.ref)"
                          [virheSanomat]="lomakeData.tuoVihannesTaimi.errorMessages"></lomake-error>
          </div>
        </ng-container>

      </div>

      <div formGroupName="tuoVihannesYksityiskaytto">
        <touko-checkbox htmlId="tuoVihannesYksityiskaytto"
                        labelAttribute="kasvinterveysrekisteri.tuoVihannesYksityiskaytto"
                        [formControlName]="lomakeData.tuoVihannesYksityiskaytto.singleField.name"
                        labelDescrAttribute="kasvinterveysrekisteri.tuoVihannesYksityiskayttoSelite"></touko-checkbox>
      </div>

      <div formGroupName="tuoVihannesValmis">
        <touko-checkbox [htmlId]="lomakeData.tuoVihannesValmis.singleField.name"
                        [labelAttribute]="lomakeData.tuoVihannesValmis.singleField.getLabelKey()"
                        [formControlName]="lomakeData.tuoVihannesValmis.singleField.name"
                        (input)="toggleGroupValues($event.target.checked, 'tuoVihannesValmis')"></touko-checkbox>

        <ng-container *ngIf="ifAnyOfGroupSelected('tuoVihannesValmis')">
          <div class="mb-4 ml-4">
            <touko-checkbox *ngFor="let item of lomakeData.tuoVihannesValmis.data| slice:1" [htmlId]="item.name"
                            [formControlName]="item.name"
                            [labelAttribute]="item.getLabelKey()"></touko-checkbox>
            <lomake-error [virheet]="getGroupVirheet(lomakeValues.get('tuoVihannesValmis'), sivu1.ref)"
                          [virheSanomat]="lomakeData.tuoVihannesValmis.errorMessages"></lomake-error>
          </div>
        </ng-container>

      </div>

    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPerunaViljely" class="mb-4">
      <legend>Perunan viljely</legend>
      <p attribute="kasvinterveysrekisteri.tuoPerunaViljelyKysymys">Merkitse toimintaan kuuluvat
        perunantuotantovaihtoehdot</p>
      <div class="form-group" formGroupName="tuoPerunaViljely">
        <touko-checkbox *ngFor="let item of lomakeData.tuoPerunaViljely.data" [htmlId]="item.name"
                        [formControlName]="item.name" [labelAttribute]="item.getLabelKey()"></touko-checkbox>
      </div>
    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPuuISPM" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tuoISPM15Otsikko">Puutavaran ISPM 15 -toimijat</legend>

      <div class="form-group">
        <div formGroupName="tuoISPM15Kasittely">
          <touko-checkbox htmlId="tuoISPM15Kasittely" [formControlName]="lomakeData.tuoISPM15Kasittely.singleField.name"
                          [labelAttribute]="lomakeData.tuoISPM15Kasittely.singleField.getLabelKey()"></touko-checkbox>

          <div class="mb-4" *ngIf="ifValueSelected('tuoISPM15Kasittely.tuoISPM15Kasittely')">
            <label id="tuoISPM15KasittelyLiite"
                   [attribute]="lomakeData.tuoISPM15Kasittely.dataObject['tuoISPM15KasittelyLiite'].getLabelKey()">Lataa
              käsittelydokumentit (esim. kuivauskäyrät)</label>
            <touko-liite labelledBy="tuoISPM15KasittelyLiite"
                         htmlId="input-tuoISPM15KasittelyLiite"
                         (liiteOut)="onSetLiite($event)"
                         [lomakeId]="id"
                         [maxLiiteLkm]="10"
                         [selite]="'ISPM15_KASITTELY'"
                         [formControlName]="lomakeData.tuoISPM15Kasittely.dataObject['tuoISPM15KasittelyLiite'].name"></touko-liite>
          </div>
        </div>

        <div formGroupName="tuoISPM15Valmistus">
          <touko-checkbox htmlId="tuoISPM15Valmistus" [formControlName]="lomakeData.tuoISPM15Valmistus.singleField.name"
                          [labelAttribute]="lomakeData.tuoISPM15Valmistus.singleField.getLabelKey()"></touko-checkbox>

          <ng-container *ngIf="ifValueSelected('tuoISPM15Valmistus.tuoISPM15Valmistus')">
            <div class="block mb-3">
              <label id="tuoISPM15ValmistusAlkuperaLiite"
                     [attribute]="lomakeData.tuoISPM15Valmistus.dataObject['tuoISPM15ValmistusAlkuperaLiite'].getLabelKey()">Lataa
                puutavaran alkuperään liittyvät dokumentit</label>
              <touko-liite (liiteOut)="onSetLiite($event)" [maxLiiteLkm]="10"
                           htmlId="input-tuoISPM15ValmistusAlkuperaLiite"
                           [showLiiteInfo]="false"
                           [selite]="'ISPM15_PUUN_ALKUPERA'"
                           [lomakeId]="id"
                           [formControlName]="lomakeData.tuoISPM15Valmistus.dataObject['tuoISPM15ValmistusAlkuperaLiite'].name"
                           labelledBy="tuoISPM15ValmistusAlkuperaLiite"></touko-liite>
            </div>

            <div class="block">
              <label id="tuoISPM15ValmistusLeimausLiite"
                     [attribute]="lomakeData.tuoISPM15Valmistus.dataObject['tuoISPM15ValmistusLeimausLiite'].getLabelKey()">Lataa
                kuvat leimausjäljistä (kaikki käytetyt leimasimet)</label>
              <touko-liite (liiteOut)="onSetLiite($event)" [maxLiiteLkm]="10"
                           [formControlName]="lomakeData.tuoISPM15Valmistus.dataObject['tuoISPM15ValmistusLeimausLiite'].name"
                           [selite]="'ISPM15_LEIMAT'"
                           [lomakeId]="id"
                           labelledBy="tuoISPM15ValmistusLeimausLiite"
                           htmlId="input-tuoISPM15ValmistusLeimausLiite"></touko-liite>
            </div>
          </ng-container>
        </div>
      </div>

    </fieldset>
  </sivu>

  <!-- MYYNTI & VÄLITYS -->

  <sivu #sivuMyyntiJaValitys [attribuuttiAvain]="'kasvinterveysrekisteri.sivuMyynti'" [ohjeet]="ohjeet.sivu3"
        [selectable]="isPerustiedotValid">

    <toiminta-alert
      *ngIf="!toiminnatActive.toimAlaPuutarhaMyymala && !toiminnatActive.toimAlaPuutarhaTukku && !toiminnatActive.toimAlaPuutarhaTarha && !toiminnatActive.toimAlaPuutarhaKasvi && !toiminnatActive.toimAlaPuutarhaViherrak && !toiminnatActive.toimAlaPuuToimitus">
      <p attribute="kasvinterveysrekisteri.sivuMyyntiAlert" [markdown]="true">
        Mikäli toimipaikkasi harjoittaa myyntiä tai välitystä, valitse Toiminta-välilehdeltä <em>Taimimyymälät</em>,
        <em>Kukka- ja puutarhatukut</em> tai <em>Viherrakentaminen</em>.
      </p>
    </toiminta-alert>

    <fieldset *ngIf="toiminnatActive.toimAlaPuutarhaMyymala " class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyTaimiOtsikko">Taimimyymälätoiminta</legend>

      <div class="form-group" formGroupName="myyTaimimyymala">
        <p attribute="kasvinterveysrekisteri.myyTaimiKysymys" [markdown]="true">Merkitse vähittäisasiakkaille myytävät
          kasvit</p>
        <div *ngFor="let item of lomakeData.myyTaimimyymala.data" [ngClass]="{'ml-5 pl-1': item.options.indent}">
          <touko-checkbox [htmlId]="item.name" [formControlName]="item.name"
                          [labelAttribute]="item.label.getFullKey()"></touko-checkbox>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPuutarhaTukku " class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyTaimiValitysOtsikko">Taimien välitys</legend>
      <p attribute="kasvinterveysrekisteri.myyTaimiValitysKysymys" [markdown]="true">
        Merkitse myymäsi kasvien taimet, jotka ovat tarkoitettu jatkokasvatukseen tai myyntiin.
      </p>

      <div class="form-group" formGroupName="myyTaimiValitysKasvi">
        <label class="text-muted" attribute="kasvinterveysrekisteri.myyKasvihuonekasvitAlaotsikko">
          Kasvihuonekasvit
        </label>
        <table class="table table-sm">
          <tbody>
          <tr checkbox-table-row *ngFor="let item of lomakeData.myyTaimiValitysKasvi.data"
              [question]="item.label"
              [formControlName]="item.name"
              [options]="myyTaimiValitysOptions"
              [values]="jatkoMyyntiValues">
          </tr>
          </tbody>
        </table>
      </div>

      <div class="form-group" formGroupName="myyTaimiValitysAvo">
        <label class="text-muted" attribute="kasvinterveysrekisteri.myyTaimitarhakasvitAlaotsikko">
          Taimitarhakasvit
        </label>
        <table class="table table-sm">
          <tbody>
          <tr checkbox-table-row *ngFor="let item of lomakeData.myyTaimiValitysAvo.data"
              [question]="item.label"
              [formControlName]="item.name"
              [options]="myyTaimiValitysOptions"
              [values]="jatkoMyyntiValues">
          </tr>
          </tbody>
        </table>
      </div>

      <div class="form-group mt-4" formGroupName="myyTaimiValitysSiemen">
        <p attribute="kasvinterveysrekisteri.myyTaimiValitysSiemenKysymys">
          Merkitse muualla kuin omalla viljelmällänne tuotetut siemenet.
        </p>
        <touko-checkbox *ngFor="let item of lomakeData.myyTaimiValitysSiemen.data" [htmlId]="item.name"
                        [formControlName]="item.name" [labelAttribute]="item.label.getFullKey()"></touko-checkbox>
      </div>

      <div class="form-group" formGroupName="myyTaimiValitysKasvipassi">
        <touko-checkbox [htmlId]="lomakeData.myyTaimiValitysKasvipassi.singleField.name"
                        [formControlName]="lomakeData.myyTaimiValitysKasvipassi.singleField.name"
                        [labelAttribute]="lomakeData.myyTaimiValitysKasvipassi.singleField.label.getFullKey()"></touko-checkbox>
      </div>

    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPuutarhaViherrak" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyViherOtsikko">Viherrakentaminen</legend>
      <div class="form-group" formGroupName="myyViherrakentaminen">
        <touko-checkbox [htmlId]="lomakeData.myyViherrakentaminen.singleField.name"
                        [formControlName]="lomakeData.myyViherrakentaminen.singleField.name"
                        [labelAttribute]="lomakeData.myyViherrakentaminen.singleField.getLabelKey()"></touko-checkbox>
      </div>
    </fieldset>

    <fieldset
      *ngIf="toiminnatActive.toimAlaPuutarhaMyymala || toiminnatActive.toimAlaPuutarhaTarha || toiminnatActive.toimAlaPuutarhaKasvi"
      class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyEtamyyntiOtsikko">Etämyynti</legend>
      <div class="form-group" formGroupName="myyEtamyynti">
        <p attribute="kasvinterveysrekisteri.myyEtamyyntiKysymys">Etämyynnillä tarkoitetaan toimintaa, jossa ostaja
          tilaa kasvit esimerkiksi verkkokaupasta, postimyyntiluettelon tilauslomakkeella tms. ja ne toimitetaan hänelle
          kuljetusliikkeen (esim. posti, matkahuolto) välityksellä.</p>
        <div *ngFor="let item of lomakeData.myyEtamyynti.data">
          <ng-container *ngIf="toiminnatActive[item.options.toimintaId]">
            <touko-checkbox [htmlId]="item.name" [formControlName]="item.name"
                            [labelAttribute]="item.getLabelKey()"></touko-checkbox>
          </ng-container>
        </div>
        <syote-group [virheet]="getGroupVirheet(lomakeValues.get('verkkokaupat'), sivuMyyntiJaValitys.ref)"
                     [virheSanomat]="lomakeData.verkkokaupat.errorMessages">
          <verkkokaupat-input (verkkokaupatUpdate)="updateVerkkokaupat($event)"
                              [formControl]="verkkokauppaControl"></verkkokaupat-input>
        </syote-group>
        <div class="block">
          <label id="myyEtamyyntiLiite"
                 [attribute]="lomakeData.myyEtamyynti.dataObject['myyEtamyyntiLiite'].getLabelKey()">Lataa
            etämyyntiliite</label>
          <touko-liite labelledBy="myyEtamyyntiLiite"
                       (liiteOut)="onSetLiite($event)"
                       htmlId="input-myyEtamyyntiLiite"
                       [lomakeId]="id"
                       [formControlName]="lomakeData.myyEtamyynti.dataObject['myyEtamyyntiLiite'].name"
                       [selite]="'ETAMYYNTI'"
                       [maxLiiteLkm]="10"></touko-liite>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPuuToimitus" class="mb-4">
      <legend [attribute]="lomakeData.myyPuutavaraKasvipassi.title.getFullKey()">Kasvipassia vaativan puutavaran
        toimitus
      </legend>

      <div class="form-group" formGroupName="myyPuutavaraKasvipassi">
        <p attribute="kasvinterveysrekisteri.myyPuutavaraKasvipassiSelite" [markdown]="true">Seuraavat toiminnat
          edellyttävät kasvipassin käyttöoikeutta:</p>
        <touko-checkbox htmlId="myyPuutavaraKasvipassi"
                        [formControlName]="lomakeData.myyPuutavaraKasvipassi.singleField.name"
                        [labelAttribute]="lomakeData.myyPuutavaraKasvipassi.singleField.getLabelKey()"></touko-checkbox>
      </div>
    </fieldset>


  </sivu>

  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuTuontiVienti'" [ohjeet]="ohjeet.sivu4"
        [selectable]="isPerustiedotValid">

    <fieldset *ngIf="!toiminnatActive.toimAlaPuutarhaKasvitiet" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tvOtsikko">Maahantuonti ja maastavienti</legend>

      <div formGroupName="tuontiJaVienti">
        <p attribute="kasvinterveysrekisteri.tvKysymys">Merkitse vaihtoehto, mikäli toimipaikkasi maahantuo tai
          maastavie jotain seuraavista. Merkitse ainoastaan EU:n ulkopuolinen toiminta.</p>
        <table class="table table-sm">
          <tbody>
          <tr checkbox-table-row *ngFor="let row of lomakeData.tuontiJaVienti.data"
              [formControlName]="row.name" [question]="row.label"
              [options]="tuontiVientiOptions"
              [values]="tuontiVientiValues">
          </tr>
          </tbody>
        </table>
      </div>

    </fieldset>

    <fieldset *ngIf="toiminnatActive.toimAlaPuutarhaKasvitiet" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tvKasvitietPuutarhaOtsikko">Kasvitieteelliset puutarhat</legend>
      <div class="form-group" formGroupName="kasvitietPuutarha">
        <touko-checkbox [htmlId]="lomakeData.kasvitietPuutarha.singleField.name"
                        [formControlName]="lomakeData.kasvitietPuutarha.singleField.name"
                        [labelAttribute]="lomakeData.kasvitietPuutarha.singleField.getLabelKey()"></touko-checkbox>
      </div>

    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuOmavalvonta'" [selectable]="isPerustiedotValid"
        [ohjeet]="ohjeet.sivu5">
    <toiminta-alert
      *ngIf="!toiminnatActive.toimAlaPuutarhaKasvi && !toiminnatActive.toimAlaPuutarhaTarha && !toiminnatActive.toimAlaPuutarhaTukku">
      <p attribute="kasvinterveysrekisteri.sivuOmavalvontaAlert" [markdown]="true">Mikäli toimipaikkasi harjoittaa
        omavalvontaa, valitse Toiminta-välilehdeltä "Taimitarhat" tai "Kasvihuoneviljelmät"</p>
    </toiminta-alert>

    <fieldset
      *ngIf="toiminnatActive.toimAlaPuutarhaKasvi || toiminnatActive.toimAlaPuutarhaTarha || toiminnatActive.toimAlaPuutarhaTukku || lomakeValues.get(['omavalvonta', 'omavalvonta'])?.value?.length > 0">
      <legend attribute="kasvinterveysrekisteri.omavalvonta">Omavalvontasuunnitelma</legend>
      <div class="form-group" formGroupName="omavalvonta">
        <p attribute="kasvinterveysrekisteri.omavalvontaKysymys" [markdown]="true">Mikäli yritykselläsi on voimassaoleva
          omavalvontasuunnitelma, lataa se tästä. Omavalvontasuunnitelma tulee päivittää vuosittain.</p>

        <label id="liite-omavalvonta" attribute="kasvinterveysrekisteri.omavalvontaLataa">Lataa
          omavalvontasuunnitelma</label>
        <touko-liite [labelledBy]="'liite-omavalvonta'"
                     (liiteOut)="onSetLiite($event)"
                     [lomakeId]="id"
                     htmlId="liite-input-omavalvonta"
                     formControlName="omavalvonta" [selite]="'OVS'"
                     [maxLiiteLkm]="10"></touko-liite>
      </div>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'teksti.sivuYhteenveto'" [selectable]="isPerustiedotValid">
    <div class="row">

      <div #lomakeYhteenvetoDiv class="col-md-9">
        <allekirjoitus [data]="allekirjoitus"></allekirjoitus>
        <yhteenveto></yhteenveto>
      </div>
      <div class="col-md-3">
        <kasvinterveys-rekisterit #rekisteritCard
                                  [formValues]="yhteenvetoValues"
                                  [asiointitunnus]="allekirjoitus?.ytunnus"
                                  [valvontakohde]="allekirjoitus?.valvontakohde"
                                  [toimintotyyppi]="toimintotyyppi"></kasvinterveys-rekisterit>
      </div>
      <div *ngIf="isAsiakas" class="col">
        <button id="laheta" class="btn btn-primary" type="submit">
          <span attribute="teksti.laheta">Lähetä</span>
          <em class="fa fa-paper-plane"></em>
        </button>
      </div>
    </div>
    <div class="col-md-9 col-12 px-0 mt-4">
      <touko-alert-box>
        <div [attribute]="'kasvinterveysrekisteri.muutosilmoitusInfo'"></div>
      </touko-alert-box>
    </div>
  </sivu>

  <div class="alert alert-primary my-2" *ngIf="lisatieto">
    <span attribute="etusivu.lisatiedot">Lisätiedot</span>: {{lisatieto}}
  </div>

</touko-lomake>

<pre *devFeature>{{lomakeValues?.getRawValue() | json}}</pre>
