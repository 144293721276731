import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer} from "@angular/forms";
import {QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-tausta-radio-date-text',
  template: `
    <ng-container [formGroup]="controlContainer.control">
      <p *ngIf="questionGroup.label">{{questionGroup.label}}</p>
      <ng-container *ngFor="let control of questionGroup.controls">
        <div class="form-check" *ngIf="control.controlType === 'radio'">
          <input class="form-check-input" type="radio" [id]="control.htmlId" [formControlName]="control.key"
                 [value]="control.value">
          <label class="form-check-label" [htmlFor]="control.htmlId">{{control.label}}</label>
        </div>
        <ng-container *ngIf="control.controlType === 'date'">
            <label class="block" [htmlFor]="'date-input-' + control.htmlId">{{control.label}}</label>
            <touko-pvm [formControlName]="control.key" [htmlId]="control.htmlId"></touko-pvm>
        </ng-container>
        <ng-container *ngIf="control.controlType === 'text-input'">
            <label *ngIf="control.label" [htmlFor]="control.htmlId">{{control.label}}</label>
            <input [id]="control.htmlId" class="form-control" type="text" [formControlName]="control.key">
        </ng-container>
      </ng-container>
    </ng-container>
  `,
})
export class QuestionTaustaRadioDateTextComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;

  constructor(public controlContainer: ControlContainer) { }

  ngOnInit() {
    this.questionGroup.controls.forEach(control => {
      if (control.options && control.options.link) {
        const textarea = this.controlContainer.control.get(control.options.link);
        const radio = this.controlContainer.control.get(control.key);

        if (textarea && radio) {
          textarea.disable();
          radio.valueChanges.subscribe(val => {
            if (val === control.value) {
              textarea.enable();
            } else {
              textarea.reset();
              textarea.disable();
            }
          });
        }
      }
    });
  }
}
