import {FormControl} from "@angular/forms";
import {ToukoValidation, VirheObjekti} from "./validators";

export class LannoiteValidators extends ToukoValidation {

  static DETAILS_NOT_EMPTY = 'detailsNotEmpty';

  /**
   * Lisätietokentän arvo ei saa olla 'null'
   * touko-checkbox-text -komponentin validointi
   *
   * @param control - lomakkeen kenttä
   * @returns
   */
  static detailsNotEmpty(control: FormControl): VirheObjekti {
    const val = control.value;
    if (val !== null && val.checked) {
      return LannoiteValidators.buildError(Boolean(val.text), LannoiteValidators.DETAILS_NOT_EMPTY);
    } else {
      return LannoiteValidators.buildError(true, LannoiteValidators.DETAILS_NOT_EMPTY);
    }
  }

}
