import {Component, Input} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-tausta-textarea',
  template: `
    <div [formGroup]="controlContainer.control" class="form-group">
      <ng-container *ngFor="let control of questionGroup.controls">
        <label [htmlFor]="control.key">{{control.label}}</label>
        <textarea class="form-control" [id]="control.key" [formControlName]="control.key"></textarea>
      </ng-container>
    </div>
  `
})
export class QuestionTaustaTextareaComponent {
  @Input() questionGroup: QuestionGroup;

  constructor(public controlContainer: ControlContainer) {
  }
}
