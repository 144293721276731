
    <form [formGroup]="lomakehakuForm" (submit)="submit()" >
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
          <div class="form-row">
          <label for="haku-tunnus" class="col-5" attribute="etusivu.tunnus">Tunnus</label> <input class="form-control col-7" type="number" formControlName="tunnus" id="haku-tunnus" />
          </div>
          </div>
          <div class="form-group">
              <touko-taglist-check [inline]="true" [tags]="toimintotyypit" [fieldValue]="lomakehakuForm?.value?.toimintotyypit" formControlName="toimintotyypit" class="form-row"></touko-taglist-check>
          </div>
          <div class="form-group">
            <div class="form-row">
              <label for="haku-ytunnus" class="col-5" attribute="etusivu.yTunnus">Y-tunnus</label> <input class="form-control col-7" formControlName="ytunnus" id="haku-ytunnus" />
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <label for="haku-maatilatunnus" class="col-5" attribute="etusivu.lahettajaMaatilatunnus">Maatilatunnus</label> <input class="form-control col-7" formControlName="maatilatunnus" id="haku-maatilatunnus" />
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <label for="haku-yritys" class="col-5" attribute="etusivu.yritys">Yritys</label> <input class="form-control col-7" formControlName="yritys" id="haku-yritys" />
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <label for="haku-valvontakohde" class="col-5" attribute="etusivu.valvontakohde">Valvontakohde</label> <input class="form-control col-7" formControlName="valvontakohde" id="haku-valvontakohde" />
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <div class="form-group">
            <div class="form-row">
              <label for="haku-lahettaja" class="col-2" attribute="etusivu.lahettaja">Lähettäjä</label> <input class="form-control col-10" formControlName="lahettaja" id="haku-lahettaja" />
            </div>
          </div>

          <div class="form-group">
            <div class="form-row">
            <label class="col-2" attribute="etusivu.lahetetty">Lähetetty</label>
            <touko-pvm [ariaLabel]="'lomakeYleinen.lahetettyAlkaen'" [htmlId]="'lahetetty-alkaen'" [placeholder]="alkaenTxt" class="col-5" formControlName="lahetettyAlkuPvm"></touko-pvm>
            <touko-pvm [ariaLabel]="'lomakeYleinen.lahetettySaakka'" [htmlId]="'lahetetty-saakka'" [placeholder]="saakkaTxt" class="col-5" formControlName="lahetettyLoppuPvm"></touko-pvm>
          </div>
          </div>

          <div class="form-group">
            <div class="form-row">
              <label for="haku-kasittelija" class="col-2" attribute="etusivu.kasittelija">Käsittelijä</label> <input class="form-control col-10" formControlName="kasittelija" id="haku-kasittelija" />
            </div>
          </div>

          <div class="form-group">
            <div class="form-row">
              <label class="col-2" attribute="etusivu.viimeksiKasitelty">Käsitelty</label>
              <touko-pvm [ariaLabel]="'lomakeYleinen.kasiteltyAlkaen'" [htmlId]="'kasitelty-alkaen'" [placeholder]="alkaenTxt" class="col-5" formControlName="kasiteltyAlkuPvm"></touko-pvm>
              <touko-pvm [ariaLabel]="'lomakeYleinen.kasiteltySaakka'" [htmlId]="'kasitelty-saakka'" [placeholder]="saakkaTxt" class="col-5" formControlName="kasiteltyLoppuPvm"></touko-pvm>
            </div>
          </div>
          <div class="form-group">
              <touko-taglist-check [inline]="true" [tags]="lomaketilat" formControlName="lomakkeenTilat" class="form-row"></touko-taglist-check>
          </div>
          <div class="form-group">
            <div class="form-row">
              <label for="haku-lisatiedot" class="col-2" attribute="etusivu.lisatiedot">Lisätiedot</label> <input class="form-control col-10" formControlName="lisatiedot" id="haku-lisatiedot" />
            </div>
          </div>
          <div class="form-group">
            <div class="form-row float-right">
              <button class="btn btn-primary" type="button" id="lomakehaku-tyhjenna" attribute="etusivu.tyhjenna" (click)="clearLomake()">Tyhjennä
                hakuehdot</button>
            </div>
          </div>

          </div>
        </div>
    </form>
