import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ReadAccount} from "../account/account.service";

export interface MaaResponse {
  maaKoodi: string;
  maanNimi: string;
}

@Injectable()
export class KoodistoService {

  postinumeroKey = 'postinumerot';
  maidenNimetKey = 'maidenNimet';
  elyKeskuksetKey = 'elyKeskukset';

  constructor(public http: HttpClient) {
  }

  public getPostinumerot(): Promise<any> {
    const cached = sessionStorage.getItem(this.postinumeroKey);
    if (cached) {
      return Promise.resolve(JSON.parse(cached));
    }

    return this.http.get(`/api/v1/${ReadAccount.getRole()}/koodistot/postitoimipaikat`)
        .toPromise()
        .then(response => {
          const postinumeroMap = {};
          if (this.postinumeroKey && sessionStorage) {
            for (const obj of Object.keys(response)) {
              postinumeroMap[response[obj].postinumero] = response[obj];
            }
            sessionStorage.setItem(this.postinumeroKey, JSON.stringify(postinumeroMap));
          }
          return postinumeroMap;
        });
  }

  /**
   * Palauttaa postitoimipaikan postinumeron perusteella
   *
   * @param postinro - Postinumero
   * @returns - Postitoimipaikka tai null (Promise-objekti)
   */
  public getPostitoimipaikkaAsync(postinro: string): Promise<string> {
    if (!postinro) {
      return Promise.resolve(null);
    }

    return this.getPostinumerot()
        .then(data => {
          if (data.hasOwnProperty(postinro)) {
            return data[postinro].postitoimipaikka;
          }
          return null;
        });
  }

  public getPostitoimipaikka(postnro): string {
    const postinumerot = sessionStorage.getItem(this.postinumeroKey);
    if (postinumerot) {
      const postinumerotJSON = JSON.parse(postinumerot);
      return postinumerotJSON[postnro] ? postinumerotJSON[postnro].postitoimipaikka : null;
    }
    return null;
  }

  public async getMaidenNimet(): Promise<{ [key: string]: MaaResponse }> {
    const cached = sessionStorage.getItem(this.maidenNimetKey);

    if (cached) {
      return Promise.resolve(JSON.parse(cached));
    }

    return this.http.get(`/api/v1/${ReadAccount.getRole()}/koodistot/maidenNimet`)
        .toPromise()
        .then(response => {
          const maanNimiMap = {};

          for (const obj of Object.keys(response)) {
            maanNimiMap[response[obj].maaKoodi] = response[obj];
          }
          sessionStorage.setItem(this.maidenNimetKey, JSON.stringify(maanNimiMap));

          return maanNimiMap;
        });
  }

  public async getMaatByMaaKoodit(): Promise<{ [key: string]: string }> {
    const maat = await this.getMaidenNimet();
    return Object.values(maat).reduce((prev, current) => {
      prev[current.maaKoodi] = current.maanNimi;
      return prev;
    }, {});
  }

  public async getElyKeskukset(): Promise<{ [key: string]: string }> {
    const cached = sessionStorage.getItem(this.elyKeskuksetKey);
    if (cached) {
      return Promise.resolve(JSON.parse(cached));
    }
    return this.http.get(`api/v2/${ReadAccount.getRole()}/lomakkeet/ilmoitukset/luomuvalvonta/uusi/elyalueet`)
        .toPromise()
        .then(response => {
          const elyKeskusMap = {};
          if (this.elyKeskuksetKey && sessionStorage) {
            for (const obj of Object.keys(response)) {
              elyKeskusMap[response[obj].alue] = response[obj].elykeskus;
            }
            sessionStorage.setItem(this.elyKeskuksetKey, JSON.stringify(elyKeskusMap));
          }
          return elyKeskusMap;
        });
  }

  public async getElyKeskusByKuntakoodi(kuntakoodi: string) {
    return this.http.get(`api/v2/${ReadAccount.getRole()}/lomakkeet/ilmoitukset/luomuvalvonta/uusi/elyalueet/${kuntakoodi}`)
        .toPromise().then((response: { alue: string, elykeskus: string }) => {
          const elyKeskus = {};
          elyKeskus[response.alue] = response.elykeskus;
          return elyKeskus;
        });
  }
}
