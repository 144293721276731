import {Component, Input, OnInit} from "@angular/core";
import {ControlContainer, FormGroup} from "@angular/forms";
import {FieldArray} from "ngx-fielding";
import {YhteyshenkiloViewOptions} from "./yhteyshenkilo-view-options";

@Component({
  selector: 'yhteyshenkilo-list',
  template: `
    <div class="form-group" [formGroup]="formGroup">
      <ng-container [formArrayName]="'yhteyshenkilot'" *ngFor="let yhteyshenkilo of yhteyshenkilotArray.controlFields; index as i">
        <yhteyshenkilo-input [viewOptions]="yhteyshenkiloViewOptions" *ngIf="yhteyshenkilo.value.elmoStatus === 'ACTIVE'" [roolit]="roolit" [formGroupName]="i" [index]="i" (onRemove)="removeYhteyshenkilo(i)"
                             [isRemovable]="isYhteyshenkiloRemovable()"></yhteyshenkilo-input>
      </ng-container>
      <button type="button" (click)="addYhteyshenkilo()" attribute="lomakeYleinen.lisaaYhteyshenkilo"
              class="btn btn-outline-primary btn-sm mb-4"></button>
    </div>
  `
})

export class YhteyshenkiloListComponent implements OnInit {
  @Input() roolit;
  @Input() yhteyshenkiloViewOptions: YhteyshenkiloViewOptions;
  defaultRooli;

  public yhteyshenkilotArray: FieldArray;
  formGroup: FormGroup;

  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.yhteyshenkilotArray = this.controlContainer.control as FieldArray;
    this.formGroup = this.yhteyshenkilotArray.parent as FormGroup;
  }

  addYhteyshenkilo() {
    this.yhteyshenkilotArray.push(this.yhteyshenkilotArray.buildField());
  }

  removeYhteyshenkilo(index) {
    const yhteyshenkiloToRemove = this.yhteyshenkilotArray.get([index]);
    if (yhteyshenkiloToRemove.get('elmoid').value) {
      yhteyshenkiloToRemove.get('elmoStatus').setValue('INACTIVE');
    } else {
      this.yhteyshenkilotArray.removeAt(index);
    }
  }

  isYhteyshenkiloRemovable() {
    return this.yhteyshenkilotArray.controlFields.filter(y => y.value.elmoStatus === 'ACTIVE').length > 1;
  }
}
