import {AbstractField, Field, FieldControl, FieldGroup} from "ngx-fielding";
import {LomakeFieldUtils, SummaryFunctions, TulkkiObject} from "../lomake-field-utils";
import {BehaviorSubject, Subscription} from "rxjs";
import {ToukoValidation} from "../../../validators/validators";
import {Account, OsoiteTiedot} from "../../../../account/account";
import {ReadAccount} from "../../../../account/account.service";

const utils = new LomakeFieldUtils();
const attr = TulkkiObject.getCreateFn('lomakeYleinen');

interface PerustiedotAndLaskutustiedotGroups {
  perustiedot: FieldGroup;
  laskutustiedot: FieldGroup;
  ulkomainenOsoite: FieldControl
}

export const createPerustiedotAndLaskutustiedot = (): PerustiedotAndLaskutustiedotGroups => {

  const perustiedot = createPerustiedotFieldGroup();
  const laskutustiedot = createLaskutustiedotFieldGroup();

  const isUlkomainenOsoite = utils.createFieldControl('ulkomainenOsoite');

  const postiosoite = perustiedot.get('postiosoite');

  let isSamaKayntiosoiteChecked = false;
  const isSamaKayntiosoiteCheck = perustiedot.get('postiosoitecheck');
  const kayntiosoite = perustiedot.get('kayntiosoite');

  let isSamaLaskutusosoiteChecked = false;
  const isSamaLaskutusosoiteCheck = laskutustiedot.get('laskutusosoiteCheck');
  const laskutusosoite = laskutustiedot.get('laskutusosoite');

  let postiosoiteSub: Subscription;

  handleUlkomainenOsoiteChange(isUlkomainenOsoite, [postiosoite, kayntiosoite, laskutusosoite]);

  const trackPostiosoiteChanges = () => {
    postiosoiteSub = postiosoite.valueChanges.subscribe(val => {
      const postiosoiteValue = utils.copyValue(val);
      if (isSamaKayntiosoiteChecked) {
        kayntiosoite.setValue(postiosoiteValue);
      }
      if (isSamaLaskutusosoiteChecked) {
        laskutusosoite.setValue(postiosoiteValue);
      }
    });
  };

  const untrackPostiosoiteChanges = () => {
    if (!isSamaKayntiosoiteChecked && !isSamaLaskutusosoiteChecked && postiosoiteSub) {
      postiosoiteSub.unsubscribe();
    }
  };

  const handleChange = (isChecked, osoiteControl) => {
    const postiosoiteValue = utils.copyValue(postiosoite.value);
    if (isChecked) {
      osoiteControl.setValue(postiosoiteValue);
      osoiteControl.disable();
      trackPostiosoiteChanges();
    } else {
      osoiteControl.enable();
      untrackPostiosoiteChanges();
    }
  };

  isSamaKayntiosoiteCheck.valueChanges.subscribe(isChecked => {
    isSamaKayntiosoiteChecked = isChecked;
    handleChange(isChecked, kayntiosoite);
  });

  isSamaLaskutusosoiteCheck.valueChanges.subscribe(isChecked => {
    isSamaLaskutusosoiteChecked = isChecked;
    handleChange(isChecked, laskutusosoite);
  });

  return {
    ulkomainenOsoite: isUlkomainenOsoite,
    perustiedot,
    laskutustiedot
  };
};

const handleUlkomainenOsoiteChange = (isUlkomainenOsoite, osoiteControls: AbstractField[]) => {
  isUlkomainenOsoite.valueChanges.subscribe(isChecked => {
    if (isChecked) {
      osoiteControls.forEach(c => {
        c.clearValidators();
        c.updateValueAndValidity();
      });
    } else {
      osoiteControls.forEach(c => {
        const val = {...c.value};
        val.maaKoodi = 'FI';
        c.setValue(val);
        c.addValidators(ToukoValidation.validOsoiteOrAllEmpty);
        c.updateValueAndValidity();
      });
    }
  });
};

/**
 * Luo vuosi-ilmoituksia varten perustiedot.
 */
export const createPerustiedotFieldGroup = (): FieldGroup => new FieldGroup(
    Field.build(
        attr('perustiedot'),
        SummaryFunctions.titleSummary
    ),
    {
      osasto: utils.createFieldControl('toimipaikanNimi', SummaryFunctions.questionAndAnswer),
      yritys: new FieldControl(
          Field.build(
              utils.tulkkiHelper.createAttr('paatoiminimi', 'kayttajatili')
          ), {value: null, disabled: true},
      ),
      ytunnus: new FieldControl(
          Field.build(
              utils.tulkkiHelper.createAttr('ytunnus', 'kayttajatili')
          ), {value: null, disabled: true},
      ),
      vatnumero: new FieldControl(
          Field.build(
              utils.tulkkiHelper.createAttr('vatNumero', 'kayttajatili')
          ), {value: null, disabled: true},
      ),
      postiosoite: createOsoiteControl('postiosoite', [ToukoValidation.validOsoiteOrAllEmpty, ToukoValidation.valueObjNotEmpty]),
      postiosoitecheck: utils.createFieldControl('checkSamaKayntiosoite'),
      kayntiosoite: createOsoiteControl('kayntiosoite', [ToukoValidation.validOsoiteOrAllEmpty, ToukoValidation.valueObjNotEmpty])
    }
);

const createLaskutustiedotFieldGroup = () => {
  const validators = [ToukoValidation.validOperaattoritunnus];
  const readAccount = ReadAccount.getAccount();
  if (Account.isAsiakas(readAccount)) {
    validators.push(ToukoValidation.validVerkkolaskuosoiteForm(readAccount.ytunnus));
    validators.push(ToukoValidation.validVerkkolaskuosoiteOrEmpty);
    validators.push(ToukoValidation.validOperaattoritunnus);
  }
  return new FieldGroup(
      Field.build(
          attr('laskutustiedot'),
          SummaryFunctions.titleSummary,
          {[ToukoValidation.VLASKUOSOITE_TAI_LASKUTUSOSOITE]: attr('errVerkkolaskuosTailaskutusos')}
      ),
      {
        laskutusosoiteCheck: utils.createFieldControl('checkSamaLaskutusosoite'),
        laskutusosoite: createOsoiteControl('laskutusosoite', [ToukoValidation.validOsoiteOrAllEmpty]),
        verkkolaskutusosoite: new FieldControl(
            Field.build(
                attr('verkkolaskutusosoite'),
                SummaryFunctions.verkkolaskuosoiteSummary,
                {
                  [ToukoValidation.VLASKUOSOITE_CORRECT_FORM]: attr('errVerkkolaskuosoiteMuoto'),
                  [ToukoValidation.VLASKUOSOITE_OR_EMPTY]: attr('errVerkkolaskuosoiteOperaattori'),
                },
                null,
                null,
                'verkkolaskutusosoite'
            ),
            null,
            validators
        )
      },
      ToukoValidation.isLaskutustiedotNotEmpty
  )
};

const createOsoiteControl = (osoiteLabel: string, validators: Array<any>): FieldControl => new FieldControl(
    Field.of({
      label: attr(osoiteLabel),
      summaryFn: SummaryFunctions.questionAndAnswerFromOsoiteTiedot,
      errorMessages: {
        [ToukoValidation.OSOITE_OR_EMPTY]: attr("errPuuttellinenOsoite"),
        [ToukoValidation.VALID_POSTINRO]: attr("errVirheellinenPostinro"),
        [ToukoValidation.VALUE_NOT_EMPTY]: attr("errPakollinenTieto")
      },
      htmlId: osoiteLabel
    }),
    new OsoiteTiedot(),
    validators
);

export class PerustiedotSkipValidationOptions {
  perustiedot: boolean;
  laskutustiedot: boolean;
  yhteyshenkilot: boolean;

  constructor(perustiedot = false, laskutustiedot = false, yhteyshenkilot = false) {
    this.perustiedot = perustiedot;
    this.laskutustiedot = laskutustiedot;
    this.yhteyshenkilot = yhteyshenkilot;
  }

  static skipLaskutustiedot() {
    return new PerustiedotSkipValidationOptions(false, true, false);
  }
}

export class PerustiedotValidator {

  private lomake: FieldGroup;
  subs: Subscription[] = [];
  perustiedotStatus: {
    perustiedot: boolean,
    laskutustiedot: boolean,
    yhteyshenkilot: boolean,
  };
  skipValidation: PerustiedotSkipValidationOptions;

  private _isPerustiedotValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPerustiedotValid$ = this._isPerustiedotValid.asObservable();

  constructor(lomake: FieldGroup, skipValidation = new PerustiedotSkipValidationOptions()) {
    this.lomake = lomake;
    this.skipValidation = skipValidation;
    this.perustiedotStatus = {
      perustiedot: Boolean(skipValidation.perustiedot),
      laskutustiedot: Boolean(skipValidation.laskutustiedot),
      yhteyshenkilot: Boolean(skipValidation.yhteyshenkilot)
    };

    this.validatePerustiedotHelper('perustiedot');
    this.validatePerustiedotHelper('laskutustiedot');
    this.validatePerustiedotHelper('yhteyshenkilot');

    Object.keys(this.perustiedotStatus).forEach(key => {
      this.perustiedotStatus[key] = this.lomake.get(key)?.valid;
    });
    this._isPerustiedotValid.next(this.validatePerustiedot());
  }

  private validatePerustiedotHelper(groupName: string) {
    if (this.skipValidation[groupName]) {
      return;
    }

    this.subs.push(this.lomake?.get(groupName)?.statusChanges.subscribe(isValid => {
      this.perustiedotStatus[groupName] = isValid === 'VALID';
      const valid = this.validatePerustiedot();
      this._isPerustiedotValid.next(valid);
    }));
  }

  private validatePerustiedot(): boolean {
    return Object.entries(this.perustiedotStatus)
        .filter(([key, _value]) => !this.skipValidation[key])
        .map(([_key, value]) => value)
        .every(v => Boolean(v));
  }

  public unsubscribe() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
