import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, OnInit, Renderer2, ViewChild} from "@angular/core";
import {Syote} from "../syote";
import {Teksti} from "../../../utils/teksti";

type YesNoSelect = { yesNo: boolean; text: string } | null;

/**
 * Kyllä / ei -radiobutton sekä dropdown lisätietoja varten
 * [checked]="initCheckboxValue()"
 */
@Component({
  selector: 'touko-yes-no-select',
  template: `
    <div class="form-row">
      <div class="col">
        <label (click)="toggleValue()" class="form-check-label" [attribute]="item.getLabelKey()">{{item.label}}</label>
      </div>
      <div class="col-1">
        <label>
          <input value="true" (click)="writeBoolean(true)" type="radio" class="form-check-input" name="{{groupName}}"
                 [checked]="fieldValue.yesNo">
          <span [attribute]="'kylla'">Kyllä</span>
        </label>
      </div>
      <div class="col-1">
        <label>
          <input value="false" (click)="writeBoolean(false)" type="radio" class="form-check-input" name="{{groupName}}"
                    [checked]="fieldValue.yesNo !== null && !fieldValue.yesNo">
          <span [attribute]="'ei'">Ei</span>
        </label>
      </div>
      <div class="col-4">
        <label class="form-check-label">
          <label [attribute]="lisaKysymys.getFullKey()">{{lisaKysymys.label}}</label>
          <select [value]="fieldValue.text" (change)="writeTextValue($event.target.value)" class="form-control" #selectList>
            <option *ngFor="let option of selectEntries" [value]="option[0]" [attribute]="option[1].getFullKey()">
            </option>
          </select>
        </label>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => YesNoSelectComponent),
    }
  ]
})
export class YesNoSelectComponent implements ControlValueAccessor, OnInit {

  @ViewChild('selectList', { static: true }) selectList: any;
  @Input() lisaKysymys: Teksti;
  @Input() groupName: string;
  @Input() item: Syote;
  @Input() selectOptions: Map<string, Teksti>;
  fieldValue: null | YesNoSelect = {yesNo: null, text: ""};
  selectEntries: any[];

  constructor(private _renderer: Renderer2) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this.selectList.nativeElement, 'disabled', isDisabled);
  }

  ngOnInit() {
    this.setDisabledState(true);
    // Tarvitaan, jotta Mappia voi käsitellä ngForilla
    this.selectEntries = Array.from(this.selectOptions.entries());
  }

  writeValue(value: YesNoSelect): void {
    if (value) {
      this.fieldValue = value;
      this.setDisabledState(!value.yesNo);
      this._onChange(this.fieldValue);
    }
  }

  writeBoolean(value: boolean): void {
    const val: YesNoSelect = {yesNo: value, text: ""};
    if (value) {
      val.text = this.fieldValue.text;
    }
    this.writeValue(val);
  }

  writeTextValue(value: string): void {
    const val: YesNoSelect = {yesNo: null, text: value};
    if (value) {
      val.yesNo = this.fieldValue.yesNo;
    }
    this.writeValue(val);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  toggleValue() {
    this.writeBoolean(!Boolean(this.fieldValue.yesNo));
  }
}
