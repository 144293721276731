import {Component, Input} from "@angular/core";
import {ControlContainer} from "@angular/forms";
import {AccountService} from '../../../account/account.service';
import {PeruslohkoComponent} from './touko-peruslohko.component';

/**
 * Peruslohkokomponentti
 */
@Component({
  selector: 'touko-perustiedot-lannoiterekisteri',
  template: `
    <div [formGroup]="controlContainer.control">
      <div class="form-row">
        <div class="col-md-12">
          <div class="col-md-6 px-0">
            <label [attribute]="isMaatila() ? 'lomakeYleinen.viljelijanNimi' : 'lomakeYleinen.toimipaikanNimi' " for="pt-osasto">Toimipaikan nimi</label>
            <touko-osasto *ngIf="!isMaatila()" formControlName="osasto" [paatoiminimi]="paatoiminimi"
                          [osastoNimiLock]="osastoNimiLock" [osastoReadonly]="osastoReadonly">
            </touko-osasto>
            <input *ngIf="isMaatila()" class="form-control" formControlName="osasto" id="pt-osasto" />
          </div>
        </div>
        <div *ngIf="!isMaatila()" class="col-md-3">
          <touko-text-input htmlId="pt-yritys" formControlName="yritys" labelAttribute="kayttajatili.paatoiminimi"></touko-text-input>
        </div>
        <div class="col-md-3">
          <touko-text-input *ngIf="!isMaatila()" htmlId="pt-ytunnus" formControlName="ytunnus" labelAttribute="kayttajatili.ytunnus"></touko-text-input>
          <touko-text-input *ngIf="isMaatila()" htmlId="pt-maatilatunnus" formControlName="maatilatunnus" labelAttribute="kayttajatili.maatilatunnus"></touko-text-input>
        </div>
        <div class="col-md-3">
          <touko-text-input htmlId="vat-numero" formControlName="vatnumero" labelAttribute="kayttajatili.vatNumero"></touko-text-input>
        </div>
      </div>
      <!-- POSTIOSOITE -->
      <touko-osoite-lannoite id="pt-posti" required (keyup)="setInitializingToFalse()" class="form-row" formControlName="postiosoite"
                    [osoiteTeksti]="formFieldsLabels['postiosoite']"
                    [readonly]="osoitteetReadonly" [ulkomainenOsoite]="ulkomainenOsoite"></touko-osoite-lannoite>
      <!-- KÄYNTIOSOITE  attribute="lomakeYleinen.checkEriKayntiosoite" -->
      <div *ngIf="!osoitteetReadonly" class="form-row mt-5">
        <touko-checkbox htmlId="postiosoiteCheck" [checked]="isEqualKayntiOsoite" labelAttribute="lomakeYleinen.checkSamaKayntiosoite" (change)="toggleCheckbox()" class="col-sm-12"></touko-checkbox>
      </div>
      <touko-osoite-lannoite id="pt-kaynti" required *ngIf="!isEqualKayntiOsoite" [osoiteTeksti]="formFieldsLabels['kayntiosoite']"
                    class="form-row" formControlName="kayntiosoite"
                    [readonly]="osoitteetReadonly" [ulkomainenOsoite]="ulkomainenOsoite">
      </touko-osoite-lannoite>
    </div>
  `
})
export class PeruslohkoLannoiterekisteriComponent extends PeruslohkoComponent {

  @Input() ulkomainenOsoite: boolean;

  constructor(public controlContainer: ControlContainer, protected accountService: AccountService) {
    super(controlContainer, accountService);
  }

  protected init() {
    super.init();
  }
}
