import {Component, OnInit} from "@angular/core";
import {FieldControl, FieldGroup, ValueOption} from "ngx-fielding";
import {ControlContainer, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {LuomuvalvontaService} from "./luomuvalvonta.service";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";
import {RadioValue} from "../common/lomake-field-utils";
import {Maatila} from "../../../maatila/maatila";
import {ReadAccount} from "../../../account/account.service";
import {LoaderService} from "../../../loader/loader.service";

@Component({
  selector: 'toiminnan-kohde',
  template: `
    <div [formGroup]="fieldGroup">
      <h2 attribute="luomu.luomutoiminnanKohde"></h2>
      <div class="col-6 px-0 mb-5">
        <touko-radio-input formControlName="toiminnanKohde"
                           htmlId="maatila"
                           labelAttribute="luomu.maatila"
                           radioGroup="toiminnanKohde"
                           value="maatila"></touko-radio-input>
        <div *ngIf="fieldGroup.get('toiminnanKohde').value === 'maatila'" class="mb-4 ml-5">
          <ng-container *ngIf="tilatunnuksetHaettu && kasittelijatHaettu">
            <div class="mb-4">
              <touko-select-menu
                  [htmlId]="tilatunnus.field.htmlId"
                  [labelAttribute]="tilatunnus.field.label"
                  [options]="tilatunnus.field.valueOptions"
                  [selectionRemovable]="false"
                  formControlName="tilatunnus"
                  [showRequired]="true"></touko-select-menu>
            </div>
            <touko-select-menu
                [htmlId]="kasittelija.field.htmlId"
                [labelAttribute]="kasittelija.field.label"
                [options]="kasittelija.field.valueOptions"
                [selectionRemovable]="false"
                [showRequired]="true"
                formControlName="kasittelija"></touko-select-menu>
          </ng-container>
        </div>
        <touko-radio-input formControlName="toiminnanKohde"
                           htmlId="muuKuinMaatila"
                           labelAttribute="luomu.muuKuinMaatila"
                           radioGroup="toiminnanKohde"
                           value="muuKuinMaatila"></touko-radio-input>
      </div>

    </div>
  `
})
@AutoUnsubscribe
export class ToiminnanKohdeComponent implements OnInit {

  subs: Subscription[] = [];
  maatilat: Maatila[];
  tilatunnuksetHaettu: boolean;
  kasittelijatHaettu: boolean;

  constructor(private controlContainer: ControlContainer, private luomuvalvontaService: LuomuvalvontaService, private loaderService: LoaderService) {
  }

  get fieldGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get tilatunnus(): FieldControl {
    return this.fieldGroup.get("tilatunnus").asControl()
  }

  get kasittelija(): FieldControl {
    return this.fieldGroup.get("kasittelija").asControl()
  }

  ngOnInit() {
    this.tilatunnuksetHaettu = false;
    this.kasittelijatHaettu = false;
    this.createMaatilaValintaArray().then(values => {
      this.loaderService.startLoadingAnimation();
      this.tilatunnus.field.valueOptions = values
      this.tilatunnuksetHaettu = true;

      this.kasittelija.enable();
      if (this.kasittelijatHaettu) {
        this.loaderService.stopLoadingAnimation();
      }
    });

    this.createKasittelijaElykeskusArray().then(response => {
      this.kasittelija.field.valueOptions = response;
      this.kasittelijatHaettu = true;

      this.kasittelija.enable();
      if (this.tilatunnuksetHaettu) {
        this.loaderService.stopLoadingAnimation();
      }
    });

    this.subs.push(this.fieldGroup.get('toiminnanKohde').valueChanges.subscribe(() => {
      if (this.fieldGroup.get('toiminnanKohde').value === "maatila") {
        this.setRequiredValidators();
      } else if (this.fieldGroup.get('toiminnanKohde').value === "muuKuinMaatila") {
        this.kasittelija.setValue(null);
        this.tilatunnus.setValue(null);
        this.clearRequiredValidators();
      }
    }));

    this.subs.push(this.tilatunnus.valueChanges.subscribe((tilatunnus) => {
      if (tilatunnus && tilatunnus !== 'eiTilatunnusta') {
        var elykeskus = this.getElykeskusByKuntakoodi(tilatunnus);
        elykeskus.then(value => {
          if (value && value.toString().length > 0) {
            this.kasittelija.enable;
            var tilanEly = this.kasittelija.field.valueOptions.find(fieldValue => fieldValue.value === value.toString()).value;
            if (tilanEly && tilanEly.length > 0) {
              this.kasittelija.setValue(tilanEly);
              this.kasittelija.disable({onlySelf: true});
            }
          }
        })
        this.luomuvalvontaService.getKasvulohkot(tilatunnus).then(response => {
          this.luomuvalvontaService.updatepeltolohkot(Object.values(response));
        })
      } else {
        this.kasittelija.setValue(null);
        this.luomuvalvontaService.updatepeltolohkot([])
        this.kasittelija.enable();
      }
    }));
  }

  async createMaatilaValintaArray(): Promise<ValueOption[]> {
    await this.luomuvalvontaService.getMaatilat().then(response => {
      this.maatilat = response
    });
    if (ReadAccount.getRole() === "vk" && this.tilatunnus.value && this.tilatunnus.value !== 'eiTilatunnusta') {
      this.luomuvalvontaService.getMaatilat(this.tilatunnus.value).then(maatilat => this.maatilat = maatilat)
    }
    const radioArray = [new RadioValue('eiTilatunnusta', 'luomu.eiTilatunnusta', 'eiTilatunnusta')];
    for (const prop in this.maatilat) {
      radioArray.push(new RadioValue(this.maatilat[prop].maatilatunnus, this.maatilat[prop].maatilatunnus, this.maatilat[prop].maatilatunnus));
    }
    return radioArray;
  }

  createRadioArray(elyMap: { [p: string]: string }) {
    const radioArray = [];
    for (const prop in elyMap) {
      radioArray.push(new RadioValue(prop + ' ' + elyMap[prop], elyMap[prop], elyMap[prop]));
    }
    return radioArray;
  }

  async createKasittelijaElykeskusArray(tilatunnus?: string): Promise<ValueOption[]> {
    if (this.maatilat && this.maatilat.length > 0 && tilatunnus) {
      const kuntakoodi = this.maatilat.filter(maatila => maatila.maatilatunnus === tilatunnus).map(maatila => maatila.sijaintikuntaNro)[0];
      if (kuntakoodi != null) {
        return await this.luomuvalvontaService.getElyKeskusByKuntakoodi(kuntakoodi).then(response => this.createRadioArray(response));
      }
    }
    return await this.luomuvalvontaService.getElyKeskukset().then(response => this.createRadioArray(response));
  }

  async getElykeskusByKuntakoodi(tilatunnus?: string): Promise<string> {
    if (this.maatilat && this.maatilat.length > 0) {
      const kuntakoodi = this.maatilat.filter(maatila => maatila.maatilatunnus === tilatunnus).map(maatila => maatila.sijaintikuntaNro)[0];
      if (kuntakoodi != null) {
        var valueArray = await this.luomuvalvontaService.getElyKeskusByKuntakoodi(kuntakoodi).then(response => this.createRadioArray(response));
        return valueArray ? valueArray[0].value : null;
      }
    }
  }

  setRequiredValidators() {
    this.tilatunnus.setValidators(Validators.required);
    this.tilatunnus.updateValueAndValidity();
    this.kasittelija.setValidators(Validators.required);
    this.kasittelija.updateValueAndValidity();
  }

  clearRequiredValidators() {
    this.tilatunnus.clearValidators();
    this.tilatunnus.updateValueAndValidity();
    this.kasittelija.clearValidators();
    this.kasittelija.updateValueAndValidity();
  }
}
