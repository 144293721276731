import { Component } from '@angular/core';

/**
 * "Sivua ei löytynyt" -komponetti.
 */
@Component({
  template: `<h2 [attribute]="'etusivu.sivuaEiLoydy'">Page not found</h2>`
})

export class PageNotFoundComponent {}
