import {Injectable} from "@angular/core";
import {LomakeV2Data} from "../vuosiilmoitus/common/lomake-base/lomake-v2-data";
import {AbstractField, Field, FieldArray, FieldControl, FieldGroup, SummaryItem, ValueOption} from "ngx-fielding";
import {createPerustiedotAndLaskutustiedot} from "../vuosiilmoitus/common/lomake-fields/perustiedot";
import {createYhteyshenkiloFieldArray} from "../vuosiilmoitus/common/lomake-fields/yhteyshenkilot";
import {KoodistoService} from "../../koodisto/koodisto.service";
import {RadioValue, SummaryFunctions, TulkkiObject} from "../vuosiilmoitus/common/lomake-field-utils";
import {ToukoValidation} from "../validators/validators";
import {QuestionAndTranslatedAnswerSummaryComponent} from "../summary/question-and-translated-answer-summary.component";
import {LiiteSummaryComponent} from "../summary/liite-summary.component";

const attr = TulkkiObject.getCreateFn('luomuPoikkeus9c');

@Injectable()
export class Luomupoikkeus9cV2Data implements LomakeV2Data {

  static tyypit: ValueOption[] = [
    {value: 'SIEMEN', label: attr('siemen')},
    {value: 'TAIMI', label: attr('taimi')}
  ]

  constructor(private koodistoService: KoodistoService) {
  }

  static getLuomuLupaControls(): FieldGroup {
    const lajiControl = new FieldControl(Field.build(null,
            (c) => {
              const parent = c.parent as FieldGroup
              return new SummaryItem(attr('lupaLaji'), c.value?.nimi ? c.value.nimi : c.value?.kuvaus, {htmlId: `lupaLaji-${parent.field.htmlId}`})
            },
            {[ToukoValidation.NONE_NULL]: attr("errLaji")}),
        null,
        ToukoValidation.controlNotEmpty);
    const lajikeControl = new FieldControl(Field.build(null,
        c => {
          const parent = c.parent as FieldGroup
          return new SummaryItem(attr('lupaLajike'), c.value?.nimi ? c.value.nimi : c.value?.kuvaus, {htmlId: `lupaLajike-${parent.field.htmlId}`})
        },
        {[ToukoValidation.NONE_NULL]: attr("errLajike")}), null, ToukoValidation.controlNotEmpty);
    const tyyppiControl = new FieldControl(Field.build(null,
        c => {
          const parent = c.parent as FieldGroup
          return new SummaryItem<any>(attr('tyyppi'), c.value, {
            show: Boolean(c.value),
            htmlId: `tyyppi-${parent.field.htmlId}`
          })
        },
        {[ToukoValidation.NONE_NULL]: attr("errTyyppi")}, Luomupoikkeus9cV2Data.tyypit), null, ToukoValidation.controlNotEmpty);
    const kayttomaaraControl = new FieldControl(Field.build(null,
        c => {
          const parent = c.parent as FieldGroup
          return new SummaryItem<any>(attr('lupaKayttomaara'), c.value, {
            show: Boolean(c.value),
            htmlId: `lupaKayttomaara-${parent.field.htmlId}`
          })
        },
        {[ToukoValidation.NONE_NULL]: attr("errKayttomaara")}), null, ToukoValidation.controlNotEmpty);

    lajiControl.valueChanges
        .subscribe((value: any) => {
          if (value && value.nimi && value.nimi.trim() !== "") {
            lajikeControl.enable();
          } else {
            lajikeControl.disable();
          }
        });

    tyyppiControl.valueChanges
        .subscribe((value: string) => {
          if (value && value.trim() !== "") {
            kayttomaaraControl.enable();
          } else {
            kayttomaaraControl.disable();
          }
        });

    const liiteArray = (groupTitle, liiteHtmlId, liiteLabel?) => {
      liiteLabel = liiteLabel || groupTitle;
      return new FieldArray(
          Field.of({label: groupTitle, htmlId: liiteHtmlId}),
          liiteFieldGroupFn(liiteLabel, liiteHtmlId)
      );
    }

    const liiteFieldGroupFn = (label, htmlId) => {
      return () => new FieldGroup(
          Field.of({
            label: label,
            summaryFn: group => {
              const parent = group.parent.parent as FieldGroup
              return new SummaryItem<any>(null, group.value, {
                show: group.value,
                htmlId: `lupaLiite-${parent.field.htmlId}`
              }, LiiteSummaryComponent)
            },
            htmlId: htmlId
          }),
          {
            lomakeId: new FieldControl(Field.build()),
            tiedostotyyppi: new FieldControl(Field.build()),
            tiedostonimi: new FieldControl(Field.build()),
            id: new FieldControl(Field.build()),
            valvontakohde: new FieldControl(Field.build())
          }
      )
    }

    const controls: { [key: string]: AbstractField } = {
      laji: lajiControl,
      lajike: lajikeControl,
      kayttomaara: kayttomaaraControl,
      tyyppi: tyyppiControl,
      seoksenKomponentti: new FieldControl(
          Field.of({
            label: null,
            valueOptions: [
              new RadioValue('KYLLA', 'teksti.kylla', null, {infoTeksti: attr('kylla')}),
              new RadioValue('EI', 'teksti.ei', null, {infoTeksti: attr('ei')}),
            ],
            errorMessages: {
              [ToukoValidation.NONE_NULL]: attr('valitseSeoksenKomponentti')
            },
            summaryFn: (control) => {
              const value = control.field.valueOptions.find(o => o.value === control.value);
              const parent = control.parent as FieldGroup;
              return new SummaryItem<any>(attr('seoksenKomponentti'), value?.label, {
                show: control.value,
                htmlId: `seoksenKomponentti-${parent.field.htmlId}`
              }, QuestionAndTranslatedAnswerSummaryComponent, value)
            }
          }), null, ToukoValidation.controlNotEmpty
      ),
      peruste: new FieldControl(
          Field.of({
            label: null,
            valueOptions: [
              new RadioValue('A', attr('lupaPerusteTavanomA'), null, {infoTeksti: attr('infoLupaPerusteTavanomA')}),
              new RadioValue('B', attr('lupaPerusteTavanomB'), null, {infoTeksti: attr('infoLupaPerusteTavanomB')}),
              new RadioValue('C', attr('lupaPerusteTavanomC'), null, {infoTeksti: attr('infoLupaPerusteTavanomC')}),
              new RadioValue('D', attr('lupaPerusteTavanomD'), null, {infoTeksti: attr('infoLupaPerusteTavanomD')}),
              new RadioValue('E', attr('lupaPerusteTavanomE'), null, {infoTeksti: attr('infoLupaPerusteTavanomE')}),
            ],
            errorMessages: {
              [ToukoValidation.NONE_NULL]: attr('errPeruste')
            },
            summaryFn: (control) => {
              const value = control.field.valueOptions.find(o => o.value === control.value);
              const parent = control.parent as FieldGroup;
              return new SummaryItem<any>(attr('lupaPeruste'), value?.label, {
                show: control.value,
                htmlId: `lupaPeruste-${parent.field.htmlId}`
              }, QuestionAndTranslatedAnswerSummaryComponent, value)
            }
          }), null, ToukoValidation.controlNotEmpty
      ),
      perustelut: new FieldControl(Field.build(null, c => {
            const parent = c.parent as FieldGroup;
            return new SummaryItem<any>(attr('lupaPerustelut'), c.value, {
              show: Boolean(c.value),
              htmlId: `lupaPerustelut-${parent.field.htmlId}`
            });
          },
          {[ToukoValidation.NONE_NULL]: attr('errPerustelut')}), null, ToukoValidation.controlNotEmpty),
      liitteet: liiteArray(attr('liitteet'), 'luomulupaliite', attr('lupaLiite'))
    };

    controls.lupaTyyppi = new FieldControl(Field.build('tavanomainen'));
    return new FieldGroup(Field.build(attr('luvatTavanomLisaysaineisto'), SummaryFunctions.titleSummary, null, null, null, 'luvatTavanomLisaysaineisto'), controls);
  }

  async getForm(toimintotyyppi: string): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();

    const {ulkomainenOsoite, perustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
        Field.build(),
        {
          ulkomainenOsoite,
          perustiedot,
          yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
          luvatTavanomLisaysaineisto: new FieldGroup(Field.build(), {
            luvatTavanomLisaysaineisto: new FieldArray(
                Field.build(),
                Luomupoikkeus9cV2Data.getLuomuLupaControls,
                [Luomupoikkeus9cV2Data.getLuomuLupaControls()])
          }),
        },
    )
  }
}



