import {Component, Input} from "@angular/core";
import {FieldArray, FieldControl, SummaryItem} from "ngx-fielding";

@Component({
  selector: 'peltolohko-summary',

  template: `
    <h4 attribute="luomu.lohkotiedot">Lohkotiedot</h4>
    <div class="row mx-0 mb-3 pb-3 border-bottom">
      <div class="col-10 px-0">
        <div class="row mb-2">
          <div attribute="luomu.siirtymavaiheenAloitusajankohta" class="col-lg-4 col-5">Siirtymävaiheen
            suunniteltu
            aloitusajankohta
          </div>
          <div [id]="'siirtymavaiheenSuunniteltuAjankohta-' + groupIndex"
               class="col">{{ siirtymavaiheenSuunniteltuAjankohta }}
          </div>
        </div>
        <div class="row mb-2">
          <div attribute="luomu.lohkonSijoitus" class="col-lg-4 col-5">Lohkon sijoitus</div>
          <div *ngIf="lohkonSijoitusValinta" [attribute]="'luomu.' + lohkonSijoitusValinta"
               [id]="'sijoitusValinta-' + groupIndex" class="col"></div>
        </div>
        <div class="row">
          <div attribute="luomu.lohkojenMaara" class="col-lg-4 col-5">Lohkojen määrä</div>
          <div [id]="'lohkojenMaara-' + groupIndex" class="col"> {{ peltolohkot.length }}</div>
        </div>
      </div>
      <div class="col-2 d-flex justify-content-end align-items-center">
        <button type="button" [id]="'collapsePeltolohkotiedot-' + groupIndex" class="btn btn-link shadow-none"
                (click)="collapse.toggle()"
                [attr.aria-expanded]="!isCollapsed">
          <i *ngIf="isCollapsed" class="fa fa-angle-down text-primary fa-2x" aria-hidden="true"></i>
          <i *ngIf="!isCollapsed" class="fa fa-angle-up text-primary fa-2x" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="collapse pl-5" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="pb-4">
        <table class="table mb-0" id="lohkotiedotsummary-table">
          <thead>
          <tr class="row mx-0">
            <th id="lohkoTunnusSummary" class="h4 col-4 d-flex justify-content-between border-top-none mb-0 p-3">
              <span attribute="luomu.tunnus"></span>
            </th>
            <th id="lohkoNimiSummary" class="h4 col-4 d-flex justify-content-between border-top-none mb-0 p-3">
              <span attribute="luomu.nimi">Nimi</span>
            </th>
            <th id="LohkoPintaAlaSummary" class="h4 col-4 d-flex justify-content-between border-top-none mb-0 p-3">
              <span attribute="luomu.pintaalaHa">Pinta-ala (ha)</span>
            </th>
          </tr>
          </thead>
          <tbody aria-live="polite" id="lohkotiedotSummary-table-body"
                 class="d-block overflow-auto list-height">
          <ng-container *ngFor="let peruslohko of peltolohkot?.controlFields; index as peruslohkoIndex">
            <tr class="row mx-0">
              <td *ngIf="peruslohko.get('lohkotunnus') && peruslohko.get('lohkotunnus').value; else eiTunnustaBlock"
                  [id]="'lohkotunnus-' + groupIndex + '-' + peruslohkoIndex" class="font-weight-normal col-4 p-3">
                {{ peruslohko.get('lohkotunnus').value }}
              </td>
              <ng-template #eiTunnustaBlock>
                <td [id]="'lohkotunnusKasin-' + groupIndex + '-' + peruslohkoIndex"
                    class="font-weight-normal col-4 p-3 font-italic" attribute="luomu.lisattyKasin">(lisätty käsin)
                </td>
              </ng-template>
              <td [id]="'lohkoNimi-' + groupIndex + '-' + peruslohkoIndex" class="font-weight-normal col-4 p-3">
                {{ peruslohko.get('lohkoNimi').value }}
              </td>
              <td [id]="'pintaAla-' + groupIndex + '-' + peruslohkoIndex" class="font-weight-normal col-4 p-3">
                {{ peruslohko.get('pintaAla').value }}
              </td>
            </tr>
            <ng-container
                *ngFor="let kasvulohko of peruslohko?.get('kasvulohkot')?.asArray().controlFields; index as kasvulohkoIndex">
              <tr class="row mx-0">
                <td [id]="'kasvulohkotunnus' + groupIndex + '-' + peruslohkoIndex + '-' + kasvulohkoIndex"
                    class="font-weight-normal col-4 p-3 pl-6">{{ kasvulohko.get('lohkotunnus').value }}
                </td>
                <td [id]="'lohkoNimi' + groupIndex + '-' + peruslohkoIndex + '-' + kasvulohkoIndex"
                    class="font-weight-normal col-4 p-3">{{ peruslohko.get('lohkoNimi').value }}
                </td>
                <td [id]="'pintaAla' + groupIndex + '-' + peruslohkoIndex + '-' + kasvulohkoIndex"
                    class="font-weight-normal col-4 p-3">{{ kasvulohko.get('pintaAla').value }}
                </td>
              </tr>
            </ng-container>

          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  `,
  styles: ['.border-top-none { border-top: none; }']
})

export class PeltolohkoSummary {
  @Input() item: SummaryItem<any>;
  isCollapsed = true;

  get groupIndex() {
    return this.item.options.peltolohkoIndex;
  }

  get siirtymavaiheenSuunniteltuAjankohta() {
    const dateObj = this.item.answer.asGroup().get('siirtymavaiheenSuunniteltuAjankohta')?.value;
    return dateObj ? `${dateObj.day}.${dateObj.month}.${dateObj.year}` : null;
  }

  get lohkonSijoitusValinta(): FieldControl {
    return this.item.answer.asGroup().get('lohkonSijoitusValinta')?.value;
  }

  get peltolohkot(): FieldArray {
    return this.item.answer.asGroup().get('peltolohkot');
  }
}
