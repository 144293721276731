import {Injectable} from "@angular/core";
import {YhteenvetoTyyppi} from "../../touko-lomake/yhteenveto/yhteenveto-utils";
import {ModelFragment, PathUtil, QuestionArray, QuestionControl, QuestionGroup} from "ee-lahete";
import {FormControl} from "@angular/forms";
import {DateObj} from "../../touko-lomake/syote/syote-utils";
import {LaheteYhteenvetoItem} from "./lahete-yhteenveto";
import {jsPDF} from "jspdf";
import {ValueAndLabel} from "../lahete/lahete-data.model";

@Injectable()
export class LaheteYhteenvetoService {

  private readonly NO_VALUE = '–';
  private readonly yhteenvetoMap: Map<YhteenvetoTyyppi, (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem | LaheteYhteenvetoItem[]> = new Map();
  private filteredPaths: string[] = [];
  private addedControlPaths: Set<string> = new Set<string>();
  private radioSelectIndex = 0;
  private dateSelectIndex = 0;
  private textValueIndex = 0;

  private static addYhteenvetoItemToParent(parent: LaheteYhteenvetoItem, item: LaheteYhteenvetoItem | LaheteYhteenvetoItem[]) {
    if (item instanceof Array) {
      parent.children.push(...item);
    } else {
      parent.children.push(item);
    }
  }

  initYhteenvetoService() {
    this.registerYhteenvetoTyypit();
  }

  getYhteenveto(formModel: {[key: string]: QuestionGroup}, formValues: Object, filteredPaths: string[]): LaheteYhteenvetoItem[] {

    this.filteredPaths = filteredPaths;
    const yhteenvetoGroups = [];
    this.addedControlPaths = new Set<string>();

    this.radioSelectIndex = 0;
    this.dateSelectIndex = 0;
    this.textValueIndex = 0;

    Object.entries(formModel).forEach(([key, qGroup]) => {
      const yhteenvetoGroup = new LaheteYhteenvetoItem(null, null, null, qGroup.label, qGroup.htmlId);
      this.addModelFragmentToYhteenvetoRecursive(qGroup, formValues[qGroup.key], yhteenvetoGroup, qGroup.key);
      if (this.isControlVisible(qGroup, 1) && formValues.hasOwnProperty(qGroup.key)) {
        yhteenvetoGroups.push(yhteenvetoGroup);
      }
    });
    return yhteenvetoGroups;
  }

  getVisibleYhteenvetoControlPaths(): Set<string> {
    return this.addedControlPaths;
  }

  /**
   * Luo yhteenvedosta pdf -tiedoston.
   */
  createYhteenvetoPdf(id: number, title: string, contentHtml: any, barcode: string): Promise<any> {
    let blob: Blob;

    const doc = new jsPDF('p', 'pt');
    const options = {
      x: 10,
      html2canvas: {
        scale: 0.5,
      },
      callback: (newDoc: jsPDF) => {
        newDoc.setPage(1);
        newDoc.addImage(barcode, "JPEG", 450, 5, 100, 40);
        blob = newDoc.output('blob');
      },
    };

    const date = new Date().toLocaleString("fi");
    const innerHTML = contentHtml.innerHTML;
    contentHtml.innerHTML = `<h2>${title}</h2>
                  <ul>
                    <li>Muodostettu: ${date}</li>
                    <li>Lähetteen tunnus Toukossa: ${id}</li>
                  </ul>
                  <hr>
                  ${innerHTML}
                 `;

    return doc.html(contentHtml, options)
      .then(() => {
        contentHtml.innerHTML = innerHTML;
        return blob;
      })
      .catch(() => {
        contentHtml.innerHTML = innerHTML;
      });
  }

  /**
   * Asettaa lomakkeen yhteenvetoon lomakkeen mallin ja arvon mukaiset tiedot rekursiivisesti.
   *
   * @param modelFragment - Lomakkeen malli
   * @param formFragmentValue - Lomakkeen arvo
   * @param yhteenvetoParent - Yhteenvedon juurikomponentti
   * @param parentPath - Polku lähetteen kohtaan
   */
  private addModelFragmentToYhteenvetoRecursive(
    modelFragment: ModelFragment,
    formFragmentValue: any,
    yhteenvetoParent: LaheteYhteenvetoItem,
    parentPath: string,
  ) {
    if (this.isHiddenFromYhteenveto(modelFragment, formFragmentValue)) {
      return;
    }

    if (formFragmentValue === undefined) {
      return;
    }

    if (modelFragment instanceof QuestionGroup) {
      this.addQuestionGroupToYhteenveto(modelFragment, formFragmentValue, yhteenvetoParent, parentPath);
    } else if (modelFragment instanceof QuestionArray) {
      this.addQuestionArrayToYhteenveto(modelFragment, formFragmentValue, yhteenvetoParent, parentPath);
    } else if (modelFragment instanceof QuestionControl) {
      this.addQuesitonControlToYhteenveto(modelFragment, formFragmentValue, yhteenvetoParent);
    }
  }

  private readonly isHiddenFromYhteenveto = (modelFragment: ModelFragment, formControlValue: any): boolean => formControlValue === undefined || (modelFragment.options &&
      modelFragment.options.hideInSummary &&
      modelFragment.options.hideInSummary(formControlValue));

  /**
   * Lisää QuestionGroup -tyyppisen kysymyksen yhteenvetoon. Asettaa rekursiivisesti myös sisäkkäiset QuestionGroup,
   * QuestionArray ja QuestionControl -tyyppiset kysymykset.
   *
   * @param questionGroup - Malli
   * @param formGroupValue - Angular control
   * @param yhteenvetoParent - Yhteenvedon juurikomponentti
   * @param parentPath - Polku lähetteen kohtaan
   */
  private readonly addQuestionGroupToYhteenveto = (questionGroup: QuestionGroup,
                                          formGroupValue: any,
                                          yhteenvetoParent: LaheteYhteenvetoItem,
                                          parentPath: string) => {

    if (formGroupValue === undefined) {
      return;
    }

    if (questionGroup.controls) {
      questionGroup.controls.forEach(control =>
        this.addModelFragmentToYhteenvetoRecursive(
          control,
          formGroupValue[control.key],
          yhteenvetoParent,
          this.combinePath(parentPath, control.key))
      );
    }

    if (questionGroup.array) {
      this.addModelFragmentToYhteenvetoRecursive(
        questionGroup.array,
        formGroupValue[questionGroup.array.key],
        yhteenvetoParent,
        this.combinePath(parentPath, questionGroup.array.key)
      );
    }

    if (questionGroup.groups) {
      questionGroup.groups.forEach(group => {
        const childPath = this.combinePath(parentPath, group.key);
        const groupValue = formGroupValue[group.key];
        this.addChildToYhteenveto(group, groupValue, yhteenvetoParent, childPath);
      });
    }

  };

  /**
   * Lisää QuestionArray -tyyppisen kysymyksen ja lapsikomponentit yhteenvetoon.
   *
   * @param questionArray - Malli
   * @param formArrayValues - Angular control
   * @param yhteenvetoParent - Yhteenvedon juurikomponentti
   * @param parentPath - Polku lähetteen kohtaan
   */
  private readonly addQuestionArrayToYhteenveto = (questionArray: QuestionArray,
                                          formArrayValues: any,
                                          yhteenvetoParent: LaheteYhteenvetoItem,
                                          parentPath: string) => {

    if (formArrayValues === undefined) {
      return;
    }

    questionArray.questions.forEach((q, index) => {
      const childPath = this.combinePath(parentPath, index.toString());
      const childAbstractControlValue = formArrayValues[index];
      this.addChildToYhteenveto(q, childAbstractControlValue, yhteenvetoParent, childPath);
    });
  };

  /**
   * Lisää QuestioGroupin tai QuestionArrayn lapsikomponentit yhteenvetoon.
   *
   * @param childFragment - Malli
   * @param childAbstractControlValue - Lomakkeen kentän arvo
   * @param yhteenvetoParent - Yhteenvedon juurikomponentti
   * @param parentPath - Polku lähetteen kohtaan
   */
  private readonly addChildToYhteenveto = (childFragment: ModelFragment,
                               childAbstractControlValue: any,
                               yhteenvetoParent: LaheteYhteenvetoItem,
                               parentPath: string) => {

    if (childAbstractControlValue === undefined) {
      return;
    }

    if (childFragment.label && this.isControlVisible(childFragment)) { // Add parent item
      const subItem = new LaheteYhteenvetoItem(childFragment.label);
      subItem.htmlIdSuffix = childFragment.htmlId;
      this.addModelFragmentToYhteenvetoRecursive(childFragment, childAbstractControlValue, subItem, parentPath);
      if (subItem.children.length > 0) {
        LaheteYhteenvetoService.addYhteenvetoItemToParent(yhteenvetoParent, subItem);
      }
    } else {
      this.addModelFragmentToYhteenvetoRecursive(childFragment, childAbstractControlValue, yhteenvetoParent, parentPath);
    }
  };

  /**
   * Lisää QuestionControl -tyyppisen kysymyksen yhteenvetoon
   *
   * @param questionControl - Malli
   * @param formControl - Angular control
   * @param yhteenvetoParent - Yhteenvedon juurikomponentti
   */
  private readonly addQuesitonControlToYhteenveto = (questionControl: ModelFragment,
                                            formControl: FormControl,
                                            yhteenvetoParent: LaheteYhteenvetoItem) => {
    const newItem = this.createItem(questionControl, formControl);

    if (newItem && this.isControlVisible(questionControl) && formControl !== undefined) {
      LaheteYhteenvetoService.addYhteenvetoItemToParent(yhteenvetoParent, newItem);
      this.addedControlPaths.add(questionControl.fullPath);
    } else if (questionControl.options?.summaryType === YhteenvetoTyyppi.NONE) {
      this.addedControlPaths.add(questionControl.fullPath);
    }
  };

  private readonly combinePath = (parent: string, current: string) => `${parent}.${current}`;

  private readonly isControlVisible = (model: ModelFragment, filterLevel = 0) => {
    if (!this.filteredPaths) {
      return true;
    }

    if (model.options && model.options.requireFullSummaryPath) {
      return new Set(this.filteredPaths).has(model.fullPath);
    }

    const currentPath = model.fullPath;

    return this.filteredPaths.some(filteredPath => {
      const comparePath = filterLevel === 0 ?
        filteredPath :
        filteredPath.split('.', filterLevel).join('.');
      return PathUtil.checkPathIsChildOf(currentPath, comparePath);
    });
  };

  private registerYhteenvetoTyypit() {
    this.yhteenvetoMap.set(YhteenvetoTyyppi.NONE, this.hiddenItem);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.VALUE_ONLY, this.singleValue);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_ONLY, this.checkboxSelect);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_AND_TEXT, this.radioSelect);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_AND_VALUE, this.textValue);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SINGLE_SELECTION_LABEL, this.dropdownSelect);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SINGLE_SELECTION_DATE, this.dateValue);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_PREFORMATTED, this.selectPreformatted);
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS, this.radioNayteSelect);
  }

  private createItem(model: ModelFragment, controlValue: any): LaheteYhteenvetoItem | LaheteYhteenvetoItem[] {
    if (this.isHiddenFromYhteenveto(model, controlValue)) {
      return null;
    }
    if (this.isSummaryItem(model)) {
      return this.yhteenvetoMap.get(model.options.summaryType)(model, controlValue, model.options.summaryType);
    }

    return null;
  }

  private isSummaryItem(model: ModelFragment) {
    return model.options && model.options.hasOwnProperty('summaryType') && this.yhteenvetoMap.has(model.options.summaryType);
  }

  /** YHTEENVETOTYYPIT **/

  private readonly hiddenItem = () => null;

  private readonly checkboxSelect: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => controlValue ? new LaheteYhteenvetoItem(null, model.label, type, null, model.htmlId) : null;

  private readonly singleValue: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => new LaheteYhteenvetoItem(null, controlValue, type, null, model.htmlId);

  private readonly radioSelect: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => model.value === controlValue ? new LaheteYhteenvetoItem(null, model.label, type, null, model.htmlId) : null;

  private readonly radioNayteSelect: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => {
    const value = model.options.data.find(item => item.value === controlValue) as ValueAndLabel;
    const laheteYhteenvetoItem = value ? new LaheteYhteenvetoItem(model.label, value.label) : new LaheteYhteenvetoItem(model.label, this.NO_VALUE);
    laheteYhteenvetoItem.htmlIdSuffix = model.htmlId + this.radioSelectIndex;
    this.radioSelectIndex = this.radioSelectIndex + 1;
    return laheteYhteenvetoItem;
  };

  private readonly selectPreformatted: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => model.value === controlValue ? new LaheteYhteenvetoItem(model.label, null, type, null, model.htmlId) : null;

  private readonly dropdownSelect: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => {
    const selectedOption = model.options && model.options.selection ?
      model.options.selection.find(option => controlValue && controlValue.toString() === `${option.value}`) :
      null;
    return selectedOption ?
      new LaheteYhteenvetoItem(model.label, selectedOption.label, type, null, model.htmlId) :
      new LaheteYhteenvetoItem(model.label, this.NO_VALUE, type, null, model.htmlId);
  };

  private readonly textValue: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => {
    var htmlIdSuffix = model.htmlId + this.textValueIndex;
    this.textValueIndex = this.textValueIndex + 1;
    return new LaheteYhteenvetoItem(model.label, controlValue || this.NO_VALUE, type, null, htmlIdSuffix);
  }

  private readonly dateValue: (model: ModelFragment, controlValue: any, type: YhteenvetoTyyppi) => LaheteYhteenvetoItem = (model, controlValue, type) => {
    const pvm = controlValue instanceof Date ? DateObj.fromDate(controlValue) : controlValue as DateObj;
    var htmlIdSuffix = model.htmlId + this.dateSelectIndex;
    this.dateSelectIndex = this.dateSelectIndex + 1;
    return pvm ?
      new LaheteYhteenvetoItem(model.label, `${pvm.day}.${pvm.month}.${pvm.year}`, type, null, htmlIdSuffix) :
      new LaheteYhteenvetoItem(model.label, this.NO_VALUE, type, null, htmlIdSuffix);
  };
}
