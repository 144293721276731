import {Component, OnInit} from '@angular/core';
import {Teksti} from "../utils/teksti";
import {AccountService} from "../account/account.service";


const VIEW = "infoValilehti";

const luomu = {teksti: new Teksti("luomu", "toimialaLuomu", "lomakeYleinen"), tyyli: "badge-success"};
const rehu = {teksti: new Teksti("rehu", "toimialaRehu", "lomakeYleinen"), tyyli: "badge-primary"};
const lannoite = {teksti: new Teksti("lannoite", "toimialaLannoite", "lomakeYleinen"), tyyli: "badge-warning"};
const siemen = {teksti: new Teksti("siemen", "toimialaSiemen", "lomakeYleinen"), tyyli: "badge-dark"};
const kasvinterveys = {teksti: new Teksti("kasvinterveys", "toimialaKate", "lomakeYleinen"), tyyli: "badge-info"};
const viranomainen = {teksti: new Teksti("viranomainen", "viranomainen", "login"), tyyli: "badge-secondary"};
const elabra = { teksti: new Teksti("elabra", "toimialaElainlaboratorio", "lomakeYleinen"), tyyli: "badge-danger" };

@Component({
  selector: 'info-valilehti',
  templateUrl: './info-valilehti.component.html',
  styles: [`
    .badge {
      margin-left: 0.5em;
    }
  `]
})

export class InfoValilehtiComponent implements OnInit {

  versions: Version[] = [
    new Version('versio1100',
      new Update('versio1100_1', lannoite, rehu),
      new Update('versio1100_2', lannoite, rehu)
    ),
    new Version('versio190',
      new Update('versio190_1', lannoite),
      new Update('versio190_2', elabra, kasvinterveys, lannoite)
    ),
    new Version('versio180',
      new Update('versio180_1', siemen, kasvinterveys, lannoite, luomu),
      new Update('versio180_2', elabra)
    ),
    new Version('versio170',
      new Update('versio1700', siemen)
    ),
    new Version('versio160',
      new Update('versio1603', elabra),
      new Update('versio1604', kasvinterveys),
      new Update('versio1604_1', luomu)
    ),
    new Version('versio150',
      new Update('versio1501', kasvinterveys),
      new Update('versio1502', rehu, lannoite, kasvinterveys, luomu)
      ),
    new Version('versio145',
      new Update('versio1451', luomu),
      new Update('versio1452', luomu),
      new Update('versio1453')
      ),
    new Version('versio140',
      new Update('versio1401', luomu),
      new Update('versio1402', luomu),
      new Update('versio1403', rehu, lannoite, kasvinterveys, luomu)
      ),
    new Version('versio130',
      new Update('versio1301', rehu, lannoite, kasvinterveys),
      new Update('versio1302', viranomainen)
      ),
    new Version('versio120',
      new Update('versio1201'),
      new Update('versio1202', viranomainen)
      ),
    new Version('versio110',
      new Update('versio1101', kasvinterveys),
      new Update('versio1102', kasvinterveys)
    ),
    new Version('versio100',
      new Update("versio1001", siemen),
      new Update("versio1002", siemen),
      new Update("versio1003", lannoite),
      new Update('versio1004', rehu),
      new Update('versio1005', siemen, rehu, lannoite)
    ),
  ];

  constructor(private accountService: AccountService) {
  }

  ngOnInit(): void {
    if (!this.accountService.isViranomainen()) {
      this.versions.forEach(versio => {
        versio.updates = versio.updates.filter(u => !u.flags.some(f => f === viranomainen));
      });
    }
  }
}

class Version {
  title: Teksti;
  updates: Update[];

  constructor(versionAttribute: string, ...updates: Update[]) {
    this.title = new Teksti('Versio', versionAttribute, VIEW);
    this.updates = updates || [];
  }
}

class Update {
  description: Teksti;
  flags: {teksti: Teksti; tyyli: string}[];

  constructor(descriptionAttribute: string, ...flags: {teksti: Teksti; tyyli: string}[]) {
    this.description = new Teksti('Päivitys', descriptionAttribute, VIEW);
    this.flags = flags || [];
  }

}
