import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {AttributeService} from "./attribute.service";
import {Subscription} from "rxjs/internal/Subscription";
import * as marked from "marked";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

@Directive({
  selector: '[attribute]'
})
@AutoUnsubscribe
export class AttributeDirective implements OnInit {

  @Input('attribute') label: string; // vai Teksti -objekti?
  @Input('lang') langOverride = null;
  @Input() multiLang = null;
  @Input() markdown = false;

  attributeSubscription: Subscription;

  constructor(private readonly el: ElementRef,
              private readonly attributeService: AttributeService,
              private readonly renderer: Renderer2) {
    this.configMarked();
  }

  configMarked() {
    const markdownRender = new marked.Renderer();
    markdownRender.link = (href, title, text) => {
      const link: string = marked.Renderer.prototype.link.call(markdownRender, href, title, text);
      return link.includes('target="_blank"') ?
          link :
          link.replace('<a', '<a target="blank"');
    };
    marked.setOptions({
      renderer: markdownRender
    });
  }

  ngOnInit() {
    if (!this.label) {
      return;
    }

    const viewName = this.attributeService.resolveView(this.label);
    const label = this.attributeService.resolveLabel(this.label);
    const element = this.el.nativeElement;
    if (label !== null) {
      element.textContent = "...";
      this.attributeSubscription = this.attributeService.ready$.subscribe(state => {
        this.attributeService.label(label, viewName, this.langOverride, this.multiLang).subscribe(data => {
          if (data) {
            if (this.markdown) {
              const markdownHtml = marked(data.teksti);
              this.renderer.setProperty(element, 'outerHTML', markdownHtml);
            } else {
              element.textContent = data.teksti;
              element.title = data.tooltip;
            }
          } else {
            element.textContent = this.label;
          }
        });
      });
    }
  }
}
