import {RaporttiHaku, Sarake, SarakeType, SortOrder, Tag, Tapahtumatyyppi} from "./raportti.utils";
import {FormGroup} from "@angular/forms";
import {Teksti} from "../utils/teksti";
import {DateObj} from "../touko-lomake/syote/syote-utils";
import {RaporttiViewService} from "./raportti-view.service";
import {Subscription} from "rxjs/index";
import {TulosraporttiService} from "./raportti.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../account/account.service";
import {Asetukset} from "../account/account";


declare let canShowAds;

/**
 * Abstrakti pohjaluokka, jonka raporttinäkymän tulee toteuttaa.
 */
export abstract class RaporttiBaseClass {

  protected abstract raporttiViewService: RaporttiViewService;
  protected abstract tulosraporttiService: TulosraporttiService;
  protected abstract accountService: AccountService;
  protected abstract activatedRoute: ActivatedRoute;
  protected abstract toimiala: string;
  protected subs: Subscription[] = [];

  abstract raporttiForm: FormGroup;
  private raporttiValues = {};
  private initialValues: {[s: string]: any};

  tapahtumatyyppi = Tapahtumatyyppi; // clientiin

  showLoader = true;

  private timeOut = 200;
  private lastUpdate = null;

  abstract getRaportitFn: (...any) => Promise<RaporttiHaku>;
  abstract liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  abstract liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  columnOrder: { key: string; title: Teksti; styleFn?: (any) => string; pipe?; clickFn? }[] = [];
  abstract keys;

  abstract initTags();

  private setInitialValues() {
    this.initialValues = {... this.raporttiForm};
  }

  private listenRajaukset() {
    this.raporttiForm.valueChanges.subscribe(value => {
      if (!value) {
        return;
      }
      this.lastUpdate = Date.now();
      setTimeout(() => {
        if (Date.now() - this.lastUpdate >= this.timeOut) {
          this.raporttiValues = value;
          return this.submit();
        }
      }, this.timeOut);
    });
  }

  alustaRajausLomake(form: FormGroup) {
    this.raporttiForm = form;
    this.setInitialValues();
    this.listenRajaukset();
  }

  initRaportit(): Promise<void> {
    try {
      if (!canShowAds) {
        console.error("Should not execute");
      }
    } catch (e) {
      this.raporttiViewService.setAdblockDetected();
    }

    this.subs.push(
      this.tulosraporttiService.isGetRaportitRequestPending$.subscribe(value => {
        this.showLoader = value;
      })
    );

    const params = this.activatedRoute.snapshot.queryParams;
    const prevAccount = this.accountService.getCurrentAccount();

    if (params.hasOwnProperty("vk") && (!prevAccount || prevAccount.valvontakohdeElmoId !== params.vk)) {

      return this.accountService.setAccountAsetukset(new Asetukset(this.toimiala, params.vk)).then((account) => {
          this.raporttiViewService.showValvontakohdeChangedMsg();
          return Promise.resolve(this.getRaportit());
      });
    } else {
      return this.getRaportit();
    }
  }

  getRaportit() {
    return this.getRaportitFn()
      .then(response => {
        this.raporttiViewService.setRaportit(response || new RaporttiHaku());
        this.raporttiViewService.jarjesta("kasiteltyAika", SortOrder.ASC);
      });
  }

  reset() {
    if (this.raporttiForm.dirty) {
      this.raporttiForm.reset(this.initialValues, {onlySelf: true, emitEvent: false});
      this.raporttiForm.markAsPristine();
    }
  }

  submit() {
    if (!this.raporttiForm.valid) {
      return;
    }
    const params = {};
    const formValue = this.raporttiForm.getRawValue();
    for (const control in formValue) {
      if (formValue.hasOwnProperty(control) && formValue[control]) {
        const val = formValue[control];
        if (val instanceof Array && val.length > 0) {
          params[control] = val.join(',');
        } else if (val instanceof DateObj) {
          params[control] = `${val.year}-${val.month}-${val.day}`;
        } else {
          params[control] = val;
        }
      }
    }

    return this.getRaportitFn(params).then(response => {
      this.raporttiViewService.setRaportit(response || new RaporttiHaku());
      this.raporttiViewService.jarjesta();
    });
  }

  getInvalid(field: string) {
    return this.raporttiForm.get(field).invalid;
  }

  mapField(mapFn: (raportti) => Tag): Tag[] {
    return this.raporttiViewService.mapRaporttiField(mapFn);
  }

  getAsiakirjatSarakeTitle(asiakasAttributeKey = 'asiakirjat'): Sarake {
    const raportit = 'raportit';
    const styleFn = () => "btn btn-outline-dark btn-sm";
    return this.accountService.isViranomainen() ?
      new Sarake('liitetiedostoList', new Teksti(raportit, 'asiakirjatNayta', raportit), SarakeType.FILE_LIST, styleFn) :
      new Sarake('liitetiedostoList', new Teksti(raportit, asiakasAttributeKey, raportit), SarakeType.FILE_LIST, styleFn);
  }
}
