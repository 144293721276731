import {Teksti} from "../../utils/teksti";
import {YhteenvetoTyyppi} from "../yhteenveto/yhteenveto-utils";

export interface SyoteInterface {

  name: string;
  label: Teksti;
  options: { [s: string]: any };
  summaryType: YhteenvetoTyyppi;
  children: SyoteInterface[] | { [key: string]: SyoteInterface }[];
  control: any;
  value?: any;
  type: SyoteType;

  setViewKey(key: string);

  getLabelKey();

  findChild(path: (string | number)[]): SyoteInterface;

  findChildByValue(path: (string | number)[], value): SyoteInterface;
}

export abstract class SyoteBase implements SyoteInterface {
  name: string;
  label: Teksti;
  options: { [s: string]: any };
  summaryType: YhteenvetoTyyppi;
  children: SyoteInterface[] | { [key: string]: SyoteInterface }[];
  control: any;
  value?: any;
  type: SyoteType;

  abstract setViewKey(key: string);

  abstract getLabelKey();

  public findChild(path: (string | number)[]): SyoteInterface {
    const nextSyote = (this.children as SyoteInterface[]).find(s => s.name === path[0]);

    if (path.length === 1) {
      return nextSyote;
    } else {
      path.splice(0, 1);
      return nextSyote.findChild(path);
    }
  }

  public findChildByValue(path: (string | number)[], value): SyoteInterface {
    if (path.length === 1) {
      const nextSyote = (this.children as SyoteInterface[]).find(s => s.name === path[0] && s.value === value);
      return nextSyote;
    } else {
      const nextSyote = (this.children as SyoteInterface[]).find(s => s.name === path[0]);
      path.splice(0, 1);
      return nextSyote.findChildByValue(path, value);
    }
  }
}

export enum SyoteType {
  CONTROL,
  ARRAY,
}
