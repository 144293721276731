import {Component} from "@angular/core";
import {Teksti} from "../../../utils/teksti";
import {LomakeHelper, YhteyshenkiloValue} from "../../touko-lomake-utils";
import {RehuvuosiilmoitusData} from "./rehuvuosiilmoitus-data";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {AccountService} from "../../../account/account.service";
import {RehuExcelMapper} from "./rehu-excel-mapper";
import {LomakeInfo} from "../common/lomake-info";
import {JSONOut} from "../../syote/custom/touko-excel-lataus.component";
import {ExcelMapper} from "../common/excel-mapper";
import {LomakeV2BaseDirective} from "../common/lomake-base/lomake-v2-base.directive";

@Component({
  templateUrl: 'rehuvuosiilmoitus.component.html',
  selector: 'rehuvuosiilmoitus',
  providers: [RehuvuosiilmoitusData, ProcessLomakeService]
})

export class RehuvuosiilmoitusComponent extends LomakeV2BaseDirective {
  lisatieto: string;
  excelMapper: ExcelMapper = new RehuExcelMapper();

  constructor(protected readonly processLomakeService: ProcessLomakeService,
              protected readonly data: RehuvuosiilmoitusData,
              protected readonly accountService: AccountService,
  ) {
    super(processLomakeService, data, accountService);
    this.saveLomake = this.saveLomake.bind(this);
    this.submitLomake = this.submitLomake.bind(this);
    this.cancelLomake = this.cancelLomake.bind(this);
    this.handleLeavePerustiedotSivu = this.handleLeavePerustiedotSivu.bind(this);
  }

  mapJsonToLomake(excelData: JSONOut) {
    if (excelData.errors.length === 0) {
      this.lomake.get(excelData.json.type).initValue(this.excelMapper.mapExcelData(excelData.json));
    }
  }

  storeLiite(liite) {
    this.liitetiedostot = Object.assign(this.liitetiedostot, liite);
  }

  removeDeletedExcelData(dataType) {
    const rehuaineKey = dataType[0].toUpperCase() + dataType.slice(1)
    this.lomake.get([dataType, `rehuAineet${rehuaineKey}`, "kasviperaiset"]).asArray().clear();
    this.lomake.get([dataType, `rehuAineet${rehuaineKey}`, "elainperaiset"]).asArray().clear();
    this.lomake.get([dataType, `rehuAineet${rehuaineKey}`, "muut"]).asArray().clear();
    Object.entries(this.lomake.get(dataType).asGroup().controlFields)
      .filter(([key, _ignore]) => key !== `rehuAineet${rehuaineKey}`)
      .map(entry => entry[1])
      .forEach(control => control.asArray().clear());
  }

  initLomakeInfo(accountType: "as" | "vk", toimintotyyppi: string): LomakeInfo {
    return new RehuvuosiilmoitusInfo(accountType);
  }

  initLomakeBehaviour() {
    // Not used currently
  }

  addLomakeBehaviour() {
    this.lomake.get('tuotteet.excelTiedostot').valueChanges.subscribe(() => this.saveLomake());
    this.lomake.get('tuotteet.laakeRehu').valueChanges.subscribe(() => this.saveLomake());
    this.setEiToimintaaStatus(this.lomake.get('tuotteet.rehuEiToimintaa').value);
  }

  setEiToimintaaStatus(value) {
    if (value) {
      this.lomake.get("tuotteet.excelTiedostot").disable({emitEvent: false});
    } else {
      this.lomake.get("tuotteet.excelTiedostot").enable({emitEvent: false});
    }
  }

}

class RehuvuosiilmoitusInfo implements LomakeInfo {
  readonly lomakeTitle: Teksti = new Teksti("Ilmoitus rekisteröintiä varten", "vuosiilmoitus", "rehuvuosiilmoitus");
  readonly asia = "rehuvuosiilmoitus";
  readonly toimintotyyppi = 'vuosiilmoitus';
  readonly serverBaseUrl: string;
  readonly clientComponentVersion = 'v1'

  readonly yhteyshenkiloRoolit: YhteyshenkiloValue[];
  readonly defaultRooli: string;

  readonly ohjeet: { [s: string]: string[] } = {};

  constructor(kayttajaRooli: "as" | "vk") {
    this.yhteyshenkiloRoolit = [YhteyshenkiloValue.VUOS_ILM, YhteyshenkiloValue.VK_YH, YhteyshenkiloValue.VK_VARA_YH];
    this.defaultRooli = this.yhteyshenkiloRoolit[0].value;
    const prefix = 'rehuvuosiilmoitus.ohje';
    this.ohjeet.sivu0 = LomakeHelper.getOhjeList(prefix, ['1'], "_1");
    this.ohjeet.sivu1 = LomakeHelper.getOhjeList(prefix, ['2'], "_1");
    this.ohjeet.sivu2 = LomakeHelper.getOhjeList(prefix, ['3'], "_1");
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/vuosiilmoitukset/rehuvuosiilmoitus/vuosiilmoitus`;
  }
}
