import {Injectable} from "@angular/core";
import {AccountService} from "../account/account.service";
import {KayttajaRooli} from "../account/account";
import {LoginState, TiiraService} from "../tiira/tiira.service";
import {CanActivate, Router} from "@angular/router";

/**
 * Tarkistaa että käyttäjän oikeudet on tunnistettu
 */
@Injectable()
export class PermissionGuardService implements CanActivate {

  constructor(private accountService: AccountService, public tiira: TiiraService, public router: Router) {}

  canActivate(): boolean {

    if (this.tiira.isLoggedIn() === LoginState.LOGGEDIN) {
      const account = this.accountService.getCurrentAccount();
      if (!account) {
        this.router.navigate(['/asiointi/error/noaccountinfo']);
        return false;
      }
      if (account.kayttajaRooli === KayttajaRooli.TUNTEMATON) {
        this.router.navigate(['/asiointi/error/nopermission']);
        return false;
      }
    }

    return true;
  }

}
