import {Component, Type} from '@angular/core';
import {Field} from "./field";
import {AbstractField, FieldArray, FieldGroup} from "./abstract-field";

/**
 * Represents a summary item that contains a question, an answer, and an Angular component to render the item.
 *
 * @template T The type of the Angular component that will render the summary item.
 */
export class SummaryItem<T> {

  private readonly _question?: string;
  private readonly _answer?: any;
  private readonly _component: Type<T>;
  private readonly _options: {[key: string]: any} = {};
  private _field: Field;

  /**
   * The answer to the question that the summary item is addressing.
   */
  get answer(): any | undefined {
    return this._answer;
  }

  /**
   * The question that the summary item is addressing.
   */
  get question(): string | undefined {
    return this._question;
  }

  /**
   *  Additional options for the summary item.
   */
  get options(): { [p: string]: any } {
    return this._options;
  }

  /**
   * The Angular component that will render the summary item.
   */
  get component(): Type<T> {
    return this._component;
  }

  /**
   * Determines whether the summary item should be shown.
   */
  get show(): boolean {
    return this._options?.show !== undefined ?
      Boolean(this._options.show) :
      true;
  }

  get field(): Field {
    return this._field;
  }

  /**
   * Set summary field, automatically set when AbstractField creates a summary item.
   * @param value field information of this summary
   */
  set field(value: Field) {
    if (!this._field) {
      this._field = value;
    }
  }

  /**
   * Creates a new instance of SummaryItem.
   *
   * @param question The question that the summary item is addressing.
   * @param answer The answer to the question that the summary item is addressing.
   * @param options Additional options for the summary item.
   * @param component The Angular component that will render the summary item.
   * @param field Field object that owns this summary item.
   */
  constructor(question?: string, answer?: any, options?: SummaryOptions, component?: Type<T>, field?: Field) {
    this._question = question;
    this._answer = answer;
    this._options = options || {};
    this._component = component;
    this._field = field;
  }
}

/**
 * Options for the summary item
 */
export interface SummaryOptions {
  /** Determines whether the summary item should be shown. */
  show?: boolean,

  /** Styles used in the summary container */
  classList?: string[],

  /** User defined options */
  [key: string]: any
}

export const EMPTY_SUMMARY_FN = (field) => new SummaryItem(null, null, {show: isAnyChildVisible(field)}, EmptySummaryComponent)

@Component({
  standalone: true,
  selector: 'empty-summary',
  template: '',
})
export class EmptySummaryComponent {
  // component logic
}

const isAnyChildVisible: (field: AbstractField) => boolean = field =>  {
  if (field instanceof FieldGroup) {
    return Object.values(field.controlFields).some(c => c?.summary?.show);
  } else if (field instanceof FieldArray) {
    return field.controlFields.some(c => c?.summary?.show);
  }

  return field?.summary?.show;
}
