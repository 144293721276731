import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {RaporttiBaseClass} from "../raportti-base";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TulosraporttiService} from "../raportti.service";
import {Teksti} from "../../utils/teksti";
import {RaporttiHaku, Sarake, SarakeType, Tag} from "../raportti.utils";
import {DataTableComponent} from "../data-table.component";
import {Syote} from "../../touko-lomake/syote/syote";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";

/**
 * Created by Seppo on 18/06/2018.
 */

const VIEW = "raportit";

@Component({
  selector: 'katetarkastus-raportti',
  templateUrl: './katetarkastus-raportti.component.html'
})
export class KateTarkastusComponent extends RaporttiBaseClass implements OnInit {
  @ViewChild("datatable") datatable: DataTableComponent;
  @Input() getRaportitFn: (...any) => Promise<RaporttiHaku>;
  raporttiForm: FormGroup;
  liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  keys;
  tapahtumatyypitTags: Tag[] = [];
  syotteet;

  protected toimiala = "KATE";

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
      this.fb.group({
        tapahtumatyyppi: [],
        kuvaus: null,
        pvmAlku: [null, ToukoValidation.isValidDate],
        pvmLoppu: [null, ToukoValidation.isValidDate],
      })
    );

    this.syotteet = {
      tapahtumatyyppi: new Syote(new Teksti('Tapahtumatyyppi', 'tapahtumatyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTapahtumatyyppi', VIEW)}),
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };

    this.liiteDownloadFn = this.tulosraporttiService.getKasvinterveysLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getKasvinterveysLiiteZip.bind(this.tulosraporttiService);
    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getKasvinterveysRaportit.bind(this.tulosraporttiService);

    this.keys = {
      tunnus: new Sarake('tapahtumaElmoid', new Teksti(VIEW, 'tunnus', VIEW)),
      kuvaus: new Sarake('kuvaus', new Teksti(VIEW, 'kuvaus', VIEW)),
      tapahtumatyyppi: new Sarake('tapahtumatyyppi', new Teksti(VIEW, "tapahtumatyyppi", VIEW), SarakeType.TYPE, () => 'badge badge-primary'),
      ntila: new Sarake('naytteenottotila', new Teksti(VIEW, 'naytteenottotila', VIEW), SarakeType.NTILA),
      pvm: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      dokumentti: this.getAsiakirjatSarakeTitle('dokumentti'),
    };

    this.columnOrder = [
      this.keys.tunnus,
      this.keys.kuvaus,
      this.keys.tapahtumatyyppi,
      this.keys.ntila,
      this.keys.pvm,
      this.keys.dokumentti
    ];

    this.initTags();
    this.initRaportit();
  }

  initTags() {
    this.tapahtumatyypitTags = [
      new Tag(new Teksti("Päätös", "paatos", VIEW), "DECISION"),
      new Tag(new Teksti("Tarkastuskäynti", "tarkastuskaynti", VIEW), "INSPVISIT"),
      new Tag(new Teksti("Rekisteriote", "rekisteriote", VIEW), "NOTIFICATION"),
    ];
  }
}

