import {Component, Input} from "@angular/core";
import {FieldControl, FieldGroup} from "ngx-fielding";

@Component({
  selector: "laskutustiedot",
  template: `
    <ng-container [formGroup]="lomake">
      <ng-container formGroupName="laskutustiedot">
        <fieldset class="mb-2">
          <legend [attribute]="lomake.get('laskutustiedot').field.label"></legend>

          <touko-checkbox [labelAttribute]="lomake.get('laskutustiedot.laskutusosoiteCheck').field.label"
                          htmlId="laskutusosoiteCheck"
                          formControlName="laskutusosoiteCheck"></touko-checkbox>
          <touko-osoite-lannoite [ulkomainenOsoite]="ulkomainenOsoite.value" id="lt-lasku" class="form-row mb-3" formControlName="laskutusosoite"></touko-osoite-lannoite>
          <touko-verkkolasku class="form-row mb-3" formControlName="verkkolaskutusosoite"></touko-verkkolasku>

          <validation-error
            [fields]="[
            lomake.get('laskutustiedot'),
            lomake.get('laskutustiedot.laskutusosoite'),
            lomake.get('laskutustiedot.verkkolaskutusosoite')]">
          </validation-error>
        </fieldset>

      </ng-container>
    </ng-container>
  `
})
export class PerustiedotLaskutustiedotComponent {
  @Input() lomake: FieldGroup;

  get ulkomainenOsoite(): FieldControl {
    return this.lomake.get('ulkomainenOsoite').asControl();
  }

}
