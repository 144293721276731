import {Component, Input} from "@angular/core";
import {Subscription} from "rxjs/internal/Subscription";
import {LomakeErrorService} from "./lomake-error.service";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 15/11/2017.
 */

/**
 * Yksittäisen sivun sisältö.
 */
@Component({
  selector: 'sivu',
  template: `
    <div *ngIf="valittu === id">
      <div class="row">
        <div class="col">
          <ng-content></ng-content>
        </div>
        <div class="col-3 mr-3 ml-3 pl-0 pr-0" id="ohje-{{id}}" *ngIf="ohjeet.length > 0">
          <p>
            <button id="lomakeohje" type="button" class="btn btn-link-secondary" (click)="toggleOhje()">
              <em class="far fa-question-circle text-primary"></em><span class="pl-2"
                                                                         attribute="lomakeYleinen.tarvitsetkoApua"></span>
            </button>
            <modal-ohje *ngIf="showOhje && yleisOhjeet.length > 0" [titleAttr]="yleisohjeOtsikkoAttr"
                        [ohjeet]="yleisOhjeet"></modal-ohje>
          </p>
          <div *ngIf="showOhje" style="word-wrap: break-word;" class="small">
            <div *ngFor="let ohje of ohjeet" class="small" [attribute]="ohje" [markdown]="true"></div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./touko-sivu.component.scss']
})
@AutoUnsubscribe
export class SivuComponent {

  /**
   * @link ToukoLomakeComponent luokassa asetetaan viitteet
   */
  public ref: string;

  id: number;
  valittu: number;
  virheita = false;
  @Input() attribuuttiAvain: string = null;
  @Input() ohjeet: string[] = [];
  @Input() yleisOhjeet: string[] = [];
  @Input() yleisohjeOtsikkoAttr: string;
  @Input() selectable = true;

  showOhje = false;
  syotteet: Set<string> = new Set();
  subs: Subscription[] = [];

  constructor(private lomakeService: LomakeErrorService) {
    this.subs.push(this.lomakeService.virhe$.subscribe((value) => {
      if (value && value.sivu === this.ref) {
        if (value.virhe) {
          this.syotteet.add(value.syote);
        } else {
          this.syotteet.delete(value.syote);
        }

        this.virheita = this.syotteet.size > 0;
      }
    }));

    this.subs.push(this.lomakeService.showOhje$.subscribe((value) => {
      this.showOhje = value;
    }));
  }

  toggleOhje() {
    this.lomakeService.toggleOhje();
  }
}
