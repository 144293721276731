import {inject, Injectable} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {EnvironmentService, Feature} from "../../environments/environment.service";

/**
 * Guard, jolla estetään pääsy routeen tuotantoympäristössä.
 *
 * Kun ominaisuus on testattu ja voidaan viedä tuotantoon, guardin voi poistaa `app-routing.module`-määrittelyistä.
 */
@Injectable()
export class WipFeatureGuardService {
    constructor(private readonly router: Router,
                private environmentService: EnvironmentService) {
    }

    canActivateWip(): boolean {
        if (this.environmentService.isFeatureEnabled(Feature.WIP_FEATURE)) {
            return true;
        }
        this.router.navigate(['/asiointi', 'etusivu']);
        return false;
    }
}

export const canActivateWipFeature: CanActivateFn = () => {
  return inject(WipFeatureGuardService).canActivateWip();
};
