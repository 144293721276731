import {Component, Input} from "@angular/core";
import {FieldControl, FieldGroup} from "ngx-fielding";
@Component({
  selector: "perustiedot",
  template: `
    <ng-container [formGroup]="lomake">

      <ng-container formGroupName="perustiedot">
        <fieldset class="mb-2">
          <legend [attribute]="lomake.get('perustiedot').field.label"></legend>

          <div class="form-row">
            <div class="col">
              <touko-checkbox [labelAttribute]="ulkomainenOsoite.field.label"
                              [formControl]="ulkomainenOsoite.asControl()"></touko-checkbox>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <label [attribute]="lomake.get('perustiedot.osasto').field.label" [htmlFor]="'pt-osasto'"></label>
              <touko-osasto formControlName="osasto" [paatoiminimi]="paatoiminimi"
                            [osastoReadonly]="false" [osastoNimiLock]="true">
              </touko-osasto>
            </div>
          </div>
            <touko-yritys-tiedot [lomake]="lomake" [isHenkiloAsiakas]="isHenkiloAsiakas"></touko-yritys-tiedot>
          <div class="form-row">
            <div class="col" required>
              <touko-osoite-lannoite id="pt-posti" class="form-row" formControlName="postiosoite" [ulkomainenOsoite]="ulkomainenOsoite.value"></touko-osoite-lannoite>
              <validation-error
                [field]="lomake.get('perustiedot.postiosoite')">
              </validation-error>
            </div>
          </div>
        </fieldset>
        <fieldset class="mb-2">
          <legend [attribute]="lomake.get('perustiedot.kayntiosoite').field.label"></legend>
          <touko-checkbox htmlId="postiosoiteCheck"
                          [labelAttribute]="lomake.get('perustiedot.postiosoitecheck').field.label"
                          formControlName="postiosoitecheck"></touko-checkbox>
          <touko-osoite-lannoite required id="pt-kaynti" class="form-row mb-3" formControlName="kayntiosoite" [ulkomainenOsoite]="ulkomainenOsoite.value"></touko-osoite-lannoite>
          <validation-error
            [field]="lomake.get('perustiedot.kayntiosoite')">
          </validation-error>
        </fieldset>
      </ng-container>
    </ng-container>
  `
})
export class PerustiedotComponent {

  @Input() lomake: FieldGroup;
  @Input() isHenkiloAsiakas: boolean;

  get ulkomainenOsoite(): FieldControl {
    return this.lomake.get('ulkomainenOsoite').asControl();
  }

  get maakoodi(): FieldControl {
    return this.lomake.get('maakoodi').asControl();
  }

  get paatoiminimi(): string {
    return this.lomake.get('perustiedot.yritys').value;
  }

}
