import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'login-virkailija',
  templateUrl: './login-virkailija.component.html',
})
export class LoginVirkailijaComponent implements OnInit {

  loginForm: FormGroup;
  @Output() onSubmitForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      KAYTTAJA: [null, Validators.required],
      SALASANA: [null, Validators.required],
      KIELI: []
    });
  }

  submitForm() {
    this.onSubmitForm.emit(this.loginForm.value);
  }
}
