import * as XLSX from "xlsx";

export class ExcelUtils {

  static EXCELTYPE = 27;
  static VERSION = 4;

  public static requiredColums(rowData) {
    const required = [];
    if(rowData) {
      rowData.forEach((value, i) => {
        if (value) {
          if (value.toString().includes("*")) {
            required.push(i);
          }
        }
      });
    }
    return required;
  }

  public static findExelInfo(workbook: XLSX.WorkBook): ExcelInfo {
    const tayttoOhje = ExcelUtils.getTayttoOhjeOrNull(workbook);
    const type = tayttoOhje?.[0]?.[ExcelUtils.EXCELTYPE];
    let version = tayttoOhje?.[0]?.[ExcelUtils.VERSION];
    if (!version) { // "väliaikainen" korjaus, lannoitelomakepohjissa versio oli rivillä 2
      version = tayttoOhje?.[1]?.[ExcelUtils.VERSION];
    }
    return new ExcelInfo(type, version);
  }

  private static getTayttoOhjeOrNull(workbook: XLSX.WorkBook) {
    const tayttoOhje = XLSX.utils.sheet_to_json(workbook.Sheets["Täyttöohje"] || workbook.Sheets["Instruktioner"], {
      header: 1,
      skipHidden: false
    });
    return tayttoOhje && tayttoOhje[0] ?
        tayttoOhje :
        null;
  }

  public static removeSpaces(data) {
    if (isNaN(data)) {
      const maara = data ? Number(data.toString().replace(' ', '')) : null;
      return isNaN(maara) ? null : maara;
    }
    return data
  }
}

export class ExcelInfo {
  readonly type: string;
  readonly version: string;

  constructor(type: string, version: string) {
    this.type = type;
    this.version = version;
  }
}
