import {Component, Input, OnInit} from '@angular/core';
import {FieldGroup, SummaryItem} from "ngx-fielding";
import {SummaryService} from "./summary.service";
import {Subscription} from "rxjs";
import {AutoUnsubscribe} from "../../../utils/auto-unsubscribe.decorator";

@Component({
  selector: 'app-field-group-summary',
  template: `
    <div class="row">
      <div [ngClass]="showCompare ? 'col-6' : 'col'">
        <summary-container *ngFor="let summaryItem of summaryItems"
                           [summaryItem]="summaryItem"></summary-container>
      </div>
    </div>
  `
})
@AutoUnsubscribe
export class FieldGroupSummaryComponent implements OnInit {
  @Input() fieldGroup: FieldGroup;
  @Input() showCompare = false;
  summaryItems: SummaryItem<any>[];
  subs: Subscription;

  constructor(private summaryService: SummaryService) {
  }

  ngOnInit() {
    this.subs = this.fieldGroup.valueChanges.subscribe(() => {
      this.summaryItems = this.summaryService.getSummary(this.fieldGroup)
    });
      this.summaryItems = this.summaryService.getSummary(this.fieldGroup)
  }
}
