import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'question-and-translated-answer',
  template: `
    <div *ngIf="item" class="row" [ngClass]="{'pb-2': !compareItemType}">
      <div *ngIf="!compareItemType" class="col-lg-4 col-5 mb-3">
        <span [id]="'label-' + htmlId" [attribute]="item?.question"></span>
      </div>
      <div class="col">
        <compare-item [compareItemType]="compareItemType">
          <span [id]="'value-' + htmlId" class="answer" [attribute]="item?.answer">{{item?.answer}}</span>
        </compare-item>
      </div>
    </div>
  `,
  styleUrls: ['./summary.scss']
})
export class QuestionAndTranslatedAnswerSummaryComponent {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;

  get htmlId() {
    return this.item?.field?.htmlId || this.item?.options?.htmlId;
  }
}
