import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LOMAKE_KUVAUKSET, LomakeResponse} from "../../touko-lomake/touko-lomake-utils";
import {LomakeService} from "../../touko-lomake/touko-lomake.service";
import {Subscription} from "rxjs/internal/Subscription";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 17/07/2018.
 */

@Component({
  selector: 'modal-jatka',
  templateUrl: 'modal-jatka.component.html'
})
@AutoUnsubscribe
export class ModalJatkaComponent implements OnInit {

  @Input() item: any;
  modalValue = null;
  keskenerainenLomake: LomakeResponse = null;
  lomakeSubscription: Subscription;
  showLoader = true;
  kuvaukset = LOMAKE_KUVAUKSET;

  constructor(public activeModal: NgbActiveModal,
              private readonly lomakeService: LomakeService) {
  }

  ngOnInit(): void {
    this.lomakeSubscription = this.lomakeService.currentLomake$.subscribe(val => {
      if (val) {
        this.keskenerainenLomake = val;
        this.modalValue = val.tila === 'ODOTTAA_LISATIETOJA' ? 'JATKA' : null;
        this.showLoader = false;
      }
    });

    if (LomakeEndpointUtil.shouldUseV2Endpoint(this.item.asia, this.item.lomaketyyppiVersio)) {
      this.lomakeService.getLomakeV2Asiakas(this.item.keskeneraisenId, this.item.asia, this.item.toimintotyyppi.toLowerCase(), true).then(
        r => {
          this.keskenerainenLomake = r;
        });
    } else {
      this.lomakeService.getLomake(this.item.keskeneraisenId).then(r => {
        this.keskenerainenLomake = r;
      });
    }
  }

  poistaLukitus() {
    this.lomakeService.updateViimeksiMuokannutAsiakas(this.keskenerainenLomake.id, this.keskenerainenLomake);
  }

  submit() {
    this.activeModal.close(this.modalValue);
  }
}
