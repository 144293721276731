import {NgModule} from "@angular/core";
import {VirkailijaTiedotComponent} from "./touko-virkailija-tiedot.component";
import {VirkailijaUudelleenkasittelyComponent} from "./virkailija-uudelleenkasittely.component";
import {SiirryTarkastukseenComponent} from "./siirry-tarkastukseen.component";
import {CommonModule} from "@angular/common";
import {AttributeModule} from "../../tiira/attribute.module";
import {ToukoInputsModule} from "../../touko-inputs/touko-inputs.module";
import {ReactiveFormsModule} from "@angular/forms";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {LoaderModule} from "../../loader/loader.module";

@NgModule({
  declarations: [
    VirkailijaTiedotComponent,
    VirkailijaUudelleenkasittelyComponent,
    SiirryTarkastukseenComponent
  ],
  imports: [
    CommonModule,
    AttributeModule,
    ReactiveFormsModule,
    NgbTypeahead,
    ToukoInputsModule,
    LoaderModule,
  ],
  exports: [
    VirkailijaTiedotComponent,
    VirkailijaUudelleenkasittelyComponent,
    SiirryTarkastukseenComponent
  ]
})
export class VirkailijaModule {

}
