import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LOMAKE_KUVAUKSET, LomakeResponse} from "../touko-lomake/touko-lomake-utils";
import {LomakeService} from "../touko-lomake/touko-lomake.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalData} from "../modaalit/modal-utils";
import {Teksti} from "../utils/teksti";
import {ModalSimpleComponent} from "../modaalit/lomake/modal-simple.component";
import {Router} from "@angular/router";

/**
 * Created by Seppo on 18/07/2018.
 */

@Component({
  selector: 'lomake-card',
  templateUrl: './lomake-card.component.html',
  styleUrls: ['./etusivu.component.scss']
})
export class LomakeCardComponent {

  @Input() lomake: LomakeResponse;
  @Output() jatka: EventEmitter<LomakeResponse> = new EventEmitter();
  @Output() poista: EventEmitter<LomakeResponse> = new EventEmitter();
  kuvaukset = LOMAKE_KUVAUKSET;

  constructor(private readonly lomakeService: LomakeService,
              private readonly modalService: NgbModal,
              private readonly router: Router) {
  }

  jatkaLomake() {
    this.jatka.emit(this.lomake);
  }

  poistaLomake() {
    this.poista.emit(this.lomake);
  }

  getKuvausTeksti(asia: string, toimintotyyppi: string): Teksti {
    const hasKuvaus = this.kuvaukset[asia] && this.kuvaukset[asia][toimintotyyppi];
    return hasKuvaus ?
      this.kuvaukset[asia][toimintotyyppi] :
      new Teksti(
        "FIXME: Täydennä LOMAKE_KUVAUKSET -listaa",
        "FIXME_täydennä_LOMAKE_KUVAUKSET-listaa",
        "etusivu"
      );
  }

  esikatsele() {
    this.router.navigate(["/asiointi/lomake/esikatselu/", this.lomake.toimintotyyppi.toLowerCase(), this.lomake.asia, `v${this.lomake.lomaketyyppiVersio}`, this.lomake.id]);
  }

  otaLomakeKasittelyyn() {
    const okFn = () => this.lomakeService.updateViimeksiMuokannutAsiakas(this.lomake.id, this.lomake).then((r) => {
      this.lomake = r;
    });

    const modalRef = this.modalService.open(ModalSimpleComponent);
    const modalData = new ModalData(
      new Teksti("Otsikko", "modalHaluatkoJatkaa", "sivupalkki"),
      new Teksti("Kysymys", "modalLomakkenMuokkaaja", "sivupalkki"),
      new Teksti("Kyllä", "kylla", "teksti"),
      okFn
    );

    modalRef.componentInstance.modalTiedot = modalData;
  }
}
