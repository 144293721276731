<div class="row">
  <div class="col-12">
    <h1 attribute="teksti.tilinAsetukset">Tilin asetukset</h1>
  </div>

  <div class="col-md-9">
    <dl class="row">
      <dt class="col-sm-3" attribute="kayttajatili.kayttaja">Kirjatunut käyttäjä</dt>
      <dd class="col-sm-9">
        <ng-container *ngIf="isYritystoimija">
          <span *ngIf="!account.yritys" attribute="kayttajatili.eiMaaritelty">Ei määritelty</span>
          <ng-container *ngIf="account.yritys">
            <span id="account-yritys-paatoiminimi">
                {{account.yritys.paaToiminimi}}
            </span>
            <span id="account-yritys-ytunnus">
                {{account.yritys.yTunnus}}
            </span>
          </ng-container>
        </ng-container>

        <span id="account-nimi">
          <ng-container *ngIf="isYritystoimija">
            ({{account?.wholeName}})
          </ng-container>
          <ng-container *ngIf="!isYritystoimija">
            {{account?.wholeName}}
          </ng-container>
        </span>
      </dd>
    </dl>
  </div>

  <div *ngIf="isYritystoimija" class="col-12">
    <hr/>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <h2 *ngIf="isYritystoimija" attribute="kayttajatili.toimipaikkaAsetukset">Toimipaikan asetukset</h2>
    <h2 *ngIf="isViranomainen" attribute="kayttajatili.viranomaisenToimiala">Viranomaisen toimiala</h2>
  </div>
  <div class="col-md-9">
    <dl class="row">
      <dt class="col-sm-3" [attribute]="'kayttajatili.toimiala'">Toimiala</dt>
      <dd class="col-sm-9">
        <ng-container *ngIf="account?.toimiala">{{account.toimialaTeksti.getFullKey() | attribute}}</ng-container>
        <ng-container *ngIf="!account?.toimiala">
          <span attribute="kayttajatili.eiMaaritelty"></span>
        </ng-container>
      </dd>
      <ng-container *ngIf="isYritystoimija && account?.toimiala !== 'ELAIN_LABORATORIO'">
        <dt class="col-sm-3" attribute="kayttajatili.toimipaikanNimi">Toimipaikan nimi</dt>
        <dd class="col-sm-9" [id]="'account-toimipaikka-nimi'">
          <span *ngIf="valvontakohdeValinta">
            {{valvontakohdeValinta.toimipaikka.nimi_fi}}
          </span>
          <span *ngIf="!valvontakohdeValinta" attribute="kayttajatili.eiMaaritelty">
            Ei määritelty
          </span>
        </dd>
        <ng-container *ngIf="valvontakohdeValinta">
          <dt class="col-sm-3" attribute="kayttajatili.kayntiosoite">Käyntiosoite</dt>
          <dd class="col-sm-9" id="account-toimipaikka-osoite">
            <span>{{valvontakohdeValinta.toimipaikka.osoite}}</span>
            <span>
              {{valvontakohdeValinta.toimipaikka.postinumero}} {{valvontakohdeValinta.toimipaikka.postinumero | postitoimipaikka | async}}
            </span>
          </dd>
          <dt class="col-sm-3" attribute="kayttajatili.yhteyshenkilo">Yhteyshenkilö</dt>
          <dd class="col-sm-9" id="account-toimipaikka-yhteyshenkilo">
            {{toimipaikanYhteyshenkilo || '-'}}
          </dd>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isHenkilo">
        <ng-container *ngIf="account.maatila">
          <dt class="col-sm-3" attribute="kayttajatili.maatilanNimi">Maatilan nimi</dt>
          <dd class="col-sm-9" [id]="'account-toimipaikka-nimi'">
            <span [id]="'account-henkilo-tilannimi'">
                {{account.maatila.tilanNimi}}
            </span>
          </dd>
        </ng-container>
        <ng-container *ngIf="account.maatila">
          <dt class="col-sm-3" attribute="kayttajatili.kayntiosoite">Käyntiosoite</dt>
          <dd class="col-sm-9" [id]="'account-maatila-osoite'">
            <span [id]="'account-henkilo-tilannimi'">
              {{account.maatila.postiosoite}} {{account.maatila.postinumero}} {{account.maatila.postinumero | postitoimipaikka | async}}
            </span>
          </dd>
        </ng-container>
      </ng-container>
    </dl>
  </div>

  <div class="col-md-3">
    <vaihda-toimipaikka [redirectUrl]="['asiointi', 'account']"></vaihda-toimipaikka>
  </div>

  <div *ngIf="isYritystoimija" class="col-12">
    <hr/>
  </div>
</div>

<div class="row" *ngIf="account?.valvontakohde">
  <div class="col-12">
    <h2 attribute="kayttajatili.ilmoitusasetukset">Ilmoitusasetukset</h2>
  </div>

  <div class="col-md-9">
    <p attribute="kayttajatili.ilmoitusKuvaus">Voit saada sähköpostitse tiedon, kun uusia päätöksiä, todistuksia tai
      muita raportteja on katsottavissa Toukossa.</p>

    <dl class="row">
      <dt class="col-md-3" attribute="kayttajatili.ilmoitusTiedotKaytossa">Ilmoitukset käytössä</dt>
      <dd class="col-md-9">
        <yes-no-badge [tila]="account.ilmoitukset"></yes-no-badge>
      </dd>
      <ng-container class="account-align-top" *ngIf="account.yhteyshenkilot">
        <dt class="col-md-3" attribute="kayttajatili.ilmoitusTiedotYhteyshenkilot">Ilmoitusten yhteyshenkilöt</dt>
        <dd class="col-md-9">
          <ng-container *ngFor="let yhteyshenkilo of account.yhteyshenkilot">
            <span *ngIf="yhteyshenkilo.ytrStatus !== 'INVALID' && yhteyshenkilo.elmoStatus !== 'INACTIVE'">
              {{yhteyshenkilo.nimi}}, <em>{{yhteyshenkilo.email}}</em> <br>
            </span>
          </ng-container>
        </dd>
      </ng-container>
    </dl>
  </div>

  <div class="col-md-3">
    <button type="button" id="account-vaihda-ilmoitustiedot" class="btn btn-outline-primary" (click)="openIlmoitukset()"
            attribute="kayttajatili.ilmoitusMuokkaa">Muokkaa
    </button>
  </div>

</div>
