import {NgModule} from "@angular/core";
import {ToukoLomakeComponent} from "./touko-lomake.component";
import {SivuComponent} from "./touko-sivu.component";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {LoaderModule} from "../loader/loader.module";
import {ModalOhjeComponent} from "./modal-ohje.component";
import {AttributeModule} from "../tiira/attribute.module";
import {ModaaliModule} from "../modaalit/modaali.module";
import {LomakeRedirectService} from "./lomake-redirect.service";
import {VirkailijaModule} from "./virkailija/virkailija.module";

@NgModule({
  declarations: [
    ToukoLomakeComponent,
    SivuComponent,
    ModalOhjeComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    AttributeModule,
    ModaaliModule,
    LoaderModule,
    VirkailijaModule
  ],
    exports: [
        ToukoLomakeComponent,
        SivuComponent,
        ModalOhjeComponent
    ],
  providers: [
    LomakeRedirectService
  ]
})
export class LomakeLayoutModule {

}
