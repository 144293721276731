<div class="card mb-2">
  <div class="card-body row">
    <div class="col-10">
      <em class="fa align-top fa-2x mr-3 p-1 w-25" [ngClass]="iconStyle"></em>
      <div class="d-inline-block w-75">
        <p [id]="'otsikko-' + title" [attribute]="title">Kirjautumistapa</p>
        <p [id]="'kuvaus-' + title" [attribute]="description" class="small">Kuvaus</p>
      </div>
    </div>
    <div class="col-2 card-arrow">
      <em class="fa fa-chevron-right align-top fa-2x p-1 float-right"></em>
    </div>
  </div>
</div>
