export class TranslateExcel {
  private tuonti = {
    "Välj från menyn som öppnas": "Valitse avautuvasta valikosta",
    "1. Spannmål och produkter därav (välj bland följande)": "1. Viljojen jyvät ja niistä saatavat tuotteet (valitse alla olevista)",
    "1.1.1 Korn": "1.1.1 Ohra",
    "1.1.2 Svällt korn": "1.1.2 Ohra, paisutettu",
    "1.1.3 Rostat korn": "1.1.3 Ohra, paahdettu",
    "1.1.4 Kornflingor": "1.1.4 Ohrahiutaleet",
    "1.1.5 Kornfiber": "1.1.5 Ohrakuitu",
    "1.1.6 Kornskal": "1.1.6 Ohrankuoret",
    "1.1.7 Kornklimjöl": "1.1.7 Ohrarehujauho",
    "1.1.8 Kornprotein": "1.1.8 Ohravalkuainen",
    "1.1.9.1 Kornproteinfoder, torkat": "1.1.9.1 Ohravalkuaisrehu, kuiva",
    "1.1.9.2 Kornproteinfoder, våt": "1.1.9.2 Ohravalkuaisrehu, tuore",
    "1.1.10 Vattenlösliga restprodukter av korn": "1.1.10 Ohran liukoiset aineet",
    "1.1.11 Kornkli": "1.1.11 Ohralese",
    "1.1.12 Flytande kornstärkelse": "1.1.12 Ohratärkkelys, nestemäinen",
    "1.1.13 Sållrester av mältat korn": "1.1.13 Mallasohran seulontajakeet",
    "1.1.14 Mältat korn och maltdamm": "1.1.14 Mallasohra ja mallasjae",
    "1.1.15 Skal från mältat korn": "1.1.15 Mallasohran kuoret",
    "1.1.16 Våta fasta partiklar av korn från destillering": "1.1.16 Ohrarankin kiinteä osa, sellaisenaan",
    "1.1.17 Våta vattenlösliga restprodukter av korn från destillering": "1.1.17 Ohrarankin liukoinen osa",
    "1.1.18 Malt ": "1.1.18 Maltaat ( 1 )",
    "1.1.19 Maltrottrådar": "1.1.19 Mallasidut ( 1 )",
    "1.2.18 Majs ": "1.2.1 Maissi ( 2 )",
    "1.2.2 Majsflingor": "1.2.2 Maissihiutaleet",
    "1.2.3 Majsklimjöl": "1.2.3 Maissirehujauho",
    "1.2.4 Majskli": "1.2.4 Maissilese",
    "1.2.5 Majskolvar": "1.2.5 Maissintähkät",
    "1.2.6 Sållrester av majs": "1.2.6 Maissin seulontajakeet",
    "1.2.7 Majsfiber": "1.2.7 Maissikuitu",
    "1.2.8 Majsgluten": "1.2.8 Maissigluteeni",
    "1.2.9 Majsglutenfoder": "1.2.9 Maissigluteenirehu",
    "1.2.10 Majsgroddar": "1.2.10 Maissinalkio",
    "1.2.11 Majsgroddsexpeller": "1.2.11 Maissinalkiopuriste",
    "1.2.12 Majsgroddsmjöl": "1.2.12 Maissinalkiorouhe",
    "1.2.13 Majsgroddsolja": "1.2.13 Maissinalkioöljy, raaka",
    "1.2.14 Svälld majs": "1.2.14 Maissi, paisutettu",
    "1.2.15 Blötläggningsvätska för majs": "1.2.15 Maissinliotusvesi",
    "1.2.16 Sockermajsensilage": "1.2.16 Sokerimaissintähkäsäilörehu",
    "1.2.17 Krossad majs utan grodd": "1.2.17 Murskattu maissi, josta on poistettu alkiot",
    "1.2.18 Majsgryn": "1.2.18 Maissiryynit(2)",
    "1.3.1 Hirs": "1.3.1 Hirssi",
    "1.4.1 Havre": "1.4.1 Kaura",
    "1.4.2 Skalad havre": "1.4.2 Kaura, kuorittu",
    "1.4.3 Havregryn": "1.4.3 Kaurahiutaleet",
    "1.4.4 Havreklifoder": "1.4.4 Kaurarehujauho /kaurarehu",
    "1.4.5 Havrekli": "1.4.5 Kauralese",
    "1.4.6 Havreskal": "1.4.6 Kaurankuoret",
    "1.4.7 Svälld havre": "1.4.7 Kaura, paisutettu",
    "1.4.8 Rensad havre": "1.4.8 Kaurasuurimot",
    "1.4.9 Havremjöl": "1.4.9 Kaurajauho",
    "1.5.1 Extraherat quinoafrö": "1.5.1 Kvinoansiemenrouhe",
    "1.6.1 Brutet ris": "1.6.1 Rouhittu riisi",
    "1.6.2 Malet ris": "1.6.2 Jauhettu riisi",
    "1.6.3 Förklistrat ris": "1.6.3 Esigelatinoitu riisi",
    "1.6.4 Extruderat ris": "1.6.4 Ekstrudoitu riisi",
    "1.6.5 Risflingor": "1.6.5 Riisihiutaleet",
    "1.6.6 Råris": "1.6.6 Esikuorittu riisi",
    "1.6.7 Malet foderris": "1.6.7 Jauhettu rehuriisi",
    "1.6.8 Rismjöl": "1.6.8 Riisijauho",
    "1.6.9 Rårismjöl": "1.6.9 Esikuorittu riisijauho",
    "1.6.10 Riskli": "1.6.10 Riisilese",
    "1.6.11 Riskli innehållande kalciumkarbonat": "1.6.11 Riisilese, kalsiumkarbonaattipitoinen",
    "1.6.12 Avfettat riskli": "1.6.12 Riisilese, rasvaton",
    "1.6.13 Riskliolja": "1.6.13 Riisileseöljy",
    "1.6.14 Risklimjöl": "1.6.14 Riisirehujauho",
    "1.6.15 Risklimjöl innehållande kalciumkarbonat": "1.6.15 Riisirehujauho, kalsiumkarbonaattipitoinen",
    "1.6.16 Ris": "1.6.16 Riisi",
    "1.6.17 Risgroddar": "1.6.17 Riisinalkio",
    "1.6.18 Risgroddsexpeller": "1.6.18 Riisinalkiopuriste",
    "1.6.20 Risprotein": "1.6.20 Riisivalkuainen",
    "1.6.21 Flytande polerat ris": "1.6.21 Nestemäinen riisirehu",
    "1.6.22 Svällt ris": "1.6.22 Riisi, paisutettu",
    "1.6.23 Fermenterat ris": "1.6.23 Riisi, fermentoitu",
    "1.6.24 Deformerat malt ris/malet ris innehållande kalk": "1.6.24 Epämuodostunut riisi, jauhettu/liitumainen riisi, jauhettu",
    "1.6.25 Omoget malet ris": "1.6.25 Epäkypsä riisi, jauhettu",
    "1.7.1 Råg": "1.7.1 Ruis",
    "1.7.2 Rågklifoder": "1.7.2 Ruisrehujauho",
    "1.7.3 Foderråg": "1.7.3 Ruisrehu",
    "1.7.4 Rågkli": "1.7.4 Ruislese",
    "1.8.1 Sorghum [Milo]": "1.8.1 Durra; [Milo]",
    "1.9.1 Speltvete": "1.9.1 Spelttivehnä",
    "1.9.2 Speltvetekli": "1.9.2 Spelttilese",
    "1.9.3 Speltveteskal": "1.9.3 Speltinkuoret",
    "1.9.4 Speltveteklimjöl": "1.9.4 Spelttirehujauho",
    "1.10.1 Triticale [Rågvete]": "1.10.1 Ruisvehnä",
    "1.11.1 Vete": "1.11.1 Vehnä",
    "1.11.2 Veterottrådar": "1.11.2 Vehnäidut",
    "1.11.3 Förklistrat vete": "1.11.3 Vehnä, esigelatinoitu",
    "1.11.4 Veteklifoder": "1.11.4 Vehnärehujauho",
    "1.11.5 Veteflingor": "1.11.5 Vehnähiutaleet",
    "1.11.6 Fodervete": "1.11.6 Vehnärehu",
    "1.11.7 Vetekli ": "1.11.7 Vehnälese ( 3 )",
    "1.11.8 Mältade fermenterade vetepartiklar": "1.11.8 Mallastetut, fermentoidut vehnän osaset",
    "1.11.10 Vetefiber": "1.11.10 Vehnäkuitu",
    "1.11.11 Vetegroddar": "1.11.11 Vehnänalkio",
    "1.11.12 Fermenterade vetegroddar": "1.11.12 Vehnänalkio, fermentoitu",
    "1.11.13 Vetegroddsexpeller": "1.11.13 Vehnänalkiopuriste",
    "1.11.15 Veteprotein": "1.11.15 Vehnävalkuainen",
    "1.11.16 Veteglutenfoder": "1.11.16 Vehnägluteenirehu",
    "1.11.18 Veteglutenmjöl": "1.11.18 Puhdas vehnägluteeni",
    "1.11.19 Flytande vetestärkelse": "1.11.19 Vehnätärkkelys, nestemäinen",
    "1.11.20 Delvis avsockrad vetestärkelse innehållande protein": "1.11.20 Vehnätärkkelys, valkuaispitoinen, vähäsokerinen",
    "1.11.21 Vattenlösliga restprodukter av vete": "1.11.21 Vehnän liukoiset aineet",
    "1.11.22 Vetejästkoncentrat": "1.11.22 Vehnähiivatiiviste",
    "1.11.23 Sållrester av mältat vete": "1.11.23 Mallastetun vehnän seulontajakeet",
    "1.11.24 Mältat vete och maltdamm": "1.11.24 Mallastettu vehnä ja mallasjae",
    "1.11.25 Skal av mältat vete": "1.11.25 Mallastetun vehnän kuoret",
    "1.12.2 Spannmålsmjöl ": "1.12.2 Viljajauho ( 1 )",
    "1.12.3 Proteinkoncentrat från spannmål ": "1.12.3 Viljavalkuaistiiviste ( 1 )",
    "1.12.4 Sållrester av spannmål ": "1.12.4 Viljan jyvien seulontajakeet ( 1 )",
    "1.12.5 Groddad spannmål ": "1.12.5 Viljanalkiot ( 1 )",
    "1.12.6 Sirap av blötläggningsvätska från spannmål ": "1.12.6 Haihduttamalla tiivistetty rankki ( 1 )",
    "1.12.7 Blöt drank / Torkad drank med tillsats av mäsk; [drank och vattenlösliga rester från destillerier] ": "1.12.7 Rankki, tuore / Rankki, tumma; [Kuivattu rankki ja liukoiset aineet]",
    "1.12.8 Koncentrerade vattenlösliga restprodukter från destillering / Drank och vattenlösliga restprodukter från destillering": "1.12.8 Rankin liukoisten aineiden tiiviste / Rankki ja liukoiset aineet ",
    "1.12.10 Torkad drank": "1.12.10 Kuivattu rankki",
    "1.12.12 Drav ": "1.12.12 Olutmäski (1 )",
    "1.12.13 Whiskydrank ": "1.12.13 Mäski ( 1 )",
    "1.12.14 Filtrerade mäskprodukter": "1.12.14 Suotopuristinmäski",
    "1.12.15 Pot ale": "1.12.15 Tislausjäännös",
    "1.12.16 Pot ale-sirap": "1.12.16 Tislausjäännöstiiviste",
    "2. Oljehaltiga frön eller frukter samt produkter och biprodukter därav (välj bland följande)": "2. Öljykasvien siemenet, öljypitoiset hedelmät ja niistä saatavat tuotteet (valitse alla olevista)",
    "2.1.1 Babassuexpeller": "2.1.1 Babassupalmupuriste",
    "2.2.1 Cirkamelinafrö": "2.2.1 Camelinansiemenet",
    "2.2.2 Cirkamelinaexpeller": "2.2.2 Camelinapuriste",
    "2.2.3 Cirkamelinamjöl": "2.2.3 Camelinarouhe",
    "2.3.1 Kakaobönskal": "2.3.1 Kaakaopavun ulkokuoret",
    "2.3.2 Kakaoskal": "2.3.2 Kaakaopavun kuoret",
    "2.3.3 Mjöl av delvis skalade kakaobönor": "2.3.3 Kaakaorouhe osittain kuorituista pavuista",
    "2.4.1 Kokos-/Kopraexpeller": "2.4.1 Kookospuriste",
    "2.4.2 Kokos/Kopra, hydrolyserad expeller": "2.4.2 Kookospuriste, hydrolysoitu",
    "2.4.3 Kokos-/Kopramjöl": "2.4.3 Kookosrouhe",
    "2.5.1 Bomullsfrön": "2.5.1 Puuvillansiemenet",
    "2.5.2 Mjöl av delvis skalat bomullsfrö": "2.5.2 Puuvillansiemenrouhe osittain kuorituista siemenistä",
    "2.5.3 Bomullsfröexpeller": "2.5.3 Puuvillansiemenpuriste",
    "2.6.1 Expeller av delvis skalade jordnötter": "2.6.1 Maapähkinäpuriste osittain kuorituista pähkinöistä",
    "2.6.2 Mjöl av delvis skalade jordnötter": "2.6.2 Maapähkinärouhe osittain kuorituista pähkinöistä",
    "2.6.3 Expeller av skalade jordnötter": "2.6.3 Maapähkinäpuriste kuorituista pähkinöistä",
    "2.6.4 Mjöl av skalade jordnötter": "2.6.4 Maapähkinärouhe kuorituista pähkinöistä",
    "2.7.1 Kapockexpeller": "2.7.1 Kapokkipuriste.",
    "2.8.1 Linfrö": "2.8.1 Pellavansiemenet",
    "2.8.2 Linfröexpeller": "2.8.2 Pellavansiemenpuriste",
    "2.8.3 Linfrömjöl": "2.8.3 Pellavansiemenrouhe",
    "2.8.4 Foder av linfröexpeller": "2.8.4 Pellavansiemenpuristerehu",
    "2.8.5 Foder av linfrömjöl": "2.8.5 Pellavansiemenrouherehu",
    "2.8.6 Linfröolja": "2.8.6 Pellavaöljy",
    "2.9.1 Senapskli": "2.9.1 Sinappilese",
    "2.9.2 Senapsfrömjöl": "2.9.2 Sinapinsiemenrouhe",
    "2.10.1 Nigerfrö": "2.10.1 Niger-kasvin siemenet",
    "2.10.2 Nigerfröexpeller": "2.10.2 Niger-siemenpuriste/-kakku",
    "2.11.1 Palmkärnexpeller": "2.11.1 Oliivirouhe",
    "2.11.1 Olivpressmassa": "2.11.2 Oliivirouherehu, rasvaton",
    "2.11.2 Foder av avfettat oljemjöl": "2.11.3 Oliivirouhe, rasvaton",
    "2.11.3 Avfettat olivmjöl": "2.12.1 Palmuydinpuriste",
    "2.12.2 Palmkärnsmjöl": "2.12.2 Palmuydinrouhe",
    "2.12.3 Palmkärnsolja": "2.12.3 Palmuöljy/palmuydinöljy",
    "2.13.1 Pumpa- och squashfrön": "2.13.1 Kurpitsansiemenet",
    "2.13.2 Pumpa- och squashfröexpeller": "2.13.2 Kurpitsansiemenpuriste",
    "2.14.1 Rapsfrön ": "2.14.1 Rapsinsiemenet ( 1 )",
    "2.14.2 Rapsfröexpeller": "2.14.2 Rapsipuriste",
    "2.14.3 Mjöl av rapsfrön": "2.14.3 Rapsirouhe",
    "2.14.4 Extruderade rapsfrön": "2.14.4 Rapsinsiemenet, ekstrudoitu",
    "2.14.5 Proteinkoncentrat av rapsfrö": "2.14.5 Tiivistetty rapsinsiemenvalkuainen",
    "2.14.6 Foder av rapsfröexpeller": "2.14.6 Rapsipuristerehu",
    "2.14.7 Foder av rapsfrömjöl": "2.14.7 Rapsirouherehu",
    "2.14.8 Rapsfröolja": "2.14.8 Rapsiöljy",
    "2.14.9 Rypsfrön ": "2.14.9 Rypsinsiemenet ( 1 )",
    "2.14.10 Rypsfröexpeller": "2.14.10 Rypsipuriste",
    "2.14.11 Mjöl av rypsfrön": "2.14.11 Rypsirouhe",
    "2.14.12 Extruderade rypsfrön": "2.14.12 Rypsinsiemenet, ekstrudoitu",
    "2.14.13 Proteinkoncentrat av rypsfrö": "2.14.13 Tiivistetty rypsinsiemenvalkuainen",
    "2.14.14 Foder av rypsfröexpeller": "2.14.14 Rypsipuristerehu",
    "2.14.15 Foder av rypsfrömjöl": "2.14.15 Rypsirouherehu",
    "2.14.16 Rypsfröolja": "2.14.16 Rypsiöljy",
    "2.15.1 Safflorfrö": "2.15.1 Safflorinsiemenet",
    "2.15.2 Mjöl av delvis skalade safflorfrön": "2.15.2 Safflorirouhe osittain kuorituista siemenistä",
    "2.15.3 Safflorskal": "2.15.3 Safflorinsiementen kuoret",
    "2.16.1 Sesamfrön": "2.16.1 Seesaminsiemenet",
    "2.17.1 Delvis skalade sesamfrön": "2.17.1 Seesaminsiemenet, osittain kuoritut",
    "2.17.2 Sesamfröskal": "2.17.2 Seesaminsiementen kuoret",
    "2.17.3 Sesamfröexpeller": "2.17.3 Seesaminsiemenpuriste",
    "2.18.1 Rostad(e) soja(bönor)": "2.18.1 Paahdetut soija(pavut)",
    "2.18.2 Soja(böns)expeller": "2.18.2 Soija(papu)puriste",
    "2.18.3 Soja(böns)mjöl": "2.18.3 Soija(papu)rouhe",
    "2.18.4 Mjöl av skalad(e) soja(bönor)": "2.18.4 Soija(papu)rouhe kuorituista soijapavuista",
    "2.18.5 Soja(böns)skal": "2.18.5 Soijan (soijapavun) kuoret",
    "2.18.6 Extruderade sojabönor": "2.18.6 Soijapavut, ekstrudoitu",
    "2.18.7 Proteinkoncentrat av soja(böna)": "2.18.7 Soija(papu)valkuaiskonsentraatti",
    "2.18.8 Sojabönspressmassa; [Sojabönspasta]": "2.18.8 Soijapapupulppa; [Soijapaputahna]",
    "2.18.9 Sojabönsmelass": "2.18.9 Soija(papu)melassi",
    "2.18.10 Biprodukt från sojabönsberedning": "2.18.10 Soijapapujen valmistuksen sivutuote",
    "2.18.11 Soja(bönor)": "2.18.11 Soija(pavut)",
    "2.18.12 Sojabönor, flingor": "2.18.12 Soijapapuhiutaleet",
    "2.18.13 Foder av soja(böns)mjöl": "2.18.13 Soija(papu)rouherehu",
    "2.18.14 Fodermjöl av skalad(e) soja(bönor)": "2.18.14 Soija(papu)rouherehu kuorituista soijapavuista",
    "2.18.15 Fermenterat soja(böns) protein (koncentrat)": "2.18.15 Soija(papu)valkuais(konnsentraatti), fermentoitu",
    "2.18.16 Sojabönolja": "2.18.16 Soijaöljy",
    "2.19.1 Solrosfrön": "2.19.1 Auringonkukansiemenet",
    "2.19.2 Solrosfröexpeller": "2.19.2 Auringonkukkapuriste",
    "2.19.3 Solrosfrömjöl": "2.19.3 Auringonkukkarouhe",
    "2.19.4 Mjöl av skalade solrosfrön": "2.19.4 Auringonkukkarouhe kuorituista siemenistä",
    "2.19.5 Solrosfröskal": "2.19.5 Auringonkukansiementen kuoret",
    "2.19.6 Foder av solrosfrömjöl": "2.19.6 Auringonkukkarouherehu",
    "2.19.7 Fodermjöl av skalade solrosfrön": "2.19.7 Auringonkukkarouherehu kuorituista siemenistä",
    "2.19.8 Fraktioner av solrosfrömjöl med högt proteininnehåll och lågt cellulosainnehåll": "2.19.8 Auringonkukkarouheen runsaasti valkuaista ja vähän selluloosaa sisältävä jae",
    "2.19.9 Fraktioner av solrosfrömjöl med högt cellulosainnehåll": "2.19.9 Auringonkukkarouheen runsaasti selluloosaa sisältävä jae",
    "2.19.10 Solrosfröolja": "2.19.10 Auringonkukansiemenöljy",
    "2.20.1 Vegetabilisk olja och fett (växtarter som inte är kända)": "2.20.1 Kasviöljy ja -rasva (kasvilaji ei tiedossa)",
    "2.20.2 Använda vegetabiliska oljor från livsmedelsindustrin": "2.20.2 Elintarviketehtaiden käytetyt kasviöljyt",
    "2.21.1 Rålecitiner": "2.21.1 Raakalesitiinit",
    "2.22.1 Hampafrö": "2.22.1 Hampunsiemenet",
    "2.22.2 Hampaexpeller": "2.22.2 Hampunsiemenpuriste",
    "2.22.3 Hampaolja": "2.22.3 Hamppuöljy",
    "2.23.1 Vallmofrön": "2.23.1 Unikonsiemenet",
    "2.23.2 Vallmofrömjöl": "2.23.2 Unikonsiemenrouhe",
    "3. Frön från baljväxter och produkter därav (välj bland följande)": "3. Palkokasvien siemenet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "3.1.1 Bönor, rostade": "3.1.1 Paahdetut pavut",
    "3.1.2 Proteinkoncentrat av bönor": "3.1.2 Tiivistetty papuvalkuainen",
    "3.2.1 Johannesbrödfrukt": "3.2.1 Johanneksenleipäpuun palot",
    "3.2.3 Krossad johannesbrödfrukt": "3.2.3 Karkeaksi rouhitut johanneksenleipäpuun palot",
    "3.2.4 Mald johannesbrödfrukt": "3.2.4 Johanneksenleipäpuujauhe; [johanneksenleipäpuujauho]",
    "3.2.5 Johannesbrödsgroddar": "3.2.5 Johanneksenleipäpuun alkio",
    "3.2.6 Johannesbrödgroddexpeller": "3.2.6 Johanneksenleipäpuunalkiopuriste",
    "3.2.7 Johannesbrödfrön": "3.2.7 Johanneksenleipäpuun siemenet",
    "3.2.8 Skal från johannesbrödfrön": "3.2.8 Johanneksenleipäpuun siementen kuoret",
    "3.3.1 Kikärter": "3.3.1 Kahviherneet",
    "3.4.1 Ervum ervilia": "3.4.1 Linssivirvilä",
    "3.5.1 Frö av bockhornsklöver": "3.5.1 Sarviapilan siemenet",
    "3.6.1 Guarmjöl": "3.6.1 Guarkumirouhe",
    "3.6.2 Guargroddsmjöl": "3.6.2 Guarkuminalkiorouhe",
    "3.7.1 Åkerbönor": "3.7.1 Härkäpapu",
    "3.7.2 Åkerbönsflingor": "3.7.2 Härkäpapuhiutaleet",
    "3.7.3 Åkerbönsskal": "3.7.3 Härkäpavun kalvot; [Härkäpavun kuoret]",
    "3.7.4 Skalade åkerbönor": "3.7.4 Härkäpapu, kuorittu",
    "3.7.5 Åkerbönsprotein": "3.7.5 Härkäpapuvalkuainen",
    "3.8.1 Linser": "3.8.1 Linssit",
    "3.8.2 Linsskal": "3.8.2 Linssin kuoret",
    "3.9.1 Sötlupiner": "3.9.1 Makealupiini",
    "3.9.2 Skalade sötlupiner": "3.9.2 Makealupiini, kuorittu",
    "3.9.3 Filmlupiner; [Lupinskal]": "3.9.3 Lupiinin kalvot; [Lupiinin kuoret]",
    "3.9.4 Lupinpressmassa": "3.9.4 Lupiinipulppa",
    "3.9.5 Lupinfodermjöl": "3.9.5 Lupiinirehujauho",
    "3.9.6 Lupinprotein": "3.9.6 Lupiinivalkuainen",
    "3.9.7 Lupinproteinmjöl": "3.9.7 Lupiinivalkuaisjauho",
    "3.10.1 Mungbönor": "3.10.1 Mungopapu",
    "3.11.1 Ärter": "3.11.1 Herne",
    "3.11.2 Ärtkli": "3.11.2 Hernelese",
    "3.11.3 Ärtflingor": "3.11.3 Hernehiutaleet",
    "3.11.4 Ärtmjöl": "3.11.4 Hernejauho",
    "3.11.5 Ärtskal": "3.11.5 Herneen kuoret",
    "3.11.6 Skalade ärter": "3.11.6 Herne, kuorittu",
    "3.11.7 Ärtfodermjöl": "3.11.7 Hernerehujauho",
    "3.11.8 Sållrester av ärta": "3.11.8 Herneen seulontajakeet",
    "3.11.9 Ärtprotein": "3.11.9 Hernevalkuainen",
    "3.11.10 Ärtpressmassa": "3.11.10 Hernepulppa",
    "3.11.11 Vattenlösliga restprodukter av ärta": "3.11.11 Herneen liukoiset aineet",
    "3.11.12 Ärtfiber": "3.11.12 Hernekuitu",
    "3.12.1 Vicker": "3.12.1 Virnat",
    "3.13.1 Plattvial": "3.13.1 Peltonätkelmä",
    "3.14.1 Monanthavicker": "3.14.1 Yksikukkavirvilä",
    "4. Rotknölar, rötter och produkter därav (välj bland följande)": "4. Juurimukulat ja juurekset ja niistä saatavat tuotteet  (valitse alla olevista)",
    "4.1.1 Sockerbetor": "4.1.1 Sokerijuurikas",
    "4.1.2 Sockerbetstoppar och rötter": "4.1.2 Sokerijuurikkaan juuren niska ja juuren häntä",
    "4.1.3 (Bet)socker; [sackaros]": "4.1.3 (Juurikas)sokeri; [Sakkaroosi]",
    "4.1.4 Betmelass (av sockerbeta)": "4.1.4 (Sokeri)juurikasmelassi",
    "4.1.5 Betmelass (av sockerbeta), delvis avsockrad och/eller avbetainiserad": "4.1.5 Erotusmelassi",
    "4.1.6 Isomaltulosmelass": "4.1.6 Isomaltuloosimelassi",
    "4.1.7 Våt betmassa (av sockerbeta)": "4.1.7 Tuore (sokeri)juurikasleike",
    "4.1.8 Pressad betmassa (av sockerbeta)": "4.1.8 Puristettu (sokeri)juurikasleike",
    "4.1.9 Melasserad pressad betmassa (av sockerbeta)": "4.1.9 Puristettu (sokeri)juurikasleike, melassoitu",
    "4.1.10 Torkad betmassa (av sockerbeta)": "4.1.10 Kuivattu (sokeri)juurikasleike",
    "4.1.11 Melasserad torkad betmassa (av sockerbeta)": "4.1.11 Kuivattu (sokeri)juurikasleike, melassoitu",
    "4.1.12 Sockersirap": "4.1.12 Sokerisiirappi",
    "4.1.13 Kokta betbitar (av sockerbeta)": "4.1.13 (Sokeri)juurikkaan palat, keitetyt",
    "4.1.14 Fruktooligosackarider": "4.1.14 Frukto-oligosakkaridit",
    "4.1.15 Betmelass (av sockerbeta), rik på betain, flytande/torkad": "4.1.15 (Sokeri)juurikasmelassi, runsaasti betaiinia sisältävä, nestemäinen/kuivattu(1)",
    "4.1.16 Isomaltulos": "4.1.16 Isomaltuloosi",
    "4.2.1 Rödbetssaft": "4.2.1 Punajuurikasmehu",
    "4.3.1 Morötter": "4.3.1 Porkkana",
    "4.3.2 Ångkokta morotsskal": "4.3.2 Porkkanan kuoret, höyrytetyt",
    "4.3.3 Morotsavskrap": "4.3.3 Kaavitut porkkanat",
    "4.3.4 Morotsflingor": "4.3.4 Porkkanahiutaleet",
    "4.3.5 Torkad morot": "4.3.5 Porkkana, kuivattu",
    "4.3.6 Torkat morotsfoder": "4.3.6 Porkkanarehu, kuivattu",
    "4.3.7 Morotkross": "4.3.7 Porkkanarouhe",
    "4.4.1 Cikoriarot": "4.4.1 Sikurin juuret",
    "4.4.2 Cikoriatoppar och cikoriablast": "4.4.2 Sikurin juuren niska ja juuren häntä",
    "4.4.3 Cikoriafrö": "4.4.3 Sikurinsiemenet",
    "4.4.4 Cikoriapressmassa": "4.4.4 Puristettu sikuripulppa",
    "4.4.5 Torkad cikoriapressmassa": "4.4.5 Kuivattu sikuripulppa",
    "4.4.6 Cikoriarotspulver": "4.4.6 Sikurin juurijauhe",
    "4.4.7 Cikoriamelass": "4.4.7 Sikurimelassi",
    "4.4.8 Cikoriavinass": "4.4.8 Sikurivinassi",
    "4.4.9 Cikoriainulin": "4.4.9 Inuliini",
    "4.4.10 Oligofruktossirap": "4.4.10 Oligofruktoosisiirappi",
    "4.4.11 Torkad oligofruktos": "4.4.11 Oligofruktoosi, kuivattu",
    "4.5.1 Torkad vitlök": "4.5.1 Valkosipuli, kuivattu",
    "4.5.2 Finhakket vitlök": "4.5.2 Valkosipulihiutale",
    "4.5.4 Krossad vitlök": "4.5.4 Valkosipulirouhe",
    "4.6.1 Maniok; [tapioka]; [kassava]": "4.6.1 Maniokki [tapioka]; [kassava]",
    "4.6.2 Torkad maniok": "4.6.2 Maniokki, kuivattu",
    "4.6.3 Puffad maniokstärkelse": "4.6.3 Maniokkitärkkelys, paisutettu",
    "4.7.1 Lökpressmassa": "4.7.1 Sipulipulppa",
    "4.7.2 Stekt lök": "4.7.2 Sipuli, paistettu",
    "4.7.3 Vattenlösliga restprodukter av lök": "4.7.3 Sipulin liukoiset aineet, kuivatut",
    "4.8.1 Potatis": "4.8.1 Peruna",
    "4.8.2 Skalad potatis": "4.8.2 Peruna, kuorittu",
    "4.8.3 Ångkokta potatisskal": "4.8.3 Perunan kuoret, höyrytetyt",
    "4.8.4 Råa potatisbitar": "4.8.4 Perunaleikkeet, raa'at",
    "4.8.5 Potatisavskrap": "4.8.5 Perunankuorimassa",
    "4.8.6 Mosad potatis": "4.8.6 Peruna, soseutettu",
    "4.8.7 Potatisflingor": "4.8.7 Perunahiutaleet",
    "4.8.8 Potatispressmassa": "4.8.8 Perunarehu",
    "4.8.9 Torkad potatispressmassa": "4.8.9 Perunarehu, kuivattu",
    "4.8.10 Potatisprotein": "4.8.10 Perunavalkuainen",
    "4.8.11 Hydrolyserat potatisprotein": "4.8.11 Perunavalkuainen, hydrolysoitu",
    "4.8.12 Fermenterat potatisprotein": "4.8.12 Perunavalkuainen, fermentoitu",
    "4.8.13 Flytande fermenterat potatisprotein": "4.8.13 Nestemäinen perunavalkuainen, fermentoitu",
    "4.8.14 Koncentrerad potatissaft": "4.8.14 Tiivistetty perunamehu",
    "4.8.15 Potatisgranulat": "4.8.15 Perunarakeet",
    "4.9.1 Sötpotatis": "4.9.1 Bataatti",
    "4.10.1 Jordärtskocka": "4.10.1 Maa-artisokka; [Topinambur]",
    "5. Andra frön och frukter samt produkter därav (välj bland följande)": "5. Muut siemenet ja hedelmät ja niistä saatavat tuotteet  (valitse alla olevista)",
    "5.1.1 Ekollon": "5.1.1 Tammenterho",
    "5.1.2 Skalade ekollon": "5.1.2 Tammenterho, kuorittu",
    "5.2.1 Mandel": "5.2.1 Manteli",
    "5.2.2 Mandelskal": "5.2.2 Mantelinkuoret",
    "5.2.3 Mandelkärnor, expeller": "5.2.3 Mantelipuriste",
    "5.3.1 Anisfrö": "5.3.1 Aniksensiemenet",
    "5.4.1 Torkad äppelpressmassa; [Torkade äppelpressrester]": "5.4.1 Omenamassa, kuivattu; [Omenapulppa, kuivattu]",
    "5.4.2 Pressad äppelpressmassa; [Pressade äppelpressrester]": "5.4.2 Omenamassa, puristettu; [Omenapulppa, puristettu]",
    "5.4.3 Äppelmelass": "5.4.3 Omenamelassi",
    "5.5.1 Sockerbetsfrön": "5.5.1 Sokerijuurikkaan siemenet",
    "5.6.1 Bovete": "5.6.1 Tattari",
    "5.6.2 Boveteskal och bovetekli": "5.6.2 Tattarinkuoret ja tattarilese",
    "5.6.3 Bovetefodermjöl": "5.6.3 Tattarirehujauho",
    "5.7.1 Rödkålsfrö": "5.7.1 Punakaalinsiemenet",
    "5.8.1 Kanariegräsfrö": "5.8.1 Kanarianhelpin siemenet",
    "5.9.1 Kumminfrö": "5.9.1 Kuminansiemenet",
    "5.12.1 Krossrester av kastanj": "5.12.1 Rouhitut kastanjat",
    "5.13.1 Citruspressmassa ": "5.13.1 Sitruspulppa ( 1 )",
    "5.13.2 Torkad citronpressmassa ": "5.13.2 Sitruspulppa, kuivattu ( 1 )",
    "5.14.1 Rödklöverfrö": "5.14.1 Puna-apilansiemenet",
    "5.15.1 Vitklöverfrö": "5.15.1 Valkoapilansiemenet",
    "5.15.1 Kaffebönskal": "5.15.1 Kahvinkuoret",
    "5.16.1 Blåklintsfrö": "5.16.1 Ruiskaunokin siemenet",
    "5.17.1 Gurkfrö": "5.17.1 Kurkunsiemenet",
    "5.18.1 Cypressfrö": "5.18.1 Sypressinsiemenet",
    "5.19.1 Dadelfrukt": "5.19.1 Taateli",
    "5.19.2 Dadelfrö": "5.19.2 Taatelinsiemenet",
    "5.20.1 Fänkålsfrö": "5.20.1 Fenkolinsiemenet",
    "5.21.1 Fikonfrukt": "5.21.1 Viikuna",
    "5.22.1 Fruktkärnor ": "5.22.1 Hedelmän siemenet ( 2 )",
    "5.22.2 Fruktpressmassa ": "5.22.2 Hedelmäpulppa ( 2 )",
    "5.22.3 Torkad fruktpressmassa ": "5.22.3 Hedelmäpulppa, kuivattu ( 2 )",
    "5.23.1 Kryddkrasse": "5.23.1 Vihanneskrassi",
    "5.24.1 Gräsfrö": "5.24.1 Heinäkasvien siemenet",
    "5.25.1 Druvkärnor": "5.25.1 Viinirypäleiden siemenet",
    "5.25.2 Druvkärnmjöl": "5.25.2 Viinirypäleiden siemenrouhe",
    "5.25.3 Druvpressmassa": "5.25.3 Viinirypälepulppa [Viinirypäleiden puristusjäämä]",
    "5.25.4 Vattenlösliga restprodukter av druvkärnor": "5.25.4 Viinirypäleiden siementen liukoiset osat",
    "5.26.1 Hasselnöt": "5.26.1 Hasselpähkinä",
    "5.26.2 Hasselnötsexpeller": "5.26.2 Hasselpähkinäpuriste",
    "5.27.1 Pektiner": "5.27.1 Pektiini",
    "5.28.1 Perillafrö": "5.28.1 Perillansiemenet",
    "5.29.1 Pinjenöt": "5.29.1 Pinjansiemenet",
    "5.30.1 Pistaschmandel": "5.30.1 Pistaasipähkinä",
    "5.31.1 Plantagofrö": "5.31.1 Plantagonsiemenet",
    "5.32.1 Rädisfrö": "5.32.1 Retiisinsiemenet",
    "5.33.1 Spenatfrö": "5.33.1 Pinaatinsiemenet",
    "5.34.1 Tistelfrö": "5.34.1 Ohdakkeensiemenet",
    "5.35.1 Tomatpressmassa [tomatpressrester]": "5.35.1 Tomaattimassa [Tomaattipulppa]",
    "5.36.1 Röllikafrö": "5.36.1 Siankärsämön siemenet",
    "5.37.1 Aprikoskärnor, expeller": "5.37.1 Aprikoosipuriste",
    "5.38.1 Svart spiskumminexpeller": "5.38.1 Mustakuminapuriste",
    "5.39.1 Gurkörtsfröexpeller": "5.39.1 Purasruohopuriste",
    "5.40.1 Nattljusexpeller": "5.40.1 Helokkipuriste",
    "5.41.1 Granatäpplexpeller": "5.41.1 Granaattiomenapuriste",
    "5.42.1 Valnötskärnexpeller": "5.42.1 Saksanpähkinäpuriste",
    "6. Vallfoder, inbegripet grovfoder, och produkter därav (välj bland följande)": "6. Nurmi- ja karkearehut ja niistä saatavat tuotteet  (valitse alla olevista)",
    "6.1.1 Betblad": "6.1.1 Juurikkaannaatit",
    "6.2.1 Spannmålsväxter ": "6.2.1 Viljakasvit ( 1 )",
    "6.3.1 Spannmålshalm ": "6.3.1 Viljan oljet ( 1 )",
    "6.3.2 Behandlad spannmålshalm  ": "6.3.2 Käsitellyt viljan oljet ( 1 ) ( 2 )",
    "6.4.1 Klövermjöl": "6.4.1 Apilajauho",
    "6.5.1 Vallfodermjöl ; [Gräsmjöl] ; [Grönmjöl] ": "6.5.1.1 Nurmirehujauho ( 3 )",
    "6.5.1.2 Gräsmjöl": "6.5.1.2 Ruohojauho ( 3 )",
    "6.5.1.3 Grönmjöl": "6.5.1.3 Viherjauho ( 3 )",
    "6.6.1 Fälttorkat gräs, [hö]": "6.6.1 Pellolla kuivattu ruoho, [Heinä]",
    "6.6.2 Hetluftstorkat gräs": "6.6.2 Lämpökuivattu ruoho",
    "6.6.3 Gräs, örter, baljväxter, [grönt vallfoder]": "6.6.3 Ruoho, yrtit, palkokasvit, [vihreä nurmirehu]",
    "6.7.1 Hampamjöl": "6.7.1 Hamppujauho",
    "6.7.2 Hampafiber": "6.7.2 Hamppukuitu",
    "6.8.1 Åkerbönshalm": "6.8.1 Härkäpavun oljet",
    "6.9.1 Linhalm": "6.9.1 Pellavan oljet",
    "6.10.1 Lusern; [Alfalfa]": "6.10.1 Sinimailanen; [Alfalfa]",
    "6.10.2 Fälttorkad lusern; [Fälttorkad alfalfa]": "6.10.2 Pellolla kuivattu sinimailanen; [Pellolla kuivattu alfalfa]",
    "6.10.3 Hetluftstorkad lusern; [Hetluftstorkad alfalfa]": "6.10.3 Lämpökuivattu sinimailanen; [Lämpökuivattu alfalfa]",
    "6.10.4 Extruderad lusern; [Extruderad alfalfa]": "6.10.4 Sinimailanen, ekstrudoitu; [Alfalfa, ekstrudoitu]",
    "6.10.5 Lusernmjöl ; [alfalfamjöl] ": "6.10.5 Sinimailasjauho ( 4 ); [Alfalfajauho] ( 4 )",
    "6.10.6 Lusernpressrester; [Alfalfapressrester]": "6.10.6 Sinimailasen puristusmassa; [Alfalfan puristusmassa]",
    "6.10.7 Lusernproteinkoncentrat; [Alfalfaproteinkoncentrat]": "6.10.7 Tiivistetty sinimailasvalkuainen [Tiivistetty alfalfavalkuainen]",
    "6.10.8 Vattenlösliga restprodukter av lusern": "6.10.8 Sinimailasen liukoiset aineet",
    "6.11.1 Majsensilage": "6.11.1 Maissisäilörehu",
    "6.12.1 Ärthalm": "6.12.1 Herneen oljet",
    "6.13.1 Raps-/rybshalm": "6.13.1 Rapsin/rypsin oljet",
    "7. Andra växter, alger och produkter därav (välj bland följande)": "7. Muut kasvit, levät ja niistä saatavat tuotteet  (valitse alla olevista)",
    "7.1.1 Alger ": "7.1.1 Levät ( 1 )",
    "7.1.2 Torkade alger ": "7.1.2 Levät, kuivatut ( 1 )",
    "7.1.3 Algmjöl ": "7.1.3 Leväjauho ( 1 )",
    "7.1.4 Algolja ": "7.1.4 Leväöljy ( 1 )",
    "7.1.5 Algextrakt ; [Algfraktion] ": "7.1.5 Leväuute ( 1 ); [Leväjae] ( 1 )",
    "7.1.6 Tångmjöl": "7.1.6 Merileväjauho",
    "7.3.1 Bark ": "7.3.1 Puun kuoret ( 1 )",
    "7.4.1 Torkade blommor ": "7.4.1 Kukat ( 1 ), kuivatut",
    "7.5.1 Torkad broccoli": "7.5.1 Parsakaali, kuivattu",
    "7.6.1 Sockerrörsmelass": "7.6.1 (Sokeri)ruokomelassi",
    "7.6.2 Delvis avsockrad sockerrörsmelass": "7.6.2 (Sokeri)ruokomelassi, josta on poistettu sokeri osittain",
    "7.6.3 (Rör)socker [sackaros]": "7.6.3 Sokeri(ruoko) [sakkaroosi]",
    "7.6.4 Sockerrörsblast": "7.6.4 Sokeriruokomassa",
    "7.7.1 Torkade blad ": "7.7.1 Lehdet, kuivatut ( 1 )",
    "7.8.1 Lignocellulosa": "7.8.1 Lignoselluloosa",
    "7.8.2 Pulvercellulosa": "7.8.2 Selluloosajauhe",
    "7.9.1 Lakritsrot": "7.9.1 Lakritsijuuri",
    "7.10.1 Mynta": "7.10.1 Minttu",
    "7.11.1 Torkad spenat": "7.11.1 Pinaatti, kuivattu",
    "7.12.1 Yucca schidigera": "7.12.1 Mojaven jukkapalmu",
    "7.12.2 Saft från Yucca schidigera": "7.12.2 Yucca Schidigera -mehu",
    "7.13.1 Vegetabiliskt kol; [träkol]": "7.13.1 Kasvihiili; [puuhiili]",
    "7.14.1 Trä ": "7.14.1 Puu ( 1 )",
    "7.15.1 Mjöl av Solanum glaucophyllum": "7.15.1 Vahalehtisestä koisosta saatu jauho",
    "8. Mjölkprodukter och produkter därav (välj bland följande)": "8. Maitotuotteet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "8.1.1 Smör och smörprodukter": "8.1.1 Voi ja voituotteet",
    "8.2.1 Kärnmjölk/Kärnmjölkspulver ": "8.2.1 Piimä",
    "8.2.2 Kärnmjölkspulver": "8.2.2 Piimäjauhe",
    "8.3.1 Kasein": "8.3.1 Kaseiini",
    "8.4.1 Kaseinat": "8.4.1 Kaseinaatti",
    "8.5.1 Ost och ostprodukter": "8.5.1 Juusto ja juustotuotteet",
    "8.6.1 Råmjölk/Råmjölkspulver": "8.6.1 Ternimaito/Ternimaitojauhe",
    "8.7.1 Mejeribiprodukter": "8.7.1 Meijeriteollisuuden sivutuotteet",
    "8.8.1 Fermenterade mjölkprodukter": "8.8.1 Fermentoidut maitotuotteet",
    "8.9.1 Laktos": "8.9.1 Laktoosi",
    "8.10.1 Mjölk": "8.10.1 Maito",
    "8.10.2 Mjölkpulver": "8.10.2 Maitojauhe",
    "8.11.1 Skummjölk": "8.11.1 Rasvaton maito",
    "8.11.2 Skummjölkspulver": "8.11.2 Rasvaton maitojauhe",
    "8.12.1 Mjölkfett": "8.12.1 Maitorasva",
    "8.13.1 Mjölkproteinpulver": "8.13.1 Maitovalkuaisjauhe",
    "8.14.1 Kondenserad och avdunstad mjölk samt produkter därav": "8.14.1 Tiivistetty ja haihdutustiivistetty maito ja siitä saatavat tuotteet",
    "8.15.1 Mjölkpermeat/Mjölkpermeatpulver ": "8.15.1 Maitopermeaatti/Maitopermeaattijauhe ( 1 )",
    "8.16.1 Mjölkretentat/Mjölkretentatpulver ": "8.16.1 Maitoretentaatti/Maitoretentaattijauhe ( 1 )",
    "8.17.1 Vassle": "8.17.1 Hera",
    "8.17.2 Vasslepulver ": "8.17.2 Herajauhe",
    "8.18.1 Laktosreducerad vassle/ Laktosreducerat vasslepulver ": "8.18.1 Laktoositon hera",
    "8.19.1 Vassleprotein": "8.19.1 Heravalkuainen",
    "8.19.2 Vassleproteinpulver": "8.19.2 Heravalkuaisjauhe",
    "8.20.1 Demineraliserad, laktosreducerad vassle/Demineraliserad, laktosreducerat vasslepulver ": "8.20.1 Demineralisoitu, laktoositon hera / Demineralisoitu, laktoositon herajauhe ( 1 )",
    "8.21.1 Vasslepermeat/Vasslepermeatpulver ": "8.21.1 Herapermeaatti/Herapermeaattijauhe ( 1 )",
    "8.22.1 Vassleretentat/Vassleretentatpulver ": "8.22.1 Heraretentaatti/Heraretentaattijauhe ( 1 )",
    "9. Produkter av landdjur och produkter därav (välj bland följande)": "9. Maaeläintuotteet ja niistä edelleen saatavat tuotteet  (valitse alla olevista)",
    "9.1.1 Hela eller delar av varmblodiga landdjur, färska (animaliska biprodukter)": "9.1.1 Maaeläimen ruho tai ruhonosa tuoreena (eläinperäiset sivutuotteet)",
    "9.1.2 Hela eller delar av varmblodiga landdjur, syrbehandlade (animaliska biprodukter)": "9.1.2 Maaeläimen ruho tai ruhonosa hapotettuna (eläinperäiset sivutuotteet)",
    "9.2.1 Djurfett ": "9.2.1 Maaeläinten rasvasta koostuva tuote (1)",
    "9.13.1 Fettgrevar ": "9.13.1 Prosessoitu eläinrasva",
    "9.3.1 Biprodukter från biodling": "9.3.1 Mehiläistenhoidon sivutuotteet",
    "9.4.1.1 Bearbetat animaliskt protein (PAP), svin, kategori 3": "9.4.1.1 Käsitelty eläinvalkuainen (PAP), sika, luokka 3",
    "9.4.1.2 Bearbetat animaliskt protein (PAP), fjäderfä, kategori 3": "9.4.1.2 Käsitelty eläinvalkuainen (PAP), siipikarja, luokka 3",
    "9.4.1.3 Bearbetat animaliskt protein (PAP), insekt, kategori 3": "9.4.1.3 Käsitelty eläinvalkuainen (PAP), hyönteinen, luokka 3",
    "9.4.1.4 Bearbetat animaliskt protein (PAP), idisslare, kategori 3": "9.4.1.4 Käsitelty eläinvalkuainen (PAP), märehtijä, luokka 3",
    "9.4.1.5 Bearbetat animaliskt protein (PAP), andra, kategori 3": "9.4.1.5 Käsitelty eläinvalkuainen (PAP), muu, luokka 3",
    "9.4.2 Köttbenmjöl - Tillverkad av biprodukter från kategori 2": "9.4.2 Lihaluujauho (1) - valmistettu luokan 2 sivutuotteista",
    "9.5.1 Proteiner från gelatinframställning ": "9.5.1 Gelatiinin valmistuksessa saadut valkuaiset ( 2 )",
    "9.6.1 Hydrolyserade animaliska proteiner ": "9.6.1 Hydrolysoidut eläinvalkuaiset ( 1 )",
    "9.7.1 Blodmjöl ": "9.7.1 Verijauho ( 1 )",
    "9.8.1 Blodprodukter": "9.8.1 Verituotteet ( 1 )",
    "9.9.1 Återanvänt köks- och matavfall [återvunnet köks- och matavfall]": "9.9.1 Ruokajäte [Ruoan uusiokäyttö]",
    "9.10.1 Kollagen ": "9.10.1 Kollageeni",
    "9.11.1 Fjädermjöl": "9.11.1 Höyhenjauho",
    "9.12.1 Gelatin ": "9.12.1 Gelatiini ( 1 )",
    "9.14.1 Produkter av animaliskt ursprung ": "9.14.1 Entiset eläinperäiset elintarvikkeet",
    "9.15.1 Ägg": "9.15.1 Kananmunat",
    "9.15.2 Äggvita": "9.15.2 Munanvalkuainen",
    "9.15.3 Torkade äggprodukter": "9.15.3 Kuivatut munatuotteet",
    "9.15.4 Sockrat äggpulver": "9.15.4 Sokeroitu munajauhe",
    "9.15.5 Torkat äggskal": "9.15.5 Kuivatut munankuoret",
    "9.16.1 Levande ryggradslösa landdjur ": "9.16.1 Elävät maaselkärangattomat ( 1 )",
    "9.16.2 Döda ryggradslösa landdjur ": "9.16.2 Kuolleet maaselkärangattomat ( 1 )",
    "10. Fisk, andra vattenlevande djur och produkter framställda därav (välj bland följande)": "10. Kalat, muut vesieläimet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "10.1.1 Vattenlevande ryggradslösa djur ": "10.1.1 Vedessä elävät selkärangattomat ( 1 )",
    "10.2.1 Biprodukter från vattenlevande djur från livsmedelslokaler": "10.2.1 Vesieläimistä saatavat sivutuotteet elintarvikehuoneistoista",
    "10.3.1 Skaldjursmjöl ": "10.3.1 Äyriäisjauho ( 2 )",
    "10.4.1 Fisk; färsk eller fryst": "10.4.1.1 Kokonaiset kalat tai niiden osat tuoreena",
    "10.4.1 Fisk; syrbehandlad": "10.4.1.4 Kokonaiset kalat tai niiden osat happokäsiteltynä",
    "10.4.2 Fiskmjöl ": "10.4.2 Kalajauho ( 2 )",
    "10.4.3 Vattenlösliga restprodukter av fisk": "10.4.3 Kalaliimavesi",
    "10.4.4 Hydrolysat av fiskprotein": "10.4.4 Kalavalkuainen, hydrolysoitu",
    "10.4.5 Fiskbensmjöl": "10.4.5 Kalanruotojauho",
    "10.4.6 Fiskolja": "10.4.6 Kalaöljy",
    "10.4.7 Hydrogenerad fiskolja": "10.4.7 Kalaöljy, hydrattu",
    "10.4.8 Fiskoljestearin [kylseparerad fiskolja]": "10.4.8 Kalaöljysteariini [jäähdytetty kalaöljy]",
    "10.5.1 Krillolja": "10.5.1 Krilliöljy",
    "10.5.2 Hydrolysat av krillproteinkoncentrat": "10.5.2 Tiivistetty krillivalkuainen, hydrolysoitu",
    "10.6.1 Ringmaskmjöl": "10.6.1 Merimadoista tehty jauho",
    "10.7.1 Mjöl av marina zooplankton": "10.7.1 Merieläinplanktonista tehty jauho",
    "10.7.2 Zooplanktonolja": "10.7.2 Merieläinplanktonista saatu öljy",
    "10.8.1 Blötdjursmjöl": "10.8.1 Nilviäisjauho",
    "10.9.1 Bläckfiskmjöl": "10.9.1 Kalmarijauho",
    "10.10.1 Sjöstjärnemjöl": "10.10.1 Meritähtijauho",
    "11. Mineraler och produkter därav (välj bland följande)": "11. Kivennäiset ja niistä saatavat tuotteet  (valitse alla olevista)",
    "11.1.1 Kalciumkarbonat ; [Kalksten]": "11.1.1 Kalsiumkarbonaatti ( 1 ); [Ruokintakalkki]",
    "11.1.2 Kalkhaltiga marina skal": "11.1.2 Kalkkipitoiset merieläinten kuoret",
    "11.1.3 Kalcium- och magnesiumkarbonat": "11.1.3 Kalsium- ja magnesiumkarbonaattien seos",
    "11.1.4 Maerl": "11.1.4 Maerl",
    "11.1.5 Lithothamnium": "11.1.5 Lithothamn",
    "11.1.6 Kalciumklorid": "11.1.6 Kalsiumkloridi",
    "11.1.7 Kalciumhydroxid": "11.1.7 Kalsiumhydroksidi",
    "11.1.8 Vattenfritt kalciumsulfat": "11.1.8 Kalsiumsulfaatti, vedetön",
    "11.1.9 Kalciumsulfathemihydrat": "11.1.9 Kalsiumsulfaattihemihydraatti",
    "11.1.10 Kalciumsulfatdihydrat": "11.1.10 Kalsiumsulfaattidihydraatti",
    "11.1.11 Kalciumsalter av organiska syror ": "11.1.11 Orgaanisten happojen kalsiumsuolat ( 2 )",
    "11.1.12 Kalciumoxid": "11.1.12 Kalsiumoksidi",
    "11.1.13 Kalciumglukonat": "11.1.13 Kalsiumglukonaatti",
    "11.1.15 Kalciumsulfat/Kalciumkarbonat": "11.1.15 Kalsiumsulfaatti/-karbonaatti",
    "11.1.16 Kalciumpidolat": "11.1.16 Kalsiumpidolaatti",
    "11.1.17 Kalciumkarbonat-magnesium oxid": "11.1.17 Kalsiumkarbonaattimagnesiumoksidi",
    "11.2.1 Magnesiumoxid": "11.2.1 Magnesiumoksidi",
    "11.2.2 Magnesiumsulfatheptahydrat": "11.2.2 Magnesiumsulfaattiheptahydraatti",
    "11.2.3 Magnesiumsulfatmonohydrat": "11.2.3 Magnesiumsulfaattimonohydraatti",
    "11.2.4 Vattenfritt magnesiumsulfat": "11.2.4 Magnesiumsulfaatti, vedetön",
    "11.2.5 Magnesiumpropionat": "11.2.5 Magnesiumpropionaatti",
    "11.2.6 Magnesiumklorid": "11.2.6 Magnesiumkloridi",
    "11.2.7 Magnesiumkarbonat": "11.2.7 Magnesiumkarbonaatti",
    "11.2.8 Magnesiumhydroxid": "11.2.8 Magnesiumhydroksidi",
    "11.2.9 Kaliummagnesiumsulfat": "11.2.9 Kaliummagnesiumsulfaatti",
    "11.2.10 Magnesiumsalter av organiska syror ": "11.2.10 Orgaanisten happojen magnesiumsuolat ( 2 )",
    "11.2.11 Magnesiumglukonat": "11.2.11 Magnesiumglukonaatti",
    "11.2.13 Magnesiumpidolat": "11.2.13 Magnesiumpidolaatti",
    "11.3.1 Dikalciumfosfat  ; [Kalciumväteortofosfat]": "11.3.1 Dikalsiumfosfaatti ( 3 ) [Kalsiumvetyortofosfaatti]",
    "11.3.2 Monodikalciumfosfat": "11.3.2 Monodikalsiumfosfaatti",
    "11.3.3 Monokalciumfosfat [Kalciumtetravätediortofosfat]": "11.3.3 Monokalsiumfosfaatti; [Kalsiumtetravetydiortofosfaatti]",
    "11.3.4 Trikalciumfosfat ; [Trikalciumortofosfat]": "11.3.4 Trikalsiumfosfaatti; [Trikalsiumortofosfaatti]",
    "11.3.5 Kalciummagnesiumfosfat": "11.3.5 Kalsiummagnesiumfosfaatti",
    "11.3.6 Avfluorerat fosfat": "11.3.6 Defluorinoitu fosfaatti",
    "11.3.7 Dikalciumpyrofosfat; [Dikalciumdifosfat]": "11.3.7 Dikalsiumpyrofosfaatti; [Dikalsiumdifosfaatti]",
    "11.3.8 Magnesiumfosfat": "11.3.8 Magnesiumfosfaatti",
    "11.3.9 Natriumkalciummagnesiumfosfat": "11.3.9 Natriumkalsiummagnesiumfosfaatti",
    "11.3.10 Mononatriumfosfat; [Natriumdiväteortofosfat]": "11.3.10 Mononatriumfosfaatti; [Natriumdivetyortofosfaatti]",
    "11.3.11 Dinatriumfosfat; [Dinatriumväteortofosfat]": "11.3.11 Dinatriumfosfaatti; [Dinatriumvetyortofosfaatti]",
    "11.3.12 Trinatriumfosfat; [Trinatriumortofosfat]": "11.3.12 Trinatriumfosfaatti; [Trinatriumortofosfaatti]",
    "11.3.13 Natriumpyrofosfat; [Tetranatriumdifosfat]": "11.3.13 Natriumpyrofosfaatti; [Tetranatriumdifosfaatti]",
    "11.3.14 Monokaliumfosfat; [Kaliumdiväteortofosfat]": "11.3.14 Monokaliumfosfaatti; [Kaliumdivetyortofosfaatti]",
    "11.3.15 Dikaliumfosfat; [Dikaliumväteortofosfat]": "11.3.15 Dikaliumfosfaatti; [Dikaliumvetyortofosfaatti]",
    "11.3.16 Kalciumnatriumfosfat": "11.3.16 Kalsiumnatriumfosfaatti",
    "11.3.17 Monoammoniumfosfat; [Ammoniumdiväteortofosfat]": "11.3.17 Monoammoniumfosfaatti; [Ammoniumdivetyortofosfaatti]",
    "11.3.18 Diammoniumfosfat; [Diammoniumväteortofosfat]": "11.3.18 Diammoniumfosfaatti; [Diammoniumvetyortofosfaatti]",
    "11.3.19 Natriumtripolyfosfat; [Pentanatriumtrifosfat]": "11.3.19 Natriumtripolyfosfaatti; [Pentanatriumtrifosfaatti]",
    "11.3.20 Natriummagnesiumfosfat": "11.3.20 Natriummagnesiumfosfaatti",
    "11.3.21 Magnesiumhypofosfit": "11.3.21 Magnesiumhypofosfiitti",
    "11.3.22 Benmjöl, fritt från limämne": "11.3.22 Degelatinisoitu luujauho",
    "11.3.23 Benaska": "11.3.23 Luutuhka",
    "11.3.24 Kalciumpolyfosfat": "11.3.24 Kalsiumpolyfosfaatti",
    "11.3.25 Kalciumdivätedifosfat": "11.3.25 Kalsiumdivetydifosfaatti",
    "11.3.26 Magnesiumdivätepyrofosfat": "11.3.26 Magnesiumpyrofosfaatti, hapan",
    "11.3.27 Dinatriumdivätedifosfat": "11.3.27 Dinatriumdivetydifosfaatti",
    "11.3.28 Trinatriumdifosfat": "11.3.28 Trinatriumdifosfaatti",
    "11.3.29 Natriumpolyfosfat; [Natriumhexametafosfat]": "11.3.29 Natriumpolyfosfaatti; [Natriumheksametafosfaatti]",
    "11.3.30 Trikaliumfosfat": "11.3.30 Trikaliumfosfaatti",
    "11.3.31 Tetrakaliumdifosfat": "11.3.31 Tetrakaliumdifosfaatti",
    "11.3.32 Pentakaliumtrifosfat": "11.3.32 Pentakaliumtrifosfaatti",
    "11.3.33 Kaliumpolyfosfat": "11.3.33 Kaliumpolyfosfaatti",
    "11.3.34 Kalciumnatriumpolyfosfat": "11.3.34 Kalsiumnatriumpolyfosfaatti",
    "11.4.1 Natriumklorid ": "11.4.1 Natriumkloridi ( 1 )",
    "11.4.2 Natriumbikarbonat [Natriumvätekarbonat]": "11.4.2 Natriumbikarbonaatti [Natriumvetykarbonaatti]",
    "11.4.3 Natrium/ammonium(bi)karbonat [Natrium/ammonium(väte)karbonat]": "11.4.3 Natrium/ammonium(bi)karbonaatti [Natrium/ammonium(vety)karbonaatti]",
    "11.4.4 Natriumkarbonat": "11.4.4 Natriumkarbonaatti",
    "11.4.5 Natriumseskvikarbonat [Trinatriumvätedikarbonat]": "11.4.5 Natriumseskvikarbonaatti [Trinatriumvetydikarbonaatti]",
    "11.4.6 Natriumsulfat": "11.4.6 Natriumsulfaatti",
    "11.4.7 Natriumsalter av organiska syror. ": "11.4.7 Orgaanisten happojen natriumsuolat ( 2 )",
    "11.5.1 Kaliumklorid": "11.5.1 Kaliumkloridi",
    "11.5.2 Kaliumsulfat": "11.5.2 Kaliumsulfaatti",
    "11.5.3 Kaliumkarbonat": "11.5.3 Kaliumkarbonaatti",
    "11.5.4 Kaliumbikarbonat [Kaliumvätekarbonat]": "11.5.4 Kaliumbikarbonaatti [Kaliumvetykarbonaatti]",
    "11.5.5 Kaliumsalter av organiska syror. ": "11.5.5 Orgaanisten happojen kaliumsuolat ( 2 )",
    "11.5.6 Kaliumpidolat": "11.5.6 Kaliumpidolaatti",
    "11.6.1 Svavelblomma": "11.6.1 Rikkikukka",
    "11.7.1 Attapulgit": "11.7.1 Attapulgiitti",
    "11.7.2 Kvarts": "11.7.2 Kvartsi",
    "11.7.3 Kristobalit": "11.7.3 Kristobaliitti",
    "11.8.1 Ammoniumsulfat": "11.8.1 Ammoniumsulfaatti",
    "11.8.3 Ammoniumsalter av organiska syror. ": "11.8.3 Orgaanisten happojen ammoniumsuolat ( 2 )",
    "11.8.4 Ammoniumlaktat": "11.8.4 Ammoniumlaktaatti",
    "11.8.5 Ammoniumacetat": "11.8.5 Ammoniumasetaatti",
    "11.9.1 Flintgrus [för matsmältning]": "11.9.1 Piikiviryyni",
    "11.9.2 Rödsten [för matsmältning]": "11.9.2 Punakivi (Redstone)",
    "12. Fermenteringsbiprodukter från mikroorganismer (välj bland följande)": "12. Mikro-organismeista saatavat käymisen (sivu)tuotteet  (valitse alla olevista)",
    "12.1.1 Produkt från proteinrika Methylophilus methylotrophus": "12.1.1 Methylophilus methylotrophus -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.2 Produkt från proteinrika Methylococcus capsulatus (Bath), Alca ligenes acidovorans, Bacillus brevis och Bacillus firmus": "12.1.2 Bakteereista Methylococcus capsulatus (Bath), Alca ligenes acidovorans, Bacillus brevis ja Bacillus firmus saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.3 Produkt från proteinrika Escherichia coli": "12.1.3 Escherichia coli -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.4 Produkt från proteinrika Corynebacterium glutamicum": "12.1.4 Corynebacterium glutamicum -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.5 Jäst [bryggerijäst]": "12.1.5 Hiivat [panimohiiva](1)(2)",
    "12.1.6 Myceliumensilage från framställning av penicillin": "12.1.6 Penisilliinin valmistuksesta saatava myseelisäilörehu(1)(2)",
    "12.1.7 Jäst från biodieselframställning": "12.1.7 Hiivat biodieselin valmistuksesta(1)(2)",
    "12.1.8 Produkt från proteinrika arter av Lactobacillus": "12.1.8 Lactobacillus -lajista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.9 Produkt från proteinrika Trichoderma viride": "12.1.9 Trichoderma viride -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.10 Produkt från proteinrika Bacillus subtilis": "12.1.10 Bacillus subtilis -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.11 Produkt från proteinrika Aspergillus oryzae": "12.1.11 Aspergillus oryzae -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.12 Jästprodukter": "12.1.12 Hiivatuotteet(1)(2)",
    "12.2.1 Vinass [kondenserad vattenlöslig restprodukt av melass]": "12.2.1 Vinassi [tiivistetty liukoinen melassi](2)(5)",
    "12.2.2 Biprodukter från framställningen av L-glutaminsyra": "12.2.2 L-glutamiinihapon valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.3 Biprodukter från framställning av L-lysinmonohydroklorid med Brevibacterium lactofermentum": "12.2.3 Brevibacterium lactofermentum -bakteerin tuottaman L-lysiinimonohydrokloridin valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.4 Biprodukter från framställningen av aminosyror med Corynebacterium glutamicum": "12.2.4 Corynebacterium glutamicum -bakteerin tuottamien aminohappojen valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.5 Biprodukter från framställningen av aminosyror med Escherichia coli K12": "12.2.5 Escherichia coli K12 -bakteerin tuottamien aminohappojen valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.6 Biprodukt av enzymframställning med Aspergillus niger": "12.2.6 Aspergillus niger -organismin entsyymituotannon sivutuotteet(2)(5)",
    "12.2.7 Polyhydroxybutyrat från fermentering med Ralstonia eutropha": "12.2.7 Ralstonia eutropha -bakteerin käymisreaktiolla saatava polyhydroksibutyraatti(2)",
    "13. Övrigt (välj bland följande)": "13. Muut  (valitse alla olevista)",
    "13.1.1 Produkter från bageri- och pastaindustrin": "13.1.1 Leipomo- ja pastateollisuuden tuotteet",
    "13.1.2 Produkter från konditorivaruindustrin": "13.1.2 Leivonnaisteollisuuden tuotteet",
    "13.1.3 Produkter från framställning av frukostflingor": "13.1.3 Aamiaismurojen valmistuksessa saatavat tuotteet",
    "13.1.4 Produkter från konfektyrindustrin": "13.1.4 Makeisteollisuuden tuotteet",
    "13.1.5 Produkter från glassindustrin": "13.1.5 Jäätelöteollisuuden tuotteet",
    "13.1.6 Produkter och biprodukter från bearbetning av färska frukter och grönsaker ": "13.1.6 Tuoreiden hedelmien ja vihannesten käsittelyssä saatavat tuotteet ja sivutuotteet ( 1 )",
    "13.1.7 Produkter från bearbetning av växter": "13.1.7 Kasvien käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.8 Produkter från bearbetning av kryddor och smaksättningsämnen ": "13.1.8 Mausteiden ja maustevalmisteiden käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.9 Produkter från bearbetning av örter ": "13.1.9 Yrttien käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.10 Produkter från potatisförädlingsindustrin": "13.1.10 Perunateollisuuden tuotteet",
    "13.1.11 Produkter och biprodukter av såsframställning": "13.1.11 Kastikkeiden valmistuksessa saatavat tuotteet ja sivutuotteet",
    "13.1.12 Produkter och biprodukter från snacksindustrin": "13.1.12 Suolaisten välipalojen valmistuksessa saatavat tuotteet ja sivutuotteet",
    "13.1.13 Produkter från färdigmatsindustrin": "13.1.13 Sellaisenaan syötävien elintarvikkeiden valmistuksessa saatavat tuotteet",
    "13.1.14 Växtbiprodukter från spritframställning": "13.1.14 Väkevien juomien valmistuksessa saatavat kasvisivutuotteet",
    "13.1.15 Foderöl": "13.1.15 Rehuolut",
    "13.1.16 Söta drycker": "13.1.16 Makeutetut juomat",
    "13.1.17 Fruktsirap": "13.1.17 Hedelmäsiirappi",
    "13.1.18 Söt sirap": "13.1.18 Makeutettu siirappi",
    "13.2.1 Karamelliserat socker": "13.2.1 Karamellisokeri",
    "13.2.2 Dextros": "13.2.2 Dekstroosi",
    "13.2.3 Fruktos": "13.2.3 Fruktoosi",
    "13.2.4 Glukossirap": "13.2.4 Glukoosisiirappi",
    "13.2.5 Glukosmelass": "13.2.5 Glukoosimelassi",
    "13.2.6 Xylos": "13.2.6 Ksyloosi",
    "13.2.7 Laktulos": "13.2.7 Laktuloosi",
    "13.2.8 Glukosamin (kitosamin)": "13.2.8 Glukosamiini (Kitosamiini)(6)",
    "13.2.9 Xylo-oligosackarid": "13.2.9 Ksylo-oligosakkaridi",
    "13.2.10 Gluko-oligosackarid": "13.2.10 Gluko-oligosakkaridi",
    "13.3.1 Stärkelse ": "13.3.1 Tärkkelys ( 2 )",
    "13.3.2 Förklistrad stärkelse ": "13.3.2 Esigelatinoitu tärkkelys ( 2 )",
    "13.3.3 Stärkelseblandning ": "13.3.3 Tärkkelysseos ( 2 )",
    "13.3.4 Stärkelsehydrolysatkaka ": "13.3.4 Tärkkelyshydrolysaattikakku ( 2 )",
    "13.3.5 Dextrin": "13.3.5 Dekstriini",
    "13.3.6 Maltodextrin": "13.3.6 Maltodekstriini",
    "13.4.1 Polydextros": "13.4.1 Polydekstroosi",
    "13.5.1 Polyoler": "13.5.1 Polyolit",
    "13.5.2 Isomalt": "13.5.2 Isomaltitoli",
    "13.5.3 Mannitol": "13.5.3 Mannitoli",
    "13.5.4 Xylitol": "13.5.4 Ksylitoli",
    "13.5.5 Sorbitol": "13.5.5 Sorbitoli",
    "13.6.1 Sur olja från kemisk raffinering ": "13.6.1 Kemiallisesta puhdistuksesta saatavat happamat öljyt ( 3 )",
    "13.6.2 Fettsyror förestrade med glycerol ": "13.6.2 Glyserolin rasvahappoesterit ( 4 )",
    "13.6.3 Mono-, di- och triglycerider av fettsyror ": "13.6.3 Rasvahappojen mono-, di- ja triglyseridit ( 4 )",
    "13.6.4 Salter av fettsyror ": "13.6.4 Rasvahappojen suolat ( 4 )",
    "13.6.5 Fettsyredestillat från fysisk raffinering ": "13.6.5 Fysikaalisesta puhdistuksesta saatavat rasvahappotisleet ( 3 )",
    "13.6.6 Obearbetade fettsyror från spaltning ": "13.6.6 Pilkkomisesta saatavat raa'at rasvahapot ( 3 )",
    "13.6.7 Rena destillerade fettsyror från spaltning ": "13.6.7 Pilkkomisesta saatavat puhtaat, tislatut rasvahapot ( 3 )",
    "13.6.8 Soap stocks ": "13.6.8 Saippuointisaostuma ( 3 )",
    "13.6.9 Mono- och diglycerider av fettsyror förestrade med organiska syror  ": "13.6.9 Orgaanisilla hapoilla esteröityjen rasvahappojen mono- ja diglyseridit ( 4 ) ( 5 )",
    "13.6.10 Sackarosestrar av fettsyror ": "13.6.10 Rasvahappojen sakkaroosiesterit ( 4 )",
    "13.6.11 Sackarosglycerider av fettsyror ": "13.6.11 Rasvahappojen sokeriglyseridit ( 4 )",
    "13.6.12 Palmitoylglukosamin": "13.6.12 Palmitoyyliglukosamiini",
    "13.6.13 Salt från laktylat i fettsyror": "13.6.13 Rasvahappojen laktylaattien suola",
    "13.8.1 Rå glycerin [rå glycerol]": "13.8.1 Glyseriini, raaka [Glyseroli, raaka]",
    "13.8.2 Glycerin [glycerol]": "13.8.2 Glyseriini [Glyseroli]",
    "13.9.1 Metylsulfonylmetan": "13.9.1 Metyylisulfonyylimetaani",
    "13.10.1 Torv": "13.10.1 Turve",
    "13.10.2 Leonardit": "13.10.2 Leonardiitti",
    "13.11.1 Propylenglykol; [1,2-propandiol; [propan-1,2-diol]": "13.11.1 Propyleeniglykoli; [1,2-propaanidioli; propaani-1,2-dioli]",
    "13.11.2 Monoestrar av propylenglykol och fettsyror ": "13.11.2 Propyleeniglykolin ja rasvahappojen monoesterit ( 4 )",
    "13.12.1 Hyaluronsyra": "13.12.1 Hyaluronihappo",
    "13.12.2 Kondroitinsulfat": "13.12.2 Kondroitiinisulfaatti",
    "13.12.3 Glukonsyra": "13.12.3 Glukonihappo",
    "14.0.0 Foderråvaran finns inte i förteckningen": "14.0.0 Rehuaine puuttuu luettelosta",
    "1a_Konserveringsmedel": "1a_Säilöntäaine",
    "1b_Antioxidant": "1b_Hapettumisenestoaine",
    "1c_Emulgeringsmedel": "1c_Emulgointiaine",
    "1d_Stabiliseringsmedel": "1d_Stabilointiaine",
    "1e_Förtjockningsmedel": "1e_Sakeuttamisaine",
    "1f_Geleringsmedel": "1f_Hyytelöitymisaine",
    "1g_Bindemedel": "1g_Sidonta-aine",
    "1h_Ämne för kontroll av kontamination av radionuklider": "1h_Radionuklidikontaminaatiota estävä aine",
    "1i_Klumpförebyggande medel": "1i_Paakkuuntumisenestoaine",
    "1j_pH-regulator": "1j_Happamuuden säätöaine",
    "1k_Ensileringstillsatser: ämnen, enzymer och mikroorganismer inbegripna, avsedda att ingå i foder för att förbättra": "1k_Säilörehun lisäaine (myös entsyymit mikro-organismit, jotka on tarkoitettu lisättäväksi rehuun säilörehun parantamiseksi)",
    "1l_Denatureringsmedel": "1l_Denaturointiaine",
    "1m_Ämne avsett att minska mykotoxinkontaminationen i foder": "1m_Mykotoksiinikontaminaatiota vähentävä aine",
    "2a_Färgämne": "2a_Väriaine",
    "2b_Aromämne": "2b_Aromiaine",
    "3a_Vitamin, provitamin eller kemiskt väldefinierat ämne med likartad effekt": "3a_Vitamiini, provitamiini tai kemiallisesti tarkkaan määritellyt aine, jolla samankaltainen vaikutus",
    "3b_01_Järn (Fe) -förening": "3b_01_Rauta (Fe) -yhdiste",
    "3b_02_Jod (J) -förening": "3b_02_Jodi (J) -yhdiste",
    "3b_03_Kobolt (Co) -förening": "3b_03_Koboltti (Co) -yhdiste",
    "3b_04_Koppar (Cu) -förening": "3b_04_Kupari (Cu) -yhdiste",
    "3b_05_Mangan (Mn) -förening": "3b_05_Mangaani (Mn) -yhdiste",
    "3b_06_Zink (Zn) -förening": "3b_06_Sinkki (Zn) -yhdiste",
    "3b_07_Molybden (Mo) -förening": "3b_07_Molybdeeni (Mo) -yhdiste",
    "3b_08_Selen (Se) -förening": "3b_08_Seleeni (Se) -yhdiste",
    "3c_01_Metionin": "3c_01_Metioniini",
    "3c_02_Lysin": "3c_02_Lysiini",
    "3c_03_Treonin": "3c_03_Treoniini",
    "3c_04_Tryptofan": "3c_04_Tryptofaani",
    "3c_05_Hydroxanaloger av metionin": "3c_05_Metioniinin hydroksianalogit",
    "3c_06_Arginin": "3c_06_Arginiini",
    "3c_07_Valin": "3c_07_Valiini",
    "3c_08_Glutamin": "3c_08_Glutamiini",
    "3c_09_Cystin": "3c_09_Kystiini",
    "3c_10_Leucin": "3c_10_Leusiini",
    "3c_11_Isoleucin": "3c_11_Isoleusiini",
    "3c_12_Histidin": "3c_12_Histidiini",
    "3c_13_Tyrosin": "3c_13_Tyrosiini",
    "3d_Urinämne och derivat därav": "3d_Urea tai sen johdannainen",
    "4a_Smältbarhetsförbättrande medel": "4a_Ruuan sulatusta edistävä aine",
    "4b_Medel som stabiliserar tarmfloran": "4b_Suolistoflooran stabiloimiseen tarkoitettu aine",
    "4c_Ämne med positiv inverkan på miljön": "4c_Ympäristöön suotuisasti vaikuttava aine",
    "4d_Anna zooteknisk tillsats": "4d_Muu eläintuotantoon vaikuttava aine",
    "5_Koccidiostatika och histomonostatika": "5_Kokkidiostaatti tai histomostaatti",
    "01_01_ Förblandning till foderblandningar": "01_01_Esiseos rehuseoksiin",
    "02_01_Förblandning gjort av ensileringstillsatser": "02_01_Säilörehun lisäaineista tehty esiseos",
    "Helfoder": "Täysrehu",
    "Kompletteringsfoder, som kräver härutöver bara grovfoder": "Täydennysrehu, joka vaatii lisäksi vain karkearehua",
    "Proteinkompletteringsfoder": "Valkuaistäydennysrehu",
    "Mineralkompletteringsfoder": "Kivennäistäydennysrehu",
    "Övrig kompletteringsfoder": "Muu täydennysrehu",
    "Slicksten": "Nuolukivi",
    "Foder avsett för särskilda näringsbehov": "Erityisruokavalio",
    "Foder som innehåller läkemedel": "Lääkerehu",
    "Dagsgamla kycklingar och/eller kycklingar": "Untuvikot ja/tai poikaset",
    "Unghönor": "Kananuorikot",
    "Värphöns och/eller avelsfåglar": "Munivat kanat ja/tai siitoslinnut",
    "Broilrar": "Broilerit",
    "Kalkoner": "Kalkkunat",
    "Andra fjäderfäarter än de som nämns ovan": "Muut siipikarjalaji kuin edellä mainitut",
    "Fjäderfä som djurgrupp": "Siipikarja eläinryhmänä",
    "Smågrisar": "Porsaat",
    "Suggor": "Emakot",
    "Slaktsvin": "Lihasiat",
    "Svin som djurgrupp": "Siat eläinryhmänä",
    "Kalvar och/eller unga nötkreatur": "Vasikat ja/tai nuorkarja",
    "Vuxna nötkreatur": "Aikuiset naudat",
    "Nötkreatur som grupp av djur": "Nautakarja eläinryhmänä",
    "Får och/eller getter": "Lampaat ja/tai vuohet",
    "Renar": "Porot",
    "Hästdjur": "Hevoseläimet",
    "Fisk": "Kalat",
    "Insekter": "Hyönteiset",
    "Köttkaniner": "Lihakanit",
    "Andra livsmedelsproducerande djurarter som inte nämns ovan": "Muu elintarviketuotantoeläinlaji kuin edellä mainittu",
    "Livsmedelsproducerande djur som grupp av djur": "Elintarviketuotantoeläimet eläinryhmänä",
    "Pälsdjur": "TURKISELÄIMET",
    "K Torrt helfoder (så kallade \"knappar\")": "K kuivat täysrehut (ns. nappulat)",
    "K Råa helfoder": "K raa'at täysrehut",
    "K Andra helfoder": "K muut täysrehut ",
    "K Råa foderråvaror av animaliskt ursprung eller kompletteringsfoder": "K raa'at rehuaineet tai täydennysrehut",
    "K Bearbetade foderråvaror av animaliskt ursprung eller kompletteringsfoder": "K käsitellyt rehuaineet tai täydennysrehut",
    "K Tuggben": "K puruluut",
    "K/M Kompletteringsfoder (som vitamin-, mineral- eller spårämnefoder)": "K/M Täydennysrehuseos (mm. vitamiini-, kivennäis- ja hivenainetäydennys)",
    "M Helfoder": "M täysrehut",
    "M Foderråvaror av animaliskt ursprung": "M eläinperäiset rehuaineet",
    "M Foderråvaror av vegetabiliskt ursprung och andra foderråvaror": "M kasviperäiset ja muut rehuaineet",
    "Hundar": "Koirat",
    "Katter": "Kissat",
    "Gnagare och kaniner": "Jyrsijät ja kanit",
    "Akvariefiskar": "Akvaariokalat",
    "Burfåglar": "Häkkilinnut",
    "Andra sällskapsdjur än de som nämns ovan": "Muut lemmikkieläimet kuin edellä mainitut",
    "Sällskapsdjur som en djurgrupp": "Lemmikkieläimet eläinryhmänä",
    "Produkter från havre": "Kaurasta saatavat tuotteet",
    "Produkter från korn": "Ohrasta saatavat tuotteet",
    "Produkter från majs": "Maissista saatavat tuotteet",
    "Produkter från andra spannmål än de som nämns ovan": "Muusta viljasta kuin edellä mainituista saatavat tuotteet",
    "Jordnötter (som sådana eller krossade)": "Maapähkinät (sellaisenaan tai rikottuna)",
    "Solrosfrön (som sådana eller skalade)": "Auringonkukansiemenet (sellaisenaan tai kuorittuna)",
    "Ärtor": "Herne",
    "Fröblandning": "Siemenseos",
    "Fettfröblandning": "Rasvasiemenseos",
    "Talgbollar, -kakor osv.": "Talipallot, -kakut yms.",
    "Foderblandning för vilt": "Rehuseos riistaeläimille",
    "Slicksten för vilt": "Nuolukivi riistaeläimille",
    "Annat än ovan nämnda fodret": "Muu rehu kuin edellä mainittu",
    "Naturfåglar (annat än viltfåglar)": "Ulkolinnut (muut kuin riistalinnut)",
    "Annat naturdjur än viltdjur": "Muut luonnoneläimet kuin riistaeläimet",
    "Viltdjur": "Riistaeläimet"
  }
  private valmistus = {
    "Välj från menyn som öppnas": "Valitse avautuvasta valikosta",
    "1. Spannmål och produkter därav (välj bland följande)": "1. Viljojen jyvät ja niistä saatavat tuotteet (valitse alla olevista)",
    "1.1.1 Korn": "1.1.1 Ohra",
    "1.1.2 Svällt korn": "1.1.2 Ohra, paisutettu",
    "1.1.3 Rostat korn": "1.1.3 Ohra, paahdettu",
    "1.1.4 Kornflingor": "1.1.4 Ohrahiutaleet",
    "1.1.5 Kornfiber": "1.1.5 Ohrakuitu",
    "1.1.6 Kornskal": "1.1.6 Ohrankuoret",
    "1.1.7 Kornklimjöl": "1.1.7 Ohrarehujauho",
    "1.1.8 Kornprotein": "1.1.8 Ohravalkuainen",
    "1.1.9.1 Kornproteinfoder, torkat": "1.1.9.1 Ohravalkuaisrehu, kuiva",
    "1.1.9.2 Kornproteinfoder, våt": "1.1.9.2 Ohravalkuaisrehu, tuore",
    "1.1.10 Vattenlösliga restprodukter av korn": "1.1.10 Ohran liukoiset aineet",
    "1.1.11 Kornkli": "1.1.11 Ohralese",
    "1.1.12 Flytande kornstärkelse": "1.1.12 Ohratärkkelys, nestemäinen",
    "1.1.13 Sållrester av mältat korn": "1.1.13 Mallasohran seulontajakeet",
    "1.1.14 Mältat korn och maltdamm": "1.1.14 Mallasohra ja mallasjae",
    "1.1.15 Skal från mältat korn": "1.1.15 Mallasohran kuoret",
    "1.1.16 Våta fasta partiklar av korn från destillering": "1.1.16 Ohrarankin kiinteä osa, sellaisenaan",
    "1.1.17 Våta vattenlösliga restprodukter av korn från destillering": "1.1.17 Ohrarankin liukoinen osa",
    "1.1.18 Malt ": "1.1.18 Maltaat ( 1 )",
    "1.1.19 Maltrottrådar": "1.1.19 Mallasidut ( 1 )",
    "1.2.18 Majs ": "1.2.1 Maissi ( 2 )",
    "1.2.2 Majsflingor": "1.2.2 Maissihiutaleet",
    "1.2.3 Majsklimjöl": "1.2.3 Maissirehujauho",
    "1.2.4 Majskli": "1.2.4 Maissilese",
    "1.2.5 Majskolvar": "1.2.5 Maissintähkät",
    "1.2.6 Sållrester av majs": "1.2.6 Maissin seulontajakeet",
    "1.2.7 Majsfiber": "1.2.7 Maissikuitu",
    "1.2.8 Majsgluten": "1.2.8 Maissigluteeni",
    "1.2.9 Majsglutenfoder": "1.2.9 Maissigluteenirehu",
    "1.2.10 Majsgroddar": "1.2.10 Maissinalkio",
    "1.2.11 Majsgroddsexpeller": "1.2.11 Maissinalkiopuriste",
    "1.2.12 Majsgroddsmjöl": "1.2.12 Maissinalkiorouhe",
    "1.2.13 Majsgroddsolja": "1.2.13 Maissinalkioöljy, raaka",
    "1.2.14 Svälld majs": "1.2.14 Maissi, paisutettu",
    "1.2.15 Blötläggningsvätska för majs": "1.2.15 Maissinliotusvesi",
    "1.2.16 Sockermajsensilage": "1.2.16 Sokerimaissintähkäsäilörehu",
    "1.2.17 Krossad majs utan grodd": "1.2.17 Murskattu maissi, josta on poistettu alkiot",
    "1.2.18 Majsgryn": "1.2.18 Maissiryynit(2)",
    "1.3.1 Hirs": "1.3.1 Hirssi",
    "1.4.1 Havre": "1.4.1 Kaura",
    "1.4.2 Skalad havre": "1.4.2 Kaura, kuorittu",
    "1.4.3 Havregryn": "1.4.3 Kaurahiutaleet",
    "1.4.4 Havreklifoder": "1.4.4 Kaurarehujauho /kaurarehu",
    "1.4.5 Havrekli": "1.4.5 Kauralese",
    "1.4.6 Havreskal": "1.4.6 Kaurankuoret",
    "1.4.7 Svälld havre": "1.4.7 Kaura, paisutettu",
    "1.4.8 Rensad havre": "1.4.8 Kaurasuurimot",
    "1.4.9 Havremjöl": "1.4.9 Kaurajauho",
    "1.5.1 Extraherat quinoafrö": "1.5.1 Kvinoansiemenrouhe",
    "1.6.1 Brutet ris": "1.6.1 Rouhittu riisi",
    "1.6.2 Malet ris": "1.6.2 Jauhettu riisi",
    "1.6.3 Förklistrat ris": "1.6.3 Esigelatinoitu riisi",
    "1.6.4 Extruderat ris": "1.6.4 Ekstrudoitu riisi",
    "1.6.5 Risflingor": "1.6.5 Riisihiutaleet",
    "1.6.6 Råris": "1.6.6 Esikuorittu riisi",
    "1.6.7 Malet foderris": "1.6.7 Jauhettu rehuriisi",
    "1.6.8 Rismjöl": "1.6.8 Riisijauho",
    "1.6.9 Rårismjöl": "1.6.9 Esikuorittu riisijauho",
    "1.6.10 Riskli": "1.6.10 Riisilese",
    "1.6.11 Riskli innehållande kalciumkarbonat": "1.6.11 Riisilese, kalsiumkarbonaattipitoinen",
    "1.6.12 Avfettat riskli": "1.6.12 Riisilese, rasvaton",
    "1.6.13 Riskliolja": "1.6.13 Riisileseöljy",
    "1.6.14 Risklimjöl": "1.6.14 Riisirehujauho",
    "1.6.15 Risklimjöl innehållande kalciumkarbonat": "1.6.15 Riisirehujauho, kalsiumkarbonaattipitoinen",
    "1.6.16 Ris": "1.6.16 Riisi",
    "1.6.17 Risgroddar": "1.6.17 Riisinalkio",
    "1.6.18 Risgroddsexpeller": "1.6.18 Riisinalkiopuriste",
    "1.6.20 Risprotein": "1.6.20 Riisivalkuainen",
    "1.6.21 Flytande polerat ris": "1.6.21 Nestemäinen riisirehu",
    "1.6.22 Svällt ris": "1.6.22 Riisi, paisutettu",
    "1.6.23 Fermenterat ris": "1.6.23 Riisi, fermentoitu",
    "1.6.24 Deformerat malt ris/malet ris innehållande kalk": "1.6.24 Epämuodostunut riisi, jauhettu/liitumainen riisi, jauhettu",
    "1.6.25 Omoget malet ris": "1.6.25 Epäkypsä riisi, jauhettu",
    "1.7.1 Råg": "1.7.1 Ruis",
    "1.7.2 Rågklifoder": "1.7.2 Ruisrehujauho",
    "1.7.3 Foderråg": "1.7.3 Ruisrehu",
    "1.7.4 Rågkli": "1.7.4 Ruislese",
    "1.8.1 Sorghum Milo": "1.8.1 Durra; Milo",
    "1.9.1 Speltvete": "1.9.1 Spelttivehnä",
    "1.9.2 Speltvetekli": "1.9.2 Spelttilese",
    "1.9.3 Speltveteskal": "1.9.3 Speltinkuoret",
    "1.9.4 Speltveteklimjöl": "1.9.4 Spelttirehujauho",
    "1.10.1 Triticale Rågvete": "1.10.1 Ruisvehnä",
    "1.11.1 Vete": "1.11.1 Vehnä",
    "1.11.2 Veterottrådar": "1.11.2 Vehnäidut",
    "1.11.3 Förklistrat vete": "1.11.3 Vehnä, esigelatinoitu",
    "1.11.4 Veteklifoder": "1.11.4 Vehnärehujauho",
    "1.11.5 Veteflingor": "1.11.5 Vehnähiutaleet",
    "1.11.6 Fodervete": "1.11.6 Vehnärehu",
    "1.11.7 Vetekli ": "1.11.7 Vehnälese ( 3 )",
    "1.11.8 Mältade fermenterade vetepartiklar": "1.11.8 Mallastetut, fermentoidut vehnän osaset",
    "1.11.10 Vetefiber": "1.11.10 Vehnäkuitu",
    "1.11.11 Vetegroddar": "1.11.11 Vehnänalkio",
    "1.11.12 Fermenterade vetegroddar": "1.11.12 Vehnänalkio, fermentoitu",
    "1.11.13 Vetegroddsexpeller": "1.11.13 Vehnänalkiopuriste",
    "1.11.15 Veteprotein": "1.11.15 Vehnävalkuainen",
    "1.11.16 Veteglutenfoder": "1.11.16 Vehnägluteenirehu",
    "1.11.18 Veteglutenmjöl": "1.11.18 Puhdas vehnägluteeni",
    "1.11.19 Flytande vetestärkelse": "1.11.19 Vehnätärkkelys, nestemäinen",
    "1.11.20 Delvis avsockrad vetestärkelse innehållande protein": "1.11.20 Vehnätärkkelys, valkuaispitoinen, vähäsokerinen",
    "1.11.21 Vattenlösliga restprodukter av vete": "1.11.21 Vehnän liukoiset aineet",
    "1.11.22 Vetejästkoncentrat": "1.11.22 Vehnähiivatiiviste",
    "1.11.23 Sållrester av mältat vete": "1.11.23 Mallastetun vehnän seulontajakeet",
    "1.11.24 Mältat vete och maltdamm": "1.11.24 Mallastettu vehnä ja mallasjae",
    "1.11.25 Skal av mältat vete": "1.11.25 Mallastetun vehnän kuoret",
    "1.12.2 Spannmålsmjöl ": "1.12.2 Viljajauho ( 1 )",
    "1.12.3 Proteinkoncentrat från spannmål ": "1.12.3 Viljavalkuaistiiviste ( 1 )",
    "1.12.4 Sållrester av spannmål ": "1.12.4 Viljan jyvien seulontajakeet ( 1 )",
    "1.12.5 Groddad spannmål ": "1.12.5 Viljanalkiot ( 1 )",
    "1.12.6 Sirap av blötläggningsvätska från spannmål ": "1.12.6 Haihduttamalla tiivistetty rankki ( 1 )",
    "1.12.7 Blöt drank / Torkad drank med tillsats av mäsk; drank och vattenlösliga rester från destillerier ": "1.12.7 Rankki, tuore / Rankki, tumma; Kuivattu rankki ja liukoiset aineet",
    "1.12.8 Koncentrerade vattenlösliga restprodukter från destillering / Drank och vattenlösliga restprodukter från destillering": "1.12.8 Rankin liukoisten aineiden tiiviste / Rankki ja liukoiset aineet ",
    "1.12.10 Torkad drank": "1.12.10 Kuivattu rankki",
    "1.12.12 Drav ": "1.12.12 Olutmäski (1 )",
    "1.12.13 Whiskydrank ": "1.12.13 Mäski ( 1 )",
    "1.12.14 Filtrerade mäskprodukter": "1.12.14 Suotopuristinmäski",
    "1.12.15 Pot ale": "1.12.15 Tislausjäännös",
    "1.12.16 Pot ale-sirap": "1.12.16 Tislausjäännöstiiviste",
    "2. Oljehaltiga frön eller frukter samt produkter och biprodukter därav (välj bland följande)": "2. Öljykasvien siemenet, öljypitoiset hedelmät ja niistä saatavat tuotteet (valitse alla olevista)",
    "2.1.1 Babassuexpeller": "2.1.1 Babassupalmupuriste",
    "2.2.1 Cirkamelinafrö": "2.2.1 Camelinansiemenet",
    "2.2.2 Cirkamelinaexpeller": "2.2.2 Camelinapuriste",
    "2.2.3 Cirkamelinamjöl": "2.2.3 Camelinarouhe",
    "2.3.1 Kakaobönskal": "2.3.1 Kaakaopavun ulkokuoret",
    "2.3.2 Kakaoskal": "2.3.2 Kaakaopavun kuoret",
    "2.3.3 Mjöl av delvis skalade kakaobönor": "2.3.3 Kaakaorouhe osittain kuorituista pavuista",
    "2.4.1 Kokos-/Kopraexpeller": "2.4.1 Kookospuriste",
    "2.4.2 Kokos/Kopra, hydrolyserad expeller": "2.4.2 Kookospuriste, hydrolysoitu",
    "2.4.3 Kokos-/Kopramjöl": "2.4.3 Kookosrouhe",
    "2.5.1 Bomullsfrön": "2.5.1 Puuvillansiemenet",
    "2.5.2 Mjöl av delvis skalat bomullsfrö": "2.5.2 Puuvillansiemenrouhe osittain kuorituista siemenistä",
    "2.5.3 Bomullsfröexpeller": "2.5.3 Puuvillansiemenpuriste",
    "2.6.1 Expeller av delvis skalade jordnötter": "2.6.1 Maapähkinäpuriste osittain kuorituista pähkinöistä",
    "2.6.2 Mjöl av delvis skalade jordnötter": "2.6.2 Maapähkinärouhe osittain kuorituista pähkinöistä",
    "2.6.3 Expeller av skalade jordnötter": "2.6.3 Maapähkinäpuriste kuorituista pähkinöistä",
    "2.6.4 Mjöl av skalade jordnötter": "2.6.4 Maapähkinärouhe kuorituista pähkinöistä",
    "2.7.1 Kapockexpeller": "2.7.1 Kapokkipuriste.",
    "2.8.1 Linfrö": "2.8.1 Pellavansiemenet",
    "2.8.2 Linfröexpeller": "2.8.2 Pellavansiemenpuriste",
    "2.8.3 Linfrömjöl": "2.8.3 Pellavansiemenrouhe",
    "2.8.4 Foder av linfröexpeller": "2.8.4 Pellavansiemenpuristerehu",
    "2.8.5 Foder av linfrömjöl": "2.8.5 Pellavansiemenrouherehu",
    "2.8.6 Linfröolja": "2.8.6 Pellavaöljy",
    "2.9.1 Senapskli": "2.9.1 Sinappilese",
    "2.9.2 Senapsfrömjöl": "2.9.2 Sinapinsiemenrouhe",
    "2.10.1 Nigerfrö": "2.10.1 Niger-kasvin siemenet",
    "2.10.2 Nigerfröexpeller": "2.10.2 Niger-siemenpuriste/-kakku",
    "2.11.1 Palmkärnexpeller": "2.11.1 Oliivirouhe",
    "2.11.1 Olivpressmassa": "2.11.2 Oliivirouherehu, rasvaton",
    "2.11.2 Foder av avfettat oljemjöl": "2.11.3 Oliivirouhe, rasvaton",
    "2.11.3 Avfettat olivmjöl": "2.12.1 Palmuydinpuriste",
    "2.12.2 Palmkärnsmjöl": "2.12.2 Palmuydinrouhe",
    "2.12.3 Palmkärnsolja": "2.12.3 Palmuöljy/palmuydinöljy",
    "2.13.1 Pumpa- och squashfrön": "2.13.1 Kurpitsansiemenet",
    "2.13.2 Pumpa- och squashfröexpeller": "2.13.2 Kurpitsansiemenpuriste",
    "2.14.1 Rapsfrön ": "2.14.1 Rapsinsiemenet ( 1 )",
    "2.14.2 Rapsfröexpeller": "2.14.2 Rapsipuriste",
    "2.14.3 Mjöl av rapsfrön": "2.14.3 Rapsirouhe",
    "2.14.4 Extruderade rapsfrön": "2.14.4 Rapsinsiemenet, ekstrudoitu",
    "2.14.5 Proteinkoncentrat av rapsfrö": "2.14.5 Tiivistetty rapsinsiemenvalkuainen",
    "2.14.6 Foder av rapsfröexpeller": "2.14.6 Rapsipuristerehu",
    "2.14.7 Foder av rapsfrömjöl": "2.14.7 Rapsirouherehu",
    "2.14.8 Rapsfröolja": "2.14.8 Rapsiöljy",
    "2.14.9 Rypsfrön ": "2.14.9 Rypsinsiemenet ( 1 )",
    "2.14.10 Rypsfröexpeller": "2.14.10 Rypsipuriste",
    "2.14.11 Mjöl av rypsfrön": "2.14.11 Rypsirouhe",
    "2.14.12 Extruderade rypsfrön": "2.14.12 Rypsinsiemenet, ekstrudoitu",
    "2.14.13 Proteinkoncentrat av rypsfrö": "2.14.13 Tiivistetty rypsinsiemenvalkuainen",
    "2.14.14 Foder av rypsfröexpeller": "2.14.14 Rypsipuristerehu",
    "2.14.15 Foder av rypsfrömjöl": "2.14.15 Rypsirouherehu",
    "2.14.16 Rypsfröolja": "2.14.16 Rypsiöljy",
    "2.15.1 Safflorfrö": "2.15.1 Safflorinsiemenet",
    "2.15.2 Mjöl av delvis skalade safflorfrön": "2.15.2 Safflorirouhe osittain kuorituista siemenistä",
    "2.15.3 Safflorskal": "2.15.3 Safflorinsiementen kuoret",
    "2.16.1 Sesamfrön": "2.16.1 Seesaminsiemenet",
    "2.17.1 Delvis skalade sesamfrön": "2.17.1 Seesaminsiemenet, osittain kuoritut",
    "2.17.2 Sesamfröskal": "2.17.2 Seesaminsiementen kuoret",
    "2.17.3 Sesamfröexpeller": "2.17.3 Seesaminsiemenpuriste",
    "2.18.1 Rostad(e) soja(bönor)": "2.18.1 Paahdetut soija(pavut)",
    "2.18.2 Soja(böns)expeller": "2.18.2 Soija(papu)puriste",
    "2.18.3 Soja(böns)mjöl": "2.18.3 Soija(papu)rouhe",
    "2.18.4 Mjöl av skalad(e) soja(bönor)": "2.18.4 Soija(papu)rouhe kuorituista soijapavuista",
    "2.18.5 Soja(böns)skal": "2.18.5 Soijan (soijapavun) kuoret",
    "2.18.6 Extruderade sojabönor": "2.18.6 Soijapavut, ekstrudoitu",
    "2.18.7 Proteinkoncentrat av soja(böna)": "2.18.7 Soija(papu)valkuaiskonsentraatti",
    "2.18.8 Sojabönspressmassa; Sojabönspasta": "2.18.8 Soijapapupulppa; Soijapaputahna",
    "2.18.9 Sojabönsmelass": "2.18.9 Soija(papu)melassi",
    "2.18.10 Biprodukt från sojabönsberedning": "2.18.10 Soijapapujen valmistuksen sivutuote",
    "2.18.11 Soja(bönor)": "2.18.11 Soija(pavut)",
    "2.18.12 Sojabönor, flingor": "2.18.12 Soijapapuhiutaleet",
    "2.18.13 Foder av soja(böns)mjöl": "2.18.13 Soija(papu)rouherehu",
    "2.18.14 Fodermjöl av skalad(e) soja(bönor)": "2.18.14 Soija(papu)rouherehu kuorituista soijapavuista",
    "2.18.15 Fermenterat soja(böns) protein (koncentrat)": "2.18.15 Soija(papu)valkuais(konnsentraatti), fermentoitu",
    "2.18.16 Sojabönolja": "2.18.16 Soijaöljy",
    "2.19.1 Solrosfrön": "2.19.1 Auringonkukansiemenet",
    "2.19.2 Solrosfröexpeller": "2.19.2 Auringonkukkapuriste",
    "2.19.3 Solrosfrömjöl": "2.19.3 Auringonkukkarouhe",
    "2.19.4 Mjöl av skalade solrosfrön": "2.19.4 Auringonkukkarouhe kuorituista siemenistä",
    "2.19.5 Solrosfröskal": "2.19.5 Auringonkukansiementen kuoret",
    "2.19.6 Foder av solrosfrömjöl": "2.19.6 Auringonkukkarouherehu",
    "2.19.7 Fodermjöl av skalade solrosfrön": "2.19.7 Auringonkukkarouherehu kuorituista siemenistä",
    "2.19.8 Fraktioner av solrosfrömjöl med högt proteininnehåll och lågt cellulosainnehåll": "2.19.8 Auringonkukkarouheen runsaasti valkuaista ja vähän selluloosaa sisältävä jae",
    "2.19.9 Fraktioner av solrosfrömjöl med högt cellulosainnehåll": "2.19.9 Auringonkukkarouheen runsaasti selluloosaa sisältävä jae",
    "2.19.10 Solrosfröolja": "2.19.10 Auringonkukansiemenöljy",
    "2.20.1 Vegetabilisk olja och fett (växtarter som inte är kända)": "2.20.1 Kasviöljy ja -rasva (kasvilaji ei tiedossa)",
    "2.20.2 Använda vegetabiliska oljor från livsmedelsindustrin": "2.20.2 Elintarviketehtaiden käytetyt kasviöljyt",
    "2.21.1 Rålecitiner": "2.21.1 Raakalesitiinit",
    "2.22.1 Hampafrö": "2.22.1 Hampunsiemenet",
    "2.22.2 Hampaexpeller": "2.22.2 Hampunsiemenpuriste",
    "2.22.3 Hampaolja": "2.22.3 Hamppuöljy",
    "2.23.1 Vallmofrön": "2.23.1 Unikonsiemenet",
    "2.23.2 Vallmofrömjöl": "2.23.2 Unikonsiemenrouhe",
    "3. Frön från baljväxter och produkter därav (välj bland följande)": "3. Palkokasvien siemenet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "3.1.1 Bönor, rostade": "3.1.1 Paahdetut pavut",
    "3.1.2 Proteinkoncentrat av bönor": "3.1.2 Tiivistetty papuvalkuainen",
    "3.2.1 Johannesbrödfrukt": "3.2.1 Johanneksenleipäpuun palot",
    "3.2.3 Krossad johannesbrödfrukt": "3.2.3 Karkeaksi rouhitut johanneksenleipäpuun palot",
    "3.2.4 Mald johannesbrödfrukt": "3.2.4 Johanneksenleipäpuujauhe; johanneksenleipäpuujauho",
    "3.2.5 Johannesbrödsgroddar": "3.2.5 Johanneksenleipäpuun alkio",
    "3.2.6 Johannesbrödgroddexpeller": "3.2.6 Johanneksenleipäpuunalkiopuriste",
    "3.2.7 Johannesbrödfrön": "3.2.7 Johanneksenleipäpuun siemenet",
    "3.2.8 Skal från johannesbrödfrön": "3.2.8 Johanneksenleipäpuun siementen kuoret",
    "3.3.1 Kikärter": "3.3.1 Kahviherneet",
    "3.4.1 Ervum ervilia": "3.4.1 Linssivirvilä",
    "3.5.1 Frö av bockhornsklöver": "3.5.1 Sarviapilan siemenet",
    "3.6.1 Guarmjöl": "3.6.1 Guarkumirouhe",
    "3.6.2 Guargroddsmjöl": "3.6.2 Guarkuminalkiorouhe",
    "3.7.1 Åkerbönor": "3.7.1 Härkäpapu",
    "3.7.2 Åkerbönsflingor": "3.7.2 Härkäpapuhiutaleet",
    "3.7.3 Åkerbönsskal": "3.7.3 Härkäpavun kalvot; Härkäpavun kuoret",
    "3.7.4 Skalade åkerbönor": "3.7.4 Härkäpapu, kuorittu",
    "3.7.5 Åkerbönsprotein": "3.7.5 Härkäpapuvalkuainen",
    "3.8.1 Linser": "3.8.1 Linssit",
    "3.8.2 Linsskal": "3.8.2 Linssin kuoret",
    "3.9.1 Sötlupiner": "3.9.1 Makealupiini",
    "3.9.2 Skalade sötlupiner": "3.9.2 Makealupiini, kuorittu",
    "3.9.3 Filmlupiner; Lupinskal": "3.9.3 Lupiinin kalvot; Lupiinin kuoret",
    "3.9.4 Lupinpressmassa": "3.9.4 Lupiinipulppa",
    "3.9.5 Lupinfodermjöl": "3.9.5 Lupiinirehujauho",
    "3.9.6 Lupinprotein": "3.9.6 Lupiinivalkuainen",
    "3.9.7 Lupinproteinmjöl": "3.9.7 Lupiinivalkuaisjauho",
    "3.10.1 Mungbönor": "3.10.1 Mungopapu",
    "3.11.1 Ärter": "3.11.1 Herne",
    "3.11.2 Ärtkli": "3.11.2 Hernelese",
    "3.11.3 Ärtflingor": "3.11.3 Hernehiutaleet",
    "3.11.4 Ärtmjöl": "3.11.4 Hernejauho",
    "3.11.5 Ärtskal": "3.11.5 Herneen kuoret",
    "3.11.6 Skalade ärter": "3.11.6 Herne, kuorittu",
    "3.11.7 Ärtfodermjöl": "3.11.7 Hernerehujauho",
    "3.11.8 Sållrester av ärta": "3.11.8 Herneen seulontajakeet",
    "3.11.9 Ärtprotein": "3.11.9 Hernevalkuainen",
    "3.11.10 Ärtpressmassa": "3.11.10 Hernepulppa",
    "3.11.11 Vattenlösliga restprodukter av ärta": "3.11.11 Herneen liukoiset aineet",
    "3.11.12 Ärtfiber": "3.11.12 Hernekuitu",
    "3.12.1 Vicker": "3.12.1 Virnat",
    "3.13.1 Plattvial": "3.13.1 Peltonätkelmä",
    "3.14.1 Monanthavicker": "3.14.1 Yksikukkavirvilä",
    "4. Rotknölar, rötter och produkter därav (välj bland följande)": "4. Juurimukulat ja juurekset ja niistä saatavat tuotteet  (valitse alla olevista)",
    "4.1.1 Sockerbetor": "4.1.1 Sokerijuurikas",
    "4.1.2 Sockerbetstoppar och rötter": "4.1.2 Sokerijuurikkaan juuren niska ja juuren häntä",
    "4.1.3 (Bet)socker; sackaros": "4.1.3 (Juurikas)sokeri; Sakkaroosi",
    "4.1.4 Betmelass (av sockerbeta)": "4.1.4 (Sokeri)juurikasmelassi",
    "4.1.5 Betmelass (av sockerbeta), delvis avsockrad och/eller avbetainiserad": "4.1.5 Erotusmelassi",
    "4.1.6 Isomaltulosmelass": "4.1.6 Isomaltuloosimelassi",
    "4.1.7 Våt betmassa (av sockerbeta)": "4.1.7 Tuore (sokeri)juurikasleike",
    "4.1.8 Pressad betmassa (av sockerbeta)": "4.1.8 Puristettu (sokeri)juurikasleike",
    "4.1.9 Melasserad pressad betmassa (av sockerbeta)": "4.1.9 Puristettu (sokeri)juurikasleike, melassoitu",
    "4.1.10 Torkad betmassa (av sockerbeta)": "4.1.10 Kuivattu (sokeri)juurikasleike",
    "4.1.11 Melasserad torkad betmassa (av sockerbeta)": "4.1.11 Kuivattu (sokeri)juurikasleike, melassoitu",
    "4.1.12 Sockersirap": "4.1.12 Sokerisiirappi",
    "4.1.13 Kokta betbitar (av sockerbeta)": "4.1.13 (Sokeri)juurikkaan palat, keitetyt",
    "4.1.14 Fruktooligosackarider": "4.1.14 Frukto-oligosakkaridit",
    "4.1.15 Betmelass (av sockerbeta), rik på betain, flytande/torkad": "4.1.15 (Sokeri)juurikasmelassi, runsaasti betaiinia sisältävä, nestemäinen/kuivattu(1)",
    "4.1.16 Isomaltulos": "4.1.16 Isomaltuloosi",
    "4.2.1 Rödbetssaft": "4.2.1 Punajuurikasmehu",
    "4.3.1 Morötter": "4.3.1 Porkkana",
    "4.3.2 Ångkokta morotsskal": "4.3.2 Porkkanan kuoret, höyrytetyt",
    "4.3.3 Morotsavskrap": "4.3.3 Kaavitut porkkanat",
    "4.3.4 Morotsflingor": "4.3.4 Porkkanahiutaleet",
    "4.3.5 Torkad morot": "4.3.5 Porkkana, kuivattu",
    "4.3.6 Torkat morotsfoder": "4.3.6 Porkkanarehu, kuivattu",
    "4.3.7 Morotkross": "4.3.7 Porkkanarouhe",
    "4.4.1 Cikoriarot": "4.4.1 Sikurin juuret",
    "4.4.2 Cikoriatoppar och cikoriablast": "4.4.2 Sikurin juuren niska ja juuren häntä",
    "4.4.3 Cikoriafrö": "4.4.3 Sikurinsiemenet",
    "4.4.4 Cikoriapressmassa": "4.4.4 Puristettu sikuripulppa",
    "4.4.5 Torkad cikoriapressmassa": "4.4.5 Kuivattu sikuripulppa",
    "4.4.6 Cikoriarotspulver": "4.4.6 Sikurin juurijauhe",
    "4.4.7 Cikoriamelass": "4.4.7 Sikurimelassi",
    "4.4.8 Cikoriavinass": "4.4.8 Sikurivinassi",
    "4.4.9 Cikoriainulin": "4.4.9 Inuliini",
    "4.4.10 Oligofruktossirap": "4.4.10 Oligofruktoosisiirappi",
    "4.4.11 Torkad oligofruktos": "4.4.11 Oligofruktoosi, kuivattu",
    "4.5.1 Torkad vitlök": "4.5.1 Valkosipuli, kuivattu",
    "4.5.2 Finhakket vitlök": "4.5.2 Valkosipulihiutale",
    "4.5.4 Krossad vitlök": "4.5.4 Valkosipulirouhe",
    "4.6.1 Maniok; tapioka; kassava": "4.6.1 Maniokki tapioka; kassava",
    "4.6.2 Torkad maniok": "4.6.2 Maniokki, kuivattu",
    "4.6.3 Puffad maniokstärkelse": "4.6.3 Maniokkitärkkelys, paisutettu",
    "4.7.1 Lökpressmassa": "4.7.1 Sipulipulppa",
    "4.7.2 Stekt lök": "4.7.2 Sipuli, paistettu",
    "4.7.3 Vattenlösliga restprodukter av lök": "4.7.3 Sipulin liukoiset aineet, kuivatut",
    "4.8.1 Potatis": "4.8.1 Peruna",
    "4.8.2 Skalad potatis": "4.8.2 Peruna, kuorittu",
    "4.8.3 Ångkokta potatisskal": "4.8.3 Perunan kuoret, höyrytetyt",
    "4.8.4 Råa potatisbitar": "4.8.4 Perunaleikkeet, raa'at",
    "4.8.5 Potatisavskrap": "4.8.5 Perunankuorimassa",
    "4.8.6 Mosad potatis": "4.8.6 Peruna, soseutettu",
    "4.8.7 Potatisflingor": "4.8.7 Perunahiutaleet",
    "4.8.8 Potatispressmassa": "4.8.8 Perunarehu",
    "4.8.9 Torkad potatispressmassa": "4.8.9 Perunarehu, kuivattu",
    "4.8.10 Potatisprotein": "4.8.10 Perunavalkuainen",
    "4.8.11 Hydrolyserat potatisprotein": "4.8.11 Perunavalkuainen, hydrolysoitu",
    "4.8.12 Fermenterat potatisprotein": "4.8.12 Perunavalkuainen, fermentoitu",
    "4.8.13 Flytande fermenterat potatisprotein": "4.8.13 Nestemäinen perunavalkuainen, fermentoitu",
    "4.8.14 Koncentrerad potatissaft": "4.8.14 Tiivistetty perunamehu",
    "4.8.15 Potatisgranulat": "4.8.15 Perunarakeet",
    "4.9.1 Sötpotatis": "4.9.1 Bataatti",
    "4.10.1 Jordärtskocka": "4.10.1 Maa-artisokka; Topinambur",
    "5. Andra frön och frukter samt produkter därav (välj bland följande)": "5. Muut siemenet ja hedelmät ja niistä saatavat tuotteet  (valitse alla olevista)",
    "5.1.1 Ekollon": "5.1.1 Tammenterho",
    "5.1.2 Skalade ekollon": "5.1.2 Tammenterho, kuorittu",
    "5.2.1 Mandel": "5.2.1 Manteli",
    "5.2.2 Mandelskal": "5.2.2 Mantelinkuoret",
    "5.2.3 Mandelkärnor, expeller": "5.2.3 Mantelipuriste",
    "5.3.1 Anisfrö": "5.3.1 Aniksensiemenet",
    "5.4.1 Torkad äppelpressmassa; Torkade äppelpressrester": "5.4.1 Omenamassa, kuivattu; Omenapulppa, kuivattu",
    "5.4.2 Pressad äppelpressmassa; Pressade äppelpressrester": "5.4.2 Omenamassa, puristettu; Omenapulppa, puristettu",
    "5.4.3 Äppelmelass": "5.4.3 Omenamelassi",
    "5.5.1 Sockerbetsfrön": "5.5.1 Sokerijuurikkaan siemenet",
    "5.6.1 Bovete": "5.6.1 Tattari",
    "5.6.2 Boveteskal och bovetekli": "5.6.2 Tattarinkuoret ja tattarilese",
    "5.6.3 Bovetefodermjöl": "5.6.3 Tattarirehujauho",
    "5.7.1 Rödkålsfrö": "5.7.1 Punakaalinsiemenet",
    "5.8.1 Kanariegräsfrö": "5.8.1 Kanarianhelpin siemenet",
    "5.9.1 Kumminfrö": "5.9.1 Kuminansiemenet",
    "5.12.1 Krossrester av kastanj": "5.12.1 Rouhitut kastanjat",
    "5.13.1 Citruspressmassa ": "5.13.1 Sitruspulppa ( 1 )",
    "5.13.2 Torkad citronpressmassa ": "5.13.2 Sitruspulppa, kuivattu ( 1 )",
    "5.14.1 Rödklöverfrö": "5.14.1 Puna-apilansiemenet",
    "5.15.1 Vitklöverfrö": "5.15.1 Valkoapilansiemenet",
    "5.15.1 Kaffebönskal": "5.15.1 Kahvinkuoret",
    "5.16.1 Blåklintsfrö": "5.16.1 Ruiskaunokin siemenet",
    "5.17.1 Gurkfrö": "5.17.1 Kurkunsiemenet",
    "5.18.1 Cypressfrö": "5.18.1 Sypressinsiemenet",
    "5.19.1 Dadelfrukt": "5.19.1 Taateli",
    "5.19.2 Dadelfrö": "5.19.2 Taatelinsiemenet",
    "5.20.1 Fänkålsfrö": "5.20.1 Fenkolinsiemenet",
    "5.21.1 Fikonfrukt": "5.21.1 Viikuna",
    "5.22.1 Fruktkärnor ": "5.22.1 Hedelmän siemenet ( 2 )",
    "5.22.2 Fruktpressmassa ": "5.22.2 Hedelmäpulppa ( 2 )",
    "5.22.3 Torkad fruktpressmassa ": "5.22.3 Hedelmäpulppa, kuivattu ( 2 )",
    "5.23.1 Kryddkrasse": "5.23.1 Vihanneskrassi",
    "5.24.1 Gräsfrö": "5.24.1 Heinäkasvien siemenet",
    "5.25.1 Druvkärnor": "5.25.1 Viinirypäleiden siemenet",
    "5.25.2 Druvkärnmjöl": "5.25.2 Viinirypäleiden siemenrouhe",
    "5.25.3 Druvpressmassa": "5.25.3 Viinirypälepulppa Viinirypäleiden puristusjäämä",
    "5.25.4 Vattenlösliga restprodukter av druvkärnor": "5.25.4 Viinirypäleiden siementen liukoiset osat",
    "5.26.1 Hasselnöt": "5.26.1 Hasselpähkinä",
    "5.26.2 Hasselnötsexpeller": "5.26.2 Hasselpähkinäpuriste",
    "5.27.1 Pektiner": "5.27.1 Pektiini",
    "5.28.1 Perillafrö": "5.28.1 Perillansiemenet",
    "5.29.1 Pinjenöt": "5.29.1 Pinjansiemenet",
    "5.30.1 Pistaschmandel": "5.30.1 Pistaasipähkinä",
    "5.31.1 Plantagofrö": "5.31.1 Plantagonsiemenet",
    "5.32.1 Rädisfrö": "5.32.1 Retiisinsiemenet",
    "5.33.1 Spenatfrö": "5.33.1 Pinaatinsiemenet",
    "5.34.1 Tistelfrö": "5.34.1 Ohdakkeensiemenet",
    "5.35.1 Tomatpressmassa tomatpressrester": "5.35.1 Tomaattimassa Tomaattipulppa",
    "5.36.1 Röllikafrö": "5.36.1 Siankärsämön siemenet",
    "5.37.1 Aprikoskärnor, expeller": "5.37.1 Aprikoosipuriste",
    "5.38.1 Svart spiskumminexpeller": "5.38.1 Mustakuminapuriste",
    "5.39.1 Gurkörtsfröexpeller": "5.39.1 Purasruohopuriste",
    "5.40.1 Nattljusexpeller": "5.40.1 Helokkipuriste",
    "5.41.1 Granatäpplexpeller": "5.41.1 Granaattiomenapuriste",
    "5.42.1 Valnötskärnexpeller": "5.42.1 Saksanpähkinäpuriste",
    "6. Vallfoder, inbegripet grovfoder, och produkter därav (välj bland följande)": "6. Nurmi- ja karkearehut ja niistä saatavat tuotteet  (valitse alla olevista)",
    "6.1.1 Betblad": "6.1.1 Juurikkaannaatit",
    "6.2.1 Spannmålsväxter ": "6.2.1 Viljakasvit ( 1 )",
    "6.3.1 Spannmålshalm ": "6.3.1 Viljan oljet ( 1 )",
    "6.3.2 Behandlad spannmålshalm  ": "6.3.2 Käsitellyt viljan oljet ( 1 ) ( 2 )",
    "6.4.1 Klövermjöl": "6.4.1 Apilajauho",
    "6.5.1 Vallfodermjöl ; Gräsmjöl ; Grönmjöl ": "6.5.1.1 Nurmirehujauho ( 3 )",
    "6.5.1.2 Gräsmjöl": "6.5.1.2 Ruohojauho ( 3 )",
    "6.5.1.3 Grönmjöl": "6.5.1.3 Viherjauho ( 3 )",
    "6.6.1 Fälttorkat gräs, hö": "6.6.1 Pellolla kuivattu ruoho, Heinä",
    "6.6.2 Hetluftstorkat gräs": "6.6.2 Lämpökuivattu ruoho",
    "6.6.3 Gräs, örter, baljväxter, grönt vallfoder": "6.6.3 Ruoho, yrtit, palkokasvit, vihreä nurmirehu",
    "6.7.1 Hampamjöl": "6.7.1 Hamppujauho",
    "6.7.2 Hampafiber": "6.7.2 Hamppukuitu",
    "6.8.1 Åkerbönshalm": "6.8.1 Härkäpavun oljet",
    "6.9.1 Linhalm": "6.9.1 Pellavan oljet",
    "6.10.1 Lusern; Alfalfa": "6.10.1 Sinimailanen; Alfalfa",
    "6.10.2 Fälttorkad lusern; Fälttorkad alfalfa": "6.10.2 Pellolla kuivattu sinimailanen; Pellolla kuivattu alfalfa",
    "6.10.3 Hetluftstorkad lusern; Hetluftstorkad alfalfa": "6.10.3 Lämpökuivattu sinimailanen; Lämpökuivattu alfalfa",
    "6.10.4 Extruderad lusern; Extruderad alfalfa": "6.10.4 Sinimailanen, ekstrudoitu; Alfalfa, ekstrudoitu",
    "6.10.5 Lusernmjöl ; alfalfamjöl ": "6.10.5 Sinimailasjauho ( 4 ); Alfalfajauho ( 4 )",
    "6.10.6 Lusernpressrester; Alfalfapressrester": "6.10.6 Sinimailasen puristusmassa; Alfalfan puristusmassa",
    "6.10.7 Lusernproteinkoncentrat; Alfalfaproteinkoncentrat": "6.10.7 Tiivistetty sinimailasvalkuainen Tiivistetty alfalfavalkuainen",
    "6.10.8 Vattenlösliga restprodukter av lusern": "6.10.8 Sinimailasen liukoiset aineet",
    "6.11.1 Majsensilage": "6.11.1 Maissisäilörehu",
    "6.12.1 Ärthalm": "6.12.1 Herneen oljet",
    "6.13.1 Raps-/rybshalm": "6.13.1 Rapsin/rypsin oljet",
    "7. Andra växter, alger och produkter därav (välj bland följande)": "7. Muut kasvit, levät ja niistä saatavat tuotteet  (valitse alla olevista)",
    "7.1.1 Alger ": "7.1.1 Levät ( 1 )",
    "7.1.2 Torkade alger ": "7.1.2 Levät, kuivatut ( 1 )",
    "7.1.3 Algmjöl ": "7.1.3 Leväjauho ( 1 )",
    "7.1.4 Algolja ": "7.1.4 Leväöljy ( 1 )",
    "7.1.5 Algextrakt ; Algfraktion ": "7.1.5 Leväuute ( 1 ); Leväjae ( 1 )",
    "7.1.6 Tångmjöl": "7.1.6 Merileväjauho",
    "7.3.1 Bark ": "7.3.1 Puun kuoret ( 1 )",
    "7.4.1 Torkade blommor ": "7.4.1 Kukat ( 1 ), kuivatut",
    "7.5.1 Torkad broccoli": "7.5.1 Parsakaali, kuivattu",
    "7.6.1 Sockerrörsmelass": "7.6.1 (Sokeri)ruokomelassi",
    "7.6.2 Delvis avsockrad sockerrörsmelass": "7.6.2 (Sokeri)ruokomelassi, josta on poistettu sokeri osittain",
    "7.6.3 (Rör)socker sackaros": "7.6.3 Sokeri(ruoko) sakkaroosi",
    "7.6.4 Sockerrörsblast": "7.6.4 Sokeriruokomassa",
    "7.7.1 Torkade blad ": "7.7.1 Lehdet, kuivatut ( 1 )",
    "7.8.1 Lignocellulosa": "7.8.1 Lignoselluloosa",
    "7.8.2 Pulvercellulosa": "7.8.2 Selluloosajauhe",
    "7.9.1 Lakritsrot": "7.9.1 Lakritsijuuri",
    "7.10.1 Mynta": "7.10.1 Minttu",
    "7.11.1 Torkad spenat": "7.11.1 Pinaatti, kuivattu",
    "7.12.1 Yucca schidigera": "7.12.1 Mojaven jukkapalmu",
    "7.12.2 Saft från Yucca schidigera": "7.12.2 Yucca Schidigera -mehu",
    "7.13.1 Vegetabiliskt kol; träkol": "7.13.1 Kasvihiili; puuhiili",
    "7.14.1 Trä ": "7.14.1 Puu ( 1 )",
    "7.15.1 Mjöl av Solanum glaucophyllum": "7.15.1 Vahalehtisestä koisosta saatu jauho",
    "8. Mjölkprodukter och produkter därav (välj bland följande)": "8. Maitotuotteet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "8.1.1 Smör och smörprodukter": "8.1.1 Voi ja voituotteet",
    "8.2.1 Kärnmjölk/Kärnmjölkspulver ": "8.2.1 Piimä",
    "8.2.2 Kärnmjölkspulver": "8.2.2 Piimäjauhe",
    "8.3.1 Kasein": "8.3.1 Kaseiini",
    "8.4.1 Kaseinat": "8.4.1 Kaseinaatti",
    "8.5.1 Ost och ostprodukter": "8.5.1 Juusto ja juustotuotteet",
    "8.6.1 Råmjölk/Råmjölkspulver": "8.6.1 Ternimaito/Ternimaitojauhe",
    "8.7.1 Mejeribiprodukter": "8.7.1 Meijeriteollisuuden sivutuotteet",
    "8.8.1 Fermenterade mjölkprodukter": "8.8.1 Fermentoidut maitotuotteet",
    "8.9.1 Laktos": "8.9.1 Laktoosi",
    "8.10.1 Mjölk": "8.10.1 Maito",
    "8.10.2 Mjölkpulver": "8.10.2 Maitojauhe",
    "8.11.1 Skummjölk": "8.11.1 Rasvaton maito",
    "8.11.2 Skummjölkspulver": "8.11.2 Rasvaton maitojauhe",
    "8.12.1 Mjölkfett": "8.12.1 Maitorasva",
    "8.13.1 Mjölkproteinpulver": "8.13.1 Maitovalkuaisjauhe",
    "8.14.1 Kondenserad och avdunstad mjölk samt produkter därav": "8.14.1 Tiivistetty ja haihdutustiivistetty maito ja siitä saatavat tuotteet",
    "8.15.1 Mjölkpermeat/Mjölkpermeatpulver ": "8.15.1 Maitopermeaatti/Maitopermeaattijauhe ( 1 )",
    "8.16.1 Mjölkretentat/Mjölkretentatpulver ": "8.16.1 Maitoretentaatti/Maitoretentaattijauhe ( 1 )",
    "8.17.1 Vassle": "8.17.1 Hera",
    "8.17.2 Vasslepulver ": "8.17.2 Herajauhe",
    "8.18.1 Laktosreducerad vassle/ Laktosreducerat vasslepulver ": "8.18.1 Laktoositon hera",
    "8.19.1 Vassleprotein": "8.19.1 Heravalkuainen",
    "8.19.2 Vassleproteinpulver": "8.19.2 Heravalkuaisjauhe",
    "8.20.1 Demineraliserad, laktosreducerad vassle/Demineraliserad, laktosreducerat vasslepulver ": "8.20.1 Demineralisoitu, laktoositon hera / Demineralisoitu, laktoositon herajauhe ( 1 )",
    "8.21.1 Vasslepermeat/Vasslepermeatpulver ": "8.21.1 Herapermeaatti/Herapermeaattijauhe ( 1 )",
    "8.22.1 Vassleretentat/Vassleretentatpulver ": "8.22.1 Heraretentaatti/Heraretentaattijauhe ( 1 )",
    "9. Produkter av landdjur och produkter därav (välj bland följande)": "9. Maaeläintuotteet ja niistä edelleen saatavat tuotteet  (valitse alla olevista)",
    "9.1.1 Hela eller delar av varmblodiga landdjur, färska (animaliska biprodukter)": "9.1.1 Maaeläimen ruho tai ruhonosa tuoreena (eläinperäiset sivutuotteet)",
    "9.1.2 Hela eller delar av varmblodiga landdjur, syrbehandlade (animaliska biprodukter)": "9.1.2 Maaeläimen ruho tai ruhonosa hapotettuna (eläinperäiset sivutuotteet)",
    "9.2.1 Djurfett ": "9.2.1 Maaeläinten rasvasta koostuva tuote (1)",
    "9.13.1 Fettgrevar ": "9.13.1 Prosessoitu eläinrasva",
    "9.3.1 Biprodukter från biodling": "9.3.1 Mehiläistenhoidon sivutuotteet",
    "9.4.1.1 Bearbetat animaliskt protein (PAP), svin, kategori 3": "9.4.1.1 Käsitelty eläinvalkuainen (PAP), sika, luokka 3",
    "9.4.1.2 Bearbetat animaliskt protein (PAP), fjäderfä, kategori 3": "9.4.1.2 Käsitelty eläinvalkuainen (PAP), siipikarja, luokka 3",
    "9.4.1.3 Bearbetat animaliskt protein (PAP), insekt, kategori 3": "9.4.1.3 Käsitelty eläinvalkuainen (PAP), hyönteinen, luokka 3",
    "9.4.1.4 Bearbetat animaliskt protein (PAP), idisslare, kategori 3": "9.4.1.4 Käsitelty eläinvalkuainen (PAP), märehtijä, luokka 3",
    "9.4.1.5 Bearbetat animaliskt protein (PAP), andra, kategori 3": "9.4.1.5 Käsitelty eläinvalkuainen (PAP), muu, luokka 3",
    "9.4.2 Köttbenmjöl - Tillverkad av biprodukter från kategori 2": "9.4.2 Lihaluujauho (1) - valmistettu luokan 2 sivutuotteista",
    "9.5.1 Proteiner från gelatinframställning ": "9.5.1 Gelatiinin valmistuksessa saadut valkuaiset ( 2 )",
    "9.6.1 Hydrolyserade animaliska proteiner ": "9.6.1 Hydrolysoidut eläinvalkuaiset ( 1 )",
    "9.7.1 Blodmjöl ": "9.7.1 Verijauho ( 1 )",
    "9.8.1 Blodprodukter": "9.8.1 Verituotteet ( 1 )",
    "9.9.1 Återanvänt köks- och matavfall återvunnet köks- och matavfall": "9.9.1 Ruokajäte Ruoan uusiokäyttö",
    "9.10.1 Kollagen ": "9.10.1 Kollageeni",
    "9.11.1 Fjädermjöl": "9.11.1 Höyhenjauho",
    "9.12.1 Gelatin ": "9.12.1 Gelatiini ( 1 )",
    "9.14.1 Produkter av animaliskt ursprung ": "9.14.1 Entiset eläinperäiset elintarvikkeet",
    "9.15.1 Ägg": "9.15.1 Kananmunat",
    "9.15.2 Äggvita": "9.15.2 Munanvalkuainen",
    "9.15.3 Torkade äggprodukter": "9.15.3 Kuivatut munatuotteet",
    "9.15.4 Sockrat äggpulver": "9.15.4 Sokeroitu munajauhe",
    "9.15.5 Torkat äggskal": "9.15.5 Kuivatut munankuoret",
    "9.16.1 Levande ryggradslösa landdjur ": "9.16.1 Elävät maaselkärangattomat ( 1 )",
    "9.16.2 Döda ryggradslösa landdjur ": "9.16.2 Kuolleet maaselkärangattomat ( 1 )",
    "10. Fisk, andra vattenlevande djur och produkter framställda därav (välj bland följande)": "10. Kalat, muut vesieläimet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "10.1.1 Vattenlevande ryggradslösa djur ": "10.1.1 Vedessä elävät selkärangattomat ( 1 )",
    "10.2.1 Biprodukter från vattenlevande djur från livsmedelslokaler": "10.2.1 Vesieläimistä saatavat sivutuotteet elintarvikehuoneistoista",
    "10.3.1 Skaldjursmjöl ": "10.3.1 Äyriäisjauho ( 2 )",
    "10.4.1 Fisk; färsk eller fryst": "10.4.1.1 Kokonaiset kalat tai niiden osat tuoreena",
    "10.4.1 Fisk; syrbehandlad": "10.4.1.4 Kokonaiset kalat tai niiden osat happokäsiteltynä",
    "10.4.2 Fiskmjöl ": "10.4.2 Kalajauho ( 2 )",
    "10.4.3 Vattenlösliga restprodukter av fisk": "10.4.3 Kalaliimavesi",
    "10.4.4 Hydrolysat av fiskprotein": "10.4.4 Kalavalkuainen, hydrolysoitu",
    "10.4.5 Fiskbensmjöl": "10.4.5 Kalanruotojauho",
    "10.4.6 Fiskolja": "10.4.6 Kalaöljy",
    "10.4.7 Hydrogenerad fiskolja": "10.4.7 Kalaöljy, hydrattu",
    "10.4.8 Fiskoljestearin kylseparerad fiskolja": "10.4.8 Kalaöljysteariini jäähdytetty kalaöljy",
    "10.5.1 Krillolja": "10.5.1 Krilliöljy",
    "10.5.2 Hydrolysat av krillproteinkoncentrat": "10.5.2 Tiivistetty krillivalkuainen, hydrolysoitu",
    "10.6.1 Ringmaskmjöl": "10.6.1 Merimadoista tehty jauho",
    "10.7.1 Mjöl av marina zooplankton": "10.7.1 Merieläinplanktonista tehty jauho",
    "10.7.2 Zooplanktonolja": "10.7.2 Merieläinplanktonista saatu öljy",
    "10.8.1 Blötdjursmjöl": "10.8.1 Nilviäisjauho",
    "10.9.1 Bläckfiskmjöl": "10.9.1 Kalmarijauho",
    "10.10.1 Sjöstjärnemjöl": "10.10.1 Meritähtijauho",
    "11. Mineraler och produkter därav (välj bland följande)": "11. Kivennäiset ja niistä saatavat tuotteet  (valitse alla olevista)",
    "11.1.1 Kalciumkarbonat ; Kalksten": "11.1.1 Kalsiumkarbonaatti ( 1 ); Ruokintakalkki",
    "11.1.2 Kalkhaltiga marina skal": "11.1.2 Kalkkipitoiset merieläinten kuoret",
    "11.1.3 Kalcium- och magnesiumkarbonat": "11.1.3 Kalsium- ja magnesiumkarbonaattien seos",
    "11.1.4 Maerl": "11.1.4 Maerl",
    "11.1.5 Lithothamnium": "11.1.5 Lithothamn",
    "11.1.6 Kalciumklorid": "11.1.6 Kalsiumkloridi",
    "11.1.7 Kalciumhydroxid": "11.1.7 Kalsiumhydroksidi",
    "11.1.8 Vattenfritt kalciumsulfat": "11.1.8 Kalsiumsulfaatti, vedetön",
    "11.1.9 Kalciumsulfathemihydrat": "11.1.9 Kalsiumsulfaattihemihydraatti",
    "11.1.10 Kalciumsulfatdihydrat": "11.1.10 Kalsiumsulfaattidihydraatti",
    "11.1.11 Kalciumsalter av organiska syror ": "11.1.11 Orgaanisten happojen kalsiumsuolat ( 2 )",
    "11.1.12 Kalciumoxid": "11.1.12 Kalsiumoksidi",
    "11.1.13 Kalciumglukonat": "11.1.13 Kalsiumglukonaatti",
    "11.1.15 Kalciumsulfat/Kalciumkarbonat": "11.1.15 Kalsiumsulfaatti/-karbonaatti",
    "11.1.16 Kalciumpidolat": "11.1.16 Kalsiumpidolaatti",
    "11.1.17 Kalciumkarbonat-magnesium oxid": "11.1.17 Kalsiumkarbonaattimagnesiumoksidi",
    "11.2.1 Magnesiumoxid": "11.2.1 Magnesiumoksidi",
    "11.2.2 Magnesiumsulfatheptahydrat": "11.2.2 Magnesiumsulfaattiheptahydraatti",
    "11.2.3 Magnesiumsulfatmonohydrat": "11.2.3 Magnesiumsulfaattimonohydraatti",
    "11.2.4 Vattenfritt magnesiumsulfat": "11.2.4 Magnesiumsulfaatti, vedetön",
    "11.2.5 Magnesiumpropionat": "11.2.5 Magnesiumpropionaatti",
    "11.2.6 Magnesiumklorid": "11.2.6 Magnesiumkloridi",
    "11.2.7 Magnesiumkarbonat": "11.2.7 Magnesiumkarbonaatti",
    "11.2.8 Magnesiumhydroxid": "11.2.8 Magnesiumhydroksidi",
    "11.2.9 Kaliummagnesiumsulfat": "11.2.9 Kaliummagnesiumsulfaatti",
    "11.2.10 Magnesiumsalter av organiska syror ": "11.2.10 Orgaanisten happojen magnesiumsuolat ( 2 )",
    "11.2.11 Magnesiumglukonat": "11.2.11 Magnesiumglukonaatti",
    "11.2.13 Magnesiumpidolat": "11.2.13 Magnesiumpidolaatti",
    "11.3.1 Dikalciumfosfat  ; Kalciumväteortofosfat": "11.3.1 Dikalsiumfosfaatti ( 3 ) Kalsiumvetyortofosfaatti",
    "11.3.2 Monodikalciumfosfat": "11.3.2 Monodikalsiumfosfaatti",
    "11.3.3 Monokalciumfosfat Kalciumtetravätediortofosfat": "11.3.3 Monokalsiumfosfaatti; Kalsiumtetravetydiortofosfaatti",
    "11.3.4 Trikalciumfosfat ; Trikalciumortofosfat": "11.3.4 Trikalsiumfosfaatti; Trikalsiumortofosfaatti",
    "11.3.5 Kalciummagnesiumfosfat": "11.3.5 Kalsiummagnesiumfosfaatti",
    "11.3.6 Avfluorerat fosfat": "11.3.6 Defluorinoitu fosfaatti",
    "11.3.7 Dikalciumpyrofosfat; Dikalciumdifosfat": "11.3.7 Dikalsiumpyrofosfaatti; Dikalsiumdifosfaatti",
    "11.3.8 Magnesiumfosfat": "11.3.8 Magnesiumfosfaatti",
    "11.3.9 Natriumkalciummagnesiumfosfat": "11.3.9 Natriumkalsiummagnesiumfosfaatti",
    "11.3.10 Mononatriumfosfat; Natriumdiväteortofosfat": "11.3.10 Mononatriumfosfaatti; Natriumdivetyortofosfaatti",
    "11.3.11 Dinatriumfosfat; Dinatriumväteortofosfat": "11.3.11 Dinatriumfosfaatti; Dinatriumvetyortofosfaatti",
    "11.3.12 Trinatriumfosfat; Trinatriumortofosfat": "11.3.12 Trinatriumfosfaatti; Trinatriumortofosfaatti",
    "11.3.13 Natriumpyrofosfat; Tetranatriumdifosfat": "11.3.13 Natriumpyrofosfaatti; Tetranatriumdifosfaatti",
    "11.3.14 Monokaliumfosfat; Kaliumdiväteortofosfat": "11.3.14 Monokaliumfosfaatti; Kaliumdivetyortofosfaatti",
    "11.3.15 Dikaliumfosfat; Dikaliumväteortofosfat": "11.3.15 Dikaliumfosfaatti; Dikaliumvetyortofosfaatti",
    "11.3.16 Kalciumnatriumfosfat": "11.3.16 Kalsiumnatriumfosfaatti",
    "11.3.17 Monoammoniumfosfat; Ammoniumdiväteortofosfat": "11.3.17 Monoammoniumfosfaatti; Ammoniumdivetyortofosfaatti",
    "11.3.18 Diammoniumfosfat; Diammoniumväteortofosfat": "11.3.18 Diammoniumfosfaatti; Diammoniumvetyortofosfaatti",
    "11.3.19 Natriumtripolyfosfat; Pentanatriumtrifosfat": "11.3.19 Natriumtripolyfosfaatti; Pentanatriumtrifosfaatti",
    "11.3.20 Natriummagnesiumfosfat": "11.3.20 Natriummagnesiumfosfaatti",
    "11.3.21 Magnesiumhypofosfit": "11.3.21 Magnesiumhypofosfiitti",
    "11.3.22 Benmjöl, fritt från limämne": "11.3.22 Degelatinisoitu luujauho",
    "11.3.23 Benaska": "11.3.23 Luutuhka",
    "11.3.24 Kalciumpolyfosfat": "11.3.24 Kalsiumpolyfosfaatti",
    "11.3.25 Kalciumdivätedifosfat": "11.3.25 Kalsiumdivetydifosfaatti",
    "11.3.26 Magnesiumdivätepyrofosfat": "11.3.26 Magnesiumpyrofosfaatti, hapan",
    "11.3.27 Dinatriumdivätedifosfat": "11.3.27 Dinatriumdivetydifosfaatti",
    "11.3.28 Trinatriumdifosfat": "11.3.28 Trinatriumdifosfaatti",
    "11.3.29 Natriumpolyfosfat; Natriumhexametafosfat": "11.3.29 Natriumpolyfosfaatti; Natriumheksametafosfaatti",
    "11.3.30 Trikaliumfosfat": "11.3.30 Trikaliumfosfaatti",
    "11.3.31 Tetrakaliumdifosfat": "11.3.31 Tetrakaliumdifosfaatti",
    "11.3.32 Pentakaliumtrifosfat": "11.3.32 Pentakaliumtrifosfaatti",
    "11.3.33 Kaliumpolyfosfat": "11.3.33 Kaliumpolyfosfaatti",
    "11.3.34 Kalciumnatriumpolyfosfat": "11.3.34 Kalsiumnatriumpolyfosfaatti",
    "11.4.1 Natriumklorid ": "11.4.1 Natriumkloridi ( 1 )",
    "11.4.2 Natriumbikarbonat Natriumvätekarbonat": "11.4.2 Natriumbikarbonaatti Natriumvetykarbonaatti",
    "11.4.3 Natrium/ammonium(bi)karbonat Natrium/ammonium(väte)karbonat": "11.4.3 Natrium/ammonium(bi)karbonaatti Natrium/ammonium(vety)karbonaatti",
    "11.4.4 Natriumkarbonat": "11.4.4 Natriumkarbonaatti",
    "11.4.5 Natriumseskvikarbonat Trinatriumvätedikarbonat": "11.4.5 Natriumseskvikarbonaatti Trinatriumvetydikarbonaatti",
    "11.4.6 Natriumsulfat": "11.4.6 Natriumsulfaatti",
    "11.4.7 Natriumsalter av organiska syror. ": "11.4.7 Orgaanisten happojen natriumsuolat ( 2 )",
    "11.5.1 Kaliumklorid": "11.5.1 Kaliumkloridi",
    "11.5.2 Kaliumsulfat": "11.5.2 Kaliumsulfaatti",
    "11.5.3 Kaliumkarbonat": "11.5.3 Kaliumkarbonaatti",
    "11.5.4 Kaliumbikarbonat Kaliumvätekarbonat": "11.5.4 Kaliumbikarbonaatti Kaliumvetykarbonaatti",
    "11.5.5 Kaliumsalter av organiska syror. ": "11.5.5 Orgaanisten happojen kaliumsuolat ( 2 )",
    "11.5.6 Kaliumpidolat": "11.5.6 Kaliumpidolaatti",
    "11.6.1 Svavelblomma": "11.6.1 Rikkikukka",
    "11.7.1 Attapulgit": "11.7.1 Attapulgiitti",
    "11.7.2 Kvarts": "11.7.2 Kvartsi",
    "11.7.3 Kristobalit": "11.7.3 Kristobaliitti",
    "11.8.1 Ammoniumsulfat": "11.8.1 Ammoniumsulfaatti",
    "11.8.3 Ammoniumsalter av organiska syror. ": "11.8.3 Orgaanisten happojen ammoniumsuolat ( 2 )",
    "11.8.4 Ammoniumlaktat": "11.8.4 Ammoniumlaktaatti",
    "11.8.5 Ammoniumacetat": "11.8.5 Ammoniumasetaatti",
    "11.9.1 Flintgrus för matsmältning": "11.9.1 Piikiviryyni",
    "11.9.2 Rödsten för matsmältning": "11.9.2 Punakivi (Redstone)",
    "12. Fermenteringsbiprodukter från mikroorganismer (välj bland följande)": "12. Mikro-organismeista saatavat käymisen (sivu)tuotteet  (valitse alla olevista)",
    "12.1.1 Produkt från proteinrika Methylophilus methylotrophus": "12.1.1 Methylophilus methylotrophus -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.2 Produkt från proteinrika Methylococcus capsulatus (Bath), Alca ligenes acidovorans, Bacillus brevis och Bacillus firmus": "12.1.2 Bakteereista Methylococcus capsulatus (Bath), Alca ligenes acidovorans, Bacillus brevis ja Bacillus firmus saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.3 Produkt från proteinrika Escherichia coli": "12.1.3 Escherichia coli -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.4 Produkt från proteinrika Corynebacterium glutamicum": "12.1.4 Corynebacterium glutamicum -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.5 Jäst bryggerijäst": "12.1.5 Hiivat panimohiiva(1)(2)",
    "12.1.6 Myceliumensilage från framställning av penicillin": "12.1.6 Penisilliinin valmistuksesta saatava myseelisäilörehu(1)(2)",
    "12.1.7 Jäst från biodieselframställning": "12.1.7 Hiivat biodieselin valmistuksesta(1)(2)",
    "12.1.8 Produkt från proteinrika arter av Lactobacillus": "12.1.8 Lactobacillus -lajista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.9 Produkt från proteinrika Trichoderma viride": "12.1.9 Trichoderma viride -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.10 Produkt från proteinrika Bacillus subtilis": "12.1.10 Bacillus subtilis -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.11 Produkt från proteinrika Aspergillus oryzae": "12.1.11 Aspergillus oryzae -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.12 Jästprodukter": "12.1.12 Hiivatuotteet(1)(2)",
    "12.2.1 Vinass kondenserad vattenlöslig restprodukt av melass": "12.2.1 Vinassi tiivistetty liukoinen melassi(2)(5)",
    "12.2.2 Biprodukter från framställningen av L-glutaminsyra": "12.2.2 L-glutamiinihapon valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.3 Biprodukter från framställning av L-lysinmonohydroklorid med Brevibacterium lactofermentum": "12.2.3 Brevibacterium lactofermentum -bakteerin tuottaman L-lysiinimonohydrokloridin valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.4 Biprodukter från framställningen av aminosyror med Corynebacterium glutamicum": "12.2.4 Corynebacterium glutamicum -bakteerin tuottamien aminohappojen valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.5 Biprodukter från framställningen av aminosyror med Escherichia coli K12": "12.2.5 Escherichia coli K12 -bakteerin tuottamien aminohappojen valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.6 Biprodukt av enzymframställning med Aspergillus niger": "12.2.6 Aspergillus niger -organismin entsyymituotannon sivutuotteet(2)(5)",
    "12.2.7 Polyhydroxybutyrat från fermentering med Ralstonia eutropha": "12.2.7 Ralstonia eutropha -bakteerin käymisreaktiolla saatava polyhydroksibutyraatti(2)",
    "13. Övrigt (välj bland följande)": "13. Muut  (valitse alla olevista)",
    "13.1.1 Produkter från bageri- och pastaindustrin": "13.1.1 Leipomo- ja pastateollisuuden tuotteet",
    "13.1.2 Produkter från konditorivaruindustrin": "13.1.2 Leivonnaisteollisuuden tuotteet",
    "13.1.3 Produkter från framställning av frukostflingor": "13.1.3 Aamiaismurojen valmistuksessa saatavat tuotteet",
    "13.1.4 Produkter från konfektyrindustrin": "13.1.4 Makeisteollisuuden tuotteet",
    "13.1.5 Produkter från glassindustrin": "13.1.5 Jäätelöteollisuuden tuotteet",
    "13.1.6 Produkter och biprodukter från bearbetning av färska frukter och grönsaker ": "13.1.6 Tuoreiden hedelmien ja vihannesten käsittelyssä saatavat tuotteet ja sivutuotteet ( 1 )",
    "13.1.7 Produkter från bearbetning av växter": "13.1.7 Kasvien käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.8 Produkter från bearbetning av kryddor och smaksättningsämnen ": "13.1.8 Mausteiden ja maustevalmisteiden käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.9 Produkter från bearbetning av örter ": "13.1.9 Yrttien käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.10 Produkter från potatisförädlingsindustrin": "13.1.10 Perunateollisuuden tuotteet",
    "13.1.11 Produkter och biprodukter av såsframställning": "13.1.11 Kastikkeiden valmistuksessa saatavat tuotteet ja sivutuotteet",
    "13.1.12 Produkter och biprodukter från snacksindustrin": "13.1.12 Suolaisten välipalojen valmistuksessa saatavat tuotteet ja sivutuotteet",
    "13.1.13 Produkter från färdigmatsindustrin": "13.1.13 Sellaisenaan syötävien elintarvikkeiden valmistuksessa saatavat tuotteet",
    "13.1.14 Växtbiprodukter från spritframställning": "13.1.14 Väkevien juomien valmistuksessa saatavat kasvisivutuotteet",
    "13.1.15 Foderöl": "13.1.15 Rehuolut",
    "13.1.16 Söta drycker": "13.1.16 Makeutetut juomat",
    "13.1.17 Fruktsirap": "13.1.17 Hedelmäsiirappi",
    "13.1.18 Söt sirap": "13.1.18 Makeutettu siirappi",
    "13.2.1 Karamelliserat socker": "13.2.1 Karamellisokeri",
    "13.2.2 Dextros": "13.2.2 Dekstroosi",
    "13.2.3 Fruktos": "13.2.3 Fruktoosi",
    "13.2.4 Glukossirap": "13.2.4 Glukoosisiirappi",
    "13.2.5 Glukosmelass": "13.2.5 Glukoosimelassi",
    "13.2.6 Xylos": "13.2.6 Ksyloosi",
    "13.2.7 Laktulos": "13.2.7 Laktuloosi",
    "13.2.8 Glukosamin (kitosamin)": "13.2.8 Glukosamiini (Kitosamiini)(6)",
    "13.2.9 Xylo-oligosackarid": "13.2.9 Ksylo-oligosakkaridi",
    "13.2.10 Gluko-oligosackarid": "13.2.10 Gluko-oligosakkaridi",
    "13.3.1 Stärkelse ": "13.3.1 Tärkkelys ( 2 )",
    "13.3.2 Förklistrad stärkelse ": "13.3.2 Esigelatinoitu tärkkelys ( 2 )",
    "13.3.3 Stärkelseblandning ": "13.3.3 Tärkkelysseos ( 2 )",
    "13.3.4 Stärkelsehydrolysatkaka ": "13.3.4 Tärkkelyshydrolysaattikakku ( 2 )",
    "13.3.5 Dextrin": "13.3.5 Dekstriini",
    "13.3.6 Maltodextrin": "13.3.6 Maltodekstriini",
    "13.4.1 Polydextros": "13.4.1 Polydekstroosi",
    "13.5.1 Polyoler": "13.5.1 Polyolit",
    "13.5.2 Isomalt": "13.5.2 Isomaltitoli",
    "13.5.3 Mannitol": "13.5.3 Mannitoli",
    "13.5.4 Xylitol": "13.5.4 Ksylitoli",
    "13.5.5 Sorbitol": "13.5.5 Sorbitoli",
    "13.6.1 Sur olja från kemisk raffinering ": "13.6.1 Kemiallisesta puhdistuksesta saatavat happamat öljyt ( 3 )",
    "13.6.2 Fettsyror förestrade med glycerol ": "13.6.2 Glyserolin rasvahappoesterit ( 4 )",
    "13.6.3 Mono-, di- och triglycerider av fettsyror ": "13.6.3 Rasvahappojen mono-, di- ja triglyseridit ( 4 )",
    "13.6.4 Salter av fettsyror ": "13.6.4 Rasvahappojen suolat ( 4 )",
    "13.6.5 Fettsyredestillat från fysisk raffinering ": "13.6.5 Fysikaalisesta puhdistuksesta saatavat rasvahappotisleet ( 3 )",
    "13.6.6 Obearbetade fettsyror från spaltning ": "13.6.6 Pilkkomisesta saatavat raa'at rasvahapot ( 3 )",
    "13.6.7 Rena destillerade fettsyror från spaltning ": "13.6.7 Pilkkomisesta saatavat puhtaat, tislatut rasvahapot ( 3 )",
    "13.6.8 Soap stocks ": "13.6.8 Saippuointisaostuma ( 3 )",
    "13.6.9 Mono- och diglycerider av fettsyror förestrade med organiska syror  ": "13.6.9 Orgaanisilla hapoilla esteröityjen rasvahappojen mono- ja diglyseridit ( 4 ) ( 5 )",
    "13.6.10 Sackarosestrar av fettsyror ": "13.6.10 Rasvahappojen sakkaroosiesterit ( 4 )",
    "13.6.11 Sackarosglycerider av fettsyror ": "13.6.11 Rasvahappojen sokeriglyseridit ( 4 )",
    "13.6.12 Palmitoylglukosamin": "13.6.12 Palmitoyyliglukosamiini",
    "13.6.13 Salt från laktylat i fettsyror": "13.6.13 Rasvahappojen laktylaattien suola",
    "13.8.1 Rå glycerin rå glycerol": "13.8.1 Glyseriini, raaka Glyseroli, raaka",
    "13.8.2 Glycerin glycerol": "13.8.2 Glyseriini Glyseroli",
    "13.9.1 Metylsulfonylmetan": "13.9.1 Metyylisulfonyylimetaani",
    "13.10.1 Torv": "13.10.1 Turve",
    "13.10.2 Leonardit": "13.10.2 Leonardiitti",
    "13.11.1 Propylenglykol; 1,2-propandiol; propan-1,2-diol": "13.11.1 Propyleeniglykoli; 1,2-propaanidioli; propaani-1,2-dioli",
    "13.11.2 Monoestrar av propylenglykol och fettsyror ": "13.11.2 Propyleeniglykolin ja rasvahappojen monoesterit ( 4 )",
    "13.12.1 Hyaluronsyra": "13.12.1 Hyaluronihappo",
    "13.12.2 Kondroitinsulfat": "13.12.2 Kondroitiinisulfaatti",
    "13.12.3 Glukonsyra": "13.12.3 Glukonihappo",
    "14.0.0 Foderråvaran finns inte i förteckningen": "14.0.0 Rehuaine puuttuu luettelosta",
    "1a_Konserveringsmedel": "1a_Säilöntäaine",
    "1b_Antioxidant": "1b_Hapettumisenestoaine",
    "1c_Emulgeringsmedel": "1c_Emulgointiaine",
    "1d_Stabiliseringsmedel": "1d_Stabilointiaine",
    "1e_Förtjockningsmedel": "1e_Sakeuttamisaine",
    "1f_Geleringsmedel": "1f_Hyytelöitymisaine",
    "1g_Bindemedel": "1g_Sidonta-aine",
    "1h_Ämne för kontroll av kontamination av radionuklider": "1h_Radionuklidikontaminaatiota estävä aine",
    "1i_Klumpförebyggande medel": "1i_Paakkuuntumisenestoaine",
    "1j_pH-regulator": "1j_Happamuuden säätöaine",
    "1k_Ensileringstillsatser: ämnen, enzymer och mikroorganismer inbegripna, avsedda att ingå i foder för att förbättra": "1k_Säilörehun lisäaine (myös entsyymit mikro-organismit, jotka on tarkoitettu lisättäväksi rehuun säilörehun parantamiseksi)",
    "1l_Denatureringsmedel": "1l_Denaturointiaine",
    "1m_Ämne avsett att minska mykotoxinkontaminationen i foder": "1m_Mykotoksiinikontaminaatiota vähentävä aine",
    "2a_Färgämne": "2a_Väriaine",
    "2b_Aromämne": "2b_Aromiaine",
    "3a_Vitamin, provitamin eller kemiskt väldefinierat ämne med likartad effekt": "3a_Vitamiini, provitamiini tai kemiallisesti tarkkaan määritellyt aine, jolla samankaltainen vaikutus",
    "3b_01_Järn (Fe) -förening": "3b_01_Rauta (Fe) -yhdiste",
    "3b_02_Jod (J) -förening": "3b_02_Jodi (J) -yhdiste",
    "3b_03_Kobolt (Co) -förening": "3b_03_Koboltti (Co) -yhdiste",
    "3b_04_Koppar (Cu) -förening": "3b_04_Kupari (Cu) -yhdiste",
    "3b_05_Mangan (Mn) -förening": "3b_05_Mangaani (Mn) -yhdiste",
    "3b_06_Zink (Zn) -förening": "3b_06_Sinkki (Zn) -yhdiste",
    "3b_07_Molybden (Mo) -förening": "3b_07_Molybdeeni (Mo) -yhdiste",
    "3b_08_Selen (Se) -förening": "3b_08_Seleeni (Se) -yhdiste",
    "3c_01_Metionin": "3c_01_Metioniini",
    "3c_02_Lysin": "3c_02_Lysiini",
    "3c_03_Treonin": "3c_03_Treoniini",
    "3c_04_Tryptofan": "3c_04_Tryptofaani",
    "3c_05_Hydroxanaloger av metionin": "3c_05_Metioniinin hydroksianalogit",
    "3c_06_Arginin": "3c_06_Arginiini",
    "3c_07_Valin": "3c_07_Valiini",
    "3c_08_Glutamin": "3c_08_Glutamiini",
    "3c_09_Cystin": "3c_09_Kystiini",
    "3c_10_Leucin": "3c_10_Leusiini",
    "3c_11_Isoleucin": "3c_11_Isoleusiini",
    "3c_12_Histidin": "3c_12_Histidiini",
    "3c_13_Tyrosin": "3c_13_Tyrosiini",
    "3d_Urinämne och derivat därav": "3d_Urea tai sen johdannainen",
    "4a_Smältbarhetsförbättrande medel": "4a_Ruuan sulatusta edistävä aine",
    "4b_Medel som stabiliserar tarmfloran": "4b_Suolistoflooran stabiloimiseen tarkoitettu aine",
    "4c_Ämne med positiv inverkan på miljön": "4c_Ympäristöön suotuisasti vaikuttava aine",
    "4d_Anna zooteknisk tillsats": "4d_Muu eläintuotantoon vaikuttava aine",
    "5_Koccidiostatika och histomonostatika": "5_Kokkidiostaatti tai histomostaatti",
    "01_01_ Förblandning till foderblandningar": "01_01_Esiseos rehuseoksiin",
    "02_01_Förblandning gjort av ensileringstillsatser": "02_01_Säilörehun lisäaineista tehty esiseos",
    "Helfoder": "Täysrehu",
    "Kompletteringsfoder, som kräver härutöver bara grovfoder": "Täydennysrehu, joka vaatii lisäksi vain karkearehua",
    "Proteinkompletteringsfoder": "Valkuaistäydennysrehu",
    "Mineralkompletteringsfoder": "Kivennäistäydennysrehu",
    "Övrig kompletteringsfoder": "Muu täydennysrehu",
    "Slicksten": "Nuolukivi",
    "Foder avsett för särskilda näringsbehov": "Erityisruokavalio",
    "Foder som innehåller läkemedel": "Lääkerehu",
    "Dagsgamla kycklingar och/eller kycklingar": "Untuvikot ja/tai poikaset",
    "Unghönor": "Kananuorikot",
    "Värphöns och/eller avelsfåglar": "Munivat kanat ja/tai siitoslinnut",
    "Broilrar": "Broilerit",
    "Kalkoner": "Kalkkunat",
    "Andra fjäderfäarter än de som nämns ovan": "Muut siipikarjalaji kuin edellä mainitut",
    "Fjäderfä som djurgrupp": "Siipikarja eläinryhmänä",
    "Smågrisar": "Porsaat",
    "Suggor": "Emakot",
    "Slaktsvin": "Lihasiat",
    "Svin som djurgrupp": "Siat eläinryhmänä",
    "Kalvar och/eller unga nötkreatur": "Vasikat ja/tai nuorkarja",
    "Vuxna nötkreatur": "Aikuiset naudat",
    "Nötkreatur som grupp av djur": "Nautakarja eläinryhmänä",
    "Får och/eller getter": "Lampaat ja/tai vuohet",
    "Renar": "Porot",
    "Hästdjur": "Hevoseläimet",
    "Fisk": "Kalat",
    "Insekter": "Hyönteiset",
    "Köttkaniner": "Lihakanit",
    "Andra livsmedelsproducerande djurarter som inte nämns ovan": "Muu elintarviketuotantoeläinlaji kuin edellä mainittu",
    "Livsmedelsproducerande djur som grupp av djur": "Elintarviketuotantoeläimet eläinryhmänä",
    "K Torrt helfoder (så kallade \"knappar\")": "K kuivat täysrehut (ns. nappulat)",
    "K Råa helfoder": "K raa'at täysrehut",
    "K Andra helfoder ": "K muut täysrehut ",
    "K Råa foderråvaror av animaliskt ursprung eller kompletteringsfoder": "K raa'at eläinperäiset rehuaineet tai täydennysrehut",
    "K Bearbetade foderråvaror av animaliskt ursprung eller kompletteringsfoder": "K käsitellyt eläinperäiset rehuaineet tai täydennysrehut",
    "K Tuggben": "K puruluut",
    "K/M Kompletteringsfoder (som vitamin-, mineral- eller spårämnefoder)": "K/M Täydennysrehuseos (mm. vitamiini-, kivennäis- ja hivenainetäydennys)",
    "K/M Foderråvaror av vegetabiliskt ursprung och andra foderråvaror eller tilläggsfoder": "K/M kasviperäiset ja muut rehuaineet tai täydennysrehut",
    "M Helfoder": "M täysrehut",
    "M Foderråvaror av animaliskt ursprung": "M eläinperäiset rehuaineet",
    "Hundar": "Koirat",
    "Katter": "Kissat",
    "Gnagare och kaniner": "Jyrsijät ja kanit",
    "Akvariefiskar": "Akvaariokalat",
    "Burfåglar": "Häkkilinnut",
    "Andra sällskapsdjur än de som nämns ovan": "Muut lemmikkieläimet kuin edellä mainitut",
    "Sällskapsdjur som en djurgrupp": "Usealle lemmikkilajille tarkoitettu",
    "Produkter från havre": "Kaurasta saatavat tuotteet",
    "Produkter från korn": "Ohrasta saatavat tuotteet",
    "Produkter från majs": "Maissista saatavat tuotteet",
    "Produkter från andra spannmål än de som nämns ovan": "Muusta viljasta kuin edellä mainituista saatavat tuotteet",
    "Jordnötter (som sådana eller krossade)": "Maapähkinät (sellaisenaan tai rikottuna)",
    "Solrosfrön (som sådana eller skalade)": "Auringonkukansiemenet (sellaisenaan tai kuorittuna)",
    "Ärtor": "Herne",
    "Fröblandning": "Siemenseos",
    "Fettfröblandning": "Rasvasiemenseos",
    "Talgbollar, -kakor osv.": "Talipallot, -kakut yms.",
    "Foderblandning för vilt": "Rehuseos riistaeläimille",
    "Slicksten för vilt": "Nuolukivi riistaeläimille",
    "Annat än ovan nämnda fodret": "Muu rehu kuin edellä mainittu",
    "Naturfåglar (annat än viltfåglar)": "Ulkolinnut (muut kuin riistalinnut)",
    "Annat naturdjur än viltdjur": "Muut luonnoneläimet kuin riistaeläimet",
    "Viltdjur": "Riistaeläimet",
    "Kompletterande foder som används foderråvara för pälsdjursfoder": "Täydennysrehut turkiseläinten rehun raaka-aineeksi",
    "Foderblandningar för pälsdjur, färska": "Turkiseläinten rehuseos, tuore",
    "Läkemedelsfoder för pälsdjur, färska": "Turkiseläinten lääkerehuseos, tuore",
    "Foderblandningar för hundar i hundgårdar, färska": "Tarhakoirille tarkoitettu rehuseos, tuore",
    "Klass 2-proteinmassa": "luokan 2 valkuaismassa",
    "Klass 2 fett": "luokan 2 rasva",
    "Foder för nötboskap, konventionellt": "Nautakarjan rehut, tavanomainen",
    "Foder för svin och gris, konventionellt": "Sikojen ja porsaiden rehut, tavanomainen",
    "Foder för fjäderfä, konventionellt": "Siipikarjan rehut, tavanomainen",
    "Foder för övriga djur, konventionellt": "Muiden eläinten rehut, tavanomainen",
    "Foder för nötboskap, EKOLOGISKT": "Nautakarjan rehut, LUOMU",
    "Foder för svin och gris, EKOLOGISKT": "Sikojen ja porsaiden rehut, LUOMU",
    "Foder för fjäderfä, EKOLOGISKT": "Siipikarjan rehut, LUOMU",
    "Foder för övriga djur, EKOLOGISKT": "Muiden eläinten rehut, LUOMU",
  }
  private raakaAine = {
    "Välj från menyn som öppnas": "Valitse avautuvasta valikosta",
    "1. Spannmål och produkter därav (välj bland följande)": "1. Viljojen jyvät ja niistä saatavat tuotteet (valitse alla olevista)",
    "1.1.1 Korn": "1.1.1 Ohra",
    "1.1.2 Svällt korn": "1.1.2 Ohra, paisutettu",
    "1.1.3 Rostat korn": "1.1.3 Ohra, paahdettu",
    "1.1.4 Kornflingor": "1.1.4 Ohrahiutaleet",
    "1.1.5 Kornfiber": "1.1.5 Ohrakuitu",
    "1.1.6 Kornskal": "1.1.6 Ohrankuoret",
    "1.1.7 Kornklimjöl": "1.1.7 Ohrarehujauho",
    "1.1.8 Kornprotein": "1.1.8 Ohravalkuainen",
    "1.1.9.1 Kornproteinfoder, torkat": "1.1.9.1 Ohravalkuaisrehu, kuiva",
    "1.1.9.2 Kornproteinfoder, våt": "1.1.9.2 Ohravalkuaisrehu, tuore",
    "1.1.10 Vattenlösliga restprodukter av korn": "1.1.10 Ohran liukoiset aineet",
    "1.1.11 Kornkli": "1.1.11 Ohralese",
    "1.1.12 Flytande kornstärkelse": "1.1.12 Ohratärkkelys, nestemäinen",
    "1.1.13 Sållrester av mältat korn": "1.1.13 Mallasohran seulontajakeet",
    "1.1.14 Mältat korn och maltdamm": "1.1.14 Mallasohra ja mallasjae",
    "1.1.15 Skal från mältat korn": "1.1.15 Mallasohran kuoret",
    "1.1.16 Våta fasta partiklar av korn från destillering": "1.1.16 Ohrarankin kiinteä osa, sellaisenaan",
    "1.1.17 Våta vattenlösliga restprodukter av korn från destillering": "1.1.17 Ohrarankin liukoinen osa",
    "1.1.18 Malt ": "1.1.18 Maltaat ( 1 )",
    "1.1.19 Maltrottrådar": "1.1.19 Mallasidut ( 1 )",
    "1.2.18 Majs ": "1.2.1 Maissi ( 2 )",
    "1.2.2 Majsflingor": "1.2.2 Maissihiutaleet",
    "1.2.3 Majsklimjöl": "1.2.3 Maissirehujauho",
    "1.2.4 Majskli": "1.2.4 Maissilese",
    "1.2.5 Majskolvar": "1.2.5 Maissintähkät",
    "1.2.6 Sållrester av majs": "1.2.6 Maissin seulontajakeet",
    "1.2.7 Majsfiber": "1.2.7 Maissikuitu",
    "1.2.8 Majsgluten": "1.2.8 Maissigluteeni",
    "1.2.9 Majsglutenfoder": "1.2.9 Maissigluteenirehu",
    "1.2.10 Majsgroddar": "1.2.10 Maissinalkio",
    "1.2.11 Majsgroddsexpeller": "1.2.11 Maissinalkiopuriste",
    "1.2.12 Majsgroddsmjöl": "1.2.12 Maissinalkiorouhe",
    "1.2.13 Majsgroddsolja": "1.2.13 Maissinalkioöljy, raaka",
    "1.2.14 Svälld majs": "1.2.14 Maissi, paisutettu",
    "1.2.15 Blötläggningsvätska för majs": "1.2.15 Maissinliotusvesi",
    "1.2.16 Sockermajsensilage": "1.2.16 Sokerimaissintähkäsäilörehu",
    "1.2.17 Krossad majs utan grodd": "1.2.17 Murskattu maissi, josta on poistettu alkiot",
    "1.2.18 Majsgryn": "1.2.18 Maissiryynit(2)",
    "1.3.1 Hirs": "1.3.1 Hirssi",
    "1.4.1 Havre": "1.4.1 Kaura",
    "1.4.2 Skalad havre": "1.4.2 Kaura, kuorittu",
    "1.4.3 Havregryn": "1.4.3 Kaurahiutaleet",
    "1.4.4 Havreklifoder": "1.4.4 Kaurarehujauho /kaurarehu",
    "1.4.5 Havrekli": "1.4.5 Kauralese",
    "1.4.6 Havreskal": "1.4.6 Kaurankuoret",
    "1.4.7 Svälld havre": "1.4.7 Kaura, paisutettu",
    "1.4.8 Rensad havre": "1.4.8 Kaurasuurimot",
    "1.4.9 Havremjöl": "1.4.9 Kaurajauho",
    "1.5.1 Extraherat quinoafrö": "1.5.1 Kvinoansiemenrouhe",
    "1.6.1 Brutet ris": "1.6.1 Rouhittu riisi",
    "1.6.2 Malet ris": "1.6.2 Jauhettu riisi",
    "1.6.3 Förklistrat ris": "1.6.3 Esigelatinoitu riisi",
    "1.6.4 Extruderat ris": "1.6.4 Ekstrudoitu riisi",
    "1.6.5 Risflingor": "1.6.5 Riisihiutaleet",
    "1.6.6 Råris": "1.6.6 Esikuorittu riisi",
    "1.6.7 Malet foderris": "1.6.7 Jauhettu rehuriisi",
    "1.6.8 Rismjöl": "1.6.8 Riisijauho",
    "1.6.9 Rårismjöl": "1.6.9 Esikuorittu riisijauho",
    "1.6.10 Riskli": "1.6.10 Riisilese",
    "1.6.11 Riskli innehållande kalciumkarbonat": "1.6.11 Riisilese, kalsiumkarbonaattipitoinen",
    "1.6.12 Avfettat riskli": "1.6.12 Riisilese, rasvaton",
    "1.6.13 Riskliolja": "1.6.13 Riisileseöljy",
    "1.6.14 Risklimjöl": "1.6.14 Riisirehujauho",
    "1.6.15 Risklimjöl innehållande kalciumkarbonat": "1.6.15 Riisirehujauho, kalsiumkarbonaattipitoinen",
    "1.6.16 Ris": "1.6.16 Riisi",
    "1.6.17 Risgroddar": "1.6.17 Riisinalkio",
    "1.6.18 Risgroddsexpeller": "1.6.18 Riisinalkiopuriste",
    "1.6.20 Risprotein": "1.6.20 Riisivalkuainen",
    "1.6.21 Flytande polerat ris": "1.6.21 Nestemäinen riisirehu",
    "1.6.22 Svällt ris": "1.6.22 Riisi, paisutettu",
    "1.6.23 Fermenterat ris": "1.6.23 Riisi, fermentoitu",
    "1.6.24 Deformerat malt ris/malet ris innehållande kalk": "1.6.24 Epämuodostunut riisi, jauhettu/liitumainen riisi, jauhettu",
    "1.6.25 Omoget malet ris": "1.6.25 Epäkypsä riisi, jauhettu",
    "1.7.1 Råg": "1.7.1 Ruis",
    "1.7.2 Rågklifoder": "1.7.2 Ruisrehujauho",
    "1.7.3 Foderråg": "1.7.3 Ruisrehu",
    "1.7.4 Rågkli": "1.7.4 Ruislese",
    "1.8.1 Sorghum Milo]": "1.8.1 Durra; Milo]",
    "1.9.1 Speltvete": "1.9.1 Spelttivehnä",
    "1.9.2 Speltvetekli": "1.9.2 Spelttilese",
    "1.9.3 Speltveteskal": "1.9.3 Speltinkuoret",
    "1.9.4 Speltveteklimjöl": "1.9.4 Spelttirehujauho",
    "1.10.1 Triticale Rågvete]": "1.10.1 Ruisvehnä",
    "1.11.1 Vete": "1.11.1 Vehnä",
    "1.11.2 Veterottrådar": "1.11.2 Vehnäidut",
    "1.11.3 Förklistrat vete": "1.11.3 Vehnä, esigelatinoitu",
    "1.11.4 Veteklifoder": "1.11.4 Vehnärehujauho",
    "1.11.5 Veteflingor": "1.11.5 Vehnähiutaleet",
    "1.11.6 Fodervete": "1.11.6 Vehnärehu",
    "1.11.7 Vetekli ": "1.11.7 Vehnälese ( 3 )",
    "1.11.8 Mältade fermenterade vetepartiklar": "1.11.8 Mallastetut, fermentoidut vehnän osaset",
    "1.11.10 Vetefiber": "1.11.10 Vehnäkuitu",
    "1.11.11 Vetegroddar": "1.11.11 Vehnänalkio",
    "1.11.12 Fermenterade vetegroddar": "1.11.12 Vehnänalkio, fermentoitu",
    "1.11.13 Vetegroddsexpeller": "1.11.13 Vehnänalkiopuriste",
    "1.11.15 Veteprotein": "1.11.15 Vehnävalkuainen",
    "1.11.16 Veteglutenfoder": "1.11.16 Vehnägluteenirehu",
    "1.11.18 Veteglutenmjöl": "1.11.18 Puhdas vehnägluteeni",
    "1.11.19 Flytande vetestärkelse": "1.11.19 Vehnätärkkelys, nestemäinen",
    "1.11.20 Delvis avsockrad vetestärkelse innehållande protein": "1.11.20 Vehnätärkkelys, valkuaispitoinen, vähäsokerinen",
    "1.11.21 Vattenlösliga restprodukter av vete": "1.11.21 Vehnän liukoiset aineet",
    "1.11.22 Vetejästkoncentrat": "1.11.22 Vehnähiivatiiviste",
    "1.11.23 Sållrester av mältat vete": "1.11.23 Mallastetun vehnän seulontajakeet",
    "1.11.24 Mältat vete och maltdamm": "1.11.24 Mallastettu vehnä ja mallasjae",
    "1.11.25 Skal av mältat vete": "1.11.25 Mallastetun vehnän kuoret",
    "1.12.2 Spannmålsmjöl ": "1.12.2 Viljajauho ( 1 )",
    "1.12.3 Proteinkoncentrat från spannmål ": "1.12.3 Viljavalkuaistiiviste ( 1 )",
    "1.12.4 Sållrester av spannmål ": "1.12.4 Viljan jyvien seulontajakeet ( 1 )",
    "1.12.5 Groddad spannmål ": "1.12.5 Viljanalkiot ( 1 )",
    "1.12.6 Sirap av blötläggningsvätska från spannmål ": "1.12.6 Haihduttamalla tiivistetty rankki ( 1 )",
    "1.12.7 Blöt drank / Torkad drank med tillsats av mäsk; drank och vattenlösliga rester från destillerier] ": "1.12.7 Rankki, tuore / Rankki, tumma; Kuivattu rankki ja liukoiset aineet]",
    "1.12.8 Koncentrerade vattenlösliga restprodukter från destillering / Drank och vattenlösliga restprodukter från destillering": "1.12.8 Rankin liukoisten aineiden tiiviste / Rankki ja liukoiset aineet ",
    "1.12.10 Torkad drank": "1.12.10 Kuivattu rankki",
    "1.12.12 Drav ": "1.12.12 Olutmäski (1 )",
    "1.12.13 Whiskydrank ": "1.12.13 Mäski ( 1 )",
    "1.12.14 Filtrerade mäskprodukter": "1.12.14 Suotopuristinmäski",
    "1.12.15 Pot ale": "1.12.15 Tislausjäännös",
    "1.12.16 Pot ale-sirap": "1.12.16 Tislausjäännöstiiviste",
    "2. Oljehaltiga frön eller frukter samt produkter och biprodukter därav (välj bland följande)": "2. Öljykasvien siemenet, öljypitoiset hedelmät ja niistä saatavat tuotteet (valitse alla olevista)",
    "2.1.1 Babassuexpeller": "2.1.1 Babassupalmupuriste",
    "2.2.1 Cirkamelinafrö": "2.2.1 Camelinansiemenet",
    "2.2.2 Cirkamelinaexpeller": "2.2.2 Camelinapuriste",
    "2.2.3 Cirkamelinamjöl": "2.2.3 Camelinarouhe",
    "2.3.1 Kakaobönskal": "2.3.1 Kaakaopavun ulkokuoret",
    "2.3.2 Kakaoskal": "2.3.2 Kaakaopavun kuoret",
    "2.3.3 Mjöl av delvis skalade kakaobönor": "2.3.3 Kaakaorouhe osittain kuorituista pavuista",
    "2.4.1 Kokos-/Kopraexpeller": "2.4.1 Kookospuriste",
    "2.4.2 Kokos/Kopra, hydrolyserad expeller": "2.4.2 Kookospuriste, hydrolysoitu",
    "2.4.3 Kokos-/Kopramjöl": "2.4.3 Kookosrouhe",
    "2.5.1 Bomullsfrön": "2.5.1 Puuvillansiemenet",
    "2.5.2 Mjöl av delvis skalat bomullsfrö": "2.5.2 Puuvillansiemenrouhe osittain kuorituista siemenistä",
    "2.5.3 Bomullsfröexpeller": "2.5.3 Puuvillansiemenpuriste",
    "2.6.1 Expeller av delvis skalade jordnötter": "2.6.1 Maapähkinäpuriste osittain kuorituista pähkinöistä",
    "2.6.2 Mjöl av delvis skalade jordnötter": "2.6.2 Maapähkinärouhe osittain kuorituista pähkinöistä",
    "2.6.3 Expeller av skalade jordnötter": "2.6.3 Maapähkinäpuriste kuorituista pähkinöistä",
    "2.6.4 Mjöl av skalade jordnötter": "2.6.4 Maapähkinärouhe kuorituista pähkinöistä",
    "2.7.1 Kapockexpeller": "2.7.1 Kapokkipuriste.",
    "2.8.1 Linfrö": "2.8.1 Pellavansiemenet",
    "2.8.2 Linfröexpeller": "2.8.2 Pellavansiemenpuriste",
    "2.8.3 Linfrömjöl": "2.8.3 Pellavansiemenrouhe",
    "2.8.4 Foder av linfröexpeller": "2.8.4 Pellavansiemenpuristerehu",
    "2.8.5 Foder av linfrömjöl": "2.8.5 Pellavansiemenrouherehu",
    "2.8.6 Linfröolja": "2.8.6 Pellavaöljy",
    "2.9.1 Senapskli": "2.9.1 Sinappilese",
    "2.9.2 Senapsfrömjöl": "2.9.2 Sinapinsiemenrouhe",
    "2.10.1 Nigerfrö": "2.10.1 Niger-kasvin siemenet",
    "2.10.2 Nigerfröexpeller": "2.10.2 Niger-siemenpuriste/-kakku",
    "2.11.1 Palmkärnexpeller": "2.11.1 Oliivirouhe",
    "2.11.1 Olivpressmassa": "2.11.2 Oliivirouherehu, rasvaton",
    "2.11.2 Foder av avfettat oljemjöl": "2.11.3 Oliivirouhe, rasvaton",
    "2.11.3 Avfettat olivmjöl": "2.12.1 Palmuydinpuriste",
    "2.12.2 Palmkärnsmjöl": "2.12.2 Palmuydinrouhe",
    "2.12.3 Palmkärnsolja": "2.12.3 Palmuöljy/palmuydinöljy",
    "2.13.1 Pumpa- och squashfrön": "2.13.1 Kurpitsansiemenet",
    "2.13.2 Pumpa- och squashfröexpeller": "2.13.2 Kurpitsansiemenpuriste",
    "2.14.1 Rapsfrön ": "2.14.1 Rapsinsiemenet ( 1 )",
    "2.14.2 Rapsfröexpeller": "2.14.2 Rapsipuriste",
    "2.14.3 Mjöl av rapsfrön": "2.14.3 Rapsirouhe",
    "2.14.4 Extruderade rapsfrön": "2.14.4 Rapsinsiemenet, ekstrudoitu",
    "2.14.5 Proteinkoncentrat av rapsfrö": "2.14.5 Tiivistetty rapsinsiemenvalkuainen",
    "2.14.6 Foder av rapsfröexpeller": "2.14.6 Rapsipuristerehu",
    "2.14.7 Foder av rapsfrömjöl": "2.14.7 Rapsirouherehu",
    "2.14.8 Rapsfröolja": "2.14.8 Rapsiöljy",
    "2.14.9 Rypsfrön ": "2.14.9 Rypsinsiemenet ( 1 )",
    "2.14.10 Rypsfröexpeller": "2.14.10 Rypsipuriste",
    "2.14.11 Mjöl av rypsfrön": "2.14.11 Rypsirouhe",
    "2.14.12 Extruderade rypsfrön": "2.14.12 Rypsinsiemenet, ekstrudoitu",
    "2.14.13 Proteinkoncentrat av rypsfrö": "2.14.13 Tiivistetty rypsinsiemenvalkuainen",
    "2.14.14 Foder av rypsfröexpeller": "2.14.14 Rypsipuristerehu",
    "2.14.15 Foder av rypsfrömjöl": "2.14.15 Rypsirouherehu",
    "2.14.16 Rypsfröolja": "2.14.16 Rypsiöljy",
    "2.15.1 Safflorfrö": "2.15.1 Safflorinsiemenet",
    "2.15.2 Mjöl av delvis skalade safflorfrön": "2.15.2 Safflorirouhe osittain kuorituista siemenistä",
    "2.15.3 Safflorskal": "2.15.3 Safflorinsiementen kuoret",
    "2.16.1 Sesamfrön": "2.16.1 Seesaminsiemenet",
    "2.17.1 Delvis skalade sesamfrön": "2.17.1 Seesaminsiemenet, osittain kuoritut",
    "2.17.2 Sesamfröskal": "2.17.2 Seesaminsiementen kuoret",
    "2.17.3 Sesamfröexpeller": "2.17.3 Seesaminsiemenpuriste",
    "2.18.1 Rostad(e) soja(bönor)": "2.18.1 Paahdetut soija(pavut)",
    "2.18.2 Soja(böns)expeller": "2.18.2 Soija(papu)puriste",
    "2.18.3 Soja(böns)mjöl": "2.18.3 Soija(papu)rouhe",
    "2.18.4 Mjöl av skalad(e) soja(bönor)": "2.18.4 Soija(papu)rouhe kuorituista soijapavuista",
    "2.18.5 Soja(böns)skal": "2.18.5 Soijan (soijapavun) kuoret",
    "2.18.6 Extruderade sojabönor": "2.18.6 Soijapavut, ekstrudoitu",
    "2.18.7 Proteinkoncentrat av soja(böna)": "2.18.7 Soija(papu)valkuaiskonsentraatti",
    "2.18.8 Sojabönspressmassa; Sojabönspasta]": "2.18.8 Soijapapupulppa; Soijapaputahna]",
    "2.18.9 Sojabönsmelass": "2.18.9 Soija(papu)melassi",
    "2.18.10 Biprodukt från sojabönsberedning": "2.18.10 Soijapapujen valmistuksen sivutuote",
    "2.18.11 Soja(bönor)": "2.18.11 Soija(pavut)",
    "2.18.12 Sojabönor, flingor": "2.18.12 Soijapapuhiutaleet",
    "2.18.13 Foder av soja(böns)mjöl": "2.18.13 Soija(papu)rouherehu",
    "2.18.14 Fodermjöl av skalad(e) soja(bönor)": "2.18.14 Soija(papu)rouherehu kuorituista soijapavuista",
    "2.18.15 Fermenterat soja(böns) protein (koncentrat)": "2.18.15 Soija(papu)valkuais(konnsentraatti), fermentoitu",
    "2.18.16 Sojabönolja": "2.18.16 Soijaöljy",
    "2.19.1 Solrosfrön": "2.19.1 Auringonkukansiemenet",
    "2.19.2 Solrosfröexpeller": "2.19.2 Auringonkukkapuriste",
    "2.19.3 Solrosfrömjöl": "2.19.3 Auringonkukkarouhe",
    "2.19.4 Mjöl av skalade solrosfrön": "2.19.4 Auringonkukkarouhe kuorituista siemenistä",
    "2.19.5 Solrosfröskal": "2.19.5 Auringonkukansiementen kuoret",
    "2.19.6 Foder av solrosfrömjöl": "2.19.6 Auringonkukkarouherehu",
    "2.19.7 Fodermjöl av skalade solrosfrön": "2.19.7 Auringonkukkarouherehu kuorituista siemenistä",
    "2.19.8 Fraktioner av solrosfrömjöl med högt proteininnehåll och lågt cellulosainnehåll": "2.19.8 Auringonkukkarouheen runsaasti valkuaista ja vähän selluloosaa sisältävä jae",
    "2.19.9 Fraktioner av solrosfrömjöl med högt cellulosainnehåll": "2.19.9 Auringonkukkarouheen runsaasti selluloosaa sisältävä jae",
    "2.19.10 Solrosfröolja": "2.19.10 Auringonkukansiemenöljy",
    "2.20.1 Vegetabilisk olja och fett (växtarter som inte är kända)": "2.20.1 Kasviöljy ja -rasva (kasvilaji ei tiedossa)",
    "2.20.2 Använda vegetabiliska oljor från livsmedelsindustrin": "2.20.2 Elintarviketehtaiden käytetyt kasviöljyt",
    "2.21.1 Rålecitiner": "2.21.1 Raakalesitiinit",
    "2.22.1 Hampafrö": "2.22.1 Hampunsiemenet",
    "2.22.2 Hampaexpeller": "2.22.2 Hampunsiemenpuriste",
    "2.22.3 Hampaolja": "2.22.3 Hamppuöljy",
    "2.23.1 Vallmofrön": "2.23.1 Unikonsiemenet",
    "2.23.2 Vallmofrömjöl": "2.23.2 Unikonsiemenrouhe",
    "3. Frön från baljväxter och produkter därav (välj bland följande)": "3. Palkokasvien siemenet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "3.1.1 Bönor, rostade": "3.1.1 Paahdetut pavut",
    "3.1.2 Proteinkoncentrat av bönor": "3.1.2 Tiivistetty papuvalkuainen",
    "3.2.1 Johannesbrödfrukt": "3.2.1 Johanneksenleipäpuun palot",
    "3.2.3 Krossad johannesbrödfrukt": "3.2.3 Karkeaksi rouhitut johanneksenleipäpuun palot",
    "3.2.4 Mald johannesbrödfrukt": "3.2.4 Johanneksenleipäpuujauhe; johanneksenleipäpuujauho]",
    "3.2.5 Johannesbrödsgroddar": "3.2.5 Johanneksenleipäpuun alkio",
    "3.2.6 Johannesbrödgroddexpeller": "3.2.6 Johanneksenleipäpuunalkiopuriste",
    "3.2.7 Johannesbrödfrön": "3.2.7 Johanneksenleipäpuun siemenet",
    "3.2.8 Skal från johannesbrödfrön": "3.2.8 Johanneksenleipäpuun siementen kuoret",
    "3.3.1 Kikärter": "3.3.1 Kahviherneet",
    "3.4.1 Ervum ervilia": "3.4.1 Linssivirvilä",
    "3.5.1 Frö av bockhornsklöver": "3.5.1 Sarviapilan siemenet",
    "3.6.1 Guarmjöl": "3.6.1 Guarkumirouhe",
    "3.6.2 Guargroddsmjöl": "3.6.2 Guarkuminalkiorouhe",
    "3.7.1 Åkerbönor": "3.7.1 Härkäpapu",
    "3.7.2 Åkerbönsflingor": "3.7.2 Härkäpapuhiutaleet",
    "3.7.3 Åkerbönsskal": "3.7.3 Härkäpavun kalvot; Härkäpavun kuoret]",
    "3.7.4 Skalade åkerbönor": "3.7.4 Härkäpapu, kuorittu",
    "3.7.5 Åkerbönsprotein": "3.7.5 Härkäpapuvalkuainen",
    "3.8.1 Linser": "3.8.1 Linssit",
    "3.8.2 Linsskal": "3.8.2 Linssin kuoret",
    "3.9.1 Sötlupiner": "3.9.1 Makealupiini",
    "3.9.2 Skalade sötlupiner": "3.9.2 Makealupiini, kuorittu",
    "3.9.3 Filmlupiner; Lupinskal]": "3.9.3 Lupiinin kalvot; Lupiinin kuoret]",
    "3.9.4 Lupinpressmassa": "3.9.4 Lupiinipulppa",
    "3.9.5 Lupinfodermjöl": "3.9.5 Lupiinirehujauho",
    "3.9.6 Lupinprotein": "3.9.6 Lupiinivalkuainen",
    "3.9.7 Lupinproteinmjöl": "3.9.7 Lupiinivalkuaisjauho",
    "3.10.1 Mungbönor": "3.10.1 Mungopapu",
    "3.11.1 Ärter": "3.11.1 Herne",
    "3.11.2 Ärtkli": "3.11.2 Hernelese",
    "3.11.3 Ärtflingor": "3.11.3 Hernehiutaleet",
    "3.11.4 Ärtmjöl": "3.11.4 Hernejauho",
    "3.11.5 Ärtskal": "3.11.5 Herneen kuoret",
    "3.11.6 Skalade ärter": "3.11.6 Herne, kuorittu",
    "3.11.7 Ärtfodermjöl": "3.11.7 Hernerehujauho",
    "3.11.8 Sållrester av ärta": "3.11.8 Herneen seulontajakeet",
    "3.11.9 Ärtprotein": "3.11.9 Hernevalkuainen",
    "3.11.10 Ärtpressmassa": "3.11.10 Hernepulppa",
    "3.11.11 Vattenlösliga restprodukter av ärta": "3.11.11 Herneen liukoiset aineet",
    "3.11.12 Ärtfiber": "3.11.12 Hernekuitu",
    "3.12.1 Vicker": "3.12.1 Virnat",
    "3.13.1 Plattvial": "3.13.1 Peltonätkelmä",
    "3.14.1 Monanthavicker": "3.14.1 Yksikukkavirvilä",
    "4. Rotknölar, rötter och produkter därav (välj bland följande)": "4. Juurimukulat ja juurekset ja niistä saatavat tuotteet  (valitse alla olevista)",
    "4.1.1 Sockerbetor": "4.1.1 Sokerijuurikas",
    "4.1.2 Sockerbetstoppar och rötter": "4.1.2 Sokerijuurikkaan juuren niska ja juuren häntä",
    "4.1.3 (Bet)socker; sackaros]": "4.1.3 (Juurikas)sokeri; Sakkaroosi]",
    "4.1.4 Betmelass (av sockerbeta)": "4.1.4 (Sokeri)juurikasmelassi",
    "4.1.5 Betmelass (av sockerbeta), delvis avsockrad och/eller avbetainiserad": "4.1.5 Erotusmelassi",
    "4.1.6 Isomaltulosmelass": "4.1.6 Isomaltuloosimelassi",
    "4.1.7 Våt betmassa (av sockerbeta)": "4.1.7 Tuore (sokeri)juurikasleike",
    "4.1.8 Pressad betmassa (av sockerbeta)": "4.1.8 Puristettu (sokeri)juurikasleike",
    "4.1.9 Melasserad pressad betmassa (av sockerbeta)": "4.1.9 Puristettu (sokeri)juurikasleike, melassoitu",
    "4.1.10 Torkad betmassa (av sockerbeta)": "4.1.10 Kuivattu (sokeri)juurikasleike",
    "4.1.11 Melasserad torkad betmassa (av sockerbeta)": "4.1.11 Kuivattu (sokeri)juurikasleike, melassoitu",
    "4.1.12 Sockersirap": "4.1.12 Sokerisiirappi",
    "4.1.13 Kokta betbitar (av sockerbeta)": "4.1.13 (Sokeri)juurikkaan palat, keitetyt",
    "4.1.14 Fruktooligosackarider": "4.1.14 Frukto-oligosakkaridit",
    "4.1.15 Betmelass (av sockerbeta), rik på betain, flytande/torkad": "4.1.15 (Sokeri)juurikasmelassi, runsaasti betaiinia sisältävä, nestemäinen/kuivattu(1)",
    "4.1.16 Isomaltulos": "4.1.16 Isomaltuloosi",
    "4.2.1 Rödbetssaft": "4.2.1 Punajuurikasmehu",
    "4.3.1 Morötter": "4.3.1 Porkkana",
    "4.3.2 Ångkokta morotsskal": "4.3.2 Porkkanan kuoret, höyrytetyt",
    "4.3.3 Morotsavskrap": "4.3.3 Kaavitut porkkanat",
    "4.3.4 Morotsflingor": "4.3.4 Porkkanahiutaleet",
    "4.3.5 Torkad morot": "4.3.5 Porkkana, kuivattu",
    "4.3.6 Torkat morotsfoder": "4.3.6 Porkkanarehu, kuivattu",
    "4.3.7 Morotkross": "4.3.7 Porkkanarouhe",
    "4.4.1 Cikoriarot": "4.4.1 Sikurin juuret",
    "4.4.2 Cikoriatoppar och cikoriablast": "4.4.2 Sikurin juuren niska ja juuren häntä",
    "4.4.3 Cikoriafrö": "4.4.3 Sikurinsiemenet",
    "4.4.4 Cikoriapressmassa": "4.4.4 Puristettu sikuripulppa",
    "4.4.5 Torkad cikoriapressmassa": "4.4.5 Kuivattu sikuripulppa",
    "4.4.6 Cikoriarotspulver": "4.4.6 Sikurin juurijauhe",
    "4.4.7 Cikoriamelass": "4.4.7 Sikurimelassi",
    "4.4.8 Cikoriavinass": "4.4.8 Sikurivinassi",
    "4.4.9 Cikoriainulin": "4.4.9 Inuliini",
    "4.4.10 Oligofruktossirap": "4.4.10 Oligofruktoosisiirappi",
    "4.4.11 Torkad oligofruktos": "4.4.11 Oligofruktoosi, kuivattu",
    "4.5.1 Torkad vitlök": "4.5.1 Valkosipuli, kuivattu",
    "4.5.2 Finhakket vitlök": "4.5.2 Valkosipulihiutale",
    "4.5.4 Krossad vitlök": "4.5.4 Valkosipulirouhe",
    "4.6.1 Maniok; tapioka]; kassava]": "4.6.1 Maniokki tapioka]; kassava]",
    "4.6.2 Torkad maniok": "4.6.2 Maniokki, kuivattu",
    "4.6.3 Puffad maniokstärkelse": "4.6.3 Maniokkitärkkelys, paisutettu",
    "4.7.1 Lökpressmassa": "4.7.1 Sipulipulppa",
    "4.7.2 Stekt lök": "4.7.2 Sipuli, paistettu",
    "4.7.3 Vattenlösliga restprodukter av lök": "4.7.3 Sipulin liukoiset aineet, kuivatut",
    "4.8.1 Potatis": "4.8.1 Peruna",
    "4.8.2 Skalad potatis": "4.8.2 Peruna, kuorittu",
    "4.8.3 Ångkokta potatisskal": "4.8.3 Perunan kuoret, höyrytetyt",
    "4.8.4 Råa potatisbitar": "4.8.4 Perunaleikkeet, raa’at",
    "4.8.5 Potatisavskrap": "4.8.5 Perunankuorimassa",
    "4.8.6 Mosad potatis": "4.8.6 Peruna, soseutettu",
    "4.8.7 Potatisflingor": "4.8.7 Perunahiutaleet",
    "4.8.8 Potatispressmassa": "4.8.8 Perunarehu",
    "4.8.9 Torkad potatispressmassa": "4.8.9 Perunarehu, kuivattu",
    "4.8.10 Potatisprotein": "4.8.10 Perunavalkuainen",
    "4.8.11 Hydrolyserat potatisprotein": "4.8.11 Perunavalkuainen, hydrolysoitu",
    "4.8.12 Fermenterat potatisprotein": "4.8.12 Perunavalkuainen, fermentoitu",
    "4.8.13 Flytande fermenterat potatisprotein": "4.8.13 Nestemäinen perunavalkuainen, fermentoitu",
    "4.8.14 Koncentrerad potatissaft": "4.8.14 Tiivistetty perunamehu",
    "4.8.15 Potatisgranulat": "4.8.15 Perunarakeet",
    "4.9.1 Sötpotatis": "4.9.1 Bataatti",
    "4.10.1 Jordärtskocka": "4.10.1 Maa-artisokka; Topinambur]",
    "5. Andra frön och frukter samt produkter därav (välj bland följande)": "5. Muut siemenet ja hedelmät ja niistä saatavat tuotteet  (valitse alla olevista)",
    "5.1.1 Ekollon": "5.1.1 Tammenterho",
    "5.1.2 Skalade ekollon": "5.1.2 Tammenterho, kuorittu",
    "5.2.1 Mandel": "5.2.1 Manteli",
    "5.2.2 Mandelskal": "5.2.2 Mantelinkuoret",
    "5.2.3 Mandelkärnor, expeller": "5.2.3 Mantelipuriste",
    "5.3.1 Anisfrö": "5.3.1 Aniksensiemenet",
    "5.4.1 Torkad äppelpressmassa; Torkade äppelpressrester]": "5.4.1 Omenamassa, kuivattu; Omenapulppa, kuivattu]",
    "5.4.2 Pressad äppelpressmassa; Pressade äppelpressrester]": "5.4.2 Omenamassa, puristettu; Omenapulppa, puristettu]",
    "5.4.3 Äppelmelass": "5.4.3 Omenamelassi",
    "5.5.1 Sockerbetsfrön": "5.5.1 Sokerijuurikkaan siemenet",
    "5.6.1 Bovete": "5.6.1 Tattari",
    "5.6.2 Boveteskal och bovetekli": "5.6.2 Tattarinkuoret ja tattarilese",
    "5.6.3 Bovetefodermjöl": "5.6.3 Tattarirehujauho",
    "5.7.1 Rödkålsfrö": "5.7.1 Punakaalinsiemenet",
    "5.8.1 Kanariegräsfrö": "5.8.1 Kanarianhelpin siemenet",
    "5.9.1 Kumminfrö": "5.9.1 Kuminansiemenet",
    "5.12.1 Krossrester av kastanj": "5.12.1 Rouhitut kastanjat",
    "5.13.1 Citruspressmassa ": "5.13.1 Sitruspulppa ( 1 )",
    "5.13.2 Torkad citronpressmassa ": "5.13.2 Sitruspulppa, kuivattu ( 1 )",
    "5.14.1 Rödklöverfrö": "5.14.1 Puna-apilansiemenet",
    "5.15.1 Vitklöverfrö": "5.15.1 Valkoapilansiemenet",
    "5.15.1 Kaffebönskal": "5.15.1 Kahvinkuoret",
    "5.16.1 Blåklintsfrö": "5.16.1 Ruiskaunokin siemenet",
    "5.17.1 Gurkfrö": "5.17.1 Kurkunsiemenet",
    "5.18.1 Cypressfrö": "5.18.1 Sypressinsiemenet",
    "5.19.1 Dadelfrukt": "5.19.1 Taateli",
    "5.19.2 Dadelfrö": "5.19.2 Taatelinsiemenet",
    "5.20.1 Fänkålsfrö": "5.20.1 Fenkolinsiemenet",
    "5.21.1 Fikonfrukt": "5.21.1 Viikuna",
    "5.22.1 Fruktkärnor ": "5.22.1 Hedelmän siemenet ( 2 )",
    "5.22.2 Fruktpressmassa ": "5.22.2 Hedelmäpulppa ( 2 )",
    "5.22.3 Torkad fruktpressmassa ": "5.22.3 Hedelmäpulppa, kuivattu ( 2 )",
    "5.23.1 Kryddkrasse": "5.23.1 Vihanneskrassi",
    "5.24.1 Gräsfrö": "5.24.1 Heinäkasvien siemenet",
    "5.25.1 Druvkärnor": "5.25.1 Viinirypäleiden siemenet",
    "5.25.2 Druvkärnmjöl": "5.25.2 Viinirypäleiden siemenrouhe",
    "5.25.3 Druvpressmassa": "5.25.3 Viinirypälepulppa Viinirypäleiden puristusjäämä]",
    "5.25.4 Vattenlösliga restprodukter av druvkärnor": "5.25.4 Viinirypäleiden siementen liukoiset osat",
    "5.26.1 Hasselnöt": "5.26.1 Hasselpähkinä",
    "5.26.2 Hasselnötsexpeller": "5.26.2 Hasselpähkinäpuriste",
    "5.27.1 Pektiner": "5.27.1 Pektiini",
    "5.28.1 Perillafrö": "5.28.1 Perillansiemenet",
    "5.29.1 Pinjenöt": "5.29.1 Pinjansiemenet",
    "5.30.1 Pistaschmandel": "5.30.1 Pistaasipähkinä",
    "5.31.1 Plantagofrö": "5.31.1 Plantagonsiemenet",
    "5.32.1 Rädisfrö": "5.32.1 Retiisinsiemenet",
    "5.33.1 Spenatfrö": "5.33.1 Pinaatinsiemenet",
    "5.34.1 Tistelfrö": "5.34.1 Ohdakkeensiemenet",
    "5.35.1 Tomatpressmassa tomatpressrester]": "5.35.1 Tomaattimassa Tomaattipulppa]",
    "5.36.1 Röllikafrö": "5.36.1 Siankärsämön siemenet",
    "5.37.1 Aprikoskärnor, expeller": "5.37.1 Aprikoosipuriste",
    "5.38.1 Svart spiskumminexpeller": "5.38.1 Mustakuminapuriste",
    "5.39.1 Gurkörtsfröexpeller": "5.39.1 Purasruohopuriste",
    "5.40.1 Nattljusexpeller": "5.40.1 Helokkipuriste",
    "5.41.1 Granatäpplexpeller": "5.41.1 Granaattiomenapuriste",
    "5.42.1 Valnötskärnexpeller": "5.42.1 Saksanpähkinäpuriste",
    "6. Vallfoder, inbegripet grovfoder, och produkter därav (välj bland följande)": "6. Nurmi- ja karkearehut ja niistä saatavat tuotteet  (valitse alla olevista)",
    "6.1.1 Betblad": "6.1.1 Juurikkaannaatit",
    "6.2.1 Spannmålsväxter ": "6.2.1 Viljakasvit ( 1 )",
    "6.3.1 Spannmålshalm ": "6.3.1 Viljan oljet ( 1 )",
    "6.3.2 Behandlad spannmålshalm  ": "6.3.2 Käsitellyt viljan oljet ( 1 ) ( 2 )",
    "6.4.1 Klövermjöl": "6.4.1 Apilajauho",
    "6.5.1 Vallfodermjöl ; Gräsmjöl] ; Grönmjöl] ": "6.5.1.1 Nurmirehujauho ( 3 )",
    "6.5.1.2 Gräsmjöl": "6.5.1.2 Ruohojauho ( 3 )",
    "6.5.1.3 Grönmjöl": "6.5.1.3 Viherjauho ( 3 )",
    "6.6.1 Fälttorkat gräs, hö]": "6.6.1 Pellolla kuivattu ruoho, Heinä]",
    "6.6.2 Hetluftstorkat gräs": "6.6.2 Lämpökuivattu ruoho",
    "6.6.3 Gräs, örter, baljväxter, grönt vallfoder]": "6.6.3 Ruoho, yrtit, palkokasvit, vihreä nurmirehu]",
    "6.7.1 Hampamjöl": "6.7.1 Hamppujauho",
    "6.7.2 Hampafiber": "6.7.2 Hamppukuitu",
    "6.8.1 Åkerbönshalm": "6.8.1 Härkäpavun oljet",
    "6.9.1 Linhalm": "6.9.1 Pellavan oljet",
    "6.10.1 Lusern; Alfalfa]": "6.10.1 Sinimailanen; Alfalfa]",
    "6.10.2 Fälttorkad lusern; Fälttorkad alfalfa]": "6.10.2 Pellolla kuivattu sinimailanen; Pellolla kuivattu alfalfa]",
    "6.10.3 Hetluftstorkad lusern; Hetluftstorkad alfalfa]": "6.10.3 Lämpökuivattu sinimailanen; Lämpökuivattu alfalfa]",
    "6.10.4 Extruderad lusern; Extruderad alfalfa]": "6.10.4 Sinimailanen, ekstrudoitu; Alfalfa, ekstrudoitu]",
    "6.10.5 Lusernmjöl ; alfalfamjöl] ": "6.10.5 Sinimailasjauho ( 4 ); Alfalfajauho] ( 4 )",
    "6.10.6 Lusernpressrester; Alfalfapressrester]": "6.10.6 Sinimailasen puristusmassa; Alfalfan puristusmassa]",
    "6.10.7 Lusernproteinkoncentrat; Alfalfaproteinkoncentrat]": "6.10.7 Tiivistetty sinimailasvalkuainen Tiivistetty alfalfavalkuainen]",
    "6.10.8 Vattenlösliga restprodukter av lusern": "6.10.8 Sinimailasen liukoiset aineet",
    "6.11.1 Majsensilage": "6.11.1 Maissisäilörehu",
    "6.12.1 Ärthalm": "6.12.1 Herneen oljet",
    "6.13.1 Raps-/rybshalm": "6.13.1 Rapsin/rypsin oljet",
    "7. Andra växter, alger och produkter därav (välj bland följande)": "7. Muut kasvit, levät ja niistä saatavat tuotteet  (valitse alla olevista)",
    "7.1.1 Alger ": "7.1.1 Levät ( 1 )",
    "7.1.2 Torkade alger ": "7.1.2 Levät, kuivatut ( 1 )",
    "7.1.3 Algmjöl ": "7.1.3 Leväjauho ( 1 )",
    "7.1.4 Algolja ": "7.1.4 Leväöljy ( 1 )",
    "7.1.5 Algextrakt ; Algfraktion] ": "7.1.5 Leväuute ( 1 ); Leväjae] ( 1 )",
    "7.1.6 Tångmjöl": "7.1.6 Merileväjauho",
    "7.3.1 Bark ": "7.3.1 Puun kuoret ( 1 )",
    "7.4.1 Torkade blommor ": "7.4.1 Kukat ( 1 ), kuivatut",
    "7.5.1 Torkad broccoli": "7.5.1 Parsakaali, kuivattu",
    "7.6.1 Sockerrörsmelass": "7.6.1 (Sokeri)ruokomelassi",
    "7.6.2 Delvis avsockrad sockerrörsmelass": "7.6.2 (Sokeri)ruokomelassi, josta on poistettu sokeri osittain",
    "7.6.3 (Rör)socker sackaros]": "7.6.3 Sokeri(ruoko) sakkaroosi]",
    "7.6.4 Sockerrörsblast": "7.6.4 Sokeriruokomassa",
    "7.7.1 Torkade blad ": "7.7.1 Lehdet, kuivatut ( 1 )",
    "7.8.1 Lignocellulosa": "7.8.1 Lignoselluloosa",
    "7.8.2 Pulvercellulosa": "7.8.2 Selluloosajauhe",
    "7.9.1 Lakritsrot": "7.9.1 Lakritsijuuri",
    "7.10.1 Mynta": "7.10.1 Minttu",
    "7.11.1 Torkad spenat": "7.11.1 Pinaatti, kuivattu",
    "7.12.1 Yucca schidigera": "7.12.1 Mojaven jukkapalmu",
    "7.12.2 Saft från Yucca schidigera": "7.12.2 Yucca Schidigera -mehu",
    "7.13.1 Vegetabiliskt kol; träkol]": "7.13.1 Kasvihiili; puuhiili]",
    "7.14.1 Trä ": "7.14.1 Puu ( 1 )",
    "7.15.1 Mjöl av Solanum glaucophyllum": "7.15.1 Vahalehtisestä koisosta saatu jauho",
    "8. Mjölkprodukter och produkter därav (välj bland följande)": "8. Maitotuotteet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "8.1.1 Smör och smörprodukter": "8.1.1 Voi ja voituotteet",
    "8.2.1 Kärnmjölk/Kärnmjölkspulver ": "8.2.1 Piimä",
    "8.2.2 Kärnmjölkspulver": "8.2.2 Piimäjauhe",
    "8.3.1 Kasein": "8.3.1 Kaseiini",
    "8.4.1 Kaseinat": "8.4.1 Kaseinaatti",
    "8.5.1 Ost och ostprodukter": "8.5.1 Juusto ja juustotuotteet",
    "8.6.1 Råmjölk/Råmjölkspulver": "8.6.1 Ternimaito/Ternimaitojauhe",
    "8.7.1 Mejeribiprodukter": "8.7.1 Meijeriteollisuuden sivutuotteet",
    "8.8.1 Fermenterade mjölkprodukter": "8.8.1 Fermentoidut maitotuotteet",
    "8.9.1 Laktos": "8.9.1 Laktoosi",
    "8.10.1 Mjölk": "8.10.1 Maito",
    "8.10.2 Mjölkpulver": "8.10.2 Maitojauhe",
    "8.11.1 Skummjölk": "8.11.1 Rasvaton maito",
    "8.11.2 Skummjölkspulver": "8.11.2 Rasvaton maitojauhe",
    "8.12.1 Mjölkfett": "8.12.1 Maitorasva",
    "8.13.1 Mjölkproteinpulver": "8.13.1 Maitovalkuaisjauhe",
    "8.14.1 Kondenserad och avdunstad mjölk samt produkter därav": "8.14.1 Tiivistetty ja haihdutustiivistetty maito ja siitä saatavat tuotteet",
    "8.15.1 Mjölkpermeat/Mjölkpermeatpulver ": "8.15.1 Maitopermeaatti/Maitopermeaattijauhe ( 1 )",
    "8.16.1 Mjölkretentat/Mjölkretentatpulver ": "8.16.1 Maitoretentaatti/Maitoretentaattijauhe ( 1 )",
    "8.17.1 Vassle": "8.17.1 Hera",
    "8.17.2 Vasslepulver ": "8.17.2 Herajauhe",
    "8.18.1 Laktosreducerad vassle/ Laktosreducerat vasslepulver ": "8.18.1 Laktoositon hera",
    "8.19.1 Vassleprotein": "8.19.1 Heravalkuainen",
    "8.19.2 Vassleproteinpulver": "8.19.2 Heravalkuaisjauhe",
    "8.20.1 Demineraliserad, laktosreducerad vassle/Demineraliserad, laktosreducerat vasslepulver ": "8.20.1 Demineralisoitu, laktoositon hera / Demineralisoitu, laktoositon herajauhe ( 1 )",
    "8.21.1 Vasslepermeat/Vasslepermeatpulver ": "8.21.1 Herapermeaatti/Herapermeaattijauhe ( 1 )",
    "8.22.1 Vassleretentat/Vassleretentatpulver ": "8.22.1 Heraretentaatti/Heraretentaattijauhe ( 1 )",
    "9. Produkter av landdjur och produkter därav (välj bland följande)": "9. Maaeläintuotteet ja niistä edelleen saatavat tuotteet  (valitse alla olevista)",
    "9.1.1 Hela eller delar av varmblodiga landdjur, färska (animaliska biprodukter)": "9.1.1 Maaeläimen ruho tai ruhonosa tuoreena (eläinperäiset sivutuotteet)",
    "9.1.2 Hela eller delar av varmblodiga landdjur, syrbehandlade (animaliska biprodukter)": "9.1.2 Maaeläimen ruho tai ruhonosa hapotettuna (eläinperäiset sivutuotteet)",
    "9.2.1 Djurfett ": "9.2.1 Maaeläinten rasvasta koostuva tuote (1)",
    "9.13.1 Fettgrevar ": "9.13.1 Prosessoitu eläinrasva",
    "9.3.1 Biprodukter från biodling": "9.3.1 Mehiläistenhoidon sivutuotteet",
    "9.4.1.1 Bearbetat animaliskt protein (PAP), svin, kategori 3": "9.4.1.1 Käsitelty eläinvalkuainen (PAP), sika, luokka 3",
    "9.4.1.2 Bearbetat animaliskt protein (PAP), fjäderfä, kategori 3": "9.4.1.2 Käsitelty eläinvalkuainen (PAP), siipikarja, luokka 3",
    "9.4.1.3 Bearbetat animaliskt protein (PAP), insekt, kategori 3": "9.4.1.3 Käsitelty eläinvalkuainen (PAP), hyönteinen, luokka 3",
    "9.4.1.4 Bearbetat animaliskt protein (PAP), idisslare, kategori 3": "9.4.1.4 Käsitelty eläinvalkuainen (PAP), märehtijä, luokka 3",
    "9.4.1.5 Bearbetat animaliskt protein (PAP), andra, kategori 3": "9.4.1.5 Käsitelty eläinvalkuainen (PAP), muu, luokka 3",
    "9.4.2 Köttbenmjöl - Tillverkad av biprodukter från kategori 2": "9.4.2 Lihaluujauho (1) - valmistettu luokan 2 sivutuotteista",
    "9.5.1 Proteiner från gelatinframställning ": "9.5.1 Gelatiinin valmistuksessa saadut valkuaiset ( 2 )",
    "9.6.1 Hydrolyserade animaliska proteiner ": "9.6.1 Hydrolysoidut eläinvalkuaiset ( 1 )",
    "9.7.1 Blodmjöl ": "9.7.1 Verijauho ( 1 )",
    "9.8.1 Blodprodukter": "9.8.1 Verituotteet ( 1 )",
    "9.9.1 Återanvänt köks- och matavfall återvunnet köks- och matavfall]": "9.9.1 Ruokajäte Ruoan uusiokäyttö]",
    "9.10.1 Kollagen ": "9.10.1 Kollageeni",
    "9.11.1 Fjädermjöl": "9.11.1 Höyhenjauho",
    "9.12.1 Gelatin ": "9.12.1 Gelatiini ( 1 )",
    "9.14.1 Produkter av animaliskt ursprung ": "9.14.1 Entiset eläinperäiset elintarvikkeet",
    "9.15.1 Ägg": "9.15.1 Kananmunat",
    "9.15.2 Äggvita": "9.15.2 Munanvalkuainen",
    "9.15.3 Torkade äggprodukter": "9.15.3 Kuivatut munatuotteet",
    "9.15.4 Sockrat äggpulver": "9.15.4 Sokeroitu munajauhe",
    "9.15.5 Torkat äggskal": "9.15.5 Kuivatut munankuoret",
    "9.16.1 Levande ryggradslösa landdjur ": "9.16.1 Elävät maaselkärangattomat ( 1 )",
    "9.16.2 Döda ryggradslösa landdjur ": "9.16.2 Kuolleet maaselkärangattomat ( 1 )",
    "10. Fisk, andra vattenlevande djur och produkter framställda därav (välj bland följande)": "10. Kalat, muut vesieläimet ja niistä saatavat tuotteet  (valitse alla olevista)",
    "10.1.1 Vattenlevande ryggradslösa djur ": "10.1.1 Vedessä elävät selkärangattomat ( 1 )",
    "10.2.1 Biprodukter från vattenlevande djur från livsmedelslokaler": "10.2.1 Vesieläimistä saatavat sivutuotteet elintarvikehuoneistoista",
    "10.3.1 Skaldjursmjöl ": "10.3.1 Äyriäisjauho ( 2 )",
    "10.4.1 Fisk; färsk eller fryst": "10.4.1.1 Kokonaiset kalat tai niiden osat tuoreena",
    "10.4.1 Fisk; syrbehandlad": "10.4.1.4 Kokonaiset kalat tai niiden osat happokäsiteltynä",
    "10.4.2 Fiskmjöl ": "10.4.2 Kalajauho ( 2 )",
    "10.4.3 Vattenlösliga restprodukter av fisk": "10.4.3 Kalaliimavesi",
    "10.4.4 Hydrolysat av fiskprotein": "10.4.4 Kalavalkuainen, hydrolysoitu",
    "10.4.5 Fiskbensmjöl": "10.4.5 Kalanruotojauho",
    "10.4.6 Fiskolja": "10.4.6 Kalaöljy",
    "10.4.7 Hydrogenerad fiskolja": "10.4.7 Kalaöljy, hydrattu",
    "10.4.8 Fiskoljestearin kylseparerad fiskolja]": "10.4.8 Kalaöljysteariini jäähdytetty kalaöljy]",
    "10.5.1 Krillolja": "10.5.1 Krilliöljy",
    "10.5.2 Hydrolysat av krillproteinkoncentrat": "10.5.2 Tiivistetty krillivalkuainen, hydrolysoitu",
    "10.6.1 Ringmaskmjöl": "10.6.1 Merimadoista tehty jauho",
    "10.7.1 Mjöl av marina zooplankton": "10.7.1 Merieläinplanktonista tehty jauho",
    "10.7.2 Zooplanktonolja": "10.7.2 Merieläinplanktonista saatu öljy",
    "10.8.1 Blötdjursmjöl": "10.8.1 Nilviäisjauho",
    "10.9.1 Bläckfiskmjöl": "10.9.1 Kalmarijauho",
    "10.10.1 Sjöstjärnemjöl": "10.10.1 Meritähtijauho",
    "11. Mineraler och produkter därav (välj bland följande)": "11. Kivennäiset ja niistä saatavat tuotteet  (valitse alla olevista)",
    "11.1.1 Kalciumkarbonat ; Kalksten]": "11.1.1 Kalsiumkarbonaatti ( 1 ); Ruokintakalkki]",
    "11.1.2 Kalkhaltiga marina skal": "11.1.2 Kalkkipitoiset merieläinten kuoret",
    "11.1.3 Kalcium- och magnesiumkarbonat": "11.1.3 Kalsium- ja magnesiumkarbonaattien seos",
    "11.1.4 Maerl": "11.1.4 Maerl",
    "11.1.5 Lithothamnium": "11.1.5 Lithothamn",
    "11.1.6 Kalciumklorid": "11.1.6 Kalsiumkloridi",
    "11.1.7 Kalciumhydroxid": "11.1.7 Kalsiumhydroksidi",
    "11.1.8 Vattenfritt kalciumsulfat": "11.1.8 Kalsiumsulfaatti, vedetön",
    "11.1.9 Kalciumsulfathemihydrat": "11.1.9 Kalsiumsulfaattihemihydraatti",
    "11.1.10 Kalciumsulfatdihydrat": "11.1.10 Kalsiumsulfaattidihydraatti",
    "11.1.11 Kalciumsalter av organiska syror ": "11.1.11 Orgaanisten happojen kalsiumsuolat ( 2 )",
    "11.1.12 Kalciumoxid": "11.1.12 Kalsiumoksidi",
    "11.1.13 Kalciumglukonat": "11.1.13 Kalsiumglukonaatti",
    "11.1.15 Kalciumsulfat/Kalciumkarbonat": "11.1.15 Kalsiumsulfaatti/-karbonaatti",
    "11.1.16 Kalciumpidolat": "11.1.16 Kalsiumpidolaatti",
    "11.1.17 Kalciumkarbonat-magnesium oxid": "11.1.17 Kalsiumkarbonaattimagnesiumoksidi",
    "11.2.1 Magnesiumoxid": "11.2.1 Magnesiumoksidi",
    "11.2.2 Magnesiumsulfatheptahydrat": "11.2.2 Magnesiumsulfaattiheptahydraatti",
    "11.2.3 Magnesiumsulfatmonohydrat": "11.2.3 Magnesiumsulfaattimonohydraatti",
    "11.2.4 Vattenfritt magnesiumsulfat": "11.2.4 Magnesiumsulfaatti, vedetön",
    "11.2.5 Magnesiumpropionat": "11.2.5 Magnesiumpropionaatti",
    "11.2.6 Magnesiumklorid": "11.2.6 Magnesiumkloridi",
    "11.2.7 Magnesiumkarbonat": "11.2.7 Magnesiumkarbonaatti",
    "11.2.8 Magnesiumhydroxid": "11.2.8 Magnesiumhydroksidi",
    "11.2.9 Kaliummagnesiumsulfat": "11.2.9 Kaliummagnesiumsulfaatti",
    "11.2.10 Magnesiumsalter av organiska syror ": "11.2.10 Orgaanisten happojen magnesiumsuolat ( 2 )",
    "11.2.11 Magnesiumglukonat": "11.2.11 Magnesiumglukonaatti",
    "11.2.13 Magnesiumpidolat": "11.2.13 Magnesiumpidolaatti",
    "11.3.1 Dikalciumfosfat  ; Kalciumväteortofosfat]": "11.3.1 Dikalsiumfosfaatti ( 3 ) Kalsiumvetyortofosfaatti]",
    "11.3.2 Monodikalciumfosfat": "11.3.2 Monodikalsiumfosfaatti",
    "11.3.3 Monokalciumfosfat Kalciumtetravätediortofosfat]": "11.3.3 Monokalsiumfosfaatti; Kalsiumtetravetydiortofosfaatti]",
    "11.3.4 Trikalciumfosfat ; Trikalciumortofosfat]": "11.3.4 Trikalsiumfosfaatti; Trikalsiumortofosfaatti]",
    "11.3.5 Kalciummagnesiumfosfat": "11.3.5 Kalsiummagnesiumfosfaatti",
    "11.3.6 Avfluorerat fosfat": "11.3.6 Defluorinoitu fosfaatti",
    "11.3.7 Dikalciumpyrofosfat; Dikalciumdifosfat]": "11.3.7 Dikalsiumpyrofosfaatti; Dikalsiumdifosfaatti]",
    "11.3.8 Magnesiumfosfat": "11.3.8 Magnesiumfosfaatti",
    "11.3.9 Natriumkalciummagnesiumfosfat": "11.3.9 Natriumkalsiummagnesiumfosfaatti",
    "11.3.10 Mononatriumfosfat; Natriumdiväteortofosfat]": "11.3.10 Mononatriumfosfaatti; Natriumdivetyortofosfaatti]",
    "11.3.11 Dinatriumfosfat; Dinatriumväteortofosfat]": "11.3.11 Dinatriumfosfaatti; Dinatriumvetyortofosfaatti]",
    "11.3.12 Trinatriumfosfat; Trinatriumortofosfat]": "11.3.12 Trinatriumfosfaatti; Trinatriumortofosfaatti]",
    "11.3.13 Natriumpyrofosfat; Tetranatriumdifosfat]": "11.3.13 Natriumpyrofosfaatti; Tetranatriumdifosfaatti]",
    "11.3.14 Monokaliumfosfat; Kaliumdiväteortofosfat]": "11.3.14 Monokaliumfosfaatti; Kaliumdivetyortofosfaatti]",
    "11.3.15 Dikaliumfosfat; Dikaliumväteortofosfat]": "11.3.15 Dikaliumfosfaatti; Dikaliumvetyortofosfaatti]",
    "11.3.16 Kalciumnatriumfosfat": "11.3.16 Kalsiumnatriumfosfaatti",
    "11.3.17 Monoammoniumfosfat; Ammoniumdiväteortofosfat]": "11.3.17 Monoammoniumfosfaatti; Ammoniumdivetyortofosfaatti]",
    "11.3.18 Diammoniumfosfat; Diammoniumväteortofosfat]": "11.3.18 Diammoniumfosfaatti; Diammoniumvetyortofosfaatti]",
    "11.3.19 Natriumtripolyfosfat; Pentanatriumtrifosfat]": "11.3.19 Natriumtripolyfosfaatti; Pentanatriumtrifosfaatti]",
    "11.3.20 Natriummagnesiumfosfat": "11.3.20 Natriummagnesiumfosfaatti",
    "11.3.21 Magnesiumhypofosfit": "11.3.21 Magnesiumhypofosfiitti",
    "11.3.22 Benmjöl, fritt från limämne": "11.3.22 Degelatinisoitu luujauho",
    "11.3.23 Benaska": "11.3.23 Luutuhka",
    "11.3.24 Kalciumpolyfosfat": "11.3.24 Kalsiumpolyfosfaatti",
    "11.3.25 Kalciumdivätedifosfat": "11.3.25 Kalsiumdivetydifosfaatti",
    "11.3.26 Magnesiumdivätepyrofosfat": "11.3.26 Magnesiumpyrofosfaatti, hapan",
    "11.3.27 Dinatriumdivätedifosfat": "11.3.27 Dinatriumdivetydifosfaatti",
    "11.3.28 Trinatriumdifosfat": "11.3.28 Trinatriumdifosfaatti",
    "11.3.29 Natriumpolyfosfat; Natriumhexametafosfat]": "11.3.29 Natriumpolyfosfaatti; Natriumheksametafosfaatti]",
    "11.3.30 Trikaliumfosfat": "11.3.30 Trikaliumfosfaatti",
    "11.3.31 Tetrakaliumdifosfat": "11.3.31 Tetrakaliumdifosfaatti",
    "11.3.32 Pentakaliumtrifosfat": "11.3.32 Pentakaliumtrifosfaatti",
    "11.3.33 Kaliumpolyfosfat": "11.3.33 Kaliumpolyfosfaatti",
    "11.3.34 Kalciumnatriumpolyfosfat": "11.3.34 Kalsiumnatriumpolyfosfaatti",
    "11.4.1 Natriumklorid ": "11.4.1 Natriumkloridi ( 1 )",
    "11.4.2 Natriumbikarbonat Natriumvätekarbonat]": "11.4.2 Natriumbikarbonaatti Natriumvetykarbonaatti]",
    "11.4.3 Natrium/ammonium(bi)karbonat Natrium/ammonium(väte)karbonat]": "11.4.3 Natrium/ammonium(bi)karbonaatti Natrium/ammonium(vety)karbonaatti]",
    "11.4.4 Natriumkarbonat": "11.4.4 Natriumkarbonaatti",
    "11.4.5 Natriumseskvikarbonat Trinatriumvätedikarbonat]": "11.4.5 Natriumseskvikarbonaatti Trinatriumvetydikarbonaatti]",
    "11.4.6 Natriumsulfat": "11.4.6 Natriumsulfaatti",
    "11.4.7 Natriumsalter av organiska syror. ": "11.4.7 Orgaanisten happojen natriumsuolat ( 2 )",
    "11.5.1 Kaliumklorid": "11.5.1 Kaliumkloridi",
    "11.5.2 Kaliumsulfat": "11.5.2 Kaliumsulfaatti",
    "11.5.3 Kaliumkarbonat": "11.5.3 Kaliumkarbonaatti",
    "11.5.4 Kaliumbikarbonat Kaliumvätekarbonat]": "11.5.4 Kaliumbikarbonaatti Kaliumvetykarbonaatti]",
    "11.5.5 Kaliumsalter av organiska syror. ": "11.5.5 Orgaanisten happojen kaliumsuolat ( 2 )",
    "11.5.6 Kaliumpidolat": "11.5.6 Kaliumpidolaatti",
    "11.6.1 Svavelblomma": "11.6.1 Rikkikukka",
    "11.7.1 Attapulgit": "11.7.1 Attapulgiitti",
    "11.7.2 Kvarts": "11.7.2 Kvartsi",
    "11.7.3 Kristobalit": "11.7.3 Kristobaliitti",
    "11.8.1 Ammoniumsulfat": "11.8.1 Ammoniumsulfaatti",
    "11.8.3 Ammoniumsalter av organiska syror. ": "11.8.3 Orgaanisten happojen ammoniumsuolat ( 2 )",
    "11.8.4 Ammoniumlaktat": "11.8.4 Ammoniumlaktaatti",
    "11.8.5 Ammoniumacetat": "11.8.5 Ammoniumasetaatti",
    "11.9.1 Flintgrus för matsmältning]": "11.9.1 Piikiviryyni",
    "11.9.2 Rödsten för matsmältning]": "11.9.2 Punakivi (Redstone)",
    "12. Fermenteringsbiprodukter från mikroorganismer (välj bland följande)": "12. Mikro-organismeista saatavat käymisen (sivu)tuotteet  (valitse alla olevista)",
    "12.1.1 Produkt från proteinrika Methylophilus methylotrophus": "12.1.1 Methylophilus methylotrophus -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.2 Produkt från proteinrika Methylococcus capsulatus (Bath), Alca ligenes acidovorans, Bacillus brevis och Bacillus firmus": "12.1.2 Bakteereista Methylococcus capsulatus (Bath), Alca ligenes acidovorans, Bacillus brevis ja Bacillus firmus saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.3 Produkt från proteinrika Escherichia coli": "12.1.3 Escherichia coli -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.4 Produkt från proteinrika Corynebacterium glutamicum": "12.1.4 Corynebacterium glutamicum -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.5 Jäst bryggerijäst]": "12.1.5 Hiivat panimohiiva](1)(2)",
    "12.1.6 Myceliumensilage från framställning av penicillin": "12.1.6 Penisilliinin valmistuksesta saatava myseelisäilörehu(1)(2)",
    "12.1.7 Jäst från biodieselframställning": "12.1.7 Hiivat biodieselin valmistuksesta(1)(2)",
    "12.1.8 Produkt från proteinrika arter av Lactobacillus": "12.1.8 Lactobacillus -lajista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.9 Produkt från proteinrika Trichoderma viride": "12.1.9 Trichoderma viride -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.10 Produkt från proteinrika Bacillus subtilis": "12.1.10 Bacillus subtilis -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.11 Produkt från proteinrika Aspergillus oryzae": "12.1.11 Aspergillus oryzae -bakteerista saatu runsaasti valkuaista sisältävä tuote(1)(2)",
    "12.1.12 Jästprodukter": "12.1.12 Hiivatuotteet(1)(2)",
    "12.2.1 Vinass kondenserad vattenlöslig restprodukt av melass]": "12.2.1 Vinassi tiivistetty liukoinen melassi](2)(5)",
    "12.2.2 Biprodukter från framställningen av L-glutaminsyra": "12.2.2 L-glutamiinihapon valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.3 Biprodukter från framställning av L-lysinmonohydroklorid med Brevibacterium lactofermentum": "12.2.3 Brevibacterium lactofermentum -bakteerin tuottaman L-lysiinimonohydrokloridin valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.4 Biprodukter från framställningen av aminosyror med Corynebacterium glutamicum": "12.2.4 Corynebacterium glutamicum -bakteerin tuottamien aminohappojen valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.5 Biprodukter från framställningen av aminosyror med Escherichia coli K12": "12.2.5 Escherichia coli K12 -bakteerin tuottamien aminohappojen valmistuksesta saatavat sivutuotteet(2)(5)",
    "12.2.6 Biprodukt av enzymframställning med Aspergillus niger": "12.2.6 Aspergillus niger -organismin entsyymituotannon sivutuotteet(2)(5)",
    "12.2.7 Polyhydroxybutyrat från fermentering med Ralstonia eutropha": "12.2.7 Ralstonia eutropha -bakteerin käymisreaktiolla saatava polyhydroksibutyraatti(2)",
    "13. Övrigt (välj bland följande)": "13. Muut  (valitse alla olevista)",
    "13.1.1 Produkter från bageri- och pastaindustrin": "13.1.1 Leipomo- ja pastateollisuuden tuotteet",
    "13.1.2 Produkter från konditorivaruindustrin": "13.1.2 Leivonnaisteollisuuden tuotteet",
    "13.1.3 Produkter från framställning av frukostflingor": "13.1.3 Aamiaismurojen valmistuksessa saatavat tuotteet",
    "13.1.4 Produkter från konfektyrindustrin": "13.1.4 Makeisteollisuuden tuotteet",
    "13.1.5 Produkter från glassindustrin": "13.1.5 Jäätelöteollisuuden tuotteet",
    "13.1.6 Produkter och biprodukter från bearbetning av färska frukter och grönsaker ": "13.1.6 Tuoreiden hedelmien ja vihannesten käsittelyssä saatavat tuotteet ja sivutuotteet ( 1 )",
    "13.1.7 Produkter från bearbetning av växter": "13.1.7 Kasvien käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.8 Produkter från bearbetning av kryddor och smaksättningsämnen ": "13.1.8 Mausteiden ja maustevalmisteiden käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.9 Produkter från bearbetning av örter ": "13.1.9 Yrttien käsittelyssä saatavat tuotteet ( 1 )",
    "13.1.10 Produkter från potatisförädlingsindustrin": "13.1.10 Perunateollisuuden tuotteet",
    "13.1.11 Produkter och biprodukter av såsframställning": "13.1.11 Kastikkeiden valmistuksessa saatavat tuotteet ja sivutuotteet",
    "13.1.12 Produkter och biprodukter från snacksindustrin": "13.1.12 Suolaisten välipalojen valmistuksessa saatavat tuotteet ja sivutuotteet",
    "13.1.13 Produkter från färdigmatsindustrin": "13.1.13 Sellaisenaan syötävien elintarvikkeiden valmistuksessa saatavat tuotteet",
    "13.1.14 Växtbiprodukter från spritframställning": "13.1.14 Väkevien juomien valmistuksessa saatavat kasvisivutuotteet",
    "13.1.15 Foderöl": "13.1.15 Rehuolut",
    "13.1.16 Söta drycker": "13.1.16 Makeutetut juomat",
    "13.1.17 Fruktsirap": "13.1.17 Hedelmäsiirappi",
    "13.1.18 Söt sirap": "13.1.18 Makeutettu siirappi",
    "13.2.1 Karamelliserat socker": "13.2.1 Karamellisokeri",
    "13.2.2 Dextros": "13.2.2 Dekstroosi",
    "13.2.3 Fruktos": "13.2.3 Fruktoosi",
    "13.2.4 Glukossirap": "13.2.4 Glukoosisiirappi",
    "13.2.5 Glukosmelass": "13.2.5 Glukoosimelassi",
    "13.2.6 Xylos": "13.2.6 Ksyloosi",
    "13.2.7 Laktulos": "13.2.7 Laktuloosi",
    "13.2.8 Glukosamin (kitosamin)": "13.2.8 Glukosamiini (Kitosamiini)(6)",
    "13.2.9 Xylo-oligosackarid": "13.2.9 Ksylo-oligosakkaridi",
    "13.2.10 Gluko-oligosackarid": "13.2.10 Gluko-oligosakkaridi",
    "13.3.1 Stärkelse ": "13.3.1 Tärkkelys ( 2 )",
    "13.3.2 Förklistrad stärkelse ": "13.3.2 Esigelatinoitu tärkkelys ( 2 )",
    "13.3.3 Stärkelseblandning ": "13.3.3 Tärkkelysseos ( 2 )",
    "13.3.4 Stärkelsehydrolysatkaka ": "13.3.4 Tärkkelyshydrolysaattikakku ( 2 )",
    "13.3.5 Dextrin": "13.3.5 Dekstriini",
    "13.3.6 Maltodextrin": "13.3.6 Maltodekstriini",
    "13.4.1 Polydextros": "13.4.1 Polydekstroosi",
    "13.5.1 Polyoler": "13.5.1 Polyolit",
    "13.5.2 Isomalt": "13.5.2 Isomaltitoli",
    "13.5.3 Mannitol": "13.5.3 Mannitoli",
    "13.5.4 Xylitol": "13.5.4 Ksylitoli",
    "13.5.5 Sorbitol": "13.5.5 Sorbitoli",
    "13.6.1 Sur olja från kemisk raffinering ": "13.6.1 Kemiallisesta puhdistuksesta saatavat happamat öljyt ( 3 )",
    "13.6.2 Fettsyror förestrade med glycerol ": "13.6.2 Glyserolin rasvahappoesterit ( 4 )",
    "13.6.3 Mono-, di- och triglycerider av fettsyror ": "13.6.3 Rasvahappojen mono-, di- ja triglyseridit ( 4 )",
    "13.6.4 Salter av fettsyror ": "13.6.4 Rasvahappojen suolat ( 4 )",
    "13.6.5 Fettsyredestillat från fysisk raffinering ": "13.6.5 Fysikaalisesta puhdistuksesta saatavat rasvahappotisleet ( 3 )",
    "13.6.6 Obearbetade fettsyror från spaltning ": "13.6.6 Pilkkomisesta saatavat raa’at rasvahapot ( 3 )",
    "13.6.7 Rena destillerade fettsyror från spaltning ": "13.6.7 Pilkkomisesta saatavat puhtaat, tislatut rasvahapot ( 3 )",
    "13.6.8 Soap stocks ": "13.6.8 Saippuointisaostuma ( 3 )",
    "13.6.9 Mono- och diglycerider av fettsyror förestrade med organiska syror  ": "13.6.9 Orgaanisilla hapoilla esteröityjen rasvahappojen mono- ja diglyseridit ( 4 ) ( 5 )",
    "13.6.10 Sackarosestrar av fettsyror ": "13.6.10 Rasvahappojen sakkaroosiesterit ( 4 )",
    "13.6.11 Sackarosglycerider av fettsyror ": "13.6.11 Rasvahappojen sokeriglyseridit ( 4 )",
    "13.6.12 Palmitoylglukosamin": "13.6.12 Palmitoyyliglukosamiini",
    "13.6.13 Salt från laktylat i fettsyror": "13.6.13 Rasvahappojen laktylaattien suola",
    "13.8.1 Rå glycerin rå glycerol]": "13.8.1 Glyseriini, raaka Glyseroli, raaka]",
    "13.8.2 Glycerin glycerol]": "13.8.2 Glyseriini Glyseroli]",
    "13.9.1 Metylsulfonylmetan": "13.9.1 Metyylisulfonyylimetaani",
    "13.10.1 Torv": "13.10.1 Turve",
    "13.10.2 Leonardit": "13.10.2 Leonardiitti",
    "13.11.1 Propylenglykol; 1,2-propandiol; propan-1,2-diol]": "13.11.1 Propyleeniglykoli; 1,2-propaanidioli; propaani-1,2-dioli]",
    "13.11.2 Monoestrar av propylenglykol och fettsyror ": "13.11.2 Propyleeniglykolin ja rasvahappojen monoesterit ( 4 )",
    "13.12.1 Hyaluronsyra": "13.12.1 Hyaluronihappo",
    "13.12.2 Kondroitinsulfat": "13.12.2 Kondroitiinisulfaatti",
    "13.12.3 Glukonsyra": "13.12.3 Glukonihappo",
    "14.0.0 Foderråvaran finns inte i förteckningen": "14.0.0 Rehuaine puuttuu luettelosta",
    "1a_Konserveringsmedel": "1a_Säilöntäaine",
    "1b_Antioxidant": "1b_Hapettumisenestoaine",
    "1c_Emulgeringsmedel": "1c_Emulgointiaine",
    "1d_Stabiliseringsmedel": "1d_Stabilointiaine",
    "1e_Förtjockningsmedel": "1e_Sakeuttamisaine",
    "1f_Geleringsmedel": "1f_Hyytelöitymisaine",
    "1g_Bindemedel": "1g_Sidonta-aine",
    "1h_Ämne för kontroll av kontamination av radionuklider": "1h_Radionuklidikontaminaatiota estävä aine",
    "1i_Klumpförebyggande medel": "1i_Paakkuuntumisenestoaine",
    "1j_pH-regulator": "1j_Happamuuden säätöaine",
    "1k_Ensileringstillsatser: ämnen, enzymer och mikroorganismer inbegripna, avsedda att ingå i foder för att förbättra": "1k_Säilörehun lisäaine (myös entsyymit mikro-organismit, jotka on tarkoitettu lisättäväksi rehuun säilörehun parantamiseksi)",
    "1l_Denatureringsmedel": "1l_Denaturointiaine",
    "1m_Ämne avsett att minska mykotoxinkontaminationen i foder": "1m_Mykotoksiinikontaminaatiota vähentävä aine",
    "2a_Färgämne": "2a_Väriaine",
    "2b_Aromämne": "2b_Aromiaine",
    "3a_Vitamin, provitamin eller kemiskt väldefinierat ämne med likartad effekt": "3a_Vitamiini, provitamiini tai kemiallisesti tarkkaan määritellyt aine, jolla samankaltainen vaikutus",
    "3b_01_Järn (Fe) -förening": "3b_01_Rauta (Fe) -yhdiste",
    "3b_02_Jod (J) -förening": "3b_02_Jodi (J) -yhdiste",
    "3b_03_Kobolt (Co) -förening": "3b_03_Koboltti (Co) -yhdiste",
    "3b_04_Koppar (Cu) -förening": "3b_04_Kupari (Cu) -yhdiste",
    "3b_05_Mangan (Mn) -förening": "3b_05_Mangaani (Mn) -yhdiste",
    "3b_06_Zink (Zn) -förening": "3b_06_Sinkki (Zn) -yhdiste",
    "3b_07_Molybden (Mo) -förening": "3b_07_Molybdeeni (Mo) -yhdiste",
    "3b_08_Selen (Se) -förening": "3b_08_Seleeni (Se) -yhdiste",
    "3c_01_Metionin": "3c_01_Metioniini",
    "3c_02_Lysin": "3c_02_Lysiini",
    "3c_03_Treonin": "3c_03_Treoniini",
    "3c_04_Tryptofan": "3c_04_Tryptofaani",
    "3c_05_Hydroxanaloger av metionin": "3c_05_Metioniinin hydroksianalogit",
    "3c_06_Arginin": "3c_06_Arginiini",
    "3c_07_Valin": "3c_07_Valiini",
    "3c_08_Glutamin": "3c_08_Glutamiini",
    "3c_09_Cystin": "3c_09_Kystiini",
    "3c_10_Leucin": "3c_10_Leusiini",
    "3c_11_Isoleucin": "3c_11_Isoleusiini",
    "3c_12_Histidin": "3c_12_Histidiini",
    "3c_13_Tyrosin": "3c_13_Tyrosiini",
    "3d_Urinämne och derivat därav": "3d_Urea tai sen johdannainen",
    "4a_Smältbarhetsförbättrande medel": "4a_Ruuan sulatusta edistävä aine",
    "4b_Medel som stabiliserar tarmfloran": "4b_Suolistoflooran stabiloimiseen tarkoitettu aine",
    "4c_Ämne med positiv inverkan på miljön": "4c_Ympäristöön suotuisasti vaikuttava aine",
    "4d_Anna zooteknisk tillsats": "4d_Muu eläintuotantoon vaikuttava aine",
    "5_Koccidiostatika och histomonostatika": "5_Kokkidiostaatti tai histomostaatti",
    "Foderblandningar för livsmedelsproducerande djur": "Elintarviketuotantoeläinten rehuseokset",
    "Sällskapsdjursfoder": "Lemmikkieläinruuat",
    "Pälsdjursfoder": "Turkiseläinten rehut",
    "Fodertillsatser": "Rehun lisäaineet",
    "Förblandningar av fodertillsatser": "Rehun lisäaineiden esiseokset",
    "Foder för naturdjur och viltdjur": "Luonnoneläinten ja riistan rehut",
    "Inhemsk": "Kotimainen",
    "Utländsk": "Ulkomainen",
    "Okänt": "Ei tiedossa",
    "Amproliumhydroklorid": "Amproliumhydrokloridi",
    "Dekokinat": "Dekokinaatti",
    "Diklazuril": "Diklatsuriili",
    "Halofuginonhydrobromid": "Halofuginonihydrobromidi",
    "Lasalocid-A-natrium": "Lasalosidinatrium A",
    "Monensin/nikarbazin": "Monensiini/nikarbatsiini",
    "Monensinnatrium": "Monensiininatrium",
    "Narasin": "Narasiini",
    "Nicarbazin": "Nikarbatsiini",
    "Robenidinhydroklorid": "Robenidiinihydrokloridi",
    "Salinomycinnatrium": "Salinomysiininatrium",
    "Semduramicinnatrium": "Semduramisiininatrium"
  }

  public translateTuonti(teksti: string | null): string | null {
    teksti = teksti ? (teksti.charAt(0).toUpperCase() + teksti.slice(1)).trim() : null;
    return this.tuonti[teksti] || teksti;
  }

  public translateValmistus(teksti: string | null): string | null {
    teksti = teksti ? (teksti.charAt(0).toUpperCase() + teksti.slice(1)).trim() : null;
    return this.valmistus[teksti] || teksti;
  }

  public translateRaakaAine(teksti: string | null): string | null {
    teksti = teksti ? (teksti.charAt(0).toUpperCase() + teksti.slice(1)).trim() : null;
    return this.raakaAine[teksti] || teksti;
  }
}
