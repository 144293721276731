import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from "@angular/core";
import {LomakeBaseService} from "../../touko-lomake/lomake-base.service";

@Directive({
  selector: '[question-error]'
})
export class QuestionErrorDirective implements OnInit, OnChanges {

  private isSent = false;
  @Input()
  readonly isInvalid: boolean = false;
  @Input()
  readonly isTouched: boolean = false;
  @Input()
  readonly errorStyle = 'is-invalid';

  constructor(private toukoLaheteService: LomakeBaseService,
              private renderer: Renderer2,
              private elmentRef: ElementRef) {
  }

  ngOnInit(): void {
    this.toukoLaheteService.isLahetetty$.subscribe(val => {
      this.isSent = val;
      this.updateStyle();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStyle();
  }

  updateStyle() {
    if (this.isInvalid && (this.isTouched || this.isSent)) {
      this.renderer.addClass(this.elmentRef.nativeElement, this.errorStyle);
    } else {
      this.renderer.removeClass(this.elmentRef.nativeElement, this.errorStyle);
    }
  }

}
