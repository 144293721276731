/**
 * Created by Seppo on 24/07/2018.
 */

import {Component, Input} from "@angular/core";
import {Allekirjoitus} from "../touko-lomake-utils";
import {AccountService} from '../../account/account.service';

@Component({
  selector: 'allekirjoitus',
  template: `
    <div class="row">
      <div class="col yhteenveto-list">
        <h2 attribute="teksti.lahettajanTiedot">Lähettäjän tiedot</h2>
        <ul class="list-group list-group-flush ml-0 mb-0">

          <li class="list-group-item pt-0" *ngIf="data?.kokoNimi">
            <span attribute="lomakeYleinen.nimi">Nimi</span>
            <em>
              <span class="user-provided-info" id="allekirjoitus-nimi"> {{data?.kokoNimi}}</span>
            </em>
          </li>
          <li class="list-group-item" *ngIf="data?.paatoiminimi">
            <span attribute="kayttajatili.toimipaikanNimi">Toimipaikan nimi</span>
            <em>
              <span class="user-provided-info" id="allekirjoitus-organisaatio"> {{data?.paatoiminimi}}</span>
            </em>
          </li>
          <li class="list-group-item" *ngIf="data?.ytunnus || data?.maatilatunnus">
            <label [attribute]="isMaatila() || data?.maatilatunnus !== null ? (data?.maatilatunnus !== null ? 'kayttajatili.maatilatunnus' : 'kayttajatili.ytunnus') : 'kayttajatili.ytunnus'">Y-tunnus</label>
            <em>
              <span class="user-provided-info" id="allekirjoitus-ytunnus"> {{isMaatila() || data?.maatilatunnus !== null ? (data?.maatilatunnus !== null ? data?.maatilatunnus : data?.ytunnus) : data?.ytunnus}}</span>
            </em>
          </li>
          <li class="list-group-item" *ngIf="data?.osasto">
            <span attribute="lomakeYleinen.toimipaikanNimi">Toimipaikan nimi</span>
            <em>
              <span class="user-provided-info" id="allekirjoitus-osasto"> {{data?.osasto}}</span>
            </em>
          </li>
          <li class="list-group-item" *ngIf="data?.valvontakohde">
            <span attribute="etusivu.valvontakohde">Valvontakohde</span>
            <em>
              <span class="user-provided-info" id="allekirjoitus-valvontakohde"> {{data?.valvontakohde}}</span>
              <span attribute="lomakeYleinen.rekisteroimaton" *ngIf="data?.valvontakohdeAktiivinen === false" class="badge badge-warning ml-1 text-uppercase">REKISTERÖIMÄTÖN</span>
            </em>
          </li>

        </ul>
      </div>
    </div>`,
  styleUrls: ["yhteenveto.component.scss"]
})
export class AllekirjoitusComponent {
  @Input() data: Allekirjoitus;

  constructor(private readonly accountService: AccountService) {
  }

  isMaatila(): boolean {
    return this.accountService.isMaatilaToimija();
  }
}
