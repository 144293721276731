import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LaheteYhteenvetoItem} from "../yhteenveto/lahete-yhteenveto";
import {LaheteYhteenvetoService} from "../yhteenveto/lahete-yhteenveto.service";
import {ToukoLaheteService} from "../touko-lahete.service";
import {ActivatedRoute} from "@angular/router";
import {MatcherService} from "ee-lahete";
import {LomakeBaseService} from "../../touko-lomake/lomake-base.service";
import {YhteenvetoTyyppi} from "../../touko-lomake/yhteenveto/yhteenveto-utils";
import * as JsBarcode from "jsbarcode";
import {LaheteService} from '../lahete/lahete.service';

@Component({
  selector: 'app-tarkastus',
  templateUrl: './lahete-tarkastus.component.html',
  providers: [
    LaheteService,
    MatcherService,
    {provide: LomakeBaseService, useClass: ToukoLaheteService},
  ],
  styleUrls: ['./lahete-tarkastus.component.scss']
})
export class LaheteTarkastusComponent implements OnInit {

  @ViewChild("barcodeCanvas", {static: false}) barcodeCanvas: ElementRef;

  yhteenveto: LaheteYhteenvetoItem[] = [];
  yhteenvetoJSON = {};
  toukoLaheteService: ToukoLaheteService;
  barcodePresent = false;
  readonly visibleSummaryPaths = ['lahete', 'tilaaja', 'elainlajit', 'toimitustiedot', 'tutkimukset'];

  constructor(private readonly laheteYhteenvetoService: LaheteYhteenvetoService,
              private readonly lomakeBaseService: LomakeBaseService,
              private readonly laheteService: LaheteService,
              private readonly matcherService: MatcherService,
              private readonly activatedRoute: ActivatedRoute,
              private renderer: Renderer2) {
    this.toukoLaheteService = lomakeBaseService as ToukoLaheteService;
  }

  ngOnInit(): void {
    this.laheteYhteenvetoService.initYhteenvetoService();

    this.laheteService.getLaheteKoodisto()
      .then(() => {

      this.activatedRoute.params.subscribe(params => {
        if (params.hasOwnProperty('id')) {
          this.toukoLaheteService.getLomake(params.id)
            .then(lahete => {
              this.yhteenvetoJSON = JSON.parse(lahete.body);

              const model = this.createLaheteModel(JSON.parse(lahete.body));
              this.matcherService.initModelAndForm(model);

              this.buildYhteenveto();
              this.yhteenveto = this.laheteYhteenvetoService.getYhteenveto(
                this.matcherService.model,
                this.yhteenvetoJSON,
                null
              );
            });
        }
      });
    });
  }

  createLaheteModel(values) {
    return [
      this.laheteService.getLahetetunnusGroup(),
      this.laheteService.getTilaajaGroup(),
      this.laheteService.getSahkoinenAsiointiGroup(),
      this.laheteService.getElainlaakariGroup(),
      this.laheteService.getOmistajaGroup(),
      this.laheteService.getTiedoksisaajaGroup({controlType: ''}, values),
      this.laheteService.getElainlajiryhmakooditGroup(),
      this.laheteService.getElainlajiGroup(),
      this.laheteService.getTutkimusGroup(),
      this.laheteService.getNaytetiedotGroup({controlType: 'naytetieto'}, values),
      this.laheteService.getAutomaattinenTiedoksisaajaGroup(),
      this.laheteService.getTilatiedotGroup(),
      this.laheteService.getTilatyypitGroup(),
      this.laheteService.getTaustakysymyksetGroup(),
      this.laheteService.getToimitusosoiteGroup()

    ];
  }

  buildYhteenveto() {
    const yhteenvetoTyypit = {
      id: {summaryType: YhteenvetoTyyppi.VALUE_ONLY},
      checkbox: {summaryType: YhteenvetoTyyppi.LABEL_ONLY},
      radio: {summaryType: YhteenvetoTyyppi.LABEL_AND_TEXT},
      'radio-card': {summaryType: YhteenvetoTyyppi.LABEL_PREFORMATTED},
      'radio-nayte': {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS},
      text: {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE},
      'text-input': {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE},
      textarea: {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE},
      select: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_LABEL},
      selection: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_LABEL},
      date: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_DATE},
      pvm: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_DATE},
      pvmVuosi: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_DATE}
    };

    Object.entries(yhteenvetoTyypit)
      .forEach(([key, value]) => {
        this.matcherService.applyOptionsByControlType(key, value);
      });
  }

  private addBarcodeToCanvas(): Element {
    const laheteTunnus = this.yhteenvetoJSON['lahete']['id'];
    const element = this.renderer.createElement('canvas') as Element;
    if (!this.barcodePresent && laheteTunnus) {
      JsBarcode(element, laheteTunnus, {height: 60, displayValue: true});
      this.renderer.appendChild(this.barcodeCanvas.nativeElement, element);
      this.barcodePresent = true;
    }
    return element;
  }

  printYhteenveto() {
    this.addBarcodeToCanvas();
    window.print();
  }

}
