/**
 * Created by Seppo on 21/11/2017.
 */

import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {OsoiteTiedot} from "../../account/account";
import {LiitetiedostoResponse} from '../touko-lomake-utils';
import {Kasvilaji} from '../../kasvi/kasvilaji.model';
import {Kasvilajike} from '../../kasvi/kasvilajike.model';

export class DateObj {
  day: number;
  month: number;
  year: number;

  constructor(day: number, month: number, year: number) {
    this.day = day;
    this.month = month;
    this.year = year;
  }

  static today() {
    const today = new Date();
    return new DateObj(today.getDate(), today.getMonth() + 1, today.getFullYear());
  }

  static firstDayOfMonth() {
    const today = new Date();
    return new DateObj(1, today.getMonth() + 1, today.getFullYear());
  }

  static isValid(date: DateObj) {
    if (date === null) {
      return true;
    }
    const dateparts = [date.day, date.month, date.year];
    if (dateparts.every(d => d !== null) && date.year > 1900) {
      return !isNaN(Date.parse(DateObj.toISO(date)));
    }

    return false;
  }

  static toISO(date: DateObj): string {
    const m = date.month < 10 ? `0${date.month}` : "" + date.month;
    const d = date.day < 10 ? `0${date.day}` : "" + date.day;

    return `${date.year}-${m}-${d}`;
  }

  static fromNgbDate(date: NgbDateStruct) {
    return new DateObj(date.day, date.month, date.year);
  }

  static fromDate(date: Date) {
    return new DateObj(date.getDate(), date.getMonth() + 1, date.getFullYear());
  }

  static fromJson(json: { year: number; month: number; day: number }): DateObj {
    return new DateObj(json.day, json.month, json.year);
  }

  static formattedDate(date: DateObj): string {
    const m = date.month < 10 ? `0${date.month}` : "" + date.month;
    const d = date.day < 10 ? `0${date.day}` : "" + date.day;

    return `${d}.${m}.${date.year}`;
  }
}

export class VerkkolaskuTiedot {
  verkkolaskutusosoite: string;
  operaattori: string;

  constructor(verkkolaskutusosoite: string = null, operaattori: string = null) {
    this.verkkolaskutusosoite = verkkolaskutusosoite;
    this.operaattori = operaattori;
  }
}

export class Yhteyshenkilo {
  id: number;
  ytrTunnus: number;
  elmoid: string;
  nimi: string;
  puhelin: string;
  email: string;
  rooli: string;
  elmoStatus: string;
  ytrStatus: string;

  constructor(nimi?: string, puhelin?: string, email?: string, rooli?: string, elmoStatus?: string, ytrStatus?: string) {
    this.nimi = nimi || null;
    this.puhelin = puhelin || null;
    this.email = email || null;
    this.rooli = rooli || null;
    this.elmoStatus = elmoStatus || "ACTIVE";
    this.ytrStatus = ytrStatus || "VALID";
  }
}

export class Verkkokauppa {
  osoite: string;
  enddt: string;
  lisatty: boolean;

  constructor(osoite?: string, enddt?) {
    this.osoite = osoite || null;
    this.enddt = enddt || null;
  }
}

export class Laskutustiedot {
  laskutustiedot: OsoiteTiedot;
  verkkolaskutusosoite: string;
  operaattori: string;
}

export class Perustiedot {
  kayntiosoite: OsoiteTiedot;
  postiosoite: OsoiteTiedot;
  osasto: string;
  postinro: number;
  toimipaikka: string;
  laskutusosoite: OsoiteTiedot;
  verkkolaskuosoite: string;
}


export class Luomulupa {
  constructor(laji: Kasvilaji = null,
              lajike: Kasvilajike = null,
              kayttomaara: number = null,
              peruste: string = null,
              perustelut: string = null,
              tyyppi: string = null,
              liitteet: LiitetiedostoResponse[] = null,
              lupaTyyppi: string = null) {
    this.laji = laji;
    this.lajike = lajike;
    this.kayttomaara = kayttomaara;
    this.peruste = peruste;
    this.perustelut = perustelut;
    this.tyyppi = tyyppi;
    this.liitteet = liitteet;
    this.lupaTyyppi = lupaTyyppi;
  }

  isEmpty() {
    return ![
      this.tyyppi,
      this.laji?.nimi?.trim(),
      this.lajike?.kuvaus?.trim()
    ].some(val => Boolean(val));
  }

  laji: Kasvilaji;
  lajike: Kasvilajike;
  kayttomaara: number;
  peruste: string;
  perustelut: string;
  liitteet: LiitetiedostoResponse[] | null;
  tyyppi: string;
  lupaTyyppi: string;
}

export class SiirtymaajanLyhennysLupa {
  constructor(kasvulohkot: string = null,
              pintaala: string = null,
              perustelut: string = null,
              liitteet: LiitetiedostoResponse[] = null) {
    this.kasvulohkot = kasvulohkot;
    this.pintaala = pintaala;
    this.perustelut = perustelut;
    this.liitteet = liitteet;
  }

  kasvulohkot: string;
  pintaala: string;
  perustelut: string;
  liitteet: LiitetiedostoResponse[] | null;
}

export class ElainTuotantoLupaSl {
  constructor(elainSiirtymaajanLyhennysValinta: string = null) {
    this.elainSiirtymaajanLyhennysValinta = elainSiirtymaajanLyhennysValinta;
  }

  elainSiirtymaajanLyhennysValinta: string;
}
