import {Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, Type, ViewChild} from "@angular/core";
import {QuestionAndAnswerSummaryComponent} from "./question-and-answer/question-and-answer-summary.component";
import {SummaryComponentHostDirective} from "./summary-component-host.directive";
import {SummaryItem} from "ngx-fielding";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'summary-container',
  template: '<div [ngClass]="summaryItem?.options?.classList || []"><ng-container summary-component-host></ng-container></div>'
})
export class SummaryContainerComponent implements OnInit {
  @ViewChild(SummaryComponentHostDirective, {static: true}) componentHost: SummaryComponentHostDirective;
  @Input() summaryItem: SummaryItem<any> = null;
  @Input() compareItemType: ResultType;

  private componentRef: ComponentRef<SummaryComponentBase>;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();
    const summaryComponent = this.summaryItem?.component || QuestionAndAnswerSummaryComponent;
    const component = this.componentFactoryResolver.resolveComponentFactory(summaryComponent as Type<SummaryComponentBase>);
    this.componentRef = viewContainerRef.createComponent(component);
    this.componentRef.instance.item = this.summaryItem;
    this.componentRef.instance.compareItemType = this.compareItemType;
  }
}

export interface SummaryComponentBase {
  item: SummaryItem<any>;
  compareItemType: ResultType;
}
