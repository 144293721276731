import {NgModule} from "@angular/core";
import {AttributeDirective} from "./attribute.directive";
import {AttributeService} from "./attribute.service";
import {BrowserModule} from "@angular/platform-browser";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AttributePipe} from "./attribute.pipe";
/**
 * Created by Seppo on 18/01/2018.
 */

@NgModule({
  declarations: [
    AttributeDirective,
    AttributePipe
  ],
  imports: [
    BrowserModule,
    NgbModule,
  ],
  exports: [
    AttributeDirective,
    AttributePipe
  ],
  providers: [
    AttributeService
  ]
})
export class AttributeModule { }
