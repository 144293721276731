import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {TulosraporttiService} from "../raportti.service";
import {RaporttiHaku, Sarake, SarakeType, Tag} from "../raportti.utils";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Teksti} from "../../utils/teksti";
import {DataTableComponent} from "../data-table.component";
import {RaporttiBaseClass} from "../raportti-base";
import {Syote} from "../../touko-lomake/syote/syote";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Team on 13/12/2022.
 */

const VIEW = "raportit";

@Component({
  selector: 'siemenmuut-raportti',
  templateUrl: './siemenmuut-raportti.component.html',
  styles: ['.no-wrap{ white-space: nowrap; }', 'th>span{cursor: pointer}']
})
@AutoUnsubscribe
export class SiemenMuutRaporttiComponent extends RaporttiBaseClass implements OnInit {

  @ViewChild("datatable") datatable: DataTableComponent;
  @Input() getRaportitFn: (...any: any[]) => Promise<RaporttiHaku>;
  raporttiForm: FormGroup;
  liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  tarkastustyypitTags: Tag[] = [];
  tilaTags: Tag[] = [];
  keys;
  tapahtumatyypitTags: Tag[] = [];
  syotteet;

  protected toimiala = "SIEMEN";

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
        this.fb.group({
          tunnus: null,
          kuvaus: null,
          tapahtumatyyppi: [],
          tila: [],
          tarkastustyyppi: [],
          pvmAlku: [null, ToukoValidation.isValidDate],
          pvmLoppu: [null, ToukoValidation.isValidDate],
        })
    );

    this.syotteet = {
      tapahtumatyyppi: new Syote(new Teksti('Tapahtumatyyppi', 'tapahtumatyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTapahtumatyyppi', VIEW)}),
      tarkastustyyppi: new Syote(new Teksti('Tarkastustyyppi', 'tarkastustyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTarkastustyyppi', VIEW)}),
      tila: new Syote(new Teksti('Tila', 'tila', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTila', VIEW)}),
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };
    this.liiteDownloadFn = this.tulosraporttiService.getSiemenValvontaLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getSiemenValvontaLiiteZip.bind(this.tulosraporttiService);
    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getSiemenValvontaRaportit.bind(this.tulosraporttiService);

    this.keys = {
      pvm: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      tunnus: new Sarake('tunnus', new Teksti(VIEW, 'tunnus', VIEW), SarakeType.DEFAULT, (val) => "no-word-break"),
      kuvaus: new Sarake('kuvaus', new Teksti(VIEW, 'kuvaus', VIEW)),
      dokumentti: this.getAsiakirjatSarakeTitle('asiakirja'),
      tila: new Sarake('tilaNimi', new Teksti(VIEW, 'tila', VIEW)),
      tarkastustyyppi: new Sarake('tarkastustyyppiNimi', new Teksti(VIEW, 'tarkastustyyppi', VIEW)),
      raporttityyppi: new Sarake('tapahtumatyyppi', new Teksti(VIEW, "tapahtumatyyppi", VIEW), SarakeType.TYPE, (tyyppi) => tyyppi === "TRADBATCHCERTDECIS" ? 'badge badge-success' : 'badge badge-primary')
    };

    this.columnOrder = [
      this.keys.tunnus,
      this.keys.kuvaus,
      this.keys.raporttityyppi,
      this.keys.pvm,
      this.keys.tila,
      this.keys.tarkastustyyppi,
      this.keys.dokumentti
    ];

    this.initRaportit().then(() => {
      this.initTags();
    });
  }


  initTags() {
    this.tapahtumatyypitTags = this.mapField(r => new Tag(r.tapahtumatyyppiNimi, r.tapahtumatyyppi));
    this.tarkastustyypitTags = this.mapField(r => new Tag(r.tarkastustyyppiNimi, r.tarkastustyyppiNimi));
    this.tilaTags = this.mapField(r => new Tag(r.tilaNimi, r.tilaNimi));
  }
}
