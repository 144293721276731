export class Operaattoritunnus {
  private static operaattoritunnusList = [
    '003701011385',
    '003701150617',
    '003703575029',
    '003708599126',
    '003714377140',
    '003722207029',
    '003723327487',
    '003726044706',
    '885060259470028',
    '003721291126',
    '003723609900',
    '5909000716438',
    'AABAFI22',
    'BAWCFI22',
    'CREDIFLOW',
    'DABAFIHH',
    'DNBAFIHX',
    'E204503',
    'EUPEPPOL',
    'EXPSYS',
    'HANDFIHH',
    'HELSFIHH',
    'INEXCHANGE',
    'ITELFIHH',
    'NDEAFIHH',
    'OKOYFIHH',
    'PAGERO',
    'POPFFI22',
    'SBANFIHH'
  ];

  public static getOperaattoritunnukset(): string[] {
    return this.operaattoritunnusList;
  }
}
