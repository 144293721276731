import {Directive, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {EnvironmentService, Feature} from "../../environments/environment.service";

/**
 * Poistaa elementin ympäristöistä, jotka ajetaan tuotanto-moodissa.
 *
 * Käytettävä asteriskin kanssa, esim.
 * <div *testFeature>
 *   Näytetään, jos testFeature on käytössä
 * </div>
 */
@Directive({
  selector: '[testFeature]'
})
export class TestFeatureDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private environmentService: EnvironmentService) {
  }

  ngOnInit(): void {
    if (!this.environmentService.isFeatureEnabled(Feature.TEST_FEATURE)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
