import {Component} from "@angular/core";
import {LoginState, TiiraService} from "../tiira/tiira.service";
import {appVersion} from "../app.version";
import {Subscription} from "rxjs/internal/Subscription";
import {AutoUnsubscribe} from "../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 12/04/2018.
 */

@Component({
  selector: 'touko-footer',
  template: `
    <hr class="mt-4 mb-2 d-print-none">
    <footer class="footer d-print-none">
      <div class="footer-content">
        <div [ngClass]="{'justify-content-center container max-50rem': !loggedIn, 'container-fluid': loggedIn}">
          <div class="row">
            <div *ngIf="!lang || lang === 'FI'" class="col-sm pl-0">
              <ul class="list-unstyled">
                <li [attribute]="'footer.ruokavirasto'" [lang]="'FI'">© Ruokavirasto</li>
                <li [attribute]="'footer.osoite'" [lang]="'FI'">Mustialankatu 3, 00790 Helsinki</li>
                <li class="mt-2"><a [attr.href]="'footer.tietosuojaLinkki' | attribute:'FI'"
                                    attribute="footer.tietosuoja"
                                    [lang]="'FI'"
                                    class="footer-link">Tietosuojailmoitus</a>
                </li>
                <li><a [attr.href]="'footer.saavutettavuusLinkki' | attribute:'FI'"
                       attribute="footer.saavutettavuusSeloste"
                       [lang]="'FI'"
                       class="footer-link">Saavutettavuus seloste</a>
                </li>
                <li class="mt-2">
                  <span [attribute]="'footer.palauteJaKysymykset'" [lang]="'FI'"></span>:
                </li>
                <li>
                  <a href="mailto:touko@ruokavirasto.fi" class="footer-link">touko@ruokavirasto.fi</a>
                </li>
              </ul>
            </div>

            <div *ngIf="!lang || lang === 'SV'" class="col-sm" [ngClass]="{'pl-0': lang || lang === 'SV'}">
              <ul class="list-unstyled">
                <li [attribute]="'footer.ruokavirasto'" [lang]="'SV'">© Livsmedelsverket</li>
                <li [attribute]="'footer.osoite'" [lang]="'SV'">Mustialankatu 3, 00790 Helsinki</li>
                <li class="mt-2"><a [attr.href]="'footer.tietosuojaLinkki' | attribute:'SV'"
                                    attribute="footer.tietosuoja"
                                    [lang]="'SV'" class="footer-link">Tietosuojailmoitus</a>
                </li>
                <li><a [attr.href]="'footer.saavutettavuusLinkki' | attribute:'SV'"
                       attribute="footer.saavutettavuusSeloste"
                       [lang]="'SV'" class="footer-link">Saavutettavuusseloste</a>
                </li>
                <li class="mt-2">
                  <span [attribute]="'footer.palauteJaKysymykset'" [lang]="'SV'"></span>:
                </li>
                <li>
                  <a href="mailto:touko@ruokavirasto.fi" class="footer-link">touko@ruokavirasto.fi</a>
                </li>
              </ul>
            </div>


            <div class="col">
              <p>
                <img class="img-fluid logo" [src]="'assets/img/Ruokavirasto_text_black_languageversions_fi.svg'"
                     alt="Ruokavirasto - Livsmedelsverket - Finnish Food Authority">
              </p>
              <p class="small">{{version}}</p>
              <accessibility-btn *testFeature></accessibility-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              <accessibility-results *testFeature></accessibility-results>
            </div>
          </div>
        </div>
      </div>
    </footer>
  `,
  styleUrls: [
    "./footer.component.scss",
    "./../login/login.component.scss"
  ]
})
@AutoUnsubscribe
export class FooterComponent {
  lang = null;
  loggedIn = false;
  public version: string = appVersion;
  private readonly subs: Subscription[] = [];

  constructor(private readonly tiiraService: TiiraService) {
    this.subs.push(tiiraService.language$.subscribe(lang => {
      this.lang = lang;
    }));
    this.subs.push(tiiraService.loginStatus$.subscribe(loginStatus => {
      this.loggedIn = loginStatus === LoginState.LOGGEDIN;
    }));

  }
}
