import {LomakeRequest, LomakeResponse} from "../../../touko-lomake-utils";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class LomakeHttpService {

  constructor(private http: HttpClient) {
  }

  fetchLomakePohjatiedot(baseUrl: string): Promise<LomakeResponse> {
    const url = `/api/v2/${baseUrl}/`;
    return this.getLomake(url);
  }

  fetchKeskenerainenLomake(baseUrl: string, id: number): Promise<LomakeResponse> {
    const url = `/api/v2/${baseUrl}/${id}`;
    return this.getLomake(url, {isKesken: true});
  }

  saveKeskenerainenLomake(requestBody: LomakeRequest, baseUrl: string, id?: number): Promise<LomakeResponse> {
    if (id) {
      const url = `/api/v2/${baseUrl}/${id}/tallenna`;
      return this.putLomake(url, requestBody);
    } else {
      const url = `/api/v2/${baseUrl}/tallenna`;
      return this.postLomake(url, requestBody);
    }
  }

  sendLomake(requestBody: LomakeRequest, baseUrl: string, id?: number): Promise<LomakeResponse> {
    if (id) {
      const url = `/api/v2/${baseUrl}/${id}/laheta`;
      return this.putLomake(url, requestBody);
    } else {
      const url = `/api/v2/${baseUrl}/laheta`;
      return this.postLomake(url, requestBody);
    }
  }

  private getLomake(url: string, params = {}) {
    return this.http.get(url, {params})
      .toPromise()
      .then(data => data as LomakeResponse)
      .then(response => response);
  }

  private postLomake(url: string, requestObject = {}) {
    return this.http.post(url, requestObject)
      .toPromise()
      .then(data => data as LomakeResponse)
      .then(response => response);
  }

  private putLomake(url: string, requestObject = {}) {
    return this.http.put(url, requestObject)
      .toPromise()
      .then(data => data as LomakeResponse)
      .then(response => response);
  }
}
