import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {LomakeService} from "./touko-lomake.service";
import {
  Allekirjoitus,
  LiitetiedostoResponse,
  LomakeConverter,
  LomakeJSONOld,
  LomakeResponse,
  LomakeTila
} from "./touko-lomake-utils";
import {MessageService} from "../message/message.service";
import {Syote} from "./syote/syote";
import {ActivatedRoute, Router} from "@angular/router";
import {Teksti} from "../utils/teksti";
import {Directive, OnInit} from "@angular/core";
import {Perustiedot, Verkkokauppa, Yhteyshenkilo} from "./syote/syote-utils";
import {AccountService} from "../account/account.service";
import {YhteenvetoService} from "./yhteenveto/yhteenveto.service";
import {Account} from '../account/account';
import {LomakeDataContent} from "./lomake-data";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";

const _ = require('lodash');

export enum LomakeStatus {
  VALMIS,
  KESKEN
}

/**
 * Lomakkeen pohjaluokka. Jokaisen lomakkeen tulee periä tämä,
 * jotta valmiit toiminnallisuudet saadaan otettua käyttöön.
 */
@Directive()
export abstract class LomakeBaseDirective implements OnInit {

  abstract id: number;
  abstract lomakeAsia: string;
  protected readonly etusivuURL = "/asiointi/etusivu";

  toimintotyyppi: string;
  liitetiedostot: {[s: string]: LiitetiedostoResponse[]} = {};
  allekirjoitus: Allekirjoitus = new Allekirjoitus();
  lomakeData: LomakeDataContent;
  lomakeValues: FormGroup;
  lomakeSent = false;
  isPerustiedotValid = false;
  isLiitetiedostotUploaded = true;
  yhteenvetoValues: LomakeJSONOld;
  perustiedot: Perustiedot = new Perustiedot();
  trackPerustietoChanges = true;
  initYhteenveto = true;
  yhteyshenkilot: Yhteyshenkilo[] = [];
  verkkokaupat: Verkkokauppa[] = [];
  lisatieto: string;

  lomakeService: LomakeService;
  messageService: MessageService;
  router: Router;
  activatedRoute;
  accountService: AccountService;
  yhteenvetoService;
  lomakeTyyppiVersio = 'v1';

  /**
   * Konstruktori
   *
   * @param lomakeService - lomakkeen toiminnot
   * @param messageService - ilmoitustoiminnot
   * @param router - router
   * @param activatedRoute - aktiivinen url
   * @param accountService - käyttäjätietojen hakutoiminnot
   * @param yhteenvetoService - Yhteenvedon tiedot
   */
  constructor(lomakeService: LomakeService,
              messageService: MessageService,
              router: Router,
              activatedRoute: ActivatedRoute,
              accountService: AccountService,
              yhteenvetoService: YhteenvetoService) {

    this.lomakeService = lomakeService;
    this.messageService = messageService;
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.accountService = accountService;
    this.yhteenvetoService = yhteenvetoService;

    this.activatedRoute.data.subscribe(d => {
      this.toimintotyyppi = d.toimintotyyppi;
    }).unsubscribe();

    Syote.resetId();
  }

  get isViranomainen(): boolean {
    return this.accountService.isViranomainen();
  }

  get isAsiakas(): boolean {
    return this.accountService.isAsiakas();
  }

  ngOnInit(): void {
    this.afterInit();
  }

  /**
   * Tarkistaa, voiko lomakkeen lähettää. Käytetään lomakkeen lähetyksen
   * yhteydessä, jonka vuoksi funktion suoritus asettaa lomakeSent -arvoksi
   * `true`
   *
   * @returns - Voiko lomakkeen lähettää
   */
  canSubmitLomake(): boolean {
    this.lomakeSent = true;
    return this.lomakeService.checkCanSubmitLomake(this.lomakeValues);
  }

  /**
   * Konvertoi lomakkeen arvot JSON muotoon
   *
   * @returns
   */
  convertLomakeToJSON() {
    return LomakeConverter.convertToJSON(this.lomakeAsia, this.lomakeValues.getRawValue());
  }

  /**
   * Hakee annetun FormGroup -tyyppisen kentän virheet. Välittää tiedon
   * kentän oikeellisuudesta lomakeServicelle.
   *
   * @param kenttaObject - tarkistettava FormGroup. Syötetään Object-tyyppisenä,
   * ks. https://stackoverflow.com/questions/47082184/angular-ng-build-prod-produces-argument-of-type-abstractcontrol-is-not-assi
   *
   * @param sivu - sivun viite (ref), jolla tarkistettava FormGroup sijaitsee templatessa
   * @param show - jos true, virhe näytetään aina
   * @returns - virheellisten kenttien avaimet, jos tyhjä lista, virheitä ei ole
   */
  getGroupVirheet(kenttaObject: Object, sivu?: string, show = false) {
    const kentta = kenttaObject as FormGroup;
    const virheita = new Set();
    if (show || this.lomakeSent || (kentta.dirty || kentta.touched)) {
      if (kentta.errors !== null) {
        for (const virhe in kentta.errors) {
          if (kentta.errors.hasOwnProperty(virhe)) {
            virheita.add(virhe);
          }
        }
      }
    }

    for (const control in kentta.controls) {
      if (kentta.controls.hasOwnProperty(control)) {
        const kenttaControl = kentta.controls[control] as FormControl;
        const kenttaVirheet = this.getKenttaVirheet(kenttaControl, null, show);
        kenttaVirheet.forEach(v => virheita.add(v));
      }
    }

    const virhelista = Array.from(virheita);

    if (sivu) {
      this.lomakeService.setVirhe(sivu, Object.keys(kentta.controls)[0], virhelista.length > 0);
    }

    return virhelista ;
  }

  /**
   * Hakee annetun yksittäisen FormControl -tyyppisen kentän virheet. Välittää tiedon
   * kentän oikeellisuudesta lomakeServicelle.
   *
   * @param kentta - tarkistettava FormControl
   * @param sivu - sivun viite (ref), jolla tarkistettava FormGroup sijaitsee templatessa
   * @param show - jos true, virhe näytetään aina
   * @returns - virheellisten kenttien avaimet, jos `null`, virheitä ei ole
   */
  getKenttaVirheet(kentta: FormControl, sivu?: string, show = false) {
    let virhe = null;
    if (show || this.lomakeSent || (kentta.dirty || kentta.touched)) {
      virhe = kentta.errors;
    }

    if (sivu) {
      this.lomakeService.setVirhe(sivu, Object.keys(kentta)[0], Boolean(virhe));
    }

    return virhe === null ? [] : Object.keys(virhe);
  }

  getKenttaVirhesanomat(kenttaAsAbstractControl: AbstractControl, formGroup: string, sivu?: string) {
    const kentta = kenttaAsAbstractControl as FormControl;
    const virheet = this.getKenttaVirheet(kentta, sivu);
    return virheet.map(v => this.lomakeData[formGroup].errorMessages[v]);
  }

  initLomake() {
    this.lomakeValues = this.getLomakeValues();
    if (this.initYhteenveto) {
      this.yhteenvetoService.initYhteenvetoService(this.lomakeData);
    }

    this.activatedRoute.params.subscribe(p => {
      const baseLomakeId = parseInt(p.id, 10);

      const isNewMuutosOrHakemusLomake = p.mode === 'aloita' && (this.toimintotyyppi === "muutos" || this.toimintotyyppi === "hakemus");

      if (baseLomakeId > 0 && p.mode === "jatka" || isNewMuutosOrHakemusLomake) {
        this.updateLomakeValues(baseLomakeId, isNewMuutosOrHakemusLomake);
      } else if (baseLomakeId > 0 && p.mode === "aloita") {
        this.router.navigate([this.etusivuURL]);
        this.messageService.notify({message: new Teksti("Lomaketta ei löytynyt", "lomakettaEiLoytynyt", "lomakeYleinen"), type: "danger"});
      } else {
        this.setAllekirjoitus();
      }

      this.id = p.mode === "jatka" ? baseLomakeId : 0;
    }).unsubscribe();
  }

  protected getLomakeValues() {
    return this.lomakeService.createLomake(this.lomakeData);
  }

  private updateLomakeValues(baseLomakeId: number, isNewMuutosOrHakemusLomake: boolean) {
    this.fetchLomakeValues(baseLomakeId, isNewMuutosOrHakemusLomake, this.toimintotyyppi)
      .then(values => {
        if (values) {
          this.updateLomakeModel(values);
          this.lomakeValues.patchValue(values);
          this.updateYhteenvetoValues();
        }
      })
      .catch(reason => {
        console.error("UpdateLomake ei onnistunut", reason);
        this.router.navigate([this.etusivuURL]);
      });
  }

  /**
   * Ylikirjoita metodi lomakkeissa, joissa malli muodostetaan dynaamisesti.
   * Esim. silloin, kun käytetään FormArray-tyyppisiä kenttiä
   *
   * @param values - lomakkeen arvot
   * @protected
   */
  protected updateLomakeModel(values) {
    //
  }

  /**
   * Ajetaan ngInit-metodissa, voidaan laajentaa / ylikirjoittaa.
   */
  afterInit() {
    this.lomakeService.blockRedirect();
    this.initLomake();
    if (this.trackPerustietoChanges) {
      this.trackPerustiedot();
    }
    /* Touch for getting fields validated when page first time loaded. If there is not valid value received. ESS-1712 */
    const yhteyshenkiloControl = this.lomakeValues?.get(['yhteyshenkilot', 'yhteyshenkilot']);
    if (yhteyshenkiloControl) {
      yhteyshenkiloControl.markAsTouched();
    }
  }

  private trackPerustiedot() {
    const updateFn = () => this.isPerustiedotValid = this.lomakeService.validatePerustiedot(this.lomakeValues);
    updateFn.bind(this);

    this.lomakeValues.get('perustiedot').valueChanges.subscribe(v => updateFn());
    this.lomakeValues.get('yhteyshenkilot').valueChanges.subscribe(v => updateFn());

    // Optional fields
    this.lomakeValues.get('verkkokaupat')?.valueChanges.subscribe(v => updateFn());
    this.lomakeValues.get('laskutustiedot')?.valueChanges.subscribe(v => updateFn());
    this.lomakeValues.get('toiminnanAloitus')?.valueChanges.subscribe(v => updateFn());
  }

  /**
   * Päivittää lomakkeen perustiedot
   *
   * @param data - perustiedot
   */
  updatePerustiedot(data: Perustiedot) {
    this.perustiedot = data;
  }

  /**
   * Päivittää lomakkeen yhteyshenkilön
   *
   * @param data
   */
  updateYhteyshenkilot(data: Yhteyshenkilo[]) {
    this.yhteyshenkilot = data;
  }

  /**
   * Päivittää lomakkeen verkkokaupan osoitteen
   *
   * @param data
   */
  updateVerkkokaupat(data: Verkkokauppa[]) {
    this.verkkokaupat = data;
  }

  /**
   * Hakee lomakkeen arvot. Jos lomake on lähetetty, allekirjoitukseksi lisätään lähettäjän nimi.
   *
   * @param id - haettavan lomakkeen id
   * @param haeElmoLomake - Haetaanko lomakkeelle pohjatiedot Elmosta
   * @param toimintotyyppi - UUSI / MUUTOS / LOPETUS / HAKEMUS
   */
  private fetchLomakeValues(id: number, haeElmoLomake: boolean, toimintotyyppi: string) {
    if (haeElmoLomake) {
      if (LomakeEndpointUtil.shouldUseV2Endpoint(this.lomakeAsia, this.lomakeTyyppiVersio)) {
        return this.lomakeService.getMuutoslomakeTiedotV2(this.lomakeAsia, toimintotyyppi)
          .then(lomake => this.processLomake(lomake));
      }
      return this.lomakeService.getMuutoslomakeTiedot(this.lomakeAsia, toimintotyyppi)
        .then(lomake => this.processLomake(lomake))
        .then(sisalto => LomakeConverter.convertToLomakeValues(sisalto));
    } else if (LomakeEndpointUtil.shouldUseV2ClientComponent(this.lomakeAsia, toimintotyyppi.toLowerCase(), this.lomakeTyyppiVersio) ||
      LomakeEndpointUtil.shouldUseV2Endpoint(this.lomakeAsia, this.lomakeTyyppiVersio)) {
      return this.lomakeService.getMuokattavaLomakeVersio2(id, this.lomakeAsia, toimintotyyppi)
        .then(lomake => this.processLomake(lomake));
    } else {
      return this.lomakeService.getMuokattavaLomake(id)
        .then(lomake => this.processLomake(lomake))
        .then(sisalto => LomakeConverter.convertToLomakeValues(sisalto));
    }
  }

  private processLomake(loadedValues: LomakeResponse) {
    loadedValues.sisalto = this.parseLoadedValues(loadedValues.sisalto);
    const allekirjoitus = loadedValues.kesken ? null : Allekirjoitus.createFromLomake(loadedValues);
    this.setAllekirjoitus(allekirjoitus);
    this.lisatieto = loadedValues.lisatieto;
    return loadedValues.sisalto.data;
  }

  /**
   * Parsii lomakkeen string-muotoisen sisällön. Voidaan ylikirjoittaa lomakekohtaisesti.
   *
   * @param rawValues - Lomakkeen sisältö string-muodossa
   */
  parseLoadedValues(rawValues: string) {
    return JSON.parse(rawValues);
  }


  /**
   * Asettaa lomakkeelle allekirjoituksen. Mikäli allekirjoitusta ei anneta, käytetään kirjautuneen käyttäjän tietoja.
   *
   * @param lomakkeenAllekirjoitus
   */
  private setAllekirjoitus(lomakkeenAllekirjoitus?: Allekirjoitus) {
    if (!lomakkeenAllekirjoitus) {
      const account = this.accountService.getCurrentAccount();
      this.allekirjoitus = this.accountService.isMaatilaToimija() ?
        this.getMaatilaAllekirjoitus(account) :
        this.getBaseAllekirjoitus(account);
    } else if (lomakkeenAllekirjoitus instanceof Allekirjoitus) {
      this.allekirjoitus = lomakkeenAllekirjoitus;
    }
  }

  private getBaseAllekirjoitus(account: Account): Allekirjoitus {
    const ytunnus = account.ytunnus;
    const maatilatunnus = account.maatilatunnus;
    const yritysNimi = _.get(account, "yritys.paaToiminimi", null);
    const vkTunnus = _.get(account, "valvontakohdeElmoId", ytunnus);
    const osasto = _.get(account, 'valvontakohde.toimipaikka.nimi_fi', null);
    const vkAktiivinen = ytunnus !== vkTunnus;

    return new Allekirjoitus(account.wholeName, ytunnus, yritysNimi, vkTunnus, vkAktiivinen, osasto, maatilatunnus);
  }

  private getMaatilaAllekirjoitus(account: Account): Allekirjoitus {
    const baseAllekirjoitus = this.getBaseAllekirjoitus(account);

    const yritysNimi = baseAllekirjoitus.paatoiminimi || _.get(account, "account.maatila.tilanNimi", "");
    const vkTunnus = baseAllekirjoitus.valvontakohde || account.maatilatunnus || "";
    const osasto = baseAllekirjoitus.osasto || yritysNimi || "";
    const ytunnus = baseAllekirjoitus.ytunnus ;
    const maatilatunnus = account.maatilatunnus;
    const vkAktiivinen = ytunnus !== vkTunnus;

    return new Allekirjoitus(account.wholeName, ytunnus, yritysNimi, vkTunnus, vkAktiivinen, osasto, maatilatunnus);
  }


  /**
   * Lomakkeen lähetys. Tämä funktio voidaan myös ylikirjoittaa.
   *
   */
  submitLomake(): Promise<LomakeResponse> {
    if (this.canSubmitLomake()) {
      return this.sendLomake(LomakeStatus.VALMIS)
        .then(r => {
          this.messageService.notify({message: new Teksti("Lomake lähetetty", "lomakeLahetetty", "lomakeYleinen"), type: "success"} );
          this.lomakeService.allowRedirect();
          const palauteParam = {palaute: r.tila === LomakeTila.ODOTTAA_KASITTELYA};
          this.router.navigate([this.etusivuURL], {queryParams: palauteParam});
          return r;
        })
        .catch(e => {
          console.error("SubmitLomake ei onnistunut", e);
          this.messageService.notify({message: new Teksti("Lomakkeen lähetys epäonnistui. Virhe palvelimella.", "lahetysEpaonnistuiPalvelin", "lomakeYleinen"), type: "danger"} );
          return null;
        });
    } else {
      this.messageService.notify({message: new Teksti("Lomakkeen lähetys epäonnistui. Tarkista virheet.", "lahetysEpaonnistui", "lomakeYleinen"), type: "warning"} );
      return Promise.resolve(null);
    }
  }

  /**
   * Tallentaa lomakkeen
   */
  saveLomake(notifyUser = true) {
    return this.sendLomake(LomakeStatus.KESKEN)
      .then(r => {
        if (notifyUser) {
          this.messageService.notify({message: new Teksti("Lomake tallennettu.", "lomakeTallennettu", "lomakeYleinen"), type: "success"} );
        }
        this.id = r.id;
        this.activatedRoute.params.subscribe(params => {
          const page = params['page'] ? params['page'] : 0;
          this.router.navigate(['/asiointi/lomake', this.toimintotyyppi, r.asia, this.lomakeTyyppiVersio, r.id, 'jatka', 'sivu', page]);
        });

      })
      .catch(e => {
        console.error("SaveLomake ei onnistunut", e);
        this.messageService.notify({message: new Teksti("Lomakkeen tallennus epäonnistui", "tallennusEpaonnistui", "lomakeYleinen"), type: "danger"} );
      });
  }

  /**
   * Keskeyttää lomakkeen.
   */
  cancelLomake() {
    this.messageService.notify({message: new Teksti("Toimenpide keskeytettiin.", "toimenpideKeskeytettiin", "lomakeYleinen"), type: "info"} );
    this.lomakeService.allowRedirect();
    return this.router.navigate([this.etusivuURL]);
  }

  onSetLiite(liiteChangeEvent: {liitteet: LiitetiedostoResponse[]; updateLomake: boolean; key: string}) {
    this.isLiitetiedostotUploaded = false;
    this.liitetiedostot[liiteChangeEvent.key] = liiteChangeEvent.liitteet;

    if (liiteChangeEvent.updateLomake) {
      // odotetaan hetki lomakeValues päivittymistä
      setTimeout(() => {
        this.saveLomake().then(() => this.isLiitetiedostotUploaded = true);
      }, 1000);
    } else {
      this.isLiitetiedostotUploaded = true;
    }
  }

  updateYhteenvetoValues() {
    this.yhteenvetoValues = this.convertLomakeToJSON();
    this.yhteenvetoService.updateLomakeArvot(this.yhteenvetoValues);
  }

  /**
   * Lähettää lomakkeen palvelimelle. Jos lomakkeen id on 0,
   * käytetään POST-metodia. Muussa tapauksessa käytetään PUT-metodia
   *
   * @param status - lomakkeen tila (kesken/valmis)
   * @returns Promise<LomakeResponse> - lomake response objekti
   */
  protected sendLomake(status: LomakeStatus): Promise<LomakeResponse> {
    const kesken = status === LomakeStatus.KESKEN;
    const requestBody = this.lomakeService.createLomakeRequest(this.convertLomakeToJSON(), this.liitetiedostot, this.perustiedot, this.yhteyshenkilot);
    if (this.id === 0) {
      return this.lomakeService.sendLomakeToV1Endpoint(requestBody, this.toimintotyyppi, this.lomakeAsia, kesken);
    } else {
      return this.lomakeService.updateLomake(requestBody, this.id, this.toimintotyyppi, this.lomakeAsia, kesken);
    }
  }

  getOhjeList(prefix: string, keys: string[], suffix = ""): string[] {
    return keys.map(k => `${prefix}${k}${suffix}`);
  }

  isOsoitetiedotOlemassa() {
    if (this.perustiedot) {
      if (this.perustiedot.kayntiosoite && this.perustiedot.kayntiosoite.osoite) {
        return true;
      }
    }
    return false;
  }
}
