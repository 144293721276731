<div class="row">
  <div class="col-12">
    <h1 attribute="raportit.otsikkoKateTarkastukset">Tulosraportit</h1>
  </div>
</div>

<!-- Rajaus -->
<form [formGroup]="raporttiForm" (submit)="submit()">
  <div class="row form-group">

    <div class="col-sm-6 col-lg-3">
      <label for="raportti-kuvaus" attribute="raportit.kuvaus">Kuvaus</label>
      <input id="raportti-kuvaus" formControlName="kuvaus" class="form-control" type="text" name="">
    </div>
    <div class="col-sm-6 col-lg-2" *ngIf="tapahtumatyypitTags?.length > 1">
      <fieldset>
        <legend class="small text-reset" attribute="raportit.tapahtumatyyppi">Tapahtumatyyppi</legend>
        <touko-taglist-check [formControlName]="'tapahtumatyyppi'"
                    [tags]="tapahtumatyypitTags"></touko-taglist-check>
      </fieldset>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">
      <touko-pvm [isInValid]="getInvalid('pvmAlku')" [htmlId]="'raportti-pvmAlku'" [item]="syotteet.pvmAlku"
              formControlName="pvmAlku"></touko-pvm>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">
      <touko-pvm [isInValid]="getInvalid('pvmLoppu')" [htmlId]="'raportti-pvmLoppu'" [item]="syotteet.pvmLoppu"
              formControlName="pvmLoppu"></touko-pvm>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-2">

      <button class="btn btn-outline-primary form-control no-label" type="button" (click)="reset()"
              id="raportti-tyhjenna"
              attribute="raportit.tyhjenna">
        Tyhjennä
      </button>
    </div>
  </div>
</form>


<raportti-sivutus></raportti-sivutus>

<div class="row form-group"></div>

<div class="row">
  <div class="col-sm-12">
    <datatable [showSpinner]="showLoader" [columnOrder]="columnOrder"
               [liiteDownloadFn]="liiteDownloadFn" [liiteZipDownloadFn]="liiteDownloadZipFn">
    </datatable>
  </div>
</div>
