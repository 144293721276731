<div class="row mt-4">
  <div class="col-sm-6 col-md-4 col-lg-2">
    <select [attr.aria-label]="'lomakeYleinen.lomakkeitaSivulla' | attribute" id="lomakehaku-nayta" class="form-control" [value]="lomakkeitaSivulla"
            (change)="selectLomakeLkm($event.target.value)">
      <option [value]="10">{{'raportit.nayta' | attribute}} 10</option>
      <option [value]="20">{{'raportit.nayta' | attribute}} 20</option>
      <option [value]="50">{{'raportit.nayta' | attribute}} 50</option>
    </select>
  </div>

  <div class="col-sm-6 col-md-4 col-lg-4">
    <ngb-pagination [collectionSize]="lomakkeet.length"
                    [pageSize]="lomakkeitaSivulla" [maxSize]="5"
                    [(page)]="sivu"
                    [boundaryLinks]="true"
                    (pageChange)="selectSivu($event)"></ngb-pagination>
  </div>
</div>

<lomake-table [lomakkeet]="taulukonLomakkeet"
              [sarakkeet]="columns"
              [sortOrder]="sortOrder"
              [viewOptions]="viewOptions"
              [previousSortKey]="previousSortKey"
              (sortChange)="sortByKey($event)"
              (selectItem)="openLink($event)"
></lomake-table>
