import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {RaporttiBaseClass} from "../raportti-base";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TulosraporttiService} from "../raportti.service";
import {Teksti} from "../../utils/teksti";
import {RaporttiHaku, Sarake, SarakeType, Tag, ViljelystarkastusRaportti} from "../raportti.utils";
import {DataTableComponent} from "../data-table.component";
import {Syote} from "../../touko-lomake/syote/syote";
import {ToukoValidation} from "../../touko-lomake/validators/validators";
import {RaporttiViewService} from "../raportti-view.service";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../account/account.service";

/**
 * Created by Seppo on 18/06/2018.
 */

const VIEW = "raportit";

@Component({
  selector: 'viljelystarkastus-raportti',
  templateUrl: './viljelystarkastus-raportti.component.html'
})
export class ViljelystarkastuspaatosComponent extends RaporttiBaseClass implements OnInit {
  @ViewChild("datatable") datatable: DataTableComponent;
  @Input() getRaportitFn: (...any) => Promise<RaporttiHaku>;
  raporttiForm: FormGroup;
  liiteDownloadFn: (tiedostonimi: string) => Promise<string>;
  liiteDownloadZipFn: (tiedostonimet: string[]) => Promise<string>;
  keys;
  kasvilajiTags: Tag[] = [];
  tapahtumatyypitTags: Tag[] = [];
  syotteet;

  protected toimiala = "SIEMEN";

  constructor(protected tulosraporttiService: TulosraporttiService,
              protected raporttiViewService: RaporttiViewService,
              protected accountService: AccountService,
              protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.alustaRajausLomake(
      this.fb.group({
        kasvilaji: null,
        tapahtumatyyppi: [],
        kasvilajike: null,
        viljelysTunnus: null,
        viljelystarkastusNumero: null,
        viljelijaNimi: null,
        pvmAlku: [null, ToukoValidation.isValidDate],
        pvmLoppu: [null, ToukoValidation.isValidDate],
      })
    );

    this.syotteet = {
      kasvilaji: new Syote(new Teksti('Kasvilaji', 'kasvilaji', VIEW), null, null, null, {dropdownTeksti: new Teksti('Kasvilaji', 'valitseKasvilaji', VIEW)}),
      tapahtumatyyppi: new Syote(new Teksti('Tapahtumatyyppi', 'tapahtumatyyppi', VIEW), null, null, null, {dropdownTeksti: new Teksti('Valitse', 'valitseTapahtumatyyppi', VIEW)}),
      pvmAlku: new Syote(new Teksti('Alkaen', 'alkaen', VIEW)),
      pvmLoppu: new Syote(new Teksti('Saakka', 'saakka', VIEW)),
    };

    this.liiteDownloadFn = this.tulosraporttiService.getViljelystarkastusLiite.bind(this.tulosraporttiService);
    this.liiteDownloadZipFn = this.tulosraporttiService.getViljelystarkastusLiiteZip.bind(this.tulosraporttiService);
    this.getRaportitFn = this.getRaportitFn || this.tulosraporttiService.getViljelystarkastusRaportit.bind(this.tulosraporttiService);

    this.keys = {
      pvm: new Sarake('kasiteltyAika', new Teksti(VIEW, 'pvm', VIEW), SarakeType.DATE),
      dokumentti: this.getAsiakirjatSarakeTitle('dokumentti'),
      kasvilaji: new Sarake('kasvilaji', new Teksti(VIEW, "kasvilaji", VIEW)),
      kasvilajike: new Sarake('kasvilajike', new Teksti(VIEW, "kasvilajike", VIEW)),
      viljelija: new Sarake('viljelijaNimi', new Teksti(VIEW, "viljelija", VIEW)),
      viljelysTunnus: new Sarake('viljelysTunnus', new Teksti(VIEW, "viljelysTunnus", VIEW), SarakeType.DEFAULT, (val) => "no-word-break"),
      viljelysNumero: new Sarake('viljelystarkastusNumero', new Teksti(VIEW, "viljelysNumero", VIEW), SarakeType.DEFAULT, (val) => "no-word-break")
    };

    this.columnOrder = [
      this.keys.viljelysTunnus,
      this.keys.viljelysNumero,
      this.keys.viljelija,
      this.keys.pvm,
      this.keys.kasvilaji,
      this.keys.kasvilajike,
      this.keys.dokumentti
    ];

    this.initRaportit().then(() => {
      this.initTags();
    });
  }

  initTags() {
    this.tapahtumatyypitTags = this.mapField((r: ViljelystarkastusRaportti) => new Tag(r.tapahtumatyyppiNimi, r.tapahtumatyyppi));
    this.kasvilajiTags = this.mapField((r: ViljelystarkastusRaportti) => new Tag(r.kasvilaji, r.kasvilaji));
  }
}

