import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {MatcherService, QuestionGroup, QuestionInitMatcher, QuestionInitValue, QuestionUpdate} from "ee-lahete";
import {LoaderService} from "../loader/loader.service";
import {Teksti} from "../utils/teksti";
import {YhteenvetoTyyppi} from "../touko-lomake/yhteenveto/yhteenveto-utils";
import {LaheteYhteenvetoService} from "./yhteenveto/lahete-yhteenveto.service";
import {LaheteYhteenvetoItem} from "./yhteenveto/lahete-yhteenveto";
import {ElaintietoResponse, ToukoLaheteService} from "./touko-lahete.service";
import {ToukoLaheteStyles} from "./touko-lahete-styles";
import {LomakeBaseService} from "../touko-lomake/lomake-base.service";
import {LiitetiedostoResponse} from "../touko-lomake/touko-lomake-utils";
import {LiitetiedostoPalvelinService} from "../touko-lomake/touko-liitetiedosto.service";
import {MessageService} from "../message/message.service";
import {AccountService} from "../account/account.service";
import {first} from "rxjs/operators";
import {Account} from "../account/account";
import * as JsBarcode from 'jsbarcode';
import {LaheteService} from './lahete/lahete.service';
import {LaheteKoodisto} from './lahete/lahete-koodisto';
import {EnvironmentService} from "../../environments/environment.service";

@Component({
  selector: 'app-touko-lahete',
  templateUrl: './touko-lahete.component.html',
  styleUrls: ['./touko-lahete.component.scss'],
  providers: [
    LaheteService,
    MatcherService,
    LaheteYhteenvetoService,
    {provide: LomakeBaseService, useClass: ToukoLaheteService},
  ]
})
export class ToukoLaheteComponent implements OnInit {

  @ViewChild("laheteYhteenvetoDiv", {static: false}) laheteYhteenvetoDiv: ElementRef;
  @ViewChild("laheteYhteenvetoPrintDiv", {static: false}) laheteYhteenvetoPrintDiv: ElementRef;
  @ViewChild("barcodeCanvas", {static: false}) barcodeCanvas: ElementRef;

  id = 0;
  lomakeAsia: string;
  laheteKoodistot: LaheteKoodisto = null;
  otsikko = new Teksti("-", "laheteOtsikko", "sahkoelain");

  lomakeGroup: FormGroup = null;
  lomakeModel: { [s: string]: QuestionGroup } = null;
  isInitialized = false;
  yhteenveto: LaheteYhteenvetoItem[] = [];
  liitetiedostot: { [s: string]: LiitetiedostoResponse[] } = {};
  toukoLaheteServiceImpl: ToukoLaheteService;
  barcodePresent = false;
  mallipohjanimi: string;
  environmentService: EnvironmentService;

  // Pitopaikkaan liittyvät tiedot
  pitopaikkaElaintietoMap: Map<string, ElaintietoResponse[]> = new Map<string, ElaintietoResponse[]>();

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly renderer: Renderer2,
              private readonly laheteService: LaheteService,
              private readonly toukoLaheteService: LomakeBaseService,
              private readonly loaderService: LoaderService,
              private readonly matcherService: MatcherService,
              private readonly yhteenvetoService: LaheteYhteenvetoService,
              private readonly liitetiedostoService: LiitetiedostoPalvelinService,
              private readonly messageService: MessageService,
              private readonly accountService: AccountService) {

    this.toukoLaheteServiceImpl = this.toukoLaheteService as ToukoLaheteService;
    this.submit = this.submit.bind(this);
    this.save = this.save.bind(this);
    this.updateYhteenveto = this.updateYhteenveto.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  private static lockControlWithValue(control: AbstractControl, value: any) {
    const emitOpts = {onlySelf: true, emitEvent: false};
    control.setValue(value, emitOpts);
    control.disable(emitOpts);
  }

  ngOnInit() {
    this.yhteenvetoService.initYhteenvetoService();
    this.toukoLaheteServiceImpl.blockRedirect();
    this.environmentService = new EnvironmentService();

    this.laheteService.getLaheteKoodisto()
        .then(response => this.laheteKoodistot = response)
        .then(() => this.loadElaintiedot())
        .then(() => this.storeMaksajaIfPresent())
        .then(() => this.storeConstants())
        .then(() => this.loadLomakeValues())
        .then(lomakeValues => this.buildLahete(lomakeValues))
        .then(() => this.loaderService.stopLoadingAnimation());

    this.laheteService.setLanguage(this.toukoLaheteServiceImpl.getDefaultLanguage());
  }

  autosaveFunction() {
    this.save(true);
  }

  buildLahete(laheteValues: any) {
    this.laheteService.$questionUpdate.subscribe((modelUpdate: QuestionUpdate) => {
      this.matcherService.updateForm(modelUpdate);
    });

    this.laheteService.$questionInitValue.subscribe((initValue: QuestionInitValue) => {
      this.matcherService.registerInitialValue(initValue);
    });

    this.laheteService.$questionInitFilter.subscribe((initMatcher: QuestionInitMatcher) => {
      this.matcherService.registerInitialFilter(initMatcher);
    });

    const laheteGroups = this.createLaheteModel(laheteValues !== null, laheteValues);
    this.matcherService.initModelAndForm(laheteGroups);
    this.matcherService.initStyleMap(ToukoLaheteStyles.styles);

    this.lomakeModel = this.matcherService.model;
    this.lomakeGroup = this.matcherService.form;

    this.lomakeGroup.addControl('toimitusehdot', new FormControl(null, Validators.required));

    const visibleSummaryPaths = ['lahete', 'tilaaja', 'elainlajit', 'toimitustiedot', 'tutkimukset', 'valitutTutkimukset.valitut', 'sahkoinenAsiointi', 'automTiedoksisaaja'];
    this.matcherService.setConstantFilteredPaths(visibleSummaryPaths);

    this.isInitialized = true;

    this.setTilaaja(laheteValues ? laheteValues.tilaaja.henkilotiedot : null);
    this.setLomakeTunnusIfEmpty();
    this.buildYhteenveto();
  }

  createLaheteModel(useLaheteValues: boolean, laheteValues: any) {
    return [
      this.laheteService.getLahetetunnusGroup({
        initialValues: laheteValues,
        controlType: 'id',
        applyValues: useLaheteValues
      }),
      this.laheteService.getTilaajaGroup({
        initialValues: laheteValues,
        controlType: 'tilaaja',
        applyValues: useLaheteValues
      }),
      this.laheteService.getSahkoinenAsiointiGroup({
            initialValues: laheteValues,
            controlType: 'sahkoinenAsiointi',
            applyValues: useLaheteValues
          }
      ),
      this.laheteService.getElainlaakariGroup({
        initialValues: laheteValues,
        controlType: 'henkilo',
        applyValues: useLaheteValues
      }),
      this.laheteService.getOmistajaGroup({
        initialValues: laheteValues,
        controlType: 'henkilo',
        applyValues: useLaheteValues
      }),
      this.laheteService.getTiedoksisaajaGroup({
        initialValues: laheteValues,
        controlType: 'henkilo',
        applyValues: useLaheteValues
      }),
      this.laheteService.getElainlajiryhmakooditGroup({
        initialValues: laheteValues,
        controlType: 'radio',
        applyValues: useLaheteValues
      }),
      this.laheteService.getElainlajiGroup({
        initialValues: laheteValues,
        controlType: 'radio',
        applyValues: useLaheteValues
      }),
      this.laheteService.getTutkimushakuGroup(),
      this.laheteService.getTutkimusalaGroup(),
      this.laheteService.getTutkimusGroup({
        initialValues: laheteValues,
        controlType: 'checkbox',
        applyValues: useLaheteValues
      }),
      this.laheteService.getValitutTutkimuksetGroup({
        initialValues: laheteValues,
        controlType: 'tutkimus-card',
        applyValues: useLaheteValues
      }),
      this.laheteService.getNaytetiedotGroup({
        initialValues: laheteValues,
        controlType: 'naytetieto',
        applyValues: useLaheteValues
      }),
      this.laheteService.getAutomaattinenTiedoksisaajaGroup({
        initialValues: laheteValues,
        controlType: 'checkbox',
        applyValues: useLaheteValues
      }),
      this.laheteService.getTilatiedotGroup({
        initialValues: laheteValues,
        controlType: '',
        applyValues: useLaheteValues
      }),
      this.laheteService.getTilatyypitGroup({
        initialValues: laheteValues,
        controlType: '',
        applyValues: useLaheteValues
      }),
      this.laheteService.getTaustakysymyksetGroup({
        initialValues: laheteValues,
        controlType: 'tausta',
        applyValues: useLaheteValues
      }),
      this.laheteService.getToimitusosoiteGroup({
        initialValues: laheteValues,
        controlType: 'radio-card',
        applyValues: useLaheteValues
      })
    ].filter(group => group !== null);
  }

  afterChildrenInit(isInitialized: boolean) {
    if (isInitialized) {
      setTimeout(() => {
        this.matcherService.applyRegisteredInitialValues();
        this.matcherService.applyRegisteredMatchers();
      }, 1);
    }
  }

  loadLomakeValues() {
    return this.route.params
        .pipe(first())
        .toPromise()
        .then(p => {
          this.id = p.id ? parseInt(p.id, 10) : 0;
          if (this.id > 0) {
            return Promise.resolve(
                this.toukoLaheteServiceImpl.getLomake(this.id)
                    .then(lahete => {
                      this.mallipohjanimi = lahete?.mallipohjanimi;
                      return JSON.parse(lahete.body);
                    })
            );
          } else {
            return Promise.resolve(null);
          }
        });
  }

  /**
   * Haetaan lista käyttäjän omistamista elämistä ja järjestetään eläinlista pitopaikkatunnuksittain
   */
  loadElaintiedot() {
    this.toukoLaheteServiceImpl.getNautarekisterinelaimet()
        .then(elaimet => {
          if (elaimet) {
            // Luettelo pitopaikkatunnuksista
            const pitopaikat = new Set<string>();
            elaimet.forEach(elain => {
              // dd-mm-yyyy hh:mm:ss -> dd-mm-yyyy
              elain.syntymaPvm = elain.syntymaPvm?.split(' ')[0];
              if (elain.pitopaikkatunnus) {
                pitopaikat.add(elain.pitopaikkatunnus);
              }
            });
            // Lista eläimistä pitopaikkatunnuksittain
            const elaintiedot = new Map<string, ElaintietoResponse[]>();
            pitopaikat.forEach(pitopaikkatunnus => {
              const pitopaikanElaimet = elaimet.filter(elain => elain.pitopaikkatunnus === pitopaikkatunnus);
              elaintiedot.set(pitopaikkatunnus, pitopaikanElaimet);
            });
            this.pitopaikkaElaintietoMap = elaintiedot;
          }
        });
  }


  storeMaksajaIfPresent() {
    return this.toukoLaheteServiceImpl.getMaksaja()
        .then(maksaja => {
          if (maksaja) {
            this.laheteService.storeValue('maksaja', maksaja);
          }
        });
  }

  storeConstants() {
    return this.toukoLaheteServiceImpl.cachePostinumerot()
        .then(postinumerot => {
          if (postinumerot) {
            this.laheteService.storeValue('postitoimipaikkaFn', this.toukoLaheteServiceImpl.getPostitoimipaikkaFn());
          }
        });
  }

  setTilaaja(loadedValues?) {
    let tilaaja: Tilaaja;
    const account = this.accountService.getCurrentAccount();
    if (loadedValues) {
      tilaaja = new Tilaaja(loadedValues);
    } else {
      tilaaja = Tilaaja.createByAccount(account);
    }

    const tilaajaHenkilotiedot = this.lomakeGroup.get(['tilaaja', 'henkilotiedot']);
    ToukoLaheteComponent.lockControlWithValue(tilaajaHenkilotiedot.get('etunimi'), tilaaja.etunimi);
    ToukoLaheteComponent.lockControlWithValue(tilaajaHenkilotiedot.get('sukunimi'), tilaaja.sukunimi);

    if (account.yritys) {
      ToukoLaheteComponent.lockControlWithValue(tilaajaHenkilotiedot.get('ytunnus'), tilaaja.ytunnus);
      ToukoLaheteComponent.lockControlWithValue(tilaajaHenkilotiedot.get('yritys'), tilaaja.yritys);
    }
  }

  setLomakeTunnusIfEmpty() {
    if (!this.lomakeGroup.get(['lahete', 'id'])?.value) {
      this.lomakeGroup.get(['lahete', 'id']).setValue(LaheteService.createTunnus());
    }
  }

  buildYhteenveto() {
    const yhteenvetoTyypit = {
      id: {summaryType: YhteenvetoTyyppi.VALUE_ONLY},
      hidden: {summaryType: YhteenvetoTyyppi.NONE},
      card: {summaryType: YhteenvetoTyyppi.NONE},
      checkbox: {summaryType: YhteenvetoTyyppi.LABEL_ONLY},
      radio: {summaryType: YhteenvetoTyyppi.LABEL_AND_TEXT},
      'radio-card': {summaryType: YhteenvetoTyyppi.LABEL_PREFORMATTED},
      'radio-nayte': {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS},
      text: {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE},
      'text-input': {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE},
      textarea: {summaryType: YhteenvetoTyyppi.LABEL_AND_VALUE},
      select: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_LABEL},
      selection: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_LABEL},
      date: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_DATE},
      pvm: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_DATE},
      pvmVuosi: {summaryType: YhteenvetoTyyppi.SINGLE_SELECTION_DATE}
    };

    Object.entries(yhteenvetoTyypit)
        .forEach(([key, value]) => {
          this.matcherService.applyOptionsByControlType(key, value);
        });
  }

  submit() {
    if (this.toukoLaheteServiceImpl.checkCanSubmitLomake(this.lomakeGroup)) {
      return this.handleSaveAndSendYhteenveto()
          .then(() => this.toukoLaheteServiceImpl.sendLomake(
              this.getValuesToSend(),
              this.id,
              this.liitetiedostot))
          .then(() => {
            this.messageService.notify({
              message: new Teksti("Lomake lähetetty", "lomakeLahetetty", "lomakeYleinen"),
              type: "success"
            });
            return this.toukoLaheteServiceImpl.redirectToEtusivu();
          });
    }
    return Promise.resolve(null);
  }

  cancel() {
    this.messageService.notify({
      message: new Teksti("Toimenpide keskeytettiin.", "toimenpideKeskeytettiin", "lomakeYleinen"),
      type: "info"
    });
    return this.toukoLaheteServiceImpl.redirectToEtusivu();
  }

  addTiedoksisaaja() {
    const tiedoksisaajaArray = this.lomakeGroup.get(['tiedoksisaaja', 'tiedoksisaajat']) as FormArray;
    const nextTiedoksisaajaId = tiedoksisaajaArray.length.toString(10);
    this.lomakeModel.tiedoksisaaja.actions.addNewTiedoksisaaja(nextTiedoksisaajaId);
  }

  nautaRekkari(): boolean {
    const show = this.lomakeGroup?.controls['elainlajiValinta']?.value['elainlaji'] === 'SPAN00304'
        && this.pitopaikkaElaintietoMap?.size !== 0;
    this.laheteService.storeValue('nautaElainhaku', show);
    return show;
  }

  getPitopaikat() {
    return Array.from(this.pitopaikkaElaintietoMap.keys());
  }

  selectPitopaikka(pitopaikka: string): void {
    this.laheteService.storeValue('pitopaikanelaimet', this.pitopaikkaElaintietoMap.get(pitopaikka));
  }

  updateYhteenveto() {
    this.buildYhteenveto();
    this.lomakeGroup.valueChanges.subscribe(() =>
        this.yhteenveto = this.yhteenvetoService.getYhteenveto(
            this.lomakeModel,
            this.lomakeGroup.getRawValue(),
            this.matcherService.getFilteredPaths()
        ));
  }

  printYhteenveto() {
    this.addBarcodeToCanvas();
    window.print();
  }

  getValuesToSend() {
    this.updateYhteenveto();
    return this.matcherService.getVisibleFormValues(
        this.yhteenvetoService.getVisibleYhteenvetoControlPaths(),
        this.lomakeGroup.getRawValue()
    );
  }

  save(notifyUser = true) {
    return this.toukoLaheteServiceImpl.saveLomake(
            this.getValuesToSend(),
            this.id,
            this.mallipohjanimi,
            this.liitetiedostot)
        .then(r => {
          if (notifyUser) {
            const url = this.router.url.split('/');
            const tailIndex = url.findIndex(fragment => fragment === 'aloita' || fragment === 'jatka');
            const urlTail = `jatka/${url.slice(tailIndex + 1).join('/')}`;
            const redirectUrl = `/asiointi/lomake/lahete/elaintautitutkimus/v1/${r.id}/${urlTail}`;
            this.router.navigate([redirectUrl]);
            this.messageService.notify({
              message: new Teksti("Lomake tallennettu.", "lomakeTallennettu", "lomakeYleinen"),
              type: "success"
            });
          }
          this.id = r.id;
          this.mallipohjanimi = r.mallipohjanimi;
        })
        .catch(e => {
          this.messageService.notify({
            message: new Teksti("Lomakkeen tallennus epäonnistui", "tallennusEpaonnistui", "lomakeYleinen"),
            type: "danger"
          });
        });
  }

  private handleSaveAndSendYhteenveto(): Promise<void> {
    // Lähetettäessä lomaketta joka perustuu mallipohjaan
    // älä köytä mallipohjan id:tä vaan tallenna uusi lomake.
    if (this.mallipohjanimi) {
      this.id = 0;
    }
    return this.id === 0 ?
        this.save(false).then(() => this.sendYhteenvetoPdf()) :
        this.sendYhteenvetoPdf();
  }

  private sendYhteenvetoPdf(): Promise<void> {
    const title = 'Lähete';
    const barcode = (this.addBarcodeToCanvas() as HTMLCanvasElement).toDataURL("image/jpg");
    const contentHtml = this.laheteYhteenvetoDiv.nativeElement;

    return this.yhteenvetoService.createYhteenvetoPdf(this.id, title, contentHtml, barcode)
        .then(pdf => this.toukoLaheteServiceImpl.sendYhteenveto(pdf, this.id))
        .then(response => {
          this.liitetiedostot['yhteenveto'] = [response];
        });
  }

  private addBarcodeToCanvas(): Element {
    const laheteTunnus = this.lomakeGroup.get('lahete.id').value;
    const element = this.renderer.createElement('canvas') as Element;
    if (!this.barcodePresent && laheteTunnus) {
      JsBarcode(element, laheteTunnus, {height: 60, displayValue: true});
      this.renderer.appendChild(this.barcodeCanvas.nativeElement, element);
      this.barcodePresent = true;
    }
    return element;
  }
}

class Tilaaja {
  etunimi: string;
  sukunimi: string;
  ytunnus: string;
  yritys: string;

  constructor(tilaaja?: Tilaaja) {
    this.etunimi = tilaaja?.etunimi;
    this.sukunimi = tilaaja?.sukunimi;
    this.ytunnus = tilaaja?.ytunnus;
    this.yritys = tilaaja?.yritys;
  }

  static createByAccount(account: Account): Tilaaja {
    const tilaaja = new Tilaaja();
    tilaaja.etunimi = account.firstName;
    tilaaja.sukunimi = account.lastName;
    tilaaja.yritys = account.yritys ? account.yritys.paaToiminimi : null;
    tilaaja.ytunnus = account.yritys ? account.yritys.yTunnus : null;
    return tilaaja;
  }
}
