import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {merge, Observable, Subject, timer} from 'rxjs';
import {Kasvilaji} from '../../../kasvi/kasvilaji.model';
import {filter, map, switchMap} from 'rxjs/operators';
import {NgbModal, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {KasviService} from '../../../kasvi/kasvi.service';
import {AttributeService} from '../../../tiira/attribute.service';
import {ToukoUusiKasvilajiModal, ToukoUusiKasviLajiModalResponse} from './touko-uusi-kasvi-laji-modal.component';
import {Kasvilajike} from '../../../kasvi/kasvilajike.model';


@Component({
  selector: 'kasvilaji-valinta',
  templateUrl: './touko-kasvilaji-valinta.component.html',
  styleUrls: ['./touko-kasvilaji-valinta.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToukoKasvilajiValintaComponent),
    }]
})
export class ToukoKasvilajiValintaComponent implements ControlValueAccessor {

  public constructor(public controlContainer: ControlContainer, private kasviService: KasviService, private attribute: AttributeService, private modalService: NgbModal) {
  }

  @Input('value') _value: Kasvilaji;
  @Input() vainLaji = false;

  @Output() uusiKasvilajike = new EventEmitter<Kasvilajike>();

  disabled = false;

  lajiFocus$ = new Subject<string>();

  private closingPopup = false;

  onChange: any = () => {
    // This is intentional
  };
  onTouched: any = () => {
    // This is intentional
  };

  get value(): Kasvilaji {
    return this._value;
  }

  set value(val: Kasvilaji) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  haeKasvilaji = (teksti: Observable<string>): Observable<Kasvilaji[]> => merge(teksti,
      this.lajiFocus$.pipe(
          filter(() => !this.closingPopup)
      )
  ).pipe<Kasvilaji[]>(
      switchMap<string, Observable<Kasvilaji[]>>((hakusana: string) =>
          this.kasviService.haeLajit(hakusana, 20)
              .pipe<Kasvilaji[]>(map<Kasvilaji[], Kasvilaji[]>(lajit => (hakusana.length > 1) ? lajit.concat([{
                elmoid: "UUSI",
                nimi: this.attribute.simpleTranslate("luomu", (this.vainLaji) ? "uusiKasvilaji" : "uusiKasvilajiLajike", null, "Uusi kasvilaji ja lajike")
              }]) : lajit))
      ));

  muotoileKasvilaji(laji: Kasvilaji): string {
    return laji.nimi;
  }

  onSelectKasvilaji(payload: NgbTypeaheadSelectItemEvent) {
    const kasvilaji = payload.item as Kasvilaji;

    if (kasvilaji.elmoid === "UUSI") {

      const modalref = this.modalService.open(ToukoUusiKasvilajiModal);
      modalref.componentInstance.vainLaji = this.vainLaji;
      modalref.result.then((paluu: ToukoUusiKasviLajiModalResponse) => {

        if (!this.vainLaji) {
          this.uusiKasvilajike.emit(paluu.lajike);
        }

        this.value = paluu.laji;

        this.closingPopup = true;

        // Ikkunan sulkeutumisen jälkeen focus palautuu kenttään ja popup aukeaa uudestaan.
        // Timeri sulkee sen automaattisesti tän tapahtumisen jälkeen.
        timer(1000).subscribe((x) => {
          this.closingPopup = false;
        });
      });
      payload.preventDefault();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: Kasvilaji): void {
    if (value) {
      this.value = value;
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
