import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LiitetiedostoPalvelinService} from "../../touko-lomake/touko-liitetiedosto.service";
import {LiitetiedostoResponse} from "../../touko-lomake/touko-lomake-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MessageService} from "../../message/message.service";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../modaalit/modal-utils";
import {Teksti} from "../../utils/teksti";
import {FieldArray, FieldGroup} from "ngx-fielding";

@Component({
  selector: 'liite-list',
  template: `
    <div *ngFor="let liite of liitteetArray.controlFields; let i = index" class="input-group mb-2">
      <div class="form-control disabled" [id]="htmlId + '-' + i">{{ liite.value.tiedostonimi }}</div>
      <div class="input-group-append">
        <button [attribute]="'lomakeYleinen.liiteAvaa'" (click)="openLiite(liite.asGroup())" class="btn btn-primary"
                type="button">Avaa
        </button>
        <button [attribute]="'lomakeYleinen.liitePoista'" class="btn btn-outline-danger" type="button"
                (click)="clickRemoveFile(liite.asGroup())" id="poista-{{htmlId}}-{{i}}">
          Poista
        </button>
      </div>
    </div>

    <div class="mb-4">
      <liite-lataus
        *ngIf="liitteetArray?.controlFields.length < maxLiiteLkm"
        (liiteOutput)="uploadLiite($event)"
        [labelledBy]="labelledBy"
        [htmlId]="htmlId"
        [isDisabled]="disabled">
      </liite-lataus>
    </div>
  `,
  styles: [`
    .disabled {
      background: #e9ecef;
    }
  `],
  providers: [
    LiitetiedostoPalvelinService
  ]
})
export class LiiteListComponent {
  @Input() labelledBy: string = null;
  @Input() htmlId: string;
  @Input() maxLiiteLkm = 1;
  @Input() selite: string;
  @Input() lomakeId: number;
  @Input() liitteetArray: FieldArray;
  @Input() disabled = false;

  /**
   * Liitetiedostot menevät palvelimelle / poistetaan sieltä samantien, muista aina tallentaa lomake kun updateLomake
   * on true.
   *
   * Muuten on riskinä että lomakkeeseen jää roikkumaan liitetiedosto joka on jo poistettu palvelimelta.
   */
  @Output() liiteOut: EventEmitter<LiitetiedostoResponse[]> = new EventEmitter();

  constructor(private liitetiedostoService: LiitetiedostoPalvelinService,
              private modalService: NgbModal,
              private messageService: MessageService) {
  }

  clickRemoveFile(item: FieldGroup) {
    const modalRef = this.modalService.open(ModalSimpleComponent);
    modalRef.componentInstance.modalTiedot = new ModalData(
      new Teksti("Otsikko", "modalPoistetaankoLiite", "lomakeYleinen"),
      new Teksti(item.value.tiedostonimi),
      new Teksti("Poista", "liitePoista", "lomakeYleinen"),
      this.removeFile.bind(this, item),
      "btn-danger"
    );
  }

  private removeFile(item: FieldGroup) {
    const itemId = item.controls.id.value
    return this.liitetiedostoService.deleteLiitetiedosto(itemId)
      .then(_ignore => {
        this.liiteOut.emit(this.liitteetArray.value);
        this.liitteetArray.controls = this.liitteetArray.controls.filter(liite => liite.value.id !== itemId);
        this.liitteetArray.setValue(this.liitteetArray.value.filter(liite => liite.id !== itemId));
        this.messageService.notify({message: new Teksti("Liitetiedosto poistettu", "liitePoistettu", "lomakeYleinen"), type: "success"} );
      });
  }

  uploadFile(liite: File, selite: string, lomId: number): Promise<void> {
    return this.liitetiedostoService.sendLiitetiedosto(liite, selite, lomId)
      .then(r => {
        this.messageService.notify({message: new Teksti("Liitetiedosto ladattu onnistuneesti", "liiteLadattu", "lomakeYleinen"), type: "success"} );
        const l = this.liitteetArray.buildField();
        l.patchValue(r);
        this.liitteetArray.push(l);
        this.liiteOut.emit(this.liitteetArray.value);
      });
  }

  /**
   * Avaa liitteen uuteen ikkunaan. HUOM! IE:llä tiedosto ladataan.
   *
   * @returns
   * @param _item
   */
  openLiite(_item: FieldGroup) {
    const item = _item.asGroup();
    return this.liitetiedostoService.downloadLiitetiedosto(item.value.id)
      .then(blob => {
        if (this.isIE()) {
          const nav = (window.navigator as any);
          nav.msSaveOrOpenBlob(blob, item.value.tiedostonimi);
        } else {
          const file = window.URL.createObjectURL(blob);
          window.open(file, "_blank");
        }
      });
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }

  uploadLiite(file) {
    this.uploadFile(file, this.selite, this.lomakeId);
  }
}
