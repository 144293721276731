import {Pipe, PipeTransform} from '@angular/core';
import {FieldArray} from "ngx-fielding";

@Pipe({
  name: 'filterDetails'
})
export class FilterDetailsPipe implements PipeTransform {
  transform(input: any[]): any[] {
    return input.filter(item => !(item instanceof FieldArray || item.field.htmlId.includes("Details")));
  }
}
