import {BehaviorSubject, Observable, Subscriber} from "rxjs";
import {LomakeResponse} from "./touko-lomake-utils";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable()
export class LaheteHakuService {

  private readonly _lahetetyt = new BehaviorSubject<LomakeResponse[]>([]);
  lahetetyt$ = this._lahetetyt.asObservable();

  private readonly _keskeneraiset = new BehaviorSubject<LomakeResponse[]>([]);
  keskeneraiset$ = this._keskeneraiset.asObservable();

  private readonly _mallipohjat = new BehaviorSubject<LomakeResponse[]>([]);
  mallipohjat$ = this._mallipohjat.asObservable();

  // private readonly _isGetLomakkeetRequestPending = new BehaviorSubject<LaheteRequestsStatus>(
  //   {lahetetty: false, kesken: false, mallipohja: false}
  // );
  private readonly _isGetLomakkeetRequestPending = new BehaviorSubject<LaheteRequestsStatus>(
    {lahetetty: false, kesken: false, mallipohja: false}
  );

  private getLomakkeetSub: Subscriber<any>;

  constructor(private readonly http: HttpClient) {
  }

  getLahetetyt(params: {sivu: number; nayta: number} = null): Promise<LomakeResponse[]> {
    const url = '/api/v1/as/lomakkeet/lahetteet/lahetetyt';
    return this.getLomakkeet(url, params, 'lahetetty').then(lomakkeet => {
      this._lahetetyt.next(lomakkeet);
      return lomakkeet;
    });
  }

  getKeskeneraiset(params: {sivu: number; nayta: number} = null): Promise<LomakeResponse[]> {
    const url = '/api/v1/as/lomakkeet/lahetteet/keskeneraiset';
    return this.getLomakkeet(url, params, 'kesken').then(lomakkeet => {
      this._keskeneraiset.next(lomakkeet);
      return lomakkeet;
    });
  }

  getMallipohjat(params: {sivu: number; nayta: number} = null): Promise<LomakeResponse[]> {
    const url = '/api/v1/as/lomakkeet/lahetteet/mallipohjat';
    return this.getLomakkeet(url, params, 'mallipohja').then(lomakkeet => {
      this._mallipohjat.next(lomakkeet);
      return lomakkeet;
    });
  }

  public deleteKeskenerainenLahete(id: number): Promise<any> {
    return this.http.delete(`/api/v1/as/lomakkeet/lahetteet/${id}`)
    .toPromise()
    .then(() => {
      this.updateLomakkeetAfterDelete(id);
    });
  }

  public updateLomakkeetAfterDelete(id: number) {
    const keskeneraiset = this._keskeneraiset.getValue();
    const mallipohjat = this._mallipohjat.getValue();

    if (keskeneraiset.some(k => k.id === id)) {
      this._keskeneraiset.next(keskeneraiset.filter(k => k.id !== id));
    } else {
      this._mallipohjat.next(mallipohjat.filter(m => m.id !== id));
    }
  }

  updateLomake(response: LomakeResponse) {
    let keskeneraiset = this._keskeneraiset.getValue();
    let mallipohjat = this._mallipohjat.getValue();

    if (keskeneraiset.some(keskenerainen => keskenerainen.id === response.id)) {
      keskeneraiset = keskeneraiset.map(keskenerainen => keskenerainen.id === response.id ? response : keskenerainen);
      this._keskeneraiset.next(keskeneraiset);
    } else {
      mallipohjat = mallipohjat.map(mallipohja => mallipohja.id === response.id ? response : mallipohja);
      this._mallipohjat.next(mallipohjat);
    }
  }

  private getLomakkeet(url, params, request: 'lahetetty' | 'kesken' | 'mallipohja') {
    if (this._isGetLomakkeetRequestPending[request]?.value) {
      this.getLomakkeetSub.error("CANCEL");
    }

    const obs = new Observable(o => this.getLomakkeetSub = o);
    const currentStatus = this._isGetLomakkeetRequestPending.getValue();
    currentStatus[request] = true;
    this._isGetLomakkeetRequestPending.next(currentStatus);

    return this.http.get(url, {params}).pipe(takeUntil(obs))
    .toPromise()
    .then(data => data as LomakeResponse[])
    .then(response => {
      currentStatus[request] = false;
      this._isGetLomakkeetRequestPending.next(currentStatus);
      return response;
    }).catch(reason => {
      if (reason !== "CANCEL") {
        console.error(reason);
        return reason;
      }
      return [];
    });
  }

}

interface LaheteRequestsStatus {
  lahetetty: boolean;
  kesken: boolean;
  mallipohja: boolean;
}
