import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {MessageService} from "../message/message.service";
import {Teksti} from "../utils/teksti";
import {LomakeBaseService} from "../touko-lomake/lomake-base.service";
import {TiiraService} from "../tiira/tiira.service";
import {LiitetiedostoResponse} from "../touko-lomake/touko-lomake-utils";
import {KoodistoService} from "../koodisto/koodisto.service";
import {LomakeRedirectService} from "../touko-lomake/lomake-redirect.service";
import {LomakeErrorService} from "../touko-lomake/lomake-error.service";

@Injectable()
export class ToukoLaheteService extends LomakeBaseService {

  // TODO: pitäisi pärjätä baseAsiakasURL attribuutilla
  private readonly AS_BASEURL = '/api/v1/as/lahetteet';

  readonly baseAsiakasURL = 'api/v1/as/lomakkeet/lahetteet';
  readonly baseViranomainenURL = 'api/v1/vk/lomakkeet/lahetteet';

  private readonly _isLahetetty = new BehaviorSubject(false);
  isLahetetty$ = this._isLahetetty.asObservable();

  constructor(
    protected readonly http: HttpClient,
    protected readonly lomakeErrorService: LomakeErrorService,
    private readonly messageService: MessageService,
    private readonly tiiraService: TiiraService,
    private readonly koodistoService: KoodistoService,
    private readonly laheteRedirectService: LomakeRedirectService
  ) {
    super();
  }

  public getDefaultLanguage(): string {
    return this.tiiraService.getDefaultLanguage();
  }

  public allowRedirect() {
    this.laheteRedirectService.canRedirect = true;
  }

  public redirectToEtusivu() {
    return this.laheteRedirectService.redirectTo(["/asiointi/etusivu"]);
  }

  public blockRedirect() {
    this.laheteRedirectService.canRedirect = false;
  }

  public sendLomake(formValues: Object, id, liite: { [s: string]: LiitetiedostoResponse[] }): Promise<Lahete> {
    const laheteRequest = this.createLaheteRequest(formValues, id, liite);
    const requestParams = {kesken: "false"};
    return this.http.put(`${this.AS_BASEURL}/${id}`, laheteRequest, {params: requestParams})
      .toPromise()
      .then(r => r as Lahete);
  }

  public createLaheteRequest(formValues: Object, id: number, liite: { [s: string]: LiitetiedostoResponse[] }) {
    const lahete = new Lahete();
    lahete.body = JSON.stringify(formValues);
    lahete.id = id > 0 ? id : null;
    lahete.liitteet = this.convertLiitetiedostoObject(liite);
    return lahete;
  }

  public getLomake(id: number): Promise<Lahete> {
    return this.http.get(`/api/v1/as/lomakkeet/lahetteet/${id}`)
      .toPromise()
      .then(r => r as Lahete);
  }

  public saveLomake(formValues: Object, id: number, mallipohjanimi: string, liitteet: { [s: string]: LiitetiedostoResponse[] }): Promise<Lahete> {
    if (mallipohjanimi) {
      id = 0;
    }
    const laheteRequest = this.createLaheteRequest(formValues, id, liitteet);
    return this.http.post(this.AS_BASEURL, laheteRequest)
      .toPromise()
      .then(r => r as Lahete);
  }

  public getMaksaja(): Promise<Maksaja> {
    if (!this.tiiraService.isLoggedIn()) {
      return Promise.resolve(null);
    }

    return this.http.get(`${this.AS_BASEURL}/maksajat`)
      .toPromise()
      .then(response => response as Maksaja[])
      .then(maksajat => maksajat.length > 0 ? maksajat[0] : null);
  }

  public createMallipohja(baseLomakeId: number, mallipohjanimi: string): Promise<Lahete> {
    return this.http.post(`/api/v1/as/lahetteet/${baseLomakeId}/mallipohja`, mallipohjanimi)
      .toPromise()
      .then(r => r as Lahete);
  }

  public getNautarekisterinelaimet(): Promise<ElaintietoResponse[]> {
    return this.http.get(`/api/v1/as/nautarekisteri/elaimenpitajanelaimet`)
      .toPromise()
      .then(r => r as ElaintietoResponse[]);
  }

  checkCanSubmitLomake(form: FormGroup): boolean {
    this._isLahetetty.next(true);
    this.checkValidity(form);

    if (form.valid) {
      return true;
    } else {
      this.messageService.notify({message: new Teksti("Lomakkeen lähetys epäonnistui. Tarkista virheet.", "lahetysEpaonnistui", "lomakeYleinen"), type: "warning"} );
      return false;
    }
  }

  checkValidity(form: AbstractControl) {
    form.updateValueAndValidity({onlySelf: true, emitEvent: false});

    if (form instanceof FormGroup) {
      Object.values(form.controls).forEach(c => this.checkValidity(c));
    } else if (form instanceof FormArray) {
      form.controls.forEach(c => this.checkValidity(c));
    }
  }

  cachePostinumerot = () => this.koodistoService.getPostinumerot();

  getPostitoimipaikkaFn: () => (pnro: string) => Promise<string> = () => this.koodistoService.getPostitoimipaikka.bind(this.koodistoService);

  sendYhteenveto(file: File, id: number): Promise<LiitetiedostoResponse> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('content-type', 'multipart/*');
    formData.append('lomakeId', id.toString());
    formData.append('selite', 'YHTEENVETO');
    formData.append('liite', file);

    const url = `${this.AS_BASEURL}/${id}/yhteenveto`;
    return this.http.post(url, formData, {headers})
      .toPromise()
      .then(r => r as LiitetiedostoResponse);
  }

  private convertLiitetiedostoObject(liitetiedostot: { [s: string]: LiitetiedostoResponse[] }): LiitetiedostoResponse[] {
    liitetiedostot = !Boolean(liitetiedostot) ? {} : liitetiedostot;
    let liiteList = [];
    for (const key in liitetiedostot) {
      if (liitetiedostot.hasOwnProperty(key)) {
        liiteList = liiteList.concat(liitetiedostot[key]);
      }
    }
    return liiteList;
  }
}

export class Lahete {
  id: number;
  body: string;
  liitteet: LiitetiedostoResponse[];
  mallipohjanimi: string;
}

export class Maksaja {
  toimijatunnus: number;
  etunimet: string;
  sukunimi: string;
}

export class ElaintietoResponse {
  euTunnus: string;
  // vanhaEuTunnus: string;
  pitopaikkatunnus: string;
  // elainlaji: number;
  syntymaPvm: string;
  // sukupuoli: string;
  // status: string;
  // kuolinPvm: string;
  // kuolinsyy: string;
  nimi: string;
  // kaytto: string;
  // rotu: string;
  korva: string;
}
