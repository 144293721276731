import {YhteenvetoTyyppi} from "../../touko-lomake/yhteenveto/yhteenveto-utils";

export class LaheteYhteenvetoItem {
  title: string;
  label: string;
  value: string;
  yhteenvetoTyyppi: YhteenvetoTyyppi;
  htmlIdSuffix: string;
  children: LaheteYhteenvetoItem[] = [];
  itemId?: number = 0;

  public YhteenvetoTyyppi = YhteenvetoTyyppi;

  constructor(label: string = null,
              value: string = null,
              type: YhteenvetoTyyppi = YhteenvetoTyyppi.NONE,
              title: string = null,
              htmlIdSuffix: string = null) {
    this.title = title;
    this.label = label;
    this.value = value;
    this.yhteenvetoTyyppi = type;
    this.htmlIdSuffix = htmlIdSuffix;
  }
}
