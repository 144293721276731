import { Teksti } from "../../utils/teksti";
import { YhteenvetoTyyppi } from "../yhteenveto/yhteenveto-utils";
import {SyoteBase, SyoteInterface, SyoteType} from "./syote-interface";

export class SyoteArray extends SyoteBase {

    static id = 0;
    type = SyoteType.ARRAY;

    constructor(label: Teksti, control = null, name = null, value = null, options: { [s: string]: any } = null,
                summaryType = null,
                children: SyoteInterface[] | {[key: string]: SyoteInterface}[] = []) {
        super();
        this.label = label;
        this.control = control;
        this.value = value;
        this.options = options;
        this.summaryType = summaryType;
        this.children = children;
        if (name !== null) {
            this.name = name;
        } else if (label.key !== null) {
            this.name = label.key;
        } else {
           throw new Error("'name' tulee olla maaritelty");
        }
    }

    setViewKey(key: string) {
        this.label.view = key;
    }

    getLabelKey() {
        return this.label.getFullKey();
    }
}
