<a [routerLink]="'/login'"><em class="fa fa-arrow-left mr-1"></em><span attribute="login.takaisinTunnistautumiseen">Takaisin tunnistatutumisen valintaan</span></a>

<form id="kirjaudu-form-viranomainen" class="form-viranomainen mt-4" [formGroup]="loginForm" novalidate
      (ngSubmit)="submitForm()">
  <div class="form-group">
    <label for="user" attribute="login.kayttajatunnus">Käyttäjätunnus</label>
    <input class="form-control" type="text" id="user" formControlName="KAYTTAJA" required autofocus>
  </div>
  <div class="form-group">
    <label for="password" attribute="login.salasana">Salasana</label>
    <input class="form-control" type="password" id="password" formControlName="SALASANA" required>
  </div>
  <div class="form-group">
    <button id="kirjaudu-button-viranomainen"
            [disabled]="!loginForm.valid"
            attribute="login.kirjauduYritys"
            class="btn btn-success"
            type="submit"></button>
  </div>
</form>
