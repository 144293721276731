<div class="card bg-light kesken">
  <div class="card-body">
    <tila-badge class="float-right" [lomakeTila]="lomake.tila"></tila-badge>
    <p>
      <a [routerLink]="[lomakeURL]"
         [id]="'etusivu-lahetetty-kuvaus-'+lomake.id">
        <span [attribute]="getKuvausTeksti(lomake.asia, lomake.toimintotyyppi).fullKey">{{lomake.kuvaus}}</span>
        <em class="fa fa-angle-double-right ml-1"></em>
      </a>
    </p>
    <div class="row">
      <div class="col-md-6">
        <h3 attribute="etusivu.toimipaikanTiedot">Toimipaikan tiedot</h3>
        <dl class="row">
          <dt class="col-6 small"  [attribute]="lomake.lahettajaMaatilatunnus ? 'etusivu.maatilaTunnus' : 'etusivu.yTunnus'">Y-tnnus</dt>
          <dd class="col-6 small">{{ lomake.lahettajaMaatilatunnus ? lomake.lahettajaMaatilatunnus : lomake.lahettajaAsiointitunnus}}</dd>
          <dt class="col-6 small" attribute="etusivu.yritys">Yritys</dt>
          <dd class="col-6 small">{{lomake.lahettajaYritys}}</dd>
          <dt class="col-6 small" attribute="etusivu.valvontakohde">Valvontakohde</dt>
          <dd class="col-6 small"><span
            [ngClass]="{'text-warning-dark': !lomake.lahettajaValvontakohdeAktiivinen}">{{lomake.lahettajaValvontakohde}}</span>
          </dd>
        </dl>
      </div>

      <div class="col-md-6">
        <h3 attribute="etusivu.lahetystiedot">Lähetystiedot</h3>
        <dl class="row">
          <dt class="col-6 small" attribute="etusivu.luotu">Luotu</dt>
          <dd class="col-6 small">{{lomake.perpvm | date: 'd.M.yyyy'}}</dd>
          <dt class="col-6 small" attribute="etusivu.paivitetty">Päivitetty</dt>
          <dd class="col-6 small">{{lomake.paipvm | date: 'd.M.yyyy'}}</dd>
          <dd class="col-6 small" attribute="etusivu.muokannutToimija">Viimeksi muokannut</dd>
          <dd class="col-6 small">{{lomake.muokannutToimija}}</dd>
        </dl>
      </div>
    </div>

    <div *ngIf="lomake.lisatieto">
      <h3 attribute="etusivu.lisatiedot">Lisätiedot</h3>
      <p class="small">{{lomake.lisatieto}}</p>
    </div>
  </div>
</div>
