
export class GroupBuildOptions {
  controlType?: string;
  onlySummary?: boolean;
  applyValues?: boolean;
  initialValues?: any;
}

export class GroupBuilder {
  controlType?: string;
  onlySummary?: boolean;
  applyValues?: boolean;
  initialValues?: any;

  constructor(options: GroupBuildOptions) {
    this.controlType = options.controlType || "";
    this.onlySummary = options.onlySummary || false;
    this.applyValues = options.applyValues || false;
    this.initialValues = options.initialValues || undefined;
    this.initValue = this.initValue.bind(this);
    this.initValueByPath = this.initValueByPath.bind(this);
  }

  initValue(valueResolveFn = (val) => val, defaultValue = null) {
    return this.applyValues ?
      valueResolveFn(this.initialValues) :
      defaultValue;
  }

  initValueByPath(path: string[] = [], defaultValue = null) {
    if (this.applyValues) {
      let returnVal = this.initialValues;
      for (let i = 0; i < path.length; i++) {
        const newVal = returnVal[path[i]];
        if (newVal) {
          returnVal = newVal;
        } else {
          return defaultValue;
        }
      }
      return returnVal;
    }
    return defaultValue;
  }
}
