import {Component} from "@angular/core";

@Component({
  selector: 'toiminta-alert',
  template: `
    <div class="alert alert-warning mb-2">
      <ng-content></ng-content>
    </div>
  `
})
export class ToimintaAlertComponent {
}
