import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Kasvilajike, KasvilajikeSorter} from './kasvilajike.model';
import {TiiraService} from '../tiira/tiira.service';
import {Kasvilaji, KasvilajiSorter} from './kasvilaji.model';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class KasviService {

  lajiKey = "kasvilajit";
  lajikeKey = "kasvilajikkeet";

  constructor(private http: HttpClient, private tiiraService: TiiraService) {}


  public getLajikkeetLajille(kasvilaji: Kasvilaji): Observable<Kasvilajike[]> {

    if(!kasvilaji || !kasvilaji.elmoid || kasvilaji.elmoid.trim() === "") {
      return this.getLajikkeet();
    }

    const cacheKey = this.lajikeKey + "_" + kasvilaji.elmoid;

    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return of(JSON.parse(cached));
    }

    return this.http.get<Kasvilajike[]>('/api/v1/as/kasvi/kasvilajikkeet/' + kasvilaji.elmoid).pipe(
      tap( (kasvilajikkeet) => {
        sessionStorage.setItem(cacheKey, JSON.stringify(kasvilajikkeet));
      })
    );
  }


  public getLajikkeet(): Observable<Kasvilajike[]> {

    const cached = sessionStorage.getItem(this.lajikeKey);
    if (cached) {
      return of(JSON.parse(cached));
    }

    return this.http.get<Kasvilajike[]>('/api/v1/as/kasvi/kasvilajikkeet').pipe(
      tap( (kasvilajikkeet) => {
        sessionStorage.setItem(this.lajikeKey, JSON.stringify(kasvilajikkeet));
      })
    );
  }


  public getLajit(): Observable<Kasvilaji[]> {

    const cacheAvain = this.lajiKey + this.tiiraService.getCurrentLanguage();

    const cached = sessionStorage.getItem(cacheAvain);
    if (cached) {
      return of(JSON.parse(cached));
    }

    return this.http.get<Kasvilaji[]>('/api/v1/as/kasvi/kasvilajit').pipe(
      tap((kasvilajit) => {
        sessionStorage.setItem(cacheAvain, JSON.stringify(kasvilajit));
      })
    );

  }


  public haeLajikkeetLajille(laji: Kasvilaji, hakusana: string, maara: number = 10): Observable<Kasvilajike[]> {

    if (!hakusana || hakusana === "") {
      this.getLajikkeetLajille(laji).pipe(
        map<Kasvilajike[], Kasvilajike[]>(lajikelista =>
          lajikelista
            .filter(lajike => lajike && lajike.kuvaus && lajike.kuvaus.trim()  !== "")
            .sort(KasvilajikeSorter).splice(0, maara)
        )
      );
    }

    return this.getLajikkeetLajille(laji).pipe(
      map<Kasvilajike[], Kasvilajike[]>(lajikelista =>
        lajikelista
          .filter(lajike => lajike && lajike.kuvaus && lajike.kuvaus.toLocaleLowerCase().indexOf(hakusana.trim().toLocaleLowerCase()) === 0)
          .sort(KasvilajikeSorter)
          .splice(0, maara)
      )
    );
  }


  public haeLajikkeet(hakusana: string, maara: number = 10): Observable<Kasvilajike[]> {
    return this.haeLajikkeetLajille(null, hakusana, maara);
  }


  public haeLajit(hakusana: string, maara: number = 10): Observable<Kasvilaji[]> {

    if (!hakusana || hakusana === "") {
      return this.getLajit()
        .pipe(
          map<Kasvilaji[], Kasvilaji[]>(kasvilajit =>
            kasvilajit
              .filter(laji => laji && laji.nimi && laji.nimi.trim()  !== "")
              .sort(KasvilajiSorter).splice(0, maara)
          ));
    }

    return this.getLajit()
      .pipe(
        map<Kasvilaji[], Kasvilaji[]>(kasvilajit =>
          kasvilajit.filter(laji => laji && laji.nimi && laji.nimi.toLocaleLowerCase().indexOf(hakusana.trim().toLocaleLowerCase()) === 0)
            .sort(KasvilajiSorter)
            .splice(0, maara)
        ));
  }
}
