import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AccountService} from "../account/account.service";
import {MessageService} from "../message/message.service";
import {Teksti} from "../utils/teksti";

/**
 * Tarkistaa käyttäjän toimialan
 */
@Injectable()
export class ToimialaGuardService implements CanActivate {

  constructor(private readonly accountService: AccountService,
              private readonly messageService: MessageService,
              private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const account = this.accountService.getCurrentAccount();

    if (!account.toimiala) {
      this.messageService.addMessage(new Teksti("Valitse asiontiasi kuvaava toimiala", "valitseToimiala", "kayttajatili"));
      if (!this.router.url.endsWith('/asiointi/account')) {
        this.router.navigate(['/asiointi/account']);
      }
      return false;
    }

    return true;
  }

}
