import {Teksti} from "../utils/teksti";
import {Palvelut} from "../utils/palvelu-tila";
import {Account} from "../account/account";
import {EnvironmentService} from "../../environments/environment.service";
import {LomakeEndpointUtil} from "../utils/lomake-endpoint-util";

export interface SidebarLinkGroup {
  title?: Teksti;
  raporttiTitle?: Teksti;
  toimiala: string;
  raporttiLinks: Array<RaporttiMenuItem>;
  lomakeLinks: Array<LomakeMenuItem>;
}

export class SidebarLinks {
  private static readonly viewKey = 'sivupalkki';
  private env: EnvironmentService;

  constructor(env: EnvironmentService) {
    this.env = env;
  }

  public getSidebarLinks(palvelut: Palvelut, user?: Account): SidebarLinkGroup[] {
    let links = this.createAllLinks();

    const palveluMap = {};
    palvelut.palvelut.forEach(p => {
      palveluMap[p.asia + p.toimintotyyppi] = {
        tila: p.tila,
        voimassaId: p.voimassaId,
        toimintotyyppi: p.toimintotyyppi,
        keskeneraisenId: p.keskeneraisenId
      };
    });

    links.forEach(link => {
      if (!palvelut.asiakirjatVisible) {
        link.raporttiLinks = [];
      }
      this.modifyLomakeLinkTiedot(link.lomakeLinks, palveluMap)
    });

    links = links.filter(link => link.toimiala === user.toimiala);

    return links;
  }

  modifyLomakeLinkTiedot(links, palveluMap) {
    if (links) {
      links.forEach(lomakeLink => {
        const key = lomakeLink.asia + lomakeLink.toimintotyyppi;
        if (palveluMap.hasOwnProperty(key) && palveluMap[key].tila === 'PIILOTETTU') {
          lomakeLink.tila = 'PIILOTETTU';
          return;
        }

        const isParent = lomakeLink.sublinks?.length > 0;
        if (palveluMap.hasOwnProperty(key) && lomakeLink.tilallinenLinkki && !isParent) {
          lomakeLink.tila = palveluMap[key].tila;
          lomakeLink.voimassaId = palveluMap[key].voimassaId;
          lomakeLink.keskeneraisenId = palveluMap[key].keskeneraisenId;
        }
        if (lomakeLink.sublinks.length) {
          this.modifyLomakeLinkTiedot(lomakeLink.sublinks, palveluMap);
        }
      });
    }
  }

  public getVirkailijaRaporttiLinks() {
    return {
      SIEMEN: [
        this.createRaporttiVirkailijaLink(new Teksti("Kauppaeräasiakirjat", "kauppaeraAsiakirjat", SidebarLinks.viewKey), 'kauppaeraraportit'),
        this.createRaporttiVirkailijaLink(new Teksti("Viljelystarkastuspäätökset", "viljelysAsiakirjat", SidebarLinks.viewKey), 'viljelystarkastuspaatokset'),
        this.createRaporttiVirkailijaLink(new Teksti("Muut asiakirjat", "siemenMuutRaportit", SidebarLinks.viewKey), 'siemenvalvontaraportit'),
      ],
      REHU: [
        this.createRaporttiVirkailijaLink(new Teksti("Rehuvalvonta-asiakirjat", "rehuvalvontaAsiakirjat", SidebarLinks.viewKey), 'rehuvalvontaraportit'),
      ],
      LANNOITE: [
        this.createRaporttiVirkailijaLink(new Teksti("Lannoitevalvonta-asiakirjat", "lannoitevalvontaAsiakirjat", SidebarLinks.viewKey), 'lannoitevalvontaraportit'),
      ],
      KATE: [
        this.createRaporttiVirkailijaLink(new Teksti("Valvonnan asiakirjat", "kasvinterveysAsiakirjat", SidebarLinks.viewKey), 'kasvinterveysraportit'),
      ],
      LUOMU: [
        this.createRaporttiVirkailijaLink(new Teksti("Luomu asiakirjat", "luomuAsiakirjat", SidebarLinks.viewKey), 'luomuraportit'),
      ]
    };
  }

  private createAllLinks() {
    const tulosraportitTeksti = new Teksti("Tulosasiakirjat", "tulosasiakirjat", SidebarLinks.viewKey);

    const luomuPoikkeuslupa9cTeksti = new Teksti("Poikkeuslupa, lisäysaineisto 9c", "luomuPoikkeus9c", SidebarLinks.viewKey);
    const toiminnanAloitus = new Teksti("Toiminnan aloitusilmoitus", "toiminnanAloitus", SidebarLinks.viewKey);
    const lannoiteMuutosilmoitus = new Teksti("Muutosilmoitus", "muutos", SidebarLinks.viewKey);
    const kylvosiemenMarkkinointiAsia = 'kylvosiemen-markkinointi';

    const rehuvalvontaAsia = 'rehuvalvonta';
    const rehuVuosiilmoitus = 'rehuvuosiilmoitus';
    const lannoiterekisteriAsia = 'lannoiterekisteri';
    const lannoiteVuosiilmoitus = 'lannoitevuosiilmoitus';

    const kasvinterveysrekisteriAsia = 'kasvinterveysrekisteri';

    const linkit: Array<SidebarLinkGroup> = [
      {
        title: new Teksti("Siemenalan toiminta", "otsikkoSiemen", SidebarLinks.viewKey),
        raporttiTitle: tulosraportitTeksti,
        toimiala: "SIEMEN",
        raporttiLinks: [
          this.createRaporttiAsiakasLink(new Teksti("Kauppaeräraportit", "kauppaeraAsiakirjat", SidebarLinks.viewKey), 'kauppaeraraportit'),
          this.createRaporttiAsiakasLink(new Teksti("Viljelystarkastuspäätökset", "viljelysRaportit", SidebarLinks.viewKey), 'viljelystarkastuspaatokset'),
          this.createRaporttiAsiakasLink(new Teksti("Muut asiakirjat", "siemenMuutRaportit", SidebarLinks.viewKey), 'siemenvalvontaraportit'),
        ],
        lomakeLinks: [
          this.createUusiMenuItemStub(kylvosiemenMarkkinointiAsia, "v2"),
          this.createMuutosMenuItemStub(kylvosiemenMarkkinointiAsia, "v2"),
          this.createLopetusMenuItemStub(kylvosiemenMarkkinointiAsia, "v2"),
        ],
      },
      {
        title: new Teksti("Siemenalan lupahakemukset", "otsikkoSiemenLupaHakemus", SidebarLinks.viewKey),
        toimiala: "SIEMEN",
        raporttiLinks: [],
        lomakeLinks: [
          this.createLomakeMenuItemStub(
              new Teksti(
                  "Siemenpakkaamolupahakemus", "siemenpakkaamolupa", SidebarLinks.viewKey),
              "HAKEMUS",
              '/asiointi/lomake/hakemus/siemenpakkaamolupa',
              'siemenpakkaamolupa'
          ),
        ],
      },
      {
        title: new Teksti("Rehualan toiminta", "otsikkoRehu", SidebarLinks.viewKey),
        raporttiTitle: tulosraportitTeksti,
        toimiala: "REHU",
        raporttiLinks: [
          this.createRaporttiAsiakasLink(new Teksti("Rehuvalvontaasiakirjat", "rehuvalvontaAsiakirjat", SidebarLinks.viewKey), 'rehuvalvontaraportit'),
        ],
        lomakeLinks: [
          this.createUusiMenuItemStub(rehuvalvontaAsia, LomakeEndpointUtil.shouldUseV2ClientComponent(rehuvalvontaAsia, "uusi", 'v1') ? 'v2' : 'v1'),
          this.createMuutosMenuItemStub(rehuvalvontaAsia, LomakeEndpointUtil.shouldUseV2ClientComponent(rehuvalvontaAsia, "muutos", 'v1') ? 'v2' : 'v1'),
          this.createLopetusMenuItemStub(rehuvalvontaAsia, LomakeEndpointUtil.shouldUseV2ClientComponent(rehuvalvontaAsia, "lopetus", 'v1') ? 'v2' : 'v1'),
          this.createLomakeMenuItemParent(
              new Teksti("Rehu vuosi-ilmoitus", "vuosiilmoitus", SidebarLinks.viewKey),
              'VUOSIILMOITUS',
              rehuVuosiilmoitus,
              [
                this.createLomakeMenuItemStub(
                    new Teksti("Rehu vuosi-ilmoitus", "annaVuosiilmoitus", SidebarLinks.viewKey),
                    "VUOSIILMOITUS",
                    `asiointi/lomake/vuosiilmoitus/${rehuVuosiilmoitus}`,
                    rehuVuosiilmoitus,
                    "v1",
                    [],
                    "AKTIIVINEN"
                ),
                this.createTemplateLink(
                    new Teksti("Rehu lomakepohjat", "lomakepohjat", SidebarLinks.viewKey),
                    rehuVuosiilmoitus,
                    `asiointi/template/rehuvuosiilmoitus`,
                )
              ],
          ),
        ],
      },
      {
        title: new Teksti("Lannoitetoiminta", "otsikkoLannoite", SidebarLinks.viewKey),
        raporttiTitle: tulosraportitTeksti,
        toimiala: "LANNOITE",
        raporttiLinks: [
          this.createRaporttiAsiakasLink(new Teksti("Lannoitevalvontaraportit", "lannoitevalvontaAsiakirjat", SidebarLinks.viewKey), 'lannoitevalvontaraportit'),
        ],
        lomakeLinks: [
          this.createLopetusMenuItemStub(lannoiterekisteriAsia, LomakeEndpointUtil.shouldUseV2ClientComponent(rehuvalvontaAsia, "lopetus", 'v1') ? 'v2' : 'v1'),
          this.createLomakeMenuItemStub(toiminnanAloitus, 'UUSI', '/asiointi/lomake/uusi/lannoiterekisteri/', 'lannoiterekisteri', 'v2'),
          this.createLomakeMenuItemStub(lannoiteMuutosilmoitus, 'MUUTOS', '/asiointi/lomake/muutos/lannoiterekisteri/', 'lannoiterekisteri', 'v2'),
          this.createLomakeMenuItemParent(
              new Teksti("Lannoite vuosi-ilmoitus", "vuosiilmoitus", SidebarLinks.viewKey),
              "VUOSIILMOITUS",
              lannoiteVuosiilmoitus,
              [
                this.createLomakeMenuItemStub(
                    new Teksti(
                        "Lannoite vuosi-ilmoitus", "annaVuosiilmoitus", SidebarLinks.viewKey),
                    "VUOSIILMOITUS",
                    `asiointi/lomake/vuosiilmoitus/${lannoiteVuosiilmoitus}`,
                    lannoiteVuosiilmoitus),
                this.createTemplateLink(
                    new Teksti("Lannoite lomakepohjat", "lomakepohjat", SidebarLinks.viewKey),
                    lannoiteVuosiilmoitus,
                    `asiointi/template/lannoitevuosiilmoitus`
                )
              ])
        ]
      },
      {
        title: new Teksti("Kasvinterveys", "otsikkoKate", SidebarLinks.viewKey),
        raporttiTitle: tulosraportitTeksti,
        toimiala: "KATE",
        raporttiLinks: [
          this.createRaporttiAsiakasLink(new Teksti("Valvonnan raportit", "kasvinterveysAsiakirjat", SidebarLinks.viewKey), 'kasvinterveysraportit'),
        ],
        lomakeLinks: [
          this.createUusiMenuItemStub(kasvinterveysrekisteriAsia),
          this.createMuutosMenuItemStub(kasvinterveysrekisteriAsia),
        ],
      },
      {
        title: new Teksti("Eläintautitutkimukset", "otsikkoElabra", SidebarLinks.viewKey),
        toimiala: "ELAIN_LABORATORIO",
        raporttiTitle: tulosraportitTeksti,
        raporttiLinks: [this.createRaporttiAsiakasLink(new Teksti("Tutkimustodistukset", "tutkimustodistukset", SidebarLinks.viewKey), 'tutkimustodistukset')],
        lomakeLinks: [
          this.createLomakeMenuItemStub(
              new Teksti(
                  "Uusi lähete", "uusiLahete", SidebarLinks.viewKey),
              "LAHETE",
              '/asiointi/lomake/lahete/elaintautitutkimus',
              'elainlaboratoriolahete'
          ),
        ],
      }];

    linkit.push(
        {
          title: new Teksti("Luomuvalvonta", "otsikkoLuomuIlmoitukset", SidebarLinks.viewKey),
          raporttiTitle: tulosraportitTeksti,
          toimiala: "LUOMU",
          raporttiLinks: [
              this.createRaporttiAsiakasLink(new Teksti("Luomun raportit", "luomuAsiakirjat", SidebarLinks.viewKey), 'luomuraportit'),
          ],
          lomakeLinks: [
              this.createLomakeMenuItemStub(toiminnanAloitus, 'UUSI', '/asiointi/lomake/uusi/luomuvalvonta/', 'luomuvalvonta', 'v2'),
          ]
        },
        {
          title: new Teksti("Luomu", "otsikkoLuomu", SidebarLinks.viewKey),
          raporttiTitle: tulosraportitTeksti,
          toimiala: "LUOMU",
          raporttiLinks: [],
          lomakeLinks: [
            this.createLomakeMenuItemStub(luomuPoikkeuslupa9cTeksti, 'HAKEMUS', '/asiointi/lomake/hakemus/luomupoikkeus9c', 'luomupoikkeus9c'),
          ],
        }
    );

    return linkit;
  }

  private createUusiMenuItemStub(asia: string, version = 'v1') {
    const uusiIlmoitusTeksti = new Teksti("Uusi ilmoitus", "toiminnanAloitus", SidebarLinks.viewKey);
    const link = `/asiointi/lomake/uusi/${asia}`;
    return this.createLomakeMenuItemStub(uusiIlmoitusTeksti, 'UUSI', link, asia, version);
  }

  private createMuutosMenuItemStub(asia: string, version = 'v1') {
    const muutosIlmoitusTeksti = new Teksti("Muutosilmoitus", "muutos", SidebarLinks.viewKey);
    const link = `/asiointi/lomake/muutos/${asia}`;
    return this.createLomakeMenuItemStub(muutosIlmoitusTeksti, 'MUUTOS', link, asia, version);
  }

  private createLopetusMenuItemStub(asia: string, version = 'v1') {
    const lopetusIlmoitusTeksti = new Teksti("Toiminnan lopetus", "lopetus", SidebarLinks.viewKey);
    const link = `/asiointi/lomake/lopetus/${asia}`;
    return this.createLomakeMenuItemStub(lopetusIlmoitusTeksti, 'LOPETUS', link, asia, version);
  }

  private createLomakeMenuItemStub(title: Teksti, toimintotyyppi: Toimintotyyppi, link: string, asia: string, version = "v1", sublinks = [], tila: LomakeMenuItemTila = 'AKTIIVINEN') {
    const lomakeLink = new LomakeMenuItem();
    const versio = LomakeEndpointUtil.shouldUseV2ClientComponent(asia, toimintotyyppi.toLowerCase()) ? 'v2' : 'v1';
    lomakeLink.title = title;
    lomakeLink.toimintotyyppi = toimintotyyppi;
    lomakeLink.link = `${link}/${versio}`;
    lomakeLink.asia = asia;
    lomakeLink.lomaketyyppiVersio = version;
    lomakeLink.sublinks = sublinks;
    lomakeLink.tila = tila;
    lomakeLink.tilallinenLinkki = true;
    return lomakeLink;
  }

  private createLomakeMenuItemParent(title: Teksti, toimintotyyppi: Toimintotyyppi, asia: string, sublinks: LomakeMenuItem[] = [], tila: LomakeMenuItemTila = 'AKTIIVINEN') {
    const lomakeLink = new LomakeMenuItem();
    lomakeLink.toimintotyyppi = toimintotyyppi;
    lomakeLink.asia = asia;
    lomakeLink.title = title;
    lomakeLink.sublinks = sublinks;
    lomakeLink.tila = tila;
    lomakeLink.tilallinenLinkki = true;
    return lomakeLink;
  }

  private createTemplateLink(title: Teksti, asia: string, link: string, tila: LomakeMenuItemTila = 'AKTIIVINEN'): LomakeMenuItem {
    const lomakeLink = new LomakeMenuItem();
    lomakeLink.title = title;
    lomakeLink.link = link;
    lomakeLink.tila = tila;
    lomakeLink.asia = asia;
    lomakeLink.tilallinenLinkki = false;
    return lomakeLink;
  }

  private createRaporttiAsiakasLink(title: Teksti, raporttiTarkenne: string): RaporttiMenuItem {
    const raporttiLink = new RaporttiMenuItem();
    raporttiLink.title = title;
    raporttiLink.link = `/asiointi/raportit/${raporttiTarkenne}`;
    return raporttiLink;
  }

  private createRaporttiVirkailijaLink(title: Teksti, raporttiTarkenne: string): RaporttiMenuItem {
    const raporttiLink = new RaporttiMenuItem();
    raporttiLink.title = title;
    raporttiLink.link = `/asiointi/raportit/virkailija/${raporttiTarkenne}`;
    return raporttiLink;
  }

  private createRehuLopetusMenuItem(): LomakeMenuItem {
    const ret = new LomakeMenuItem();
    ret.title = new Teksti('Toiminnan lopetus', 'lopetus', SidebarLinks.viewKey);
    ret.toimintotyyppi = 'LOPETUS';
    ret.link = '/asiointi/lomake/lopetus/rehuvalvonta/v1';
    ret.asia = 'rehuvalvonta';
    ret.lomaketyyppiVersio = 'v1';
    ret.sublinks = [];
    ret.tila = 'AKTIIVINEN';
    ret.tilallinenLinkki = true;
    return ret;
  }
}

export class LomakeMenuItem {
  title: Teksti;
  link: string;
  isCollapsed = true;
  tila?: LomakeMenuItemTila;
  asia?: string;
  sublinks: LomakeMenuItem[] = [];
  lomaketyyppiVersio: string;
  toimintotyyppi: string;
  keskeneraisenId?: number;
  voimassaId?: number;
  tilallinenLinkki: boolean;
}

type LomakeMenuItemTila = "PIILOTETTU" | "AKTIIVINEN" | "EI_AKTIIVINEN";
type Toimintotyyppi = 'UUSI' | 'MUUTOS' | 'LOPETUS' | 'HAKEMUS' | 'LAHETE' | 'VUOSIILMOITUS';

export class RaporttiMenuItem {
  title: Teksti;
  link: string;
}
