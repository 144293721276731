import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from "@angular/core";
/**
 * Created by Seppo on 28/02/2018.
 */


/**
 * Käytetään painikkeissa, jotka aiheuttavat kutsuja palvelimelle.
 * Jos painikkeen klikkaaminen aiheutta sivun vaihdon, käytä
 * ScreenLoader-komponenttia.
 */
@Directive({
  selector: '[clickAsync]'
})
export class ClickAsyncDirective implements OnInit {

  @Input('clickAsync') loaderEvent: (...args: any[]) => Promise<any>;
  @Input() params: any[];

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  @HostListener('click') onClick() {
    if (!this.loaderEvent) {
      return;
    }

    this.addLoader();

    if (this.params) {
      this.loaderEvent(...this.params)
        .then(() => this.removeLoader())
        .catch(() => this.removeLoader());
    } else {
      this.loaderEvent()
        .then(() => this.removeLoader())
        .catch(() => this.removeLoader());
    }
  }

  ngOnInit(): void {
    const spinner = document.createElement('div');
    spinner.classList.add('spinner');
    this.renderer.appendChild(this.elementRef.nativeElement, spinner);
  }

  addLoader() {
    this.renderer.addClass(this.elementRef.nativeElement, 'loading');
  }

  removeLoader() {
    this.renderer.removeClass(this.elementRef.nativeElement, 'loading');
  }
}
