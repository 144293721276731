import {Component} from '@angular/core';
import {AccessibilityService} from "./accessibility.service";

@Component({
  selector: 'accessibility-btn',
  template: `
    <button id="run-axe-btn" class="btn btn-sm btn-secondary" type="button" (click)="checkAccessibility()">
      Tarkista saavutettavuus
    </button>
  `
})
export class AccessibilityBtnComponent {

  constructor(private accessibilityService: AccessibilityService) {
  }

  checkAccessibility() {
    this.accessibilityService.runAccessibilityCheck();
  }

}
