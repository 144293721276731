import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { LOMAKE_KUVAUKSET, LomakeResponse, LomakeKuvaus } from "../../touko-lomake/touko-lomake-utils";
import { Router } from "@angular/router";
import { Sarake, SarakeType, SortOrder } from "../../raportti/raportti.utils";
import { Teksti } from "../../utils/teksti";
import { SarakeClickEvent } from "./lomake-row.component";
import { LomakeTableViewOptions } from "./lomake-table.component";

/**
 * Created by Seppo on 29/01/2018.
 */

@Component({
  selector: 'lomake-list',
  templateUrl: './lomake-list-view.component.html',
  styleUrls: ['../../raportti/data-table.component.scss'] // Käytetään samoja tyylejä kuin raporteissa
})
export class LomakeListViewComponent implements OnChanges {
  @Input() lomakkeet: LomakeResponse[] = [];
  @Input() viewOptions = new LomakeTableViewOptions();
  @Input() initialSivunumero = 1;
  @Output() changeSivu = new EventEmitter<number>();
  taulukonLomakkeet: LomakeResponse[] = [];
  sivu = 1;

  lomakkeitaSivulla = 20;
  kuvaukset: { [s: string]: LomakeKuvaus } = LOMAKE_KUVAUKSET;
  columns: Sarake[] = [];
  sortOrder = -1;
  previousSortKey = "lahetysAika";

  constructor(private readonly router: Router) {
    this.columns.push(
      new Sarake('id', new Teksti('-', 'tunnus', 'etusivu'), SarakeType.ID),
      new Sarake('toimintotyyppi', new Teksti('-', 'hakemusIlmoitus', 'etusivu'), SarakeType.LOMAKE_LINK),
      new Sarake('lahettajaAsiointitunnus', new Teksti('-', 'yTunnus', 'etusivu')),
      new Sarake('lahettajaYritys', new Teksti('-', 'toimija', 'etusivu')),
      new Sarake(
        'lahettajaValvontakohde',
        new Teksti('-', 'valvontakohde', 'etusivu'),
        SarakeType.DEFAULT,
        (lomake: LomakeResponse) => !lomake.lahettajaValvontakohdeAktiivinen ? "text-warning-dark" : ""
      ),
      new Sarake('lahettajaNimi', new Teksti('-', 'lahettaja', 'etusivu')),
      new Sarake('lahetysAika', new Teksti('-', 'lahetetty', 'etusivu'), SarakeType.DATE),
      new Sarake('kasittelija', new Teksti('-', 'kasittelija', 'etusivu')),
      new Sarake('viimeksiKasiteltyAika', new Teksti('-', 'viimeksiKasitelty', 'etusivu'), SarakeType.DATE),
      new Sarake('tila', new Teksti('-', 'tila', 'etusivu'), SarakeType.TYPE),
      new Sarake('lisatieto', new Teksti('-', 'lisatiedot', 'etusivu')),
    );
  }

  ngOnChanges(change: SimpleChanges): void {
    this.sortByKey(this.previousSortKey, SortOrder.ASC);
    if (change.hasOwnProperty('lomakkeet')) {
      this.sivu = 1;
    }

    this.selectSivu(this.initialSivunumero);
    this.updateTaulukonLomakkeet();
  }

  private updateTaulukonLomakkeet() {
    const start = (this.sivu - 1) * this.lomakkeitaSivulla;
    const end = start + this.lomakkeitaSivulla;
    this.taulukonLomakkeet = this.lomakkeet.slice(start, end);
  }

  selectSivu(val: number) {
    this.sivu = val;
    this.updateTaulukonLomakkeet();
    this.changeSivu.emit(this.sivu);
  }

  selectLomakeLkm(val: string) {
    this.sivu = 1;
    this.lomakkeitaSivulla = parseInt(val, 10);
    this.updateTaulukonLomakkeet();
  }

  sortByKey(key: string, sortOrder?) {

    if (sortOrder) {
      this.sortOrder = sortOrder;
    } else if (this.previousSortKey === key) {
      this.sortOrder = this.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    } else {
      const isDateType = this.columns.find(col => col.key === key).type === SarakeType.DATE;
      this.sortOrder = isDateType ? SortOrder.ASC : SortOrder.DESC;
    }

    if (key !== 'hakemusIlmoitus') {
      this.sortDefault(key);
    } else {
      this.sortKuvaus();
    }

    this.previousSortKey = key;
    this.updateTaulukonLomakkeet();
  }

  /**
   * Apufunktio lomakkeiden järjestämiseen.
   *
   * @param key - Kenttä, jonka mukaan lomakkeet järjestetään
   */
  sortDefault(key: string) {
    this.lomakkeet.sort((a, b) => {
      const aKey = a[key] !== null ? a[key] : "";
      const bKey = b[key] !== null ? b[key] : "";
      return this.sortHelper(aKey, bKey);
    });
  }

  /**
   * Apufunktio lomakkeiden järjestämiseksi kuvauksen mukaan. Kuvaus on haettu Tulkista.
   */
  sortKuvaus() {
    this.lomakkeet.sort((a, b) => {
      const aKey = this.getKuvausTeksti(a.toimintotyyppi, a.asia);
      const bKey = this.getKuvausTeksti(b.toimintotyyppi, b.asia);
      return this.sortHelper(aKey.label, bKey.label);
    });
  }

  /**
   * Apufunktio lomakkeiden järjestämiseen.
   *
   * @param aVal - 1. vertailtava arvo
   * @param bVal - 2. vertailtava arvo
   * @returns arvojen aVal ja bVal järjestys
   */
  private sortHelper(aVal, bVal) {
    if (aVal > bVal) {
      return this.sortOrder;
    } else if (aVal < bVal) {
      return -1 * this.sortOrder;
    }
    return 0;
  }

  /**
   * Hakee järjestyksen tietylle kentälle. Valitulla kentällä arvo on -1 (=ASC) tai 1 (=DESC), muilla 0.
   *
   * @param key - kenttä
   * @returns -1 (=ASC) tai 1 (=DESC) tai 0.
   */
  getJarjestys(key: string) {
    return this.previousSortKey === key ? this.sortOrder : 0;
  }

  /**
   * Hakee ilmoituksen kuvauksen.
   *
   * @param asia - Lomakkeen asia
   * @param toimintotyyppi - Lomakkeen toimintotyyppi
   * @returns - Tekstiobjekti lomakkeen kuvauksesta
   */
  getKuvausTeksti(toimintotyyppi: string, asia: string): Teksti {
    return this.kuvaukset[asia]["LYHYT_KUVAUS"][toimintotyyppi];
  }

  openLink(lomakeClickEvent: SarakeClickEvent) {
    const lomake = lomakeClickEvent.lomake;
    this.router.navigate(["/asiointi/lomake/tarkastus/", lomake.toimintotyyppi.toLowerCase(), lomake.asia, `v${lomake.lomaketyyppiVersio}`, lomake.id]);
  }
}
