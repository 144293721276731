import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LaheteKoodisto} from "./lahete-koodisto";
import {Subject} from "rxjs";
import {NaytetiedotGroup} from "./groups/naytetiedot-group";
import {ElainlajiryhmakooditGroup} from "./groups/elainlajiryhmakoodit-group";
import {TutkimusGroup} from "./groups/tutkimus-group";
import {TaustakysymyksetGroup} from "./groups/taustakysymykset-group";
import {ValitutTutkimuksetGroup} from "./groups/valitut-tutkimukset-group";
import {TutkimusalatGroup} from "./groups/tutkimusalat-group";
import {TutkimushakuGroup} from "./groups/tutkimushaku-group";
import {ElainlajiGroup} from "./groups/elainlaji-group";
import {ToimitusosoiteGroup} from "./groups/toimitusosoite-group";
import {AutomaattinenTiedoksisaajaGroup} from "./groups/automaattinen-tiedoksisaaja-group";
import {LaheteTunnusGroup} from "./groups/lahete-tunnus-group";
import {TilaajaGroup} from "./groups/perustiedot/tilaaja-group";
import {OmistajaGroup} from "./groups/perustiedot/omistaja-group";
import {ElainlaakariGroup} from "./groups/perustiedot/elainlaakari-group";
import {TiedoksisaajaGroup} from "./groups/perustiedot/tiedoksisaaja-group";
import {TilatyypitGroup} from "./groups/tilatyypit-group";
import {TilatiedotGroup} from "./groups/tilatiedot-group";

import {GroupBuilder, GroupBuildOptions} from "./groups/group-build-options";
import {SahkoinenAsiointiGroup} from "./groups/perustiedot/sahkoinen-asiointi-group";
import {QuestionGroup, QuestionInitMatcher, QuestionInitValue, QuestionUpdate} from 'ee-lahete';

@Injectable()
export abstract class LaheteServiceConfig {
  abstract getKoodistoURL: string;
  debug = false;
  jarjestelma = "SE"; // Sähköeläin
  /**
   * Ei vielä käytössä.
   */
  // componentMap: {[component: string]: Type<any>} = {
  //   radio: QuestionRadioComponent,
  //   checkbox: QuestionCheckboxComponent
  // };
}

// setting below needed for successful --prod compilation
// @dynamic
@Injectable({
  providedIn: 'root'
})
export class LaheteService {

  lang: LaheteLanguage = LaheteLanguage.FI;
  laheteKoodisto: LaheteKoodisto;
  storedValues: { [s: string]: any } = {};

  private readonly _questionUpdate = new Subject<QuestionUpdate>();
  $questionUpdate = this._questionUpdate.asObservable();

  private readonly _questionInitValue = new Subject<QuestionInitValue>();
  $questionInitValue = this._questionInitValue.asObservable();

  private readonly _questionInitFilter = new Subject<QuestionInitMatcher>();
  $questionInitFilter = this._questionInitFilter.asObservable();

  public static createTunnus(): string {
    let tunnus = '';
    const characters = 'ABCDEFGHJKLMNPRSTUVWXYZ23456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      tunnus += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return tunnus;
  }

  constructor(private readonly http: HttpClient,
              private readonly config: LaheteServiceConfig) {
  }

  public updateQuestion(questionUpdate: QuestionUpdate) {
    this._questionUpdate.next(questionUpdate);
  }

  public registerInitialValue(valueInit: QuestionInitValue) {
    this._questionInitValue.next(valueInit);
  }

  public registerInitialFilter(matcherInit: QuestionInitMatcher) {
    matcherInit.modelFragment.lateRegisterMatchers = true;
    this._questionInitFilter.next(matcherInit);
  }

  /**
   * Asettaa kielen (FI/SV) jolla kirjasto palauttaa merkkijonoja. Oletuksena FI.
   * @param lang - Kieli (Fi/Sv)
   */
  public setLanguage(lang: string) {
    this.lang = LaheteLanguage[lang] || LaheteLanguage.FI;
  }

  /**
   * Hakee lähetteen tiedot
   */
  public getLaheteKoodisto() {
    if (this.laheteKoodisto) {
      return Promise.resolve(this.laheteKoodisto);
    }

    return this.http.get(this.config.getKoodistoURL)
      .toPromise()
      .then(r => r as LaheteKoodisto)
      .then(r => {
        const filteredResponse = r;
        filteredResponse.koodisto.elainlajiryhmakoodit = r.koodisto.elainlajiryhmakoodit.filter(e => e.jarjestelma.includes(this.config.jarjestelma));
        this.laheteKoodisto = filteredResponse;
        this.laheteKoodisto.dataObj = this.laheteKoodisto.data.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});

        return this.laheteKoodisto;
      });
  }

  getLahetetunnusGroup(options: GroupBuildOptions = {controlType: 'id'}): QuestionGroup {
    const laheteTunnusGroup = new LaheteTunnusGroup(this);
    return laheteTunnusGroup.getGroup("", new GroupBuilder(options));
  }

  getSahkoinenAsiointiGroup(options: GroupBuildOptions = {controlType: ''}): QuestionGroup {
    return new SahkoinenAsiointiGroup(this).getGroup('', new GroupBuilder(options));
  }

  getTilaajaGroup(options: GroupBuildOptions = {controlType: 'tilaaja'}): QuestionGroup {
    return new TilaajaGroup(this).getGroup('', new GroupBuilder(options));
  }

  getOmistajaGroup(options: GroupBuildOptions = {controlType: 'henkilo'}): QuestionGroup {
    return new OmistajaGroup(this).getGroup('', new GroupBuilder(options));
  }

  getTiedoksisaajaGroup(options: GroupBuildOptions = {controlType: ''}, summaryValues?): QuestionGroup {
    const tiedoksisaajaGroup = new TiedoksisaajaGroup(this);
    return summaryValues ?
      tiedoksisaajaGroup.getSummaryGroup(options.controlType, summaryValues) :
      tiedoksisaajaGroup.getGroup(options.controlType, new GroupBuilder(options));
  }

  getElainlaakariGroup(options: GroupBuildOptions = {controlType: 'henkilo'}): QuestionGroup {
    return new ElainlaakariGroup(this).getGroup('', new GroupBuilder(options));
  }

  getElainlajiryhmakooditGroup(options: GroupBuildOptions = {controlType: 'radio'}): QuestionGroup {
    const elainlajiryhmakooditGroup = new ElainlajiryhmakooditGroup(this);
    return elainlajiryhmakooditGroup.getGroup('', new GroupBuilder(options));
  }

  getElainlajiGroup(options: GroupBuildOptions = {controlType: 'radio'}): QuestionGroup {
    const elainlajiGroup = new ElainlajiGroup(this);
    return elainlajiGroup.getGroup('', new GroupBuilder(options));
  }

  getTutkimushakuGroup(controlType = 'text'): QuestionGroup {
    const tutkimushakuGroup = new TutkimushakuGroup(this);
    return tutkimushakuGroup.getGroup(controlType);
  }

  getTutkimusalaGroup(controlType = 'checkboxbutton'): QuestionGroup {
    const tutkimusalatGroup = new TutkimusalatGroup(this);
    return tutkimusalatGroup.getGroup(controlType);
  }

  getTutkimusGroup(options: GroupBuildOptions = {controlType: 'checkbox'}): QuestionGroup {
    const tutkimusGroup = new TutkimusGroup(this);
    return tutkimusGroup.getGroup('', new GroupBuilder(options));
  }

  getValitutTutkimuksetGroup(options: GroupBuildOptions = {controlType: 'tutkimus-card'}): QuestionGroup {
    const valitutTutkimuksetGroup = new ValitutTutkimuksetGroup(this);
    return valitutTutkimuksetGroup.getGroup('', new GroupBuilder(options));
  }

  getNaytetiedotGroup(options: GroupBuildOptions = {controlType: 'naytetieto'}, summaryValues?): QuestionGroup {
    const naytetiedotGroup = new NaytetiedotGroup(this);

    return summaryValues ?
      naytetiedotGroup.getSummaryGroup(options.controlType, summaryValues) :
      naytetiedotGroup.getGroup('', new GroupBuilder(options));
  }

  getTilatyypitGroup(options: GroupBuildOptions = {controlType: ''}): QuestionGroup {
    return new TilatyypitGroup(this).getGroup('', new GroupBuilder(options));
  }

  getTilatiedotGroup(options: GroupBuildOptions = {controlType: ''}): QuestionGroup {
    return new TilatiedotGroup(this).getGroup('', new GroupBuilder(options));
  }

  getTaustakysymyksetGroup(options: GroupBuildOptions = {controlType: ''}): QuestionGroup {
    const taustakysymyksetGroup = new TaustakysymyksetGroup(this);
    return taustakysymyksetGroup.getGroup('', new GroupBuilder(options));
  }

  getAutomaattinenTiedoksisaajaGroup(options: GroupBuildOptions = {controlType: 'checkbox'}): QuestionGroup {
    const tiedoksisaajaGroup = new AutomaattinenTiedoksisaajaGroup(this);
    return tiedoksisaajaGroup.getGroup('', new GroupBuilder(options));
  }

  getToimitusosoiteGroup(options: GroupBuildOptions = {controlType: 'radio-card'}): QuestionGroup {
    const toimitusosoiteGroup = new ToimitusosoiteGroup(this);
    return toimitusosoiteGroup.getGroup('', new GroupBuilder(options));
  }

  storeValue(key, value) {
    this.storedValues[key] = value;
  }

  getStoredValue(key) {
    return this.storedValues[key];
  }
}

export enum LaheteLanguage {
  FI = "Fi",
  SV = "Sv"
}
