import {Component, OnInit} from "@angular/core";
import {ProcessLomakeService} from "../common/lomake-base/process-lomake.service";
import {FieldControl, FieldGroup} from "ngx-fielding";
import {LomakeInfo, LomakeMetaData} from "../common/lomake-info";
import {YhteyshenkiloViewOptions} from "../../../touko-inputs/yhteyshenkilot/yhteyshenkilo-view-options";
import {RekisteriLopetusV2Data} from "./rekisteri-lopetus-v2-data";
import {AccountService} from "../../../account/account.service";
import {LomakeDataResolver} from "../common/lomake-base/lomake-v2-data";
import {Account} from "../../../account/account";
import {Teksti} from "../../../utils/teksti";
import {YhteyshenkiloValue} from "../../touko-lomake-utils";

@Component({
  selector: 'rekisteri-lopetus-v2',
  template: `
    <form [formGroup]="lomake" (submit)="submitLomake()">
      <h1 attribute="lomakeYleinen.toiminnanLopetus"></h1>
      <div formGroupName="lopetustiedot" class="col-md-6 col-12 px-0">
        <date label="lomakeYleinen.arvioituLopetus" formControlName="lopetusPvm"
              htmlIdSuffix="datepicker"></date>
      </div>

      <fieldset formGroupName="lopetustoiminta" *ngIf="isSiemenrekisteriLopetus" class="mt-4">
        <legend attribute="lomakeYleinen.lopetusTiedot"></legend>
        <touko-radio-input *ngFor="let radio of lomake.get('lopetustoiminta.radio').field.valueOptions"
                           [htmlId]="radio.options.htmlId"
                           [labelAttribute]="radio.label"
                           radioGroup="toimintaJaLisatiedotRadioGroup"
                           [value]="radio.value"
                           formControlName="radio"></touko-radio-input>
      </fieldset>

      <div formGroupName="lisatiedot" class="col-md-6 col-12 px-0">
        <touko-textarea
          [htmlId]="lisatieto.field.htmlId"
          [labelAttribute]="lisatieto.field.label"
          formControlName="lisatieto"></touko-textarea>
      </div>
      <fieldset formGroupName="yhteyshenkilot">
        <legend attribute="lomakeYleinen.ilmoYhteyshenkiloInfo"></legend>
        <ng-container formArrayName="yhteyshenkilot">
          <yhteyshenkilo-input formGroupName="0" [viewOptions]="yhteyshenkiloViewOptions" [index]="0"></yhteyshenkilo-input>
        </ng-container>
      </fieldset>
      <div class="form-row">
        <div class="col">
          <div *ngIf="lomakeMetaData.isAsiakas" class="form-group">
            <button id="lopeta-toiminta" class="btn btn-primary" type="submit"
                    attribute="lomakeYleinen.lopetaToiminta">Lopeta toiminta
            </button>
            <button id="btn-tallenna" type="button" class="btn btn-success ml-2" [clickAsync]="saveLomake">
              <em class="fa fa-save"></em>
              <span attribute="teksti.tallennaKeskeneraisena" id="btn-tallenna-teksti">Tallenna keskeneräisenä</span>
            </button>
          </div>
          <siirry-tarkastukseen [saveFn]="saveLomake"></siirry-tarkastukseen>

        </div>
      </div>
    </form>

    <hr>

    <div class="col-md-6 col-12 px-0">
      <touko-alert-box>
        <div [attribute]="'lomakeYleinen.lopetusInfoRehu'"></div>
      </touko-alert-box>
    </div>
    <app-field-group-summary *ngIf="lomakeMetaData.isAsiakas && toimipaikanTiedot.get('perustiedot')"
                             [fieldGroup]="toimipaikanTiedot"></app-field-group-summary>

  `,
  providers: [ProcessLomakeService]
})
export class RekisteriLopetusV2Component implements OnInit {
  lomake: FieldGroup;
  lomakeInfo: LomakeInfo;
  lomakeMetaData: LomakeMetaData;
  toimipaikanTiedot: FieldGroup;

  yhteyshenkiloViewOptions = new YhteyshenkiloViewOptions(true, true, true, false)
  constructor(private readonly processLomakeService: ProcessLomakeService,
              private readonly data: RekisteriLopetusV2Data,
              protected readonly accountService: AccountService,
              private readonly lomakeDataResolver: LomakeDataResolver) {
    this.saveLomake = this.saveLomake.bind(this);
    this.getToimipaikanTiedot = this.getToimipaikanTiedot.bind(this);
  }

  ngOnInit(): void {
    this.lomakeMetaData = new LomakeMetaData(this.accountService.getCurrentAccount());
    this.lomakeInfo = new LopetusLomakeInfo(this.processLomakeService.getUrlInformation().asia, Account.isViranomainen(this.lomakeMetaData.account) ? "vk" : "as")
    this.lomake = this.data.getForm(this.lomakeInfo.asia);

    this.getLomake();
    if (this.lomakeMetaData.isAsiakas) {
      this.getToimipaikanTiedot();
    }
  }

  get isSiemenrekisteriLopetus() {
    return this.lomakeInfo.asia !== 'kylvosiemen-markkinointi'
  }

  get lisatieto(): FieldControl {
    return this.lomake.get('lisatiedot.lisatieto').asControl();
  }

  getLomake() {
    return this.processLomakeService.getLomakeTiedot(this.lomakeInfo).then(response => {
      if (response?.id) {
        this.lomakeMetaData.id = response.id;
        this.lomake.initValue(JSON.parse(response.sisalto).data)
      } else {
        this.lomake.get('yhteyshenkilot.yhteyshenkilot.0.nimi').setValue(Account.getReversedWholename(this.accountService.getCurrentAccount()))
      }
      return response;
    });
  }

  async getToimipaikanTiedot() {
    this.toimipaikanTiedot = await this.lomakeDataResolver.getLomakeData(this.lomakeInfo.asia).getForm('muutos');
    const pohjatiedotValue = await this.processLomakeService.getLomakePohjatiedot(this.lomakeInfo.serverBaseUrl);
    this.toimipaikanTiedot.initValue(JSON.parse(pohjatiedotValue.sisalto).data);
  }

  saveLomake() {
    return this.processLomakeService.saveLomake(this.lomake.getRawValue(), this.lomakeInfo, {}, this.lomakeMetaData.id).then(responseId => {
      this.lomakeMetaData.id = responseId || null;
    });
  }

  submitLomake() {
    this.lomake.markAllAsTouched();
    return this.processLomakeService.submitLomake(this.lomake.value, this.lomakeInfo, this.lomakeMetaData.id, {}, this.lomake.valid).then(responseId => {
      this.lomakeMetaData.id = responseId || null;
    });
  }
}

class LopetusLomakeInfo implements LomakeInfo {
  asia: string;
  clientComponentVersion: 'v1' | 'v2' = 'v1';
  defaultRooli: string = null;
  lomakeTitle: Teksti;
  ohjeet: { [p: string]: string[] } = {};
  serverBaseUrl: string;
  toimintotyyppi: string = 'lopetus';
  yhteyshenkiloRoolit: YhteyshenkiloValue[] = [];

  constructor(asia: string, kayttajaRooli: 'as' | 'vk') {
    this.asia = asia;
    this.serverBaseUrl = `${kayttajaRooli}/lomakkeet/ilmoitukset/${asia}/lopetus`
  }
}
