import {Component, OnInit} from "@angular/core";
import {ControlContainer, Validators} from "@angular/forms";
import {FieldArray, FieldGroup} from "ngx-fielding";
import {AutoUnsubscribe} from "../../../../utils/auto-unsubscribe.decorator";
import {LiitetiedostoResponse} from "../../../touko-lomake-utils";
import {LiitetiedostoLomakeService} from "../../../liitetiedosto-lomake.service";
import {LiitetiedostoPalvelinService} from "../../../touko-liitetiedosto.service";

@Component({
  selector: 'elaintuotannon-poikkeuslupa',
  template: `
    <div [formGroup]="formGroup" class="mb-5">
      <h3 attribute="luomu.elaintuotannonPoikkeuslupa">Elaintuotannon poikkeuslupa</h3>
      <fieldset>
        <legend attribute="luomu.elaintuotannonPoikkeuslupaHaku"
                class="font-base font-weight-normal text-body required">Haen
          eläintuotannon poikkeuslupaa
        </legend>
        <touko-radio-input
            formControlName="elaintuotannonPoikkeuslupaValinta"
            htmlId="elaintuotannonPoikkeuslupaEi"
            labelAttribute="teksti.ei"
            radioGroup="elaintuotannonPoikkeuslupa"
            value="ei"
            (change)="deleteLiite(hakemusElaintuotannonPoikkeusluvasta.controls[0])">
        </touko-radio-input>
        <touko-radio-input
            formControlName="elaintuotannonPoikkeuslupaValinta"
            htmlId="elaintuotannonPoikkeuslupaKylla"
            labelAttribute="teksti.kylla"
            radioGroup="elaintuotannonPoikkeuslupa"
            value="kylla"></touko-radio-input>
        <div *ngIf="isElaintuotannonPoikkeuslupaValintaKylla" class="ml-5">
          <label attribute="luomu.hakemusElaintuotannonPoikkeusluvasta" class="required"></label>
          <liite-list htmlId="elaintuotannonPoikkeuslupaLiite"
                      labelledBy="elaintuotannonPoikkeuslupaKylla"
                      [liitteetArray]="formGroup.controlFields.hakemusElaintuotannonPoikkeusluvasta.asArray()"
                      (liiteOut)="setLiitteet($event, 'hakemusElaintuotannonPoikkeusluvasta')"
                      [lomakeId]="lomakeId"></liite-list>
          <validation-error
              [field]="formGroup.controlFields.hakemusElaintuotannonPoikkeusluvasta.asArray()"></validation-error>
        </div>
      </fieldset>
    </div>
  `
})

@AutoUnsubscribe
export class ElaintuotannonPoikkeuslupaComponent implements OnInit {
  lomakeId: number;

  constructor(private controlContainer: ControlContainer, private liitetiedostoLomakeService: LiitetiedostoLomakeService,
              private liitetiedostoService: LiitetiedostoPalvelinService) {
  }

  get hakemusElaintuotannonPoikkeusluvasta(): FieldArray {
    return this.formGroup.controlFields.hakemusElaintuotannonPoikkeusluvasta.asArray();
  }

  get formGroup(): FieldGroup {
    return this.controlContainer.control as FieldGroup;
  }

  get isElaintuotannonPoikkeuslupaValintaKylla(): boolean {
    return this.formGroup.get('elaintuotannonPoikkeuslupaValinta').value === "kylla";
  }

  ngOnInit(): void {
    this.getLomakeId();
    this.formGroup.valueChanges.subscribe(value => {
      if (value.elaintuotannonPoikkeuslupaValinta === 'kylla' && value.hakemusElaintuotannonPoikkeusluvasta.length === 0) {
        this.hakemusElaintuotannonPoikkeusluvasta.setValidators(Validators.required);
        this.hakemusElaintuotannonPoikkeusluvasta.setErrors({'required': true});
      } else {
        this.hakemusElaintuotannonPoikkeusluvasta.setValidators(null)
        this.hakemusElaintuotannonPoikkeusluvasta.setErrors(null)
      }
    })
  }

  getLomakeId(): void {
    this.liitetiedostoLomakeService.lomakeId.subscribe(id => {
      this.lomakeId = id;
    })
  }

  deleteLiite(item: any) {
    if (item) {
      const itemId = item.controls.id.value
      return this.liitetiedostoService.deleteLiitetiedosto(itemId)
          .then(_ignore => {
            this.setLiitteet(this.hakemusElaintuotannonPoikkeusluvasta.value, "hakemusElaintuotannonPoikkeusluvasta")
            this.hakemusElaintuotannonPoikkeusluvasta.controls = [];
            this.hakemusElaintuotannonPoikkeusluvasta.setValue([]);
          });
    }
  }

  setLiitteet(evt: LiitetiedostoResponse[], liiteAvain) {
    this.liitetiedostoLomakeService.setLiite(evt, liiteAvain);
  }
}
