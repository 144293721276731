import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";
import {Injectable} from "@angular/core";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem, ValueOption} from "ngx-fielding";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {createToiminnanAjankohta} from "../common/lomake-fields/toiminnan-ajankohta";
import {TulkkiObject} from "../common/lomake-field-utils";
import {createToimintaJaTuoteluokaKansallinen} from "./kansallinen-toiminta";
import {createToimintaJaTuoteluokatEUToiminta} from "./eu-toiminta";
import {createLisatiedotJaLiitteet} from "./lisatiedot-ja-liitteet";
import {subTitleH3Summary, subTitleH3WithBadgeSummary, titleSummary} from "./summary-helpers";
import {QuestionAndTranslatedAnswerSummaryComponent} from "../../summary/question-and-translated-answer-summary.component";
import {TitleH5SummaryComponent} from "../../summary/title-summary/title-summary.component";

const attr = TulkkiObject.getCreateFn('lannoiterekisteri');

class RadioValue implements ValueOption {
  label: string;
  value: string;

  constructor(value: string, label?) {
    this.label = label || attr(value);
    this.value = value;
  }
}

@Injectable()
export class LannoiterekisteriV2Data implements LomakeV2Data {
  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(toimintotyyppi: string): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();
    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    return new FieldGroup(
      Field.build(
        attr('lannoiterekisteriIlmoitus')
      ), {
        ulkomainenOsoite,
        perustiedot,
        laskutustiedot,
        yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
        toiminnanAloitus: createToiminnanAjankohta(toimintotyyppi),
        toimintaJaTuoteluokatKansallinen: createToimintaJaTuoteluokaKansallinen(toimintotyyppi),
        toimintaJaTuoteluokatEUToiminta: createToimintaJaTuoteluokatEUToiminta(toimintotyyppi),
        muuToiminta: createMuuToiminta(toimintotyyppi),
        lannoitesivutuotetoimija: createLannoitesivutuotetoimija(toimintotyyppi),
        ...createLisatiedotJaLiitteet(toimintotyyppi)
      })
  }
}

export const createMuuToiminta = (toimintotyyppi): FieldGroup => {
  return new FieldGroup(Field.build(
    attr('muuToiminta'), titleSummary), {
    lannoitevalmisteenMarkkinointi: new FieldGroup(Field.build(
      attr('lannoitevalmisteenMarkkinointi'), toimintotyyppi === 'muutos' ? subTitleH3WithBadgeSummary : subTitleH3Summary), {
      lopetaToiminta: new FieldControl(Field.of({
        label: attr('haluanLopettaaToiminnan'),
        htmlId: 'vastavuoroisentunnustamiseenperustuva-lopetaToiminta',
        summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
      })),
      maarat: new FieldGroup(Field.build(), {
        maara: new FieldControl(Field.of({
          label: attr('markkinoilleTuotavienLannoitteidenMaara'),
          htmlId: 'muutoiminta-maara',
          summaryFn: control => new SummaryItem<any>(control.field.label, control.value, {
            show: control.value,
            classList: ['pl-5'],
            valueSuffix: 'lannoiterekisteri.tnVuosi'
          })
        }))
      })
    })
  })
}

const createLannoitesivutuotetoimija = (toimintotyyppi) => new FieldGroup(Field.build(
  attr('sivutuotteenKasittelyLannoitevalmisteeksi'), titleSummary), {
  lannoitesivutuotetoimija: new FieldGroup(Field.build(
    attr('lannoitesivutuotetoimija'), toimintotyyppi === 'muutos' ? subTitleH3WithBadgeSummary : subTitleH3Summary), {
    lopetaToiminta: new FieldControl(Field.of({
      label: attr('haluanLopettaaToiminnan'),
      htmlId: 'lannoitesivutuotetoimija-lopetaToiminta',
      summaryFn: (c) => new SummaryItem<any>(c.field.label, null, {show: c.value})
    })),
    laitosHyvaksynta: new FieldGroup(Field.of({
      label: attr('laitosHyvaksynta'), htmlId: 'laitosHyvaksynta', summaryFn: laitosHyvaksyntaSummary
    }), {
        laitosHyvaksyntaRadio: createRadioButton(),
        laitosHyvaksyntaMuuInfo: new FieldControl(Field.of({htmlId: 'laitosHyvaksynta-laitosHyvaksyntaMuuInfo'}), {value: null, disabled: true})
      }
    ),
    kasiteltavatRaakaaineet: new FieldGroup(Field.build(
        attr('kasiteltavatRaakaaineet'), raakaaineetTitleSummary), {
        luokankolmejate: new FieldControl(Field.of({
          label: attr('luokankolmejate'),
          htmlId: 'kasiteltavatRaakaaineet-luokankolmejate',
          summaryFn: control => new SummaryItem<any>(control.field.label, null, {show: control.value, classList: ['pl-5']})
        })),
        muuLuokankolmeElainperainenSivutuote: new FieldControl(Field.of({
          label: attr('muuLuokankolmeElainperainenSivutuote'),
          htmlId: 'kasiteltavatRaakaaineet-muuLuokankolmeElainperainenSivutuote',
          summaryFn: control => new SummaryItem<any>(control.field.label, null, {show: control.value, classList: ['pl-5']})
        })),
        lanta: new FieldControl(Field.of({
          label: attr('lanta'),
          htmlId: 'kasiteltavatRaakaaineet-lanta',
          summaryFn: control => new SummaryItem<any>(control.field.label, null, {show: control.value, classList: ['pl-5']})
        })),
        muu: new FieldControl(Field.of({
          label: attr('muuMika'),
          htmlId: 'kasiteltavatRaakaaineet-muu'
        })),
        muuInfo: new FieldControl(Field.of({
          htmlId: 'kasiteltavatRaakaaineet-muuInfo',
          summaryFn: control => new SummaryItem<any>(control.value, null, {show: control.value, classList: ['pl-5']})
        }),
          {value: null, disabled: true})
      }
    ),
    ymparistolupa: new FieldGroup(Field.build(
        attr('ymparistolupa'), ymparistolupaTitleSummary), {
        toiminnallaOnYmparistolupa: new FieldControl(Field.of({
          label: attr('toiminnallaOnYmparistolupa'),
          htmlId: 'ymparistolupa-toiminnallaOnYmparistolupa',
          summaryFn: control => new SummaryItem<any>(control.field.label, getYesNoText(control.value), {show: control.value, classList: ['pl-5']}, QuestionAndTranslatedAnswerSummaryComponent)
        })),
        luvanNum: new FieldControl(Field.of({
          htmlId: 'ymparistolupa-luvanNum',
          summaryFn: control => new SummaryItem<any>(
            attr('ymparistoluvanNumero'),
            control.value,
            {classList: ['pl-5']})
        }), {value: null, disabled: true})
      }
    ),
  })
});

const getYesNoText = (value) => value ? 'teksti.kylla' : 'teksti.ei';

const ymparistolupaTitleSummary = (group: FieldGroup) => {
  return new SummaryItem<any>(group.field.label, null, {show: group.get('toiminnallaOnYmparistolupa').value, classList: ['mt-2 pl-5']}, TitleH5SummaryComponent)
}
const raakaaineetTitleSummary = (group: FieldGroup) => {
  return new SummaryItem<any>(group.field.label, null, {show: Object.values(group.controlFields).filter(c => c.field.htmlId !== 'kasiteltavatRaakaaineet-muuInfo').some(c => c.value), classList: ['mt-2 pl-5']}, TitleH5SummaryComponent)
}

const laitosHyvaksyntaSummary: SummaryFnType = (group: FieldGroup) => {
  const radioValue = group.get('laitosHyvaksyntaRadio').value;
  if (!radioValue) {
    return null;
  }
  if (radioValue === 'muu') {
    return new SummaryItem<any>(attr('haeToiminnalleLaitoshyväksyntaa'), group.get('laitosHyvaksyntaMuuInfo').value, {classList: ['pl-5']})
  }
  return new SummaryItem<any>(attr('haeToiminnalleLaitoshyväksyntaa'), group.get('laitosHyvaksyntaRadio').field.label, {classList: ['pl-5']}, QuestionAndTranslatedAnswerSummaryComponent)
}

const createRadioButton = () => new FieldControl(
  Field.of(
    {
      valueOptions: [
        new RadioValue("uusiTuotantolaitosTaiToiminta", attr("uusiTuotantolaitosTaiToiminta")),
        new RadioValue("toiminnassaOlevaLaitos", attr("toiminnassaOlevaLaitos")),
        new RadioValue("muu", attr("muu")),
      ]
    }
  )
)
