import {Field, FieldArray, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {LomakeFieldUtils, TulkkiObject} from "../lomake-field-utils";
import {TitleSummaryComponent} from "../../../summary/title-summary/title-summary.component";
import {YhteyshenkiloSummaryComponent} from "../../../summary/yhteyshenkilo-summary/yhteyshenkilo-summary.component";
import {YhteyshenkiloTeksti} from "../../../touko-lomake-utils";

const utils = new LomakeFieldUtils();
const attr = TulkkiObject.getCreateFn('lomakeYleinen');

const REGEXP_EMAIL = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
const REGEXP_PHONE = new RegExp('^(\\d| |\\+){5,15}$');


export const createYhteyshenkiloFieldArray = (): FieldArray => new FieldArray(
  Field.build(
    attr('yhteyshenkilot'),
    yhteyshenkiloArraySummary
  ),
  createYhteyshenkilo,
  [createYhteyshenkilo()]
);

const yhteyshenkiloArraySummary: SummaryFnType = (yhteyshenkilot: FieldArray) => {
  const mappedValues = yhteyshenkilot.asArray().controlFields
    .map(yhteyshenkilo => yhteyshenkilo.summary)
    .filter(singleYhteyshenkiloSummary => singleYhteyshenkiloSummary.show);

  return new SummaryItem(yhteyshenkilot.field.label, null, {show: mappedValues.length > 0, type: "yhteyshenkilot"}, TitleSummaryComponent);
};


const createYhteyshenkilo = () => new FieldGroup(
  Field.of(
    {
      label: attr('yhteyshenkilot'),
      summaryFn: yhteyshenkiloSummary,
      errorMessages: {
        validYhteyshenkilo: attr('errYhteyshenkilo'),
        email: attr('errEmail'),
        puhelin: attr('errPuhelin')
      },
      htmlId: 'yhteyshenkilot'
    }
  ),
  {
    nimi: utils.createFieldControl('nimi'),
    puhelin: utils.createFieldControl('puhelin'),
    email: utils.createFieldControl('email'),
    rooli: utils.createFieldControl('rooli'),
    elmoid: utils.createFieldControl('elmoid'),
    elmoStatus: new FieldControl(Field.build(""), 'ACTIVE')
  },
  (group: FieldGroup) => {
    const yhteyshenkilo = group.value;
    if (yhteyshenkilo.elmoStatus !== "ACTIVE") {
      return null;
    }
    const virheet: any = {};
    if (!Boolean(yhteyshenkilo.nimi && yhteyshenkilo.puhelin && yhteyshenkilo.email)) {
      virheet.validYhteyshenkilo = true;
    }
    if (!REGEXP_EMAIL.test(yhteyshenkilo.email)) {
      virheet.email = true;
    }
    if (!REGEXP_PHONE.test(yhteyshenkilo.puhelin)) {
      virheet.puhelin = true;
    }
    return Object.keys(virheet).length > 0 ? virheet : null;
  }
);

const yhteyshenkiloSummary: SummaryFnType = (c: FieldGroup) => {
  const show = c.value?.nimi || c.value?.puhelin || c.value?.email;
  const yhteyshenkilo = {...c.value, rooli: YhteyshenkiloTeksti[c.value.rooli]?.fullKey}
  return new SummaryItem<any>(c.field.label, yhteyshenkilo, {show}, YhteyshenkiloSummaryComponent);
};
