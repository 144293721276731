<div class="row">
  <div class="col-12">
    <h1 attribute="etusivu.toimitetutLahetteet">Toimitetut eläintautitutkimuslähetteet</h1>
  </div>
</div>

<div class="row" *ngIf="!isLoaded.lahetetyt && !isLoaded.keskeneraiset">
    <div class="d-block ml-3">
      <p attribute="etusivu.ladataanLahetteita" class="d-inline">Ladataan lähetteitä...</p>
      <div class="float-right ml-3" [showSpinner]="true"></div>
    </div>
</div>

<div class="row" *ngIf="isLoaded.lahetetyt && lahetetyt.length === 0 && isLoaded.keskeneraiset && keskeneraiset.length === 0">
  <div class="col-12 alert alert-warning">
    <p attribute="etusivu.lahetteitaEiLoytynyt">
      Toimitettuja lähetteitä ei löytynyt.
    </p>
    <p>
      <a [routerLink]="'/asiointi/lomake/lahete/elaintautitutkimus/v1/0/aloita'">
        <em class="fa fa-chevron-right mr-2"></em>
        <span attribute="etusivu.luoUusiLahete">Luo uusi lähete</span>
      </a>
    </p>
  </div>
</div>

<div class="row">
  <lahete-listaus *ngIf="keskeneraiset.length > 0"
                  class="col-md-6"
                  [showSpinner]="!isLoaded.keskeneraiset"
                  (selectRow)="continueKeskenerainen($event)"
                  (selectItem)="selectLaheteSarake($event)"
                  (showMore)="showMore($event)"
                  [viewOptions]="keskeneraisetViewOptions"
                  [sarakkeet]="keskeneraisetSarakkeet"
                  [lomakkeet]="keskeneraiset">
  </lahete-listaus>

  <lahete-listaus *ngIf="lahetetyt.length > 0"
                  class="col-md-6"
                  [showSpinner]="!isLoaded.lahetetyt"
                  (selectItem)="openMallipohjaModal($event)"
                  (showMore)="showMore($event)"
                  [viewOptions]="lahetetytViewOptions"
                  [sarakkeet]="lahetetytSarakkeet"
                  [lomakkeet]="lahetetyt">
  </lahete-listaus>

  <lahete-listaus *ngIf="mallipohjat.length > 0"
                  class="col-md-6"
                  [showSpinner]="!isLoaded.mallipohjat"
                  (selectRow)="continueKeskenerainen($event)"
                  (selectItem)="selectLaheteSarake($event)"
                  (showMore)="showMore($event)"
                  [viewOptions]="mallipohjaViewOptions"
                  [sarakkeet]="mallipohjaSarakkeet"
                  [lomakkeet]="mallipohjat">
  </lahete-listaus>
</div>
