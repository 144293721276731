import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem, ValueOption} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {Validators} from "@angular/forms";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";
import {AnswerOnlySummaryComponent} from "../../summary/answer-only-summary.component";
import {TranslatedQuestionSummaryComponent} from "../../summary/translated-question-summary.component";
import {liiteArray} from "../common/lomake-fields/liite";

const attr = TulkkiObject.getCreateFn('siemenrekisteri');

class RadioValue implements ValueOption{
  label: string;
  value: string;

  constructor(value: string, label?) {
    this.label = label || attr(value);
    this.value = value;
  }
}

export const createToiminnanLaajuus = (): FieldGroup => {
  return new FieldGroup(
    Field.of({label: attr('toiminnanLaajuus'), summaryFn: titleSummary}),
    {
      "radio-group": new FieldControl(
        Field.of(
          {
            valueOptions: [
              new RadioValue("alle100", attr("alle100")),
              new RadioValue("100-1000", "100–1 000 kg"),
              new RadioValue("1000-10000", "1 000–10 000 kg"),
              new RadioValue("10000-100000", "10 000–100 000 kg"),
              new RadioValue("100000-1000000", "100 000–1 000 000 kg"),
              new RadioValue("yli1000000", attr("yli1000000")),
            ],
            errorMessages: {
              required: attr('toiminnanLaajuusVirhe')
            },
            summaryFn: radioSummary
          }
        ),
        null,
        Validators.required
      )
    }
  )
}

export const createToimiala = (): FieldGroup => {
  return new FieldGroup(
    Field.of({label: attr('toimiala'), summaryFn: titleSummary}),
    {
      "toimiala-radio-group": new FieldControl(
        Field.of(
          {
            label: attr('parhaitenKuvaavaToimiala'),
            valueOptions: [
              new RadioValue("maatalousVahittaiskauppa"),
              new RadioValue("muuVahittaiskauppa"),
              new RadioValue("maahantuontiJaValitus"),
              new RadioValue("muuSiementoiminta"),
              new RadioValue("siemenpakkaamo"),
              new RadioValue("yksityiskaytto"),
            ],
            errorMessages: {
              required: attr('toimialaVirhe')
            },
            summaryFn: radioSummary
          },
        ),
        null,
        Validators.required
      )
    }
  )
}

const createTitleSummary = (showFn: (group: FieldGroup) => boolean): SummaryFnType => {
  return (fieldGroup) => {

    return new SummaryItem<any>(
      fieldGroup.field.label,
      null,
      {show: showFn(fieldGroup.asGroup())},
      TitleSummaryComponent)
  }
}

const titleSummary: SummaryFnType = createTitleSummary(group => Object.values(group.controlFields).some(c => c?.summary?.show));

const radioSummary = control => {
  const value = control.field.valueOptions.find(o => o.value === control.value);
  return new SummaryItem<any>(value?.label, null, {show: control.value}, TranslatedQuestionSummaryComponent, Field.of({htmlId: control.value}))
}

export const createLisatiedot = (): FieldGroup => {
  return new FieldGroup(
    Field.of({label: attr('lisatieto'), summaryFn: titleSummary}),
    {
      lisatieto: new FieldControl(
        Field.of({
          label: attr('lisatieto'),
          summaryFn: control => new SummaryItem<any>(null, control.value, {show: control.value}, AnswerOnlySummaryComponent)
        }))
    }
  )
}

export const createLiite = (): FieldGroup => {
  return new FieldGroup(
    Field.build(
        'lomakeYleinen.liitteet',
      createTitleSummary(g => g.get('liite').asArray().controlFields.some(c => c?.summary?.show))),
    {
      liite: liiteArray(attr('liite'), 'liite-kaupparekisteri')
    }
  )
}
