
<h1 class="d-print-none">Lähetteen tiedot</h1>

<div id="laheteYhteenvetoPrintDiv">
  <div class="d-none d-print-block" #barcodeCanvas id="barcodeCanvas"></div>
</div>
<lahete-yhteenveto [yhteenveto]="yhteenveto" prefix="yhteenvetoValue-"></lahete-yhteenveto>

<div class="form-row d-print-none">
  <div class="col-9">
    <button class="btn btn-primary" type="button" id="tulostaYhteenvetoButton" (click)="printYhteenveto()">
      <em class="fa fa-print"></em>
      <span attribute="teksti.tulostaYhteenveto">Tulosta yhteenveto</span>
    </button>
  </div>
</div>

<pre *devFeature class="small d-print-none">
  {{ yhteenvetoJSON | json }}
</pre>
