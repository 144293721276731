<touko-lomake [formGroup]="lomakeValues"
              [tunnus]="id"
              [otsikko]="lomakeTitle"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [saveFn]="saveLomake"
              [cancelFn]="cancelLomake"
              (onSivuChanged)="updateYhteenvetoValues()">

  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'" [ohjeet]="ohjeet.sivu0" #sivuPerustiedot>

    <div class="mb-5">
      <touko-alert-box>
        <p attribute="lomakeYleinen.perustiedotTuleeTayttaa" class="mb-0"></p>
      </touko-alert-box>
    </div>

    <syote-group [otsikko]="lomakeData.perustiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('perustiedot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.perustiedot.errorMessages">
      <div class="form-row mb-3" formGroupName="ulkomainenOsoite">
        <touko-checkbox htmlId="ulkomainenOsoiteCheck" labelAttribute="lomakeYleinen.ulkomainenOsoite" formControlName="ulkomainenOsoite" class="col-md-12"></touko-checkbox>
      </div>

      <touko-perustiedot-lannoiterekisteri (perustietoUpdate)="updatePerustiedot($event)"
                                           [formFields]="lomakeData.perustiedot.data"
                                           [paatoiminimi]="allekirjoitus.paatoiminimi" [osastoNimiLock]="true"
                                           formGroupName="perustiedot" [ulkomainenOsoite]="ulkomainenOsoite"></touko-perustiedot-lannoiterekisteri>
    </syote-group>

    <syote-group [otsikko]="lomakeData.laskutustiedot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('laskutustiedot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.laskutustiedot.errorMessages">
      <touko-laskutustiedot-lannoiterekisteri [formFields]="lomakeData.laskutustiedot.data" [ulkomainenOsoite]="ulkomainenOsoite"
                            formGroupName="laskutustiedot"></touko-laskutustiedot-lannoiterekisteri>
    </syote-group>

    <syote-group [otsikko]="lomakeData.yhteyshenkilot.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('yhteyshenkilot'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.yhteyshenkilot.errorMessages"
                 formGroupName="yhteyshenkilot">
      <yhteyshenkilot-input (yhteyshenkilotUpdate)="updateYhteyshenkilot($event)" [rooliValues]="yhteyshenkiloRoolit"
                            formControlName="yhteyshenkilot"></yhteyshenkilot-input>
    </syote-group>


    <syote-group [otsikko]="lomakeData.toiminnanAloitus.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('toiminnanAloitus'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.toiminnanAloitus.errorMessages"
                 formGroupName="toiminnanAloitus">
      <div class="row">
        <div class="col-md-6">
          <touko-pvm formControlName="toiminnanAloitus" [item]="lomakeData.toiminnanAloitus.singleField"
                     [htmlId]="lomakeData.toiminnanAloitus.singleField.name"></touko-pvm>
        </div>
      </div>
    </syote-group>

    <syote-group *ngIf="toimintotyyppi === 'muutos'" [otsikko]="lomakeData.sahkoinenAsiointi.title"
                 formGroupName="sahkoinenAsiointi">
      <p attribute="lomakeYleinen.infoSahkAsiointi"></p>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="check-sahkoinen-asiointi"
               formControlName="sahkoinenAsiointi">
        <label class="form-check-label" for="check-sahkoinen-asiointi"
               [attribute]="lomakeData.sahkoinenAsiointi.singleField.label.fullKey">Suostun sähköiseen asiointiin
          toimipaikkani asioissa.</label>
      </div>
    </syote-group>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'lannoiterekisteri.toimintaJaTuoteluokat'"
        [ohjeet]="ohjeet.sivu1"
        #sivu1>

    <div class="mb-5">
      <touko-alert-box alertType="warning">
        <p
          [attribute]="toimintotyyppi === 'muutos' ? 'lannoiterekisteri.tuoteluokatMuutosLabel' : 'lannoiterekisteri.tuoteluokatLabel'"
          class="mb-0"></p>
      </touko-alert-box>
    </div>

    <syote-group [otsikko]="lomakeData.toimintaJaTuoteluokatKansallinen.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('toimintaJaTuoteluokatKansallinen'), sivu1.ref)"
                 [virheSanomat]="lomakeData.toimintaJaTuoteluokatKansallinen.errorMessages"
                 formGroupName="toimintaJaTuoteluokatKansallinen">

      <div *ngFor="let item of lomakeData.toimintaJaTuoteluokatKansallinen.data" [formGroupName]="item.name">

        <touko-collapse-component htmlId="toggler-{{item.name}}"
                                  [labelAttribute]="item.getLabelKey()"
                                  [infoAttribute]="getCollapseComponentLabel(item.options?.alakohdeToiminta)">

          <div *ngFor="let childItem of item.children">
            <ng-container [ngSwitch]="childItem.options?.type">

              <ng-container *ngSwitchCase="'lopetaToiminta'">
                <touko-checkbox *ngIf="isToimintoActive(item.options?.alakohdeToiminta)"
                                [htmlId]="'toimintaJaTuoteluokatKansallinen-' + item.name"
                                [formControlName]="childItem.name"
                                labelAttribute="lannoiterekisteri.haluanLopettaaToiminnan">
                </touko-checkbox>
              </ng-container>

              <div *ngSwitchCase="'checkbox'">
                <ng-container *ngIf="!isToimintoActive(item.options?.alakohdeToiminta)">
                  <touko-checkbox [labelAttribute]="'lannoiterekisteri.' + childItem.label.key"
                                  [htmlId]="childItem.name"
                                  [formControlName]="childItem.name"></touko-checkbox>
                </ng-container>
              </div>

              <div *ngSwitchDefault>
                <div [formGroupName]="childItem.name" *ngIf="!isToimintoActive(item.options?.alakohdeToiminta)">
                  <touko-collapse-component htmlId="toggler-{{item.name}}-{{childItem.name}}"
                                            [labelAttribute]="childItem.getLabelKey()">
                    <div class="pl-4 mb-4">
                      <touko-background-card>
                        <div *ngFor="let childSecondItem of childItem.children">
                          <ng-container [ngSwitch]="childSecondItem.options?.type">
                            <fieldset *ngSwitchCase="'text'"
                                      [ngClass]="{'mb-4': childSecondItem.options?.showValmistusTitle}" class="mb-0">
                              <legend class="h4 text-dark" *ngIf="childSecondItem.options?.showValmistusTitle"
                                      attribute="lannoiterekisteri.lannoitteidenMaaraJaValmistus">Ilmoita tuotteiden
                                arvioidut määrät ja kerro valmistuksesta:
                              </legend>
                              <legend class="h4 text-dark" *ngIf="childSecondItem.options?.showValmistusTitle == false"
                                      attribute="lannoiterekisteri.arvioidutMaarat">Ilmoita tuotteiden arvioidut määrät
                              </legend>
                              <touko-number-input
                                [formControlName]="childSecondItem.name"
                                [htmlId]="item.name + '-' + childItem.name + '-' + childSecondItem.name"
                                labelAttribute="lannoiterekisteri.{{childSecondItem.label._key}}"
                                descriptionAttribute="lannoiterekisteri.kgVuosi"
                                inputStyle="inline"
                                inputWidth="small">
                              </touko-number-input>
                            </fieldset>
                            <div *ngSwitchCase="'dropdown'">
                              <tuotetiedot-tyyppinimi [syote]="childSecondItem"></tuotetiedot-tyyppinimi>
                            </div>
                            <div *ngSwitchCase="'AOLcheckbox'">
                              <fieldset class="mb-0">
                                <legend class="h4 text-dark" *ngIf="childSecondItem.options?.showTitle"
                                        attribute="lannoiterekisteri.labelAinesosaluokat"></legend>
                                <touko-checkbox [htmlId]="item.name + '-' + childItem.name + '-' + childSecondItem.name"
                                                value="childSecondItem.value"
                                                labelAttribute="lannoiterekisteri.{{childSecondItem.label._key}}"
                                                [formControlName]="childSecondItem.name"></touko-checkbox>
                              </fieldset>
                            </div>

                            <div *ngSwitchCase="'lannoitteenValmistusRadio'" class="mb-4">
                              <fieldset class="mb-0">
                                <legend class="h4 font-weight-normal text-dark"
                                        attribute="lannoiterekisteri.{{childSecondItem.label._key}}"></legend>
                                <touko-radio-input
                                  [htmlId]="item.name + '-' + childItem.name + '-' + childSecondItem.name + '_' + 'yes'"
                                  [formControlName]="childSecondItem.name" labelAttribute="kylla" [value]="true"
                                  [radioGroup]="childItem.name + '_' + childSecondItem.name"></touko-radio-input>
                                <touko-radio-input
                                  [htmlId]="item.name + '-' + childItem.name + '-' + childSecondItem.name + '_' + 'no'"
                                  [formControlName]="childSecondItem.name" labelAttribute="ei" [value]="false"
                                  [radioGroup]="childItem.name + '_' + childSecondItem.name"></touko-radio-input>
                              </fieldset>
                            </div>
                            <fieldset *ngSwitchCase="'textJakelija'">
                              <legend class="h4 text-dark" *ngIf="childSecondItem.options?.showTitle"
                                      attribute="lannoiterekisteri.arvioidutMaarat">Ilmoita tuotteiden arvioidut määrät
                              </legend>
                              <touko-number-input
                                [formControlName]="childSecondItem.name"
                                [htmlId]="item.name + '-' + childItem.name + '-' + childSecondItem.name"
                                labelAttribute="lannoiterekisteri.{{childSecondItem.label._key}}"
                                descriptionAttribute="lannoiterekisteri.kgVuosi"
                                inputStyle="inline"
                                inputWidth="small">
                              </touko-number-input>
                            </fieldset>

                          </ng-container>
                        </div>
                      </touko-background-card>
                    </div>
                  </touko-collapse-component>
                </div>
              </div>
            </ng-container>
          </div>
        </touko-collapse-component>
      </div>
    </syote-group>
    <hr/>
    <syote-group [otsikko]="lomakeData.toimintaJaTuoteluokatEUToiminta.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('toimintaJaTuoteluokatEUToiminta'), sivu1.ref)"
                 [virheSanomat]="lomakeData.toimintaJaTuoteluokatEUToiminta.errorMessages"
                 formGroupName="toimintaJaTuoteluokatEUToiminta">
      <div *ngFor="let item of lomakeData.toimintaJaTuoteluokatEUToiminta.data" [formGroupName]="item.name">
        <touko-collapse-component htmlId="toggler-{{item.name}}" [labelAttribute]="item.getLabelKey()" [infoAttribute]="getCollapseComponentLabel(item.options?.alakohdeToiminta)">
          <div *ngFor="let childItem of item.children">
            <ng-container [ngSwitch]='childItem.options?.type'>
              <ng-container *ngSwitchCase="'lopetaToiminta'">
                <touko-checkbox *ngIf="isToimintoActive(item.options?.alakohdeToiminta)"
                                [htmlId]="'toimintaJaTuoteluokatEUToiminta-' + item.name"
                                [formControlName]="childItem.name"
                                labelAttribute="lannoiterekisteri.haluanLopettaaToiminnan">
                </touko-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'pfcCheckbox'">

                <div [formGroupName]="childItem.name" *ngIf="!isToimintoActive(item.options?.alakohdeToiminta)">
                  <touko-collapse-component htmlId="toggler-{{item.name}}-{{childItem.name}}"
                                            [labelAttribute]="childItem.getLabelKey()">
                    <div class="pl-4">
                      <div *ngFor="let childSecondItem of childItem.children" [formGroupName]="childSecondItem.name">
                        <touko-collapse-component
                          htmlId="toggler-{{item.name}}-{{childItem.name}}-{{childSecondItem.name}}"
                          [labelAttribute]="childSecondItem.getLabelKey()">
                          <div class="pl-4 mb-4">
                            <touko-background-card>
                              <div *ngFor="let childThirdItem of childSecondItem.children">
                                <ng-container [ngSwitch]='childThirdItem.options?.type'>
                                  <fieldset *ngSwitchCase="'text'" class="mb-0">
                                    <legend class="h4 text-dark"
                                            attribute="lannoiterekisteri.arvioidutMaarat">Ilmoita tuotteiden arvioidut
                                      määrät
                                    </legend>
                                    <touko-number-input
                                      [formControlName]="childThirdItem.name"
                                      [htmlId]="item.name + '-' + childItem.name + '-' + childSecondItem.name + '-' + childThirdItem.name"
                                      labelAttribute="lannoiterekisteri.{{childThirdItem.label._key}}"
                                      descriptionAttribute="lannoiterekisteri.kgVuosi"
                                      inputStyle="inline"
                                      inputWidth="small">
                                    </touko-number-input>
                                  </fieldset>

                                  <div *ngSwitchCase="'lannoitteenValmistusAinoastaanPakkaamistaEuRadio'">
                                    <fieldset class="mt-4 mb-4">
                                      <legend attribute="lannoiterekisteri.{{childThirdItem.label._key}}"
                                              class="h4 text-dark font-weight-normal"></legend>
                                      <touko-radio-input
                                        [htmlId]="childSecondItem.name + childThirdItem.name + '_' + 'yes'"
                                        [formControlName]="childThirdItem.name" labelAttribute="kylla" [value]="true"
                                        [radioGroup]="childSecondItem.name+'_'+childThirdItem.name"></touko-radio-input>
                                      <touko-radio-input
                                        [htmlId]="childSecondItem.name + childThirdItem.name + '_' + 'no'"
                                        [formControlName]="childThirdItem.name" labelAttribute="ei" [value]="false"
                                        [radioGroup]="childSecondItem.name+'_'+childThirdItem.name"></touko-radio-input>
                                    </fieldset>
                                  </div>

                                  <fieldset *ngSwitchCase="'cmcCheckbox'" class="mb-0">
                                    <legend class="h4 text-dark"
                                            *ngIf="childThirdItem.options?.showTitle"
                                            attribute="lannoiterekisteri.valmistettavanTuotteenAinesosiaVastavaatAinesosaluokat">
                                      Valitse valmistettavan tuotteen ainesosia vastavaat ainesosaluokat (CMC)
                                    </legend>
                                    <touko-checkbox
                                      [htmlId]="childSecondItem.name + childThirdItem.name"
                                      [formControlName]="childThirdItem.name"
                                      labelAttribute="lannoiterekisteri.{{childThirdItem.label._key}}"></touko-checkbox>
                                  </fieldset>
                                </ng-container>
                              </div>
                            </touko-background-card>
                          </div>
                        </touko-collapse-component>
                      </div>
                    </div>
                  </touko-collapse-component>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </touko-collapse-component>
      </div>
    </syote-group>
    <hr/>
    <syote-group [otsikko]="lomakeData.muuToiminta.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('muuToiminta'), sivu1.ref)"
                 [virheSanomat]="lomakeData.muuToiminta.errorMessages"
                 formGroupName="muuToiminta">
      <div *ngFor="let item of lomakeData.muuToiminta.data" [formGroupName]="item.name">
        <touko-collapse-component htmlId="toggler-{{item.name}}" [labelAttribute]="item.getLabelKey()" [infoAttribute]="getCollapseComponentLabel(item.options?.alakohdeToiminta)">
          <touko-checkbox *ngIf="isToimintoActive('LANNMARKVASTVTUN')"
                            formControlName="lopetaToiminta"
                            [htmlId]="'vastavuoroisentunnustamiseenperustuva-lopetaToiminta'"
                            labelAttribute="lannoiterekisteri.haluanLopettaaToiminnan">
          </touko-checkbox>
          <ng-container *ngIf="!isToimintoActive('LANNMARKVASTVTUN')">
            <div>
              <touko-background-card>
                <ng-container>
                  <fieldset class="mb-0">
                    <legend class="h4 text-dark" attribute="lannoiterekisteri.arvioidutMaarat">Ilmoita tuotteiden
                      arvioidut määrät
                    </legend>
                    <touko-number-input
                      [formControlName]="vastavuoroinenToimintaControl.name"
                      [htmlId]="vastavuoroinenToimintaControl.name"
                      [labelAttribute]="vastavuoroinenToimintaControl.label.fullKey"
                      descriptionAttribute="lannoiterekisteri.kgVuosi"
                      inputStyle="inline"
                      inputWidth="small">
                    </touko-number-input>
                  </fieldset>

                </ng-container>
              </touko-background-card>
            </div>
          </ng-container>
        </touko-collapse-component>
      </div>
    </syote-group>
    <hr/>
    <syote-group [otsikko]="lomakeData.lannoitesivutuotetoimija.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('lannoitesivutuotetoimija'), sivu1.ref)"
                 [virheSanomat]="lomakeData.lannoitesivutuotetoimija.errorMessages"
                 formGroupName="lannoitesivutuotetoimija">
      <div *ngFor="let item of lomakeData.lannoitesivutuotetoimija.data" [formGroupName]="item.name">
        <touko-collapse-component htmlId="toggler-{{item.name}}" [labelAttribute]="item.getLabelKey()" [infoAttribute]="getCollapseComponentLabel(item.options?.alakohdeToiminta)">
          <touko-checkbox *ngIf="isToimintoActive('SIVULANNOITE')"
                          formControlName="lopetaToiminta"
                          [htmlId]="'lannoitesivutuotetoimija-lopetaToiminta'"
                          labelAttribute="lannoiterekisteri.haluanLopettaaToiminnan">
          </touko-checkbox>
          <touko-background-card *ngIf="!isToimintoActive('SIVULANNOITE')">
            <fieldset *ngFor="let childItem of item.children" [formGroupName]="childItem.name"
                      class="card-section mb-0">
              <legend *ngIf="childItem.label._key !== 'haluanLopettaaToiminnan'" class="h4 text-dark" attribute="lannoiterekisteri.{{childItem.label._key}}"></legend>
              <div *ngFor="let childItemLevel2 of childItem.children">
                <ng-container [ngSwitch]="childItemLevel2.options?.type">
                  <div *ngSwitchCase="'radio'">
                    <touko-radio-input
                      [formControlName]="childItemLevel2.name"
                      [value]="childItemLevel2.value"
                      [htmlId]="childItem.name + '-' + childItemLevel2.value"
                      [labelAttribute]="childItemLevel2.getLabelKey() || item.label.label"
                      [radioGroup]="childItemLevel2.name"></touko-radio-input>
                  </div>
                  <div *ngSwitchCase="'text'" class="col-6 pl-5">
                    <touko-text-input
                      [labelledById]="childItemLevel2.options?.labelledBy"
                      [formControlName]="childItemLevel2.name"
                      [htmlId]="childItem.name + '-' + childItemLevel2.name">
                    </touko-text-input>
                  </div>
                  <div *ngSwitchCase="'checkbox'">
                    <touko-checkbox
                      [formControlName]="childItemLevel2.name"
                      [htmlId]="childItem.name + '-' + childItemLevel2.name"
                      labelAttribute="lannoiterekisteri.{{childItemLevel2.label._key}}"></touko-checkbox>
                  </div>
                </ng-container>
              </div>
            </fieldset>
          </touko-background-card>
        </touko-collapse-component>
      </div>
    </syote-group>
    <hr/>
  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'lannoiterekisteri.liitteet'" [ohjeet]="ohjeet.sivu2"
        #sivu2>

    <syote-group
      [otsikko]="lomakeData.lisatiedot.title">

      <ng-container>
        <div formGroupName="lisatiedot">
          <div class="form-row">
            <div class="col-md-12 mb-2">
              <touko-textarea [formControlName]="lomakeData.lisatiedot.data[0].label.key" htmlId="liiteMuu1"
                              [labelAttribute]="lomakeData.lisatiedot.data[0].label.fullKey"></touko-textarea>
            </div>
          </div>
        </div>
      </ng-container>
    </syote-group>

    <syote-group [otsikko]="lomakeData.liitePakolliset.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('liitePakolliset'), sivu2.ref)"
                 [virheSanomat]="lomakeData.liitePakolliset.errorMessages"
                 formGroupName="liitePakolliset">
      <div class="mb-3">
        <touko-liite-pvm [itemLiiteAttr]="lomakeData.liitePakolliset.data[0].options['liite'].getFullKey()"
                         [itemPvmAttr]="lomakeData.liitePakolliset.data[0].options['pvm'].getFullKey()"
                         liiteFieldsetLegend="lannoiterekisteri.liiteTuotetiedotOtsikko"
                         (liiteOut)="onSetLiite($event)"
                         [maxLiiteLkm]="1" [selite]="'VALM_TUOTETIEDOT'"
                         [formControlName]="'liiteValmisteet'"></touko-liite-pvm>
      </div>

      <touko-liite-pvm [itemLiiteAttr]="lomakeData.liitePakolliset.data[1].options['liite'].getFullKey()"
                       [itemPvmAttr]="lomakeData.liitePakolliset.data[1].options['pvm'].getFullKey()"
                       liiteFieldsetLegend="lannoiterekisteri.liiteLaatujarjestelmaOtsikko"
                       (liiteOut)="onSetLiite($event)"
                       [maxLiiteLkm]="1" [selite]="'OVS'"
                       [formControlName]="'liiteLaatujarjestelma'"></touko-liite-pvm>
    </syote-group>

    <syote-group formGroupName="liiteMuut">
      <div class="col-md-10 pl-0">
        <label class="h4" [attribute]="'lannoiterekisteri.liiteMuut'" [id]="'label-liiteMuut'"></label>
        <touko-liite (liiteOut)="onSetLiite($event)" [labelledBy]="'label-liiteMuut'" [maxLiiteLkm]="10"
                     formControlName="liiteMuut"
                     [htmlId]="'liiteMuut'" [selite]="'MUUT'"></touko-liite>
      </div>
    </syote-group>

    <syote-group [otsikko]="lomakeData.verkkokaupat.title"
                 [virheet]="getGroupVirheet(lomakeValues.get('verkkokaupat'), sivuPerustiedot.ref)"
                 [virheSanomat]="lomakeData.verkkokaupat.errorMessages"
                 formGroupName="verkkokaupat">
      <verkkokaupat-input (verkkokaupatUpdate)="updateVerkkokaupat($event)"
                          formControlName="verkkokaupat"></verkkokaupat-input>
    </syote-group>

  </sivu>

  <sivu [selectable]="isPerustiedotValid" [attribuuttiAvain]="'teksti.sivuYhteenveto'">
    <allekirjoitus [data]="allekirjoitus"></allekirjoitus>
    <yhteenveto></yhteenveto>
    <button *ngIf="isAsiakas" id="laheta" toukobutton buttonStyle="primary" type="submit" attribute="teksti.laheta">Lähetä</button>
  </sivu>

  <div class="alert alert-primary my-2" *ngIf="lisatieto">
    <span attribute="etusivu.lisatiedot">Lisätiedot</span>: {{lisatieto}}
  </div>

</touko-lomake>

<pre *devFeature class="small">
  {{ convertLomakeToJSON() | json }}
</pre>
