import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'excel-summary',
  template: `
    <div *ngIf="!compareItemType" class="row">
      <div class="col">
        <excel-list [loadedExcels]="item?.answer" [excelRemovable]="false" [lataaBtnLabel]="'lomakeYleinen.lataaTiedosto'"></excel-list>
      </div>
    </div>
  `
})

export class ExcelSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
