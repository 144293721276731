import {MatcherModel, ModelFragment, ModelFragmentType, Topic, TopicListener} from "../matcher/matcher-model";
import {AsyncValidatorFn, ValidatorFn} from "@angular/forms";
import {QuestionArray} from "./question-array";
import {PathUtil} from "./path-util";
import {QuestionControl} from "./question-control";
import {QuestionOptions} from "./question-model";

/**
 * Kysymys ryhmän malli. Näiden mukaan muodostetaan `FormGroup`-instanssit.
 */
export class QuestionGroup implements ModelFragment {
  key: string;
  parent: ModelFragment;
  fullPath: string;
  label: string;
  placeholder: string;
  initialValue: any;

  controlType: string;
  htmlId: string;
  value: any;

  validators: ValidatorFn[];
  filters: {[s: string]: MatcherModel};
  disablers: {[s: string]: MatcherModel};
  modifiers: {[s: string]: MatcherModel};
  aggregators: {[s: string]: MatcherModel};
  errorMessages: {[s: string]: string};
  asyncValidators: AsyncValidatorFn[];
  options: {[s: string]: any};
  actions: {[s: string]: (...params: any) => void};

  groupComponentType: string;
  groups: QuestionGroup[];
  array: QuestionArray;
  controls: QuestionControl[];

  publishers: Topic[];
  subscribers: TopicListener[];
  lateRegisterMatchers: boolean;

  constructor(options: QuestionOptions) {
    this.key = options.key.toString();
    this.fullPath = options.fullPath || this.key;
    this.parent = options.parent || null;
    this.label = options.label;
    this.placeholder = options.placeholder;
    this.initialValue = options.initialValue || null;
    this.groupComponentType = options.groupComponentType || null;
    this.validators = options.validators;
    this.filters = options.filters || null;
    this.disablers = options.disablers || null;
    this.modifiers = options.modifiers || null;
    this.aggregators = options.aggregators || null;
    this.errorMessages = options.errorMessages || null;
    this.options = options.options || {};
    this.actions = options.actions;
    this.publishers = options.publishers || [];
    this.subscribers = options.subscribers || [];
    this.groups = this.initGroups(options.groups, this.fullPath);
    this.array = this.initArray(options.array, this.fullPath);
    this.controls = this.initControls(options.controls, this.fullPath);
    this.lateRegisterMatchers = options.lateRegisterMatchers || false;
  }

  initControls(children: QuestionControl[], parentKey: string): QuestionControl[] {
    if (children) {
      children.forEach(c => {
        c.updatePath(parentKey);
      });
    }
    return children;
  }

  initGroups(groups: QuestionGroup[], parentKey: string): QuestionGroup[] {
    if (groups) {
      groups.forEach(g => {
        g.updatePath(parentKey);
      });
    }
    return groups;
  }

  initArray(array: QuestionArray, parentKey: string) {
    if (array) {
      array.updatePath(parentKey);
    }
    return array;
  }

  find(path: any[]): ModelFragment {
    if (path.length === 0) {
      return this;
    }

    const subPath = path.slice(1);
    if (this.hasSubGroups()) {
      const nextGroup = this.groups.find(g => g.key === path[0]);
      if (nextGroup) {
        return nextGroup.find(subPath);
      }
    }

    if (this.array && this.array.key === path[0]) {
      return this.array.find(subPath);
    }

    if (this.controls && this.controls.length > 0) {
      const nextControl = this.controls.find(g => g.key === path[0]);
      if (nextControl) {
        return nextControl.find(subPath);
      }
    }

    console.error(`Polkua [${path.join(', ')}] ei löytynyt.`);
    return null;
  }

  findAll(path: string[], found: ModelFragment[] = []): ModelFragment[] {
    if (path.length === 0) {
      found.push(this);
      return found;
    }

    if (this.hasSubGroups()) {
      this.findAllSubQuestions(this.groups, path, found);
    }

    if (this.hasArray() && PathUtil.canContinue(path, this.array.key)) {
      const pathCopy = PathUtil.getConsumedPath(path, this.array.key);
      this.array.findAll(pathCopy, found);
    }

    if (this.hasSubControls()) {
      this.findAllSubQuestions(this.controls, path, found);
    }

    return found;
  }

  findAllLeaves(found = []): ModelFragment[] {
    if (this.hasSubGroups()) {
      this.groups.forEach(g => g.findAllLeaves(found));
    } else if (this.hasArray()) {
      this.array.findAllLeaves(found);
    } else if (this.hasSubControls()) {
      this.controls.forEach(c => c.findAllLeaves(found));
    }

    return found;
  }

  hasSubGroups(): boolean {
    return this.groups && this.groups.length > 0;
  }

  hasArray(): boolean {
    return Boolean(this.array);
  }

  hasSubControls(): boolean {
    return this.controls && this.controls.length > 0;
  }

  private findAllSubQuestions(questions: ModelFragment[], path, found) {
    questions.forEach(q => {
      if (PathUtil.canContinue(path, q.key)) {
        const subPath = PathUtil.getConsumedPath(path, q.key);
        q.findAll(subPath, found);
      }
    });
  }

  push(item: QuestionGroup | QuestionControl, modelFragmentType: ModelFragmentType) {
    item.updatePath(this.fullPath);
    if (item instanceof QuestionGroup && modelFragmentType === ModelFragmentType.GROUP) {
      this.groups.push(item);
    } else if (item instanceof QuestionControl && modelFragmentType === ModelFragmentType.CONTROL) {
      this.controls.push(item);
    } else if (item instanceof QuestionControl && modelFragmentType === ModelFragmentType.ARRAY_ITEM) {
      this.array.push(item, modelFragmentType);
    }
  }

  updatePath(parentFullPath: string) {
    if (PathUtil.checkPathIsChildOf(this.fullPath, parentFullPath)) {
      return;
    }
    this.fullPath = `${parentFullPath}.${this.key}`;

    if (this.groups) {
      this.groups.forEach(g => g.updatePath(this.fullPath));
    }

    if (this.array instanceof QuestionArray) {
      this.array.updatePath(this.fullPath);
    }

    if (this.controls) {
      this.controls.forEach(c => c.updatePath(this.fullPath));
    }
  }

  updateKey(key: string) {
    this.key = key;
    this.updatePath(PathUtil.getParent(this.fullPath));
  }
}
