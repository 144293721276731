import {Field, FieldControl, FieldGroup, SummaryItem} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {createPerustiedotAndLaskutustiedot} from "../common/lomake-fields/perustiedot";
import {
  createRehuAineetRaakaaineKaytto,
  createTuontiArray,
  createRehuAineetValmistus,
  createRehuAineetTuonti,
  createValmistusArray,
  createRaakaaineKayttoArray,
  createLisaaineetKokkidiostaatitRaakaAineKaytto, createValmistusRahtisekoittajatArray
} from "./rehu-aineet";
import {createYhteyshenkiloFieldArray} from "../common/lomake-fields/yhteyshenkilot";
import {Injectable} from "@angular/core";
import {KoodistoService} from "../../../koodisto/koodisto.service";
import {createExcelTiedostot} from "../common/lomake-fields/excel-lataus";
import {createEiToimintaa, createLisatiedot, createSahkoinenAsiointi} from "../common/lomake-fields/common-fields";
import {LomakeV2Data} from "../common/lomake-base/lomake-v2-data";
import {ExcelSummaryComponent} from "../../summary/excel-summary.component";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";

const attr = TulkkiObject.getCreateFn('lomakeYleinen');
const vuosiilmoitusAttr = TulkkiObject.getCreateFn('rehuvuosiilmoitus');

const raakaAineKayttoValidator = (control: FieldControl) => {
  const hasRaakaAineKaytto = control.value.some(c => c.type === "raakaAineKaytto");
  if (hasRaakaAineKaytto) {
    const hasValmistus = control.value.some(c => c.type === "valmistus");
    return hasValmistus ? null : {raakaAineKayttoErr: true}
  }
  return null;
}

@Injectable()
export class RehuvuosiilmoitusData implements LomakeV2Data {

  constructor(private koodistoService: KoodistoService) {
  }

  async getForm(): Promise<FieldGroup> {
    await this.koodistoService.getMaidenNimet();
    const {ulkomainenOsoite, perustiedot, laskutustiedot} = createPerustiedotAndLaskutustiedot();

    const excelTiedostotControl = createExcelTiedostot();
    excelTiedostotControl.addValidators(raakaAineKayttoValidator);
    excelTiedostotControl.field.errorMessages['raakaAineKayttoErr'] = vuosiilmoitusAttr('rehuExcelRaakaAineError');

    return new FieldGroup(
      Field.build(
        vuosiilmoitusAttr('rehuvuosiilmoitus')
      ),
      {
        ulkomainenOsoite,
        perustiedot,
        laskutustiedot,
        yhteyshenkilot: new FieldGroup(Field.build(), {yhteyshenkilot: createYhteyshenkiloFieldArray()}),
        sahkoinenAsiointi: createSahkoinenAsiointi(),
        tuotteet: new FieldGroup(Field.build("lomakeYleinen.ilmoitetutTuotteet", (c)=> new SummaryItem(c.field.label, null,null,TitleSummaryComponent), {pakollinentieto: attr('errExcelTaiEiToimintaaPuuttuu')}), {
            excelTiedostot: excelTiedostotControl,
            rehuEiToimintaa: createEiToimintaa(vuosiilmoitusAttr('eiToimintaa'), vuosiilmoitusAttr('eiToimintaaYhteenvetoInfo')),
            laakeRehu: new FieldControl(
              Field.build(
                "lomakeYleinen.ilmoitetutTuotteet",
                (control) => new SummaryItem<any>(control.field.label, control.value, {show: Boolean(control.status === "VALID" && control.value.length)}, ExcelSummaryComponent)
              ),[])
          },
          [(group) => {
            const validateEiKumpaakaanValittu = !group.get('rehuEiToimintaa').value && !group.get('excelTiedostot').value?.length;
            return validateEiKumpaakaanValittu ? {pakollinentieto: true} : null;
          }]),
        lisatiedot: createLisatiedot(),
        valmistus: new FieldGroup(Field.build(), {
          rehuAineetValmistus: createRehuAineetValmistus(),
          lisaaineetValmistus: createValmistusArray("Lisäaineet"),
          lisaaineidenEsiseoksetValmistus: createValmistusArray("Lisäaineiden esiseokset"),
          elintarviketuotantoelaintenRehutValmistus: createValmistusArray("Elintarviketuotantoeläinten rehut"),
          lemmikkielaintenRehutValmistus: createValmistusArray("Lemmikkieläinten rehut"),
          luonnonelaintenRehutValmistus: createValmistusArray("Luonnoneläinten rehut"),
          turkiselaintenRehutValmistus: createValmistusArray("Turkiseläinten rehut"),
          rahtisekoittajat: createValmistusRahtisekoittajatArray()
        }),
        tuonti: new FieldGroup(Field.build(), {
          rehuAineetTuonti: createRehuAineetTuonti(),
          lisaaineetTuonti: createTuontiArray("Lisäaineet"),
          lisaaineidenEsiseoksetTuonti: createTuontiArray("Lisäaineiden esiseokset"),
          elintarviketuotantoelaintenRehutTuonti: createTuontiArray("Elintarviketuotantoeläinten rehut"),
          lemmikkielaintenRehutTuonti: createTuontiArray("Lemmikkieläinten rehut"),
          luonnonelaintenRehutTuonti: createTuontiArray("Luonnoneläinten rehut")
        }),
        raakaAineKaytto: new FieldGroup(Field.build(), {
          rehuAineetRaakaAineKaytto: createRehuAineetRaakaaineKaytto(),
          lisaaineetKokkidiostaatitRaakaAineKaytto: createLisaaineetKokkidiostaatitRaakaAineKaytto(),
          lisaaineetRaakaAineKaytto: createRaakaaineKayttoArray("Lisäaineet käyttö"),
          lisaaineidenEsiseoksetRaakaAineKaytto: createRaakaaineKayttoArray("Lisäaineiden esiseokset käyttö"),
          rehuseoksetRaakaAineKaytto: createRaakaaineKayttoArray("Rehuseokset käyttö")
        })
      }
    );
  }
}
