import {AbstractControl, FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {DateObj, Verkkokauppa, VerkkolaskuTiedot, Yhteyshenkilo} from "../syote/syote-utils";
import {Operaattoritunnus} from "../../utils/operaattoritunnus";

/**
 * Created by Seppo on 21/08/2017.
 */

export type VirheObjekti = { [s: string]: any } | null;

export class ToukoValidation {

  static AT_LEAST_ONE_TRUE = 'atLeastOneTrue';
  static NONE_NULL = 'noneNull';
  static ATTACHMENT_WITH_DATE = 'attachmentWithDate';
  static PVM_VALID = 'pvm';
  static REQUIRED = 'required';
  static IS_TRUE = 'isTrue';
  static OSOITE_OR_EMPTY = 'osoiteOrEmpty';
  static VALID_POSTINRO = 'validPostinro';
  static VLASKUOSOITE_OR_EMPTY = 'vlaskuosoiteOrEmpty';
  static VALID_YHTEYSHENKILO = 'validYhteyshenkilo';
  static VALID_VERKKOKAUPPA = 'validVerkkokauppa';
  static ALL_KATE_VASTAAVA = 'allKateVastaava';
  static VALUE_NOT_EMPTY = 'valueNotEmpty';
  static VALID_LOPETUSILMOITUS_LISATIETO = 'validLopetusilmoitusLisatieto';
  static AT_LEAST_ONE_CHILD_SELECTED = 'atLeastOneChildSelected';
  static VLASKUOSOITE_CORRECT_FORM = 'vlaskuosoiteCorrectForm';
  static VLASKUOSOITE_OPERAATTORI_TUNNUS = 'vlaskuosoiteOperaattoriTunnus';
  static VLASKUOSOITE_TAI_LASKUTUSOSOITE = 'vlaskuosoiteTaiLaskutusosoite';
  static TUOTETIEDOT_LIITE_TAI_PVM = 'tuotetiedotLiiteTaiPvm';

  static REGEXP_EMAIL = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
  static REGEXP_PHONE = new RegExp('^(\\d| |\\+){5,15}$');

  //Verkkolaskuosoitteen tarkistukseen
  static REGEXP_IBAN = new RegExp(/^(FI)(\d{16})$/);
  static REGEXP_OVT_TUNNUS = new RegExp(/^(0037)(\d{8}\w{0,5}$)/);
  static REGEXP_TE_TUNNUS = new RegExp(/^(TE)(\d{12,16}$)/);
  static REGEXP_PEPPOL_TUNNUS = new RegExp(/^(\d{4}):/);

  static OPERAATTORITUNNUS_LISTA = Operaattoritunnus.getOperaattoritunnukset();

  /**
   * Vähintään yksi lomakeryhmän kentistä täytyy olla 'true',
   * jotta validointi on hyväksytty.
   *
   * @param group - lomakkeen syöteryhmä
   * @returns
   * @constructor
   */
  static atLeastOneTrue(group: FormGroup): VirheObjekti {
    let atLeastOneTrue = false;
    for (const item in group.controls) {
      if (group.controls[item].value) {
        atLeastOneTrue = true;
      }
    }
    return ToukoValidation.buildError(atLeastOneTrue, ToukoValidation.AT_LEAST_ONE_TRUE);
  }

  static kateAtLeastOneTrue(group: FormGroup): VirheObjekti {
    let atLeastOneTrue = false;
    if (Object.values(group.controls)[0].value === true) {
      Object.values(group.controls).splice(1).forEach(item => {
        if (!ToukoValidation.isNullOrAllFalse(item.value) || item?.value === true) {
          atLeastOneTrue = true
        }
      })
    } else {
      atLeastOneTrue = true
    }
    return ToukoValidation.buildError(atLeastOneTrue, ToukoValidation.AT_LEAST_ONE_TRUE);
  }

  /**
   * Mikään lomakeryhmän kentistä ei saa olla 'null', jotta
   * validointi olisi hyväksytty.
   *
   * @param group - lomakkeen syöteryhmä
   * @returns
   */
  static noneNull(group: FormGroup): VirheObjekti {
    let allFilled = true;
    for (const item in group.controls) {
      if (group.controls[item].value === null) {
        allFilled = false;
      }
    }
    return ToukoValidation.buildError(allFilled, ToukoValidation.NONE_NULL);
  }

  static controlNotEmpty(control: FormControl): VirheObjekti {
    return ToukoValidation.buildError(!control.value !== true, ToukoValidation.NONE_NULL);
  }

  static lisatietoWhenRequired(group: FormGroup) {
    const isLisatietoValid = group.value['paatos'] === 'KASITELTY' || Boolean(group.value['lisatieto']);
    return ToukoValidation.buildError(isLisatietoValid, "lisatieto");
  }

  static isValidDate(control: FormControl): VirheObjekti {
    const val = control.value as DateObj;
    return ToukoValidation.buildError(DateObj.isValid(val), ToukoValidation.PVM_VALID);
  }

  static isTrue(control: FormControl): VirheObjekti {
    const val = Boolean(control.value);
    return ToukoValidation.buildError(val, ToukoValidation.IS_TRUE);
  }

  static peltolohkojenTiedotNotEmpty(group: FormGroup): VirheObjekti {
    const groupValue = {...group.value};
    const val = {
      siirtymavaiheenSuunniteltuAjankohta: groupValue?.siirtymavaiheenSuunniteltuAjankohta || null,
      lohkonSijoitusValinta: groupValue?.lohkonSijoitusValinta || null,
      peltolohkot: groupValue?.peltolohkot || null
    };

    if (!val.lohkonSijoitusValinta || val.peltolohkot.length === 0 || !val.siirtymavaiheenSuunniteltuAjankohta) {
      return ToukoValidation.buildError(false, "errPeltolohkotiedot")
    }
  }

  static valueObjNotEmpty(control: FormControl) {
    const controlValue = {...control.value};
    const val = {
      osoite: controlValue?.osoite || null,
      postinumero: controlValue?.postinumero || null,
      postitoimipaikka: controlValue?.postitoimipaikka || null
    };
    return ToukoValidation.buildError(!ToukoValidation.isNullOrAllEmpty(val), ToukoValidation.VALUE_NOT_EMPTY);
  }

  static validOsoiteOrAllEmpty(control: FormControl): VirheObjekti {
    const controlValue = {...control.value};
    const isKotimainenOsoite = controlValue?.maaKoodi === 'FI';
    const val = {
      osoite: controlValue?.osoite || null,
      postinumero: controlValue?.postinumero || null,
      postitoimipaikka: controlValue?.postitoimipaikka || null
    };

    if (ToukoValidation.isNullOrAllEmpty(val)) {
      return ToukoValidation.buildError(true, ToukoValidation.OSOITE_OR_EMPTY);
    }
    if (val.osoite && val.postinumero && !val.postitoimipaikka && isKotimainenOsoite) {
      return ToukoValidation.buildError(false, ToukoValidation.VALID_POSTINRO);
    }

    return ToukoValidation.buildError([val.osoite, val.postinumero, val.postitoimipaikka].every(Boolean), ToukoValidation.OSOITE_OR_EMPTY);
  }

  static getControlRoot(control: AbstractControl): AbstractControl {
    const parent = control.parent;

    if (parent === null) {
      return control;
    } else {
      return ToukoValidation.getControlRoot(parent);
    }
  }

  static validYhteyshenkiloList(control: FormControl): VirheObjekti {
    const val = ToukoValidation.getActiveYhteyshenkilot(control.value as Yhteyshenkilo[]);
    let virheet = ToukoValidation.buildError(val.every(item => Boolean(item.nimi && item.puhelin && item.email)), ToukoValidation.VALID_YHTEYSHENKILO);

    const emailVirheet = val.map(v => !ToukoValidation.REGEXP_EMAIL.test(v.email)).filter(Boolean);
    const emailVirheObj = ToukoValidation.buildError(emailVirheet.length === 0, "email");

    const puhVirheet = val.map(v => !ToukoValidation.REGEXP_PHONE.test(v.puhelin)).filter(Boolean);
    const puhVirheObj = puhVirheet.length > 0 ? ToukoValidation.buildError(false, "pattern") : null;

    virheet = {...virheet, ...emailVirheObj, ...puhVirheObj};

    return ToukoValidation.getErrorOrNull(virheet);
  }

  static getActiveYhteyshenkilot(yhteyshenkilot: Yhteyshenkilo[]) {
    return yhteyshenkilot.filter(y => y.elmoStatus !== 'INACTIVE');
  }

  static validIlmoitusYhteyshenkiloList(control: FormControl): VirheObjekti {
    const val = control.value as Yhteyshenkilo[];

    const ytrVirheet = val.filter(v => v.ytrStatus === 'INVALID');
    const ytrVirheObj = ToukoValidation.buildError(ytrVirheet.length === 0, "ytrVirhe");

    let virheet = ToukoValidation.buildError(val.filter(item => item.ytrStatus === "VALID").every(item => Boolean(item.nimi && item.email)), ToukoValidation.VALID_YHTEYSHENKILO);

    const nimiVirheet = val.map(v => v.ytrStatus === 'VALID' && v.nimi && v.nimi.length > 200).filter(Boolean);
    const nimiVirheObj = ToukoValidation.buildError(nimiVirheet.length === 0, "name");

    const emailVirheet = val.map(v => v.ytrStatus === 'VALID' && !ToukoValidation.REGEXP_EMAIL.test(v.email)).filter(Boolean);
    const emailVirheObj = ToukoValidation.buildError(emailVirheet.length === 0, "email");

    virheet = {...ytrVirheObj, ...virheet, ...nimiVirheObj, ...emailVirheObj};

    return ToukoValidation.getErrorOrNull(virheet);
  }

  static allKasvinterveysvastaavaYhteyshenkilo(control: FormControl): VirheObjekti {
    const val = ToukoValidation.getActiveYhteyshenkilot(control.value as Yhteyshenkilo[]);
    const allValid = val.filter(yh => yh.elmoStatus === 'ACTIVE').every(yh => yh.rooli === 'ReVCCPlantHealthContact');
    return ToukoValidation.buildError(allValid, ToukoValidation.ALL_KATE_VASTAAVA);
  }

  static validVerkkolaskuosoiteOrEmpty(control: FormControl) {
    const val = control.value as VerkkolaskuTiedot;
    if (ToukoValidation.isNullOrAllEmpty(val)) {
      return ToukoValidation.buildError(true, ToukoValidation.VLASKUOSOITE_OR_EMPTY);
    }

    if (val) {
      return ToukoValidation.buildError([val.verkkolaskutusosoite, val.operaattori].every(Boolean), ToukoValidation.VLASKUOSOITE_OR_EMPTY);
    }
    return ToukoValidation.buildError(false, ToukoValidation.VLASKUOSOITE_OR_EMPTY);
  }

  static validVerkkokauppaList(control: FormControl) {
    const val = control.value as Verkkokauppa[];
    if (ToukoValidation.isNullOrAllEmpty(val)) {
      return ToukoValidation.buildError(true, ToukoValidation.VALID_VERKKOKAUPPA);
    }
  }

  static validOperaattoritunnus(control: FormControl) {
    if (!control || !control.value || ToukoValidation.isNullOrAllEmpty(control.value)) {
      return null;
    }

    const val = control.value as VerkkolaskuTiedot;
    const operaattoritunnus = val?.operaattori;
    const operaattoritunnusChecks = ToukoValidation.OPERAATTORITUNNUS_LISTA.some(item => item === operaattoritunnus);

    if (operaattoritunnus) {
      return ToukoValidation.buildError(operaattoritunnusChecks, ToukoValidation.VLASKUOSOITE_OPERAATTORI_TUNNUS);
    }

    return ToukoValidation.buildError(false, ToukoValidation.VLASKUOSOITE_OPERAATTORI_TUNNUS);
  }

  static validVerkkolaskuosoiteForm(ytunnus: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control || !control.value) {
        return null;
      }

      const val = control.value as VerkkolaskuTiedot;
      const verkkolaskutusosoite = val?.verkkolaskutusosoite;
      const ytunnusInOvtForm = ytunnus.split('-').join('');
      const validTeOrOvtTunnus = verkkolaskutusosoite?.includes(ytunnusInOvtForm) && (ToukoValidation.REGEXP_TE_TUNNUS.test(verkkolaskutusosoite) && ToukoValidation.REGEXP_OVT_TUNNUS.test(verkkolaskutusosoite.substring(2)) || ToukoValidation.REGEXP_OVT_TUNNUS.test(verkkolaskutusosoite));
      const verkkolaskutusosoiteChecks = [ToukoValidation.REGEXP_IBAN, ToukoValidation.REGEXP_PEPPOL_TUNNUS];
      const isSomeValid = verkkolaskutusosoiteChecks.some(item => item.test(verkkolaskutusosoite)) || validTeOrOvtTunnus;

      if (verkkolaskutusosoite) {
        return ToukoValidation.buildError(isSomeValid, ToukoValidation.VLASKUOSOITE_CORRECT_FORM);
      }
      return ToukoValidation.buildError(true, ToukoValidation.VLASKUOSOITE_CORRECT_FORM);

    };
  }

  static isLaskutustiedotNotEmpty(laskutustiedotGroup: FormGroup) {

    const isNullOrEmptyGroup = (value) => {
      if (value === null || value === undefined) {
        return true;
      } else {
        return !Object.values(value).some(val => val);
      }
    };

    const laskutusosoite = {...laskutustiedotGroup.get('laskutusosoite').value} || {};
    laskutusosoite.maaKoodi = '';
    const someNotNull = isNullOrEmptyGroup(laskutustiedotGroup.get('verkkolaskutusosoite').value) && isNullOrEmptyGroup(laskutusosoite);
    return ToukoValidation.buildError(!someNotNull, ToukoValidation.VLASKUOSOITE_TAI_LASKUTUSOSOITE);
  }

  static isNullOrAllEmpty(obj: any): boolean {
    if (obj === null) {
      return true;
    }
    return Object.keys(obj).map(key => obj[key]).every(item => item === null || item === "");
  }

  static isNullOrAllFalse(obj: any): boolean {
    if (obj === null) {
      return true;
    }
    return Object.keys(obj).map(key => obj[key]).every(item => item === null || item === false);
  }

  static isValidLopetusilmoitusLisatieto(group: FormGroup): VirheObjekti {
    let isValidLisatieto = true;

    const parent = group.parent;
    if (parent) {
      const lisatietoVal = group.parent.controls['lisatiedot'].controls['lisatieto'].value;
      const radioVal = group.parent.controls['lopetustoiminta'].controls['radio'].value;

      if (radioVal === 'MUUTOKSIA') {
        isValidLisatieto = Boolean(lisatietoVal);
      }
    }

    return ToukoValidation.buildError(isValidLisatieto, ToukoValidation.VALID_LOPETUSILMOITUS_LISATIETO);
  }

  static atLeastOneChildSelected(group: FormGroup): VirheObjekti {
    const isTrueField = (value) => value === true || value && Object.values(value).some(v => Boolean(v));
    let selectedFields = 0;

    for (const key in group.controls) {
      if (group.controls.hasOwnProperty(key)) {
        selectedFields += isTrueField(group.controls[key].value) ? 1 : 0;
      }
    }

    return ToukoValidation.buildError(selectedFields === 0 || selectedFields > 1, ToukoValidation.AT_LEAST_ONE_CHILD_SELECTED);
  }

  static tuotetiedotLiiteTaiPvm(group: FormGroup): VirheObjekti {
    const doesLiiteExist = group.value?.liite ? group.value.liite.length !== 0 : false;
    const isDateSelected = !!group.value?.pvm;
    const atLeastOneTrue = doesLiiteExist || isDateSelected;

    return ToukoValidation.buildError(atLeastOneTrue, ToukoValidation.AT_LEAST_ONE_TRUE);
  }

  /**
   * Tuottaa virheobjektin
   *
   * @param isValidValue - jos tosi, virhettä ei ole
   * @param key - virheobjektin avain, arvona aina true
   * @returns
   */
  protected static buildError(isValidValue: boolean, key: string): VirheObjekti {
    const error: VirheObjekti = {};
    error[key] = true;

    return isValidValue ? null : error;
  }

  protected static getErrorOrNull(errors: VirheObjekti = {}): VirheObjekti {
    return (errors && Object.keys(errors).length) > 0 ?
        errors :
        null;
  }
}
