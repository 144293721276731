import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Account} from "../../account/account";
import {Asiakirja, Asiakirjatyyppi, Toimenpidetyyppi} from "./asiakirja";
import {ReadAccount} from "../../account/account.service";

@Injectable()
export class ArkistodokumentitService {

  constructor(private http: HttpClient) {}

  downloadAsiakirja(asiakirjaTunnus: number): Promise<Blob> {
    const role = ReadAccount.getRole();
    const url = `api/v1/${role}/arkisto/asiakirja`;

    return this.http.get(`${url}/${asiakirjaTunnus}`, {params: null, responseType: 'blob' as 'json'})
      .toPromise()
      .then(r => r as Blob);
  }

  readAsiakirjatunnus(asiaTunnus: number, toimenpidetyyppi: Toimenpidetyyppi, asiakirjatyyppi: Asiakirjatyyppi): Promise<number> {
    // vk/arkisto/asiakirjat/{asiatunnus}/{toimenpidetyyppi}/{asiakirjatyyppi}
    const role = ReadAccount.getRole();
    const url = `api/v1/${role}/arkisto/asiakirjat`;

    return this.http.get(
      `${url}/${asiaTunnus}/${toimenpidetyyppi}/${asiakirjatyyppi}`)
      .toPromise()
      .then(response => {
        const asiakirja = response as Asiakirja[];
        if(asiakirja.length > 0) {
          return asiakirja[0].tunnus;
        }
        return null;
      });
  }

  readAsiakirjat(asiaTunnus: number, toimenpidetyyppi: Toimenpidetyyppi, asiakirjatyyppi: Asiakirjatyyppi): Promise<Asiakirja[]> {
    // vk/arkisto/asiakirjat/{asiatunnus}/{toimenpidetyyppi}/{asiakirjatyyppi}
    const role = ReadAccount.getRole();
    const url = `api/v1/${role}/arkisto/asiakirjat`;

    return this.http.get(
      `${url}/${asiaTunnus}/${toimenpidetyyppi}/${asiakirjatyyppi}`)
      .toPromise()
      .then(response => {
        const asiakirjat = response as Asiakirja[];
        return asiakirjat;
      });
  }
}
