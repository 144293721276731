<h1 [attribute]="title.getFullKey()">Toiminnan lopetus</h1>
<span class="d-none" id="lomakeTunnus">{{ id }}</span>

<form (submit)="submitLomake()" [formGroup]="lomakeValues" class="touko-lomake">
  <div class="col-md-4 pl-0">
    <syote-group [virheet]="getGroupVirheet(lomakeValues.get('lopetustiedot'))"
      [virheSanomat]="lomakeData.lopetustiedot.errorMessages">
    <div formGroupName="lopetustiedot">
      <touko-pvm [item]="lomakeData.lopetustiedot.singleField" [formControlName]="'lopetusPvm'" [htmlId]="'datepicker'"></touko-pvm>
    </div>
    </syote-group>
  </div>
  <syote-group [otsikko]="lomakeData.lopetustoiminta.title"
               [virheet]="getGroupVirheet(lomakeValues.get('lopetustoiminta'))"
               [virheSanomat]="lomakeData.lopetustoiminta.errorMessages">
    <div formGroupName="lopetustoiminta" *ngIf="toimiala !== 'SIEMEN'">
        <div class="form-check" *ngFor="let item of lomakeData.lopetustoiminta.data">
          <label class="form-check-label ml-2">
            <input type="radio" class="form-check-input" id="ei-muutoksia" formControlName="radio" [value]="item.value">
            <span [attribute]="item.label.getFullKey()">Ei valmistusta tai tuontia lopetusvuoden aikana</span>
          </label>
        </div>
    </div>

    <div formGroupName="lisatiedot">
      <div class="form-row">
        <div class="form-group col-md-9 mb-0">
          <label for="muut-lisatiedot" [attribute]="lomakeData.lisatiedot.singleField.getLabelKey()">
            Muut lisätiedot
          </label>
          <textarea id="muut-lisatiedot" formControlName="lisatieto" class="form-control"></textarea>
        </div>
      </div>
    </div>
  </syote-group>
  <syote-group [otsikko]="lomakeData.yhteyshenkilot.title"
               [virheet]="getGroupVirheet(lomakeValues.get('yhteyshenkilot'))"
               [virheSanomat]="lomakeData.yhteyshenkilot.errorMessages">
    <div formGroupName="yhteyshenkilot" class="form-row">
    <div class="col-md-9">
      <p attribute="lomakeYleinen.ilmoYhteyshenkiloInfo">Ilmoita henkilö, jolle lähetetään tieto lomakkeen etenemisestä.</p>
      <yhteyshenkilot-input [maxCount]="1"
                            (yhteyshenkilotUpdate)="updateYhteyshenkilot($event)"
                            [viewOptions]="yhteyshenkiloViewOptions"
                            formControlName="yhteyshenkilot"></yhteyshenkilot-input>
      </div>
    </div>
  </syote-group>
  <div class="form-row">
    <div class="col">
    <div class="form-group">
      <button id="lopeta-toiminta"  class="btn btn-primary" type="submit"
              attribute="lomakeYleinen.lopetaToiminta">Lopeta toiminta
      </button>

      <button id="btn-tallenna" type="button" class="btn btn-success ml-2" [clickAsync]="saveLomake">
        <em class="fa fa-save"></em>
        <span attribute="teksti.tallennaKeskeneraisena" id="btn-tallenna-teksti">Tallenna keskeneräisenä</span>
      </button>
    </div>
  </div>
  </div>
</form>

<hr>

<div class="touko-lomake">
  <div class="row">
    <div class="col">
      <div class="alert alert-warning mb-4">
        <p attribute="lomakeYleinen.lopetusInfoRehu">Ruokavirasto poistaa alla mainitun toimipaikan tiedot
          asiakasrekisteristä ilmoitetusta päivämäärästä lähtien. Yksittäisen toiminnan lopettaminen ilmoitetaan
          muutosilmoituslomakkeella.</p>
      </div>
    </div>
  </div>
</div>

<yhteenveto [titleAttribute]="'lomakeYleinen.nykyinenToiminta'"
            [useImportedData]="true"
            [yhteenveto]="currentToiminta"></yhteenveto>

<div class="row" *ngIf="isViranomainen">
  <div class="col-12">
    <hr>
    <button type="button" class="btn btn-success" [clickAsync]="siirryTarkastukseen" attribute="lomakeYleinen.tallennaJaSiirryTarkastukseen">Tallenna ja siirry tarkastusnäkymään</button>
  </div>
</div>

<pre *devFeature class="small">
  {{ convertLomakeToJSON() | json }}
</pre>
