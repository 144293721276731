import {Injectable} from "@angular/core";
import {Field, FieldArray, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {DateObj} from "../../syote/syote-utils";
import {LomakeFieldUtils, TulkkiObject} from "../common/lomake-field-utils";
import {QuestionAndAnswerSummaryComponent} from "../../summary/question-and-answer/question-and-answer-summary.component";
import {TitleAndTranslatedAnswerSummaryComponent} from "../../summary/title-and-translated-answer-summary.component";
import {YhteyshenkiloSimpleSummaryComponent} from "../../summary/yhteyshenkilo-summary/yhteyshenkilo-simple-summary.component";

const attr = TulkkiObject.getCreateFn('lomakeYleinen');

@Injectable()
export class RekisteriLopetusV2Data {
  public getForm(asia: string): FieldGroup {
    const lisatietoLabel = asia !== 'kylvosiemen-markkinointi' ?
        attr('lopetusMuutLisatiedot') :
        attr('lisatiedot');

    return new FieldGroup(
        Field.build(),
        {
          lopetustiedot: new FieldGroup(Field.build(), {
            lopetusPvm: new FieldControl(Field.build(attr("arvioituLopetus"), dateSummary), DateObj.today())
          }),
          lopetustoiminta: new FieldGroup(Field.build(), {
            radio: new FieldControl(Field.of({
              label: attr("lopetusTiedot"),
              summaryFn: radioButtonSummary,
              valueOptions: [
                {label: attr('lopetusEiValmistusta'), value: 'EI_MUUTOKSIA', options: {htmlId: 'ei-muutoksia'}},
                {
                  label: attr('lopetusTiedotLisatiedoissa'),
                  value: 'MUUTOKSIA',
                  options: {htmlId: 'kylla-muutosksia'}
                }
              ]
            }))
          }),
          lisatiedot: new FieldGroup(Field.build(), {
            lisatieto: new FieldControl(Field.of({
              label: lisatietoLabel,
              summaryFn: questionAndAnswerSummary,
              htmlId: 'muut-lisatiedot'
            }))
          }),
          yhteyshenkilot: new FieldGroup(Field.build(), {
            yhteyshenkilot: new FieldArray(Field.build(
                    attr('ilmoYhteyshenkilo')
                ),
                createYhteyshenkilo,
                [createYhteyshenkilo()])
          })
        })
  }
}

const radioButtonSummary: SummaryFnType = (control) => new SummaryItem(control.field.label, control.field?.valueOptions.find(value => value.value === control.value)?.label, {show: control.value !== null}, TitleAndTranslatedAnswerSummaryComponent)
const questionAndAnswerSummary: SummaryFnType = (control) => new SummaryItem(control.field.label, control.value, {show: control.value !== null}, QuestionAndAnswerSummaryComponent)
const dateSummary: SummaryFnType = (c) => new SummaryItem<any>(
    c.field.label,
    `${c.value?.day}.${c.value?.month}.${c.value?.year}`,
    {show: Boolean(c.value?.day)}, QuestionAndAnswerSummaryComponent)

const utils = new LomakeFieldUtils();
const REGEXP_EMAIL = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:[.]+[a-zA-Z0-9-]+)+$');
const REGEXP_PHONE = new RegExp('^(\\d| |\\+){5,15}$');
const createYhteyshenkilo = () => new FieldGroup(
    Field.of(
        {
          label: attr('ilmoYhteyshenkilo'),
          summaryFn: yhteyshenkiloSummary,
          errorMessages: {
            validYhteyshenkilo: attr('errYhteyshenkilo'),
            email: attr('errEmail'),
            puhelin: attr('errPuhelin')
          },
          htmlId: 'yhteyshenkilot'
        }
    ),
    {
      nimi: utils.createFieldControl('nimi'),
      puhelin: utils.createFieldControl('puhelin'),
      email: utils.createFieldControl('email')
    },
    (group: FieldGroup) => {
      const yhteyshenkilo = group.value;
      const virheet: any = {};
      if (!Boolean(yhteyshenkilo.nimi && yhteyshenkilo.puhelin && yhteyshenkilo.email)) {
        virheet.validYhteyshenkilo = true;
      }
      if (!REGEXP_EMAIL.test(yhteyshenkilo.email)) {
        virheet.email = true;
      }
      if (!REGEXP_PHONE.test(yhteyshenkilo.puhelin)) {
        virheet.puhelin = true;
      }
      return Object.keys(virheet).length > 0 ? virheet : null;
    }
);

const yhteyshenkiloSummary: SummaryFnType = (c: FieldGroup) => {
  const show = c.value?.nimi || c.value?.puhelin || c.value?.email;
  const yhteyshenkilo = {...c.value}
  return new SummaryItem<any>(c.field.label, yhteyshenkilo, {show}, YhteyshenkiloSimpleSummaryComponent);
};
