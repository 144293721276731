import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LomakeService} from "../touko-lomake.service";
import {AccountService} from "../../account/account.service";
import {FieldGroup} from "ngx-fielding";
import {
  Allekirjoitus,
  LOMAKE_KASITTELY_MESSAGE,
  LOMAKE_KUVAUKSET,
  LomakeKasittely,
  LomakeResponse,
  LomakeTila
} from "../touko-lomake-utils";
import {Teksti} from "../../utils/teksti";
import {TarkastusPDFService} from "./tarkastus-pdf.service";
import {MessageService} from "../../message/message.service";
import {LomakeDataResolver} from "../vuosiilmoitus/common/lomake-base/lomake-v2-data";
import {RekisteriLopetusV2Data} from "../vuosiilmoitus/rekisteri-lopetus/rekisteri-lopetus-v2-data";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

@Component({
  selector: 'tarkastus-lopetus-v2',
  template: `
    <div>
      <h2 *ngIf="this.otsikko" [attribute]="this.otsikko"></h2>
      <h2 *ngIf="!this.otsikko">Lomakkeen tiedot</h2>
      <lomaketiedot id="lomaketiedot" [data]="lomakeKasittely"></lomaketiedot>
      <div #lomakeYhteenvetoDiv>
        <allekirjoitus id="allekirjoitus" [data]="allekirjoitus"></allekirjoitus>
        <app-field-group-summary class="summary" *ngIf="this.lomake"
                                 [fieldGroup]="lomake"></app-field-group-summary>
        <app-field-group-summary class="summary" *ngIf="toimipaikanTiedot"
                                 [fieldGroup]="toimipaikanTiedot"></app-field-group-summary>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <virkailija-tiedot (onHyvaksy)="lahetaYhteenvetoJaKasittely($event)"></virkailija-tiedot>
      </div>
    </div>
    <pre *devFeature class="small">
  {{ lomake?.getRawValue() | json }}
</pre>
  `
})

@AutoUnsubscribe
export class TarkastusLopetusV2Component implements OnInit {
  lomake: FieldGroup;
  lomakeResponse: LomakeResponse;
  lomakeVersiot: LomakeResponse[] = [];
  showLoader = true;
  allekirjoitus: Allekirjoitus = new Allekirjoitus();
  otsikko: string;
  isViranomainen = false;
  isEsikatselu = false;
  excludePaths: string[] = [];
  lomakeKasittely: LomakeKasittely = null;
  toimipaikanTiedot: FieldGroup;

  @ViewChild("lomakeYhteenvetoDiv") lomakeYhteenvetoDiv: ElementRef;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly lomakeService: LomakeService,
              private readonly accountService: AccountService,
              private readonly tarkastusPdfService: TarkastusPDFService,
              private readonly messageService: MessageService,
              private readonly lomakeDataResolver: LomakeDataResolver) {
    this.getToimipaikanTiedot = this.getToimipaikanTiedot.bind(this);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async param => {
          const paramId = parseInt(param.id, 10);
          const toimintotyyppi = (param.toimintotyyppi || this.activatedRoute.snapshot.url[0].path).toLowerCase();
          const asia = param.asia || this.activatedRoute.snapshot.url[1].path;
          this.excludePaths = this.getExcludedPaths(asia)
          if (this.accountService.isViranomainen()) {
            this.isViranomainen = true;
            this.lomakeResponse = await this.lomakeService.getLomakeV2Viranomainen(paramId, asia, toimintotyyppi);
          } else {
            this.isEsikatselu = this.activatedRoute.snapshot.data['isEsikatselu'];
            this.lomakeResponse = await this.lomakeService.getLomakeV2Asiakas(paramId, asia, toimintotyyppi, this.isEsikatselu);
          }
          this.otsikko = this.getLomakeKuvausKaannos(this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi)

          if (this.lomakeVersiot.length === 0) {
            const alkupLomakeId = this.lomakeResponse.alkuperainenLomakeId ? this.lomakeResponse.alkuperainenLomakeId : this.lomakeResponse.id;
            this.lomakeService.getLomakeVersiot(alkupLomakeId)
                .then(data => this.lomakeVersiot = data)
                .then(() => this.showLoader = false);
          }

      this.lomake = new RekisteriLopetusV2Data().getForm(toimintotyyppi);
          const lomakkeenSisaltoDataJson = JSON.parse(this.lomakeResponse.sisalto).data;
          this.lomake.initValue(lomakkeenSisaltoDataJson);
          this.allekirjoitus = Allekirjoitus.createFromLomake(this.lomakeResponse);
      const isKasittelyssa = LomakeTila[this.lomakeResponse.tila] === LomakeTila.KASITTELYSSA;
      const kasittelija = this.isViranomainen && isKasittelyssa ? this.lomakeResponse.muokannutToimija : null;
      this.lomakeKasittely = new LomakeKasittely(this.lomakeResponse.id, LomakeTila[this.lomakeResponse.tila], this.lomakeResponse.lisatieto, kasittelija, this.getKuvausTeksti(this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi));
      await this.getToimipaikanTiedot();
        }
    );
  }

  sendYhteenvetoPdf(kasittely: LomakeKasittely): Promise<any> {
    const contentElement: ElementRef = this.lomakeYhteenvetoDiv.nativeElement

    return this.tarkastusPdfService.createYhteenvetoPdf(kasittely, this.allekirjoitus, this.otsikko, contentElement)
        .then(pdf => this.lomakeService.sendYhteenveto(kasittely.id, pdf, true));
  }

  kasitteleLomake(lomakeKasittely: LomakeKasittely): void {
    const kasitteleLomakeFn = this.lomakeService.kasitteleLomakeV2(lomakeKasittely, this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi);

    kasitteleLomakeFn.then(() => {
          this.messageService.notify({message: LOMAKE_KASITTELY_MESSAGE[lomakeKasittely.tila], type: "success"});
          this.router.navigate(['/asiointi/etusivu']);
        })
        .catch(() => {
          this.messageService.notify({
            message: new Teksti("Käsittelyvirhe", "errLomakkeenKasittelyvirhe", "lomakeYleinen"),
            type: "danger"
          });
        });
  }

  async getToimipaikanTiedot() {
    this.toimipaikanTiedot = await this.lomakeDataResolver.getLomakeData(this.lomakeResponse.asia).getForm(this.lomakeResponse.toimintotyyppi);
    let toiminnanHakuFn = this.isViranomainen
        ? await this.lomakeService.getToiminnanTiedotViranomainenV2.bind(this.lomakeService, this.lomakeResponse.id, this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi)
        : await this.lomakeService.getMuutoslomakeTiedotV2.bind(this.lomakeService, this.lomakeResponse.asia, this.lomakeResponse.toimintotyyppi, false);
    toiminnanHakuFn().then(pohjatiedotValue => {
      this.toimipaikanTiedot.initValue(JSON.parse(pohjatiedotValue.sisalto).data);
    })
  }

  lahetaYhteenvetoJaKasittely($event: LomakeKasittely) {
    this.sendYhteenvetoPdf($event)
        .then(() => {
          this.kasitteleLomake($event);
        });
  }

  getKuvausTeksti(asia: string, toimintotyyppi: string): Teksti {
    return LOMAKE_KUVAUKSET[asia][toimintotyyppi];
  }

  getLomakeKuvausKaannos(asia, toimintotyyppi) {
    return this.getKuvausTeksti(asia, toimintotyyppi).fullKey;
  }

  getExcludedPaths(asia): string[] {
    const paths = {
      lannoiterekisteri: ['toimintaJaTuoteluokatKansallinen', 'toimintaJaTuoteluokatEUToiminta', 'muuToiminta', 'lannoitesivutuotetoimija'],
      siemenpakkaamolupa: ['toiminnanAloitus', 'markkinoimasiKylvosiemenet', 'koneetJaLaitteet', 'lisatiedot', 'painatus', 'tarkastusAjankohta']
    }
    return paths[asia] || []
  }
}
