import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'question-only-summary',
  template: `
    <div *ngIf="item" class="row">
      <div class="col">
        <compare-item [compareItemType]="compareItemType">
          <span *ngIf="item.show" [id]="'value-' + item.field.htmlId" [attribute]="item.question"></span>
        </compare-item>
      </div>
    </div>
  `,
  styleUrls: ['./summary.scss']
})
export class QuestionOnlySummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
