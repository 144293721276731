import {Pipe, PipeTransform} from "@angular/core";
import {KoodistoService} from "./koodisto.service";

@Pipe({
  name: 'postitoimipaikka'
})
export class PostitoimipaikkaPipe implements PipeTransform {

  constructor(private koodistoService: KoodistoService) {}

  public transform(value: string): string | Promise<string> {
    if (!value) {
      return '';
    }

    return this.koodistoService.getPostinumerot()
      .then( data => {
        if (data.hasOwnProperty(value)) {
          return data[value].postitoimipaikka;
        }
        return null;
      });
  }

}
