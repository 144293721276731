import {MatcherModel, ModelFragment, Topic, TopicListener} from "../matcher/matcher-model";
import {ValidatorFn} from "@angular/forms";
import {QuestionOptions} from "./question-model";
import {PathUtil} from "./path-util";

/**
 * Yksittäisen kysymyksen malli. Näiden mukaan muodostetaan `FormControl`-instanssit.
 */
export class QuestionControl implements ModelFragment {
  parent: ModelFragment;
  value: any;
  initialValue: any;
  htmlId: string;
  key: string;
  fullPath: string;
  label: string;
  placeholder: string;
  validators: ValidatorFn[];
  order: number;
  controlType: string;
  errorMessages: {[s: string]: string};
  options: {[s: string]: any};
  actions: {[s: string]: (...params: any) => void};
  filters: {[s: string]: MatcherModel};
  disablers: {[s: string]: MatcherModel};
  modifiers: {[s: string]: MatcherModel};
  aggregators: {[s: string]: MatcherModel};

  publishers: Topic[];
  subscribers: TopicListener[];
  lateRegisterMatchers: boolean;

  /**
   *
   * @param options
   */
  constructor(
    options: QuestionOptions
  ) {
    this.parent = options.parent;
    this.value = options.value;
    this.initialValue = options.initialValue === undefined ? null : options.initialValue;
    this.key = options.key.toString();
    this.fullPath = options.fullPath || this.key;
    this.htmlId = options.htmlId || this.fullPath || null;
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.validators = options.validators || null;
    this.errorMessages = options.errorMessages || null;
    this.controlType = options.controlType;
    this.options = options.options || {};
    this.filters = options.filters || null;
    this.disablers = options.disablers || null;
    this.modifiers = options.modifiers || null;
    this.aggregators = options.aggregators || null;
    this.actions = options.actions;
    this.publishers = options.publishers || [];
    this.subscribers = options.subscribers || [];
    this.lateRegisterMatchers = options.lateRegisterMatchers || false;
  }

  find(path: string[]): ModelFragment {
    if (path.length === 0) {
      return this;
    }
    return null;
  }

  findAll(path: string[], found: ModelFragment[] = []): ModelFragment[] {
    if (path.length === 0) {
      found.push(this);
    }
    return found;
  }

  findAllLeaves(found = []): ModelFragment[] {
    found.push(this);
    return found;
  }

  updatePath(parentFullPath: string) {
    if (typeof this.fullPath === 'string' && PathUtil.checkPathIsChildOf(this.fullPath, parentFullPath)) {
      return;
    }
    this.fullPath = `${parentFullPath}.${this.key}`;
  }

  updateKey(key: string) {
    this.key = key;
    this.updatePath(PathUtil.getParent(this.fullPath));
  }
}

