<h1 attribute="raportit.vkOtsikko">
  Toimijan raportit
</h1>

<p attribute="raportit.vkOhje">
  Virkailijana voit nähdä, mitä tapahtumia ja raportteja tietylle toimijalle näkyy. PDF-tiedostojen tarkastelu tapahtuu
  virkailijan osalta Elmo-järjestelmän kautta.
</p>

<form [formGroup]="toimijaForm" novalidate>
  <div class="form-row">
    <div class="col-md-4">
      <div class="form-group">
        <div class="form-check">
          <touko-checkbox class="form-check-input" type="checkbox" htmlId="kayttaja-easiointi" formControlName="easiointi"></touko-checkbox>
          <label class="form-check-label" [htmlFor]="'kayttaja-easiointi'">
            Näytä ainoastaan sähköisesti asioivat toimijat.
          </label>
        </div>
        <div class="form-check">
          <touko-checkbox class="form-check-input" type="checkbox" htmlId="kayttaja-avaamattomat-asiakirjat" formControlName="avaamattomatAsiakirjat"></touko-checkbox>
          <label class="form-check-label" [htmlFor]="'kayttaja-avaamattomat-asiakirjat'">
            Näytä ainoastaan toimijat, joilla avaamattomia asiakirjoja.
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4">
      <div class="form-group">
        <label [htmlFor]="'kayttaja-yritys'" attribute="raportit.vkLabelToimijanYritys">Toimijan yritys</label>
        <yritys-typeahead
          [htmlId]="'kayttaja-yritys'"
          [controlName]="'yritys'"
          [items]="yritysList">
        </yritys-typeahead>
      </div>
    </div>

    <div class="col-md-8">
      <div class="form-group">
        <label  [htmlFor]="'kayttaja-toimipaikka'" attribute="raportit.vkLabelToimipaikka">Toimipaikka</label>
        <valvontakohde-typeahead
          [htmlId]="'kayttaja-toimipaikka'"
          [controlName]="'toimipaikka'"
          (selectValvontakohde)="updateToimija($event.valvontakohde)"
          [valvontakohteet]="valvontakohdeList"></valvontakohde-typeahead>
      </div>
    </div>
  </div>
</form>

<div [hidden]="!toimipaikka">
  <hr>
  <ng-container component-host></ng-container>
</div>
