import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Account} from "../../account/account";
import {LomakeResponse} from "../../touko-lomake/touko-lomake-utils";
import {Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {LomakeHakuService} from "../../touko-lomake/lomake-haku.service";
import {DateObj} from '../../touko-lomake/syote/syote-utils';
import {InitialHakuParams} from "../lomake-table/lomake-haku.component";

@Component({
  selector: 'etusivu-virkailija',
  templateUrl: './etusivu-virkailija.component.html'
})
export class EtusivuVirkailijaComponent implements OnInit, OnDestroy {

  @Input() account: Account = null;
  lomakeLista: LomakeResponse[] = [];
  showLoader;
  subs: Subscription[] = [];
  totalCounter = 0;
  params = {};
  initialHakuParams = new InitialHakuParams();
  initialSivunumero = 1;
  sivunumero: number;
  initToimiala: string;

  constructor(private readonly lomakeHakuService: LomakeHakuService,
              private readonly router: Router) {
  }

  ngOnInit() {
    this.initToimiala = this.account.toimiala;
    this.onToimialaChanged();
    this.initLomakeParams();
    this.initSivunumero();
    this.subs.push(this.lomakeHakuService.lomakkeet$.subscribe(lomakkeet => {
      if (lomakkeet) {
        this.lomakeLista = [];
        lomakkeet.forEach(lomake => {
          this.lomakeLista.push(lomake);
        });
        this.lomakeLista.sort((a, b) => new Date(b.paipvm).getTime() - new Date(a.paipvm).getTime());
      }
    }));

    this.subs.push(this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event --> re-initalise the component
      if (e instanceof NavigationEnd) {
        this.refreshLomakkeet();
      }
    }));

    this.subs.push(this.lomakeHakuService.isGetLomakkeetRequestPending$.subscribe(value => {
      this.showLoader = value;
    }));
  }

  ngOnDestroy() {
    this.showLoader = false;
    this.subs.forEach(s => s.unsubscribe());
    sessionStorage.setItem('etusivuToimiala', this.initToimiala);
  }

  initSivunumero = () => {
    const sivunumero = sessionStorage.getItem('lomakeSivunumero');
    if (sivunumero) {
      this.initialSivunumero = parseInt(sivunumero);
    }
  };

  onSivuChanged = (pageNum: number) => {
    this.sivunumero = pageNum;
    sessionStorage.setItem('lomakeSivunumero', '' + this.sivunumero);
  };

  onLomakeCleared = (cleared: boolean) => {
    if (cleared) {
      this.initialSivunumero = 1;
    }
  };

  onToimialaChanged = (): void => {
    const aiempiToimiala = sessionStorage.getItem('etusivuToimiala');
    if (aiempiToimiala && this.initToimiala !== aiempiToimiala) {
      sessionStorage.removeItem('lomakeSivunumero');
      sessionStorage.removeItem('lomakeHakuParams');
    }
  };

  getDateOrNull(date?: { year: number; month: number; day: number }): DateObj | null {
    return date ? DateObj.fromJson(date) : null;
  }

  initLomakeParams = () => {
    const lomakeParams = sessionStorage.getItem('lomakeHakuParams');
    const json = JSON.parse(lomakeParams || "{}");
    if (Object.keys(json).length) {
      json.lahetettyAlkuPvm = this.getDateOrNull(json.lahetettyAlkuPvm);
      json.lahetettyLoppuPvm = this.getDateOrNull(json.lahetettyLoppuPvm);
      json.kasiteltyAlkuPvm = this.getDateOrNull(json.kasiteltyAlkuPvm);
      json.kasiteltyLoppuPvm = this.getDateOrNull(json.kasiteltyLoppuPvm);
      this.initialHakuParams = json;
      this.refreshLomakkeet(json);
    } else {
      this.refreshLomakkeet();
    }
  };

  /**
   * Hakee käyttäjän lomakkeet
   *
   * @param vkParams - Lomakkeiden hakuehdot
   */
  public refreshLomakkeet(vkParams = null): void {
    this.params = vkParams || {};
    sessionStorage.setItem('lomakeHakuParams', JSON.stringify(this.params));
    this.lomakeHakuService.getVirkailijaIlmoitukset(vkParams).then(r => {
      this.totalCounter = r?.length;
    });
  }

}
