import {AbstractLaheteGroup} from "./abstract-lahete-group";
import {CompareAction, CompareType, MatcherModel} from "ee-lahete";
import {FormArray} from "@angular/forms";
import {NaytetiedotGroup} from "./naytetiedot-group";
import {QuestionUpdate, QuestionUpdateType} from "ee-lahete";
import {QuestionControl} from "ee-lahete";
import {QuestionGroup} from "ee-lahete";
import {QuestionArray} from "ee-lahete";
import {GroupBuilder} from "./group-build-options";

export class ValitutTutkimuksetGroup extends AbstractLaheteGroup {

  getGroup(controlType?: string, builder = new GroupBuilder({controlType: 'tutkimus-card'})): QuestionGroup {
    const aggregators = this.laheteKoodisto.data.reduce((previousValue, currentValue) => {
      const key = `tutkimukset.${currentValue.id}`;
      previousValue[key] = new MatcherModel(currentValue.id, ValitutTutkimuksetGroup.addToArrayIfTrue, this.valittuTutkimusAggregatorFn, CompareType.SINGLE);
      return previousValue;
    }, {});

    let questions = [];
    if (builder.applyValues) {
      const valitut = builder.initValueByPath(['valitutTutkimukset', 'valitut'], []);
      questions = valitut.map(valittu => this.createCard(valittu.id));
    }

    return new QuestionGroup({
      key: 'valitutTutkimukset',
      array: new QuestionArray({
        key: 'valitut',
        questions: questions,
        controlType: builder.controlType,
        aggregators: aggregators
      }),
      filters: {
        'tutkimukset': new MatcherModel(null, ValitutTutkimuksetGroup.anyObjectValueTrue, ValitutTutkimuksetGroup.filterActionFn)
      },
    });
  }

  /**
   * TODO: funktion voi poistaa, kun komponentin tiedot päivitetään oikein. Tutkimuskorttiin ei tule tutkimussyyn,
   *       näytetietojen tai tiedoksisaaja valintaa ollenkaan.
   *
   * @param formArray
   * @param id
   * @param compareActionType
   */
  valittuTutkimusAggregatorFn = (formArray: FormArray, id: string, compareActionType: CompareAction) => {
    if (compareActionType === CompareAction.ARRAY_ADD && formArray.controls.filter(c => this.areObjectStringsEqual(c.value.id, id)).length === 0) {
      const question = this.createCard(id);

      this.laheteService.updateQuestion(new QuestionUpdate('valitutTutkimukset.valitut', question.key, QuestionUpdateType.ADD, question));

    } else if (compareActionType === CompareAction.ARRAY_REMOVE) {
      this.laheteService.updateQuestion(new QuestionUpdate('valitutTutkimukset.valitut', id, QuestionUpdateType.REMOVE));
    }
  }

  createCard = (id: string) => {
    const nayteTutkimus = NaytetiedotGroup.mapNayteTutkimusData(
      this.laheteKoodisto.dataObj[id],
      this.laheteKoodisto.koodisto.tutkimussyyt,
      this.laheteKoodisto.koodisto.naytemateriaalit,
      this.laheteKoodisto.koodisto.tiedoksisaajat,
      this.lang);

    return new QuestionControl({
      controlType: 'card',
      key: id,
      label: this.getLocalizedOf(this.laheteKoodisto.dataObj[id], 'tutkimus'),
      initialValue: {
        id: id,
        selected: true
      },
      options: {
        tutkimus: this.laheteKoodisto.dataObj[id],
        tutkimussyyt: nayteTutkimus.tutkimussyyt,
        naytemateriaalit: nayteTutkimus.naytemateriaalit,
        tiedoksisaaja: nayteTutkimus.tiedoksisaaja,
      }
    });
  }

  areObjectStringsEqual = (left, right) => {
    return left.toString() === right.toString();
  }
}
