import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CompareStatus, YhteenvetoCompareGroup, YhteenvetoGroup} from "../yhteenveto-utils";
import {YhteenvetoVertailuService} from "./yhteenveto-vertailu.service";

@Component({
  selector: 'yhteenveto-vertailu',
  templateUrl: './yhteenveto-vertailu.component.html',
  styleUrls: ['../yhteenveto.component.scss'],
  providers: [YhteenvetoVertailuService]
})
export class YhteenvetoVertailuComponent implements OnChanges {

  @Input() lomakeYhteenveto: YhteenvetoGroup[];
  @Input() toimintaYhteenveto: YhteenvetoGroup[];
  @Input() showCompareYhteenveto = false;
  @Input() titleAttribute = "teksti.sivuYhteenveto";
  yhteenveto: YhteenvetoCompareGroup[] = [];
  compareStatusEnum = CompareStatus;

  constructor(private readonly yhteenvetoVertailuService: YhteenvetoVertailuService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!(this.lomakeYhteenveto || this.toimintaYhteenveto)) {
      return;
    }

    this.yhteenveto = this.yhteenvetoVertailuService.compareLomakeWithToiminta(
      this.showCompareYhteenveto,
      this.lomakeYhteenveto,
      this.toimintaYhteenveto
    );
  }
}
