import {Field, FieldControl, FieldGroup, SummaryFnType, SummaryItem} from "ngx-fielding";
import {TulkkiObject} from "../common/lomake-field-utils";
import {TitleSummaryComponent} from "../../summary/title-summary/title-summary.component";
import {TranslatedQuestionSummaryComponent} from "../../summary/translated-question-summary.component";
import {liiteArray} from "../common/lomake-fields/liite";

const attr = TulkkiObject.getCreateFn('rehuvalvonta');

export const createSivutuoteAsetuksenMukainenHyvaksynta = (): FieldGroup => {
  const hakemuksenLiitteet = 'hakemuksenLiitteet';

  const isSivutuoteasetusValittu = (group) => {
    const kasittelylaitosValittu = group.get('kasittelylaitos').value;
    const lemmikkielaintenRuokiaValmistavaLaitosValittu = group.get('lemmikkielaintenRuokiaValmistavaLaitos').value;
    const anyLiite = group.get(hakemuksenLiitteet).asArray().length > 0;

    return kasittelylaitosValittu || lemmikkielaintenRuokiaValmistavaLaitosValittu || anyLiite;
  }

  return new FieldGroup(
    Field.build(
      attr('sivutuoteHyv'),
      createTitleSummary(isSivutuoteasetusValittu),
      {liitePuuttuu: attr('liitePuuttuu')}
    ),
    {
      kasittelylaitos: new FieldControl(
        Field.of({label: attr('kasittelylaitos'), summaryFn: checkboxSummary, htmlId: 'kasittelylaitos'})
      ),
      lemmikkielaintenRuokiaValmistavaLaitos: new FieldControl(
        Field.of({label: attr('lemmikkielaintenRuokiaValmistavaLaitos'), summaryFn: checkboxSummary, htmlId: 'lemmikkielaintenRuokiaValmistavaLaitos'})
      ),
      hakemuksenLiitteet: liiteArray(attr(hakemuksenLiitteet), hakemuksenLiitteet)
    },
    (group: FieldGroup) => {
      const isKasittelylaitos = group.value['kasittelylaitos'] === true;
      const isLemmikkiRuokaLaitos = group.value['lemmikkielaintenRuokiaValmistavaLaitos'] === true;
      const hasToimintoja = isKasittelylaitos || isLemmikkiRuokaLaitos;

      const liitteet = group.value[hakemuksenLiitteet] || [];

      return hasToimintoja && liitteet.length === 0 ?
        {liitePuuttuu: true} :
        null
    }
  );
}

export const createSivutuoteAsetuksenMukainenRekisterointi = (): FieldGroup => {
  return new FieldGroup(Field.build(attr('sivutuoteRek'), titleSummary), {
    rehusekoittamo: new FieldControl(Field.of({label: attr('rehusekoittamo'), summaryFn: checkboxSummary, htmlId: 'rehusekoittamo'})),
    kerayskeskus: new FieldControl(Field.of({label: attr('kerayskeskus'), summaryFn: checkboxSummary, htmlId: 'kerayskeskus'})),
  });
}


export const createRehunJaToiminnanLisatiedot = (): FieldGroup => {
  return new FieldGroup(Field.build(attr('lisatietojaRehuistaJaToiminnasta'), titleSummary), {
    rehunJaToiminnanVapaamuotoisetLisatiedot: new FieldControl(
      Field.of({
          label: attr('lisatietojaRehuistaJaToiminnasta'),
          htmlId: 'rehunJaToiminnanLisatiedot',
          summaryFn: control => new SummaryItem<any>(control.field.label, control.value, {show: control.value})
        }))
  });
}

export const createLaadunohjaus = (): FieldGroup => {
  return new FieldGroup(
    Field.build(attr('laadunohjausTitle'), titleSummary),
    {
      ReVFeedQSEFMC: new FieldControl(Field.of({label: attr('ReVFeedQSEFMC'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSEFMC'})),
      ReVFeedQSFAMIQS: new FieldControl(Field.of({label: attr('ReVFeedQSFAMIQS'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSFAMIQS'})),
      ReVFeedQSFEDIAF: new FieldControl(Field.of({label: attr('ReVFeedQSFEDIAF'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSFEDIAF'})),
      ReVFeedQSFEDIOL: new FieldControl(Field.of({label: attr('ReVFeedQSFEDIOL'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSFEDIOL'})),
      ReVFeedQSFSSC22000: new FieldControl(Field.of({label: attr('ReVFeedQSFSSC22000'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSFSSC22000'})),
      ReVFeedQSGMP: new FieldControl(Field.of({label: attr('ReVFeedQSGMP'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSGMP'})),
      ReVFeedQSISO22000: new FieldControl(Field.of({label: attr('ReVFeedQSISO22000'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSISO22000'})),
      ReVFeedQSISO9001: new FieldControl(Field.of({label: attr('ReVFeedQSISO9001'), summaryFn: checkboxSummary, htmlId: 'laadunohjausReVFeedQSISO9001'})),
      ReVFeedQSOther: createReVFeedQSOther()
    },
  )
}

const createReVFeedQSOther = () => {

  const checked = new FieldControl(Field.of({htmlId: 'check-ReVFeedQSOther'}));
  const text = new FieldControl(Field.of({htmlId: 'text-ReVFeedQSOther'}), {value: null, disabled: true});

  const group = new FieldGroup(
    Field.build(
      attr('ReVFeedQSOther'),
      laadunohjausGroup => new SummaryItem(laadunohjausGroup.field.label, laadunohjausGroup.get('text').value, {show: laadunohjausGroup.get('checked').value}),
      {detailsNotEmpty: attr('errTaLisatiedot')},
      null,
      null,
      'laadunohjausReVFeedQSOther'
    ), {
      checked: checked,
      text: text
    },
    (laadunohjausInput: FieldGroup) => {
      const val = laadunohjausInput.value;
      if (val !== null && val.checked) {
        return Boolean(val?.text) ?
          null :
          {detailsNotEmpty: true};
      } else {
        return null;
      }
    }
  )

  checked.valueChanges.subscribe(isChecked => {
    if (isChecked) {
      text.enable()
    } else {
      text.disable()
    }
  })

  return group;

}

const createTitleSummary = (showFn: (group: FieldGroup) => boolean): SummaryFnType => {
  return (fieldGroup) => {

    return new SummaryItem<any>(
      fieldGroup.field.label,
      null,
      {show: showFn(fieldGroup.asGroup())},
      TitleSummaryComponent)
  }
}

const titleSummary: SummaryFnType = createTitleSummary(group => Object.values(group.controlFields).some(c => c.summary.show));

const checkboxSummary: SummaryFnType = control => new SummaryItem<any>(
  control.field.label,
  null,
  {show: control.value},
  TranslatedQuestionSummaryComponent
);
