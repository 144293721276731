/**
 * Created by Seppo on 22/11/2017.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild} from "@angular/core";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {LiiteComponent} from "./touko-liite.component";
import {PvmComponent} from "../../../touko-inputs/touko-pvm";
import {DateObj} from "../syote-utils";

/**
 * Liitetiedoston lataus-komponentti. Lataa liitteen palvelimelle ja tuottaa latauslinkin liitteelle.
 * Asettaa lomakkeelle ladatun liitteen id:n, tiedoston tiedot, sekä latauslinkin.
 * @deprecated uudet lomakkeet käyttävät LiitePvmComponentV2 luokkaa
 */
@Component({
  selector: 'touko-liite-pvm',
  template: `

    <fieldset>
      <legend *ngIf="liiteFieldsetLegend" class="h4" [attribute]="liiteFieldsetLegend"></legend>
      <div class="col-12 px-0">
        <touko-radio-input
          [htmlId]="itemLiiteAttr"
          [labelAttribute]="itemLiiteAttr"
          [checked]="fieldValue.isLiiteSelected"
          (change)="setRadio(true)"></touko-radio-input>
      </div>
      <div class="col-10 mb-3 pl-5 pr-0" [hidden]="!fieldValue.isLiiteSelected">
          <touko-liite [labelledBy]="'label-' + itemLiiteAttr" [key]="formControlName" [htmlId]="formControlName"
                       (liiteOut)="onChange($event, 'liite')" [maxLiiteLkm]="maxLiiteLkm" [selite]="selite"
                       #liite></touko-liite>
      </div>
      <div class="col-10 pl-0 pr-2">
        <div class="d-flex justify-content-between align-items-center">
          <touko-radio-input
              [htmlId]="itemPvmAttr"
              [labelAttribute]="itemPvmAttr"
              [checked]="fieldValue.isLiiteSelected === false"
              (change)="setRadio(false)"></touko-radio-input>
          <touko-pvm [inputDisabled]="fieldValue.isLiiteSelected !== false"
                     [ariaLabel]="itemPvmAttr" [htmlId]="formControlName"
                     (valueChange)="onChange($event, 'pvm')" #pvm></touko-pvm>
        </div>
      </div>
    </fieldset>

  `,
  providers: [
    LiitetiedostoPalvelinService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LiitePvmComponent),
    }
  ]
})
export class LiitePvmComponent implements ControlValueAccessor {

  @Input() formControlName: string;
  @Input() groupName: string;
  @Input() itemLiiteAttr: string;
  @Input() itemPvmAttr: string;
  @Input() liiteFieldsetLegend: string;
  @Input() selite: string;
  @Input() maxLiiteLkm = 1;
  @Output() liiteOut: EventEmitter<{ liitteet: LiitetiedostoResponse[]; updateLomake: boolean; key: string }> = new EventEmitter();

  @ViewChild('liite', { static: true }) liite: LiiteComponent;
  @ViewChild('pvm', { static: true }) pvm: PvmComponent;


  fieldValue: {
    isLiiteSelected: boolean;
    liite: LiitetiedostoResponse[];
    pvm: DateObj;
  } = {isLiiteSelected: null, liite: [], pvm: null};

  constructor(private _renderer: Renderer2,
              private _elementRef: ElementRef) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  onChange(event, field) {
    const isLiite = field === 'liite';


    if (isLiite) {
      this.fieldValue.liite = event.liitteet;
      this.liiteOut.emit(event);
    }

    this.setRadio(isLiite);
  }

  setRadio(isLiite) {
    const valueToWrite = {
      liite: this.fieldValue.liite,
      pvm: isLiite ? null : this.fieldValue.pvm,
      isLiiteSelected: isLiite
    };
    if (!isLiite) {
      if (valueToWrite.pvm === null) {
        valueToWrite.pvm = this.pvm.selectToday();
      }
    }


    this.writeValue(valueToWrite, false);
  }

  writeValue(value, update = true): void {
    if (typeof value === 'undefined') {
      return;
    }
    this.fieldValue = value !== null ? value : this.getEmptyValue();

    if (value !== null && update) {
      if (this.fieldValue.isLiiteSelected) {
        this.liite.writeValue(this.fieldValue.liite);
      } else {
        this.pvm.writeValue(this.fieldValue.pvm);
      }
    }

    this._onChange(this.fieldValue);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  getEmptyValue() {
    return {isLiiteSelected: null, liite: [], pvm: null};
  }

}
