<touko-lomake (onSivuChanged)="handleLeavePerustiedotSivu($event)"
              *ngIf="lomake"
              [cancelFn]="cancelLomake"
              [formGroup]="lomake"
              [otsikko]="lomakeInfo.lomakeTitle"
              [saveFn]="saveLomake"
              [saveable]="isPerustiedotValid"
              [submitFn]="submitLomake"
              [tunnus]="lomakeMetaData.id">


  <sivu [attribuuttiAvain]="'lomakeYleinen.perustiedot'"
        [ohjeet]="lomakeInfo.ohjeet['sivu0']"
        [virheGroups]="['perustiedot', 'yhteyshenkilot']" sivu="ref0"
        sivuvirhekasittely>
    <perustiedot [lomake]="lomake"></perustiedot>
    <laskutustiedot [lomake]="lomake"></laskutustiedot>
    <yhteyshenkilot [lomake]="lomake" [rooliValues]="lomakeInfo.yhteyshenkiloRoolit"></yhteyshenkilot>

    <fieldset class="mb-2" formGroupName="toiminnanAloitus">
      <legend [attribute]="lomake.get('toiminnanAloitus').field.label"></legend>
      <date [label]="lomake.get('toiminnanAloitus.toiminnanAloitus').field.label"
            formControlName="toiminnanAloitus"
            htmlIdSuffix="toiminnanAloitus"></date>
      <validation-error [field]="lomake.get('toiminnanAloitus.toiminnanAloitus')"></validation-error>
    </fieldset>
    <fieldset class="mt-6 mb-2">
      <legend [attribute]="'lomakeYleinen.titleSahkAsiointi'"></legend>
      <p attribute="lomakeYleinen.infoSahkAsiointi"></p>
      <touko-checkbox checked="false"
                      class="strong"
                      [formControl]="lomake.get('sahkoinenAsiointi.sahkoinenAsiointi').asControl()"
                      htmlId="sahkoinenAsiointi"
                      labelAttribute="lomakeYleinen.checkSahkAsiointi">
      </touko-checkbox>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuToiminta'" [selectable]="isPerustiedotValid"
        [ohjeet]="lomakeInfo.ohjeet['sivu1']">

    <fieldset>
      <legend attribute="kasvinterveysrekisteri.toimOtsikko">Toiminta</legend>
      <div class="form-group" formGroupName="toiminta">
        <p [markdown]="true" attribute="kasvinterveysrekisteri.toimSelite">Valitse toimintaasi parhaiten
          kuvaavat
          vaihtoehdot.</p>
        <div class="form-row">
          <toiminta-card-v2 *ngFor="let item of toiminnat" [formControl]="item.asControl()"
                            [otsikko]="item.field.label" [toimintaDetails]="item.field.options"
                            [hasToimintaValues] = "checkSomeToimintaValuesNotNull(this.toimintaMap[item.field.label.split('.')[1]])"
                            class="col-md-4 mb-4"></toiminta-card-v2>
        </div>
      </div>
    </fieldset>
  </sivu>

  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuTuotanto'" [selectable]="isPerustiedotValid"
        [ohjeet]="lomakeInfo.ohjeet['sivu2']">
    <toiminta-alert
        *ngIf="!lomake.get('toiminta').value.toimAlaPuutarhaKasvi &&
               !lomake.get('toiminta').value.toimAlaPuutarhaTarha &&
               !lomake.get('toiminta').value.toimAlaPuuISPM &&
               !lomake.get('toiminta').value.toimAlaPerunaViljely">
      <p [markdown]="true" attribute="kasvinterveysrekisteri.sivuTuotantoAlert">
        Mikäli toimipaikkasi harjoittaa kasvien tai taimien tuotantoa, valitse Toiminta-välilehdeltä
        <em>Taimitarhat</em> tai <em>Kasvihuoneviljelmät</em>.
      </p>
    </toiminta-alert>

    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaTarha" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tuoTaimiOtsikko">Tuotanto taimitarhoissa</legend>
      <p attribute="kasvinterveysrekisteri.tuoTaimiKysymys">Merkitse toimintaan kuuluvien taimien tuotanto.</p>

      <div formGroupName="tuoPuuvart">
        <touko-checkbox [formControl]="tuoPuuvart[0].asControl()"
                        [htmlId]="tuoPuuvart[0].field.htmlId"
                        [labelAttribute]="tuoPuuvart[0].field.label">
        </touko-checkbox>
        <div *ngIf="lomake.get('tuoPuuvart.tuoPuuvart').value" class="mb-4">
          <p [markdown]="true" attribute="kasvinterveysrekisteri.tuoPuuvartSelite">Merkitse puuvartiset
            kasvit</p>
          <table class="table table-sm">
            <th></th>
            <tbody>
            <tr *ngFor="let row of tuoPuuvart | slice:1" [formGroup]="row.asGroup()"
                [question]="row.field.label"
                checkbox-table-row-v2>
            </tr>
            </tbody>
          </table>
          <validation-error [field]="lomake.get('tuoPuuvart')"></validation-error>
        </div>
      </div>
      <div formGroupName="tuoMarjaVarm">
        <touko-checkbox [formControl]="tuoMarjaVarm[0].asControl()"
                        [htmlId]="tuoMarjaVarm[0].field.htmlId"
                        [labelAttribute]="tuoMarjaVarm[0].field.label">
        </touko-checkbox>
        <div *ngIf="lomake.get('tuoMarjaVarm.tuoMarjaVarm').value" class="mb-4">
          <p [markdown]="true" attribute="kasvinterveysrekisteri.tuoMarjaVarmKysymys">Merkitse marjakasvien
            varmennetut taimet</p>
          <table class="table table-sm">
            <th></th>
            <tbody>
            <tr *ngFor="let row of tuoMarjaVarm | slice:1"
                [formGroup]="row.asGroup()"
                [question]="row.field.label"
                checkbox-table-row-v2>
            </tr>
            </tbody>
          </table>
          <validation-error [field]="lomake.get('tuoMarjaVarm')"></validation-error>

        </div>
      </div>
      <div formGroupName="tuoMarjaCAC">
        <touko-checkbox [formControl]="tuoMarjaCAC[0].asControl()"
                        [htmlId]="tuoMarjaCAC[0].field.htmlId"
                        [labelAttribute]="tuoMarjaCAC[0].field.label">
        </touko-checkbox>
        <div *ngIf="lomake.get('tuoMarjaCAC.tuoMarjaCAC').value" class="mb-4">
          <p [markdown]="true" attribute="kasvinterveysrekisteri.tuoMarjaCACKysymys">Merkitse marjakasvien
            CAC-luokan taimet</p>
          <table class="table table-sm">
            <th></th>
            <tbody>
            <tr *ngFor="let row of tuoMarjaCAC | slice:1"
                [formGroup]="row.asGroup()"
                [question]="row.field.label"
                checkbox-table-row-v2>
            </tr>
            </tbody>
          </table>
          <validation-error [field]="lomake.get('tuoMarjaCAC')"></validation-error>
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaKasvi" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tuoKasviOtsikko">Tuotanto kasvihuoneviljelmillä</legend>
      <p [markdown]="true" attribute="kasvinterveysrekisteri.tuoKasviKysymys">Merkitse tuottamasi kasvien taimet
        jatkokasvatukseen ja myyntiin</p>
      <div formGroupName="tuoKasvi">
        <table class="table table-sm">
          <th></th>
          <tbody>
          <tr *ngFor="let row of tuoKasvi"
              [formGroup]="row.asGroup()"
              [question]="row.field.label"
              checkbox-table-row-v2>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4" formGroupName="tuoVihannesTaimi">
        <p attribute="kasvinterveysrekisteri.tuoVihannesTaimiKysymys">Merkitse kasvihuoneessa tuottamasi
          vihannekset tai
          taimet, joita jatkokasvatetaan toisilla ammattiviljelmillä</p>
        <touko-checkbox [formControl]="tuoVihannesTaimi[0].asControl()"
                        [htmlId]="tuoVihannesTaimi[0].field.htmlId"
                        [labelAttribute]="tuoVihannesTaimi[0].field.label"></touko-checkbox>

        <ng-container *ngIf="lomake.get('tuoVihannesTaimi.tuoVihannesTaimi').value">
          <div class="mb-4 ml-4">
            <touko-checkbox *ngFor="let item of tuoVihannesTaimi | slice:1"
                            [formControl]="item.asControl()"
                            [htmlId]="item.field.htmlId"
                            [labelAttribute]="item.field.label"
                            [labelDescrAttribute]="item.field.label + 'Lat'"
                            [labelDescrPositionInline]="true"></touko-checkbox>
            <validation-error [field]="lomake.get('tuoVihannesTaimi')"></validation-error>
          </div>
        </ng-container>
      </div>
      <div formGroupName="tuoVihannesYksityiskaytto">
        <touko-checkbox [formControl]="tuoVihannesYksityiskaytto.asControl()"
                        htmlId="tuoVihannesYksityiskaytto"
                        labelAttribute="kasvinterveysrekisteri.tuoVihannesYksityiskaytto"
                        labelDescrAttribute="kasvinterveysrekisteri.tuoVihannesYksityiskayttoSelite"></touko-checkbox>
      </div>
      <div class="mt-4" formGroupName="tuoVihannesValmis">
        <touko-checkbox [formControl]="tuoVihannesValmis[0].asControl()"
                        [htmlId]="tuoVihannesValmis[0].field.htmlId"
                        [labelAttribute]="tuoVihannesValmis[0].field.label"></touko-checkbox>

        <ng-container *ngIf="lomake.get('tuoVihannesValmis.tuoVihannesValmis').value">
          <div class="mb-4 ml-4">
            <touko-checkbox *ngFor="let item of tuoVihannesValmis | slice:1"
                            [formControl]="item.asControl()"
                            [htmlId]="item.field.htmlId"
                            [labelAttribute]="item.field.label"></touko-checkbox>
            <validation-error [field]="lomake.get('tuoVihannesValmis')"></validation-error>
          </div>
        </ng-container>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPerunaViljely" class="mb-4">
      <legend>Perunan viljely</legend>
      <p attribute="kasvinterveysrekisteri.tuoPerunaViljelyKysymys">Merkitse toimintaan kuuluvat
        perunantuotantovaihtoehdot</p>
      <div class="form-group" formGroupName="tuoPerunaViljely">
        <touko-checkbox *ngFor="let item of tuoPerunaViljely"
                        [formControl]="item.asControl()"
                        [htmlId]="item.field.htmlId"
                        [labelAttribute]="item.field.label"></touko-checkbox>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuuISPM" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tuoISPM15Otsikko">Puutavaran ISPM 15 -toimijat</legend>
      <div class="form-group">
        <div formGroupName="tuoISPM15Kasittely">
          <touko-checkbox [formControl]="tuoISPM15Kasittely.asControl()"
                          [htmlId]="tuoISPM15Kasittely.field.htmlId"
                          [labelAttribute]="tuoISPM15Kasittely.field.label"></touko-checkbox>
          <div *ngIf="lomake.get('tuoISPM15Kasittely.tuoISPM15Kasittely').value" class="mb-4">
            <label attribute="kasvinterveysrekisteri.tuoISPM15KasittelyLiite"
                   id="tuoISPM15KasittelyLiite">Lataa
              käsittelydokumentit (esim. kuivauskäyrät)</label>
            <liite-list (liiteOut)="onSetLiitteet($event, 'liitteet')"
                        [liitteetArray]="lomake.get('tuoISPM15Kasittely.tuoISPM15KasittelyLiite').asArray()"
                        [lomakeId]="lomakeMetaData.id"
                        [maxLiiteLkm]="10" formArrayName="tuoISPM15KasittelyLiite"
                        htmlId="input-tuoISPM15KasittelyLiite"
                        labelledBy="tuoISPM15KasittelyLiite"
                        selite="ISPM15_KASITTELY"></liite-list>
          </div>
        </div>

        <div formGroupName="tuoISPM15Valmistus">
          <touko-checkbox [formControl]="tuoISPM15Valmistus.asControl()"
                          [htmlId]="tuoISPM15Valmistus.field.htmlId"
                          [labelAttribute]="tuoISPM15Valmistus.field.label"></touko-checkbox>
          <div *ngIf="lomake.get('tuoISPM15Valmistus.tuoISPM15Valmistus').value">
            <div class="block mb-3">
              <label attribute="kasvinterveysrekisteri.tuoISPM15ValmistusAlkuperaLiite"
                     id="tuoISPM15ValmistusLiite">Lataa
                käsittelydokumentit (esim. kuivauskäyrät)</label>
              <liite-list (liiteOut)="onSetLiitteet($event, 'liitteet')"
                          [liitteetArray]="lomake.get('tuoISPM15Valmistus.tuoISPM15ValmistusAlkuperaLiite').asArray()"
                          [lomakeId]="lomakeMetaData.id"
                          [maxLiiteLkm]="10" formArrayName="tuoISPM15ValmistusAlkuperaLiite"
                          htmlId="input-tuoISPM15ValmistusAlkuperaLiite"
                          labelledBy="tuoISPM15ValmistusAlkuperaLiite"
                          selite="ISPM15_PUUN_ALKUPERA"></liite-list>
            </div>
            <div class="block">
              <label attribute="kasvinterveysrekisteri.tuoISPM15ValmistusLeimausLiite"
                     id="tuoISPM15ValmistusLeimausLiite">Lataa
                kuvat leimausjäljistä (kaikki käytetyt leimasimet)</label>
              <liite-list (liiteOut)="onSetLiitteet($event, 'liitteet')"
                          [liitteetArray]="lomake.get('tuoISPM15Valmistus.tuoISPM15ValmistusLeimausLiite').asArray()"
                          [lomakeId]="lomakeMetaData.id"
                          [maxLiiteLkm]="10" formArrayName="tuoISPM15ValmistusLeimausLiite"
                          htmlId="input-tuoISPM15ValmistusLeimausLiite"
                          labelledBy="tuoISPM15ValmistusLeimausLiite"
                          selite="ISPM15_LEIMAT"></liite-list>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </sivu>
  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuMyynti'" [selectable]="isPerustiedotValid"
        [ohjeet]="lomakeInfo.ohjeet['sivu3']">

    <toiminta-alert
        *ngIf="!lomake.get('toiminta').value.toimAlaPuutarhaMyymala &&
               !lomake.get('toiminta').value.toimAlaPuutarhaTukku &&
               !lomake.get('toiminta').value.toimAlaPuutarhaTarha &&
               !lomake.get('toiminta').value.toimAlaPuutarhaKasvi &&
               !lomake.get('toiminta').value.toimAlaPuutarhaViherrak &&
               !lomake.get('toiminta').value.toimAlaPuuToimitus">
      <p [markdown]="true" attribute="kasvinterveysrekisteri.sivuMyyntiAlert">
        Mikäli toimipaikkasi harjoittaa myyntiä tai välitystä, valitse Toiminta-välilehdeltä <em>Taimimyymälät</em>,
        <em>Kukka- ja puutarhatukut</em> tai <em>Viherrakentaminen</em>.
      </p>
    </toiminta-alert>

    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaMyymala " class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyTaimiOtsikko">Taimimyymälätoiminta</legend>
      <div class="form-group" formGroupName="myyTaimimyymala">
        <p [markdown]="true" attribute="kasvinterveysrekisteri.myyTaimiKysymys">Merkitse vähittäisasiakkaille myytävät
          kasvit</p>
        <div *ngFor="let item of myyTaimimyymala" [ngClass]="{'ml-5 pl-1': item.field.options?.indent}">
          <touko-checkbox [formControl]="item.asControl()" [htmlId]="item.field.htmlId"
                          [labelAttribute]="item.field.label"></touko-checkbox>
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaTukku " class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyTaimiValitysOtsikko">Taimien välitys</legend>
      <p [markdown]="true" attribute="kasvinterveysrekisteri.myyTaimiValitysKysymys">
        Merkitse myymäsi kasvien taimet, jotka ovat tarkoitettu jatkokasvatukseen tai myyntiin.
      </p>

      <div class="form-group" formGroupName="myyTaimiValitysKasvi">
        <label attribute="kasvinterveysrekisteri.myyKasvihuonekasvitAlaotsikko" class="text-muted">
          Kasvihuonekasvit
        </label>
        <table class="table table-sm">
          <th></th>
          <tbody>
          <tr *ngFor="let item of myyTaimiValitysKasvi" [formGroup]="item.asGroup()"
              [question]="item.field.label"
              checkbox-table-row-v2>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group" formGroupName="myyTaimiValitysAvo">
        <label attribute="kasvinterveysrekisteri.myyTaimitarhakasvitAlaotsikko" class="text-muted">
          Taimitarhakasvit
        </label>
        <table class="table table-sm">
          <th></th>
          <tbody>
          <tr *ngFor="let item of myyTaimiValitysAvo" [formGroup]="item.asGroup()"
              [question]="item.field.label"
              checkbox-table-row-v2>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group mt-4" formGroupName="myyTaimiValitysSiemen">
        <p attribute="kasvinterveysrekisteri.myyTaimiValitysSiemenKysymys">
          Merkitse muualla kuin omalla viljelmällänne tuotetut siemenet.
        </p>
        <touko-checkbox *ngFor="let item of myyTaimiValitysSiemen" [formControl]="item.asControl()"
                        [htmlId]="item.field.htmlId" [labelAttribute]="item.field.label"></touko-checkbox>
      </div>
      <div class="form-group" formGroupName="myyTaimiValitysKasvipassi">
        <touko-checkbox [formControl]="myyTaimiValitysKasvipassi.asControl()"
                        [htmlId]="myyTaimiValitysKasvipassi.field.htmlId"
                        [labelAttribute]="myyTaimiValitysKasvipassi.field.label"></touko-checkbox>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaViherrak" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyViherOtsikko">Viherrakentaminen</legend>
      <div class="form-group" formGroupName="myyViherrakentaminen">
        <touko-checkbox [formControl]="myyViherrakentaminen.asControl()"
                        [htmlId]="myyViherrakentaminen.field.htmlId"
                        [labelAttribute]="myyViherrakentaminen.field.label"></touko-checkbox>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaMyymala ||
                     lomake.get('toiminta').value.toimAlaPuutarhaTarha ||
                     lomake.get('toiminta').value.toimAlaPuutarhaKasvi"
              class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyEtamyyntiOtsikko">Etämyynti</legend>
      <div class="form-group" formGroupName="myyEtamyynti">
        <p attribute="kasvinterveysrekisteri.myyEtamyyntiKysymys">Etämyynnillä tarkoitetaan toimintaa, jossa ostaja
          tilaa kasvit esimerkiksi verkkokaupasta, postimyyntiluettelon tilauslomakkeella tms. ja ne toimitetaan hänelle
          kuljetusliikkeen (esim. posti, matkahuolto) välityksellä.</p>
        <div *ngFor="let item of myyEtamyynti">
          <ng-container *ngIf="lomake.get('toiminta').value[item.field.options.toimintaId]">
            <touko-checkbox [formControl]="item.asControl()" [htmlId]="item.field.htmlId"
                            [labelAttribute]="item.field.label"></touko-checkbox>
          </ng-container>
        </div>
      </div>
      <div class="mb-2" formGroupName="verkkokaupat">
        <verkkokaupat-list></verkkokaupat-list>
      </div>
      <div class="block">
        <label attribute="kasvinterveysrekisteri.myyEtamyyntiLiite" class="mt-3"
               id="myyEtamyyntiLiite">
          Lataa etämyyntiliite</label>
        <liite-list (liiteOut)="onSetLiitteet($event, 'liitteet')"
                    [liitteetArray]="lomake.get('myyEtamyynti.myyEtamyyntiLiite').asArray()"
                    [lomakeId]="lomakeMetaData.id"
                    [maxLiiteLkm]="10"
                    htmlId="input-myyEtamyyntiLiite"
                    labelledBy="myyEtamyyntiLiite"
                    selite="ETAMYYNTI"></liite-list>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuuToimitus" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.myyPuutavaraKasvipassi">Kasvipassia vaativan puutavaran
        toimitus
      </legend>
      <div class="form-group" formGroupName="myyPuutavaraKasvipassi">
        <p [markdown]="true" attribute="kasvinterveysrekisteri.myyPuutavaraKasvipassiSelite">Seuraavat toiminnat
          edellyttävät kasvipassin käyttöoikeutta:</p>
        <touko-checkbox [formControl]="myyPuutavaraKasvipassi.asControl()"
                        [labelAttribute]="myyPuutavaraKasvipassi.field.label"
                        htmlId="myyPuutavaraKasvipassi"></touko-checkbox>
      </div>
    </fieldset>
  </sivu>
  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuTuontiVienti'" [selectable]="isPerustiedotValid"
        [ohjeet]="lomakeInfo.ohjeet['sivu4']">
    <fieldset *ngIf="!lomake.get('toiminta').value.toimAlaPuutarhaKasvitiet" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tvOtsikko">Maahantuonti ja maastavienti</legend>
      <div formGroupName="tuontiJaVienti">
        <p attribute="kasvinterveysrekisteri.tvKysymys">Merkitse vaihtoehto, mikäli toimipaikkasi maahantuo tai
          maastavie jotain seuraavista. Merkitse ainoastaan EU:n ulkopuolinen toiminta.</p>
        <table class="table table-sm">
          <th></th>
          <tbody>
          <tr *ngFor="let row of tuontiJaVienti" [formGroup]="row.asGroup()"
              [question]="row.field.label" checkbox-table-row-v2>
          </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
    <fieldset *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaKasvitiet" class="mb-4">
      <legend attribute="kasvinterveysrekisteri.tvKasvitietPuutarhaOtsikko">Kasvitieteelliset puutarhat</legend>
      <div class="form-group" formGroupName="kasvitietPuutarha">
        <touko-checkbox [formControl]="kasvitietPuutarha.asControl()"
                        [htmlId]="kasvitietPuutarha.field.htmlId"
                        [labelAttribute]="kasvitietPuutarha.field.label"></touko-checkbox>
      </div>
    </fieldset>
  </sivu>
  <sivu [attribuuttiAvain]="'kasvinterveysrekisteri.sivuOmavalvonta'" [selectable]="isPerustiedotValid"
        [ohjeet]="lomakeInfo.ohjeet['sivu5']">
    <toiminta-alert
        *ngIf="!lomake.get('toiminta').value.toimAlaPuutarhaKasvi && !lomake.get('toiminta').value.toimAlaPuutarhaTarha && !lomake.get('toiminta').value.toimAlaPuutarhaTukku">
      <p attribute="kasvinterveysrekisteri.sivuOmavalvontaAlert" [markdown]="true">Mikäli toimipaikkasi harjoittaa
        omavalvontaa, valitse Toiminta-välilehdeltä "Taimitarhat" tai "Kasvihuoneviljelmät"</p>
    </toiminta-alert>

    <fieldset
        *ngIf="lomake.get('toiminta').value.toimAlaPuutarhaKasvi || lomake.get('toiminta').value.toimAlaPuutarhaTarha || lomake.get('toiminta').value.toimAlaPuutarhaTukku || lomake.get(['omavalvonta', 'omavalvonta'])?.value?.length > 0">
      <legend attribute="kasvinterveysrekisteri.omavalvonta">Omavalvontasuunnitelma</legend>
      <div class="form-group" formGroupName="omavalvonta">
        <p attribute="kasvinterveysrekisteri.omavalvontaKysymys" [markdown]="true">Mikäli yritykselläsi on voimassaoleva
          omavalvontasuunnitelma, lataa se tästä. Omavalvontasuunnitelma tulee päivittää vuosittain.</p>

        <label id="liite-omavalvonta" attribute="kasvinterveysrekisteri.omavalvontaLataa">Lataa
          omavalvontasuunnitelma</label>
        <liite-list (liiteOut)="onSetLiitteet($event, 'liite-omavalvonta')"
                    [liitteetArray]="lomake.get('omavalvonta.omavalvontaLiite').asArray()"
                    [lomakeId]="lomakeMetaData.id"
                    [maxLiiteLkm]="10"
                    htmlId="input-omavalvontaLiite"
                    labelledBy="omavalvontaLiite"
                    selite="OMAVALVONTA"></liite-list>
      </div>
    </fieldset>
  </sivu>
  <sivu [attribuuttiAvain]="'teksti.sivuYhteenveto'" [selectable]="isPerustiedotValid">
    <div class="row">
      <div #lomakeYhteenvetoDiv class="col-md-9">
        <summary-allekirjoitus [allekirjoitus]="lomakeMetaData.allekirjoitus"></summary-allekirjoitus>
        <app-field-group-summary [fieldGroup]="lomake" class="summary"></app-field-group-summary>
      </div>
      <div *ngIf="lomake.value?.perustiedot?.osasto" class="col-md-3">
        <kasvinterveys-rekisterit #rekisteritCard
                                  [asiointitunnus]="lomakeMetaData.allekirjoitus?.ytunnus"
                                  [formValues]="lomake.value"
                                  [toimintotyyppi]="lomakeInfo.toimintotyyppi"
                                  [valvontakohde]="lomakeMetaData.allekirjoitus?.valvontakohde"></kasvinterveys-rekisterit>
      </div>
      <div *ngIf="lomakeMetaData.isAsiakas">
        <hr>
        <button attribute="teksti.laheta" class="mt-4 btn btn-primary" id="laheta" type="submit">Lähetä</button>
      </div>
    </div>
    <div class="col-md-9 col-12 px-0 mt-4">
      <touko-alert-box>
        <div [attribute]="'kasvinterveysrekisteri.muutosilmoitusInfo'"></div>
      </touko-alert-box>
    </div>
  </sivu>
</touko-lomake>

<pre *devFeature>{{ lomake?.getRawValue() | json }}</pre>
