import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from "@angular/core";
import {SivuComponent} from "./touko-sivu.component";
import {FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Teksti} from "../utils/teksti";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MessageService} from "../message/message.service";
import {LoaderService} from "../loader/loader.service";
import {ModalData} from "../modaalit/modal-utils";
import {ModalSimpleComponent} from "../modaalit/lomake/modal-simple.component";
import {LomakeRedirectService} from "./lomake-redirect.service";

/**
 * Created by Seppo on 15/11/2017.
 */

/**
 * Lomakkeen esittämistä helpottava komponentti
 *
 * Jokaisen palvelun tulisi käyttää komponenttia lomakkeen muodostamiseen.
 * Hakee <sivu>-komponentit ja muodostaa näiden perusteella lomakkeen sivutuksen.
 *
 * Esimerkki komponentin käytöstä hakemistossa `esimerkki-lomake`
 */
@Component({
  selector: 'touko-lomake',
  styleUrls: ['./touko-lomake.component.scss'],
  template: `
    <h1 class="d-print-none" [attribute]="otsikko.fullKey"></h1>
    <span class="tunnus-position" id="lomakeTunnus">{{ tunnus }}</span>

    <ol class="nav nav-tabs d-print-none">
      <li class="nav-item" *ngFor="let tab of tabs; let i = index">
        <button [disabled]="!tabInfo[i].selectable"
                id="sivu-{{tab}}" class="nav-link btn-sm"
                [ngClass]="{'active': i === sivu, 'error': getSivunVirheet(i)}"
                (click)="valitseSivu(i)">{{ i + 1 }}.
          <span [attribute]="tab"></span>
        </button>
      </li>
    </ol>

    <form [formGroup]="formGroup" novalidate (submit)="submit()" class="mb-4">
      <ng-content></ng-content>
    </form>

    <div class="d-flex justify-content-between d-print-none">
      <div>
        <button [disabled]="!tabInfo[sivu-1]?.selectable" id="btn-edellinen" class="btn btn-outline-secondary"
                [ngClass]="{'disabled': sivu <= 0}"
                (click)="valitseSivu(sivu-1)">
          <em class="fa fa-arrow-circle-left"></em>
          <span attribute="teksti.edellinen">Edellinen</span>
        </button>
      </div>
      <div>
        <button id="btn-keskeyta" type="button" class="btn btn-outline-primary mr-1" (click)="cancelForm()">
          <em class="fa fa-times"></em>
          <span attribute="teksti.keskeyta">Keskeytä</span>
        </button>
        <button *ngIf="showSave" [disabled]="!saveable" id="btn-tallenna" type="button"
                class="btn btn-outline-success mr-1"
                [clickAsync]="saveFn">
          <em class="fa fa-save"></em>
          <span attribute="teksti.tallennaKeskeneraisena">Tallenna keskeneräisenä</span>
        </button>
        <button [disabled]="!tabInfo[sivu+1]?.selectable" id="btn-seuraava" class="btn btn-secondary"
                [hidden]="sivu >= tabs.length-1"
                [ngClass]="{'disabled': sivu >= tabs.length-1}"
                (click)="valitseSivu(sivu+1)">
          <span attribute="teksti.seuraava">Seuraava</span>
          <em class="fa fa-arrow-circle-right"></em>
        </button>
      </div>
    </div>

    <div class="row d-print-none">
      <ng-container *ngFor="let v of virheita">
        <div class="col-lg-12" *ngIf="v.sivu !== sivu">
          <br>
          <button class="btn btn-danger" (click)="valitseSivu(v.sivu)">
            <span attribute="teksti.virheitaSivulla">Virheitä sivulla {{ v.otsikko }}</span>:
            <strong>
              <span [attribute]="v.otsikko"></span>
            </strong>
          </button>
        </div>
      </ng-container>
    </div>

    <siirry-tarkastukseen [saveFn]="saveFn"></siirry-tarkastukseen>
  `,
  styles: ['.tunnus-position {position: absolute; top: -50rem}']
})
export class ToukoLomakeComponent implements AfterContentChecked, AfterViewInit, OnInit {
  @ContentChildren(SivuComponent) childComponents: QueryList<SivuComponent>;

  tabs = [];
  tabInfo = new Map<number, SivuComponent>();
  virheita = new Set<{ sivu: number; otsikko: string }>();
  sivu = 0; // hae TAI tuo sivunumero URLista?
  private showMsg = true;

  @Input() saveable = false;
  @Input() showSave = true;

  /** Lomakkeen tunnus */
  @Input() tunnus: number;

  /** Lomakkeen group */
  @Input() formGroup: FormGroup;

  /** Lomakkeen otsikko */
  @Input() otsikko: Teksti;

  /** Lomakkeeseen liitettävä submit funktio */
  @Input() submitFn: () => Promise<any>;

  /** Lomakkeeseen liitettävä tallennusfunktio */
  @Input() saveFn: () => Promise<any>;

  /** Lomakkeeseen liitettävä keskeytysfunktio */
  @Input() cancelFn: () => Promise<boolean>;

  @Output() onSivuChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output() onLastPage: EventEmitter<void> = new EventEmitter();

  constructor(private readonly modalService: NgbModal,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly loaderService: LoaderService,
              private readonly messageService: MessageService,
              private readonly lomakeRedirectService: LomakeRedirectService) {
  }

  ngOnInit(): void {
    this.siirryTarkastukseen = this.siirryTarkastukseen.bind(this);
  }

  /**
   * Tarvitaan yhteenvedon päivittämiseen, kun tullaan suoralla osoitteella yhteenvetoon.
   */
  ngAfterViewInit(): void {
    Promise.resolve()
        .then(() => this.onSivuChanged.emit(-1));
  }

  /**
   * Hakee sivut, sekä asettaa näille id ja ref-muuttujiin arvot. Jos sivu ei ole piilotettu,
   * se lisätään tabs-listaan, joka näyttää sivut lomakkeen yläpuolella.
   *
   * Funktio suoritetaan aina, kun komponentin sisältö tarkistetaan.
   */
  ngAfterContentChecked(): void {
    this.virheita.clear();
    this.tabs = [];

    this.childComponents.forEach((item, index) => {

      this.tabs.push(item.attribuuttiAvain);
      item.id = index;
      item.valittu = this.sivu;
      item.ref = `ref${index}`;
      this.tabInfo[item.id] = item;
      if (item.virheita && !this.formGroup.valid) {
        this.virheita.add({sivu: item.id, otsikko: item.attribuuttiAvain});
      }
    });

    if (this.showMsg) {
      this.showMsg = false;
      this.activatedRoute.params.subscribe((params: Params) => {
        const pageNumStr = params['page'];
        if (/^\d+$/.test(pageNumStr)) {
          const pageNum = parseInt(pageNumStr, 10);
          if (0 <= pageNum && pageNum < this.tabs.length) {
            this.sivu = pageNum;
            return;
          }
        }
        this.messageService.notify({message: "Sivua ei löytynyt", type: "warning"});
      });
    }

  }

  /**
   * Valitsee sivun
   *
   * @param sivu - uusi sivu
   */
  valitseSivu(sivu: number) {
    this.onSivuChanged.emit(this.sivu);
    if (sivu < 0 || sivu >= this.tabs.length) {
      return;
    } else if (sivu == this.tabs.length - 1) {
      this.onLastPage.emit();
    }
    this.sivu = sivu;
    this.router.navigate(['..', this.sivu], {relativeTo: this.activatedRoute});
    window.scrollTo(0, 0);
  }

  /**
   * Hakee tiedon, onko pyydetyllä sivulla virheitä
   *
   * @param sivu - jolta virheitä haetaan
   * @returns - onko sivulla virheitä vai ei
   */
  getSivunVirheet(sivu: number) {
    let virheita = false;

    this.virheita.forEach(v => {
      if (v.sivu === sivu) {
        virheita = true;
      }
    });
    return virheita;
  }

  /**
   * Lähettää lomakkeen
   */
  submit() {
    this.loaderService.startLoadingAnimation(new Teksti("lähetetään lomaketta", "loaderLomakeLahetys", "lomakeYleinen"));
    this.submitFn()
        .then(() => this.loaderService.stopLoadingAnimation());
  }

  cancelForm() {
    const modalData = new ModalData(
        new Teksti("Otsikko", "keskeytaAsiointi", "teksti"),
        new Teksti("Kuvaus", "tallentamattomatPoistetaan", "teksti"),
        new Teksti("Keskeytä tallentamatta", "keskeytaTallentamatta", "teksti"),
        this.cancelFn,
        "btn-danger"
    );

    const modalRef = this.modalService.open(ModalSimpleComponent);
    modalRef.componentInstance.modalTiedot = modalData;
  }

  siirryTarkastukseen() {
    const urlSegments = this.router.url.split('/');
    const route = ['/asiointi/lomake/tarkastus']
        .concat(urlSegments.slice(3, 7));

    this.lomakeRedirectService.canRedirect = true;
    return Promise.resolve(this.saveFn()).then(() => this.router.navigate(route));
  }
}
