import {Injectable} from "@angular/core";
import {Teksti} from "../../utils/teksti";
import {HttpClient} from "@angular/common/http";
import {LomakeRequest} from "../touko-lomake-utils";
import {Rekisteri, RekisteriOrOikeus, RekisteriResponse} from "./rekisteri";
import {AccountService} from "../../account/account.service";

@Injectable()
export class RekisteritService {

  private static rekisteriTekstit = {
    kasvinterveysrekisteri: {
      teksti: new Teksti("Kasvinterveysrekisteri", "rekKasvinterveys", "kasvinterveysrekisteri"),
      order: 1,
      type: RekisteriOrOikeus.REKISTERI
    },
    taimiaineistorekisteri: {
      teksti: new Teksti("Taimiaineistorekisteri", "rekTaimiaineisto", "kasvinterveysrekisteri"),
      order: 2,
      type: RekisteriOrOikeus.REKISTERI
    },
    ispm15: {
      teksti: new Teksti("ISPM 15", "rekISPM15", "kasvinterveysrekisteri"),
      order: 3,
      type: RekisteriOrOikeus.OIKEUS
    },
    kasvipassioikeus: {
      teksti: new Teksti("Kasvipassi", "rekKasvipassi", "kasvinterveysrekisteri"),
      order: 4,
      type: RekisteriOrOikeus.OIKEUS
    },
    taimitodistus: {
      teksti: new Teksti("Taimitodistus", "rekTaimitodistus", "kasvinterveysrekisteri"),
      order: 5,
      type: RekisteriOrOikeus.OIKEUS
    },
    varmennettutaimituotanto: {
      teksti: new Teksti("Varmennettu taimituotanto", "rekVarmennettuTaimituotanto", "kasvinterveysrekisteri"),
      order: 6,
      type: RekisteriOrOikeus.OIKEUS
    },
  };

  constructor(private http: HttpClient, private accountService: AccountService) {}

  public getLomakeVirkailijaRekisterit(lomake: LomakeRequest, asiointitunnus: string, valvontakohde: string): Promise<Rekisteri[]> {
    return this.http.post(`api/v1/vk/rekisterit/asiointitunnukset/${asiointitunnus}/valvontakohteet/${valvontakohde}`, lomake)
      .toPromise()
      .then(response => response as RekisteriResponse[])
      .then(response =>
        response.map(r => ({...RekisteritService.rekisteriTekstit[r.rekisteriClientId], status: r.status} as Rekisteri)));
  }

  public getLomakeRekisterit(lomake: LomakeRequest): Promise<Rekisteri[]> {
    const role = this.accountService.isAsiakas() ? "as" : "vk";
    return this.http.post(`/api/v1/${role}/rekisterit`, lomake)
      .toPromise()
      .then(response => response as RekisteriResponse[])
      .then(response =>
        response.map(r => ({...RekisteritService.rekisteriTekstit[r.rekisteriClientId], status: r.status} as Rekisteri))
      );
  }
}
