import {Raportti, RaporttiHaku, SortOrder, Tag} from "./raportti.utils";
import {BehaviorSubject, Observable} from "rxjs/index";
import {Injectable} from "@angular/core";
import {Teksti} from "../utils/teksti";
import {MessageService} from "../message/message.service";

/**
 * Raporttinäkymän logiikka.
 */
@Injectable()
export class RaporttiViewService {
  private raporttiHaku = new RaporttiHaku();  // palvelimelta saatu hakuobjekti
  private previousSortKey;
  private previousSortOrder;

  private _raportit = new BehaviorSubject<Raportti[]>([]);                        // clientissa näytettävät raporti
  private _raporttiLkmSivulla = new BehaviorSubject<number>(20);
  private _sivu = new BehaviorSubject<number>(1);
  private _raportitYht = new BehaviorSubject<number>(0);
  private _sortArrow = new BehaviorSubject<{key: string; order: number}>({key: "kasiteltyAika", order: SortOrder.ASC});

  raportit$: Observable<Raportti[]> = this._raportit.asObservable();
  raporttiLkmSivulla$: Observable<number> = this._raporttiLkmSivulla.asObservable();
  sivu$: Observable<number> = this._sivu.asObservable();
  raportitYht$: Observable<number> = this._raportitYht.asObservable();
  sortArrow$: Observable<{key: string; order: number}> = this._sortArrow.asObservable();

  constructor(private messageService: MessageService) {
  }

  cleanUp(): void {
    this._raportit.next([]);
    this._raporttiLkmSivulla.next(20);
    this._sivu.next(1);
    this._raportitYht.next(0);
  }

  setAdblockDetected() {
    this.messageService.addMessage(new Teksti("Adblock havaittu", "adblock", "teksti"), 1000, "danger");
  }

  showValvontakohdeChangedMsg() {
    this.messageService.addMessage(new Teksti("Toimipaikka valittu", "msgToimipaikkaValittu", "raportit"), MessageService.DEFAULT_TIMEOUT, "success");
  }

  public setRaportit(response: RaporttiHaku) {
    this.raporttiHaku = response;
    this._raportitYht.next(response.raporttiLkm);
    this.sliceRaportit(response.raportit);
  }

  sliceRaportit(raportit: Raportti[]) {
    if (!raportit) {
      return [];
    }
    const lkmSivulla = this._raporttiLkmSivulla.getValue();
    const start = (this._sivu.getValue() - 1) * lkmSivulla;
    const end = start + lkmSivulla;

    this._raportit.next(raportit.slice(start, end));
  }

  selectRaporttiLkm(lkm: number) {
    this._raporttiLkmSivulla.next(lkm);

    if (this._sivu.getValue() >= this.getSivumaara()) {
      this._sivu.next(this.getSivumaara());
    }

    this.sliceRaportit(this.raporttiHaku.raportit);
  }

  selectSivu(sivu: number, updateRaportit = true) {
    this._sivu.next(sivu);

    if (updateRaportit) {
      this.sliceRaportit(this.raporttiHaku.raportit);
    }
  }

  getSivumaara() {
    return Math.floor(this.raporttiHaku.raporttiLkm / this._raporttiLkmSivulla.getValue()) + 1;
  }

  jarjesta(key?: string, sortOrder?: SortOrder) {

    if (!key) {
      key = this.previousSortKey;
    }
    if (!sortOrder) {
      sortOrder = this.previousSortOrder;
    }

    this._sortArrow.next({key, order: sortOrder});

    if (!this.raporttiHaku.raportit) {
      return [];
    }

    const raportit = [...this.raporttiHaku.raportit].sort((r1, r2) => {
      const r1Key = r1[key] !== null ? r1[key] : "";
      const r2Key = r2[key] !== null ? r2[key] : "";

      if (r1Key > r2Key) {
        return sortOrder;
      } else if (r1Key < r2Key) {
        return -1 * sortOrder;
      }
      return 0;
    });

    this.previousSortKey = key;
    this.previousSortOrder = sortOrder;
    this.sliceRaportit(raportit);
  }

  mapRaporttiField(mapFn: (raportti) => Tag): Tag[] {
    const values = [];
    const tags = [];
    this.raporttiHaku.raportit.forEach(r => {
      const tag = mapFn(r);
        if (values.findIndex(v => v === tag.value) < 0) {
          values.push(tag.value);
          tags.push(tag);
        }
      }
    );

    return tags;
  }
}
