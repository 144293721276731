import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ValidatorsBase} from "./validators-base";

// @dynamic
export class AsiakastietoValidators extends ValidatorsBase {

  static readonly EMAIL = 'email';
  static readonly PHONE = 'phone';
  static readonly YTUNNUS = 'ytunnus';
  static readonly ELAINLAAKARITUNNUS = 'eltunnus';
  static readonly MAKSAJA = 'maksaja';
  static readonly POSTINUMERO = 'postinumero';

  static readonly REQUIRED_IF_CHECKED = 'requiredIfChecked';

  private static REGEXP_EMAIL = new RegExp('^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)+$');
  private static REGEXP_PHONE = new RegExp('^\\+?(\\d| ){6,15}$');
  private static REGEXP_YTUNNUS = new RegExp('^\\d{7}-\\d$');
  private static REGEXP_ELAINLAAKARITUNNUS = new RegExp('^\\d{4}$');

  static isValidEmail(control: FormControl) {
    return control.value ?
      AsiakastietoValidators.validate(AsiakastietoValidators.REGEXP_EMAIL.test(control.value), AsiakastietoValidators.EMAIL) :
      null;
  }

  static isValidPhonenumber(control: FormControl) {
    return control.value ?
      AsiakastietoValidators.validate(AsiakastietoValidators.REGEXP_PHONE.test(control.value), AsiakastietoValidators.PHONE) :
      null;
  }

  static isValidYtunnus(control: FormControl) {
    return control.value ?
      AsiakastietoValidators.validate(AsiakastietoValidators.REGEXP_YTUNNUS.test(control.value), AsiakastietoValidators.YTUNNUS) :
      null;
  }

  static isValidElainlaakaritunnus(control: FormControl) {
    return control.value ?
      AsiakastietoValidators.validate(AsiakastietoValidators.REGEXP_ELAINLAAKARITUNNUS.test(control.value), AsiakastietoValidators.ELAINLAAKARITUNNUS) :
      null;
  }

  static hasOnlyOneMaksaja(control: FormControl) {
    if (control.root instanceof FormGroup) {
      const root = control.root as FormGroup;
      const maksajaControls: AbstractControl[] = [];

      maksajaControls.push(root.get(['tilaaja', 'lisatiedot', 'maksaja']));
      maksajaControls.push(root.get(['elainlaakari', 'lisatiedot', 'maksaja']));
      maksajaControls.push(root.get(['omistaja', 'lisatiedot', 'maksaja']));

      const array = root.get(['tiedoksisaaja', 'tiedoksisaajat']) as FormArray;
      array.controls.forEach(group => {
        maksajaControls.push(group.get(['lisatiedot', 'maksaja']));
      });

      let maksajia = 0 ;
      maksajaControls.forEach(maksajaControl => {
        maksajia += maksajaControl.value ? 1 : 0;
      });

      return AsiakastietoValidators.validate(maksajia === 1, AsiakastietoValidators.MAKSAJA);
    } else {
      return null;
    }
  }

  static requiredIfOtherFilled(otherControlName: string): ValidatorFn {

    // @dynamic
    return (control: AbstractControl) => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const otherControl = parent.get(otherControlName);
      if (!otherControl || !otherControl.value) {
        return null;
      }

      return Validators.required(control);
    };
  }

  static isValidPostinumero(postinumeroValidatorFn: (pnro: string) => Promise<string>): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control || !control.value || postinumeroValidatorFn === undefined) {
        return null;
      }

      const result = postinumeroValidatorFn(control.value);
      return AsiakastietoValidators.validate(Boolean(result), AsiakastietoValidators.POSTINUMERO);
    };
  }
}
