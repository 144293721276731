import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalData} from "../../modaalit/modal-utils";
import {Teksti} from "../../utils/teksti";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {LomakeResponse} from "../../touko-lomake/touko-lomake-utils";
import {Account, Toimiala} from "../../account/account";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PalveluService} from "../../sidebar/palvelu.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {LomakeHakuService} from "../../touko-lomake/lomake-haku.service";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";

@Component({
  selector: 'etusivu-asiakas',
  templateUrl: './etusivu-asiakas.component.html'
})
export class EtusivuAsiakasComponent implements OnInit, OnDestroy {

  @Input() account: Account = null;
  keskeneraisetLomakkeet: LomakeResponse[] = [];
  lahetetytLomakkeet: LomakeResponse[] = [];
  showLoader;
  subs: Subscription[] = [];

  private lahetetytCount = 5;
  public canLoadMore = true;
  public showPalaute = false;

  constructor(private readonly lomakeHakuService: LomakeHakuService,
              private readonly modalService: NgbModal,
              private readonly sidebarService: PalveluService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router) {
  }

  ngOnInit() {
    this.showLoader = true;
    this.refreshLomakkeet();

    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.get('palaute')) {
        this.showPalaute = true;
      }
    });

    this.subs.push(this.lomakeHakuService.lomakkeet$.subscribe(lomakkeet => {
      if (lomakkeet) {
        this.lahetetytLomakkeet = [];
        this.keskeneraisetLomakkeet = [];
        lomakkeet.forEach(lomake => {
          if (lomake.kesken) {
            this.keskeneraisetLomakkeet.push(lomake);
          } else {
            this.lahetetytLomakkeet.push(lomake);
          }
        });
        this.canLoadMore = this.lahetetytLomakkeet.length === this.lahetetytCount;
        this.keskeneraisetLomakkeet = this.sortById(this.keskeneraisetLomakkeet);
      }
    }));

    this.subs.push(this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event --> re-initalise the component
      if (e instanceof NavigationEnd) {
        this.refreshLomakkeet();
      }
    }));

    this.subs.push(this.lomakeHakuService.isGetLomakkeetRequestPending$.subscribe(value => {
      this.showLoader = value;
    }));
  }

  sortById(lomakkeet) {
    return lomakkeet.sort((a, b) => {
      const millisA = new Date(a.paipvm).getTime();
      const millisB = new Date(b.paipvm).getTime();
      return millisB - millisA;
    });
  }

  ngOnDestroy() {
    this.showLoader = false;
    this.subs.forEach(s => s.unsubscribe());
  }

  /**
   * Hakee käyttäjän lomakkeet. Päivitetään sivupalkki myös silloin, kun kutsu epäonnistuu.
   */
  public refreshLomakkeet = (): Promise<void> => this.lomakeHakuService.getAsiakasIlmoitukset(this.lahetetytCount)
      .then(result => {
        if (!result) {
          return Promise.reject('Request cancelled');
        }
        return this.sidebarService.updatePalvelut();
      }).catch((error: any) => {
        console.error(error);
        return this.sidebarService.updatePalvelut();
      });

  deleteKeskenerainen(id: number) {
    const poistettavaLomake = this.keskeneraisetLomakkeet.filter(lomake => lomake.id === id)[0];


    const okFn = () => {
      if (LomakeEndpointUtil.shouldUseV2Endpoint(poistettavaLomake.asia, `v${poistettavaLomake.lomaketyyppiVersio}`)) {
        return this.lomakeHakuService.deleteKeskenerainenIlmoitusV2(id, poistettavaLomake.toimintotyyppi.toLowerCase(), poistettavaLomake.asia)
            .then(() => {
              this.keskeneraisetLomakkeet = this.keskeneraisetLomakkeet.filter(k => k.id !== id);
            });
      } else {
        return this.lomakeHakuService.deleteKeskenerainenIlmoitus(id)
            .then(() => {
              this.keskeneraisetLomakkeet = this.keskeneraisetLomakkeet.filter(k => k.id !== id);
            });
      }
    };

    const modalData = new ModalData(
        new Teksti("Otsikko", "keskeytaAsiointi", "teksti"),
        new Teksti("Kuvaus", "tallentamattomatPoistetaan", "teksti"),
        new Teksti("Poista", "poista", "teksti"),
        okFn,
        "btn-danger"
    );
    const modalRef = this.modalService.open(ModalSimpleComponent);
    modalRef.componentInstance.modalTiedot = modalData;
  }


  jatkaKeskeneraistaLomaketta(lomake: LomakeResponse) {
    const version = LomakeEndpointUtil.shouldUseV2ClientComponent(lomake.asia, lomake.toimintotyyppi.toLowerCase(), lomake.lomaketyyppiVersio) ? "v2" : "v1";
    const linkArray = ['/asiointi/lomake', lomake.toimintotyyppi.toLowerCase(), lomake.asia, version, lomake.id, 'jatka'];

    if (lomake.viimeksiMuokannut) {
      this.router.navigate(linkArray);
    } else {
      const modalRef = this.modalService.open(ModalSimpleComponent);

      const modalData = new ModalData(
          new Teksti("Otsikko", "modalHaluatkoJatkaa", "sivupalkki"),
          new Teksti("Kysymys", "modalLomakkenMuokkaaja", "sivupalkki")
      );
      modalData.okFn = () => Promise.resolve(this.router.navigate(linkArray));
      modalRef.componentInstance.modalTiedot = modalData;
    }
  }

  showOVSNotification(): boolean {
    return this.account?.toimiala === Toimiala.KATE;
  }

  naytaLisaaLomakkeita = (): Promise<void> => {
    this.lahetetytCount += 5;
    return this.refreshLomakkeet();
  };

  closePalauteAlert(): Promise<boolean> {
    this.showPalaute = false;
    return this.router.navigate([], {queryParams: null});
  }
}
