/**
 * Created by Seppo on 22/11/2017.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild} from "@angular/core";
import {Syote} from "../syote";
import {LiitetiedostoPalvelinService} from "../../touko-liitetiedosto.service";
import {MessageService} from "../../../message/message.service";
import {LiitetiedostoResponse} from "../../touko-lomake-utils";
import {Teksti} from "../../../utils/teksti";
import {ModalData} from "../../../modaalit/modal-utils";
import {ModalSimpleComponent} from "../../../modaalit/lomake/modal-simple.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

/**
 * Liitetiedoston lataus-komponentti. Lataa liitteen palvelimelle ja tuottaa latauslinkin liitteelle.
 * Asettaa lomakkeelle ladatun liitteen id:n, tiedoston tiedot, sekä latauslinkin.
 * @deprecated uudet lomakkeet käyttävät LiiteListComponent luokkaa
 */
@Component({
  selector: 'touko-liite',
  template: `
    <div class="input-group mb-2" *ngFor="let liite of fieldValue; let i = index">
      <input [attr.aria-labelledby]="labelledBy" type="text" class="form-control" disabled [value]="liite.tiedostonimi"/>
      <div class="input-group-append">
        <button [attribute]="'lomakeYleinen.liiteAvaa'" (click)="clickOpenLiite(liite)" class="btn btn-primary" type="button">Avaa</button>
        <button [attribute]="'lomakeYleinen.liitePoista'" class="btn btn-outline-danger" type="button"
                (click)="clickRemoveFile(liite)" id="poista-{{htmlId}}-{{i}}">
          Poista
        </button>
      </div>
    </div>

    <ng-container *ngIf="fieldValue.length < maxLiiteLkm">
      <div class="input-group">
        <input autocomplete="off" [id]="'hidden-' + htmlId" class="form-control-file" style="display: none;" accept="image/jpeg, image/png, application/pdf"
               type="file" (change)="onFileChange($event)" #fileInput/>
        <input autocomplete="off" [attr.aria-labelledby]="labelledBy" [disabled]="isDisabled" type="text" [id]="htmlId" (click)="fileInput.click()" class="form-control"
               placeholder="{{'lomakeYleinen.liiteEiLadattua' | attribute}}">
        <div class="input-group-append">

          <button [attribute]="'lomakeYleinen.liiteSelaa'" id="selaa-{{htmlId}}" class="btn btn-outline-primary"
                  (click)="fileInput.click()" [disabled]="isDisabled"
                  type="button">Selaa...
          </button>
        </div>
      </div>

      <em *ngIf="showLiiteInfo" class="small text-muted" attribute="lomakeYleinen.liiteOhje">Tuetut tiedostomuodot ovat PDF, JPG ja PNG. Tiedoston maksimikoko 8Mb.</em>
    </ng-container>

    <!-- hidden download link -->
    <a class="invisible" #downloadlink></a>
  `,
  providers: [
    LiitetiedostoPalvelinService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LiiteComponent),
    }
  ]
})
export class LiiteComponent implements ControlValueAccessor {

  @Input() labelledBy: string = null;
  @Input() htmlId: string;
  @Input() key: string;
  @Input() item: Syote;
  @Input() maxLiiteLkm = 1;
  @Input() showLiiteInfo = true;
  @Input() selite: string;
  @Input() lomakeId: number;
  @Input() isDisabled = false;

  /**
   * Liitetiedostot menevät palvelimelle / poistetaan sieltä samantien, muista aina tallentaa lomake kun updateLomake
   * on true.
   *
   * Muuten on riskinä että lomakkeeseen jää roikkumaan liitetiedosto joka on jo poistettu palvelimelta.
   */
  @Output() liiteOut: EventEmitter<{ liitteet: LiitetiedostoResponse[]; updateLomake: boolean; key: string }> = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('downloadlink', { static: true }) downloadlink: ElementRef;

  fieldValue: LiitetiedostoResponse[] = [];

  constructor(private _renderer: Renderer2,
              private _elementRef: ElementRef,
              private liitetiedostoService: LiitetiedostoPalvelinService,
              private modalService: NgbModal,
              private messageService: MessageService) {
  }

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  writeValue(value: LiitetiedostoResponse[] | null, updateLomake = false): void {
    if (value) {
      this.fieldValue = value;
      this.liiteOut.emit({liitteet: this.fieldValue, updateLomake, key: this.htmlId});
    }
    this._onChange(this.fieldValue);
  }

  onFileChange(event) {
    if (this.fieldValue !== null && this.fieldValue.length >= this.maxLiiteLkm) {
      return;
    }
    if (event.target.files.length > 0) {
      this.uploadFile(event.target.files[0], this.selite, this.lomakeId).catch(reason => {
        this._renderer.setProperty(this.fileInput.nativeElement, 'value', null);
      });
    }
  }

  clickRemoveFile(item: LiitetiedostoResponse) {
    const modalRef = this.modalService.open(ModalSimpleComponent);
    const modalData = new ModalData(
      new Teksti("Otsikko", "modalPoistetaankoLiite", "lomakeYleinen"),
      new Teksti(item.tiedostonimi),
      new Teksti("Poista", "liitePoista", "lomakeYleinen"),
      this.removeFile.bind(this, item),
      "btn-danger"
    );
    modalRef.componentInstance.modalTiedot = modalData;
  }

  private removeFile(item: LiitetiedostoResponse) {
    return this.liitetiedostoService.deleteLiitetiedosto(item.id)
      .then(r => {
        const liiteList = this.fieldValue.filter(liite => liite.id !== item.id);
        this.writeValue(liiteList, true);
        this.messageService.notify({message: new Teksti("Liitetiedosto poistettu", "liitePoistettu", "lomakeYleinen"), type: "success"} );
        this._onChange(this.fieldValue);

        this._renderer.setProperty(this.fileInput.nativeElement, 'value', null);
      });
  }

  uploadFile(liite: File, selite: string, lomId: number): Promise<void> {
    return this.liitetiedostoService.sendLiitetiedosto(liite, selite, lomId)
      .then(r => {
        const liiteList = this.fieldValue.concat([r]);
        this.writeValue(liiteList, true);
        this.messageService.notify({message: new Teksti("Liitetiedosto ladattu onnistuneesti", "liiteLadattu", "lomakeYleinen"), type: "success"} );
        this._renderer.setProperty(this.fileInput.nativeElement, 'value', null);
      });
  }

  /**
   * Avaa liitteen uuteen ikkunaan. HUOM! IE:llä tiedosto ladataan.
   *
   * @param item - Liitetiedoston sisältö
   * @returns
   */
  clickOpenLiite(item: LiitetiedostoResponse) {
    return this.liitetiedostoService.downloadLiitetiedosto(item.id)
      .then(blob => {
        if (this.isIE()) {
          const nav = (window.navigator as any);
          nav.msSaveOrOpenBlob(blob, item.tiedostonimi);
        } else {
          const file = window.URL.createObjectURL(blob);
          window.open(file, "_blank");
        }
      });
  }

  /**
   * Tarkistaa, onko käyttäjän selain IE
   *
   * @returns
   */
  isIE(): boolean {
    return typeof window.navigator.msSaveBlob !== "undefined";
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

}
