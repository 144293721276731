import {ControlContainer, FormGroup} from "@angular/forms";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Teksti} from "../../utils/teksti";
import {LiitetiedostoResponse} from '../touko-lomake-utils';
import {Subject} from 'rxjs';
import {KasviService} from '../../kasvi/kasvi.service';
import {SyoteTemplateTaulukko} from '../syote/SyoteTemplateTaulukko';
import {LomakeService} from '../touko-lomake.service';
import {Kasvilaji} from '../../kasvi/kasvilaji.model';
import {Kasvilajike} from '../../kasvi/kasvilajike.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AttributeService} from '../../tiira/attribute.service';
import {ToukoKasvilajikeValintaComponent} from '../syote/custom/touko-kasvilajike-valinta.component';
import {ModalData} from "../../modaalit/modal-utils";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {LiitetiedostoPalvelinService} from "../touko-liitetiedosto.service";
import {FieldGroup} from "ngx-fielding";

abstract class Tyyppi {
  static SIEMEN = "SIEMEN";
  static TAIMI = "TAIMI";
}

@Component({
  selector: 'luomuluvat-input',
  templateUrl: './luomuluvat-input.component.html',
  styleUrls: ['./luomuluvat-input.component.scss'],
})
export class LuomuluvatInputComponent {

  lupaPerusteet: string[] = ['A', 'B', 'C', 'D', 'E'];
  lupaPerusteTekstit = {
    A: {
      valintaTeksti: new Teksti("Ei saatavana luomuna", "lupaPerusteTavanomA", "luomuPoikkeus9c"),
      infoTeksti: new Teksti("Viljeltävästä lajista ei ole rekisteröity yhtään lajiketta luomulisäysaineistorekisteriin.", "infoLupaPerusteTavanomA", "luomuPoikkeus9c")
    },
    B: {
      valintaTeksti: new Teksti("Toimitusvaikeus", "lupaPerusteTavanomB", "luomuPoikkeus9c"),
      infoTeksti: new Teksti("Yksikään myyjä ei pysty toimittamaan lisäysaineistoa ennen kylvöä tai istutusta, vaikka viljelijä on tilannut lisäysaineiston hyvissä ajoin. Syötä perustelut alla olevaan kenttään.", "infoLupaPerusteTavanomB", "luomuPoikkeus9c")
    },
    C: {
      valintaTeksti: new Teksti("Soveltumaton lajike", "lupaPerusteTavanomC", "luomuPoikkeus9c"),
      infoTeksti: new Teksti("Lajiketta, jota viljelijä haluaa käyttää, ei ole rekisterissä eivätkä rekisterissä olevat lajikkeet sovellu tuotantoon. Syötä perustelut alla olevaan kenttään.", "infoLupaPerusteTavanomC", "luomuPoikkeus9c")
    },
    D: {
      valintaTeksti: new Teksti("Tutkimuskäyttö tai lajikkeen suojelu", "lupaPerusteTavanomD", "luomuPoikkeus9c"),
      infoTeksti: new Teksti("Syötä perustelut alla olevaan kenttään.", "infoLupaPerusteTavanomD", "luomuPoikkeus9c")
    },
    E: {
      valintaTeksti: new Teksti("Uuden lisäysaineiston tuotanto omaa käyttöä varten", "lupaPerusteTavanomE", "luomuPoikkeus9c"),
      infoTeksti: new Teksti("Syötä tieto kylvöalasta (ha tai m²) tulevalle kasvukaudelle alla olevaan kenttään.", "infoLupaPerusteTavanomE", "luomuPoikkeus9c")
    }
  };

  @Input() lomake: FieldGroup;
  @Input() lomakeId: number;
  @Input() virheSanomat: { string: string };
  @Input() sivu: string;
  @Input() lupaTyyppi = "TAVANOM";
  @Input() otsikko = new Teksti("Lupa käyttää tavanomaisesti tuotettua lisäysaineistoa", "lupaTavanomLisaysaineisto", "luomuPoikkeus9c");
  @Output() tallennaMuutokset: EventEmitter<{ liitteet: LiitetiedostoResponse[]; updateLomake: boolean; key: string }> = new EventEmitter();
  lajiFocus$ = new Subject<string>();
  lajikeFocus$ = new Subject<string>();

  constructor(
      private kasviService: KasviService,
      public controlContainer: ControlContainer,
      private lomakeService: LomakeService,
      private modalService: NgbModal,
      private attribute: AttributeService,
      private liitetiedostoService: LiitetiedostoPalvelinService,
  ) {}

  getIdPrefix(): string {
    let id = "";
    this.controlContainer.name === "luvatTavanomLisaysaineisto" ? id = "tavanomainen" : id = "kemiallisestipeitattu";

    return id;
  }

  get controlName(): string {
    return "" + this.controlContainer.name;
  }

  get lupaTaulukko(): SyoteTemplateTaulukko {
    return this.controlContainer.control.get(this.controlName) as SyoteTemplateTaulukko;
  }

  get luvatLength(): number {
    return this.lupaTaulukko.length;
  }

  addRow() {
    this.lupaTaulukko.lisaaTyhja();
  }

  removeRow(lupaTaulukkoIndex) {
    const attachments = this.lupaTaulukko.value[lupaTaulukkoIndex].liitteet;
    if (!attachments.length) {
      this.lupaTaulukko.removeAt(lupaTaulukkoIndex);
    } else {

      const attachmentsIds: number[] = attachments.map(v => v.id);

      this.liitetiedostoService.deleteLiitetiedostot(attachmentsIds)
        .then(r => {
          const key = `${this.getIdPrefix()}-${lupaTaulukkoIndex}_liite`;
          this.lupaTaulukko.removeAt(lupaTaulukkoIndex);
          this.tallennaMuutokset.emit({liitteet: [], updateLomake: true, key});
        });
    }
  }

  uusiKasvilajike(kasvilajike: Kasvilajike, groupIndex: number) {
    const lajikecontrol = (this.lupaTaulukko.controls as FormGroup[])[groupIndex].get("lajike");

    lajikecontrol.setValue(kasvilajike, true);
  }

  lajiValittu(laji: Kasvilaji, lajikeRef: ToukoKasvilajikeValintaComponent) {
    lajikeRef.kasvilaji = laji;
  }

  asetaLaji(lajikeRef: ToukoKasvilajikeValintaComponent, groupIndex: number) {
    const lajiControl = (this.lupaTaulukko.controls as FormGroup[])[groupIndex].get("laji");
    lajikeRef.kasvilaji = lajiControl.value;
  }


  onSetLiite(liiteChangeEvent: { liitteet: LiitetiedostoResponse[]; updateLomake: boolean; key: string }) {
    this.tallennaMuutokset.emit(liiteChangeEvent);
  }

  naytaPerustelulista(): boolean {
    return this.lupaTyyppi === 'TAVANOM';
  }

  getGroupVirheet(kenttaObject: Object) {
    const kentta = kenttaObject as FormGroup;

    if (kentta.errors === null || (!kentta.dirty && !kentta.touched)) {
      return [];
    }

    const virheita = new Set();

    for (const virhe in kentta.errors) {
      if (kentta.errors.hasOwnProperty(virhe)) {
        virheita.add(virhe);
      }
    }

    const virhelista = Array.from(virheita);

    if (this.sivu) {
      this.lomakeService.setVirhe(this.sivu, Object.keys(kentta.controls)[0], virhelista.length > 0);
    }

    return virhelista;
  }


  getMaaraTyyppiLabel(lupaIndex: number): string {

    const aineistoTyyppi = (this.lupaTaulukko.controls as FormGroup[])[lupaIndex].get("tyyppi").value as Tyyppi;

    switch (aineistoTyyppi) {
      case Tyyppi.SIEMEN:
        return ", " + this.attribute.simpleTranslate("luomuPoikkeus9c", "kiloa", null, "Kiloa");
      case Tyyppi.TAIMI:
        return ", " + this.attribute.simpleTranslate("luomuPoikkeus9c", "kappaletta", null, "Kappaletta");
    }

    return "";

  }

  validateKayttomaara(lupaIndex: number) {
    const kayttomaaraControl = (this.lupaTaulukko.controls as FormGroup[])[lupaIndex].get("kayttomaara");
    const kayttomaaraValue: number = kayttomaaraControl.value;
    const aineistoTyyppi = (this.lupaTaulukko.controls as FormGroup[])[lupaIndex].get("tyyppi").value as Tyyppi;

    if (kayttomaaraValue) {
      let validated: string = String(kayttomaaraValue).valueOf();

      // Poistetaan negatiiviset
      validated = validated.replace('-', '');

      // Hyväksytään max 10 merkkiä pitkä syöte
      if (validated.length > 10) {
        validated = validated.substr(0, 10);
      }

      // Pyöristetään lähimpään kokonaislukuun, jos kysytään kappalemäärää
      if (aineistoTyyppi == Tyyppi.TAIMI) {
        validated = Math.round(Number(validated)).toString();
      }

      if (validated !== String(kayttomaaraValue)) {
        kayttomaaraControl.setValue(Number(validated));
      }
    }
  }

  showHelpModal() {
    const modalRef = this.modalService.open(ModalSimpleComponent);

    modalRef.componentInstance.modalTiedot = new ModalData(
      new Teksti("Otsikko", "ohjeOtsikko", "luomuPoikkeus9c"),
      new Teksti("Ohje 1.1 blaa blaa...", "ohje1_1", "luomuPoikkeus9c"),
      new Teksti("Sulje", "suljeOhje", "luomuPoikkeus9c"),
      null,
      "",
      "",
      true, // Modaalissa näytetään pelkästään sulje painike
      new Teksti("Sulje", "sulje", "teksti"),
    );
  }

}
