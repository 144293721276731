import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomDateFormatter, CustomDatepickerI18n } from '../../../utils/localizedDates';

@Component({
  selector: 'nautarekisteri-date',
  styles: [`
    .form-group.hidden {
      width: 0;
      margin: 0;
      border: none;
      padding: 0;
    }
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `],
  template: `
    <div class="input-group">
      <input name="datepicker"
             class="form-control"
             ngbDatepicker
             #datepicker="ngbDatepicker"
             (dateSelect)="onDateSelection($event)"
             [displayMonths]="2"
             [dayTemplate]="t"
             outsideDays="hidden"
             tabindex="-1"
             type="hidden">
      <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
    <div class="form-row">
  <div class="col-lg-6">
    <div class="input-group">
      <input #dpFromDate
             class="form-control" placeholder="pp.kk.vvvv"
             name="fromDate"
             aria-labelledby="rajaaNautaHakuaDate"
             id="nautarekisteri-date-input1"
             [value]="formatter.format(fromDate)"
             (keyup)="onKeyUp(dpFromDate.name, dpFromDate.value)">
      <div class="input-group-append">
        <button class="btn btn-primary form-control" (click)="datepicker.toggle()" type="button" aria-label="Valitse aloituspäivä">
          <span class="fa fa-calendar-alt"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="input-group">
      <input #dpToDate
             class="form-control" placeholder="pp.kk.vvvv"
             name="toDate"
             aria-labelledby="rajaaNautaHakuaDate"
             id="nautarekisteri-date-input2"
             [value]="formatter.format(toDate)"
             (keyup)="onKeyUp(dpToDate.name, dpToDate.value)">
      <div class="input-group-append">
        <button class="btn btn-primary form-control" (click)="datepicker.toggle()" type="button" aria-label="Valitse lopetuspäivä">
           <span class="fa fa-calendar-alt"></span>
        </button>
      </div>
    </div>
  </div>
    </div>

<hr/>
            `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NautarekisteriDate),
    },
      {
        provide: NgbDateParserFormatter,
        useClass: CustomDateFormatter
      },
      {
        provide: NgbDatepickerI18n,
        useClass: CustomDatepickerI18n
      }
  ]
})
export class NautarekisteriDate implements OnInit {
  private dates: Subject<DateInput> = new Subject();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  @Output() dateRange: EventEmitter<Object> = new EventEmitter();

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.fromDate = null;
    this.toDate = null;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  ngOnInit() {
    this.dates.pipe(
      debounceTime(300)
    ).subscribe((inputValue: DateInput) => {
      this.validateKeyStrokes({name: inputValue.name, input: inputValue.input});
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.dateEmitter();
  }

  onKeyUp(name: string, inputText: string) {
    this.dates.next({ name, input: inputText });
  }

  validateKeyStrokes(value: DateInput) {
    const isValidValue = this.rangevaluesHelper(value.name, value.input);
    if (isValidValue) {
this.dateEmitter();
}
  }

  rangevaluesHelper(name: string, input: string): boolean {
    if (this.isEmpty(input)) {
      this[name] = null;
      return true;
    }

    if (name === 'fromDate' && this.validateInput(this.fromDate, input)) {
      this.fromDate = NgbDate.from(this.validateInput(this.fromDate, input));
      return true;
    }
    if (name === 'toDate' && this.validateInput(this.toDate, input)) {
      this.toDate = NgbDate.from(this.validateInput(this.toDate, input));
      return true;
    }
  }

  isEmpty(input: string): boolean {
    return !input || input.length === 0;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  dateEmitter() {
    this.dateRange.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }
}

interface DateInput {
  name: string;
  input: string;
}
