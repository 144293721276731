import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, forwardRef, Input, OnChanges, Renderer2} from "@angular/core";
import {Syote} from "../syote";

/**
 * Rehuvalvontalomakkeen yhteiset ehdolliset kysymykset
 */
@Component({
  selector: 'touko-rehuvalinnaiset',
  template: `
    
    <div *ngIf="naytaKysymys('arvioRehunMaarasta')" class="form-group">
      <label for="arvioRehunMaarasta" [attribute]="'rehuvalvonta.arvioRehunMaarasta'"></label>
      <input #textField type="text" class="form-control" id="arvioRehunMaarasta" (keyup)="writeValue(textField.value)"
      [value]="getTextFieldValue()">
    </div>

    <div *ngIf="naytaKysymys('valmistettujenRehuSeostenLkm')" class="form-group">
      <label for="valmistettujenRehuSeostenLkm" [attribute]="'rehuvalvonta.valmistettujenRehuSeostenLkm'"></label>
      <input #textField type="text" class="form-control" id="valmistettujenRehuSeostenLkm" (keyup)="writeValue(textField.value)" 
      [value]="getTextFieldValue()">
    </div>
    
    <div *ngIf="naytaKysymys('rehunValmistusOmaan')" class="form-row normal-label">
      <div class="col-8">
      <label (click)="toggleValue()" class="form-check-label" [attribute]="'rehuvalvonta.rehunValmistusOmaan'"></label>
      </div>

      <label class="col">
        <input value="true" (click)="writeValue(true)" type="radio" class="form-check-input" 
               name="rehunValmistusOmaanKayttoon" [checked]="fieldValue">
        <span [attribute]="'kylla'">Kyllä</span>
      </label>
      <label class="col">
        <input value="false" (click)="writeValue(false)" type="radio" class="form-check-input"
               name="rehunValmistusOmaanKayttoon" [checked]="fieldValue !== null && fieldValue === false">
        <span [attribute]="'ei'">Ei</span>
      </label>
    </div>
    
    <div *ngIf="naytaKysymys('valmistaakoElintarvikkeita')" class="form-row normal-label">
      <div class="col-8">
        <label (click)="toggleValue()" class="form-check-label" [attribute]="'rehuvalvonta.valmistaakoElintarvikkeita'">
        </label>
      </div>

      <label class="col">
        <input value="true" (click)="writeValue(true)" type="radio" class="form-check-input" 
               name="valmistaakoElintarvikkeita" [checked]="fieldValue">
        <span [attribute]="'kylla'">Kyllä</span>
      </label>
      <label class="col">
        <input value="false" (click)="writeValue(false)" type="radio" class="form-check-input"
               name="valmistaakoElintarvikkeita"  [checked]="fieldValue !== null && fieldValue === false">
        <span [attribute]="'ei'">Ei</span>
      </label>
    </div>

    <div *ngIf="naytaKysymys('valmistusVainPakkaamista')" class="form-row normal-label">
      <div class="col-8">
        <label (click)="toggleValue()" class="form-check-label" [attribute]="'rehuvalvonta.valmistusVainPakkaamista'">
        </label>
      </div>

      <label class="col">
        <input value="true" (click)="writeValue(true)" type="radio" class="form-check-input"
               name="valmistusVainPakkaamista" [checked]="fieldValue">
        <span [attribute]="'kylla'">Kyllä</span>
      </label>
      <label class="col">
        <input value="false" (click)="writeValue(false)" type="radio" class="form-check-input"
               name="valmistusVainPakkaamista" [checked]="fieldValue !== null && fieldValue === false">
        <span [attribute]="'ei'">Ei</span>
      </label>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RehuValinnaisetComponent),
    }
  ]
})
export class RehuValinnaisetComponent implements ControlValueAccessor, OnChanges {

  fieldValue: any;
  @Input() item: Syote;
  @Input() kysymysViittaukset: Map<string, number>;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  _onChange = (_: any) => {
    // This is intentional
  };
  _onTouched = (_: any) => {
    // This is intentional
  };

  ngOnChanges() {
    // tyhjennä vastaus ettei se jää kummittelemaan lomakkeelle
    if (this.kysymysViittaukset.get(this.item.name) === 0) {
      this.writeValue(null);
    }
  }

  writeValue(value: any): void {
    this.fieldValue = value;
    this._onChange(this.fieldValue);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  naytaKysymys(kysymys: string): boolean {
    return this.item.name === kysymys && this.kysymysViittaukset.get(kysymys) > 0;
  }

  toggleValue() {
    this.writeValue(!Boolean(this.fieldValue));
  }

  getTextFieldValue(): string {
    if (this.fieldValue) {
      return this.fieldValue;
    } else {
      return "";
    }
  }
}
