import {Component, Input} from "@angular/core";
import {CompareService, SummaryCompareItem} from "./compare.service";
import {FieldGroup} from "ngx-fielding";

@Component({
  selector: 'field-group-summary-compare',
  template: `
    <div *ngFor="let summaryItem of summaryCompareItems" class="row">
      <div *ngIf="summaryItem.type === 'IGNORED'" class="col">
        <summary-container [summaryItem]="summaryItem.newSummary"></summary-container>
      </div>
      <ng-container *ngIf="summaryItem.type !== 'IGNORED'">
        <div class="col-6">
          <summary-container [summaryItem]="summaryItem.newSummary"></summary-container>
        </div>
        <div class="col-6">
          <summary-container *ngIf="summaryItem.type !== 'UNCHANGED' && summaryItem.oldSummary?.answer" [summaryItem]="summaryItem.oldSummary" [compareItemType]="summaryItem.type"></summary-container>
        </div>
      </ng-container>
    </div>
  `
})
export class FieldGroupSummaryCompareComponent {
  @Input() currentFieldGroup: FieldGroup;
  @Input() compareFieldGroup: FieldGroup;
  @Input() excludedPaths: string[];

  private compareService = new CompareService();

  get summaryCompareItems(): SummaryCompareItem[] {
    return this.compareService.getSummaryCompare(
      this.compareService.compare(this.currentFieldGroup, this.compareFieldGroup), [], new Set(this.excludedPaths || [])
    ).filter(c => c.type !== 'HIDDEN');
  }
}
