import {Component, Input, OnInit} from '@angular/core';
import {Account} from "../../account/account";
import {LomakeResponse} from "../../touko-lomake/touko-lomake-utils";
import {
  LaheteListausViewOptions,
  ShowMoreEvent
} from "../lahetetyt/lahete-listaus.component";
import {LomakeTableViewOptions} from "../lomake-table/lomake-table.component";
import {Sarake, SarakeType} from "../../raportti/raportti.utils";
import {Teksti} from "../../utils/teksti";
import {Router} from "@angular/router";
import {ModalSimpleComponent} from "../../modaalit/lomake/modal-simple.component";
import {ModalData} from "../../modaalit/modal-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToukoLaheteService} from "../../touko-lahete/touko-lahete.service";
import {LomakeBaseService} from "../../touko-lomake/lomake-base.service";
import {SarakeClickEvent} from "../lomake-table/lomake-row.component";
import {MallipohjaModalComponent} from "./mallipohja-modal.component";
import {LaheteHakuService} from "../../touko-lomake/lahete-haku.service";

@Component({
  selector: 'etusivu-elaintaudit',
  templateUrl: './etusivu-elaintaudit.component.html',
  providers: [
    {provide: LomakeBaseService, useClass: ToukoLaheteService}
  ]
})
export class EtusivuElaintauditComponent implements OnInit {

  @Input() account: Account = null;

  isLoaded = {lahetetyt: false, keskeneraiset: false, mallipohjat: false};

  lahetetyt: LomakeResponse[] = [];
  lahetetytSarakkeet: Sarake[] = [];
  lahetetytViewOptions: LaheteListausViewOptions;

  keskeneraiset: LomakeResponse[] = [];
  keskeneraisetSarakkeet: Sarake[] = [];
  keskeneraisetViewOptions: LaheteListausViewOptions;

  mallipohjat: LomakeResponse[] = [];
  mallipohjaSarakkeet: Sarake[] = [];
  mallipohjaViewOptions: LaheteListausViewOptions;

  constructor(private readonly laheteHakuService: LaheteHakuService,
              private readonly toukoLaheteService: LomakeBaseService,
              private readonly modalService: NgbModal,
              private readonly router: Router) {

    const tableStyles = ["table", "table-striped", "table-hover"];
    const deleteStyleFn = lahete => lahete.viimeksiMuokannut ?
      "fa fa-trash float-right text-danger" :
      "fa fa-lock float-right text-secondary";

    this.lahetetytViewOptions = new LaheteListausViewOptions(
      'lahetetyt',
      "etusivu.lahetetyt",
      "etusivu.luoUusiLahete",
      "/asiointi/lomake/lahete/elaintautitutkimus/v1/0/aloita",
      new LomakeTableViewOptions(
        tableStyles,
        true,
        (lomake: LomakeResponse) => `toimitettu-${lomake.id}`,
        (lomake: LomakeResponse) => `/asiointi/lomake/tarkastus/lahete/${lomake.id}`
      )
    );
    this.lahetetytSarakkeet = [
      new Sarake('id', undefined, SarakeType.ID, () => 'd-none'),
      new Sarake('laheteId', new Teksti('-', 'laheteTunnus', 'etusivu')),
      new Sarake('lahettajaNimi', new Teksti('-', 'lahettaja', 'etusivu')),
      new Sarake('lahetysAika', new Teksti('-', 'lahetetty', 'etusivu'), SarakeType.DATETIME),
      new Sarake('tila', new Teksti('-', 'tila', 'etusivu'), SarakeType.TYPE),
      new Sarake('mallipohja', new Teksti('-', 'kopioi', 'etusivu'),
        SarakeType.SYMBOL_LINK, () => "fa fa-clone text-danger")
    ];

    this.keskeneraisetViewOptions = new LaheteListausViewOptions(
      'keskeneraiset',
      "etusivu.keskeneraiset",
      "etusivu.luoUusiLahete",
      "/asiointi/lomake/lahete/elaintautitutkimus/v1/0/aloita",
      new LomakeTableViewOptions(
        tableStyles,
        true,
        (lomake: LomakeResponse) => `kesken-${lomake.id}`,
      )
    );
    this.keskeneraisetSarakkeet = [
      new Sarake('id', undefined, SarakeType.ID, () => 'd-none'),
      new Sarake('muokannutToimija', new Teksti('-', 'muokannutToimija', 'etusivu')),
      new Sarake('paipvm', new Teksti('-', 'paivitetty', 'etusivu'), SarakeType.DATETIME),
      new Sarake('poista', null, SarakeType.SYMBOL_LINK, deleteStyleFn)
    ];

    this.mallipohjaViewOptions = new LaheteListausViewOptions(
      'mallipohjat',
      "etusivu.mallipohjat",
      null, "/asiointi/lomake/lahete/elaintautitutkimus/v1/0/aloita", // "etusivu.luoUusiLahete",
      new LomakeTableViewOptions(
        tableStyles,
        true,
        (lomake: LomakeResponse) => `mallipohja-${lomake.id}`,
      )
    );
    this.mallipohjaSarakkeet = [
      new Sarake('id', undefined, SarakeType.ID, () => 'd-none'),
      new Sarake('muokannutToimija', new Teksti('-', 'muokannutToimija', 'etusivu')),
      new Sarake('mallipohjanimi', new Teksti('-', 'nimi', 'etusivu')),
      new Sarake('poista', null, SarakeType.SYMBOL_LINK, deleteStyleFn)
    ];
  }

  ngOnInit(): void {
    this.getLahetetyt();
    this.getKeskeneraiset();
    this.getMallipohjat();
  }

  async getLahetetyt() {
    return this.laheteHakuService.getLahetetyt().then(() => {
      this.laheteHakuService.lahetetyt$.subscribe(lahetetyt => {
        this.lahetetyt = lahetetyt;
        this.isLoaded.lahetetyt = true;
      });
    });
  }

  async getKeskeneraiset() {
    return this.laheteHakuService.getKeskeneraiset().then(() => {
      this.laheteHakuService.keskeneraiset$.subscribe(keskeneraiset => {
        this.keskeneraiset = keskeneraiset;
        this.isLoaded.keskeneraiset = true;
      });
    });
  }

  async getMallipohjat() {
    return this.laheteHakuService.getMallipohjat().then(() => {
      this.laheteHakuService.mallipohjat$.subscribe(mallipohjat => {
        this.mallipohjat = mallipohjat;
        this.isLoaded.mallipohjat = true;
      });
    });
  }

  continueKeskenerainen(lahete: LomakeResponse) {
    //v1 tulee urliin routerista.
    const linkArray = ['asiointi', 'lomake', 'lahete', 'elaintautitutkimus', lahete.id, 'jatka'];
    if (lahete.viimeksiMuokannut) {
      this.router.navigate(linkArray);
    } else {
      const modalRef = this.modalService.open(ModalSimpleComponent);
      const modalData = new ModalData(
        new Teksti("Otsikko", "modalHaluatkoJatkaa", "sivupalkki"),
        new Teksti("Kysymys", "modalLomakkenMuokkaaja", "sivupalkki")
      );
      modalData.okFn = () => this.toukoLaheteService.updateViimeksiMuokannutAsiakas(lahete.id)
          .then(() => this.router.navigate(linkArray));
      modalRef.componentInstance.modalTiedot = modalData;
    }
  }

  selectLaheteSarake(sarakeClick: SarakeClickEvent) {
    const lomake = sarakeClick.lomake;
    if (lomake.viimeksiMuokannut) {
      this.deleteKeskenerainen(lomake);
    } else {
      this.updateLaheteMuokkaaja(lomake);
    }
  }

  showMore(showMoreEvent: ShowMoreEvent) {
    const laheteHakuFn = {
      lahetetyt: this.laheteHakuService.getLahetetyt.bind(this.laheteHakuService),
      keskeneraiset: this.laheteHakuService.getKeskeneraiset.bind(this.laheteHakuService),
      mallipohjat: this.laheteHakuService.getMallipohjat.bind(this.laheteHakuService)
    };

    this.isLoaded[showMoreEvent.lahetetyyppi] = false;

    laheteHakuFn[showMoreEvent.lahetetyyppi]({sivu: 0, nayta: showMoreEvent.pageSize});
  }

  openMallipohjaModal(sarakeClick: SarakeClickEvent) {
    const modalRef = this.modalService.open(MallipohjaModalComponent);
    modalRef.result.then(mallipohjanimi => {
      if (mallipohjanimi && sarakeClick?.lomake?.id) {
        return this.createMallipohja(sarakeClick.lomake.id, mallipohjanimi);
      }
      return Promise.resolve(null);
    }, () => null);
  }

  createMallipohja(baseLomakeId: number, mallipohjanimi: string) {
    return this.toukoLaheteService.createMallipohja(baseLomakeId, mallipohjanimi)
      .then(() => this.laheteHakuService.getMallipohjat());
  }

  private deleteKeskenerainen(lomake: LomakeResponse) {
    const okFn = () => this.laheteHakuService.deleteKeskenerainenLahete(lomake.id);

    const modalRef = this.modalService.open(ModalSimpleComponent);
    modalRef.componentInstance.modalTiedot = new ModalData(
      new Teksti("Otsikko", "keskeytaAsiointi", "teksti"),
      new Teksti("Kuvaus", "tallentamattomatPoistetaan", "teksti"),
      new Teksti("Poista", "poista", "teksti"),
      okFn,
      "btn-danger"
    );
  }

  private updateLaheteMuokkaaja(lomake: LomakeResponse) {
    const okFn = () => this.toukoLaheteService.updateViimeksiMuokannutAsiakas(lomake.id)
      .then(r => this.laheteHakuService.updateLomake(r));

    const modalRef = this.modalService.open(ModalSimpleComponent);
    modalRef.componentInstance.modalTiedot = new ModalData(
      new Teksti("Otsikko", "modalHaluatkoJatkaa", "sivupalkki"),
      new Teksti("Kysymys", "modalLomakkenMuokkaaja", "sivupalkki"),
      new Teksti("Kyllä", "kylla", "teksti"),
      okFn
    );
  }

}
