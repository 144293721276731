import {Injectable} from "@angular/core";
import {YhteenvetoGroup, YhteenvetoItem, YhteenvetoTyyppi} from "./yhteenveto-utils";
import {Teksti} from "../../utils/teksti";
import {
  DateObj,
  ElainTuotantoLupaSl,
  Luomulupa,
  SiirtymaajanLyhennysLupa,
  Verkkokauppa,
  VerkkolaskuTiedot,
  Yhteyshenkilo
} from "../syote/syote-utils";
import {BehaviorSubject, Observable} from "rxjs";
import {LomakeJSONOld, YhteyshenkiloTeksti} from "../touko-lomake-utils";
import {OsoiteTiedot} from "../../account/account";
import {AttributeService} from "../../tiira/attribute.service";
import {LomakeDataContent} from "../lomake-data";
import {
  TuoteluokatYhteenvetoComponent
} from "./lannoiterekisteri-components/tuoteluokat-yhteenveto/tuoteluokat-yhteenveto.component";
import {TYYPPINIMI_MAP} from "../../utils/tyyppinimet";
import {
  LannoitevalmisteenjakelijaYhteenvetoComponent
} from "./lannoiterekisteri-components/lainnoitevalmisteenjakelija/lannoitevalmisteenjakelija-yhteenveto.component";
import {
  LannoitevalmisteAinesosaluokatYhteenvetoComponent
} from "./lannoiterekisteri-components/lannoitevalmiste-ainesosaluokat/lannoitevalmiste-ainesosaluokat-yhteenveto.component";
import {SyoteInterface} from "../syote/syote-interface";
import {
  LannoitevarastointiComponent
} from "./lannoiterekisteri-components/lannoitevarastointi/lannoitevarastointi.component";
import {
  LannoiteEuToimintaComponent
} from "./lannoiterekisteri-components/lannoite-eu-toiminta/lannoite-eu-toiminta.component";
import {
  LannoiteEuToimintaValmistajaValmistuttajaComponent
} from "./lannoiterekisteri-components/lannoite-eu-toiminta-valmistaja/lannoite-eu-toiminta-valmistaja-valmistuttaja.component";
import {
  LannoiteMuuToimintaComponent
} from "./lannoiterekisteri-components/lannoite-muu-toiminta/lainnoite-muu-toiminta.component";
import {
  LannoiteSivutuoteComponent
} from "./lannoiterekisteri-components/lannoite-sivutuote/lainnoite-sivutuote.component";
import {LannoiteEuJakelija} from "./lannoiterekisteri-components/lannoite-eu-jakelija/lannoite-eu-jakelija";
import {
  LannoiteLopetaToimintaComponent
} from "./lannoiterekisteri-components/lannoite-lopeta-toiminta/lannoite-lopeta-toiminta.component";
import {KoodistoService} from "../../koodisto/koodisto.service";

/**
 * Created by Seppo on 10/07/2018.
 */

@Injectable()
export class YhteenvetoService {

  private lomakeDataDict: { [key: string]: Teksti };
  private lomakeDataOptions;
  private lomakeArvot: LomakeJSONOld;
  private lomakeData: LomakeDataContent;
  private readonly yhteenvetoMap: Map<YhteenvetoTyyppi, (data: any, syote?: SyoteInterface) => YhteenvetoItem | YhteenvetoItem[]> = new Map();

  private readonly _yhteenveto = new BehaviorSubject<YhteenvetoGroup[]>([]);
  yhteenveto$: Observable<YhteenvetoGroup[]> = this._yhteenveto.asObservable();
  maakoodiMap: { [key: string]: string };

  constructor(private readonly attributeService: AttributeService, private readonly koodistoService: KoodistoService) {
  }

  /**
   * Alustaa yhteenvetoon lomakkeen syötteiden arvot, kenttien nimet alustaa yhteenvetoMap-objektin
   *
   * @param lomakeData
   * @returns
   */
  initYhteenvetoService(lomakeData?) {
    this.registerYhteenvetoTyypit();
    if (lomakeData) {
      this.lomakeData = lomakeData;
      this.setLomakeDataDict(lomakeData);
    }

    this.koodistoService.getMaatByMaaKoodit().then(response => {
      this.maakoodiMap = response;
    });
  }

  /**
   * Päivittää lomakekenttien arvot
   *
   * @param lomakeArvot
   */
  updateLomakeArvot(lomakeArvot: LomakeJSONOld | string) {
    if (typeof lomakeArvot === "string") {
      lomakeArvot = JSON.parse(lomakeArvot);
    }
    this.lomakeArvot = lomakeArvot as LomakeJSONOld;
    this._yhteenveto.next(this.getYhteenveto(this.lomakeArvot));
  }

  /**
   * Palauttaa yhteenvedon lomakearvojen perusteella
   *
   * @returns
   */
  getYhteenveto(lomakeArvot: LomakeJSONOld, lomakeDataImport?: LomakeDataContent): YhteenvetoGroup[] {
    const yhteenveto: YhteenvetoGroup[] = [];
    const origLomakeData = this.lomakeData;
    const origLomakeDataDict = this.lomakeDataDict;
    const origLomakeArvot = this.lomakeArvot;

    if (lomakeDataImport) {
      this.lomakeData = lomakeDataImport;
      this.lomakeDataDict = this.setLomakeDataDict(lomakeDataImport);
      this.lomakeArvot = lomakeArvot;
    }

    if (!lomakeArvot || !this.lomakeData) {
      return yhteenveto;
    }

    for (const valueGroup of lomakeArvot.data) {
      const listaItem = new YhteenvetoGroup();
      const syoteGroup = this.lomakeData[valueGroup.groupName];

      if (!syoteGroup || syoteGroup.summaryType === YhteenvetoTyyppi.NONE || !this.hasTrueValues(valueGroup.values, syoteGroup.summaryType)) {
        continue;
      }

      listaItem.title = syoteGroup.title;
      for (const syoteArvo of valueGroup.values) {
        // mahdollistaa syöte groupin yhteenvetotyypin lisäksi jokaiselle syötteelle oman yhteenvetotyypin tarvittaessa.
        const syote = syoteGroup.dataObject[syoteArvo.field];
        if (!syote) {
          continue;
        }
        const summaryType = (syote?.summaryType || syoteGroup.summaryType) as YhteenvetoTyyppi;
        const item = this.createItem(summaryType, syoteArvo, syote);

        if (item instanceof Array) {
          listaItem.data.push(...item);
        } else if (item) {
          listaItem.data.push(item);
        }
      }
      yhteenveto.push(listaItem);
    }
    this.lomakeData = origLomakeData;
    this.lomakeDataDict = origLomakeDataDict;
    this.lomakeArvot = origLomakeArvot;

    return yhteenveto;
  }

  private hasTrueValues(values: { field: string; value: any }[], summaryType): boolean {
    if (!values) {
      return false;
    }
    if (summaryType === YhteenvetoTyyppi.YES_NO) {
      return true;
    }
    return values.map(val => val.value).filter(val => Boolean(val)).length > 0;
  }

  /**
   * Palauttaa yhteenvetotyypin mukaisesti näytetettävän tiedon annetuista lomakekentän arvoista.
   *
   * @param yhteenvetoTyyppi - Yhteenvetotyyppi, jonka mukaan tieto esitetään
   * @param syoteArvo - Näytettävät tiedot
   * @returns Yhteenvedossa näytettävä kohta tai lista niitä
   */
  createItem(yhteenvetoTyyppi: YhteenvetoTyyppi, syoteArvo, syote: SyoteInterface): YhteenvetoItem | YhteenvetoItem[] {
    if (!this.yhteenvetoMap.has(yhteenvetoTyyppi)) {
      console.error(`Yhteenvetotyypille "${YhteenvetoTyyppi[yhteenvetoTyyppi]}" ei löydy toteutusta`);
      return null;
    }
    return this.yhteenvetoMap.get(yhteenvetoTyyppi)(syoteArvo, syote);
  }

  /**
   * Asettaa yhteenvetoMap-objektin tunnistamat yhteenvetotyypit.
   * Lisää tähän metodiin toteutukset yhteenvedossa näytettävistä tiedoista.
   */
  private registerYhteenvetoTyypit() {
    this.yhteenvetoMap.set(YhteenvetoTyyppi.YES_NO, this.yesNoListItem.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_AND_TEXT, this.labelAndText.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_ONLY, this.labelOnly.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_AND_VALUE, this.labelAndText.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_AND_VALUE_BY_OPTIONS, this.labelAndValueByOptions.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.REHU_LABEL_TEXT, this.rehulabelText.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.REHU_YHTEISETKYSYMYKSET, this.rehuYhteiskysymykset.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.VALUE_ONLY, this.valueOnly.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.CHECKBOXES_WITH_OPTIONAL, this.checkboxOptional.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.ATTACHMENT, this.attachment.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.ATTACHMENT_NO_LABEL, this.attachmentNoLabel.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LABEL_OR_ATTACHMENT, this.labelOrAttachment.bind(this));

    // TODO: Tästä alaspäin päivitettävä muotoon, jossa ei käytetä lomakeDataDict objektia
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SINGLE_SELECTION_LABEL, this.singleSelectionLabel.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SINGLE_SELECTION_DATE, this.singleSelectionDate.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SINGLE_SELECTION_DATE_AND_LABEL, this.singleSelectionDateAndLabel.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.ATTACHMENT_DATE, this.attachmentDate.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.OSOITE_OR_TEXT, this.osoiteOrText.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LASKUTUSTIETO, this.laskutustieto.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.YHTEYSHENKILOT, this.yhteyshenkilot.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.VERKKOKAUPAT, this.verkkokaupat.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LUOMULUVAT, this.luomuluvat.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SIIRTYMAAJANLYHENNYSLUVAT, this.siirtymaajanLyhennysluvat.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.ELAINTUOTANTOLUPA_SL, this.elainTuotantoLupaSl.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.MUUTOSILMOITUS_TOIMINNANKESKEYTYS_ALOITUS_KATKO, this.labelAndYesOrDate.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.MUUTOSILMOITUS_TOIMINNAN_MUUTOS, this.labelAndYes.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.MUUTOSILMOITUS_TOIMINNAN_MUUTOS_LUOMUELINTARVIKKEET, this.labelAndYes.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.MUUTOSILMOITUS_LISATIETOJA, this.muutosilmoitusLisatietoja.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LAITE_HYVAKSYTTY_PVM, this.siemenpakkaamoLaiteHyvaksytty.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SYOTE_CHECKBOX_AND_DATE_ARRAY, this.siemenpakkaamoCheckboxAndDateArray.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SYOTE_DATE_ARRAY, this.siemenpakkaamoDateArray.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.SIEMENPAKKAAMO_VALUE_ONLY, this.siemenpakkaamoValueOnly.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_KANSALLINEN_TOIMINTA, this.lannoitekansallinentoiminta.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITEVALMISTEEN_JAKELIJA, this.lannoitevalmisteenJakelija.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITEVALMISTE_AINESOSALUOKAT, this.lannoitevalmisteAinesosaluokat.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_VARASTOINTI, this.lannoiteVarastointi.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_EU_TOIMINTA, this.lannoiteEuToiminta.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_EU_TOIMINTA_VALMISTAJA_VALMISTUTTAJA, this.lannoiteEuToimintaValmistajaValmistuttaja.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_EU_JAKELIJA, this.lannoiteEuJakelija.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_MUU_TOIMINTA, this.lannoiteMuuToiminta.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.LANNOITE_SIVUTUOTE, this.lannoiteSivutuote.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.RADIO_SELECTION, this.radioSelection.bind(this));
    this.yhteenvetoMap.set(YhteenvetoTyyppi.NONE, (_ignored) => null);
  }

  private setLomakeDataDict(lomakeData: LomakeDataContent) {
    this.lomakeDataDict = {};
    this.lomakeDataOptions = {};
    for (const syoteGroup in lomakeData) {
      if (!lomakeData.hasOwnProperty(syoteGroup)) {
        continue;
      }
      const sg = lomakeData[syoteGroup];
      for (const syote of sg.data) {
        // käytetään siemenlomakkeella toiminnan laajuuden näyttämisessä
        if (syote.value !== null) {
          this.lomakeDataDict[syote.value] = syote.label;
        }
        this.lomakeDataDict[syote.name] = syote.label;
        this.lomakeDataOptions[syote.name] = syote.options;
        syote.children.forEach(child => {
          this.lomakeDataDict[child.name] = child.label;
          this.lomakeDataOptions[child.name] = child.options;
        });
      }
    }
    return this.lomakeDataDict;
  }


  // YHTEENVETOTYYPPIEN TOTEUTUKSET

  private yesNoListItem(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const label = syote.label;
    const value = syoteArvo.value;

    const valueText = value ? YhteenvetoItem.KYLLA_TEKSTI : YhteenvetoItem.EI_TEKSTI;
    return new YhteenvetoItem(label, valueText);
  }

  private laskutustieto(syoteArvo): YhteenvetoItem {
    let val = syoteArvo.value ? syoteArvo.value : null;

    if (val && val.hasOwnProperty('osoite')) {
      val = new OsoiteTiedot(val.osoite, val.postinumero, val.postitoimipaikka);
    } else if (val && val.hasOwnProperty('verkkolaskutusosoite')) {
      val = new VerkkolaskuTiedot(val.verkkolaskutusosoite, val.operaattori);
    }

    if (val && val instanceof OsoiteTiedot) {
      return this.osoite(syoteArvo);
    } else if (val && val instanceof VerkkolaskuTiedot) {
      return this.verkkolaskuosoite(syoteArvo);
    }
    return null;
  }

  private osoiteOrText(syoteArvo): YhteenvetoItem {
    const val = syoteArvo.value ? syoteArvo.value : "-";
    if (typeof val === "string") {
      return new YhteenvetoItem(this.lomakeDataDict[syoteArvo.field], new Teksti(val));
    }

    return this.osoite(syoteArvo);
  }

  private osoite(syoteArvo): YhteenvetoItem {
    let val = syoteArvo.value ? syoteArvo.value : null;

    if (val && !(val instanceof OsoiteTiedot)) {
      val = new OsoiteTiedot(val.osoite, val.postinumero, val.postitoimipaikka, val.maaKoodi);
    }

    if (val instanceof OsoiteTiedot) {
      const [osoite, postinumero, postitoimipaikka, maa] = [val.osoite ? val.osoite : "-",
        val.postinumero ? val.postinumero : "-",
        val.postitoimipaikka ? val.postitoimipaikka : "-",
        (this.maakoodiMap || {FI: 'Suomi'})[val.maaKoodi || "FI"]]; // Maakoodit eivät aina ole alustettuja (ratkaisu on kaukana täydellisestä).
      return new YhteenvetoItem(this.lomakeDataDict[syoteArvo.field], new Teksti(`${osoite}, ${postinumero} ${postitoimipaikka}, ${maa}`));
    }
    return null;
  }

  private verkkolaskuosoite(syoteArvo): YhteenvetoItem {
    let val = syoteArvo.value ? syoteArvo.value : null;

    if (val && !(val instanceof VerkkolaskuTiedot)) {
      val = new VerkkolaskuTiedot(val.verkkolaskutusosoite, val.operaattori);
    }

    if (val instanceof VerkkolaskuTiedot) {
      return new YhteenvetoItem(this.lomakeDataDict[syoteArvo.field], new Teksti([val.verkkolaskutusosoite, val.operaattori].join(' / ')));
    }
    return null;
  }

  private yhteyshenkilot(syoteArvo): YhteenvetoItem[] {
    let val = syoteArvo.value;
    if (val && val instanceof Array && val.length > 0 && !(val[0] instanceof Yhteyshenkilo)) {
      val = val.map(o => new Yhteyshenkilo(o.nimi, o.puhelin, o.email, o.rooli, o.elmoStatus));
    }
    return val.filter(yhteyshenkilo => yhteyshenkilo.elmoStatus === "ACTIVE").map((value, index) => {
      const yhtVal = YhteyshenkiloTeksti[value.rooli];
      const rooliVal = yhtVal ? this.attributeService.simpleTranslate(yhtVal.view, yhtVal.key) : null;
      const teksti = [value.nimi, value.email, value.puhelin, rooliVal].filter(Boolean).join(', ');
      return new YhteenvetoItem(null, new Teksti(teksti), `${syoteArvo.field}-${index}`);
    });
  }

  private verkkokaupat(syoteArvo): YhteenvetoItem[] {
    let val = syoteArvo.value;
    if (val && val instanceof Array && val.length > 0 && !(val[0] instanceof Verkkokauppa)) {
      val = val.map(o => new Verkkokauppa(o.osoite));
    }
    return val.filter(verkkokauppa => verkkokauppa.osoite > "" && !verkkokauppa.enddt).map((value, index) => {
      const teksti = [value.osoite].filter(Boolean).join(', ');
      return new YhteenvetoItem(null, new Teksti(teksti), `${syoteArvo.field}-${index}`);
    });
  }

  private labelAndText(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const val = syoteArvo.value ? syoteArvo.value : "-";
    return new YhteenvetoItem(syote.label, new Teksti(val));
  }

  private labelAndValueByOptions(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (!syoteArvo) {
      return null;
    }

    const val = syoteArvo.value;

    if (val && val instanceof Object && Object.values(val).some(v => Boolean(v))) {

      const order = syote.options.order;
      const orderElementsFn = (a, b) => order.indexOf(a[0]) < order.indexOf(b[0]) ? -1 : 1;

      const values = Object.entries(val)
        .filter(entry => entry[1])
        .sort(order ? orderElementsFn : () => 1)
        .map(entry => syote.options[entry[0]])
        .map((entry: Teksti) => this.attributeService.simpleTranslate(entry.view, entry.key).toLocaleLowerCase())
        .join(", ");
      return new YhteenvetoItem(syote.label, new Teksti(values), syoteArvo.field);
    } else if (val === true) {
      return new YhteenvetoItem(syote.label, null);
    }

    return null;
  }

  private labelOnly(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (!syoteArvo) {
      return null;
    }

    return syoteArvo.value ? new YhteenvetoItem(null, syote.label) : null;
  }

  private rehulabelText(syoteArvo, syote: SyoteInterface): YhteenvetoItem[] {
    const yhteenvetoList = [];
    if (syoteArvo.value?.valittu) {
      yhteenvetoList.push(new YhteenvetoItem(null, syote.label));
      if (syoteArvo.value.hasOwnProperty("vastaukset") && syoteArvo.value.vastaukset) {
        for (const [kysymys, vastaus] of Object.entries(syoteArvo.value.vastaukset)) {
          yhteenvetoList.push(new YhteenvetoItem(new Teksti("", kysymys, 'rehuvalvonta'), new Teksti(vastaus)));
        }
      }
    }
    return yhteenvetoList;
  }

  private valueOnly(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    return new YhteenvetoItem(null, new Teksti(syoteArvo.value), syote.label.key);
  }

  private rehuYhteiskysymykset(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (typeof syoteArvo.value === 'boolean') {
      return this.yesNoListItem(syoteArvo, syote);
    }
    return new YhteenvetoItem(syote.label, new Teksti(syoteArvo.value));
  }

  private checkboxOptional(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const value = syoteArvo.value;
    if (!value) {
      return null;
    }

    const labelForValue = syote.label;
    const valueText = typeof value === "boolean" || !value.text ? labelForValue : new Teksti(value.text);
    return new YhteenvetoItem(null, valueText, syote.label.key);
  }

  private attachment(syoteArvo, syote: SyoteInterface): YhteenvetoItem[] {
    const yhteenvetoList = [];
    syoteArvo.value.forEach(tiedosto => {
      const yhteenvetoItem = new YhteenvetoItem(syote.label, new Teksti(tiedosto.tiedostonimi));
      yhteenvetoItem.downloadLink = tiedosto;
      yhteenvetoList.push(yhteenvetoItem);
    });
    return yhteenvetoList;
  }

  private attachmentNoLabel(syoteArvo, syote: SyoteInterface): YhteenvetoItem[] {
    return this.attachment(syoteArvo, syote).map(item => {
        item.label = null;
        return item;
      }
    );
  }

  private labelOrAttachment(syoteArvo, syote: SyoteInterface): YhteenvetoItem[] {
    if (!syoteArvo.value) {
      return null;
    }
    return typeof syoteArvo.value === "boolean" ?
      [this.labelOnly(syoteArvo, syote)] :
      this.attachmentNoLabel(syoteArvo, syote);
  }

  private radioSelection(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const radioChoice: SyoteInterface = syote.options.radioOptions.find(option => option.value === syoteArvo.value);
    return new YhteenvetoItem(null, radioChoice?.label);
  }

  private singleSelectionLabel(syoteArvo): YhteenvetoItem {
    return new YhteenvetoItem(null, this.lomakeDataDict[syoteArvo.value], syoteArvo.field);
  }

  private singleSelectionDate(syoteArvo) {
    const pvm = syoteArvo.value as DateObj;
    const htmlId = this.lomakeDataDict[syoteArvo.field].key;
    const valueText = pvm !== null ? new Teksti(`${pvm.day}.${pvm.month}.${pvm.year}`) : null;
    return new YhteenvetoItem(null, valueText, htmlId);
  }

  private singleSelectionDateAndLabel(syoteArvo) {
    const yhteenvetoItem = this.singleSelectionDate(syoteArvo);
    yhteenvetoItem.label = this.lomakeDataDict[syoteArvo.field];
    return yhteenvetoItem;
  }

  private siemenpakkaamoLaiteHyvaksytty(syoteArvo) {
    return this.singleSelectionDate(syoteArvo);
  }

  private siemenpakkaamoCheckboxAndDateArray(syoteArvo): YhteenvetoItem[] {
    const val = syoteArvo?.value;
    const lomakeArvo = this.lomakeDataDict[syoteArvo.field];
    const view = lomakeArvo.view;
    const yhteenvetoItems: YhteenvetoItem[] = [];
    const translations = this.lomakeDataOptions[syoteArvo.field].translate;

    val.forEach((value, index) => {
      const checkbox = value?.checkbox;
      const date = value?.date as DateObj;
      if (value?.hasOwnProperty("checkbox")) {
        yhteenvetoItems.push(new YhteenvetoItem(
          null,
          new Teksti(`${index + 1}: ${this.attributeService.simpleTranslate(view, translations.checkbox(checkbox))}`),
          `${syoteArvo.field}-checkbox-${index}`));
      }
      if (date) {
        yhteenvetoItems.push(new YhteenvetoItem(
          null,
          new Teksti(`- ${this.attributeService.simpleTranslate(view, translations.date)} ${date.month}/${date.year}`),
          `${syoteArvo.field}-date-${index}`));
      }
    });
    return yhteenvetoItems;
  }

  private siemenpakkaamoDateArray(syoteArvo): YhteenvetoItem[] {
    const val = syoteArvo?.value;
    const lomakeArvo = this.lomakeDataDict[syoteArvo.field];
    const view = lomakeArvo.view;
    const yhteenvetoItems: YhteenvetoItem[] = [];
    const translations = this.lomakeDataOptions[syoteArvo.field].translate;

    val.forEach((value, index) => {
      const date = value?.date as DateObj;
      if (date) {
        yhteenvetoItems.push(new YhteenvetoItem(
          null,
          new Teksti(`${index + 1}: ${this.attributeService.simpleTranslate(view, translations.date)} ${date.month}/${date.year}`),
          `${syoteArvo.field}-date-${index}`));
      }
    });
    return yhteenvetoItems;
  }

  private siemenpakkaamoValueOnly(syoteArvo) {
    const htmlId = this.lomakeDataOptions[syoteArvo.field].yhteenvetoId;
    return new YhteenvetoItem(null, new Teksti(syoteArvo.value), htmlId);
  }

  private attachmentDate(syoteArvo): YhteenvetoItem | YhteenvetoItem[] {
    const isLiiteSelected = syoteArvo.value.isLiiteSelected;
    if (isLiiteSelected) {
      const yhteenvetoList = [];
      syoteArvo.value.liite.forEach(tiedosto => {
        const yhteenvetoItem = new YhteenvetoItem(this.lomakeDataOptions[syoteArvo.field].liite, new Teksti(tiedosto.tiedostonimi));
        yhteenvetoItem.downloadLink = tiedosto;
        yhteenvetoList.push(yhteenvetoItem);
      });
      return yhteenvetoList;
    } else {
      const pvm = syoteArvo.value.pvm as DateObj;
      const label = this.lomakeDataOptions[syoteArvo.field].pvm;
      const htmlId = this.lomakeDataDict[syoteArvo.field].key;
      const valueText = pvm !== null ? new Teksti(`${pvm.day}.${pvm.month}.${pvm.year}`) : null;
      return new YhteenvetoItem(label, valueText, htmlId);
    }
  }


  private luomuluvat(syoteArvo): YhteenvetoItem[] {

    const val = syoteArvo?.value;
    if (!(val && val instanceof Array)) {
      return null;
    }

    const yhteenvedot: YhteenvetoItem[] = [];

    val.map(o => new Luomulupa(o.laji, o.lajike, o.kayttomaara, o.peruste, o.perustelut, o.tyyppi, o.liitteet, o.lupaTyyppi))
      .filter(lupa => !lupa.isEmpty())
      .forEach((lupa, htmlId) => {

        const lupaYhteenveto = new YhteenvetoItem(
          new Teksti("", "lajikeYhteenveto", "luomuPoikkeus9c"),
          new Teksti(this.createLuomulupaOtsikko(lupa)),
          lupa.lupaTyyppi + "-lajikeYhteenveto_" + htmlId
        );

        lupaYhteenveto.subitems = [];

        const kayttomaara = lupa.kayttomaara || "0";
        if (lupa.tyyppi === "SIEMEN") {
          lupaYhteenveto.subitems.push(new YhteenvetoItem(
            new Teksti("", "lupaKayttomaaraSiemen", "luomuPoikkeus9c"),
            new Teksti(kayttomaara + " " + this.attributeService.simpleTranslate("luomuPoikkeus9c", "kiloa", null, "")), lupa.lupaTyyppi + "-lajikeMaara_" + htmlId));
        } else {
          lupaYhteenveto.subitems.push(new YhteenvetoItem(
            new Teksti("", "lupaKayttomaaraTaimen", "luomuPoikkeus9c"),
            new Teksti(kayttomaara + " " + this.attributeService.simpleTranslate("luomuPoikkeus9c", "kappaletta", null, "")), lupa.lupaTyyppi + "-lajikeMaara_" + htmlId));
        }

        if (lupa.peruste && ["A", "B", "C", "D", "E"].includes(lupa.peruste.toUpperCase())) {
          lupaYhteenveto.subitems.push(new YhteenvetoItem(new Teksti("", "perusteYhteenveto", "luomuPoikkeus9c"), new Teksti("", "lupaPerusteTavanomYhteenveto" + lupa.peruste.toUpperCase(), "luomuPoikkeus9c"), lupa.lupaTyyppi + "-luomulupa_peruste_" + htmlId));
        }

        if (lupa.perustelut && lupa.perustelut.trim() !== "") {
          lupaYhteenveto.subitems.push(new YhteenvetoItem(new Teksti("", "perustelutYhteenveto", "luomuPoikkeus9c"), new Teksti(lupa.perustelut), lupa.lupaTyyppi + "-luomulupa_perustelut_" + htmlId));
        }

        if (lupa.liitteet) {
          lupa.liitteet.forEach(tiedosto => {
            const yhteenvetoItem = new YhteenvetoItem(new Teksti("", "lupaLiiteYhteenveto", "luomuPoikkeus9c"), new Teksti(tiedosto.tiedostonimi), lupa.lupaTyyppi + "-lupaLiiteYhteenveto_" + htmlId);
            yhteenvetoItem.downloadLink = tiedosto;
            lupaYhteenveto.subitems.push(yhteenvetoItem);
          });
        }
        yhteenvedot.push(lupaYhteenveto);
      });

    return yhteenvedot;
  }

  private createLuomulupaOtsikko(lupa: Luomulupa) {
    const otsikkoKuvaus = lupa.lajike?.kuvaus || "";
    const erotin = otsikkoKuvaus ? ", " : "";
    return `${(lupa.laji?.nimi || "")}${erotin}${otsikkoKuvaus}`;
  }

  private siirtymaajanLyhennysluvat(syoteArvo): YhteenvetoItem[] {
    let val = syoteArvo.value;

    if (val && val instanceof Array && val.length > 0 && !(val[0] instanceof SiirtymaajanLyhennysLupa)) {
      val = val.map(o => new SiirtymaajanLyhennysLupa(o.kasvulohkot, o.pintaala, o.perustelut, o.liitteet));
    }

    const luvat = val as SiirtymaajanLyhennysLupa[];

    const yhteenvedot: YhteenvetoItem[] = [];

    let htmlId = 0;

    for (const lupa of luvat) {
      const lupaYhteenveto = new YhteenvetoItem(new Teksti("", "kasvulohkot", "luomuPoikkeus9sl"), new Teksti(lupa.kasvulohkot), "kasvulohkotYhteenveto" + htmlId);
      lupaYhteenveto.subitems = [];

      if (lupa.kasvulohkot && lupa.kasvulohkot.trim() !== "") {
        lupaYhteenveto.subitems.push(new YhteenvetoItem(new Teksti("", "kasvulohkot", "luomuPoikkeus9sl"), new Teksti(lupa.kasvulohkot), "kasvulohkot_" + htmlId));
      }

      if (lupa.pintaala) {
        lupaYhteenveto.subitems.push(new YhteenvetoItem(new Teksti("", "lohkojenPintaAla", "luomuPoikkeus9sl"), new Teksti(lupa.pintaala), "pintaala_" + htmlId));
      }

      if (lupa.perustelut && lupa.perustelut.trim() !== "") {
        lupaYhteenveto.subitems.push(new YhteenvetoItem(new Teksti("", "perustelutYhteenveto", "luomuPoikkeus9c"), new Teksti(lupa.perustelut), "luomulupa_perustelut_" + htmlId));
      }

      if (lupa.liitteet) {
        lupa.liitteet.forEach(tiedosto => {
          const yhteenvetoItem = new YhteenvetoItem(new Teksti("", "lupaLiiteYhteenveto", "luomuPoikkeus9c"), new Teksti(tiedosto.tiedostonimi));
          yhteenvetoItem.downloadLink = tiedosto;
          lupaYhteenveto.subitems.push(yhteenvetoItem);
        });
      }

      yhteenvedot.push(lupaYhteenveto);
      htmlId++;
    }

    return yhteenvedot;
  }

  private elainTuotantoLupaSl(syoteArvo): YhteenvetoItem[] {
    let val = syoteArvo.value;

    if (val && val instanceof Array && val.length > 0 && !(val[0] instanceof ElainTuotantoLupaSl)) {
      val = val.map(o => new ElainTuotantoLupaSl(o.elainSiirtymaajanLyhennysValinta));
    }

    const luvat = val as ElainTuotantoLupaSl[];
    const yhteenvedot: YhteenvetoItem[] = [];

    for (const lupa of luvat) {
      const lupaYhteenvetoContent = lupa?.elainSiirtymaajanLyhennysValinta?.trim() !== "" ?
        lupa.elainSiirtymaajanLyhennysValinta.trim() :
        '-';

      const lupaYhteenveto = new YhteenvetoItem(new Teksti(""), new Teksti(lupaYhteenvetoContent),
        "elainSiirtymaajanLyhennysYhteenveto"); // TODO: kaikki saavat saman id:n, miksi?

      lupaYhteenveto.subitems = [];

      yhteenvedot.push(lupaYhteenveto);
    }

    return yhteenvedot;
  }

  private labelAndYesOrDate(syoteArvo): YhteenvetoItem[] {
    const val = syoteArvo?.value;
    const field = syoteArvo?.field;

    if (val) {
      const formattedValue = val instanceof DateObj ?
        new Teksti(DateObj.formattedDate(val)) :
        YhteenvetoItem.KYLLA_TEKSTI;

      return [
        new YhteenvetoItem(
          this.lomakeDataDict[field],
          formattedValue,
          field
        )
      ];
    }
    return [];
  }

  private labelAndYes(syoteArvo): YhteenvetoItem[] {
    const val = syoteArvo?.value;
    const field = syoteArvo?.field;

    if (val) {
      return [
        new YhteenvetoItem(
          this.lomakeDataDict[field],
          YhteenvetoItem.KYLLA_TEKSTI,
          field
        )
      ];
    }
    return [];
  }

  private muutosilmoitusLisatietoja(syoteArvo): YhteenvetoItem[] {
    const val = syoteArvo.value;
    const field = syoteArvo.field;
    let ilmoitusYhteenveto;
    const yhteenvedot: YhteenvetoItem[] = [];

    if (val && field === "lisatiedot") {
      ilmoitusYhteenveto = new YhteenvetoItem(null, new Teksti(val), "muutosilmoitusLisatietoja");
    }

    yhteenvedot.push(ilmoitusYhteenveto);

    return yhteenvedot;
  }

  private lannoitekansallinentoiminta(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const val = syoteArvo.value;

    if (val?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }

    const subItems = Object.entries(val)
      .filter(([_key, value]) => value && value["maara"])
      .map(([key, value]) => this.createMaaraSubItem(syote, value, syote.findChild([key]).label));

    const vanhanLainsaadannonTyyppinimet = val.vanhanLainsaadannonMukaanTuonti?.kansallinenTaiEYtyyppinimitunnus || [];
    vanhanLainsaadannonTyyppinimet.filter(t => t["tyyppiNimi"])
      .forEach((tyyppinimi, i) => {
        const tyyppinimiLabelIfFirst = i === 0 ? syote.findChild(["vanhanLainsaadannonMukaanTuonti"]).label : null;
        subItems.push(new YhteenvetoItem(
          tyyppinimiLabelIfFirst,
          this.getTyyppiNimiValueMap(tyyppinimi),
          'id-' + i,
          null,
          YhteenvetoTyyppi.LABEL_AND_VALUE_ARRAY));
      });

    const yhteenveto = new YhteenvetoItem(syote.label, new Teksti(val), "lannoitekansallinentoiminta", [], null, TuoteluokatYhteenvetoComponent);
    yhteenveto.subitems = subItems;
    yhteenveto.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    return subItems.length > 0 ? yhteenveto : null;
  }

  private lannoiteMuuToiminta(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const val = syoteArvo.value;
    if (val?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }
    if (val["maara"] === null) {
      return null;
    }
    const subItem: YhteenvetoItem = this.createMaaraSubItem(syote, val, syote.label);
    const yhteenveto = new YhteenvetoItem(syote.label, new Teksti(val), "lannoiteMuuToiminta", [], YhteenvetoTyyppi.LANNOITE_MUU_TOIMINTA, LannoiteMuuToimintaComponent);
    yhteenveto.subitems = [subItem];
    yhteenveto.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    return yhteenveto;
  }

  private lannoiteSivutuote(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    const val = syoteArvo.value;

    if (val?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }

    const yhteenveto = new YhteenvetoItem(syote.label, new Teksti(val), "lannoitesivutuotetoimija", [], YhteenvetoTyyppi.LANNOITE_SIVUTUOTE, LannoiteSivutuoteComponent);

    yhteenveto.subitems = Object.entries(val).map(([key, value]) => {
      const item = new YhteenvetoItem(syote.findChild([key]).label, null, null, null);
      const subs: YhteenvetoItem[] = [];

      if (key === "kasiteltavatRaakaaineet") {

        item.summaryType = YhteenvetoTyyppi.LANNOITE_SIVUTUOTE_RAAKAAINEET;
        Object.entries(value).filter(([keyItem, valueItem]) => valueItem && keyItem !== 'muu').forEach(([keyItem, valueItem]) => {
          const syoteItem = syote.findChild([key, keyItem]);
          if (keyItem !== 'muuInfo' && valueItem) {
            subs.push(new YhteenvetoItem(syoteItem.label, null, `${syoteArvo.field}-${keyItem}`, null));
          } else if (valueItem) {
            subs.push(new YhteenvetoItem(syoteItem.label, new Teksti(valueItem), `${syoteArvo.field}-${keyItem}`, null));
          }
        });

      } else if (key === "ymparistolupa") {

        const ymparistolupaValue = value["toiminnallaOnYmparistolupa"];
        const luvanNumValue = value["luvanNum"];

        if (ymparistolupaValue && luvanNumValue) {
          const ymparistolupaObj = {
            toiminnallaOnYmparistolupaKey: syote.findChild([key, "toiminnallaOnYmparistolupa"]).label,
            toiminnallaOnYmparistolupa: ymparistolupaValue ? new Teksti(null, 'kylla', 'teksti') : new Teksti(null, 'ei', 'teksti'),
            luvanNumKey: syote.findChild([key, "luvanNum"]).label,
            luvanNum: new Teksti(luvanNumValue)
          };
          item.summaryType = YhteenvetoTyyppi.LANNOITE_SIVUTUOTE_YMPARISTOLUPA;
          subs.push(new YhteenvetoItem(ymparistolupaObj.toiminnallaOnYmparistolupaKey, ymparistolupaObj, `${syoteArvo.field}-${ymparistolupaObj.toiminnallaOnYmparistolupaKey.label}`));
        }
      } else if (key === "laitosHyvaksynta") {
        item.summaryType = YhteenvetoTyyppi.LANNOITE_SIVUTUOTE_LAITOSHYVAKSYNTA;
        Object.entries(value).filter(([keyItem, valueItem]) => valueItem && keyItem !== 'laitosHyvaksyntaRadio').forEach(([keyItem, valueItem]) => {
          if (keyItem === 'laitosHyvaksyntaMuuInfo') {
            subs.push(new YhteenvetoItem(syote.findChild([key, keyItem]).label, new Teksti(valueItem), `${syoteArvo.field}-${keyItem}`));
          } else {
            subs.push(new YhteenvetoItem(syote.findChildByValue([key, keyItem], valueItem).label, new Teksti(valueItem), `${syoteArvo.field}-${keyItem}`));
          }
        });
      }
      item.subitems = subs;

      return item.subitems.length > 0 ? item : null;
    });
    yhteenveto.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    yhteenveto.subitems.sort((a, b) => a?.htmlId.localeCompare(b?.htmlId));
    return yhteenveto.subitems.every(v => v === null) ? null : yhteenveto;
  }

  private createToiminnanLopetusYhteenveto(syote: SyoteInterface) {
    const yhteenveto = new YhteenvetoItem(syote.label, null, `yhteenveto-${syote.name}`, null, null, LannoiteLopetaToimintaComponent);
    yhteenveto.subitems = [
      new YhteenvetoItem(
        new Teksti('Toiminta lopetetaan', 'yhtToimintaLopetetaan', 'lannoiterekisteri'),
        null,
        `yhteenveto-${syote.name}-lopeta`,
        null,
        null)];

    return yhteenveto;
  }

  private createMaaraSubItem(syote: SyoteInterface, val, label) {
    const maaraValue = {
      maaraTeksti: syote.options.yhteenvetoMaaraTeksti,
      maara: new Teksti(val["maara"])
    };
    return new YhteenvetoItem(label, maaraValue, null, null, YhteenvetoTyyppi.LABEL_AND_VALUE);
  }

  private getTyyppiNimiValueMap(tyyppinimiValue): any {
    return {
      tyyppiNimi: TYYPPINIMI_MAP[tyyppinimiValue.tyyppiNimi],
      maara: new Teksti(tyyppinimiValue.maara)
    };
  }

  private lannoitevalmisteenJakelija(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (syoteArvo.value?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }

    const parent = new YhteenvetoItem(syote.label, null, null, null, null, LannoitevalmisteenjakelijaYhteenvetoComponent);
    parent.subitems = Object.entries(syoteArvo.value)
      .filter(([_key, value]) => value)
      .filter(([_key, value]) => value["maaraSuomi"] || value["maaraSisamarkkinat"])
      .map(([key, value]) => {
        const {maaraSuomi, maaraSisamarkkinat} = value as any;
        return new YhteenvetoItem(syote.findChild([key]).label, {
          maaraSuomi: new Teksti(maaraSuomi),
          maaraSisamarkkinat: new Teksti(maaraSisamarkkinat)
        });
      });
    parent.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};

    return parent.subitems.length > 0 ? parent : null;
  }

  private static sortAinesosaValinnat(a: YhteenvetoItem, b: YhteenvetoItem) {
    const first = parseInt(a.label.label.match(/\d+/).toString());
    const second = parseInt(b.label.label.match(/\d+/).toString());

    return first - second;
  }

  private lannoitevalmisteAinesosaluokat(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (syoteArvo.value?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }

    const parent = new YhteenvetoItem(syote.label, null, null, null, null, LannoitevalmisteAinesosaluokatYhteenvetoComponent);
    parent.subitems = Object.entries(syoteArvo.value)
      .filter(([_key, value]) => value)
      .filter(([_key, value]) => Object.values(value).some(v => v))
      .map(([lannoiteAineKey, lannoiteAineValue]) => {

        const aolValinnat: YhteenvetoItem[] = [];
        Object.entries(lannoiteAineValue).forEach(([aolKey, isSelected]) => {
          if (aolKey.includes('aol') && isSelected) {
            const aolSyote = syote.findChild([lannoiteAineKey, aolKey]);
            aolValinnat.push(new YhteenvetoItem(aolSyote.label, null, `${syoteArvo.field}-${aolKey}`));
          }
        });

        aolValinnat.sort(YhteenvetoService.sortAinesosaValinnat);

        const valmistusTiedot: any = {};

        const maara = lannoiteAineValue['maara'];
        if (maara) {
          valmistusTiedot.maara = new Teksti(maara);
        }

        const omaKaytto = lannoiteAineValue['lannoitteenValmistusOmaanKayttoon'];
        if (typeof omaKaytto === 'boolean') {
          valmistusTiedot.omaKaytto = omaKaytto ? new Teksti(null, 'kylla', 'teksti') : new Teksti(null, 'ei', 'teksti');
        }

        const vainPakkaamista = lannoiteAineValue['lannoitteenValmistusAinoastaanPakkaamista'];
        if (typeof vainPakkaamista === 'boolean') {
          valmistusTiedot.vainPakkaamista = vainPakkaamista ? new Teksti(null, 'kylla', 'teksti') : new Teksti(null, 'ei', 'teksti');
        }

        const lannoiteAine = new YhteenvetoItem(syote.findChild([lannoiteAineKey]).label, valmistusTiedot);
        lannoiteAine.subitems = aolValinnat;
        return lannoiteAine;
      });
    parent.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    return parent.subitems.length > 0 ? parent : null;
  }

  private lannoiteVarastointi(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (syoteArvo.value?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }

    if (!Object.values(syoteArvo.value).some(v => v)) {
      return null;
    }

    const parent = new YhteenvetoItem(syote.label, null, null, null, null, LannoitevarastointiComponent);

    parent.subitems = Object.entries(syoteArvo.value)
      .filter(([_lannoiteAine, isSelected]) => isSelected)
      .map(([lannoiteAine, _isSelected]) => {
        const childSyote = (syote.children as SyoteInterface[]).find(s => s.name === lannoiteAine);
        return new YhteenvetoItem(childSyote.label);
      });
    parent.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    return parent.subitems.length > 0 ? parent : null;
  }

  private lannoiteEuToiminta(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (syoteArvo.value?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }
    const parent = new YhteenvetoItem(syote.label, null, null, null, null, LannoiteEuToimintaComponent);
    const arr: YhteenvetoItem[] = [];
    Object.keys(syoteArvo.value).forEach((v, i) => {
      const pfcSyoteChild = syote.findChild([v]);
      const pfcYhteenvetoItem = new YhteenvetoItem(pfcSyoteChild.label, null, `${syote.label.fullKey}_${pfcSyoteChild.label.fullKey}_${i}`, null, null, null);
      const pfcValues = syoteArvo.value[v] || {};
      const pfcContentArray = Object.entries(pfcValues).filter(([_key, value]) => value['maara']).map(([key, value]) => new YhteenvetoItem(pfcSyoteChild.findChild([key]).label, {maara: new Teksti(value['maara'])}, `${pfcSyoteChild}_${key}`));
      if (pfcContentArray.length > 0) {
        pfcYhteenvetoItem.subitems = Object.values(pfcContentArray);
        arr.push(pfcYhteenvetoItem);
      }
    });
    parent.subitems = arr;
    parent.options = {
      isMuutosIlmoitus: syote.options?.isMuutosIlmoitus,
      maaraTeksti: syote.options.yhteenvetoMaaraTeksti
    };
    return arr.length > 0 ? parent : null;
  }

  private lannoiteEuJakelija(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (syoteArvo.value?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }
    const parent = new YhteenvetoItem(syote.label, null, null, null, null, LannoiteEuJakelija);
    const arr: YhteenvetoItem[] = [];
    Object.keys(syoteArvo.value).forEach((v, i) => {

      const pfcSyoteChild = syote.findChild([v]);
      const pfcItem = new YhteenvetoItem(pfcSyoteChild.label, null, `${syote.label.fullKey}_${pfcSyoteChild.label.fullKey}_${i}`);
      const pfcValues = syoteArvo.value[v] || {};
      const maaraArray = Object.entries(pfcValues)
        .filter(([_key, value]) => this.filterNullValues(Object.values(value)))
        .map(([key, value]) => {
          const lannoiteAineChild = pfcSyoteChild.findChild([key]);
          const {maaraSuomi, maaraSisamarkkinat} = value as any;
          const lannoiteAineValue = {
            maaraSuomi: new Teksti(maaraSuomi),
            maaraSisamarkkinat: new Teksti(maaraSisamarkkinat)
          };
          return new YhteenvetoItem(lannoiteAineChild.label, lannoiteAineValue);
        });
      if (maaraArray.length > 0) {
        pfcItem.subitems = maaraArray;
        arr.push(pfcItem);
      }

    });
    parent.subitems = arr;
    parent.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    return arr.length > 0 ? parent : null;
  }

  private lannoiteEuToimintaValmistajaValmistuttaja(syoteArvo, syote: SyoteInterface): YhteenvetoItem {
    if (syoteArvo.value?.lopetaToiminta) {
      return this.createToiminnanLopetusYhteenveto(syote);
    }
    const parent = new YhteenvetoItem(syote.label, null, null, null, null, LannoiteEuToimintaValmistajaValmistuttajaComponent);
    const arr: YhteenvetoItem[] = [];
    Object.keys(syoteArvo.value).forEach((v, i) => {
      const pfcSyoteChild = syote.findChild([v]);
      const pfcYhteenvetoItem = new YhteenvetoItem(pfcSyoteChild.label, null, `${syote.label.fullKey}_${pfcSyoteChild.label.fullKey}_${i}`, null, null, null);
      const pfcValues = syoteArvo.value[v] || {};

      const pfcContentArray = Object.entries(pfcValues)
        .filter(([_key, value]) => this.filterNullValues(Object.values(value)))
        .map(([key, value]) => {
          const cmc = this.createCmcArray(value, pfcSyoteChild, key, syoteArvo);
          const valmistusTiedot = this.createValmistusTiedot(value);

          const item = new YhteenvetoItem(pfcSyoteChild.findChild([key]).label, valmistusTiedot);
          if (cmc.length > 0) {
            item.subitems = cmc;
          }
          return item;
        });

      if (pfcContentArray.length > 0) {
        pfcYhteenvetoItem.subitems = Object.values(pfcContentArray);
        arr.push(pfcYhteenvetoItem);
      }
    });

    parent.subitems = arr;
    parent.options = {isMuutosIlmoitus: syote.options?.isMuutosIlmoitus};
    return arr.length > 0 ? parent : null;
  }

  private createCmcArray(value, pfcSyoteChild: SyoteInterface, key: string, syoteArvo) {
    const cmc: YhteenvetoItem[] = [];
    Object.entries(value).forEach(([cmcKey, isSelected]) => {
      if (cmcKey.includes('cmc') && isSelected) {
        const aolSyote = pfcSyoteChild.findChild([key, cmcKey]);
        cmc.push(new YhteenvetoItem(aolSyote.label, null, `${syoteArvo.field}-${cmcKey}`));
      }
    });
    cmc.sort(YhteenvetoService.sortAinesosaValinnat);
    return cmc;
  }

  private createValmistusTiedot(value) {
    const valmistusTiedot: any = {};
    const maara = value['maara'];
    if (maara) {
      valmistusTiedot.maara = new Teksti(maara);
    }
    const vainPakkaamista = value['lannoitteenValmistusAinoastaanPakkaamista'];
    if (typeof vainPakkaamista === 'boolean') {
      valmistusTiedot.vainPakkaamista = vainPakkaamista ? new Teksti(null, 'kylla', 'teksti') : new Teksti(null, 'ei', 'teksti');
    }
    return valmistusTiedot;
  }

  private filterNullValues(values) {
    let b = false;
    values.forEach(v => {
      if (typeof v === 'object' && v !== null) {
        b = this.filterNullValues(Object.values(v));
      } else {
        if (v != null) {
          b = true;
        }
      }
    });
    return b;
  }
}
