import {Injectable} from "@angular/core";
import {Allekirjoitus, LomakeKasittely, LomakeResponse} from "../touko-lomake-utils";
import {jsPDF} from "jspdf";
import {YhteenvetoHtmlBuilder} from "./yhteenveto-html-builder";

/**
 * Created by Seppo on 06/02/2019.
 */

@Injectable()
export class TarkastusPDFService {

  /**
   * Lataa käyttäjän koneelle PDF-tiedoston yhteenvedosta
   *
   * @param kasittely - Lomakkeen käsittelytiedot
   * @param lomake - Lomake
   * @param allekirjoitus - Lomakkeen allekirjoitus
   * @param titleHtml - Otsikon HTML-elementti
   * @param contentHtml - Sisällön HTML-elementti, yhteenveto
   */
  downloadTarkastusAsPdf(kasittely: LomakeKasittely, lomake: LomakeResponse, allekirjoitus: Allekirjoitus, titleHtml, contentHtml): Promise<void> {

    const doc = new jsPDF('p', 'pt');
    contentHtml.style.width = '1150px';
    const options = {
      x: 10,
      html2canvas: {
        scale: 0.5,
      },
      callback: (newDoc: jsPDF) => {
        const filename = `${allekirjoitus.valvontakohde}_${lomake.asia}_${lomake.id}.pdf`;
        newDoc.save(filename);
      },
    };

    const yhteenvetoHtml = new YhteenvetoHtmlBuilder(titleHtml, kasittely, contentHtml);
    yhteenvetoHtml.applyYhteenvetoHTML();

    return doc.html(yhteenvetoHtml.content, options)
        .then(() => {
          yhteenvetoHtml.resetOriginalElement();
        })
        .catch(() => {
          yhteenvetoHtml.resetOriginalElement();
        });
  }

  /**
   * Luo yhteenvedosta pdf -tiedoston.
   */
  createYhteenvetoPdf(kasittely: LomakeKasittely, allekirjoitus: Allekirjoitus, titleHtml, contentHtml: any): Promise<any> {
    let blob: Blob;

    const doc = new jsPDF('p', 'pt');
    contentHtml.style.width = '1150px';

    const options = {
      x: 10,
      html2canvas: {
        scale: 0.5,
      },
      callback: (newDoc: jsPDF) => {
        blob = newDoc.output('blob');
      },
    };

    const yhteenvetoHtml = new YhteenvetoHtmlBuilder(titleHtml, kasittely, contentHtml);
    yhteenvetoHtml.applyYhteenvetoHTML();

    return doc.html(contentHtml, options)
        .then(() => {
          yhteenvetoHtml.resetOriginalElement();
          return blob;
        })
        .catch(() => {
          yhteenvetoHtml.resetOriginalElement();
        });
  }

}
