import {Component, forwardRef, Input} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {Kasvilaji, KasvilajiSorter} from '../../../kasvi/kasvilaji.model';
import {map, switchMap} from 'rxjs/operators';
import {NgbModal, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {KasviService} from '../../../kasvi/kasvi.service';
import {AttributeService} from '../../../tiira/attribute.service';
import {ToukoUusiKasvilajiModal, ToukoUusiKasviLajiModalResponse} from './touko-uusi-kasvi-laji-modal.component';


@Component({
  selector: 'kasvilaji-monivalinta',
  templateUrl: './touko-kasvilaji-monivalinta.component.html',
  styleUrls: ['./touko-kasvilaji-monivalinta.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToukoKasvilajiMonivalintaComponent),
    }]
})
export class ToukoKasvilajiMonivalintaComponent implements ControlValueAccessor {

  public constructor(public controlContainer: ControlContainer, private kasviService: KasviService, private attribute: AttributeService, private modalService: NgbModal) {
  }

  @Input('value') _value: Kasvilaji[] = [];
  onChange: any = () => {
    // This is intentional
  };
  onTouched: any = () => {
    // This is intentional
  };

  get value(): Kasvilaji[] {

    if (!this._value || !Array.isArray(this._value)) {
      this._value = [];
    }

    return this._value;
  }

  set value(val: Kasvilaji[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }


  haeKasvilaji = (teksti: Observable<string>): Observable<Kasvilaji[]> => teksti.pipe<Kasvilaji[]>(
      switchMap<string, Observable<Kasvilaji[]>>((hakusana: string) =>
        this.kasviService.haeLajit(hakusana, 10)
          .pipe<Kasvilaji[]>(map<Kasvilaji[], Kasvilaji[]>(lajit => (hakusana.length > 1) ? lajit.concat([{elmoid: "UUSI", nimi: this.attribute.simpleTranslate("luomu", "uusiKasvilaji", null, "Uusi kasvilaji ja lajike") }]) : lajit))
      ));

  muotoileKasvilaji(laji: Kasvilaji): string {
    return laji.nimi;
  }

  private lisaaLaji(laji: Kasvilaji) {
    this.value.push(laji);
    this.value.sort(KasvilajiSorter);
  }

  onSelectKasvilaji(payload: NgbTypeaheadSelectItemEvent, kasviValinta: HTMLInputElement) {

    const kasvilaji = payload.item as Kasvilaji;

    if (kasvilaji.elmoid === "UUSI") {

      const modalref = this.modalService.open(ToukoUusiKasvilajiModal);
      modalref.componentInstance.vainLaji = true;
      modalref.result.then((paluu: ToukoUusiKasviLajiModalResponse) => {
        this.lisaaLaji(paluu.laji);
      });
    } else {
      this.lisaaLaji(kasvilaji);
    }

    kasviValinta.value = '';

    payload.preventDefault();

  }

  poistaLaji(index: number) {
    const lajit = this.value;

    lajit.splice(index, 1);

    this.value = lajit;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: Kasvilaji[]): void {
    if (value) {
      this.value = value;
    }
  }


}
