import {Component, Input} from "@angular/core";
import {SummaryItem} from "ngx-fielding";
import {SummaryComponentBase} from "./summary-container.component";
import {ResultType} from "./field-summary-compare/compare.service";

@Component({
  selector: 'title-and-answer-summary',
  template: `
    <h2 [attribute]="item?.question" [ngClass]="{'invisible': compareItemType}" class="mb-2 mt-4"></h2>
    <compare-item [compareItemType]="compareItemType">
      <span [id]="'value-' + item.field.htmlId">{{item?.answer}}</span>
    </compare-item>
  `
})
export class TitleAndAnswerSummaryComponent implements SummaryComponentBase {
  @Input() item: SummaryItem<any>;
  @Input() compareItemType: ResultType;
}
