import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {
  Allekirjoitus,
  LOMAKE_KASITTELY_MESSAGE,
  LOMAKE_KUVAUKSET,
  LomakeConverter,
  LomakeJSONOld,
  LomakeKasittely,
  LomakeResponse,
  LomakeTila
} from "../touko-lomake-utils";
import {Subscription} from "rxjs";
import {RekisteriLopetusData} from "../rekisteri-lopetus/rekisteri-lopetus-data";
import {YhteenvetoService} from "../yhteenveto/yhteenveto.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LomakeService} from "../touko-lomake.service";
import {YhteenvetoGroup} from "../yhteenveto/yhteenveto-utils";
import {AccountService} from "../../account/account.service";
import {resolveLomakeData} from "../touko-lomakedata-constants";
import {TarkastusPDFService} from "./tarkastus-pdf.service";
import {Teksti} from "../../utils/teksti";
import {MessageService} from "../../message/message.service";
import {LomakeEndpointUtil} from "../../utils/lomake-endpoint-util";
import {AutoUnsubscribe} from "../../utils/auto-unsubscribe.decorator";

/**
 * Created by Seppo on 09/01/2019.
 */

@Component({
  templateUrl: 'tarkastus-lopetus.component.html',
  selector: 'tarkastus-lopetus',
})
@AutoUnsubscribe
export class TarkastusLopetusComponent implements OnInit {

  @ViewChild("lomakeYhteenvetoDiv", {static: true}) lomakeYhteenvetoDiv: ElementRef;
  @ViewChild("lomakeKuvausH2") lomakeKuvausH2: ElementRef;

  allekirjoitus: Allekirjoitus = new Allekirjoitus();
  lomakeKasittely: LomakeKasittely = null;
  lomakeSub: Subscription;

  currentLomake: LomakeResponse = null;

  lopetusLomake: YhteenvetoGroup[] = [];
  currentToiminta: YhteenvetoGroup[] = [];
  paramId = null;

  private readonly isViranomainen: boolean;

  constructor(private readonly yhteenvetoService: YhteenvetoService,
              private readonly tarkastusPdfService: TarkastusPDFService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly accountService: AccountService,
              private readonly lomakeService: LomakeService,
              private readonly messageService: MessageService,
              private readonly router: Router) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.yhteenvetoService.initYhteenvetoService();
      this.paramId = parseInt(param.id, 10);
      if (LomakeEndpointUtil.shouldUseV2Endpoint(param.asia, param.versio)) {
        this.accountService.isAsiakas() ?
            this.lomakeService.getLomakeV2Asiakas(this.paramId, param.asia, "lopetus") :
            this.lomakeService.getLomakeV2Viranomainen(this.paramId, param.asia, "lopetus");
      } else {
        this.lomakeService.getLomake(this.paramId);
      }
    }).unsubscribe();

    this.lomakeSub = this.lomakeService.currentLomake$.subscribe(response => {
      if (response && response.id === this.paramId) {
        this.currentLomake = response;
        const isV2Endpoint = LomakeEndpointUtil.shouldUseV2Endpoint(response.asia, response.lomaketyyppiVersio);

        let toiminnanHakuFn;
        if (isV2Endpoint) {
          toiminnanHakuFn = this.accountService.isAsiakas() ?
              this.lomakeService.getMuutoslomakeTiedotV2.bind(this.lomakeService, response.asia, response.toimintotyyppi, false) :
              this.lomakeService.getToiminnanTiedotViranomainenV2.bind(this.lomakeService, response.id, response.asia, response.toimintotyyppi);
        } else {
          toiminnanHakuFn = this.accountService.isAsiakas() ?
              this.lomakeService.getMuutoslomakeTiedot.bind(this.lomakeService, response.asia, false) :
              this.lomakeService.getToiminnanTiedotViranomainen.bind(this.lomakeService, response.id);
        }
        toiminnanHakuFn().then(values => {
          if (isV2Endpoint) {
            this.currentToiminta = this.yhteenvetoService.getYhteenveto(LomakeConverter.convertToJSON(response.asia, JSON.parse(values.sisalto).data), resolveLomakeData(response.asia));
            this.lopetusLomake = this.yhteenvetoService.getYhteenveto(LomakeConverter.convertToJSON(response.asia, JSON.parse(response.sisalto).data) as LomakeJSONOld, RekisteriLopetusData.getData(this.accountService.getCurrentAccount()));
          } else {
            this.currentToiminta = this.yhteenvetoService.getYhteenveto(JSON.parse(values.sisalto) as LomakeJSONOld, resolveLomakeData(response.asia, response.lomaketyyppiVersio, 'muutos'));
            this.lopetusLomake = this.yhteenvetoService.getYhteenveto(JSON.parse(response.sisalto) as LomakeJSONOld, RekisteriLopetusData.getData(this.accountService.getCurrentAccount()));
          }
        });

        const isKasittelyssa = LomakeTila[response.tila] === LomakeTila.KASITTELYSSA;
        const kasittelija = this.isViranomainen && isKasittelyssa ? response.muokannutToimija : null;
        this.allekirjoitus = Allekirjoitus.createFromLomake(response);
        this.lomakeKasittely = new LomakeKasittely(response.id, LomakeTila[response.tila], response.lisatieto, kasittelija, this.getKuvausTeksti(response.asia, response.toimintotyyppi));
      }
    });
  }

  getKuvausTeksti(asia: string, toimintotyyppi: string) {
    return LOMAKE_KUVAUKSET[asia][toimintotyyppi];
  }

  /**
   * Lähettää käsittelyn palvelimelle.
   *
   * @param lomakeKasittely lomakkeen käsittelyssä tarvittavat tiedot
   */
  lahetaKasittely(lomakeKasittely: LomakeKasittely) {
    const kasitteleLomakeFn = LomakeEndpointUtil.shouldUseV2Endpoint(this.currentLomake.asia, this.currentLomake.lomaketyyppiVersio) ?
        this.lomakeService.kasitteleLomakeV2(lomakeKasittely, this.currentLomake.asia, this.currentLomake.toimintotyyppi) :
        this.lomakeService.kasitteleLomake(lomakeKasittely);

    kasitteleLomakeFn.then(() => {
          this.messageService.notify({message: LOMAKE_KASITTELY_MESSAGE[lomakeKasittely.tila], type: "success"});
          this.router.navigate(['/asiointi/etusivu']);
        })
        .catch(err => {
          this.messageService.notify({
            message: new Teksti("Käsittelyvirhe", "errLomakkeenKasittelyvirhe", "lomakeYleinen"),
            type: "danger"
          });
        });
  }
}
