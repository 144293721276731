import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
  selector: "touko-alert-box",
  template: `
    <div [ngClass]="alertBoxStyle" class="alertbox d-flex justify-content-between align-items-baseline">
      <div class="d-flex align-items-baseline">
        <i [ngClass]="alertBoxIcon" class="mr-3 font-lg" aria-hidden="true"></i>
        <div class="font-sm info">
          <ng-content></ng-content>
        </div>
      </div>
      <button *ngIf="closable" type="button" class="btn-no-style" aria-label="Sulje" (click)="closeAlertBox()"><i class="fas fa-times text-primary" aria-hidden="true"></i></button>
    </div>
  `
  ,
  styleUrls: ['./alert-box.component.scss'],
})

export class AlertBoxComponent{
  @Input() alertType = "warning";
  @Input() closable = false;
  @Output() close: EventEmitter<any> = new EventEmitter();

  alertBoxStyle: string;
  alertBoxIcon: string;

  ngOnInit() {
    if (this.alertType === 'warning') {
      this.alertBoxStyle = 'alertbox-warning';
      this.alertBoxIcon = 'fas fa-info-circle text-warning';
    } else if (this.alertType === 'error') {
      this.alertBoxStyle = 'alertbox-error';
      this.alertBoxIcon = 'far fa-bell text-danger';
    }
  }

  closeAlertBox() {
    this.close.emit();
  }
}
