import {Component, Input} from "@angular/core";
import {YhteenvetoComponentBase} from "../../yhteenveto-container.component";
import {CompareStatus, YhteenvetoItem, YhteenvetoTyyppi} from "../../yhteenveto-utils";

@Component({
  selector: "lainnoite-muu-toiminta",
  templateUrl: "lainnoite-muu-toiminta.component.html",
  styleUrls: ["../yhteenveto-style.scss", "../../yhteenvet-item/yhteenveto-item.component.scss"]
})
export class LannoiteMuuToimintaComponent implements YhteenvetoComponentBase {
  @Input() item: YhteenvetoItem = null;
  @Input() prevItem: YhteenvetoItem = null;
  @Input() compareStatus?: CompareStatus = null;
  @Input() showCompareYhteenveto = false;
  public readonly yhteenvetoTyyppi = YhteenvetoTyyppi;
  itemStyle: string;

  ngOnInit(): void {
    this.itemStyle = this.compareStatus ?
      CompareStatus[this.compareStatus.valueOf()].toLowerCase() :
      null;
  }
}
