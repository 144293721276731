<table *ngIf="lomakeVersiot && lomakeVersiot.length > 0" class="table table-striped table-hover table-sm small">
  <thead>
  <tr>
    <th id="lomakeVersiotEtusivuVersio" attribute="etusivu.versio">Versio</th>
    <th id="lomakeVersiotEtusivuLahetetty" attribute="etusivu.lahetetty">Lähetetty</th>
    <th id="lomakeVersiotEtusivuKasitelty" attribute="etusivu.kasitelty">Käsitelty</th>
    <th id="lomakeVersiotEtusivuTila" attribute="etusivu.tila">Tila</th>
    <th id="lomakeVersiotEtusivuLisatiedot"attribute="etusivu.lisatiedot">Lisätiedot</th>
  </tr>
  </thead>
  <tbody style="cursor: pointer">
  <tr *ngFor="let lomake of lomakeVersiot" (click)="clickVersio(lomake)">
    <td><span attribute="etusivu.versio"></span> {{lomake.versio}}</td>
    <td>{{lomake.lahetysAika | date:'d.M.yyyy'}}</td>
    <td>{{lomake.viimeksiKasiteltyAika | date:'d.M.yyyy'}}</td>
    <td>
      <tila-badge [lomakeTila]="lomake.tila"></tila-badge>
    </td>
    <td>{{lomake.lisatieto}}</td>
  </tr>
  </tbody>
</table>
