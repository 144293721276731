import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Field, FieldArray, FieldControl, FieldGroup} from "ngx-fielding";
import {Validators} from "@angular/forms";

@Component({
  selector: 'peltolohko-modal',
  template: `
    <div class="modal-header">
      <h1 class="modal-title" attribute="luomu.lohkotietojenLisaaminenOtsikko">Lohkotietojen lisääminen</h1>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div>
      <div class="modal-body">
        <div class="col">
          <fieldset [formGroup]="esitayttoValinta" class="mb-2">
            <legend attribute="luomu.valitseEsitayttotapa" class="font-base mb-3">Valitse esitäyttötapa:</legend>
            <touko-radio-input radioGroup="esitayttotapa" htmlId="esitayttotapaVipu"
                               labelAttribute="luomu.esitayttotapaVipu" formControlName="esitaytto"
                               value="vipu"></touko-radio-input>
            <touko-radio-input radioGroup="esitayttotapa" htmlId="esitayttotapaKasin"
                               labelAttribute="luomu.esitayttotapaKasin" formControlName="esitaytto"
                               value="kasin"></touko-radio-input>
          </fieldset>

          <ng-container *ngIf="esitayttoValinta.get('esitaytto').value === 'vipu'">
            <div class="position-relative">
              <touko-text-input [placeholderAttribute]="'luomu.haeTunnuksellaTaiNimella' | attribute"
                                htmlId="haeTunnuksellaTaiNimella"
                                [value]="searchInput"
                                (keyup)="searchInput = $event.target.value"></touko-text-input>
              <i class="fa fa-search text-black-50 position-absolute search-icon" aria-hidden="true"></i>
            </div>
            <div class="d-flex align-items-center mb-5">
              <label class="switch">
                <input type="checkbox" id="naytaValitut" (change)="changeShowSelectedLohkot()">
                <span class="slider round"></span>
              </label>
              <label class="ml-3" for="naytaValitut" attribute="luomu.naytaValitut" id="naytaValitut-label"></label>
            </div>

            <table class="table" id="lohkotiedot-table">
              <thead>
              <tr class="row mx-0">
                <th id="lohkotunnus" class="h4 col-4 d-flex justify-content-between mb-0 p-3">
                  <touko-checkbox labelAttribute="luomu.tunnus" htmlId="tunnusLabel"
                                  marginBottom="mb-0"
                                  (change)="selectEveryPeruslohko($event.target.checked)"
                                  [checked]="everyPeltolohkoSelected"></touko-checkbox>
                  <button (click)="sortByKey('lohkotunnus')" class="btn-no-style">
                    <i class="fa fa-sort text-primary" aria-hidden="true"></i>
                  </button>
                </th>
                <th id="lohkoNimi" class="h4 col-4 d-flex justify-content-between mb-0 p-3">
                  <span attribute="luomu.nimi">Nimi</span>
                  <button (click)="sortByKey('lohkoNimi')" class="btn-no-style">
                    <i class="fa fa-sort text-primary" aria-hidden="true"></i>
                  </button>
                </th>
                <th id="LohkoPinta-ala" class="h4 col-4 d-flex justify-content-between mb-0 p-3">
                  <span attribute="luomu.pintaalaHa">Pinta-ala (ha)</span>
                  <button (click)="sortByKey('pintaAla')" class="btn-no-style">
                    <i class="fa fa-sort text-primary" aria-hidden="true"></i>
                  </button>
                </th>
              </tr>
              </thead>

              <tbody aria-live="polite" id="lohkotiedot-table-body" class="d-block overflow-auto list-height">
              <ng-container *ngIf="filteredLohkotiedotArray.length; else eiFilteredLohkotietoja">
                <ng-container *ngFor="let peruslohko of filteredLohkotiedotArray;">
                  <tr class="row mx-0">
                    <td class="font-weight-normal col-4 p-3">
                      <touko-checkbox [htmlId]="peruslohko.lohkotunnus"
                                      [labelAttribute]="peruslohko.lohkotunnus"
                                      (change)="onTogglePeruslohkoChecked(peruslohko, $event.target.checked)"
                                      [checked]="peruslohko.isSelected"
                                      marginBottom="mb-0"></touko-checkbox>
                    </td>
                    <td class="font-weight-normal col-4 p-3">{{peruslohko.lohkoNimi}}</td>
                    <td class="font-weight-normal col-4 p-3">{{peruslohko.pintaAla}}</td>
                  </tr>
                  <tr *ngFor="let kasvulohko of peruslohko.kasvulohkot" class="row mx-0">
                    <td class="font-weight-normal col-4 p-3 pl-6">
                      <touko-checkbox [htmlId]="kasvulohko.lohkotunnus"
                                      [labelAttribute]="kasvulohko.lohkotunnus"
                                      (change)="onToggleKasvulohkoChecked(kasvulohko, peruslohko, $event.target.checked)"
                                      [checked]="kasvulohko.isSelected"
                                      marginBottom="mb-0"></touko-checkbox>
                    </td>
                    <td class="font-weight-normal col-4 p-3">{{peruslohko.lohkoNimi}}</td>
                    <td class="font-weight-normal col-4 p-3">{{kasvulohko.pintaAla}}</td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-template #eiFilteredLohkotietoja>
                <div class="d-flex justify-content-center mt-5">
                  <p attribute="luomu.eiNaytettaviaLohkotietoja">Ei näytettäviä lohkotietoja</p>
                </div>
              </ng-template>
              </tbody>
            </table>
          </ng-container>

          <ng-container *ngIf="esitayttoValinta.get('esitaytto').value === 'kasin'">
            <div class="mb-4">
              <touko-alert-box>
                <p attribute="luomu.esitayttotapaKasinInfo" class="mb-0"></p>
              </touko-alert-box>
            </div>
            <div *ngFor="let lohko of peltolohkotKasinArray.controlFields; let i = index;" [formGroup]="lohko.asGroup()"
                 class="row">
              <touko-text-input [htmlId]="'lohkotietoNimi-' + i" formControlName="lohkoNimi" class="col-6"
                                labelAttribute="luomu.nimi" required></touko-text-input>
              <touko-text-input [htmlId]="'lohkotietoPintaala-' + i" formControlName="pintaAla" class="col-4"
                                labelAttribute="luomu.pintaalaHa" required></touko-text-input>
              <button [id]="'lohkotietoPoista-' + i" type="button" class="btn btn-link-primary col-1"
                      attribute="teksti.poista"
                      (click)="removePeltolohkoKasinGroup(i)">Poista
              </button>
            </div>
            <div *ngIf="peltolohkotKasinArray.invalid && submitted" class="mb-4">
              <touko-alert-box alertType="error">
                <p attribute="luomu.syotaKasinError" class="mb-0">Kaikilla syötetyillä peltolohkoilla tulee olla nimi ja
                  pinta-ala.</p>
              </touko-alert-box>
            </div>
            <button type="button" toukobutton class="btn-secondary" (click)="addPeltolohkoKasinGroup()">
              <i class="fa fa-plus-square"></i>
              <span attribute="teksti.uusi"></span>
            </button>
          </ng-container>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" id="button-peruuta" class="btn btn-outline-primary" attribute="teksti.peruuta"
                (click)="activeModal.dismiss('Cross click')"></button>
        <button type="button" id="button-valmis" class="btn btn-primary" attribute="teksti.valmis"
                (click)="addPeltolohkot()">
          Valmis
        </button>
      </div>
    </div>
  `,
  styleUrls: ['peltolohko-modal.component..scss']
})

export class PeltolohkoModalComponent implements OnInit {
  @Input() lohkotiedotArray: Array<any>;

  searchInput: string = "";
  lohkoDataModified = [];
  dataIsAscending = true;
  showOnlySelectedPeltolohkot = false;
  submitted = false;

  esitayttoValinta = new FieldGroup(Field.build(), {
    esitaytto: new FieldControl(Field.build())
  })

  peltolohkotKasinArray = new FieldArray(Field.build(), () => this.createLohkotiedotKasinGroup(), [this.createLohkotiedotKasinGroup()]);

  get filteredLohkotiedotArray() {
    return this.lohkotiedotArray.filter(peruslohko => {
      const filteredBySearch = peruslohko.lohkoNimi.toUpperCase().includes(this.searchInput.toUpperCase())
          || peruslohko.lohkotunnus.toUpperCase().includes(this.searchInput.toUpperCase())

      const someKasvulohkoIsSelected = peruslohko.kasvulohkot.some(kasvulohko => kasvulohko.isSelected);

      const kasvulohkoMatchesSearch = peruslohko.kasvulohkot.some(kasvulohko =>
          kasvulohko.lohkoNimi.toUpperCase().includes(this.searchInput.toUpperCase()) ||
          kasvulohko.lohkotunnus.toUpperCase().includes(this.searchInput.toUpperCase())
      );

      if (this.showOnlySelectedPeltolohkot) {
        return (filteredBySearch || kasvulohkoMatchesSearch) && (peruslohko.isSelected || someKasvulohkoIsSelected);
      } else {
        return filteredBySearch || kasvulohkoMatchesSearch;
      }
    });
  }

  constructor(public activeModal: NgbActiveModal) {
  }

  get everyPeltolohkoSelected() {
    if (this.lohkotiedotArray.length > 0) {
      return this.lohkotiedotArray.every(lohko => lohko.isSelected);
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.lohkotiedotArray.forEach(peruslohko => {
      peruslohko['isSelected'] = false;
      peruslohko.kasvulohkot.forEach(kasvulohko => kasvulohko['isSelected'] = false);
    })
  }

  createLohkotiedotKasinGroup(lohkotunnus?: string, lohkoNimi?: string, pintaAla?: number) {
    return new FieldGroup(Field.build(), {
      lohkoNimi: new FieldControl(Field.build(), lohkoNimi, Validators.required),
      pintaAla: new FieldControl(Field.build(), pintaAla, Validators.required)
    });
  }

  removePeltolohkoKasinGroup(index: number) {
    this.peltolohkotKasinArray.removeAt(index);

    if (!this.peltolohkotKasinArray.length) {
      this.peltolohkotKasinArray.push(this.peltolohkotKasinArray.buildField());
    }
  }

  addPeltolohkoKasinGroup() {
    this.peltolohkotKasinArray.push(this.peltolohkotKasinArray.buildField());
  }

  sortByKey(key): void {
    this.dataIsAscending = !this.dataIsAscending

    this.lohkotiedotArray.sort((a, b) => {
      const keyA = a[key];
      const keyB = b[key];

      if (this.dataIsAscending) {
        return typeof keyA === 'string' ? keyB.localeCompare(keyA) : keyB - keyA;
      } else {
        return typeof keyA === 'string' ? keyA.localeCompare(keyB) : keyA - keyB;
      }
    })
  }

  changeShowSelectedLohkot() {
    this.showOnlySelectedPeltolohkot = !this.showOnlySelectedPeltolohkot;
    this.lohkoDataModified.filter(peruslohko => peruslohko.isSelected)
    if (this.showOnlySelectedPeltolohkot) {
      this.lohkoDataModified.filter(peruslohko => peruslohko.isSelected)
    }
  }

  selectEveryPeruslohko(checked) {
    this.lohkotiedotArray.forEach(lohko => {
      this.onTogglePeruslohkoChecked(lohko, checked);
    })
  }

  onTogglePeruslohkoChecked(peruslohko, checked) {
    peruslohko.isSelected = checked;
    peruslohko.kasvulohkot.forEach(kasvulohko => kasvulohko.isSelected = checked);
  }

  onToggleKasvulohkoChecked(kasvulohko: any, peruslohko: any, checked: any) {
    kasvulohko.isSelected = checked;

    const anyKasvulohkoSelected = peruslohko.kasvulohkot.some(kasvulohko => kasvulohko.isSelected);
    peruslohko.isSelected = anyKasvulohkoSelected;
  }

  createPeruslohkoGroup(lohkotunnus?: string, lohkoNimi?: string, pintaAla?: number, kasvulohkot?: FieldArray) {
    return new FieldGroup(Field.build(), {
      lohkotunnus: new FieldControl(Field.build(), lohkotunnus),
      lohkoNimi: new FieldControl(Field.build(), lohkoNimi),
      pintaAla: new FieldControl(Field.build(), pintaAla),
      kasvulohkot: kasvulohkot
    });
  }

  createKasvulohkoGroup(lohkotunnus?: string, pintaAla?: number) {
    return new FieldGroup(Field.build(), {
      lohkotunnus: new FieldControl(Field.build(), lohkotunnus),
      pintaAla: new FieldControl(Field.build(), pintaAla)
    })
  }

  addPeltolohkot() {
    if (this.esitayttoValinta.get('esitaytto').value === 'kasin') {
      this.addLohkotiedotKasin();
    } else if (this.esitayttoValinta.get('esitaytto').value === 'vipu') {
      this.addLohkotiedotVipu();
    } else {
      this.activeModal.close();
    }
  }

  addLohkotiedotKasin() {
    this.submitted = true;

    if (this.peltolohkotKasinArray.valid) {
      if (this.peltolohkotKasinArray.length) {
        this.activeModal.close(this.peltolohkotKasinArray);
      } else {
        this.activeModal.close();
      }
    }
  }

  addLohkotiedotVipu() {
    const selectedLohkot = this.lohkotiedotArray.filter(peruslohko => peruslohko.isSelected)
    const lohkotFieldArray = new FieldArray(Field.build(), () => this.createPeruslohkoGroup());

    selectedLohkot.forEach(peruslohko => {
      peruslohko.kasvulohkot.filter(kasvulohko => kasvulohko.isSelected)

      const kasvuLohkotFieldArray = new FieldArray(Field.build(), () => this.createKasvulohkoGroup());

      peruslohko.kasvulohkot.forEach(kasvulohko => {
        if (kasvulohko.isSelected) {
          kasvuLohkotFieldArray.push(this.createKasvulohkoGroup(kasvulohko.lohkotunnus, kasvulohko.pintaAla))
        }
      })

      lohkotFieldArray.push(this.createPeruslohkoGroup(
          peruslohko.lohkotunnus,
          peruslohko.lohkoNimi,
          peruslohko.pintaAla,
          kasvuLohkotFieldArray
      ))
    })

    if (lohkotFieldArray.length) {
      this.activeModal.close(lohkotFieldArray);
    } else {
      this.activeModal.close();
    }
  }
}

