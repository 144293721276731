import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl} from "@angular/forms";
import {QuestionComponent, QuestionComponentData, QuestionGroup} from "ee-lahete";

@Component({
  selector: 'question-radio',
  template: `
    <div [formGroup]="controlContainer?.control" class="form-check d-flex align-items-center mb-4" [ngClass]="{'disabled': control.disabled}">
      <input class="form-check-input radio-button"
             type="radio"
             [id]="questionData.htmlId"
             [formControlName]="questionData.formControlName"
             [checked]="isSelected()"
             [value]="questionData.controlValue">
      <label class="form-check-label pt-1 ml-3" [htmlFor]="questionData.htmlId">{{questionData.label}}</label>
    </div>
    <question-error [control]="control"
                    [sivuRef]="sivuRef"
                    [fullPath]="questionData.question.fullPath"
                    [conditions]="[isLast]"
                    [errorMessages]="questionData.question.errorMessages"></question-error>
  `
})
export class QuestionRadioComponent extends QuestionComponent implements OnInit {
  @Input() questionData: QuestionComponentData;
  @Input() questionParent: QuestionGroup;
  @Input() isLast = false;
  @Input() sivuRef: string;

  control: FormControl;

  constructor(public controlContainer: ControlContainer) {
    super();
  }

  public isSelected() {
    return this.questionData.question.value === this.control.value;
  }

  ngOnInit(): void {
    if (this.controlContainer.control && this.questionData) {
      this.control = this.controlContainer.control.get(this.questionData.formControlName) as FormControl;
    }
  }
}
