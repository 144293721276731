import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Teksti} from "./teksti";

@Component({
  selector: 'yes-no-badge',
  template: `
    <span *ngIf="tilaTeksti !== null" class="badge text-uppercase" [ngClass]="tilaTyyli">
      {{tilaTeksti.getFullKey() | attribute}}
    </span>
  `
})
export class YesNoBadgeComponent implements OnChanges {

  @Input() tila: boolean;

  tilaTyyli: string = null;
  tilaTeksti: Teksti = null;

  @Input() trueTeksti = new Teksti("Kyllä", "kylla", "teksti");
  @Input() falseTeksti = new Teksti("Ei", "ei", "teksti");
  @Input() trueTyyli = "badge badge-success";
  @Input() falseTyyli = "badge badge-warning";

  ngOnChanges(change: SimpleChanges): void {
    const tila = change.tila.currentValue;
    this.tilaTeksti = tila ? this.trueTeksti : this.falseTeksti;
    this.tilaTyyli = tila ? this.trueTyyli : this.falseTyyli;
  }
}
